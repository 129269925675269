import { Injectable } from '@angular/core';
import { ProtoUtil } from '../util/proto-util';
import { AppError } from '../../general/util/error';
import { StorageService } from './storage.service';
import { NavigationService } from './navigation.service';
import { AuthenticationStatus } from '../util/util';
import { Util } from 'src/app/general/util/util';
import { TimeUtil } from 'src/app/general/util/time-util';
import { Param } from 'src/app/general/interfaces/param';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private authToken: string;
  private validUntil: Date;
  private location: proto.waiternow.common.ILocationProto | null | undefined;
  private checkOrdersPollRate: proto.google.protobuf.IDuration | null | undefined;
  private heartbeatRate: proto.google.protobuf.IDuration | null | undefined;

  // firebaseMessagingToken here is to avoid a circular dependency in FirebaseService.
  // FirebaseService gets the firebaseMessagingToken, then DeviceSessionService uses it to
  // start a device session. But FirebaseService uses FirebaseMessageHandlerService to delegate
  // the actual message handling. But FirebaseMessageHandlerService uses DeviceSessionService
  // to terminate the device session on message request. And then DeviceSessionService uses
  // FirebaseService to get the token.
  // So, to avoid this circular dependency, AppComponent initializes FirebaseService and sets
  // the token here. Now DeviceSessionService uses this firebaseMessagingToken to start the
  // device session.
  // There is one implication about this approach. Getting the firebaseMessagingToken
  // is an async operation. Thus we need to start the device session after the token is obtained.
  // In this app, AppComponent will initialize Firebase when the app is loaded (sign-in page is the
  // first), and the home page will start the device session (after sign-in). This will give enough
  // time for the token to be available.
  private firebaseMessagingToken: string | null | undefined;

  private ordersContinuationToken: string;

  constructor(
      private storageService: StorageService,
      private navigationService: NavigationService) {
    this.authToken = Util.safeString(this.storageService.getAuthTokenStore().get());
    const storedValidUntil = this.storageService.getAuthTokenValidUntilStore().get();
    if (storedValidUntil) {
      this.validUntil = storedValidUntil;
    } else {
      this.validUntil = new Date();
    }
    this.ordersContinuationToken = '';
  }

  public setAuthToken(
      value: string, since: proto.google.protobuf.ITimestamp | null | undefined,
      expiration: proto.google.protobuf.IDuration | null | undefined): void {
    this.authToken = value;
    if (since && expiration) {
      this.validUntil = TimeUtil.plusMillis(
        TimeUtil.createDateFromMillis(ProtoUtil.timestampProtoToMillis(since)),
        ProtoUtil.durationProtoToMillis(expiration));
    } else {
      // Uses 10 hours as default
      this.validUntil = TimeUtil.plusMillis(new Date(), 10 * 60 * 1000);
    }
    this.storageService.getAuthTokenStore().set(this.authToken);
    this.storageService.getAuthTokenValidUntilStore().set(this.validUntil);
  }

  public getAuthToken(): string {
    return this.authToken;
  }

  /**
   * Method to use with the action component to add the auth token form param.
   */
  public getAuthTokenFormParam(): Param {
    return { name: 'Auth-Token', value: this.authToken };
  }

  public setFirebaseMessagingToken(token: string | null | undefined): void {
    this.firebaseMessagingToken = token;
  }

  public getFirebaseMessagingToken(): string | null | undefined {
    return this.firebaseMessagingToken;
  }

  public setCheckOrdersPollRate(rate: proto.google.protobuf.IDuration | null | undefined): void {
    this.checkOrdersPollRate = rate;
  }

  public getCheckOrdersPollRate(): proto.google.protobuf.IDuration | null | undefined {
    return this.checkOrdersPollRate;
  }

  public setHeartbeatRate(rate: proto.google.protobuf.IDuration | null | undefined): void {
    this.heartbeatRate = rate;
  }

  public getHeartbeatRate(): proto.google.protobuf.IDuration | null | undefined {
    return this.heartbeatRate;
  }

  public setLocation(location: proto.waiternow.common.ILocationProto): void {
    this.location = location;
  }

  public getLocation(): proto.waiternow.common.ILocationProto | null | undefined {
    return this.location;
  }

  public setOrdersContinuationToken(token: string): void {
    this.ordersContinuationToken = token;
  }

  public getOrdersContinuationToken(): string {
    return this.ordersContinuationToken;
  }

  public logout() {
    this.authToken = '';
    this.storageService.getAuthTokenStore().clear();
    this.storageService.getAuthTokenValidUntilStore().clear;
    this.location = undefined;
    this.ordersContinuationToken = '';
  }

  public isLoggedIn() {
    if (!this.authToken || this.validUntil < TimeUtil.now()) {
      this.logout();
      return false;
    }
    return true;
  }

  /**
   * Checks whether the user is authenticated, if not it gets redirected to the sign in page.
   *
   * @returns true if the user is authenticated, false if the page will be redirected to the sign in page
   */
  public enforceAuthentication(): AuthenticationStatus {
    if (!this.isLoggedIn()) {
      this.navigationService.goToSignInPage();
      return AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE;
    }
    return AuthenticationStatus.USER_ALREADY_AUTHENTICATED;
  }

  /**
   * Checks whether the error is an authentication error and redirects to the sign in page.
   *
   * @param error error to check
   * @returns true if the error was handled, false otherwise
   */
  public handleAuthenticationError(error: AppError): boolean {
    if (error.isAuthenticationError()) {
      this.navigationService.goToSignInPage();
      return true;
    }
    return false;
  }
}
