import { Injectable } from '@angular/core';
import { DateFieldLocalStore, StringFieldLocalStore } from 'src/app/general/util/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  authTokenStore: StringFieldLocalStore;
  authTokenValidUntilStore: DateFieldLocalStore;
  lastUserEmailStore: StringFieldLocalStore;
  deviceIdStore: StringFieldLocalStore;
  locationIdStore: StringFieldLocalStore;

  constructor() {
      this.authTokenStore = new StringFieldLocalStore('auth-token');
      this.authTokenValidUntilStore = new DateFieldLocalStore('auth-token-valid-until');
      this.lastUserEmailStore = new StringFieldLocalStore('last-user-email');
      this.deviceIdStore = new StringFieldLocalStore('device-id');
      this.locationIdStore = new StringFieldLocalStore('location-id');
  }

  public getAuthTokenStore(): StringFieldLocalStore {
    return this.authTokenStore;
  }

  public getAuthTokenValidUntilStore(): DateFieldLocalStore {
    return this.authTokenValidUntilStore;
  }

  public getLastUserEmailStore(): StringFieldLocalStore {
    return this.lastUserEmailStore;
  }

  public getDeviceIdStore(): StringFieldLocalStore {
    return this.deviceIdStore;
  }

  public getLocationIdStore(): StringFieldLocalStore {
    return this.locationIdStore;
  }
}
