import * as proto from 'src/proto/compiled-protos';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { SessionService } from './session.service';
import { Util } from 'src/app/general/util/util';
import { LoggingService } from 'src/app/general/services/logging.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import { Timer } from 'src/app/general/util/timer';
import { StorageService } from './storage.service';
import { Observable, of, map } from 'rxjs';
import { IndeterminatePaginatorData } from 'src/app/general/components/indeterminate-paginator/indeterminate-paginator-model';
import { DeviceSessionService } from './device-session.service';
import { ProtoUtil } from '../util/proto-util';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  // This location id is only used to compare with the one in the session. If they are different, the data is cleared.
  // We cannot call OrdersService from SessionService, otherwise a circular dependency would be introduced.
  private locationId: string | null | undefined;

  private pendingOrders: Array<proto.waiternow.common.IOrderProto>;
  private pendingOrdersIds: Set<string>;
  // For paid orders history, the OrderHistoryPageComponent uses this service to avoid refetching the same orders again
  // and again. For example, when an order is selected from the history, and we click the back button. We don't want to
  // refetch the same orders.
  private paidOrdersHistory: Array<proto.waiternow.common.IOrderProto>;
  private paidOrdersHistoryIds: Set<string>;
  private paidOrdersHistoryContinuationToken: string | null | undefined;

  private initialized: boolean;
  private isFirebaseMessagingEnabled: boolean = false;

  // The home page sets these listeners on ngInit() and removes them on ngDestroy().
  private onNewPendingOrder?: Consumer<proto.waiternow.common.IOrderProto>
  private onPendingOrderUpdated?: Consumer<proto.waiternow.common.IOrderProto>
  private onPendingOrderRemoved?: Consumer<proto.waiternow.common.IOrderProto>

  // These timers once created are only stopped and reset on login. However if no location is selected
  // the onTick() method does nothing.
  // Note that we cannot use a timer that is started every time the home page is initialized and stopped
  // on ngDestroy(). The home page is left when we open an order or another page. Thus, we could be starting
  // and stopping timers, and with the combination of all these timer reinitializations, it is possible that
  //  the heartbeat is not sent for 35 minutes, which is the value of business_device_session_expiration_minutes
  // (to consider a location offline).
  private heartbeatTimer?: Timer;
  // If Firebase messages are enabled, FirebaseMessageHandlerService calls OrderFetcherService.fetchOrders()
  // If Firebase messages are enabled, fetchOrdersTimer is setup with safetyCheckOrdersPollRate
  // If Firebase messages are not supported or enabled, fetchOrdersTimer is setup with checkOrdersPollRate
  private fetchPendingOrdersTimer?: Timer;

  constructor(
      private backendService: BackendService,
      private sessionService: SessionService,
      private deviceSessionService: DeviceSessionService,
      private loggingService: LoggingService,
      private storageService: StorageService) {
    this.locationId = '';
    this.pendingOrders = [];
    this.pendingOrdersIds = new Set<string>();
    this.paidOrdersHistory = [];
    this.paidOrdersHistoryIds = new Set<string>();
    this.paidOrdersHistoryContinuationToken = '';
    this.initialized = false;
  }

  private clearData(): void {
    this.locationId = '';
    this.pendingOrders = [];
    this.pendingOrdersIds = new Set<string>();
    this.paidOrdersHistory = [];
    this.paidOrdersHistoryIds = new Set<string>();
    this.paidOrdersHistoryContinuationToken = '';
    this.initialized = false;
  }

  // We don't call FirebaseService.getFirebaseMessagingToken() to avoid a circular dependency.
  public onIsFirebaseMessagingEnabled() {
    this.isFirebaseMessagingEnabled = true;
    // On login, setPollingRates() is called which initializes timers. However, if the user refreshes the page,
    // the auth token is in the storage service and setPollingRates() is not called from login. In that case
    // we use this initialization from the storage.
    this.setPollingRates(
        this.createDurationFromSeconds(this.storageService.getHeartbeatRateSecondsStore().get()),
        this.createDurationFromSeconds(this.storageService.getCheckOrdersPollRateSecondsStore().get()),
        this.createDurationFromSeconds(this.storageService.getSafetyCheckOrdersPollRateSecondsStore().get())
    );
  }

  // We don't call FirebaseService.getFirebaseMessagingToken() to avoid a circular dependency.
  public onIsFirebaseMessagingDisabled() {
    this.isFirebaseMessagingEnabled = false;
    // On login, setPollingRates() is called which initializes timers. However, if the user refreshes the page,
    // the auth token is in the storage service and setPollingRates() is not called from login. In that case
    // we use this initialization from the storage.
    this.setPollingRates(
        this.createDurationFromSeconds(this.storageService.getHeartbeatRateSecondsStore().get()),
        this.createDurationFromSeconds(this.storageService.getCheckOrdersPollRateSecondsStore().get()),
        this.createDurationFromSeconds(this.storageService.getSafetyCheckOrdersPollRateSecondsStore().get())
    );
  }

  public setPollingRates(
      heartbeatRate: proto.google.protobuf.IDuration | null | undefined,
      checkOrdersPollRate: proto.google.protobuf.IDuration | null | undefined,
      safetyCheckOrdersPollRate: proto.google.protobuf.IDuration | null | undefined) {

    if (this.heartbeatTimer) {
      this.heartbeatTimer.stop()
      this.heartbeatTimer = undefined;
    }
    if (this.fetchPendingOrdersTimer) {
      this.fetchPendingOrdersTimer.stop()
      this.fetchPendingOrdersTimer = undefined;
    }

    if (heartbeatRate && heartbeatRate.seconds) {
      this.loggingService.logDebug('Setting up heartbeat with a rate of ' + heartbeatRate.seconds + ' seconds');
      this.heartbeatTimer = new Timer(/* onTick= */ () => this.sendHeartbeat(), heartbeatRate.seconds * 1000);
      this.heartbeatTimer.start();
    }

    if (this.isFirebaseMessagingEnabled) {
      if (safetyCheckOrdersPollRate && safetyCheckOrdersPollRate.seconds) {
        this.loggingService.logDebug('Firebase messaging enabled; setting up safety check orders polling with a rate of ' + safetyCheckOrdersPollRate.seconds + ' seconds');
        this.fetchPendingOrdersTimer = new Timer(/* onTick= */ () => this.fetchPendingOrders(), safetyCheckOrdersPollRate.seconds * 1000);
        this.fetchPendingOrdersTimer.start();
      }
    } else {
      if (checkOrdersPollRate && checkOrdersPollRate.seconds) {
        this.loggingService.logDebug('Firebase messaging not supported or disabled; setting up check orders polling with a rate of ' + checkOrdersPollRate.seconds + ' seconds');
        this.fetchPendingOrdersTimer = new Timer(/* onTick= */ () => this.fetchPendingOrders(), checkOrdersPollRate.seconds * 1000);
        this.fetchPendingOrdersTimer.start();
      }
    }

    if (heartbeatRate && heartbeatRate.seconds) {
      this.storageService.getHeartbeatRateSecondsStore().set(heartbeatRate.seconds);
    }
    if (checkOrdersPollRate && checkOrdersPollRate.seconds) {
      this.storageService.getCheckOrdersPollRateSecondsStore().set(checkOrdersPollRate.seconds);
    }
    if (safetyCheckOrdersPollRate && safetyCheckOrdersPollRate.seconds) {
      this.storageService.getSafetyCheckOrdersPollRateSecondsStore().set(safetyCheckOrdersPollRate.seconds);
    }
  }

  public setPendingOrdersListener(
      onNewPendingOrder?: Consumer<proto.waiternow.common.IOrderProto>,
      onPendingOrderUpdated?: Consumer<proto.waiternow.common.IOrderProto>,
      onPendingOrderRemoved?: Consumer<proto.waiternow.common.IOrderProto>) {
    this.onNewPendingOrder = onNewPendingOrder;
    this.onPendingOrderUpdated = onPendingOrderUpdated;
    this.onPendingOrderRemoved = onPendingOrderRemoved;

    const location = this.sessionService.getLocation();
    if (!location || (this.locationId != location.id)) {
      this.clearData();
      if (location) {
        this.locationId = location.id;
      }
    }

    if (!this.initialized) {
      this.initialized = true;
      this.fetchPendingOrders();
    } else {
      if (this.onNewPendingOrder) {
        for (const order of this.pendingOrders) {
          this.onNewPendingOrder(order);
        }
      }
    }
  }

  public clearListener() {
    this.onNewPendingOrder = undefined;
    this.onPendingOrderRemoved = undefined;
  }

  public sendHeartbeat(): void {
    if (!this.sessionService.getLocation()) {
      return;
    }
    this.loggingService.logInfo('Sending Heartbeat');
    this.deviceSessionService.sendHeartbeat();
  }

  public fetchPendingOrders() {
    if (!this.sessionService.isLoggedIn()) {
      this.clearData();
      return;
    }

    const location = this.sessionService.getLocation();
    if (!location) {
      this.clearData();
      return;
    }

    if (this.locationId != location.id) {
      this.clearData();
      this.locationId = location.id;
    }

    this.loggingService.logInfo('Fetching orders with continuation token');
    this.backendService.findPendingOrders(
      Util.safeString(location.id),
      this.sessionService.getPendingOrdersContinuationToken(),
      /* onSuccess= */ ordersProto => {
        if (ordersProto && ordersProto.orders) {
          if (ordersProto.continuationToken) {
            // We only replace the continuation token from the session if it is not empty.
            // Unacked orders don't use mark because its status may change in short time and
            // the mark may not exists anymore. Thus the continuation token is based on the
            // timestamp of the last order. If there is no new orders, the continuation token
            // will come empty.
            // The continuation token is stored in SessionService because it is cleared on lougout and when a new location is selected.
            this.sessionService.setPendingOrdersContinuationToken(ordersProto.continuationToken);
          }
          const newPaidOrdersForHistory: Array<proto.waiternow.common.IOrderProto> = [];
          for (const order of ordersProto.orders) {
            const safeOrderId = Util.safeString(order.id);
            if (!this.pendingOrdersIds.has(safeOrderId)) {
              this.pendingOrdersIds.add(safeOrderId);
              this.pendingOrders.push(order);
              if (this.onNewPendingOrder) {
                this.onNewPendingOrder(order);
              }
            }
            if (this.isPaidOrder(order) && !this.paidOrdersHistoryIds.has(safeOrderId)) {
              this.paidOrdersHistoryIds.add(safeOrderId);
              newPaidOrdersForHistory.push(order);
            }
          }
          // Pending orders are kept in ascending order by creation time: older order first
          // Paid orders history is kept in decending order. Newest order first
          this.paidOrdersHistory = [...newPaidOrdersForHistory.reverse(), ...this.paidOrdersHistory];
        }
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error fetching orders');
      }
    );
  }

  private isPaidOrder(order: proto.waiternow.common.IOrderProto): boolean {
    // No need to chedk if the payment is successful, backendService.findPendingOrders()
    // would only return successfull paid orders.
    if (order && order.status && order.status.payment) {
      return true;
    }
    return false;
  }

  // This method is for the order history page. The current paidOrdersHistory is used as initial data for
  // the paginator when it is initialized. And if more orders are fetched, the paidOrdersHistory is
  // updated before returning more data to the paginator.
  public fetchPaidOrders(): Observable<IndeterminatePaginatorData<proto.waiternow.common.IOrderProto>> {
    const location = this.sessionService.getLocation();
    if (!location) {
      return of({data: [], continuationToken: ''});
    }
    return this.backendService.findLocationPaidOrdersReturnObservable(Util.safeString(location.id), this.paidOrdersHistoryContinuationToken)
      .pipe(
        map(locationOrders => {
          const paginatorData: IndeterminatePaginatorData<proto.waiternow.common.IOrderProto> = {data: [], continuationToken: this.paidOrdersHistoryContinuationToken};
          if (locationOrders) {
            paginatorData.continuationToken = locationOrders.continuationToken;
            this.paidOrdersHistoryContinuationToken = locationOrders.continuationToken;
            if (locationOrders.orders) {
              for (const order of locationOrders.orders) {
                const safeOrderId = Util.safeString(order.id);
                if (!this.paidOrdersHistoryIds.has(safeOrderId)) {
                  this.paidOrdersHistoryIds.add(safeOrderId);
                  this.paidOrdersHistory.push(order);
                  if (paginatorData.data) {
                    paginatorData.data.push(order);
                  }
                }
              }
            }
          }
          return paginatorData;
        })
      );
  }

  // This method is for the order history page to be used as the initial data for the paginator.
  public getPaidOrders(): IndeterminatePaginatorData<proto.waiternow.common.IOrderProto> {
    return { data: [...this.paidOrdersHistory], continuationToken: this.paidOrdersHistoryContinuationToken};
  }

  public orderAcked(order: proto.waiternow.common.IOrderProto) {
    const updatedOrder = new proto.waiternow.common.OrderProto(order);
    if (!updatedOrder.status) {
      updatedOrder.status = new proto.waiternow.common.OrderProto.StatusProto();
    }
    // The time stamp does not actually matter here. It is just to locally signal that the order has been acked.
    updatedOrder.status.acked = new proto.google.protobuf.Timestamp();
    this.orderUpdated(updatedOrder);
  }

  public orderCompleted(order: proto.waiternow.common.IOrderProto) {
    const updatedOrder = new proto.waiternow.common.OrderProto(order);
    if (!updatedOrder.status) {
      updatedOrder.status = new proto.waiternow.common.OrderProto.StatusProto();
    }
    // The time stamp does not actually matter here. It is just to locally signal that the order has been acked.
    updatedOrder.status.acked = new proto.google.protobuf.Timestamp();
    updatedOrder.status.completed = new proto.google.protobuf.Timestamp();
    this.orderUpdated(updatedOrder);
    this.removePendingOrder(order);
  }

  public orderRefunded(order: proto.waiternow.common.IOrderProto) {
    /*
    This code could be used to show the refund time after the order is refunded. However we need a way to
    update the order in OrderComponent. If the order page is refreshed, the order will be fetched from the
    backend and it wil have the refund time.

    const updatedOrder = new proto.waiternow.common.OrderProto(order);
    if (updatedOrder.status && updatedOrder.status.payment) {
      const payment = updatedOrder.status.payment;
      payment.refundStatus = new proto.waiternow.common.PaymentProto.RefundStatusProto();
      payment.refundStatus.refundTime = ProtoUtil.createTimestampProtoFromMillis(new Date().getTime());
    }
    */
    this.removePendingOrder(order);
    this.removePaidOrderFromHistory(order);
  }

  public searchOrderById(orderId: string): proto.waiternow.common.IOrderProto | undefined {
    for (const order of this.pendingOrders) {
      if (order.id == orderId) {
        return order;
      }
    }
    for (const order of this.paidOrdersHistory) {
      if (order.id == orderId) {
        return order;
      }
    }
    return undefined;
  }

  public removePendingOrder(order: proto.waiternow.common.IOrderProto) {
    this.pendingOrders = this.pendingOrders.filter(arrayItem => arrayItem.id != order.id);
    if (this.onPendingOrderRemoved) {
      this.onPendingOrderRemoved(order);
    }
  }

  public removePaidOrderFromHistory(order: proto.waiternow.common.IOrderProto) {
    this.paidOrdersHistory = this.paidOrdersHistory.filter(arrayItem => arrayItem.id != order.id);
  }

  public orderUpdated(updatedOrder: proto.waiternow.common.IOrderProto) {
    {
      const newPendingOrders: Array<proto.waiternow.common.IOrderProto> = [];
      for (const order of this.pendingOrders) {
        if (order.id != updatedOrder.id) {
          newPendingOrders.push(order);
        } else {
          newPendingOrders.push(updatedOrder);
        }
      }
      this.pendingOrders = newPendingOrders;
      if (this.onPendingOrderUpdated) {
        this.onPendingOrderUpdated(updatedOrder);
      }
    }
    {
      const newPaidOrderHistory: Array<proto.waiternow.common.IOrderProto> = [];
      for (const order of this.paidOrdersHistory) {
        if (order.id != updatedOrder.id) {
          newPaidOrderHistory.push(order);
        } else {
          newPaidOrderHistory.push(updatedOrder);
        }
      }
      this.paidOrdersHistory = newPaidOrderHistory;
    }
  }

  private createDurationFromSeconds(seconds: number | null | undefined) {
    if (!seconds) {
      return undefined;
    }
    const duration = new proto.google.protobuf.Duration();
    duration.seconds = seconds;
    return duration;
  }
}
