import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { SessionService } from './session.service';
import { Util } from 'src/app/general/util/util';
import { LoggingService } from 'src/app/general/services/logging.service';
import { Consumer } from 'src/app/general/interfaces/functions';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private orders: Array<proto.waiternow.common.IOrderProto>;
  private orderIds: Set<string>;

  private initialized: boolean;

  private onOrderAdded?: Consumer<proto.waiternow.common.IOrderProto>
  private onOrderRemoved?: Consumer<proto.waiternow.common.IOrderProto>

  constructor(
      private backendService: BackendService,
      private sessionService: SessionService,
      private loggingService: LoggingService) {
    this.orders = [];
    this.orderIds = new Set<string>();
    this.initialized = false;
  }

  public setListener(onOrderAdded?: Consumer<proto.waiternow.common.IOrderProto>, onOrderRemoved?: Consumer<proto.waiternow.common.IOrderProto>) {
    this.onOrderAdded = onOrderAdded;
    this.onOrderRemoved = onOrderRemoved;
    if (!this.initialized) {
      this.initialized = true;
      this.fetchOrders();
    } else {
      if (this.onOrderAdded) {
        for (const order of this.orders) {
          this.onOrderAdded(order);
        }
      }
    }
  }

  public clearListener() {
    this.onOrderAdded = undefined;
    this.onOrderRemoved = undefined;
  }

  public fetchOrders() {
    if (!this.sessionService.isLoggedIn()) {
      this.loggingService.logInfo('Clearing orders due to logout');
      this.orderIds.clear();
      this.orders = [];
      this.initialized = false;
      return;
    }

    this.loggingService.logInfo('Fetching orders with continuation token');
    if (this.sessionService.getLocation()) {
      this.backendService.findPendingOrders(
        Util.safeString(this.sessionService.getLocation()?.id),
        this.sessionService.getOrdersContinuationToken(),
        /* onSuccess= */ ordersProto => {
          if (ordersProto && ordersProto.orders) {
            if (ordersProto.continuationToken) {
              // We only replace the continuation token from the session if it is not empty.
              // Unacked orders don't use mark because its status may change in short time and
              // the mark may not exists anymore. Thus the continuation token is based on the
              // timestamp of the last order. If there is no new orders, the continuation token
              // will come empty.
              // The continuation token is stored in SessionService because it is cleared on lougout.
              this.sessionService.setOrdersContinuationToken(ordersProto.continuationToken);
            }
            for (const order of ordersProto.orders) {
              const safeOrderId = Util.safeString(order.id);
              if (!this.orderIds.has(safeOrderId)) {
                this.orderIds.add(safeOrderId);
                this.orders.push(order);
                if (this.onOrderAdded) {
                  this.onOrderAdded(order);
                }
              }
            }
          }
        },
        /* onError= */ error => {
          this.loggingService.logErrorWithCause(error, 'Error fetching orders');
        }
      );
    }
  }

  public orderAcked(order: proto.waiternow.common.IOrderProto) {
    if (!order.redundantData || !order.redundantData.isCompletedStatusExpected) {
      this.orders = this.orders.filter(arrayItem => arrayItem.id != order.id);
      if (this.onOrderRemoved) {
        this.onOrderRemoved(order);
      }
    }
  }

  public orderCompleted(order: proto.waiternow.common.IOrderProto) {
    this.orders = this.orders.filter(arrayItem => arrayItem.id != order.id);
    if (this.onOrderRemoved) {
      this.onOrderRemoved(order);
    }
  }

  public searchOrderById(orderId: string): proto.waiternow.common.IOrderProto | undefined {
    for (const order of this.orders) {
      if (order.id == orderId) {
        return order;
      }
    }
    return undefined;
  }
}
