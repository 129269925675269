<app-loadable-content [loadingController]="orderLoadingController" (onRetry)="loadOrder()">
    <br>
    <div *ngIf="isWaiterRequest()">
        <table align="center">
            <tr><td align="center"><h1>{{ getPointOfServiceFriendlyName() }}</h1></td></tr>
            <tr *ngIf="getCustomerName()"><td align="center"><h2>{{ getCustomerName() }}</h2></td></tr>
            <tr *ngIf="getWaiterRequestAction()"><td align="center"><h3>{{ getWaiterRequestAction() }}</h3></td></tr>
            <tr *ngIf="getWaiterRequestCustomMessage()"><td align="center"><h3>{{ getWaiterRequestCustomMessage() }}</h3></td></tr>
        </table>
    </div>
    <div *ngIf="isStructuredMenuOrder()">
        <table width="100%">
            <tr *ngIf="isOrderRefunded">
                <td align="center" class="order-refunded">
                    {{ 'order_refunded' | translate }}
                </td>
            </tr>
            <tr *ngFor="let refundTime of getRefundTimes()">
                <td align="center">
                    {{ refundTime }}
                </td>
            </tr>
            <tr><br></tr>
            <tr>
                <td>
                    <table width="100%">
                        <tr align="center">
                            <td colspan="2"><h3>{{ getOrderType() }}</h3></td>
                        </tr>
                        <tr>
                            <td colspan="2">{{ 'order_number' | translate }}</td>
                        </tr>
                        <tr>
                            <!-- These spaces and the td width define the size of the table for all entries -->
                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                            <td width="100%">{{ getOrderNumber() }}</td>
                        </tr>
                        <tr>
                            <td colspan="2">{{ 'customer_name' | translate }}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td>{{ getCustomerName() }}</td>
                        </tr>
                        <tr *ngIf="getOrderTime()">
                            <td colspan="2">{{ getOrderTimeLabel() }}</td>
                        </tr>
                        <tr *ngIf="getOrderTime()">
                            <td></td>
                            <td>{{ getOrderTime() }}</td>
                        </tr>
                        <tr *ngIf="getEstimatedDropoffTime()">
                            <td colspan="2">{{ 'estimated_dropoff_time' | translate }}</td>
                        </tr>
                        <tr *ngIf="getEstimatedDropoffTime()">
                            <td></td>
                            <td>{{ getEstimatedDropoffTime() }}</td>
                        </tr>
                        <tr *ngIf="isDelivery()">
                            <td colspan="2">{{ 'delivery_address' | translate }}</td>
                        </tr>
                        <tr *ngIf="isDelivery()">
                            <td></td>
                            <td>{{ getDeliveryAddress() }}</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr><td><hr style="width:100%; border-style: none none dotted;"></td></tr>
            <tr>
                <td>
                    <table width="100%" class="menu-entry-text">
                        <!-- This line only defines the sizes. The first 2 <td> are used for indentation so the smallest the better. -->
                        <tr><td></td><td></td><td></td><td width="100%"></td></tr>
                        <!--
                            For combos we use 3 <td> for Combo name, menu item name and selections.
                            For menu items we use 2 <td> for menu item name and selections.
                            The table has to have the same ampount of <td>, we use space to indent.
                        -->
                        <ng-template let-menuEntry ngFor [ngForOf]="getMenuEntries()">
                            <!--
                                Menu Item
                            -->
                            <ng-template [ngIf]="menuEntry.menuItem">
                                <tr>
                                    <td>
                                        {{ getQuantity(menuEntry) }}
                                    </td>
                                    <td colspan="3">
                                        {{ getText(menuEntry.menuItem.name) }}
                                    </td>
                                </tr>
                                <ng-template let-itemSelection ngFor [ngForOf]="menuEntry.menuItem.itemSelections">
                                    <tr *ngFor="let selectedItem of itemSelection.selectedItems">
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td colspan="2">
                                            {{ getText(selectedItem.name) }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <ng-template [ngIf]="menuEntry.menuItem.withoutIngredients">
                                    <tr *ngFor="let itemToRemove of menuEntry.menuItem.withoutIngredients">
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td colspan="2" class="without-ingredient">
                                            No {{ getText(itemToRemove.name) }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <tr *ngIf="menuEntry.menuItem.specialInstructions">
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td colspan="2">
                                        {{ menuEntry.menuItem.specialInstructions }}
                                    </td>
                                </tr>
                            </ng-template>
                            <!--
                                Combo
                            -->
                            <ng-template [ngIf]="menuEntry.combo">
                                <tr>
                                    <td>
                                        {{ getQuantity(menuEntry) }}
                                    </td>
                                    <td colspan="3">
                                        {{ getText(menuEntry.combo.name) }}
                                    </td>
                                </tr>
                                <ng-template let-itemSelection ngFor [ngForOf]="menuEntry.combo.itemSelections">
                                    <tr *ngFor="let selectedItem of itemSelection.selectedItems">
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td colspan="2">
                                            {{ getText(selectedItem.name) }}
                                        </td>
                                    </tr>
                                </ng-template>
                                <!-- getMenuItems() returns the combo menu items, and all menu items selections -->
                                <ng-template let-menuItem ngFor [ngForOf]="getMenuItems(menuEntry.combo)">
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                        <td colspan="3">
                                            {{ getText(menuItem.name) }}
                                        </td>
                                    </tr>
                                    <ng-template let-itemSelection ngFor [ngForOf]="menuItem.itemSelections">
                                        <tr *ngFor="let selectedItem of itemSelection.selectedItems">
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                            <td>
                                                {{ getText(selectedItem.name) }}
                                            </td>
                                        </tr>
                                    </ng-template>
                                    <ng-template [ngIf]="menuItem.withoutIngredients">
                                        <tr *ngFor="let itemToRemove of menuItem.withoutIngredients">
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                            <td class="without-ingredient">
                                                No {{ getText(itemToRemove.name) }}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </ng-template>
                                <tr *ngIf="menuEntry.combo.specialInstructions">
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                    <td colspan="2">
                                        {{ menuEntry.combo.specialInstructions }}
                                    </td>
                                </tr>
                            </ng-template>
                        </ng-template>
                    </table>
                </td>
            </tr>
            <tr><td><hr style="width:100%; border-style: none none dotted;"></td></tr>
            <tr><td><br></td></tr>
            <tr><td align="center" class="order-label" *ngIf="getToGoLabel()">{{ getToGoLabel() }}</td></tr>
            <tr><td align="center" class="order-label" *ngIf="getNoCutleryLabel()">{{  getNoCutleryLabel() }}</td></tr>
            <tr><td align="center" class="order-label" *ngIf="getForLaterLabel()">{{  getForLaterLabel() }}</td></tr>
            <tr><td align="center" class="order-label" *ngIf="getIdRequiredLabel()">{{  getIdRequiredLabel() }}</td></tr>
            <tr><td align="center" class="order-label" *ngIf="getContactlessDropoffLabel()">{{  getContactlessDropoffLabel() }}</td></tr>
            <tr><td align="center" class="order-label" *ngIf="getDropoffInstructions()">{{  getDropoffInstructions() }}</td></tr>
            <tr><td><br></td></tr>
        </table>
    </div>
    <table align="center">
        <tr>
            <td>
                <app-action
                    text="{{ 'back' | translate }}"
                    (onExecute)="goBack()">
                </app-action>
            </td>
            <td *ngIf="isCompletedStatusExpected()">
                <app-action
                    text="{{ 'mark_as_completed' | translate }}"
                    [disabled]="isOrderMarkedAsCompleted"
                    (onAsyncExecute)="markOrderAsCompleted($event)">
                </app-action>
            </td>
            <td *ngIf="isPaidOrder()">
                <app-action
                    text="{{ 'refund' | translate }}"
                    [disabled]="isOrderRefunded"
                    (onAsyncExecute)="refundOrder($event)">
                </app-action>
            </td>
        </tr>
    </table>
</app-loadable-content>
