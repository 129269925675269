import * as proto from 'src/proto/compiled-protos';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { LocalizationService } from '../../../general/services/localization.service';
import { SessionService } from '../../services/session.service';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStatus } from '../../util/util';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { DeviceSessionService } from '../../services/device-session.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { OrdersService } from '../../services/orders.service';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { Util } from 'src/app/general/util/util';
import { Formatter } from '../../util/formatter';
import { ToolbarAction } from 'src/app/general/components/toolbar/toolbar-action';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, SuccessResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { VersionService } from '../../services/version.service';
import { SubtitleComponent } from 'src/app/general/components/subtitle/subtitle.component';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    ActionComponent,
    TitleComponent,
    SubtitleComponent,
    DataTableComponent
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, OnDestroy {

  public pendingOrders: Array<proto.waiternow.common.IOrderProto>;
  public ordersColumns: Array<DataTableColumn<proto.waiternow.common.IOrderProto>>;

  public toolbarActions: Array<ToolbarAction<proto.waiternow.common.IOrderProto>>;

  constructor(
      public localizationService: LocalizationService,
      private sessionService: SessionService,
      private backendService: BackendService,
      private navigationService: NavigationService,
      private activatedRoute: ActivatedRoute,
      private deviceSession: DeviceSessionService,
      private changeDetectorRef: ChangeDetectorRef,
      private ordersService: OrdersService,
      private versionService: VersionService) {
    this.versionService.getVersion(
      // Version is printed by the version service. For the moment we just let the logger to print the version.
      /* onSuccess */ version => {},
      /* onError */ () => {}
    );

    this.ordersColumns = [
      new StringColumn(
        /* name= */ 'orderNumber',
        /* translateId= */ 'order_number',
        /* valueExtractor= */  order => this.getOrderNumber(order)),
      new StringColumn(
        /* name= */ 'orderType',
        /* translateId= */ 'order_type',
        /* valueExtractor= */  order => this.getOrderType(order)),
      new StringColumn(
        /* name= */ 'customerName',
        /* translateId= */ 'customer_name',
        /* valueExtractor= */  order => this.getCustomerName(order),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'summary',
        /* translateId= */ 'summary',
        /* valueExtractor= */  order => this.getOrderSummary(order),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'creationTime',
        /* translateId= */ 'creation_time',
        /* valueExtractor= */  order => this.getOrderCreationTime(order))
    ];
    this.pendingOrders = [];

    this.toolbarActions = [
      {
        textTranslateId: 'hide',
        isHidden: order => this.isHideActionHidden(order),
        isDisabled: order => this.isHideActionDisabled(order),
        onExecute: order => {
          if (order) {
            this.ordersService.removePendingOrder(order);
          }
        }
      },
      {
        textTranslateId: 'completed',
        isHidden: order => this.isMarkCompletedActionHidden(order),
        isDisabled: order => this.isMarkCompletedActionDisabled(order),
        onAsyncExecute: (order: proto.waiternow.common.IOrderProto | undefined, actionObserver: ActionObserver) => {
          this.markOrderAsCompleted(order, actionObserver);
        }
      }
    ];
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }
    this.navigationService.handleRedirect(this.activatedRoute, /* onNotRedirected= */ () => {
      // Put any remaining code on ngOnInit() inside this scope. Nothing should be after
      // this.navigationService.handleRedirect() otherwise it will execute regardless
      // the page is redirected.
      // navigationService.handleRedirect() uses async code to get query parameters and
      // thus it cannot return whether the page was redirected as
      // sessionService.enforceAuthentication() does.
      if (!this.sessionService.getLocation()) {
        this.navigationService.goToSelectLocationPage();
        return;
      }
      this.ordersService.setPendingOrdersListener(
        /* onNewPendingOrder= */ order => {
          // Angular does not detect changes with array.push(). It is better to replace the array instance.
          this.pendingOrders = [...this.pendingOrders, order];
          // The new order will come from a call to the backend, and thus Angular may not detect the changes.
          this.changeDetectorRef.detectChanges();
        },
        /* onPendingOrderUpdated= */ updatedOrder => {
          // Angular does not detect changes with array.push(). It is better to replace the array instance.
          const newOrders: Array<proto.waiternow.common.IOrderProto> = [];
          for (const order of this.pendingOrders) {
            if (order.id != updatedOrder.id) {
              newOrders.push(order);
            } else {
              newOrders.push(updatedOrder);
            }
          }
          this.pendingOrders = newOrders;
        },
        /* onPendingOrderRemoved= */ order => {
          // this.loggingService.logDebug('Order removed: ' + order.id);
          this.pendingOrders = this.pendingOrders.filter(arrayItem => arrayItem.id != order.id);
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.ordersService.clearListener();
  }

  public isHideActionHidden(order: proto.waiternow.common.IOrderProto | undefined): boolean {
    if (!order || !order.redundantData) {
      return true;
    }
    return Boolean(order.redundantData.isCompletedStatusExpected);
  }

  public isHideActionDisabled(order: proto.waiternow.common.IOrderProto | undefined): boolean {
    if (order && order.status && order.status.acked) {
      return false;
    }
    return true;
  }

  public isMarkCompletedActionHidden(order: proto.waiternow.common.IOrderProto | undefined): boolean {
    if (!order || !order.redundantData) {
      return true;
    }
    return !Boolean(order.redundantData.isCompletedStatusExpected);
  }

  public isMarkCompletedActionDisabled(order: proto.waiternow.common.IOrderProto | undefined): boolean {
    if (order && order.status && order.status.acked) {
      return false;
    }
    return true;
  }

  public markOrderAsCompleted(order: proto.waiternow.common.IOrderProto | undefined, actionObserver: ActionObserver): void {
    if (!order) {
      actionObserver.onError(ErrorResult.empty())
      return;
    }
    this.backendService.markOrderAsCompleted(
      Util.safeString(order.id),
      /* onSuccess= */ () => {
        this.ordersService.orderCompleted(order);
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
      }
    );
  }

  public getLocationName() {
    const location = this.sessionService.getLocation();
    if (location) {
      return location.redundantData?.businessName + ' ' + location.name;
    }
    return '';
  }

  public onRowClick(order: proto.waiternow.common.IOrderProto) {
    this.navigationService.goToOrderPageFromHomePage(Util.safeString(order?.id));
  }

  public getOrderCreationTime(order: proto.waiternow.common.IOrderProto | null | undefined): string {
    if (order) {
      return Formatter.formatTimestampProtoWithLocale(order.creationTime);
    }
    return '';
  }

  public getOrderNumber(order: proto.waiternow.common.IOrderProto | null | undefined): string {
    if (order && order.orderNumber) {
      return order.orderNumber.toString();
    }
    return '';
  }

  public getCustomerName(order: proto.waiternow.common.IOrderProto | null | undefined): string {
    if (order && order.redundantData) {
      return Util.safeString(order.redundantData.consumerName);
    }
    return '';
  }

  public getPointOfServiceFriendlyName(order: proto.waiternow.common.IOrderProto | null | undefined) {
    if (order && order.redundantData && order.redundantData.pointOfServiceFriendlyName) {
        return order.redundantData.pointOfServiceFriendlyName;
    }
    return '';
  }

  // NOTE: this method is duplicated in OrderComponent
  public getOrderType(order: proto.waiternow.common.IOrderProto | null | undefined): string {
    if (order && order.redundantData) {
      const redundantData = order.redundantData;
      if (redundantData.isDelivery) {
        return  this.localizationService.isSpanish() ? 'Entrega a Domicilio' : 'Delivery';
      }
      else if (redundantData.isOnlinePickup) {
        return  this.localizationService.isSpanish() ? 'Para Recoger' : 'Pickup';
      }
      else if (redundantData.isOnsitePickup) {
        return  this.localizationService.isSpanish() ? 'Para Recoger' : 'Pickup';
      }
      else if (redundantData.pointOfServiceFriendlyName) {
        return redundantData.pointOfServiceFriendlyName;
      }
    }
    return '';
  }

  public getOrderSummary(order: proto.waiternow.common.IOrderProto | null | undefined): string {
    if (order) {
      if (order.waiterRequest) {
        const waiterRequest = order.waiterRequest;
        if (waiterRequest.predefinedAction == proto.waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction.CALL_WAITER) {
          return this.localizationService.isSpanish() ? 'Solicita mesero' : 'Calling for waiter';
        } else  if(waiterRequest.customMessage) {
          return waiterRequest.customMessage;
        }
      } else if (order.structuredMenuOrder) {
        return this.localizationService.isSpanish() ? 'Orden del menu' : 'Menu order';
      }
    }
    return '';
  }
}
