import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';
import { StorageService } from './storage.service';
import { SessionService } from './session.service';
import { Util } from 'src/app/general/util/util';
import { Runnable } from 'src/app/general/interfaces/functions';
import { LoggingService } from 'src/app/general/services/logging.service';
import * as proto from 'src/proto/compiled-protos';
import { VersionService } from './version.service';

@Injectable({
  providedIn: 'root'
})
export class DeviceSessionService {

  private deviceId: string | null | undefined;
  private isSessionOn: boolean;
  private version: string;

  constructor(
      private backendService: BackendService,
      private storageService: StorageService,
      private sessionService: SessionService,
      private versionService: VersionService,
      private loggingService: LoggingService) {
    this.deviceId = this.storageService.getDeviceIdStore().get();
    this.isSessionOn = false;
    this.version = '';
    this.versionService.getVersion(
      /* onSuccess= */ versionResponse => this.version = versionResponse.currentVersion,
      /* onError */ error => {}
    );
  }

  public startSession(): void {
    if (!this.sessionService.getLocation()) {
      return;
    }
    const interests = new proto.waiternow.common.DeviceProto.InterestsProto();
    interests.locationId = Util.safeString(this.sessionService.getLocation()?.id);
    if (this.deviceId) {
      if (this.isSessionOn) {
        this.backendService.endDeviceSession(
          Util.safeString(this.deviceId),
          /* onSuccess */ () => {
            this.loggingService.logInfo('Device session ended');
            this.isSessionOn = false;
            this.doStartDeviceSession(this.sessionService.getFirebaseMessagingToken(), interests);
          },
          /* onError= */ error => {
            this.loggingService.logErrorWithCause(error, 'Error ending device session');
            this.doStartDeviceSession(this.sessionService.getFirebaseMessagingToken(), interests);
          }
        );
      } else {
        this.doStartDeviceSession(this.sessionService.getFirebaseMessagingToken(), interests);
      }
    } else {
      this.registerDevice(
        this.version,
        /* onSuccess */ () => {
          this.doStartDeviceSession(this.sessionService.getFirebaseMessagingToken(), interests);
        }
      );
    }
  }

  private doStartDeviceSession(
      firebaseMessagingToken: string | null | undefined,
      interests: proto.waiternow.common.DeviceProto.IInterestsProto): void {
    this.loggingService.logInfo('Strarting device session for device id ' + this.deviceId + ' and firebase token ' + this.sessionService.getFirebaseMessagingToken());
    this.backendService.startDeviceSession(
      Util.safeString(this.deviceId),
      Util.safeString(firebaseMessagingToken),
      interests,
      this.version,
      /* onSuccess */ deviceSessionInfo => {
        this.isSessionOn = true;
        // TODO: use deviceSessionInfo.expiration
        this.loggingService.logInfo('Device session started', deviceSessionInfo);
      },
      /* onError= */ error => {
        // TODO: Devices are deleted after one year of not usage. If the device id
        // from the local store is too old, we need to register the device again.
        this.loggingService.logErrorWithCause(error, 'Error starting device session');
      }
    )
  }

  private registerDevice(version: string, onSuccess: Runnable): void {
    this.loggingService.logInfo('Registering device');
    this.backendService.registerDevice(
      proto.waiternow.common.DeviceProto.ClientType.BUSINESS,
      proto.waiternow.common.DeviceProto.DeviceType.WEB,
      version,
      /* onSuccess */ device => {
        if (device) {
          this.deviceId = Util.safeString(device.id);
          this.storageService.getDeviceIdStore().set(Util.safeString(this.deviceId));
          onSuccess();
        } else {
          this.loggingService.logError('Error registering device session. Device came empty.');
        }
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error registering device session');
      }
    )
  }

  // This method may not be needed for the business web. If the business web ends session, and a consumer was already
  // paying, the business web owner would receive a text message when the order is placed.
  public starGracePeriodToEndSession(): void {
    this.backendService.startGracePeriodToEndDeviceSession(
      Util.safeString(this.deviceId),
      /* onSuccess */ () => {
        this.loggingService.logInfo('Grace period to end session started');
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error starting grace period to end device session');
        console.log(error);
      }
    );
  }

  public endSession(): void {
    if (!this.isSessionOn) {
      return;
    }
    this.backendService.endDeviceSession(
      Util.safeString(this.deviceId),
      /* onSuccess */ () => {
        this.isSessionOn = false;
        this.loggingService.logInfo('Device session ended');
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error ending device session');
      }
    );
  }

  public endSessionAndLogout(): void {
    this.backendService.endDeviceSession(
      Util.safeString(this.deviceId),
      /* onSuccess */ () => {
        this.isSessionOn = false;
        this.loggingService.logInfo('Device session ended and logged out');
        this.sessionService.logout()
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error ending device session');
      }
    );
  }

  public sendHeartbeat(): void {
    if (!this.isSessionOn) {
      return;
    }
    this.backendService.sendDeviceHeartbeat(
      Util.safeString(this.deviceId),
      /* onSuccess */ () => {
        this.loggingService.logInfo('Device heartbeat sent');
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error ending device heartbeat');
      }
    );
  }
}
