import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { SidebarService } from '../../services/sidebar.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NavigationService } from '../../services/navigation.service';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';
import { DeviceSessionService } from '../../services/device-session.service';
import { SessionService } from '../../services/session.service';

// NOTE! This component cannot use ActionComponent. If <app-action> tags are used it breaks all buttons.
// The .button style does not work, however .buton:hover does work.

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-sidebar-content></app-sidebar-content>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-sidebar-content',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    TranslateModule
  ],
  templateUrl: './sidebar-content.component.html',
  styleUrls: ['./sidebar-content.component.css']
})
export class SidebarContentComponent {

  languageToSwitchTo: string;

  constructor(
      public deviceService: DeviceService,
      private sidebarService: SidebarService,
      private localizationService: LocalizationService,
      private navigationService: NavigationService,
      public sessionService: SessionService,
      public deviceSessionService: DeviceSessionService) {
    if (this.localizationService.isSpanish()) {
      this.languageToSwitchTo = 'English';
    } else {
      this.languageToSwitchTo = 'Español';
    }
  }

  public toggleLanguage(): void {
    if (this.localizationService.isSpanish()) {
      this.localizationService.setEnglish();
      this.languageToSwitchTo = 'Español';
    } else {
      this.localizationService.setSpanish();
      this.languageToSwitchTo = 'English';
    }
    this.toggleSidebar();
  }

  public toggleSidebar(): void {
    this.sidebarService.toggleSidebar();
  }

  goToHomePage() {
    this.toggleSidebar();
    this.navigationService.goToHomePage();
  }

  goToSelectLocationPage() {
    this.toggleSidebar();
    this.navigationService.goToSelectLocationPage();
  }

  goToOrderHistoryPage() {
    this.toggleSidebar();
    this.navigationService.goToOrderHistoryPage();
  }

  logout() {
    this.deviceSessionService.endSessionAndLogout();
    this.toggleSidebar();
    this.navigationService.goToSignInPage()
  }
}
