import { Runnable } from "../interfaces/functions";

/*
 * Usage example:
 * private timer: Timer;
 * // In constructor:
 * this.timer = new Timer(() => console.log("On tick"), 1000);
 *
 * ngOnInit(): void {
 *   this.timer.start();
 * }
 *
 * ngOnDestroy(): void {
 *   this.timer.stop();
 * }
 */
export class Timer {
  private onTick: Runnable;
  private rateMillis: number;
  private ticker?: any;

  constructor(onTick: Runnable, rateMillis: number) {
    this.onTick = onTick;
    this.rateMillis = rateMillis;
  }

  public start(): void {
    if (!this.ticker) {
      this.tick();
    }
  }

  public stop(): void {
    if (this.ticker) {
      const oldTicker = this.ticker;
      this.ticker = undefined;
      clearTimeout(oldTicker);
    }
  }

  private tick() {
    this.ticker = setTimeout(() => {
      if (this.ticker) {
        this.onTick();
        this.tick();
      }
    },
    this.rateMillis);
  }
}
