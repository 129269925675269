import { CURRENCIES } from '../util/constants';
import { Money } from 'src/app/general/components/money-picker/money-picker.component';
import { Util } from 'src/app/general/util/util';
import * as proto from 'src/proto/compiled-protos';

interface SecondsAndNanos {
  seconds: number | null | undefined;
  nanos: number | null | undefined;
}

export class ProtoUtil {

  static protoToString(protoMessage: object | null | undefined) {
    if (!protoMessage) {
      return '';
    }
    return JSON.stringify(protoMessage);
  }

  static secondsAndNanosToMillis(secondsAndNanos: SecondsAndNanos) {
    let milliseconds = Util.safeNumber(secondsAndNanos.seconds) * 1000;
    milliseconds += Math.floor(Util.safeNumber(secondsAndNanos.nanos) / 1000_000);
    return milliseconds;
  }

  static secondsAndNanosFromMillis(millis: number): SecondsAndNanos {
    const seconds = Math.floor(millis / 1000);
    const remaining = millis % 1000;
    const nanos = remaining *1000_000;
    return { seconds: seconds, nanos: nanos };
  }

  static timestampProtoToMillis(timestamp: proto.google.protobuf.ITimestamp | null | undefined): number {
    if (!timestamp) {
      return 0;
    }
    return ProtoUtil.secondsAndNanosToMillis({ seconds: timestamp.seconds, nanos: timestamp.nanos});
  }

  static durationProtoToMillis(duration: proto.google.protobuf.IDuration | null | undefined): number {
    if (!duration) {
      return 0;
    }
    return ProtoUtil.secondsAndNanosToMillis({ seconds: duration.seconds, nanos: duration.nanos});
  }

  static createTimestampProtoFromMillis(millis: number): proto.google.protobuf.Duration {
    const secondsAndNanos = ProtoUtil.secondsAndNanosFromMillis(millis);
    const timestamp = new proto.google.protobuf.Timestamp();
    timestamp.seconds = Util.safeNumber(secondsAndNanos.seconds);
    timestamp.nanos = Util.safeNumber(secondsAndNanos.nanos);
    return timestamp;
  }

  static createDurationProtoFromMillis(millis: number): proto.google.protobuf.Duration {
    const secondsAndNanos = ProtoUtil.secondsAndNanosFromMillis(millis);
    const duration = new proto.google.protobuf.Duration();
    duration.seconds = Util.safeNumber(secondsAndNanos.seconds);
    duration.nanos = Util.safeNumber(secondsAndNanos.nanos);
    return duration;
  }

  static moneyToNumber(money: proto.waiternow.common.IMoneyProto | null | undefined): number {
    if (!money) {
      return 0;
    }
    return Util.safeNumber(money.units) + (Util.safeNumber(money.cents) / 100);
  }

  static createTextProto(textEn?: string, textEs?: string): proto.waiternow.common.TextProto {
    const textProto = new proto.waiternow.common.TextProto();
    if (textEn) {
      textProto.text = textEn;
      textProto.language = proto.waiternow.common.Language.ENGLISH;
      if (textEs) {
        const localizedTextProto = new proto.waiternow.common.TextProto.LocalizedTextProto();
        localizedTextProto.text = textEs;
        localizedTextProto.language = proto.waiternow.common.Language.SPANISH;
        textProto.localizedTexts = new Array<proto.waiternow.common.TextProto.LocalizedTextProto>();
        textProto.localizedTexts.push(localizedTextProto);
      }
    } else if (textEs) {
      textProto.text = textEs;
      textProto.language = proto.waiternow.common.Language.SPANISH;
    }
    return textProto;
  }

  static createMoneyProto(units: number, cents: number, currencyCode: string): proto.waiternow.common.MoneyProto {
    const moneyProto = new proto.waiternow.common.MoneyProto();
    moneyProto.currencyCode = currencyCode;
    moneyProto.units = units;
    moneyProto.cents = cents;
    return moneyProto;
  }

  static moneyProtoToPickerMoney(moneyProto: proto.waiternow.common.IMoneyProto): Money {
    const money: Money = {units: 0, cents: 0, currencyCode: CURRENCIES[0]};
    if (moneyProto) {
      money.units = Util.safeNumber(moneyProto.units);
      money.cents = Util.safeNumber(moneyProto.cents);
      money.currencyCode = Util.safeString(moneyProto.currencyCode);
    }
    return money;
  }

  static pickerMoneyToMoneyProto(money: Money): proto.waiternow.common.MoneyProto {
    return ProtoUtil.createMoneyProto(money.units, money.cents, money.currencyCode);
  }

  static createSimplePhoneNumberProto(simplePhoneNumber: string): proto.waiternow.common.PhoneNumberProto {
    const phoneNumberProto = new proto.waiternow.common.PhoneNumberProto();
    phoneNumberProto.simplePhoneNumber = new proto.waiternow.common.SimplePhoneNumberProto();
    phoneNumberProto.simplePhoneNumber.phoneNumber = simplePhoneNumber;
    return phoneNumberProto;
  }

  static createSimpleAddressProto(simpleAddress: string): proto.waiternow.common.AddressProto {
    const addressProto = new proto.waiternow.common.AddressProto();
    addressProto.simpleAddress = new proto.waiternow.common.SimpleAddressProto();
    addressProto.simpleAddress.address = simpleAddress;
    return addressProto;
  }

  static createStructuredAddressProto(
      street: string,
      city: string,
      state: string,
      postalCode: string,
      country: proto.waiternow.common.Country): proto.waiternow.common.AddressProto {
    const addressProto = new proto.waiternow.common.AddressProto();
    addressProto.structuredAddress = new proto.waiternow.common.StructuredAddressProto();
    addressProto.structuredAddress.street = street;
    addressProto.structuredAddress.city = city;
    addressProto.structuredAddress.state = state;
    addressProto.structuredAddress.postalCode = postalCode;
    addressProto.structuredAddress.country = country;
    return addressProto;
  }

  static timeTextToTimeProto(timeAsText: string): proto.waiternow.common.TimeProto {
    const timeProto = new proto.waiternow.common.TimeProto();
    const timeComponents = timeAsText.split(':');
    timeProto.hour = parseInt(timeComponents[0]);
    timeProto.minute = parseInt(timeComponents[1]);
    return timeProto;
  }

  /**
   * Converts a data in the fotrmat MM/dd/YYY to a proto. If the date is invalid it returns undefined.
   */
  static dateToDateProto(date: Date): proto.waiternow.common.DateProto | undefined {
    if (!date) {
      return undefined;
    }
    /*
    if (isNaN(new Date(strDate).getDate())) {
      return undefined;
    }

    const dateComponents = strDate.split('/');
    if (dateComponents.length != 3) {
      return undefined;
    }

    const month = Number(dateComponents[0]);
    const day = Number(dateComponents[1]);
    const year = Number(dateComponents[2]);
    */

    const dateProto = new proto.waiternow.common.DateProto();
    dateProto.day = date.getDate();
    dateProto.month = date.getMonth() + 1;
    dateProto.year = date.getFullYear();
    return dateProto;
  }
}
