import { Routes } from '@angular/router';
import { HomePageComponent } from './specific/pages/home-page/home-page.component';
import { PageNotFoundComponent } from './specific/pages/page-not-found/page-not-found.component';
import { SignInPageComponent } from './specific/pages/sign-in-page/sign-in-page.component';
import { SelectLocationPageComponent } from './specific/pages/select-location-page/select-location-page.component';
import { OrderPageComponent } from './specific/pages/order-page/order-page.component';

// https://angular.io/guide/router
// https://angular.io/start/start-routing

const routeConfig: Routes = [
  {
    path: '',
    component: HomePageComponent,
    title: 'Home page'
  },
  {
    path: 'signin',
    component: SignInPageComponent,
    title: 'Sign-in'
  },
  {
    path: 'select_location',
    component: SelectLocationPageComponent,
    title: 'Select Location'
  },
  {
    path: 'order/:orderId',
    component: OrderPageComponent,
    title: 'Order'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'Page not found'
  },
];

export default routeConfig;
