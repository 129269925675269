<app-page>
    <div *ngIf="isWaiterRequest()">
        Witer request
    </div>
    <div *ngIf="isStructuredMenuOrder()">
        Structured menu order
    </div>
    <table align="center">
        <tr>
            <td>
                <app-action
                    text="{{ 'back' | translate }}"
                    (onExecute)="navigationService.goToHomePage()">
                </app-action>
            </td>
            <td *ngIf="isCompletedStatusExpected()">
                <app-action
                    text="{{ 'mark_as_completed' | translate }}"
                    [disabled]="isOrderMarkedAsCompleted"
                    (onAsyncExecute)="markOrderAsCompleted($event)">
                </app-action>
            </td>
        </tr>
    </table>
</app-page>
