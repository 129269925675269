<app-page>
    <app-title>{{ getLocationName() }}</app-title>
    <div align="center"><app-subtitle>{{ 'pending_orders' | translate }}</app-subtitle></div>
    <app-data-table
        [data]="pendingOrders"
        [columns]="ordersColumns"
        [toolbarActions]="toolbarActions"
        (onRowClick)="onRowClick($event)">
    </app-data-table>
</app-page>
