<app-page>
    <app-loadable-content [loadingController]="ordersLoadingController" (onRetry)="loadInitialData()">
        <app-title>{{ getLocationName() }}</app-title>
        <div align="center"><app-subtitle>{{ 'order_history' | translate }}</app-subtitle></div>
        <table width="100%">
            <tr>
                <td>
                    <app-data-table
                        [data]="orders"
                        [columns]="columns"
                        (onRowClick)="onRowClick($event)">
                    </app-data-table>
                </td>
            </tr>
            <tr>
                <td align="right">
                    <app-indeterminate-paginator [model]="indeterminatePaginatorModel" [pageSize]="10" [initialData]="initialPaginatorData"></app-indeterminate-paginator>
                </td>
            </tr>
        </table>
    </app-loadable-content>
</app-page>