import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { ActivatedRoute } from '@angular/router';
import { Runnable } from 'src/app/general/interfaces/functions';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(
      private router: Router){
  }

  // See com.waiternow.app.web.waiternow.main.frontend.support.ServerMain (RedirectController).
  // RedirectController redirects all paths to the root and includes the original path as query
  // parameter "original_path".
  // When you try to access "/foo/bar" directly, the browser sends HTTP GET request to such
  // URL, causing a page not found error. When you try to access this URL via Angular link
  // clicking, Angular is intercepting this action and does the redirect. Thus, every request
  // has to be redirected to the index file that bootstraps AngularJS application.
  public handleRedirect(activatedRoute: ActivatedRoute, onNotRedirected: Runnable = () => {}): void {
    RouteUtil.getQueryParams(activatedRoute, params => {
      if (params['original_path']) {
        this.router.navigate([params['original_path']], {})
      } else {
        onNotRedirected();
      }
    });
  }

  public openInNewTab(url: string): void {
    const windowProxy = window.open(url, '_blank');
    if (windowProxy) {
      windowProxy.focus();
    }
  }

  public goToHomePage(): void {
    this.router.navigate([''], {});
  }

  public goToSignInPage(): void {
    this.router.navigate(['signin'], {});
  }

  public goToSelectLocationPage(): void {
    this.router.navigate(['select_location'], {});
  }

  public goToOrderPageFromHomePage(orderId: string): void {
    this.router.navigate(['order', orderId, 'from_page', 'home']);
  }

  public goToOrderPageFromOrderHistoryPage(orderId: string): void {
    this.router.navigate(['order', orderId, 'from_page', 'order_history']);
  }

  public goToOrderHistoryPage(): void {
    this.router.navigate(['order_history'], {});
  }
}
