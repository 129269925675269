<table>
    <tr>
        <td>
            <table width="100%">
                <tr>
                    <td class="vertical-align-middle">
                        <img class="logo" src="../../assets/img/logo.png"/>
                    </td>
                    <td align="right" class="vertical-align-middle">
                        <button mat-icon-button (click)="toggleSidebar()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </td>
                </tr>
            </table>
        </td>
    </tr>
    <tr>
        <table>
            <tr>
                <td>
                    <button mat-flat-button (click)="goToHomePage()">
                        <mat-icon>home</mat-icon>
                        {{ 'pending_orders' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getLocation()">
                <td>
                    <button mat-flat-button (click)="goToOrderHistoryPage()">
                        <mat-icon>history</mat-icon>
                        {{ 'order_history' | translate }}
                    </button>
                </td>
            </tr>
            <tr *ngIf="sessionService.getLocations().length > 1">
                <td>
                    <button mat-flat-button (click)="goToSelectLocationPage()">
                        <mat-icon>house</mat-icon>
                        {{ 'locations' | translate }}
                    </button>
                </td>
            </tr>
            <tr>
                <td>
                    <button mat-flat-button (click)="logout()">
                        <mat-icon>logout</mat-icon>
                        {{ 'sign_out' | translate }}
                    </button>
                </td>
            </tr>
        </table>
    </tr>
</table>
