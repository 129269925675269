<app-page>
    <app-loadable-content [loadingController]="userLocationsLoadingController" (onRetry)="loadLocations()">
        <mat-card>
            <mat-card-header>
                <mat-card-title>{{ 'select_location' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <app-data-table
                    [data]="locations"
                    [columns]="columns"
                    (onRowClick)="onRowClick($event)">
                </app-data-table>
            </mat-card-content>
        </mat-card>
    </app-loadable-content>
</app-page>