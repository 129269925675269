import { Injectable } from '@angular/core';
import { OrdersService } from './orders.service';
import { NavigationService } from './navigation.service';
import { DeviceSessionService } from './device-session.service';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class FirebaseMessageHandlerService {

  constructor(
      private navigationService: NavigationService,
      private deviceSessionService: DeviceSessionService,
      private ordersService: OrdersService){
  }

  public handleMessage(message: proto.waiternow.common.ClientNotificationProto) {
    if (message.newOrder) {
      this.ordersService.fetchOrders();
    } else if (message.orderStatusUpdated) {
      const order = message.orderStatusUpdated.order;
      if (order?.status && !order.status.acked) {
        this.ordersService.fetchOrders();
      }
    }
    // TODO: if Firebase notifications are not supported or allowed, we need a way
    // to pull whether we need to terminate the session of the business web.
    else if (message.terminateDeviceSessionRequest) {
    this.deviceSessionService.endSessionAndLogout();
      this.navigationService.goToSignInPage();
    }
  }
}
