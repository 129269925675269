import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { PageComponent } from '../../components/page/page.component';
import { DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { LoadingController } from 'src/app/general/components/loadable-content/loading-controler';
import { LoadingMessage, ErrorResult } from 'src/app/general/util/result';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { LoadableContentComponent } from 'src/app/general/components/loadable-content/loadable-content.component';
import { MatCardModule } from '@angular/material/card';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { NavigationService } from '../../services/navigation.service';
import { StorageService } from '../../services/storage.service';
import { Util } from 'src/app/general/util/util';
import { DialogService } from 'src/app/general/services/dialog.service';
import * as proto from 'src/proto/compiled-protos';

@Component({
  selector: 'app-select-location-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    LoadableContentComponent,
    MatCardModule,
    DataTableComponent,
  ],
  templateUrl: './select-location-page.component.html',
  styleUrls: ['./select-location-page.component.css']
})
export class SelectLocationPageComponent {
  userLocationsLoadingController: LoadingController;

  locations: Array<proto.waiternow.common.UserLocationsProto.IManagedLocationProto>;
  columns: Array<DataTableColumn<proto.waiternow.common.UserLocationsProto.IManagedLocationProto>>;

  constructor(
      private backendService: BackendService,
      private sessionService: SessionService,
      private navigationService: NavigationService,
      private storageService: StorageService,
      private dialogService: DialogService,
      private translateService: TranslateService) {
    this.userLocationsLoadingController = new LoadingController();

    this.locations = new Array();
    this.columns = [
      new StringColumn(
        /* name= */ 'businessName',
        /* translateId= */ 'business_name',
        /* valueExtractor= */  managedLocation => managedLocation.location?.redundantData?.businessName),
      new StringColumn(
        /* name= */ 'locationLame',
        /* translateId= */ 'location_name',
        /* valueExtractor= */  managedLocation => managedLocation.location?.name,
        /* hideInCompactMode= */ false)
    ];
  }

  ngOnInit(): void {
    this.loadLocations();
  }

  public loadLocations(): void {
    this.userLocationsLoadingController.onLoadingStarted(LoadingMessage.withMessageTranslateId('loading_locations'));
    this.backendService.findLocationsByUser(
      /* userId= */ '',
      /* onSuccess= */ userLocations => {
        this.userLocationsLoadingController.onSuccess();
        if (userLocations && userLocations?.locations) {
          this.locations = userLocations?.locations;
          if (this.locations.length == 1) {
            this.onLocationSelected(this.locations[0].location);
          }
        } else {
          this.locations = [];
        }
      },
      /* onError= */ error => {
        this.userLocationsLoadingController.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_loading_location_devices'));
      }
    );
  }

  private onLocationSelected(location: proto.waiternow.common.ILocationProto | null | undefined) {
    if (location) {
      this.sessionService.setLocation(location);
      this.storageService.getLocationIdStore().set(Util.safeString(location.id));
      this.navigationService.goToHomePage();
    }
  }

  public onRowClick(managedLocation: proto.waiternow.common.UserLocationsProto.IManagedLocationProto) {
    if (managedLocation?.location) {
      const location = managedLocation?.location;
      if(this.storageService.getLocationIdStore().get()
           && this.storageService.getLocationIdStore().get() != location.id) {
        this.translateService.get('confirmation_select_different_location', { locationName: location.redundantData?.businessName + ' ' + location.name }).subscribe(text => {
          this.dialogService.openConfirmationDialog(
            text,
            /* onYes= */ () => {
              this.onLocationSelected(location);
            },
            /* onNo= */ () => {

            }
          );
        });
      } else {
        this.onLocationSelected(location);
      }
    }
  }
}
