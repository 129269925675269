import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarService } from '../../services/sidebar.service';
import { NavigationService } from '../../services/navigation.service';
import { TranslateModule } from '@ngx-translate/core';
import { DeviceService } from 'src/app/general/services/device.service';
import { LocalizationService } from 'src/app/general/services/localization.service';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { DeviceSessionService } from '../../services/device-session.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-header></app-header>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ActionComponent
  ],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css', '../../../../styles.css']
})
export class HeaderComponent {

  constructor(
      public deviceService: DeviceService,
      public navigationService: NavigationService,
      public localizationService: LocalizationService,
      public deviceSessionService: DeviceSessionService,
      private sidebarService: SidebarService) {
  }

  public toggleSidebar() {
    this.sidebarService.toggleSidebar();
  }

  logout() {
    this.deviceSessionService.endSessionAndLogout()
    this.navigationService.goToSignInPage()
  }
}
