import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { LocalizationService } from '../../../general/services/localization.service';
import { SessionService } from '../../services/session.service';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationStatus } from '../../util/util';
import { TranslateModule } from '@ngx-translate/core';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { DeviceSessionService } from '../../services/device-session.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { DataTableColumn, StringColumn } from 'src/app/general/components/data-table/data-table-column';
import { ProtoUtil } from '../../util/proto-util';
import { TimeUtil } from 'src/app/general/util/time-util';
import { OrdersService } from '../../services/orders.service';
import { DataTableComponent } from 'src/app/general/components/data-table/data-table.component';
import { Util } from 'src/app/general/util/util';
import * as proto from 'src/proto/compiled-protos';
import { LoggingService } from 'src/app/general/services/logging.service';

@Component({
  selector: 'app-home-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    TranslateModule,
    ActionComponent,
    TitleComponent,
    DataTableComponent
  ],
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit, OnDestroy {

  public orders: Array<proto.waiternow.common.IOrderProto>;
  public ordersColumns: Array<DataTableColumn<proto.waiternow.common.IOrderProto>>;

  constructor(
      public localizationService: LocalizationService,
      private sessionService: SessionService,
      private navigationService: NavigationService,
      private activatedRoute: ActivatedRoute,
      private deviceSession: DeviceSessionService,
      private loggingService: LoggingService,
      private changeDetectorRef: ChangeDetectorRef,
      public ordersService: OrdersService) {

    this.ordersColumns = [
      new StringColumn(
        /* name= */ 'orderNumber',
        /* translateId= */ 'order_number',
        /* valueExtractor= */  order => this.getOrderNumber(order)),
      new StringColumn(
        /* name= */ 'customerName',
        /* translateId= */ 'customer_name',
        /* valueExtractor= */  order => this.getCustomerName(order)),
      new StringColumn(
        /* name= */ 'orderType',
        /* translateId= */ 'order_type',
        /* valueExtractor= */  order => this.getOrderType(order)),
      new StringColumn(
        /* name= */ 'summary',
        /* translateId= */ 'summary',
        /* valueExtractor= */  order => this.getOrderSummary(order),
        /* hideInCompactMode= */ true),
      new StringColumn(
        /* name= */ 'creationTime',
        /* translateId= */ 'creation_time',
        /* valueExtractor= */  order => this.getOrderCreationTime(order))
    ];
    this.orders = [];
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }
    this.navigationService.handleRedirect(this.activatedRoute, /* onNotRedirected= */ () => {
      // Put any remaining code on ngOnInit() inside this scope. Nothing should be after
      // this.navigationService.handleRedirect() otherwise it will execute regardless
      // the page is redirected.
      // navigationService.handleRedirect() uses async code to get query parameters and
      // thus it cannot return whether the page was redirected as
      // sessionService.enforceAuthentication() does.
      if (!this.sessionService.getLocation()) {
        this.navigationService.goToSelectLocationPage();
        return;
      }
      this.deviceSession.startSession();
      this.ordersService.setListener(
        /* onOrderAdded= */ order => {
          this.loggingService.logDebug('New order: ' + order.id);
          // Angular does not detect changes with array.push(). It is better to replace the array instance.
          this.orders = [...this.orders, order];
          // The new order will come from a call to the backend, and thus Angular may not detect the changes.
          this.changeDetectorRef.detectChanges();
        },
        /* onOrderRemoved= */ order => {
          this.loggingService.logDebug('Order removed: ' + order.id);
          this.orders = this.orders.filter(arrayItem => arrayItem.id != order.id);
        }
      );
    });
  }

  ngOnDestroy(): void {
    this.ordersService.clearListener();
  }

  public getLocationName() {
    const location = this.sessionService.getLocation();
    if (location) {
      return location.redundantData?.businessName + ' ' + location.name;
    }
    return '';
  }

  private getOrderCreationTime(order: proto.waiternow.common.IOrderProto): string {
    if (order.creationTime) {
      const creationDate = TimeUtil.createDateFromMillis(ProtoUtil.timestampProtoToMillis(order.creationTime));
      const now = TimeUtil.now();
      if (creationDate.getDate() != now.getDate() || creationDate.getMonth() != now.getMonth() || creationDate.getFullYear() != now.getFullYear()) {
        return creationDate.toLocaleString();
      }
      return creationDate.toLocaleTimeString();
    }
    return '';
  }

  private getOrderNumber(order: proto.waiternow.common.IOrderProto): string {
    if (order.orderNumber) {
      return order.orderNumber.toString();
    }
    return '';
  }

  private getCustomerName(order: proto.waiternow.common.IOrderProto): string {
    if (order.redundantData) {
      return Util.safeString(order.redundantData.consumerName);
    }
    return '';
  }

  private getOrderType(order: proto.waiternow.common.IOrderProto): string {
    if (order.redundantData) {
      const redundantData = order.redundantData;
      if (redundantData.isDelivery) {
        return  this.localizationService.isSpanish() ? 'Entrega a Domicilio' : 'Delivery';
      }
      else if (redundantData.isOnlinePickup) {
        return  this.localizationService.isSpanish() ? 'Para Recoger' : 'Pickup';
      }
      else if (redundantData.isOnsitePickup) {
        return  this.localizationService.isSpanish() ? 'Para Recoger' : 'Pickup';
      }
      else if (redundantData.pointOfServiceFriendlyName) {
        return redundantData.pointOfServiceFriendlyName;
      }
    }
    return '';
  }

  private getOrderSummary(order: proto.waiternow.common.IOrderProto): string {
    if (order.waiterRequest) {
      const waiterRequest = order.waiterRequest;
      if (waiterRequest.predefinedAction == proto.waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction.CALL_WAITER) {
        return this.localizationService.isSpanish() ? 'Solicita mesero' : 'Calling for waiter';
      } else  if(waiterRequest.customMessage) {
        return waiterRequest.customMessage;
      }
    } else if (order.structuredMenuOrder) {
      return this.localizationService.isSpanish() ? 'Orden del menu' : 'Menu order';
    }
    return '';
  }

  public onRowClick(order: proto.waiternow.common.IOrderProto) {
    this.navigationService.goToOrderPage(Util.safeString(order?.id));
  }
}
