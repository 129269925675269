import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormService } from 'src/app/general/services/form.service';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DialogService } from 'src/app/general/services/dialog.service';
import { BackendService } from '../../services/backend.service';
import { SessionService } from '../../services/session.service';
import { StorageService } from '../../services/storage.service';
import { TitleComponent } from 'src/app/general/components/title/title.component';
import { FieldContainerDirective } from 'src/app/general/directives/field/field-container.directive';
import { FieldDirective } from 'src/app/general/directives/field/field.directive';
import { Router } from '@angular/router';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { OrdersService } from '../../services/orders.service';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// <app-sign-in></app-sign-in>
// ----------------------------------------------------------------------------

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    TitleComponent,
    FieldContainerDirective,
    FieldDirective,
    ActionComponent
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css', '../../../../styles.css']
})
export class SignInComponent {

  hidePassword: boolean;
  errorMessage: string;

  emailFormControl: FormControl;
  passwordFormControl: FormControl;
  rememberMeFormControl: FormControl;

  constructor(
      public formService: FormService,
      private progressDialogService: DialogService,
      private backendService: BackendService,
      private translateService: TranslateService,
      private sessionService: SessionService,
      private storageService: StorageService,
      private ordersService: OrdersService,
      private router: Router) {
    this.hidePassword = true;
    this.errorMessage = '';

    const lastUserEmail = this.storageService.getLastUserEmailStore().get();
    let emailInitialValue = '';
    let rememberMeInitialValue = false;
    if (lastUserEmail) {
      emailInitialValue = lastUserEmail;
      rememberMeInitialValue = true;
    }

    this.emailFormControl = new FormControl(emailInitialValue, [Validators.required, Validators.email]);
    this.passwordFormControl = new FormControl('', [Validators.required]);
    this.rememberMeFormControl = new FormControl(rememberMeInitialValue);
  }

  public signIn(): void {
    this.errorMessage = '';
    const closeDialog = this.progressDialogService.openProgressDialog();
    this.backendService.signIn(
      this.emailFormControl.value,
      this.passwordFormControl.value,
      /* onSuccess= */ response => {
        closeDialog();
        this.sessionService.setAuthToken(response.authToken, response.since, response.authTokenExpiration);
        this.ordersService.setPollingRates(
            response.businessDeviceHeartbeatRate,
            response.businessCheckOrdersPollRate,
            response.businessSafetyCheckOrdersPollRate);
        if (this.rememberMeFormControl.value) {
          this.storageService.getLastUserEmailStore().set(this.emailFormControl.value);
        } else {
          this.storageService.getLastUserEmailStore().clear();
        }
        this.router.navigate([''], {});
      },
      /* onError= */ error => {
        closeDialog();
        if (error.isServerError()) {
          if (error.getServerErrorCode() === 'NOT_FOUND') {
            this.translateService.get('error_invalid_credentials').subscribe(text => this.errorMessage = text);
          } else if (error.getServerErrorCode() === 'INVALID_INPUT'){
            this.translateService.get('error_invalid_input').subscribe(text => this.errorMessage = text);
          } else {
            this.translateService.get('error_internal').subscribe(text => this.errorMessage = text);
          }
        } else {
          this.translateService.get('error_internal').subscribe(text => this.errorMessage = text);
        }
      });
  }
}
