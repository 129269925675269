import { Injectable } from '@angular/core';
import { DateFieldLocalStore, NumberFieldLocalStore, StringFieldLocalStore } from 'src/app/general/util/storage';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private authTokenStore: StringFieldLocalStore;
  private authTokenValidUntilStore: DateFieldLocalStore;
  private lastUserEmailStore: StringFieldLocalStore;
  private deviceIdStore: StringFieldLocalStore;
  private locationIdStore: StringFieldLocalStore;
  private heartbeatRateSecondsStore: NumberFieldLocalStore;
  private checkOrdersPollRateSecondsStore: NumberFieldLocalStore;
  private safetyCheckOrdersPollRateSecondsStore: NumberFieldLocalStore;

  constructor() {
      this.authTokenStore = new StringFieldLocalStore('auth-token');
      this.authTokenValidUntilStore = new DateFieldLocalStore('auth-token-valid-until');
      this.lastUserEmailStore = new StringFieldLocalStore('last-user-email');
      this.deviceIdStore = new StringFieldLocalStore('device-id');
      this.locationIdStore = new StringFieldLocalStore('location-id');
      this.heartbeatRateSecondsStore = new NumberFieldLocalStore('heartbeat-rate-seconds');
      this.checkOrdersPollRateSecondsStore = new NumberFieldLocalStore('check-orders-poll-rate-seconds');
      this.safetyCheckOrdersPollRateSecondsStore = new NumberFieldLocalStore('safety-check-orders-poll-rate-seconds');
  }

  public getAuthTokenStore(): StringFieldLocalStore {
    return this.authTokenStore;
  }

  public getAuthTokenValidUntilStore(): DateFieldLocalStore {
    return this.authTokenValidUntilStore;
  }

  public getLastUserEmailStore(): StringFieldLocalStore {
    return this.lastUserEmailStore;
  }

  public getDeviceIdStore(): StringFieldLocalStore {
    return this.deviceIdStore;
  }

  public getLocationIdStore(): StringFieldLocalStore {
    return this.locationIdStore;
  }

  public getHeartbeatRateSecondsStore(): NumberFieldLocalStore {
    return this.heartbeatRateSecondsStore;
  }

  public getCheckOrdersPollRateSecondsStore(): NumberFieldLocalStore {
    return this.checkOrdersPollRateSecondsStore;
  }

  public getSafetyCheckOrdersPollRateSecondsStore(): NumberFieldLocalStore {
    return this.safetyCheckOrdersPollRateSecondsStore;
  }
}
