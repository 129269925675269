import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { ActionComponent } from 'src/app/general/components/action/action.component';
import { NavigationService } from '../../services/navigation.service';
import { TranslateModule } from '@ngx-translate/core';
import { OrdersService } from '../../services/orders.service';
import { BackendService } from '../../services/backend.service';
import { LoggingService } from 'src/app/general/services/logging.service';
import * as proto from 'src/proto/compiled-protos';
import { ActionObserver } from 'src/app/general/components/action/action';
import { ErrorResult, SuccessResult } from 'src/app/general/util/result';

@Component({
  selector: 'app-order-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    ActionComponent,
    TranslateModule
  ],
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css']
})
export class OrderPageComponent {
  private orderId: string;
  private order?: proto.waiternow.common.IOrderProto;
  public isOrderMarkedAsCompleted: boolean;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute,
      private ordersService: OrdersService,
      private backendService: BackendService,
      private loggingService: LoggingService,
      public navigationService: NavigationService) {
    this.orderId = '';
    this.isOrderMarkedAsCompleted = false;
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    this.orderId = Util.safeString(pathParams.get('orderId'));

    this.order = this.ordersService.searchOrderById(this.orderId);
    if (this.order) {
      this.ackOrder();
    }
  }

  public isWaiterRequest(): boolean {
    if (this.order && this.order.waiterRequest) {
      return true;
    }
    return false;
  }

  public isStructuredMenuOrder(): boolean {
    if (this.order && this.order.structuredMenuOrder) {
      return true;
    }
    return false;
  }

  public isCompletedStatusExpected(): boolean {
    if (this.order && this.order.redundantData && this.order.redundantData.isCompletedStatusExpected) {
      return true;
    }
    return false;
  }

  private ackOrder() {
    if (!this.order) {
      return;
    }
    this.backendService.ackOrder(
      Util.safeString(this.order.id),
      /* onSuccess= */ () => {
        if (this.order) {
          this.ordersService.orderAcked(this.order);
        }
      },
      /* onError= */ error => {
        this.loggingService.logErrorWithCause(error, 'Error marking order as acked');
      }
    );
  }

  public markOrderAsCompleted(actionObserver: ActionObserver): void {
    if (!this.order) {
      actionObserver.onError(ErrorResult.empty())
      return;
    }
    this.backendService.markOrderAsCompleted(
      Util.safeString(this.order.id),
      /* onSuccess= */ () => {
        if (this.order) {
          this.ordersService.orderCompleted(this.order);
        }
        this.isOrderMarkedAsCompleted = true;
        actionObserver.onSuccess(SuccessResult.empty());
      },
      /* onError= */ error => {
        actionObserver.onError(ErrorResult.withErrorAndMessageTranslateId(error, 'error_internal'));
      }
    );
  }
}
