import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { getMessaging, getToken, onMessage, Messaging, MessagePayload } from 'firebase/messaging';
import { Consumer } from 'src/app/general/interfaces/functions';
import { LoggingService } from 'src/app/general/services/logging.service';
import { FirebaseMessageHandlerService } from './firebase-message-handler.service';
import * as protobuf from 'protobufjs';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  private messaging: Messaging;
  private broadcastChannel: BroadcastChannel;
  private firebaseMessagingToken: string;

  constructor(
      private loggingService: LoggingService,
      private firebaseMessageHandlerService: FirebaseMessageHandlerService) {
    this.messaging = getMessaging();
    this.broadcastChannel = new BroadcastChannel('fb_sw');
    this.firebaseMessagingToken = '';

    // This is required to be able to show notifications when we receive messages in
    // the backgroiund and we want something to popup to the user. This permission is
    // not rewquired to get messages in the background, it is just for the popup
    // notifications. These notifications are displayed in src/firebase-messaging-sw.js
    /*
    Notification.requestPermission().then((permission: NotificationPermission) => {
      if (permission === 'granted') {
        this.loggingService.logInfo('Notification permission granted.');
      }
    });
    */
    getToken(this.messaging, { vapidKey: environment.firebaseConfig.vapidKey}).then(
      currentToken => {
        if (currentToken) {
          this.firebaseMessagingToken = currentToken;
          this.loggingService.logInfo('Ready to get firebase messages. firebaseMessagingToken: ' + this.firebaseMessagingToken);
          onMessage(this.messaging, payload => {
            this.loggingService.logDebug('Message received', payload);
            this.handleMessage(payload);
          });
          this.broadcastChannel.onmessage = (e) => {
            this.loggingService.logDebug('Message received from broadcast channel. ', e.data);
            this.handleMessage(e.data);
          };
        } else {
          this.loggingService.logWarning('Unable to get permission to get Firebase messages. Configure a pulling mechanism.');
        }
      }).catch(error => {
        this.loggingService.logErrorWithCause(error, 'Unable to get permission to get Firebase messages. Configure a pulling mechanism.');
      });
  }

  public getFirebaseMessagingToken(consumer: Consumer<string | null | undefined>): void {
    if (this.firebaseMessagingToken) {
      consumer(this.firebaseMessagingToken);
      return;
    }
    // Note that getToken() is also executed in the constructor. The problem is that the
    // first time FirebaseService.getFirebaseMessagingToken() is used the constructor is
    // executed, but the otken is fetched async, thus it is possible that
    // this.firebaseMessagingToken is empty.
    getToken(this.messaging, { vapidKey: environment.firebaseConfig.vapidKey}).then(
      currentToken => {
        consumer(currentToken);
      }).catch(err => {
        consumer(undefined);
      }
    );
  }

  /*
  import deleteToken from firebase/messaging
  private deleteToken(): void {
    if (this.firebaseMessagingToken !== '') {
      deleteToken(this.messaging).then(response => {
        this.loggingService.logInfo('Token deleted: ', response);
        this.firebaseMessagingToken = '';
      }, error => {
        this.loggingService.logErrorWithCause(error, 'Error deleting token: ');
      });
    }
  }
  */

  private handleMessage(payload: MessagePayload): void {
    if (payload.data && payload.data['client-notification-proto']) {
      const base64EncodedMessage = payload.data['client-notification-proto'];
      const base64DecodedMessage = atob(base64EncodedMessage);
      const uint8ArrayMessage = this.stringToUint8Array(base64DecodedMessage);
      try {
        // TODO: propagate message to the real handler
        const message = proto.waiternow.common.ClientNotificationProto.decode(uint8ArrayMessage)
        this.loggingService.logDebug('Decoded client notification', message);
        this.firebaseMessageHandlerService.handleMessage(message);
      } catch (e) {
        if (e instanceof protobuf.util.ProtocolError) {
          // e.instance holds the so far decoded message with missing required fields
          this.loggingService.logError('Error decoding proto message. Decoded message with missing required fields');
        } else {
          // wire format is invalid
          this.loggingService.logError('Error decoding proto message. Wire format is invalid');
        }
      }
    } else {
      this.loggingService.logError('Message received without client-notification-proto payload');
    }
  }

  private stringToUint8Array(str: string): Uint8Array {
    const array = new Uint8Array(str.length);
    for (let i = 0; i < str.length; i++) {
      array[i] = str.charCodeAt(i);
    }
    return array;
  }
}
