import * as proto from 'src/proto/compiled-protos';
import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageComponent } from '../../components/page/page.component';
import { ActivatedRoute } from '@angular/router';
import { RouteUtil } from 'src/app/general/util/route-util';
import { Util } from 'src/app/general/util/util';
import { SessionService } from '../../services/session.service';
import { AuthenticationStatus } from '../../util/util';
import { OrdersService } from '../../services/orders.service';
import { OrderComponent } from '../../components/order/order.component';

@Component({
  selector: 'app-order-page',
  standalone: true,
  imports: [
    CommonModule,
    PageComponent,
    OrderComponent,
  ],
  templateUrl: './order-page.component.html',
  styleUrls: ['./order-page.component.css']
})
export class OrderPageComponent {
  public orderId: string;
  public order?: proto.waiternow.common.IOrderProto;
  public fromPage?: string;

  constructor(
      private sessionService: SessionService,
      private activatedRoute: ActivatedRoute,
      private ordersService: OrdersService) {
    this.orderId = '';
  }

  ngOnInit(): void {
    if (this.sessionService.enforceAuthentication()
        == AuthenticationStatus.USER_NOT_AUTHENTICATED_AND_REDIRECTED_TO_SIGNIN_PAGE) {
      return;
    }

    const pathParams = RouteUtil.getPathParams(this.activatedRoute);
    const orderIdParam = Util.safeString(pathParams.get('orderId'));
    this.fromPage = Util.safeString(pathParams.get('fromPage'));

    this.order = this.ordersService.searchOrderById(orderIdParam);
    // If the order is found it is rendered, otherwise we use the order id
    //  to initialize the order component so the order component loads the order.
    if (!this.order) {
      this.orderId = orderIdParam;
    }
  }
}
