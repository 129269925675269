<table class="palette-background-color-black" width="100%">
    <tr>
        <td *ngIf="deviceService.isMobile()">
            <app-action icon="menu" (click)="toggleSidebar()" class="palette-color-light-grey">
            </app-action>
        </td>
        <td>
            <div class="link-like"
                    (click)="navigationService.goToHomePage()"
                    [ngClass]="deviceService.ifMobile('logo-container-mobile').orElse('logo-container')">
                <img [ngClass]="deviceService.ifMobile('logo-image-mobile').orElse('logo-image')"
                        src="../../assets/img/logo-full.png"/>
            </div>
        </td>
        <td width="100%" style="vertical-align: bottom; padding-bottom: 10px;">
            <table *ngIf="!deviceService.isMobile()" align="center">
                <tr>
                    <td>
                        <app-action [text]="'home' | translate" (click)="navigationService.goToHomePage()">
                        </app-action>
                    </td>
                    <td>
                        <app-action [text]="'sign_out' | translate" (click)="logout()">
                        </app-action>
                    </td>
                </tr>
            </table>
        </td>
        <td>
            <div [ngClass]="localizationService.isEnglish() ? 'language-button-on' : 'link-like language-button-off'"
                    (click)="localizationService.setEnglish()">
                EN
            </div>
        </td>
        <td>
            &nbsp;
        </td>
        <td>
            <div [ngClass]="localizationService.isSpanish() ? 'language-button-on' : 'link-like language-button-off'"
                    (click)="localizationService.setSpanish()">
                ES
            </div>
        </td>
        <td>
            &nbsp;&nbsp;&nbsp;&nbsp;
        </td>
    </tr>
</table>
