// To replace environment.ts with this file use (defined in angular.json):
// ng serve --configuration=development
// ng build --configuration=development
export const environment = {
  isDev: true,
  isProd: false,
  backend: {
    url: 'https://backend.waiternow.dev'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyBV2i7XLqWdPRYYR4pb8XWv0ZMB97uo6vM',
    authDomain: 'waiternow-dev.firebaseapp.com',
    databaseURL: 'https://waiternow-dev.firebaseio.com',
    projectId: 'waiternow-dev',
    storageBucket: 'waiternow-dev.appspot.com',
    messagingSenderId: '32810023397',
    appId: '1:32810023397:web:3914182348212fa434d70c',
    measurementId: 'G-W87ZVVSXLR',
    vapidKey: 'BHtJ1iWIcbnWWiT5vrpKLgdPyG8NxhOiQh5VuKnBg_lfaRZctda0STDtuWjRDGk2qfjfZwGvRi1_QYE9ntPIKcY'
  },
};
