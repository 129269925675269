import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableColumn } from './data-table-column';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';
import { CompactTextComponent } from '../compact-text/compact-text.component';
import { ComponentUtil } from '../../util/component-util';
import { Util } from '../../util/util';
import { DeviceService } from '../../services/device.service';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarAction } from '../toolbar/toolbar-action';

// ----------------------------------------------------------------------------
// Usage Example

// Template:

// To update data replace the data instance, not just add or remove items.

// <app-data-table [data]="data" [columns]="columns"></app-data-table>
// <button (click)="updateOrders()">Update orders</button>

// toolbarActions and compactToolbarActions are optional and they are used to add
// a tool bar for each element in the table. If toolbarActions is present and
// compactToolbarActions is not, then toolbarActions is displayed for both desktop
// and mobile clients.

// <app-data-table
//     [data]="data"
//     [columns]="columns"
//     [toolbarActions]="toolbarActions"
//     [compactToolbarActions]="compactToolbarActions"
//     (onRowClick)="onRowClick($event)">
// </app-data-table>
// <button (click)="updateOrders()">Update orders</button>

// TS:

// columns: Array<DataTableColumn<proto.waiternow.common.IOrderProto>> = [
//   new CompactTextColumn(
//       /* name= */ 'id',
//       /* translateId= */ 'id',
//       /* valueExtractor= */  order => order.id + '',
//       /* compactTextLength= */ 6),
//   new CompactTextIfMobileColumn(
//       /* name= */ 'id_2',
//       /* translateId= */ 'id',
//       /* valueExtractor= */  order => order.id + '',
//       /* compactTextLength= */ 6),
//   new StringColumn(
//       /* name= */ 'orderNumber',
//       /* translateId= */ 'order_number',
//       /* valueExtractor= */  order => order.orderNumber + '',
//       /* hideInCompactMode=*/ true),
//   ];

// toolbarActions: Array<ToolbarAction<proto.waiternow.common.IOrderProto>> = [
//   { icon: 'home', tooltipTranslateId: 'home', onExecute: order => { console.log('Home clicked for ' + order?.id); } },
//   { icon: 'edit', tooltipTranslateId: 'edit', onExecute: order => { console.log('Edit clicked for ' + order?.id); } },
//   {
//     icon: 'delete',
//     tooltipTranslateId: 'delete',
//     onAsyncExecute: (order: proto.waiternow.common.IOrderProto | undefined, actionObserver: ActionObserver) => {
//       this.executeAfterDelay(3000, () => {
//         // actionObserver.onSuccess(SuccessResult.withMessage('Success message'));
//         // or
//         const error = new AppError('App error');
//         actionObserver.onError(ErrorResult.withErrorAndMessage(error, 'Error message'));
//       });
//     }
//   }
// ];
// compactToolbarActions: Array<ToolbarAction<proto.waiternow.common.IOrderProto>> = [
//   {
//     icon: 'open_in_new',
//     tooltipTranslateId: 'open_in_new_window',
//     onExecute: order => { console.log('Open in new window for ' + order?.id); }
//   },
// ];
// async executeAfterDelay(delayMilis: number, task: () => void): void {
//   await new Promise(resolve => setTimeout(resolve, delayMilis)).then(task);
// }

// public onRowClick(order: proto.waiternow.common.IOrderProto) {
//   this.navigationService.goToOrderPage(Util.safeString(order?.id));
// }

// data: Array<proto.waiternow.common.IOrderProto> = [
//   this.createOrder("10000000001" , 1),
//   this.createOrder("20000000002" , 2),
//   this.createOrder("30000000003" , 3),
//   this.createOrder("40000000004" , 4),
//   this.createOrder("50000000005" , 5)
// ];

// private createOrder(id: string, orderNumber: number): proto.waiternow.common.OrderProto {
//   const order = new proto.waiternow.common.OrderProto();
//   order.id = id;
//   order.orderNumber = orderNumber;
//   return order;
// }

// To update data reemplace the data instance, not just add or remove items
// public updateOrders(): void {
//   this.data = [
//     this.createOrder("60000000001" , 6),
//     this.createOrder("70000000002" , 7),
//     this.createOrder("80000000003" , 8),
//     this.createOrder("90000000004" , 9),
//   ];
// }

// ----------------------------------------------------------------------------

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    CompactTextComponent,
    ToolbarComponent
  ],
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent<T> implements OnInit, OnChanges {
  @Input() data!: Array<T>;
  @Input() columns!: Array<DataTableColumn<T>>;
  @Input() toolbarActions!: Array<ToolbarAction<T>>;
  @Input() compactToolbarActions?: Array<ToolbarAction<T>>;
  @Output() onRowClick: EventEmitter<T> = new EventEmitter<T>();

  actualColumns: Array<DataTableColumn<T>>;
  actualColumnNames: Array<String>;

  actualMobileColumns: Array<DataTableColumn<T>>;
  actualMobileColumnNames: Array<String>;

  tableDataSource: MatTableDataSource<T>;

  constructor(public deviceService: DeviceService) {
    this.actualColumns = new Array();
    this.actualColumnNames = new Array();
    this.actualMobileColumns = new Array();
    this.actualMobileColumnNames = new Array();
    this.tableDataSource = new MatTableDataSource();
  }

  ngOnInit(): void {
    Util.replaceArrayItems(this.actualColumns, this.columns);
    Util.replaceArrayItems(this.actualMobileColumns, this.columns.filter(column => !column.hideInCompactMode));
    if (this.toolbarActions || this.compactToolbarActions) {
      const toolbarColumn = new DataTableColumn(/* name= */ 'column_toolbar', /* translateId= */ '');
      this.actualColumns.push(toolbarColumn);
      this.actualMobileColumns.push(toolbarColumn);
    }
    Util.replaceArrayItems(this.actualColumnNames, this.actualColumns.map(column => column.name));
    Util.replaceArrayItems(this.actualMobileColumnNames, this.actualMobileColumns.map(column => column.name));
    this.tableDataSource.data = this.data;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (ComponentUtil.bindingChanged('dataModel', changes)
        || ComponentUtil.bindingChanged('toolbarActions', changes)
        || ComponentUtil.bindingChanged('compactToolbarActions', changes)) {
      this.ngOnInit();
    }
    if (ComponentUtil.bindingChanged('data', changes)) {
      this.tableDataSource.data = this.data;
    }
  }

  public getColumns() {
    if (this.deviceService.isMobile()) {
      return this.actualMobileColumns;
    }
    return this.actualColumns;
  }

  public getColumnNames() {
    if (this.deviceService.isMobile()) {
      return this.actualMobileColumnNames;
    }
    return this.actualColumnNames;
  }

  public emitRowClick(dataItem: T, column: DataTableColumn<T>): void {
    // The other type of columns have a component that responds to events. If we fire the
    // row click then two events will be triggered. For example, if the column is the toolbar,
    // the toolbar action will triger, but also the row click.
    if (!column.isStringColumn()) {
      return;
    }
    if (this.onRowClick.observed) {
      this.onRowClick.emit(dataItem);
    }
  }
}
