import { Injectable } from '@angular/core';
import { Observable, catchError, map, retry, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EnvironmentUtil } from '../util/environment-util';
import { SessionService } from './session.service';
import { AppError } from '../../general/util/error';
import { Function, Runnable, Consumer } from 'src/app/general/interfaces/functions';
import { LoggingService } from 'src/app/general/services/logging.service';
import * as proto from 'src/proto/compiled-protos';

@Injectable({
  providedIn: 'root'
})
export class BackendService {

  constructor(
      private httpClient: HttpClient,
      private sessionService: SessionService,
      private loggingService: LoggingService) {
  }

  public getVersions(
      onSuccess: Consumer<proto.waiternow.common.GetVersionsActionProto.Response>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetVersionsActionProto.Request();

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/common/versions/get'),
      request,
      protoRequest => proto.waiternow.common.GetVersionsActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetVersionsActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public signIn(email: string, password: string, onSuccess: Consumer<proto.waiternow.common.SignInActionProto.Response>, onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.SignInActionProto.Request();
    request.email = email;
    request.password = password;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/signin'),
      request,
      protoRequest => proto.waiternow.common.SignInActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.SignInActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public registerDevice(
      clientType: proto.waiternow.common.DeviceProto.ClientType,
      deviceType: proto.waiternow.common.DeviceProto.DeviceType,
      version: string,
      onSuccess: Consumer<proto.waiternow.common.IDeviceProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.RegisterDeviceActionProto.Request();
    request.clientType = clientType;
    request.deviceType = deviceType;
    request.version = version;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/common/device/register'),
      request,
      protoRequest => proto.waiternow.common.RegisterDeviceActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.RegisterDeviceActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.device))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public startDeviceSession(
      deviceId: string,
      firebaseMessagingToken: string,
      interests: proto.waiternow.common.DeviceProto.IInterestsProto,
      version: string,
      onSuccess: Consumer<proto.waiternow.common.IDeviceSessionInfoProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.StartDeviceSessionActionProto.Request();
    request.deviceId = deviceId;
    request.firebaseMessagingToken = firebaseMessagingToken;
    request.interests = interests;
    request.version = version;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/common/device/startsession'),
      request,
      protoRequest => proto.waiternow.common.StartDeviceSessionActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.StartDeviceSessionActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.deviceSessionInfo))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public sendDeviceHeartbeat(
      deviceId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.DeviceHeartbeatActionProto.Request();
    request.deviceId = deviceId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/common/device/heartbeat'),
      request,
      protoRequest => proto.waiternow.common.DeviceHeartbeatActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.DeviceHeartbeatActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public startGracePeriodToEndDeviceSession(
      deviceId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request();
    request.deviceId = deviceId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/common/device/start_grace_period_to_end_session'),
      request,
      protoRequest => proto.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public endDeviceSession(
      deviceId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.EndDeviceSessionActionProto.Request();
    request.deviceId = deviceId;

    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/common/device/endsession'),
      request,
      protoRequest => proto.waiternow.common.EndDeviceSessionActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.EndDeviceSessionActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findLocationsByUser(
      userId: string,
      onSuccess: Consumer<proto.waiternow.common.IUserLocationsProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindLocationsByUserActionProto.Request();
    request.userId = userId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/location/findbyuser'),
      request,
      protoRequest => proto.waiternow.common.FindLocationsByUserActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindLocationsByUserActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.userLocations))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findPendingOrders(
      locationId: string,
      continuationToken: string,
      onSuccess: Consumer<proto.waiternow.common.ILocationOrdersProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.FindPendingOrdersActionProto.Request();
    request.locationId = locationId;
    request.continuationToken = continuationToken;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/find_pending'),
      request,
      protoRequest => proto.waiternow.common.FindPendingOrdersActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindPendingOrdersActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.orders))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public getOrder(
      orderId: string,
      onSuccess: Consumer<proto.waiternow.common.IOrderProto | null | undefined>,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.GetOrderActionProto.Request();
    request.orderId = orderId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/get'),
      request,
      protoRequest => proto.waiternow.common.GetOrderActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.GetOrderActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.order))
    )
    .subscribe(
      {
        next: data => onSuccess(data),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public ackOrder(
      orderId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.AckOrderActionProto.Request();
    request.orderId = orderId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/ack'),
      request,
      protoRequest => proto.waiternow.common.AckOrderActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.AckOrderActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public markOrderAsCompleted(
      orderId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.MarkOrderAsCompletedActionProto.Request();
    request.orderId = orderId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/mark_completed'),
      request,
      protoRequest => proto.waiternow.common.MarkOrderAsCompletedActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.MarkOrderAsCompletedActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public findLocationPaidOrdersReturnObservable(
      locationId: string, continuationToken?: string | null | undefined):
      Observable<proto.waiternow.common.ILocationOrdersProto | null | undefined> {
    const request = new proto.waiternow.common.FindPaidOrdersActionProto.Request();
    request.locationId = locationId;
    request.sortOrder = proto.waiternow.common.SortOrder.DESCENDING;
    if (continuationToken) {
      request.continuationToken = continuationToken;
    }
    return this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/findpaid'),
      request,
      protoRequest => proto.waiternow.common.FindPaidOrdersActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.FindPaidOrdersActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => response.locationOrders))
    );
  }

  public findLocationPaidOrders(
      locationId: string,
      continuationToken: string | null | undefined,
      onSuccess: Consumer<proto.waiternow.common.ILocationOrdersProto | null | undefined>,
      onError: Consumer<AppError>): void {
    this.findLocationPaidOrdersReturnObservable(locationId, continuationToken)
      .subscribe(
        {
          next: data => onSuccess(data),
          error: error => onError(this.toAppError(error))
        }
      );
  }

  public refunOrder(
      orderId: string,
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    const request = new proto.waiternow.common.RefundPaidOrderActionProto.Request();
    request.orderId = orderId;
    this.httpPostWithProtoRequestAndProtoResponse(
      EnvironmentUtil.resolveBackendUrl('/service/user/order/refund'),
      request,
      protoRequest => proto.waiternow.common.RefundPaidOrderActionProto.Request.encode(protoRequest).finish(),
      uint8ArrayProtoResponse => proto.waiternow.common.RefundPaidOrderActionProto.Response.decode(uint8ArrayProtoResponse)
    )
    .pipe(
      map(
        this.extractDataAndMapOperationStatusErrorToObservableError(
          /* extractOperationStatus= */ response => response.operationStatus,
          /* extractData= */ response => undefined))
    )
    .subscribe(
      {
        next: data => onSuccess(),
        error: error => onError(this.toAppError(error))
      }
    );
  }

  private extractDataAndMapOperationStatusErrorToObservableError<R, D>(
      extractOperationStatus: (response: R) => proto.waiternow.common.IOperationStatusProto | null | undefined,
      extractData: (response: R) => D): (response: R) => D {
    return response => {
      const operationstatus = extractOperationStatus(response);
      if (operationstatus && operationstatus.isFailure) {
        const errorMessage = 'Server error:'
            + '\n  Error code: '+ operationstatus.errorCode
            + '.\n  Message: ' + operationstatus.errorMessage;
        this.loggingService.logError(errorMessage)
        throw new AppError(
          /* message= */ errorMessage,
          /* cause= */ undefined,
          /* httpErrorCode= */ undefined,
          /* serverErrorCode= */ operationstatus.errorCode);
      }
      return extractData(response);
    };
  }

  private httpPostWithBinaryRequestAndBinaryResponse(url: string, request: ArrayBuffer): Observable<ArrayBuffer> {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    const observableHttpResponse = this.httpClient.post(url, request, {headers: httpHeaders, responseType: 'arraybuffer'})
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
    return observableHttpResponse;
  }

  private httpPostWithProtoRequestAndProtoResponse<T, R>(
      url: string,
      request:T,
      encodeRequest: Function<T, Uint8Array>,
      decodeResponse: Function<Uint8Array, R>): Observable<R> {
    const uint8Array = encodeRequest(request);
    const arrayBufferRequest = this.uint8ArrayToArrayBuffer(uint8Array);
    return this.httpPostWithBinaryRequestAndBinaryResponse(url, arrayBufferRequest)
    .pipe(
      map(
        (arrayBufferResponse: ArrayBuffer) => {
          return decodeResponse(new Uint8Array(arrayBufferResponse));
      }));
  }

  private httpPostWithFile(url: string, fileFieldName: string, file: File): Observable<ArrayBuffer> {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    const formData = new FormData();
    formData.append(fileFieldName, file);
    const observableHttpResponse = this.httpClient.post(url, formData, {headers: httpHeaders, responseType: 'arraybuffer'})
      .pipe(
        retry(0),
        catchError(this.handleError)
      );
    return observableHttpResponse;
  }

  public httpGetAndOpenInNewWindow(url: string, onSuccess: Runnable, onError: Consumer<AppError>): void {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    this.httpClient.get(url, {headers: httpHeaders, responseType: 'arraybuffer'})
      .subscribe(
      {
        next: (arrayBufferResponse: ArrayBuffer) => {
          const downloadLink: string = window.URL.createObjectURL(new Blob([arrayBufferResponse]));
          const windowProxy = window.open(downloadLink, "_blank");
          if (windowProxy) {
            windowProxy.focus();
          }
          onSuccess();
        },
        error: error => onError(this.toAppError(error))
      }
    );
  }

  public httpPostAndOpenInNewWindow(
      url: string,
      params: {[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;},
      onSuccess: Runnable,
      onError: Consumer<AppError>): void {
    let httpHeaders = new HttpHeaders();
    if (this.sessionService.getAuthToken()) {
      httpHeaders = httpHeaders.set("Auth-Token", this.sessionService.getAuthToken());
    }
    this.httpClient.post(url, params, {headers: httpHeaders, responseType: 'arraybuffer'})
      .subscribe(
      {
        next: (arrayBufferResponse: ArrayBuffer) => {
          const downloadLink: string = window.URL.createObjectURL(new Blob([arrayBufferResponse]));
          const windowProxy = window.open(downloadLink, "_blank");
          if (windowProxy) {
            windowProxy.focus();
          }
          onSuccess();
        },
        error: error => onError(this.toAppError(error))
      }
    );
  }

  private uint8ArrayToArrayBuffer(uint8Array: Uint8Array): ArrayBuffer {
    const arrayBuffer = new ArrayBuffer(uint8Array.length);
    const auxbuffer = new Uint8Array(arrayBuffer);
    for (let i=0; i < uint8Array.length; i++) {
      auxbuffer[i] = uint8Array[i];
    }
    return arrayBuffer;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      this.loggingService.logError('Client error: ', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      this.loggingService.logError(
        `HTTP error code: ${error.status}, body: `, error.error);
    }
    // Return an observable with a user-facing error message.
    // return throwError(() => new Error('Something bad happened; please try again later.'));
    return throwError(() => new AppError(/* message= */ 'HTTP error', /* cause= */ error, /* httpErrorCode= */ error.status));
  }

  private toAppError(error: any): AppError {
    if (error instanceof AppError) {
      return error;
    }
    return new AppError(/* message= */ 'Unknown error', /* cause= */ error);
  }
}
