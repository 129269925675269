/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
"use strict";

var $protobuf = require("protobufjs/minimal");

// Common aliases
var $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
var $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

$root.waiternow = (function() {

    /**
     * Namespace waiternow.
     * @exports waiternow
     * @namespace
     */
    var waiternow = {};

    waiternow.common = (function() {

        /**
         * Namespace common.
         * @memberof waiternow
         * @namespace
         */
        var common = {};

        common.AnalyticsProto = (function() {

            /**
             * Properties of an AnalyticsProto.
             * @memberof waiternow.common
             * @interface IAnalyticsProto
             * @property {string|null} [locationId] AnalyticsProto locationId
             * @property {google.protobuf.ITimestamp|null} [creationTime] AnalyticsProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] AnalyticsProto lastUpdateTime
             * @property {string|null} [concurrencyToken] AnalyticsProto concurrencyToken
             * @property {Array.<waiternow.common.AnalyticsProto.IYearStatisticsProto>|null} [yearStatistics] AnalyticsProto yearStatistics
             * @property {waiternow.common.AnalyticsProto.IRedundantDataProto|null} [redundantData] AnalyticsProto redundantData
             */

            /**
             * Constructs a new AnalyticsProto.
             * @memberof waiternow.common
             * @classdesc Represents an AnalyticsProto.
             * @implements IAnalyticsProto
             * @constructor
             * @param {waiternow.common.IAnalyticsProto=} [properties] Properties to set
             */
            function AnalyticsProto(properties) {
                this.yearStatistics = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AnalyticsProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.AnalyticsProto
             * @instance
             */
            AnalyticsProto.prototype.locationId = "";

            /**
             * AnalyticsProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.AnalyticsProto
             * @instance
             */
            AnalyticsProto.prototype.creationTime = null;

            /**
             * AnalyticsProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.AnalyticsProto
             * @instance
             */
            AnalyticsProto.prototype.lastUpdateTime = null;

            /**
             * AnalyticsProto concurrencyToken.
             * @member {string} concurrencyToken
             * @memberof waiternow.common.AnalyticsProto
             * @instance
             */
            AnalyticsProto.prototype.concurrencyToken = "";

            /**
             * AnalyticsProto yearStatistics.
             * @member {Array.<waiternow.common.AnalyticsProto.IYearStatisticsProto>} yearStatistics
             * @memberof waiternow.common.AnalyticsProto
             * @instance
             */
            AnalyticsProto.prototype.yearStatistics = $util.emptyArray;

            /**
             * AnalyticsProto redundantData.
             * @member {waiternow.common.AnalyticsProto.IRedundantDataProto|null|undefined} redundantData
             * @memberof waiternow.common.AnalyticsProto
             * @instance
             */
            AnalyticsProto.prototype.redundantData = null;

            /**
             * Creates a new AnalyticsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {waiternow.common.IAnalyticsProto=} [properties] Properties to set
             * @returns {waiternow.common.AnalyticsProto} AnalyticsProto instance
             */
            AnalyticsProto.create = function create(properties) {
                return new AnalyticsProto(properties);
            };

            /**
             * Encodes the specified AnalyticsProto message. Does not implicitly {@link waiternow.common.AnalyticsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {waiternow.common.IAnalyticsProto} message AnalyticsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnalyticsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.concurrencyToken != null && Object.hasOwnProperty.call(message, "concurrencyToken"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.concurrencyToken);
                if (message.yearStatistics != null && message.yearStatistics.length)
                    for (var i = 0; i < message.yearStatistics.length; ++i)
                        $root.waiternow.common.AnalyticsProto.YearStatisticsProto.encode(message.yearStatistics[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.redundantData != null && Object.hasOwnProperty.call(message, "redundantData"))
                    $root.waiternow.common.AnalyticsProto.RedundantDataProto.encode(message.redundantData, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AnalyticsProto message, length delimited. Does not implicitly {@link waiternow.common.AnalyticsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {waiternow.common.IAnalyticsProto} message AnalyticsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AnalyticsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AnalyticsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AnalyticsProto} AnalyticsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnalyticsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AnalyticsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.concurrencyToken = reader.string();
                            break;
                        }
                    case 5: {
                            if (!(message.yearStatistics && message.yearStatistics.length))
                                message.yearStatistics = [];
                            message.yearStatistics.push($root.waiternow.common.AnalyticsProto.YearStatisticsProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            message.redundantData = $root.waiternow.common.AnalyticsProto.RedundantDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AnalyticsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AnalyticsProto} AnalyticsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AnalyticsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AnalyticsProto message.
             * @function verify
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AnalyticsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.concurrencyToken != null && message.hasOwnProperty("concurrencyToken"))
                    if (!$util.isString(message.concurrencyToken))
                        return "concurrencyToken: string expected";
                if (message.yearStatistics != null && message.hasOwnProperty("yearStatistics")) {
                    if (!Array.isArray(message.yearStatistics))
                        return "yearStatistics: array expected";
                    for (var i = 0; i < message.yearStatistics.length; ++i) {
                        var error = $root.waiternow.common.AnalyticsProto.YearStatisticsProto.verify(message.yearStatistics[i]);
                        if (error)
                            return "yearStatistics." + error;
                    }
                }
                if (message.redundantData != null && message.hasOwnProperty("redundantData")) {
                    var error = $root.waiternow.common.AnalyticsProto.RedundantDataProto.verify(message.redundantData);
                    if (error)
                        return "redundantData." + error;
                }
                return null;
            };

            /**
             * Creates an AnalyticsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AnalyticsProto} AnalyticsProto
             */
            AnalyticsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AnalyticsProto)
                    return object;
                var message = new $root.waiternow.common.AnalyticsProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.AnalyticsProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.AnalyticsProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.concurrencyToken != null)
                    message.concurrencyToken = String(object.concurrencyToken);
                if (object.yearStatistics) {
                    if (!Array.isArray(object.yearStatistics))
                        throw TypeError(".waiternow.common.AnalyticsProto.yearStatistics: array expected");
                    message.yearStatistics = [];
                    for (var i = 0; i < object.yearStatistics.length; ++i) {
                        if (typeof object.yearStatistics[i] !== "object")
                            throw TypeError(".waiternow.common.AnalyticsProto.yearStatistics: object expected");
                        message.yearStatistics[i] = $root.waiternow.common.AnalyticsProto.YearStatisticsProto.fromObject(object.yearStatistics[i]);
                    }
                }
                if (object.redundantData != null) {
                    if (typeof object.redundantData !== "object")
                        throw TypeError(".waiternow.common.AnalyticsProto.redundantData: object expected");
                    message.redundantData = $root.waiternow.common.AnalyticsProto.RedundantDataProto.fromObject(object.redundantData);
                }
                return message;
            };

            /**
             * Creates a plain object from an AnalyticsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {waiternow.common.AnalyticsProto} message AnalyticsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AnalyticsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.yearStatistics = [];
                if (options.defaults) {
                    object.locationId = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.concurrencyToken = "";
                    object.redundantData = null;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.concurrencyToken != null && message.hasOwnProperty("concurrencyToken"))
                    object.concurrencyToken = message.concurrencyToken;
                if (message.yearStatistics && message.yearStatistics.length) {
                    object.yearStatistics = [];
                    for (var j = 0; j < message.yearStatistics.length; ++j)
                        object.yearStatistics[j] = $root.waiternow.common.AnalyticsProto.YearStatisticsProto.toObject(message.yearStatistics[j], options);
                }
                if (message.redundantData != null && message.hasOwnProperty("redundantData"))
                    object.redundantData = $root.waiternow.common.AnalyticsProto.RedundantDataProto.toObject(message.redundantData, options);
                return object;
            };

            /**
             * Converts this AnalyticsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AnalyticsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AnalyticsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AnalyticsProto
             * @function getTypeUrl
             * @memberof waiternow.common.AnalyticsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AnalyticsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AnalyticsProto";
            };

            AnalyticsProto.StatisticsProto = (function() {

                /**
                 * Properties of a StatisticsProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @interface IStatisticsProto
                 * @property {number|null} [numberOfRequests] StatisticsProto numberOfRequests
                 * @property {number|null} [numberOfCheckIns] StatisticsProto numberOfCheckIns
                 * @property {number|null} [numberOfPaidOrders] StatisticsProto numberOfPaidOrders
                 * @property {number|null} [numberOfThumbsUp] StatisticsProto numberOfThumbsUp
                 * @property {number|null} [numberOfThumbsDown] StatisticsProto numberOfThumbsDown
                 * @property {number|null} [numberOfFiveStarsRatings] StatisticsProto numberOfFiveStarsRatings
                 * @property {number|null} [numberOfFourStarsRatings] StatisticsProto numberOfFourStarsRatings
                 * @property {number|null} [numberOfThreeStarsRatings] StatisticsProto numberOfThreeStarsRatings
                 * @property {number|null} [numberOfTwoStarsRatings] StatisticsProto numberOfTwoStarsRatings
                 * @property {number|null} [numberOfOneStarRatings] StatisticsProto numberOfOneStarRatings
                 */

                /**
                 * Constructs a new StatisticsProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @classdesc Represents a StatisticsProto.
                 * @implements IStatisticsProto
                 * @constructor
                 * @param {waiternow.common.AnalyticsProto.IStatisticsProto=} [properties] Properties to set
                 */
                function StatisticsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatisticsProto numberOfRequests.
                 * @member {number} numberOfRequests
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfRequests = 0;

                /**
                 * StatisticsProto numberOfCheckIns.
                 * @member {number} numberOfCheckIns
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfCheckIns = 0;

                /**
                 * StatisticsProto numberOfPaidOrders.
                 * @member {number} numberOfPaidOrders
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfPaidOrders = 0;

                /**
                 * StatisticsProto numberOfThumbsUp.
                 * @member {number} numberOfThumbsUp
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfThumbsUp = 0;

                /**
                 * StatisticsProto numberOfThumbsDown.
                 * @member {number} numberOfThumbsDown
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfThumbsDown = 0;

                /**
                 * StatisticsProto numberOfFiveStarsRatings.
                 * @member {number} numberOfFiveStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfFiveStarsRatings = 0;

                /**
                 * StatisticsProto numberOfFourStarsRatings.
                 * @member {number} numberOfFourStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfFourStarsRatings = 0;

                /**
                 * StatisticsProto numberOfThreeStarsRatings.
                 * @member {number} numberOfThreeStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfThreeStarsRatings = 0;

                /**
                 * StatisticsProto numberOfTwoStarsRatings.
                 * @member {number} numberOfTwoStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfTwoStarsRatings = 0;

                /**
                 * StatisticsProto numberOfOneStarRatings.
                 * @member {number} numberOfOneStarRatings
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 */
                StatisticsProto.prototype.numberOfOneStarRatings = 0;

                /**
                 * Creates a new StatisticsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IStatisticsProto=} [properties] Properties to set
                 * @returns {waiternow.common.AnalyticsProto.StatisticsProto} StatisticsProto instance
                 */
                StatisticsProto.create = function create(properties) {
                    return new StatisticsProto(properties);
                };

                /**
                 * Encodes the specified StatisticsProto message. Does not implicitly {@link waiternow.common.AnalyticsProto.StatisticsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IStatisticsProto} message StatisticsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatisticsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.numberOfRequests != null && Object.hasOwnProperty.call(message, "numberOfRequests"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.numberOfRequests);
                    if (message.numberOfCheckIns != null && Object.hasOwnProperty.call(message, "numberOfCheckIns"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.numberOfCheckIns);
                    if (message.numberOfPaidOrders != null && Object.hasOwnProperty.call(message, "numberOfPaidOrders"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.numberOfPaidOrders);
                    if (message.numberOfThumbsUp != null && Object.hasOwnProperty.call(message, "numberOfThumbsUp"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.numberOfThumbsUp);
                    if (message.numberOfThumbsDown != null && Object.hasOwnProperty.call(message, "numberOfThumbsDown"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.numberOfThumbsDown);
                    if (message.numberOfFiveStarsRatings != null && Object.hasOwnProperty.call(message, "numberOfFiveStarsRatings"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.numberOfFiveStarsRatings);
                    if (message.numberOfFourStarsRatings != null && Object.hasOwnProperty.call(message, "numberOfFourStarsRatings"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.numberOfFourStarsRatings);
                    if (message.numberOfThreeStarsRatings != null && Object.hasOwnProperty.call(message, "numberOfThreeStarsRatings"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.numberOfThreeStarsRatings);
                    if (message.numberOfTwoStarsRatings != null && Object.hasOwnProperty.call(message, "numberOfTwoStarsRatings"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int32(message.numberOfTwoStarsRatings);
                    if (message.numberOfOneStarRatings != null && Object.hasOwnProperty.call(message, "numberOfOneStarRatings"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.numberOfOneStarRatings);
                    return writer;
                };

                /**
                 * Encodes the specified StatisticsProto message, length delimited. Does not implicitly {@link waiternow.common.AnalyticsProto.StatisticsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IStatisticsProto} message StatisticsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatisticsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatisticsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AnalyticsProto.StatisticsProto} StatisticsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatisticsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AnalyticsProto.StatisticsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.numberOfRequests = reader.int32();
                                break;
                            }
                        case 2: {
                                message.numberOfCheckIns = reader.int32();
                                break;
                            }
                        case 3: {
                                message.numberOfPaidOrders = reader.int32();
                                break;
                            }
                        case 4: {
                                message.numberOfThumbsUp = reader.int32();
                                break;
                            }
                        case 5: {
                                message.numberOfThumbsDown = reader.int32();
                                break;
                            }
                        case 6: {
                                message.numberOfFiveStarsRatings = reader.int32();
                                break;
                            }
                        case 7: {
                                message.numberOfFourStarsRatings = reader.int32();
                                break;
                            }
                        case 8: {
                                message.numberOfThreeStarsRatings = reader.int32();
                                break;
                            }
                        case 9: {
                                message.numberOfTwoStarsRatings = reader.int32();
                                break;
                            }
                        case 10: {
                                message.numberOfOneStarRatings = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatisticsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AnalyticsProto.StatisticsProto} StatisticsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatisticsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatisticsProto message.
                 * @function verify
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatisticsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.numberOfRequests != null && message.hasOwnProperty("numberOfRequests"))
                        if (!$util.isInteger(message.numberOfRequests))
                            return "numberOfRequests: integer expected";
                    if (message.numberOfCheckIns != null && message.hasOwnProperty("numberOfCheckIns"))
                        if (!$util.isInteger(message.numberOfCheckIns))
                            return "numberOfCheckIns: integer expected";
                    if (message.numberOfPaidOrders != null && message.hasOwnProperty("numberOfPaidOrders"))
                        if (!$util.isInteger(message.numberOfPaidOrders))
                            return "numberOfPaidOrders: integer expected";
                    if (message.numberOfThumbsUp != null && message.hasOwnProperty("numberOfThumbsUp"))
                        if (!$util.isInteger(message.numberOfThumbsUp))
                            return "numberOfThumbsUp: integer expected";
                    if (message.numberOfThumbsDown != null && message.hasOwnProperty("numberOfThumbsDown"))
                        if (!$util.isInteger(message.numberOfThumbsDown))
                            return "numberOfThumbsDown: integer expected";
                    if (message.numberOfFiveStarsRatings != null && message.hasOwnProperty("numberOfFiveStarsRatings"))
                        if (!$util.isInteger(message.numberOfFiveStarsRatings))
                            return "numberOfFiveStarsRatings: integer expected";
                    if (message.numberOfFourStarsRatings != null && message.hasOwnProperty("numberOfFourStarsRatings"))
                        if (!$util.isInteger(message.numberOfFourStarsRatings))
                            return "numberOfFourStarsRatings: integer expected";
                    if (message.numberOfThreeStarsRatings != null && message.hasOwnProperty("numberOfThreeStarsRatings"))
                        if (!$util.isInteger(message.numberOfThreeStarsRatings))
                            return "numberOfThreeStarsRatings: integer expected";
                    if (message.numberOfTwoStarsRatings != null && message.hasOwnProperty("numberOfTwoStarsRatings"))
                        if (!$util.isInteger(message.numberOfTwoStarsRatings))
                            return "numberOfTwoStarsRatings: integer expected";
                    if (message.numberOfOneStarRatings != null && message.hasOwnProperty("numberOfOneStarRatings"))
                        if (!$util.isInteger(message.numberOfOneStarRatings))
                            return "numberOfOneStarRatings: integer expected";
                    return null;
                };

                /**
                 * Creates a StatisticsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AnalyticsProto.StatisticsProto} StatisticsProto
                 */
                StatisticsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AnalyticsProto.StatisticsProto)
                        return object;
                    var message = new $root.waiternow.common.AnalyticsProto.StatisticsProto();
                    if (object.numberOfRequests != null)
                        message.numberOfRequests = object.numberOfRequests | 0;
                    if (object.numberOfCheckIns != null)
                        message.numberOfCheckIns = object.numberOfCheckIns | 0;
                    if (object.numberOfPaidOrders != null)
                        message.numberOfPaidOrders = object.numberOfPaidOrders | 0;
                    if (object.numberOfThumbsUp != null)
                        message.numberOfThumbsUp = object.numberOfThumbsUp | 0;
                    if (object.numberOfThumbsDown != null)
                        message.numberOfThumbsDown = object.numberOfThumbsDown | 0;
                    if (object.numberOfFiveStarsRatings != null)
                        message.numberOfFiveStarsRatings = object.numberOfFiveStarsRatings | 0;
                    if (object.numberOfFourStarsRatings != null)
                        message.numberOfFourStarsRatings = object.numberOfFourStarsRatings | 0;
                    if (object.numberOfThreeStarsRatings != null)
                        message.numberOfThreeStarsRatings = object.numberOfThreeStarsRatings | 0;
                    if (object.numberOfTwoStarsRatings != null)
                        message.numberOfTwoStarsRatings = object.numberOfTwoStarsRatings | 0;
                    if (object.numberOfOneStarRatings != null)
                        message.numberOfOneStarRatings = object.numberOfOneStarRatings | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a StatisticsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.StatisticsProto} message StatisticsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatisticsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.numberOfRequests = 0;
                        object.numberOfCheckIns = 0;
                        object.numberOfPaidOrders = 0;
                        object.numberOfThumbsUp = 0;
                        object.numberOfThumbsDown = 0;
                        object.numberOfFiveStarsRatings = 0;
                        object.numberOfFourStarsRatings = 0;
                        object.numberOfThreeStarsRatings = 0;
                        object.numberOfTwoStarsRatings = 0;
                        object.numberOfOneStarRatings = 0;
                    }
                    if (message.numberOfRequests != null && message.hasOwnProperty("numberOfRequests"))
                        object.numberOfRequests = message.numberOfRequests;
                    if (message.numberOfCheckIns != null && message.hasOwnProperty("numberOfCheckIns"))
                        object.numberOfCheckIns = message.numberOfCheckIns;
                    if (message.numberOfPaidOrders != null && message.hasOwnProperty("numberOfPaidOrders"))
                        object.numberOfPaidOrders = message.numberOfPaidOrders;
                    if (message.numberOfThumbsUp != null && message.hasOwnProperty("numberOfThumbsUp"))
                        object.numberOfThumbsUp = message.numberOfThumbsUp;
                    if (message.numberOfThumbsDown != null && message.hasOwnProperty("numberOfThumbsDown"))
                        object.numberOfThumbsDown = message.numberOfThumbsDown;
                    if (message.numberOfFiveStarsRatings != null && message.hasOwnProperty("numberOfFiveStarsRatings"))
                        object.numberOfFiveStarsRatings = message.numberOfFiveStarsRatings;
                    if (message.numberOfFourStarsRatings != null && message.hasOwnProperty("numberOfFourStarsRatings"))
                        object.numberOfFourStarsRatings = message.numberOfFourStarsRatings;
                    if (message.numberOfThreeStarsRatings != null && message.hasOwnProperty("numberOfThreeStarsRatings"))
                        object.numberOfThreeStarsRatings = message.numberOfThreeStarsRatings;
                    if (message.numberOfTwoStarsRatings != null && message.hasOwnProperty("numberOfTwoStarsRatings"))
                        object.numberOfTwoStarsRatings = message.numberOfTwoStarsRatings;
                    if (message.numberOfOneStarRatings != null && message.hasOwnProperty("numberOfOneStarRatings"))
                        object.numberOfOneStarRatings = message.numberOfOneStarRatings;
                    return object;
                };

                /**
                 * Converts this StatisticsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatisticsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatisticsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.AnalyticsProto.StatisticsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatisticsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AnalyticsProto.StatisticsProto";
                };

                return StatisticsProto;
            })();

            AnalyticsProto.MonthStatisticsProto = (function() {

                /**
                 * Properties of a MonthStatisticsProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @interface IMonthStatisticsProto
                 * @property {waiternow.common.Month|null} [month] MonthStatisticsProto month
                 * @property {waiternow.common.AnalyticsProto.IStatisticsProto|null} [statistics] MonthStatisticsProto statistics
                 */

                /**
                 * Constructs a new MonthStatisticsProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @classdesc Represents a MonthStatisticsProto.
                 * @implements IMonthStatisticsProto
                 * @constructor
                 * @param {waiternow.common.AnalyticsProto.IMonthStatisticsProto=} [properties] Properties to set
                 */
                function MonthStatisticsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MonthStatisticsProto month.
                 * @member {waiternow.common.Month} month
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @instance
                 */
                MonthStatisticsProto.prototype.month = 0;

                /**
                 * MonthStatisticsProto statistics.
                 * @member {waiternow.common.AnalyticsProto.IStatisticsProto|null|undefined} statistics
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @instance
                 */
                MonthStatisticsProto.prototype.statistics = null;

                /**
                 * Creates a new MonthStatisticsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IMonthStatisticsProto=} [properties] Properties to set
                 * @returns {waiternow.common.AnalyticsProto.MonthStatisticsProto} MonthStatisticsProto instance
                 */
                MonthStatisticsProto.create = function create(properties) {
                    return new MonthStatisticsProto(properties);
                };

                /**
                 * Encodes the specified MonthStatisticsProto message. Does not implicitly {@link waiternow.common.AnalyticsProto.MonthStatisticsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IMonthStatisticsProto} message MonthStatisticsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MonthStatisticsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.month);
                    if (message.statistics != null && Object.hasOwnProperty.call(message, "statistics"))
                        $root.waiternow.common.AnalyticsProto.StatisticsProto.encode(message.statistics, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MonthStatisticsProto message, length delimited. Does not implicitly {@link waiternow.common.AnalyticsProto.MonthStatisticsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IMonthStatisticsProto} message MonthStatisticsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MonthStatisticsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MonthStatisticsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AnalyticsProto.MonthStatisticsProto} MonthStatisticsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MonthStatisticsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AnalyticsProto.MonthStatisticsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.month = reader.int32();
                                break;
                            }
                        case 2: {
                                message.statistics = $root.waiternow.common.AnalyticsProto.StatisticsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MonthStatisticsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AnalyticsProto.MonthStatisticsProto} MonthStatisticsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MonthStatisticsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MonthStatisticsProto message.
                 * @function verify
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MonthStatisticsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.month != null && message.hasOwnProperty("month"))
                        switch (message.month) {
                        default:
                            return "month: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                        case 9:
                        case 10:
                        case 11:
                        case 12:
                            break;
                        }
                    if (message.statistics != null && message.hasOwnProperty("statistics")) {
                        var error = $root.waiternow.common.AnalyticsProto.StatisticsProto.verify(message.statistics);
                        if (error)
                            return "statistics." + error;
                    }
                    return null;
                };

                /**
                 * Creates a MonthStatisticsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AnalyticsProto.MonthStatisticsProto} MonthStatisticsProto
                 */
                MonthStatisticsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AnalyticsProto.MonthStatisticsProto)
                        return object;
                    var message = new $root.waiternow.common.AnalyticsProto.MonthStatisticsProto();
                    switch (object.month) {
                    default:
                        if (typeof object.month === "number") {
                            message.month = object.month;
                            break;
                        }
                        break;
                    case "MONTH_UNSPECIFIED":
                    case 0:
                        message.month = 0;
                        break;
                    case "JANUARY":
                    case 1:
                        message.month = 1;
                        break;
                    case "FEBRUARY":
                    case 2:
                        message.month = 2;
                        break;
                    case "MARCH":
                    case 3:
                        message.month = 3;
                        break;
                    case "APRIL":
                    case 4:
                        message.month = 4;
                        break;
                    case "MAY":
                    case 5:
                        message.month = 5;
                        break;
                    case "JUNE":
                    case 6:
                        message.month = 6;
                        break;
                    case "JULY":
                    case 7:
                        message.month = 7;
                        break;
                    case "AUGUST":
                    case 8:
                        message.month = 8;
                        break;
                    case "SEPTEMBER":
                    case 9:
                        message.month = 9;
                        break;
                    case "OCTOBER":
                    case 10:
                        message.month = 10;
                        break;
                    case "NOVEMBER":
                    case 11:
                        message.month = 11;
                        break;
                    case "DECEMBER":
                    case 12:
                        message.month = 12;
                        break;
                    }
                    if (object.statistics != null) {
                        if (typeof object.statistics !== "object")
                            throw TypeError(".waiternow.common.AnalyticsProto.MonthStatisticsProto.statistics: object expected");
                        message.statistics = $root.waiternow.common.AnalyticsProto.StatisticsProto.fromObject(object.statistics);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MonthStatisticsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.MonthStatisticsProto} message MonthStatisticsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MonthStatisticsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.month = options.enums === String ? "MONTH_UNSPECIFIED" : 0;
                        object.statistics = null;
                    }
                    if (message.month != null && message.hasOwnProperty("month"))
                        object.month = options.enums === String ? $root.waiternow.common.Month[message.month] === undefined ? message.month : $root.waiternow.common.Month[message.month] : message.month;
                    if (message.statistics != null && message.hasOwnProperty("statistics"))
                        object.statistics = $root.waiternow.common.AnalyticsProto.StatisticsProto.toObject(message.statistics, options);
                    return object;
                };

                /**
                 * Converts this MonthStatisticsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MonthStatisticsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MonthStatisticsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.AnalyticsProto.MonthStatisticsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MonthStatisticsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AnalyticsProto.MonthStatisticsProto";
                };

                return MonthStatisticsProto;
            })();

            AnalyticsProto.YearStatisticsProto = (function() {

                /**
                 * Properties of a YearStatisticsProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @interface IYearStatisticsProto
                 * @property {number|null} [year] YearStatisticsProto year
                 * @property {Array.<waiternow.common.AnalyticsProto.IMonthStatisticsProto>|null} [monthStatistics] YearStatisticsProto monthStatistics
                 */

                /**
                 * Constructs a new YearStatisticsProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @classdesc Represents a YearStatisticsProto.
                 * @implements IYearStatisticsProto
                 * @constructor
                 * @param {waiternow.common.AnalyticsProto.IYearStatisticsProto=} [properties] Properties to set
                 */
                function YearStatisticsProto(properties) {
                    this.monthStatistics = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * YearStatisticsProto year.
                 * @member {number} year
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @instance
                 */
                YearStatisticsProto.prototype.year = 0;

                /**
                 * YearStatisticsProto monthStatistics.
                 * @member {Array.<waiternow.common.AnalyticsProto.IMonthStatisticsProto>} monthStatistics
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @instance
                 */
                YearStatisticsProto.prototype.monthStatistics = $util.emptyArray;

                /**
                 * Creates a new YearStatisticsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IYearStatisticsProto=} [properties] Properties to set
                 * @returns {waiternow.common.AnalyticsProto.YearStatisticsProto} YearStatisticsProto instance
                 */
                YearStatisticsProto.create = function create(properties) {
                    return new YearStatisticsProto(properties);
                };

                /**
                 * Encodes the specified YearStatisticsProto message. Does not implicitly {@link waiternow.common.AnalyticsProto.YearStatisticsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IYearStatisticsProto} message YearStatisticsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                YearStatisticsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                    if (message.monthStatistics != null && message.monthStatistics.length)
                        for (var i = 0; i < message.monthStatistics.length; ++i)
                            $root.waiternow.common.AnalyticsProto.MonthStatisticsProto.encode(message.monthStatistics[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified YearStatisticsProto message, length delimited. Does not implicitly {@link waiternow.common.AnalyticsProto.YearStatisticsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IYearStatisticsProto} message YearStatisticsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                YearStatisticsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a YearStatisticsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AnalyticsProto.YearStatisticsProto} YearStatisticsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                YearStatisticsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AnalyticsProto.YearStatisticsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.year = reader.int32();
                                break;
                            }
                        case 2: {
                                if (!(message.monthStatistics && message.monthStatistics.length))
                                    message.monthStatistics = [];
                                message.monthStatistics.push($root.waiternow.common.AnalyticsProto.MonthStatisticsProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a YearStatisticsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AnalyticsProto.YearStatisticsProto} YearStatisticsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                YearStatisticsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a YearStatisticsProto message.
                 * @function verify
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                YearStatisticsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.year != null && message.hasOwnProperty("year"))
                        if (!$util.isInteger(message.year))
                            return "year: integer expected";
                    if (message.monthStatistics != null && message.hasOwnProperty("monthStatistics")) {
                        if (!Array.isArray(message.monthStatistics))
                            return "monthStatistics: array expected";
                        for (var i = 0; i < message.monthStatistics.length; ++i) {
                            var error = $root.waiternow.common.AnalyticsProto.MonthStatisticsProto.verify(message.monthStatistics[i]);
                            if (error)
                                return "monthStatistics." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a YearStatisticsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AnalyticsProto.YearStatisticsProto} YearStatisticsProto
                 */
                YearStatisticsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AnalyticsProto.YearStatisticsProto)
                        return object;
                    var message = new $root.waiternow.common.AnalyticsProto.YearStatisticsProto();
                    if (object.year != null)
                        message.year = object.year | 0;
                    if (object.monthStatistics) {
                        if (!Array.isArray(object.monthStatistics))
                            throw TypeError(".waiternow.common.AnalyticsProto.YearStatisticsProto.monthStatistics: array expected");
                        message.monthStatistics = [];
                        for (var i = 0; i < object.monthStatistics.length; ++i) {
                            if (typeof object.monthStatistics[i] !== "object")
                                throw TypeError(".waiternow.common.AnalyticsProto.YearStatisticsProto.monthStatistics: object expected");
                            message.monthStatistics[i] = $root.waiternow.common.AnalyticsProto.MonthStatisticsProto.fromObject(object.monthStatistics[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a YearStatisticsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.YearStatisticsProto} message YearStatisticsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                YearStatisticsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.monthStatistics = [];
                    if (options.defaults)
                        object.year = 0;
                    if (message.year != null && message.hasOwnProperty("year"))
                        object.year = message.year;
                    if (message.monthStatistics && message.monthStatistics.length) {
                        object.monthStatistics = [];
                        for (var j = 0; j < message.monthStatistics.length; ++j)
                            object.monthStatistics[j] = $root.waiternow.common.AnalyticsProto.MonthStatisticsProto.toObject(message.monthStatistics[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this YearStatisticsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                YearStatisticsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for YearStatisticsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.AnalyticsProto.YearStatisticsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                YearStatisticsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AnalyticsProto.YearStatisticsProto";
                };

                return YearStatisticsProto;
            })();

            AnalyticsProto.RedundantDataProto = (function() {

                /**
                 * Properties of a RedundantDataProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @interface IRedundantDataProto
                 * @property {number|null} [totalNumberOfRequests] RedundantDataProto totalNumberOfRequests
                 * @property {number|null} [totalNumberOfCheckIns] RedundantDataProto totalNumberOfCheckIns
                 * @property {number|null} [totalNumberOfPaidOrders] RedundantDataProto totalNumberOfPaidOrders
                 * @property {number|null} [totalNumberOfThumbsUp] RedundantDataProto totalNumberOfThumbsUp
                 * @property {number|null} [totalNumberOfThumbsDown] RedundantDataProto totalNumberOfThumbsDown
                 * @property {number|null} [totalNumberOfFiveStarsRatings] RedundantDataProto totalNumberOfFiveStarsRatings
                 * @property {number|null} [totalNumberOfFourStarsRatings] RedundantDataProto totalNumberOfFourStarsRatings
                 * @property {number|null} [totalNumberOfThreeStarsRatings] RedundantDataProto totalNumberOfThreeStarsRatings
                 * @property {number|null} [totalNumberOfTwoStarsRatings] RedundantDataProto totalNumberOfTwoStarsRatings
                 * @property {number|null} [totalNumberOfOneStarRatings] RedundantDataProto totalNumberOfOneStarRatings
                 */

                /**
                 * Constructs a new RedundantDataProto.
                 * @memberof waiternow.common.AnalyticsProto
                 * @classdesc Represents a RedundantDataProto.
                 * @implements IRedundantDataProto
                 * @constructor
                 * @param {waiternow.common.AnalyticsProto.IRedundantDataProto=} [properties] Properties to set
                 */
                function RedundantDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RedundantDataProto totalNumberOfRequests.
                 * @member {number} totalNumberOfRequests
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfRequests = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfCheckIns.
                 * @member {number} totalNumberOfCheckIns
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfCheckIns = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfPaidOrders.
                 * @member {number} totalNumberOfPaidOrders
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfPaidOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfThumbsUp.
                 * @member {number} totalNumberOfThumbsUp
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfThumbsUp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfThumbsDown.
                 * @member {number} totalNumberOfThumbsDown
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfThumbsDown = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfFiveStarsRatings.
                 * @member {number} totalNumberOfFiveStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfFiveStarsRatings = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfFourStarsRatings.
                 * @member {number} totalNumberOfFourStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfFourStarsRatings = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfThreeStarsRatings.
                 * @member {number} totalNumberOfThreeStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfThreeStarsRatings = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfTwoStarsRatings.
                 * @member {number} totalNumberOfTwoStarsRatings
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfTwoStarsRatings = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * RedundantDataProto totalNumberOfOneStarRatings.
                 * @member {number} totalNumberOfOneStarRatings
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.totalNumberOfOneStarRatings = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new RedundantDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IRedundantDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.AnalyticsProto.RedundantDataProto} RedundantDataProto instance
                 */
                RedundantDataProto.create = function create(properties) {
                    return new RedundantDataProto(properties);
                };

                /**
                 * Encodes the specified RedundantDataProto message. Does not implicitly {@link waiternow.common.AnalyticsProto.RedundantDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.totalNumberOfRequests != null && Object.hasOwnProperty.call(message, "totalNumberOfRequests"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.totalNumberOfRequests);
                    if (message.totalNumberOfCheckIns != null && Object.hasOwnProperty.call(message, "totalNumberOfCheckIns"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.totalNumberOfCheckIns);
                    if (message.totalNumberOfPaidOrders != null && Object.hasOwnProperty.call(message, "totalNumberOfPaidOrders"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.totalNumberOfPaidOrders);
                    if (message.totalNumberOfThumbsUp != null && Object.hasOwnProperty.call(message, "totalNumberOfThumbsUp"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.totalNumberOfThumbsUp);
                    if (message.totalNumberOfThumbsDown != null && Object.hasOwnProperty.call(message, "totalNumberOfThumbsDown"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int64(message.totalNumberOfThumbsDown);
                    if (message.totalNumberOfFiveStarsRatings != null && Object.hasOwnProperty.call(message, "totalNumberOfFiveStarsRatings"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int64(message.totalNumberOfFiveStarsRatings);
                    if (message.totalNumberOfFourStarsRatings != null && Object.hasOwnProperty.call(message, "totalNumberOfFourStarsRatings"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int64(message.totalNumberOfFourStarsRatings);
                    if (message.totalNumberOfThreeStarsRatings != null && Object.hasOwnProperty.call(message, "totalNumberOfThreeStarsRatings"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.totalNumberOfThreeStarsRatings);
                    if (message.totalNumberOfTwoStarsRatings != null && Object.hasOwnProperty.call(message, "totalNumberOfTwoStarsRatings"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.totalNumberOfTwoStarsRatings);
                    if (message.totalNumberOfOneStarRatings != null && Object.hasOwnProperty.call(message, "totalNumberOfOneStarRatings"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int64(message.totalNumberOfOneStarRatings);
                    return writer;
                };

                /**
                 * Encodes the specified RedundantDataProto message, length delimited. Does not implicitly {@link waiternow.common.AnalyticsProto.RedundantDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AnalyticsProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AnalyticsProto.RedundantDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.totalNumberOfRequests = reader.int64();
                                break;
                            }
                        case 2: {
                                message.totalNumberOfCheckIns = reader.int64();
                                break;
                            }
                        case 3: {
                                message.totalNumberOfPaidOrders = reader.int64();
                                break;
                            }
                        case 4: {
                                message.totalNumberOfThumbsUp = reader.int64();
                                break;
                            }
                        case 5: {
                                message.totalNumberOfThumbsDown = reader.int64();
                                break;
                            }
                        case 6: {
                                message.totalNumberOfFiveStarsRatings = reader.int64();
                                break;
                            }
                        case 7: {
                                message.totalNumberOfFourStarsRatings = reader.int64();
                                break;
                            }
                        case 8: {
                                message.totalNumberOfThreeStarsRatings = reader.int64();
                                break;
                            }
                        case 9: {
                                message.totalNumberOfTwoStarsRatings = reader.int64();
                                break;
                            }
                        case 10: {
                                message.totalNumberOfOneStarRatings = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AnalyticsProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RedundantDataProto message.
                 * @function verify
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedundantDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.totalNumberOfRequests != null && message.hasOwnProperty("totalNumberOfRequests"))
                        if (!$util.isInteger(message.totalNumberOfRequests) && !(message.totalNumberOfRequests && $util.isInteger(message.totalNumberOfRequests.low) && $util.isInteger(message.totalNumberOfRequests.high)))
                            return "totalNumberOfRequests: integer|Long expected";
                    if (message.totalNumberOfCheckIns != null && message.hasOwnProperty("totalNumberOfCheckIns"))
                        if (!$util.isInteger(message.totalNumberOfCheckIns) && !(message.totalNumberOfCheckIns && $util.isInteger(message.totalNumberOfCheckIns.low) && $util.isInteger(message.totalNumberOfCheckIns.high)))
                            return "totalNumberOfCheckIns: integer|Long expected";
                    if (message.totalNumberOfPaidOrders != null && message.hasOwnProperty("totalNumberOfPaidOrders"))
                        if (!$util.isInteger(message.totalNumberOfPaidOrders) && !(message.totalNumberOfPaidOrders && $util.isInteger(message.totalNumberOfPaidOrders.low) && $util.isInteger(message.totalNumberOfPaidOrders.high)))
                            return "totalNumberOfPaidOrders: integer|Long expected";
                    if (message.totalNumberOfThumbsUp != null && message.hasOwnProperty("totalNumberOfThumbsUp"))
                        if (!$util.isInteger(message.totalNumberOfThumbsUp) && !(message.totalNumberOfThumbsUp && $util.isInteger(message.totalNumberOfThumbsUp.low) && $util.isInteger(message.totalNumberOfThumbsUp.high)))
                            return "totalNumberOfThumbsUp: integer|Long expected";
                    if (message.totalNumberOfThumbsDown != null && message.hasOwnProperty("totalNumberOfThumbsDown"))
                        if (!$util.isInteger(message.totalNumberOfThumbsDown) && !(message.totalNumberOfThumbsDown && $util.isInteger(message.totalNumberOfThumbsDown.low) && $util.isInteger(message.totalNumberOfThumbsDown.high)))
                            return "totalNumberOfThumbsDown: integer|Long expected";
                    if (message.totalNumberOfFiveStarsRatings != null && message.hasOwnProperty("totalNumberOfFiveStarsRatings"))
                        if (!$util.isInteger(message.totalNumberOfFiveStarsRatings) && !(message.totalNumberOfFiveStarsRatings && $util.isInteger(message.totalNumberOfFiveStarsRatings.low) && $util.isInteger(message.totalNumberOfFiveStarsRatings.high)))
                            return "totalNumberOfFiveStarsRatings: integer|Long expected";
                    if (message.totalNumberOfFourStarsRatings != null && message.hasOwnProperty("totalNumberOfFourStarsRatings"))
                        if (!$util.isInteger(message.totalNumberOfFourStarsRatings) && !(message.totalNumberOfFourStarsRatings && $util.isInteger(message.totalNumberOfFourStarsRatings.low) && $util.isInteger(message.totalNumberOfFourStarsRatings.high)))
                            return "totalNumberOfFourStarsRatings: integer|Long expected";
                    if (message.totalNumberOfThreeStarsRatings != null && message.hasOwnProperty("totalNumberOfThreeStarsRatings"))
                        if (!$util.isInteger(message.totalNumberOfThreeStarsRatings) && !(message.totalNumberOfThreeStarsRatings && $util.isInteger(message.totalNumberOfThreeStarsRatings.low) && $util.isInteger(message.totalNumberOfThreeStarsRatings.high)))
                            return "totalNumberOfThreeStarsRatings: integer|Long expected";
                    if (message.totalNumberOfTwoStarsRatings != null && message.hasOwnProperty("totalNumberOfTwoStarsRatings"))
                        if (!$util.isInteger(message.totalNumberOfTwoStarsRatings) && !(message.totalNumberOfTwoStarsRatings && $util.isInteger(message.totalNumberOfTwoStarsRatings.low) && $util.isInteger(message.totalNumberOfTwoStarsRatings.high)))
                            return "totalNumberOfTwoStarsRatings: integer|Long expected";
                    if (message.totalNumberOfOneStarRatings != null && message.hasOwnProperty("totalNumberOfOneStarRatings"))
                        if (!$util.isInteger(message.totalNumberOfOneStarRatings) && !(message.totalNumberOfOneStarRatings && $util.isInteger(message.totalNumberOfOneStarRatings.low) && $util.isInteger(message.totalNumberOfOneStarRatings.high)))
                            return "totalNumberOfOneStarRatings: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a RedundantDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AnalyticsProto.RedundantDataProto} RedundantDataProto
                 */
                RedundantDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AnalyticsProto.RedundantDataProto)
                        return object;
                    var message = new $root.waiternow.common.AnalyticsProto.RedundantDataProto();
                    if (object.totalNumberOfRequests != null)
                        if ($util.Long)
                            (message.totalNumberOfRequests = $util.Long.fromValue(object.totalNumberOfRequests)).unsigned = false;
                        else if (typeof object.totalNumberOfRequests === "string")
                            message.totalNumberOfRequests = parseInt(object.totalNumberOfRequests, 10);
                        else if (typeof object.totalNumberOfRequests === "number")
                            message.totalNumberOfRequests = object.totalNumberOfRequests;
                        else if (typeof object.totalNumberOfRequests === "object")
                            message.totalNumberOfRequests = new $util.LongBits(object.totalNumberOfRequests.low >>> 0, object.totalNumberOfRequests.high >>> 0).toNumber();
                    if (object.totalNumberOfCheckIns != null)
                        if ($util.Long)
                            (message.totalNumberOfCheckIns = $util.Long.fromValue(object.totalNumberOfCheckIns)).unsigned = false;
                        else if (typeof object.totalNumberOfCheckIns === "string")
                            message.totalNumberOfCheckIns = parseInt(object.totalNumberOfCheckIns, 10);
                        else if (typeof object.totalNumberOfCheckIns === "number")
                            message.totalNumberOfCheckIns = object.totalNumberOfCheckIns;
                        else if (typeof object.totalNumberOfCheckIns === "object")
                            message.totalNumberOfCheckIns = new $util.LongBits(object.totalNumberOfCheckIns.low >>> 0, object.totalNumberOfCheckIns.high >>> 0).toNumber();
                    if (object.totalNumberOfPaidOrders != null)
                        if ($util.Long)
                            (message.totalNumberOfPaidOrders = $util.Long.fromValue(object.totalNumberOfPaidOrders)).unsigned = false;
                        else if (typeof object.totalNumberOfPaidOrders === "string")
                            message.totalNumberOfPaidOrders = parseInt(object.totalNumberOfPaidOrders, 10);
                        else if (typeof object.totalNumberOfPaidOrders === "number")
                            message.totalNumberOfPaidOrders = object.totalNumberOfPaidOrders;
                        else if (typeof object.totalNumberOfPaidOrders === "object")
                            message.totalNumberOfPaidOrders = new $util.LongBits(object.totalNumberOfPaidOrders.low >>> 0, object.totalNumberOfPaidOrders.high >>> 0).toNumber();
                    if (object.totalNumberOfThumbsUp != null)
                        if ($util.Long)
                            (message.totalNumberOfThumbsUp = $util.Long.fromValue(object.totalNumberOfThumbsUp)).unsigned = false;
                        else if (typeof object.totalNumberOfThumbsUp === "string")
                            message.totalNumberOfThumbsUp = parseInt(object.totalNumberOfThumbsUp, 10);
                        else if (typeof object.totalNumberOfThumbsUp === "number")
                            message.totalNumberOfThumbsUp = object.totalNumberOfThumbsUp;
                        else if (typeof object.totalNumberOfThumbsUp === "object")
                            message.totalNumberOfThumbsUp = new $util.LongBits(object.totalNumberOfThumbsUp.low >>> 0, object.totalNumberOfThumbsUp.high >>> 0).toNumber();
                    if (object.totalNumberOfThumbsDown != null)
                        if ($util.Long)
                            (message.totalNumberOfThumbsDown = $util.Long.fromValue(object.totalNumberOfThumbsDown)).unsigned = false;
                        else if (typeof object.totalNumberOfThumbsDown === "string")
                            message.totalNumberOfThumbsDown = parseInt(object.totalNumberOfThumbsDown, 10);
                        else if (typeof object.totalNumberOfThumbsDown === "number")
                            message.totalNumberOfThumbsDown = object.totalNumberOfThumbsDown;
                        else if (typeof object.totalNumberOfThumbsDown === "object")
                            message.totalNumberOfThumbsDown = new $util.LongBits(object.totalNumberOfThumbsDown.low >>> 0, object.totalNumberOfThumbsDown.high >>> 0).toNumber();
                    if (object.totalNumberOfFiveStarsRatings != null)
                        if ($util.Long)
                            (message.totalNumberOfFiveStarsRatings = $util.Long.fromValue(object.totalNumberOfFiveStarsRatings)).unsigned = false;
                        else if (typeof object.totalNumberOfFiveStarsRatings === "string")
                            message.totalNumberOfFiveStarsRatings = parseInt(object.totalNumberOfFiveStarsRatings, 10);
                        else if (typeof object.totalNumberOfFiveStarsRatings === "number")
                            message.totalNumberOfFiveStarsRatings = object.totalNumberOfFiveStarsRatings;
                        else if (typeof object.totalNumberOfFiveStarsRatings === "object")
                            message.totalNumberOfFiveStarsRatings = new $util.LongBits(object.totalNumberOfFiveStarsRatings.low >>> 0, object.totalNumberOfFiveStarsRatings.high >>> 0).toNumber();
                    if (object.totalNumberOfFourStarsRatings != null)
                        if ($util.Long)
                            (message.totalNumberOfFourStarsRatings = $util.Long.fromValue(object.totalNumberOfFourStarsRatings)).unsigned = false;
                        else if (typeof object.totalNumberOfFourStarsRatings === "string")
                            message.totalNumberOfFourStarsRatings = parseInt(object.totalNumberOfFourStarsRatings, 10);
                        else if (typeof object.totalNumberOfFourStarsRatings === "number")
                            message.totalNumberOfFourStarsRatings = object.totalNumberOfFourStarsRatings;
                        else if (typeof object.totalNumberOfFourStarsRatings === "object")
                            message.totalNumberOfFourStarsRatings = new $util.LongBits(object.totalNumberOfFourStarsRatings.low >>> 0, object.totalNumberOfFourStarsRatings.high >>> 0).toNumber();
                    if (object.totalNumberOfThreeStarsRatings != null)
                        if ($util.Long)
                            (message.totalNumberOfThreeStarsRatings = $util.Long.fromValue(object.totalNumberOfThreeStarsRatings)).unsigned = false;
                        else if (typeof object.totalNumberOfThreeStarsRatings === "string")
                            message.totalNumberOfThreeStarsRatings = parseInt(object.totalNumberOfThreeStarsRatings, 10);
                        else if (typeof object.totalNumberOfThreeStarsRatings === "number")
                            message.totalNumberOfThreeStarsRatings = object.totalNumberOfThreeStarsRatings;
                        else if (typeof object.totalNumberOfThreeStarsRatings === "object")
                            message.totalNumberOfThreeStarsRatings = new $util.LongBits(object.totalNumberOfThreeStarsRatings.low >>> 0, object.totalNumberOfThreeStarsRatings.high >>> 0).toNumber();
                    if (object.totalNumberOfTwoStarsRatings != null)
                        if ($util.Long)
                            (message.totalNumberOfTwoStarsRatings = $util.Long.fromValue(object.totalNumberOfTwoStarsRatings)).unsigned = false;
                        else if (typeof object.totalNumberOfTwoStarsRatings === "string")
                            message.totalNumberOfTwoStarsRatings = parseInt(object.totalNumberOfTwoStarsRatings, 10);
                        else if (typeof object.totalNumberOfTwoStarsRatings === "number")
                            message.totalNumberOfTwoStarsRatings = object.totalNumberOfTwoStarsRatings;
                        else if (typeof object.totalNumberOfTwoStarsRatings === "object")
                            message.totalNumberOfTwoStarsRatings = new $util.LongBits(object.totalNumberOfTwoStarsRatings.low >>> 0, object.totalNumberOfTwoStarsRatings.high >>> 0).toNumber();
                    if (object.totalNumberOfOneStarRatings != null)
                        if ($util.Long)
                            (message.totalNumberOfOneStarRatings = $util.Long.fromValue(object.totalNumberOfOneStarRatings)).unsigned = false;
                        else if (typeof object.totalNumberOfOneStarRatings === "string")
                            message.totalNumberOfOneStarRatings = parseInt(object.totalNumberOfOneStarRatings, 10);
                        else if (typeof object.totalNumberOfOneStarRatings === "number")
                            message.totalNumberOfOneStarRatings = object.totalNumberOfOneStarRatings;
                        else if (typeof object.totalNumberOfOneStarRatings === "object")
                            message.totalNumberOfOneStarRatings = new $util.LongBits(object.totalNumberOfOneStarRatings.low >>> 0, object.totalNumberOfOneStarRatings.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a RedundantDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.AnalyticsProto.RedundantDataProto} message RedundantDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedundantDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfRequests = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfRequests = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfCheckIns = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfCheckIns = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfPaidOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfPaidOrders = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfThumbsUp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfThumbsUp = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfThumbsDown = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfThumbsDown = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfFiveStarsRatings = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfFiveStarsRatings = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfFourStarsRatings = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfFourStarsRatings = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfThreeStarsRatings = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfThreeStarsRatings = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfTwoStarsRatings = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfTwoStarsRatings = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.totalNumberOfOneStarRatings = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.totalNumberOfOneStarRatings = options.longs === String ? "0" : 0;
                    }
                    if (message.totalNumberOfRequests != null && message.hasOwnProperty("totalNumberOfRequests"))
                        if (typeof message.totalNumberOfRequests === "number")
                            object.totalNumberOfRequests = options.longs === String ? String(message.totalNumberOfRequests) : message.totalNumberOfRequests;
                        else
                            object.totalNumberOfRequests = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfRequests) : options.longs === Number ? new $util.LongBits(message.totalNumberOfRequests.low >>> 0, message.totalNumberOfRequests.high >>> 0).toNumber() : message.totalNumberOfRequests;
                    if (message.totalNumberOfCheckIns != null && message.hasOwnProperty("totalNumberOfCheckIns"))
                        if (typeof message.totalNumberOfCheckIns === "number")
                            object.totalNumberOfCheckIns = options.longs === String ? String(message.totalNumberOfCheckIns) : message.totalNumberOfCheckIns;
                        else
                            object.totalNumberOfCheckIns = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfCheckIns) : options.longs === Number ? new $util.LongBits(message.totalNumberOfCheckIns.low >>> 0, message.totalNumberOfCheckIns.high >>> 0).toNumber() : message.totalNumberOfCheckIns;
                    if (message.totalNumberOfPaidOrders != null && message.hasOwnProperty("totalNumberOfPaidOrders"))
                        if (typeof message.totalNumberOfPaidOrders === "number")
                            object.totalNumberOfPaidOrders = options.longs === String ? String(message.totalNumberOfPaidOrders) : message.totalNumberOfPaidOrders;
                        else
                            object.totalNumberOfPaidOrders = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfPaidOrders) : options.longs === Number ? new $util.LongBits(message.totalNumberOfPaidOrders.low >>> 0, message.totalNumberOfPaidOrders.high >>> 0).toNumber() : message.totalNumberOfPaidOrders;
                    if (message.totalNumberOfThumbsUp != null && message.hasOwnProperty("totalNumberOfThumbsUp"))
                        if (typeof message.totalNumberOfThumbsUp === "number")
                            object.totalNumberOfThumbsUp = options.longs === String ? String(message.totalNumberOfThumbsUp) : message.totalNumberOfThumbsUp;
                        else
                            object.totalNumberOfThumbsUp = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfThumbsUp) : options.longs === Number ? new $util.LongBits(message.totalNumberOfThumbsUp.low >>> 0, message.totalNumberOfThumbsUp.high >>> 0).toNumber() : message.totalNumberOfThumbsUp;
                    if (message.totalNumberOfThumbsDown != null && message.hasOwnProperty("totalNumberOfThumbsDown"))
                        if (typeof message.totalNumberOfThumbsDown === "number")
                            object.totalNumberOfThumbsDown = options.longs === String ? String(message.totalNumberOfThumbsDown) : message.totalNumberOfThumbsDown;
                        else
                            object.totalNumberOfThumbsDown = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfThumbsDown) : options.longs === Number ? new $util.LongBits(message.totalNumberOfThumbsDown.low >>> 0, message.totalNumberOfThumbsDown.high >>> 0).toNumber() : message.totalNumberOfThumbsDown;
                    if (message.totalNumberOfFiveStarsRatings != null && message.hasOwnProperty("totalNumberOfFiveStarsRatings"))
                        if (typeof message.totalNumberOfFiveStarsRatings === "number")
                            object.totalNumberOfFiveStarsRatings = options.longs === String ? String(message.totalNumberOfFiveStarsRatings) : message.totalNumberOfFiveStarsRatings;
                        else
                            object.totalNumberOfFiveStarsRatings = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfFiveStarsRatings) : options.longs === Number ? new $util.LongBits(message.totalNumberOfFiveStarsRatings.low >>> 0, message.totalNumberOfFiveStarsRatings.high >>> 0).toNumber() : message.totalNumberOfFiveStarsRatings;
                    if (message.totalNumberOfFourStarsRatings != null && message.hasOwnProperty("totalNumberOfFourStarsRatings"))
                        if (typeof message.totalNumberOfFourStarsRatings === "number")
                            object.totalNumberOfFourStarsRatings = options.longs === String ? String(message.totalNumberOfFourStarsRatings) : message.totalNumberOfFourStarsRatings;
                        else
                            object.totalNumberOfFourStarsRatings = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfFourStarsRatings) : options.longs === Number ? new $util.LongBits(message.totalNumberOfFourStarsRatings.low >>> 0, message.totalNumberOfFourStarsRatings.high >>> 0).toNumber() : message.totalNumberOfFourStarsRatings;
                    if (message.totalNumberOfThreeStarsRatings != null && message.hasOwnProperty("totalNumberOfThreeStarsRatings"))
                        if (typeof message.totalNumberOfThreeStarsRatings === "number")
                            object.totalNumberOfThreeStarsRatings = options.longs === String ? String(message.totalNumberOfThreeStarsRatings) : message.totalNumberOfThreeStarsRatings;
                        else
                            object.totalNumberOfThreeStarsRatings = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfThreeStarsRatings) : options.longs === Number ? new $util.LongBits(message.totalNumberOfThreeStarsRatings.low >>> 0, message.totalNumberOfThreeStarsRatings.high >>> 0).toNumber() : message.totalNumberOfThreeStarsRatings;
                    if (message.totalNumberOfTwoStarsRatings != null && message.hasOwnProperty("totalNumberOfTwoStarsRatings"))
                        if (typeof message.totalNumberOfTwoStarsRatings === "number")
                            object.totalNumberOfTwoStarsRatings = options.longs === String ? String(message.totalNumberOfTwoStarsRatings) : message.totalNumberOfTwoStarsRatings;
                        else
                            object.totalNumberOfTwoStarsRatings = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfTwoStarsRatings) : options.longs === Number ? new $util.LongBits(message.totalNumberOfTwoStarsRatings.low >>> 0, message.totalNumberOfTwoStarsRatings.high >>> 0).toNumber() : message.totalNumberOfTwoStarsRatings;
                    if (message.totalNumberOfOneStarRatings != null && message.hasOwnProperty("totalNumberOfOneStarRatings"))
                        if (typeof message.totalNumberOfOneStarRatings === "number")
                            object.totalNumberOfOneStarRatings = options.longs === String ? String(message.totalNumberOfOneStarRatings) : message.totalNumberOfOneStarRatings;
                        else
                            object.totalNumberOfOneStarRatings = options.longs === String ? $util.Long.prototype.toString.call(message.totalNumberOfOneStarRatings) : options.longs === Number ? new $util.LongBits(message.totalNumberOfOneStarRatings.low >>> 0, message.totalNumberOfOneStarRatings.high >>> 0).toNumber() : message.totalNumberOfOneStarRatings;
                    return object;
                };

                /**
                 * Converts this RedundantDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedundantDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RedundantDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.AnalyticsProto.RedundantDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedundantDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AnalyticsProto.RedundantDataProto";
                };

                return RedundantDataProto;
            })();

            return AnalyticsProto;
        })();

        /**
         * Month enum.
         * @name waiternow.common.Month
         * @enum {number}
         * @property {number} MONTH_UNSPECIFIED=0 MONTH_UNSPECIFIED value
         * @property {number} JANUARY=1 JANUARY value
         * @property {number} FEBRUARY=2 FEBRUARY value
         * @property {number} MARCH=3 MARCH value
         * @property {number} APRIL=4 APRIL value
         * @property {number} MAY=5 MAY value
         * @property {number} JUNE=6 JUNE value
         * @property {number} JULY=7 JULY value
         * @property {number} AUGUST=8 AUGUST value
         * @property {number} SEPTEMBER=9 SEPTEMBER value
         * @property {number} OCTOBER=10 OCTOBER value
         * @property {number} NOVEMBER=11 NOVEMBER value
         * @property {number} DECEMBER=12 DECEMBER value
         */
        common.Month = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "MONTH_UNSPECIFIED"] = 0;
            values[valuesById[1] = "JANUARY"] = 1;
            values[valuesById[2] = "FEBRUARY"] = 2;
            values[valuesById[3] = "MARCH"] = 3;
            values[valuesById[4] = "APRIL"] = 4;
            values[valuesById[5] = "MAY"] = 5;
            values[valuesById[6] = "JUNE"] = 6;
            values[valuesById[7] = "JULY"] = 7;
            values[valuesById[8] = "AUGUST"] = 8;
            values[valuesById[9] = "SEPTEMBER"] = 9;
            values[valuesById[10] = "OCTOBER"] = 10;
            values[valuesById[11] = "NOVEMBER"] = 11;
            values[valuesById[12] = "DECEMBER"] = 12;
            return values;
        })();

        /**
         * DayOfWeek enum.
         * @name waiternow.common.DayOfWeek
         * @enum {number}
         * @property {number} DAY_OF_WEEK_UNSPECIFIED=0 DAY_OF_WEEK_UNSPECIFIED value
         * @property {number} SUNDAY=1 SUNDAY value
         * @property {number} MONDAY=2 MONDAY value
         * @property {number} TUESDAY=3 TUESDAY value
         * @property {number} WEDNESDAY=4 WEDNESDAY value
         * @property {number} THURSDAY=5 THURSDAY value
         * @property {number} FRIDAY=6 FRIDAY value
         * @property {number} SATURDAY=7 SATURDAY value
         */
        common.DayOfWeek = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DAY_OF_WEEK_UNSPECIFIED"] = 0;
            values[valuesById[1] = "SUNDAY"] = 1;
            values[valuesById[2] = "MONDAY"] = 2;
            values[valuesById[3] = "TUESDAY"] = 3;
            values[valuesById[4] = "WEDNESDAY"] = 4;
            values[valuesById[5] = "THURSDAY"] = 5;
            values[valuesById[6] = "FRIDAY"] = 6;
            values[valuesById[7] = "SATURDAY"] = 7;
            return values;
        })();

        common.DateProto = (function() {

            /**
             * Properties of a DateProto.
             * @memberof waiternow.common
             * @interface IDateProto
             * @property {number|null} [year] DateProto year
             * @property {waiternow.common.Month|null} [month] DateProto month
             * @property {number|null} [day] DateProto day
             */

            /**
             * Constructs a new DateProto.
             * @memberof waiternow.common
             * @classdesc Represents a DateProto.
             * @implements IDateProto
             * @constructor
             * @param {waiternow.common.IDateProto=} [properties] Properties to set
             */
            function DateProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DateProto year.
             * @member {number} year
             * @memberof waiternow.common.DateProto
             * @instance
             */
            DateProto.prototype.year = 0;

            /**
             * DateProto month.
             * @member {waiternow.common.Month} month
             * @memberof waiternow.common.DateProto
             * @instance
             */
            DateProto.prototype.month = 0;

            /**
             * DateProto day.
             * @member {number} day
             * @memberof waiternow.common.DateProto
             * @instance
             */
            DateProto.prototype.day = 0;

            /**
             * Creates a new DateProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DateProto
             * @static
             * @param {waiternow.common.IDateProto=} [properties] Properties to set
             * @returns {waiternow.common.DateProto} DateProto instance
             */
            DateProto.create = function create(properties) {
                return new DateProto(properties);
            };

            /**
             * Encodes the specified DateProto message. Does not implicitly {@link waiternow.common.DateProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DateProto
             * @static
             * @param {waiternow.common.IDateProto} message DateProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.day);
                return writer;
            };

            /**
             * Encodes the specified DateProto message, length delimited. Does not implicitly {@link waiternow.common.DateProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DateProto
             * @static
             * @param {waiternow.common.IDateProto} message DateProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DateProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DateProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DateProto} DateProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DateProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.year = reader.int32();
                            break;
                        }
                    case 2: {
                            message.month = reader.int32();
                            break;
                        }
                    case 3: {
                            message.day = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DateProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DateProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DateProto} DateProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DateProto message.
             * @function verify
             * @memberof waiternow.common.DateProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DateProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.year != null && message.hasOwnProperty("year"))
                    if (!$util.isInteger(message.year))
                        return "year: integer expected";
                if (message.month != null && message.hasOwnProperty("month"))
                    switch (message.month) {
                    default:
                        return "month: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.day != null && message.hasOwnProperty("day"))
                    if (!$util.isInteger(message.day))
                        return "day: integer expected";
                return null;
            };

            /**
             * Creates a DateProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DateProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DateProto} DateProto
             */
            DateProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DateProto)
                    return object;
                var message = new $root.waiternow.common.DateProto();
                if (object.year != null)
                    message.year = object.year | 0;
                switch (object.month) {
                default:
                    if (typeof object.month === "number") {
                        message.month = object.month;
                        break;
                    }
                    break;
                case "MONTH_UNSPECIFIED":
                case 0:
                    message.month = 0;
                    break;
                case "JANUARY":
                case 1:
                    message.month = 1;
                    break;
                case "FEBRUARY":
                case 2:
                    message.month = 2;
                    break;
                case "MARCH":
                case 3:
                    message.month = 3;
                    break;
                case "APRIL":
                case 4:
                    message.month = 4;
                    break;
                case "MAY":
                case 5:
                    message.month = 5;
                    break;
                case "JUNE":
                case 6:
                    message.month = 6;
                    break;
                case "JULY":
                case 7:
                    message.month = 7;
                    break;
                case "AUGUST":
                case 8:
                    message.month = 8;
                    break;
                case "SEPTEMBER":
                case 9:
                    message.month = 9;
                    break;
                case "OCTOBER":
                case 10:
                    message.month = 10;
                    break;
                case "NOVEMBER":
                case 11:
                    message.month = 11;
                    break;
                case "DECEMBER":
                case 12:
                    message.month = 12;
                    break;
                }
                if (object.day != null)
                    message.day = object.day | 0;
                return message;
            };

            /**
             * Creates a plain object from a DateProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DateProto
             * @static
             * @param {waiternow.common.DateProto} message DateProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DateProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.year = 0;
                    object.month = options.enums === String ? "MONTH_UNSPECIFIED" : 0;
                    object.day = 0;
                }
                if (message.year != null && message.hasOwnProperty("year"))
                    object.year = message.year;
                if (message.month != null && message.hasOwnProperty("month"))
                    object.month = options.enums === String ? $root.waiternow.common.Month[message.month] === undefined ? message.month : $root.waiternow.common.Month[message.month] : message.month;
                if (message.day != null && message.hasOwnProperty("day"))
                    object.day = message.day;
                return object;
            };

            /**
             * Converts this DateProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DateProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DateProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DateProto
             * @function getTypeUrl
             * @memberof waiternow.common.DateProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DateProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DateProto";
            };

            return DateProto;
        })();

        common.TimeProto = (function() {

            /**
             * Properties of a TimeProto.
             * @memberof waiternow.common
             * @interface ITimeProto
             * @property {number|null} [hour] TimeProto hour
             * @property {number|null} [minute] TimeProto minute
             * @property {number|null} [second] TimeProto second
             * @property {number|null} [nano] TimeProto nano
             */

            /**
             * Constructs a new TimeProto.
             * @memberof waiternow.common
             * @classdesc Represents a TimeProto.
             * @implements ITimeProto
             * @constructor
             * @param {waiternow.common.ITimeProto=} [properties] Properties to set
             */
            function TimeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TimeProto hour.
             * @member {number} hour
             * @memberof waiternow.common.TimeProto
             * @instance
             */
            TimeProto.prototype.hour = 0;

            /**
             * TimeProto minute.
             * @member {number} minute
             * @memberof waiternow.common.TimeProto
             * @instance
             */
            TimeProto.prototype.minute = 0;

            /**
             * TimeProto second.
             * @member {number} second
             * @memberof waiternow.common.TimeProto
             * @instance
             */
            TimeProto.prototype.second = 0;

            /**
             * TimeProto nano.
             * @member {number} nano
             * @memberof waiternow.common.TimeProto
             * @instance
             */
            TimeProto.prototype.nano = 0;

            /**
             * Creates a new TimeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {waiternow.common.ITimeProto=} [properties] Properties to set
             * @returns {waiternow.common.TimeProto} TimeProto instance
             */
            TimeProto.create = function create(properties) {
                return new TimeProto(properties);
            };

            /**
             * Encodes the specified TimeProto message. Does not implicitly {@link waiternow.common.TimeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {waiternow.common.ITimeProto} message TimeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.hour != null && Object.hasOwnProperty.call(message, "hour"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.hour);
                if (message.minute != null && Object.hasOwnProperty.call(message, "minute"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.minute);
                if (message.second != null && Object.hasOwnProperty.call(message, "second"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.second);
                if (message.nano != null && Object.hasOwnProperty.call(message, "nano"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.nano);
                return writer;
            };

            /**
             * Encodes the specified TimeProto message, length delimited. Does not implicitly {@link waiternow.common.TimeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {waiternow.common.ITimeProto} message TimeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TimeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.TimeProto} TimeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TimeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.hour = reader.int32();
                            break;
                        }
                    case 2: {
                            message.minute = reader.int32();
                            break;
                        }
                    case 3: {
                            message.second = reader.int32();
                            break;
                        }
                    case 4: {
                            message.nano = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TimeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.TimeProto} TimeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TimeProto message.
             * @function verify
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TimeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.hour != null && message.hasOwnProperty("hour"))
                    if (!$util.isInteger(message.hour))
                        return "hour: integer expected";
                if (message.minute != null && message.hasOwnProperty("minute"))
                    if (!$util.isInteger(message.minute))
                        return "minute: integer expected";
                if (message.second != null && message.hasOwnProperty("second"))
                    if (!$util.isInteger(message.second))
                        return "second: integer expected";
                if (message.nano != null && message.hasOwnProperty("nano"))
                    if (!$util.isInteger(message.nano))
                        return "nano: integer expected";
                return null;
            };

            /**
             * Creates a TimeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.TimeProto} TimeProto
             */
            TimeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.TimeProto)
                    return object;
                var message = new $root.waiternow.common.TimeProto();
                if (object.hour != null)
                    message.hour = object.hour | 0;
                if (object.minute != null)
                    message.minute = object.minute | 0;
                if (object.second != null)
                    message.second = object.second | 0;
                if (object.nano != null)
                    message.nano = object.nano | 0;
                return message;
            };

            /**
             * Creates a plain object from a TimeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {waiternow.common.TimeProto} message TimeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.hour = 0;
                    object.minute = 0;
                    object.second = 0;
                    object.nano = 0;
                }
                if (message.hour != null && message.hasOwnProperty("hour"))
                    object.hour = message.hour;
                if (message.minute != null && message.hasOwnProperty("minute"))
                    object.minute = message.minute;
                if (message.second != null && message.hasOwnProperty("second"))
                    object.second = message.second;
                if (message.nano != null && message.hasOwnProperty("nano"))
                    object.nano = message.nano;
                return object;
            };

            /**
             * Converts this TimeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.TimeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TimeProto
             * @function getTypeUrl
             * @memberof waiternow.common.TimeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.TimeProto";
            };

            return TimeProto;
        })();

        common.DateTimeProto = (function() {

            /**
             * Properties of a DateTimeProto.
             * @memberof waiternow.common
             * @interface IDateTimeProto
             * @property {waiternow.common.IDateProto|null} [date] DateTimeProto date
             * @property {waiternow.common.ITimeProto|null} [time] DateTimeProto time
             */

            /**
             * Constructs a new DateTimeProto.
             * @memberof waiternow.common
             * @classdesc Represents a DateTimeProto.
             * @implements IDateTimeProto
             * @constructor
             * @param {waiternow.common.IDateTimeProto=} [properties] Properties to set
             */
            function DateTimeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DateTimeProto date.
             * @member {waiternow.common.IDateProto|null|undefined} date
             * @memberof waiternow.common.DateTimeProto
             * @instance
             */
            DateTimeProto.prototype.date = null;

            /**
             * DateTimeProto time.
             * @member {waiternow.common.ITimeProto|null|undefined} time
             * @memberof waiternow.common.DateTimeProto
             * @instance
             */
            DateTimeProto.prototype.time = null;

            /**
             * Creates a new DateTimeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {waiternow.common.IDateTimeProto=} [properties] Properties to set
             * @returns {waiternow.common.DateTimeProto} DateTimeProto instance
             */
            DateTimeProto.create = function create(properties) {
                return new DateTimeProto(properties);
            };

            /**
             * Encodes the specified DateTimeProto message. Does not implicitly {@link waiternow.common.DateTimeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {waiternow.common.IDateTimeProto} message DateTimeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateTimeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    $root.waiternow.common.DateProto.encode(message.date, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.time != null && Object.hasOwnProperty.call(message, "time"))
                    $root.waiternow.common.TimeProto.encode(message.time, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DateTimeProto message, length delimited. Does not implicitly {@link waiternow.common.DateTimeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {waiternow.common.IDateTimeProto} message DateTimeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateTimeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DateTimeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DateTimeProto} DateTimeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateTimeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DateTimeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.date = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.time = $root.waiternow.common.TimeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DateTimeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DateTimeProto} DateTimeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateTimeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DateTimeProto message.
             * @function verify
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DateTimeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.date != null && message.hasOwnProperty("date")) {
                    var error = $root.waiternow.common.DateProto.verify(message.date);
                    if (error)
                        return "date." + error;
                }
                if (message.time != null && message.hasOwnProperty("time")) {
                    var error = $root.waiternow.common.TimeProto.verify(message.time);
                    if (error)
                        return "time." + error;
                }
                return null;
            };

            /**
             * Creates a DateTimeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DateTimeProto} DateTimeProto
             */
            DateTimeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DateTimeProto)
                    return object;
                var message = new $root.waiternow.common.DateTimeProto();
                if (object.date != null) {
                    if (typeof object.date !== "object")
                        throw TypeError(".waiternow.common.DateTimeProto.date: object expected");
                    message.date = $root.waiternow.common.DateProto.fromObject(object.date);
                }
                if (object.time != null) {
                    if (typeof object.time !== "object")
                        throw TypeError(".waiternow.common.DateTimeProto.time: object expected");
                    message.time = $root.waiternow.common.TimeProto.fromObject(object.time);
                }
                return message;
            };

            /**
             * Creates a plain object from a DateTimeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {waiternow.common.DateTimeProto} message DateTimeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DateTimeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.date = null;
                    object.time = null;
                }
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = $root.waiternow.common.DateProto.toObject(message.date, options);
                if (message.time != null && message.hasOwnProperty("time"))
                    object.time = $root.waiternow.common.TimeProto.toObject(message.time, options);
                return object;
            };

            /**
             * Converts this DateTimeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DateTimeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DateTimeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DateTimeProto
             * @function getTypeUrl
             * @memberof waiternow.common.DateTimeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DateTimeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DateTimeProto";
            };

            return DateTimeProto;
        })();

        common.MonthRangeProto = (function() {

            /**
             * Properties of a MonthRangeProto.
             * @memberof waiternow.common
             * @interface IMonthRangeProto
             * @property {waiternow.common.Month|null} [from] MonthRangeProto from
             * @property {waiternow.common.Month|null} [to] MonthRangeProto to
             */

            /**
             * Constructs a new MonthRangeProto.
             * @memberof waiternow.common
             * @classdesc Represents a MonthRangeProto.
             * @implements IMonthRangeProto
             * @constructor
             * @param {waiternow.common.IMonthRangeProto=} [properties] Properties to set
             */
            function MonthRangeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MonthRangeProto from.
             * @member {waiternow.common.Month} from
             * @memberof waiternow.common.MonthRangeProto
             * @instance
             */
            MonthRangeProto.prototype.from = 0;

            /**
             * MonthRangeProto to.
             * @member {waiternow.common.Month} to
             * @memberof waiternow.common.MonthRangeProto
             * @instance
             */
            MonthRangeProto.prototype.to = 0;

            /**
             * Creates a new MonthRangeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {waiternow.common.IMonthRangeProto=} [properties] Properties to set
             * @returns {waiternow.common.MonthRangeProto} MonthRangeProto instance
             */
            MonthRangeProto.create = function create(properties) {
                return new MonthRangeProto(properties);
            };

            /**
             * Encodes the specified MonthRangeProto message. Does not implicitly {@link waiternow.common.MonthRangeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {waiternow.common.IMonthRangeProto} message MonthRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MonthRangeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.to);
                return writer;
            };

            /**
             * Encodes the specified MonthRangeProto message, length delimited. Does not implicitly {@link waiternow.common.MonthRangeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {waiternow.common.IMonthRangeProto} message MonthRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MonthRangeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MonthRangeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MonthRangeProto} MonthRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MonthRangeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MonthRangeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = reader.int32();
                            break;
                        }
                    case 2: {
                            message.to = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MonthRangeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MonthRangeProto} MonthRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MonthRangeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MonthRangeProto message.
             * @function verify
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MonthRangeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    switch (message.from) {
                    default:
                        return "from: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.to != null && message.hasOwnProperty("to"))
                    switch (message.to) {
                    default:
                        return "to: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                return null;
            };

            /**
             * Creates a MonthRangeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MonthRangeProto} MonthRangeProto
             */
            MonthRangeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MonthRangeProto)
                    return object;
                var message = new $root.waiternow.common.MonthRangeProto();
                switch (object.from) {
                default:
                    if (typeof object.from === "number") {
                        message.from = object.from;
                        break;
                    }
                    break;
                case "MONTH_UNSPECIFIED":
                case 0:
                    message.from = 0;
                    break;
                case "JANUARY":
                case 1:
                    message.from = 1;
                    break;
                case "FEBRUARY":
                case 2:
                    message.from = 2;
                    break;
                case "MARCH":
                case 3:
                    message.from = 3;
                    break;
                case "APRIL":
                case 4:
                    message.from = 4;
                    break;
                case "MAY":
                case 5:
                    message.from = 5;
                    break;
                case "JUNE":
                case 6:
                    message.from = 6;
                    break;
                case "JULY":
                case 7:
                    message.from = 7;
                    break;
                case "AUGUST":
                case 8:
                    message.from = 8;
                    break;
                case "SEPTEMBER":
                case 9:
                    message.from = 9;
                    break;
                case "OCTOBER":
                case 10:
                    message.from = 10;
                    break;
                case "NOVEMBER":
                case 11:
                    message.from = 11;
                    break;
                case "DECEMBER":
                case 12:
                    message.from = 12;
                    break;
                }
                switch (object.to) {
                default:
                    if (typeof object.to === "number") {
                        message.to = object.to;
                        break;
                    }
                    break;
                case "MONTH_UNSPECIFIED":
                case 0:
                    message.to = 0;
                    break;
                case "JANUARY":
                case 1:
                    message.to = 1;
                    break;
                case "FEBRUARY":
                case 2:
                    message.to = 2;
                    break;
                case "MARCH":
                case 3:
                    message.to = 3;
                    break;
                case "APRIL":
                case 4:
                    message.to = 4;
                    break;
                case "MAY":
                case 5:
                    message.to = 5;
                    break;
                case "JUNE":
                case 6:
                    message.to = 6;
                    break;
                case "JULY":
                case 7:
                    message.to = 7;
                    break;
                case "AUGUST":
                case 8:
                    message.to = 8;
                    break;
                case "SEPTEMBER":
                case 9:
                    message.to = 9;
                    break;
                case "OCTOBER":
                case 10:
                    message.to = 10;
                    break;
                case "NOVEMBER":
                case 11:
                    message.to = 11;
                    break;
                case "DECEMBER":
                case 12:
                    message.to = 12;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a MonthRangeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {waiternow.common.MonthRangeProto} message MonthRangeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MonthRangeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.from = options.enums === String ? "MONTH_UNSPECIFIED" : 0;
                    object.to = options.enums === String ? "MONTH_UNSPECIFIED" : 0;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = options.enums === String ? $root.waiternow.common.Month[message.from] === undefined ? message.from : $root.waiternow.common.Month[message.from] : message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = options.enums === String ? $root.waiternow.common.Month[message.to] === undefined ? message.to : $root.waiternow.common.Month[message.to] : message.to;
                return object;
            };

            /**
             * Converts this MonthRangeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MonthRangeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MonthRangeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MonthRangeProto
             * @function getTypeUrl
             * @memberof waiternow.common.MonthRangeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MonthRangeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MonthRangeProto";
            };

            return MonthRangeProto;
        })();

        common.DateRangeProto = (function() {

            /**
             * Properties of a DateRangeProto.
             * @memberof waiternow.common
             * @interface IDateRangeProto
             * @property {waiternow.common.IDateProto|null} [from] DateRangeProto from
             * @property {waiternow.common.IDateProto|null} [to] DateRangeProto to
             */

            /**
             * Constructs a new DateRangeProto.
             * @memberof waiternow.common
             * @classdesc Represents a DateRangeProto.
             * @implements IDateRangeProto
             * @constructor
             * @param {waiternow.common.IDateRangeProto=} [properties] Properties to set
             */
            function DateRangeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DateRangeProto from.
             * @member {waiternow.common.IDateProto|null|undefined} from
             * @memberof waiternow.common.DateRangeProto
             * @instance
             */
            DateRangeProto.prototype.from = null;

            /**
             * DateRangeProto to.
             * @member {waiternow.common.IDateProto|null|undefined} to
             * @memberof waiternow.common.DateRangeProto
             * @instance
             */
            DateRangeProto.prototype.to = null;

            /**
             * Creates a new DateRangeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {waiternow.common.IDateRangeProto=} [properties] Properties to set
             * @returns {waiternow.common.DateRangeProto} DateRangeProto instance
             */
            DateRangeProto.create = function create(properties) {
                return new DateRangeProto(properties);
            };

            /**
             * Encodes the specified DateRangeProto message. Does not implicitly {@link waiternow.common.DateRangeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {waiternow.common.IDateRangeProto} message DateRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateRangeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    $root.waiternow.common.DateProto.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    $root.waiternow.common.DateProto.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DateRangeProto message, length delimited. Does not implicitly {@link waiternow.common.DateRangeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {waiternow.common.IDateRangeProto} message DateRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateRangeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DateRangeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DateRangeProto} DateRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateRangeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DateRangeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.to = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DateRangeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DateRangeProto} DateRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateRangeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DateRangeProto message.
             * @function verify
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DateRangeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from")) {
                    var error = $root.waiternow.common.DateProto.verify(message.from);
                    if (error)
                        return "from." + error;
                }
                if (message.to != null && message.hasOwnProperty("to")) {
                    var error = $root.waiternow.common.DateProto.verify(message.to);
                    if (error)
                        return "to." + error;
                }
                return null;
            };

            /**
             * Creates a DateRangeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DateRangeProto} DateRangeProto
             */
            DateRangeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DateRangeProto)
                    return object;
                var message = new $root.waiternow.common.DateRangeProto();
                if (object.from != null) {
                    if (typeof object.from !== "object")
                        throw TypeError(".waiternow.common.DateRangeProto.from: object expected");
                    message.from = $root.waiternow.common.DateProto.fromObject(object.from);
                }
                if (object.to != null) {
                    if (typeof object.to !== "object")
                        throw TypeError(".waiternow.common.DateRangeProto.to: object expected");
                    message.to = $root.waiternow.common.DateProto.fromObject(object.to);
                }
                return message;
            };

            /**
             * Creates a plain object from a DateRangeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {waiternow.common.DateRangeProto} message DateRangeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DateRangeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.from = null;
                    object.to = null;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = $root.waiternow.common.DateProto.toObject(message.from, options);
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = $root.waiternow.common.DateProto.toObject(message.to, options);
                return object;
            };

            /**
             * Converts this DateRangeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DateRangeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DateRangeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DateRangeProto
             * @function getTypeUrl
             * @memberof waiternow.common.DateRangeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DateRangeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DateRangeProto";
            };

            return DateRangeProto;
        })();

        common.TimeRangeProto = (function() {

            /**
             * Properties of a TimeRangeProto.
             * @memberof waiternow.common
             * @interface ITimeRangeProto
             * @property {waiternow.common.ITimeProto|null} [from] TimeRangeProto from
             * @property {waiternow.common.ITimeProto|null} [to] TimeRangeProto to
             */

            /**
             * Constructs a new TimeRangeProto.
             * @memberof waiternow.common
             * @classdesc Represents a TimeRangeProto.
             * @implements ITimeRangeProto
             * @constructor
             * @param {waiternow.common.ITimeRangeProto=} [properties] Properties to set
             */
            function TimeRangeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TimeRangeProto from.
             * @member {waiternow.common.ITimeProto|null|undefined} from
             * @memberof waiternow.common.TimeRangeProto
             * @instance
             */
            TimeRangeProto.prototype.from = null;

            /**
             * TimeRangeProto to.
             * @member {waiternow.common.ITimeProto|null|undefined} to
             * @memberof waiternow.common.TimeRangeProto
             * @instance
             */
            TimeRangeProto.prototype.to = null;

            /**
             * Creates a new TimeRangeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {waiternow.common.ITimeRangeProto=} [properties] Properties to set
             * @returns {waiternow.common.TimeRangeProto} TimeRangeProto instance
             */
            TimeRangeProto.create = function create(properties) {
                return new TimeRangeProto(properties);
            };

            /**
             * Encodes the specified TimeRangeProto message. Does not implicitly {@link waiternow.common.TimeRangeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {waiternow.common.ITimeRangeProto} message TimeRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeRangeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    $root.waiternow.common.TimeProto.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    $root.waiternow.common.TimeProto.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TimeRangeProto message, length delimited. Does not implicitly {@link waiternow.common.TimeRangeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {waiternow.common.ITimeRangeProto} message TimeRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimeRangeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TimeRangeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.TimeRangeProto} TimeRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeRangeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TimeRangeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = $root.waiternow.common.TimeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.to = $root.waiternow.common.TimeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TimeRangeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.TimeRangeProto} TimeRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimeRangeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TimeRangeProto message.
             * @function verify
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TimeRangeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from")) {
                    var error = $root.waiternow.common.TimeProto.verify(message.from);
                    if (error)
                        return "from." + error;
                }
                if (message.to != null && message.hasOwnProperty("to")) {
                    var error = $root.waiternow.common.TimeProto.verify(message.to);
                    if (error)
                        return "to." + error;
                }
                return null;
            };

            /**
             * Creates a TimeRangeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.TimeRangeProto} TimeRangeProto
             */
            TimeRangeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.TimeRangeProto)
                    return object;
                var message = new $root.waiternow.common.TimeRangeProto();
                if (object.from != null) {
                    if (typeof object.from !== "object")
                        throw TypeError(".waiternow.common.TimeRangeProto.from: object expected");
                    message.from = $root.waiternow.common.TimeProto.fromObject(object.from);
                }
                if (object.to != null) {
                    if (typeof object.to !== "object")
                        throw TypeError(".waiternow.common.TimeRangeProto.to: object expected");
                    message.to = $root.waiternow.common.TimeProto.fromObject(object.to);
                }
                return message;
            };

            /**
             * Creates a plain object from a TimeRangeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {waiternow.common.TimeRangeProto} message TimeRangeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimeRangeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.from = null;
                    object.to = null;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = $root.waiternow.common.TimeProto.toObject(message.from, options);
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = $root.waiternow.common.TimeProto.toObject(message.to, options);
                return object;
            };

            /**
             * Converts this TimeRangeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.TimeRangeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimeRangeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TimeRangeProto
             * @function getTypeUrl
             * @memberof waiternow.common.TimeRangeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TimeRangeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.TimeRangeProto";
            };

            return TimeRangeProto;
        })();

        common.DateTimeRangeProto = (function() {

            /**
             * Properties of a DateTimeRangeProto.
             * @memberof waiternow.common
             * @interface IDateTimeRangeProto
             * @property {waiternow.common.IDateTimeProto|null} [from] DateTimeRangeProto from
             * @property {waiternow.common.IDateTimeProto|null} [to] DateTimeRangeProto to
             */

            /**
             * Constructs a new DateTimeRangeProto.
             * @memberof waiternow.common
             * @classdesc Represents a DateTimeRangeProto.
             * @implements IDateTimeRangeProto
             * @constructor
             * @param {waiternow.common.IDateTimeRangeProto=} [properties] Properties to set
             */
            function DateTimeRangeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DateTimeRangeProto from.
             * @member {waiternow.common.IDateTimeProto|null|undefined} from
             * @memberof waiternow.common.DateTimeRangeProto
             * @instance
             */
            DateTimeRangeProto.prototype.from = null;

            /**
             * DateTimeRangeProto to.
             * @member {waiternow.common.IDateTimeProto|null|undefined} to
             * @memberof waiternow.common.DateTimeRangeProto
             * @instance
             */
            DateTimeRangeProto.prototype.to = null;

            /**
             * Creates a new DateTimeRangeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {waiternow.common.IDateTimeRangeProto=} [properties] Properties to set
             * @returns {waiternow.common.DateTimeRangeProto} DateTimeRangeProto instance
             */
            DateTimeRangeProto.create = function create(properties) {
                return new DateTimeRangeProto(properties);
            };

            /**
             * Encodes the specified DateTimeRangeProto message. Does not implicitly {@link waiternow.common.DateTimeRangeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {waiternow.common.IDateTimeRangeProto} message DateTimeRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateTimeRangeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    $root.waiternow.common.DateTimeProto.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    $root.waiternow.common.DateTimeProto.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DateTimeRangeProto message, length delimited. Does not implicitly {@link waiternow.common.DateTimeRangeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {waiternow.common.IDateTimeRangeProto} message DateTimeRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DateTimeRangeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DateTimeRangeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DateTimeRangeProto} DateTimeRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateTimeRangeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DateTimeRangeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = $root.waiternow.common.DateTimeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.to = $root.waiternow.common.DateTimeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DateTimeRangeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DateTimeRangeProto} DateTimeRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DateTimeRangeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DateTimeRangeProto message.
             * @function verify
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DateTimeRangeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from")) {
                    var error = $root.waiternow.common.DateTimeProto.verify(message.from);
                    if (error)
                        return "from." + error;
                }
                if (message.to != null && message.hasOwnProperty("to")) {
                    var error = $root.waiternow.common.DateTimeProto.verify(message.to);
                    if (error)
                        return "to." + error;
                }
                return null;
            };

            /**
             * Creates a DateTimeRangeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DateTimeRangeProto} DateTimeRangeProto
             */
            DateTimeRangeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DateTimeRangeProto)
                    return object;
                var message = new $root.waiternow.common.DateTimeRangeProto();
                if (object.from != null) {
                    if (typeof object.from !== "object")
                        throw TypeError(".waiternow.common.DateTimeRangeProto.from: object expected");
                    message.from = $root.waiternow.common.DateTimeProto.fromObject(object.from);
                }
                if (object.to != null) {
                    if (typeof object.to !== "object")
                        throw TypeError(".waiternow.common.DateTimeRangeProto.to: object expected");
                    message.to = $root.waiternow.common.DateTimeProto.fromObject(object.to);
                }
                return message;
            };

            /**
             * Creates a plain object from a DateTimeRangeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {waiternow.common.DateTimeRangeProto} message DateTimeRangeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DateTimeRangeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.from = null;
                    object.to = null;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = $root.waiternow.common.DateTimeProto.toObject(message.from, options);
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = $root.waiternow.common.DateTimeProto.toObject(message.to, options);
                return object;
            };

            /**
             * Converts this DateTimeRangeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DateTimeRangeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DateTimeRangeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DateTimeRangeProto
             * @function getTypeUrl
             * @memberof waiternow.common.DateTimeRangeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DateTimeRangeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DateTimeRangeProto";
            };

            return DateTimeRangeProto;
        })();

        common.TimestampRangeProto = (function() {

            /**
             * Properties of a TimestampRangeProto.
             * @memberof waiternow.common
             * @interface ITimestampRangeProto
             * @property {google.protobuf.ITimestamp|null} [from] TimestampRangeProto from
             * @property {google.protobuf.ITimestamp|null} [to] TimestampRangeProto to
             */

            /**
             * Constructs a new TimestampRangeProto.
             * @memberof waiternow.common
             * @classdesc Represents a TimestampRangeProto.
             * @implements ITimestampRangeProto
             * @constructor
             * @param {waiternow.common.ITimestampRangeProto=} [properties] Properties to set
             */
            function TimestampRangeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TimestampRangeProto from.
             * @member {google.protobuf.ITimestamp|null|undefined} from
             * @memberof waiternow.common.TimestampRangeProto
             * @instance
             */
            TimestampRangeProto.prototype.from = null;

            /**
             * TimestampRangeProto to.
             * @member {google.protobuf.ITimestamp|null|undefined} to
             * @memberof waiternow.common.TimestampRangeProto
             * @instance
             */
            TimestampRangeProto.prototype.to = null;

            /**
             * Creates a new TimestampRangeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {waiternow.common.ITimestampRangeProto=} [properties] Properties to set
             * @returns {waiternow.common.TimestampRangeProto} TimestampRangeProto instance
             */
            TimestampRangeProto.create = function create(properties) {
                return new TimestampRangeProto(properties);
            };

            /**
             * Encodes the specified TimestampRangeProto message. Does not implicitly {@link waiternow.common.TimestampRangeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {waiternow.common.ITimestampRangeProto} message TimestampRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimestampRangeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    $root.google.protobuf.Timestamp.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    $root.google.protobuf.Timestamp.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified TimestampRangeProto message, length delimited. Does not implicitly {@link waiternow.common.TimestampRangeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {waiternow.common.ITimestampRangeProto} message TimestampRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TimestampRangeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TimestampRangeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.TimestampRangeProto} TimestampRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimestampRangeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TimestampRangeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.to = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TimestampRangeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.TimestampRangeProto} TimestampRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TimestampRangeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TimestampRangeProto message.
             * @function verify
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TimestampRangeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.from);
                    if (error)
                        return "from." + error;
                }
                if (message.to != null && message.hasOwnProperty("to")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.to);
                    if (error)
                        return "to." + error;
                }
                return null;
            };

            /**
             * Creates a TimestampRangeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.TimestampRangeProto} TimestampRangeProto
             */
            TimestampRangeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.TimestampRangeProto)
                    return object;
                var message = new $root.waiternow.common.TimestampRangeProto();
                if (object.from != null) {
                    if (typeof object.from !== "object")
                        throw TypeError(".waiternow.common.TimestampRangeProto.from: object expected");
                    message.from = $root.google.protobuf.Timestamp.fromObject(object.from);
                }
                if (object.to != null) {
                    if (typeof object.to !== "object")
                        throw TypeError(".waiternow.common.TimestampRangeProto.to: object expected");
                    message.to = $root.google.protobuf.Timestamp.fromObject(object.to);
                }
                return message;
            };

            /**
             * Creates a plain object from a TimestampRangeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {waiternow.common.TimestampRangeProto} message TimestampRangeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TimestampRangeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.from = null;
                    object.to = null;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = $root.google.protobuf.Timestamp.toObject(message.from, options);
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = $root.google.protobuf.Timestamp.toObject(message.to, options);
                return object;
            };

            /**
             * Converts this TimestampRangeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.TimestampRangeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TimestampRangeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TimestampRangeProto
             * @function getTypeUrl
             * @memberof waiternow.common.TimestampRangeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TimestampRangeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.TimestampRangeProto";
            };

            return TimestampRangeProto;
        })();

        common.BusinessHoursProto = (function() {

            /**
             * Properties of a BusinessHoursProto.
             * @memberof waiternow.common
             * @interface IBusinessHoursProto
             * @property {waiternow.common.ITimeRangeProto|null} [sundayHours] BusinessHoursProto sundayHours
             * @property {waiternow.common.ITimeRangeProto|null} [mondayHours] BusinessHoursProto mondayHours
             * @property {waiternow.common.ITimeRangeProto|null} [tuesdayHours] BusinessHoursProto tuesdayHours
             * @property {waiternow.common.ITimeRangeProto|null} [wednesdayHours] BusinessHoursProto wednesdayHours
             * @property {waiternow.common.ITimeRangeProto|null} [thursdayHours] BusinessHoursProto thursdayHours
             * @property {waiternow.common.ITimeRangeProto|null} [fridayHours] BusinessHoursProto fridayHours
             * @property {waiternow.common.ITimeRangeProto|null} [saturdayHours] BusinessHoursProto saturdayHours
             */

            /**
             * Constructs a new BusinessHoursProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessHoursProto.
             * @implements IBusinessHoursProto
             * @constructor
             * @param {waiternow.common.IBusinessHoursProto=} [properties] Properties to set
             */
            function BusinessHoursProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessHoursProto sundayHours.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} sundayHours
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             */
            BusinessHoursProto.prototype.sundayHours = null;

            /**
             * BusinessHoursProto mondayHours.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} mondayHours
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             */
            BusinessHoursProto.prototype.mondayHours = null;

            /**
             * BusinessHoursProto tuesdayHours.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} tuesdayHours
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             */
            BusinessHoursProto.prototype.tuesdayHours = null;

            /**
             * BusinessHoursProto wednesdayHours.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} wednesdayHours
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             */
            BusinessHoursProto.prototype.wednesdayHours = null;

            /**
             * BusinessHoursProto thursdayHours.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} thursdayHours
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             */
            BusinessHoursProto.prototype.thursdayHours = null;

            /**
             * BusinessHoursProto fridayHours.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} fridayHours
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             */
            BusinessHoursProto.prototype.fridayHours = null;

            /**
             * BusinessHoursProto saturdayHours.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} saturdayHours
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             */
            BusinessHoursProto.prototype.saturdayHours = null;

            /**
             * Creates a new BusinessHoursProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {waiternow.common.IBusinessHoursProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessHoursProto} BusinessHoursProto instance
             */
            BusinessHoursProto.create = function create(properties) {
                return new BusinessHoursProto(properties);
            };

            /**
             * Encodes the specified BusinessHoursProto message. Does not implicitly {@link waiternow.common.BusinessHoursProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {waiternow.common.IBusinessHoursProto} message BusinessHoursProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessHoursProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.sundayHours != null && Object.hasOwnProperty.call(message, "sundayHours"))
                    $root.waiternow.common.TimeRangeProto.encode(message.sundayHours, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.mondayHours != null && Object.hasOwnProperty.call(message, "mondayHours"))
                    $root.waiternow.common.TimeRangeProto.encode(message.mondayHours, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.tuesdayHours != null && Object.hasOwnProperty.call(message, "tuesdayHours"))
                    $root.waiternow.common.TimeRangeProto.encode(message.tuesdayHours, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.wednesdayHours != null && Object.hasOwnProperty.call(message, "wednesdayHours"))
                    $root.waiternow.common.TimeRangeProto.encode(message.wednesdayHours, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.thursdayHours != null && Object.hasOwnProperty.call(message, "thursdayHours"))
                    $root.waiternow.common.TimeRangeProto.encode(message.thursdayHours, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.fridayHours != null && Object.hasOwnProperty.call(message, "fridayHours"))
                    $root.waiternow.common.TimeRangeProto.encode(message.fridayHours, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.saturdayHours != null && Object.hasOwnProperty.call(message, "saturdayHours"))
                    $root.waiternow.common.TimeRangeProto.encode(message.saturdayHours, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified BusinessHoursProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessHoursProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {waiternow.common.IBusinessHoursProto} message BusinessHoursProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessHoursProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessHoursProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessHoursProto} BusinessHoursProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessHoursProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessHoursProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.sundayHours = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.mondayHours = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.tuesdayHours = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.wednesdayHours = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.thursdayHours = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.fridayHours = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.saturdayHours = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessHoursProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessHoursProto} BusinessHoursProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessHoursProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessHoursProto message.
             * @function verify
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessHoursProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.sundayHours != null && message.hasOwnProperty("sundayHours")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.sundayHours);
                    if (error)
                        return "sundayHours." + error;
                }
                if (message.mondayHours != null && message.hasOwnProperty("mondayHours")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.mondayHours);
                    if (error)
                        return "mondayHours." + error;
                }
                if (message.tuesdayHours != null && message.hasOwnProperty("tuesdayHours")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.tuesdayHours);
                    if (error)
                        return "tuesdayHours." + error;
                }
                if (message.wednesdayHours != null && message.hasOwnProperty("wednesdayHours")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.wednesdayHours);
                    if (error)
                        return "wednesdayHours." + error;
                }
                if (message.thursdayHours != null && message.hasOwnProperty("thursdayHours")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.thursdayHours);
                    if (error)
                        return "thursdayHours." + error;
                }
                if (message.fridayHours != null && message.hasOwnProperty("fridayHours")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.fridayHours);
                    if (error)
                        return "fridayHours." + error;
                }
                if (message.saturdayHours != null && message.hasOwnProperty("saturdayHours")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.saturdayHours);
                    if (error)
                        return "saturdayHours." + error;
                }
                return null;
            };

            /**
             * Creates a BusinessHoursProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessHoursProto} BusinessHoursProto
             */
            BusinessHoursProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessHoursProto)
                    return object;
                var message = new $root.waiternow.common.BusinessHoursProto();
                if (object.sundayHours != null) {
                    if (typeof object.sundayHours !== "object")
                        throw TypeError(".waiternow.common.BusinessHoursProto.sundayHours: object expected");
                    message.sundayHours = $root.waiternow.common.TimeRangeProto.fromObject(object.sundayHours);
                }
                if (object.mondayHours != null) {
                    if (typeof object.mondayHours !== "object")
                        throw TypeError(".waiternow.common.BusinessHoursProto.mondayHours: object expected");
                    message.mondayHours = $root.waiternow.common.TimeRangeProto.fromObject(object.mondayHours);
                }
                if (object.tuesdayHours != null) {
                    if (typeof object.tuesdayHours !== "object")
                        throw TypeError(".waiternow.common.BusinessHoursProto.tuesdayHours: object expected");
                    message.tuesdayHours = $root.waiternow.common.TimeRangeProto.fromObject(object.tuesdayHours);
                }
                if (object.wednesdayHours != null) {
                    if (typeof object.wednesdayHours !== "object")
                        throw TypeError(".waiternow.common.BusinessHoursProto.wednesdayHours: object expected");
                    message.wednesdayHours = $root.waiternow.common.TimeRangeProto.fromObject(object.wednesdayHours);
                }
                if (object.thursdayHours != null) {
                    if (typeof object.thursdayHours !== "object")
                        throw TypeError(".waiternow.common.BusinessHoursProto.thursdayHours: object expected");
                    message.thursdayHours = $root.waiternow.common.TimeRangeProto.fromObject(object.thursdayHours);
                }
                if (object.fridayHours != null) {
                    if (typeof object.fridayHours !== "object")
                        throw TypeError(".waiternow.common.BusinessHoursProto.fridayHours: object expected");
                    message.fridayHours = $root.waiternow.common.TimeRangeProto.fromObject(object.fridayHours);
                }
                if (object.saturdayHours != null) {
                    if (typeof object.saturdayHours !== "object")
                        throw TypeError(".waiternow.common.BusinessHoursProto.saturdayHours: object expected");
                    message.saturdayHours = $root.waiternow.common.TimeRangeProto.fromObject(object.saturdayHours);
                }
                return message;
            };

            /**
             * Creates a plain object from a BusinessHoursProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {waiternow.common.BusinessHoursProto} message BusinessHoursProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessHoursProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.sundayHours = null;
                    object.mondayHours = null;
                    object.tuesdayHours = null;
                    object.wednesdayHours = null;
                    object.thursdayHours = null;
                    object.fridayHours = null;
                    object.saturdayHours = null;
                }
                if (message.sundayHours != null && message.hasOwnProperty("sundayHours"))
                    object.sundayHours = $root.waiternow.common.TimeRangeProto.toObject(message.sundayHours, options);
                if (message.mondayHours != null && message.hasOwnProperty("mondayHours"))
                    object.mondayHours = $root.waiternow.common.TimeRangeProto.toObject(message.mondayHours, options);
                if (message.tuesdayHours != null && message.hasOwnProperty("tuesdayHours"))
                    object.tuesdayHours = $root.waiternow.common.TimeRangeProto.toObject(message.tuesdayHours, options);
                if (message.wednesdayHours != null && message.hasOwnProperty("wednesdayHours"))
                    object.wednesdayHours = $root.waiternow.common.TimeRangeProto.toObject(message.wednesdayHours, options);
                if (message.thursdayHours != null && message.hasOwnProperty("thursdayHours"))
                    object.thursdayHours = $root.waiternow.common.TimeRangeProto.toObject(message.thursdayHours, options);
                if (message.fridayHours != null && message.hasOwnProperty("fridayHours"))
                    object.fridayHours = $root.waiternow.common.TimeRangeProto.toObject(message.fridayHours, options);
                if (message.saturdayHours != null && message.hasOwnProperty("saturdayHours"))
                    object.saturdayHours = $root.waiternow.common.TimeRangeProto.toObject(message.saturdayHours, options);
                return object;
            };

            /**
             * Converts this BusinessHoursProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessHoursProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessHoursProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessHoursProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessHoursProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessHoursProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessHoursProto";
            };

            return BusinessHoursProto;
        })();

        common.AuthUserProto = (function() {

            /**
             * Properties of an AuthUserProto.
             * @memberof waiternow.common
             * @interface IAuthUserProto
             * @property {string|null} [userId] AuthUserProto userId
             */

            /**
             * Constructs a new AuthUserProto.
             * @memberof waiternow.common
             * @classdesc Represents an AuthUserProto.
             * @implements IAuthUserProto
             * @constructor
             * @param {waiternow.common.IAuthUserProto=} [properties] Properties to set
             */
            function AuthUserProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AuthUserProto userId.
             * @member {string} userId
             * @memberof waiternow.common.AuthUserProto
             * @instance
             */
            AuthUserProto.prototype.userId = "";

            /**
             * Creates a new AuthUserProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {waiternow.common.IAuthUserProto=} [properties] Properties to set
             * @returns {waiternow.common.AuthUserProto} AuthUserProto instance
             */
            AuthUserProto.create = function create(properties) {
                return new AuthUserProto(properties);
            };

            /**
             * Encodes the specified AuthUserProto message. Does not implicitly {@link waiternow.common.AuthUserProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {waiternow.common.IAuthUserProto} message AuthUserProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthUserProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                return writer;
            };

            /**
             * Encodes the specified AuthUserProto message, length delimited. Does not implicitly {@link waiternow.common.AuthUserProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {waiternow.common.IAuthUserProto} message AuthUserProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AuthUserProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AuthUserProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AuthUserProto} AuthUserProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthUserProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AuthUserProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AuthUserProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AuthUserProto} AuthUserProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AuthUserProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AuthUserProto message.
             * @function verify
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AuthUserProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                return null;
            };

            /**
             * Creates an AuthUserProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AuthUserProto} AuthUserProto
             */
            AuthUserProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AuthUserProto)
                    return object;
                var message = new $root.waiternow.common.AuthUserProto();
                if (object.userId != null)
                    message.userId = String(object.userId);
                return message;
            };

            /**
             * Creates a plain object from an AuthUserProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {waiternow.common.AuthUserProto} message AuthUserProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AuthUserProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.userId = "";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                return object;
            };

            /**
             * Converts this AuthUserProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AuthUserProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AuthUserProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AuthUserProto
             * @function getTypeUrl
             * @memberof waiternow.common.AuthUserProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AuthUserProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AuthUserProto";
            };

            return AuthUserProto;
        })();

        common.BillProto = (function() {

            /**
             * Properties of a BillProto.
             * @memberof waiternow.common
             * @interface IBillProto
             * @property {string|null} [id] BillProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] BillProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] BillProto lastUpdateTime
             * @property {number|null} [billNumber] BillProto billNumber
             * @property {string|null} [businessId] BillProto businessId
             * @property {string|null} [businessName] BillProto businessName
             * @property {string|null} [businessOfficialId] BillProto businessOfficialId
             * @property {waiternow.common.IAddressProto|null} [billingAddress] BillProto billingAddress
             * @property {waiternow.common.IPeriodProto|null} [period] BillProto period
             * @property {Array.<waiternow.common.BillProto.ILocationBillProto>|null} [locationBills] BillProto locationBills
             * @property {waiternow.common.IMoneyProto|null} [subtotal] BillProto subtotal
             * @property {waiternow.common.BillProto.IDiscountProto|null} [discountTotal] BillProto discountTotal
             * @property {waiternow.common.ITaxesProto|null} [taxes] BillProto taxes
             * @property {waiternow.common.IMoneyProto|null} [total] BillProto total
             * @property {waiternow.common.BillProto.IStatusProto|null} [status] BillProto status
             */

            /**
             * Constructs a new BillProto.
             * @memberof waiternow.common
             * @classdesc Represents a BillProto.
             * @implements IBillProto
             * @constructor
             * @param {waiternow.common.IBillProto=} [properties] Properties to set
             */
            function BillProto(properties) {
                this.locationBills = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BillProto id.
             * @member {string} id
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.id = "";

            /**
             * BillProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.creationTime = null;

            /**
             * BillProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.lastUpdateTime = null;

            /**
             * BillProto billNumber.
             * @member {number} billNumber
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.billNumber = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * BillProto businessId.
             * @member {string} businessId
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.businessId = "";

            /**
             * BillProto businessName.
             * @member {string} businessName
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.businessName = "";

            /**
             * BillProto businessOfficialId.
             * @member {string} businessOfficialId
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.businessOfficialId = "";

            /**
             * BillProto billingAddress.
             * @member {waiternow.common.IAddressProto|null|undefined} billingAddress
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.billingAddress = null;

            /**
             * BillProto period.
             * @member {waiternow.common.IPeriodProto|null|undefined} period
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.period = null;

            /**
             * BillProto locationBills.
             * @member {Array.<waiternow.common.BillProto.ILocationBillProto>} locationBills
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.locationBills = $util.emptyArray;

            /**
             * BillProto subtotal.
             * @member {waiternow.common.IMoneyProto|null|undefined} subtotal
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.subtotal = null;

            /**
             * BillProto discountTotal.
             * @member {waiternow.common.BillProto.IDiscountProto|null|undefined} discountTotal
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.discountTotal = null;

            /**
             * BillProto taxes.
             * @member {waiternow.common.ITaxesProto|null|undefined} taxes
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.taxes = null;

            /**
             * BillProto total.
             * @member {waiternow.common.IMoneyProto|null|undefined} total
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.total = null;

            /**
             * BillProto status.
             * @member {waiternow.common.BillProto.IStatusProto|null|undefined} status
             * @memberof waiternow.common.BillProto
             * @instance
             */
            BillProto.prototype.status = null;

            /**
             * Creates a new BillProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BillProto
             * @static
             * @param {waiternow.common.IBillProto=} [properties] Properties to set
             * @returns {waiternow.common.BillProto} BillProto instance
             */
            BillProto.create = function create(properties) {
                return new BillProto(properties);
            };

            /**
             * Encodes the specified BillProto message. Does not implicitly {@link waiternow.common.BillProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BillProto
             * @static
             * @param {waiternow.common.IBillProto} message BillProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BillProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.billNumber != null && Object.hasOwnProperty.call(message, "billNumber"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.billNumber);
                if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessId);
                if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.businessName);
                if (message.businessOfficialId != null && Object.hasOwnProperty.call(message, "businessOfficialId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.businessOfficialId);
                if (message.billingAddress != null && Object.hasOwnProperty.call(message, "billingAddress"))
                    $root.waiternow.common.AddressProto.encode(message.billingAddress, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                    $root.waiternow.common.PeriodProto.encode(message.period, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.locationBills != null && message.locationBills.length)
                    for (var i = 0; i < message.locationBills.length; ++i)
                        $root.waiternow.common.BillProto.LocationBillProto.encode(message.locationBills[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.subtotal != null && Object.hasOwnProperty.call(message, "subtotal"))
                    $root.waiternow.common.MoneyProto.encode(message.subtotal, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.discountTotal != null && Object.hasOwnProperty.call(message, "discountTotal"))
                    $root.waiternow.common.BillProto.DiscountProto.encode(message.discountTotal, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                    $root.waiternow.common.TaxesProto.encode(message.taxes, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    $root.waiternow.common.MoneyProto.encode(message.total, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.waiternow.common.BillProto.StatusProto.encode(message.status, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified BillProto message, length delimited. Does not implicitly {@link waiternow.common.BillProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BillProto
             * @static
             * @param {waiternow.common.IBillProto} message BillProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BillProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BillProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BillProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BillProto} BillProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BillProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BillProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 14: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.billNumber = reader.int64();
                            break;
                        }
                    case 3: {
                            message.businessId = reader.string();
                            break;
                        }
                    case 4: {
                            message.businessName = reader.string();
                            break;
                        }
                    case 5: {
                            message.businessOfficialId = reader.string();
                            break;
                        }
                    case 6: {
                            message.billingAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.period = $root.waiternow.common.PeriodProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            if (!(message.locationBills && message.locationBills.length))
                                message.locationBills = [];
                            message.locationBills.push($root.waiternow.common.BillProto.LocationBillProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 9: {
                            message.subtotal = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.discountTotal = $root.waiternow.common.BillProto.DiscountProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.taxes = $root.waiternow.common.TaxesProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.total = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.status = $root.waiternow.common.BillProto.StatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BillProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BillProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BillProto} BillProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BillProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BillProto message.
             * @function verify
             * @memberof waiternow.common.BillProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BillProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.billNumber != null && message.hasOwnProperty("billNumber"))
                    if (!$util.isInteger(message.billNumber) && !(message.billNumber && $util.isInteger(message.billNumber.low) && $util.isInteger(message.billNumber.high)))
                        return "billNumber: integer|Long expected";
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    if (!$util.isString(message.businessId))
                        return "businessId: string expected";
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    if (!$util.isString(message.businessName))
                        return "businessName: string expected";
                if (message.businessOfficialId != null && message.hasOwnProperty("businessOfficialId"))
                    if (!$util.isString(message.businessOfficialId))
                        return "businessOfficialId: string expected";
                if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
                    var error = $root.waiternow.common.AddressProto.verify(message.billingAddress);
                    if (error)
                        return "billingAddress." + error;
                }
                if (message.period != null && message.hasOwnProperty("period")) {
                    var error = $root.waiternow.common.PeriodProto.verify(message.period);
                    if (error)
                        return "period." + error;
                }
                if (message.locationBills != null && message.hasOwnProperty("locationBills")) {
                    if (!Array.isArray(message.locationBills))
                        return "locationBills: array expected";
                    for (var i = 0; i < message.locationBills.length; ++i) {
                        var error = $root.waiternow.common.BillProto.LocationBillProto.verify(message.locationBills[i]);
                        if (error)
                            return "locationBills." + error;
                    }
                }
                if (message.subtotal != null && message.hasOwnProperty("subtotal")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.subtotal);
                    if (error)
                        return "subtotal." + error;
                }
                if (message.discountTotal != null && message.hasOwnProperty("discountTotal")) {
                    var error = $root.waiternow.common.BillProto.DiscountProto.verify(message.discountTotal);
                    if (error)
                        return "discountTotal." + error;
                }
                if (message.taxes != null && message.hasOwnProperty("taxes")) {
                    var error = $root.waiternow.common.TaxesProto.verify(message.taxes);
                    if (error)
                        return "taxes." + error;
                }
                if (message.total != null && message.hasOwnProperty("total")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.total);
                    if (error)
                        return "total." + error;
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    var error = $root.waiternow.common.BillProto.StatusProto.verify(message.status);
                    if (error)
                        return "status." + error;
                }
                return null;
            };

            /**
             * Creates a BillProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BillProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BillProto} BillProto
             */
            BillProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BillProto)
                    return object;
                var message = new $root.waiternow.common.BillProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.BillProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.BillProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.billNumber != null)
                    if ($util.Long)
                        (message.billNumber = $util.Long.fromValue(object.billNumber)).unsigned = false;
                    else if (typeof object.billNumber === "string")
                        message.billNumber = parseInt(object.billNumber, 10);
                    else if (typeof object.billNumber === "number")
                        message.billNumber = object.billNumber;
                    else if (typeof object.billNumber === "object")
                        message.billNumber = new $util.LongBits(object.billNumber.low >>> 0, object.billNumber.high >>> 0).toNumber();
                if (object.businessId != null)
                    message.businessId = String(object.businessId);
                if (object.businessName != null)
                    message.businessName = String(object.businessName);
                if (object.businessOfficialId != null)
                    message.businessOfficialId = String(object.businessOfficialId);
                if (object.billingAddress != null) {
                    if (typeof object.billingAddress !== "object")
                        throw TypeError(".waiternow.common.BillProto.billingAddress: object expected");
                    message.billingAddress = $root.waiternow.common.AddressProto.fromObject(object.billingAddress);
                }
                if (object.period != null) {
                    if (typeof object.period !== "object")
                        throw TypeError(".waiternow.common.BillProto.period: object expected");
                    message.period = $root.waiternow.common.PeriodProto.fromObject(object.period);
                }
                if (object.locationBills) {
                    if (!Array.isArray(object.locationBills))
                        throw TypeError(".waiternow.common.BillProto.locationBills: array expected");
                    message.locationBills = [];
                    for (var i = 0; i < object.locationBills.length; ++i) {
                        if (typeof object.locationBills[i] !== "object")
                            throw TypeError(".waiternow.common.BillProto.locationBills: object expected");
                        message.locationBills[i] = $root.waiternow.common.BillProto.LocationBillProto.fromObject(object.locationBills[i]);
                    }
                }
                if (object.subtotal != null) {
                    if (typeof object.subtotal !== "object")
                        throw TypeError(".waiternow.common.BillProto.subtotal: object expected");
                    message.subtotal = $root.waiternow.common.MoneyProto.fromObject(object.subtotal);
                }
                if (object.discountTotal != null) {
                    if (typeof object.discountTotal !== "object")
                        throw TypeError(".waiternow.common.BillProto.discountTotal: object expected");
                    message.discountTotal = $root.waiternow.common.BillProto.DiscountProto.fromObject(object.discountTotal);
                }
                if (object.taxes != null) {
                    if (typeof object.taxes !== "object")
                        throw TypeError(".waiternow.common.BillProto.taxes: object expected");
                    message.taxes = $root.waiternow.common.TaxesProto.fromObject(object.taxes);
                }
                if (object.total != null) {
                    if (typeof object.total !== "object")
                        throw TypeError(".waiternow.common.BillProto.total: object expected");
                    message.total = $root.waiternow.common.MoneyProto.fromObject(object.total);
                }
                if (object.status != null) {
                    if (typeof object.status !== "object")
                        throw TypeError(".waiternow.common.BillProto.status: object expected");
                    message.status = $root.waiternow.common.BillProto.StatusProto.fromObject(object.status);
                }
                return message;
            };

            /**
             * Creates a plain object from a BillProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BillProto
             * @static
             * @param {waiternow.common.BillProto} message BillProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BillProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.locationBills = [];
                if (options.defaults) {
                    object.id = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.billNumber = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.billNumber = options.longs === String ? "0" : 0;
                    object.businessId = "";
                    object.businessName = "";
                    object.businessOfficialId = "";
                    object.billingAddress = null;
                    object.period = null;
                    object.subtotal = null;
                    object.discountTotal = null;
                    object.taxes = null;
                    object.total = null;
                    object.status = null;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.billNumber != null && message.hasOwnProperty("billNumber"))
                    if (typeof message.billNumber === "number")
                        object.billNumber = options.longs === String ? String(message.billNumber) : message.billNumber;
                    else
                        object.billNumber = options.longs === String ? $util.Long.prototype.toString.call(message.billNumber) : options.longs === Number ? new $util.LongBits(message.billNumber.low >>> 0, message.billNumber.high >>> 0).toNumber() : message.billNumber;
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    object.businessId = message.businessId;
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    object.businessName = message.businessName;
                if (message.businessOfficialId != null && message.hasOwnProperty("businessOfficialId"))
                    object.businessOfficialId = message.businessOfficialId;
                if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                    object.billingAddress = $root.waiternow.common.AddressProto.toObject(message.billingAddress, options);
                if (message.period != null && message.hasOwnProperty("period"))
                    object.period = $root.waiternow.common.PeriodProto.toObject(message.period, options);
                if (message.locationBills && message.locationBills.length) {
                    object.locationBills = [];
                    for (var j = 0; j < message.locationBills.length; ++j)
                        object.locationBills[j] = $root.waiternow.common.BillProto.LocationBillProto.toObject(message.locationBills[j], options);
                }
                if (message.subtotal != null && message.hasOwnProperty("subtotal"))
                    object.subtotal = $root.waiternow.common.MoneyProto.toObject(message.subtotal, options);
                if (message.discountTotal != null && message.hasOwnProperty("discountTotal"))
                    object.discountTotal = $root.waiternow.common.BillProto.DiscountProto.toObject(message.discountTotal, options);
                if (message.taxes != null && message.hasOwnProperty("taxes"))
                    object.taxes = $root.waiternow.common.TaxesProto.toObject(message.taxes, options);
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = $root.waiternow.common.MoneyProto.toObject(message.total, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = $root.waiternow.common.BillProto.StatusProto.toObject(message.status, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                return object;
            };

            /**
             * Converts this BillProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BillProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BillProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BillProto
             * @function getTypeUrl
             * @memberof waiternow.common.BillProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BillProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BillProto";
            };

            BillProto.DiscountProto = (function() {

                /**
                 * Properties of a DiscountProto.
                 * @memberof waiternow.common.BillProto
                 * @interface IDiscountProto
                 * @property {waiternow.common.IMoneyProto|null} [amount] DiscountProto amount
                 * @property {string|null} [description] DiscountProto description
                 */

                /**
                 * Constructs a new DiscountProto.
                 * @memberof waiternow.common.BillProto
                 * @classdesc Represents a DiscountProto.
                 * @implements IDiscountProto
                 * @constructor
                 * @param {waiternow.common.BillProto.IDiscountProto=} [properties] Properties to set
                 */
                function DiscountProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DiscountProto amount.
                 * @member {waiternow.common.IMoneyProto|null|undefined} amount
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @instance
                 */
                DiscountProto.prototype.amount = null;

                /**
                 * DiscountProto description.
                 * @member {string} description
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @instance
                 */
                DiscountProto.prototype.description = "";

                /**
                 * Creates a new DiscountProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {waiternow.common.BillProto.IDiscountProto=} [properties] Properties to set
                 * @returns {waiternow.common.BillProto.DiscountProto} DiscountProto instance
                 */
                DiscountProto.create = function create(properties) {
                    return new DiscountProto(properties);
                };

                /**
                 * Encodes the specified DiscountProto message. Does not implicitly {@link waiternow.common.BillProto.DiscountProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {waiternow.common.BillProto.IDiscountProto} message DiscountProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DiscountProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                        $root.waiternow.common.MoneyProto.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                    return writer;
                };

                /**
                 * Encodes the specified DiscountProto message, length delimited. Does not implicitly {@link waiternow.common.BillProto.DiscountProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {waiternow.common.BillProto.IDiscountProto} message DiscountProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DiscountProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DiscountProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.BillProto.DiscountProto} DiscountProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DiscountProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BillProto.DiscountProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.amount = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.description = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DiscountProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.BillProto.DiscountProto} DiscountProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DiscountProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DiscountProto message.
                 * @function verify
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DiscountProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    return null;
                };

                /**
                 * Creates a DiscountProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.BillProto.DiscountProto} DiscountProto
                 */
                DiscountProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.BillProto.DiscountProto)
                        return object;
                    var message = new $root.waiternow.common.BillProto.DiscountProto();
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".waiternow.common.BillProto.DiscountProto.amount: object expected");
                        message.amount = $root.waiternow.common.MoneyProto.fromObject(object.amount);
                    }
                    if (object.description != null)
                        message.description = String(object.description);
                    return message;
                };

                /**
                 * Creates a plain object from a DiscountProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {waiternow.common.BillProto.DiscountProto} message DiscountProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DiscountProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.amount = null;
                        object.description = "";
                    }
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.waiternow.common.MoneyProto.toObject(message.amount, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    return object;
                };

                /**
                 * Converts this DiscountProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DiscountProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DiscountProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.BillProto.DiscountProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DiscountProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.BillProto.DiscountProto";
                };

                return DiscountProto;
            })();

            BillProto.LocationBillProto = (function() {

                /**
                 * Properties of a LocationBillProto.
                 * @memberof waiternow.common.BillProto
                 * @interface ILocationBillProto
                 * @property {string|null} [locationId] LocationBillProto locationId
                 * @property {string|null} [locationName] LocationBillProto locationName
                 * @property {waiternow.common.IAddressProto|null} [locationAddress] LocationBillProto locationAddress
                 * @property {waiternow.common.ITimestampRangeProto|null} [period] LocationBillProto period
                 * @property {number|null} [numberOfPointsOfService] LocationBillProto numberOfPointsOfService
                 * @property {number|null} [numberOfRequests] LocationBillProto numberOfRequests
                 * @property {waiternow.common.IMoneyProto|null} [charges] LocationBillProto charges
                 * @property {Array.<waiternow.common.BillProto.IDiscountProto>|null} [discounts] LocationBillProto discounts
                 */

                /**
                 * Constructs a new LocationBillProto.
                 * @memberof waiternow.common.BillProto
                 * @classdesc Represents a LocationBillProto.
                 * @implements ILocationBillProto
                 * @constructor
                 * @param {waiternow.common.BillProto.ILocationBillProto=} [properties] Properties to set
                 */
                function LocationBillProto(properties) {
                    this.discounts = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocationBillProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.locationId = "";

                /**
                 * LocationBillProto locationName.
                 * @member {string} locationName
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.locationName = "";

                /**
                 * LocationBillProto locationAddress.
                 * @member {waiternow.common.IAddressProto|null|undefined} locationAddress
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.locationAddress = null;

                /**
                 * LocationBillProto period.
                 * @member {waiternow.common.ITimestampRangeProto|null|undefined} period
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.period = null;

                /**
                 * LocationBillProto numberOfPointsOfService.
                 * @member {number} numberOfPointsOfService
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.numberOfPointsOfService = 0;

                /**
                 * LocationBillProto numberOfRequests.
                 * @member {number} numberOfRequests
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.numberOfRequests = 0;

                /**
                 * LocationBillProto charges.
                 * @member {waiternow.common.IMoneyProto|null|undefined} charges
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.charges = null;

                /**
                 * LocationBillProto discounts.
                 * @member {Array.<waiternow.common.BillProto.IDiscountProto>} discounts
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 */
                LocationBillProto.prototype.discounts = $util.emptyArray;

                /**
                 * Creates a new LocationBillProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {waiternow.common.BillProto.ILocationBillProto=} [properties] Properties to set
                 * @returns {waiternow.common.BillProto.LocationBillProto} LocationBillProto instance
                 */
                LocationBillProto.create = function create(properties) {
                    return new LocationBillProto(properties);
                };

                /**
                 * Encodes the specified LocationBillProto message. Does not implicitly {@link waiternow.common.BillProto.LocationBillProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {waiternow.common.BillProto.ILocationBillProto} message LocationBillProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationBillProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationName);
                    if (message.locationAddress != null && Object.hasOwnProperty.call(message, "locationAddress"))
                        $root.waiternow.common.AddressProto.encode(message.locationAddress, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                        $root.waiternow.common.TimestampRangeProto.encode(message.period, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.numberOfPointsOfService != null && Object.hasOwnProperty.call(message, "numberOfPointsOfService"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.numberOfPointsOfService);
                    if (message.numberOfRequests != null && Object.hasOwnProperty.call(message, "numberOfRequests"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.numberOfRequests);
                    if (message.charges != null && Object.hasOwnProperty.call(message, "charges"))
                        $root.waiternow.common.MoneyProto.encode(message.charges, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.discounts != null && message.discounts.length)
                        for (var i = 0; i < message.discounts.length; ++i)
                            $root.waiternow.common.BillProto.DiscountProto.encode(message.discounts[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified LocationBillProto message, length delimited. Does not implicitly {@link waiternow.common.BillProto.LocationBillProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {waiternow.common.BillProto.ILocationBillProto} message LocationBillProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationBillProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocationBillProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.BillProto.LocationBillProto} LocationBillProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationBillProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BillProto.LocationBillProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 3: {
                                message.locationAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.period = $root.waiternow.common.TimestampRangeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.numberOfPointsOfService = reader.int32();
                                break;
                            }
                        case 6: {
                                message.numberOfRequests = reader.int32();
                                break;
                            }
                        case 7: {
                                message.charges = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.discounts && message.discounts.length))
                                    message.discounts = [];
                                message.discounts.push($root.waiternow.common.BillProto.DiscountProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocationBillProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.BillProto.LocationBillProto} LocationBillProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationBillProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocationBillProto message.
                 * @function verify
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocationBillProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.locationAddress);
                        if (error)
                            return "locationAddress." + error;
                    }
                    if (message.period != null && message.hasOwnProperty("period")) {
                        var error = $root.waiternow.common.TimestampRangeProto.verify(message.period);
                        if (error)
                            return "period." + error;
                    }
                    if (message.numberOfPointsOfService != null && message.hasOwnProperty("numberOfPointsOfService"))
                        if (!$util.isInteger(message.numberOfPointsOfService))
                            return "numberOfPointsOfService: integer expected";
                    if (message.numberOfRequests != null && message.hasOwnProperty("numberOfRequests"))
                        if (!$util.isInteger(message.numberOfRequests))
                            return "numberOfRequests: integer expected";
                    if (message.charges != null && message.hasOwnProperty("charges")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.charges);
                        if (error)
                            return "charges." + error;
                    }
                    if (message.discounts != null && message.hasOwnProperty("discounts")) {
                        if (!Array.isArray(message.discounts))
                            return "discounts: array expected";
                        for (var i = 0; i < message.discounts.length; ++i) {
                            var error = $root.waiternow.common.BillProto.DiscountProto.verify(message.discounts[i]);
                            if (error)
                                return "discounts." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a LocationBillProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.BillProto.LocationBillProto} LocationBillProto
                 */
                LocationBillProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.BillProto.LocationBillProto)
                        return object;
                    var message = new $root.waiternow.common.BillProto.LocationBillProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.locationAddress != null) {
                        if (typeof object.locationAddress !== "object")
                            throw TypeError(".waiternow.common.BillProto.LocationBillProto.locationAddress: object expected");
                        message.locationAddress = $root.waiternow.common.AddressProto.fromObject(object.locationAddress);
                    }
                    if (object.period != null) {
                        if (typeof object.period !== "object")
                            throw TypeError(".waiternow.common.BillProto.LocationBillProto.period: object expected");
                        message.period = $root.waiternow.common.TimestampRangeProto.fromObject(object.period);
                    }
                    if (object.numberOfPointsOfService != null)
                        message.numberOfPointsOfService = object.numberOfPointsOfService | 0;
                    if (object.numberOfRequests != null)
                        message.numberOfRequests = object.numberOfRequests | 0;
                    if (object.charges != null) {
                        if (typeof object.charges !== "object")
                            throw TypeError(".waiternow.common.BillProto.LocationBillProto.charges: object expected");
                        message.charges = $root.waiternow.common.MoneyProto.fromObject(object.charges);
                    }
                    if (object.discounts) {
                        if (!Array.isArray(object.discounts))
                            throw TypeError(".waiternow.common.BillProto.LocationBillProto.discounts: array expected");
                        message.discounts = [];
                        for (var i = 0; i < object.discounts.length; ++i) {
                            if (typeof object.discounts[i] !== "object")
                                throw TypeError(".waiternow.common.BillProto.LocationBillProto.discounts: object expected");
                            message.discounts[i] = $root.waiternow.common.BillProto.DiscountProto.fromObject(object.discounts[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LocationBillProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {waiternow.common.BillProto.LocationBillProto} message LocationBillProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocationBillProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.discounts = [];
                    if (options.defaults) {
                        object.locationId = "";
                        object.locationName = "";
                        object.locationAddress = null;
                        object.period = null;
                        object.numberOfPointsOfService = 0;
                        object.numberOfRequests = 0;
                        object.charges = null;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress"))
                        object.locationAddress = $root.waiternow.common.AddressProto.toObject(message.locationAddress, options);
                    if (message.period != null && message.hasOwnProperty("period"))
                        object.period = $root.waiternow.common.TimestampRangeProto.toObject(message.period, options);
                    if (message.numberOfPointsOfService != null && message.hasOwnProperty("numberOfPointsOfService"))
                        object.numberOfPointsOfService = message.numberOfPointsOfService;
                    if (message.numberOfRequests != null && message.hasOwnProperty("numberOfRequests"))
                        object.numberOfRequests = message.numberOfRequests;
                    if (message.charges != null && message.hasOwnProperty("charges"))
                        object.charges = $root.waiternow.common.MoneyProto.toObject(message.charges, options);
                    if (message.discounts && message.discounts.length) {
                        object.discounts = [];
                        for (var j = 0; j < message.discounts.length; ++j)
                            object.discounts[j] = $root.waiternow.common.BillProto.DiscountProto.toObject(message.discounts[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this LocationBillProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocationBillProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocationBillProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.BillProto.LocationBillProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocationBillProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.BillProto.LocationBillProto";
                };

                return LocationBillProto;
            })();

            BillProto.StatusProto = (function() {

                /**
                 * Properties of a StatusProto.
                 * @memberof waiternow.common.BillProto
                 * @interface IStatusProto
                 * @property {waiternow.common.BillProto.StatusProto.State|null} [state] StatusProto state
                 * @property {string|null} [justification] StatusProto justification
                 * @property {waiternow.common.IPaymentProto|null} [payment] StatusProto payment
                 */

                /**
                 * Constructs a new StatusProto.
                 * @memberof waiternow.common.BillProto
                 * @classdesc Represents a StatusProto.
                 * @implements IStatusProto
                 * @constructor
                 * @param {waiternow.common.BillProto.IStatusProto=} [properties] Properties to set
                 */
                function StatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatusProto state.
                 * @member {waiternow.common.BillProto.StatusProto.State} state
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.state = 0;

                /**
                 * StatusProto justification.
                 * @member {string} justification
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.justification = "";

                /**
                 * StatusProto payment.
                 * @member {waiternow.common.IPaymentProto|null|undefined} payment
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.payment = null;

                /**
                 * Creates a new StatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {waiternow.common.BillProto.IStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.BillProto.StatusProto} StatusProto instance
                 */
                StatusProto.create = function create(properties) {
                    return new StatusProto(properties);
                };

                /**
                 * Encodes the specified StatusProto message. Does not implicitly {@link waiternow.common.BillProto.StatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {waiternow.common.BillProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
                    if (message.payment != null && Object.hasOwnProperty.call(message, "payment"))
                        $root.waiternow.common.PaymentProto.encode(message.payment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.justification != null && Object.hasOwnProperty.call(message, "justification"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.justification);
                    return writer;
                };

                /**
                 * Encodes the specified StatusProto message, length delimited. Does not implicitly {@link waiternow.common.BillProto.StatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {waiternow.common.BillProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.BillProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BillProto.StatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = reader.int32();
                                break;
                            }
                        case 3: {
                                message.justification = reader.string();
                                break;
                            }
                        case 2: {
                                message.payment = $root.waiternow.common.PaymentProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.BillProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatusProto message.
                 * @function verify
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.justification != null && message.hasOwnProperty("justification"))
                        if (!$util.isString(message.justification))
                            return "justification: string expected";
                    if (message.payment != null && message.hasOwnProperty("payment")) {
                        var error = $root.waiternow.common.PaymentProto.verify(message.payment);
                        if (error)
                            return "payment." + error;
                    }
                    return null;
                };

                /**
                 * Creates a StatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.BillProto.StatusProto} StatusProto
                 */
                StatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.BillProto.StatusProto)
                        return object;
                    var message = new $root.waiternow.common.BillProto.StatusProto();
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATE_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "PENDING":
                    case 1:
                        message.state = 1;
                        break;
                    case "PAID":
                    case 2:
                        message.state = 2;
                        break;
                    case "REFUNDED":
                    case 3:
                        message.state = 3;
                        break;
                    case "NULL":
                    case 4:
                        message.state = 4;
                        break;
                    case "CANCELED":
                    case 5:
                        message.state = 5;
                        break;
                    }
                    if (object.justification != null)
                        message.justification = String(object.justification);
                    if (object.payment != null) {
                        if (typeof object.payment !== "object")
                            throw TypeError(".waiternow.common.BillProto.StatusProto.payment: object expected");
                        message.payment = $root.waiternow.common.PaymentProto.fromObject(object.payment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a StatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {waiternow.common.BillProto.StatusProto} message StatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.state = options.enums === String ? "STATE_UNSPECIFIED" : 0;
                        object.payment = null;
                        object.justification = "";
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.BillProto.StatusProto.State[message.state] === undefined ? message.state : $root.waiternow.common.BillProto.StatusProto.State[message.state] : message.state;
                    if (message.payment != null && message.hasOwnProperty("payment"))
                        object.payment = $root.waiternow.common.PaymentProto.toObject(message.payment, options);
                    if (message.justification != null && message.hasOwnProperty("justification"))
                        object.justification = message.justification;
                    return object;
                };

                /**
                 * Converts this StatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.BillProto.StatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.BillProto.StatusProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.BillProto.StatusProto.State
                 * @enum {number}
                 * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
                 * @property {number} PENDING=1 PENDING value
                 * @property {number} PAID=2 PAID value
                 * @property {number} REFUNDED=3 REFUNDED value
                 * @property {number} NULL=4 NULL value
                 * @property {number} CANCELED=5 CANCELED value
                 */
                StatusProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "PENDING"] = 1;
                    values[valuesById[2] = "PAID"] = 2;
                    values[valuesById[3] = "REFUNDED"] = 3;
                    values[valuesById[4] = "NULL"] = 4;
                    values[valuesById[5] = "CANCELED"] = 5;
                    return values;
                })();

                return StatusProto;
            })();

            return BillProto;
        })();

        common.BusinessBillsProto = (function() {

            /**
             * Properties of a BusinessBillsProto.
             * @memberof waiternow.common
             * @interface IBusinessBillsProto
             * @property {string|null} [businessId] BusinessBillsProto businessId
             * @property {Array.<waiternow.common.IBillProto>|null} [bills] BusinessBillsProto bills
             */

            /**
             * Constructs a new BusinessBillsProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessBillsProto.
             * @implements IBusinessBillsProto
             * @constructor
             * @param {waiternow.common.IBusinessBillsProto=} [properties] Properties to set
             */
            function BusinessBillsProto(properties) {
                this.bills = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessBillsProto businessId.
             * @member {string} businessId
             * @memberof waiternow.common.BusinessBillsProto
             * @instance
             */
            BusinessBillsProto.prototype.businessId = "";

            /**
             * BusinessBillsProto bills.
             * @member {Array.<waiternow.common.IBillProto>} bills
             * @memberof waiternow.common.BusinessBillsProto
             * @instance
             */
            BusinessBillsProto.prototype.bills = $util.emptyArray;

            /**
             * Creates a new BusinessBillsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {waiternow.common.IBusinessBillsProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessBillsProto} BusinessBillsProto instance
             */
            BusinessBillsProto.create = function create(properties) {
                return new BusinessBillsProto(properties);
            };

            /**
             * Encodes the specified BusinessBillsProto message. Does not implicitly {@link waiternow.common.BusinessBillsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {waiternow.common.IBusinessBillsProto} message BusinessBillsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessBillsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                if (message.bills != null && message.bills.length)
                    for (var i = 0; i < message.bills.length; ++i)
                        $root.waiternow.common.BillProto.encode(message.bills[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified BusinessBillsProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessBillsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {waiternow.common.IBusinessBillsProto} message BusinessBillsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessBillsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessBillsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessBillsProto} BusinessBillsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessBillsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessBillsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.businessId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.bills && message.bills.length))
                                message.bills = [];
                            message.bills.push($root.waiternow.common.BillProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessBillsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessBillsProto} BusinessBillsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessBillsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessBillsProto message.
             * @function verify
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessBillsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    if (!$util.isString(message.businessId))
                        return "businessId: string expected";
                if (message.bills != null && message.hasOwnProperty("bills")) {
                    if (!Array.isArray(message.bills))
                        return "bills: array expected";
                    for (var i = 0; i < message.bills.length; ++i) {
                        var error = $root.waiternow.common.BillProto.verify(message.bills[i]);
                        if (error)
                            return "bills." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a BusinessBillsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessBillsProto} BusinessBillsProto
             */
            BusinessBillsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessBillsProto)
                    return object;
                var message = new $root.waiternow.common.BusinessBillsProto();
                if (object.businessId != null)
                    message.businessId = String(object.businessId);
                if (object.bills) {
                    if (!Array.isArray(object.bills))
                        throw TypeError(".waiternow.common.BusinessBillsProto.bills: array expected");
                    message.bills = [];
                    for (var i = 0; i < object.bills.length; ++i) {
                        if (typeof object.bills[i] !== "object")
                            throw TypeError(".waiternow.common.BusinessBillsProto.bills: object expected");
                        message.bills[i] = $root.waiternow.common.BillProto.fromObject(object.bills[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a BusinessBillsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {waiternow.common.BusinessBillsProto} message BusinessBillsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessBillsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.bills = [];
                if (options.defaults)
                    object.businessId = "";
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    object.businessId = message.businessId;
                if (message.bills && message.bills.length) {
                    object.bills = [];
                    for (var j = 0; j < message.bills.length; ++j)
                        object.bills[j] = $root.waiternow.common.BillProto.toObject(message.bills[j], options);
                }
                return object;
            };

            /**
             * Converts this BusinessBillsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessBillsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessBillsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessBillsProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessBillsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessBillsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessBillsProto";
            };

            return BusinessBillsProto;
        })();

        common.BusinessPendingBillsProto = (function() {

            /**
             * Properties of a BusinessPendingBillsProto.
             * @memberof waiternow.common
             * @interface IBusinessPendingBillsProto
             * @property {string|null} [businessId] BusinessPendingBillsProto businessId
             * @property {waiternow.common.IMoneyProto|null} [balance] BusinessPendingBillsProto balance
             * @property {Array.<waiternow.common.IBillProto>|null} [bills] BusinessPendingBillsProto bills
             */

            /**
             * Constructs a new BusinessPendingBillsProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessPendingBillsProto.
             * @implements IBusinessPendingBillsProto
             * @constructor
             * @param {waiternow.common.IBusinessPendingBillsProto=} [properties] Properties to set
             */
            function BusinessPendingBillsProto(properties) {
                this.bills = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessPendingBillsProto businessId.
             * @member {string} businessId
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @instance
             */
            BusinessPendingBillsProto.prototype.businessId = "";

            /**
             * BusinessPendingBillsProto balance.
             * @member {waiternow.common.IMoneyProto|null|undefined} balance
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @instance
             */
            BusinessPendingBillsProto.prototype.balance = null;

            /**
             * BusinessPendingBillsProto bills.
             * @member {Array.<waiternow.common.IBillProto>} bills
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @instance
             */
            BusinessPendingBillsProto.prototype.bills = $util.emptyArray;

            /**
             * Creates a new BusinessPendingBillsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {waiternow.common.IBusinessPendingBillsProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessPendingBillsProto} BusinessPendingBillsProto instance
             */
            BusinessPendingBillsProto.create = function create(properties) {
                return new BusinessPendingBillsProto(properties);
            };

            /**
             * Encodes the specified BusinessPendingBillsProto message. Does not implicitly {@link waiternow.common.BusinessPendingBillsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {waiternow.common.IBusinessPendingBillsProto} message BusinessPendingBillsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessPendingBillsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                if (message.balance != null && Object.hasOwnProperty.call(message, "balance"))
                    $root.waiternow.common.MoneyProto.encode(message.balance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.bills != null && message.bills.length)
                    for (var i = 0; i < message.bills.length; ++i)
                        $root.waiternow.common.BillProto.encode(message.bills[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified BusinessPendingBillsProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessPendingBillsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {waiternow.common.IBusinessPendingBillsProto} message BusinessPendingBillsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessPendingBillsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessPendingBillsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessPendingBillsProto} BusinessPendingBillsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessPendingBillsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessPendingBillsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.businessId = reader.string();
                            break;
                        }
                    case 2: {
                            message.balance = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            if (!(message.bills && message.bills.length))
                                message.bills = [];
                            message.bills.push($root.waiternow.common.BillProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessPendingBillsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessPendingBillsProto} BusinessPendingBillsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessPendingBillsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessPendingBillsProto message.
             * @function verify
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessPendingBillsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    if (!$util.isString(message.businessId))
                        return "businessId: string expected";
                if (message.balance != null && message.hasOwnProperty("balance")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.balance);
                    if (error)
                        return "balance." + error;
                }
                if (message.bills != null && message.hasOwnProperty("bills")) {
                    if (!Array.isArray(message.bills))
                        return "bills: array expected";
                    for (var i = 0; i < message.bills.length; ++i) {
                        var error = $root.waiternow.common.BillProto.verify(message.bills[i]);
                        if (error)
                            return "bills." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a BusinessPendingBillsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessPendingBillsProto} BusinessPendingBillsProto
             */
            BusinessPendingBillsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessPendingBillsProto)
                    return object;
                var message = new $root.waiternow.common.BusinessPendingBillsProto();
                if (object.businessId != null)
                    message.businessId = String(object.businessId);
                if (object.balance != null) {
                    if (typeof object.balance !== "object")
                        throw TypeError(".waiternow.common.BusinessPendingBillsProto.balance: object expected");
                    message.balance = $root.waiternow.common.MoneyProto.fromObject(object.balance);
                }
                if (object.bills) {
                    if (!Array.isArray(object.bills))
                        throw TypeError(".waiternow.common.BusinessPendingBillsProto.bills: array expected");
                    message.bills = [];
                    for (var i = 0; i < object.bills.length; ++i) {
                        if (typeof object.bills[i] !== "object")
                            throw TypeError(".waiternow.common.BusinessPendingBillsProto.bills: object expected");
                        message.bills[i] = $root.waiternow.common.BillProto.fromObject(object.bills[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a BusinessPendingBillsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {waiternow.common.BusinessPendingBillsProto} message BusinessPendingBillsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessPendingBillsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.bills = [];
                if (options.defaults) {
                    object.businessId = "";
                    object.balance = null;
                }
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    object.businessId = message.businessId;
                if (message.balance != null && message.hasOwnProperty("balance"))
                    object.balance = $root.waiternow.common.MoneyProto.toObject(message.balance, options);
                if (message.bills && message.bills.length) {
                    object.bills = [];
                    for (var j = 0; j < message.bills.length; ++j)
                        object.bills[j] = $root.waiternow.common.BillProto.toObject(message.bills[j], options);
                }
                return object;
            };

            /**
             * Converts this BusinessPendingBillsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessPendingBillsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessPendingBillsProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessPendingBillsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessPendingBillsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessPendingBillsProto";
            };

            return BusinessPendingBillsProto;
        })();

        /**
         * ErrorCode enum.
         * @name waiternow.common.ErrorCode
         * @enum {number}
         * @property {number} ERROR_CODE_UNSPECIFIED=0 ERROR_CODE_UNSPECIFIED value
         * @property {number} CHANGE_OF_ADDRESS_NOT_ALLOWED_OUTSIDE_COUNTRY=30 CHANGE_OF_ADDRESS_NOT_ALLOWED_OUTSIDE_COUNTRY value
         * @property {number} DELIVERY_FAILED_ORDER_REFUNDED=19 DELIVERY_FAILED_ORDER_REFUNDED value
         * @property {number} DELIVERY_NOT_ALLOWED=16 DELIVERY_NOT_ALLOWED value
         * @property {number} DELIVERY_NOT_ALLOWED_DELIVERY_ADDRESS_NOT_IN_COVERAGE=22 DELIVERY_NOT_ALLOWED_DELIVERY_ADDRESS_NOT_IN_COVERAGE value
         * @property {number} DELIVERY_NOT_ALLOWED_DISTANCE_TOO_LONG=17 DELIVERY_NOT_ALLOWED_DISTANCE_TOO_LONG value
         * @property {number} DELIVERY_NOT_ALLOWED_OUTSIDE_OF_DELIVERY_TIME=23 DELIVERY_NOT_ALLOWED_OUTSIDE_OF_DELIVERY_TIME value
         * @property {number} DELIVERY_NOT_ALLOWED_PICKUP_ADDRESS_NOT_IN_COVERAGE=21 DELIVERY_NOT_ALLOWED_PICKUP_ADDRESS_NOT_IN_COVERAGE value
         * @property {number} DELIVERY_NOT_ALLOWED_REQUESTED_DROP_OFF_TIME_TOO_SOON=27 DELIVERY_NOT_ALLOWED_REQUESTED_DROP_OFF_TIME_TOO_SOON value
         * @property {number} DELIVERY_NOT_CONFIGURED_AT_LOCATION=28 DELIVERY_NOT_CONFIGURED_AT_LOCATION value
         * @property {number} DELIVERY_NOT_ENABLED_AT_LOCATION=15 DELIVERY_NOT_ENABLED_AT_LOCATION value
         * @property {number} EMAIL_ALREADY_IN_USE=2 EMAIL_ALREADY_IN_USE value
         * @property {number} INTERNAL_ERROR=1 INTERNAL_ERROR value
         * @property {number} INVALID_ADDRESS=9 INVALID_ADDRESS value
         * @property {number} INVALID_BUSINESS_DESCRIPTION=24 INVALID_BUSINESS_DESCRIPTION value
         * @property {number} INVALID_DELIVERY_CONFIGURATION=25 INVALID_DELIVERY_CONFIGURATION value
         * @property {number} INVALID_EMAIL=18 INVALID_EMAIL value
         * @property {number} INVALID_EMAIL_VERIFICATION_CODE=7 INVALID_EMAIL_VERIFICATION_CODE value
         * @property {number} INVALID_INPUT=3 INVALID_INPUT value
         * @property {number} INVALID_ORDER_ALCOHOL_NOT_ALLOWED=29 INVALID_ORDER_ALCOHOL_NOT_ALLOWED value
         * @property {number} INVALID_ORDER_TIME=11 INVALID_ORDER_TIME value
         * @property {number} INVALID_ORDER_TIME_IN_THE_PAST=12 INVALID_ORDER_TIME_IN_THE_PAST value
         * @property {number} INVALID_ORDER_TIME_TOO_FAR_IN_THE_FUTURE=13 INVALID_ORDER_TIME_TOO_FAR_IN_THE_FUTURE value
         * @property {number} INVALID_PHONE_NUMBER=10 INVALID_PHONE_NUMBER value
         * @property {number} INVALID_PHONE_NUMBER_VERIFICATION_CODE=8 INVALID_PHONE_NUMBER_VERIFICATION_CODE value
         * @property {number} INVALID_PICKUP_PHONE_NUMBER=26 INVALID_PICKUP_PHONE_NUMBER value
         * @property {number} INVALID_MENU=33 INVALID_MENU value
         * @property {number} INVALID_MENU_CURRENCY_FOR_LOCATION=31 INVALID_MENU_CURRENCY_FOR_LOCATION value
         * @property {number} INVALID_MENU_CURRENCY_FOR_BUSINESS=32 INVALID_MENU_CURRENCY_FOR_BUSINESS value
         * @property {number} LOCATION_OFFLINE=6 LOCATION_OFFLINE value
         * @property {number} LOCATION_WITHOUT_TAX_INFORMATION=20 LOCATION_WITHOUT_TAX_INFORMATION value
         * @property {number} LOCATION_WITHOUT_TIMEZONE=14 LOCATION_WITHOUT_TIMEZONE value
         * @property {number} NOT_FOUND=5 NOT_FOUND value
         * @property {number} SERVICE_SUSPENDED=4 SERVICE_SUSPENDED value
         */
        common.ErrorCode = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ERROR_CODE_UNSPECIFIED"] = 0;
            values[valuesById[30] = "CHANGE_OF_ADDRESS_NOT_ALLOWED_OUTSIDE_COUNTRY"] = 30;
            values[valuesById[19] = "DELIVERY_FAILED_ORDER_REFUNDED"] = 19;
            values[valuesById[16] = "DELIVERY_NOT_ALLOWED"] = 16;
            values[valuesById[22] = "DELIVERY_NOT_ALLOWED_DELIVERY_ADDRESS_NOT_IN_COVERAGE"] = 22;
            values[valuesById[17] = "DELIVERY_NOT_ALLOWED_DISTANCE_TOO_LONG"] = 17;
            values[valuesById[23] = "DELIVERY_NOT_ALLOWED_OUTSIDE_OF_DELIVERY_TIME"] = 23;
            values[valuesById[21] = "DELIVERY_NOT_ALLOWED_PICKUP_ADDRESS_NOT_IN_COVERAGE"] = 21;
            values[valuesById[27] = "DELIVERY_NOT_ALLOWED_REQUESTED_DROP_OFF_TIME_TOO_SOON"] = 27;
            values[valuesById[28] = "DELIVERY_NOT_CONFIGURED_AT_LOCATION"] = 28;
            values[valuesById[15] = "DELIVERY_NOT_ENABLED_AT_LOCATION"] = 15;
            values[valuesById[2] = "EMAIL_ALREADY_IN_USE"] = 2;
            values[valuesById[1] = "INTERNAL_ERROR"] = 1;
            values[valuesById[9] = "INVALID_ADDRESS"] = 9;
            values[valuesById[24] = "INVALID_BUSINESS_DESCRIPTION"] = 24;
            values[valuesById[25] = "INVALID_DELIVERY_CONFIGURATION"] = 25;
            values[valuesById[18] = "INVALID_EMAIL"] = 18;
            values[valuesById[7] = "INVALID_EMAIL_VERIFICATION_CODE"] = 7;
            values[valuesById[3] = "INVALID_INPUT"] = 3;
            values[valuesById[29] = "INVALID_ORDER_ALCOHOL_NOT_ALLOWED"] = 29;
            values[valuesById[11] = "INVALID_ORDER_TIME"] = 11;
            values[valuesById[12] = "INVALID_ORDER_TIME_IN_THE_PAST"] = 12;
            values[valuesById[13] = "INVALID_ORDER_TIME_TOO_FAR_IN_THE_FUTURE"] = 13;
            values[valuesById[10] = "INVALID_PHONE_NUMBER"] = 10;
            values[valuesById[8] = "INVALID_PHONE_NUMBER_VERIFICATION_CODE"] = 8;
            values[valuesById[26] = "INVALID_PICKUP_PHONE_NUMBER"] = 26;
            values[valuesById[33] = "INVALID_MENU"] = 33;
            values[valuesById[31] = "INVALID_MENU_CURRENCY_FOR_LOCATION"] = 31;
            values[valuesById[32] = "INVALID_MENU_CURRENCY_FOR_BUSINESS"] = 32;
            values[valuesById[6] = "LOCATION_OFFLINE"] = 6;
            values[valuesById[20] = "LOCATION_WITHOUT_TAX_INFORMATION"] = 20;
            values[valuesById[14] = "LOCATION_WITHOUT_TIMEZONE"] = 14;
            values[valuesById[5] = "NOT_FOUND"] = 5;
            values[valuesById[4] = "SERVICE_SUSPENDED"] = 4;
            return values;
        })();

        /**
         * Language enum.
         * @name waiternow.common.Language
         * @enum {number}
         * @property {number} LANGUAGE_UNSPECIFIED=0 LANGUAGE_UNSPECIFIED value
         * @property {number} ENGLISH=1 ENGLISH value
         * @property {number} SPANISH=2 SPANISH value
         */
        common.Language = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "LANGUAGE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "ENGLISH"] = 1;
            values[valuesById[2] = "SPANISH"] = 2;
            return values;
        })();

        /**
         * QrCodeSize enum.
         * @name waiternow.common.QrCodeSize
         * @enum {number}
         * @property {number} QR_CODE_SIZE_UNSPECIFIED=0 QR_CODE_SIZE_UNSPECIFIED value
         * @property {number} SMALL=1 SMALL value
         * @property {number} SMALL_RAW=2 SMALL_RAW value
         * @property {number} SIZE_4_BY_3_INCH=3 SIZE_4_BY_3_INCH value
         * @property {number} SIZE_4_BY_4_INCH=4 SIZE_4_BY_4_INCH value
         * @property {number} SIZE_4_BY_6_INCH=5 SIZE_4_BY_6_INCH value
         */
        common.QrCodeSize = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "QR_CODE_SIZE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "SMALL"] = 1;
            values[valuesById[2] = "SMALL_RAW"] = 2;
            values[valuesById[3] = "SIZE_4_BY_3_INCH"] = 3;
            values[valuesById[4] = "SIZE_4_BY_4_INCH"] = 4;
            values[valuesById[5] = "SIZE_4_BY_6_INCH"] = 5;
            return values;
        })();

        /**
         * Theme enum.
         * @name waiternow.common.Theme
         * @enum {number}
         * @property {number} THEME_UNSPECIFIED=0 THEME_UNSPECIFIED value
         * @property {number} DARK=1 DARK value
         * @property {number} LIGHT=2 LIGHT value
         */
        common.Theme = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "THEME_UNSPECIFIED"] = 0;
            values[valuesById[1] = "DARK"] = 1;
            values[valuesById[2] = "LIGHT"] = 2;
            return values;
        })();

        common.QrCodeDataProto = (function() {

            /**
             * Properties of a QrCodeDataProto.
             * @memberof waiternow.common
             * @interface IQrCodeDataProto
             * @property {string|null} [dataToEncode] QrCodeDataProto dataToEncode
             * @property {string|null} [encodedDataReference] QrCodeDataProto encodedDataReference
             * @property {string|null} [title] QrCodeDataProto title
             * @property {string|null} [subtitle] QrCodeDataProto subtitle
             */

            /**
             * Constructs a new QrCodeDataProto.
             * @memberof waiternow.common
             * @classdesc Represents a QrCodeDataProto.
             * @implements IQrCodeDataProto
             * @constructor
             * @param {waiternow.common.IQrCodeDataProto=} [properties] Properties to set
             */
            function QrCodeDataProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * QrCodeDataProto dataToEncode.
             * @member {string} dataToEncode
             * @memberof waiternow.common.QrCodeDataProto
             * @instance
             */
            QrCodeDataProto.prototype.dataToEncode = "";

            /**
             * QrCodeDataProto encodedDataReference.
             * @member {string} encodedDataReference
             * @memberof waiternow.common.QrCodeDataProto
             * @instance
             */
            QrCodeDataProto.prototype.encodedDataReference = "";

            /**
             * QrCodeDataProto title.
             * @member {string} title
             * @memberof waiternow.common.QrCodeDataProto
             * @instance
             */
            QrCodeDataProto.prototype.title = "";

            /**
             * QrCodeDataProto subtitle.
             * @member {string} subtitle
             * @memberof waiternow.common.QrCodeDataProto
             * @instance
             */
            QrCodeDataProto.prototype.subtitle = "";

            /**
             * Creates a new QrCodeDataProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {waiternow.common.IQrCodeDataProto=} [properties] Properties to set
             * @returns {waiternow.common.QrCodeDataProto} QrCodeDataProto instance
             */
            QrCodeDataProto.create = function create(properties) {
                return new QrCodeDataProto(properties);
            };

            /**
             * Encodes the specified QrCodeDataProto message. Does not implicitly {@link waiternow.common.QrCodeDataProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {waiternow.common.IQrCodeDataProto} message QrCodeDataProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QrCodeDataProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dataToEncode != null && Object.hasOwnProperty.call(message, "dataToEncode"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.dataToEncode);
                if (message.encodedDataReference != null && Object.hasOwnProperty.call(message, "encodedDataReference"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.encodedDataReference);
                if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.title);
                if (message.subtitle != null && Object.hasOwnProperty.call(message, "subtitle"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.subtitle);
                return writer;
            };

            /**
             * Encodes the specified QrCodeDataProto message, length delimited. Does not implicitly {@link waiternow.common.QrCodeDataProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {waiternow.common.IQrCodeDataProto} message QrCodeDataProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            QrCodeDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a QrCodeDataProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.QrCodeDataProto} QrCodeDataProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QrCodeDataProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.QrCodeDataProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.dataToEncode = reader.string();
                            break;
                        }
                    case 2: {
                            message.encodedDataReference = reader.string();
                            break;
                        }
                    case 3: {
                            message.title = reader.string();
                            break;
                        }
                    case 5: {
                            message.subtitle = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a QrCodeDataProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.QrCodeDataProto} QrCodeDataProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            QrCodeDataProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a QrCodeDataProto message.
             * @function verify
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            QrCodeDataProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dataToEncode != null && message.hasOwnProperty("dataToEncode"))
                    if (!$util.isString(message.dataToEncode))
                        return "dataToEncode: string expected";
                if (message.encodedDataReference != null && message.hasOwnProperty("encodedDataReference"))
                    if (!$util.isString(message.encodedDataReference))
                        return "encodedDataReference: string expected";
                if (message.title != null && message.hasOwnProperty("title"))
                    if (!$util.isString(message.title))
                        return "title: string expected";
                if (message.subtitle != null && message.hasOwnProperty("subtitle"))
                    if (!$util.isString(message.subtitle))
                        return "subtitle: string expected";
                return null;
            };

            /**
             * Creates a QrCodeDataProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.QrCodeDataProto} QrCodeDataProto
             */
            QrCodeDataProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.QrCodeDataProto)
                    return object;
                var message = new $root.waiternow.common.QrCodeDataProto();
                if (object.dataToEncode != null)
                    message.dataToEncode = String(object.dataToEncode);
                if (object.encodedDataReference != null)
                    message.encodedDataReference = String(object.encodedDataReference);
                if (object.title != null)
                    message.title = String(object.title);
                if (object.subtitle != null)
                    message.subtitle = String(object.subtitle);
                return message;
            };

            /**
             * Creates a plain object from a QrCodeDataProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {waiternow.common.QrCodeDataProto} message QrCodeDataProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            QrCodeDataProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.dataToEncode = "";
                    object.encodedDataReference = "";
                    object.title = "";
                    object.subtitle = "";
                }
                if (message.dataToEncode != null && message.hasOwnProperty("dataToEncode"))
                    object.dataToEncode = message.dataToEncode;
                if (message.encodedDataReference != null && message.hasOwnProperty("encodedDataReference"))
                    object.encodedDataReference = message.encodedDataReference;
                if (message.title != null && message.hasOwnProperty("title"))
                    object.title = message.title;
                if (message.subtitle != null && message.hasOwnProperty("subtitle"))
                    object.subtitle = message.subtitle;
                return object;
            };

            /**
             * Converts this QrCodeDataProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.QrCodeDataProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            QrCodeDataProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for QrCodeDataProto
             * @function getTypeUrl
             * @memberof waiternow.common.QrCodeDataProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            QrCodeDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.QrCodeDataProto";
            };

            return QrCodeDataProto;
        })();

        /**
         * Rating enum.
         * @name waiternow.common.Rating
         * @enum {number}
         * @property {number} RATING_UNSPECIFIED=0 RATING_UNSPECIFIED value
         * @property {number} ONE_STAR=1 ONE_STAR value
         * @property {number} TWO_STARS=2 TWO_STARS value
         * @property {number} THREE_STARS=3 THREE_STARS value
         * @property {number} FOUR_STARS=4 FOUR_STARS value
         * @property {number} FIVE_STARS=5 FIVE_STARS value
         */
        common.Rating = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RATING_UNSPECIFIED"] = 0;
            values[valuesById[1] = "ONE_STAR"] = 1;
            values[valuesById[2] = "TWO_STARS"] = 2;
            values[valuesById[3] = "THREE_STARS"] = 3;
            values[valuesById[4] = "FOUR_STARS"] = 4;
            values[valuesById[5] = "FIVE_STARS"] = 5;
            return values;
        })();

        /**
         * ThumbSignal enum.
         * @name waiternow.common.ThumbSignal
         * @enum {number}
         * @property {number} THUMB_SIGNAL_UNSPECIFIED=0 THUMB_SIGNAL_UNSPECIFIED value
         * @property {number} THUMB_UP=1 THUMB_UP value
         * @property {number} THUMB_DOWN=2 THUMB_DOWN value
         */
        common.ThumbSignal = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "THUMB_SIGNAL_UNSPECIFIED"] = 0;
            values[valuesById[1] = "THUMB_UP"] = 1;
            values[valuesById[2] = "THUMB_DOWN"] = 2;
            return values;
        })();

        /**
         * ContactMedium enum.
         * @name waiternow.common.ContactMedium
         * @enum {number}
         * @property {number} CONTACT_MEDIUM_UNSPECIFIED=0 CONTACT_MEDIUM_UNSPECIFIED value
         * @property {number} EMAIL=1 EMAIL value
         * @property {number} PHONE_CALL=2 PHONE_CALL value
         * @property {number} TEXT_MESSAGE=3 TEXT_MESSAGE value
         * @property {number} WHATS_APP=4 WHATS_APP value
         */
        common.ContactMedium = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "CONTACT_MEDIUM_UNSPECIFIED"] = 0;
            values[valuesById[1] = "EMAIL"] = 1;
            values[valuesById[2] = "PHONE_CALL"] = 2;
            values[valuesById[3] = "TEXT_MESSAGE"] = 3;
            values[valuesById[4] = "WHATS_APP"] = 4;
            return values;
        })();

        /**
         * RecordType enum.
         * @name waiternow.common.RecordType
         * @enum {number}
         * @property {number} RECORD_TYPE_UNSPECIFIED=0 RECORD_TYPE_UNSPECIFIED value
         * @property {number} REGULAR=1 REGULAR value
         * @property {number} PRE_CREATED=3 PRE_CREATED value
         * @property {number} TEST=4 TEST value
         * @property {number} DEMO=5 DEMO value
         */
        common.RecordType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "RECORD_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "REGULAR"] = 1;
            values[valuesById[3] = "PRE_CREATED"] = 3;
            values[valuesById[4] = "TEST"] = 4;
            values[valuesById[5] = "DEMO"] = 5;
            return values;
        })();

        common.TextProto = (function() {

            /**
             * Properties of a TextProto.
             * @memberof waiternow.common
             * @interface ITextProto
             * @property {string|null} [text] TextProto text
             * @property {waiternow.common.Language|null} [language] TextProto language
             * @property {Array.<waiternow.common.TextProto.ILocalizedTextProto>|null} [localizedTexts] TextProto localizedTexts
             */

            /**
             * Constructs a new TextProto.
             * @memberof waiternow.common
             * @classdesc Represents a TextProto.
             * @implements ITextProto
             * @constructor
             * @param {waiternow.common.ITextProto=} [properties] Properties to set
             */
            function TextProto(properties) {
                this.localizedTexts = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TextProto text.
             * @member {string} text
             * @memberof waiternow.common.TextProto
             * @instance
             */
            TextProto.prototype.text = "";

            /**
             * TextProto language.
             * @member {waiternow.common.Language} language
             * @memberof waiternow.common.TextProto
             * @instance
             */
            TextProto.prototype.language = 0;

            /**
             * TextProto localizedTexts.
             * @member {Array.<waiternow.common.TextProto.ILocalizedTextProto>} localizedTexts
             * @memberof waiternow.common.TextProto
             * @instance
             */
            TextProto.prototype.localizedTexts = $util.emptyArray;

            /**
             * Creates a new TextProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.TextProto
             * @static
             * @param {waiternow.common.ITextProto=} [properties] Properties to set
             * @returns {waiternow.common.TextProto} TextProto instance
             */
            TextProto.create = function create(properties) {
                return new TextProto(properties);
            };

            /**
             * Encodes the specified TextProto message. Does not implicitly {@link waiternow.common.TextProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.TextProto
             * @static
             * @param {waiternow.common.ITextProto} message TextProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TextProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                if (message.localizedTexts != null && message.localizedTexts.length)
                    for (var i = 0; i < message.localizedTexts.length; ++i)
                        $root.waiternow.common.TextProto.LocalizedTextProto.encode(message.localizedTexts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.language);
                return writer;
            };

            /**
             * Encodes the specified TextProto message, length delimited. Does not implicitly {@link waiternow.common.TextProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.TextProto
             * @static
             * @param {waiternow.common.ITextProto} message TextProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TextProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TextProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.TextProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.TextProto} TextProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TextProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TextProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.text = reader.string();
                            break;
                        }
                    case 3: {
                            message.language = reader.int32();
                            break;
                        }
                    case 2: {
                            if (!(message.localizedTexts && message.localizedTexts.length))
                                message.localizedTexts = [];
                            message.localizedTexts.push($root.waiternow.common.TextProto.LocalizedTextProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TextProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.TextProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.TextProto} TextProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TextProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TextProto message.
             * @function verify
             * @memberof waiternow.common.TextProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TextProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.text != null && message.hasOwnProperty("text"))
                    if (!$util.isString(message.text))
                        return "text: string expected";
                if (message.language != null && message.hasOwnProperty("language"))
                    switch (message.language) {
                    default:
                        return "language: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.localizedTexts != null && message.hasOwnProperty("localizedTexts")) {
                    if (!Array.isArray(message.localizedTexts))
                        return "localizedTexts: array expected";
                    for (var i = 0; i < message.localizedTexts.length; ++i) {
                        var error = $root.waiternow.common.TextProto.LocalizedTextProto.verify(message.localizedTexts[i]);
                        if (error)
                            return "localizedTexts." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a TextProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.TextProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.TextProto} TextProto
             */
            TextProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.TextProto)
                    return object;
                var message = new $root.waiternow.common.TextProto();
                if (object.text != null)
                    message.text = String(object.text);
                switch (object.language) {
                default:
                    if (typeof object.language === "number") {
                        message.language = object.language;
                        break;
                    }
                    break;
                case "LANGUAGE_UNSPECIFIED":
                case 0:
                    message.language = 0;
                    break;
                case "ENGLISH":
                case 1:
                    message.language = 1;
                    break;
                case "SPANISH":
                case 2:
                    message.language = 2;
                    break;
                }
                if (object.localizedTexts) {
                    if (!Array.isArray(object.localizedTexts))
                        throw TypeError(".waiternow.common.TextProto.localizedTexts: array expected");
                    message.localizedTexts = [];
                    for (var i = 0; i < object.localizedTexts.length; ++i) {
                        if (typeof object.localizedTexts[i] !== "object")
                            throw TypeError(".waiternow.common.TextProto.localizedTexts: object expected");
                        message.localizedTexts[i] = $root.waiternow.common.TextProto.LocalizedTextProto.fromObject(object.localizedTexts[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a TextProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.TextProto
             * @static
             * @param {waiternow.common.TextProto} message TextProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TextProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.localizedTexts = [];
                if (options.defaults) {
                    object.text = "";
                    object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                }
                if (message.text != null && message.hasOwnProperty("text"))
                    object.text = message.text;
                if (message.localizedTexts && message.localizedTexts.length) {
                    object.localizedTexts = [];
                    for (var j = 0; j < message.localizedTexts.length; ++j)
                        object.localizedTexts[j] = $root.waiternow.common.TextProto.LocalizedTextProto.toObject(message.localizedTexts[j], options);
                }
                if (message.language != null && message.hasOwnProperty("language"))
                    object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                return object;
            };

            /**
             * Converts this TextProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.TextProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TextProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TextProto
             * @function getTypeUrl
             * @memberof waiternow.common.TextProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TextProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.TextProto";
            };

            TextProto.LocalizedTextProto = (function() {

                /**
                 * Properties of a LocalizedTextProto.
                 * @memberof waiternow.common.TextProto
                 * @interface ILocalizedTextProto
                 * @property {string|null} [text] LocalizedTextProto text
                 * @property {waiternow.common.Language|null} [language] LocalizedTextProto language
                 */

                /**
                 * Constructs a new LocalizedTextProto.
                 * @memberof waiternow.common.TextProto
                 * @classdesc Represents a LocalizedTextProto.
                 * @implements ILocalizedTextProto
                 * @constructor
                 * @param {waiternow.common.TextProto.ILocalizedTextProto=} [properties] Properties to set
                 */
                function LocalizedTextProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocalizedTextProto text.
                 * @member {string} text
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @instance
                 */
                LocalizedTextProto.prototype.text = "";

                /**
                 * LocalizedTextProto language.
                 * @member {waiternow.common.Language} language
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @instance
                 */
                LocalizedTextProto.prototype.language = 0;

                /**
                 * Creates a new LocalizedTextProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {waiternow.common.TextProto.ILocalizedTextProto=} [properties] Properties to set
                 * @returns {waiternow.common.TextProto.LocalizedTextProto} LocalizedTextProto instance
                 */
                LocalizedTextProto.create = function create(properties) {
                    return new LocalizedTextProto(properties);
                };

                /**
                 * Encodes the specified LocalizedTextProto message. Does not implicitly {@link waiternow.common.TextProto.LocalizedTextProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {waiternow.common.TextProto.ILocalizedTextProto} message LocalizedTextProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalizedTextProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.text != null && Object.hasOwnProperty.call(message, "text"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.text);
                    if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.language);
                    return writer;
                };

                /**
                 * Encodes the specified LocalizedTextProto message, length delimited. Does not implicitly {@link waiternow.common.TextProto.LocalizedTextProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {waiternow.common.TextProto.ILocalizedTextProto} message LocalizedTextProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocalizedTextProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocalizedTextProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.TextProto.LocalizedTextProto} LocalizedTextProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalizedTextProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TextProto.LocalizedTextProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.text = reader.string();
                                break;
                            }
                        case 2: {
                                message.language = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocalizedTextProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.TextProto.LocalizedTextProto} LocalizedTextProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocalizedTextProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocalizedTextProto message.
                 * @function verify
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocalizedTextProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.text != null && message.hasOwnProperty("text"))
                        if (!$util.isString(message.text))
                            return "text: string expected";
                    if (message.language != null && message.hasOwnProperty("language"))
                        switch (message.language) {
                        default:
                            return "language: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a LocalizedTextProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.TextProto.LocalizedTextProto} LocalizedTextProto
                 */
                LocalizedTextProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.TextProto.LocalizedTextProto)
                        return object;
                    var message = new $root.waiternow.common.TextProto.LocalizedTextProto();
                    if (object.text != null)
                        message.text = String(object.text);
                    switch (object.language) {
                    default:
                        if (typeof object.language === "number") {
                            message.language = object.language;
                            break;
                        }
                        break;
                    case "LANGUAGE_UNSPECIFIED":
                    case 0:
                        message.language = 0;
                        break;
                    case "ENGLISH":
                    case 1:
                        message.language = 1;
                        break;
                    case "SPANISH":
                    case 2:
                        message.language = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a LocalizedTextProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {waiternow.common.TextProto.LocalizedTextProto} message LocalizedTextProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocalizedTextProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.text = "";
                        object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                    }
                    if (message.text != null && message.hasOwnProperty("text"))
                        object.text = message.text;
                    if (message.language != null && message.hasOwnProperty("language"))
                        object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                    return object;
                };

                /**
                 * Converts this LocalizedTextProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocalizedTextProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocalizedTextProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.TextProto.LocalizedTextProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocalizedTextProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.TextProto.LocalizedTextProto";
                };

                return LocalizedTextProto;
            })();

            return TextProto;
        })();

        common.ImageProto = (function() {

            /**
             * Properties of an ImageProto.
             * @memberof waiternow.common
             * @interface IImageProto
             * @property {string|null} [imageId] ImageProto imageId
             * @property {string|null} [imageUrl] ImageProto imageUrl
             */

            /**
             * Constructs a new ImageProto.
             * @memberof waiternow.common
             * @classdesc Represents an ImageProto.
             * @implements IImageProto
             * @constructor
             * @param {waiternow.common.IImageProto=} [properties] Properties to set
             */
            function ImageProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ImageProto imageId.
             * @member {string} imageId
             * @memberof waiternow.common.ImageProto
             * @instance
             */
            ImageProto.prototype.imageId = "";

            /**
             * ImageProto imageUrl.
             * @member {string} imageUrl
             * @memberof waiternow.common.ImageProto
             * @instance
             */
            ImageProto.prototype.imageUrl = "";

            /**
             * Creates a new ImageProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {waiternow.common.IImageProto=} [properties] Properties to set
             * @returns {waiternow.common.ImageProto} ImageProto instance
             */
            ImageProto.create = function create(properties) {
                return new ImageProto(properties);
            };

            /**
             * Encodes the specified ImageProto message. Does not implicitly {@link waiternow.common.ImageProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {waiternow.common.IImageProto} message ImageProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImageProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.imageId);
                if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.imageUrl);
                return writer;
            };

            /**
             * Encodes the specified ImageProto message, length delimited. Does not implicitly {@link waiternow.common.ImageProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {waiternow.common.IImageProto} message ImageProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ImageProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an ImageProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ImageProto} ImageProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImageProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ImageProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.imageId = reader.string();
                            break;
                        }
                    case 2: {
                            message.imageUrl = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an ImageProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ImageProto} ImageProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ImageProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an ImageProto message.
             * @function verify
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ImageProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    if (!$util.isString(message.imageId))
                        return "imageId: string expected";
                if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                    if (!$util.isString(message.imageUrl))
                        return "imageUrl: string expected";
                return null;
            };

            /**
             * Creates an ImageProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ImageProto} ImageProto
             */
            ImageProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ImageProto)
                    return object;
                var message = new $root.waiternow.common.ImageProto();
                if (object.imageId != null)
                    message.imageId = String(object.imageId);
                if (object.imageUrl != null)
                    message.imageUrl = String(object.imageUrl);
                return message;
            };

            /**
             * Creates a plain object from an ImageProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {waiternow.common.ImageProto} message ImageProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ImageProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.imageId = "";
                    object.imageUrl = "";
                }
                if (message.imageId != null && message.hasOwnProperty("imageId"))
                    object.imageId = message.imageId;
                if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                    object.imageUrl = message.imageUrl;
                return object;
            };

            /**
             * Converts this ImageProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ImageProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ImageProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ImageProto
             * @function getTypeUrl
             * @memberof waiternow.common.ImageProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ImageProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ImageProto";
            };

            return ImageProto;
        })();

        /**
         * SelectionType enum.
         * @name waiternow.common.SelectionType
         * @enum {number}
         * @property {number} SELECTION_TYPE_UNSPECIFIED=0 SELECTION_TYPE_UNSPECIFIED value
         * @property {number} SINGLE=1 SINGLE value
         * @property {number} MULTIPLE=2 MULTIPLE value
         */
        common.SelectionType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SELECTION_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "SINGLE"] = 1;
            values[valuesById[2] = "MULTIPLE"] = 2;
            return values;
        })();

        /**
         * ReachMedium enum.
         * @name waiternow.common.ReachMedium
         * @enum {number}
         * @property {number} REACH_MEDIUM_UNDEFINED=0 REACH_MEDIUM_UNDEFINED value
         * @property {number} GOOGLE=1 GOOGLE value
         * @property {number} FACEBOOK=2 FACEBOOK value
         * @property {number} INSTRAGRAM=3 INSTRAGRAM value
         * @property {number} LINKED_IN=4 LINKED_IN value
         * @property {number} RECOMMENDATION=5 RECOMMENDATION value
         * @property {number} SALESPERSON=6 SALESPERSON value
         * @property {number} OTHER=7 OTHER value
         */
        common.ReachMedium = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "REACH_MEDIUM_UNDEFINED"] = 0;
            values[valuesById[1] = "GOOGLE"] = 1;
            values[valuesById[2] = "FACEBOOK"] = 2;
            values[valuesById[3] = "INSTRAGRAM"] = 3;
            values[valuesById[4] = "LINKED_IN"] = 4;
            values[valuesById[5] = "RECOMMENDATION"] = 5;
            values[valuesById[6] = "SALESPERSON"] = 6;
            values[valuesById[7] = "OTHER"] = 7;
            return values;
        })();

        common.PeriodProto = (function() {

            /**
             * Properties of a PeriodProto.
             * @memberof waiternow.common
             * @interface IPeriodProto
             * @property {number|null} [year] PeriodProto year
             * @property {waiternow.common.Month|null} [month] PeriodProto month
             * @property {waiternow.common.ITimestampRangeProto|null} [range] PeriodProto range
             */

            /**
             * Constructs a new PeriodProto.
             * @memberof waiternow.common
             * @classdesc Represents a PeriodProto.
             * @implements IPeriodProto
             * @constructor
             * @param {waiternow.common.IPeriodProto=} [properties] Properties to set
             */
            function PeriodProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PeriodProto year.
             * @member {number} year
             * @memberof waiternow.common.PeriodProto
             * @instance
             */
            PeriodProto.prototype.year = 0;

            /**
             * PeriodProto month.
             * @member {waiternow.common.Month} month
             * @memberof waiternow.common.PeriodProto
             * @instance
             */
            PeriodProto.prototype.month = 0;

            /**
             * PeriodProto range.
             * @member {waiternow.common.ITimestampRangeProto|null|undefined} range
             * @memberof waiternow.common.PeriodProto
             * @instance
             */
            PeriodProto.prototype.range = null;

            /**
             * Creates a new PeriodProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {waiternow.common.IPeriodProto=} [properties] Properties to set
             * @returns {waiternow.common.PeriodProto} PeriodProto instance
             */
            PeriodProto.create = function create(properties) {
                return new PeriodProto(properties);
            };

            /**
             * Encodes the specified PeriodProto message. Does not implicitly {@link waiternow.common.PeriodProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {waiternow.common.IPeriodProto} message PeriodProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PeriodProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.year != null && Object.hasOwnProperty.call(message, "year"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.year);
                if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.month);
                if (message.range != null && Object.hasOwnProperty.call(message, "range"))
                    $root.waiternow.common.TimestampRangeProto.encode(message.range, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PeriodProto message, length delimited. Does not implicitly {@link waiternow.common.PeriodProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {waiternow.common.IPeriodProto} message PeriodProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PeriodProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PeriodProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PeriodProto} PeriodProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PeriodProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PeriodProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.year = reader.int32();
                            break;
                        }
                    case 2: {
                            message.month = reader.int32();
                            break;
                        }
                    case 3: {
                            message.range = $root.waiternow.common.TimestampRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PeriodProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PeriodProto} PeriodProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PeriodProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PeriodProto message.
             * @function verify
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PeriodProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.year != null && message.hasOwnProperty("year"))
                    if (!$util.isInteger(message.year))
                        return "year: integer expected";
                if (message.month != null && message.hasOwnProperty("month"))
                    switch (message.month) {
                    default:
                        return "month: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.range != null && message.hasOwnProperty("range")) {
                    var error = $root.waiternow.common.TimestampRangeProto.verify(message.range);
                    if (error)
                        return "range." + error;
                }
                return null;
            };

            /**
             * Creates a PeriodProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PeriodProto} PeriodProto
             */
            PeriodProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PeriodProto)
                    return object;
                var message = new $root.waiternow.common.PeriodProto();
                if (object.year != null)
                    message.year = object.year | 0;
                switch (object.month) {
                default:
                    if (typeof object.month === "number") {
                        message.month = object.month;
                        break;
                    }
                    break;
                case "MONTH_UNSPECIFIED":
                case 0:
                    message.month = 0;
                    break;
                case "JANUARY":
                case 1:
                    message.month = 1;
                    break;
                case "FEBRUARY":
                case 2:
                    message.month = 2;
                    break;
                case "MARCH":
                case 3:
                    message.month = 3;
                    break;
                case "APRIL":
                case 4:
                    message.month = 4;
                    break;
                case "MAY":
                case 5:
                    message.month = 5;
                    break;
                case "JUNE":
                case 6:
                    message.month = 6;
                    break;
                case "JULY":
                case 7:
                    message.month = 7;
                    break;
                case "AUGUST":
                case 8:
                    message.month = 8;
                    break;
                case "SEPTEMBER":
                case 9:
                    message.month = 9;
                    break;
                case "OCTOBER":
                case 10:
                    message.month = 10;
                    break;
                case "NOVEMBER":
                case 11:
                    message.month = 11;
                    break;
                case "DECEMBER":
                case 12:
                    message.month = 12;
                    break;
                }
                if (object.range != null) {
                    if (typeof object.range !== "object")
                        throw TypeError(".waiternow.common.PeriodProto.range: object expected");
                    message.range = $root.waiternow.common.TimestampRangeProto.fromObject(object.range);
                }
                return message;
            };

            /**
             * Creates a plain object from a PeriodProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {waiternow.common.PeriodProto} message PeriodProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PeriodProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.year = 0;
                    object.month = options.enums === String ? "MONTH_UNSPECIFIED" : 0;
                    object.range = null;
                }
                if (message.year != null && message.hasOwnProperty("year"))
                    object.year = message.year;
                if (message.month != null && message.hasOwnProperty("month"))
                    object.month = options.enums === String ? $root.waiternow.common.Month[message.month] === undefined ? message.month : $root.waiternow.common.Month[message.month] : message.month;
                if (message.range != null && message.hasOwnProperty("range"))
                    object.range = $root.waiternow.common.TimestampRangeProto.toObject(message.range, options);
                return object;
            };

            /**
             * Converts this PeriodProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PeriodProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PeriodProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PeriodProto
             * @function getTypeUrl
             * @memberof waiternow.common.PeriodProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PeriodProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PeriodProto";
            };

            return PeriodProto;
        })();

        /**
         * EmailRecipient enum.
         * @name waiternow.common.EmailRecipient
         * @enum {number}
         * @property {number} EMAIL_RECIPIENT_UNSPECIFIED=0 EMAIL_RECIPIENT_UNSPECIFIED value
         * @property {number} INFO=1 INFO value
         * @property {number} SYSTEM=2 SYSTEM value
         * @property {number} SUPPORT=3 SUPPORT value
         * @property {number} CONTACT=4 CONTACT value
         * @property {number} FEEDBACK=5 FEEDBACK value
         * @property {number} SALES=6 SALES value
         * @property {number} BILLING=7 BILLING value
         * @property {number} QR_CODE_SALES=8 QR_CODE_SALES value
         */
        common.EmailRecipient = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "EMAIL_RECIPIENT_UNSPECIFIED"] = 0;
            values[valuesById[1] = "INFO"] = 1;
            values[valuesById[2] = "SYSTEM"] = 2;
            values[valuesById[3] = "SUPPORT"] = 3;
            values[valuesById[4] = "CONTACT"] = 4;
            values[valuesById[5] = "FEEDBACK"] = 5;
            values[valuesById[6] = "SALES"] = 6;
            values[valuesById[7] = "BILLING"] = 7;
            values[valuesById[8] = "QR_CODE_SALES"] = 8;
            return values;
        })();

        common.ContinuationTokenProto = (function() {

            /**
             * Properties of a ContinuationTokenProto.
             * @memberof waiternow.common
             * @interface IContinuationTokenProto
             * @property {string|null} [lastFetchedId] ContinuationTokenProto lastFetchedId
             * @property {google.protobuf.ITimestamp|null} [lastFetchedTimestamp] ContinuationTokenProto lastFetchedTimestamp
             */

            /**
             * Constructs a new ContinuationTokenProto.
             * @memberof waiternow.common
             * @classdesc Represents a ContinuationTokenProto.
             * @implements IContinuationTokenProto
             * @constructor
             * @param {waiternow.common.IContinuationTokenProto=} [properties] Properties to set
             */
            function ContinuationTokenProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ContinuationTokenProto lastFetchedId.
             * @member {string} lastFetchedId
             * @memberof waiternow.common.ContinuationTokenProto
             * @instance
             */
            ContinuationTokenProto.prototype.lastFetchedId = "";

            /**
             * ContinuationTokenProto lastFetchedTimestamp.
             * @member {google.protobuf.ITimestamp|null|undefined} lastFetchedTimestamp
             * @memberof waiternow.common.ContinuationTokenProto
             * @instance
             */
            ContinuationTokenProto.prototype.lastFetchedTimestamp = null;

            /**
             * Creates a new ContinuationTokenProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {waiternow.common.IContinuationTokenProto=} [properties] Properties to set
             * @returns {waiternow.common.ContinuationTokenProto} ContinuationTokenProto instance
             */
            ContinuationTokenProto.create = function create(properties) {
                return new ContinuationTokenProto(properties);
            };

            /**
             * Encodes the specified ContinuationTokenProto message. Does not implicitly {@link waiternow.common.ContinuationTokenProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {waiternow.common.IContinuationTokenProto} message ContinuationTokenProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ContinuationTokenProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.lastFetchedId != null && Object.hasOwnProperty.call(message, "lastFetchedId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.lastFetchedId);
                if (message.lastFetchedTimestamp != null && Object.hasOwnProperty.call(message, "lastFetchedTimestamp"))
                    $root.google.protobuf.Timestamp.encode(message.lastFetchedTimestamp, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ContinuationTokenProto message, length delimited. Does not implicitly {@link waiternow.common.ContinuationTokenProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {waiternow.common.IContinuationTokenProto} message ContinuationTokenProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ContinuationTokenProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ContinuationTokenProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ContinuationTokenProto} ContinuationTokenProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ContinuationTokenProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ContinuationTokenProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.lastFetchedId = reader.string();
                            break;
                        }
                    case 2: {
                            message.lastFetchedTimestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ContinuationTokenProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ContinuationTokenProto} ContinuationTokenProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ContinuationTokenProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ContinuationTokenProto message.
             * @function verify
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ContinuationTokenProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.lastFetchedId != null && message.hasOwnProperty("lastFetchedId"))
                    if (!$util.isString(message.lastFetchedId))
                        return "lastFetchedId: string expected";
                if (message.lastFetchedTimestamp != null && message.hasOwnProperty("lastFetchedTimestamp")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastFetchedTimestamp);
                    if (error)
                        return "lastFetchedTimestamp." + error;
                }
                return null;
            };

            /**
             * Creates a ContinuationTokenProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ContinuationTokenProto} ContinuationTokenProto
             */
            ContinuationTokenProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ContinuationTokenProto)
                    return object;
                var message = new $root.waiternow.common.ContinuationTokenProto();
                if (object.lastFetchedId != null)
                    message.lastFetchedId = String(object.lastFetchedId);
                if (object.lastFetchedTimestamp != null) {
                    if (typeof object.lastFetchedTimestamp !== "object")
                        throw TypeError(".waiternow.common.ContinuationTokenProto.lastFetchedTimestamp: object expected");
                    message.lastFetchedTimestamp = $root.google.protobuf.Timestamp.fromObject(object.lastFetchedTimestamp);
                }
                return message;
            };

            /**
             * Creates a plain object from a ContinuationTokenProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {waiternow.common.ContinuationTokenProto} message ContinuationTokenProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ContinuationTokenProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.lastFetchedId = "";
                    object.lastFetchedTimestamp = null;
                }
                if (message.lastFetchedId != null && message.hasOwnProperty("lastFetchedId"))
                    object.lastFetchedId = message.lastFetchedId;
                if (message.lastFetchedTimestamp != null && message.hasOwnProperty("lastFetchedTimestamp"))
                    object.lastFetchedTimestamp = $root.google.protobuf.Timestamp.toObject(message.lastFetchedTimestamp, options);
                return object;
            };

            /**
             * Converts this ContinuationTokenProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ContinuationTokenProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ContinuationTokenProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ContinuationTokenProto
             * @function getTypeUrl
             * @memberof waiternow.common.ContinuationTokenProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ContinuationTokenProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ContinuationTokenProto";
            };

            return ContinuationTokenProto;
        })();

        common.TaxesProto = (function() {

            /**
             * Properties of a TaxesProto.
             * @memberof waiternow.common
             * @interface ITaxesProto
             * @property {Array.<waiternow.common.TaxesProto.ITaxProto>|null} [saleTaxes] TaxesProto saleTaxes
             * @property {waiternow.common.TaxesProto.ITaxProto|null} [alcoholTax] TaxesProto alcoholTax
             * @property {waiternow.common.IDecimalProto|null} [salesTax] TaxesProto salesTax
             * @property {waiternow.common.TaxesProto.Type|null} [type] TaxesProto type
             */

            /**
             * Constructs a new TaxesProto.
             * @memberof waiternow.common
             * @classdesc Represents a TaxesProto.
             * @implements ITaxesProto
             * @constructor
             * @param {waiternow.common.ITaxesProto=} [properties] Properties to set
             */
            function TaxesProto(properties) {
                this.saleTaxes = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * TaxesProto saleTaxes.
             * @member {Array.<waiternow.common.TaxesProto.ITaxProto>} saleTaxes
             * @memberof waiternow.common.TaxesProto
             * @instance
             */
            TaxesProto.prototype.saleTaxes = $util.emptyArray;

            /**
             * TaxesProto alcoholTax.
             * @member {waiternow.common.TaxesProto.ITaxProto|null|undefined} alcoholTax
             * @memberof waiternow.common.TaxesProto
             * @instance
             */
            TaxesProto.prototype.alcoholTax = null;

            /**
             * TaxesProto salesTax.
             * @member {waiternow.common.IDecimalProto|null|undefined} salesTax
             * @memberof waiternow.common.TaxesProto
             * @instance
             */
            TaxesProto.prototype.salesTax = null;

            /**
             * TaxesProto type.
             * @member {waiternow.common.TaxesProto.Type} type
             * @memberof waiternow.common.TaxesProto
             * @instance
             */
            TaxesProto.prototype.type = 0;

            /**
             * Creates a new TaxesProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {waiternow.common.ITaxesProto=} [properties] Properties to set
             * @returns {waiternow.common.TaxesProto} TaxesProto instance
             */
            TaxesProto.create = function create(properties) {
                return new TaxesProto(properties);
            };

            /**
             * Encodes the specified TaxesProto message. Does not implicitly {@link waiternow.common.TaxesProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {waiternow.common.ITaxesProto} message TaxesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaxesProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.saleTaxes != null && message.saleTaxes.length)
                    for (var i = 0; i < message.saleTaxes.length; ++i)
                        $root.waiternow.common.TaxesProto.TaxProto.encode(message.saleTaxes[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.alcoholTax != null && Object.hasOwnProperty.call(message, "alcoholTax"))
                    $root.waiternow.common.TaxesProto.TaxProto.encode(message.alcoholTax, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.salesTax != null && Object.hasOwnProperty.call(message, "salesTax"))
                    $root.waiternow.common.DecimalProto.encode(message.salesTax, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                return writer;
            };

            /**
             * Encodes the specified TaxesProto message, length delimited. Does not implicitly {@link waiternow.common.TaxesProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {waiternow.common.ITaxesProto} message TaxesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TaxesProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TaxesProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.TaxesProto} TaxesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaxesProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TaxesProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.saleTaxes && message.saleTaxes.length))
                                message.saleTaxes = [];
                            message.saleTaxes.push($root.waiternow.common.TaxesProto.TaxProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.alcoholTax = $root.waiternow.common.TaxesProto.TaxProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.salesTax = $root.waiternow.common.DecimalProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.type = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TaxesProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.TaxesProto} TaxesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TaxesProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TaxesProto message.
             * @function verify
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TaxesProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.saleTaxes != null && message.hasOwnProperty("saleTaxes")) {
                    if (!Array.isArray(message.saleTaxes))
                        return "saleTaxes: array expected";
                    for (var i = 0; i < message.saleTaxes.length; ++i) {
                        var error = $root.waiternow.common.TaxesProto.TaxProto.verify(message.saleTaxes[i]);
                        if (error)
                            return "saleTaxes." + error;
                    }
                }
                if (message.alcoholTax != null && message.hasOwnProperty("alcoholTax")) {
                    var error = $root.waiternow.common.TaxesProto.TaxProto.verify(message.alcoholTax);
                    if (error)
                        return "alcoholTax." + error;
                }
                if (message.salesTax != null && message.hasOwnProperty("salesTax")) {
                    var error = $root.waiternow.common.DecimalProto.verify(message.salesTax);
                    if (error)
                        return "salesTax." + error;
                }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                return null;
            };

            /**
             * Creates a TaxesProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.TaxesProto} TaxesProto
             */
            TaxesProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.TaxesProto)
                    return object;
                var message = new $root.waiternow.common.TaxesProto();
                if (object.saleTaxes) {
                    if (!Array.isArray(object.saleTaxes))
                        throw TypeError(".waiternow.common.TaxesProto.saleTaxes: array expected");
                    message.saleTaxes = [];
                    for (var i = 0; i < object.saleTaxes.length; ++i) {
                        if (typeof object.saleTaxes[i] !== "object")
                            throw TypeError(".waiternow.common.TaxesProto.saleTaxes: object expected");
                        message.saleTaxes[i] = $root.waiternow.common.TaxesProto.TaxProto.fromObject(object.saleTaxes[i]);
                    }
                }
                if (object.alcoholTax != null) {
                    if (typeof object.alcoholTax !== "object")
                        throw TypeError(".waiternow.common.TaxesProto.alcoholTax: object expected");
                    message.alcoholTax = $root.waiternow.common.TaxesProto.TaxProto.fromObject(object.alcoholTax);
                }
                if (object.salesTax != null) {
                    if (typeof object.salesTax !== "object")
                        throw TypeError(".waiternow.common.TaxesProto.salesTax: object expected");
                    message.salesTax = $root.waiternow.common.DecimalProto.fromObject(object.salesTax);
                }
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "TYPE_UNSPECIFIED":
                case 0:
                    message.type = 0;
                    break;
                case "UNALTERABLE":
                case 1:
                    message.type = 1;
                    break;
                case "FALLBACK":
                case 2:
                    message.type = 2;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a TaxesProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {waiternow.common.TaxesProto} message TaxesProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TaxesProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.saleTaxes = [];
                if (options.defaults) {
                    object.alcoholTax = null;
                    object.salesTax = null;
                    object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                }
                if (message.saleTaxes && message.saleTaxes.length) {
                    object.saleTaxes = [];
                    for (var j = 0; j < message.saleTaxes.length; ++j)
                        object.saleTaxes[j] = $root.waiternow.common.TaxesProto.TaxProto.toObject(message.saleTaxes[j], options);
                }
                if (message.alcoholTax != null && message.hasOwnProperty("alcoholTax"))
                    object.alcoholTax = $root.waiternow.common.TaxesProto.TaxProto.toObject(message.alcoholTax, options);
                if (message.salesTax != null && message.hasOwnProperty("salesTax"))
                    object.salesTax = $root.waiternow.common.DecimalProto.toObject(message.salesTax, options);
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.waiternow.common.TaxesProto.Type[message.type] === undefined ? message.type : $root.waiternow.common.TaxesProto.Type[message.type] : message.type;
                return object;
            };

            /**
             * Converts this TaxesProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.TaxesProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TaxesProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TaxesProto
             * @function getTypeUrl
             * @memberof waiternow.common.TaxesProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TaxesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.TaxesProto";
            };

            TaxesProto.TaxProto = (function() {

                /**
                 * Properties of a TaxProto.
                 * @memberof waiternow.common.TaxesProto
                 * @interface ITaxProto
                 * @property {string|null} [name] TaxProto name
                 * @property {waiternow.common.IDecimalProto|null} [percentage] TaxProto percentage
                 */

                /**
                 * Constructs a new TaxProto.
                 * @memberof waiternow.common.TaxesProto
                 * @classdesc Represents a TaxProto.
                 * @implements ITaxProto
                 * @constructor
                 * @param {waiternow.common.TaxesProto.ITaxProto=} [properties] Properties to set
                 */
                function TaxProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TaxProto name.
                 * @member {string} name
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @instance
                 */
                TaxProto.prototype.name = "";

                /**
                 * TaxProto percentage.
                 * @member {waiternow.common.IDecimalProto|null|undefined} percentage
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @instance
                 */
                TaxProto.prototype.percentage = null;

                /**
                 * Creates a new TaxProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {waiternow.common.TaxesProto.ITaxProto=} [properties] Properties to set
                 * @returns {waiternow.common.TaxesProto.TaxProto} TaxProto instance
                 */
                TaxProto.create = function create(properties) {
                    return new TaxProto(properties);
                };

                /**
                 * Encodes the specified TaxProto message. Does not implicitly {@link waiternow.common.TaxesProto.TaxProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {waiternow.common.TaxesProto.ITaxProto} message TaxProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaxProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.percentage != null && Object.hasOwnProperty.call(message, "percentage"))
                        $root.waiternow.common.DecimalProto.encode(message.percentage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified TaxProto message, length delimited. Does not implicitly {@link waiternow.common.TaxesProto.TaxProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {waiternow.common.TaxesProto.ITaxProto} message TaxProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TaxProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TaxProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.TaxesProto.TaxProto} TaxProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaxProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TaxesProto.TaxProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 3: {
                                message.percentage = $root.waiternow.common.DecimalProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TaxProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.TaxesProto.TaxProto} TaxProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TaxProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TaxProto message.
                 * @function verify
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TaxProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.percentage != null && message.hasOwnProperty("percentage")) {
                        var error = $root.waiternow.common.DecimalProto.verify(message.percentage);
                        if (error)
                            return "percentage." + error;
                    }
                    return null;
                };

                /**
                 * Creates a TaxProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.TaxesProto.TaxProto} TaxProto
                 */
                TaxProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.TaxesProto.TaxProto)
                        return object;
                    var message = new $root.waiternow.common.TaxesProto.TaxProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.percentage != null) {
                        if (typeof object.percentage !== "object")
                            throw TypeError(".waiternow.common.TaxesProto.TaxProto.percentage: object expected");
                        message.percentage = $root.waiternow.common.DecimalProto.fromObject(object.percentage);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a TaxProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {waiternow.common.TaxesProto.TaxProto} message TaxProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TaxProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = "";
                        object.percentage = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.percentage != null && message.hasOwnProperty("percentage"))
                        object.percentage = $root.waiternow.common.DecimalProto.toObject(message.percentage, options);
                    return object;
                };

                /**
                 * Converts this TaxProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TaxProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TaxProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.TaxesProto.TaxProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TaxProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.TaxesProto.TaxProto";
                };

                return TaxProto;
            })();

            /**
             * Type enum.
             * @name waiternow.common.TaxesProto.Type
             * @enum {number}
             * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
             * @property {number} UNALTERABLE=1 UNALTERABLE value
             * @property {number} FALLBACK=2 FALLBACK value
             */
            TaxesProto.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "UNALTERABLE"] = 1;
                values[valuesById[2] = "FALLBACK"] = 2;
                return values;
            })();

            return TaxesProto;
        })();

        /**
         * SortOrder enum.
         * @name waiternow.common.SortOrder
         * @enum {number}
         * @property {number} SORT_ORDER_UNSPECIFIED=0 SORT_ORDER_UNSPECIFIED value
         * @property {number} ASCENDING=1 ASCENDING value
         * @property {number} DESCENDING=2 DESCENDING value
         */
        common.SortOrder = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "SORT_ORDER_UNSPECIFIED"] = 0;
            values[valuesById[1] = "ASCENDING"] = 1;
            values[valuesById[2] = "DESCENDING"] = 2;
            return values;
        })();

        common.DerivedQuantityConfigProto = (function() {

            /**
             * Properties of a DerivedQuantityConfigProto.
             * @memberof waiternow.common
             * @interface IDerivedQuantityConfigProto
             * @property {waiternow.common.IMoneyProto|null} [fixedAmount] DerivedQuantityConfigProto fixedAmount
             * @property {waiternow.common.IDecimalProto|null} [percentage] DerivedQuantityConfigProto percentage
             */

            /**
             * Constructs a new DerivedQuantityConfigProto.
             * @memberof waiternow.common
             * @classdesc Represents a DerivedQuantityConfigProto.
             * @implements IDerivedQuantityConfigProto
             * @constructor
             * @param {waiternow.common.IDerivedQuantityConfigProto=} [properties] Properties to set
             */
            function DerivedQuantityConfigProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DerivedQuantityConfigProto fixedAmount.
             * @member {waiternow.common.IMoneyProto|null|undefined} fixedAmount
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @instance
             */
            DerivedQuantityConfigProto.prototype.fixedAmount = null;

            /**
             * DerivedQuantityConfigProto percentage.
             * @member {waiternow.common.IDecimalProto|null|undefined} percentage
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @instance
             */
            DerivedQuantityConfigProto.prototype.percentage = null;

            /**
             * Creates a new DerivedQuantityConfigProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {waiternow.common.IDerivedQuantityConfigProto=} [properties] Properties to set
             * @returns {waiternow.common.DerivedQuantityConfigProto} DerivedQuantityConfigProto instance
             */
            DerivedQuantityConfigProto.create = function create(properties) {
                return new DerivedQuantityConfigProto(properties);
            };

            /**
             * Encodes the specified DerivedQuantityConfigProto message. Does not implicitly {@link waiternow.common.DerivedQuantityConfigProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {waiternow.common.IDerivedQuantityConfigProto} message DerivedQuantityConfigProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DerivedQuantityConfigProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.fixedAmount != null && Object.hasOwnProperty.call(message, "fixedAmount"))
                    $root.waiternow.common.MoneyProto.encode(message.fixedAmount, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.percentage != null && Object.hasOwnProperty.call(message, "percentage"))
                    $root.waiternow.common.DecimalProto.encode(message.percentage, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DerivedQuantityConfigProto message, length delimited. Does not implicitly {@link waiternow.common.DerivedQuantityConfigProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {waiternow.common.IDerivedQuantityConfigProto} message DerivedQuantityConfigProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DerivedQuantityConfigProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DerivedQuantityConfigProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DerivedQuantityConfigProto} DerivedQuantityConfigProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DerivedQuantityConfigProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DerivedQuantityConfigProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            message.fixedAmount = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.percentage = $root.waiternow.common.DecimalProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DerivedQuantityConfigProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DerivedQuantityConfigProto} DerivedQuantityConfigProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DerivedQuantityConfigProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DerivedQuantityConfigProto message.
             * @function verify
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DerivedQuantityConfigProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.fixedAmount != null && message.hasOwnProperty("fixedAmount")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.fixedAmount);
                    if (error)
                        return "fixedAmount." + error;
                }
                if (message.percentage != null && message.hasOwnProperty("percentage")) {
                    var error = $root.waiternow.common.DecimalProto.verify(message.percentage);
                    if (error)
                        return "percentage." + error;
                }
                return null;
            };

            /**
             * Creates a DerivedQuantityConfigProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DerivedQuantityConfigProto} DerivedQuantityConfigProto
             */
            DerivedQuantityConfigProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DerivedQuantityConfigProto)
                    return object;
                var message = new $root.waiternow.common.DerivedQuantityConfigProto();
                if (object.fixedAmount != null) {
                    if (typeof object.fixedAmount !== "object")
                        throw TypeError(".waiternow.common.DerivedQuantityConfigProto.fixedAmount: object expected");
                    message.fixedAmount = $root.waiternow.common.MoneyProto.fromObject(object.fixedAmount);
                }
                if (object.percentage != null) {
                    if (typeof object.percentage !== "object")
                        throw TypeError(".waiternow.common.DerivedQuantityConfigProto.percentage: object expected");
                    message.percentage = $root.waiternow.common.DecimalProto.fromObject(object.percentage);
                }
                return message;
            };

            /**
             * Creates a plain object from a DerivedQuantityConfigProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {waiternow.common.DerivedQuantityConfigProto} message DerivedQuantityConfigProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DerivedQuantityConfigProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.fixedAmount = null;
                    object.percentage = null;
                }
                if (message.fixedAmount != null && message.hasOwnProperty("fixedAmount"))
                    object.fixedAmount = $root.waiternow.common.MoneyProto.toObject(message.fixedAmount, options);
                if (message.percentage != null && message.hasOwnProperty("percentage"))
                    object.percentage = $root.waiternow.common.DecimalProto.toObject(message.percentage, options);
                return object;
            };

            /**
             * Converts this DerivedQuantityConfigProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DerivedQuantityConfigProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DerivedQuantityConfigProto
             * @function getTypeUrl
             * @memberof waiternow.common.DerivedQuantityConfigProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DerivedQuantityConfigProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DerivedQuantityConfigProto";
            };

            return DerivedQuantityConfigProto;
        })();

        /**
         * DeliveryCarrier enum.
         * @name waiternow.common.DeliveryCarrier
         * @enum {number}
         * @property {number} DELIVERY_CARRIER_UNSPECIFIED=0 DELIVERY_CARRIER_UNSPECIFIED value
         * @property {number} SELF=1 SELF value
         * @property {number} DOORDASH=2 DOORDASH value
         */
        common.DeliveryCarrier = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "DELIVERY_CARRIER_UNSPECIFIED"] = 0;
            values[valuesById[1] = "SELF"] = 1;
            values[valuesById[2] = "DOORDASH"] = 2;
            return values;
        })();

        common.GeoDistanceProto = (function() {

            /**
             * Properties of a GeoDistanceProto.
             * @memberof waiternow.common
             * @interface IGeoDistanceProto
             * @property {waiternow.common.IDistanceQuantityProto|null} [distance] GeoDistanceProto distance
             * @property {google.protobuf.IDuration|null} [drivingDuration] GeoDistanceProto drivingDuration
             * @property {google.protobuf.IDuration|null} [drivingDurationInTraffic] GeoDistanceProto drivingDurationInTraffic
             */

            /**
             * Constructs a new GeoDistanceProto.
             * @memberof waiternow.common
             * @classdesc Represents a GeoDistanceProto.
             * @implements IGeoDistanceProto
             * @constructor
             * @param {waiternow.common.IGeoDistanceProto=} [properties] Properties to set
             */
            function GeoDistanceProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeoDistanceProto distance.
             * @member {waiternow.common.IDistanceQuantityProto|null|undefined} distance
             * @memberof waiternow.common.GeoDistanceProto
             * @instance
             */
            GeoDistanceProto.prototype.distance = null;

            /**
             * GeoDistanceProto drivingDuration.
             * @member {google.protobuf.IDuration|null|undefined} drivingDuration
             * @memberof waiternow.common.GeoDistanceProto
             * @instance
             */
            GeoDistanceProto.prototype.drivingDuration = null;

            /**
             * GeoDistanceProto drivingDurationInTraffic.
             * @member {google.protobuf.IDuration|null|undefined} drivingDurationInTraffic
             * @memberof waiternow.common.GeoDistanceProto
             * @instance
             */
            GeoDistanceProto.prototype.drivingDurationInTraffic = null;

            /**
             * Creates a new GeoDistanceProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {waiternow.common.IGeoDistanceProto=} [properties] Properties to set
             * @returns {waiternow.common.GeoDistanceProto} GeoDistanceProto instance
             */
            GeoDistanceProto.create = function create(properties) {
                return new GeoDistanceProto(properties);
            };

            /**
             * Encodes the specified GeoDistanceProto message. Does not implicitly {@link waiternow.common.GeoDistanceProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {waiternow.common.IGeoDistanceProto} message GeoDistanceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeoDistanceProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                    $root.waiternow.common.DistanceQuantityProto.encode(message.distance, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.drivingDuration != null && Object.hasOwnProperty.call(message, "drivingDuration"))
                    $root.google.protobuf.Duration.encode(message.drivingDuration, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.drivingDurationInTraffic != null && Object.hasOwnProperty.call(message, "drivingDurationInTraffic"))
                    $root.google.protobuf.Duration.encode(message.drivingDurationInTraffic, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GeoDistanceProto message, length delimited. Does not implicitly {@link waiternow.common.GeoDistanceProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {waiternow.common.IGeoDistanceProto} message GeoDistanceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeoDistanceProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GeoDistanceProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GeoDistanceProto} GeoDistanceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeoDistanceProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GeoDistanceProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.distance = $root.waiternow.common.DistanceQuantityProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.drivingDuration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.drivingDurationInTraffic = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GeoDistanceProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GeoDistanceProto} GeoDistanceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeoDistanceProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GeoDistanceProto message.
             * @function verify
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeoDistanceProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.distance != null && message.hasOwnProperty("distance")) {
                    var error = $root.waiternow.common.DistanceQuantityProto.verify(message.distance);
                    if (error)
                        return "distance." + error;
                }
                if (message.drivingDuration != null && message.hasOwnProperty("drivingDuration")) {
                    var error = $root.google.protobuf.Duration.verify(message.drivingDuration);
                    if (error)
                        return "drivingDuration." + error;
                }
                if (message.drivingDurationInTraffic != null && message.hasOwnProperty("drivingDurationInTraffic")) {
                    var error = $root.google.protobuf.Duration.verify(message.drivingDurationInTraffic);
                    if (error)
                        return "drivingDurationInTraffic." + error;
                }
                return null;
            };

            /**
             * Creates a GeoDistanceProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GeoDistanceProto} GeoDistanceProto
             */
            GeoDistanceProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GeoDistanceProto)
                    return object;
                var message = new $root.waiternow.common.GeoDistanceProto();
                if (object.distance != null) {
                    if (typeof object.distance !== "object")
                        throw TypeError(".waiternow.common.GeoDistanceProto.distance: object expected");
                    message.distance = $root.waiternow.common.DistanceQuantityProto.fromObject(object.distance);
                }
                if (object.drivingDuration != null) {
                    if (typeof object.drivingDuration !== "object")
                        throw TypeError(".waiternow.common.GeoDistanceProto.drivingDuration: object expected");
                    message.drivingDuration = $root.google.protobuf.Duration.fromObject(object.drivingDuration);
                }
                if (object.drivingDurationInTraffic != null) {
                    if (typeof object.drivingDurationInTraffic !== "object")
                        throw TypeError(".waiternow.common.GeoDistanceProto.drivingDurationInTraffic: object expected");
                    message.drivingDurationInTraffic = $root.google.protobuf.Duration.fromObject(object.drivingDurationInTraffic);
                }
                return message;
            };

            /**
             * Creates a plain object from a GeoDistanceProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {waiternow.common.GeoDistanceProto} message GeoDistanceProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeoDistanceProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.distance = null;
                    object.drivingDuration = null;
                    object.drivingDurationInTraffic = null;
                }
                if (message.distance != null && message.hasOwnProperty("distance"))
                    object.distance = $root.waiternow.common.DistanceQuantityProto.toObject(message.distance, options);
                if (message.drivingDuration != null && message.hasOwnProperty("drivingDuration"))
                    object.drivingDuration = $root.google.protobuf.Duration.toObject(message.drivingDuration, options);
                if (message.drivingDurationInTraffic != null && message.hasOwnProperty("drivingDurationInTraffic"))
                    object.drivingDurationInTraffic = $root.google.protobuf.Duration.toObject(message.drivingDurationInTraffic, options);
                return object;
            };

            /**
             * Converts this GeoDistanceProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GeoDistanceProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeoDistanceProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GeoDistanceProto
             * @function getTypeUrl
             * @memberof waiternow.common.GeoDistanceProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GeoDistanceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GeoDistanceProto";
            };

            return GeoDistanceProto;
        })();

        common.PropertyProto = (function() {

            /**
             * Properties of a PropertyProto.
             * @memberof waiternow.common
             * @interface IPropertyProto
             * @property {string|null} [name] PropertyProto name
             * @property {waiternow.common.PropertyProto.IValueProto|null} [value] PropertyProto value
             */

            /**
             * Constructs a new PropertyProto.
             * @memberof waiternow.common
             * @classdesc Represents a PropertyProto.
             * @implements IPropertyProto
             * @constructor
             * @param {waiternow.common.IPropertyProto=} [properties] Properties to set
             */
            function PropertyProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PropertyProto name.
             * @member {string} name
             * @memberof waiternow.common.PropertyProto
             * @instance
             */
            PropertyProto.prototype.name = "";

            /**
             * PropertyProto value.
             * @member {waiternow.common.PropertyProto.IValueProto|null|undefined} value
             * @memberof waiternow.common.PropertyProto
             * @instance
             */
            PropertyProto.prototype.value = null;

            /**
             * Creates a new PropertyProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {waiternow.common.IPropertyProto=} [properties] Properties to set
             * @returns {waiternow.common.PropertyProto} PropertyProto instance
             */
            PropertyProto.create = function create(properties) {
                return new PropertyProto(properties);
            };

            /**
             * Encodes the specified PropertyProto message. Does not implicitly {@link waiternow.common.PropertyProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {waiternow.common.IPropertyProto} message PropertyProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PropertyProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    $root.waiternow.common.PropertyProto.ValueProto.encode(message.value, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PropertyProto message, length delimited. Does not implicitly {@link waiternow.common.PropertyProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {waiternow.common.IPropertyProto} message PropertyProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PropertyProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PropertyProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PropertyProto} PropertyProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PropertyProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PropertyProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.name = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = $root.waiternow.common.PropertyProto.ValueProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PropertyProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PropertyProto} PropertyProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PropertyProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PropertyProto message.
             * @function verify
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PropertyProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    var error = $root.waiternow.common.PropertyProto.ValueProto.verify(message.value);
                    if (error)
                        return "value." + error;
                }
                return null;
            };

            /**
             * Creates a PropertyProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PropertyProto} PropertyProto
             */
            PropertyProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PropertyProto)
                    return object;
                var message = new $root.waiternow.common.PropertyProto();
                if (object.name != null)
                    message.name = String(object.name);
                if (object.value != null) {
                    if (typeof object.value !== "object")
                        throw TypeError(".waiternow.common.PropertyProto.value: object expected");
                    message.value = $root.waiternow.common.PropertyProto.ValueProto.fromObject(object.value);
                }
                return message;
            };

            /**
             * Creates a plain object from a PropertyProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {waiternow.common.PropertyProto} message PropertyProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PropertyProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.name = "";
                    object.value = null;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = $root.waiternow.common.PropertyProto.ValueProto.toObject(message.value, options);
                return object;
            };

            /**
             * Converts this PropertyProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PropertyProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PropertyProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PropertyProto
             * @function getTypeUrl
             * @memberof waiternow.common.PropertyProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PropertyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PropertyProto";
            };

            PropertyProto.ValueProto = (function() {

                /**
                 * Properties of a ValueProto.
                 * @memberof waiternow.common.PropertyProto
                 * @interface IValueProto
                 * @property {string|null} [asString] ValueProto asString
                 * @property {boolean|null} [asBool] ValueProto asBool
                 * @property {number|null} [asInt] ValueProto asInt
                 * @property {number|null} [asLong] ValueProto asLong
                 * @property {number|null} [asUnsignedInt] ValueProto asUnsignedInt
                 * @property {number|null} [asUnsignedLong] ValueProto asUnsignedLong
                 * @property {number|null} [asFloat] ValueProto asFloat
                 * @property {number|null} [asDouble] ValueProto asDouble
                 * @property {Uint8Array|null} [asBytes] ValueProto asBytes
                 * @property {google.protobuf.IAny|null} [asAny] ValueProto asAny
                 */

                /**
                 * Constructs a new ValueProto.
                 * @memberof waiternow.common.PropertyProto
                 * @classdesc Represents a ValueProto.
                 * @implements IValueProto
                 * @constructor
                 * @param {waiternow.common.PropertyProto.IValueProto=} [properties] Properties to set
                 */
                function ValueProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ValueProto asString.
                 * @member {string|null|undefined} asString
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asString = null;

                /**
                 * ValueProto asBool.
                 * @member {boolean|null|undefined} asBool
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asBool = null;

                /**
                 * ValueProto asInt.
                 * @member {number|null|undefined} asInt
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asInt = null;

                /**
                 * ValueProto asLong.
                 * @member {number|null|undefined} asLong
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asLong = null;

                /**
                 * ValueProto asUnsignedInt.
                 * @member {number|null|undefined} asUnsignedInt
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asUnsignedInt = null;

                /**
                 * ValueProto asUnsignedLong.
                 * @member {number|null|undefined} asUnsignedLong
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asUnsignedLong = null;

                /**
                 * ValueProto asFloat.
                 * @member {number|null|undefined} asFloat
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asFloat = null;

                /**
                 * ValueProto asDouble.
                 * @member {number|null|undefined} asDouble
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asDouble = null;

                /**
                 * ValueProto asBytes.
                 * @member {Uint8Array|null|undefined} asBytes
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asBytes = null;

                /**
                 * ValueProto asAny.
                 * @member {google.protobuf.IAny|null|undefined} asAny
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                ValueProto.prototype.asAny = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ValueProto valueType.
                 * @member {"asString"|"asBool"|"asInt"|"asLong"|"asUnsignedInt"|"asUnsignedLong"|"asFloat"|"asDouble"|"asBytes"|"asAny"|undefined} valueType
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 */
                Object.defineProperty(ValueProto.prototype, "valueType", {
                    get: $util.oneOfGetter($oneOfFields = ["asString", "asBool", "asInt", "asLong", "asUnsignedInt", "asUnsignedLong", "asFloat", "asDouble", "asBytes", "asAny"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ValueProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {waiternow.common.PropertyProto.IValueProto=} [properties] Properties to set
                 * @returns {waiternow.common.PropertyProto.ValueProto} ValueProto instance
                 */
                ValueProto.create = function create(properties) {
                    return new ValueProto(properties);
                };

                /**
                 * Encodes the specified ValueProto message. Does not implicitly {@link waiternow.common.PropertyProto.ValueProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {waiternow.common.PropertyProto.IValueProto} message ValueProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ValueProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.asString != null && Object.hasOwnProperty.call(message, "asString"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.asString);
                    if (message.asBool != null && Object.hasOwnProperty.call(message, "asBool"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.asBool);
                    if (message.asInt != null && Object.hasOwnProperty.call(message, "asInt"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.asInt);
                    if (message.asLong != null && Object.hasOwnProperty.call(message, "asLong"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.asLong);
                    if (message.asUnsignedInt != null && Object.hasOwnProperty.call(message, "asUnsignedInt"))
                        writer.uint32(/* id 5, wireType 0 =*/40).uint32(message.asUnsignedInt);
                    if (message.asUnsignedLong != null && Object.hasOwnProperty.call(message, "asUnsignedLong"))
                        writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.asUnsignedLong);
                    if (message.asFloat != null && Object.hasOwnProperty.call(message, "asFloat"))
                        writer.uint32(/* id 7, wireType 5 =*/61).float(message.asFloat);
                    if (message.asDouble != null && Object.hasOwnProperty.call(message, "asDouble"))
                        writer.uint32(/* id 8, wireType 1 =*/65).double(message.asDouble);
                    if (message.asBytes != null && Object.hasOwnProperty.call(message, "asBytes"))
                        writer.uint32(/* id 9, wireType 2 =*/74).bytes(message.asBytes);
                    if (message.asAny != null && Object.hasOwnProperty.call(message, "asAny"))
                        $root.google.protobuf.Any.encode(message.asAny, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ValueProto message, length delimited. Does not implicitly {@link waiternow.common.PropertyProto.ValueProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {waiternow.common.PropertyProto.IValueProto} message ValueProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ValueProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ValueProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PropertyProto.ValueProto} ValueProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ValueProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PropertyProto.ValueProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.asString = reader.string();
                                break;
                            }
                        case 2: {
                                message.asBool = reader.bool();
                                break;
                            }
                        case 3: {
                                message.asInt = reader.int32();
                                break;
                            }
                        case 4: {
                                message.asLong = reader.int64();
                                break;
                            }
                        case 5: {
                                message.asUnsignedInt = reader.uint32();
                                break;
                            }
                        case 6: {
                                message.asUnsignedLong = reader.uint64();
                                break;
                            }
                        case 7: {
                                message.asFloat = reader.float();
                                break;
                            }
                        case 8: {
                                message.asDouble = reader.double();
                                break;
                            }
                        case 9: {
                                message.asBytes = reader.bytes();
                                break;
                            }
                        case 10: {
                                message.asAny = $root.google.protobuf.Any.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ValueProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PropertyProto.ValueProto} ValueProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ValueProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ValueProto message.
                 * @function verify
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ValueProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.asString != null && message.hasOwnProperty("asString")) {
                        properties.valueType = 1;
                        if (!$util.isString(message.asString))
                            return "asString: string expected";
                    }
                    if (message.asBool != null && message.hasOwnProperty("asBool")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (typeof message.asBool !== "boolean")
                            return "asBool: boolean expected";
                    }
                    if (message.asInt != null && message.hasOwnProperty("asInt")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (!$util.isInteger(message.asInt))
                            return "asInt: integer expected";
                    }
                    if (message.asLong != null && message.hasOwnProperty("asLong")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (!$util.isInteger(message.asLong) && !(message.asLong && $util.isInteger(message.asLong.low) && $util.isInteger(message.asLong.high)))
                            return "asLong: integer|Long expected";
                    }
                    if (message.asUnsignedInt != null && message.hasOwnProperty("asUnsignedInt")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (!$util.isInteger(message.asUnsignedInt))
                            return "asUnsignedInt: integer expected";
                    }
                    if (message.asUnsignedLong != null && message.hasOwnProperty("asUnsignedLong")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (!$util.isInteger(message.asUnsignedLong) && !(message.asUnsignedLong && $util.isInteger(message.asUnsignedLong.low) && $util.isInteger(message.asUnsignedLong.high)))
                            return "asUnsignedLong: integer|Long expected";
                    }
                    if (message.asFloat != null && message.hasOwnProperty("asFloat")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (typeof message.asFloat !== "number")
                            return "asFloat: number expected";
                    }
                    if (message.asDouble != null && message.hasOwnProperty("asDouble")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (typeof message.asDouble !== "number")
                            return "asDouble: number expected";
                    }
                    if (message.asBytes != null && message.hasOwnProperty("asBytes")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        if (!(message.asBytes && typeof message.asBytes.length === "number" || $util.isString(message.asBytes)))
                            return "asBytes: buffer expected";
                    }
                    if (message.asAny != null && message.hasOwnProperty("asAny")) {
                        if (properties.valueType === 1)
                            return "valueType: multiple values";
                        properties.valueType = 1;
                        {
                            var error = $root.google.protobuf.Any.verify(message.asAny);
                            if (error)
                                return "asAny." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a ValueProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PropertyProto.ValueProto} ValueProto
                 */
                ValueProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PropertyProto.ValueProto)
                        return object;
                    var message = new $root.waiternow.common.PropertyProto.ValueProto();
                    if (object.asString != null)
                        message.asString = String(object.asString);
                    if (object.asBool != null)
                        message.asBool = Boolean(object.asBool);
                    if (object.asInt != null)
                        message.asInt = object.asInt | 0;
                    if (object.asLong != null)
                        if ($util.Long)
                            (message.asLong = $util.Long.fromValue(object.asLong)).unsigned = false;
                        else if (typeof object.asLong === "string")
                            message.asLong = parseInt(object.asLong, 10);
                        else if (typeof object.asLong === "number")
                            message.asLong = object.asLong;
                        else if (typeof object.asLong === "object")
                            message.asLong = new $util.LongBits(object.asLong.low >>> 0, object.asLong.high >>> 0).toNumber();
                    if (object.asUnsignedInt != null)
                        message.asUnsignedInt = object.asUnsignedInt >>> 0;
                    if (object.asUnsignedLong != null)
                        if ($util.Long)
                            (message.asUnsignedLong = $util.Long.fromValue(object.asUnsignedLong)).unsigned = true;
                        else if (typeof object.asUnsignedLong === "string")
                            message.asUnsignedLong = parseInt(object.asUnsignedLong, 10);
                        else if (typeof object.asUnsignedLong === "number")
                            message.asUnsignedLong = object.asUnsignedLong;
                        else if (typeof object.asUnsignedLong === "object")
                            message.asUnsignedLong = new $util.LongBits(object.asUnsignedLong.low >>> 0, object.asUnsignedLong.high >>> 0).toNumber(true);
                    if (object.asFloat != null)
                        message.asFloat = Number(object.asFloat);
                    if (object.asDouble != null)
                        message.asDouble = Number(object.asDouble);
                    if (object.asBytes != null)
                        if (typeof object.asBytes === "string")
                            $util.base64.decode(object.asBytes, message.asBytes = $util.newBuffer($util.base64.length(object.asBytes)), 0);
                        else if (object.asBytes.length >= 0)
                            message.asBytes = object.asBytes;
                    if (object.asAny != null) {
                        if (typeof object.asAny !== "object")
                            throw TypeError(".waiternow.common.PropertyProto.ValueProto.asAny: object expected");
                        message.asAny = $root.google.protobuf.Any.fromObject(object.asAny);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ValueProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {waiternow.common.PropertyProto.ValueProto} message ValueProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ValueProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.asString != null && message.hasOwnProperty("asString")) {
                        object.asString = message.asString;
                        if (options.oneofs)
                            object.valueType = "asString";
                    }
                    if (message.asBool != null && message.hasOwnProperty("asBool")) {
                        object.asBool = message.asBool;
                        if (options.oneofs)
                            object.valueType = "asBool";
                    }
                    if (message.asInt != null && message.hasOwnProperty("asInt")) {
                        object.asInt = message.asInt;
                        if (options.oneofs)
                            object.valueType = "asInt";
                    }
                    if (message.asLong != null && message.hasOwnProperty("asLong")) {
                        if (typeof message.asLong === "number")
                            object.asLong = options.longs === String ? String(message.asLong) : message.asLong;
                        else
                            object.asLong = options.longs === String ? $util.Long.prototype.toString.call(message.asLong) : options.longs === Number ? new $util.LongBits(message.asLong.low >>> 0, message.asLong.high >>> 0).toNumber() : message.asLong;
                        if (options.oneofs)
                            object.valueType = "asLong";
                    }
                    if (message.asUnsignedInt != null && message.hasOwnProperty("asUnsignedInt")) {
                        object.asUnsignedInt = message.asUnsignedInt;
                        if (options.oneofs)
                            object.valueType = "asUnsignedInt";
                    }
                    if (message.asUnsignedLong != null && message.hasOwnProperty("asUnsignedLong")) {
                        if (typeof message.asUnsignedLong === "number")
                            object.asUnsignedLong = options.longs === String ? String(message.asUnsignedLong) : message.asUnsignedLong;
                        else
                            object.asUnsignedLong = options.longs === String ? $util.Long.prototype.toString.call(message.asUnsignedLong) : options.longs === Number ? new $util.LongBits(message.asUnsignedLong.low >>> 0, message.asUnsignedLong.high >>> 0).toNumber(true) : message.asUnsignedLong;
                        if (options.oneofs)
                            object.valueType = "asUnsignedLong";
                    }
                    if (message.asFloat != null && message.hasOwnProperty("asFloat")) {
                        object.asFloat = options.json && !isFinite(message.asFloat) ? String(message.asFloat) : message.asFloat;
                        if (options.oneofs)
                            object.valueType = "asFloat";
                    }
                    if (message.asDouble != null && message.hasOwnProperty("asDouble")) {
                        object.asDouble = options.json && !isFinite(message.asDouble) ? String(message.asDouble) : message.asDouble;
                        if (options.oneofs)
                            object.valueType = "asDouble";
                    }
                    if (message.asBytes != null && message.hasOwnProperty("asBytes")) {
                        object.asBytes = options.bytes === String ? $util.base64.encode(message.asBytes, 0, message.asBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.asBytes) : message.asBytes;
                        if (options.oneofs)
                            object.valueType = "asBytes";
                    }
                    if (message.asAny != null && message.hasOwnProperty("asAny")) {
                        object.asAny = $root.google.protobuf.Any.toObject(message.asAny, options);
                        if (options.oneofs)
                            object.valueType = "asAny";
                    }
                    return object;
                };

                /**
                 * Converts this ValueProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ValueProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ValueProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PropertyProto.ValueProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ValueProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PropertyProto.ValueProto";
                };

                return ValueProto;
            })();

            return PropertyProto;
        })();

        common.MoneyProto = (function() {

            /**
             * Properties of a MoneyProto.
             * @memberof waiternow.common
             * @interface IMoneyProto
             * @property {string|null} [currencyCode] MoneyProto currencyCode
             * @property {number|null} [units] MoneyProto units
             * @property {number|null} [cents] MoneyProto cents
             */

            /**
             * Constructs a new MoneyProto.
             * @memberof waiternow.common
             * @classdesc Represents a MoneyProto.
             * @implements IMoneyProto
             * @constructor
             * @param {waiternow.common.IMoneyProto=} [properties] Properties to set
             */
            function MoneyProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MoneyProto currencyCode.
             * @member {string} currencyCode
             * @memberof waiternow.common.MoneyProto
             * @instance
             */
            MoneyProto.prototype.currencyCode = "";

            /**
             * MoneyProto units.
             * @member {number} units
             * @memberof waiternow.common.MoneyProto
             * @instance
             */
            MoneyProto.prototype.units = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MoneyProto cents.
             * @member {number} cents
             * @memberof waiternow.common.MoneyProto
             * @instance
             */
            MoneyProto.prototype.cents = 0;

            /**
             * Creates a new MoneyProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {waiternow.common.IMoneyProto=} [properties] Properties to set
             * @returns {waiternow.common.MoneyProto} MoneyProto instance
             */
            MoneyProto.create = function create(properties) {
                return new MoneyProto(properties);
            };

            /**
             * Encodes the specified MoneyProto message. Does not implicitly {@link waiternow.common.MoneyProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {waiternow.common.IMoneyProto} message MoneyProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MoneyProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.currencyCode);
                if (message.units != null && Object.hasOwnProperty.call(message, "units"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.units);
                if (message.cents != null && Object.hasOwnProperty.call(message, "cents"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.cents);
                return writer;
            };

            /**
             * Encodes the specified MoneyProto message, length delimited. Does not implicitly {@link waiternow.common.MoneyProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {waiternow.common.IMoneyProto} message MoneyProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MoneyProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MoneyProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MoneyProto} MoneyProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MoneyProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MoneyProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.currencyCode = reader.string();
                            break;
                        }
                    case 2: {
                            message.units = reader.int64();
                            break;
                        }
                    case 3: {
                            message.cents = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MoneyProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MoneyProto} MoneyProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MoneyProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MoneyProto message.
             * @function verify
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MoneyProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                    if (!$util.isString(message.currencyCode))
                        return "currencyCode: string expected";
                if (message.units != null && message.hasOwnProperty("units"))
                    if (!$util.isInteger(message.units) && !(message.units && $util.isInteger(message.units.low) && $util.isInteger(message.units.high)))
                        return "units: integer|Long expected";
                if (message.cents != null && message.hasOwnProperty("cents"))
                    if (!$util.isInteger(message.cents))
                        return "cents: integer expected";
                return null;
            };

            /**
             * Creates a MoneyProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MoneyProto} MoneyProto
             */
            MoneyProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MoneyProto)
                    return object;
                var message = new $root.waiternow.common.MoneyProto();
                if (object.currencyCode != null)
                    message.currencyCode = String(object.currencyCode);
                if (object.units != null)
                    if ($util.Long)
                        (message.units = $util.Long.fromValue(object.units)).unsigned = false;
                    else if (typeof object.units === "string")
                        message.units = parseInt(object.units, 10);
                    else if (typeof object.units === "number")
                        message.units = object.units;
                    else if (typeof object.units === "object")
                        message.units = new $util.LongBits(object.units.low >>> 0, object.units.high >>> 0).toNumber();
                if (object.cents != null)
                    message.cents = object.cents | 0;
                return message;
            };

            /**
             * Creates a plain object from a MoneyProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {waiternow.common.MoneyProto} message MoneyProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MoneyProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.currencyCode = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.units = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.units = options.longs === String ? "0" : 0;
                    object.cents = 0;
                }
                if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                    object.currencyCode = message.currencyCode;
                if (message.units != null && message.hasOwnProperty("units"))
                    if (typeof message.units === "number")
                        object.units = options.longs === String ? String(message.units) : message.units;
                    else
                        object.units = options.longs === String ? $util.Long.prototype.toString.call(message.units) : options.longs === Number ? new $util.LongBits(message.units.low >>> 0, message.units.high >>> 0).toNumber() : message.units;
                if (message.cents != null && message.hasOwnProperty("cents"))
                    object.cents = message.cents;
                return object;
            };

            /**
             * Converts this MoneyProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MoneyProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MoneyProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MoneyProto
             * @function getTypeUrl
             * @memberof waiternow.common.MoneyProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MoneyProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MoneyProto";
            };

            return MoneyProto;
        })();

        common.DecimalProto = (function() {

            /**
             * Properties of a DecimalProto.
             * @memberof waiternow.common
             * @interface IDecimalProto
             * @property {string|null} [value] DecimalProto value
             */

            /**
             * Constructs a new DecimalProto.
             * @memberof waiternow.common
             * @classdesc Represents a DecimalProto.
             * @implements IDecimalProto
             * @constructor
             * @param {waiternow.common.IDecimalProto=} [properties] Properties to set
             */
            function DecimalProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DecimalProto value.
             * @member {string} value
             * @memberof waiternow.common.DecimalProto
             * @instance
             */
            DecimalProto.prototype.value = "";

            /**
             * Creates a new DecimalProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {waiternow.common.IDecimalProto=} [properties] Properties to set
             * @returns {waiternow.common.DecimalProto} DecimalProto instance
             */
            DecimalProto.create = function create(properties) {
                return new DecimalProto(properties);
            };

            /**
             * Encodes the specified DecimalProto message. Does not implicitly {@link waiternow.common.DecimalProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {waiternow.common.IDecimalProto} message DecimalProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DecimalProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.value);
                return writer;
            };

            /**
             * Encodes the specified DecimalProto message, length delimited. Does not implicitly {@link waiternow.common.DecimalProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {waiternow.common.IDecimalProto} message DecimalProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DecimalProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DecimalProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DecimalProto} DecimalProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DecimalProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DecimalProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DecimalProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DecimalProto} DecimalProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DecimalProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DecimalProto message.
             * @function verify
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DecimalProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!$util.isString(message.value))
                        return "value: string expected";
                return null;
            };

            /**
             * Creates a DecimalProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DecimalProto} DecimalProto
             */
            DecimalProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DecimalProto)
                    return object;
                var message = new $root.waiternow.common.DecimalProto();
                if (object.value != null)
                    message.value = String(object.value);
                return message;
            };

            /**
             * Creates a plain object from a DecimalProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {waiternow.common.DecimalProto} message DecimalProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DecimalProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.value = "";
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = message.value;
                return object;
            };

            /**
             * Converts this DecimalProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DecimalProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DecimalProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DecimalProto
             * @function getTypeUrl
             * @memberof waiternow.common.DecimalProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DecimalProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DecimalProto";
            };

            return DecimalProto;
        })();

        common.DistanceQuantityProto = (function() {

            /**
             * Properties of a DistanceQuantityProto.
             * @memberof waiternow.common
             * @interface IDistanceQuantityProto
             * @property {waiternow.common.IDecimalProto|null} [value] DistanceQuantityProto value
             * @property {waiternow.common.DistanceQuantityProto.Unit|null} [unit] DistanceQuantityProto unit
             */

            /**
             * Constructs a new DistanceQuantityProto.
             * @memberof waiternow.common
             * @classdesc Represents a DistanceQuantityProto.
             * @implements IDistanceQuantityProto
             * @constructor
             * @param {waiternow.common.IDistanceQuantityProto=} [properties] Properties to set
             */
            function DistanceQuantityProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DistanceQuantityProto value.
             * @member {waiternow.common.IDecimalProto|null|undefined} value
             * @memberof waiternow.common.DistanceQuantityProto
             * @instance
             */
            DistanceQuantityProto.prototype.value = null;

            /**
             * DistanceQuantityProto unit.
             * @member {waiternow.common.DistanceQuantityProto.Unit} unit
             * @memberof waiternow.common.DistanceQuantityProto
             * @instance
             */
            DistanceQuantityProto.prototype.unit = 0;

            /**
             * Creates a new DistanceQuantityProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {waiternow.common.IDistanceQuantityProto=} [properties] Properties to set
             * @returns {waiternow.common.DistanceQuantityProto} DistanceQuantityProto instance
             */
            DistanceQuantityProto.create = function create(properties) {
                return new DistanceQuantityProto(properties);
            };

            /**
             * Encodes the specified DistanceQuantityProto message. Does not implicitly {@link waiternow.common.DistanceQuantityProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {waiternow.common.IDistanceQuantityProto} message DistanceQuantityProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DistanceQuantityProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    $root.waiternow.common.DecimalProto.encode(message.value, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.unit != null && Object.hasOwnProperty.call(message, "unit"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.unit);
                return writer;
            };

            /**
             * Encodes the specified DistanceQuantityProto message, length delimited. Does not implicitly {@link waiternow.common.DistanceQuantityProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {waiternow.common.IDistanceQuantityProto} message DistanceQuantityProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DistanceQuantityProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DistanceQuantityProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DistanceQuantityProto} DistanceQuantityProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DistanceQuantityProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DistanceQuantityProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.value = $root.waiternow.common.DecimalProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.unit = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DistanceQuantityProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DistanceQuantityProto} DistanceQuantityProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DistanceQuantityProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DistanceQuantityProto message.
             * @function verify
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DistanceQuantityProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.value != null && message.hasOwnProperty("value")) {
                    var error = $root.waiternow.common.DecimalProto.verify(message.value);
                    if (error)
                        return "value." + error;
                }
                if (message.unit != null && message.hasOwnProperty("unit"))
                    switch (message.unit) {
                    default:
                        return "unit: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a DistanceQuantityProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DistanceQuantityProto} DistanceQuantityProto
             */
            DistanceQuantityProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DistanceQuantityProto)
                    return object;
                var message = new $root.waiternow.common.DistanceQuantityProto();
                if (object.value != null) {
                    if (typeof object.value !== "object")
                        throw TypeError(".waiternow.common.DistanceQuantityProto.value: object expected");
                    message.value = $root.waiternow.common.DecimalProto.fromObject(object.value);
                }
                switch (object.unit) {
                default:
                    if (typeof object.unit === "number") {
                        message.unit = object.unit;
                        break;
                    }
                    break;
                case "UNIT_UNSPECIFIED":
                case 0:
                    message.unit = 0;
                    break;
                case "METER":
                case 1:
                    message.unit = 1;
                    break;
                case "KILOMETER":
                case 2:
                    message.unit = 2;
                    break;
                case "MILE":
                case 3:
                    message.unit = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a DistanceQuantityProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {waiternow.common.DistanceQuantityProto} message DistanceQuantityProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DistanceQuantityProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.value = null;
                    object.unit = options.enums === String ? "UNIT_UNSPECIFIED" : 0;
                }
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = $root.waiternow.common.DecimalProto.toObject(message.value, options);
                if (message.unit != null && message.hasOwnProperty("unit"))
                    object.unit = options.enums === String ? $root.waiternow.common.DistanceQuantityProto.Unit[message.unit] === undefined ? message.unit : $root.waiternow.common.DistanceQuantityProto.Unit[message.unit] : message.unit;
                return object;
            };

            /**
             * Converts this DistanceQuantityProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DistanceQuantityProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DistanceQuantityProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DistanceQuantityProto
             * @function getTypeUrl
             * @memberof waiternow.common.DistanceQuantityProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DistanceQuantityProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DistanceQuantityProto";
            };

            /**
             * Unit enum.
             * @name waiternow.common.DistanceQuantityProto.Unit
             * @enum {number}
             * @property {number} UNIT_UNSPECIFIED=0 UNIT_UNSPECIFIED value
             * @property {number} METER=1 METER value
             * @property {number} KILOMETER=2 KILOMETER value
             * @property {number} MILE=3 MILE value
             */
            DistanceQuantityProto.Unit = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "UNIT_UNSPECIFIED"] = 0;
                values[valuesById[1] = "METER"] = 1;
                values[valuesById[2] = "KILOMETER"] = 2;
                values[valuesById[3] = "MILE"] = 3;
                return values;
            })();

            return DistanceQuantityProto;
        })();

        common.SimplePhoneNumberProto = (function() {

            /**
             * Properties of a SimplePhoneNumberProto.
             * @memberof waiternow.common
             * @interface ISimplePhoneNumberProto
             * @property {string|null} [phoneNumber] SimplePhoneNumberProto phoneNumber
             */

            /**
             * Constructs a new SimplePhoneNumberProto.
             * @memberof waiternow.common
             * @classdesc Represents a SimplePhoneNumberProto.
             * @implements ISimplePhoneNumberProto
             * @constructor
             * @param {waiternow.common.ISimplePhoneNumberProto=} [properties] Properties to set
             */
            function SimplePhoneNumberProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SimplePhoneNumberProto phoneNumber.
             * @member {string} phoneNumber
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @instance
             */
            SimplePhoneNumberProto.prototype.phoneNumber = "";

            /**
             * Creates a new SimplePhoneNumberProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {waiternow.common.ISimplePhoneNumberProto=} [properties] Properties to set
             * @returns {waiternow.common.SimplePhoneNumberProto} SimplePhoneNumberProto instance
             */
            SimplePhoneNumberProto.create = function create(properties) {
                return new SimplePhoneNumberProto(properties);
            };

            /**
             * Encodes the specified SimplePhoneNumberProto message. Does not implicitly {@link waiternow.common.SimplePhoneNumberProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {waiternow.common.ISimplePhoneNumberProto} message SimplePhoneNumberProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SimplePhoneNumberProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.phoneNumber);
                return writer;
            };

            /**
             * Encodes the specified SimplePhoneNumberProto message, length delimited. Does not implicitly {@link waiternow.common.SimplePhoneNumberProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {waiternow.common.ISimplePhoneNumberProto} message SimplePhoneNumberProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SimplePhoneNumberProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SimplePhoneNumberProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SimplePhoneNumberProto} SimplePhoneNumberProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SimplePhoneNumberProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SimplePhoneNumberProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.phoneNumber = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SimplePhoneNumberProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SimplePhoneNumberProto} SimplePhoneNumberProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SimplePhoneNumberProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SimplePhoneNumberProto message.
             * @function verify
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SimplePhoneNumberProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    if (!$util.isString(message.phoneNumber))
                        return "phoneNumber: string expected";
                return null;
            };

            /**
             * Creates a SimplePhoneNumberProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SimplePhoneNumberProto} SimplePhoneNumberProto
             */
            SimplePhoneNumberProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SimplePhoneNumberProto)
                    return object;
                var message = new $root.waiternow.common.SimplePhoneNumberProto();
                if (object.phoneNumber != null)
                    message.phoneNumber = String(object.phoneNumber);
                return message;
            };

            /**
             * Creates a plain object from a SimplePhoneNumberProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {waiternow.common.SimplePhoneNumberProto} message SimplePhoneNumberProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SimplePhoneNumberProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.phoneNumber = "";
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = message.phoneNumber;
                return object;
            };

            /**
             * Converts this SimplePhoneNumberProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SimplePhoneNumberProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SimplePhoneNumberProto
             * @function getTypeUrl
             * @memberof waiternow.common.SimplePhoneNumberProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SimplePhoneNumberProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SimplePhoneNumberProto";
            };

            return SimplePhoneNumberProto;
        })();

        common.StructuredPhoneNumberProto = (function() {

            /**
             * Properties of a StructuredPhoneNumberProto.
             * @memberof waiternow.common
             * @interface IStructuredPhoneNumberProto
             * @property {number|null} [countryCode] StructuredPhoneNumberProto countryCode
             * @property {number|null} [number] StructuredPhoneNumberProto number
             */

            /**
             * Constructs a new StructuredPhoneNumberProto.
             * @memberof waiternow.common
             * @classdesc Represents a StructuredPhoneNumberProto.
             * @implements IStructuredPhoneNumberProto
             * @constructor
             * @param {waiternow.common.IStructuredPhoneNumberProto=} [properties] Properties to set
             */
            function StructuredPhoneNumberProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StructuredPhoneNumberProto countryCode.
             * @member {number} countryCode
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @instance
             */
            StructuredPhoneNumberProto.prototype.countryCode = 0;

            /**
             * StructuredPhoneNumberProto number.
             * @member {number} number
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @instance
             */
            StructuredPhoneNumberProto.prototype.number = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new StructuredPhoneNumberProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {waiternow.common.IStructuredPhoneNumberProto=} [properties] Properties to set
             * @returns {waiternow.common.StructuredPhoneNumberProto} StructuredPhoneNumberProto instance
             */
            StructuredPhoneNumberProto.create = function create(properties) {
                return new StructuredPhoneNumberProto(properties);
            };

            /**
             * Encodes the specified StructuredPhoneNumberProto message. Does not implicitly {@link waiternow.common.StructuredPhoneNumberProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {waiternow.common.IStructuredPhoneNumberProto} message StructuredPhoneNumberProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredPhoneNumberProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.countryCode != null && Object.hasOwnProperty.call(message, "countryCode"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.countryCode);
                if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int64(message.number);
                return writer;
            };

            /**
             * Encodes the specified StructuredPhoneNumberProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredPhoneNumberProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {waiternow.common.IStructuredPhoneNumberProto} message StructuredPhoneNumberProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredPhoneNumberProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StructuredPhoneNumberProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.StructuredPhoneNumberProto} StructuredPhoneNumberProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredPhoneNumberProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredPhoneNumberProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.countryCode = reader.int32();
                            break;
                        }
                    case 2: {
                            message.number = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StructuredPhoneNumberProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.StructuredPhoneNumberProto} StructuredPhoneNumberProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredPhoneNumberProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StructuredPhoneNumberProto message.
             * @function verify
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StructuredPhoneNumberProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    if (!$util.isInteger(message.countryCode))
                        return "countryCode: integer expected";
                if (message.number != null && message.hasOwnProperty("number"))
                    if (!$util.isInteger(message.number) && !(message.number && $util.isInteger(message.number.low) && $util.isInteger(message.number.high)))
                        return "number: integer|Long expected";
                return null;
            };

            /**
             * Creates a StructuredPhoneNumberProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.StructuredPhoneNumberProto} StructuredPhoneNumberProto
             */
            StructuredPhoneNumberProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.StructuredPhoneNumberProto)
                    return object;
                var message = new $root.waiternow.common.StructuredPhoneNumberProto();
                if (object.countryCode != null)
                    message.countryCode = object.countryCode | 0;
                if (object.number != null)
                    if ($util.Long)
                        (message.number = $util.Long.fromValue(object.number)).unsigned = false;
                    else if (typeof object.number === "string")
                        message.number = parseInt(object.number, 10);
                    else if (typeof object.number === "number")
                        message.number = object.number;
                    else if (typeof object.number === "object")
                        message.number = new $util.LongBits(object.number.low >>> 0, object.number.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a StructuredPhoneNumberProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {waiternow.common.StructuredPhoneNumberProto} message StructuredPhoneNumberProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StructuredPhoneNumberProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.countryCode = 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.number = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.number = options.longs === String ? "0" : 0;
                }
                if (message.countryCode != null && message.hasOwnProperty("countryCode"))
                    object.countryCode = message.countryCode;
                if (message.number != null && message.hasOwnProperty("number"))
                    if (typeof message.number === "number")
                        object.number = options.longs === String ? String(message.number) : message.number;
                    else
                        object.number = options.longs === String ? $util.Long.prototype.toString.call(message.number) : options.longs === Number ? new $util.LongBits(message.number.low >>> 0, message.number.high >>> 0).toNumber() : message.number;
                return object;
            };

            /**
             * Converts this StructuredPhoneNumberProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StructuredPhoneNumberProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StructuredPhoneNumberProto
             * @function getTypeUrl
             * @memberof waiternow.common.StructuredPhoneNumberProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StructuredPhoneNumberProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.StructuredPhoneNumberProto";
            };

            return StructuredPhoneNumberProto;
        })();

        common.PhoneNumberProto = (function() {

            /**
             * Properties of a PhoneNumberProto.
             * @memberof waiternow.common
             * @interface IPhoneNumberProto
             * @property {waiternow.common.ISimplePhoneNumberProto|null} [simplePhoneNumber] PhoneNumberProto simplePhoneNumber
             * @property {waiternow.common.IStructuredPhoneNumberProto|null} [structuredPhoneNumber] PhoneNumberProto structuredPhoneNumber
             * @property {waiternow.common.PhoneNumberProto.PhoneType|null} [phoneType] PhoneNumberProto phoneType
             */

            /**
             * Constructs a new PhoneNumberProto.
             * @memberof waiternow.common
             * @classdesc Represents a PhoneNumberProto.
             * @implements IPhoneNumberProto
             * @constructor
             * @param {waiternow.common.IPhoneNumberProto=} [properties] Properties to set
             */
            function PhoneNumberProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PhoneNumberProto simplePhoneNumber.
             * @member {waiternow.common.ISimplePhoneNumberProto|null|undefined} simplePhoneNumber
             * @memberof waiternow.common.PhoneNumberProto
             * @instance
             */
            PhoneNumberProto.prototype.simplePhoneNumber = null;

            /**
             * PhoneNumberProto structuredPhoneNumber.
             * @member {waiternow.common.IStructuredPhoneNumberProto|null|undefined} structuredPhoneNumber
             * @memberof waiternow.common.PhoneNumberProto
             * @instance
             */
            PhoneNumberProto.prototype.structuredPhoneNumber = null;

            /**
             * PhoneNumberProto phoneType.
             * @member {waiternow.common.PhoneNumberProto.PhoneType} phoneType
             * @memberof waiternow.common.PhoneNumberProto
             * @instance
             */
            PhoneNumberProto.prototype.phoneType = 0;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * PhoneNumberProto phoneNumber.
             * @member {"simplePhoneNumber"|"structuredPhoneNumber"|undefined} phoneNumber
             * @memberof waiternow.common.PhoneNumberProto
             * @instance
             */
            Object.defineProperty(PhoneNumberProto.prototype, "phoneNumber", {
                get: $util.oneOfGetter($oneOfFields = ["simplePhoneNumber", "structuredPhoneNumber"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new PhoneNumberProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {waiternow.common.IPhoneNumberProto=} [properties] Properties to set
             * @returns {waiternow.common.PhoneNumberProto} PhoneNumberProto instance
             */
            PhoneNumberProto.create = function create(properties) {
                return new PhoneNumberProto(properties);
            };

            /**
             * Encodes the specified PhoneNumberProto message. Does not implicitly {@link waiternow.common.PhoneNumberProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {waiternow.common.IPhoneNumberProto} message PhoneNumberProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhoneNumberProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.simplePhoneNumber != null && Object.hasOwnProperty.call(message, "simplePhoneNumber"))
                    $root.waiternow.common.SimplePhoneNumberProto.encode(message.simplePhoneNumber, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.structuredPhoneNumber != null && Object.hasOwnProperty.call(message, "structuredPhoneNumber"))
                    $root.waiternow.common.StructuredPhoneNumberProto.encode(message.structuredPhoneNumber, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.phoneType != null && Object.hasOwnProperty.call(message, "phoneType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.phoneType);
                return writer;
            };

            /**
             * Encodes the specified PhoneNumberProto message, length delimited. Does not implicitly {@link waiternow.common.PhoneNumberProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {waiternow.common.IPhoneNumberProto} message PhoneNumberProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PhoneNumberProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PhoneNumberProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PhoneNumberProto} PhoneNumberProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhoneNumberProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PhoneNumberProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.simplePhoneNumber = $root.waiternow.common.SimplePhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.structuredPhoneNumber = $root.waiternow.common.StructuredPhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.phoneType = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PhoneNumberProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PhoneNumberProto} PhoneNumberProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PhoneNumberProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PhoneNumberProto message.
             * @function verify
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PhoneNumberProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.simplePhoneNumber != null && message.hasOwnProperty("simplePhoneNumber")) {
                    properties.phoneNumber = 1;
                    {
                        var error = $root.waiternow.common.SimplePhoneNumberProto.verify(message.simplePhoneNumber);
                        if (error)
                            return "simplePhoneNumber." + error;
                    }
                }
                if (message.structuredPhoneNumber != null && message.hasOwnProperty("structuredPhoneNumber")) {
                    if (properties.phoneNumber === 1)
                        return "phoneNumber: multiple values";
                    properties.phoneNumber = 1;
                    {
                        var error = $root.waiternow.common.StructuredPhoneNumberProto.verify(message.structuredPhoneNumber);
                        if (error)
                            return "structuredPhoneNumber." + error;
                    }
                }
                if (message.phoneType != null && message.hasOwnProperty("phoneType"))
                    switch (message.phoneType) {
                    default:
                        return "phoneType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                return null;
            };

            /**
             * Creates a PhoneNumberProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PhoneNumberProto} PhoneNumberProto
             */
            PhoneNumberProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PhoneNumberProto)
                    return object;
                var message = new $root.waiternow.common.PhoneNumberProto();
                if (object.simplePhoneNumber != null) {
                    if (typeof object.simplePhoneNumber !== "object")
                        throw TypeError(".waiternow.common.PhoneNumberProto.simplePhoneNumber: object expected");
                    message.simplePhoneNumber = $root.waiternow.common.SimplePhoneNumberProto.fromObject(object.simplePhoneNumber);
                }
                if (object.structuredPhoneNumber != null) {
                    if (typeof object.structuredPhoneNumber !== "object")
                        throw TypeError(".waiternow.common.PhoneNumberProto.structuredPhoneNumber: object expected");
                    message.structuredPhoneNumber = $root.waiternow.common.StructuredPhoneNumberProto.fromObject(object.structuredPhoneNumber);
                }
                switch (object.phoneType) {
                default:
                    if (typeof object.phoneType === "number") {
                        message.phoneType = object.phoneType;
                        break;
                    }
                    break;
                case "PHONE_TYPE_UNSPECIFIED":
                case 0:
                    message.phoneType = 0;
                    break;
                case "MOBILE":
                case 1:
                    message.phoneType = 1;
                    break;
                case "HOME":
                case 2:
                    message.phoneType = 2;
                    break;
                case "WORK":
                case 3:
                    message.phoneType = 3;
                    break;
                }
                return message;
            };

            /**
             * Creates a plain object from a PhoneNumberProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {waiternow.common.PhoneNumberProto} message PhoneNumberProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PhoneNumberProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.phoneType = options.enums === String ? "PHONE_TYPE_UNSPECIFIED" : 0;
                if (message.simplePhoneNumber != null && message.hasOwnProperty("simplePhoneNumber")) {
                    object.simplePhoneNumber = $root.waiternow.common.SimplePhoneNumberProto.toObject(message.simplePhoneNumber, options);
                    if (options.oneofs)
                        object.phoneNumber = "simplePhoneNumber";
                }
                if (message.structuredPhoneNumber != null && message.hasOwnProperty("structuredPhoneNumber")) {
                    object.structuredPhoneNumber = $root.waiternow.common.StructuredPhoneNumberProto.toObject(message.structuredPhoneNumber, options);
                    if (options.oneofs)
                        object.phoneNumber = "structuredPhoneNumber";
                }
                if (message.phoneType != null && message.hasOwnProperty("phoneType"))
                    object.phoneType = options.enums === String ? $root.waiternow.common.PhoneNumberProto.PhoneType[message.phoneType] === undefined ? message.phoneType : $root.waiternow.common.PhoneNumberProto.PhoneType[message.phoneType] : message.phoneType;
                return object;
            };

            /**
             * Converts this PhoneNumberProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PhoneNumberProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PhoneNumberProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PhoneNumberProto
             * @function getTypeUrl
             * @memberof waiternow.common.PhoneNumberProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PhoneNumberProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PhoneNumberProto";
            };

            /**
             * PhoneType enum.
             * @name waiternow.common.PhoneNumberProto.PhoneType
             * @enum {number}
             * @property {number} PHONE_TYPE_UNSPECIFIED=0 PHONE_TYPE_UNSPECIFIED value
             * @property {number} MOBILE=1 MOBILE value
             * @property {number} HOME=2 HOME value
             * @property {number} WORK=3 WORK value
             */
            PhoneNumberProto.PhoneType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PHONE_TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "MOBILE"] = 1;
                values[valuesById[2] = "HOME"] = 2;
                values[valuesById[3] = "WORK"] = 3;
                return values;
            })();

            return PhoneNumberProto;
        })();

        /**
         * Country enum.
         * @name waiternow.common.Country
         * @enum {number}
         * @property {number} COUNTRY_UNSPECIFIED=0 COUNTRY_UNSPECIFIED value
         * @property {number} UNITED_STATES=1 UNITED_STATES value
         * @property {number} MEXICO=2 MEXICO value
         */
        common.Country = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "COUNTRY_UNSPECIFIED"] = 0;
            values[valuesById[1] = "UNITED_STATES"] = 1;
            values[valuesById[2] = "MEXICO"] = 2;
            return values;
        })();

        common.SimpleAddressProto = (function() {

            /**
             * Properties of a SimpleAddressProto.
             * @memberof waiternow.common
             * @interface ISimpleAddressProto
             * @property {string|null} [address] SimpleAddressProto address
             */

            /**
             * Constructs a new SimpleAddressProto.
             * @memberof waiternow.common
             * @classdesc Represents a SimpleAddressProto.
             * @implements ISimpleAddressProto
             * @constructor
             * @param {waiternow.common.ISimpleAddressProto=} [properties] Properties to set
             */
            function SimpleAddressProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SimpleAddressProto address.
             * @member {string} address
             * @memberof waiternow.common.SimpleAddressProto
             * @instance
             */
            SimpleAddressProto.prototype.address = "";

            /**
             * Creates a new SimpleAddressProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {waiternow.common.ISimpleAddressProto=} [properties] Properties to set
             * @returns {waiternow.common.SimpleAddressProto} SimpleAddressProto instance
             */
            SimpleAddressProto.create = function create(properties) {
                return new SimpleAddressProto(properties);
            };

            /**
             * Encodes the specified SimpleAddressProto message. Does not implicitly {@link waiternow.common.SimpleAddressProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {waiternow.common.ISimpleAddressProto} message SimpleAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SimpleAddressProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.address);
                return writer;
            };

            /**
             * Encodes the specified SimpleAddressProto message, length delimited. Does not implicitly {@link waiternow.common.SimpleAddressProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {waiternow.common.ISimpleAddressProto} message SimpleAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SimpleAddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SimpleAddressProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SimpleAddressProto} SimpleAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SimpleAddressProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SimpleAddressProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.address = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SimpleAddressProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SimpleAddressProto} SimpleAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SimpleAddressProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SimpleAddressProto message.
             * @function verify
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SimpleAddressProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.address != null && message.hasOwnProperty("address"))
                    if (!$util.isString(message.address))
                        return "address: string expected";
                return null;
            };

            /**
             * Creates a SimpleAddressProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SimpleAddressProto} SimpleAddressProto
             */
            SimpleAddressProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SimpleAddressProto)
                    return object;
                var message = new $root.waiternow.common.SimpleAddressProto();
                if (object.address != null)
                    message.address = String(object.address);
                return message;
            };

            /**
             * Creates a plain object from a SimpleAddressProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {waiternow.common.SimpleAddressProto} message SimpleAddressProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SimpleAddressProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.address = "";
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = message.address;
                return object;
            };

            /**
             * Converts this SimpleAddressProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SimpleAddressProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SimpleAddressProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SimpleAddressProto
             * @function getTypeUrl
             * @memberof waiternow.common.SimpleAddressProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SimpleAddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SimpleAddressProto";
            };

            return SimpleAddressProto;
        })();

        common.StructuredAddressProto = (function() {

            /**
             * Properties of a StructuredAddressProto.
             * @memberof waiternow.common
             * @interface IStructuredAddressProto
             * @property {string|null} [street] StructuredAddressProto street
             * @property {string|null} [city] StructuredAddressProto city
             * @property {string|null} [state] StructuredAddressProto state
             * @property {string|null} [postalCode] StructuredAddressProto postalCode
             * @property {waiternow.common.Country|null} [country] StructuredAddressProto country
             * @property {string|null} [otherCountry] StructuredAddressProto otherCountry
             */

            /**
             * Constructs a new StructuredAddressProto.
             * @memberof waiternow.common
             * @classdesc Represents a StructuredAddressProto.
             * @implements IStructuredAddressProto
             * @constructor
             * @param {waiternow.common.IStructuredAddressProto=} [properties] Properties to set
             */
            function StructuredAddressProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StructuredAddressProto street.
             * @member {string} street
             * @memberof waiternow.common.StructuredAddressProto
             * @instance
             */
            StructuredAddressProto.prototype.street = "";

            /**
             * StructuredAddressProto city.
             * @member {string} city
             * @memberof waiternow.common.StructuredAddressProto
             * @instance
             */
            StructuredAddressProto.prototype.city = "";

            /**
             * StructuredAddressProto state.
             * @member {string} state
             * @memberof waiternow.common.StructuredAddressProto
             * @instance
             */
            StructuredAddressProto.prototype.state = "";

            /**
             * StructuredAddressProto postalCode.
             * @member {string} postalCode
             * @memberof waiternow.common.StructuredAddressProto
             * @instance
             */
            StructuredAddressProto.prototype.postalCode = "";

            /**
             * StructuredAddressProto country.
             * @member {waiternow.common.Country} country
             * @memberof waiternow.common.StructuredAddressProto
             * @instance
             */
            StructuredAddressProto.prototype.country = 0;

            /**
             * StructuredAddressProto otherCountry.
             * @member {string} otherCountry
             * @memberof waiternow.common.StructuredAddressProto
             * @instance
             */
            StructuredAddressProto.prototype.otherCountry = "";

            /**
             * Creates a new StructuredAddressProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {waiternow.common.IStructuredAddressProto=} [properties] Properties to set
             * @returns {waiternow.common.StructuredAddressProto} StructuredAddressProto instance
             */
            StructuredAddressProto.create = function create(properties) {
                return new StructuredAddressProto(properties);
            };

            /**
             * Encodes the specified StructuredAddressProto message. Does not implicitly {@link waiternow.common.StructuredAddressProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {waiternow.common.IStructuredAddressProto} message StructuredAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredAddressProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.street != null && Object.hasOwnProperty.call(message, "street"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.street);
                if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.city);
                if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.state);
                if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.postalCode);
                if (message.otherCountry != null && Object.hasOwnProperty.call(message, "otherCountry"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.otherCountry);
                if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.country);
                return writer;
            };

            /**
             * Encodes the specified StructuredAddressProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredAddressProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {waiternow.common.IStructuredAddressProto} message StructuredAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredAddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StructuredAddressProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.StructuredAddressProto} StructuredAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredAddressProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredAddressProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.street = reader.string();
                            break;
                        }
                    case 2: {
                            message.city = reader.string();
                            break;
                        }
                    case 3: {
                            message.state = reader.string();
                            break;
                        }
                    case 4: {
                            message.postalCode = reader.string();
                            break;
                        }
                    case 6: {
                            message.country = reader.int32();
                            break;
                        }
                    case 5: {
                            message.otherCountry = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StructuredAddressProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.StructuredAddressProto} StructuredAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredAddressProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StructuredAddressProto message.
             * @function verify
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StructuredAddressProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.street != null && message.hasOwnProperty("street"))
                    if (!$util.isString(message.street))
                        return "street: string expected";
                if (message.city != null && message.hasOwnProperty("city"))
                    if (!$util.isString(message.city))
                        return "city: string expected";
                if (message.state != null && message.hasOwnProperty("state"))
                    if (!$util.isString(message.state))
                        return "state: string expected";
                if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                    if (!$util.isString(message.postalCode))
                        return "postalCode: string expected";
                if (message.country != null && message.hasOwnProperty("country"))
                    switch (message.country) {
                    default:
                        return "country: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.otherCountry != null && message.hasOwnProperty("otherCountry"))
                    if (!$util.isString(message.otherCountry))
                        return "otherCountry: string expected";
                return null;
            };

            /**
             * Creates a StructuredAddressProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.StructuredAddressProto} StructuredAddressProto
             */
            StructuredAddressProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.StructuredAddressProto)
                    return object;
                var message = new $root.waiternow.common.StructuredAddressProto();
                if (object.street != null)
                    message.street = String(object.street);
                if (object.city != null)
                    message.city = String(object.city);
                if (object.state != null)
                    message.state = String(object.state);
                if (object.postalCode != null)
                    message.postalCode = String(object.postalCode);
                switch (object.country) {
                default:
                    if (typeof object.country === "number") {
                        message.country = object.country;
                        break;
                    }
                    break;
                case "COUNTRY_UNSPECIFIED":
                case 0:
                    message.country = 0;
                    break;
                case "UNITED_STATES":
                case 1:
                    message.country = 1;
                    break;
                case "MEXICO":
                case 2:
                    message.country = 2;
                    break;
                }
                if (object.otherCountry != null)
                    message.otherCountry = String(object.otherCountry);
                return message;
            };

            /**
             * Creates a plain object from a StructuredAddressProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {waiternow.common.StructuredAddressProto} message StructuredAddressProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StructuredAddressProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.street = "";
                    object.city = "";
                    object.state = "";
                    object.postalCode = "";
                    object.otherCountry = "";
                    object.country = options.enums === String ? "COUNTRY_UNSPECIFIED" : 0;
                }
                if (message.street != null && message.hasOwnProperty("street"))
                    object.street = message.street;
                if (message.city != null && message.hasOwnProperty("city"))
                    object.city = message.city;
                if (message.state != null && message.hasOwnProperty("state"))
                    object.state = message.state;
                if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                    object.postalCode = message.postalCode;
                if (message.otherCountry != null && message.hasOwnProperty("otherCountry"))
                    object.otherCountry = message.otherCountry;
                if (message.country != null && message.hasOwnProperty("country"))
                    object.country = options.enums === String ? $root.waiternow.common.Country[message.country] === undefined ? message.country : $root.waiternow.common.Country[message.country] : message.country;
                return object;
            };

            /**
             * Converts this StructuredAddressProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.StructuredAddressProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StructuredAddressProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StructuredAddressProto
             * @function getTypeUrl
             * @memberof waiternow.common.StructuredAddressProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StructuredAddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.StructuredAddressProto";
            };

            return StructuredAddressProto;
        })();

        common.GeocodeProto = (function() {

            /**
             * Properties of a GeocodeProto.
             * @memberof waiternow.common
             * @interface IGeocodeProto
             * @property {waiternow.common.GeocodeProto.ILocationProto|null} [location] GeocodeProto location
             * @property {string|null} [timeZone] GeocodeProto timeZone
             * @property {string|null} [formattedAddress] GeocodeProto formattedAddress
             * @property {waiternow.common.GeocodeProto.IAddressComponentsProto|null} [addressComponents] GeocodeProto addressComponents
             */

            /**
             * Constructs a new GeocodeProto.
             * @memberof waiternow.common
             * @classdesc Represents a GeocodeProto.
             * @implements IGeocodeProto
             * @constructor
             * @param {waiternow.common.IGeocodeProto=} [properties] Properties to set
             */
            function GeocodeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * GeocodeProto location.
             * @member {waiternow.common.GeocodeProto.ILocationProto|null|undefined} location
             * @memberof waiternow.common.GeocodeProto
             * @instance
             */
            GeocodeProto.prototype.location = null;

            /**
             * GeocodeProto timeZone.
             * @member {string} timeZone
             * @memberof waiternow.common.GeocodeProto
             * @instance
             */
            GeocodeProto.prototype.timeZone = "";

            /**
             * GeocodeProto formattedAddress.
             * @member {string} formattedAddress
             * @memberof waiternow.common.GeocodeProto
             * @instance
             */
            GeocodeProto.prototype.formattedAddress = "";

            /**
             * GeocodeProto addressComponents.
             * @member {waiternow.common.GeocodeProto.IAddressComponentsProto|null|undefined} addressComponents
             * @memberof waiternow.common.GeocodeProto
             * @instance
             */
            GeocodeProto.prototype.addressComponents = null;

            /**
             * Creates a new GeocodeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {waiternow.common.IGeocodeProto=} [properties] Properties to set
             * @returns {waiternow.common.GeocodeProto} GeocodeProto instance
             */
            GeocodeProto.create = function create(properties) {
                return new GeocodeProto(properties);
            };

            /**
             * Encodes the specified GeocodeProto message. Does not implicitly {@link waiternow.common.GeocodeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {waiternow.common.IGeocodeProto} message GeocodeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeocodeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                    $root.waiternow.common.GeocodeProto.LocationProto.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.timeZone);
                if (message.formattedAddress != null && Object.hasOwnProperty.call(message, "formattedAddress"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.formattedAddress);
                if (message.addressComponents != null && Object.hasOwnProperty.call(message, "addressComponents"))
                    $root.waiternow.common.GeocodeProto.AddressComponentsProto.encode(message.addressComponents, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified GeocodeProto message, length delimited. Does not implicitly {@link waiternow.common.GeocodeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {waiternow.common.IGeocodeProto} message GeocodeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GeocodeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GeocodeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GeocodeProto} GeocodeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeocodeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GeocodeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.location = $root.waiternow.common.GeocodeProto.LocationProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.timeZone = reader.string();
                            break;
                        }
                    case 3: {
                            message.formattedAddress = reader.string();
                            break;
                        }
                    case 4: {
                            message.addressComponents = $root.waiternow.common.GeocodeProto.AddressComponentsProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GeocodeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GeocodeProto} GeocodeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GeocodeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GeocodeProto message.
             * @function verify
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GeocodeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.location != null && message.hasOwnProperty("location")) {
                    var error = $root.waiternow.common.GeocodeProto.LocationProto.verify(message.location);
                    if (error)
                        return "location." + error;
                }
                if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                    if (!$util.isString(message.timeZone))
                        return "timeZone: string expected";
                if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                    if (!$util.isString(message.formattedAddress))
                        return "formattedAddress: string expected";
                if (message.addressComponents != null && message.hasOwnProperty("addressComponents")) {
                    var error = $root.waiternow.common.GeocodeProto.AddressComponentsProto.verify(message.addressComponents);
                    if (error)
                        return "addressComponents." + error;
                }
                return null;
            };

            /**
             * Creates a GeocodeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GeocodeProto} GeocodeProto
             */
            GeocodeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GeocodeProto)
                    return object;
                var message = new $root.waiternow.common.GeocodeProto();
                if (object.location != null) {
                    if (typeof object.location !== "object")
                        throw TypeError(".waiternow.common.GeocodeProto.location: object expected");
                    message.location = $root.waiternow.common.GeocodeProto.LocationProto.fromObject(object.location);
                }
                if (object.timeZone != null)
                    message.timeZone = String(object.timeZone);
                if (object.formattedAddress != null)
                    message.formattedAddress = String(object.formattedAddress);
                if (object.addressComponents != null) {
                    if (typeof object.addressComponents !== "object")
                        throw TypeError(".waiternow.common.GeocodeProto.addressComponents: object expected");
                    message.addressComponents = $root.waiternow.common.GeocodeProto.AddressComponentsProto.fromObject(object.addressComponents);
                }
                return message;
            };

            /**
             * Creates a plain object from a GeocodeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {waiternow.common.GeocodeProto} message GeocodeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GeocodeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.location = null;
                    object.timeZone = "";
                    object.formattedAddress = "";
                    object.addressComponents = null;
                }
                if (message.location != null && message.hasOwnProperty("location"))
                    object.location = $root.waiternow.common.GeocodeProto.LocationProto.toObject(message.location, options);
                if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                    object.timeZone = message.timeZone;
                if (message.formattedAddress != null && message.hasOwnProperty("formattedAddress"))
                    object.formattedAddress = message.formattedAddress;
                if (message.addressComponents != null && message.hasOwnProperty("addressComponents"))
                    object.addressComponents = $root.waiternow.common.GeocodeProto.AddressComponentsProto.toObject(message.addressComponents, options);
                return object;
            };

            /**
             * Converts this GeocodeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GeocodeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GeocodeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GeocodeProto
             * @function getTypeUrl
             * @memberof waiternow.common.GeocodeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GeocodeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GeocodeProto";
            };

            GeocodeProto.LocationProto = (function() {

                /**
                 * Properties of a LocationProto.
                 * @memberof waiternow.common.GeocodeProto
                 * @interface ILocationProto
                 * @property {number|null} [latitud] LocationProto latitud
                 * @property {number|null} [longitude] LocationProto longitude
                 */

                /**
                 * Constructs a new LocationProto.
                 * @memberof waiternow.common.GeocodeProto
                 * @classdesc Represents a LocationProto.
                 * @implements ILocationProto
                 * @constructor
                 * @param {waiternow.common.GeocodeProto.ILocationProto=} [properties] Properties to set
                 */
                function LocationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocationProto latitud.
                 * @member {number} latitud
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @instance
                 */
                LocationProto.prototype.latitud = 0;

                /**
                 * LocationProto longitude.
                 * @member {number} longitude
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @instance
                 */
                LocationProto.prototype.longitude = 0;

                /**
                 * Creates a new LocationProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.ILocationProto=} [properties] Properties to set
                 * @returns {waiternow.common.GeocodeProto.LocationProto} LocationProto instance
                 */
                LocationProto.create = function create(properties) {
                    return new LocationProto(properties);
                };

                /**
                 * Encodes the specified LocationProto message. Does not implicitly {@link waiternow.common.GeocodeProto.LocationProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.ILocationProto} message LocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.latitud != null && Object.hasOwnProperty.call(message, "latitud"))
                        writer.uint32(/* id 1, wireType 1 =*/9).double(message.latitud);
                    if (message.longitude != null && Object.hasOwnProperty.call(message, "longitude"))
                        writer.uint32(/* id 2, wireType 1 =*/17).double(message.longitude);
                    return writer;
                };

                /**
                 * Encodes the specified LocationProto message, length delimited. Does not implicitly {@link waiternow.common.GeocodeProto.LocationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.ILocationProto} message LocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GeocodeProto.LocationProto} LocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GeocodeProto.LocationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.latitud = reader.double();
                                break;
                            }
                        case 2: {
                                message.longitude = reader.double();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GeocodeProto.LocationProto} LocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocationProto message.
                 * @function verify
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.latitud != null && message.hasOwnProperty("latitud"))
                        if (typeof message.latitud !== "number")
                            return "latitud: number expected";
                    if (message.longitude != null && message.hasOwnProperty("longitude"))
                        if (typeof message.longitude !== "number")
                            return "longitude: number expected";
                    return null;
                };

                /**
                 * Creates a LocationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GeocodeProto.LocationProto} LocationProto
                 */
                LocationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GeocodeProto.LocationProto)
                        return object;
                    var message = new $root.waiternow.common.GeocodeProto.LocationProto();
                    if (object.latitud != null)
                        message.latitud = Number(object.latitud);
                    if (object.longitude != null)
                        message.longitude = Number(object.longitude);
                    return message;
                };

                /**
                 * Creates a plain object from a LocationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.LocationProto} message LocationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.latitud = 0;
                        object.longitude = 0;
                    }
                    if (message.latitud != null && message.hasOwnProperty("latitud"))
                        object.latitud = options.json && !isFinite(message.latitud) ? String(message.latitud) : message.latitud;
                    if (message.longitude != null && message.hasOwnProperty("longitude"))
                        object.longitude = options.json && !isFinite(message.longitude) ? String(message.longitude) : message.longitude;
                    return object;
                };

                /**
                 * Converts this LocationProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocationProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.GeocodeProto.LocationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GeocodeProto.LocationProto";
                };

                return LocationProto;
            })();

            GeocodeProto.AddressComponentsProto = (function() {

                /**
                 * Properties of an AddressComponentsProto.
                 * @memberof waiternow.common.GeocodeProto
                 * @interface IAddressComponentsProto
                 * @property {string|null} [number] AddressComponentsProto number
                 * @property {string|null} [street] AddressComponentsProto street
                 * @property {string|null} [streetShortName] AddressComponentsProto streetShortName
                 * @property {string|null} [city] AddressComponentsProto city
                 * @property {string|null} [cityShortName] AddressComponentsProto cityShortName
                 * @property {string|null} [state] AddressComponentsProto state
                 * @property {string|null} [stateShortName] AddressComponentsProto stateShortName
                 * @property {string|null} [postalCode] AddressComponentsProto postalCode
                 * @property {string|null} [country] AddressComponentsProto country
                 * @property {string|null} [countryShortName] AddressComponentsProto countryShortName
                 */

                /**
                 * Constructs a new AddressComponentsProto.
                 * @memberof waiternow.common.GeocodeProto
                 * @classdesc Represents an AddressComponentsProto.
                 * @implements IAddressComponentsProto
                 * @constructor
                 * @param {waiternow.common.GeocodeProto.IAddressComponentsProto=} [properties] Properties to set
                 */
                function AddressComponentsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * AddressComponentsProto number.
                 * @member {string} number
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.number = "";

                /**
                 * AddressComponentsProto street.
                 * @member {string} street
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.street = "";

                /**
                 * AddressComponentsProto streetShortName.
                 * @member {string} streetShortName
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.streetShortName = "";

                /**
                 * AddressComponentsProto city.
                 * @member {string} city
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.city = "";

                /**
                 * AddressComponentsProto cityShortName.
                 * @member {string} cityShortName
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.cityShortName = "";

                /**
                 * AddressComponentsProto state.
                 * @member {string} state
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.state = "";

                /**
                 * AddressComponentsProto stateShortName.
                 * @member {string} stateShortName
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.stateShortName = "";

                /**
                 * AddressComponentsProto postalCode.
                 * @member {string} postalCode
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.postalCode = "";

                /**
                 * AddressComponentsProto country.
                 * @member {string} country
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.country = "";

                /**
                 * AddressComponentsProto countryShortName.
                 * @member {string} countryShortName
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 */
                AddressComponentsProto.prototype.countryShortName = "";

                /**
                 * Creates a new AddressComponentsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.IAddressComponentsProto=} [properties] Properties to set
                 * @returns {waiternow.common.GeocodeProto.AddressComponentsProto} AddressComponentsProto instance
                 */
                AddressComponentsProto.create = function create(properties) {
                    return new AddressComponentsProto(properties);
                };

                /**
                 * Encodes the specified AddressComponentsProto message. Does not implicitly {@link waiternow.common.GeocodeProto.AddressComponentsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.IAddressComponentsProto} message AddressComponentsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressComponentsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.number != null && Object.hasOwnProperty.call(message, "number"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.number);
                    if (message.street != null && Object.hasOwnProperty.call(message, "street"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.street);
                    if (message.streetShortName != null && Object.hasOwnProperty.call(message, "streetShortName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.streetShortName);
                    if (message.city != null && Object.hasOwnProperty.call(message, "city"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.city);
                    if (message.cityShortName != null && Object.hasOwnProperty.call(message, "cityShortName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.cityShortName);
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.state);
                    if (message.stateShortName != null && Object.hasOwnProperty.call(message, "stateShortName"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.stateShortName);
                    if (message.postalCode != null && Object.hasOwnProperty.call(message, "postalCode"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.postalCode);
                    if (message.country != null && Object.hasOwnProperty.call(message, "country"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.country);
                    if (message.countryShortName != null && Object.hasOwnProperty.call(message, "countryShortName"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.countryShortName);
                    return writer;
                };

                /**
                 * Encodes the specified AddressComponentsProto message, length delimited. Does not implicitly {@link waiternow.common.GeocodeProto.AddressComponentsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.IAddressComponentsProto} message AddressComponentsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                AddressComponentsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an AddressComponentsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GeocodeProto.AddressComponentsProto} AddressComponentsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressComponentsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GeocodeProto.AddressComponentsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.number = reader.string();
                                break;
                            }
                        case 2: {
                                message.street = reader.string();
                                break;
                            }
                        case 3: {
                                message.streetShortName = reader.string();
                                break;
                            }
                        case 4: {
                                message.city = reader.string();
                                break;
                            }
                        case 5: {
                                message.cityShortName = reader.string();
                                break;
                            }
                        case 6: {
                                message.state = reader.string();
                                break;
                            }
                        case 7: {
                                message.stateShortName = reader.string();
                                break;
                            }
                        case 8: {
                                message.postalCode = reader.string();
                                break;
                            }
                        case 9: {
                                message.country = reader.string();
                                break;
                            }
                        case 10: {
                                message.countryShortName = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an AddressComponentsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GeocodeProto.AddressComponentsProto} AddressComponentsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                AddressComponentsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an AddressComponentsProto message.
                 * @function verify
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                AddressComponentsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.number != null && message.hasOwnProperty("number"))
                        if (!$util.isString(message.number))
                            return "number: string expected";
                    if (message.street != null && message.hasOwnProperty("street"))
                        if (!$util.isString(message.street))
                            return "street: string expected";
                    if (message.streetShortName != null && message.hasOwnProperty("streetShortName"))
                        if (!$util.isString(message.streetShortName))
                            return "streetShortName: string expected";
                    if (message.city != null && message.hasOwnProperty("city"))
                        if (!$util.isString(message.city))
                            return "city: string expected";
                    if (message.cityShortName != null && message.hasOwnProperty("cityShortName"))
                        if (!$util.isString(message.cityShortName))
                            return "cityShortName: string expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        if (!$util.isString(message.state))
                            return "state: string expected";
                    if (message.stateShortName != null && message.hasOwnProperty("stateShortName"))
                        if (!$util.isString(message.stateShortName))
                            return "stateShortName: string expected";
                    if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                        if (!$util.isString(message.postalCode))
                            return "postalCode: string expected";
                    if (message.country != null && message.hasOwnProperty("country"))
                        if (!$util.isString(message.country))
                            return "country: string expected";
                    if (message.countryShortName != null && message.hasOwnProperty("countryShortName"))
                        if (!$util.isString(message.countryShortName))
                            return "countryShortName: string expected";
                    return null;
                };

                /**
                 * Creates an AddressComponentsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GeocodeProto.AddressComponentsProto} AddressComponentsProto
                 */
                AddressComponentsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GeocodeProto.AddressComponentsProto)
                        return object;
                    var message = new $root.waiternow.common.GeocodeProto.AddressComponentsProto();
                    if (object.number != null)
                        message.number = String(object.number);
                    if (object.street != null)
                        message.street = String(object.street);
                    if (object.streetShortName != null)
                        message.streetShortName = String(object.streetShortName);
                    if (object.city != null)
                        message.city = String(object.city);
                    if (object.cityShortName != null)
                        message.cityShortName = String(object.cityShortName);
                    if (object.state != null)
                        message.state = String(object.state);
                    if (object.stateShortName != null)
                        message.stateShortName = String(object.stateShortName);
                    if (object.postalCode != null)
                        message.postalCode = String(object.postalCode);
                    if (object.country != null)
                        message.country = String(object.country);
                    if (object.countryShortName != null)
                        message.countryShortName = String(object.countryShortName);
                    return message;
                };

                /**
                 * Creates a plain object from an AddressComponentsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {waiternow.common.GeocodeProto.AddressComponentsProto} message AddressComponentsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                AddressComponentsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.number = "";
                        object.street = "";
                        object.streetShortName = "";
                        object.city = "";
                        object.cityShortName = "";
                        object.state = "";
                        object.stateShortName = "";
                        object.postalCode = "";
                        object.country = "";
                        object.countryShortName = "";
                    }
                    if (message.number != null && message.hasOwnProperty("number"))
                        object.number = message.number;
                    if (message.street != null && message.hasOwnProperty("street"))
                        object.street = message.street;
                    if (message.streetShortName != null && message.hasOwnProperty("streetShortName"))
                        object.streetShortName = message.streetShortName;
                    if (message.city != null && message.hasOwnProperty("city"))
                        object.city = message.city;
                    if (message.cityShortName != null && message.hasOwnProperty("cityShortName"))
                        object.cityShortName = message.cityShortName;
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = message.state;
                    if (message.stateShortName != null && message.hasOwnProperty("stateShortName"))
                        object.stateShortName = message.stateShortName;
                    if (message.postalCode != null && message.hasOwnProperty("postalCode"))
                        object.postalCode = message.postalCode;
                    if (message.country != null && message.hasOwnProperty("country"))
                        object.country = message.country;
                    if (message.countryShortName != null && message.hasOwnProperty("countryShortName"))
                        object.countryShortName = message.countryShortName;
                    return object;
                };

                /**
                 * Converts this AddressComponentsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                AddressComponentsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for AddressComponentsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.GeocodeProto.AddressComponentsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                AddressComponentsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GeocodeProto.AddressComponentsProto";
                };

                return AddressComponentsProto;
            })();

            return GeocodeProto;
        })();

        common.AddressProto = (function() {

            /**
             * Properties of an AddressProto.
             * @memberof waiternow.common
             * @interface IAddressProto
             * @property {waiternow.common.ISimpleAddressProto|null} [simpleAddress] AddressProto simpleAddress
             * @property {waiternow.common.IStructuredAddressProto|null} [structuredAddress] AddressProto structuredAddress
             * @property {waiternow.common.IGeocodeProto|null} [geocode] AddressProto geocode
             */

            /**
             * Constructs a new AddressProto.
             * @memberof waiternow.common
             * @classdesc Represents an AddressProto.
             * @implements IAddressProto
             * @constructor
             * @param {waiternow.common.IAddressProto=} [properties] Properties to set
             */
            function AddressProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AddressProto simpleAddress.
             * @member {waiternow.common.ISimpleAddressProto|null|undefined} simpleAddress
             * @memberof waiternow.common.AddressProto
             * @instance
             */
            AddressProto.prototype.simpleAddress = null;

            /**
             * AddressProto structuredAddress.
             * @member {waiternow.common.IStructuredAddressProto|null|undefined} structuredAddress
             * @memberof waiternow.common.AddressProto
             * @instance
             */
            AddressProto.prototype.structuredAddress = null;

            /**
             * AddressProto geocode.
             * @member {waiternow.common.IGeocodeProto|null|undefined} geocode
             * @memberof waiternow.common.AddressProto
             * @instance
             */
            AddressProto.prototype.geocode = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * AddressProto address.
             * @member {"simpleAddress"|"structuredAddress"|undefined} address
             * @memberof waiternow.common.AddressProto
             * @instance
             */
            Object.defineProperty(AddressProto.prototype, "address", {
                get: $util.oneOfGetter($oneOfFields = ["simpleAddress", "structuredAddress"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new AddressProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {waiternow.common.IAddressProto=} [properties] Properties to set
             * @returns {waiternow.common.AddressProto} AddressProto instance
             */
            AddressProto.create = function create(properties) {
                return new AddressProto(properties);
            };

            /**
             * Encodes the specified AddressProto message. Does not implicitly {@link waiternow.common.AddressProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {waiternow.common.IAddressProto} message AddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddressProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.simpleAddress != null && Object.hasOwnProperty.call(message, "simpleAddress"))
                    $root.waiternow.common.SimpleAddressProto.encode(message.simpleAddress, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.structuredAddress != null && Object.hasOwnProperty.call(message, "structuredAddress"))
                    $root.waiternow.common.StructuredAddressProto.encode(message.structuredAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.geocode != null && Object.hasOwnProperty.call(message, "geocode"))
                    $root.waiternow.common.GeocodeProto.encode(message.geocode, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AddressProto message, length delimited. Does not implicitly {@link waiternow.common.AddressProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {waiternow.common.IAddressProto} message AddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddressProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AddressProto} AddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddressProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddressProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.simpleAddress = $root.waiternow.common.SimpleAddressProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.structuredAddress = $root.waiternow.common.StructuredAddressProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.geocode = $root.waiternow.common.GeocodeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddressProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AddressProto} AddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddressProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddressProto message.
             * @function verify
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddressProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.simpleAddress != null && message.hasOwnProperty("simpleAddress")) {
                    properties.address = 1;
                    {
                        var error = $root.waiternow.common.SimpleAddressProto.verify(message.simpleAddress);
                        if (error)
                            return "simpleAddress." + error;
                    }
                }
                if (message.structuredAddress != null && message.hasOwnProperty("structuredAddress")) {
                    if (properties.address === 1)
                        return "address: multiple values";
                    properties.address = 1;
                    {
                        var error = $root.waiternow.common.StructuredAddressProto.verify(message.structuredAddress);
                        if (error)
                            return "structuredAddress." + error;
                    }
                }
                if (message.geocode != null && message.hasOwnProperty("geocode")) {
                    var error = $root.waiternow.common.GeocodeProto.verify(message.geocode);
                    if (error)
                        return "geocode." + error;
                }
                return null;
            };

            /**
             * Creates an AddressProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AddressProto} AddressProto
             */
            AddressProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AddressProto)
                    return object;
                var message = new $root.waiternow.common.AddressProto();
                if (object.simpleAddress != null) {
                    if (typeof object.simpleAddress !== "object")
                        throw TypeError(".waiternow.common.AddressProto.simpleAddress: object expected");
                    message.simpleAddress = $root.waiternow.common.SimpleAddressProto.fromObject(object.simpleAddress);
                }
                if (object.structuredAddress != null) {
                    if (typeof object.structuredAddress !== "object")
                        throw TypeError(".waiternow.common.AddressProto.structuredAddress: object expected");
                    message.structuredAddress = $root.waiternow.common.StructuredAddressProto.fromObject(object.structuredAddress);
                }
                if (object.geocode != null) {
                    if (typeof object.geocode !== "object")
                        throw TypeError(".waiternow.common.AddressProto.geocode: object expected");
                    message.geocode = $root.waiternow.common.GeocodeProto.fromObject(object.geocode);
                }
                return message;
            };

            /**
             * Creates a plain object from an AddressProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {waiternow.common.AddressProto} message AddressProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddressProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.geocode = null;
                if (message.simpleAddress != null && message.hasOwnProperty("simpleAddress")) {
                    object.simpleAddress = $root.waiternow.common.SimpleAddressProto.toObject(message.simpleAddress, options);
                    if (options.oneofs)
                        object.address = "simpleAddress";
                }
                if (message.structuredAddress != null && message.hasOwnProperty("structuredAddress")) {
                    object.structuredAddress = $root.waiternow.common.StructuredAddressProto.toObject(message.structuredAddress, options);
                    if (options.oneofs)
                        object.address = "structuredAddress";
                }
                if (message.geocode != null && message.hasOwnProperty("geocode"))
                    object.geocode = $root.waiternow.common.GeocodeProto.toObject(message.geocode, options);
                return object;
            };

            /**
             * Converts this AddressProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AddressProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddressProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddressProto
             * @function getTypeUrl
             * @memberof waiternow.common.AddressProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AddressProto";
            };

            return AddressProto;
        })();

        common.EmailAddressProto = (function() {

            /**
             * Properties of an EmailAddressProto.
             * @memberof waiternow.common
             * @interface IEmailAddressProto
             * @property {string|null} [email] EmailAddressProto email
             * @property {string|null} [name] EmailAddressProto name
             */

            /**
             * Constructs a new EmailAddressProto.
             * @memberof waiternow.common
             * @classdesc Represents an EmailAddressProto.
             * @implements IEmailAddressProto
             * @constructor
             * @param {waiternow.common.IEmailAddressProto=} [properties] Properties to set
             */
            function EmailAddressProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EmailAddressProto email.
             * @member {string} email
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             */
            EmailAddressProto.prototype.email = "";

            /**
             * EmailAddressProto name.
             * @member {string} name
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             */
            EmailAddressProto.prototype.name = "";

            /**
             * Creates a new EmailAddressProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto=} [properties] Properties to set
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto instance
             */
            EmailAddressProto.create = function create(properties) {
                return new EmailAddressProto(properties);
            };

            /**
             * Encodes the specified EmailAddressProto message. Does not implicitly {@link waiternow.common.EmailAddressProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto} message EmailAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailAddressProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.name);
                return writer;
            };

            /**
             * Encodes the specified EmailAddressProto message, length delimited. Does not implicitly {@link waiternow.common.EmailAddressProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.IEmailAddressProto} message EmailAddressProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailAddressProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EmailAddressProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailAddressProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EmailAddressProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EmailAddressProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailAddressProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EmailAddressProto message.
             * @function verify
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EmailAddressProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates an EmailAddressProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EmailAddressProto} EmailAddressProto
             */
            EmailAddressProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EmailAddressProto)
                    return object;
                var message = new $root.waiternow.common.EmailAddressProto();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from an EmailAddressProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {waiternow.common.EmailAddressProto} message EmailAddressProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EmailAddressProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.name = "";
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                return object;
            };

            /**
             * Converts this EmailAddressProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EmailAddressProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EmailAddressProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EmailAddressProto
             * @function getTypeUrl
             * @memberof waiternow.common.EmailAddressProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EmailAddressProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EmailAddressProto";
            };

            return EmailAddressProto;
        })();

        common.PaymentProto = (function() {

            /**
             * Properties of a PaymentProto.
             * @memberof waiternow.common
             * @interface IPaymentProto
             * @property {string|null} [id] PaymentProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] PaymentProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] PaymentProto lastUpdateTime
             * @property {waiternow.common.PaymentProto.PaymentCollector|null} [collector] PaymentProto collector
             * @property {waiternow.common.IMoneyProto|null} [subtotal] PaymentProto subtotal
             * @property {Array.<waiternow.common.IDiscountProto>|null} [discounts] PaymentProto discounts
             * @property {waiternow.common.ITaxesProto|null} [appliedTaxes] PaymentProto appliedTaxes
             * @property {waiternow.common.IMoneyProto|null} [tax] PaymentProto tax
             * @property {waiternow.common.IMoneyProto|null} [tip] PaymentProto tip
             * @property {waiternow.common.IMoneyProto|null} [deliveryFee] PaymentProto deliveryFee
             * @property {boolean|null} [isDeliveryFeePaidToBusiness] PaymentProto isDeliveryFeePaidToBusiness
             * @property {boolean|null} [isDeliveryFeeChargedToWaiterNow] PaymentProto isDeliveryFeeChargedToWaiterNow
             * @property {waiternow.common.IMoneyProto|null} [deliveryTip] PaymentProto deliveryTip
             * @property {waiternow.common.IMoneyProto|null} [creditCardFeeAdjustment] PaymentProto creditCardFeeAdjustment
             * @property {boolean|null} [isCreditCardFeeChargedToConsumer] PaymentProto isCreditCardFeeChargedToConsumer
             * @property {waiternow.common.IMoneyProto|null} [waiterNowFee] PaymentProto waiterNowFee
             * @property {waiternow.common.IMoneyProto|null} [serviceFee] PaymentProto serviceFee
             * @property {waiternow.common.IMoneyProto|null} [stripeFee] PaymentProto stripeFee
             * @property {waiternow.common.IMoneyProto|null} [applicationFee] PaymentProto applicationFee
             * @property {waiternow.common.IMoneyProto|null} [netPaidToBusiness] PaymentProto netPaidToBusiness
             * @property {waiternow.common.IMoneyProto|null} [total] PaymentProto total
             * @property {google.protobuf.ITimestamp|null} [paymentTime] PaymentProto paymentTime
             * @property {waiternow.common.PaymentProto.Status|null} [status] PaymentProto status
             * @property {string|null} [error] PaymentProto error
             * @property {string|null} [expectedReference] PaymentProto expectedReference
             * @property {string|null} [referenceFromPaymentCollector] PaymentProto referenceFromPaymentCollector
             * @property {waiternow.common.PaymentProto.ICancelationStatusProto|null} [cancelationStatus] PaymentProto cancelationStatus
             * @property {waiternow.common.PaymentProto.IRefundStatusProto|null} [refundStatus] PaymentProto refundStatus
             * @property {Array.<waiternow.common.PaymentProto.IPartialRefundProto>|null} [partialRefunds] PaymentProto partialRefunds
             * @property {waiternow.common.PaymentProto.IDisputeProto|null} [dispute] PaymentProto dispute
             * @property {string|null} [stripeClientSecret] PaymentProto stripeClientSecret
             * @property {string|null} [payerEmail] PaymentProto payerEmail
             * @property {waiternow.common.IPhoneNumberProto|null} [payerPhoneNumber] PaymentProto payerPhoneNumber
             */

            /**
             * Constructs a new PaymentProto.
             * @memberof waiternow.common
             * @classdesc Represents a PaymentProto.
             * @implements IPaymentProto
             * @constructor
             * @param {waiternow.common.IPaymentProto=} [properties] Properties to set
             */
            function PaymentProto(properties) {
                this.discounts = [];
                this.partialRefunds = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PaymentProto id.
             * @member {string} id
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.id = "";

            /**
             * PaymentProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.creationTime = null;

            /**
             * PaymentProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.lastUpdateTime = null;

            /**
             * PaymentProto collector.
             * @member {waiternow.common.PaymentProto.PaymentCollector} collector
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.collector = 0;

            /**
             * PaymentProto subtotal.
             * @member {waiternow.common.IMoneyProto|null|undefined} subtotal
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.subtotal = null;

            /**
             * PaymentProto discounts.
             * @member {Array.<waiternow.common.IDiscountProto>} discounts
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.discounts = $util.emptyArray;

            /**
             * PaymentProto appliedTaxes.
             * @member {waiternow.common.ITaxesProto|null|undefined} appliedTaxes
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.appliedTaxes = null;

            /**
             * PaymentProto tax.
             * @member {waiternow.common.IMoneyProto|null|undefined} tax
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.tax = null;

            /**
             * PaymentProto tip.
             * @member {waiternow.common.IMoneyProto|null|undefined} tip
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.tip = null;

            /**
             * PaymentProto deliveryFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryFee
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.deliveryFee = null;

            /**
             * PaymentProto isDeliveryFeePaidToBusiness.
             * @member {boolean} isDeliveryFeePaidToBusiness
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.isDeliveryFeePaidToBusiness = false;

            /**
             * PaymentProto isDeliveryFeeChargedToWaiterNow.
             * @member {boolean} isDeliveryFeeChargedToWaiterNow
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.isDeliveryFeeChargedToWaiterNow = false;

            /**
             * PaymentProto deliveryTip.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryTip
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.deliveryTip = null;

            /**
             * PaymentProto creditCardFeeAdjustment.
             * @member {waiternow.common.IMoneyProto|null|undefined} creditCardFeeAdjustment
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.creditCardFeeAdjustment = null;

            /**
             * PaymentProto isCreditCardFeeChargedToConsumer.
             * @member {boolean} isCreditCardFeeChargedToConsumer
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.isCreditCardFeeChargedToConsumer = false;

            /**
             * PaymentProto waiterNowFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} waiterNowFee
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.waiterNowFee = null;

            /**
             * PaymentProto serviceFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} serviceFee
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.serviceFee = null;

            /**
             * PaymentProto stripeFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} stripeFee
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.stripeFee = null;

            /**
             * PaymentProto applicationFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} applicationFee
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.applicationFee = null;

            /**
             * PaymentProto netPaidToBusiness.
             * @member {waiternow.common.IMoneyProto|null|undefined} netPaidToBusiness
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.netPaidToBusiness = null;

            /**
             * PaymentProto total.
             * @member {waiternow.common.IMoneyProto|null|undefined} total
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.total = null;

            /**
             * PaymentProto paymentTime.
             * @member {google.protobuf.ITimestamp|null|undefined} paymentTime
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.paymentTime = null;

            /**
             * PaymentProto status.
             * @member {waiternow.common.PaymentProto.Status} status
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.status = 0;

            /**
             * PaymentProto error.
             * @member {string} error
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.error = "";

            /**
             * PaymentProto expectedReference.
             * @member {string} expectedReference
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.expectedReference = "";

            /**
             * PaymentProto referenceFromPaymentCollector.
             * @member {string} referenceFromPaymentCollector
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.referenceFromPaymentCollector = "";

            /**
             * PaymentProto cancelationStatus.
             * @member {waiternow.common.PaymentProto.ICancelationStatusProto|null|undefined} cancelationStatus
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.cancelationStatus = null;

            /**
             * PaymentProto refundStatus.
             * @member {waiternow.common.PaymentProto.IRefundStatusProto|null|undefined} refundStatus
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.refundStatus = null;

            /**
             * PaymentProto partialRefunds.
             * @member {Array.<waiternow.common.PaymentProto.IPartialRefundProto>} partialRefunds
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.partialRefunds = $util.emptyArray;

            /**
             * PaymentProto dispute.
             * @member {waiternow.common.PaymentProto.IDisputeProto|null|undefined} dispute
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.dispute = null;

            /**
             * PaymentProto stripeClientSecret.
             * @member {string} stripeClientSecret
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.stripeClientSecret = "";

            /**
             * PaymentProto payerEmail.
             * @member {string} payerEmail
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.payerEmail = "";

            /**
             * PaymentProto payerPhoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} payerPhoneNumber
             * @memberof waiternow.common.PaymentProto
             * @instance
             */
            PaymentProto.prototype.payerPhoneNumber = null;

            /**
             * Creates a new PaymentProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {waiternow.common.IPaymentProto=} [properties] Properties to set
             * @returns {waiternow.common.PaymentProto} PaymentProto instance
             */
            PaymentProto.create = function create(properties) {
                return new PaymentProto(properties);
            };

            /**
             * Encodes the specified PaymentProto message. Does not implicitly {@link waiternow.common.PaymentProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {waiternow.common.IPaymentProto} message PaymentProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.collector != null && Object.hasOwnProperty.call(message, "collector"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.collector);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.subtotal != null && Object.hasOwnProperty.call(message, "subtotal"))
                    $root.waiternow.common.MoneyProto.encode(message.subtotal, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.appliedTaxes != null && Object.hasOwnProperty.call(message, "appliedTaxes"))
                    $root.waiternow.common.TaxesProto.encode(message.appliedTaxes, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.tax != null && Object.hasOwnProperty.call(message, "tax"))
                    $root.waiternow.common.MoneyProto.encode(message.tax, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.waiterNowFee != null && Object.hasOwnProperty.call(message, "waiterNowFee"))
                    $root.waiternow.common.MoneyProto.encode(message.waiterNowFee, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
                    $root.waiternow.common.MoneyProto.encode(message.tip, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    $root.waiternow.common.MoneyProto.encode(message.total, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.paymentTime != null && Object.hasOwnProperty.call(message, "paymentTime"))
                    $root.google.protobuf.Timestamp.encode(message.paymentTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.status);
                if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.error);
                if (message.expectedReference != null && Object.hasOwnProperty.call(message, "expectedReference"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.expectedReference);
                if (message.referenceFromPaymentCollector != null && Object.hasOwnProperty.call(message, "referenceFromPaymentCollector"))
                    writer.uint32(/* id 14, wireType 2 =*/114).string(message.referenceFromPaymentCollector);
                if (message.cancelationStatus != null && Object.hasOwnProperty.call(message, "cancelationStatus"))
                    $root.waiternow.common.PaymentProto.CancelationStatusProto.encode(message.cancelationStatus, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.refundStatus != null && Object.hasOwnProperty.call(message, "refundStatus"))
                    $root.waiternow.common.PaymentProto.RefundStatusProto.encode(message.refundStatus, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.stripeClientSecret != null && Object.hasOwnProperty.call(message, "stripeClientSecret"))
                    writer.uint32(/* id 17, wireType 2 =*/138).string(message.stripeClientSecret);
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.partialRefunds != null && message.partialRefunds.length)
                    for (var i = 0; i < message.partialRefunds.length; ++i)
                        $root.waiternow.common.PaymentProto.PartialRefundProto.encode(message.partialRefunds[i], writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.discounts != null && message.discounts.length)
                    for (var i = 0; i < message.discounts.length; ++i)
                        $root.waiternow.common.DiscountProto.encode(message.discounts[i], writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.payerEmail != null && Object.hasOwnProperty.call(message, "payerEmail"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.payerEmail);
                if (message.payerPhoneNumber != null && Object.hasOwnProperty.call(message, "payerPhoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.payerPhoneNumber, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.deliveryFee != null && Object.hasOwnProperty.call(message, "deliveryFee"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryFee, writer.uint32(/* id 24, wireType 2 =*/194).fork()).ldelim();
                if (message.isDeliveryFeePaidToBusiness != null && Object.hasOwnProperty.call(message, "isDeliveryFeePaidToBusiness"))
                    writer.uint32(/* id 25, wireType 0 =*/200).bool(message.isDeliveryFeePaidToBusiness);
                if (message.isDeliveryFeeChargedToWaiterNow != null && Object.hasOwnProperty.call(message, "isDeliveryFeeChargedToWaiterNow"))
                    writer.uint32(/* id 26, wireType 0 =*/208).bool(message.isDeliveryFeeChargedToWaiterNow);
                if (message.deliveryTip != null && Object.hasOwnProperty.call(message, "deliveryTip"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryTip, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.creditCardFeeAdjustment != null && Object.hasOwnProperty.call(message, "creditCardFeeAdjustment"))
                    $root.waiternow.common.MoneyProto.encode(message.creditCardFeeAdjustment, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.serviceFee != null && Object.hasOwnProperty.call(message, "serviceFee"))
                    $root.waiternow.common.MoneyProto.encode(message.serviceFee, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
                if (message.stripeFee != null && Object.hasOwnProperty.call(message, "stripeFee"))
                    $root.waiternow.common.MoneyProto.encode(message.stripeFee, writer.uint32(/* id 30, wireType 2 =*/242).fork()).ldelim();
                if (message.applicationFee != null && Object.hasOwnProperty.call(message, "applicationFee"))
                    $root.waiternow.common.MoneyProto.encode(message.applicationFee, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
                if (message.netPaidToBusiness != null && Object.hasOwnProperty.call(message, "netPaidToBusiness"))
                    $root.waiternow.common.MoneyProto.encode(message.netPaidToBusiness, writer.uint32(/* id 32, wireType 2 =*/258).fork()).ldelim();
                if (message.isCreditCardFeeChargedToConsumer != null && Object.hasOwnProperty.call(message, "isCreditCardFeeChargedToConsumer"))
                    writer.uint32(/* id 33, wireType 0 =*/264).bool(message.isCreditCardFeeChargedToConsumer);
                if (message.dispute != null && Object.hasOwnProperty.call(message, "dispute"))
                    $root.waiternow.common.PaymentProto.DisputeProto.encode(message.dispute, writer.uint32(/* id 34, wireType 2 =*/274).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PaymentProto message, length delimited. Does not implicitly {@link waiternow.common.PaymentProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {waiternow.common.IPaymentProto} message PaymentProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaymentProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PaymentProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PaymentProto} PaymentProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaymentProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 3: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.collector = reader.int32();
                            break;
                        }
                    case 4: {
                            message.subtotal = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 20: {
                            if (!(message.discounts && message.discounts.length))
                                message.discounts = [];
                            message.discounts.push($root.waiternow.common.DiscountProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            message.appliedTaxes = $root.waiternow.common.TaxesProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.tax = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.tip = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 24: {
                            message.deliveryFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 25: {
                            message.isDeliveryFeePaidToBusiness = reader.bool();
                            break;
                        }
                    case 26: {
                            message.isDeliveryFeeChargedToWaiterNow = reader.bool();
                            break;
                        }
                    case 27: {
                            message.deliveryTip = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 28: {
                            message.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 33: {
                            message.isCreditCardFeeChargedToConsumer = reader.bool();
                            break;
                        }
                    case 7: {
                            message.waiterNowFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 29: {
                            message.serviceFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 30: {
                            message.stripeFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 31: {
                            message.applicationFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 32: {
                            message.netPaidToBusiness = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.total = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.paymentTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.status = reader.int32();
                            break;
                        }
                    case 12: {
                            message.error = reader.string();
                            break;
                        }
                    case 13: {
                            message.expectedReference = reader.string();
                            break;
                        }
                    case 14: {
                            message.referenceFromPaymentCollector = reader.string();
                            break;
                        }
                    case 15: {
                            message.cancelationStatus = $root.waiternow.common.PaymentProto.CancelationStatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.refundStatus = $root.waiternow.common.PaymentProto.RefundStatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            if (!(message.partialRefunds && message.partialRefunds.length))
                                message.partialRefunds = [];
                            message.partialRefunds.push($root.waiternow.common.PaymentProto.PartialRefundProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 34: {
                            message.dispute = $root.waiternow.common.PaymentProto.DisputeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.stripeClientSecret = reader.string();
                            break;
                        }
                    case 21: {
                            message.payerEmail = reader.string();
                            break;
                        }
                    case 22: {
                            message.payerPhoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PaymentProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PaymentProto} PaymentProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaymentProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PaymentProto message.
             * @function verify
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaymentProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.collector != null && message.hasOwnProperty("collector"))
                    switch (message.collector) {
                    default:
                        return "collector: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.subtotal != null && message.hasOwnProperty("subtotal")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.subtotal);
                    if (error)
                        return "subtotal." + error;
                }
                if (message.discounts != null && message.hasOwnProperty("discounts")) {
                    if (!Array.isArray(message.discounts))
                        return "discounts: array expected";
                    for (var i = 0; i < message.discounts.length; ++i) {
                        var error = $root.waiternow.common.DiscountProto.verify(message.discounts[i]);
                        if (error)
                            return "discounts." + error;
                    }
                }
                if (message.appliedTaxes != null && message.hasOwnProperty("appliedTaxes")) {
                    var error = $root.waiternow.common.TaxesProto.verify(message.appliedTaxes);
                    if (error)
                        return "appliedTaxes." + error;
                }
                if (message.tax != null && message.hasOwnProperty("tax")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.tax);
                    if (error)
                        return "tax." + error;
                }
                if (message.tip != null && message.hasOwnProperty("tip")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.tip);
                    if (error)
                        return "tip." + error;
                }
                if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryFee);
                    if (error)
                        return "deliveryFee." + error;
                }
                if (message.isDeliveryFeePaidToBusiness != null && message.hasOwnProperty("isDeliveryFeePaidToBusiness"))
                    if (typeof message.isDeliveryFeePaidToBusiness !== "boolean")
                        return "isDeliveryFeePaidToBusiness: boolean expected";
                if (message.isDeliveryFeeChargedToWaiterNow != null && message.hasOwnProperty("isDeliveryFeeChargedToWaiterNow"))
                    if (typeof message.isDeliveryFeeChargedToWaiterNow !== "boolean")
                        return "isDeliveryFeeChargedToWaiterNow: boolean expected";
                if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryTip);
                    if (error)
                        return "deliveryTip." + error;
                }
                if (message.creditCardFeeAdjustment != null && message.hasOwnProperty("creditCardFeeAdjustment")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.creditCardFeeAdjustment);
                    if (error)
                        return "creditCardFeeAdjustment." + error;
                }
                if (message.isCreditCardFeeChargedToConsumer != null && message.hasOwnProperty("isCreditCardFeeChargedToConsumer"))
                    if (typeof message.isCreditCardFeeChargedToConsumer !== "boolean")
                        return "isCreditCardFeeChargedToConsumer: boolean expected";
                if (message.waiterNowFee != null && message.hasOwnProperty("waiterNowFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.waiterNowFee);
                    if (error)
                        return "waiterNowFee." + error;
                }
                if (message.serviceFee != null && message.hasOwnProperty("serviceFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.serviceFee);
                    if (error)
                        return "serviceFee." + error;
                }
                if (message.stripeFee != null && message.hasOwnProperty("stripeFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.stripeFee);
                    if (error)
                        return "stripeFee." + error;
                }
                if (message.applicationFee != null && message.hasOwnProperty("applicationFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.applicationFee);
                    if (error)
                        return "applicationFee." + error;
                }
                if (message.netPaidToBusiness != null && message.hasOwnProperty("netPaidToBusiness")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.netPaidToBusiness);
                    if (error)
                        return "netPaidToBusiness." + error;
                }
                if (message.total != null && message.hasOwnProperty("total")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.total);
                    if (error)
                        return "total." + error;
                }
                if (message.paymentTime != null && message.hasOwnProperty("paymentTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.paymentTime);
                    if (error)
                        return "paymentTime." + error;
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.error != null && message.hasOwnProperty("error"))
                    if (!$util.isString(message.error))
                        return "error: string expected";
                if (message.expectedReference != null && message.hasOwnProperty("expectedReference"))
                    if (!$util.isString(message.expectedReference))
                        return "expectedReference: string expected";
                if (message.referenceFromPaymentCollector != null && message.hasOwnProperty("referenceFromPaymentCollector"))
                    if (!$util.isString(message.referenceFromPaymentCollector))
                        return "referenceFromPaymentCollector: string expected";
                if (message.cancelationStatus != null && message.hasOwnProperty("cancelationStatus")) {
                    var error = $root.waiternow.common.PaymentProto.CancelationStatusProto.verify(message.cancelationStatus);
                    if (error)
                        return "cancelationStatus." + error;
                }
                if (message.refundStatus != null && message.hasOwnProperty("refundStatus")) {
                    var error = $root.waiternow.common.PaymentProto.RefundStatusProto.verify(message.refundStatus);
                    if (error)
                        return "refundStatus." + error;
                }
                if (message.partialRefunds != null && message.hasOwnProperty("partialRefunds")) {
                    if (!Array.isArray(message.partialRefunds))
                        return "partialRefunds: array expected";
                    for (var i = 0; i < message.partialRefunds.length; ++i) {
                        var error = $root.waiternow.common.PaymentProto.PartialRefundProto.verify(message.partialRefunds[i]);
                        if (error)
                            return "partialRefunds." + error;
                    }
                }
                if (message.dispute != null && message.hasOwnProperty("dispute")) {
                    var error = $root.waiternow.common.PaymentProto.DisputeProto.verify(message.dispute);
                    if (error)
                        return "dispute." + error;
                }
                if (message.stripeClientSecret != null && message.hasOwnProperty("stripeClientSecret"))
                    if (!$util.isString(message.stripeClientSecret))
                        return "stripeClientSecret: string expected";
                if (message.payerEmail != null && message.hasOwnProperty("payerEmail"))
                    if (!$util.isString(message.payerEmail))
                        return "payerEmail: string expected";
                if (message.payerPhoneNumber != null && message.hasOwnProperty("payerPhoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.payerPhoneNumber);
                    if (error)
                        return "payerPhoneNumber." + error;
                }
                return null;
            };

            /**
             * Creates a PaymentProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PaymentProto} PaymentProto
             */
            PaymentProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PaymentProto)
                    return object;
                var message = new $root.waiternow.common.PaymentProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                switch (object.collector) {
                default:
                    if (typeof object.collector === "number") {
                        message.collector = object.collector;
                        break;
                    }
                    break;
                case "PAYMENT_COLLECTOR_UNSPECIFIED":
                case 0:
                    message.collector = 0;
                    break;
                case "PAYPAL_USA":
                case 1:
                    message.collector = 1;
                    break;
                case "STRIPE_USA":
                case 2:
                    message.collector = 2;
                    break;
                }
                if (object.subtotal != null) {
                    if (typeof object.subtotal !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.subtotal: object expected");
                    message.subtotal = $root.waiternow.common.MoneyProto.fromObject(object.subtotal);
                }
                if (object.discounts) {
                    if (!Array.isArray(object.discounts))
                        throw TypeError(".waiternow.common.PaymentProto.discounts: array expected");
                    message.discounts = [];
                    for (var i = 0; i < object.discounts.length; ++i) {
                        if (typeof object.discounts[i] !== "object")
                            throw TypeError(".waiternow.common.PaymentProto.discounts: object expected");
                        message.discounts[i] = $root.waiternow.common.DiscountProto.fromObject(object.discounts[i]);
                    }
                }
                if (object.appliedTaxes != null) {
                    if (typeof object.appliedTaxes !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.appliedTaxes: object expected");
                    message.appliedTaxes = $root.waiternow.common.TaxesProto.fromObject(object.appliedTaxes);
                }
                if (object.tax != null) {
                    if (typeof object.tax !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.tax: object expected");
                    message.tax = $root.waiternow.common.MoneyProto.fromObject(object.tax);
                }
                if (object.tip != null) {
                    if (typeof object.tip !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.tip: object expected");
                    message.tip = $root.waiternow.common.MoneyProto.fromObject(object.tip);
                }
                if (object.deliveryFee != null) {
                    if (typeof object.deliveryFee !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.deliveryFee: object expected");
                    message.deliveryFee = $root.waiternow.common.MoneyProto.fromObject(object.deliveryFee);
                }
                if (object.isDeliveryFeePaidToBusiness != null)
                    message.isDeliveryFeePaidToBusiness = Boolean(object.isDeliveryFeePaidToBusiness);
                if (object.isDeliveryFeeChargedToWaiterNow != null)
                    message.isDeliveryFeeChargedToWaiterNow = Boolean(object.isDeliveryFeeChargedToWaiterNow);
                if (object.deliveryTip != null) {
                    if (typeof object.deliveryTip !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.deliveryTip: object expected");
                    message.deliveryTip = $root.waiternow.common.MoneyProto.fromObject(object.deliveryTip);
                }
                if (object.creditCardFeeAdjustment != null) {
                    if (typeof object.creditCardFeeAdjustment !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.creditCardFeeAdjustment: object expected");
                    message.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.fromObject(object.creditCardFeeAdjustment);
                }
                if (object.isCreditCardFeeChargedToConsumer != null)
                    message.isCreditCardFeeChargedToConsumer = Boolean(object.isCreditCardFeeChargedToConsumer);
                if (object.waiterNowFee != null) {
                    if (typeof object.waiterNowFee !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.waiterNowFee: object expected");
                    message.waiterNowFee = $root.waiternow.common.MoneyProto.fromObject(object.waiterNowFee);
                }
                if (object.serviceFee != null) {
                    if (typeof object.serviceFee !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.serviceFee: object expected");
                    message.serviceFee = $root.waiternow.common.MoneyProto.fromObject(object.serviceFee);
                }
                if (object.stripeFee != null) {
                    if (typeof object.stripeFee !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.stripeFee: object expected");
                    message.stripeFee = $root.waiternow.common.MoneyProto.fromObject(object.stripeFee);
                }
                if (object.applicationFee != null) {
                    if (typeof object.applicationFee !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.applicationFee: object expected");
                    message.applicationFee = $root.waiternow.common.MoneyProto.fromObject(object.applicationFee);
                }
                if (object.netPaidToBusiness != null) {
                    if (typeof object.netPaidToBusiness !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.netPaidToBusiness: object expected");
                    message.netPaidToBusiness = $root.waiternow.common.MoneyProto.fromObject(object.netPaidToBusiness);
                }
                if (object.total != null) {
                    if (typeof object.total !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.total: object expected");
                    message.total = $root.waiternow.common.MoneyProto.fromObject(object.total);
                }
                if (object.paymentTime != null) {
                    if (typeof object.paymentTime !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.paymentTime: object expected");
                    message.paymentTime = $root.google.protobuf.Timestamp.fromObject(object.paymentTime);
                }
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "STATUS_UNSPECIFIED":
                case 0:
                    message.status = 0;
                    break;
                case "SUCCESSFUL":
                case 1:
                    message.status = 1;
                    break;
                case "REQUIRES_PAYMENT_METHOD":
                case 2:
                    message.status = 2;
                    break;
                case "REQUIRES_ACTION":
                case 3:
                    message.status = 3;
                    break;
                case "PROCESSING":
                case 4:
                    message.status = 4;
                    break;
                case "DECLINED":
                case 5:
                    message.status = 5;
                    break;
                case "CANCELED":
                case 6:
                    message.status = 6;
                    break;
                case "REFUNDED":
                case 7:
                    message.status = 7;
                    break;
                case "DISPUTED":
                case 8:
                    message.status = 8;
                    break;
                }
                if (object.error != null)
                    message.error = String(object.error);
                if (object.expectedReference != null)
                    message.expectedReference = String(object.expectedReference);
                if (object.referenceFromPaymentCollector != null)
                    message.referenceFromPaymentCollector = String(object.referenceFromPaymentCollector);
                if (object.cancelationStatus != null) {
                    if (typeof object.cancelationStatus !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.cancelationStatus: object expected");
                    message.cancelationStatus = $root.waiternow.common.PaymentProto.CancelationStatusProto.fromObject(object.cancelationStatus);
                }
                if (object.refundStatus != null) {
                    if (typeof object.refundStatus !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.refundStatus: object expected");
                    message.refundStatus = $root.waiternow.common.PaymentProto.RefundStatusProto.fromObject(object.refundStatus);
                }
                if (object.partialRefunds) {
                    if (!Array.isArray(object.partialRefunds))
                        throw TypeError(".waiternow.common.PaymentProto.partialRefunds: array expected");
                    message.partialRefunds = [];
                    for (var i = 0; i < object.partialRefunds.length; ++i) {
                        if (typeof object.partialRefunds[i] !== "object")
                            throw TypeError(".waiternow.common.PaymentProto.partialRefunds: object expected");
                        message.partialRefunds[i] = $root.waiternow.common.PaymentProto.PartialRefundProto.fromObject(object.partialRefunds[i]);
                    }
                }
                if (object.dispute != null) {
                    if (typeof object.dispute !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.dispute: object expected");
                    message.dispute = $root.waiternow.common.PaymentProto.DisputeProto.fromObject(object.dispute);
                }
                if (object.stripeClientSecret != null)
                    message.stripeClientSecret = String(object.stripeClientSecret);
                if (object.payerEmail != null)
                    message.payerEmail = String(object.payerEmail);
                if (object.payerPhoneNumber != null) {
                    if (typeof object.payerPhoneNumber !== "object")
                        throw TypeError(".waiternow.common.PaymentProto.payerPhoneNumber: object expected");
                    message.payerPhoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.payerPhoneNumber);
                }
                return message;
            };

            /**
             * Creates a plain object from a PaymentProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {waiternow.common.PaymentProto} message PaymentProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaymentProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.partialRefunds = [];
                    object.discounts = [];
                }
                if (options.defaults) {
                    object.id = "";
                    object.collector = options.enums === String ? "PAYMENT_COLLECTOR_UNSPECIFIED" : 0;
                    object.creationTime = null;
                    object.subtotal = null;
                    object.appliedTaxes = null;
                    object.tax = null;
                    object.waiterNowFee = null;
                    object.tip = null;
                    object.total = null;
                    object.paymentTime = null;
                    object.status = options.enums === String ? "STATUS_UNSPECIFIED" : 0;
                    object.error = "";
                    object.expectedReference = "";
                    object.referenceFromPaymentCollector = "";
                    object.cancelationStatus = null;
                    object.refundStatus = null;
                    object.stripeClientSecret = "";
                    object.lastUpdateTime = null;
                    object.payerEmail = "";
                    object.payerPhoneNumber = null;
                    object.deliveryFee = null;
                    object.isDeliveryFeePaidToBusiness = false;
                    object.isDeliveryFeeChargedToWaiterNow = false;
                    object.deliveryTip = null;
                    object.creditCardFeeAdjustment = null;
                    object.serviceFee = null;
                    object.stripeFee = null;
                    object.applicationFee = null;
                    object.netPaidToBusiness = null;
                    object.isCreditCardFeeChargedToConsumer = false;
                    object.dispute = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.collector != null && message.hasOwnProperty("collector"))
                    object.collector = options.enums === String ? $root.waiternow.common.PaymentProto.PaymentCollector[message.collector] === undefined ? message.collector : $root.waiternow.common.PaymentProto.PaymentCollector[message.collector] : message.collector;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.subtotal != null && message.hasOwnProperty("subtotal"))
                    object.subtotal = $root.waiternow.common.MoneyProto.toObject(message.subtotal, options);
                if (message.appliedTaxes != null && message.hasOwnProperty("appliedTaxes"))
                    object.appliedTaxes = $root.waiternow.common.TaxesProto.toObject(message.appliedTaxes, options);
                if (message.tax != null && message.hasOwnProperty("tax"))
                    object.tax = $root.waiternow.common.MoneyProto.toObject(message.tax, options);
                if (message.waiterNowFee != null && message.hasOwnProperty("waiterNowFee"))
                    object.waiterNowFee = $root.waiternow.common.MoneyProto.toObject(message.waiterNowFee, options);
                if (message.tip != null && message.hasOwnProperty("tip"))
                    object.tip = $root.waiternow.common.MoneyProto.toObject(message.tip, options);
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = $root.waiternow.common.MoneyProto.toObject(message.total, options);
                if (message.paymentTime != null && message.hasOwnProperty("paymentTime"))
                    object.paymentTime = $root.google.protobuf.Timestamp.toObject(message.paymentTime, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.waiternow.common.PaymentProto.Status[message.status] === undefined ? message.status : $root.waiternow.common.PaymentProto.Status[message.status] : message.status;
                if (message.error != null && message.hasOwnProperty("error"))
                    object.error = message.error;
                if (message.expectedReference != null && message.hasOwnProperty("expectedReference"))
                    object.expectedReference = message.expectedReference;
                if (message.referenceFromPaymentCollector != null && message.hasOwnProperty("referenceFromPaymentCollector"))
                    object.referenceFromPaymentCollector = message.referenceFromPaymentCollector;
                if (message.cancelationStatus != null && message.hasOwnProperty("cancelationStatus"))
                    object.cancelationStatus = $root.waiternow.common.PaymentProto.CancelationStatusProto.toObject(message.cancelationStatus, options);
                if (message.refundStatus != null && message.hasOwnProperty("refundStatus"))
                    object.refundStatus = $root.waiternow.common.PaymentProto.RefundStatusProto.toObject(message.refundStatus, options);
                if (message.stripeClientSecret != null && message.hasOwnProperty("stripeClientSecret"))
                    object.stripeClientSecret = message.stripeClientSecret;
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.partialRefunds && message.partialRefunds.length) {
                    object.partialRefunds = [];
                    for (var j = 0; j < message.partialRefunds.length; ++j)
                        object.partialRefunds[j] = $root.waiternow.common.PaymentProto.PartialRefundProto.toObject(message.partialRefunds[j], options);
                }
                if (message.discounts && message.discounts.length) {
                    object.discounts = [];
                    for (var j = 0; j < message.discounts.length; ++j)
                        object.discounts[j] = $root.waiternow.common.DiscountProto.toObject(message.discounts[j], options);
                }
                if (message.payerEmail != null && message.hasOwnProperty("payerEmail"))
                    object.payerEmail = message.payerEmail;
                if (message.payerPhoneNumber != null && message.hasOwnProperty("payerPhoneNumber"))
                    object.payerPhoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.payerPhoneNumber, options);
                if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                    object.deliveryFee = $root.waiternow.common.MoneyProto.toObject(message.deliveryFee, options);
                if (message.isDeliveryFeePaidToBusiness != null && message.hasOwnProperty("isDeliveryFeePaidToBusiness"))
                    object.isDeliveryFeePaidToBusiness = message.isDeliveryFeePaidToBusiness;
                if (message.isDeliveryFeeChargedToWaiterNow != null && message.hasOwnProperty("isDeliveryFeeChargedToWaiterNow"))
                    object.isDeliveryFeeChargedToWaiterNow = message.isDeliveryFeeChargedToWaiterNow;
                if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip"))
                    object.deliveryTip = $root.waiternow.common.MoneyProto.toObject(message.deliveryTip, options);
                if (message.creditCardFeeAdjustment != null && message.hasOwnProperty("creditCardFeeAdjustment"))
                    object.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.toObject(message.creditCardFeeAdjustment, options);
                if (message.serviceFee != null && message.hasOwnProperty("serviceFee"))
                    object.serviceFee = $root.waiternow.common.MoneyProto.toObject(message.serviceFee, options);
                if (message.stripeFee != null && message.hasOwnProperty("stripeFee"))
                    object.stripeFee = $root.waiternow.common.MoneyProto.toObject(message.stripeFee, options);
                if (message.applicationFee != null && message.hasOwnProperty("applicationFee"))
                    object.applicationFee = $root.waiternow.common.MoneyProto.toObject(message.applicationFee, options);
                if (message.netPaidToBusiness != null && message.hasOwnProperty("netPaidToBusiness"))
                    object.netPaidToBusiness = $root.waiternow.common.MoneyProto.toObject(message.netPaidToBusiness, options);
                if (message.isCreditCardFeeChargedToConsumer != null && message.hasOwnProperty("isCreditCardFeeChargedToConsumer"))
                    object.isCreditCardFeeChargedToConsumer = message.isCreditCardFeeChargedToConsumer;
                if (message.dispute != null && message.hasOwnProperty("dispute"))
                    object.dispute = $root.waiternow.common.PaymentProto.DisputeProto.toObject(message.dispute, options);
                return object;
            };

            /**
             * Converts this PaymentProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PaymentProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaymentProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PaymentProto
             * @function getTypeUrl
             * @memberof waiternow.common.PaymentProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaymentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PaymentProto";
            };

            /**
             * PaymentCollector enum.
             * @name waiternow.common.PaymentProto.PaymentCollector
             * @enum {number}
             * @property {number} PAYMENT_COLLECTOR_UNSPECIFIED=0 PAYMENT_COLLECTOR_UNSPECIFIED value
             * @property {number} PAYPAL_USA=1 PAYPAL_USA value
             * @property {number} STRIPE_USA=2 STRIPE_USA value
             */
            PaymentProto.PaymentCollector = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PAYMENT_COLLECTOR_UNSPECIFIED"] = 0;
                values[valuesById[1] = "PAYPAL_USA"] = 1;
                values[valuesById[2] = "STRIPE_USA"] = 2;
                return values;
            })();

            /**
             * Status enum.
             * @name waiternow.common.PaymentProto.Status
             * @enum {number}
             * @property {number} STATUS_UNSPECIFIED=0 STATUS_UNSPECIFIED value
             * @property {number} SUCCESSFUL=1 SUCCESSFUL value
             * @property {number} REQUIRES_PAYMENT_METHOD=2 REQUIRES_PAYMENT_METHOD value
             * @property {number} REQUIRES_ACTION=3 REQUIRES_ACTION value
             * @property {number} PROCESSING=4 PROCESSING value
             * @property {number} DECLINED=5 DECLINED value
             * @property {number} CANCELED=6 CANCELED value
             * @property {number} REFUNDED=7 REFUNDED value
             * @property {number} DISPUTED=8 DISPUTED value
             */
            PaymentProto.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATUS_UNSPECIFIED"] = 0;
                values[valuesById[1] = "SUCCESSFUL"] = 1;
                values[valuesById[2] = "REQUIRES_PAYMENT_METHOD"] = 2;
                values[valuesById[3] = "REQUIRES_ACTION"] = 3;
                values[valuesById[4] = "PROCESSING"] = 4;
                values[valuesById[5] = "DECLINED"] = 5;
                values[valuesById[6] = "CANCELED"] = 6;
                values[valuesById[7] = "REFUNDED"] = 7;
                values[valuesById[8] = "DISPUTED"] = 8;
                return values;
            })();

            PaymentProto.CancelationStatusProto = (function() {

                /**
                 * Properties of a CancelationStatusProto.
                 * @memberof waiternow.common.PaymentProto
                 * @interface ICancelationStatusProto
                 * @property {string|null} [cancelationId] CancelationStatusProto cancelationId
                 * @property {google.protobuf.ITimestamp|null} [cancelationTime] CancelationStatusProto cancelationTime
                 * @property {waiternow.common.PaymentProto.CancelationStatusProto.State|null} [state] CancelationStatusProto state
                 * @property {string|null} [error] CancelationStatusProto error
                 */

                /**
                 * Constructs a new CancelationStatusProto.
                 * @memberof waiternow.common.PaymentProto
                 * @classdesc Represents a CancelationStatusProto.
                 * @implements ICancelationStatusProto
                 * @constructor
                 * @param {waiternow.common.PaymentProto.ICancelationStatusProto=} [properties] Properties to set
                 */
                function CancelationStatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CancelationStatusProto cancelationId.
                 * @member {string} cancelationId
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @instance
                 */
                CancelationStatusProto.prototype.cancelationId = "";

                /**
                 * CancelationStatusProto cancelationTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} cancelationTime
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @instance
                 */
                CancelationStatusProto.prototype.cancelationTime = null;

                /**
                 * CancelationStatusProto state.
                 * @member {waiternow.common.PaymentProto.CancelationStatusProto.State} state
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @instance
                 */
                CancelationStatusProto.prototype.state = 0;

                /**
                 * CancelationStatusProto error.
                 * @member {string} error
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @instance
                 */
                CancelationStatusProto.prototype.error = "";

                /**
                 * Creates a new CancelationStatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.ICancelationStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.PaymentProto.CancelationStatusProto} CancelationStatusProto instance
                 */
                CancelationStatusProto.create = function create(properties) {
                    return new CancelationStatusProto(properties);
                };

                /**
                 * Encodes the specified CancelationStatusProto message. Does not implicitly {@link waiternow.common.PaymentProto.CancelationStatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.ICancelationStatusProto} message CancelationStatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelationStatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.cancelationId != null && Object.hasOwnProperty.call(message, "cancelationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.cancelationId);
                    if (message.cancelationTime != null && Object.hasOwnProperty.call(message, "cancelationTime"))
                        $root.google.protobuf.Timestamp.encode(message.cancelationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.error);
                    return writer;
                };

                /**
                 * Encodes the specified CancelationStatusProto message, length delimited. Does not implicitly {@link waiternow.common.PaymentProto.CancelationStatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.ICancelationStatusProto} message CancelationStatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CancelationStatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CancelationStatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PaymentProto.CancelationStatusProto} CancelationStatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelationStatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaymentProto.CancelationStatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.cancelationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.cancelationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.state = reader.int32();
                                break;
                            }
                        case 4: {
                                message.error = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CancelationStatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PaymentProto.CancelationStatusProto} CancelationStatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CancelationStatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CancelationStatusProto message.
                 * @function verify
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CancelationStatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.cancelationId != null && message.hasOwnProperty("cancelationId"))
                        if (!$util.isString(message.cancelationId))
                            return "cancelationId: string expected";
                    if (message.cancelationTime != null && message.hasOwnProperty("cancelationTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.cancelationTime);
                        if (error)
                            return "cancelationTime." + error;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    return null;
                };

                /**
                 * Creates a CancelationStatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PaymentProto.CancelationStatusProto} CancelationStatusProto
                 */
                CancelationStatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PaymentProto.CancelationStatusProto)
                        return object;
                    var message = new $root.waiternow.common.PaymentProto.CancelationStatusProto();
                    if (object.cancelationId != null)
                        message.cancelationId = String(object.cancelationId);
                    if (object.cancelationTime != null) {
                        if (typeof object.cancelationTime !== "object")
                            throw TypeError(".waiternow.common.PaymentProto.CancelationStatusProto.cancelationTime: object expected");
                        message.cancelationTime = $root.google.protobuf.Timestamp.fromObject(object.cancelationTime);
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATUS_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "SUCCESSFUL":
                    case 1:
                        message.state = 1;
                        break;
                    case "PENDING":
                    case 2:
                        message.state = 2;
                        break;
                    case "FAILED":
                    case 3:
                        message.state = 3;
                        break;
                    }
                    if (object.error != null)
                        message.error = String(object.error);
                    return message;
                };

                /**
                 * Creates a plain object from a CancelationStatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.CancelationStatusProto} message CancelationStatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CancelationStatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.cancelationId = "";
                        object.cancelationTime = null;
                        object.state = options.enums === String ? "STATUS_UNSPECIFIED" : 0;
                        object.error = "";
                    }
                    if (message.cancelationId != null && message.hasOwnProperty("cancelationId"))
                        object.cancelationId = message.cancelationId;
                    if (message.cancelationTime != null && message.hasOwnProperty("cancelationTime"))
                        object.cancelationTime = $root.google.protobuf.Timestamp.toObject(message.cancelationTime, options);
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.PaymentProto.CancelationStatusProto.State[message.state] === undefined ? message.state : $root.waiternow.common.PaymentProto.CancelationStatusProto.State[message.state] : message.state;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    return object;
                };

                /**
                 * Converts this CancelationStatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CancelationStatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CancelationStatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PaymentProto.CancelationStatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CancelationStatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PaymentProto.CancelationStatusProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.PaymentProto.CancelationStatusProto.State
                 * @enum {number}
                 * @property {number} STATUS_UNSPECIFIED=0 STATUS_UNSPECIFIED value
                 * @property {number} SUCCESSFUL=1 SUCCESSFUL value
                 * @property {number} PENDING=2 PENDING value
                 * @property {number} FAILED=3 FAILED value
                 */
                CancelationStatusProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "SUCCESSFUL"] = 1;
                    values[valuesById[2] = "PENDING"] = 2;
                    values[valuesById[3] = "FAILED"] = 3;
                    return values;
                })();

                return CancelationStatusProto;
            })();

            PaymentProto.RefundStatusProto = (function() {

                /**
                 * Properties of a RefundStatusProto.
                 * @memberof waiternow.common.PaymentProto
                 * @interface IRefundStatusProto
                 * @property {string|null} [refundId] RefundStatusProto refundId
                 * @property {google.protobuf.ITimestamp|null} [refundTime] RefundStatusProto refundTime
                 * @property {waiternow.common.PaymentProto.RefundStatusProto.State|null} [state] RefundStatusProto state
                 * @property {string|null} [error] RefundStatusProto error
                 */

                /**
                 * Constructs a new RefundStatusProto.
                 * @memberof waiternow.common.PaymentProto
                 * @classdesc Represents a RefundStatusProto.
                 * @implements IRefundStatusProto
                 * @constructor
                 * @param {waiternow.common.PaymentProto.IRefundStatusProto=} [properties] Properties to set
                 */
                function RefundStatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RefundStatusProto refundId.
                 * @member {string} refundId
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @instance
                 */
                RefundStatusProto.prototype.refundId = "";

                /**
                 * RefundStatusProto refundTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} refundTime
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @instance
                 */
                RefundStatusProto.prototype.refundTime = null;

                /**
                 * RefundStatusProto state.
                 * @member {waiternow.common.PaymentProto.RefundStatusProto.State} state
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @instance
                 */
                RefundStatusProto.prototype.state = 0;

                /**
                 * RefundStatusProto error.
                 * @member {string} error
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @instance
                 */
                RefundStatusProto.prototype.error = "";

                /**
                 * Creates a new RefundStatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IRefundStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.PaymentProto.RefundStatusProto} RefundStatusProto instance
                 */
                RefundStatusProto.create = function create(properties) {
                    return new RefundStatusProto(properties);
                };

                /**
                 * Encodes the specified RefundStatusProto message. Does not implicitly {@link waiternow.common.PaymentProto.RefundStatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IRefundStatusProto} message RefundStatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RefundStatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.refundId != null && Object.hasOwnProperty.call(message, "refundId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.refundId);
                    if (message.refundTime != null && Object.hasOwnProperty.call(message, "refundTime"))
                        $root.google.protobuf.Timestamp.encode(message.refundTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.error);
                    return writer;
                };

                /**
                 * Encodes the specified RefundStatusProto message, length delimited. Does not implicitly {@link waiternow.common.PaymentProto.RefundStatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IRefundStatusProto} message RefundStatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RefundStatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RefundStatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PaymentProto.RefundStatusProto} RefundStatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RefundStatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaymentProto.RefundStatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.refundId = reader.string();
                                break;
                            }
                        case 2: {
                                message.refundTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.state = reader.int32();
                                break;
                            }
                        case 4: {
                                message.error = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RefundStatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PaymentProto.RefundStatusProto} RefundStatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RefundStatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RefundStatusProto message.
                 * @function verify
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RefundStatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.refundId != null && message.hasOwnProperty("refundId"))
                        if (!$util.isString(message.refundId))
                            return "refundId: string expected";
                    if (message.refundTime != null && message.hasOwnProperty("refundTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.refundTime);
                        if (error)
                            return "refundTime." + error;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    return null;
                };

                /**
                 * Creates a RefundStatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PaymentProto.RefundStatusProto} RefundStatusProto
                 */
                RefundStatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PaymentProto.RefundStatusProto)
                        return object;
                    var message = new $root.waiternow.common.PaymentProto.RefundStatusProto();
                    if (object.refundId != null)
                        message.refundId = String(object.refundId);
                    if (object.refundTime != null) {
                        if (typeof object.refundTime !== "object")
                            throw TypeError(".waiternow.common.PaymentProto.RefundStatusProto.refundTime: object expected");
                        message.refundTime = $root.google.protobuf.Timestamp.fromObject(object.refundTime);
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATUS_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "SUCCESSFUL":
                    case 1:
                        message.state = 1;
                        break;
                    case "PENDING":
                    case 2:
                        message.state = 2;
                        break;
                    case "FAILED":
                    case 3:
                        message.state = 3;
                        break;
                    }
                    if (object.error != null)
                        message.error = String(object.error);
                    return message;
                };

                /**
                 * Creates a plain object from a RefundStatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {waiternow.common.PaymentProto.RefundStatusProto} message RefundStatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RefundStatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.refundId = "";
                        object.refundTime = null;
                        object.state = options.enums === String ? "STATUS_UNSPECIFIED" : 0;
                        object.error = "";
                    }
                    if (message.refundId != null && message.hasOwnProperty("refundId"))
                        object.refundId = message.refundId;
                    if (message.refundTime != null && message.hasOwnProperty("refundTime"))
                        object.refundTime = $root.google.protobuf.Timestamp.toObject(message.refundTime, options);
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.PaymentProto.RefundStatusProto.State[message.state] === undefined ? message.state : $root.waiternow.common.PaymentProto.RefundStatusProto.State[message.state] : message.state;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    return object;
                };

                /**
                 * Converts this RefundStatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RefundStatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RefundStatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PaymentProto.RefundStatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RefundStatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PaymentProto.RefundStatusProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.PaymentProto.RefundStatusProto.State
                 * @enum {number}
                 * @property {number} STATUS_UNSPECIFIED=0 STATUS_UNSPECIFIED value
                 * @property {number} SUCCESSFUL=1 SUCCESSFUL value
                 * @property {number} PENDING=2 PENDING value
                 * @property {number} FAILED=3 FAILED value
                 */
                RefundStatusProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "SUCCESSFUL"] = 1;
                    values[valuesById[2] = "PENDING"] = 2;
                    values[valuesById[3] = "FAILED"] = 3;
                    return values;
                })();

                return RefundStatusProto;
            })();

            PaymentProto.PartialRefundProto = (function() {

                /**
                 * Properties of a PartialRefundProto.
                 * @memberof waiternow.common.PaymentProto
                 * @interface IPartialRefundProto
                 * @property {string|null} [refundId] PartialRefundProto refundId
                 * @property {google.protobuf.ITimestamp|null} [refundTime] PartialRefundProto refundTime
                 * @property {waiternow.common.PaymentProto.PartialRefundProto.State|null} [state] PartialRefundProto state
                 * @property {string|null} [error] PartialRefundProto error
                 * @property {waiternow.common.IMoneyProto|null} [amount] PartialRefundProto amount
                 */

                /**
                 * Constructs a new PartialRefundProto.
                 * @memberof waiternow.common.PaymentProto
                 * @classdesc Represents a PartialRefundProto.
                 * @implements IPartialRefundProto
                 * @constructor
                 * @param {waiternow.common.PaymentProto.IPartialRefundProto=} [properties] Properties to set
                 */
                function PartialRefundProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PartialRefundProto refundId.
                 * @member {string} refundId
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @instance
                 */
                PartialRefundProto.prototype.refundId = "";

                /**
                 * PartialRefundProto refundTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} refundTime
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @instance
                 */
                PartialRefundProto.prototype.refundTime = null;

                /**
                 * PartialRefundProto state.
                 * @member {waiternow.common.PaymentProto.PartialRefundProto.State} state
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @instance
                 */
                PartialRefundProto.prototype.state = 0;

                /**
                 * PartialRefundProto error.
                 * @member {string} error
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @instance
                 */
                PartialRefundProto.prototype.error = "";

                /**
                 * PartialRefundProto amount.
                 * @member {waiternow.common.IMoneyProto|null|undefined} amount
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @instance
                 */
                PartialRefundProto.prototype.amount = null;

                /**
                 * Creates a new PartialRefundProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IPartialRefundProto=} [properties] Properties to set
                 * @returns {waiternow.common.PaymentProto.PartialRefundProto} PartialRefundProto instance
                 */
                PartialRefundProto.create = function create(properties) {
                    return new PartialRefundProto(properties);
                };

                /**
                 * Encodes the specified PartialRefundProto message. Does not implicitly {@link waiternow.common.PaymentProto.PartialRefundProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IPartialRefundProto} message PartialRefundProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PartialRefundProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.refundId != null && Object.hasOwnProperty.call(message, "refundId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.refundId);
                    if (message.refundTime != null && Object.hasOwnProperty.call(message, "refundTime"))
                        $root.google.protobuf.Timestamp.encode(message.refundTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.state);
                    if (message.error != null && Object.hasOwnProperty.call(message, "error"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.error);
                    if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                        $root.waiternow.common.MoneyProto.encode(message.amount, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified PartialRefundProto message, length delimited. Does not implicitly {@link waiternow.common.PaymentProto.PartialRefundProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IPartialRefundProto} message PartialRefundProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PartialRefundProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PartialRefundProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PaymentProto.PartialRefundProto} PartialRefundProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PartialRefundProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaymentProto.PartialRefundProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.refundId = reader.string();
                                break;
                            }
                        case 2: {
                                message.refundTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.state = reader.int32();
                                break;
                            }
                        case 4: {
                                message.error = reader.string();
                                break;
                            }
                        case 5: {
                                message.amount = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PartialRefundProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PaymentProto.PartialRefundProto} PartialRefundProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PartialRefundProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PartialRefundProto message.
                 * @function verify
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PartialRefundProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.refundId != null && message.hasOwnProperty("refundId"))
                        if (!$util.isString(message.refundId))
                            return "refundId: string expected";
                    if (message.refundTime != null && message.hasOwnProperty("refundTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.refundTime);
                        if (error)
                            return "refundTime." + error;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.error != null && message.hasOwnProperty("error"))
                        if (!$util.isString(message.error))
                            return "error: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    return null;
                };

                /**
                 * Creates a PartialRefundProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PaymentProto.PartialRefundProto} PartialRefundProto
                 */
                PartialRefundProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PaymentProto.PartialRefundProto)
                        return object;
                    var message = new $root.waiternow.common.PaymentProto.PartialRefundProto();
                    if (object.refundId != null)
                        message.refundId = String(object.refundId);
                    if (object.refundTime != null) {
                        if (typeof object.refundTime !== "object")
                            throw TypeError(".waiternow.common.PaymentProto.PartialRefundProto.refundTime: object expected");
                        message.refundTime = $root.google.protobuf.Timestamp.fromObject(object.refundTime);
                    }
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATUS_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "SUCCESSFUL":
                    case 1:
                        message.state = 1;
                        break;
                    case "PENDING":
                    case 2:
                        message.state = 2;
                        break;
                    case "FAILED":
                    case 3:
                        message.state = 3;
                        break;
                    }
                    if (object.error != null)
                        message.error = String(object.error);
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".waiternow.common.PaymentProto.PartialRefundProto.amount: object expected");
                        message.amount = $root.waiternow.common.MoneyProto.fromObject(object.amount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a PartialRefundProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {waiternow.common.PaymentProto.PartialRefundProto} message PartialRefundProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PartialRefundProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.refundId = "";
                        object.refundTime = null;
                        object.state = options.enums === String ? "STATUS_UNSPECIFIED" : 0;
                        object.error = "";
                        object.amount = null;
                    }
                    if (message.refundId != null && message.hasOwnProperty("refundId"))
                        object.refundId = message.refundId;
                    if (message.refundTime != null && message.hasOwnProperty("refundTime"))
                        object.refundTime = $root.google.protobuf.Timestamp.toObject(message.refundTime, options);
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.PaymentProto.PartialRefundProto.State[message.state] === undefined ? message.state : $root.waiternow.common.PaymentProto.PartialRefundProto.State[message.state] : message.state;
                    if (message.error != null && message.hasOwnProperty("error"))
                        object.error = message.error;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.waiternow.common.MoneyProto.toObject(message.amount, options);
                    return object;
                };

                /**
                 * Converts this PartialRefundProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PartialRefundProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PartialRefundProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PaymentProto.PartialRefundProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PartialRefundProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PaymentProto.PartialRefundProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.PaymentProto.PartialRefundProto.State
                 * @enum {number}
                 * @property {number} STATUS_UNSPECIFIED=0 STATUS_UNSPECIFIED value
                 * @property {number} SUCCESSFUL=1 SUCCESSFUL value
                 * @property {number} PENDING=2 PENDING value
                 * @property {number} FAILED=3 FAILED value
                 */
                PartialRefundProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATUS_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "SUCCESSFUL"] = 1;
                    values[valuesById[2] = "PENDING"] = 2;
                    values[valuesById[3] = "FAILED"] = 3;
                    return values;
                })();

                return PartialRefundProto;
            })();

            PaymentProto.DisputeProto = (function() {

                /**
                 * Properties of a DisputeProto.
                 * @memberof waiternow.common.PaymentProto
                 * @interface IDisputeProto
                 * @property {waiternow.common.IDateProto|null} [disputeDate] DisputeProto disputeDate
                 * @property {string|null} [reason] DisputeProto reason
                 */

                /**
                 * Constructs a new DisputeProto.
                 * @memberof waiternow.common.PaymentProto
                 * @classdesc Represents a DisputeProto.
                 * @implements IDisputeProto
                 * @constructor
                 * @param {waiternow.common.PaymentProto.IDisputeProto=} [properties] Properties to set
                 */
                function DisputeProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * DisputeProto disputeDate.
                 * @member {waiternow.common.IDateProto|null|undefined} disputeDate
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @instance
                 */
                DisputeProto.prototype.disputeDate = null;

                /**
                 * DisputeProto reason.
                 * @member {string} reason
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @instance
                 */
                DisputeProto.prototype.reason = "";

                /**
                 * Creates a new DisputeProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IDisputeProto=} [properties] Properties to set
                 * @returns {waiternow.common.PaymentProto.DisputeProto} DisputeProto instance
                 */
                DisputeProto.create = function create(properties) {
                    return new DisputeProto(properties);
                };

                /**
                 * Encodes the specified DisputeProto message. Does not implicitly {@link waiternow.common.PaymentProto.DisputeProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IDisputeProto} message DisputeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisputeProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.disputeDate != null && Object.hasOwnProperty.call(message, "disputeDate"))
                        $root.waiternow.common.DateProto.encode(message.disputeDate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.reason);
                    return writer;
                };

                /**
                 * Encodes the specified DisputeProto message, length delimited. Does not implicitly {@link waiternow.common.PaymentProto.DisputeProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {waiternow.common.PaymentProto.IDisputeProto} message DisputeProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                DisputeProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a DisputeProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PaymentProto.DisputeProto} DisputeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisputeProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaymentProto.DisputeProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.disputeDate = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.reason = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a DisputeProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PaymentProto.DisputeProto} DisputeProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                DisputeProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a DisputeProto message.
                 * @function verify
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                DisputeProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.disputeDate != null && message.hasOwnProperty("disputeDate")) {
                        var error = $root.waiternow.common.DateProto.verify(message.disputeDate);
                        if (error)
                            return "disputeDate." + error;
                    }
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        if (!$util.isString(message.reason))
                            return "reason: string expected";
                    return null;
                };

                /**
                 * Creates a DisputeProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PaymentProto.DisputeProto} DisputeProto
                 */
                DisputeProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PaymentProto.DisputeProto)
                        return object;
                    var message = new $root.waiternow.common.PaymentProto.DisputeProto();
                    if (object.disputeDate != null) {
                        if (typeof object.disputeDate !== "object")
                            throw TypeError(".waiternow.common.PaymentProto.DisputeProto.disputeDate: object expected");
                        message.disputeDate = $root.waiternow.common.DateProto.fromObject(object.disputeDate);
                    }
                    if (object.reason != null)
                        message.reason = String(object.reason);
                    return message;
                };

                /**
                 * Creates a plain object from a DisputeProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {waiternow.common.PaymentProto.DisputeProto} message DisputeProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                DisputeProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.disputeDate = null;
                        object.reason = "";
                    }
                    if (message.disputeDate != null && message.hasOwnProperty("disputeDate"))
                        object.disputeDate = $root.waiternow.common.DateProto.toObject(message.disputeDate, options);
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        object.reason = message.reason;
                    return object;
                };

                /**
                 * Converts this DisputeProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                DisputeProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for DisputeProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PaymentProto.DisputeProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                DisputeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PaymentProto.DisputeProto";
                };

                return DisputeProto;
            })();

            return PaymentProto;
        })();

        common.OrderTotalCalculationProto = (function() {

            /**
             * Properties of an OrderTotalCalculationProto.
             * @memberof waiternow.common
             * @interface IOrderTotalCalculationProto
             * @property {waiternow.common.IMoneyProto|null} [subtotal] OrderTotalCalculationProto subtotal
             * @property {Array.<waiternow.common.IDiscountProto>|null} [discounts] OrderTotalCalculationProto discounts
             * @property {waiternow.common.ITaxesProto|null} [appliedTaxes] OrderTotalCalculationProto appliedTaxes
             * @property {waiternow.common.IMoneyProto|null} [tax] OrderTotalCalculationProto tax
             * @property {waiternow.common.IMoneyProto|null} [tip] OrderTotalCalculationProto tip
             * @property {waiternow.common.IMoneyProto|null} [deliveryFee] OrderTotalCalculationProto deliveryFee
             * @property {boolean|null} [isDeliveryFeePaidToBusiness] OrderTotalCalculationProto isDeliveryFeePaidToBusiness
             * @property {boolean|null} [isDeliveryFeeChargedToWaiterNow] OrderTotalCalculationProto isDeliveryFeeChargedToWaiterNow
             * @property {waiternow.common.IMoneyProto|null} [deliveryTip] OrderTotalCalculationProto deliveryTip
             * @property {waiternow.common.IMoneyProto|null} [creditCardFeeAdjustment] OrderTotalCalculationProto creditCardFeeAdjustment
             * @property {boolean|null} [isCreditCardFeeChargedToConsumer] OrderTotalCalculationProto isCreditCardFeeChargedToConsumer
             * @property {waiternow.common.IMoneyProto|null} [waiterNowFee] OrderTotalCalculationProto waiterNowFee
             * @property {waiternow.common.IMoneyProto|null} [serviceFee] OrderTotalCalculationProto serviceFee
             * @property {waiternow.common.IMoneyProto|null} [total] OrderTotalCalculationProto total
             * @property {waiternow.common.IMoneyProto|null} [stripeFee] OrderTotalCalculationProto stripeFee
             * @property {waiternow.common.IMoneyProto|null} [applicationFee] OrderTotalCalculationProto applicationFee
             * @property {waiternow.common.IMoneyProto|null} [netPaidToBusiness] OrderTotalCalculationProto netPaidToBusiness
             */

            /**
             * Constructs a new OrderTotalCalculationProto.
             * @memberof waiternow.common
             * @classdesc Represents an OrderTotalCalculationProto.
             * @implements IOrderTotalCalculationProto
             * @constructor
             * @param {waiternow.common.IOrderTotalCalculationProto=} [properties] Properties to set
             */
            function OrderTotalCalculationProto(properties) {
                this.discounts = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderTotalCalculationProto subtotal.
             * @member {waiternow.common.IMoneyProto|null|undefined} subtotal
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.subtotal = null;

            /**
             * OrderTotalCalculationProto discounts.
             * @member {Array.<waiternow.common.IDiscountProto>} discounts
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.discounts = $util.emptyArray;

            /**
             * OrderTotalCalculationProto appliedTaxes.
             * @member {waiternow.common.ITaxesProto|null|undefined} appliedTaxes
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.appliedTaxes = null;

            /**
             * OrderTotalCalculationProto tax.
             * @member {waiternow.common.IMoneyProto|null|undefined} tax
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.tax = null;

            /**
             * OrderTotalCalculationProto tip.
             * @member {waiternow.common.IMoneyProto|null|undefined} tip
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.tip = null;

            /**
             * OrderTotalCalculationProto deliveryFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryFee
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.deliveryFee = null;

            /**
             * OrderTotalCalculationProto isDeliveryFeePaidToBusiness.
             * @member {boolean} isDeliveryFeePaidToBusiness
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.isDeliveryFeePaidToBusiness = false;

            /**
             * OrderTotalCalculationProto isDeliveryFeeChargedToWaiterNow.
             * @member {boolean} isDeliveryFeeChargedToWaiterNow
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.isDeliveryFeeChargedToWaiterNow = false;

            /**
             * OrderTotalCalculationProto deliveryTip.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryTip
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.deliveryTip = null;

            /**
             * OrderTotalCalculationProto creditCardFeeAdjustment.
             * @member {waiternow.common.IMoneyProto|null|undefined} creditCardFeeAdjustment
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.creditCardFeeAdjustment = null;

            /**
             * OrderTotalCalculationProto isCreditCardFeeChargedToConsumer.
             * @member {boolean} isCreditCardFeeChargedToConsumer
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.isCreditCardFeeChargedToConsumer = false;

            /**
             * OrderTotalCalculationProto waiterNowFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} waiterNowFee
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.waiterNowFee = null;

            /**
             * OrderTotalCalculationProto serviceFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} serviceFee
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.serviceFee = null;

            /**
             * OrderTotalCalculationProto total.
             * @member {waiternow.common.IMoneyProto|null|undefined} total
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.total = null;

            /**
             * OrderTotalCalculationProto stripeFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} stripeFee
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.stripeFee = null;

            /**
             * OrderTotalCalculationProto applicationFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} applicationFee
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.applicationFee = null;

            /**
             * OrderTotalCalculationProto netPaidToBusiness.
             * @member {waiternow.common.IMoneyProto|null|undefined} netPaidToBusiness
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             */
            OrderTotalCalculationProto.prototype.netPaidToBusiness = null;

            /**
             * Creates a new OrderTotalCalculationProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {waiternow.common.IOrderTotalCalculationProto=} [properties] Properties to set
             * @returns {waiternow.common.OrderTotalCalculationProto} OrderTotalCalculationProto instance
             */
            OrderTotalCalculationProto.create = function create(properties) {
                return new OrderTotalCalculationProto(properties);
            };

            /**
             * Encodes the specified OrderTotalCalculationProto message. Does not implicitly {@link waiternow.common.OrderTotalCalculationProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {waiternow.common.IOrderTotalCalculationProto} message OrderTotalCalculationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderTotalCalculationProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.subtotal != null && Object.hasOwnProperty.call(message, "subtotal"))
                    $root.waiternow.common.MoneyProto.encode(message.subtotal, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.discounts != null && message.discounts.length)
                    for (var i = 0; i < message.discounts.length; ++i)
                        $root.waiternow.common.DiscountProto.encode(message.discounts[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.appliedTaxes != null && Object.hasOwnProperty.call(message, "appliedTaxes"))
                    $root.waiternow.common.TaxesProto.encode(message.appliedTaxes, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.tax != null && Object.hasOwnProperty.call(message, "tax"))
                    $root.waiternow.common.MoneyProto.encode(message.tax, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
                    $root.waiternow.common.MoneyProto.encode(message.tip, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.deliveryFee != null && Object.hasOwnProperty.call(message, "deliveryFee"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryFee, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.isDeliveryFeePaidToBusiness != null && Object.hasOwnProperty.call(message, "isDeliveryFeePaidToBusiness"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isDeliveryFeePaidToBusiness);
                if (message.isDeliveryFeeChargedToWaiterNow != null && Object.hasOwnProperty.call(message, "isDeliveryFeeChargedToWaiterNow"))
                    writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isDeliveryFeeChargedToWaiterNow);
                if (message.deliveryTip != null && Object.hasOwnProperty.call(message, "deliveryTip"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryTip, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.creditCardFeeAdjustment != null && Object.hasOwnProperty.call(message, "creditCardFeeAdjustment"))
                    $root.waiternow.common.MoneyProto.encode(message.creditCardFeeAdjustment, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.isCreditCardFeeChargedToConsumer != null && Object.hasOwnProperty.call(message, "isCreditCardFeeChargedToConsumer"))
                    writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isCreditCardFeeChargedToConsumer);
                if (message.waiterNowFee != null && Object.hasOwnProperty.call(message, "waiterNowFee"))
                    $root.waiternow.common.MoneyProto.encode(message.waiterNowFee, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.serviceFee != null && Object.hasOwnProperty.call(message, "serviceFee"))
                    $root.waiternow.common.MoneyProto.encode(message.serviceFee, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    $root.waiternow.common.MoneyProto.encode(message.total, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.stripeFee != null && Object.hasOwnProperty.call(message, "stripeFee"))
                    $root.waiternow.common.MoneyProto.encode(message.stripeFee, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.applicationFee != null && Object.hasOwnProperty.call(message, "applicationFee"))
                    $root.waiternow.common.MoneyProto.encode(message.applicationFee, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.netPaidToBusiness != null && Object.hasOwnProperty.call(message, "netPaidToBusiness"))
                    $root.waiternow.common.MoneyProto.encode(message.netPaidToBusiness, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OrderTotalCalculationProto message, length delimited. Does not implicitly {@link waiternow.common.OrderTotalCalculationProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {waiternow.common.IOrderTotalCalculationProto} message OrderTotalCalculationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderTotalCalculationProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrderTotalCalculationProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OrderTotalCalculationProto} OrderTotalCalculationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderTotalCalculationProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderTotalCalculationProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.subtotal = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.discounts && message.discounts.length))
                                message.discounts = [];
                            message.discounts.push($root.waiternow.common.DiscountProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.appliedTaxes = $root.waiternow.common.TaxesProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.tax = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.tip = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.deliveryFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.isDeliveryFeePaidToBusiness = reader.bool();
                            break;
                        }
                    case 8: {
                            message.isDeliveryFeeChargedToWaiterNow = reader.bool();
                            break;
                        }
                    case 9: {
                            message.deliveryTip = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.isCreditCardFeeChargedToConsumer = reader.bool();
                            break;
                        }
                    case 12: {
                            message.waiterNowFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.serviceFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.total = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.stripeFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.applicationFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.netPaidToBusiness = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrderTotalCalculationProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OrderTotalCalculationProto} OrderTotalCalculationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderTotalCalculationProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrderTotalCalculationProto message.
             * @function verify
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderTotalCalculationProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.subtotal != null && message.hasOwnProperty("subtotal")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.subtotal);
                    if (error)
                        return "subtotal." + error;
                }
                if (message.discounts != null && message.hasOwnProperty("discounts")) {
                    if (!Array.isArray(message.discounts))
                        return "discounts: array expected";
                    for (var i = 0; i < message.discounts.length; ++i) {
                        var error = $root.waiternow.common.DiscountProto.verify(message.discounts[i]);
                        if (error)
                            return "discounts." + error;
                    }
                }
                if (message.appliedTaxes != null && message.hasOwnProperty("appliedTaxes")) {
                    var error = $root.waiternow.common.TaxesProto.verify(message.appliedTaxes);
                    if (error)
                        return "appliedTaxes." + error;
                }
                if (message.tax != null && message.hasOwnProperty("tax")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.tax);
                    if (error)
                        return "tax." + error;
                }
                if (message.tip != null && message.hasOwnProperty("tip")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.tip);
                    if (error)
                        return "tip." + error;
                }
                if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryFee);
                    if (error)
                        return "deliveryFee." + error;
                }
                if (message.isDeliveryFeePaidToBusiness != null && message.hasOwnProperty("isDeliveryFeePaidToBusiness"))
                    if (typeof message.isDeliveryFeePaidToBusiness !== "boolean")
                        return "isDeliveryFeePaidToBusiness: boolean expected";
                if (message.isDeliveryFeeChargedToWaiterNow != null && message.hasOwnProperty("isDeliveryFeeChargedToWaiterNow"))
                    if (typeof message.isDeliveryFeeChargedToWaiterNow !== "boolean")
                        return "isDeliveryFeeChargedToWaiterNow: boolean expected";
                if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryTip);
                    if (error)
                        return "deliveryTip." + error;
                }
                if (message.creditCardFeeAdjustment != null && message.hasOwnProperty("creditCardFeeAdjustment")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.creditCardFeeAdjustment);
                    if (error)
                        return "creditCardFeeAdjustment." + error;
                }
                if (message.isCreditCardFeeChargedToConsumer != null && message.hasOwnProperty("isCreditCardFeeChargedToConsumer"))
                    if (typeof message.isCreditCardFeeChargedToConsumer !== "boolean")
                        return "isCreditCardFeeChargedToConsumer: boolean expected";
                if (message.waiterNowFee != null && message.hasOwnProperty("waiterNowFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.waiterNowFee);
                    if (error)
                        return "waiterNowFee." + error;
                }
                if (message.serviceFee != null && message.hasOwnProperty("serviceFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.serviceFee);
                    if (error)
                        return "serviceFee." + error;
                }
                if (message.total != null && message.hasOwnProperty("total")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.total);
                    if (error)
                        return "total." + error;
                }
                if (message.stripeFee != null && message.hasOwnProperty("stripeFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.stripeFee);
                    if (error)
                        return "stripeFee." + error;
                }
                if (message.applicationFee != null && message.hasOwnProperty("applicationFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.applicationFee);
                    if (error)
                        return "applicationFee." + error;
                }
                if (message.netPaidToBusiness != null && message.hasOwnProperty("netPaidToBusiness")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.netPaidToBusiness);
                    if (error)
                        return "netPaidToBusiness." + error;
                }
                return null;
            };

            /**
             * Creates an OrderTotalCalculationProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OrderTotalCalculationProto} OrderTotalCalculationProto
             */
            OrderTotalCalculationProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OrderTotalCalculationProto)
                    return object;
                var message = new $root.waiternow.common.OrderTotalCalculationProto();
                if (object.subtotal != null) {
                    if (typeof object.subtotal !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.subtotal: object expected");
                    message.subtotal = $root.waiternow.common.MoneyProto.fromObject(object.subtotal);
                }
                if (object.discounts) {
                    if (!Array.isArray(object.discounts))
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.discounts: array expected");
                    message.discounts = [];
                    for (var i = 0; i < object.discounts.length; ++i) {
                        if (typeof object.discounts[i] !== "object")
                            throw TypeError(".waiternow.common.OrderTotalCalculationProto.discounts: object expected");
                        message.discounts[i] = $root.waiternow.common.DiscountProto.fromObject(object.discounts[i]);
                    }
                }
                if (object.appliedTaxes != null) {
                    if (typeof object.appliedTaxes !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.appliedTaxes: object expected");
                    message.appliedTaxes = $root.waiternow.common.TaxesProto.fromObject(object.appliedTaxes);
                }
                if (object.tax != null) {
                    if (typeof object.tax !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.tax: object expected");
                    message.tax = $root.waiternow.common.MoneyProto.fromObject(object.tax);
                }
                if (object.tip != null) {
                    if (typeof object.tip !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.tip: object expected");
                    message.tip = $root.waiternow.common.MoneyProto.fromObject(object.tip);
                }
                if (object.deliveryFee != null) {
                    if (typeof object.deliveryFee !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.deliveryFee: object expected");
                    message.deliveryFee = $root.waiternow.common.MoneyProto.fromObject(object.deliveryFee);
                }
                if (object.isDeliveryFeePaidToBusiness != null)
                    message.isDeliveryFeePaidToBusiness = Boolean(object.isDeliveryFeePaidToBusiness);
                if (object.isDeliveryFeeChargedToWaiterNow != null)
                    message.isDeliveryFeeChargedToWaiterNow = Boolean(object.isDeliveryFeeChargedToWaiterNow);
                if (object.deliveryTip != null) {
                    if (typeof object.deliveryTip !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.deliveryTip: object expected");
                    message.deliveryTip = $root.waiternow.common.MoneyProto.fromObject(object.deliveryTip);
                }
                if (object.creditCardFeeAdjustment != null) {
                    if (typeof object.creditCardFeeAdjustment !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.creditCardFeeAdjustment: object expected");
                    message.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.fromObject(object.creditCardFeeAdjustment);
                }
                if (object.isCreditCardFeeChargedToConsumer != null)
                    message.isCreditCardFeeChargedToConsumer = Boolean(object.isCreditCardFeeChargedToConsumer);
                if (object.waiterNowFee != null) {
                    if (typeof object.waiterNowFee !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.waiterNowFee: object expected");
                    message.waiterNowFee = $root.waiternow.common.MoneyProto.fromObject(object.waiterNowFee);
                }
                if (object.serviceFee != null) {
                    if (typeof object.serviceFee !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.serviceFee: object expected");
                    message.serviceFee = $root.waiternow.common.MoneyProto.fromObject(object.serviceFee);
                }
                if (object.total != null) {
                    if (typeof object.total !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.total: object expected");
                    message.total = $root.waiternow.common.MoneyProto.fromObject(object.total);
                }
                if (object.stripeFee != null) {
                    if (typeof object.stripeFee !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.stripeFee: object expected");
                    message.stripeFee = $root.waiternow.common.MoneyProto.fromObject(object.stripeFee);
                }
                if (object.applicationFee != null) {
                    if (typeof object.applicationFee !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.applicationFee: object expected");
                    message.applicationFee = $root.waiternow.common.MoneyProto.fromObject(object.applicationFee);
                }
                if (object.netPaidToBusiness != null) {
                    if (typeof object.netPaidToBusiness !== "object")
                        throw TypeError(".waiternow.common.OrderTotalCalculationProto.netPaidToBusiness: object expected");
                    message.netPaidToBusiness = $root.waiternow.common.MoneyProto.fromObject(object.netPaidToBusiness);
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderTotalCalculationProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {waiternow.common.OrderTotalCalculationProto} message OrderTotalCalculationProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderTotalCalculationProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.discounts = [];
                if (options.defaults) {
                    object.subtotal = null;
                    object.appliedTaxes = null;
                    object.tax = null;
                    object.tip = null;
                    object.deliveryFee = null;
                    object.isDeliveryFeePaidToBusiness = false;
                    object.isDeliveryFeeChargedToWaiterNow = false;
                    object.deliveryTip = null;
                    object.creditCardFeeAdjustment = null;
                    object.isCreditCardFeeChargedToConsumer = false;
                    object.waiterNowFee = null;
                    object.serviceFee = null;
                    object.total = null;
                    object.stripeFee = null;
                    object.applicationFee = null;
                    object.netPaidToBusiness = null;
                }
                if (message.subtotal != null && message.hasOwnProperty("subtotal"))
                    object.subtotal = $root.waiternow.common.MoneyProto.toObject(message.subtotal, options);
                if (message.discounts && message.discounts.length) {
                    object.discounts = [];
                    for (var j = 0; j < message.discounts.length; ++j)
                        object.discounts[j] = $root.waiternow.common.DiscountProto.toObject(message.discounts[j], options);
                }
                if (message.appliedTaxes != null && message.hasOwnProperty("appliedTaxes"))
                    object.appliedTaxes = $root.waiternow.common.TaxesProto.toObject(message.appliedTaxes, options);
                if (message.tax != null && message.hasOwnProperty("tax"))
                    object.tax = $root.waiternow.common.MoneyProto.toObject(message.tax, options);
                if (message.tip != null && message.hasOwnProperty("tip"))
                    object.tip = $root.waiternow.common.MoneyProto.toObject(message.tip, options);
                if (message.deliveryFee != null && message.hasOwnProperty("deliveryFee"))
                    object.deliveryFee = $root.waiternow.common.MoneyProto.toObject(message.deliveryFee, options);
                if (message.isDeliveryFeePaidToBusiness != null && message.hasOwnProperty("isDeliveryFeePaidToBusiness"))
                    object.isDeliveryFeePaidToBusiness = message.isDeliveryFeePaidToBusiness;
                if (message.isDeliveryFeeChargedToWaiterNow != null && message.hasOwnProperty("isDeliveryFeeChargedToWaiterNow"))
                    object.isDeliveryFeeChargedToWaiterNow = message.isDeliveryFeeChargedToWaiterNow;
                if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip"))
                    object.deliveryTip = $root.waiternow.common.MoneyProto.toObject(message.deliveryTip, options);
                if (message.creditCardFeeAdjustment != null && message.hasOwnProperty("creditCardFeeAdjustment"))
                    object.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.toObject(message.creditCardFeeAdjustment, options);
                if (message.isCreditCardFeeChargedToConsumer != null && message.hasOwnProperty("isCreditCardFeeChargedToConsumer"))
                    object.isCreditCardFeeChargedToConsumer = message.isCreditCardFeeChargedToConsumer;
                if (message.waiterNowFee != null && message.hasOwnProperty("waiterNowFee"))
                    object.waiterNowFee = $root.waiternow.common.MoneyProto.toObject(message.waiterNowFee, options);
                if (message.serviceFee != null && message.hasOwnProperty("serviceFee"))
                    object.serviceFee = $root.waiternow.common.MoneyProto.toObject(message.serviceFee, options);
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = $root.waiternow.common.MoneyProto.toObject(message.total, options);
                if (message.stripeFee != null && message.hasOwnProperty("stripeFee"))
                    object.stripeFee = $root.waiternow.common.MoneyProto.toObject(message.stripeFee, options);
                if (message.applicationFee != null && message.hasOwnProperty("applicationFee"))
                    object.applicationFee = $root.waiternow.common.MoneyProto.toObject(message.applicationFee, options);
                if (message.netPaidToBusiness != null && message.hasOwnProperty("netPaidToBusiness"))
                    object.netPaidToBusiness = $root.waiternow.common.MoneyProto.toObject(message.netPaidToBusiness, options);
                return object;
            };

            /**
             * Converts this OrderTotalCalculationProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderTotalCalculationProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrderTotalCalculationProto
             * @function getTypeUrl
             * @memberof waiternow.common.OrderTotalCalculationProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrderTotalCalculationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OrderTotalCalculationProto";
            };

            return OrderTotalCalculationProto;
        })();

        common.MarketplacePaymentsLocationEnrollmentProto = (function() {

            /**
             * Properties of a MarketplacePaymentsLocationEnrollmentProto.
             * @memberof waiternow.common
             * @interface IMarketplacePaymentsLocationEnrollmentProto
             * @property {string|null} [redirectUrl] MarketplacePaymentsLocationEnrollmentProto redirectUrl
             */

            /**
             * Constructs a new MarketplacePaymentsLocationEnrollmentProto.
             * @memberof waiternow.common
             * @classdesc Represents a MarketplacePaymentsLocationEnrollmentProto.
             * @implements IMarketplacePaymentsLocationEnrollmentProto
             * @constructor
             * @param {waiternow.common.IMarketplacePaymentsLocationEnrollmentProto=} [properties] Properties to set
             */
            function MarketplacePaymentsLocationEnrollmentProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MarketplacePaymentsLocationEnrollmentProto redirectUrl.
             * @member {string} redirectUrl
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @instance
             */
            MarketplacePaymentsLocationEnrollmentProto.prototype.redirectUrl = "";

            /**
             * Creates a new MarketplacePaymentsLocationEnrollmentProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {waiternow.common.IMarketplacePaymentsLocationEnrollmentProto=} [properties] Properties to set
             * @returns {waiternow.common.MarketplacePaymentsLocationEnrollmentProto} MarketplacePaymentsLocationEnrollmentProto instance
             */
            MarketplacePaymentsLocationEnrollmentProto.create = function create(properties) {
                return new MarketplacePaymentsLocationEnrollmentProto(properties);
            };

            /**
             * Encodes the specified MarketplacePaymentsLocationEnrollmentProto message. Does not implicitly {@link waiternow.common.MarketplacePaymentsLocationEnrollmentProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {waiternow.common.IMarketplacePaymentsLocationEnrollmentProto} message MarketplacePaymentsLocationEnrollmentProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarketplacePaymentsLocationEnrollmentProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.redirectUrl != null && Object.hasOwnProperty.call(message, "redirectUrl"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.redirectUrl);
                return writer;
            };

            /**
             * Encodes the specified MarketplacePaymentsLocationEnrollmentProto message, length delimited. Does not implicitly {@link waiternow.common.MarketplacePaymentsLocationEnrollmentProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {waiternow.common.IMarketplacePaymentsLocationEnrollmentProto} message MarketplacePaymentsLocationEnrollmentProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarketplacePaymentsLocationEnrollmentProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MarketplacePaymentsLocationEnrollmentProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MarketplacePaymentsLocationEnrollmentProto} MarketplacePaymentsLocationEnrollmentProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarketplacePaymentsLocationEnrollmentProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.redirectUrl = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MarketplacePaymentsLocationEnrollmentProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MarketplacePaymentsLocationEnrollmentProto} MarketplacePaymentsLocationEnrollmentProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarketplacePaymentsLocationEnrollmentProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MarketplacePaymentsLocationEnrollmentProto message.
             * @function verify
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarketplacePaymentsLocationEnrollmentProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                    if (!$util.isString(message.redirectUrl))
                        return "redirectUrl: string expected";
                return null;
            };

            /**
             * Creates a MarketplacePaymentsLocationEnrollmentProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MarketplacePaymentsLocationEnrollmentProto} MarketplacePaymentsLocationEnrollmentProto
             */
            MarketplacePaymentsLocationEnrollmentProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto)
                    return object;
                var message = new $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto();
                if (object.redirectUrl != null)
                    message.redirectUrl = String(object.redirectUrl);
                return message;
            };

            /**
             * Creates a plain object from a MarketplacePaymentsLocationEnrollmentProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {waiternow.common.MarketplacePaymentsLocationEnrollmentProto} message MarketplacePaymentsLocationEnrollmentProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarketplacePaymentsLocationEnrollmentProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.redirectUrl = "";
                if (message.redirectUrl != null && message.hasOwnProperty("redirectUrl"))
                    object.redirectUrl = message.redirectUrl;
                return object;
            };

            /**
             * Converts this MarketplacePaymentsLocationEnrollmentProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarketplacePaymentsLocationEnrollmentProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MarketplacePaymentsLocationEnrollmentProto
             * @function getTypeUrl
             * @memberof waiternow.common.MarketplacePaymentsLocationEnrollmentProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarketplacePaymentsLocationEnrollmentProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MarketplacePaymentsLocationEnrollmentProto";
            };

            return MarketplacePaymentsLocationEnrollmentProto;
        })();

        common.DiscountProto = (function() {

            /**
             * Properties of a DiscountProto.
             * @memberof waiternow.common
             * @interface IDiscountProto
             * @property {waiternow.common.IMoneyProto|null} [amount] DiscountProto amount
             * @property {string|null} [description] DiscountProto description
             */

            /**
             * Constructs a new DiscountProto.
             * @memberof waiternow.common
             * @classdesc Represents a DiscountProto.
             * @implements IDiscountProto
             * @constructor
             * @param {waiternow.common.IDiscountProto=} [properties] Properties to set
             */
            function DiscountProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DiscountProto amount.
             * @member {waiternow.common.IMoneyProto|null|undefined} amount
             * @memberof waiternow.common.DiscountProto
             * @instance
             */
            DiscountProto.prototype.amount = null;

            /**
             * DiscountProto description.
             * @member {string} description
             * @memberof waiternow.common.DiscountProto
             * @instance
             */
            DiscountProto.prototype.description = "";

            /**
             * Creates a new DiscountProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {waiternow.common.IDiscountProto=} [properties] Properties to set
             * @returns {waiternow.common.DiscountProto} DiscountProto instance
             */
            DiscountProto.create = function create(properties) {
                return new DiscountProto(properties);
            };

            /**
             * Encodes the specified DiscountProto message. Does not implicitly {@link waiternow.common.DiscountProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {waiternow.common.IDiscountProto} message DiscountProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DiscountProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                    $root.waiternow.common.MoneyProto.encode(message.amount, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.description);
                return writer;
            };

            /**
             * Encodes the specified DiscountProto message, length delimited. Does not implicitly {@link waiternow.common.DiscountProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {waiternow.common.IDiscountProto} message DiscountProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DiscountProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DiscountProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DiscountProto} DiscountProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DiscountProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DiscountProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.amount = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.description = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DiscountProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DiscountProto} DiscountProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DiscountProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DiscountProto message.
             * @function verify
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DiscountProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.amount != null && message.hasOwnProperty("amount")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.amount);
                    if (error)
                        return "amount." + error;
                }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a DiscountProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DiscountProto} DiscountProto
             */
            DiscountProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DiscountProto)
                    return object;
                var message = new $root.waiternow.common.DiscountProto();
                if (object.amount != null) {
                    if (typeof object.amount !== "object")
                        throw TypeError(".waiternow.common.DiscountProto.amount: object expected");
                    message.amount = $root.waiternow.common.MoneyProto.fromObject(object.amount);
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a DiscountProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {waiternow.common.DiscountProto} message DiscountProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DiscountProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.amount = null;
                    object.description = "";
                }
                if (message.amount != null && message.hasOwnProperty("amount"))
                    object.amount = $root.waiternow.common.MoneyProto.toObject(message.amount, options);
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this DiscountProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DiscountProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DiscountProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DiscountProto
             * @function getTypeUrl
             * @memberof waiternow.common.DiscountProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DiscountProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DiscountProto";
            };

            return DiscountProto;
        })();

        common.BusinessProto = (function() {

            /**
             * Properties of a BusinessProto.
             * @memberof waiternow.common
             * @interface IBusinessProto
             * @property {string|null} [id] BusinessProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] BusinessProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] BusinessProto lastUpdateTime
             * @property {string|null} [businessName] BusinessProto businessName
             * @property {boolean|null} [isSuspended] BusinessProto isSuspended
             * @property {waiternow.common.BusinessProto.SuspensionCause|null} [suspensionCause] BusinessProto suspensionCause
             * @property {waiternow.common.LocationProto.ISettingsProto|null} [locationSettings] BusinessProto locationSettings
             * @property {waiternow.common.BusinessProto.IMetadataProto|null} [metadata] BusinessProto metadata
             * @property {boolean|null} [isBillable] BusinessProto isBillable
             * @property {waiternow.common.IImageProto|null} [logo] BusinessProto logo
             * @property {waiternow.common.BusinessProto.ISettingsProto|null} [settings] BusinessProto settings
             * @property {waiternow.common.IImageProto|null} [headerForLandscapeScreen] BusinessProto headerForLandscapeScreen
             * @property {waiternow.common.IImageProto|null} [headerForPortraitScreen] BusinessProto headerForPortraitScreen
             * @property {string|null} [description] BusinessProto description
             */

            /**
             * Constructs a new BusinessProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessProto.
             * @implements IBusinessProto
             * @constructor
             * @param {waiternow.common.IBusinessProto=} [properties] Properties to set
             */
            function BusinessProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessProto id.
             * @member {string} id
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.id = "";

            /**
             * BusinessProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.creationTime = null;

            /**
             * BusinessProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.lastUpdateTime = null;

            /**
             * BusinessProto businessName.
             * @member {string} businessName
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.businessName = "";

            /**
             * BusinessProto isSuspended.
             * @member {boolean} isSuspended
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.isSuspended = false;

            /**
             * BusinessProto suspensionCause.
             * @member {waiternow.common.BusinessProto.SuspensionCause} suspensionCause
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.suspensionCause = 0;

            /**
             * BusinessProto locationSettings.
             * @member {waiternow.common.LocationProto.ISettingsProto|null|undefined} locationSettings
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.locationSettings = null;

            /**
             * BusinessProto metadata.
             * @member {waiternow.common.BusinessProto.IMetadataProto|null|undefined} metadata
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.metadata = null;

            /**
             * BusinessProto isBillable.
             * @member {boolean} isBillable
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.isBillable = false;

            /**
             * BusinessProto logo.
             * @member {waiternow.common.IImageProto|null|undefined} logo
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.logo = null;

            /**
             * BusinessProto settings.
             * @member {waiternow.common.BusinessProto.ISettingsProto|null|undefined} settings
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.settings = null;

            /**
             * BusinessProto headerForLandscapeScreen.
             * @member {waiternow.common.IImageProto|null|undefined} headerForLandscapeScreen
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.headerForLandscapeScreen = null;

            /**
             * BusinessProto headerForPortraitScreen.
             * @member {waiternow.common.IImageProto|null|undefined} headerForPortraitScreen
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.headerForPortraitScreen = null;

            /**
             * BusinessProto description.
             * @member {string} description
             * @memberof waiternow.common.BusinessProto
             * @instance
             */
            BusinessProto.prototype.description = "";

            /**
             * Creates a new BusinessProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {waiternow.common.IBusinessProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessProto} BusinessProto instance
             */
            BusinessProto.create = function create(properties) {
                return new BusinessProto(properties);
            };

            /**
             * Encodes the specified BusinessProto message. Does not implicitly {@link waiternow.common.BusinessProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {waiternow.common.IBusinessProto} message BusinessProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessName);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.isSuspended != null && Object.hasOwnProperty.call(message, "isSuspended"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isSuspended);
                if (message.suspensionCause != null && Object.hasOwnProperty.call(message, "suspensionCause"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int32(message.suspensionCause);
                if (message.locationSettings != null && Object.hasOwnProperty.call(message, "locationSettings"))
                    $root.waiternow.common.LocationProto.SettingsProto.encode(message.locationSettings, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                    $root.waiternow.common.BusinessProto.MetadataProto.encode(message.metadata, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.isBillable != null && Object.hasOwnProperty.call(message, "isBillable"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isBillable);
                if (message.logo != null && Object.hasOwnProperty.call(message, "logo"))
                    $root.waiternow.common.ImageProto.encode(message.logo, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.waiternow.common.BusinessProto.SettingsProto.encode(message.settings, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.headerForLandscapeScreen != null && Object.hasOwnProperty.call(message, "headerForLandscapeScreen"))
                    $root.waiternow.common.ImageProto.encode(message.headerForLandscapeScreen, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.headerForPortraitScreen != null && Object.hasOwnProperty.call(message, "headerForPortraitScreen"))
                    $root.waiternow.common.ImageProto.encode(message.headerForPortraitScreen, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 15, wireType 2 =*/122).string(message.description);
                return writer;
            };

            /**
             * Encodes the specified BusinessProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {waiternow.common.IBusinessProto} message BusinessProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessProto} BusinessProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 4: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.businessName = reader.string();
                            break;
                        }
                    case 6: {
                            message.isSuspended = reader.bool();
                            break;
                        }
                    case 7: {
                            message.suspensionCause = reader.int32();
                            break;
                        }
                    case 8: {
                            message.locationSettings = $root.waiternow.common.LocationProto.SettingsProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.metadata = $root.waiternow.common.BusinessProto.MetadataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.isBillable = reader.bool();
                            break;
                        }
                    case 11: {
                            message.logo = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.settings = $root.waiternow.common.BusinessProto.SettingsProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.headerForLandscapeScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.headerForPortraitScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.description = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessProto} BusinessProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessProto message.
             * @function verify
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    if (!$util.isString(message.businessName))
                        return "businessName: string expected";
                if (message.isSuspended != null && message.hasOwnProperty("isSuspended"))
                    if (typeof message.isSuspended !== "boolean")
                        return "isSuspended: boolean expected";
                if (message.suspensionCause != null && message.hasOwnProperty("suspensionCause"))
                    switch (message.suspensionCause) {
                    default:
                        return "suspensionCause: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.locationSettings != null && message.hasOwnProperty("locationSettings")) {
                    var error = $root.waiternow.common.LocationProto.SettingsProto.verify(message.locationSettings);
                    if (error)
                        return "locationSettings." + error;
                }
                if (message.metadata != null && message.hasOwnProperty("metadata")) {
                    var error = $root.waiternow.common.BusinessProto.MetadataProto.verify(message.metadata);
                    if (error)
                        return "metadata." + error;
                }
                if (message.isBillable != null && message.hasOwnProperty("isBillable"))
                    if (typeof message.isBillable !== "boolean")
                        return "isBillable: boolean expected";
                if (message.logo != null && message.hasOwnProperty("logo")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.logo);
                    if (error)
                        return "logo." + error;
                }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    var error = $root.waiternow.common.BusinessProto.SettingsProto.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
                if (message.headerForLandscapeScreen != null && message.hasOwnProperty("headerForLandscapeScreen")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.headerForLandscapeScreen);
                    if (error)
                        return "headerForLandscapeScreen." + error;
                }
                if (message.headerForPortraitScreen != null && message.hasOwnProperty("headerForPortraitScreen")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.headerForPortraitScreen);
                    if (error)
                        return "headerForPortraitScreen." + error;
                }
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                return null;
            };

            /**
             * Creates a BusinessProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessProto} BusinessProto
             */
            BusinessProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessProto)
                    return object;
                var message = new $root.waiternow.common.BusinessProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.businessName != null)
                    message.businessName = String(object.businessName);
                if (object.isSuspended != null)
                    message.isSuspended = Boolean(object.isSuspended);
                switch (object.suspensionCause) {
                default:
                    if (typeof object.suspensionCause === "number") {
                        message.suspensionCause = object.suspensionCause;
                        break;
                    }
                    break;
                case "SUSPENSION_CAUSE_UNSPECIFIED":
                case 0:
                    message.suspensionCause = 0;
                    break;
                case "UNPAID_BILL":
                case 1:
                    message.suspensionCause = 1;
                    break;
                case "QUOTA_LIMIT_REACHED":
                case 2:
                    message.suspensionCause = 2;
                    break;
                case "SUSPENDED_BY_OWNER":
                case 3:
                    message.suspensionCause = 3;
                    break;
                case "BLOCKED_OWNER":
                case 4:
                    message.suspensionCause = 4;
                    break;
                }
                if (object.locationSettings != null) {
                    if (typeof object.locationSettings !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.locationSettings: object expected");
                    message.locationSettings = $root.waiternow.common.LocationProto.SettingsProto.fromObject(object.locationSettings);
                }
                if (object.metadata != null) {
                    if (typeof object.metadata !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.metadata: object expected");
                    message.metadata = $root.waiternow.common.BusinessProto.MetadataProto.fromObject(object.metadata);
                }
                if (object.isBillable != null)
                    message.isBillable = Boolean(object.isBillable);
                if (object.logo != null) {
                    if (typeof object.logo !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.logo: object expected");
                    message.logo = $root.waiternow.common.ImageProto.fromObject(object.logo);
                }
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.settings: object expected");
                    message.settings = $root.waiternow.common.BusinessProto.SettingsProto.fromObject(object.settings);
                }
                if (object.headerForLandscapeScreen != null) {
                    if (typeof object.headerForLandscapeScreen !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.headerForLandscapeScreen: object expected");
                    message.headerForLandscapeScreen = $root.waiternow.common.ImageProto.fromObject(object.headerForLandscapeScreen);
                }
                if (object.headerForPortraitScreen != null) {
                    if (typeof object.headerForPortraitScreen !== "object")
                        throw TypeError(".waiternow.common.BusinessProto.headerForPortraitScreen: object expected");
                    message.headerForPortraitScreen = $root.waiternow.common.ImageProto.fromObject(object.headerForPortraitScreen);
                }
                if (object.description != null)
                    message.description = String(object.description);
                return message;
            };

            /**
             * Creates a plain object from a BusinessProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {waiternow.common.BusinessProto} message BusinessProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.businessName = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.isSuspended = false;
                    object.suspensionCause = options.enums === String ? "SUSPENSION_CAUSE_UNSPECIFIED" : 0;
                    object.locationSettings = null;
                    object.metadata = null;
                    object.isBillable = false;
                    object.logo = null;
                    object.settings = null;
                    object.headerForLandscapeScreen = null;
                    object.headerForPortraitScreen = null;
                    object.description = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    object.businessName = message.businessName;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.isSuspended != null && message.hasOwnProperty("isSuspended"))
                    object.isSuspended = message.isSuspended;
                if (message.suspensionCause != null && message.hasOwnProperty("suspensionCause"))
                    object.suspensionCause = options.enums === String ? $root.waiternow.common.BusinessProto.SuspensionCause[message.suspensionCause] === undefined ? message.suspensionCause : $root.waiternow.common.BusinessProto.SuspensionCause[message.suspensionCause] : message.suspensionCause;
                if (message.locationSettings != null && message.hasOwnProperty("locationSettings"))
                    object.locationSettings = $root.waiternow.common.LocationProto.SettingsProto.toObject(message.locationSettings, options);
                if (message.metadata != null && message.hasOwnProperty("metadata"))
                    object.metadata = $root.waiternow.common.BusinessProto.MetadataProto.toObject(message.metadata, options);
                if (message.isBillable != null && message.hasOwnProperty("isBillable"))
                    object.isBillable = message.isBillable;
                if (message.logo != null && message.hasOwnProperty("logo"))
                    object.logo = $root.waiternow.common.ImageProto.toObject(message.logo, options);
                if (message.settings != null && message.hasOwnProperty("settings"))
                    object.settings = $root.waiternow.common.BusinessProto.SettingsProto.toObject(message.settings, options);
                if (message.headerForLandscapeScreen != null && message.hasOwnProperty("headerForLandscapeScreen"))
                    object.headerForLandscapeScreen = $root.waiternow.common.ImageProto.toObject(message.headerForLandscapeScreen, options);
                if (message.headerForPortraitScreen != null && message.hasOwnProperty("headerForPortraitScreen"))
                    object.headerForPortraitScreen = $root.waiternow.common.ImageProto.toObject(message.headerForPortraitScreen, options);
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                return object;
            };

            /**
             * Converts this BusinessProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessProto";
            };

            /**
             * SuspensionCause enum.
             * @name waiternow.common.BusinessProto.SuspensionCause
             * @enum {number}
             * @property {number} SUSPENSION_CAUSE_UNSPECIFIED=0 SUSPENSION_CAUSE_UNSPECIFIED value
             * @property {number} UNPAID_BILL=1 UNPAID_BILL value
             * @property {number} QUOTA_LIMIT_REACHED=2 QUOTA_LIMIT_REACHED value
             * @property {number} SUSPENDED_BY_OWNER=3 SUSPENDED_BY_OWNER value
             * @property {number} BLOCKED_OWNER=4 BLOCKED_OWNER value
             */
            BusinessProto.SuspensionCause = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUSPENSION_CAUSE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "UNPAID_BILL"] = 1;
                values[valuesById[2] = "QUOTA_LIMIT_REACHED"] = 2;
                values[valuesById[3] = "SUSPENDED_BY_OWNER"] = 3;
                values[valuesById[4] = "BLOCKED_OWNER"] = 4;
                return values;
            })();

            BusinessProto.MetadataProto = (function() {

                /**
                 * Properties of a MetadataProto.
                 * @memberof waiternow.common.BusinessProto
                 * @interface IMetadataProto
                 * @property {waiternow.common.RecordType|null} [recordType] MetadataProto recordType
                 * @property {string|null} [salesReference] MetadataProto salesReference
                 * @property {string|null} [salespersonId] MetadataProto salespersonId
                 * @property {string|null} [salespersonCode] MetadataProto salespersonCode
                 * @property {number|null} [trialPeriodDays] MetadataProto trialPeriodDays
                 */

                /**
                 * Constructs a new MetadataProto.
                 * @memberof waiternow.common.BusinessProto
                 * @classdesc Represents a MetadataProto.
                 * @implements IMetadataProto
                 * @constructor
                 * @param {waiternow.common.BusinessProto.IMetadataProto=} [properties] Properties to set
                 */
                function MetadataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MetadataProto recordType.
                 * @member {waiternow.common.RecordType} recordType
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.recordType = 0;

                /**
                 * MetadataProto salesReference.
                 * @member {string} salesReference
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.salesReference = "";

                /**
                 * MetadataProto salespersonId.
                 * @member {string} salespersonId
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.salespersonId = "";

                /**
                 * MetadataProto salespersonCode.
                 * @member {string} salespersonCode
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.salespersonCode = "";

                /**
                 * MetadataProto trialPeriodDays.
                 * @member {number} trialPeriodDays
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.trialPeriodDays = 0;

                /**
                 * Creates a new MetadataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {waiternow.common.BusinessProto.IMetadataProto=} [properties] Properties to set
                 * @returns {waiternow.common.BusinessProto.MetadataProto} MetadataProto instance
                 */
                MetadataProto.create = function create(properties) {
                    return new MetadataProto(properties);
                };

                /**
                 * Encodes the specified MetadataProto message. Does not implicitly {@link waiternow.common.BusinessProto.MetadataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {waiternow.common.BusinessProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.recordType != null && Object.hasOwnProperty.call(message, "recordType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.recordType);
                    if (message.salesReference != null && Object.hasOwnProperty.call(message, "salesReference"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.salesReference);
                    if (message.salespersonId != null && Object.hasOwnProperty.call(message, "salespersonId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.salespersonId);
                    if (message.trialPeriodDays != null && Object.hasOwnProperty.call(message, "trialPeriodDays"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.trialPeriodDays);
                    if (message.salespersonCode != null && Object.hasOwnProperty.call(message, "salespersonCode"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.salespersonCode);
                    return writer;
                };

                /**
                 * Encodes the specified MetadataProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessProto.MetadataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {waiternow.common.BusinessProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.BusinessProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessProto.MetadataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.recordType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.salesReference = reader.string();
                                break;
                            }
                        case 3: {
                                message.salespersonId = reader.string();
                                break;
                            }
                        case 5: {
                                message.salespersonCode = reader.string();
                                break;
                            }
                        case 4: {
                                message.trialPeriodDays = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.BusinessProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MetadataProto message.
                 * @function verify
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MetadataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordType != null && message.hasOwnProperty("recordType"))
                        switch (message.recordType) {
                        default:
                            return "recordType: enum value expected";
                        case 0:
                        case 1:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.salesReference != null && message.hasOwnProperty("salesReference"))
                        if (!$util.isString(message.salesReference))
                            return "salesReference: string expected";
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        if (!$util.isString(message.salespersonId))
                            return "salespersonId: string expected";
                    if (message.salespersonCode != null && message.hasOwnProperty("salespersonCode"))
                        if (!$util.isString(message.salespersonCode))
                            return "salespersonCode: string expected";
                    if (message.trialPeriodDays != null && message.hasOwnProperty("trialPeriodDays"))
                        if (!$util.isInteger(message.trialPeriodDays))
                            return "trialPeriodDays: integer expected";
                    return null;
                };

                /**
                 * Creates a MetadataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.BusinessProto.MetadataProto} MetadataProto
                 */
                MetadataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.BusinessProto.MetadataProto)
                        return object;
                    var message = new $root.waiternow.common.BusinessProto.MetadataProto();
                    switch (object.recordType) {
                    default:
                        if (typeof object.recordType === "number") {
                            message.recordType = object.recordType;
                            break;
                        }
                        break;
                    case "RECORD_TYPE_UNSPECIFIED":
                    case 0:
                        message.recordType = 0;
                        break;
                    case "REGULAR":
                    case 1:
                        message.recordType = 1;
                        break;
                    case "PRE_CREATED":
                    case 3:
                        message.recordType = 3;
                        break;
                    case "TEST":
                    case 4:
                        message.recordType = 4;
                        break;
                    case "DEMO":
                    case 5:
                        message.recordType = 5;
                        break;
                    }
                    if (object.salesReference != null)
                        message.salesReference = String(object.salesReference);
                    if (object.salespersonId != null)
                        message.salespersonId = String(object.salespersonId);
                    if (object.salespersonCode != null)
                        message.salespersonCode = String(object.salespersonCode);
                    if (object.trialPeriodDays != null)
                        message.trialPeriodDays = object.trialPeriodDays | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a MetadataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {waiternow.common.BusinessProto.MetadataProto} message MetadataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MetadataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.recordType = options.enums === String ? "RECORD_TYPE_UNSPECIFIED" : 0;
                        object.salesReference = "";
                        object.salespersonId = "";
                        object.trialPeriodDays = 0;
                        object.salespersonCode = "";
                    }
                    if (message.recordType != null && message.hasOwnProperty("recordType"))
                        object.recordType = options.enums === String ? $root.waiternow.common.RecordType[message.recordType] === undefined ? message.recordType : $root.waiternow.common.RecordType[message.recordType] : message.recordType;
                    if (message.salesReference != null && message.hasOwnProperty("salesReference"))
                        object.salesReference = message.salesReference;
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        object.salespersonId = message.salespersonId;
                    if (message.trialPeriodDays != null && message.hasOwnProperty("trialPeriodDays"))
                        object.trialPeriodDays = message.trialPeriodDays;
                    if (message.salespersonCode != null && message.hasOwnProperty("salespersonCode"))
                        object.salespersonCode = message.salespersonCode;
                    return object;
                };

                /**
                 * Converts this MetadataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MetadataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MetadataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.BusinessProto.MetadataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MetadataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.BusinessProto.MetadataProto";
                };

                return MetadataProto;
            })();

            BusinessProto.SettingsProto = (function() {

                /**
                 * Properties of a SettingsProto.
                 * @memberof waiternow.common.BusinessProto
                 * @interface ISettingsProto
                 * @property {waiternow.common.BusinessProto.SettingsProto.IBillingInfoProto|null} [billingInfo] SettingsProto billingInfo
                 * @property {waiternow.common.BusinessProto.SettingsProto.ICustomPlanProto|null} [customPlan] SettingsProto customPlan
                 */

                /**
                 * Constructs a new SettingsProto.
                 * @memberof waiternow.common.BusinessProto
                 * @classdesc Represents a SettingsProto.
                 * @implements ISettingsProto
                 * @constructor
                 * @param {waiternow.common.BusinessProto.ISettingsProto=} [properties] Properties to set
                 */
                function SettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SettingsProto billingInfo.
                 * @member {waiternow.common.BusinessProto.SettingsProto.IBillingInfoProto|null|undefined} billingInfo
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.billingInfo = null;

                /**
                 * SettingsProto customPlan.
                 * @member {waiternow.common.BusinessProto.SettingsProto.ICustomPlanProto|null|undefined} customPlan
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.customPlan = null;

                /**
                 * Creates a new SettingsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {waiternow.common.BusinessProto.ISettingsProto=} [properties] Properties to set
                 * @returns {waiternow.common.BusinessProto.SettingsProto} SettingsProto instance
                 */
                SettingsProto.create = function create(properties) {
                    return new SettingsProto(properties);
                };

                /**
                 * Encodes the specified SettingsProto message. Does not implicitly {@link waiternow.common.BusinessProto.SettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {waiternow.common.BusinessProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.billingInfo != null && Object.hasOwnProperty.call(message, "billingInfo"))
                        $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.encode(message.billingInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.customPlan != null && Object.hasOwnProperty.call(message, "customPlan"))
                        $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.encode(message.customPlan, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SettingsProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessProto.SettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {waiternow.common.BusinessProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.BusinessProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessProto.SettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.billingInfo = $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.customPlan = $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.BusinessProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SettingsProto message.
                 * @function verify
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billingInfo != null && message.hasOwnProperty("billingInfo")) {
                        var error = $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.verify(message.billingInfo);
                        if (error)
                            return "billingInfo." + error;
                    }
                    if (message.customPlan != null && message.hasOwnProperty("customPlan")) {
                        var error = $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.verify(message.customPlan);
                        if (error)
                            return "customPlan." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.BusinessProto.SettingsProto} SettingsProto
                 */
                SettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.BusinessProto.SettingsProto)
                        return object;
                    var message = new $root.waiternow.common.BusinessProto.SettingsProto();
                    if (object.billingInfo != null) {
                        if (typeof object.billingInfo !== "object")
                            throw TypeError(".waiternow.common.BusinessProto.SettingsProto.billingInfo: object expected");
                        message.billingInfo = $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.fromObject(object.billingInfo);
                    }
                    if (object.customPlan != null) {
                        if (typeof object.customPlan !== "object")
                            throw TypeError(".waiternow.common.BusinessProto.SettingsProto.customPlan: object expected");
                        message.customPlan = $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.fromObject(object.customPlan);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {waiternow.common.BusinessProto.SettingsProto} message SettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.billingInfo = null;
                        object.customPlan = null;
                    }
                    if (message.billingInfo != null && message.hasOwnProperty("billingInfo"))
                        object.billingInfo = $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.toObject(message.billingInfo, options);
                    if (message.customPlan != null && message.hasOwnProperty("customPlan"))
                        object.customPlan = $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.toObject(message.customPlan, options);
                    return object;
                };

                /**
                 * Converts this SettingsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SettingsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.BusinessProto.SettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.BusinessProto.SettingsProto";
                };

                SettingsProto.BillingInfoProto = (function() {

                    /**
                     * Properties of a BillingInfoProto.
                     * @memberof waiternow.common.BusinessProto.SettingsProto
                     * @interface IBillingInfoProto
                     * @property {string|null} [businessOfficialId] BillingInfoProto businessOfficialId
                     * @property {waiternow.common.IAddressProto|null} [billingAddress] BillingInfoProto billingAddress
                     * @property {string|null} [businessOfficialName] BillingInfoProto businessOfficialName
                     */

                    /**
                     * Constructs a new BillingInfoProto.
                     * @memberof waiternow.common.BusinessProto.SettingsProto
                     * @classdesc Represents a BillingInfoProto.
                     * @implements IBillingInfoProto
                     * @constructor
                     * @param {waiternow.common.BusinessProto.SettingsProto.IBillingInfoProto=} [properties] Properties to set
                     */
                    function BillingInfoProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * BillingInfoProto businessOfficialId.
                     * @member {string} businessOfficialId
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @instance
                     */
                    BillingInfoProto.prototype.businessOfficialId = "";

                    /**
                     * BillingInfoProto billingAddress.
                     * @member {waiternow.common.IAddressProto|null|undefined} billingAddress
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @instance
                     */
                    BillingInfoProto.prototype.billingAddress = null;

                    /**
                     * BillingInfoProto businessOfficialName.
                     * @member {string} businessOfficialName
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @instance
                     */
                    BillingInfoProto.prototype.businessOfficialName = "";

                    /**
                     * Creates a new BillingInfoProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.IBillingInfoProto=} [properties] Properties to set
                     * @returns {waiternow.common.BusinessProto.SettingsProto.BillingInfoProto} BillingInfoProto instance
                     */
                    BillingInfoProto.create = function create(properties) {
                        return new BillingInfoProto(properties);
                    };

                    /**
                     * Encodes the specified BillingInfoProto message. Does not implicitly {@link waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.IBillingInfoProto} message BillingInfoProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BillingInfoProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.businessOfficialId != null && Object.hasOwnProperty.call(message, "businessOfficialId"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessOfficialId);
                        if (message.billingAddress != null && Object.hasOwnProperty.call(message, "billingAddress"))
                            $root.waiternow.common.AddressProto.encode(message.billingAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.businessOfficialName != null && Object.hasOwnProperty.call(message, "businessOfficialName"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessOfficialName);
                        return writer;
                    };

                    /**
                     * Encodes the specified BillingInfoProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.IBillingInfoProto} message BillingInfoProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    BillingInfoProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a BillingInfoProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.BusinessProto.SettingsProto.BillingInfoProto} BillingInfoProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BillingInfoProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.businessOfficialId = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.billingAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                    break;
                                }
                            case 3: {
                                    message.businessOfficialName = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a BillingInfoProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.BusinessProto.SettingsProto.BillingInfoProto} BillingInfoProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    BillingInfoProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a BillingInfoProto message.
                     * @function verify
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    BillingInfoProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.businessOfficialId != null && message.hasOwnProperty("businessOfficialId"))
                            if (!$util.isString(message.businessOfficialId))
                                return "businessOfficialId: string expected";
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress")) {
                            var error = $root.waiternow.common.AddressProto.verify(message.billingAddress);
                            if (error)
                                return "billingAddress." + error;
                        }
                        if (message.businessOfficialName != null && message.hasOwnProperty("businessOfficialName"))
                            if (!$util.isString(message.businessOfficialName))
                                return "businessOfficialName: string expected";
                        return null;
                    };

                    /**
                     * Creates a BillingInfoProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.BusinessProto.SettingsProto.BillingInfoProto} BillingInfoProto
                     */
                    BillingInfoProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto)
                            return object;
                        var message = new $root.waiternow.common.BusinessProto.SettingsProto.BillingInfoProto();
                        if (object.businessOfficialId != null)
                            message.businessOfficialId = String(object.businessOfficialId);
                        if (object.billingAddress != null) {
                            if (typeof object.billingAddress !== "object")
                                throw TypeError(".waiternow.common.BusinessProto.SettingsProto.BillingInfoProto.billingAddress: object expected");
                            message.billingAddress = $root.waiternow.common.AddressProto.fromObject(object.billingAddress);
                        }
                        if (object.businessOfficialName != null)
                            message.businessOfficialName = String(object.businessOfficialName);
                        return message;
                    };

                    /**
                     * Creates a plain object from a BillingInfoProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.BillingInfoProto} message BillingInfoProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    BillingInfoProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.businessOfficialId = "";
                            object.billingAddress = null;
                            object.businessOfficialName = "";
                        }
                        if (message.businessOfficialId != null && message.hasOwnProperty("businessOfficialId"))
                            object.businessOfficialId = message.businessOfficialId;
                        if (message.billingAddress != null && message.hasOwnProperty("billingAddress"))
                            object.billingAddress = $root.waiternow.common.AddressProto.toObject(message.billingAddress, options);
                        if (message.businessOfficialName != null && message.hasOwnProperty("businessOfficialName"))
                            object.businessOfficialName = message.businessOfficialName;
                        return object;
                    };

                    /**
                     * Converts this BillingInfoProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    BillingInfoProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for BillingInfoProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.BusinessProto.SettingsProto.BillingInfoProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    BillingInfoProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.BusinessProto.SettingsProto.BillingInfoProto";
                    };

                    return BillingInfoProto;
                })();

                SettingsProto.CustomPlanProto = (function() {

                    /**
                     * Properties of a CustomPlanProto.
                     * @memberof waiternow.common.BusinessProto.SettingsProto
                     * @interface ICustomPlanProto
                     * @property {number|null} [maxNumberOfLocations] CustomPlanProto maxNumberOfLocations
                     * @property {number|null} [maxNumberOfPointsOfServiceByLocation] CustomPlanProto maxNumberOfPointsOfServiceByLocation
                     * @property {waiternow.common.IMoneyProto|null} [cost] CustomPlanProto cost
                     */

                    /**
                     * Constructs a new CustomPlanProto.
                     * @memberof waiternow.common.BusinessProto.SettingsProto
                     * @classdesc Represents a CustomPlanProto.
                     * @implements ICustomPlanProto
                     * @constructor
                     * @param {waiternow.common.BusinessProto.SettingsProto.ICustomPlanProto=} [properties] Properties to set
                     */
                    function CustomPlanProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * CustomPlanProto maxNumberOfLocations.
                     * @member {number} maxNumberOfLocations
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @instance
                     */
                    CustomPlanProto.prototype.maxNumberOfLocations = 0;

                    /**
                     * CustomPlanProto maxNumberOfPointsOfServiceByLocation.
                     * @member {number} maxNumberOfPointsOfServiceByLocation
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @instance
                     */
                    CustomPlanProto.prototype.maxNumberOfPointsOfServiceByLocation = 0;

                    /**
                     * CustomPlanProto cost.
                     * @member {waiternow.common.IMoneyProto|null|undefined} cost
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @instance
                     */
                    CustomPlanProto.prototype.cost = null;

                    /**
                     * Creates a new CustomPlanProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.ICustomPlanProto=} [properties] Properties to set
                     * @returns {waiternow.common.BusinessProto.SettingsProto.CustomPlanProto} CustomPlanProto instance
                     */
                    CustomPlanProto.create = function create(properties) {
                        return new CustomPlanProto(properties);
                    };

                    /**
                     * Encodes the specified CustomPlanProto message. Does not implicitly {@link waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.ICustomPlanProto} message CustomPlanProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomPlanProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.maxNumberOfLocations != null && Object.hasOwnProperty.call(message, "maxNumberOfLocations"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.maxNumberOfLocations);
                        if (message.maxNumberOfPointsOfServiceByLocation != null && Object.hasOwnProperty.call(message, "maxNumberOfPointsOfServiceByLocation"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.maxNumberOfPointsOfServiceByLocation);
                        if (message.cost != null && Object.hasOwnProperty.call(message, "cost"))
                            $root.waiternow.common.MoneyProto.encode(message.cost, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified CustomPlanProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.ICustomPlanProto} message CustomPlanProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    CustomPlanProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a CustomPlanProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.BusinessProto.SettingsProto.CustomPlanProto} CustomPlanProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomPlanProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.maxNumberOfLocations = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.maxNumberOfPointsOfServiceByLocation = reader.int32();
                                    break;
                                }
                            case 3: {
                                    message.cost = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a CustomPlanProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.BusinessProto.SettingsProto.CustomPlanProto} CustomPlanProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    CustomPlanProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a CustomPlanProto message.
                     * @function verify
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    CustomPlanProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.maxNumberOfLocations != null && message.hasOwnProperty("maxNumberOfLocations"))
                            if (!$util.isInteger(message.maxNumberOfLocations))
                                return "maxNumberOfLocations: integer expected";
                        if (message.maxNumberOfPointsOfServiceByLocation != null && message.hasOwnProperty("maxNumberOfPointsOfServiceByLocation"))
                            if (!$util.isInteger(message.maxNumberOfPointsOfServiceByLocation))
                                return "maxNumberOfPointsOfServiceByLocation: integer expected";
                        if (message.cost != null && message.hasOwnProperty("cost")) {
                            var error = $root.waiternow.common.MoneyProto.verify(message.cost);
                            if (error)
                                return "cost." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a CustomPlanProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.BusinessProto.SettingsProto.CustomPlanProto} CustomPlanProto
                     */
                    CustomPlanProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto)
                            return object;
                        var message = new $root.waiternow.common.BusinessProto.SettingsProto.CustomPlanProto();
                        if (object.maxNumberOfLocations != null)
                            message.maxNumberOfLocations = object.maxNumberOfLocations | 0;
                        if (object.maxNumberOfPointsOfServiceByLocation != null)
                            message.maxNumberOfPointsOfServiceByLocation = object.maxNumberOfPointsOfServiceByLocation | 0;
                        if (object.cost != null) {
                            if (typeof object.cost !== "object")
                                throw TypeError(".waiternow.common.BusinessProto.SettingsProto.CustomPlanProto.cost: object expected");
                            message.cost = $root.waiternow.common.MoneyProto.fromObject(object.cost);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a CustomPlanProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {waiternow.common.BusinessProto.SettingsProto.CustomPlanProto} message CustomPlanProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    CustomPlanProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.maxNumberOfLocations = 0;
                            object.maxNumberOfPointsOfServiceByLocation = 0;
                            object.cost = null;
                        }
                        if (message.maxNumberOfLocations != null && message.hasOwnProperty("maxNumberOfLocations"))
                            object.maxNumberOfLocations = message.maxNumberOfLocations;
                        if (message.maxNumberOfPointsOfServiceByLocation != null && message.hasOwnProperty("maxNumberOfPointsOfServiceByLocation"))
                            object.maxNumberOfPointsOfServiceByLocation = message.maxNumberOfPointsOfServiceByLocation;
                        if (message.cost != null && message.hasOwnProperty("cost"))
                            object.cost = $root.waiternow.common.MoneyProto.toObject(message.cost, options);
                        return object;
                    };

                    /**
                     * Converts this CustomPlanProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    CustomPlanProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for CustomPlanProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.BusinessProto.SettingsProto.CustomPlanProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    CustomPlanProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.BusinessProto.SettingsProto.CustomPlanProto";
                    };

                    return CustomPlanProto;
                })();

                return SettingsProto;
            })();

            return BusinessProto;
        })();

        common.BusinessesProto = (function() {

            /**
             * Properties of a BusinessesProto.
             * @memberof waiternow.common
             * @interface IBusinessesProto
             * @property {Array.<waiternow.common.IBusinessProto>|null} [businesses] BusinessesProto businesses
             * @property {string|null} [continuationToken] BusinessesProto continuationToken
             */

            /**
             * Constructs a new BusinessesProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessesProto.
             * @implements IBusinessesProto
             * @constructor
             * @param {waiternow.common.IBusinessesProto=} [properties] Properties to set
             */
            function BusinessesProto(properties) {
                this.businesses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessesProto businesses.
             * @member {Array.<waiternow.common.IBusinessProto>} businesses
             * @memberof waiternow.common.BusinessesProto
             * @instance
             */
            BusinessesProto.prototype.businesses = $util.emptyArray;

            /**
             * BusinessesProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.BusinessesProto
             * @instance
             */
            BusinessesProto.prototype.continuationToken = "";

            /**
             * Creates a new BusinessesProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {waiternow.common.IBusinessesProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessesProto} BusinessesProto instance
             */
            BusinessesProto.create = function create(properties) {
                return new BusinessesProto(properties);
            };

            /**
             * Encodes the specified BusinessesProto message. Does not implicitly {@link waiternow.common.BusinessesProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {waiternow.common.IBusinessesProto} message BusinessesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessesProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.businesses != null && message.businesses.length)
                    for (var i = 0; i < message.businesses.length; ++i)
                        $root.waiternow.common.BusinessProto.encode(message.businesses[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified BusinessesProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessesProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {waiternow.common.IBusinessesProto} message BusinessesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessesProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessesProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessesProto} BusinessesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessesProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessesProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.businesses && message.businesses.length))
                                message.businesses = [];
                            message.businesses.push($root.waiternow.common.BusinessProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessesProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessesProto} BusinessesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessesProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessesProto message.
             * @function verify
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessesProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.businesses != null && message.hasOwnProperty("businesses")) {
                    if (!Array.isArray(message.businesses))
                        return "businesses: array expected";
                    for (var i = 0; i < message.businesses.length; ++i) {
                        var error = $root.waiternow.common.BusinessProto.verify(message.businesses[i]);
                        if (error)
                            return "businesses." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a BusinessesProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessesProto} BusinessesProto
             */
            BusinessesProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessesProto)
                    return object;
                var message = new $root.waiternow.common.BusinessesProto();
                if (object.businesses) {
                    if (!Array.isArray(object.businesses))
                        throw TypeError(".waiternow.common.BusinessesProto.businesses: array expected");
                    message.businesses = [];
                    for (var i = 0; i < object.businesses.length; ++i) {
                        if (typeof object.businesses[i] !== "object")
                            throw TypeError(".waiternow.common.BusinessesProto.businesses: object expected");
                        message.businesses[i] = $root.waiternow.common.BusinessProto.fromObject(object.businesses[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a BusinessesProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {waiternow.common.BusinessesProto} message BusinessesProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessesProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.businesses = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.businesses && message.businesses.length) {
                    object.businesses = [];
                    for (var j = 0; j < message.businesses.length; ++j)
                        object.businesses[j] = $root.waiternow.common.BusinessProto.toObject(message.businesses[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this BusinessesProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessesProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessesProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessesProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessesProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessesProto";
            };

            return BusinessesProto;
        })();

        common.UserBusinessesProto = (function() {

            /**
             * Properties of a UserBusinessesProto.
             * @memberof waiternow.common
             * @interface IUserBusinessesProto
             * @property {string|null} [userId] UserBusinessesProto userId
             * @property {Array.<waiternow.common.UserBusinessesProto.IManagedBusinessProto>|null} [businesses] UserBusinessesProto businesses
             */

            /**
             * Constructs a new UserBusinessesProto.
             * @memberof waiternow.common
             * @classdesc Represents a UserBusinessesProto.
             * @implements IUserBusinessesProto
             * @constructor
             * @param {waiternow.common.IUserBusinessesProto=} [properties] Properties to set
             */
            function UserBusinessesProto(properties) {
                this.businesses = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserBusinessesProto userId.
             * @member {string} userId
             * @memberof waiternow.common.UserBusinessesProto
             * @instance
             */
            UserBusinessesProto.prototype.userId = "";

            /**
             * UserBusinessesProto businesses.
             * @member {Array.<waiternow.common.UserBusinessesProto.IManagedBusinessProto>} businesses
             * @memberof waiternow.common.UserBusinessesProto
             * @instance
             */
            UserBusinessesProto.prototype.businesses = $util.emptyArray;

            /**
             * Creates a new UserBusinessesProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {waiternow.common.IUserBusinessesProto=} [properties] Properties to set
             * @returns {waiternow.common.UserBusinessesProto} UserBusinessesProto instance
             */
            UserBusinessesProto.create = function create(properties) {
                return new UserBusinessesProto(properties);
            };

            /**
             * Encodes the specified UserBusinessesProto message. Does not implicitly {@link waiternow.common.UserBusinessesProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {waiternow.common.IUserBusinessesProto} message UserBusinessesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserBusinessesProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.businesses != null && message.businesses.length)
                    for (var i = 0; i < message.businesses.length; ++i)
                        $root.waiternow.common.UserBusinessesProto.ManagedBusinessProto.encode(message.businesses[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UserBusinessesProto message, length delimited. Does not implicitly {@link waiternow.common.UserBusinessesProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {waiternow.common.IUserBusinessesProto} message UserBusinessesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserBusinessesProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserBusinessesProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UserBusinessesProto} UserBusinessesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserBusinessesProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserBusinessesProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.businesses && message.businesses.length))
                                message.businesses = [];
                            message.businesses.push($root.waiternow.common.UserBusinessesProto.ManagedBusinessProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserBusinessesProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UserBusinessesProto} UserBusinessesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserBusinessesProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserBusinessesProto message.
             * @function verify
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserBusinessesProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.businesses != null && message.hasOwnProperty("businesses")) {
                    if (!Array.isArray(message.businesses))
                        return "businesses: array expected";
                    for (var i = 0; i < message.businesses.length; ++i) {
                        var error = $root.waiternow.common.UserBusinessesProto.ManagedBusinessProto.verify(message.businesses[i]);
                        if (error)
                            return "businesses." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a UserBusinessesProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UserBusinessesProto} UserBusinessesProto
             */
            UserBusinessesProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UserBusinessesProto)
                    return object;
                var message = new $root.waiternow.common.UserBusinessesProto();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.businesses) {
                    if (!Array.isArray(object.businesses))
                        throw TypeError(".waiternow.common.UserBusinessesProto.businesses: array expected");
                    message.businesses = [];
                    for (var i = 0; i < object.businesses.length; ++i) {
                        if (typeof object.businesses[i] !== "object")
                            throw TypeError(".waiternow.common.UserBusinessesProto.businesses: object expected");
                        message.businesses[i] = $root.waiternow.common.UserBusinessesProto.ManagedBusinessProto.fromObject(object.businesses[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a UserBusinessesProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {waiternow.common.UserBusinessesProto} message UserBusinessesProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserBusinessesProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.businesses = [];
                if (options.defaults)
                    object.userId = "";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.businesses && message.businesses.length) {
                    object.businesses = [];
                    for (var j = 0; j < message.businesses.length; ++j)
                        object.businesses[j] = $root.waiternow.common.UserBusinessesProto.ManagedBusinessProto.toObject(message.businesses[j], options);
                }
                return object;
            };

            /**
             * Converts this UserBusinessesProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UserBusinessesProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserBusinessesProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserBusinessesProto
             * @function getTypeUrl
             * @memberof waiternow.common.UserBusinessesProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserBusinessesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UserBusinessesProto";
            };

            UserBusinessesProto.ManagedBusinessProto = (function() {

                /**
                 * Properties of a ManagedBusinessProto.
                 * @memberof waiternow.common.UserBusinessesProto
                 * @interface IManagedBusinessProto
                 * @property {waiternow.common.IBusinessProto|null} [business] ManagedBusinessProto business
                 * @property {waiternow.common.Role|null} [userRole] ManagedBusinessProto userRole
                 */

                /**
                 * Constructs a new ManagedBusinessProto.
                 * @memberof waiternow.common.UserBusinessesProto
                 * @classdesc Represents a ManagedBusinessProto.
                 * @implements IManagedBusinessProto
                 * @constructor
                 * @param {waiternow.common.UserBusinessesProto.IManagedBusinessProto=} [properties] Properties to set
                 */
                function ManagedBusinessProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ManagedBusinessProto business.
                 * @member {waiternow.common.IBusinessProto|null|undefined} business
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @instance
                 */
                ManagedBusinessProto.prototype.business = null;

                /**
                 * ManagedBusinessProto userRole.
                 * @member {waiternow.common.Role} userRole
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @instance
                 */
                ManagedBusinessProto.prototype.userRole = 0;

                /**
                 * Creates a new ManagedBusinessProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {waiternow.common.UserBusinessesProto.IManagedBusinessProto=} [properties] Properties to set
                 * @returns {waiternow.common.UserBusinessesProto.ManagedBusinessProto} ManagedBusinessProto instance
                 */
                ManagedBusinessProto.create = function create(properties) {
                    return new ManagedBusinessProto(properties);
                };

                /**
                 * Encodes the specified ManagedBusinessProto message. Does not implicitly {@link waiternow.common.UserBusinessesProto.ManagedBusinessProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {waiternow.common.UserBusinessesProto.IManagedBusinessProto} message ManagedBusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagedBusinessProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.business != null && Object.hasOwnProperty.call(message, "business"))
                        $root.waiternow.common.BusinessProto.encode(message.business, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.userRole != null && Object.hasOwnProperty.call(message, "userRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userRole);
                    return writer;
                };

                /**
                 * Encodes the specified ManagedBusinessProto message, length delimited. Does not implicitly {@link waiternow.common.UserBusinessesProto.ManagedBusinessProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {waiternow.common.UserBusinessesProto.IManagedBusinessProto} message ManagedBusinessProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagedBusinessProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ManagedBusinessProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UserBusinessesProto.ManagedBusinessProto} ManagedBusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagedBusinessProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserBusinessesProto.ManagedBusinessProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.business = $root.waiternow.common.BusinessProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.userRole = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ManagedBusinessProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UserBusinessesProto.ManagedBusinessProto} ManagedBusinessProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagedBusinessProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ManagedBusinessProto message.
                 * @function verify
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ManagedBusinessProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.business != null && message.hasOwnProperty("business")) {
                        var error = $root.waiternow.common.BusinessProto.verify(message.business);
                        if (error)
                            return "business." + error;
                    }
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        switch (message.userRole) {
                        default:
                            return "userRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ManagedBusinessProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UserBusinessesProto.ManagedBusinessProto} ManagedBusinessProto
                 */
                ManagedBusinessProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UserBusinessesProto.ManagedBusinessProto)
                        return object;
                    var message = new $root.waiternow.common.UserBusinessesProto.ManagedBusinessProto();
                    if (object.business != null) {
                        if (typeof object.business !== "object")
                            throw TypeError(".waiternow.common.UserBusinessesProto.ManagedBusinessProto.business: object expected");
                        message.business = $root.waiternow.common.BusinessProto.fromObject(object.business);
                    }
                    switch (object.userRole) {
                    default:
                        if (typeof object.userRole === "number") {
                            message.userRole = object.userRole;
                            break;
                        }
                        break;
                    case "ROLE_UNSPECIFIED":
                    case 0:
                        message.userRole = 0;
                        break;
                    case "OWNER":
                    case 1:
                        message.userRole = 1;
                        break;
                    case "ADMIN":
                    case 2:
                        message.userRole = 2;
                        break;
                    case "VIEWER":
                    case 3:
                        message.userRole = 3;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ManagedBusinessProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {waiternow.common.UserBusinessesProto.ManagedBusinessProto} message ManagedBusinessProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ManagedBusinessProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.business = null;
                        object.userRole = options.enums === String ? "ROLE_UNSPECIFIED" : 0;
                    }
                    if (message.business != null && message.hasOwnProperty("business"))
                        object.business = $root.waiternow.common.BusinessProto.toObject(message.business, options);
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        object.userRole = options.enums === String ? $root.waiternow.common.Role[message.userRole] === undefined ? message.userRole : $root.waiternow.common.Role[message.userRole] : message.userRole;
                    return object;
                };

                /**
                 * Converts this ManagedBusinessProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ManagedBusinessProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ManagedBusinessProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.UserBusinessesProto.ManagedBusinessProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ManagedBusinessProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UserBusinessesProto.ManagedBusinessProto";
                };

                return ManagedBusinessProto;
            })();

            return UserBusinessesProto;
        })();

        common.BusinessUsersProto = (function() {

            /**
             * Properties of a BusinessUsersProto.
             * @memberof waiternow.common
             * @interface IBusinessUsersProto
             * @property {string|null} [businessId] BusinessUsersProto businessId
             * @property {Array.<waiternow.common.BusinessUsersProto.IManagerUserProto>|null} [users] BusinessUsersProto users
             */

            /**
             * Constructs a new BusinessUsersProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessUsersProto.
             * @implements IBusinessUsersProto
             * @constructor
             * @param {waiternow.common.IBusinessUsersProto=} [properties] Properties to set
             */
            function BusinessUsersProto(properties) {
                this.users = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessUsersProto businessId.
             * @member {string} businessId
             * @memberof waiternow.common.BusinessUsersProto
             * @instance
             */
            BusinessUsersProto.prototype.businessId = "";

            /**
             * BusinessUsersProto users.
             * @member {Array.<waiternow.common.BusinessUsersProto.IManagerUserProto>} users
             * @memberof waiternow.common.BusinessUsersProto
             * @instance
             */
            BusinessUsersProto.prototype.users = $util.emptyArray;

            /**
             * Creates a new BusinessUsersProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {waiternow.common.IBusinessUsersProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessUsersProto} BusinessUsersProto instance
             */
            BusinessUsersProto.create = function create(properties) {
                return new BusinessUsersProto(properties);
            };

            /**
             * Encodes the specified BusinessUsersProto message. Does not implicitly {@link waiternow.common.BusinessUsersProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {waiternow.common.IBusinessUsersProto} message BusinessUsersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessUsersProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                if (message.users != null && message.users.length)
                    for (var i = 0; i < message.users.length; ++i)
                        $root.waiternow.common.BusinessUsersProto.ManagerUserProto.encode(message.users[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified BusinessUsersProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessUsersProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {waiternow.common.IBusinessUsersProto} message BusinessUsersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessUsersProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessUsersProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessUsersProto} BusinessUsersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessUsersProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessUsersProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.businessId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.users && message.users.length))
                                message.users = [];
                            message.users.push($root.waiternow.common.BusinessUsersProto.ManagerUserProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessUsersProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessUsersProto} BusinessUsersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessUsersProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessUsersProto message.
             * @function verify
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessUsersProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    if (!$util.isString(message.businessId))
                        return "businessId: string expected";
                if (message.users != null && message.hasOwnProperty("users")) {
                    if (!Array.isArray(message.users))
                        return "users: array expected";
                    for (var i = 0; i < message.users.length; ++i) {
                        var error = $root.waiternow.common.BusinessUsersProto.ManagerUserProto.verify(message.users[i]);
                        if (error)
                            return "users." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a BusinessUsersProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessUsersProto} BusinessUsersProto
             */
            BusinessUsersProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessUsersProto)
                    return object;
                var message = new $root.waiternow.common.BusinessUsersProto();
                if (object.businessId != null)
                    message.businessId = String(object.businessId);
                if (object.users) {
                    if (!Array.isArray(object.users))
                        throw TypeError(".waiternow.common.BusinessUsersProto.users: array expected");
                    message.users = [];
                    for (var i = 0; i < object.users.length; ++i) {
                        if (typeof object.users[i] !== "object")
                            throw TypeError(".waiternow.common.BusinessUsersProto.users: object expected");
                        message.users[i] = $root.waiternow.common.BusinessUsersProto.ManagerUserProto.fromObject(object.users[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a BusinessUsersProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {waiternow.common.BusinessUsersProto} message BusinessUsersProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessUsersProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.users = [];
                if (options.defaults)
                    object.businessId = "";
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    object.businessId = message.businessId;
                if (message.users && message.users.length) {
                    object.users = [];
                    for (var j = 0; j < message.users.length; ++j)
                        object.users[j] = $root.waiternow.common.BusinessUsersProto.ManagerUserProto.toObject(message.users[j], options);
                }
                return object;
            };

            /**
             * Converts this BusinessUsersProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessUsersProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessUsersProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessUsersProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessUsersProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessUsersProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessUsersProto";
            };

            BusinessUsersProto.ManagerUserProto = (function() {

                /**
                 * Properties of a ManagerUserProto.
                 * @memberof waiternow.common.BusinessUsersProto
                 * @interface IManagerUserProto
                 * @property {string|null} [email] ManagerUserProto email
                 * @property {waiternow.common.Role|null} [role] ManagerUserProto role
                 * @property {string|null} [id] ManagerUserProto id
                 * @property {string|null} [name] ManagerUserProto name
                 * @property {string|null} [lastName] ManagerUserProto lastName
                 * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] ManagerUserProto phoneNumber
                 */

                /**
                 * Constructs a new ManagerUserProto.
                 * @memberof waiternow.common.BusinessUsersProto
                 * @classdesc Represents a ManagerUserProto.
                 * @implements IManagerUserProto
                 * @constructor
                 * @param {waiternow.common.BusinessUsersProto.IManagerUserProto=} [properties] Properties to set
                 */
                function ManagerUserProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ManagerUserProto email.
                 * @member {string} email
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.email = "";

                /**
                 * ManagerUserProto role.
                 * @member {waiternow.common.Role} role
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.role = 0;

                /**
                 * ManagerUserProto id.
                 * @member {string} id
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.id = "";

                /**
                 * ManagerUserProto name.
                 * @member {string} name
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.name = "";

                /**
                 * ManagerUserProto lastName.
                 * @member {string} lastName
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.lastName = "";

                /**
                 * ManagerUserProto phoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.phoneNumber = null;

                /**
                 * Creates a new ManagerUserProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.BusinessUsersProto.IManagerUserProto=} [properties] Properties to set
                 * @returns {waiternow.common.BusinessUsersProto.ManagerUserProto} ManagerUserProto instance
                 */
                ManagerUserProto.create = function create(properties) {
                    return new ManagerUserProto(properties);
                };

                /**
                 * Encodes the specified ManagerUserProto message. Does not implicitly {@link waiternow.common.BusinessUsersProto.ManagerUserProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.BusinessUsersProto.IManagerUserProto} message ManagerUserProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagerUserProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.role);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastName);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ManagerUserProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessUsersProto.ManagerUserProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.BusinessUsersProto.IManagerUserProto} message ManagerUserProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagerUserProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ManagerUserProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.BusinessUsersProto.ManagerUserProto} ManagerUserProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagerUserProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessUsersProto.ManagerUserProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        case 2: {
                                message.role = reader.int32();
                                break;
                            }
                        case 3: {
                                message.id = reader.string();
                                break;
                            }
                        case 4: {
                                message.name = reader.string();
                                break;
                            }
                        case 5: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 6: {
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ManagerUserProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.BusinessUsersProto.ManagerUserProto} ManagerUserProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagerUserProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ManagerUserProto message.
                 * @function verify
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ManagerUserProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.role != null && message.hasOwnProperty("role"))
                        switch (message.role) {
                        default:
                            return "role: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ManagerUserProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.BusinessUsersProto.ManagerUserProto} ManagerUserProto
                 */
                ManagerUserProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.BusinessUsersProto.ManagerUserProto)
                        return object;
                    var message = new $root.waiternow.common.BusinessUsersProto.ManagerUserProto();
                    if (object.email != null)
                        message.email = String(object.email);
                    switch (object.role) {
                    default:
                        if (typeof object.role === "number") {
                            message.role = object.role;
                            break;
                        }
                        break;
                    case "ROLE_UNSPECIFIED":
                    case 0:
                        message.role = 0;
                        break;
                    case "OWNER":
                    case 1:
                        message.role = 1;
                        break;
                    case "ADMIN":
                    case 2:
                        message.role = 2;
                        break;
                    case "VIEWER":
                    case 3:
                        message.role = 3;
                        break;
                    }
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".waiternow.common.BusinessUsersProto.ManagerUserProto.phoneNumber: object expected");
                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ManagerUserProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.BusinessUsersProto.ManagerUserProto} message ManagerUserProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ManagerUserProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.role = options.enums === String ? "ROLE_UNSPECIFIED" : 0;
                        object.id = "";
                        object.name = "";
                        object.lastName = "";
                        object.phoneNumber = null;
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.role != null && message.hasOwnProperty("role"))
                        object.role = options.enums === String ? $root.waiternow.common.Role[message.role] === undefined ? message.role : $root.waiternow.common.Role[message.role] : message.role;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                    return object;
                };

                /**
                 * Converts this ManagerUserProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ManagerUserProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ManagerUserProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.BusinessUsersProto.ManagerUserProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ManagerUserProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.BusinessUsersProto.ManagerUserProto";
                };

                return ManagerUserProto;
            })();

            return BusinessUsersProto;
        })();

        common.LocationProto = (function() {

            /**
             * Properties of a LocationProto.
             * @memberof waiternow.common
             * @interface ILocationProto
             * @property {string|null} [id] LocationProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] LocationProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] LocationProto lastUpdateTime
             * @property {string|null} [businessId] LocationProto businessId
             * @property {string|null} [name] LocationProto name
             * @property {waiternow.common.IAddressProto|null} [address] LocationProto address
             * @property {waiternow.common.LocationProto.ISettingsProto|null} [settings] LocationProto settings
             * @property {waiternow.common.LocationProto.IRedundantDataProto|null} [redundantData] LocationProto redundantData
             * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] LocationProto phoneNumber
             * @property {waiternow.common.LocationProto.IMetadataProto|null} [metadata] LocationProto metadata
             */

            /**
             * Constructs a new LocationProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationProto.
             * @implements ILocationProto
             * @constructor
             * @param {waiternow.common.ILocationProto=} [properties] Properties to set
             */
            function LocationProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationProto id.
             * @member {string} id
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.id = "";

            /**
             * LocationProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.creationTime = null;

            /**
             * LocationProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.lastUpdateTime = null;

            /**
             * LocationProto businessId.
             * @member {string} businessId
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.businessId = "";

            /**
             * LocationProto name.
             * @member {string} name
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.name = "";

            /**
             * LocationProto address.
             * @member {waiternow.common.IAddressProto|null|undefined} address
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.address = null;

            /**
             * LocationProto settings.
             * @member {waiternow.common.LocationProto.ISettingsProto|null|undefined} settings
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.settings = null;

            /**
             * LocationProto redundantData.
             * @member {waiternow.common.LocationProto.IRedundantDataProto|null|undefined} redundantData
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.redundantData = null;

            /**
             * LocationProto phoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.phoneNumber = null;

            /**
             * LocationProto metadata.
             * @member {waiternow.common.LocationProto.IMetadataProto|null|undefined} metadata
             * @memberof waiternow.common.LocationProto
             * @instance
             */
            LocationProto.prototype.metadata = null;

            /**
             * Creates a new LocationProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {waiternow.common.ILocationProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationProto} LocationProto instance
             */
            LocationProto.create = function create(properties) {
                return new LocationProto(properties);
            };

            /**
             * Encodes the specified LocationProto message. Does not implicitly {@link waiternow.common.LocationProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {waiternow.common.ILocationProto} message LocationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                    $root.waiternow.common.AddressProto.encode(message.address, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.waiternow.common.LocationProto.SettingsProto.encode(message.settings, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.redundantData != null && Object.hasOwnProperty.call(message, "redundantData"))
                    $root.waiternow.common.LocationProto.RedundantDataProto.encode(message.redundantData, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                    $root.waiternow.common.LocationProto.MetadataProto.encode(message.metadata, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LocationProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {waiternow.common.ILocationProto} message LocationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationProto} LocationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 5: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.businessId = reader.string();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    case 4: {
                            message.address = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.settings = $root.waiternow.common.LocationProto.SettingsProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.redundantData = $root.waiternow.common.LocationProto.RedundantDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.metadata = $root.waiternow.common.LocationProto.MetadataProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationProto} LocationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationProto message.
             * @function verify
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    if (!$util.isString(message.businessId))
                        return "businessId: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.address != null && message.hasOwnProperty("address")) {
                    var error = $root.waiternow.common.AddressProto.verify(message.address);
                    if (error)
                        return "address." + error;
                }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    var error = $root.waiternow.common.LocationProto.SettingsProto.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
                if (message.redundantData != null && message.hasOwnProperty("redundantData")) {
                    var error = $root.waiternow.common.LocationProto.RedundantDataProto.verify(message.redundantData);
                    if (error)
                        return "redundantData." + error;
                }
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                    if (error)
                        return "phoneNumber." + error;
                }
                if (message.metadata != null && message.hasOwnProperty("metadata")) {
                    var error = $root.waiternow.common.LocationProto.MetadataProto.verify(message.metadata);
                    if (error)
                        return "metadata." + error;
                }
                return null;
            };

            /**
             * Creates a LocationProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationProto} LocationProto
             */
            LocationProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationProto)
                    return object;
                var message = new $root.waiternow.common.LocationProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.LocationProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.LocationProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.businessId != null)
                    message.businessId = String(object.businessId);
                if (object.name != null)
                    message.name = String(object.name);
                if (object.address != null) {
                    if (typeof object.address !== "object")
                        throw TypeError(".waiternow.common.LocationProto.address: object expected");
                    message.address = $root.waiternow.common.AddressProto.fromObject(object.address);
                }
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".waiternow.common.LocationProto.settings: object expected");
                    message.settings = $root.waiternow.common.LocationProto.SettingsProto.fromObject(object.settings);
                }
                if (object.redundantData != null) {
                    if (typeof object.redundantData !== "object")
                        throw TypeError(".waiternow.common.LocationProto.redundantData: object expected");
                    message.redundantData = $root.waiternow.common.LocationProto.RedundantDataProto.fromObject(object.redundantData);
                }
                if (object.phoneNumber != null) {
                    if (typeof object.phoneNumber !== "object")
                        throw TypeError(".waiternow.common.LocationProto.phoneNumber: object expected");
                    message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                }
                if (object.metadata != null) {
                    if (typeof object.metadata !== "object")
                        throw TypeError(".waiternow.common.LocationProto.metadata: object expected");
                    message.metadata = $root.waiternow.common.LocationProto.MetadataProto.fromObject(object.metadata);
                }
                return message;
            };

            /**
             * Creates a plain object from a LocationProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {waiternow.common.LocationProto} message LocationProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.businessId = "";
                    object.name = "";
                    object.address = null;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.settings = null;
                    object.redundantData = null;
                    object.phoneNumber = null;
                    object.metadata = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.businessId != null && message.hasOwnProperty("businessId"))
                    object.businessId = message.businessId;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.address != null && message.hasOwnProperty("address"))
                    object.address = $root.waiternow.common.AddressProto.toObject(message.address, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.settings != null && message.hasOwnProperty("settings"))
                    object.settings = $root.waiternow.common.LocationProto.SettingsProto.toObject(message.settings, options);
                if (message.redundantData != null && message.hasOwnProperty("redundantData"))
                    object.redundantData = $root.waiternow.common.LocationProto.RedundantDataProto.toObject(message.redundantData, options);
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                if (message.metadata != null && message.hasOwnProperty("metadata"))
                    object.metadata = $root.waiternow.common.LocationProto.MetadataProto.toObject(message.metadata, options);
                return object;
            };

            /**
             * Converts this LocationProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationProto";
            };

            LocationProto.SettingsProto = (function() {

                /**
                 * Properties of a SettingsProto.
                 * @memberof waiternow.common.LocationProto
                 * @interface ISettingsProto
                 * @property {waiternow.common.IMenuProto|null} [menu] SettingsProto menu
                 * @property {waiternow.common.LocationProto.SettingsProto.ILocaleSettingsProto|null} [localeSettings] SettingsProto localeSettings
                 * @property {waiternow.common.LocationProto.SettingsProto.IQuotaProto|null} [quota] SettingsProto quota
                 * @property {waiternow.common.LocationProto.SettingsProto.IViewSettingsProto|null} [viewSettings] SettingsProto viewSettings
                 * @property {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto|null} [externalLinks] SettingsProto externalLinks
                 * @property {waiternow.common.LocationProto.SettingsProto.IOperationSettingsProto|null} [operationSettings] SettingsProto operationSettings
                 */

                /**
                 * Constructs a new SettingsProto.
                 * @memberof waiternow.common.LocationProto
                 * @classdesc Represents a SettingsProto.
                 * @implements ISettingsProto
                 * @constructor
                 * @param {waiternow.common.LocationProto.ISettingsProto=} [properties] Properties to set
                 */
                function SettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SettingsProto menu.
                 * @member {waiternow.common.IMenuProto|null|undefined} menu
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.menu = null;

                /**
                 * SettingsProto localeSettings.
                 * @member {waiternow.common.LocationProto.SettingsProto.ILocaleSettingsProto|null|undefined} localeSettings
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.localeSettings = null;

                /**
                 * SettingsProto quota.
                 * @member {waiternow.common.LocationProto.SettingsProto.IQuotaProto|null|undefined} quota
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.quota = null;

                /**
                 * SettingsProto viewSettings.
                 * @member {waiternow.common.LocationProto.SettingsProto.IViewSettingsProto|null|undefined} viewSettings
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.viewSettings = null;

                /**
                 * SettingsProto externalLinks.
                 * @member {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto|null|undefined} externalLinks
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.externalLinks = null;

                /**
                 * SettingsProto operationSettings.
                 * @member {waiternow.common.LocationProto.SettingsProto.IOperationSettingsProto|null|undefined} operationSettings
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.operationSettings = null;

                /**
                 * Creates a new SettingsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {waiternow.common.LocationProto.ISettingsProto=} [properties] Properties to set
                 * @returns {waiternow.common.LocationProto.SettingsProto} SettingsProto instance
                 */
                SettingsProto.create = function create(properties) {
                    return new SettingsProto(properties);
                };

                /**
                 * Encodes the specified SettingsProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {waiternow.common.LocationProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.MenuProto.encode(message.menu, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.localeSettings != null && Object.hasOwnProperty.call(message, "localeSettings"))
                        $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto.encode(message.localeSettings, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.quota != null && Object.hasOwnProperty.call(message, "quota"))
                        $root.waiternow.common.LocationProto.SettingsProto.QuotaProto.encode(message.quota, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.viewSettings != null && Object.hasOwnProperty.call(message, "viewSettings"))
                        $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.encode(message.viewSettings, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.externalLinks != null && Object.hasOwnProperty.call(message, "externalLinks"))
                        $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.encode(message.externalLinks, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.operationSettings != null && Object.hasOwnProperty.call(message, "operationSettings"))
                        $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.encode(message.operationSettings, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SettingsProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {waiternow.common.LocationProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.LocationProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.menu = $root.waiternow.common.MenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.localeSettings = $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.quota = $root.waiternow.common.LocationProto.SettingsProto.QuotaProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.viewSettings = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.operationSettings = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.LocationProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SettingsProto message.
                 * @function verify
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.MenuProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    if (message.localeSettings != null && message.hasOwnProperty("localeSettings")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto.verify(message.localeSettings);
                        if (error)
                            return "localeSettings." + error;
                    }
                    if (message.quota != null && message.hasOwnProperty("quota")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.QuotaProto.verify(message.quota);
                        if (error)
                            return "quota." + error;
                    }
                    if (message.viewSettings != null && message.hasOwnProperty("viewSettings")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.verify(message.viewSettings);
                        if (error)
                            return "viewSettings." + error;
                    }
                    if (message.externalLinks != null && message.hasOwnProperty("externalLinks")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.verify(message.externalLinks);
                        if (error)
                            return "externalLinks." + error;
                    }
                    if (message.operationSettings != null && message.hasOwnProperty("operationSettings")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.verify(message.operationSettings);
                        if (error)
                            return "operationSettings." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.LocationProto.SettingsProto} SettingsProto
                 */
                SettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.LocationProto.SettingsProto)
                        return object;
                    var message = new $root.waiternow.common.LocationProto.SettingsProto();
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.LocationProto.SettingsProto.menu: object expected");
                        message.menu = $root.waiternow.common.MenuProto.fromObject(object.menu);
                    }
                    if (object.localeSettings != null) {
                        if (typeof object.localeSettings !== "object")
                            throw TypeError(".waiternow.common.LocationProto.SettingsProto.localeSettings: object expected");
                        message.localeSettings = $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto.fromObject(object.localeSettings);
                    }
                    if (object.quota != null) {
                        if (typeof object.quota !== "object")
                            throw TypeError(".waiternow.common.LocationProto.SettingsProto.quota: object expected");
                        message.quota = $root.waiternow.common.LocationProto.SettingsProto.QuotaProto.fromObject(object.quota);
                    }
                    if (object.viewSettings != null) {
                        if (typeof object.viewSettings !== "object")
                            throw TypeError(".waiternow.common.LocationProto.SettingsProto.viewSettings: object expected");
                        message.viewSettings = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.fromObject(object.viewSettings);
                    }
                    if (object.externalLinks != null) {
                        if (typeof object.externalLinks !== "object")
                            throw TypeError(".waiternow.common.LocationProto.SettingsProto.externalLinks: object expected");
                        message.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.fromObject(object.externalLinks);
                    }
                    if (object.operationSettings != null) {
                        if (typeof object.operationSettings !== "object")
                            throw TypeError(".waiternow.common.LocationProto.SettingsProto.operationSettings: object expected");
                        message.operationSettings = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.fromObject(object.operationSettings);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {waiternow.common.LocationProto.SettingsProto} message SettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.menu = null;
                        object.localeSettings = null;
                        object.quota = null;
                        object.viewSettings = null;
                        object.externalLinks = null;
                        object.operationSettings = null;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.MenuProto.toObject(message.menu, options);
                    if (message.localeSettings != null && message.hasOwnProperty("localeSettings"))
                        object.localeSettings = $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto.toObject(message.localeSettings, options);
                    if (message.quota != null && message.hasOwnProperty("quota"))
                        object.quota = $root.waiternow.common.LocationProto.SettingsProto.QuotaProto.toObject(message.quota, options);
                    if (message.viewSettings != null && message.hasOwnProperty("viewSettings"))
                        object.viewSettings = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.toObject(message.viewSettings, options);
                    if (message.externalLinks != null && message.hasOwnProperty("externalLinks"))
                        object.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.toObject(message.externalLinks, options);
                    if (message.operationSettings != null && message.hasOwnProperty("operationSettings"))
                        object.operationSettings = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.toObject(message.operationSettings, options);
                    return object;
                };

                /**
                 * Converts this SettingsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SettingsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.LocationProto.SettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto";
                };

                SettingsProto.LocaleSettingsProto = (function() {

                    /**
                     * Properties of a LocaleSettingsProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @interface ILocaleSettingsProto
                     * @property {waiternow.common.Language|null} [language] LocaleSettingsProto language
                     * @property {string|null} [currencyCode] LocaleSettingsProto currencyCode
                     * @property {string|null} [timeZone] LocaleSettingsProto timeZone
                     */

                    /**
                     * Constructs a new LocaleSettingsProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @classdesc Represents a LocaleSettingsProto.
                     * @implements ILocaleSettingsProto
                     * @constructor
                     * @param {waiternow.common.LocationProto.SettingsProto.ILocaleSettingsProto=} [properties] Properties to set
                     */
                    function LocaleSettingsProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * LocaleSettingsProto language.
                     * @member {waiternow.common.Language} language
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @instance
                     */
                    LocaleSettingsProto.prototype.language = 0;

                    /**
                     * LocaleSettingsProto currencyCode.
                     * @member {string} currencyCode
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @instance
                     */
                    LocaleSettingsProto.prototype.currencyCode = "";

                    /**
                     * LocaleSettingsProto timeZone.
                     * @member {string} timeZone
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @instance
                     */
                    LocaleSettingsProto.prototype.timeZone = "";

                    /**
                     * Creates a new LocaleSettingsProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.ILocaleSettingsProto=} [properties] Properties to set
                     * @returns {waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto} LocaleSettingsProto instance
                     */
                    LocaleSettingsProto.create = function create(properties) {
                        return new LocaleSettingsProto(properties);
                    };

                    /**
                     * Encodes the specified LocaleSettingsProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.ILocaleSettingsProto} message LocaleSettingsProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LocaleSettingsProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.language);
                        if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                        if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.timeZone);
                        return writer;
                    };

                    /**
                     * Encodes the specified LocaleSettingsProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.ILocaleSettingsProto} message LocaleSettingsProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    LocaleSettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a LocaleSettingsProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto} LocaleSettingsProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LocaleSettingsProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.language = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.currencyCode = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.timeZone = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a LocaleSettingsProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto} LocaleSettingsProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    LocaleSettingsProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a LocaleSettingsProto message.
                     * @function verify
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    LocaleSettingsProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.language != null && message.hasOwnProperty("language"))
                            switch (message.language) {
                            default:
                                return "language: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            if (!$util.isString(message.currencyCode))
                                return "currencyCode: string expected";
                        if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                            if (!$util.isString(message.timeZone))
                                return "timeZone: string expected";
                        return null;
                    };

                    /**
                     * Creates a LocaleSettingsProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto} LocaleSettingsProto
                     */
                    LocaleSettingsProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto)
                            return object;
                        var message = new $root.waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto();
                        switch (object.language) {
                        default:
                            if (typeof object.language === "number") {
                                message.language = object.language;
                                break;
                            }
                            break;
                        case "LANGUAGE_UNSPECIFIED":
                        case 0:
                            message.language = 0;
                            break;
                        case "ENGLISH":
                        case 1:
                            message.language = 1;
                            break;
                        case "SPANISH":
                        case 2:
                            message.language = 2;
                            break;
                        }
                        if (object.currencyCode != null)
                            message.currencyCode = String(object.currencyCode);
                        if (object.timeZone != null)
                            message.timeZone = String(object.timeZone);
                        return message;
                    };

                    /**
                     * Creates a plain object from a LocaleSettingsProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto} message LocaleSettingsProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    LocaleSettingsProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                            object.currencyCode = "";
                            object.timeZone = "";
                        }
                        if (message.language != null && message.hasOwnProperty("language"))
                            object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                        if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                            object.currencyCode = message.currencyCode;
                        if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                            object.timeZone = message.timeZone;
                        return object;
                    };

                    /**
                     * Converts this LocaleSettingsProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    LocaleSettingsProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for LocaleSettingsProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    LocaleSettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.LocaleSettingsProto";
                    };

                    return LocaleSettingsProto;
                })();

                SettingsProto.QuotaProto = (function() {

                    /**
                     * Properties of a QuotaProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @interface IQuotaProto
                     * @property {waiternow.common.IMoneyProto|null} [monthlyBudget] QuotaProto monthlyBudget
                     */

                    /**
                     * Constructs a new QuotaProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @classdesc Represents a QuotaProto.
                     * @implements IQuotaProto
                     * @constructor
                     * @param {waiternow.common.LocationProto.SettingsProto.IQuotaProto=} [properties] Properties to set
                     */
                    function QuotaProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * QuotaProto monthlyBudget.
                     * @member {waiternow.common.IMoneyProto|null|undefined} monthlyBudget
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @instance
                     */
                    QuotaProto.prototype.monthlyBudget = null;

                    /**
                     * Creates a new QuotaProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IQuotaProto=} [properties] Properties to set
                     * @returns {waiternow.common.LocationProto.SettingsProto.QuotaProto} QuotaProto instance
                     */
                    QuotaProto.create = function create(properties) {
                        return new QuotaProto(properties);
                    };

                    /**
                     * Encodes the specified QuotaProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.QuotaProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IQuotaProto} message QuotaProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QuotaProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.monthlyBudget != null && Object.hasOwnProperty.call(message, "monthlyBudget"))
                            $root.waiternow.common.MoneyProto.encode(message.monthlyBudget, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified QuotaProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.QuotaProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IQuotaProto} message QuotaProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    QuotaProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a QuotaProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.LocationProto.SettingsProto.QuotaProto} QuotaProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QuotaProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.QuotaProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.monthlyBudget = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a QuotaProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.LocationProto.SettingsProto.QuotaProto} QuotaProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    QuotaProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a QuotaProto message.
                     * @function verify
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    QuotaProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.monthlyBudget != null && message.hasOwnProperty("monthlyBudget")) {
                            var error = $root.waiternow.common.MoneyProto.verify(message.monthlyBudget);
                            if (error)
                                return "monthlyBudget." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a QuotaProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.LocationProto.SettingsProto.QuotaProto} QuotaProto
                     */
                    QuotaProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.QuotaProto)
                            return object;
                        var message = new $root.waiternow.common.LocationProto.SettingsProto.QuotaProto();
                        if (object.monthlyBudget != null) {
                            if (typeof object.monthlyBudget !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.QuotaProto.monthlyBudget: object expected");
                            message.monthlyBudget = $root.waiternow.common.MoneyProto.fromObject(object.monthlyBudget);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a QuotaProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.QuotaProto} message QuotaProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    QuotaProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults)
                            object.monthlyBudget = null;
                        if (message.monthlyBudget != null && message.hasOwnProperty("monthlyBudget"))
                            object.monthlyBudget = $root.waiternow.common.MoneyProto.toObject(message.monthlyBudget, options);
                        return object;
                    };

                    /**
                     * Converts this QuotaProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    QuotaProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for QuotaProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.LocationProto.SettingsProto.QuotaProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    QuotaProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.QuotaProto";
                    };

                    return QuotaProto;
                })();

                SettingsProto.ViewSettingsProto = (function() {

                    /**
                     * Properties of a ViewSettingsProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @interface IViewSettingsProto
                     * @property {waiternow.common.Theme|null} [theme] ViewSettingsProto theme
                     * @property {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode|null} [appMode] ViewSettingsProto appMode
                     * @property {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto|null} [colorPalette] ViewSettingsProto colorPalette
                     */

                    /**
                     * Constructs a new ViewSettingsProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @classdesc Represents a ViewSettingsProto.
                     * @implements IViewSettingsProto
                     * @constructor
                     * @param {waiternow.common.LocationProto.SettingsProto.IViewSettingsProto=} [properties] Properties to set
                     */
                    function ViewSettingsProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ViewSettingsProto theme.
                     * @member {waiternow.common.Theme} theme
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @instance
                     */
                    ViewSettingsProto.prototype.theme = 0;

                    /**
                     * ViewSettingsProto appMode.
                     * @member {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode} appMode
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @instance
                     */
                    ViewSettingsProto.prototype.appMode = 0;

                    /**
                     * ViewSettingsProto colorPalette.
                     * @member {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto|null|undefined} colorPalette
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @instance
                     */
                    ViewSettingsProto.prototype.colorPalette = null;

                    /**
                     * Creates a new ViewSettingsProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IViewSettingsProto=} [properties] Properties to set
                     * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto} ViewSettingsProto instance
                     */
                    ViewSettingsProto.create = function create(properties) {
                        return new ViewSettingsProto(properties);
                    };

                    /**
                     * Encodes the specified ViewSettingsProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IViewSettingsProto} message ViewSettingsProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ViewSettingsProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.theme != null && Object.hasOwnProperty.call(message, "theme"))
                            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.theme);
                        if (message.appMode != null && Object.hasOwnProperty.call(message, "appMode"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.appMode);
                        if (message.colorPalette != null && Object.hasOwnProperty.call(message, "colorPalette"))
                            $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.encode(message.colorPalette, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified ViewSettingsProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IViewSettingsProto} message ViewSettingsProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ViewSettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a ViewSettingsProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto} ViewSettingsProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ViewSettingsProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.theme = reader.int32();
                                    break;
                                }
                            case 2: {
                                    message.appMode = reader.int32();
                                    break;
                                }
                            case 3: {
                                    message.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a ViewSettingsProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto} ViewSettingsProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ViewSettingsProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a ViewSettingsProto message.
                     * @function verify
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ViewSettingsProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.theme != null && message.hasOwnProperty("theme"))
                            switch (message.theme) {
                            default:
                                return "theme: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.appMode != null && message.hasOwnProperty("appMode"))
                            switch (message.appMode) {
                            default:
                                return "appMode: enum value expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                        if (message.colorPalette != null && message.hasOwnProperty("colorPalette")) {
                            var error = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.verify(message.colorPalette);
                            if (error)
                                return "colorPalette." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates a ViewSettingsProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto} ViewSettingsProto
                     */
                    ViewSettingsProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto)
                            return object;
                        var message = new $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto();
                        switch (object.theme) {
                        default:
                            if (typeof object.theme === "number") {
                                message.theme = object.theme;
                                break;
                            }
                            break;
                        case "THEME_UNSPECIFIED":
                        case 0:
                            message.theme = 0;
                            break;
                        case "DARK":
                        case 1:
                            message.theme = 1;
                            break;
                        case "LIGHT":
                        case 2:
                            message.theme = 2;
                            break;
                        }
                        switch (object.appMode) {
                        default:
                            if (typeof object.appMode === "number") {
                                message.appMode = object.appMode;
                                break;
                            }
                            break;
                        case "APP_MODE_UNSPECIFIED":
                        case 0:
                            message.appMode = 0;
                            break;
                        case "FULL":
                        case 1:
                            message.appMode = 1;
                            break;
                        case "REQUEST_WAITER_ONLY":
                        case 2:
                            message.appMode = 2;
                            break;
                        }
                        if (object.colorPalette != null) {
                            if (typeof object.colorPalette !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.colorPalette: object expected");
                            message.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.fromObject(object.colorPalette);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from a ViewSettingsProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto} message ViewSettingsProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ViewSettingsProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.theme = options.enums === String ? "THEME_UNSPECIFIED" : 0;
                            object.appMode = options.enums === String ? "APP_MODE_UNSPECIFIED" : 0;
                            object.colorPalette = null;
                        }
                        if (message.theme != null && message.hasOwnProperty("theme"))
                            object.theme = options.enums === String ? $root.waiternow.common.Theme[message.theme] === undefined ? message.theme : $root.waiternow.common.Theme[message.theme] : message.theme;
                        if (message.appMode != null && message.hasOwnProperty("appMode"))
                            object.appMode = options.enums === String ? $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode[message.appMode] === undefined ? message.appMode : $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode[message.appMode] : message.appMode;
                        if (message.colorPalette != null && message.hasOwnProperty("colorPalette"))
                            object.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.toObject(message.colorPalette, options);
                        return object;
                    };

                    /**
                     * Converts this ViewSettingsProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ViewSettingsProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for ViewSettingsProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ViewSettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.ViewSettingsProto";
                    };

                    /**
                     * AppMode enum.
                     * @name waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode
                     * @enum {number}
                     * @property {number} APP_MODE_UNSPECIFIED=0 APP_MODE_UNSPECIFIED value
                     * @property {number} FULL=1 FULL value
                     * @property {number} REQUEST_WAITER_ONLY=2 REQUEST_WAITER_ONLY value
                     */
                    ViewSettingsProto.AppMode = (function() {
                        var valuesById = {}, values = Object.create(valuesById);
                        values[valuesById[0] = "APP_MODE_UNSPECIFIED"] = 0;
                        values[valuesById[1] = "FULL"] = 1;
                        values[valuesById[2] = "REQUEST_WAITER_ONLY"] = 2;
                        return values;
                    })();

                    ViewSettingsProto.ColorPaletteProto = (function() {

                        /**
                         * Properties of a ColorPaletteProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                         * @interface IColorPaletteProto
                         * @property {string|null} [background] ColorPaletteProto background
                         * @property {string|null} [contrast] ColorPaletteProto contrast
                         * @property {string|null} [buttonBackground] ColorPaletteProto buttonBackground
                         * @property {string|null} [iconLine] ColorPaletteProto iconLine
                         * @property {string|null} [disabledIconLine] ColorPaletteProto disabledIconLine
                         * @property {string|null} [buttonStatusLine] ColorPaletteProto buttonStatusLine
                         * @property {string|null} [warningMessage] ColorPaletteProto warningMessage
                         */

                        /**
                         * Constructs a new ColorPaletteProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto
                         * @classdesc Represents a ColorPaletteProto.
                         * @implements IColorPaletteProto
                         * @constructor
                         * @param {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto=} [properties] Properties to set
                         */
                        function ColorPaletteProto(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * ColorPaletteProto background.
                         * @member {string} background
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         */
                        ColorPaletteProto.prototype.background = "";

                        /**
                         * ColorPaletteProto contrast.
                         * @member {string} contrast
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         */
                        ColorPaletteProto.prototype.contrast = "";

                        /**
                         * ColorPaletteProto buttonBackground.
                         * @member {string} buttonBackground
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         */
                        ColorPaletteProto.prototype.buttonBackground = "";

                        /**
                         * ColorPaletteProto iconLine.
                         * @member {string} iconLine
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         */
                        ColorPaletteProto.prototype.iconLine = "";

                        /**
                         * ColorPaletteProto disabledIconLine.
                         * @member {string} disabledIconLine
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         */
                        ColorPaletteProto.prototype.disabledIconLine = "";

                        /**
                         * ColorPaletteProto buttonStatusLine.
                         * @member {string} buttonStatusLine
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         */
                        ColorPaletteProto.prototype.buttonStatusLine = "";

                        /**
                         * ColorPaletteProto warningMessage.
                         * @member {string} warningMessage
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         */
                        ColorPaletteProto.prototype.warningMessage = "";

                        /**
                         * Creates a new ColorPaletteProto instance using the specified properties.
                         * @function create
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto=} [properties] Properties to set
                         * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto} ColorPaletteProto instance
                         */
                        ColorPaletteProto.create = function create(properties) {
                            return new ColorPaletteProto(properties);
                        };

                        /**
                         * Encodes the specified ColorPaletteProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.verify|verify} messages.
                         * @function encode
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto} message ColorPaletteProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ColorPaletteProto.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.background != null && Object.hasOwnProperty.call(message, "background"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.background);
                            if (message.contrast != null && Object.hasOwnProperty.call(message, "contrast"))
                                writer.uint32(/* id 2, wireType 2 =*/18).string(message.contrast);
                            if (message.buttonBackground != null && Object.hasOwnProperty.call(message, "buttonBackground"))
                                writer.uint32(/* id 3, wireType 2 =*/26).string(message.buttonBackground);
                            if (message.iconLine != null && Object.hasOwnProperty.call(message, "iconLine"))
                                writer.uint32(/* id 4, wireType 2 =*/34).string(message.iconLine);
                            if (message.disabledIconLine != null && Object.hasOwnProperty.call(message, "disabledIconLine"))
                                writer.uint32(/* id 5, wireType 2 =*/42).string(message.disabledIconLine);
                            if (message.buttonStatusLine != null && Object.hasOwnProperty.call(message, "buttonStatusLine"))
                                writer.uint32(/* id 6, wireType 2 =*/50).string(message.buttonStatusLine);
                            if (message.warningMessage != null && Object.hasOwnProperty.call(message, "warningMessage"))
                                writer.uint32(/* id 7, wireType 2 =*/58).string(message.warningMessage);
                            return writer;
                        };

                        /**
                         * Encodes the specified ColorPaletteProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto} message ColorPaletteProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        ColorPaletteProto.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a ColorPaletteProto message from the specified reader or buffer.
                         * @function decode
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto} ColorPaletteProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ColorPaletteProto.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.background = reader.string();
                                        break;
                                    }
                                case 2: {
                                        message.contrast = reader.string();
                                        break;
                                    }
                                case 3: {
                                        message.buttonBackground = reader.string();
                                        break;
                                    }
                                case 4: {
                                        message.iconLine = reader.string();
                                        break;
                                    }
                                case 5: {
                                        message.disabledIconLine = reader.string();
                                        break;
                                    }
                                case 6: {
                                        message.buttonStatusLine = reader.string();
                                        break;
                                    }
                                case 7: {
                                        message.warningMessage = reader.string();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a ColorPaletteProto message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto} ColorPaletteProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        ColorPaletteProto.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a ColorPaletteProto message.
                         * @function verify
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        ColorPaletteProto.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.background != null && message.hasOwnProperty("background"))
                                if (!$util.isString(message.background))
                                    return "background: string expected";
                            if (message.contrast != null && message.hasOwnProperty("contrast"))
                                if (!$util.isString(message.contrast))
                                    return "contrast: string expected";
                            if (message.buttonBackground != null && message.hasOwnProperty("buttonBackground"))
                                if (!$util.isString(message.buttonBackground))
                                    return "buttonBackground: string expected";
                            if (message.iconLine != null && message.hasOwnProperty("iconLine"))
                                if (!$util.isString(message.iconLine))
                                    return "iconLine: string expected";
                            if (message.disabledIconLine != null && message.hasOwnProperty("disabledIconLine"))
                                if (!$util.isString(message.disabledIconLine))
                                    return "disabledIconLine: string expected";
                            if (message.buttonStatusLine != null && message.hasOwnProperty("buttonStatusLine"))
                                if (!$util.isString(message.buttonStatusLine))
                                    return "buttonStatusLine: string expected";
                            if (message.warningMessage != null && message.hasOwnProperty("warningMessage"))
                                if (!$util.isString(message.warningMessage))
                                    return "warningMessage: string expected";
                            return null;
                        };

                        /**
                         * Creates a ColorPaletteProto message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto} ColorPaletteProto
                         */
                        ColorPaletteProto.fromObject = function fromObject(object) {
                            if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto)
                                return object;
                            var message = new $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto();
                            if (object.background != null)
                                message.background = String(object.background);
                            if (object.contrast != null)
                                message.contrast = String(object.contrast);
                            if (object.buttonBackground != null)
                                message.buttonBackground = String(object.buttonBackground);
                            if (object.iconLine != null)
                                message.iconLine = String(object.iconLine);
                            if (object.disabledIconLine != null)
                                message.disabledIconLine = String(object.disabledIconLine);
                            if (object.buttonStatusLine != null)
                                message.buttonStatusLine = String(object.buttonStatusLine);
                            if (object.warningMessage != null)
                                message.warningMessage = String(object.warningMessage);
                            return message;
                        };

                        /**
                         * Creates a plain object from a ColorPaletteProto message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto} message ColorPaletteProto
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        ColorPaletteProto.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.background = "";
                                object.contrast = "";
                                object.buttonBackground = "";
                                object.iconLine = "";
                                object.disabledIconLine = "";
                                object.buttonStatusLine = "";
                                object.warningMessage = "";
                            }
                            if (message.background != null && message.hasOwnProperty("background"))
                                object.background = message.background;
                            if (message.contrast != null && message.hasOwnProperty("contrast"))
                                object.contrast = message.contrast;
                            if (message.buttonBackground != null && message.hasOwnProperty("buttonBackground"))
                                object.buttonBackground = message.buttonBackground;
                            if (message.iconLine != null && message.hasOwnProperty("iconLine"))
                                object.iconLine = message.iconLine;
                            if (message.disabledIconLine != null && message.hasOwnProperty("disabledIconLine"))
                                object.disabledIconLine = message.disabledIconLine;
                            if (message.buttonStatusLine != null && message.hasOwnProperty("buttonStatusLine"))
                                object.buttonStatusLine = message.buttonStatusLine;
                            if (message.warningMessage != null && message.hasOwnProperty("warningMessage"))
                                object.warningMessage = message.warningMessage;
                            return object;
                        };

                        /**
                         * Converts this ColorPaletteProto to JSON.
                         * @function toJSON
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        ColorPaletteProto.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for ColorPaletteProto
                         * @function getTypeUrl
                         * @memberof waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        ColorPaletteProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto";
                        };

                        return ColorPaletteProto;
                    })();

                    return ViewSettingsProto;
                })();

                SettingsProto.ExternalLinksProto = (function() {

                    /**
                     * Properties of an ExternalLinksProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @interface IExternalLinksProto
                     * @property {string|null} [website] ExternalLinksProto website
                     * @property {string|null} [youtube] ExternalLinksProto youtube
                     * @property {string|null} [facebook] ExternalLinksProto facebook
                     * @property {string|null} [instagram] ExternalLinksProto instagram
                     * @property {string|null} [twitter] ExternalLinksProto twitter
                     * @property {string|null} [linkedIn] ExternalLinksProto linkedIn
                     * @property {string|null} [tiktok] ExternalLinksProto tiktok
                     */

                    /**
                     * Constructs a new ExternalLinksProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @classdesc Represents an ExternalLinksProto.
                     * @implements IExternalLinksProto
                     * @constructor
                     * @param {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto=} [properties] Properties to set
                     */
                    function ExternalLinksProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * ExternalLinksProto website.
                     * @member {string} website
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     */
                    ExternalLinksProto.prototype.website = "";

                    /**
                     * ExternalLinksProto youtube.
                     * @member {string} youtube
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     */
                    ExternalLinksProto.prototype.youtube = "";

                    /**
                     * ExternalLinksProto facebook.
                     * @member {string} facebook
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     */
                    ExternalLinksProto.prototype.facebook = "";

                    /**
                     * ExternalLinksProto instagram.
                     * @member {string} instagram
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     */
                    ExternalLinksProto.prototype.instagram = "";

                    /**
                     * ExternalLinksProto twitter.
                     * @member {string} twitter
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     */
                    ExternalLinksProto.prototype.twitter = "";

                    /**
                     * ExternalLinksProto linkedIn.
                     * @member {string} linkedIn
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     */
                    ExternalLinksProto.prototype.linkedIn = "";

                    /**
                     * ExternalLinksProto tiktok.
                     * @member {string} tiktok
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     */
                    ExternalLinksProto.prototype.tiktok = "";

                    /**
                     * Creates a new ExternalLinksProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto=} [properties] Properties to set
                     * @returns {waiternow.common.LocationProto.SettingsProto.ExternalLinksProto} ExternalLinksProto instance
                     */
                    ExternalLinksProto.create = function create(properties) {
                        return new ExternalLinksProto(properties);
                    };

                    /**
                     * Encodes the specified ExternalLinksProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto} message ExternalLinksProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExternalLinksProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.website != null && Object.hasOwnProperty.call(message, "website"))
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.website);
                        if (message.youtube != null && Object.hasOwnProperty.call(message, "youtube"))
                            writer.uint32(/* id 2, wireType 2 =*/18).string(message.youtube);
                        if (message.facebook != null && Object.hasOwnProperty.call(message, "facebook"))
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.facebook);
                        if (message.instagram != null && Object.hasOwnProperty.call(message, "instagram"))
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.instagram);
                        if (message.twitter != null && Object.hasOwnProperty.call(message, "twitter"))
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.twitter);
                        if (message.linkedIn != null && Object.hasOwnProperty.call(message, "linkedIn"))
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.linkedIn);
                        if (message.tiktok != null && Object.hasOwnProperty.call(message, "tiktok"))
                            writer.uint32(/* id 7, wireType 2 =*/58).string(message.tiktok);
                        return writer;
                    };

                    /**
                     * Encodes the specified ExternalLinksProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto} message ExternalLinksProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    ExternalLinksProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an ExternalLinksProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.LocationProto.SettingsProto.ExternalLinksProto} ExternalLinksProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExternalLinksProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.website = reader.string();
                                    break;
                                }
                            case 2: {
                                    message.youtube = reader.string();
                                    break;
                                }
                            case 3: {
                                    message.facebook = reader.string();
                                    break;
                                }
                            case 4: {
                                    message.instagram = reader.string();
                                    break;
                                }
                            case 5: {
                                    message.twitter = reader.string();
                                    break;
                                }
                            case 6: {
                                    message.linkedIn = reader.string();
                                    break;
                                }
                            case 7: {
                                    message.tiktok = reader.string();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an ExternalLinksProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.LocationProto.SettingsProto.ExternalLinksProto} ExternalLinksProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    ExternalLinksProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an ExternalLinksProto message.
                     * @function verify
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    ExternalLinksProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.website != null && message.hasOwnProperty("website"))
                            if (!$util.isString(message.website))
                                return "website: string expected";
                        if (message.youtube != null && message.hasOwnProperty("youtube"))
                            if (!$util.isString(message.youtube))
                                return "youtube: string expected";
                        if (message.facebook != null && message.hasOwnProperty("facebook"))
                            if (!$util.isString(message.facebook))
                                return "facebook: string expected";
                        if (message.instagram != null && message.hasOwnProperty("instagram"))
                            if (!$util.isString(message.instagram))
                                return "instagram: string expected";
                        if (message.twitter != null && message.hasOwnProperty("twitter"))
                            if (!$util.isString(message.twitter))
                                return "twitter: string expected";
                        if (message.linkedIn != null && message.hasOwnProperty("linkedIn"))
                            if (!$util.isString(message.linkedIn))
                                return "linkedIn: string expected";
                        if (message.tiktok != null && message.hasOwnProperty("tiktok"))
                            if (!$util.isString(message.tiktok))
                                return "tiktok: string expected";
                        return null;
                    };

                    /**
                     * Creates an ExternalLinksProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.LocationProto.SettingsProto.ExternalLinksProto} ExternalLinksProto
                     */
                    ExternalLinksProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto)
                            return object;
                        var message = new $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto();
                        if (object.website != null)
                            message.website = String(object.website);
                        if (object.youtube != null)
                            message.youtube = String(object.youtube);
                        if (object.facebook != null)
                            message.facebook = String(object.facebook);
                        if (object.instagram != null)
                            message.instagram = String(object.instagram);
                        if (object.twitter != null)
                            message.twitter = String(object.twitter);
                        if (object.linkedIn != null)
                            message.linkedIn = String(object.linkedIn);
                        if (object.tiktok != null)
                            message.tiktok = String(object.tiktok);
                        return message;
                    };

                    /**
                     * Creates a plain object from an ExternalLinksProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.ExternalLinksProto} message ExternalLinksProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    ExternalLinksProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.website = "";
                            object.youtube = "";
                            object.facebook = "";
                            object.instagram = "";
                            object.twitter = "";
                            object.linkedIn = "";
                            object.tiktok = "";
                        }
                        if (message.website != null && message.hasOwnProperty("website"))
                            object.website = message.website;
                        if (message.youtube != null && message.hasOwnProperty("youtube"))
                            object.youtube = message.youtube;
                        if (message.facebook != null && message.hasOwnProperty("facebook"))
                            object.facebook = message.facebook;
                        if (message.instagram != null && message.hasOwnProperty("instagram"))
                            object.instagram = message.instagram;
                        if (message.twitter != null && message.hasOwnProperty("twitter"))
                            object.twitter = message.twitter;
                        if (message.linkedIn != null && message.hasOwnProperty("linkedIn"))
                            object.linkedIn = message.linkedIn;
                        if (message.tiktok != null && message.hasOwnProperty("tiktok"))
                            object.tiktok = message.tiktok;
                        return object;
                    };

                    /**
                     * Converts this ExternalLinksProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    ExternalLinksProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for ExternalLinksProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.LocationProto.SettingsProto.ExternalLinksProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    ExternalLinksProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.ExternalLinksProto";
                    };

                    return ExternalLinksProto;
                })();

                SettingsProto.OperationSettingsProto = (function() {

                    /**
                     * Properties of an OperationSettingsProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @interface IOperationSettingsProto
                     * @property {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto|null} [enabledFeatures] OperationSettingsProto enabledFeatures
                     * @property {waiternow.common.ITaxesProto|null} [taxes] OperationSettingsProto taxes
                     * @property {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IStripeAccountProto|null} [stripeAccount] OperationSettingsProto stripeAccount
                     * @property {Array.<waiternow.common.IPrinterProto>|null} [printers] OperationSettingsProto printers
                     * @property {waiternow.common.IDerivedQuantityConfigProto|null} [orderPaymentWaiterNowFee] OperationSettingsProto orderPaymentWaiterNowFee
                     * @property {waiternow.common.IBusinessHoursProto|null} [businessHours] OperationSettingsProto businessHours
                     * @property {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto|null} [alertConfig] OperationSettingsProto alertConfig
                     * @property {google.protobuf.IDuration|null} [orderPreparationDuration] OperationSettingsProto orderPreparationDuration
                     * @property {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IDeliveryConfigProto|null} [deliveryConfig] OperationSettingsProto deliveryConfig
                     */

                    /**
                     * Constructs a new OperationSettingsProto.
                     * @memberof waiternow.common.LocationProto.SettingsProto
                     * @classdesc Represents an OperationSettingsProto.
                     * @implements IOperationSettingsProto
                     * @constructor
                     * @param {waiternow.common.LocationProto.SettingsProto.IOperationSettingsProto=} [properties] Properties to set
                     */
                    function OperationSettingsProto(properties) {
                        this.printers = [];
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * OperationSettingsProto enabledFeatures.
                     * @member {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto|null|undefined} enabledFeatures
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.enabledFeatures = null;

                    /**
                     * OperationSettingsProto taxes.
                     * @member {waiternow.common.ITaxesProto|null|undefined} taxes
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.taxes = null;

                    /**
                     * OperationSettingsProto stripeAccount.
                     * @member {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IStripeAccountProto|null|undefined} stripeAccount
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.stripeAccount = null;

                    /**
                     * OperationSettingsProto printers.
                     * @member {Array.<waiternow.common.IPrinterProto>} printers
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.printers = $util.emptyArray;

                    /**
                     * OperationSettingsProto orderPaymentWaiterNowFee.
                     * @member {waiternow.common.IDerivedQuantityConfigProto|null|undefined} orderPaymentWaiterNowFee
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.orderPaymentWaiterNowFee = null;

                    /**
                     * OperationSettingsProto businessHours.
                     * @member {waiternow.common.IBusinessHoursProto|null|undefined} businessHours
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.businessHours = null;

                    /**
                     * OperationSettingsProto alertConfig.
                     * @member {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto|null|undefined} alertConfig
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.alertConfig = null;

                    /**
                     * OperationSettingsProto orderPreparationDuration.
                     * @member {google.protobuf.IDuration|null|undefined} orderPreparationDuration
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.orderPreparationDuration = null;

                    /**
                     * OperationSettingsProto deliveryConfig.
                     * @member {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IDeliveryConfigProto|null|undefined} deliveryConfig
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     */
                    OperationSettingsProto.prototype.deliveryConfig = null;

                    /**
                     * Creates a new OperationSettingsProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IOperationSettingsProto=} [properties] Properties to set
                     * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto} OperationSettingsProto instance
                     */
                    OperationSettingsProto.create = function create(properties) {
                        return new OperationSettingsProto(properties);
                    };

                    /**
                     * Encodes the specified OperationSettingsProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IOperationSettingsProto} message OperationSettingsProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OperationSettingsProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.enabledFeatures != null && Object.hasOwnProperty.call(message, "enabledFeatures"))
                            $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.encode(message.enabledFeatures, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                        if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                            $root.waiternow.common.TaxesProto.encode(message.taxes, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                        if (message.stripeAccount != null && Object.hasOwnProperty.call(message, "stripeAccount"))
                            $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.encode(message.stripeAccount, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                        if (message.printers != null && message.printers.length)
                            for (var i = 0; i < message.printers.length; ++i)
                                $root.waiternow.common.PrinterProto.encode(message.printers[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                        if (message.orderPaymentWaiterNowFee != null && Object.hasOwnProperty.call(message, "orderPaymentWaiterNowFee"))
                            $root.waiternow.common.DerivedQuantityConfigProto.encode(message.orderPaymentWaiterNowFee, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                        if (message.businessHours != null && Object.hasOwnProperty.call(message, "businessHours"))
                            $root.waiternow.common.BusinessHoursProto.encode(message.businessHours, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                        if (message.alertConfig != null && Object.hasOwnProperty.call(message, "alertConfig"))
                            $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.encode(message.alertConfig, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                        if (message.orderPreparationDuration != null && Object.hasOwnProperty.call(message, "orderPreparationDuration"))
                            $root.google.protobuf.Duration.encode(message.orderPreparationDuration, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                        if (message.deliveryConfig != null && Object.hasOwnProperty.call(message, "deliveryConfig"))
                            $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.encode(message.deliveryConfig, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                        return writer;
                    };

                    /**
                     * Encodes the specified OperationSettingsProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.IOperationSettingsProto} message OperationSettingsProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    OperationSettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes an OperationSettingsProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto} OperationSettingsProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OperationSettingsProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.enabledFeatures = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.decode(reader, reader.uint32());
                                    break;
                                }
                            case 2: {
                                    message.taxes = $root.waiternow.common.TaxesProto.decode(reader, reader.uint32());
                                    break;
                                }
                            case 3: {
                                    message.stripeAccount = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.decode(reader, reader.uint32());
                                    break;
                                }
                            case 4: {
                                    if (!(message.printers && message.printers.length))
                                        message.printers = [];
                                    message.printers.push($root.waiternow.common.PrinterProto.decode(reader, reader.uint32()));
                                    break;
                                }
                            case 5: {
                                    message.orderPaymentWaiterNowFee = $root.waiternow.common.DerivedQuantityConfigProto.decode(reader, reader.uint32());
                                    break;
                                }
                            case 6: {
                                    message.businessHours = $root.waiternow.common.BusinessHoursProto.decode(reader, reader.uint32());
                                    break;
                                }
                            case 7: {
                                    message.alertConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.decode(reader, reader.uint32());
                                    break;
                                }
                            case 8: {
                                    message.orderPreparationDuration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                    break;
                                }
                            case 9: {
                                    message.deliveryConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.decode(reader, reader.uint32());
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes an OperationSettingsProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto} OperationSettingsProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    OperationSettingsProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies an OperationSettingsProto message.
                     * @function verify
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    OperationSettingsProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.enabledFeatures != null && message.hasOwnProperty("enabledFeatures")) {
                            var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.verify(message.enabledFeatures);
                            if (error)
                                return "enabledFeatures." + error;
                        }
                        if (message.taxes != null && message.hasOwnProperty("taxes")) {
                            var error = $root.waiternow.common.TaxesProto.verify(message.taxes);
                            if (error)
                                return "taxes." + error;
                        }
                        if (message.stripeAccount != null && message.hasOwnProperty("stripeAccount")) {
                            var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.verify(message.stripeAccount);
                            if (error)
                                return "stripeAccount." + error;
                        }
                        if (message.printers != null && message.hasOwnProperty("printers")) {
                            if (!Array.isArray(message.printers))
                                return "printers: array expected";
                            for (var i = 0; i < message.printers.length; ++i) {
                                var error = $root.waiternow.common.PrinterProto.verify(message.printers[i]);
                                if (error)
                                    return "printers." + error;
                            }
                        }
                        if (message.orderPaymentWaiterNowFee != null && message.hasOwnProperty("orderPaymentWaiterNowFee")) {
                            var error = $root.waiternow.common.DerivedQuantityConfigProto.verify(message.orderPaymentWaiterNowFee);
                            if (error)
                                return "orderPaymentWaiterNowFee." + error;
                        }
                        if (message.businessHours != null && message.hasOwnProperty("businessHours")) {
                            var error = $root.waiternow.common.BusinessHoursProto.verify(message.businessHours);
                            if (error)
                                return "businessHours." + error;
                        }
                        if (message.alertConfig != null && message.hasOwnProperty("alertConfig")) {
                            var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.verify(message.alertConfig);
                            if (error)
                                return "alertConfig." + error;
                        }
                        if (message.orderPreparationDuration != null && message.hasOwnProperty("orderPreparationDuration")) {
                            var error = $root.google.protobuf.Duration.verify(message.orderPreparationDuration);
                            if (error)
                                return "orderPreparationDuration." + error;
                        }
                        if (message.deliveryConfig != null && message.hasOwnProperty("deliveryConfig")) {
                            var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.verify(message.deliveryConfig);
                            if (error)
                                return "deliveryConfig." + error;
                        }
                        return null;
                    };

                    /**
                     * Creates an OperationSettingsProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto} OperationSettingsProto
                     */
                    OperationSettingsProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto)
                            return object;
                        var message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto();
                        if (object.enabledFeatures != null) {
                            if (typeof object.enabledFeatures !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.enabledFeatures: object expected");
                            message.enabledFeatures = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.fromObject(object.enabledFeatures);
                        }
                        if (object.taxes != null) {
                            if (typeof object.taxes !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.taxes: object expected");
                            message.taxes = $root.waiternow.common.TaxesProto.fromObject(object.taxes);
                        }
                        if (object.stripeAccount != null) {
                            if (typeof object.stripeAccount !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.stripeAccount: object expected");
                            message.stripeAccount = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.fromObject(object.stripeAccount);
                        }
                        if (object.printers) {
                            if (!Array.isArray(object.printers))
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.printers: array expected");
                            message.printers = [];
                            for (var i = 0; i < object.printers.length; ++i) {
                                if (typeof object.printers[i] !== "object")
                                    throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.printers: object expected");
                                message.printers[i] = $root.waiternow.common.PrinterProto.fromObject(object.printers[i]);
                            }
                        }
                        if (object.orderPaymentWaiterNowFee != null) {
                            if (typeof object.orderPaymentWaiterNowFee !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.orderPaymentWaiterNowFee: object expected");
                            message.orderPaymentWaiterNowFee = $root.waiternow.common.DerivedQuantityConfigProto.fromObject(object.orderPaymentWaiterNowFee);
                        }
                        if (object.businessHours != null) {
                            if (typeof object.businessHours !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.businessHours: object expected");
                            message.businessHours = $root.waiternow.common.BusinessHoursProto.fromObject(object.businessHours);
                        }
                        if (object.alertConfig != null) {
                            if (typeof object.alertConfig !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.alertConfig: object expected");
                            message.alertConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.fromObject(object.alertConfig);
                        }
                        if (object.orderPreparationDuration != null) {
                            if (typeof object.orderPreparationDuration !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.orderPreparationDuration: object expected");
                            message.orderPreparationDuration = $root.google.protobuf.Duration.fromObject(object.orderPreparationDuration);
                        }
                        if (object.deliveryConfig != null) {
                            if (typeof object.deliveryConfig !== "object")
                                throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.deliveryConfig: object expected");
                            message.deliveryConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.fromObject(object.deliveryConfig);
                        }
                        return message;
                    };

                    /**
                     * Creates a plain object from an OperationSettingsProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto} message OperationSettingsProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    OperationSettingsProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.arrays || options.defaults)
                            object.printers = [];
                        if (options.defaults) {
                            object.enabledFeatures = null;
                            object.taxes = null;
                            object.stripeAccount = null;
                            object.orderPaymentWaiterNowFee = null;
                            object.businessHours = null;
                            object.alertConfig = null;
                            object.orderPreparationDuration = null;
                            object.deliveryConfig = null;
                        }
                        if (message.enabledFeatures != null && message.hasOwnProperty("enabledFeatures"))
                            object.enabledFeatures = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.toObject(message.enabledFeatures, options);
                        if (message.taxes != null && message.hasOwnProperty("taxes"))
                            object.taxes = $root.waiternow.common.TaxesProto.toObject(message.taxes, options);
                        if (message.stripeAccount != null && message.hasOwnProperty("stripeAccount"))
                            object.stripeAccount = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.toObject(message.stripeAccount, options);
                        if (message.printers && message.printers.length) {
                            object.printers = [];
                            for (var j = 0; j < message.printers.length; ++j)
                                object.printers[j] = $root.waiternow.common.PrinterProto.toObject(message.printers[j], options);
                        }
                        if (message.orderPaymentWaiterNowFee != null && message.hasOwnProperty("orderPaymentWaiterNowFee"))
                            object.orderPaymentWaiterNowFee = $root.waiternow.common.DerivedQuantityConfigProto.toObject(message.orderPaymentWaiterNowFee, options);
                        if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                            object.businessHours = $root.waiternow.common.BusinessHoursProto.toObject(message.businessHours, options);
                        if (message.alertConfig != null && message.hasOwnProperty("alertConfig"))
                            object.alertConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.toObject(message.alertConfig, options);
                        if (message.orderPreparationDuration != null && message.hasOwnProperty("orderPreparationDuration"))
                            object.orderPreparationDuration = $root.google.protobuf.Duration.toObject(message.orderPreparationDuration, options);
                        if (message.deliveryConfig != null && message.hasOwnProperty("deliveryConfig"))
                            object.deliveryConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.toObject(message.deliveryConfig, options);
                        return object;
                    };

                    /**
                     * Converts this OperationSettingsProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    OperationSettingsProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for OperationSettingsProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    OperationSettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.OperationSettingsProto";
                    };

                    OperationSettingsProto.EnabledFeaturesProto = (function() {

                        /**
                         * Properties of an EnabledFeaturesProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @interface IEnabledFeaturesProto
                         * @property {boolean|null} [isPaymentRequiredForOrders] EnabledFeaturesProto isPaymentRequiredForOrders
                         * @property {boolean|null} [isPokeWaiterDisabled] EnabledFeaturesProto isPokeWaiterDisabled
                         * @property {boolean|null} [isStructuredMenuDisabled] EnabledFeaturesProto isStructuredMenuDisabled
                         * @property {boolean|null} [isImageMenuDisabled] EnabledFeaturesProto isImageMenuDisabled
                         * @property {boolean|null} [isMessageWaiterDisabled] EnabledFeaturesProto isMessageWaiterDisabled
                         * @property {boolean|null} [isAskForCheckDisabled] EnabledFeaturesProto isAskForCheckDisabled
                         * @property {boolean|null} [isSpecialInstructionsDisabled] EnabledFeaturesProto isSpecialInstructionsDisabled
                         * @property {boolean|null} [isCompletedStatusSupportedForPaidOrders] EnabledFeaturesProto isCompletedStatusSupportedForPaidOrders
                         * @property {boolean|null} [isOnsiteTipsDisabled] EnabledFeaturesProto isOnsiteTipsDisabled
                         * @property {boolean|null} [isOnlineTipsDisabled] EnabledFeaturesProto isOnlineTipsDisabled
                         * @property {boolean|null} [isDeliveryDisabled] EnabledFeaturesProto isDeliveryDisabled
                         * @property {boolean|null} [chargeCreditCardFeeToConsumer] EnabledFeaturesProto chargeCreditCardFeeToConsumer
                         * @property {boolean|null} [isAlcoholForOnlinePickupAllowed] EnabledFeaturesProto isAlcoholForOnlinePickupAllowed
                         * @property {boolean|null} [isAlcoholForDeliveryAllowed] EnabledFeaturesProto isAlcoholForDeliveryAllowed
                         * @property {boolean|null} [printOrderAndReceipt] EnabledFeaturesProto printOrderAndReceipt
                         * @property {boolean|null} [isOrderForLaterDisabled] EnabledFeaturesProto isOrderForLaterDisabled
                         * @property {boolean|null} [isOrderForLaterDayDisabled] EnabledFeaturesProto isOrderForLaterDayDisabled
                         * @property {boolean|null} [isSmsOnNewOrdersForBusinessWebEnabled] EnabledFeaturesProto isSmsOnNewOrdersForBusinessWebEnabled
                         */

                        /**
                         * Constructs a new EnabledFeaturesProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @classdesc Represents an EnabledFeaturesProto.
                         * @implements IEnabledFeaturesProto
                         * @constructor
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto=} [properties] Properties to set
                         */
                        function EnabledFeaturesProto(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * EnabledFeaturesProto isPaymentRequiredForOrders.
                         * @member {boolean} isPaymentRequiredForOrders
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isPaymentRequiredForOrders = false;

                        /**
                         * EnabledFeaturesProto isPokeWaiterDisabled.
                         * @member {boolean} isPokeWaiterDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isPokeWaiterDisabled = false;

                        /**
                         * EnabledFeaturesProto isStructuredMenuDisabled.
                         * @member {boolean} isStructuredMenuDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isStructuredMenuDisabled = false;

                        /**
                         * EnabledFeaturesProto isImageMenuDisabled.
                         * @member {boolean} isImageMenuDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isImageMenuDisabled = false;

                        /**
                         * EnabledFeaturesProto isMessageWaiterDisabled.
                         * @member {boolean} isMessageWaiterDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isMessageWaiterDisabled = false;

                        /**
                         * EnabledFeaturesProto isAskForCheckDisabled.
                         * @member {boolean} isAskForCheckDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isAskForCheckDisabled = false;

                        /**
                         * EnabledFeaturesProto isSpecialInstructionsDisabled.
                         * @member {boolean} isSpecialInstructionsDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isSpecialInstructionsDisabled = false;

                        /**
                         * EnabledFeaturesProto isCompletedStatusSupportedForPaidOrders.
                         * @member {boolean} isCompletedStatusSupportedForPaidOrders
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isCompletedStatusSupportedForPaidOrders = false;

                        /**
                         * EnabledFeaturesProto isOnsiteTipsDisabled.
                         * @member {boolean} isOnsiteTipsDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isOnsiteTipsDisabled = false;

                        /**
                         * EnabledFeaturesProto isOnlineTipsDisabled.
                         * @member {boolean} isOnlineTipsDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isOnlineTipsDisabled = false;

                        /**
                         * EnabledFeaturesProto isDeliveryDisabled.
                         * @member {boolean} isDeliveryDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isDeliveryDisabled = false;

                        /**
                         * EnabledFeaturesProto chargeCreditCardFeeToConsumer.
                         * @member {boolean} chargeCreditCardFeeToConsumer
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.chargeCreditCardFeeToConsumer = false;

                        /**
                         * EnabledFeaturesProto isAlcoholForOnlinePickupAllowed.
                         * @member {boolean} isAlcoholForOnlinePickupAllowed
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isAlcoholForOnlinePickupAllowed = false;

                        /**
                         * EnabledFeaturesProto isAlcoholForDeliveryAllowed.
                         * @member {boolean} isAlcoholForDeliveryAllowed
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isAlcoholForDeliveryAllowed = false;

                        /**
                         * EnabledFeaturesProto printOrderAndReceipt.
                         * @member {boolean} printOrderAndReceipt
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.printOrderAndReceipt = false;

                        /**
                         * EnabledFeaturesProto isOrderForLaterDisabled.
                         * @member {boolean} isOrderForLaterDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isOrderForLaterDisabled = false;

                        /**
                         * EnabledFeaturesProto isOrderForLaterDayDisabled.
                         * @member {boolean} isOrderForLaterDayDisabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isOrderForLaterDayDisabled = false;

                        /**
                         * EnabledFeaturesProto isSmsOnNewOrdersForBusinessWebEnabled.
                         * @member {boolean} isSmsOnNewOrdersForBusinessWebEnabled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         */
                        EnabledFeaturesProto.prototype.isSmsOnNewOrdersForBusinessWebEnabled = false;

                        /**
                         * Creates a new EnabledFeaturesProto instance using the specified properties.
                         * @function create
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto=} [properties] Properties to set
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto} EnabledFeaturesProto instance
                         */
                        EnabledFeaturesProto.create = function create(properties) {
                            return new EnabledFeaturesProto(properties);
                        };

                        /**
                         * Encodes the specified EnabledFeaturesProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.verify|verify} messages.
                         * @function encode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto} message EnabledFeaturesProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnabledFeaturesProto.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.isPaymentRequiredForOrders != null && Object.hasOwnProperty.call(message, "isPaymentRequiredForOrders"))
                                writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isPaymentRequiredForOrders);
                            if (message.isPokeWaiterDisabled != null && Object.hasOwnProperty.call(message, "isPokeWaiterDisabled"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isPokeWaiterDisabled);
                            if (message.isStructuredMenuDisabled != null && Object.hasOwnProperty.call(message, "isStructuredMenuDisabled"))
                                writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isStructuredMenuDisabled);
                            if (message.isImageMenuDisabled != null && Object.hasOwnProperty.call(message, "isImageMenuDisabled"))
                                writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isImageMenuDisabled);
                            if (message.isMessageWaiterDisabled != null && Object.hasOwnProperty.call(message, "isMessageWaiterDisabled"))
                                writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isMessageWaiterDisabled);
                            if (message.isAskForCheckDisabled != null && Object.hasOwnProperty.call(message, "isAskForCheckDisabled"))
                                writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isAskForCheckDisabled);
                            if (message.isSpecialInstructionsDisabled != null && Object.hasOwnProperty.call(message, "isSpecialInstructionsDisabled"))
                                writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isSpecialInstructionsDisabled);
                            if (message.isCompletedStatusSupportedForPaidOrders != null && Object.hasOwnProperty.call(message, "isCompletedStatusSupportedForPaidOrders"))
                                writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isCompletedStatusSupportedForPaidOrders);
                            if (message.isOnsiteTipsDisabled != null && Object.hasOwnProperty.call(message, "isOnsiteTipsDisabled"))
                                writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isOnsiteTipsDisabled);
                            if (message.isOnlineTipsDisabled != null && Object.hasOwnProperty.call(message, "isOnlineTipsDisabled"))
                                writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isOnlineTipsDisabled);
                            if (message.isDeliveryDisabled != null && Object.hasOwnProperty.call(message, "isDeliveryDisabled"))
                                writer.uint32(/* id 11, wireType 0 =*/88).bool(message.isDeliveryDisabled);
                            if (message.chargeCreditCardFeeToConsumer != null && Object.hasOwnProperty.call(message, "chargeCreditCardFeeToConsumer"))
                                writer.uint32(/* id 12, wireType 0 =*/96).bool(message.chargeCreditCardFeeToConsumer);
                            if (message.isAlcoholForOnlinePickupAllowed != null && Object.hasOwnProperty.call(message, "isAlcoholForOnlinePickupAllowed"))
                                writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isAlcoholForOnlinePickupAllowed);
                            if (message.isAlcoholForDeliveryAllowed != null && Object.hasOwnProperty.call(message, "isAlcoholForDeliveryAllowed"))
                                writer.uint32(/* id 14, wireType 0 =*/112).bool(message.isAlcoholForDeliveryAllowed);
                            if (message.printOrderAndReceipt != null && Object.hasOwnProperty.call(message, "printOrderAndReceipt"))
                                writer.uint32(/* id 15, wireType 0 =*/120).bool(message.printOrderAndReceipt);
                            if (message.isOrderForLaterDisabled != null && Object.hasOwnProperty.call(message, "isOrderForLaterDisabled"))
                                writer.uint32(/* id 16, wireType 0 =*/128).bool(message.isOrderForLaterDisabled);
                            if (message.isOrderForLaterDayDisabled != null && Object.hasOwnProperty.call(message, "isOrderForLaterDayDisabled"))
                                writer.uint32(/* id 17, wireType 0 =*/136).bool(message.isOrderForLaterDayDisabled);
                            if (message.isSmsOnNewOrdersForBusinessWebEnabled != null && Object.hasOwnProperty.call(message, "isSmsOnNewOrdersForBusinessWebEnabled"))
                                writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isSmsOnNewOrdersForBusinessWebEnabled);
                            return writer;
                        };

                        /**
                         * Encodes the specified EnabledFeaturesProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto} message EnabledFeaturesProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        EnabledFeaturesProto.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an EnabledFeaturesProto message from the specified reader or buffer.
                         * @function decode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto} EnabledFeaturesProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnabledFeaturesProto.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.isPaymentRequiredForOrders = reader.bool();
                                        break;
                                    }
                                case 2: {
                                        message.isPokeWaiterDisabled = reader.bool();
                                        break;
                                    }
                                case 3: {
                                        message.isStructuredMenuDisabled = reader.bool();
                                        break;
                                    }
                                case 4: {
                                        message.isImageMenuDisabled = reader.bool();
                                        break;
                                    }
                                case 5: {
                                        message.isMessageWaiterDisabled = reader.bool();
                                        break;
                                    }
                                case 6: {
                                        message.isAskForCheckDisabled = reader.bool();
                                        break;
                                    }
                                case 7: {
                                        message.isSpecialInstructionsDisabled = reader.bool();
                                        break;
                                    }
                                case 8: {
                                        message.isCompletedStatusSupportedForPaidOrders = reader.bool();
                                        break;
                                    }
                                case 9: {
                                        message.isOnsiteTipsDisabled = reader.bool();
                                        break;
                                    }
                                case 10: {
                                        message.isOnlineTipsDisabled = reader.bool();
                                        break;
                                    }
                                case 11: {
                                        message.isDeliveryDisabled = reader.bool();
                                        break;
                                    }
                                case 12: {
                                        message.chargeCreditCardFeeToConsumer = reader.bool();
                                        break;
                                    }
                                case 13: {
                                        message.isAlcoholForOnlinePickupAllowed = reader.bool();
                                        break;
                                    }
                                case 14: {
                                        message.isAlcoholForDeliveryAllowed = reader.bool();
                                        break;
                                    }
                                case 15: {
                                        message.printOrderAndReceipt = reader.bool();
                                        break;
                                    }
                                case 16: {
                                        message.isOrderForLaterDisabled = reader.bool();
                                        break;
                                    }
                                case 17: {
                                        message.isOrderForLaterDayDisabled = reader.bool();
                                        break;
                                    }
                                case 18: {
                                        message.isSmsOnNewOrdersForBusinessWebEnabled = reader.bool();
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an EnabledFeaturesProto message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto} EnabledFeaturesProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        EnabledFeaturesProto.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an EnabledFeaturesProto message.
                         * @function verify
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        EnabledFeaturesProto.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.isPaymentRequiredForOrders != null && message.hasOwnProperty("isPaymentRequiredForOrders"))
                                if (typeof message.isPaymentRequiredForOrders !== "boolean")
                                    return "isPaymentRequiredForOrders: boolean expected";
                            if (message.isPokeWaiterDisabled != null && message.hasOwnProperty("isPokeWaiterDisabled"))
                                if (typeof message.isPokeWaiterDisabled !== "boolean")
                                    return "isPokeWaiterDisabled: boolean expected";
                            if (message.isStructuredMenuDisabled != null && message.hasOwnProperty("isStructuredMenuDisabled"))
                                if (typeof message.isStructuredMenuDisabled !== "boolean")
                                    return "isStructuredMenuDisabled: boolean expected";
                            if (message.isImageMenuDisabled != null && message.hasOwnProperty("isImageMenuDisabled"))
                                if (typeof message.isImageMenuDisabled !== "boolean")
                                    return "isImageMenuDisabled: boolean expected";
                            if (message.isMessageWaiterDisabled != null && message.hasOwnProperty("isMessageWaiterDisabled"))
                                if (typeof message.isMessageWaiterDisabled !== "boolean")
                                    return "isMessageWaiterDisabled: boolean expected";
                            if (message.isAskForCheckDisabled != null && message.hasOwnProperty("isAskForCheckDisabled"))
                                if (typeof message.isAskForCheckDisabled !== "boolean")
                                    return "isAskForCheckDisabled: boolean expected";
                            if (message.isSpecialInstructionsDisabled != null && message.hasOwnProperty("isSpecialInstructionsDisabled"))
                                if (typeof message.isSpecialInstructionsDisabled !== "boolean")
                                    return "isSpecialInstructionsDisabled: boolean expected";
                            if (message.isCompletedStatusSupportedForPaidOrders != null && message.hasOwnProperty("isCompletedStatusSupportedForPaidOrders"))
                                if (typeof message.isCompletedStatusSupportedForPaidOrders !== "boolean")
                                    return "isCompletedStatusSupportedForPaidOrders: boolean expected";
                            if (message.isOnsiteTipsDisabled != null && message.hasOwnProperty("isOnsiteTipsDisabled"))
                                if (typeof message.isOnsiteTipsDisabled !== "boolean")
                                    return "isOnsiteTipsDisabled: boolean expected";
                            if (message.isOnlineTipsDisabled != null && message.hasOwnProperty("isOnlineTipsDisabled"))
                                if (typeof message.isOnlineTipsDisabled !== "boolean")
                                    return "isOnlineTipsDisabled: boolean expected";
                            if (message.isDeliveryDisabled != null && message.hasOwnProperty("isDeliveryDisabled"))
                                if (typeof message.isDeliveryDisabled !== "boolean")
                                    return "isDeliveryDisabled: boolean expected";
                            if (message.chargeCreditCardFeeToConsumer != null && message.hasOwnProperty("chargeCreditCardFeeToConsumer"))
                                if (typeof message.chargeCreditCardFeeToConsumer !== "boolean")
                                    return "chargeCreditCardFeeToConsumer: boolean expected";
                            if (message.isAlcoholForOnlinePickupAllowed != null && message.hasOwnProperty("isAlcoholForOnlinePickupAllowed"))
                                if (typeof message.isAlcoholForOnlinePickupAllowed !== "boolean")
                                    return "isAlcoholForOnlinePickupAllowed: boolean expected";
                            if (message.isAlcoholForDeliveryAllowed != null && message.hasOwnProperty("isAlcoholForDeliveryAllowed"))
                                if (typeof message.isAlcoholForDeliveryAllowed !== "boolean")
                                    return "isAlcoholForDeliveryAllowed: boolean expected";
                            if (message.printOrderAndReceipt != null && message.hasOwnProperty("printOrderAndReceipt"))
                                if (typeof message.printOrderAndReceipt !== "boolean")
                                    return "printOrderAndReceipt: boolean expected";
                            if (message.isOrderForLaterDisabled != null && message.hasOwnProperty("isOrderForLaterDisabled"))
                                if (typeof message.isOrderForLaterDisabled !== "boolean")
                                    return "isOrderForLaterDisabled: boolean expected";
                            if (message.isOrderForLaterDayDisabled != null && message.hasOwnProperty("isOrderForLaterDayDisabled"))
                                if (typeof message.isOrderForLaterDayDisabled !== "boolean")
                                    return "isOrderForLaterDayDisabled: boolean expected";
                            if (message.isSmsOnNewOrdersForBusinessWebEnabled != null && message.hasOwnProperty("isSmsOnNewOrdersForBusinessWebEnabled"))
                                if (typeof message.isSmsOnNewOrdersForBusinessWebEnabled !== "boolean")
                                    return "isSmsOnNewOrdersForBusinessWebEnabled: boolean expected";
                            return null;
                        };

                        /**
                         * Creates an EnabledFeaturesProto message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto} EnabledFeaturesProto
                         */
                        EnabledFeaturesProto.fromObject = function fromObject(object) {
                            if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto)
                                return object;
                            var message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto();
                            if (object.isPaymentRequiredForOrders != null)
                                message.isPaymentRequiredForOrders = Boolean(object.isPaymentRequiredForOrders);
                            if (object.isPokeWaiterDisabled != null)
                                message.isPokeWaiterDisabled = Boolean(object.isPokeWaiterDisabled);
                            if (object.isStructuredMenuDisabled != null)
                                message.isStructuredMenuDisabled = Boolean(object.isStructuredMenuDisabled);
                            if (object.isImageMenuDisabled != null)
                                message.isImageMenuDisabled = Boolean(object.isImageMenuDisabled);
                            if (object.isMessageWaiterDisabled != null)
                                message.isMessageWaiterDisabled = Boolean(object.isMessageWaiterDisabled);
                            if (object.isAskForCheckDisabled != null)
                                message.isAskForCheckDisabled = Boolean(object.isAskForCheckDisabled);
                            if (object.isSpecialInstructionsDisabled != null)
                                message.isSpecialInstructionsDisabled = Boolean(object.isSpecialInstructionsDisabled);
                            if (object.isCompletedStatusSupportedForPaidOrders != null)
                                message.isCompletedStatusSupportedForPaidOrders = Boolean(object.isCompletedStatusSupportedForPaidOrders);
                            if (object.isOnsiteTipsDisabled != null)
                                message.isOnsiteTipsDisabled = Boolean(object.isOnsiteTipsDisabled);
                            if (object.isOnlineTipsDisabled != null)
                                message.isOnlineTipsDisabled = Boolean(object.isOnlineTipsDisabled);
                            if (object.isDeliveryDisabled != null)
                                message.isDeliveryDisabled = Boolean(object.isDeliveryDisabled);
                            if (object.chargeCreditCardFeeToConsumer != null)
                                message.chargeCreditCardFeeToConsumer = Boolean(object.chargeCreditCardFeeToConsumer);
                            if (object.isAlcoholForOnlinePickupAllowed != null)
                                message.isAlcoholForOnlinePickupAllowed = Boolean(object.isAlcoholForOnlinePickupAllowed);
                            if (object.isAlcoholForDeliveryAllowed != null)
                                message.isAlcoholForDeliveryAllowed = Boolean(object.isAlcoholForDeliveryAllowed);
                            if (object.printOrderAndReceipt != null)
                                message.printOrderAndReceipt = Boolean(object.printOrderAndReceipt);
                            if (object.isOrderForLaterDisabled != null)
                                message.isOrderForLaterDisabled = Boolean(object.isOrderForLaterDisabled);
                            if (object.isOrderForLaterDayDisabled != null)
                                message.isOrderForLaterDayDisabled = Boolean(object.isOrderForLaterDayDisabled);
                            if (object.isSmsOnNewOrdersForBusinessWebEnabled != null)
                                message.isSmsOnNewOrdersForBusinessWebEnabled = Boolean(object.isSmsOnNewOrdersForBusinessWebEnabled);
                            return message;
                        };

                        /**
                         * Creates a plain object from an EnabledFeaturesProto message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto} message EnabledFeaturesProto
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        EnabledFeaturesProto.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.isPaymentRequiredForOrders = false;
                                object.isPokeWaiterDisabled = false;
                                object.isStructuredMenuDisabled = false;
                                object.isImageMenuDisabled = false;
                                object.isMessageWaiterDisabled = false;
                                object.isAskForCheckDisabled = false;
                                object.isSpecialInstructionsDisabled = false;
                                object.isCompletedStatusSupportedForPaidOrders = false;
                                object.isOnsiteTipsDisabled = false;
                                object.isOnlineTipsDisabled = false;
                                object.isDeliveryDisabled = false;
                                object.chargeCreditCardFeeToConsumer = false;
                                object.isAlcoholForOnlinePickupAllowed = false;
                                object.isAlcoholForDeliveryAllowed = false;
                                object.printOrderAndReceipt = false;
                                object.isOrderForLaterDisabled = false;
                                object.isOrderForLaterDayDisabled = false;
                                object.isSmsOnNewOrdersForBusinessWebEnabled = false;
                            }
                            if (message.isPaymentRequiredForOrders != null && message.hasOwnProperty("isPaymentRequiredForOrders"))
                                object.isPaymentRequiredForOrders = message.isPaymentRequiredForOrders;
                            if (message.isPokeWaiterDisabled != null && message.hasOwnProperty("isPokeWaiterDisabled"))
                                object.isPokeWaiterDisabled = message.isPokeWaiterDisabled;
                            if (message.isStructuredMenuDisabled != null && message.hasOwnProperty("isStructuredMenuDisabled"))
                                object.isStructuredMenuDisabled = message.isStructuredMenuDisabled;
                            if (message.isImageMenuDisabled != null && message.hasOwnProperty("isImageMenuDisabled"))
                                object.isImageMenuDisabled = message.isImageMenuDisabled;
                            if (message.isMessageWaiterDisabled != null && message.hasOwnProperty("isMessageWaiterDisabled"))
                                object.isMessageWaiterDisabled = message.isMessageWaiterDisabled;
                            if (message.isAskForCheckDisabled != null && message.hasOwnProperty("isAskForCheckDisabled"))
                                object.isAskForCheckDisabled = message.isAskForCheckDisabled;
                            if (message.isSpecialInstructionsDisabled != null && message.hasOwnProperty("isSpecialInstructionsDisabled"))
                                object.isSpecialInstructionsDisabled = message.isSpecialInstructionsDisabled;
                            if (message.isCompletedStatusSupportedForPaidOrders != null && message.hasOwnProperty("isCompletedStatusSupportedForPaidOrders"))
                                object.isCompletedStatusSupportedForPaidOrders = message.isCompletedStatusSupportedForPaidOrders;
                            if (message.isOnsiteTipsDisabled != null && message.hasOwnProperty("isOnsiteTipsDisabled"))
                                object.isOnsiteTipsDisabled = message.isOnsiteTipsDisabled;
                            if (message.isOnlineTipsDisabled != null && message.hasOwnProperty("isOnlineTipsDisabled"))
                                object.isOnlineTipsDisabled = message.isOnlineTipsDisabled;
                            if (message.isDeliveryDisabled != null && message.hasOwnProperty("isDeliveryDisabled"))
                                object.isDeliveryDisabled = message.isDeliveryDisabled;
                            if (message.chargeCreditCardFeeToConsumer != null && message.hasOwnProperty("chargeCreditCardFeeToConsumer"))
                                object.chargeCreditCardFeeToConsumer = message.chargeCreditCardFeeToConsumer;
                            if (message.isAlcoholForOnlinePickupAllowed != null && message.hasOwnProperty("isAlcoholForOnlinePickupAllowed"))
                                object.isAlcoholForOnlinePickupAllowed = message.isAlcoholForOnlinePickupAllowed;
                            if (message.isAlcoholForDeliveryAllowed != null && message.hasOwnProperty("isAlcoholForDeliveryAllowed"))
                                object.isAlcoholForDeliveryAllowed = message.isAlcoholForDeliveryAllowed;
                            if (message.printOrderAndReceipt != null && message.hasOwnProperty("printOrderAndReceipt"))
                                object.printOrderAndReceipt = message.printOrderAndReceipt;
                            if (message.isOrderForLaterDisabled != null && message.hasOwnProperty("isOrderForLaterDisabled"))
                                object.isOrderForLaterDisabled = message.isOrderForLaterDisabled;
                            if (message.isOrderForLaterDayDisabled != null && message.hasOwnProperty("isOrderForLaterDayDisabled"))
                                object.isOrderForLaterDayDisabled = message.isOrderForLaterDayDisabled;
                            if (message.isSmsOnNewOrdersForBusinessWebEnabled != null && message.hasOwnProperty("isSmsOnNewOrdersForBusinessWebEnabled"))
                                object.isSmsOnNewOrdersForBusinessWebEnabled = message.isSmsOnNewOrdersForBusinessWebEnabled;
                            return object;
                        };

                        /**
                         * Converts this EnabledFeaturesProto to JSON.
                         * @function toJSON
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        EnabledFeaturesProto.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for EnabledFeaturesProto
                         * @function getTypeUrl
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        EnabledFeaturesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto";
                        };

                        return EnabledFeaturesProto;
                    })();

                    OperationSettingsProto.StripeAccountProto = (function() {

                        /**
                         * Properties of a StripeAccountProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @interface IStripeAccountProto
                         * @property {string|null} [id] StripeAccountProto id
                         * @property {boolean|null} [isEnrolled] StripeAccountProto isEnrolled
                         * @property {google.protobuf.ITimestamp|null} [enrolledOn] StripeAccountProto enrolledOn
                         */

                        /**
                         * Constructs a new StripeAccountProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @classdesc Represents a StripeAccountProto.
                         * @implements IStripeAccountProto
                         * @constructor
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IStripeAccountProto=} [properties] Properties to set
                         */
                        function StripeAccountProto(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * StripeAccountProto id.
                         * @member {string} id
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @instance
                         */
                        StripeAccountProto.prototype.id = "";

                        /**
                         * StripeAccountProto isEnrolled.
                         * @member {boolean} isEnrolled
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @instance
                         */
                        StripeAccountProto.prototype.isEnrolled = false;

                        /**
                         * StripeAccountProto enrolledOn.
                         * @member {google.protobuf.ITimestamp|null|undefined} enrolledOn
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @instance
                         */
                        StripeAccountProto.prototype.enrolledOn = null;

                        /**
                         * Creates a new StripeAccountProto instance using the specified properties.
                         * @function create
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IStripeAccountProto=} [properties] Properties to set
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto} StripeAccountProto instance
                         */
                        StripeAccountProto.create = function create(properties) {
                            return new StripeAccountProto(properties);
                        };

                        /**
                         * Encodes the specified StripeAccountProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.verify|verify} messages.
                         * @function encode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IStripeAccountProto} message StripeAccountProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StripeAccountProto.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                            if (message.isEnrolled != null && Object.hasOwnProperty.call(message, "isEnrolled"))
                                writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isEnrolled);
                            if (message.enrolledOn != null && Object.hasOwnProperty.call(message, "enrolledOn"))
                                $root.google.protobuf.Timestamp.encode(message.enrolledOn, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified StripeAccountProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IStripeAccountProto} message StripeAccountProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        StripeAccountProto.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a StripeAccountProto message from the specified reader or buffer.
                         * @function decode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto} StripeAccountProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StripeAccountProto.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.id = reader.string();
                                        break;
                                    }
                                case 2: {
                                        message.isEnrolled = reader.bool();
                                        break;
                                    }
                                case 3: {
                                        message.enrolledOn = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a StripeAccountProto message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto} StripeAccountProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        StripeAccountProto.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a StripeAccountProto message.
                         * @function verify
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        StripeAccountProto.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.id != null && message.hasOwnProperty("id"))
                                if (!$util.isString(message.id))
                                    return "id: string expected";
                            if (message.isEnrolled != null && message.hasOwnProperty("isEnrolled"))
                                if (typeof message.isEnrolled !== "boolean")
                                    return "isEnrolled: boolean expected";
                            if (message.enrolledOn != null && message.hasOwnProperty("enrolledOn")) {
                                var error = $root.google.protobuf.Timestamp.verify(message.enrolledOn);
                                if (error)
                                    return "enrolledOn." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a StripeAccountProto message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto} StripeAccountProto
                         */
                        StripeAccountProto.fromObject = function fromObject(object) {
                            if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto)
                                return object;
                            var message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto();
                            if (object.id != null)
                                message.id = String(object.id);
                            if (object.isEnrolled != null)
                                message.isEnrolled = Boolean(object.isEnrolled);
                            if (object.enrolledOn != null) {
                                if (typeof object.enrolledOn !== "object")
                                    throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto.enrolledOn: object expected");
                                message.enrolledOn = $root.google.protobuf.Timestamp.fromObject(object.enrolledOn);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a StripeAccountProto message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto} message StripeAccountProto
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        StripeAccountProto.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.id = "";
                                object.isEnrolled = false;
                                object.enrolledOn = null;
                            }
                            if (message.id != null && message.hasOwnProperty("id"))
                                object.id = message.id;
                            if (message.isEnrolled != null && message.hasOwnProperty("isEnrolled"))
                                object.isEnrolled = message.isEnrolled;
                            if (message.enrolledOn != null && message.hasOwnProperty("enrolledOn"))
                                object.enrolledOn = $root.google.protobuf.Timestamp.toObject(message.enrolledOn, options);
                            return object;
                        };

                        /**
                         * Converts this StripeAccountProto to JSON.
                         * @function toJSON
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        StripeAccountProto.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for StripeAccountProto
                         * @function getTypeUrl
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        StripeAccountProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.StripeAccountProto";
                        };

                        return StripeAccountProto;
                    })();

                    OperationSettingsProto.AlertConfigProto = (function() {

                        /**
                         * Properties of an AlertConfigProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @interface IAlertConfigProto
                         * @property {string|null} [email] AlertConfigProto email
                         * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] AlertConfigProto phoneNumber
                         */

                        /**
                         * Constructs a new AlertConfigProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @classdesc Represents an AlertConfigProto.
                         * @implements IAlertConfigProto
                         * @constructor
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto=} [properties] Properties to set
                         */
                        function AlertConfigProto(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * AlertConfigProto email.
                         * @member {string} email
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @instance
                         */
                        AlertConfigProto.prototype.email = "";

                        /**
                         * AlertConfigProto phoneNumber.
                         * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @instance
                         */
                        AlertConfigProto.prototype.phoneNumber = null;

                        /**
                         * Creates a new AlertConfigProto instance using the specified properties.
                         * @function create
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto=} [properties] Properties to set
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto} AlertConfigProto instance
                         */
                        AlertConfigProto.create = function create(properties) {
                            return new AlertConfigProto(properties);
                        };

                        /**
                         * Encodes the specified AlertConfigProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.verify|verify} messages.
                         * @function encode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto} message AlertConfigProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AlertConfigProto.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                                writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                            if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                                $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified AlertConfigProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto} message AlertConfigProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        AlertConfigProto.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes an AlertConfigProto message from the specified reader or buffer.
                         * @function decode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto} AlertConfigProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AlertConfigProto.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.email = reader.string();
                                        break;
                                    }
                                case 2: {
                                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes an AlertConfigProto message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto} AlertConfigProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        AlertConfigProto.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies an AlertConfigProto message.
                         * @function verify
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        AlertConfigProto.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.email != null && message.hasOwnProperty("email"))
                                if (!$util.isString(message.email))
                                    return "email: string expected";
                            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                                var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                                if (error)
                                    return "phoneNumber." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates an AlertConfigProto message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto} AlertConfigProto
                         */
                        AlertConfigProto.fromObject = function fromObject(object) {
                            if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto)
                                return object;
                            var message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto();
                            if (object.email != null)
                                message.email = String(object.email);
                            if (object.phoneNumber != null) {
                                if (typeof object.phoneNumber !== "object")
                                    throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.phoneNumber: object expected");
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from an AlertConfigProto message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto} message AlertConfigProto
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        AlertConfigProto.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.email = "";
                                object.phoneNumber = null;
                            }
                            if (message.email != null && message.hasOwnProperty("email"))
                                object.email = message.email;
                            if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                                object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                            return object;
                        };

                        /**
                         * Converts this AlertConfigProto to JSON.
                         * @function toJSON
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        AlertConfigProto.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for AlertConfigProto
                         * @function getTypeUrl
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        AlertConfigProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto";
                        };

                        return AlertConfigProto;
                    })();

                    OperationSettingsProto.DeliveryConfigProto = (function() {

                        /**
                         * Properties of a DeliveryConfigProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @interface IDeliveryConfigProto
                         * @property {waiternow.common.DeliveryCarrier|null} [deliveryCarrier] DeliveryConfigProto deliveryCarrier
                         * @property {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.ISelfDeliveryConfigProto|null} [selfDeliveryConfig] DeliveryConfigProto selfDeliveryConfig
                         */

                        /**
                         * Constructs a new DeliveryConfigProto.
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto
                         * @classdesc Represents a DeliveryConfigProto.
                         * @implements IDeliveryConfigProto
                         * @constructor
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IDeliveryConfigProto=} [properties] Properties to set
                         */
                        function DeliveryConfigProto(properties) {
                            if (properties)
                                for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                    if (properties[keys[i]] != null)
                                        this[keys[i]] = properties[keys[i]];
                        }

                        /**
                         * DeliveryConfigProto deliveryCarrier.
                         * @member {waiternow.common.DeliveryCarrier} deliveryCarrier
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @instance
                         */
                        DeliveryConfigProto.prototype.deliveryCarrier = 0;

                        /**
                         * DeliveryConfigProto selfDeliveryConfig.
                         * @member {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.ISelfDeliveryConfigProto|null|undefined} selfDeliveryConfig
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @instance
                         */
                        DeliveryConfigProto.prototype.selfDeliveryConfig = null;

                        /**
                         * Creates a new DeliveryConfigProto instance using the specified properties.
                         * @function create
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IDeliveryConfigProto=} [properties] Properties to set
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto} DeliveryConfigProto instance
                         */
                        DeliveryConfigProto.create = function create(properties) {
                            return new DeliveryConfigProto(properties);
                        };

                        /**
                         * Encodes the specified DeliveryConfigProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.verify|verify} messages.
                         * @function encode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IDeliveryConfigProto} message DeliveryConfigProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DeliveryConfigProto.encode = function encode(message, writer) {
                            if (!writer)
                                writer = $Writer.create();
                            if (message.deliveryCarrier != null && Object.hasOwnProperty.call(message, "deliveryCarrier"))
                                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.deliveryCarrier);
                            if (message.selfDeliveryConfig != null && Object.hasOwnProperty.call(message, "selfDeliveryConfig"))
                                $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.encode(message.selfDeliveryConfig, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                            return writer;
                        };

                        /**
                         * Encodes the specified DeliveryConfigProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.verify|verify} messages.
                         * @function encodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IDeliveryConfigProto} message DeliveryConfigProto message or plain object to encode
                         * @param {$protobuf.Writer} [writer] Writer to encode to
                         * @returns {$protobuf.Writer} Writer
                         */
                        DeliveryConfigProto.encodeDelimited = function encodeDelimited(message, writer) {
                            return this.encode(message, writer).ldelim();
                        };

                        /**
                         * Decodes a DeliveryConfigProto message from the specified reader or buffer.
                         * @function decode
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @param {number} [length] Message length if known beforehand
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto} DeliveryConfigProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DeliveryConfigProto.decode = function decode(reader, length) {
                            if (!(reader instanceof $Reader))
                                reader = $Reader.create(reader);
                            var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto();
                            while (reader.pos < end) {
                                var tag = reader.uint32();
                                switch (tag >>> 3) {
                                case 1: {
                                        message.deliveryCarrier = reader.int32();
                                        break;
                                    }
                                case 2: {
                                        message.selfDeliveryConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.decode(reader, reader.uint32());
                                        break;
                                    }
                                default:
                                    reader.skipType(tag & 7);
                                    break;
                                }
                            }
                            return message;
                        };

                        /**
                         * Decodes a DeliveryConfigProto message from the specified reader or buffer, length delimited.
                         * @function decodeDelimited
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto} DeliveryConfigProto
                         * @throws {Error} If the payload is not a reader or valid buffer
                         * @throws {$protobuf.util.ProtocolError} If required fields are missing
                         */
                        DeliveryConfigProto.decodeDelimited = function decodeDelimited(reader) {
                            if (!(reader instanceof $Reader))
                                reader = new $Reader(reader);
                            return this.decode(reader, reader.uint32());
                        };

                        /**
                         * Verifies a DeliveryConfigProto message.
                         * @function verify
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {Object.<string,*>} message Plain object to verify
                         * @returns {string|null} `null` if valid, otherwise the reason why it is not
                         */
                        DeliveryConfigProto.verify = function verify(message) {
                            if (typeof message !== "object" || message === null)
                                return "object expected";
                            if (message.deliveryCarrier != null && message.hasOwnProperty("deliveryCarrier"))
                                switch (message.deliveryCarrier) {
                                default:
                                    return "deliveryCarrier: enum value expected";
                                case 0:
                                case 1:
                                case 2:
                                    break;
                                }
                            if (message.selfDeliveryConfig != null && message.hasOwnProperty("selfDeliveryConfig")) {
                                var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.verify(message.selfDeliveryConfig);
                                if (error)
                                    return "selfDeliveryConfig." + error;
                            }
                            return null;
                        };

                        /**
                         * Creates a DeliveryConfigProto message from a plain object. Also converts values to their respective internal types.
                         * @function fromObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {Object.<string,*>} object Plain object
                         * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto} DeliveryConfigProto
                         */
                        DeliveryConfigProto.fromObject = function fromObject(object) {
                            if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto)
                                return object;
                            var message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto();
                            switch (object.deliveryCarrier) {
                            default:
                                if (typeof object.deliveryCarrier === "number") {
                                    message.deliveryCarrier = object.deliveryCarrier;
                                    break;
                                }
                                break;
                            case "DELIVERY_CARRIER_UNSPECIFIED":
                            case 0:
                                message.deliveryCarrier = 0;
                                break;
                            case "SELF":
                            case 1:
                                message.deliveryCarrier = 1;
                                break;
                            case "DOORDASH":
                            case 2:
                                message.deliveryCarrier = 2;
                                break;
                            }
                            if (object.selfDeliveryConfig != null) {
                                if (typeof object.selfDeliveryConfig !== "object")
                                    throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.selfDeliveryConfig: object expected");
                                message.selfDeliveryConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.fromObject(object.selfDeliveryConfig);
                            }
                            return message;
                        };

                        /**
                         * Creates a plain object from a DeliveryConfigProto message. Also converts values to other types if specified.
                         * @function toObject
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto} message DeliveryConfigProto
                         * @param {$protobuf.IConversionOptions} [options] Conversion options
                         * @returns {Object.<string,*>} Plain object
                         */
                        DeliveryConfigProto.toObject = function toObject(message, options) {
                            if (!options)
                                options = {};
                            var object = {};
                            if (options.defaults) {
                                object.deliveryCarrier = options.enums === String ? "DELIVERY_CARRIER_UNSPECIFIED" : 0;
                                object.selfDeliveryConfig = null;
                            }
                            if (message.deliveryCarrier != null && message.hasOwnProperty("deliveryCarrier"))
                                object.deliveryCarrier = options.enums === String ? $root.waiternow.common.DeliveryCarrier[message.deliveryCarrier] === undefined ? message.deliveryCarrier : $root.waiternow.common.DeliveryCarrier[message.deliveryCarrier] : message.deliveryCarrier;
                            if (message.selfDeliveryConfig != null && message.hasOwnProperty("selfDeliveryConfig"))
                                object.selfDeliveryConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.toObject(message.selfDeliveryConfig, options);
                            return object;
                        };

                        /**
                         * Converts this DeliveryConfigProto to JSON.
                         * @function toJSON
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @instance
                         * @returns {Object.<string,*>} JSON object
                         */
                        DeliveryConfigProto.prototype.toJSON = function toJSON() {
                            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                        };

                        /**
                         * Gets the default type url for DeliveryConfigProto
                         * @function getTypeUrl
                         * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                         * @static
                         * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                         * @returns {string} The default type url
                         */
                        DeliveryConfigProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                            if (typeUrlPrefix === undefined) {
                                typeUrlPrefix = "type.googleapis.com";
                            }
                            return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto";
                        };

                        DeliveryConfigProto.SelfDeliveryConfigProto = (function() {

                            /**
                             * Properties of a SelfDeliveryConfigProto.
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                             * @interface ISelfDeliveryConfigProto
                             * @property {waiternow.common.IMoneyProto|null} [baseFee] SelfDeliveryConfigProto baseFee
                             * @property {waiternow.common.IDistanceQuantityProto|null} [baseDistance] SelfDeliveryConfigProto baseDistance
                             * @property {waiternow.common.IMoneyProto|null} [feePerDistanceUnit] SelfDeliveryConfigProto feePerDistanceUnit
                             * @property {waiternow.common.IDistanceQuantityProto|null} [maxDistance] SelfDeliveryConfigProto maxDistance
                             */

                            /**
                             * Constructs a new SelfDeliveryConfigProto.
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto
                             * @classdesc Represents a SelfDeliveryConfigProto.
                             * @implements ISelfDeliveryConfigProto
                             * @constructor
                             * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.ISelfDeliveryConfigProto=} [properties] Properties to set
                             */
                            function SelfDeliveryConfigProto(properties) {
                                if (properties)
                                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                        if (properties[keys[i]] != null)
                                            this[keys[i]] = properties[keys[i]];
                            }

                            /**
                             * SelfDeliveryConfigProto baseFee.
                             * @member {waiternow.common.IMoneyProto|null|undefined} baseFee
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @instance
                             */
                            SelfDeliveryConfigProto.prototype.baseFee = null;

                            /**
                             * SelfDeliveryConfigProto baseDistance.
                             * @member {waiternow.common.IDistanceQuantityProto|null|undefined} baseDistance
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @instance
                             */
                            SelfDeliveryConfigProto.prototype.baseDistance = null;

                            /**
                             * SelfDeliveryConfigProto feePerDistanceUnit.
                             * @member {waiternow.common.IMoneyProto|null|undefined} feePerDistanceUnit
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @instance
                             */
                            SelfDeliveryConfigProto.prototype.feePerDistanceUnit = null;

                            /**
                             * SelfDeliveryConfigProto maxDistance.
                             * @member {waiternow.common.IDistanceQuantityProto|null|undefined} maxDistance
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @instance
                             */
                            SelfDeliveryConfigProto.prototype.maxDistance = null;

                            /**
                             * Creates a new SelfDeliveryConfigProto instance using the specified properties.
                             * @function create
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.ISelfDeliveryConfigProto=} [properties] Properties to set
                             * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto} SelfDeliveryConfigProto instance
                             */
                            SelfDeliveryConfigProto.create = function create(properties) {
                                return new SelfDeliveryConfigProto(properties);
                            };

                            /**
                             * Encodes the specified SelfDeliveryConfigProto message. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.verify|verify} messages.
                             * @function encode
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.ISelfDeliveryConfigProto} message SelfDeliveryConfigProto message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            SelfDeliveryConfigProto.encode = function encode(message, writer) {
                                if (!writer)
                                    writer = $Writer.create();
                                if (message.baseFee != null && Object.hasOwnProperty.call(message, "baseFee"))
                                    $root.waiternow.common.MoneyProto.encode(message.baseFee, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                                if (message.baseDistance != null && Object.hasOwnProperty.call(message, "baseDistance"))
                                    $root.waiternow.common.DistanceQuantityProto.encode(message.baseDistance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                                if (message.feePerDistanceUnit != null && Object.hasOwnProperty.call(message, "feePerDistanceUnit"))
                                    $root.waiternow.common.MoneyProto.encode(message.feePerDistanceUnit, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                                if (message.maxDistance != null && Object.hasOwnProperty.call(message, "maxDistance"))
                                    $root.waiternow.common.DistanceQuantityProto.encode(message.maxDistance, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                                return writer;
                            };

                            /**
                             * Encodes the specified SelfDeliveryConfigProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.verify|verify} messages.
                             * @function encodeDelimited
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.ISelfDeliveryConfigProto} message SelfDeliveryConfigProto message or plain object to encode
                             * @param {$protobuf.Writer} [writer] Writer to encode to
                             * @returns {$protobuf.Writer} Writer
                             */
                            SelfDeliveryConfigProto.encodeDelimited = function encodeDelimited(message, writer) {
                                return this.encode(message, writer).ldelim();
                            };

                            /**
                             * Decodes a SelfDeliveryConfigProto message from the specified reader or buffer.
                             * @function decode
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @param {number} [length] Message length if known beforehand
                             * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto} SelfDeliveryConfigProto
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            SelfDeliveryConfigProto.decode = function decode(reader, length) {
                                if (!(reader instanceof $Reader))
                                    reader = $Reader.create(reader);
                                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto();
                                while (reader.pos < end) {
                                    var tag = reader.uint32();
                                    switch (tag >>> 3) {
                                    case 1: {
                                            message.baseFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                            break;
                                        }
                                    case 2: {
                                            message.baseDistance = $root.waiternow.common.DistanceQuantityProto.decode(reader, reader.uint32());
                                            break;
                                        }
                                    case 3: {
                                            message.feePerDistanceUnit = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                            break;
                                        }
                                    case 4: {
                                            message.maxDistance = $root.waiternow.common.DistanceQuantityProto.decode(reader, reader.uint32());
                                            break;
                                        }
                                    default:
                                        reader.skipType(tag & 7);
                                        break;
                                    }
                                }
                                return message;
                            };

                            /**
                             * Decodes a SelfDeliveryConfigProto message from the specified reader or buffer, length delimited.
                             * @function decodeDelimited
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                             * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto} SelfDeliveryConfigProto
                             * @throws {Error} If the payload is not a reader or valid buffer
                             * @throws {$protobuf.util.ProtocolError} If required fields are missing
                             */
                            SelfDeliveryConfigProto.decodeDelimited = function decodeDelimited(reader) {
                                if (!(reader instanceof $Reader))
                                    reader = new $Reader(reader);
                                return this.decode(reader, reader.uint32());
                            };

                            /**
                             * Verifies a SelfDeliveryConfigProto message.
                             * @function verify
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {Object.<string,*>} message Plain object to verify
                             * @returns {string|null} `null` if valid, otherwise the reason why it is not
                             */
                            SelfDeliveryConfigProto.verify = function verify(message) {
                                if (typeof message !== "object" || message === null)
                                    return "object expected";
                                if (message.baseFee != null && message.hasOwnProperty("baseFee")) {
                                    var error = $root.waiternow.common.MoneyProto.verify(message.baseFee);
                                    if (error)
                                        return "baseFee." + error;
                                }
                                if (message.baseDistance != null && message.hasOwnProperty("baseDistance")) {
                                    var error = $root.waiternow.common.DistanceQuantityProto.verify(message.baseDistance);
                                    if (error)
                                        return "baseDistance." + error;
                                }
                                if (message.feePerDistanceUnit != null && message.hasOwnProperty("feePerDistanceUnit")) {
                                    var error = $root.waiternow.common.MoneyProto.verify(message.feePerDistanceUnit);
                                    if (error)
                                        return "feePerDistanceUnit." + error;
                                }
                                if (message.maxDistance != null && message.hasOwnProperty("maxDistance")) {
                                    var error = $root.waiternow.common.DistanceQuantityProto.verify(message.maxDistance);
                                    if (error)
                                        return "maxDistance." + error;
                                }
                                return null;
                            };

                            /**
                             * Creates a SelfDeliveryConfigProto message from a plain object. Also converts values to their respective internal types.
                             * @function fromObject
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {Object.<string,*>} object Plain object
                             * @returns {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto} SelfDeliveryConfigProto
                             */
                            SelfDeliveryConfigProto.fromObject = function fromObject(object) {
                                if (object instanceof $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto)
                                    return object;
                                var message = new $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto();
                                if (object.baseFee != null) {
                                    if (typeof object.baseFee !== "object")
                                        throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.baseFee: object expected");
                                    message.baseFee = $root.waiternow.common.MoneyProto.fromObject(object.baseFee);
                                }
                                if (object.baseDistance != null) {
                                    if (typeof object.baseDistance !== "object")
                                        throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.baseDistance: object expected");
                                    message.baseDistance = $root.waiternow.common.DistanceQuantityProto.fromObject(object.baseDistance);
                                }
                                if (object.feePerDistanceUnit != null) {
                                    if (typeof object.feePerDistanceUnit !== "object")
                                        throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.feePerDistanceUnit: object expected");
                                    message.feePerDistanceUnit = $root.waiternow.common.MoneyProto.fromObject(object.feePerDistanceUnit);
                                }
                                if (object.maxDistance != null) {
                                    if (typeof object.maxDistance !== "object")
                                        throw TypeError(".waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto.maxDistance: object expected");
                                    message.maxDistance = $root.waiternow.common.DistanceQuantityProto.fromObject(object.maxDistance);
                                }
                                return message;
                            };

                            /**
                             * Creates a plain object from a SelfDeliveryConfigProto message. Also converts values to other types if specified.
                             * @function toObject
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto} message SelfDeliveryConfigProto
                             * @param {$protobuf.IConversionOptions} [options] Conversion options
                             * @returns {Object.<string,*>} Plain object
                             */
                            SelfDeliveryConfigProto.toObject = function toObject(message, options) {
                                if (!options)
                                    options = {};
                                var object = {};
                                if (options.defaults) {
                                    object.baseFee = null;
                                    object.baseDistance = null;
                                    object.feePerDistanceUnit = null;
                                    object.maxDistance = null;
                                }
                                if (message.baseFee != null && message.hasOwnProperty("baseFee"))
                                    object.baseFee = $root.waiternow.common.MoneyProto.toObject(message.baseFee, options);
                                if (message.baseDistance != null && message.hasOwnProperty("baseDistance"))
                                    object.baseDistance = $root.waiternow.common.DistanceQuantityProto.toObject(message.baseDistance, options);
                                if (message.feePerDistanceUnit != null && message.hasOwnProperty("feePerDistanceUnit"))
                                    object.feePerDistanceUnit = $root.waiternow.common.MoneyProto.toObject(message.feePerDistanceUnit, options);
                                if (message.maxDistance != null && message.hasOwnProperty("maxDistance"))
                                    object.maxDistance = $root.waiternow.common.DistanceQuantityProto.toObject(message.maxDistance, options);
                                return object;
                            };

                            /**
                             * Converts this SelfDeliveryConfigProto to JSON.
                             * @function toJSON
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @instance
                             * @returns {Object.<string,*>} JSON object
                             */
                            SelfDeliveryConfigProto.prototype.toJSON = function toJSON() {
                                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                            };

                            /**
                             * Gets the default type url for SelfDeliveryConfigProto
                             * @function getTypeUrl
                             * @memberof waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto
                             * @static
                             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                             * @returns {string} The default type url
                             */
                            SelfDeliveryConfigProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                                if (typeUrlPrefix === undefined) {
                                    typeUrlPrefix = "type.googleapis.com";
                                }
                                return typeUrlPrefix + "/waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.DeliveryConfigProto.SelfDeliveryConfigProto";
                            };

                            return SelfDeliveryConfigProto;
                        })();

                        return DeliveryConfigProto;
                    })();

                    return OperationSettingsProto;
                })();

                return SettingsProto;
            })();

            LocationProto.RedundantDataProto = (function() {

                /**
                 * Properties of a RedundantDataProto.
                 * @memberof waiternow.common.LocationProto
                 * @interface IRedundantDataProto
                 * @property {string|null} [businessName] RedundantDataProto businessName
                 * @property {boolean|null} [isBusinessSuspended] RedundantDataProto isBusinessSuspended
                 * @property {waiternow.common.LocationProto.ISettingsProto|null} [businessLocationSettings] RedundantDataProto businessLocationSettings
                 * @property {waiternow.common.IImageProto|null} [businessLogo] RedundantDataProto businessLogo
                 * @property {waiternow.common.IImageProto|null} [businessHeaderForLandscapeScreen] RedundantDataProto businessHeaderForLandscapeScreen
                 * @property {waiternow.common.IImageProto|null} [businessHeaderForPortraitScreen] RedundantDataProto businessHeaderForPortraitScreen
                 * @property {string|null} [businessDescription] RedundantDataProto businessDescription
                 */

                /**
                 * Constructs a new RedundantDataProto.
                 * @memberof waiternow.common.LocationProto
                 * @classdesc Represents a RedundantDataProto.
                 * @implements IRedundantDataProto
                 * @constructor
                 * @param {waiternow.common.LocationProto.IRedundantDataProto=} [properties] Properties to set
                 */
                function RedundantDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RedundantDataProto businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessName = "";

                /**
                 * RedundantDataProto isBusinessSuspended.
                 * @member {boolean} isBusinessSuspended
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.isBusinessSuspended = false;

                /**
                 * RedundantDataProto businessLocationSettings.
                 * @member {waiternow.common.LocationProto.ISettingsProto|null|undefined} businessLocationSettings
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessLocationSettings = null;

                /**
                 * RedundantDataProto businessLogo.
                 * @member {waiternow.common.IImageProto|null|undefined} businessLogo
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessLogo = null;

                /**
                 * RedundantDataProto businessHeaderForLandscapeScreen.
                 * @member {waiternow.common.IImageProto|null|undefined} businessHeaderForLandscapeScreen
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessHeaderForLandscapeScreen = null;

                /**
                 * RedundantDataProto businessHeaderForPortraitScreen.
                 * @member {waiternow.common.IImageProto|null|undefined} businessHeaderForPortraitScreen
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessHeaderForPortraitScreen = null;

                /**
                 * RedundantDataProto businessDescription.
                 * @member {string} businessDescription
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessDescription = "";

                /**
                 * Creates a new RedundantDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationProto.IRedundantDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.LocationProto.RedundantDataProto} RedundantDataProto instance
                 */
                RedundantDataProto.create = function create(properties) {
                    return new RedundantDataProto(properties);
                };

                /**
                 * Encodes the specified RedundantDataProto message. Does not implicitly {@link waiternow.common.LocationProto.RedundantDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.isBusinessSuspended != null && Object.hasOwnProperty.call(message, "isBusinessSuspended"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isBusinessSuspended);
                    if (message.businessLocationSettings != null && Object.hasOwnProperty.call(message, "businessLocationSettings"))
                        $root.waiternow.common.LocationProto.SettingsProto.encode(message.businessLocationSettings, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.businessLogo != null && Object.hasOwnProperty.call(message, "businessLogo"))
                        $root.waiternow.common.ImageProto.encode(message.businessLogo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.businessHeaderForLandscapeScreen != null && Object.hasOwnProperty.call(message, "businessHeaderForLandscapeScreen"))
                        $root.waiternow.common.ImageProto.encode(message.businessHeaderForLandscapeScreen, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.businessHeaderForPortraitScreen != null && Object.hasOwnProperty.call(message, "businessHeaderForPortraitScreen"))
                        $root.waiternow.common.ImageProto.encode(message.businessHeaderForPortraitScreen, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.businessDescription != null && Object.hasOwnProperty.call(message, "businessDescription"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.businessDescription);
                    return writer;
                };

                /**
                 * Encodes the specified RedundantDataProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.RedundantDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.LocationProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.RedundantDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 3: {
                                message.isBusinessSuspended = reader.bool();
                                break;
                            }
                        case 4: {
                                message.businessLocationSettings = $root.waiternow.common.LocationProto.SettingsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.businessLogo = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.businessHeaderForLandscapeScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.businessHeaderForPortraitScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.businessDescription = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.LocationProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RedundantDataProto message.
                 * @function verify
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedundantDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.isBusinessSuspended != null && message.hasOwnProperty("isBusinessSuspended"))
                        if (typeof message.isBusinessSuspended !== "boolean")
                            return "isBusinessSuspended: boolean expected";
                    if (message.businessLocationSettings != null && message.hasOwnProperty("businessLocationSettings")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.verify(message.businessLocationSettings);
                        if (error)
                            return "businessLocationSettings." + error;
                    }
                    if (message.businessLogo != null && message.hasOwnProperty("businessLogo")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.businessLogo);
                        if (error)
                            return "businessLogo." + error;
                    }
                    if (message.businessHeaderForLandscapeScreen != null && message.hasOwnProperty("businessHeaderForLandscapeScreen")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.businessHeaderForLandscapeScreen);
                        if (error)
                            return "businessHeaderForLandscapeScreen." + error;
                    }
                    if (message.businessHeaderForPortraitScreen != null && message.hasOwnProperty("businessHeaderForPortraitScreen")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.businessHeaderForPortraitScreen);
                        if (error)
                            return "businessHeaderForPortraitScreen." + error;
                    }
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        if (!$util.isString(message.businessDescription))
                            return "businessDescription: string expected";
                    return null;
                };

                /**
                 * Creates a RedundantDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.LocationProto.RedundantDataProto} RedundantDataProto
                 */
                RedundantDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.LocationProto.RedundantDataProto)
                        return object;
                    var message = new $root.waiternow.common.LocationProto.RedundantDataProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.isBusinessSuspended != null)
                        message.isBusinessSuspended = Boolean(object.isBusinessSuspended);
                    if (object.businessLocationSettings != null) {
                        if (typeof object.businessLocationSettings !== "object")
                            throw TypeError(".waiternow.common.LocationProto.RedundantDataProto.businessLocationSettings: object expected");
                        message.businessLocationSettings = $root.waiternow.common.LocationProto.SettingsProto.fromObject(object.businessLocationSettings);
                    }
                    if (object.businessLogo != null) {
                        if (typeof object.businessLogo !== "object")
                            throw TypeError(".waiternow.common.LocationProto.RedundantDataProto.businessLogo: object expected");
                        message.businessLogo = $root.waiternow.common.ImageProto.fromObject(object.businessLogo);
                    }
                    if (object.businessHeaderForLandscapeScreen != null) {
                        if (typeof object.businessHeaderForLandscapeScreen !== "object")
                            throw TypeError(".waiternow.common.LocationProto.RedundantDataProto.businessHeaderForLandscapeScreen: object expected");
                        message.businessHeaderForLandscapeScreen = $root.waiternow.common.ImageProto.fromObject(object.businessHeaderForLandscapeScreen);
                    }
                    if (object.businessHeaderForPortraitScreen != null) {
                        if (typeof object.businessHeaderForPortraitScreen !== "object")
                            throw TypeError(".waiternow.common.LocationProto.RedundantDataProto.businessHeaderForPortraitScreen: object expected");
                        message.businessHeaderForPortraitScreen = $root.waiternow.common.ImageProto.fromObject(object.businessHeaderForPortraitScreen);
                    }
                    if (object.businessDescription != null)
                        message.businessDescription = String(object.businessDescription);
                    return message;
                };

                /**
                 * Creates a plain object from a RedundantDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationProto.RedundantDataProto} message RedundantDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedundantDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessName = "";
                        object.isBusinessSuspended = false;
                        object.businessLocationSettings = null;
                        object.businessLogo = null;
                        object.businessHeaderForLandscapeScreen = null;
                        object.businessHeaderForPortraitScreen = null;
                        object.businessDescription = "";
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.isBusinessSuspended != null && message.hasOwnProperty("isBusinessSuspended"))
                        object.isBusinessSuspended = message.isBusinessSuspended;
                    if (message.businessLocationSettings != null && message.hasOwnProperty("businessLocationSettings"))
                        object.businessLocationSettings = $root.waiternow.common.LocationProto.SettingsProto.toObject(message.businessLocationSettings, options);
                    if (message.businessLogo != null && message.hasOwnProperty("businessLogo"))
                        object.businessLogo = $root.waiternow.common.ImageProto.toObject(message.businessLogo, options);
                    if (message.businessHeaderForLandscapeScreen != null && message.hasOwnProperty("businessHeaderForLandscapeScreen"))
                        object.businessHeaderForLandscapeScreen = $root.waiternow.common.ImageProto.toObject(message.businessHeaderForLandscapeScreen, options);
                    if (message.businessHeaderForPortraitScreen != null && message.hasOwnProperty("businessHeaderForPortraitScreen"))
                        object.businessHeaderForPortraitScreen = $root.waiternow.common.ImageProto.toObject(message.businessHeaderForPortraitScreen, options);
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        object.businessDescription = message.businessDescription;
                    return object;
                };

                /**
                 * Converts this RedundantDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedundantDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RedundantDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.LocationProto.RedundantDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedundantDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.LocationProto.RedundantDataProto";
                };

                return RedundantDataProto;
            })();

            LocationProto.MetadataProto = (function() {

                /**
                 * Properties of a MetadataProto.
                 * @memberof waiternow.common.LocationProto
                 * @interface IMetadataProto
                 * @property {string|null} [salespersonId] MetadataProto salespersonId
                 */

                /**
                 * Constructs a new MetadataProto.
                 * @memberof waiternow.common.LocationProto
                 * @classdesc Represents a MetadataProto.
                 * @implements IMetadataProto
                 * @constructor
                 * @param {waiternow.common.LocationProto.IMetadataProto=} [properties] Properties to set
                 */
                function MetadataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MetadataProto salespersonId.
                 * @member {string} salespersonId
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.salespersonId = "";

                /**
                 * Creates a new MetadataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {waiternow.common.LocationProto.IMetadataProto=} [properties] Properties to set
                 * @returns {waiternow.common.LocationProto.MetadataProto} MetadataProto instance
                 */
                MetadataProto.create = function create(properties) {
                    return new MetadataProto(properties);
                };

                /**
                 * Encodes the specified MetadataProto message. Does not implicitly {@link waiternow.common.LocationProto.MetadataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {waiternow.common.LocationProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.salespersonId != null && Object.hasOwnProperty.call(message, "salespersonId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.salespersonId);
                    return writer;
                };

                /**
                 * Encodes the specified MetadataProto message, length delimited. Does not implicitly {@link waiternow.common.LocationProto.MetadataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {waiternow.common.LocationProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.LocationProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationProto.MetadataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.salespersonId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.LocationProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MetadataProto message.
                 * @function verify
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MetadataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        if (!$util.isString(message.salespersonId))
                            return "salespersonId: string expected";
                    return null;
                };

                /**
                 * Creates a MetadataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.LocationProto.MetadataProto} MetadataProto
                 */
                MetadataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.LocationProto.MetadataProto)
                        return object;
                    var message = new $root.waiternow.common.LocationProto.MetadataProto();
                    if (object.salespersonId != null)
                        message.salespersonId = String(object.salespersonId);
                    return message;
                };

                /**
                 * Creates a plain object from a MetadataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {waiternow.common.LocationProto.MetadataProto} message MetadataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MetadataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.salespersonId = "";
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        object.salespersonId = message.salespersonId;
                    return object;
                };

                /**
                 * Converts this MetadataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MetadataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MetadataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.LocationProto.MetadataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MetadataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.LocationProto.MetadataProto";
                };

                return MetadataProto;
            })();

            return LocationProto;
        })();

        common.LocationsProto = (function() {

            /**
             * Properties of a LocationsProto.
             * @memberof waiternow.common
             * @interface ILocationsProto
             * @property {Array.<waiternow.common.ILocationProto>|null} [locations] LocationsProto locations
             * @property {string|null} [continuationToken] LocationsProto continuationToken
             */

            /**
             * Constructs a new LocationsProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationsProto.
             * @implements ILocationsProto
             * @constructor
             * @param {waiternow.common.ILocationsProto=} [properties] Properties to set
             */
            function LocationsProto(properties) {
                this.locations = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationsProto locations.
             * @member {Array.<waiternow.common.ILocationProto>} locations
             * @memberof waiternow.common.LocationsProto
             * @instance
             */
            LocationsProto.prototype.locations = $util.emptyArray;

            /**
             * LocationsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.LocationsProto
             * @instance
             */
            LocationsProto.prototype.continuationToken = "";

            /**
             * Creates a new LocationsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {waiternow.common.ILocationsProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationsProto} LocationsProto instance
             */
            LocationsProto.create = function create(properties) {
                return new LocationsProto(properties);
            };

            /**
             * Encodes the specified LocationsProto message. Does not implicitly {@link waiternow.common.LocationsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {waiternow.common.ILocationsProto} message LocationsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locations != null && message.locations.length)
                    for (var i = 0; i < message.locations.length; ++i)
                        $root.waiternow.common.LocationProto.encode(message.locations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified LocationsProto message, length delimited. Does not implicitly {@link waiternow.common.LocationsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {waiternow.common.ILocationsProto} message LocationsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationsProto} LocationsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.locations && message.locations.length))
                                message.locations = [];
                            message.locations.push($root.waiternow.common.LocationProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationsProto} LocationsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationsProto message.
             * @function verify
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locations != null && message.hasOwnProperty("locations")) {
                    if (!Array.isArray(message.locations))
                        return "locations: array expected";
                    for (var i = 0; i < message.locations.length; ++i) {
                        var error = $root.waiternow.common.LocationProto.verify(message.locations[i]);
                        if (error)
                            return "locations." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a LocationsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationsProto} LocationsProto
             */
            LocationsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationsProto)
                    return object;
                var message = new $root.waiternow.common.LocationsProto();
                if (object.locations) {
                    if (!Array.isArray(object.locations))
                        throw TypeError(".waiternow.common.LocationsProto.locations: array expected");
                    message.locations = [];
                    for (var i = 0; i < object.locations.length; ++i) {
                        if (typeof object.locations[i] !== "object")
                            throw TypeError(".waiternow.common.LocationsProto.locations: object expected");
                        message.locations[i] = $root.waiternow.common.LocationProto.fromObject(object.locations[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a LocationsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {waiternow.common.LocationsProto} message LocationsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.locations = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.locations && message.locations.length) {
                    object.locations = [];
                    for (var j = 0; j < message.locations.length; ++j)
                        object.locations[j] = $root.waiternow.common.LocationProto.toObject(message.locations[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this LocationsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationsProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationsProto";
            };

            return LocationsProto;
        })();

        common.BusinessLocationsProto = (function() {

            /**
             * Properties of a BusinessLocationsProto.
             * @memberof waiternow.common
             * @interface IBusinessLocationsProto
             * @property {Array.<waiternow.common.ILocationProto>|null} [locations] BusinessLocationsProto locations
             */

            /**
             * Constructs a new BusinessLocationsProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessLocationsProto.
             * @implements IBusinessLocationsProto
             * @constructor
             * @param {waiternow.common.IBusinessLocationsProto=} [properties] Properties to set
             */
            function BusinessLocationsProto(properties) {
                this.locations = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessLocationsProto locations.
             * @member {Array.<waiternow.common.ILocationProto>} locations
             * @memberof waiternow.common.BusinessLocationsProto
             * @instance
             */
            BusinessLocationsProto.prototype.locations = $util.emptyArray;

            /**
             * Creates a new BusinessLocationsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {waiternow.common.IBusinessLocationsProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessLocationsProto} BusinessLocationsProto instance
             */
            BusinessLocationsProto.create = function create(properties) {
                return new BusinessLocationsProto(properties);
            };

            /**
             * Encodes the specified BusinessLocationsProto message. Does not implicitly {@link waiternow.common.BusinessLocationsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {waiternow.common.IBusinessLocationsProto} message BusinessLocationsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessLocationsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locations != null && message.locations.length)
                    for (var i = 0; i < message.locations.length; ++i)
                        $root.waiternow.common.LocationProto.encode(message.locations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified BusinessLocationsProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessLocationsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {waiternow.common.IBusinessLocationsProto} message BusinessLocationsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessLocationsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessLocationsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessLocationsProto} BusinessLocationsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessLocationsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessLocationsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.locations && message.locations.length))
                                message.locations = [];
                            message.locations.push($root.waiternow.common.LocationProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessLocationsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessLocationsProto} BusinessLocationsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessLocationsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessLocationsProto message.
             * @function verify
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessLocationsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locations != null && message.hasOwnProperty("locations")) {
                    if (!Array.isArray(message.locations))
                        return "locations: array expected";
                    for (var i = 0; i < message.locations.length; ++i) {
                        var error = $root.waiternow.common.LocationProto.verify(message.locations[i]);
                        if (error)
                            return "locations." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a BusinessLocationsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessLocationsProto} BusinessLocationsProto
             */
            BusinessLocationsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessLocationsProto)
                    return object;
                var message = new $root.waiternow.common.BusinessLocationsProto();
                if (object.locations) {
                    if (!Array.isArray(object.locations))
                        throw TypeError(".waiternow.common.BusinessLocationsProto.locations: array expected");
                    message.locations = [];
                    for (var i = 0; i < object.locations.length; ++i) {
                        if (typeof object.locations[i] !== "object")
                            throw TypeError(".waiternow.common.BusinessLocationsProto.locations: object expected");
                        message.locations[i] = $root.waiternow.common.LocationProto.fromObject(object.locations[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a BusinessLocationsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {waiternow.common.BusinessLocationsProto} message BusinessLocationsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessLocationsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.locations = [];
                if (message.locations && message.locations.length) {
                    object.locations = [];
                    for (var j = 0; j < message.locations.length; ++j)
                        object.locations[j] = $root.waiternow.common.LocationProto.toObject(message.locations[j], options);
                }
                return object;
            };

            /**
             * Converts this BusinessLocationsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessLocationsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessLocationsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessLocationsProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessLocationsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessLocationsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessLocationsProto";
            };

            return BusinessLocationsProto;
        })();

        common.UserLocationsProto = (function() {

            /**
             * Properties of a UserLocationsProto.
             * @memberof waiternow.common
             * @interface IUserLocationsProto
             * @property {string|null} [userId] UserLocationsProto userId
             * @property {Array.<waiternow.common.UserLocationsProto.IManagedLocationProto>|null} [locations] UserLocationsProto locations
             */

            /**
             * Constructs a new UserLocationsProto.
             * @memberof waiternow.common
             * @classdesc Represents a UserLocationsProto.
             * @implements IUserLocationsProto
             * @constructor
             * @param {waiternow.common.IUserLocationsProto=} [properties] Properties to set
             */
            function UserLocationsProto(properties) {
                this.locations = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserLocationsProto userId.
             * @member {string} userId
             * @memberof waiternow.common.UserLocationsProto
             * @instance
             */
            UserLocationsProto.prototype.userId = "";

            /**
             * UserLocationsProto locations.
             * @member {Array.<waiternow.common.UserLocationsProto.IManagedLocationProto>} locations
             * @memberof waiternow.common.UserLocationsProto
             * @instance
             */
            UserLocationsProto.prototype.locations = $util.emptyArray;

            /**
             * Creates a new UserLocationsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {waiternow.common.IUserLocationsProto=} [properties] Properties to set
             * @returns {waiternow.common.UserLocationsProto} UserLocationsProto instance
             */
            UserLocationsProto.create = function create(properties) {
                return new UserLocationsProto(properties);
            };

            /**
             * Encodes the specified UserLocationsProto message. Does not implicitly {@link waiternow.common.UserLocationsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {waiternow.common.IUserLocationsProto} message UserLocationsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserLocationsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.locations != null && message.locations.length)
                    for (var i = 0; i < message.locations.length; ++i)
                        $root.waiternow.common.UserLocationsProto.ManagedLocationProto.encode(message.locations[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UserLocationsProto message, length delimited. Does not implicitly {@link waiternow.common.UserLocationsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {waiternow.common.IUserLocationsProto} message UserLocationsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserLocationsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserLocationsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UserLocationsProto} UserLocationsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserLocationsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserLocationsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.locations && message.locations.length))
                                message.locations = [];
                            message.locations.push($root.waiternow.common.UserLocationsProto.ManagedLocationProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserLocationsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UserLocationsProto} UserLocationsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserLocationsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserLocationsProto message.
             * @function verify
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserLocationsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.locations != null && message.hasOwnProperty("locations")) {
                    if (!Array.isArray(message.locations))
                        return "locations: array expected";
                    for (var i = 0; i < message.locations.length; ++i) {
                        var error = $root.waiternow.common.UserLocationsProto.ManagedLocationProto.verify(message.locations[i]);
                        if (error)
                            return "locations." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a UserLocationsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UserLocationsProto} UserLocationsProto
             */
            UserLocationsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UserLocationsProto)
                    return object;
                var message = new $root.waiternow.common.UserLocationsProto();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.locations) {
                    if (!Array.isArray(object.locations))
                        throw TypeError(".waiternow.common.UserLocationsProto.locations: array expected");
                    message.locations = [];
                    for (var i = 0; i < object.locations.length; ++i) {
                        if (typeof object.locations[i] !== "object")
                            throw TypeError(".waiternow.common.UserLocationsProto.locations: object expected");
                        message.locations[i] = $root.waiternow.common.UserLocationsProto.ManagedLocationProto.fromObject(object.locations[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a UserLocationsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {waiternow.common.UserLocationsProto} message UserLocationsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserLocationsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.locations = [];
                if (options.defaults)
                    object.userId = "";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.locations && message.locations.length) {
                    object.locations = [];
                    for (var j = 0; j < message.locations.length; ++j)
                        object.locations[j] = $root.waiternow.common.UserLocationsProto.ManagedLocationProto.toObject(message.locations[j], options);
                }
                return object;
            };

            /**
             * Converts this UserLocationsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UserLocationsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserLocationsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserLocationsProto
             * @function getTypeUrl
             * @memberof waiternow.common.UserLocationsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserLocationsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UserLocationsProto";
            };

            UserLocationsProto.ManagedLocationProto = (function() {

                /**
                 * Properties of a ManagedLocationProto.
                 * @memberof waiternow.common.UserLocationsProto
                 * @interface IManagedLocationProto
                 * @property {waiternow.common.ILocationProto|null} [location] ManagedLocationProto location
                 * @property {waiternow.common.Role|null} [userRole] ManagedLocationProto userRole
                 */

                /**
                 * Constructs a new ManagedLocationProto.
                 * @memberof waiternow.common.UserLocationsProto
                 * @classdesc Represents a ManagedLocationProto.
                 * @implements IManagedLocationProto
                 * @constructor
                 * @param {waiternow.common.UserLocationsProto.IManagedLocationProto=} [properties] Properties to set
                 */
                function ManagedLocationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ManagedLocationProto location.
                 * @member {waiternow.common.ILocationProto|null|undefined} location
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @instance
                 */
                ManagedLocationProto.prototype.location = null;

                /**
                 * ManagedLocationProto userRole.
                 * @member {waiternow.common.Role} userRole
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @instance
                 */
                ManagedLocationProto.prototype.userRole = 0;

                /**
                 * Creates a new ManagedLocationProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {waiternow.common.UserLocationsProto.IManagedLocationProto=} [properties] Properties to set
                 * @returns {waiternow.common.UserLocationsProto.ManagedLocationProto} ManagedLocationProto instance
                 */
                ManagedLocationProto.create = function create(properties) {
                    return new ManagedLocationProto(properties);
                };

                /**
                 * Encodes the specified ManagedLocationProto message. Does not implicitly {@link waiternow.common.UserLocationsProto.ManagedLocationProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {waiternow.common.UserLocationsProto.IManagedLocationProto} message ManagedLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagedLocationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                        $root.waiternow.common.LocationProto.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.userRole != null && Object.hasOwnProperty.call(message, "userRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userRole);
                    return writer;
                };

                /**
                 * Encodes the specified ManagedLocationProto message, length delimited. Does not implicitly {@link waiternow.common.UserLocationsProto.ManagedLocationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {waiternow.common.UserLocationsProto.IManagedLocationProto} message ManagedLocationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagedLocationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ManagedLocationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UserLocationsProto.ManagedLocationProto} ManagedLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagedLocationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserLocationsProto.ManagedLocationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.location = $root.waiternow.common.LocationProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.userRole = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ManagedLocationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UserLocationsProto.ManagedLocationProto} ManagedLocationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagedLocationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ManagedLocationProto message.
                 * @function verify
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ManagedLocationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.location != null && message.hasOwnProperty("location")) {
                        var error = $root.waiternow.common.LocationProto.verify(message.location);
                        if (error)
                            return "location." + error;
                    }
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        switch (message.userRole) {
                        default:
                            return "userRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ManagedLocationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UserLocationsProto.ManagedLocationProto} ManagedLocationProto
                 */
                ManagedLocationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UserLocationsProto.ManagedLocationProto)
                        return object;
                    var message = new $root.waiternow.common.UserLocationsProto.ManagedLocationProto();
                    if (object.location != null) {
                        if (typeof object.location !== "object")
                            throw TypeError(".waiternow.common.UserLocationsProto.ManagedLocationProto.location: object expected");
                        message.location = $root.waiternow.common.LocationProto.fromObject(object.location);
                    }
                    switch (object.userRole) {
                    default:
                        if (typeof object.userRole === "number") {
                            message.userRole = object.userRole;
                            break;
                        }
                        break;
                    case "ROLE_UNSPECIFIED":
                    case 0:
                        message.userRole = 0;
                        break;
                    case "OWNER":
                    case 1:
                        message.userRole = 1;
                        break;
                    case "ADMIN":
                    case 2:
                        message.userRole = 2;
                        break;
                    case "VIEWER":
                    case 3:
                        message.userRole = 3;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ManagedLocationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {waiternow.common.UserLocationsProto.ManagedLocationProto} message ManagedLocationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ManagedLocationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.location = null;
                        object.userRole = options.enums === String ? "ROLE_UNSPECIFIED" : 0;
                    }
                    if (message.location != null && message.hasOwnProperty("location"))
                        object.location = $root.waiternow.common.LocationProto.toObject(message.location, options);
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        object.userRole = options.enums === String ? $root.waiternow.common.Role[message.userRole] === undefined ? message.userRole : $root.waiternow.common.Role[message.userRole] : message.userRole;
                    return object;
                };

                /**
                 * Converts this ManagedLocationProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ManagedLocationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ManagedLocationProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.UserLocationsProto.ManagedLocationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ManagedLocationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UserLocationsProto.ManagedLocationProto";
                };

                return ManagedLocationProto;
            })();

            return UserLocationsProto;
        })();

        common.LocationUsersProto = (function() {

            /**
             * Properties of a LocationUsersProto.
             * @memberof waiternow.common
             * @interface ILocationUsersProto
             * @property {string|null} [locationId] LocationUsersProto locationId
             * @property {Array.<waiternow.common.LocationUsersProto.IManagerUserProto>|null} [users] LocationUsersProto users
             */

            /**
             * Constructs a new LocationUsersProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationUsersProto.
             * @implements ILocationUsersProto
             * @constructor
             * @param {waiternow.common.ILocationUsersProto=} [properties] Properties to set
             */
            function LocationUsersProto(properties) {
                this.users = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationUsersProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.LocationUsersProto
             * @instance
             */
            LocationUsersProto.prototype.locationId = "";

            /**
             * LocationUsersProto users.
             * @member {Array.<waiternow.common.LocationUsersProto.IManagerUserProto>} users
             * @memberof waiternow.common.LocationUsersProto
             * @instance
             */
            LocationUsersProto.prototype.users = $util.emptyArray;

            /**
             * Creates a new LocationUsersProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {waiternow.common.ILocationUsersProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationUsersProto} LocationUsersProto instance
             */
            LocationUsersProto.create = function create(properties) {
                return new LocationUsersProto(properties);
            };

            /**
             * Encodes the specified LocationUsersProto message. Does not implicitly {@link waiternow.common.LocationUsersProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {waiternow.common.ILocationUsersProto} message LocationUsersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationUsersProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.users != null && message.users.length)
                    for (var i = 0; i < message.users.length; ++i)
                        $root.waiternow.common.LocationUsersProto.ManagerUserProto.encode(message.users[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LocationUsersProto message, length delimited. Does not implicitly {@link waiternow.common.LocationUsersProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {waiternow.common.ILocationUsersProto} message LocationUsersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationUsersProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationUsersProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationUsersProto} LocationUsersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationUsersProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationUsersProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.users && message.users.length))
                                message.users = [];
                            message.users.push($root.waiternow.common.LocationUsersProto.ManagerUserProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationUsersProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationUsersProto} LocationUsersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationUsersProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationUsersProto message.
             * @function verify
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationUsersProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.users != null && message.hasOwnProperty("users")) {
                    if (!Array.isArray(message.users))
                        return "users: array expected";
                    for (var i = 0; i < message.users.length; ++i) {
                        var error = $root.waiternow.common.LocationUsersProto.ManagerUserProto.verify(message.users[i]);
                        if (error)
                            return "users." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a LocationUsersProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationUsersProto} LocationUsersProto
             */
            LocationUsersProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationUsersProto)
                    return object;
                var message = new $root.waiternow.common.LocationUsersProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.users) {
                    if (!Array.isArray(object.users))
                        throw TypeError(".waiternow.common.LocationUsersProto.users: array expected");
                    message.users = [];
                    for (var i = 0; i < object.users.length; ++i) {
                        if (typeof object.users[i] !== "object")
                            throw TypeError(".waiternow.common.LocationUsersProto.users: object expected");
                        message.users[i] = $root.waiternow.common.LocationUsersProto.ManagerUserProto.fromObject(object.users[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a LocationUsersProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {waiternow.common.LocationUsersProto} message LocationUsersProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationUsersProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.users = [];
                if (options.defaults)
                    object.locationId = "";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.users && message.users.length) {
                    object.users = [];
                    for (var j = 0; j < message.users.length; ++j)
                        object.users[j] = $root.waiternow.common.LocationUsersProto.ManagerUserProto.toObject(message.users[j], options);
                }
                return object;
            };

            /**
             * Converts this LocationUsersProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationUsersProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationUsersProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationUsersProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationUsersProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationUsersProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationUsersProto";
            };

            LocationUsersProto.ManagerUserProto = (function() {

                /**
                 * Properties of a ManagerUserProto.
                 * @memberof waiternow.common.LocationUsersProto
                 * @interface IManagerUserProto
                 * @property {string|null} [userEmail] ManagerUserProto userEmail
                 * @property {waiternow.common.Role|null} [userRole] ManagerUserProto userRole
                 */

                /**
                 * Constructs a new ManagerUserProto.
                 * @memberof waiternow.common.LocationUsersProto
                 * @classdesc Represents a ManagerUserProto.
                 * @implements IManagerUserProto
                 * @constructor
                 * @param {waiternow.common.LocationUsersProto.IManagerUserProto=} [properties] Properties to set
                 */
                function ManagerUserProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ManagerUserProto userEmail.
                 * @member {string} userEmail
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.userEmail = "";

                /**
                 * ManagerUserProto userRole.
                 * @member {waiternow.common.Role} userRole
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @instance
                 */
                ManagerUserProto.prototype.userRole = 0;

                /**
                 * Creates a new ManagerUserProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.LocationUsersProto.IManagerUserProto=} [properties] Properties to set
                 * @returns {waiternow.common.LocationUsersProto.ManagerUserProto} ManagerUserProto instance
                 */
                ManagerUserProto.create = function create(properties) {
                    return new ManagerUserProto(properties);
                };

                /**
                 * Encodes the specified ManagerUserProto message. Does not implicitly {@link waiternow.common.LocationUsersProto.ManagerUserProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.LocationUsersProto.IManagerUserProto} message ManagerUserProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagerUserProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userEmail);
                    if (message.userRole != null && Object.hasOwnProperty.call(message, "userRole"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.userRole);
                    return writer;
                };

                /**
                 * Encodes the specified ManagerUserProto message, length delimited. Does not implicitly {@link waiternow.common.LocationUsersProto.ManagerUserProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.LocationUsersProto.IManagerUserProto} message ManagerUserProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ManagerUserProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ManagerUserProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.LocationUsersProto.ManagerUserProto} ManagerUserProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagerUserProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationUsersProto.ManagerUserProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userEmail = reader.string();
                                break;
                            }
                        case 2: {
                                message.userRole = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ManagerUserProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.LocationUsersProto.ManagerUserProto} ManagerUserProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ManagerUserProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ManagerUserProto message.
                 * @function verify
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ManagerUserProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        switch (message.userRole) {
                        default:
                            return "userRole: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a ManagerUserProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.LocationUsersProto.ManagerUserProto} ManagerUserProto
                 */
                ManagerUserProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.LocationUsersProto.ManagerUserProto)
                        return object;
                    var message = new $root.waiternow.common.LocationUsersProto.ManagerUserProto();
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    switch (object.userRole) {
                    default:
                        if (typeof object.userRole === "number") {
                            message.userRole = object.userRole;
                            break;
                        }
                        break;
                    case "ROLE_UNSPECIFIED":
                    case 0:
                        message.userRole = 0;
                        break;
                    case "OWNER":
                    case 1:
                        message.userRole = 1;
                        break;
                    case "ADMIN":
                    case 2:
                        message.userRole = 2;
                        break;
                    case "VIEWER":
                    case 3:
                        message.userRole = 3;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ManagerUserProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {waiternow.common.LocationUsersProto.ManagerUserProto} message ManagerUserProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ManagerUserProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userEmail = "";
                        object.userRole = options.enums === String ? "ROLE_UNSPECIFIED" : 0;
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    if (message.userRole != null && message.hasOwnProperty("userRole"))
                        object.userRole = options.enums === String ? $root.waiternow.common.Role[message.userRole] === undefined ? message.userRole : $root.waiternow.common.Role[message.userRole] : message.userRole;
                    return object;
                };

                /**
                 * Converts this ManagerUserProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ManagerUserProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ManagerUserProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.LocationUsersProto.ManagerUserProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ManagerUserProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.LocationUsersProto.ManagerUserProto";
                };

                return ManagerUserProto;
            })();

            return LocationUsersProto;
        })();

        common.LocationBusinessHoursProto = (function() {

            /**
             * Properties of a LocationBusinessHoursProto.
             * @memberof waiternow.common
             * @interface ILocationBusinessHoursProto
             * @property {string|null} [locationId] LocationBusinessHoursProto locationId
             * @property {waiternow.common.IBusinessHoursProto|null} [businessHours] LocationBusinessHoursProto businessHours
             * @property {string|null} [timeZone] LocationBusinessHoursProto timeZone
             * @property {boolean|null} [isLocationOnline] LocationBusinessHoursProto isLocationOnline
             * @property {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto|null} [alertConfig] LocationBusinessHoursProto alertConfig
             * @property {google.protobuf.ITimestamp|null} [lastAlert] LocationBusinessHoursProto lastAlert
             * @property {waiternow.common.LocationBusinessHoursProto.IRedundantDataProto|null} [redundantData] LocationBusinessHoursProto redundantData
             */

            /**
             * Constructs a new LocationBusinessHoursProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationBusinessHoursProto.
             * @implements ILocationBusinessHoursProto
             * @constructor
             * @param {waiternow.common.ILocationBusinessHoursProto=} [properties] Properties to set
             */
            function LocationBusinessHoursProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationBusinessHoursProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             */
            LocationBusinessHoursProto.prototype.locationId = "";

            /**
             * LocationBusinessHoursProto businessHours.
             * @member {waiternow.common.IBusinessHoursProto|null|undefined} businessHours
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             */
            LocationBusinessHoursProto.prototype.businessHours = null;

            /**
             * LocationBusinessHoursProto timeZone.
             * @member {string} timeZone
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             */
            LocationBusinessHoursProto.prototype.timeZone = "";

            /**
             * LocationBusinessHoursProto isLocationOnline.
             * @member {boolean} isLocationOnline
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             */
            LocationBusinessHoursProto.prototype.isLocationOnline = false;

            /**
             * LocationBusinessHoursProto alertConfig.
             * @member {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IAlertConfigProto|null|undefined} alertConfig
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             */
            LocationBusinessHoursProto.prototype.alertConfig = null;

            /**
             * LocationBusinessHoursProto lastAlert.
             * @member {google.protobuf.ITimestamp|null|undefined} lastAlert
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             */
            LocationBusinessHoursProto.prototype.lastAlert = null;

            /**
             * LocationBusinessHoursProto redundantData.
             * @member {waiternow.common.LocationBusinessHoursProto.IRedundantDataProto|null|undefined} redundantData
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             */
            LocationBusinessHoursProto.prototype.redundantData = null;

            /**
             * Creates a new LocationBusinessHoursProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {waiternow.common.ILocationBusinessHoursProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationBusinessHoursProto} LocationBusinessHoursProto instance
             */
            LocationBusinessHoursProto.create = function create(properties) {
                return new LocationBusinessHoursProto(properties);
            };

            /**
             * Encodes the specified LocationBusinessHoursProto message. Does not implicitly {@link waiternow.common.LocationBusinessHoursProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {waiternow.common.ILocationBusinessHoursProto} message LocationBusinessHoursProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationBusinessHoursProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.businessHours != null && Object.hasOwnProperty.call(message, "businessHours"))
                    $root.waiternow.common.BusinessHoursProto.encode(message.businessHours, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.timeZone != null && Object.hasOwnProperty.call(message, "timeZone"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.timeZone);
                if (message.isLocationOnline != null && Object.hasOwnProperty.call(message, "isLocationOnline"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isLocationOnline);
                if (message.alertConfig != null && Object.hasOwnProperty.call(message, "alertConfig"))
                    $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.encode(message.alertConfig, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.lastAlert != null && Object.hasOwnProperty.call(message, "lastAlert"))
                    $root.google.protobuf.Timestamp.encode(message.lastAlert, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.redundantData != null && Object.hasOwnProperty.call(message, "redundantData"))
                    $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto.encode(message.redundantData, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LocationBusinessHoursProto message, length delimited. Does not implicitly {@link waiternow.common.LocationBusinessHoursProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {waiternow.common.ILocationBusinessHoursProto} message LocationBusinessHoursProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationBusinessHoursProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationBusinessHoursProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationBusinessHoursProto} LocationBusinessHoursProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationBusinessHoursProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationBusinessHoursProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            message.businessHours = $root.waiternow.common.BusinessHoursProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.timeZone = reader.string();
                            break;
                        }
                    case 4: {
                            message.isLocationOnline = reader.bool();
                            break;
                        }
                    case 5: {
                            message.alertConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.lastAlert = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.redundantData = $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationBusinessHoursProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationBusinessHoursProto} LocationBusinessHoursProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationBusinessHoursProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationBusinessHoursProto message.
             * @function verify
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationBusinessHoursProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.businessHours != null && message.hasOwnProperty("businessHours")) {
                    var error = $root.waiternow.common.BusinessHoursProto.verify(message.businessHours);
                    if (error)
                        return "businessHours." + error;
                }
                if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                    if (!$util.isString(message.timeZone))
                        return "timeZone: string expected";
                if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                    if (typeof message.isLocationOnline !== "boolean")
                        return "isLocationOnline: boolean expected";
                if (message.alertConfig != null && message.hasOwnProperty("alertConfig")) {
                    var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.verify(message.alertConfig);
                    if (error)
                        return "alertConfig." + error;
                }
                if (message.lastAlert != null && message.hasOwnProperty("lastAlert")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastAlert);
                    if (error)
                        return "lastAlert." + error;
                }
                if (message.redundantData != null && message.hasOwnProperty("redundantData")) {
                    var error = $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto.verify(message.redundantData);
                    if (error)
                        return "redundantData." + error;
                }
                return null;
            };

            /**
             * Creates a LocationBusinessHoursProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationBusinessHoursProto} LocationBusinessHoursProto
             */
            LocationBusinessHoursProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationBusinessHoursProto)
                    return object;
                var message = new $root.waiternow.common.LocationBusinessHoursProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.businessHours != null) {
                    if (typeof object.businessHours !== "object")
                        throw TypeError(".waiternow.common.LocationBusinessHoursProto.businessHours: object expected");
                    message.businessHours = $root.waiternow.common.BusinessHoursProto.fromObject(object.businessHours);
                }
                if (object.timeZone != null)
                    message.timeZone = String(object.timeZone);
                if (object.isLocationOnline != null)
                    message.isLocationOnline = Boolean(object.isLocationOnline);
                if (object.alertConfig != null) {
                    if (typeof object.alertConfig !== "object")
                        throw TypeError(".waiternow.common.LocationBusinessHoursProto.alertConfig: object expected");
                    message.alertConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.fromObject(object.alertConfig);
                }
                if (object.lastAlert != null) {
                    if (typeof object.lastAlert !== "object")
                        throw TypeError(".waiternow.common.LocationBusinessHoursProto.lastAlert: object expected");
                    message.lastAlert = $root.google.protobuf.Timestamp.fromObject(object.lastAlert);
                }
                if (object.redundantData != null) {
                    if (typeof object.redundantData !== "object")
                        throw TypeError(".waiternow.common.LocationBusinessHoursProto.redundantData: object expected");
                    message.redundantData = $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto.fromObject(object.redundantData);
                }
                return message;
            };

            /**
             * Creates a plain object from a LocationBusinessHoursProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {waiternow.common.LocationBusinessHoursProto} message LocationBusinessHoursProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationBusinessHoursProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.locationId = "";
                    object.businessHours = null;
                    object.timeZone = "";
                    object.isLocationOnline = false;
                    object.alertConfig = null;
                    object.lastAlert = null;
                    object.redundantData = null;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                    object.businessHours = $root.waiternow.common.BusinessHoursProto.toObject(message.businessHours, options);
                if (message.timeZone != null && message.hasOwnProperty("timeZone"))
                    object.timeZone = message.timeZone;
                if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                    object.isLocationOnline = message.isLocationOnline;
                if (message.alertConfig != null && message.hasOwnProperty("alertConfig"))
                    object.alertConfig = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.AlertConfigProto.toObject(message.alertConfig, options);
                if (message.lastAlert != null && message.hasOwnProperty("lastAlert"))
                    object.lastAlert = $root.google.protobuf.Timestamp.toObject(message.lastAlert, options);
                if (message.redundantData != null && message.hasOwnProperty("redundantData"))
                    object.redundantData = $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto.toObject(message.redundantData, options);
                return object;
            };

            /**
             * Converts this LocationBusinessHoursProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationBusinessHoursProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationBusinessHoursProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationBusinessHoursProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationBusinessHoursProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationBusinessHoursProto";
            };

            LocationBusinessHoursProto.RedundantDataProto = (function() {

                /**
                 * Properties of a RedundantDataProto.
                 * @memberof waiternow.common.LocationBusinessHoursProto
                 * @interface IRedundantDataProto
                 * @property {string|null} [businessName] RedundantDataProto businessName
                 * @property {string|null} [locationName] RedundantDataProto locationName
                 * @property {waiternow.common.IAddressProto|null} [locationAddress] RedundantDataProto locationAddress
                 */

                /**
                 * Constructs a new RedundantDataProto.
                 * @memberof waiternow.common.LocationBusinessHoursProto
                 * @classdesc Represents a RedundantDataProto.
                 * @implements IRedundantDataProto
                 * @constructor
                 * @param {waiternow.common.LocationBusinessHoursProto.IRedundantDataProto=} [properties] Properties to set
                 */
                function RedundantDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RedundantDataProto businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessName = "";

                /**
                 * RedundantDataProto locationName.
                 * @member {string} locationName
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationName = "";

                /**
                 * RedundantDataProto locationAddress.
                 * @member {waiternow.common.IAddressProto|null|undefined} locationAddress
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationAddress = null;

                /**
                 * Creates a new RedundantDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationBusinessHoursProto.IRedundantDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.LocationBusinessHoursProto.RedundantDataProto} RedundantDataProto instance
                 */
                RedundantDataProto.create = function create(properties) {
                    return new RedundantDataProto(properties);
                };

                /**
                 * Encodes the specified RedundantDataProto message. Does not implicitly {@link waiternow.common.LocationBusinessHoursProto.RedundantDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationBusinessHoursProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.locationAddress != null && Object.hasOwnProperty.call(message, "locationAddress"))
                        $root.waiternow.common.AddressProto.encode(message.locationAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.locationName);
                    return writer;
                };

                /**
                 * Encodes the specified RedundantDataProto message, length delimited. Does not implicitly {@link waiternow.common.LocationBusinessHoursProto.RedundantDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationBusinessHoursProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.LocationBusinessHoursProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 3: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.LocationBusinessHoursProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RedundantDataProto message.
                 * @function verify
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedundantDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.locationAddress);
                        if (error)
                            return "locationAddress." + error;
                    }
                    return null;
                };

                /**
                 * Creates a RedundantDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.LocationBusinessHoursProto.RedundantDataProto} RedundantDataProto
                 */
                RedundantDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto)
                        return object;
                    var message = new $root.waiternow.common.LocationBusinessHoursProto.RedundantDataProto();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.locationAddress != null) {
                        if (typeof object.locationAddress !== "object")
                            throw TypeError(".waiternow.common.LocationBusinessHoursProto.RedundantDataProto.locationAddress: object expected");
                        message.locationAddress = $root.waiternow.common.AddressProto.fromObject(object.locationAddress);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RedundantDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.LocationBusinessHoursProto.RedundantDataProto} message RedundantDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedundantDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessName = "";
                        object.locationAddress = null;
                        object.locationName = "";
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress"))
                        object.locationAddress = $root.waiternow.common.AddressProto.toObject(message.locationAddress, options);
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    return object;
                };

                /**
                 * Converts this RedundantDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedundantDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RedundantDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.LocationBusinessHoursProto.RedundantDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedundantDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.LocationBusinessHoursProto.RedundantDataProto";
                };

                return RedundantDataProto;
            })();

            return LocationBusinessHoursProto;
        })();

        common.MenuProto = (function() {

            /**
             * Properties of a MenuProto.
             * @memberof waiternow.common
             * @interface IMenuProto
             * @property {Array.<waiternow.common.MenuProto.ISubmenuProto>|null} [submenus] MenuProto submenus
             */

            /**
             * Constructs a new MenuProto.
             * @memberof waiternow.common
             * @classdesc Represents a MenuProto.
             * @implements IMenuProto
             * @constructor
             * @param {waiternow.common.IMenuProto=} [properties] Properties to set
             */
            function MenuProto(properties) {
                this.submenus = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MenuProto submenus.
             * @member {Array.<waiternow.common.MenuProto.ISubmenuProto>} submenus
             * @memberof waiternow.common.MenuProto
             * @instance
             */
            MenuProto.prototype.submenus = $util.emptyArray;

            /**
             * Creates a new MenuProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {waiternow.common.IMenuProto=} [properties] Properties to set
             * @returns {waiternow.common.MenuProto} MenuProto instance
             */
            MenuProto.create = function create(properties) {
                return new MenuProto(properties);
            };

            /**
             * Encodes the specified MenuProto message. Does not implicitly {@link waiternow.common.MenuProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {waiternow.common.IMenuProto} message MenuProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MenuProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.submenus != null && message.submenus.length)
                    for (var i = 0; i < message.submenus.length; ++i)
                        $root.waiternow.common.MenuProto.SubmenuProto.encode(message.submenus[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MenuProto message, length delimited. Does not implicitly {@link waiternow.common.MenuProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {waiternow.common.IMenuProto} message MenuProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MenuProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MenuProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MenuProto} MenuProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MenuProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MenuProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.submenus && message.submenus.length))
                                message.submenus = [];
                            message.submenus.push($root.waiternow.common.MenuProto.SubmenuProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MenuProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MenuProto} MenuProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MenuProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MenuProto message.
             * @function verify
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MenuProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.submenus != null && message.hasOwnProperty("submenus")) {
                    if (!Array.isArray(message.submenus))
                        return "submenus: array expected";
                    for (var i = 0; i < message.submenus.length; ++i) {
                        var error = $root.waiternow.common.MenuProto.SubmenuProto.verify(message.submenus[i]);
                        if (error)
                            return "submenus." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a MenuProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MenuProto} MenuProto
             */
            MenuProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MenuProto)
                    return object;
                var message = new $root.waiternow.common.MenuProto();
                if (object.submenus) {
                    if (!Array.isArray(object.submenus))
                        throw TypeError(".waiternow.common.MenuProto.submenus: array expected");
                    message.submenus = [];
                    for (var i = 0; i < object.submenus.length; ++i) {
                        if (typeof object.submenus[i] !== "object")
                            throw TypeError(".waiternow.common.MenuProto.submenus: object expected");
                        message.submenus[i] = $root.waiternow.common.MenuProto.SubmenuProto.fromObject(object.submenus[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a MenuProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {waiternow.common.MenuProto} message MenuProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MenuProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.submenus = [];
                if (message.submenus && message.submenus.length) {
                    object.submenus = [];
                    for (var j = 0; j < message.submenus.length; ++j)
                        object.submenus[j] = $root.waiternow.common.MenuProto.SubmenuProto.toObject(message.submenus[j], options);
                }
                return object;
            };

            /**
             * Converts this MenuProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MenuProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MenuProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MenuProto
             * @function getTypeUrl
             * @memberof waiternow.common.MenuProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MenuProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MenuProto";
            };

            MenuProto.SubmenuProto = (function() {

                /**
                 * Properties of a SubmenuProto.
                 * @memberof waiternow.common.MenuProto
                 * @interface ISubmenuProto
                 * @property {string|null} [name] SubmenuProto name
                 * @property {Array.<waiternow.common.IImageProto>|null} [images] SubmenuProto images
                 */

                /**
                 * Constructs a new SubmenuProto.
                 * @memberof waiternow.common.MenuProto
                 * @classdesc Represents a SubmenuProto.
                 * @implements ISubmenuProto
                 * @constructor
                 * @param {waiternow.common.MenuProto.ISubmenuProto=} [properties] Properties to set
                 */
                function SubmenuProto(properties) {
                    this.images = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SubmenuProto name.
                 * @member {string} name
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @instance
                 */
                SubmenuProto.prototype.name = "";

                /**
                 * SubmenuProto images.
                 * @member {Array.<waiternow.common.IImageProto>} images
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @instance
                 */
                SubmenuProto.prototype.images = $util.emptyArray;

                /**
                 * Creates a new SubmenuProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {waiternow.common.MenuProto.ISubmenuProto=} [properties] Properties to set
                 * @returns {waiternow.common.MenuProto.SubmenuProto} SubmenuProto instance
                 */
                SubmenuProto.create = function create(properties) {
                    return new SubmenuProto(properties);
                };

                /**
                 * Encodes the specified SubmenuProto message. Does not implicitly {@link waiternow.common.MenuProto.SubmenuProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {waiternow.common.MenuProto.ISubmenuProto} message SubmenuProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmenuProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.name);
                    if (message.images != null && message.images.length)
                        for (var i = 0; i < message.images.length; ++i)
                            $root.waiternow.common.ImageProto.encode(message.images[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SubmenuProto message, length delimited. Does not implicitly {@link waiternow.common.MenuProto.SubmenuProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {waiternow.common.MenuProto.ISubmenuProto} message SubmenuProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SubmenuProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SubmenuProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MenuProto.SubmenuProto} SubmenuProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmenuProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MenuProto.SubmenuProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.name = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.images && message.images.length))
                                    message.images = [];
                                message.images.push($root.waiternow.common.ImageProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SubmenuProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MenuProto.SubmenuProto} SubmenuProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SubmenuProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SubmenuProto message.
                 * @function verify
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SubmenuProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.images != null && message.hasOwnProperty("images")) {
                        if (!Array.isArray(message.images))
                            return "images: array expected";
                        for (var i = 0; i < message.images.length; ++i) {
                            var error = $root.waiternow.common.ImageProto.verify(message.images[i]);
                            if (error)
                                return "images." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a SubmenuProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MenuProto.SubmenuProto} SubmenuProto
                 */
                SubmenuProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MenuProto.SubmenuProto)
                        return object;
                    var message = new $root.waiternow.common.MenuProto.SubmenuProto();
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.images) {
                        if (!Array.isArray(object.images))
                            throw TypeError(".waiternow.common.MenuProto.SubmenuProto.images: array expected");
                        message.images = [];
                        for (var i = 0; i < object.images.length; ++i) {
                            if (typeof object.images[i] !== "object")
                                throw TypeError(".waiternow.common.MenuProto.SubmenuProto.images: object expected");
                            message.images[i] = $root.waiternow.common.ImageProto.fromObject(object.images[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SubmenuProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {waiternow.common.MenuProto.SubmenuProto} message SubmenuProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SubmenuProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.images = [];
                    if (options.defaults)
                        object.name = "";
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.images && message.images.length) {
                        object.images = [];
                        for (var j = 0; j < message.images.length; ++j)
                            object.images[j] = $root.waiternow.common.ImageProto.toObject(message.images[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this SubmenuProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SubmenuProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SubmenuProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.MenuProto.SubmenuProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SubmenuProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MenuProto.SubmenuProto";
                };

                return SubmenuProto;
            })();

            return MenuProto;
        })();

        common.AvailabilityScheduleProto = (function() {

            /**
             * Properties of an AvailabilityScheduleProto.
             * @memberof waiternow.common
             * @interface IAvailabilityScheduleProto
             * @property {string|null} [id] AvailabilityScheduleProto id
             * @property {waiternow.common.IScheduleProto|null} [schedule] AvailabilityScheduleProto schedule
             */

            /**
             * Constructs a new AvailabilityScheduleProto.
             * @memberof waiternow.common
             * @classdesc Represents an AvailabilityScheduleProto.
             * @implements IAvailabilityScheduleProto
             * @constructor
             * @param {waiternow.common.IAvailabilityScheduleProto=} [properties] Properties to set
             */
            function AvailabilityScheduleProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * AvailabilityScheduleProto id.
             * @member {string} id
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @instance
             */
            AvailabilityScheduleProto.prototype.id = "";

            /**
             * AvailabilityScheduleProto schedule.
             * @member {waiternow.common.IScheduleProto|null|undefined} schedule
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @instance
             */
            AvailabilityScheduleProto.prototype.schedule = null;

            /**
             * Creates a new AvailabilityScheduleProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {waiternow.common.IAvailabilityScheduleProto=} [properties] Properties to set
             * @returns {waiternow.common.AvailabilityScheduleProto} AvailabilityScheduleProto instance
             */
            AvailabilityScheduleProto.create = function create(properties) {
                return new AvailabilityScheduleProto(properties);
            };

            /**
             * Encodes the specified AvailabilityScheduleProto message. Does not implicitly {@link waiternow.common.AvailabilityScheduleProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {waiternow.common.IAvailabilityScheduleProto} message AvailabilityScheduleProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AvailabilityScheduleProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                    $root.waiternow.common.ScheduleProto.encode(message.schedule, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified AvailabilityScheduleProto message, length delimited. Does not implicitly {@link waiternow.common.AvailabilityScheduleProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {waiternow.common.IAvailabilityScheduleProto} message AvailabilityScheduleProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AvailabilityScheduleProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AvailabilityScheduleProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AvailabilityScheduleProto} AvailabilityScheduleProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AvailabilityScheduleProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AvailabilityScheduleProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.schedule = $root.waiternow.common.ScheduleProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AvailabilityScheduleProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AvailabilityScheduleProto} AvailabilityScheduleProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AvailabilityScheduleProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AvailabilityScheduleProto message.
             * @function verify
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AvailabilityScheduleProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.schedule != null && message.hasOwnProperty("schedule")) {
                    var error = $root.waiternow.common.ScheduleProto.verify(message.schedule);
                    if (error)
                        return "schedule." + error;
                }
                return null;
            };

            /**
             * Creates an AvailabilityScheduleProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AvailabilityScheduleProto} AvailabilityScheduleProto
             */
            AvailabilityScheduleProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AvailabilityScheduleProto)
                    return object;
                var message = new $root.waiternow.common.AvailabilityScheduleProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.schedule != null) {
                    if (typeof object.schedule !== "object")
                        throw TypeError(".waiternow.common.AvailabilityScheduleProto.schedule: object expected");
                    message.schedule = $root.waiternow.common.ScheduleProto.fromObject(object.schedule);
                }
                return message;
            };

            /**
             * Creates a plain object from an AvailabilityScheduleProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {waiternow.common.AvailabilityScheduleProto} message AvailabilityScheduleProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AvailabilityScheduleProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.schedule = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.schedule != null && message.hasOwnProperty("schedule"))
                    object.schedule = $root.waiternow.common.ScheduleProto.toObject(message.schedule, options);
                return object;
            };

            /**
             * Converts this AvailabilityScheduleProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AvailabilityScheduleProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AvailabilityScheduleProto
             * @function getTypeUrl
             * @memberof waiternow.common.AvailabilityScheduleProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AvailabilityScheduleProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AvailabilityScheduleProto";
            };

            return AvailabilityScheduleProto;
        })();

        common.StructuredMenuSpecProto = (function() {

            /**
             * Properties of a StructuredMenuSpecProto.
             * @memberof waiternow.common
             * @interface IStructuredMenuSpecProto
             * @property {waiternow.common.IImageProto|null} [headerImage] StructuredMenuSpecProto headerImage
             * @property {Array.<waiternow.common.StructuredMenuSpecProto.ICategoryProto>|null} [categories] StructuredMenuSpecProto categories
             * @property {Array.<waiternow.common.StructuredMenuSpecProto.IItemProto>|null} [items] StructuredMenuSpecProto items
             * @property {Array.<waiternow.common.StructuredMenuSpecProto.IItemSelectionProto>|null} [itemSelections] StructuredMenuSpecProto itemSelections
             * @property {Array.<waiternow.common.StructuredMenuSpecProto.IMenuItemProto>|null} [menuItems] StructuredMenuSpecProto menuItems
             * @property {Array.<waiternow.common.StructuredMenuSpecProto.IMenuItemSelectionProto>|null} [menuItemSelections] StructuredMenuSpecProto menuItemSelections
             * @property {Array.<waiternow.common.StructuredMenuSpecProto.IComboProto>|null} [combos] StructuredMenuSpecProto combos
             * @property {Array.<waiternow.common.IAvailabilityScheduleProto>|null} [schedules] StructuredMenuSpecProto schedules
             */

            /**
             * Constructs a new StructuredMenuSpecProto.
             * @memberof waiternow.common
             * @classdesc Represents a StructuredMenuSpecProto.
             * @implements IStructuredMenuSpecProto
             * @constructor
             * @param {waiternow.common.IStructuredMenuSpecProto=} [properties] Properties to set
             */
            function StructuredMenuSpecProto(properties) {
                this.categories = [];
                this.items = [];
                this.itemSelections = [];
                this.menuItems = [];
                this.menuItemSelections = [];
                this.combos = [];
                this.schedules = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StructuredMenuSpecProto headerImage.
             * @member {waiternow.common.IImageProto|null|undefined} headerImage
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.headerImage = null;

            /**
             * StructuredMenuSpecProto categories.
             * @member {Array.<waiternow.common.StructuredMenuSpecProto.ICategoryProto>} categories
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.categories = $util.emptyArray;

            /**
             * StructuredMenuSpecProto items.
             * @member {Array.<waiternow.common.StructuredMenuSpecProto.IItemProto>} items
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.items = $util.emptyArray;

            /**
             * StructuredMenuSpecProto itemSelections.
             * @member {Array.<waiternow.common.StructuredMenuSpecProto.IItemSelectionProto>} itemSelections
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.itemSelections = $util.emptyArray;

            /**
             * StructuredMenuSpecProto menuItems.
             * @member {Array.<waiternow.common.StructuredMenuSpecProto.IMenuItemProto>} menuItems
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.menuItems = $util.emptyArray;

            /**
             * StructuredMenuSpecProto menuItemSelections.
             * @member {Array.<waiternow.common.StructuredMenuSpecProto.IMenuItemSelectionProto>} menuItemSelections
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.menuItemSelections = $util.emptyArray;

            /**
             * StructuredMenuSpecProto combos.
             * @member {Array.<waiternow.common.StructuredMenuSpecProto.IComboProto>} combos
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.combos = $util.emptyArray;

            /**
             * StructuredMenuSpecProto schedules.
             * @member {Array.<waiternow.common.IAvailabilityScheduleProto>} schedules
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             */
            StructuredMenuSpecProto.prototype.schedules = $util.emptyArray;

            /**
             * Creates a new StructuredMenuSpecProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {waiternow.common.IStructuredMenuSpecProto=} [properties] Properties to set
             * @returns {waiternow.common.StructuredMenuSpecProto} StructuredMenuSpecProto instance
             */
            StructuredMenuSpecProto.create = function create(properties) {
                return new StructuredMenuSpecProto(properties);
            };

            /**
             * Encodes the specified StructuredMenuSpecProto message. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {waiternow.common.IStructuredMenuSpecProto} message StructuredMenuSpecProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredMenuSpecProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.headerImage != null && Object.hasOwnProperty.call(message, "headerImage"))
                    $root.waiternow.common.ImageProto.encode(message.headerImage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.categories != null && message.categories.length)
                    for (var i = 0; i < message.categories.length; ++i)
                        $root.waiternow.common.StructuredMenuSpecProto.CategoryProto.encode(message.categories[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.items != null && message.items.length)
                    for (var i = 0; i < message.items.length; ++i)
                        $root.waiternow.common.StructuredMenuSpecProto.ItemProto.encode(message.items[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.itemSelections != null && message.itemSelections.length)
                    for (var i = 0; i < message.itemSelections.length; ++i)
                        $root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.encode(message.itemSelections[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.menuItems != null && message.menuItems.length)
                    for (var i = 0; i < message.menuItems.length; ++i)
                        $root.waiternow.common.StructuredMenuSpecProto.MenuItemProto.encode(message.menuItems[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.menuItemSelections != null && message.menuItemSelections.length)
                    for (var i = 0; i < message.menuItemSelections.length; ++i)
                        $root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.encode(message.menuItemSelections[i], writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.combos != null && message.combos.length)
                    for (var i = 0; i < message.combos.length; ++i)
                        $root.waiternow.common.StructuredMenuSpecProto.ComboProto.encode(message.combos[i], writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.schedules != null && message.schedules.length)
                    for (var i = 0; i < message.schedules.length; ++i)
                        $root.waiternow.common.AvailabilityScheduleProto.encode(message.schedules[i], writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified StructuredMenuSpecProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {waiternow.common.IStructuredMenuSpecProto} message StructuredMenuSpecProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredMenuSpecProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StructuredMenuSpecProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.StructuredMenuSpecProto} StructuredMenuSpecProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredMenuSpecProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuSpecProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.headerImage = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.categories && message.categories.length))
                                message.categories = [];
                            message.categories.push($root.waiternow.common.StructuredMenuSpecProto.CategoryProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.items && message.items.length))
                                message.items = [];
                            message.items.push($root.waiternow.common.StructuredMenuSpecProto.ItemProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.itemSelections && message.itemSelections.length))
                                message.itemSelections = [];
                            message.itemSelections.push($root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            if (!(message.menuItems && message.menuItems.length))
                                message.menuItems = [];
                            message.menuItems.push($root.waiternow.common.StructuredMenuSpecProto.MenuItemProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 6: {
                            if (!(message.menuItemSelections && message.menuItemSelections.length))
                                message.menuItemSelections = [];
                            message.menuItemSelections.push($root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 7: {
                            if (!(message.combos && message.combos.length))
                                message.combos = [];
                            message.combos.push($root.waiternow.common.StructuredMenuSpecProto.ComboProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 8: {
                            if (!(message.schedules && message.schedules.length))
                                message.schedules = [];
                            message.schedules.push($root.waiternow.common.AvailabilityScheduleProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StructuredMenuSpecProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.StructuredMenuSpecProto} StructuredMenuSpecProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredMenuSpecProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StructuredMenuSpecProto message.
             * @function verify
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StructuredMenuSpecProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.headerImage != null && message.hasOwnProperty("headerImage")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.headerImage);
                    if (error)
                        return "headerImage." + error;
                }
                if (message.categories != null && message.hasOwnProperty("categories")) {
                    if (!Array.isArray(message.categories))
                        return "categories: array expected";
                    for (var i = 0; i < message.categories.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.CategoryProto.verify(message.categories[i]);
                        if (error)
                            return "categories." + error;
                    }
                }
                if (message.items != null && message.hasOwnProperty("items")) {
                    if (!Array.isArray(message.items))
                        return "items: array expected";
                    for (var i = 0; i < message.items.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.ItemProto.verify(message.items[i]);
                        if (error)
                            return "items." + error;
                    }
                }
                if (message.itemSelections != null && message.hasOwnProperty("itemSelections")) {
                    if (!Array.isArray(message.itemSelections))
                        return "itemSelections: array expected";
                    for (var i = 0; i < message.itemSelections.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.verify(message.itemSelections[i]);
                        if (error)
                            return "itemSelections." + error;
                    }
                }
                if (message.menuItems != null && message.hasOwnProperty("menuItems")) {
                    if (!Array.isArray(message.menuItems))
                        return "menuItems: array expected";
                    for (var i = 0; i < message.menuItems.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.MenuItemProto.verify(message.menuItems[i]);
                        if (error)
                            return "menuItems." + error;
                    }
                }
                if (message.menuItemSelections != null && message.hasOwnProperty("menuItemSelections")) {
                    if (!Array.isArray(message.menuItemSelections))
                        return "menuItemSelections: array expected";
                    for (var i = 0; i < message.menuItemSelections.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.verify(message.menuItemSelections[i]);
                        if (error)
                            return "menuItemSelections." + error;
                    }
                }
                if (message.combos != null && message.hasOwnProperty("combos")) {
                    if (!Array.isArray(message.combos))
                        return "combos: array expected";
                    for (var i = 0; i < message.combos.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.ComboProto.verify(message.combos[i]);
                        if (error)
                            return "combos." + error;
                    }
                }
                if (message.schedules != null && message.hasOwnProperty("schedules")) {
                    if (!Array.isArray(message.schedules))
                        return "schedules: array expected";
                    for (var i = 0; i < message.schedules.length; ++i) {
                        var error = $root.waiternow.common.AvailabilityScheduleProto.verify(message.schedules[i]);
                        if (error)
                            return "schedules." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a StructuredMenuSpecProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.StructuredMenuSpecProto} StructuredMenuSpecProto
             */
            StructuredMenuSpecProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.StructuredMenuSpecProto)
                    return object;
                var message = new $root.waiternow.common.StructuredMenuSpecProto();
                if (object.headerImage != null) {
                    if (typeof object.headerImage !== "object")
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.headerImage: object expected");
                    message.headerImage = $root.waiternow.common.ImageProto.fromObject(object.headerImage);
                }
                if (object.categories) {
                    if (!Array.isArray(object.categories))
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.categories: array expected");
                    message.categories = [];
                    for (var i = 0; i < object.categories.length; ++i) {
                        if (typeof object.categories[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.categories: object expected");
                        message.categories[i] = $root.waiternow.common.StructuredMenuSpecProto.CategoryProto.fromObject(object.categories[i]);
                    }
                }
                if (object.items) {
                    if (!Array.isArray(object.items))
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.items: array expected");
                    message.items = [];
                    for (var i = 0; i < object.items.length; ++i) {
                        if (typeof object.items[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.items: object expected");
                        message.items[i] = $root.waiternow.common.StructuredMenuSpecProto.ItemProto.fromObject(object.items[i]);
                    }
                }
                if (object.itemSelections) {
                    if (!Array.isArray(object.itemSelections))
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.itemSelections: array expected");
                    message.itemSelections = [];
                    for (var i = 0; i < object.itemSelections.length; ++i) {
                        if (typeof object.itemSelections[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.itemSelections: object expected");
                        message.itemSelections[i] = $root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.fromObject(object.itemSelections[i]);
                    }
                }
                if (object.menuItems) {
                    if (!Array.isArray(object.menuItems))
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.menuItems: array expected");
                    message.menuItems = [];
                    for (var i = 0; i < object.menuItems.length; ++i) {
                        if (typeof object.menuItems[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.menuItems: object expected");
                        message.menuItems[i] = $root.waiternow.common.StructuredMenuSpecProto.MenuItemProto.fromObject(object.menuItems[i]);
                    }
                }
                if (object.menuItemSelections) {
                    if (!Array.isArray(object.menuItemSelections))
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.menuItemSelections: array expected");
                    message.menuItemSelections = [];
                    for (var i = 0; i < object.menuItemSelections.length; ++i) {
                        if (typeof object.menuItemSelections[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.menuItemSelections: object expected");
                        message.menuItemSelections[i] = $root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.fromObject(object.menuItemSelections[i]);
                    }
                }
                if (object.combos) {
                    if (!Array.isArray(object.combos))
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.combos: array expected");
                    message.combos = [];
                    for (var i = 0; i < object.combos.length; ++i) {
                        if (typeof object.combos[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.combos: object expected");
                        message.combos[i] = $root.waiternow.common.StructuredMenuSpecProto.ComboProto.fromObject(object.combos[i]);
                    }
                }
                if (object.schedules) {
                    if (!Array.isArray(object.schedules))
                        throw TypeError(".waiternow.common.StructuredMenuSpecProto.schedules: array expected");
                    message.schedules = [];
                    for (var i = 0; i < object.schedules.length; ++i) {
                        if (typeof object.schedules[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.schedules: object expected");
                        message.schedules[i] = $root.waiternow.common.AvailabilityScheduleProto.fromObject(object.schedules[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a StructuredMenuSpecProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {waiternow.common.StructuredMenuSpecProto} message StructuredMenuSpecProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StructuredMenuSpecProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.categories = [];
                    object.items = [];
                    object.itemSelections = [];
                    object.menuItems = [];
                    object.menuItemSelections = [];
                    object.combos = [];
                    object.schedules = [];
                }
                if (options.defaults)
                    object.headerImage = null;
                if (message.headerImage != null && message.hasOwnProperty("headerImage"))
                    object.headerImage = $root.waiternow.common.ImageProto.toObject(message.headerImage, options);
                if (message.categories && message.categories.length) {
                    object.categories = [];
                    for (var j = 0; j < message.categories.length; ++j)
                        object.categories[j] = $root.waiternow.common.StructuredMenuSpecProto.CategoryProto.toObject(message.categories[j], options);
                }
                if (message.items && message.items.length) {
                    object.items = [];
                    for (var j = 0; j < message.items.length; ++j)
                        object.items[j] = $root.waiternow.common.StructuredMenuSpecProto.ItemProto.toObject(message.items[j], options);
                }
                if (message.itemSelections && message.itemSelections.length) {
                    object.itemSelections = [];
                    for (var j = 0; j < message.itemSelections.length; ++j)
                        object.itemSelections[j] = $root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.toObject(message.itemSelections[j], options);
                }
                if (message.menuItems && message.menuItems.length) {
                    object.menuItems = [];
                    for (var j = 0; j < message.menuItems.length; ++j)
                        object.menuItems[j] = $root.waiternow.common.StructuredMenuSpecProto.MenuItemProto.toObject(message.menuItems[j], options);
                }
                if (message.menuItemSelections && message.menuItemSelections.length) {
                    object.menuItemSelections = [];
                    for (var j = 0; j < message.menuItemSelections.length; ++j)
                        object.menuItemSelections[j] = $root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.toObject(message.menuItemSelections[j], options);
                }
                if (message.combos && message.combos.length) {
                    object.combos = [];
                    for (var j = 0; j < message.combos.length; ++j)
                        object.combos[j] = $root.waiternow.common.StructuredMenuSpecProto.ComboProto.toObject(message.combos[j], options);
                }
                if (message.schedules && message.schedules.length) {
                    object.schedules = [];
                    for (var j = 0; j < message.schedules.length; ++j)
                        object.schedules[j] = $root.waiternow.common.AvailabilityScheduleProto.toObject(message.schedules[j], options);
                }
                return object;
            };

            /**
             * Converts this StructuredMenuSpecProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StructuredMenuSpecProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StructuredMenuSpecProto
             * @function getTypeUrl
             * @memberof waiternow.common.StructuredMenuSpecProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StructuredMenuSpecProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.StructuredMenuSpecProto";
            };

            StructuredMenuSpecProto.ItemProto = (function() {

                /**
                 * Properties of an ItemProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @interface IItemProto
                 * @property {string|null} [id] ItemProto id
                 * @property {waiternow.common.ITextProto|null} [name] ItemProto name
                 * @property {waiternow.common.ITextProto|null} [description] ItemProto description
                 * @property {waiternow.common.IImageProto|null} [image] ItemProto image
                 * @property {waiternow.common.IMoneyProto|null} [price] ItemProto price
                 */

                /**
                 * Constructs a new ItemProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @classdesc Represents an ItemProto.
                 * @implements IItemProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemProto=} [properties] Properties to set
                 */
                function ItemProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.id = "";

                /**
                 * ItemProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.name = null;

                /**
                 * ItemProto description.
                 * @member {waiternow.common.ITextProto|null|undefined} description
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.description = null;

                /**
                 * ItemProto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.image = null;

                /**
                 * ItemProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.price = null;

                /**
                 * Creates a new ItemProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemProto} ItemProto instance
                 */
                ItemProto.create = function create(properties) {
                    return new ItemProto(properties);
                };

                /**
                 * Encodes the specified ItemProto message. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.ItemProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemProto} message ItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        $root.waiternow.common.TextProto.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ItemProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.ItemProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemProto} message ItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ItemProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemProto} ItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuSpecProto.ItemProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.description = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ItemProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemProto} ItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ItemProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description")) {
                        var error = $root.waiternow.common.TextProto.verify(message.description);
                        if (error)
                            return "description." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ItemProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemProto} ItemProto
                 */
                ItemProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuSpecProto.ItemProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuSpecProto.ItemProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ItemProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.description != null) {
                        if (typeof object.description !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ItemProto.description: object expected");
                        message.description = $root.waiternow.common.TextProto.fromObject(object.description);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ItemProto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ItemProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ItemProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.ItemProto} message ItemProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.name = null;
                        object.description = null;
                        object.image = null;
                        object.price = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = $root.waiternow.common.TextProto.toObject(message.description, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    return object;
                };

                /**
                 * Converts this ItemProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ItemProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ItemProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuSpecProto.ItemProto";
                };

                return ItemProto;
            })();

            StructuredMenuSpecProto.ItemSelectionProto = (function() {

                /**
                 * Properties of an ItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @interface IItemSelectionProto
                 * @property {string|null} [id] ItemSelectionProto id
                 * @property {waiternow.common.ITextProto|null} [title] ItemSelectionProto title
                 * @property {Array.<string>|null} [itemIds] ItemSelectionProto itemIds
                 * @property {waiternow.common.SelectionType|null} [selectionType] ItemSelectionProto selectionType
                 * @property {boolean|null} [isRequired] ItemSelectionProto isRequired
                 * @property {number|null} [maxSelections] ItemSelectionProto maxSelections
                 */

                /**
                 * Constructs a new ItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @classdesc Represents an ItemSelectionProto.
                 * @implements IItemSelectionProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemSelectionProto=} [properties] Properties to set
                 */
                function ItemSelectionProto(properties) {
                    this.itemIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemSelectionProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.id = "";

                /**
                 * ItemSelectionProto title.
                 * @member {waiternow.common.ITextProto|null|undefined} title
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.title = null;

                /**
                 * ItemSelectionProto itemIds.
                 * @member {Array.<string>} itemIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.itemIds = $util.emptyArray;

                /**
                 * ItemSelectionProto selectionType.
                 * @member {waiternow.common.SelectionType} selectionType
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.selectionType = 0;

                /**
                 * ItemSelectionProto isRequired.
                 * @member {boolean} isRequired
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.isRequired = false;

                /**
                 * ItemSelectionProto maxSelections.
                 * @member {number} maxSelections
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.maxSelections = 0;

                /**
                 * Creates a new ItemSelectionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemSelectionProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemSelectionProto} ItemSelectionProto instance
                 */
                ItemSelectionProto.create = function create(properties) {
                    return new ItemSelectionProto(properties);
                };

                /**
                 * Encodes the specified ItemSelectionProto message. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemSelectionProto} message ItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemSelectionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        $root.waiternow.common.TextProto.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.itemIds != null && message.itemIds.length)
                        for (var i = 0; i < message.itemIds.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.itemIds[i]);
                    if (message.selectionType != null && Object.hasOwnProperty.call(message, "selectionType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.selectionType);
                    if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isRequired);
                    if (message.maxSelections != null && Object.hasOwnProperty.call(message, "maxSelections"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxSelections);
                    return writer;
                };

                /**
                 * Encodes the specified ItemSelectionProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IItemSelectionProto} message ItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemSelectionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ItemSelectionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemSelectionProto} ItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemSelectionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.title = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.itemIds && message.itemIds.length))
                                    message.itemIds = [];
                                message.itemIds.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.selectionType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.isRequired = reader.bool();
                                break;
                            }
                        case 6: {
                                message.maxSelections = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ItemSelectionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemSelectionProto} ItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemSelectionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ItemSelectionProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemSelectionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.waiternow.common.TextProto.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.itemIds != null && message.hasOwnProperty("itemIds")) {
                        if (!Array.isArray(message.itemIds))
                            return "itemIds: array expected";
                        for (var i = 0; i < message.itemIds.length; ++i)
                            if (!$util.isString(message.itemIds[i]))
                                return "itemIds: string[] expected";
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        switch (message.selectionType) {
                        default:
                            return "selectionType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        if (typeof message.isRequired !== "boolean")
                            return "isRequired: boolean expected";
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        if (!$util.isInteger(message.maxSelections))
                            return "maxSelections: integer expected";
                    return null;
                };

                /**
                 * Creates an ItemSelectionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuSpecProto.ItemSelectionProto} ItemSelectionProto
                 */
                ItemSelectionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuSpecProto.ItemSelectionProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.title: object expected");
                        message.title = $root.waiternow.common.TextProto.fromObject(object.title);
                    }
                    if (object.itemIds) {
                        if (!Array.isArray(object.itemIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ItemSelectionProto.itemIds: array expected");
                        message.itemIds = [];
                        for (var i = 0; i < object.itemIds.length; ++i)
                            message.itemIds[i] = String(object.itemIds[i]);
                    }
                    switch (object.selectionType) {
                    default:
                        if (typeof object.selectionType === "number") {
                            message.selectionType = object.selectionType;
                            break;
                        }
                        break;
                    case "SELECTION_TYPE_UNSPECIFIED":
                    case 0:
                        message.selectionType = 0;
                        break;
                    case "SINGLE":
                    case 1:
                        message.selectionType = 1;
                        break;
                    case "MULTIPLE":
                    case 2:
                        message.selectionType = 2;
                        break;
                    }
                    if (object.isRequired != null)
                        message.isRequired = Boolean(object.isRequired);
                    if (object.maxSelections != null)
                        message.maxSelections = object.maxSelections | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ItemSelectionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.ItemSelectionProto} message ItemSelectionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemSelectionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.itemIds = [];
                    if (options.defaults) {
                        object.id = "";
                        object.title = null;
                        object.selectionType = options.enums === String ? "SELECTION_TYPE_UNSPECIFIED" : 0;
                        object.isRequired = false;
                        object.maxSelections = 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.waiternow.common.TextProto.toObject(message.title, options);
                    if (message.itemIds && message.itemIds.length) {
                        object.itemIds = [];
                        for (var j = 0; j < message.itemIds.length; ++j)
                            object.itemIds[j] = message.itemIds[j];
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        object.selectionType = options.enums === String ? $root.waiternow.common.SelectionType[message.selectionType] === undefined ? message.selectionType : $root.waiternow.common.SelectionType[message.selectionType] : message.selectionType;
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        object.isRequired = message.isRequired;
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        object.maxSelections = message.maxSelections;
                    return object;
                };

                /**
                 * Converts this ItemSelectionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemSelectionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ItemSelectionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuSpecProto.ItemSelectionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ItemSelectionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuSpecProto.ItemSelectionProto";
                };

                return ItemSelectionProto;
            })();

            StructuredMenuSpecProto.MenuItemProto = (function() {

                /**
                 * Properties of a MenuItemProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @interface IMenuItemProto
                 * @property {string|null} [id] MenuItemProto id
                 * @property {waiternow.common.ITextProto|null} [name] MenuItemProto name
                 * @property {waiternow.common.ITextProto|null} [description] MenuItemProto description
                 * @property {waiternow.common.IImageProto|null} [image] MenuItemProto image
                 * @property {Array.<string>|null} [removableIngredientItemIds] MenuItemProto removableIngredientItemIds
                 * @property {Array.<string>|null} [itemSelectionIds] MenuItemProto itemSelectionIds
                 * @property {number|null} [calories] MenuItemProto calories
                 * @property {boolean|null} [containsAlcohol] MenuItemProto containsAlcohol
                 * @property {boolean|null} [isUnavailable] MenuItemProto isUnavailable
                 * @property {waiternow.common.IMoneyProto|null} [price] MenuItemProto price
                 * @property {waiternow.common.IMoneyProto|null} [priceInCombo] MenuItemProto priceInCombo
                 * @property {Array.<string>|null} [categoryIds] MenuItemProto categoryIds
                 * @property {string|null} [station] MenuItemProto station
                 * @property {string|null} [availabilityScheduleId] MenuItemProto availabilityScheduleId
                 */

                /**
                 * Constructs a new MenuItemProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @classdesc Represents a MenuItemProto.
                 * @implements IMenuItemProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemProto=} [properties] Properties to set
                 */
                function MenuItemProto(properties) {
                    this.removableIngredientItemIds = [];
                    this.itemSelectionIds = [];
                    this.categoryIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuItemProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.id = "";

                /**
                 * MenuItemProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.name = null;

                /**
                 * MenuItemProto description.
                 * @member {waiternow.common.ITextProto|null|undefined} description
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.description = null;

                /**
                 * MenuItemProto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.image = null;

                /**
                 * MenuItemProto removableIngredientItemIds.
                 * @member {Array.<string>} removableIngredientItemIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.removableIngredientItemIds = $util.emptyArray;

                /**
                 * MenuItemProto itemSelectionIds.
                 * @member {Array.<string>} itemSelectionIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.itemSelectionIds = $util.emptyArray;

                /**
                 * MenuItemProto calories.
                 * @member {number} calories
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.calories = 0;

                /**
                 * MenuItemProto containsAlcohol.
                 * @member {boolean} containsAlcohol
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.containsAlcohol = false;

                /**
                 * MenuItemProto isUnavailable.
                 * @member {boolean} isUnavailable
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.isUnavailable = false;

                /**
                 * MenuItemProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.price = null;

                /**
                 * MenuItemProto priceInCombo.
                 * @member {waiternow.common.IMoneyProto|null|undefined} priceInCombo
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.priceInCombo = null;

                /**
                 * MenuItemProto categoryIds.
                 * @member {Array.<string>} categoryIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.categoryIds = $util.emptyArray;

                /**
                 * MenuItemProto station.
                 * @member {string} station
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.station = "";

                /**
                 * MenuItemProto availabilityScheduleId.
                 * @member {string} availabilityScheduleId
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.availabilityScheduleId = "";

                /**
                 * Creates a new MenuItemProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemProto} MenuItemProto instance
                 */
                MenuItemProto.create = function create(properties) {
                    return new MenuItemProto(properties);
                };

                /**
                 * Encodes the specified MenuItemProto message. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.MenuItemProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemProto} message MenuItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        $root.waiternow.common.TextProto.encode(message.description, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.removableIngredientItemIds != null && message.removableIngredientItemIds.length)
                        for (var i = 0; i < message.removableIngredientItemIds.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.removableIngredientItemIds[i]);
                    if (message.itemSelectionIds != null && message.itemSelectionIds.length)
                        for (var i = 0; i < message.itemSelectionIds.length; ++i)
                            writer.uint32(/* id 6, wireType 2 =*/50).string(message.itemSelectionIds[i]);
                    if (message.calories != null && Object.hasOwnProperty.call(message, "calories"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.calories);
                    if (message.containsAlcohol != null && Object.hasOwnProperty.call(message, "containsAlcohol"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.containsAlcohol);
                    if (message.isUnavailable != null && Object.hasOwnProperty.call(message, "isUnavailable"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isUnavailable);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.priceInCombo != null && Object.hasOwnProperty.call(message, "priceInCombo"))
                        $root.waiternow.common.MoneyProto.encode(message.priceInCombo, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.categoryIds != null && message.categoryIds.length)
                        for (var i = 0; i < message.categoryIds.length; ++i)
                            writer.uint32(/* id 12, wireType 2 =*/98).string(message.categoryIds[i]);
                    if (message.station != null && Object.hasOwnProperty.call(message, "station"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.station);
                    if (message.availabilityScheduleId != null && Object.hasOwnProperty.call(message, "availabilityScheduleId"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.availabilityScheduleId);
                    return writer;
                };

                /**
                 * Encodes the specified MenuItemProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.MenuItemProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemProto} message MenuItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuItemProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemProto} MenuItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuSpecProto.MenuItemProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.description = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                if (!(message.removableIngredientItemIds && message.removableIngredientItemIds.length))
                                    message.removableIngredientItemIds = [];
                                message.removableIngredientItemIds.push(reader.string());
                                break;
                            }
                        case 6: {
                                if (!(message.itemSelectionIds && message.itemSelectionIds.length))
                                    message.itemSelectionIds = [];
                                message.itemSelectionIds.push(reader.string());
                                break;
                            }
                        case 7: {
                                message.calories = reader.int32();
                                break;
                            }
                        case 8: {
                                message.containsAlcohol = reader.bool();
                                break;
                            }
                        case 9: {
                                message.isUnavailable = reader.bool();
                                break;
                            }
                        case 10: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.priceInCombo = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                if (!(message.categoryIds && message.categoryIds.length))
                                    message.categoryIds = [];
                                message.categoryIds.push(reader.string());
                                break;
                            }
                        case 13: {
                                message.station = reader.string();
                                break;
                            }
                        case 14: {
                                message.availabilityScheduleId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuItemProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemProto} MenuItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuItemProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuItemProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description")) {
                        var error = $root.waiternow.common.TextProto.verify(message.description);
                        if (error)
                            return "description." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.removableIngredientItemIds != null && message.hasOwnProperty("removableIngredientItemIds")) {
                        if (!Array.isArray(message.removableIngredientItemIds))
                            return "removableIngredientItemIds: array expected";
                        for (var i = 0; i < message.removableIngredientItemIds.length; ++i)
                            if (!$util.isString(message.removableIngredientItemIds[i]))
                                return "removableIngredientItemIds: string[] expected";
                    }
                    if (message.itemSelectionIds != null && message.hasOwnProperty("itemSelectionIds")) {
                        if (!Array.isArray(message.itemSelectionIds))
                            return "itemSelectionIds: array expected";
                        for (var i = 0; i < message.itemSelectionIds.length; ++i)
                            if (!$util.isString(message.itemSelectionIds[i]))
                                return "itemSelectionIds: string[] expected";
                    }
                    if (message.calories != null && message.hasOwnProperty("calories"))
                        if (!$util.isInteger(message.calories))
                            return "calories: integer expected";
                    if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                        if (typeof message.containsAlcohol !== "boolean")
                            return "containsAlcohol: boolean expected";
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        if (typeof message.isUnavailable !== "boolean")
                            return "isUnavailable: boolean expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.priceInCombo != null && message.hasOwnProperty("priceInCombo")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.priceInCombo);
                        if (error)
                            return "priceInCombo." + error;
                    }
                    if (message.categoryIds != null && message.hasOwnProperty("categoryIds")) {
                        if (!Array.isArray(message.categoryIds))
                            return "categoryIds: array expected";
                        for (var i = 0; i < message.categoryIds.length; ++i)
                            if (!$util.isString(message.categoryIds[i]))
                                return "categoryIds: string[] expected";
                    }
                    if (message.station != null && message.hasOwnProperty("station"))
                        if (!$util.isString(message.station))
                            return "station: string expected";
                    if (message.availabilityScheduleId != null && message.hasOwnProperty("availabilityScheduleId"))
                        if (!$util.isString(message.availabilityScheduleId))
                            return "availabilityScheduleId: string expected";
                    return null;
                };

                /**
                 * Creates a MenuItemProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemProto} MenuItemProto
                 */
                MenuItemProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuSpecProto.MenuItemProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuSpecProto.MenuItemProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.description != null) {
                        if (typeof object.description !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.description: object expected");
                        message.description = $root.waiternow.common.TextProto.fromObject(object.description);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.removableIngredientItemIds) {
                        if (!Array.isArray(object.removableIngredientItemIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.removableIngredientItemIds: array expected");
                        message.removableIngredientItemIds = [];
                        for (var i = 0; i < object.removableIngredientItemIds.length; ++i)
                            message.removableIngredientItemIds[i] = String(object.removableIngredientItemIds[i]);
                    }
                    if (object.itemSelectionIds) {
                        if (!Array.isArray(object.itemSelectionIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.itemSelectionIds: array expected");
                        message.itemSelectionIds = [];
                        for (var i = 0; i < object.itemSelectionIds.length; ++i)
                            message.itemSelectionIds[i] = String(object.itemSelectionIds[i]);
                    }
                    if (object.calories != null)
                        message.calories = object.calories | 0;
                    if (object.containsAlcohol != null)
                        message.containsAlcohol = Boolean(object.containsAlcohol);
                    if (object.isUnavailable != null)
                        message.isUnavailable = Boolean(object.isUnavailable);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    if (object.priceInCombo != null) {
                        if (typeof object.priceInCombo !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.priceInCombo: object expected");
                        message.priceInCombo = $root.waiternow.common.MoneyProto.fromObject(object.priceInCombo);
                    }
                    if (object.categoryIds) {
                        if (!Array.isArray(object.categoryIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemProto.categoryIds: array expected");
                        message.categoryIds = [];
                        for (var i = 0; i < object.categoryIds.length; ++i)
                            message.categoryIds[i] = String(object.categoryIds[i]);
                    }
                    if (object.station != null)
                        message.station = String(object.station);
                    if (object.availabilityScheduleId != null)
                        message.availabilityScheduleId = String(object.availabilityScheduleId);
                    return message;
                };

                /**
                 * Creates a plain object from a MenuItemProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.MenuItemProto} message MenuItemProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuItemProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.removableIngredientItemIds = [];
                        object.itemSelectionIds = [];
                        object.categoryIds = [];
                    }
                    if (options.defaults) {
                        object.id = "";
                        object.name = null;
                        object.description = null;
                        object.image = null;
                        object.calories = 0;
                        object.containsAlcohol = false;
                        object.isUnavailable = false;
                        object.price = null;
                        object.priceInCombo = null;
                        object.station = "";
                        object.availabilityScheduleId = "";
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = $root.waiternow.common.TextProto.toObject(message.description, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    if (message.removableIngredientItemIds && message.removableIngredientItemIds.length) {
                        object.removableIngredientItemIds = [];
                        for (var j = 0; j < message.removableIngredientItemIds.length; ++j)
                            object.removableIngredientItemIds[j] = message.removableIngredientItemIds[j];
                    }
                    if (message.itemSelectionIds && message.itemSelectionIds.length) {
                        object.itemSelectionIds = [];
                        for (var j = 0; j < message.itemSelectionIds.length; ++j)
                            object.itemSelectionIds[j] = message.itemSelectionIds[j];
                    }
                    if (message.calories != null && message.hasOwnProperty("calories"))
                        object.calories = message.calories;
                    if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                        object.containsAlcohol = message.containsAlcohol;
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        object.isUnavailable = message.isUnavailable;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.priceInCombo != null && message.hasOwnProperty("priceInCombo"))
                        object.priceInCombo = $root.waiternow.common.MoneyProto.toObject(message.priceInCombo, options);
                    if (message.categoryIds && message.categoryIds.length) {
                        object.categoryIds = [];
                        for (var j = 0; j < message.categoryIds.length; ++j)
                            object.categoryIds[j] = message.categoryIds[j];
                    }
                    if (message.station != null && message.hasOwnProperty("station"))
                        object.station = message.station;
                    if (message.availabilityScheduleId != null && message.hasOwnProperty("availabilityScheduleId"))
                        object.availabilityScheduleId = message.availabilityScheduleId;
                    return object;
                };

                /**
                 * Converts this MenuItemProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuItemProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuItemProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuItemProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuSpecProto.MenuItemProto";
                };

                return MenuItemProto;
            })();

            StructuredMenuSpecProto.MenuItemSelectionProto = (function() {

                /**
                 * Properties of a MenuItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @interface IMenuItemSelectionProto
                 * @property {string|null} [id] MenuItemSelectionProto id
                 * @property {waiternow.common.ITextProto|null} [title] MenuItemSelectionProto title
                 * @property {Array.<string>|null} [menuItemIds] MenuItemSelectionProto menuItemIds
                 * @property {waiternow.common.SelectionType|null} [selectionType] MenuItemSelectionProto selectionType
                 * @property {boolean|null} [isRequired] MenuItemSelectionProto isRequired
                 * @property {number|null} [maxSelections] MenuItemSelectionProto maxSelections
                 */

                /**
                 * Constructs a new MenuItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @classdesc Represents a MenuItemSelectionProto.
                 * @implements IMenuItemSelectionProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemSelectionProto=} [properties] Properties to set
                 */
                function MenuItemSelectionProto(properties) {
                    this.menuItemIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuItemSelectionProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.id = "";

                /**
                 * MenuItemSelectionProto title.
                 * @member {waiternow.common.ITextProto|null|undefined} title
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.title = null;

                /**
                 * MenuItemSelectionProto menuItemIds.
                 * @member {Array.<string>} menuItemIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.menuItemIds = $util.emptyArray;

                /**
                 * MenuItemSelectionProto selectionType.
                 * @member {waiternow.common.SelectionType} selectionType
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.selectionType = 0;

                /**
                 * MenuItemSelectionProto isRequired.
                 * @member {boolean} isRequired
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.isRequired = false;

                /**
                 * MenuItemSelectionProto maxSelections.
                 * @member {number} maxSelections
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.maxSelections = 0;

                /**
                 * Creates a new MenuItemSelectionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemSelectionProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto} MenuItemSelectionProto instance
                 */
                MenuItemSelectionProto.create = function create(properties) {
                    return new MenuItemSelectionProto(properties);
                };

                /**
                 * Encodes the specified MenuItemSelectionProto message. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemSelectionProto} message MenuItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemSelectionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        $root.waiternow.common.TextProto.encode(message.title, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.menuItemIds != null && message.menuItemIds.length)
                        for (var i = 0; i < message.menuItemIds.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.menuItemIds[i]);
                    if (message.selectionType != null && Object.hasOwnProperty.call(message, "selectionType"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.selectionType);
                    if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isRequired);
                    if (message.maxSelections != null && Object.hasOwnProperty.call(message, "maxSelections"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.maxSelections);
                    return writer;
                };

                /**
                 * Encodes the specified MenuItemSelectionProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IMenuItemSelectionProto} message MenuItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemSelectionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuItemSelectionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto} MenuItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemSelectionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.title = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.menuItemIds && message.menuItemIds.length))
                                    message.menuItemIds = [];
                                message.menuItemIds.push(reader.string());
                                break;
                            }
                        case 4: {
                                message.selectionType = reader.int32();
                                break;
                            }
                        case 5: {
                                message.isRequired = reader.bool();
                                break;
                            }
                        case 6: {
                                message.maxSelections = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuItemSelectionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto} MenuItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemSelectionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuItemSelectionProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuItemSelectionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.waiternow.common.TextProto.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.menuItemIds != null && message.hasOwnProperty("menuItemIds")) {
                        if (!Array.isArray(message.menuItemIds))
                            return "menuItemIds: array expected";
                        for (var i = 0; i < message.menuItemIds.length; ++i)
                            if (!$util.isString(message.menuItemIds[i]))
                                return "menuItemIds: string[] expected";
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        switch (message.selectionType) {
                        default:
                            return "selectionType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        if (typeof message.isRequired !== "boolean")
                            return "isRequired: boolean expected";
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        if (!$util.isInteger(message.maxSelections))
                            return "maxSelections: integer expected";
                    return null;
                };

                /**
                 * Creates a MenuItemSelectionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto} MenuItemSelectionProto
                 */
                MenuItemSelectionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.title: object expected");
                        message.title = $root.waiternow.common.TextProto.fromObject(object.title);
                    }
                    if (object.menuItemIds) {
                        if (!Array.isArray(object.menuItemIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto.menuItemIds: array expected");
                        message.menuItemIds = [];
                        for (var i = 0; i < object.menuItemIds.length; ++i)
                            message.menuItemIds[i] = String(object.menuItemIds[i]);
                    }
                    switch (object.selectionType) {
                    default:
                        if (typeof object.selectionType === "number") {
                            message.selectionType = object.selectionType;
                            break;
                        }
                        break;
                    case "SELECTION_TYPE_UNSPECIFIED":
                    case 0:
                        message.selectionType = 0;
                        break;
                    case "SINGLE":
                    case 1:
                        message.selectionType = 1;
                        break;
                    case "MULTIPLE":
                    case 2:
                        message.selectionType = 2;
                        break;
                    }
                    if (object.isRequired != null)
                        message.isRequired = Boolean(object.isRequired);
                    if (object.maxSelections != null)
                        message.maxSelections = object.maxSelections | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a MenuItemSelectionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto} message MenuItemSelectionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuItemSelectionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.menuItemIds = [];
                    if (options.defaults) {
                        object.id = "";
                        object.title = null;
                        object.selectionType = options.enums === String ? "SELECTION_TYPE_UNSPECIFIED" : 0;
                        object.isRequired = false;
                        object.maxSelections = 0;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.waiternow.common.TextProto.toObject(message.title, options);
                    if (message.menuItemIds && message.menuItemIds.length) {
                        object.menuItemIds = [];
                        for (var j = 0; j < message.menuItemIds.length; ++j)
                            object.menuItemIds[j] = message.menuItemIds[j];
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        object.selectionType = options.enums === String ? $root.waiternow.common.SelectionType[message.selectionType] === undefined ? message.selectionType : $root.waiternow.common.SelectionType[message.selectionType] : message.selectionType;
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        object.isRequired = message.isRequired;
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        object.maxSelections = message.maxSelections;
                    return object;
                };

                /**
                 * Converts this MenuItemSelectionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuItemSelectionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuItemSelectionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuItemSelectionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuSpecProto.MenuItemSelectionProto";
                };

                return MenuItemSelectionProto;
            })();

            StructuredMenuSpecProto.ComboProto = (function() {

                /**
                 * Properties of a ComboProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @interface IComboProto
                 * @property {string|null} [id] ComboProto id
                 * @property {waiternow.common.ITextProto|null} [name] ComboProto name
                 * @property {waiternow.common.ITextProto|null} [description] ComboProto description
                 * @property {waiternow.common.IImageProto|null} [image] ComboProto image
                 * @property {Array.<string>|null} [menuItemIds] ComboProto menuItemIds
                 * @property {Array.<string>|null} [menuItemSelectionIds] ComboProto menuItemSelectionIds
                 * @property {Array.<string>|null} [itemSelectionIds] ComboProto itemSelectionIds
                 * @property {boolean|null} [isUnavailable] ComboProto isUnavailable
                 * @property {waiternow.common.IMoneyProto|null} [price] ComboProto price
                 * @property {Array.<string>|null} [categoryIds] ComboProto categoryIds
                 * @property {string|null} [availabilityScheduleId] ComboProto availabilityScheduleId
                 */

                /**
                 * Constructs a new ComboProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @classdesc Represents a ComboProto.
                 * @implements IComboProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuSpecProto.IComboProto=} [properties] Properties to set
                 */
                function ComboProto(properties) {
                    this.menuItemIds = [];
                    this.menuItemSelectionIds = [];
                    this.itemSelectionIds = [];
                    this.categoryIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ComboProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.id = "";

                /**
                 * ComboProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.name = null;

                /**
                 * ComboProto description.
                 * @member {waiternow.common.ITextProto|null|undefined} description
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.description = null;

                /**
                 * ComboProto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.image = null;

                /**
                 * ComboProto menuItemIds.
                 * @member {Array.<string>} menuItemIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.menuItemIds = $util.emptyArray;

                /**
                 * ComboProto menuItemSelectionIds.
                 * @member {Array.<string>} menuItemSelectionIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.menuItemSelectionIds = $util.emptyArray;

                /**
                 * ComboProto itemSelectionIds.
                 * @member {Array.<string>} itemSelectionIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.itemSelectionIds = $util.emptyArray;

                /**
                 * ComboProto isUnavailable.
                 * @member {boolean} isUnavailable
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.isUnavailable = false;

                /**
                 * ComboProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.price = null;

                /**
                 * ComboProto categoryIds.
                 * @member {Array.<string>} categoryIds
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.categoryIds = $util.emptyArray;

                /**
                 * ComboProto availabilityScheduleId.
                 * @member {string} availabilityScheduleId
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.availabilityScheduleId = "";

                /**
                 * Creates a new ComboProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IComboProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuSpecProto.ComboProto} ComboProto instance
                 */
                ComboProto.create = function create(properties) {
                    return new ComboProto(properties);
                };

                /**
                 * Encodes the specified ComboProto message. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.ComboProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IComboProto} message ComboProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComboProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.menuItemIds != null && message.menuItemIds.length)
                        for (var i = 0; i < message.menuItemIds.length; ++i)
                            writer.uint32(/* id 3, wireType 2 =*/26).string(message.menuItemIds[i]);
                    if (message.menuItemSelectionIds != null && message.menuItemSelectionIds.length)
                        for (var i = 0; i < message.menuItemSelectionIds.length; ++i)
                            writer.uint32(/* id 4, wireType 2 =*/34).string(message.menuItemSelectionIds[i]);
                    if (message.itemSelectionIds != null && message.itemSelectionIds.length)
                        for (var i = 0; i < message.itemSelectionIds.length; ++i)
                            writer.uint32(/* id 5, wireType 2 =*/42).string(message.itemSelectionIds[i]);
                    if (message.isUnavailable != null && Object.hasOwnProperty.call(message, "isUnavailable"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isUnavailable);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.categoryIds != null && message.categoryIds.length)
                        for (var i = 0; i < message.categoryIds.length; ++i)
                            writer.uint32(/* id 8, wireType 2 =*/66).string(message.categoryIds[i]);
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        $root.waiternow.common.TextProto.encode(message.description, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.availabilityScheduleId != null && Object.hasOwnProperty.call(message, "availabilityScheduleId"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.availabilityScheduleId);
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ComboProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.ComboProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.IComboProto} message ComboProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComboProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ComboProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuSpecProto.ComboProto} ComboProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComboProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuSpecProto.ComboProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.description = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.menuItemIds && message.menuItemIds.length))
                                    message.menuItemIds = [];
                                message.menuItemIds.push(reader.string());
                                break;
                            }
                        case 4: {
                                if (!(message.menuItemSelectionIds && message.menuItemSelectionIds.length))
                                    message.menuItemSelectionIds = [];
                                message.menuItemSelectionIds.push(reader.string());
                                break;
                            }
                        case 5: {
                                if (!(message.itemSelectionIds && message.itemSelectionIds.length))
                                    message.itemSelectionIds = [];
                                message.itemSelectionIds.push(reader.string());
                                break;
                            }
                        case 6: {
                                message.isUnavailable = reader.bool();
                                break;
                            }
                        case 7: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                if (!(message.categoryIds && message.categoryIds.length))
                                    message.categoryIds = [];
                                message.categoryIds.push(reader.string());
                                break;
                            }
                        case 10: {
                                message.availabilityScheduleId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ComboProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuSpecProto.ComboProto} ComboProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComboProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ComboProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ComboProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description")) {
                        var error = $root.waiternow.common.TextProto.verify(message.description);
                        if (error)
                            return "description." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.menuItemIds != null && message.hasOwnProperty("menuItemIds")) {
                        if (!Array.isArray(message.menuItemIds))
                            return "menuItemIds: array expected";
                        for (var i = 0; i < message.menuItemIds.length; ++i)
                            if (!$util.isString(message.menuItemIds[i]))
                                return "menuItemIds: string[] expected";
                    }
                    if (message.menuItemSelectionIds != null && message.hasOwnProperty("menuItemSelectionIds")) {
                        if (!Array.isArray(message.menuItemSelectionIds))
                            return "menuItemSelectionIds: array expected";
                        for (var i = 0; i < message.menuItemSelectionIds.length; ++i)
                            if (!$util.isString(message.menuItemSelectionIds[i]))
                                return "menuItemSelectionIds: string[] expected";
                    }
                    if (message.itemSelectionIds != null && message.hasOwnProperty("itemSelectionIds")) {
                        if (!Array.isArray(message.itemSelectionIds))
                            return "itemSelectionIds: array expected";
                        for (var i = 0; i < message.itemSelectionIds.length; ++i)
                            if (!$util.isString(message.itemSelectionIds[i]))
                                return "itemSelectionIds: string[] expected";
                    }
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        if (typeof message.isUnavailable !== "boolean")
                            return "isUnavailable: boolean expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.categoryIds != null && message.hasOwnProperty("categoryIds")) {
                        if (!Array.isArray(message.categoryIds))
                            return "categoryIds: array expected";
                        for (var i = 0; i < message.categoryIds.length; ++i)
                            if (!$util.isString(message.categoryIds[i]))
                                return "categoryIds: string[] expected";
                    }
                    if (message.availabilityScheduleId != null && message.hasOwnProperty("availabilityScheduleId"))
                        if (!$util.isString(message.availabilityScheduleId))
                            return "availabilityScheduleId: string expected";
                    return null;
                };

                /**
                 * Creates a ComboProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuSpecProto.ComboProto} ComboProto
                 */
                ComboProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuSpecProto.ComboProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuSpecProto.ComboProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.description != null) {
                        if (typeof object.description !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.description: object expected");
                        message.description = $root.waiternow.common.TextProto.fromObject(object.description);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.menuItemIds) {
                        if (!Array.isArray(object.menuItemIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.menuItemIds: array expected");
                        message.menuItemIds = [];
                        for (var i = 0; i < object.menuItemIds.length; ++i)
                            message.menuItemIds[i] = String(object.menuItemIds[i]);
                    }
                    if (object.menuItemSelectionIds) {
                        if (!Array.isArray(object.menuItemSelectionIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.menuItemSelectionIds: array expected");
                        message.menuItemSelectionIds = [];
                        for (var i = 0; i < object.menuItemSelectionIds.length; ++i)
                            message.menuItemSelectionIds[i] = String(object.menuItemSelectionIds[i]);
                    }
                    if (object.itemSelectionIds) {
                        if (!Array.isArray(object.itemSelectionIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.itemSelectionIds: array expected");
                        message.itemSelectionIds = [];
                        for (var i = 0; i < object.itemSelectionIds.length; ++i)
                            message.itemSelectionIds[i] = String(object.itemSelectionIds[i]);
                    }
                    if (object.isUnavailable != null)
                        message.isUnavailable = Boolean(object.isUnavailable);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    if (object.categoryIds) {
                        if (!Array.isArray(object.categoryIds))
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.ComboProto.categoryIds: array expected");
                        message.categoryIds = [];
                        for (var i = 0; i < object.categoryIds.length; ++i)
                            message.categoryIds[i] = String(object.categoryIds[i]);
                    }
                    if (object.availabilityScheduleId != null)
                        message.availabilityScheduleId = String(object.availabilityScheduleId);
                    return message;
                };

                /**
                 * Creates a plain object from a ComboProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.ComboProto} message ComboProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ComboProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.menuItemIds = [];
                        object.menuItemSelectionIds = [];
                        object.itemSelectionIds = [];
                        object.categoryIds = [];
                    }
                    if (options.defaults) {
                        object.id = "";
                        object.name = null;
                        object.isUnavailable = false;
                        object.price = null;
                        object.description = null;
                        object.availabilityScheduleId = "";
                        object.image = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.menuItemIds && message.menuItemIds.length) {
                        object.menuItemIds = [];
                        for (var j = 0; j < message.menuItemIds.length; ++j)
                            object.menuItemIds[j] = message.menuItemIds[j];
                    }
                    if (message.menuItemSelectionIds && message.menuItemSelectionIds.length) {
                        object.menuItemSelectionIds = [];
                        for (var j = 0; j < message.menuItemSelectionIds.length; ++j)
                            object.menuItemSelectionIds[j] = message.menuItemSelectionIds[j];
                    }
                    if (message.itemSelectionIds && message.itemSelectionIds.length) {
                        object.itemSelectionIds = [];
                        for (var j = 0; j < message.itemSelectionIds.length; ++j)
                            object.itemSelectionIds[j] = message.itemSelectionIds[j];
                    }
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        object.isUnavailable = message.isUnavailable;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.categoryIds && message.categoryIds.length) {
                        object.categoryIds = [];
                        for (var j = 0; j < message.categoryIds.length; ++j)
                            object.categoryIds[j] = message.categoryIds[j];
                    }
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = $root.waiternow.common.TextProto.toObject(message.description, options);
                    if (message.availabilityScheduleId != null && message.hasOwnProperty("availabilityScheduleId"))
                        object.availabilityScheduleId = message.availabilityScheduleId;
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    return object;
                };

                /**
                 * Converts this ComboProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ComboProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ComboProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuSpecProto.ComboProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ComboProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuSpecProto.ComboProto";
                };

                return ComboProto;
            })();

            StructuredMenuSpecProto.CategoryProto = (function() {

                /**
                 * Properties of a CategoryProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @interface ICategoryProto
                 * @property {string|null} [id] CategoryProto id
                 * @property {string|null} [parentCategoryId] CategoryProto parentCategoryId
                 * @property {waiternow.common.ITextProto|null} [name] CategoryProto name
                 * @property {waiternow.common.IImageProto|null} [image] CategoryProto image
                 */

                /**
                 * Constructs a new CategoryProto.
                 * @memberof waiternow.common.StructuredMenuSpecProto
                 * @classdesc Represents a CategoryProto.
                 * @implements ICategoryProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuSpecProto.ICategoryProto=} [properties] Properties to set
                 */
                function CategoryProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CategoryProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @instance
                 */
                CategoryProto.prototype.id = "";

                /**
                 * CategoryProto parentCategoryId.
                 * @member {string} parentCategoryId
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @instance
                 */
                CategoryProto.prototype.parentCategoryId = "";

                /**
                 * CategoryProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @instance
                 */
                CategoryProto.prototype.name = null;

                /**
                 * CategoryProto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @instance
                 */
                CategoryProto.prototype.image = null;

                /**
                 * Creates a new CategoryProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.ICategoryProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuSpecProto.CategoryProto} CategoryProto instance
                 */
                CategoryProto.create = function create(properties) {
                    return new CategoryProto(properties);
                };

                /**
                 * Encodes the specified CategoryProto message. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.CategoryProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.ICategoryProto} message CategoryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                    if (message.parentCategoryId != null && Object.hasOwnProperty.call(message, "parentCategoryId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.parentCategoryId);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified CategoryProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuSpecProto.CategoryProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.ICategoryProto} message CategoryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CategoryProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuSpecProto.CategoryProto} CategoryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuSpecProto.CategoryProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.id = reader.string();
                                break;
                            }
                        case 2: {
                                message.parentCategoryId = reader.string();
                                break;
                            }
                        case 3: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CategoryProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuSpecProto.CategoryProto} CategoryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CategoryProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CategoryProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.parentCategoryId != null && message.hasOwnProperty("parentCategoryId"))
                        if (!$util.isString(message.parentCategoryId))
                            return "parentCategoryId: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    return null;
                };

                /**
                 * Creates a CategoryProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuSpecProto.CategoryProto} CategoryProto
                 */
                CategoryProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuSpecProto.CategoryProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuSpecProto.CategoryProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.parentCategoryId != null)
                        message.parentCategoryId = String(object.parentCategoryId);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.CategoryProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuSpecProto.CategoryProto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CategoryProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuSpecProto.CategoryProto} message CategoryProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CategoryProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.id = "";
                        object.parentCategoryId = "";
                        object.name = null;
                        object.image = null;
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.parentCategoryId != null && message.hasOwnProperty("parentCategoryId"))
                        object.parentCategoryId = message.parentCategoryId;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    return object;
                };

                /**
                 * Converts this CategoryProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CategoryProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CategoryProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuSpecProto.CategoryProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CategoryProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuSpecProto.CategoryProto";
                };

                return CategoryProto;
            })();

            return StructuredMenuSpecProto;
        })();

        common.StructuredMenuProto = (function() {

            /**
             * Properties of a StructuredMenuProto.
             * @memberof waiternow.common
             * @interface IStructuredMenuProto
             * @property {waiternow.common.IImageProto|null} [headerImage] StructuredMenuProto headerImage
             * @property {Array.<waiternow.common.StructuredMenuProto.ICategoryLevel1Proto>|null} [categories] StructuredMenuProto categories
             * @property {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>|null} [uncategorizedMenuEntries] StructuredMenuProto uncategorizedMenuEntries
             * @property {google.protobuf.ITimestamp|null} [expiration] StructuredMenuProto expiration
             */

            /**
             * Constructs a new StructuredMenuProto.
             * @memberof waiternow.common
             * @classdesc Represents a StructuredMenuProto.
             * @implements IStructuredMenuProto
             * @constructor
             * @param {waiternow.common.IStructuredMenuProto=} [properties] Properties to set
             */
            function StructuredMenuProto(properties) {
                this.categories = [];
                this.uncategorizedMenuEntries = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StructuredMenuProto headerImage.
             * @member {waiternow.common.IImageProto|null|undefined} headerImage
             * @memberof waiternow.common.StructuredMenuProto
             * @instance
             */
            StructuredMenuProto.prototype.headerImage = null;

            /**
             * StructuredMenuProto categories.
             * @member {Array.<waiternow.common.StructuredMenuProto.ICategoryLevel1Proto>} categories
             * @memberof waiternow.common.StructuredMenuProto
             * @instance
             */
            StructuredMenuProto.prototype.categories = $util.emptyArray;

            /**
             * StructuredMenuProto uncategorizedMenuEntries.
             * @member {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>} uncategorizedMenuEntries
             * @memberof waiternow.common.StructuredMenuProto
             * @instance
             */
            StructuredMenuProto.prototype.uncategorizedMenuEntries = $util.emptyArray;

            /**
             * StructuredMenuProto expiration.
             * @member {google.protobuf.ITimestamp|null|undefined} expiration
             * @memberof waiternow.common.StructuredMenuProto
             * @instance
             */
            StructuredMenuProto.prototype.expiration = null;

            /**
             * Creates a new StructuredMenuProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {waiternow.common.IStructuredMenuProto=} [properties] Properties to set
             * @returns {waiternow.common.StructuredMenuProto} StructuredMenuProto instance
             */
            StructuredMenuProto.create = function create(properties) {
                return new StructuredMenuProto(properties);
            };

            /**
             * Encodes the specified StructuredMenuProto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {waiternow.common.IStructuredMenuProto} message StructuredMenuProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredMenuProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.headerImage != null && Object.hasOwnProperty.call(message, "headerImage"))
                    $root.waiternow.common.ImageProto.encode(message.headerImage, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.categories != null && message.categories.length)
                    for (var i = 0; i < message.categories.length; ++i)
                        $root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto.encode(message.categories[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.uncategorizedMenuEntries != null && message.uncategorizedMenuEntries.length)
                    for (var i = 0; i < message.uncategorizedMenuEntries.length; ++i)
                        $root.waiternow.common.StructuredMenuProto.MenuEntryProto.encode(message.uncategorizedMenuEntries[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                    $root.google.protobuf.Timestamp.encode(message.expiration, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified StructuredMenuProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {waiternow.common.IStructuredMenuProto} message StructuredMenuProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredMenuProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StructuredMenuProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.StructuredMenuProto} StructuredMenuProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredMenuProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.headerImage = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            if (!(message.categories && message.categories.length))
                                message.categories = [];
                            message.categories.push($root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.uncategorizedMenuEntries && message.uncategorizedMenuEntries.length))
                                message.uncategorizedMenuEntries = [];
                            message.uncategorizedMenuEntries.push($root.waiternow.common.StructuredMenuProto.MenuEntryProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            message.expiration = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StructuredMenuProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.StructuredMenuProto} StructuredMenuProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredMenuProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StructuredMenuProto message.
             * @function verify
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StructuredMenuProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.headerImage != null && message.hasOwnProperty("headerImage")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.headerImage);
                    if (error)
                        return "headerImage." + error;
                }
                if (message.categories != null && message.hasOwnProperty("categories")) {
                    if (!Array.isArray(message.categories))
                        return "categories: array expected";
                    for (var i = 0; i < message.categories.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto.verify(message.categories[i]);
                        if (error)
                            return "categories." + error;
                    }
                }
                if (message.uncategorizedMenuEntries != null && message.hasOwnProperty("uncategorizedMenuEntries")) {
                    if (!Array.isArray(message.uncategorizedMenuEntries))
                        return "uncategorizedMenuEntries: array expected";
                    for (var i = 0; i < message.uncategorizedMenuEntries.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.verify(message.uncategorizedMenuEntries[i]);
                        if (error)
                            return "uncategorizedMenuEntries." + error;
                    }
                }
                if (message.expiration != null && message.hasOwnProperty("expiration")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.expiration);
                    if (error)
                        return "expiration." + error;
                }
                return null;
            };

            /**
             * Creates a StructuredMenuProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.StructuredMenuProto} StructuredMenuProto
             */
            StructuredMenuProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.StructuredMenuProto)
                    return object;
                var message = new $root.waiternow.common.StructuredMenuProto();
                if (object.headerImage != null) {
                    if (typeof object.headerImage !== "object")
                        throw TypeError(".waiternow.common.StructuredMenuProto.headerImage: object expected");
                    message.headerImage = $root.waiternow.common.ImageProto.fromObject(object.headerImage);
                }
                if (object.categories) {
                    if (!Array.isArray(object.categories))
                        throw TypeError(".waiternow.common.StructuredMenuProto.categories: array expected");
                    message.categories = [];
                    for (var i = 0; i < object.categories.length; ++i) {
                        if (typeof object.categories[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.categories: object expected");
                        message.categories[i] = $root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto.fromObject(object.categories[i]);
                    }
                }
                if (object.uncategorizedMenuEntries) {
                    if (!Array.isArray(object.uncategorizedMenuEntries))
                        throw TypeError(".waiternow.common.StructuredMenuProto.uncategorizedMenuEntries: array expected");
                    message.uncategorizedMenuEntries = [];
                    for (var i = 0; i < object.uncategorizedMenuEntries.length; ++i) {
                        if (typeof object.uncategorizedMenuEntries[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.uncategorizedMenuEntries: object expected");
                        message.uncategorizedMenuEntries[i] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.fromObject(object.uncategorizedMenuEntries[i]);
                    }
                }
                if (object.expiration != null) {
                    if (typeof object.expiration !== "object")
                        throw TypeError(".waiternow.common.StructuredMenuProto.expiration: object expected");
                    message.expiration = $root.google.protobuf.Timestamp.fromObject(object.expiration);
                }
                return message;
            };

            /**
             * Creates a plain object from a StructuredMenuProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {waiternow.common.StructuredMenuProto} message StructuredMenuProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StructuredMenuProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.categories = [];
                    object.uncategorizedMenuEntries = [];
                }
                if (options.defaults) {
                    object.headerImage = null;
                    object.expiration = null;
                }
                if (message.headerImage != null && message.hasOwnProperty("headerImage"))
                    object.headerImage = $root.waiternow.common.ImageProto.toObject(message.headerImage, options);
                if (message.categories && message.categories.length) {
                    object.categories = [];
                    for (var j = 0; j < message.categories.length; ++j)
                        object.categories[j] = $root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto.toObject(message.categories[j], options);
                }
                if (message.uncategorizedMenuEntries && message.uncategorizedMenuEntries.length) {
                    object.uncategorizedMenuEntries = [];
                    for (var j = 0; j < message.uncategorizedMenuEntries.length; ++j)
                        object.uncategorizedMenuEntries[j] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.toObject(message.uncategorizedMenuEntries[j], options);
                }
                if (message.expiration != null && message.hasOwnProperty("expiration"))
                    object.expiration = $root.google.protobuf.Timestamp.toObject(message.expiration, options);
                return object;
            };

            /**
             * Converts this StructuredMenuProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.StructuredMenuProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StructuredMenuProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StructuredMenuProto
             * @function getTypeUrl
             * @memberof waiternow.common.StructuredMenuProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StructuredMenuProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.StructuredMenuProto";
            };

            StructuredMenuProto.ItemProto = (function() {

                /**
                 * Properties of an ItemProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface IItemProto
                 * @property {string|null} [id] ItemProto id
                 * @property {waiternow.common.ITextProto|null} [name] ItemProto name
                 * @property {waiternow.common.ITextProto|null} [description] ItemProto description
                 * @property {waiternow.common.IImageProto|null} [image] ItemProto image
                 * @property {waiternow.common.IMoneyProto|null} [price] ItemProto price
                 */

                /**
                 * Constructs a new ItemProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents an ItemProto.
                 * @implements IItemProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.IItemProto=} [properties] Properties to set
                 */
                function ItemProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.id = "";

                /**
                 * ItemProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.name = null;

                /**
                 * ItemProto description.
                 * @member {waiternow.common.ITextProto|null|undefined} description
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.description = null;

                /**
                 * ItemProto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.image = null;

                /**
                 * ItemProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.price = null;

                /**
                 * Creates a new ItemProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IItemProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.ItemProto} ItemProto instance
                 */
                ItemProto.create = function create(properties) {
                    return new ItemProto(properties);
                };

                /**
                 * Encodes the specified ItemProto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.ItemProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IItemProto} message ItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        $root.waiternow.common.TextProto.encode(message.description, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified ItemProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.ItemProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IItemProto} message ItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ItemProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.ItemProto} ItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.ItemProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 5: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.description = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ItemProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.ItemProto} ItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ItemProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description")) {
                        var error = $root.waiternow.common.TextProto.verify(message.description);
                        if (error)
                            return "description." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ItemProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.ItemProto} ItemProto
                 */
                ItemProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.ItemProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.ItemProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ItemProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.description != null) {
                        if (typeof object.description !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ItemProto.description: object expected");
                        message.description = $root.waiternow.common.TextProto.fromObject(object.description);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ItemProto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ItemProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ItemProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ItemProto} message ItemProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = null;
                        object.description = null;
                        object.image = null;
                        object.price = null;
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = $root.waiternow.common.TextProto.toObject(message.description, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this ItemProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ItemProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.ItemProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ItemProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.ItemProto";
                };

                return ItemProto;
            })();

            StructuredMenuProto.ItemSelectionProto = (function() {

                /**
                 * Properties of an ItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface IItemSelectionProto
                 * @property {string|null} [id] ItemSelectionProto id
                 * @property {waiternow.common.ITextProto|null} [title] ItemSelectionProto title
                 * @property {Array.<waiternow.common.StructuredMenuProto.IItemProto>|null} [items] ItemSelectionProto items
                 * @property {waiternow.common.SelectionType|null} [selectionType] ItemSelectionProto selectionType
                 * @property {boolean|null} [isRequired] ItemSelectionProto isRequired
                 * @property {number|null} [maxSelections] ItemSelectionProto maxSelections
                 */

                /**
                 * Constructs a new ItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents an ItemSelectionProto.
                 * @implements IItemSelectionProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.IItemSelectionProto=} [properties] Properties to set
                 */
                function ItemSelectionProto(properties) {
                    this.items = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemSelectionProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.id = "";

                /**
                 * ItemSelectionProto title.
                 * @member {waiternow.common.ITextProto|null|undefined} title
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.title = null;

                /**
                 * ItemSelectionProto items.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IItemProto>} items
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.items = $util.emptyArray;

                /**
                 * ItemSelectionProto selectionType.
                 * @member {waiternow.common.SelectionType} selectionType
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.selectionType = 0;

                /**
                 * ItemSelectionProto isRequired.
                 * @member {boolean} isRequired
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.isRequired = false;

                /**
                 * ItemSelectionProto maxSelections.
                 * @member {number} maxSelections
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.maxSelections = 0;

                /**
                 * Creates a new ItemSelectionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IItemSelectionProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.ItemSelectionProto} ItemSelectionProto instance
                 */
                ItemSelectionProto.create = function create(properties) {
                    return new ItemSelectionProto(properties);
                };

                /**
                 * Encodes the specified ItemSelectionProto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.ItemSelectionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IItemSelectionProto} message ItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemSelectionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        $root.waiternow.common.TextProto.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.items != null && message.items.length)
                        for (var i = 0; i < message.items.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.ItemProto.encode(message.items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.selectionType != null && Object.hasOwnProperty.call(message, "selectionType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.selectionType);
                    if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isRequired);
                    if (message.maxSelections != null && Object.hasOwnProperty.call(message, "maxSelections"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxSelections);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified ItemSelectionProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.ItemSelectionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IItemSelectionProto} message ItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemSelectionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ItemSelectionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.ItemSelectionProto} ItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemSelectionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.ItemSelectionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 6: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.title = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.items && message.items.length))
                                    message.items = [];
                                message.items.push($root.waiternow.common.StructuredMenuProto.ItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.selectionType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.isRequired = reader.bool();
                                break;
                            }
                        case 5: {
                                message.maxSelections = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ItemSelectionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.ItemSelectionProto} ItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemSelectionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ItemSelectionProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemSelectionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.waiternow.common.TextProto.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.items != null && message.hasOwnProperty("items")) {
                        if (!Array.isArray(message.items))
                            return "items: array expected";
                        for (var i = 0; i < message.items.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.ItemProto.verify(message.items[i]);
                            if (error)
                                return "items." + error;
                        }
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        switch (message.selectionType) {
                        default:
                            return "selectionType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        if (typeof message.isRequired !== "boolean")
                            return "isRequired: boolean expected";
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        if (!$util.isInteger(message.maxSelections))
                            return "maxSelections: integer expected";
                    return null;
                };

                /**
                 * Creates an ItemSelectionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.ItemSelectionProto} ItemSelectionProto
                 */
                ItemSelectionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.ItemSelectionProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.ItemSelectionProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ItemSelectionProto.title: object expected");
                        message.title = $root.waiternow.common.TextProto.fromObject(object.title);
                    }
                    if (object.items) {
                        if (!Array.isArray(object.items))
                            throw TypeError(".waiternow.common.StructuredMenuProto.ItemSelectionProto.items: array expected");
                        message.items = [];
                        for (var i = 0; i < object.items.length; ++i) {
                            if (typeof object.items[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.ItemSelectionProto.items: object expected");
                            message.items[i] = $root.waiternow.common.StructuredMenuProto.ItemProto.fromObject(object.items[i]);
                        }
                    }
                    switch (object.selectionType) {
                    default:
                        if (typeof object.selectionType === "number") {
                            message.selectionType = object.selectionType;
                            break;
                        }
                        break;
                    case "SELECTION_TYPE_UNSPECIFIED":
                    case 0:
                        message.selectionType = 0;
                        break;
                    case "SINGLE":
                    case 1:
                        message.selectionType = 1;
                        break;
                    case "MULTIPLE":
                    case 2:
                        message.selectionType = 2;
                        break;
                    }
                    if (object.isRequired != null)
                        message.isRequired = Boolean(object.isRequired);
                    if (object.maxSelections != null)
                        message.maxSelections = object.maxSelections | 0;
                    return message;
                };

                /**
                 * Creates a plain object from an ItemSelectionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ItemSelectionProto} message ItemSelectionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemSelectionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.items = [];
                    if (options.defaults) {
                        object.title = null;
                        object.selectionType = options.enums === String ? "SELECTION_TYPE_UNSPECIFIED" : 0;
                        object.isRequired = false;
                        object.maxSelections = 0;
                        object.id = "";
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.waiternow.common.TextProto.toObject(message.title, options);
                    if (message.items && message.items.length) {
                        object.items = [];
                        for (var j = 0; j < message.items.length; ++j)
                            object.items[j] = $root.waiternow.common.StructuredMenuProto.ItemProto.toObject(message.items[j], options);
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        object.selectionType = options.enums === String ? $root.waiternow.common.SelectionType[message.selectionType] === undefined ? message.selectionType : $root.waiternow.common.SelectionType[message.selectionType] : message.selectionType;
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        object.isRequired = message.isRequired;
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        object.maxSelections = message.maxSelections;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this ItemSelectionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemSelectionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ItemSelectionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.ItemSelectionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ItemSelectionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.ItemSelectionProto";
                };

                return ItemSelectionProto;
            })();

            StructuredMenuProto.MenuItemProto = (function() {

                /**
                 * Properties of a MenuItemProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface IMenuItemProto
                 * @property {string|null} [id] MenuItemProto id
                 * @property {waiternow.common.ITextProto|null} [name] MenuItemProto name
                 * @property {waiternow.common.ITextProto|null} [description] MenuItemProto description
                 * @property {waiternow.common.IImageProto|null} [image] MenuItemProto image
                 * @property {Array.<waiternow.common.StructuredMenuProto.IItemProto>|null} [removableIngredients] MenuItemProto removableIngredients
                 * @property {Array.<waiternow.common.StructuredMenuProto.IItemSelectionProto>|null} [itemSelections] MenuItemProto itemSelections
                 * @property {number|null} [calories] MenuItemProto calories
                 * @property {boolean|null} [containsAlcohol] MenuItemProto containsAlcohol
                 * @property {boolean|null} [isUnavailable] MenuItemProto isUnavailable
                 * @property {waiternow.common.IMoneyProto|null} [price] MenuItemProto price
                 * @property {waiternow.common.IMoneyProto|null} [priceInCombo] MenuItemProto priceInCombo
                 * @property {string|null} [station] MenuItemProto station
                 * @property {waiternow.common.IAvailabilityScheduleProto|null} [availabilitySchedule] MenuItemProto availabilitySchedule
                 */

                /**
                 * Constructs a new MenuItemProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents a MenuItemProto.
                 * @implements IMenuItemProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemProto=} [properties] Properties to set
                 */
                function MenuItemProto(properties) {
                    this.removableIngredients = [];
                    this.itemSelections = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuItemProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.id = "";

                /**
                 * MenuItemProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.name = null;

                /**
                 * MenuItemProto description.
                 * @member {waiternow.common.ITextProto|null|undefined} description
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.description = null;

                /**
                 * MenuItemProto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.image = null;

                /**
                 * MenuItemProto removableIngredients.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IItemProto>} removableIngredients
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.removableIngredients = $util.emptyArray;

                /**
                 * MenuItemProto itemSelections.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IItemSelectionProto>} itemSelections
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.itemSelections = $util.emptyArray;

                /**
                 * MenuItemProto calories.
                 * @member {number} calories
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.calories = 0;

                /**
                 * MenuItemProto containsAlcohol.
                 * @member {boolean} containsAlcohol
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.containsAlcohol = false;

                /**
                 * MenuItemProto isUnavailable.
                 * @member {boolean} isUnavailable
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.isUnavailable = false;

                /**
                 * MenuItemProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.price = null;

                /**
                 * MenuItemProto priceInCombo.
                 * @member {waiternow.common.IMoneyProto|null|undefined} priceInCombo
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.priceInCombo = null;

                /**
                 * MenuItemProto station.
                 * @member {string} station
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.station = "";

                /**
                 * MenuItemProto availabilitySchedule.
                 * @member {waiternow.common.IAvailabilityScheduleProto|null|undefined} availabilitySchedule
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.availabilitySchedule = null;

                /**
                 * Creates a new MenuItemProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemProto} MenuItemProto instance
                 */
                MenuItemProto.create = function create(properties) {
                    return new MenuItemProto(properties);
                };

                /**
                 * Encodes the specified MenuItemProto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.MenuItemProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemProto} message MenuItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        $root.waiternow.common.TextProto.encode(message.description, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.removableIngredients != null && message.removableIngredients.length)
                        for (var i = 0; i < message.removableIngredients.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.ItemProto.encode(message.removableIngredients[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.itemSelections != null && message.itemSelections.length)
                        for (var i = 0; i < message.itemSelections.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.encode(message.itemSelections[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.calories != null && Object.hasOwnProperty.call(message, "calories"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.calories);
                    if (message.containsAlcohol != null && Object.hasOwnProperty.call(message, "containsAlcohol"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.containsAlcohol);
                    if (message.isUnavailable != null && Object.hasOwnProperty.call(message, "isUnavailable"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isUnavailable);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.priceInCombo != null && Object.hasOwnProperty.call(message, "priceInCombo"))
                        $root.waiternow.common.MoneyProto.encode(message.priceInCombo, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.station != null && Object.hasOwnProperty.call(message, "station"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.station);
                    if (message.availabilitySchedule != null && Object.hasOwnProperty.call(message, "availabilitySchedule"))
                        $root.waiternow.common.AvailabilityScheduleProto.encode(message.availabilitySchedule, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified MenuItemProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.MenuItemProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemProto} message MenuItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuItemProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemProto} MenuItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.MenuItemProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 13: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.description = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                if (!(message.removableIngredients && message.removableIngredients.length))
                                    message.removableIngredients = [];
                                message.removableIngredients.push($root.waiternow.common.StructuredMenuProto.ItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                if (!(message.itemSelections && message.itemSelections.length))
                                    message.itemSelections = [];
                                message.itemSelections.push($root.waiternow.common.StructuredMenuProto.ItemSelectionProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 6: {
                                message.calories = reader.int32();
                                break;
                            }
                        case 7: {
                                message.containsAlcohol = reader.bool();
                                break;
                            }
                        case 8: {
                                message.isUnavailable = reader.bool();
                                break;
                            }
                        case 9: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.priceInCombo = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.station = reader.string();
                                break;
                            }
                        case 12: {
                                message.availabilitySchedule = $root.waiternow.common.AvailabilityScheduleProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuItemProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemProto} MenuItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuItemProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuItemProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description")) {
                        var error = $root.waiternow.common.TextProto.verify(message.description);
                        if (error)
                            return "description." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.removableIngredients != null && message.hasOwnProperty("removableIngredients")) {
                        if (!Array.isArray(message.removableIngredients))
                            return "removableIngredients: array expected";
                        for (var i = 0; i < message.removableIngredients.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.ItemProto.verify(message.removableIngredients[i]);
                            if (error)
                                return "removableIngredients." + error;
                        }
                    }
                    if (message.itemSelections != null && message.hasOwnProperty("itemSelections")) {
                        if (!Array.isArray(message.itemSelections))
                            return "itemSelections: array expected";
                        for (var i = 0; i < message.itemSelections.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.verify(message.itemSelections[i]);
                            if (error)
                                return "itemSelections." + error;
                        }
                    }
                    if (message.calories != null && message.hasOwnProperty("calories"))
                        if (!$util.isInteger(message.calories))
                            return "calories: integer expected";
                    if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                        if (typeof message.containsAlcohol !== "boolean")
                            return "containsAlcohol: boolean expected";
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        if (typeof message.isUnavailable !== "boolean")
                            return "isUnavailable: boolean expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.priceInCombo != null && message.hasOwnProperty("priceInCombo")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.priceInCombo);
                        if (error)
                            return "priceInCombo." + error;
                    }
                    if (message.station != null && message.hasOwnProperty("station"))
                        if (!$util.isString(message.station))
                            return "station: string expected";
                    if (message.availabilitySchedule != null && message.hasOwnProperty("availabilitySchedule")) {
                        var error = $root.waiternow.common.AvailabilityScheduleProto.verify(message.availabilitySchedule);
                        if (error)
                            return "availabilitySchedule." + error;
                    }
                    return null;
                };

                /**
                 * Creates a MenuItemProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemProto} MenuItemProto
                 */
                MenuItemProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.MenuItemProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.MenuItemProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.description != null) {
                        if (typeof object.description !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.description: object expected");
                        message.description = $root.waiternow.common.TextProto.fromObject(object.description);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.removableIngredients) {
                        if (!Array.isArray(object.removableIngredients))
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.removableIngredients: array expected");
                        message.removableIngredients = [];
                        for (var i = 0; i < object.removableIngredients.length; ++i) {
                            if (typeof object.removableIngredients[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.removableIngredients: object expected");
                            message.removableIngredients[i] = $root.waiternow.common.StructuredMenuProto.ItemProto.fromObject(object.removableIngredients[i]);
                        }
                    }
                    if (object.itemSelections) {
                        if (!Array.isArray(object.itemSelections))
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.itemSelections: array expected");
                        message.itemSelections = [];
                        for (var i = 0; i < object.itemSelections.length; ++i) {
                            if (typeof object.itemSelections[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.itemSelections: object expected");
                            message.itemSelections[i] = $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.fromObject(object.itemSelections[i]);
                        }
                    }
                    if (object.calories != null)
                        message.calories = object.calories | 0;
                    if (object.containsAlcohol != null)
                        message.containsAlcohol = Boolean(object.containsAlcohol);
                    if (object.isUnavailable != null)
                        message.isUnavailable = Boolean(object.isUnavailable);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    if (object.priceInCombo != null) {
                        if (typeof object.priceInCombo !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.priceInCombo: object expected");
                        message.priceInCombo = $root.waiternow.common.MoneyProto.fromObject(object.priceInCombo);
                    }
                    if (object.station != null)
                        message.station = String(object.station);
                    if (object.availabilitySchedule != null) {
                        if (typeof object.availabilitySchedule !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemProto.availabilitySchedule: object expected");
                        message.availabilitySchedule = $root.waiternow.common.AvailabilityScheduleProto.fromObject(object.availabilitySchedule);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MenuItemProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.MenuItemProto} message MenuItemProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuItemProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.removableIngredients = [];
                        object.itemSelections = [];
                    }
                    if (options.defaults) {
                        object.name = null;
                        object.description = null;
                        object.image = null;
                        object.calories = 0;
                        object.containsAlcohol = false;
                        object.isUnavailable = false;
                        object.price = null;
                        object.priceInCombo = null;
                        object.station = "";
                        object.availabilitySchedule = null;
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = $root.waiternow.common.TextProto.toObject(message.description, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    if (message.removableIngredients && message.removableIngredients.length) {
                        object.removableIngredients = [];
                        for (var j = 0; j < message.removableIngredients.length; ++j)
                            object.removableIngredients[j] = $root.waiternow.common.StructuredMenuProto.ItemProto.toObject(message.removableIngredients[j], options);
                    }
                    if (message.itemSelections && message.itemSelections.length) {
                        object.itemSelections = [];
                        for (var j = 0; j < message.itemSelections.length; ++j)
                            object.itemSelections[j] = $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.toObject(message.itemSelections[j], options);
                    }
                    if (message.calories != null && message.hasOwnProperty("calories"))
                        object.calories = message.calories;
                    if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                        object.containsAlcohol = message.containsAlcohol;
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        object.isUnavailable = message.isUnavailable;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.priceInCombo != null && message.hasOwnProperty("priceInCombo"))
                        object.priceInCombo = $root.waiternow.common.MoneyProto.toObject(message.priceInCombo, options);
                    if (message.station != null && message.hasOwnProperty("station"))
                        object.station = message.station;
                    if (message.availabilitySchedule != null && message.hasOwnProperty("availabilitySchedule"))
                        object.availabilitySchedule = $root.waiternow.common.AvailabilityScheduleProto.toObject(message.availabilitySchedule, options);
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this MenuItemProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuItemProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuItemProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuItemProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.MenuItemProto";
                };

                return MenuItemProto;
            })();

            StructuredMenuProto.MenuItemSelectionProto = (function() {

                /**
                 * Properties of a MenuItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface IMenuItemSelectionProto
                 * @property {string|null} [id] MenuItemSelectionProto id
                 * @property {waiternow.common.ITextProto|null} [title] MenuItemSelectionProto title
                 * @property {Array.<waiternow.common.StructuredMenuProto.IMenuItemProto>|null} [menuItems] MenuItemSelectionProto menuItems
                 * @property {waiternow.common.SelectionType|null} [selectionType] MenuItemSelectionProto selectionType
                 * @property {boolean|null} [isRequired] MenuItemSelectionProto isRequired
                 * @property {number|null} [maxSelections] MenuItemSelectionProto maxSelections
                 */

                /**
                 * Constructs a new MenuItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents a MenuItemSelectionProto.
                 * @implements IMenuItemSelectionProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemSelectionProto=} [properties] Properties to set
                 */
                function MenuItemSelectionProto(properties) {
                    this.menuItems = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuItemSelectionProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.id = "";

                /**
                 * MenuItemSelectionProto title.
                 * @member {waiternow.common.ITextProto|null|undefined} title
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.title = null;

                /**
                 * MenuItemSelectionProto menuItems.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IMenuItemProto>} menuItems
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.menuItems = $util.emptyArray;

                /**
                 * MenuItemSelectionProto selectionType.
                 * @member {waiternow.common.SelectionType} selectionType
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.selectionType = 0;

                /**
                 * MenuItemSelectionProto isRequired.
                 * @member {boolean} isRequired
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.isRequired = false;

                /**
                 * MenuItemSelectionProto maxSelections.
                 * @member {number} maxSelections
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.maxSelections = 0;

                /**
                 * Creates a new MenuItemSelectionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemSelectionProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemSelectionProto} MenuItemSelectionProto instance
                 */
                MenuItemSelectionProto.create = function create(properties) {
                    return new MenuItemSelectionProto(properties);
                };

                /**
                 * Encodes the specified MenuItemSelectionProto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.MenuItemSelectionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemSelectionProto} message MenuItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemSelectionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        $root.waiternow.common.TextProto.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menuItems != null && message.menuItems.length)
                        for (var i = 0; i < message.menuItems.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.MenuItemProto.encode(message.menuItems[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.selectionType != null && Object.hasOwnProperty.call(message, "selectionType"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.selectionType);
                    if (message.isRequired != null && Object.hasOwnProperty.call(message, "isRequired"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isRequired);
                    if (message.maxSelections != null && Object.hasOwnProperty.call(message, "maxSelections"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxSelections);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified MenuItemSelectionProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.MenuItemSelectionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuItemSelectionProto} message MenuItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemSelectionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuItemSelectionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemSelectionProto} MenuItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemSelectionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 6: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.title = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.menuItems && message.menuItems.length))
                                    message.menuItems = [];
                                message.menuItems.push($root.waiternow.common.StructuredMenuProto.MenuItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                message.selectionType = reader.int32();
                                break;
                            }
                        case 4: {
                                message.isRequired = reader.bool();
                                break;
                            }
                        case 5: {
                                message.maxSelections = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuItemSelectionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemSelectionProto} MenuItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemSelectionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuItemSelectionProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuItemSelectionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.waiternow.common.TextProto.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.menuItems != null && message.hasOwnProperty("menuItems")) {
                        if (!Array.isArray(message.menuItems))
                            return "menuItems: array expected";
                        for (var i = 0; i < message.menuItems.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.MenuItemProto.verify(message.menuItems[i]);
                            if (error)
                                return "menuItems." + error;
                        }
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        switch (message.selectionType) {
                        default:
                            return "selectionType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        if (typeof message.isRequired !== "boolean")
                            return "isRequired: boolean expected";
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        if (!$util.isInteger(message.maxSelections))
                            return "maxSelections: integer expected";
                    return null;
                };

                /**
                 * Creates a MenuItemSelectionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.MenuItemSelectionProto} MenuItemSelectionProto
                 */
                MenuItemSelectionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemSelectionProto.title: object expected");
                        message.title = $root.waiternow.common.TextProto.fromObject(object.title);
                    }
                    if (object.menuItems) {
                        if (!Array.isArray(object.menuItems))
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemSelectionProto.menuItems: array expected");
                        message.menuItems = [];
                        for (var i = 0; i < object.menuItems.length; ++i) {
                            if (typeof object.menuItems[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.MenuItemSelectionProto.menuItems: object expected");
                            message.menuItems[i] = $root.waiternow.common.StructuredMenuProto.MenuItemProto.fromObject(object.menuItems[i]);
                        }
                    }
                    switch (object.selectionType) {
                    default:
                        if (typeof object.selectionType === "number") {
                            message.selectionType = object.selectionType;
                            break;
                        }
                        break;
                    case "SELECTION_TYPE_UNSPECIFIED":
                    case 0:
                        message.selectionType = 0;
                        break;
                    case "SINGLE":
                    case 1:
                        message.selectionType = 1;
                        break;
                    case "MULTIPLE":
                    case 2:
                        message.selectionType = 2;
                        break;
                    }
                    if (object.isRequired != null)
                        message.isRequired = Boolean(object.isRequired);
                    if (object.maxSelections != null)
                        message.maxSelections = object.maxSelections | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a MenuItemSelectionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.MenuItemSelectionProto} message MenuItemSelectionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuItemSelectionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.menuItems = [];
                    if (options.defaults) {
                        object.title = null;
                        object.selectionType = options.enums === String ? "SELECTION_TYPE_UNSPECIFIED" : 0;
                        object.isRequired = false;
                        object.maxSelections = 0;
                        object.id = "";
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.waiternow.common.TextProto.toObject(message.title, options);
                    if (message.menuItems && message.menuItems.length) {
                        object.menuItems = [];
                        for (var j = 0; j < message.menuItems.length; ++j)
                            object.menuItems[j] = $root.waiternow.common.StructuredMenuProto.MenuItemProto.toObject(message.menuItems[j], options);
                    }
                    if (message.selectionType != null && message.hasOwnProperty("selectionType"))
                        object.selectionType = options.enums === String ? $root.waiternow.common.SelectionType[message.selectionType] === undefined ? message.selectionType : $root.waiternow.common.SelectionType[message.selectionType] : message.selectionType;
                    if (message.isRequired != null && message.hasOwnProperty("isRequired"))
                        object.isRequired = message.isRequired;
                    if (message.maxSelections != null && message.hasOwnProperty("maxSelections"))
                        object.maxSelections = message.maxSelections;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this MenuItemSelectionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuItemSelectionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuItemSelectionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.MenuItemSelectionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuItemSelectionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.MenuItemSelectionProto";
                };

                return MenuItemSelectionProto;
            })();

            StructuredMenuProto.ComboProto = (function() {

                /**
                 * Properties of a ComboProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface IComboProto
                 * @property {string|null} [id] ComboProto id
                 * @property {waiternow.common.ITextProto|null} [name] ComboProto name
                 * @property {waiternow.common.ITextProto|null} [description] ComboProto description
                 * @property {waiternow.common.IImageProto|null} [image] ComboProto image
                 * @property {Array.<waiternow.common.StructuredMenuProto.IMenuItemProto>|null} [menuItems] ComboProto menuItems
                 * @property {Array.<waiternow.common.StructuredMenuProto.IMenuItemSelectionProto>|null} [menuItemSelections] ComboProto menuItemSelections
                 * @property {Array.<waiternow.common.StructuredMenuProto.IItemSelectionProto>|null} [itemSelections] ComboProto itemSelections
                 * @property {boolean|null} [isUnavailable] ComboProto isUnavailable
                 * @property {waiternow.common.IMoneyProto|null} [price] ComboProto price
                 * @property {waiternow.common.IAvailabilityScheduleProto|null} [availabilitySchedule] ComboProto availabilitySchedule
                 */

                /**
                 * Constructs a new ComboProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents a ComboProto.
                 * @implements IComboProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.IComboProto=} [properties] Properties to set
                 */
                function ComboProto(properties) {
                    this.menuItems = [];
                    this.menuItemSelections = [];
                    this.itemSelections = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ComboProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.id = "";

                /**
                 * ComboProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.name = null;

                /**
                 * ComboProto description.
                 * @member {waiternow.common.ITextProto|null|undefined} description
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.description = null;

                /**
                 * ComboProto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.image = null;

                /**
                 * ComboProto menuItems.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IMenuItemProto>} menuItems
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.menuItems = $util.emptyArray;

                /**
                 * ComboProto menuItemSelections.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IMenuItemSelectionProto>} menuItemSelections
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.menuItemSelections = $util.emptyArray;

                /**
                 * ComboProto itemSelections.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IItemSelectionProto>} itemSelections
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.itemSelections = $util.emptyArray;

                /**
                 * ComboProto isUnavailable.
                 * @member {boolean} isUnavailable
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.isUnavailable = false;

                /**
                 * ComboProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.price = null;

                /**
                 * ComboProto availabilitySchedule.
                 * @member {waiternow.common.IAvailabilityScheduleProto|null|undefined} availabilitySchedule
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.availabilitySchedule = null;

                /**
                 * Creates a new ComboProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IComboProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.ComboProto} ComboProto instance
                 */
                ComboProto.create = function create(properties) {
                    return new ComboProto(properties);
                };

                /**
                 * Encodes the specified ComboProto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.ComboProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IComboProto} message ComboProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComboProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menuItems != null && message.menuItems.length)
                        for (var i = 0; i < message.menuItems.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.MenuItemProto.encode(message.menuItems[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.menuItemSelections != null && message.menuItemSelections.length)
                        for (var i = 0; i < message.menuItemSelections.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto.encode(message.menuItemSelections[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.itemSelections != null && message.itemSelections.length)
                        for (var i = 0; i < message.itemSelections.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.encode(message.itemSelections[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.isUnavailable != null && Object.hasOwnProperty.call(message, "isUnavailable"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isUnavailable);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        $root.waiternow.common.TextProto.encode(message.description, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.availabilitySchedule != null && Object.hasOwnProperty.call(message, "availabilitySchedule"))
                        $root.waiternow.common.AvailabilityScheduleProto.encode(message.availabilitySchedule, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.id);
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ComboProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.ComboProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IComboProto} message ComboProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComboProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ComboProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.ComboProto} ComboProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComboProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.ComboProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 9: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.description = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.menuItems && message.menuItems.length))
                                    message.menuItems = [];
                                message.menuItems.push($root.waiternow.common.StructuredMenuProto.MenuItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.menuItemSelections && message.menuItemSelections.length))
                                    message.menuItemSelections = [];
                                message.menuItemSelections.push($root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.itemSelections && message.itemSelections.length))
                                    message.itemSelections = [];
                                message.itemSelections.push($root.waiternow.common.StructuredMenuProto.ItemSelectionProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                message.isUnavailable = reader.bool();
                                break;
                            }
                        case 6: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.availabilitySchedule = $root.waiternow.common.AvailabilityScheduleProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ComboProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.ComboProto} ComboProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComboProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ComboProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ComboProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description")) {
                        var error = $root.waiternow.common.TextProto.verify(message.description);
                        if (error)
                            return "description." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.menuItems != null && message.hasOwnProperty("menuItems")) {
                        if (!Array.isArray(message.menuItems))
                            return "menuItems: array expected";
                        for (var i = 0; i < message.menuItems.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.MenuItemProto.verify(message.menuItems[i]);
                            if (error)
                                return "menuItems." + error;
                        }
                    }
                    if (message.menuItemSelections != null && message.hasOwnProperty("menuItemSelections")) {
                        if (!Array.isArray(message.menuItemSelections))
                            return "menuItemSelections: array expected";
                        for (var i = 0; i < message.menuItemSelections.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto.verify(message.menuItemSelections[i]);
                            if (error)
                                return "menuItemSelections." + error;
                        }
                    }
                    if (message.itemSelections != null && message.hasOwnProperty("itemSelections")) {
                        if (!Array.isArray(message.itemSelections))
                            return "itemSelections: array expected";
                        for (var i = 0; i < message.itemSelections.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.verify(message.itemSelections[i]);
                            if (error)
                                return "itemSelections." + error;
                        }
                    }
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        if (typeof message.isUnavailable !== "boolean")
                            return "isUnavailable: boolean expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.availabilitySchedule != null && message.hasOwnProperty("availabilitySchedule")) {
                        var error = $root.waiternow.common.AvailabilityScheduleProto.verify(message.availabilitySchedule);
                        if (error)
                            return "availabilitySchedule." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ComboProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.ComboProto} ComboProto
                 */
                ComboProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.ComboProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.ComboProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.description != null) {
                        if (typeof object.description !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.description: object expected");
                        message.description = $root.waiternow.common.TextProto.fromObject(object.description);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.menuItems) {
                        if (!Array.isArray(object.menuItems))
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.menuItems: array expected");
                        message.menuItems = [];
                        for (var i = 0; i < object.menuItems.length; ++i) {
                            if (typeof object.menuItems[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.menuItems: object expected");
                            message.menuItems[i] = $root.waiternow.common.StructuredMenuProto.MenuItemProto.fromObject(object.menuItems[i]);
                        }
                    }
                    if (object.menuItemSelections) {
                        if (!Array.isArray(object.menuItemSelections))
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.menuItemSelections: array expected");
                        message.menuItemSelections = [];
                        for (var i = 0; i < object.menuItemSelections.length; ++i) {
                            if (typeof object.menuItemSelections[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.menuItemSelections: object expected");
                            message.menuItemSelections[i] = $root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto.fromObject(object.menuItemSelections[i]);
                        }
                    }
                    if (object.itemSelections) {
                        if (!Array.isArray(object.itemSelections))
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.itemSelections: array expected");
                        message.itemSelections = [];
                        for (var i = 0; i < object.itemSelections.length; ++i) {
                            if (typeof object.itemSelections[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.itemSelections: object expected");
                            message.itemSelections[i] = $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.fromObject(object.itemSelections[i]);
                        }
                    }
                    if (object.isUnavailable != null)
                        message.isUnavailable = Boolean(object.isUnavailable);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    if (object.availabilitySchedule != null) {
                        if (typeof object.availabilitySchedule !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.ComboProto.availabilitySchedule: object expected");
                        message.availabilitySchedule = $root.waiternow.common.AvailabilityScheduleProto.fromObject(object.availabilitySchedule);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ComboProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ComboProto} message ComboProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ComboProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.menuItems = [];
                        object.menuItemSelections = [];
                        object.itemSelections = [];
                    }
                    if (options.defaults) {
                        object.name = null;
                        object.isUnavailable = false;
                        object.price = null;
                        object.description = null;
                        object.availabilitySchedule = null;
                        object.id = "";
                        object.image = null;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.menuItems && message.menuItems.length) {
                        object.menuItems = [];
                        for (var j = 0; j < message.menuItems.length; ++j)
                            object.menuItems[j] = $root.waiternow.common.StructuredMenuProto.MenuItemProto.toObject(message.menuItems[j], options);
                    }
                    if (message.menuItemSelections && message.menuItemSelections.length) {
                        object.menuItemSelections = [];
                        for (var j = 0; j < message.menuItemSelections.length; ++j)
                            object.menuItemSelections[j] = $root.waiternow.common.StructuredMenuProto.MenuItemSelectionProto.toObject(message.menuItemSelections[j], options);
                    }
                    if (message.itemSelections && message.itemSelections.length) {
                        object.itemSelections = [];
                        for (var j = 0; j < message.itemSelections.length; ++j)
                            object.itemSelections[j] = $root.waiternow.common.StructuredMenuProto.ItemSelectionProto.toObject(message.itemSelections[j], options);
                    }
                    if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                        object.isUnavailable = message.isUnavailable;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = $root.waiternow.common.TextProto.toObject(message.description, options);
                    if (message.availabilitySchedule != null && message.hasOwnProperty("availabilitySchedule"))
                        object.availabilitySchedule = $root.waiternow.common.AvailabilityScheduleProto.toObject(message.availabilitySchedule, options);
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    return object;
                };

                /**
                 * Converts this ComboProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ComboProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ComboProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.ComboProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ComboProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.ComboProto";
                };

                return ComboProto;
            })();

            StructuredMenuProto.MenuEntryProto = (function() {

                /**
                 * Properties of a MenuEntryProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface IMenuEntryProto
                 * @property {waiternow.common.StructuredMenuProto.IMenuItemProto|null} [menuItem] MenuEntryProto menuItem
                 * @property {waiternow.common.StructuredMenuProto.IComboProto|null} [combo] MenuEntryProto combo
                 */

                /**
                 * Constructs a new MenuEntryProto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents a MenuEntryProto.
                 * @implements IMenuEntryProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.IMenuEntryProto=} [properties] Properties to set
                 */
                function MenuEntryProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuEntryProto menuItem.
                 * @member {waiternow.common.StructuredMenuProto.IMenuItemProto|null|undefined} menuItem
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @instance
                 */
                MenuEntryProto.prototype.menuItem = null;

                /**
                 * MenuEntryProto combo.
                 * @member {waiternow.common.StructuredMenuProto.IComboProto|null|undefined} combo
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @instance
                 */
                MenuEntryProto.prototype.combo = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * MenuEntryProto entryType.
                 * @member {"menuItem"|"combo"|undefined} entryType
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @instance
                 */
                Object.defineProperty(MenuEntryProto.prototype, "entryType", {
                    get: $util.oneOfGetter($oneOfFields = ["menuItem", "combo"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new MenuEntryProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuEntryProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.MenuEntryProto} MenuEntryProto instance
                 */
                MenuEntryProto.create = function create(properties) {
                    return new MenuEntryProto(properties);
                };

                /**
                 * Encodes the specified MenuEntryProto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.MenuEntryProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuEntryProto} message MenuEntryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuEntryProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.menuItem != null && Object.hasOwnProperty.call(message, "menuItem"))
                        $root.waiternow.common.StructuredMenuProto.MenuItemProto.encode(message.menuItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.combo != null && Object.hasOwnProperty.call(message, "combo"))
                        $root.waiternow.common.StructuredMenuProto.ComboProto.encode(message.combo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MenuEntryProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.MenuEntryProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.IMenuEntryProto} message MenuEntryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuEntryProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuEntryProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.MenuEntryProto} MenuEntryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuEntryProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.MenuEntryProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.menuItem = $root.waiternow.common.StructuredMenuProto.MenuItemProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.combo = $root.waiternow.common.StructuredMenuProto.ComboProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuEntryProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.MenuEntryProto} MenuEntryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuEntryProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuEntryProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuEntryProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.menuItem != null && message.hasOwnProperty("menuItem")) {
                        properties.entryType = 1;
                        {
                            var error = $root.waiternow.common.StructuredMenuProto.MenuItemProto.verify(message.menuItem);
                            if (error)
                                return "menuItem." + error;
                        }
                    }
                    if (message.combo != null && message.hasOwnProperty("combo")) {
                        if (properties.entryType === 1)
                            return "entryType: multiple values";
                        properties.entryType = 1;
                        {
                            var error = $root.waiternow.common.StructuredMenuProto.ComboProto.verify(message.combo);
                            if (error)
                                return "combo." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MenuEntryProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.MenuEntryProto} MenuEntryProto
                 */
                MenuEntryProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.MenuEntryProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.MenuEntryProto();
                    if (object.menuItem != null) {
                        if (typeof object.menuItem !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuEntryProto.menuItem: object expected");
                        message.menuItem = $root.waiternow.common.StructuredMenuProto.MenuItemProto.fromObject(object.menuItem);
                    }
                    if (object.combo != null) {
                        if (typeof object.combo !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.MenuEntryProto.combo: object expected");
                        message.combo = $root.waiternow.common.StructuredMenuProto.ComboProto.fromObject(object.combo);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MenuEntryProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.MenuEntryProto} message MenuEntryProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuEntryProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.menuItem != null && message.hasOwnProperty("menuItem")) {
                        object.menuItem = $root.waiternow.common.StructuredMenuProto.MenuItemProto.toObject(message.menuItem, options);
                        if (options.oneofs)
                            object.entryType = "menuItem";
                    }
                    if (message.combo != null && message.hasOwnProperty("combo")) {
                        object.combo = $root.waiternow.common.StructuredMenuProto.ComboProto.toObject(message.combo, options);
                        if (options.oneofs)
                            object.entryType = "combo";
                    }
                    return object;
                };

                /**
                 * Converts this MenuEntryProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuEntryProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuEntryProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.MenuEntryProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuEntryProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.MenuEntryProto";
                };

                return MenuEntryProto;
            })();

            StructuredMenuProto.CategoryLevel3Proto = (function() {

                /**
                 * Properties of a CategoryLevel3Proto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface ICategoryLevel3Proto
                 * @property {string|null} [id] CategoryLevel3Proto id
                 * @property {waiternow.common.ITextProto|null} [name] CategoryLevel3Proto name
                 * @property {waiternow.common.IImageProto|null} [image] CategoryLevel3Proto image
                 * @property {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>|null} [menuEntries] CategoryLevel3Proto menuEntries
                 */

                /**
                 * Constructs a new CategoryLevel3Proto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents a CategoryLevel3Proto.
                 * @implements ICategoryLevel3Proto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel3Proto=} [properties] Properties to set
                 */
                function CategoryLevel3Proto(properties) {
                    this.menuEntries = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CategoryLevel3Proto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @instance
                 */
                CategoryLevel3Proto.prototype.id = "";

                /**
                 * CategoryLevel3Proto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @instance
                 */
                CategoryLevel3Proto.prototype.name = null;

                /**
                 * CategoryLevel3Proto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @instance
                 */
                CategoryLevel3Proto.prototype.image = null;

                /**
                 * CategoryLevel3Proto menuEntries.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>} menuEntries
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @instance
                 */
                CategoryLevel3Proto.prototype.menuEntries = $util.emptyArray;

                /**
                 * Creates a new CategoryLevel3Proto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel3Proto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel3Proto} CategoryLevel3Proto instance
                 */
                CategoryLevel3Proto.create = function create(properties) {
                    return new CategoryLevel3Proto(properties);
                };

                /**
                 * Encodes the specified CategoryLevel3Proto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.CategoryLevel3Proto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel3Proto} message CategoryLevel3Proto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryLevel3Proto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.menuEntries != null && message.menuEntries.length)
                        for (var i = 0; i < message.menuEntries.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.MenuEntryProto.encode(message.menuEntries[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified CategoryLevel3Proto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.CategoryLevel3Proto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel3Proto} message CategoryLevel3Proto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryLevel3Proto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CategoryLevel3Proto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel3Proto} CategoryLevel3Proto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryLevel3Proto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 4: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.menuEntries && message.menuEntries.length))
                                    message.menuEntries = [];
                                message.menuEntries.push($root.waiternow.common.StructuredMenuProto.MenuEntryProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CategoryLevel3Proto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel3Proto} CategoryLevel3Proto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryLevel3Proto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CategoryLevel3Proto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CategoryLevel3Proto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.menuEntries != null && message.hasOwnProperty("menuEntries")) {
                        if (!Array.isArray(message.menuEntries))
                            return "menuEntries: array expected";
                        for (var i = 0; i < message.menuEntries.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.verify(message.menuEntries[i]);
                            if (error)
                                return "menuEntries." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CategoryLevel3Proto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel3Proto} CategoryLevel3Proto
                 */
                CategoryLevel3Proto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel3Proto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel3Proto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.menuEntries) {
                        if (!Array.isArray(object.menuEntries))
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel3Proto.menuEntries: array expected");
                        message.menuEntries = [];
                        for (var i = 0; i < object.menuEntries.length; ++i) {
                            if (typeof object.menuEntries[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel3Proto.menuEntries: object expected");
                            message.menuEntries[i] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.fromObject(object.menuEntries[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CategoryLevel3Proto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.CategoryLevel3Proto} message CategoryLevel3Proto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CategoryLevel3Proto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.menuEntries = [];
                    if (options.defaults) {
                        object.name = null;
                        object.image = null;
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    if (message.menuEntries && message.menuEntries.length) {
                        object.menuEntries = [];
                        for (var j = 0; j < message.menuEntries.length; ++j)
                            object.menuEntries[j] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.toObject(message.menuEntries[j], options);
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this CategoryLevel3Proto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CategoryLevel3Proto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CategoryLevel3Proto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel3Proto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CategoryLevel3Proto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.CategoryLevel3Proto";
                };

                return CategoryLevel3Proto;
            })();

            StructuredMenuProto.CategoryLevel2Proto = (function() {

                /**
                 * Properties of a CategoryLevel2Proto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface ICategoryLevel2Proto
                 * @property {string|null} [id] CategoryLevel2Proto id
                 * @property {waiternow.common.ITextProto|null} [name] CategoryLevel2Proto name
                 * @property {waiternow.common.IImageProto|null} [image] CategoryLevel2Proto image
                 * @property {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>|null} [menuEntries] CategoryLevel2Proto menuEntries
                 * @property {Array.<waiternow.common.StructuredMenuProto.ICategoryLevel3Proto>|null} [categories] CategoryLevel2Proto categories
                 */

                /**
                 * Constructs a new CategoryLevel2Proto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents a CategoryLevel2Proto.
                 * @implements ICategoryLevel2Proto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel2Proto=} [properties] Properties to set
                 */
                function CategoryLevel2Proto(properties) {
                    this.menuEntries = [];
                    this.categories = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CategoryLevel2Proto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @instance
                 */
                CategoryLevel2Proto.prototype.id = "";

                /**
                 * CategoryLevel2Proto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @instance
                 */
                CategoryLevel2Proto.prototype.name = null;

                /**
                 * CategoryLevel2Proto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @instance
                 */
                CategoryLevel2Proto.prototype.image = null;

                /**
                 * CategoryLevel2Proto menuEntries.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>} menuEntries
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @instance
                 */
                CategoryLevel2Proto.prototype.menuEntries = $util.emptyArray;

                /**
                 * CategoryLevel2Proto categories.
                 * @member {Array.<waiternow.common.StructuredMenuProto.ICategoryLevel3Proto>} categories
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @instance
                 */
                CategoryLevel2Proto.prototype.categories = $util.emptyArray;

                /**
                 * Creates a new CategoryLevel2Proto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel2Proto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel2Proto} CategoryLevel2Proto instance
                 */
                CategoryLevel2Proto.create = function create(properties) {
                    return new CategoryLevel2Proto(properties);
                };

                /**
                 * Encodes the specified CategoryLevel2Proto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.CategoryLevel2Proto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel2Proto} message CategoryLevel2Proto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryLevel2Proto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.menuEntries != null && message.menuEntries.length)
                        for (var i = 0; i < message.menuEntries.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.MenuEntryProto.encode(message.menuEntries[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.categories != null && message.categories.length)
                        for (var i = 0; i < message.categories.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto.encode(message.categories[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified CategoryLevel2Proto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.CategoryLevel2Proto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel2Proto} message CategoryLevel2Proto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryLevel2Proto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CategoryLevel2Proto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel2Proto} CategoryLevel2Proto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryLevel2Proto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 5: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.menuEntries && message.menuEntries.length))
                                    message.menuEntries = [];
                                message.menuEntries.push($root.waiternow.common.StructuredMenuProto.MenuEntryProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.categories && message.categories.length))
                                    message.categories = [];
                                message.categories.push($root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CategoryLevel2Proto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel2Proto} CategoryLevel2Proto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryLevel2Proto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CategoryLevel2Proto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CategoryLevel2Proto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.menuEntries != null && message.hasOwnProperty("menuEntries")) {
                        if (!Array.isArray(message.menuEntries))
                            return "menuEntries: array expected";
                        for (var i = 0; i < message.menuEntries.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.verify(message.menuEntries[i]);
                            if (error)
                                return "menuEntries." + error;
                        }
                    }
                    if (message.categories != null && message.hasOwnProperty("categories")) {
                        if (!Array.isArray(message.categories))
                            return "categories: array expected";
                        for (var i = 0; i < message.categories.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto.verify(message.categories[i]);
                            if (error)
                                return "categories." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CategoryLevel2Proto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel2Proto} CategoryLevel2Proto
                 */
                CategoryLevel2Proto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel2Proto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel2Proto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.menuEntries) {
                        if (!Array.isArray(object.menuEntries))
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel2Proto.menuEntries: array expected");
                        message.menuEntries = [];
                        for (var i = 0; i < object.menuEntries.length; ++i) {
                            if (typeof object.menuEntries[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel2Proto.menuEntries: object expected");
                            message.menuEntries[i] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.fromObject(object.menuEntries[i]);
                        }
                    }
                    if (object.categories) {
                        if (!Array.isArray(object.categories))
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel2Proto.categories: array expected");
                        message.categories = [];
                        for (var i = 0; i < object.categories.length; ++i) {
                            if (typeof object.categories[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel2Proto.categories: object expected");
                            message.categories[i] = $root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto.fromObject(object.categories[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CategoryLevel2Proto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.CategoryLevel2Proto} message CategoryLevel2Proto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CategoryLevel2Proto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.menuEntries = [];
                        object.categories = [];
                    }
                    if (options.defaults) {
                        object.name = null;
                        object.image = null;
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    if (message.menuEntries && message.menuEntries.length) {
                        object.menuEntries = [];
                        for (var j = 0; j < message.menuEntries.length; ++j)
                            object.menuEntries[j] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.toObject(message.menuEntries[j], options);
                    }
                    if (message.categories && message.categories.length) {
                        object.categories = [];
                        for (var j = 0; j < message.categories.length; ++j)
                            object.categories[j] = $root.waiternow.common.StructuredMenuProto.CategoryLevel3Proto.toObject(message.categories[j], options);
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this CategoryLevel2Proto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CategoryLevel2Proto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CategoryLevel2Proto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel2Proto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CategoryLevel2Proto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.CategoryLevel2Proto";
                };

                return CategoryLevel2Proto;
            })();

            StructuredMenuProto.CategoryLevel1Proto = (function() {

                /**
                 * Properties of a CategoryLevel1Proto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @interface ICategoryLevel1Proto
                 * @property {string|null} [id] CategoryLevel1Proto id
                 * @property {waiternow.common.ITextProto|null} [name] CategoryLevel1Proto name
                 * @property {waiternow.common.IImageProto|null} [image] CategoryLevel1Proto image
                 * @property {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>|null} [menuEntries] CategoryLevel1Proto menuEntries
                 * @property {Array.<waiternow.common.StructuredMenuProto.ICategoryLevel2Proto>|null} [categories] CategoryLevel1Proto categories
                 */

                /**
                 * Constructs a new CategoryLevel1Proto.
                 * @memberof waiternow.common.StructuredMenuProto
                 * @classdesc Represents a CategoryLevel1Proto.
                 * @implements ICategoryLevel1Proto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel1Proto=} [properties] Properties to set
                 */
                function CategoryLevel1Proto(properties) {
                    this.menuEntries = [];
                    this.categories = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CategoryLevel1Proto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @instance
                 */
                CategoryLevel1Proto.prototype.id = "";

                /**
                 * CategoryLevel1Proto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @instance
                 */
                CategoryLevel1Proto.prototype.name = null;

                /**
                 * CategoryLevel1Proto image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @instance
                 */
                CategoryLevel1Proto.prototype.image = null;

                /**
                 * CategoryLevel1Proto menuEntries.
                 * @member {Array.<waiternow.common.StructuredMenuProto.IMenuEntryProto>} menuEntries
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @instance
                 */
                CategoryLevel1Proto.prototype.menuEntries = $util.emptyArray;

                /**
                 * CategoryLevel1Proto categories.
                 * @member {Array.<waiternow.common.StructuredMenuProto.ICategoryLevel2Proto>} categories
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @instance
                 */
                CategoryLevel1Proto.prototype.categories = $util.emptyArray;

                /**
                 * Creates a new CategoryLevel1Proto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel1Proto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel1Proto} CategoryLevel1Proto instance
                 */
                CategoryLevel1Proto.create = function create(properties) {
                    return new CategoryLevel1Proto(properties);
                };

                /**
                 * Encodes the specified CategoryLevel1Proto message. Does not implicitly {@link waiternow.common.StructuredMenuProto.CategoryLevel1Proto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel1Proto} message CategoryLevel1Proto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryLevel1Proto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.menuEntries != null && message.menuEntries.length)
                        for (var i = 0; i < message.menuEntries.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.MenuEntryProto.encode(message.menuEntries[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.categories != null && message.categories.length)
                        for (var i = 0; i < message.categories.length; ++i)
                            $root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto.encode(message.categories[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified CategoryLevel1Proto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuProto.CategoryLevel1Proto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.ICategoryLevel1Proto} message CategoryLevel1Proto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CategoryLevel1Proto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CategoryLevel1Proto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel1Proto} CategoryLevel1Proto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryLevel1Proto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 5: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                if (!(message.menuEntries && message.menuEntries.length))
                                    message.menuEntries = [];
                                message.menuEntries.push($root.waiternow.common.StructuredMenuProto.MenuEntryProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.categories && message.categories.length))
                                    message.categories = [];
                                message.categories.push($root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CategoryLevel1Proto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel1Proto} CategoryLevel1Proto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CategoryLevel1Proto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CategoryLevel1Proto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CategoryLevel1Proto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    if (message.menuEntries != null && message.hasOwnProperty("menuEntries")) {
                        if (!Array.isArray(message.menuEntries))
                            return "menuEntries: array expected";
                        for (var i = 0; i < message.menuEntries.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.verify(message.menuEntries[i]);
                            if (error)
                                return "menuEntries." + error;
                        }
                    }
                    if (message.categories != null && message.hasOwnProperty("categories")) {
                        if (!Array.isArray(message.categories))
                            return "categories: array expected";
                        for (var i = 0; i < message.categories.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto.verify(message.categories[i]);
                            if (error)
                                return "categories." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a CategoryLevel1Proto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuProto.CategoryLevel1Proto} CategoryLevel1Proto
                 */
                CategoryLevel1Proto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuProto.CategoryLevel1Proto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel1Proto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel1Proto.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    if (object.menuEntries) {
                        if (!Array.isArray(object.menuEntries))
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel1Proto.menuEntries: array expected");
                        message.menuEntries = [];
                        for (var i = 0; i < object.menuEntries.length; ++i) {
                            if (typeof object.menuEntries[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel1Proto.menuEntries: object expected");
                            message.menuEntries[i] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.fromObject(object.menuEntries[i]);
                        }
                    }
                    if (object.categories) {
                        if (!Array.isArray(object.categories))
                            throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel1Proto.categories: array expected");
                        message.categories = [];
                        for (var i = 0; i < object.categories.length; ++i) {
                            if (typeof object.categories[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuProto.CategoryLevel1Proto.categories: object expected");
                            message.categories[i] = $root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto.fromObject(object.categories[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a CategoryLevel1Proto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {waiternow.common.StructuredMenuProto.CategoryLevel1Proto} message CategoryLevel1Proto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CategoryLevel1Proto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.menuEntries = [];
                        object.categories = [];
                    }
                    if (options.defaults) {
                        object.name = null;
                        object.image = null;
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    if (message.menuEntries && message.menuEntries.length) {
                        object.menuEntries = [];
                        for (var j = 0; j < message.menuEntries.length; ++j)
                            object.menuEntries[j] = $root.waiternow.common.StructuredMenuProto.MenuEntryProto.toObject(message.menuEntries[j], options);
                    }
                    if (message.categories && message.categories.length) {
                        object.categories = [];
                        for (var j = 0; j < message.categories.length; ++j)
                            object.categories[j] = $root.waiternow.common.StructuredMenuProto.CategoryLevel2Proto.toObject(message.categories[j], options);
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this CategoryLevel1Proto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CategoryLevel1Proto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CategoryLevel1Proto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuProto.CategoryLevel1Proto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CategoryLevel1Proto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuProto.CategoryLevel1Proto";
                };

                return CategoryLevel1Proto;
            })();

            return StructuredMenuProto;
        })();

        common.StructuredMenuOrderProto = (function() {

            /**
             * Properties of a StructuredMenuOrderProto.
             * @memberof waiternow.common
             * @interface IStructuredMenuOrderProto
             * @property {Array.<waiternow.common.StructuredMenuOrderProto.IMenuEntryProto>|null} [menuEntries] StructuredMenuOrderProto menuEntries
             * @property {boolean|null} [toGo] StructuredMenuOrderProto toGo
             * @property {boolean|null} [includeCutlery] StructuredMenuOrderProto includeCutlery
             */

            /**
             * Constructs a new StructuredMenuOrderProto.
             * @memberof waiternow.common
             * @classdesc Represents a StructuredMenuOrderProto.
             * @implements IStructuredMenuOrderProto
             * @constructor
             * @param {waiternow.common.IStructuredMenuOrderProto=} [properties] Properties to set
             */
            function StructuredMenuOrderProto(properties) {
                this.menuEntries = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * StructuredMenuOrderProto menuEntries.
             * @member {Array.<waiternow.common.StructuredMenuOrderProto.IMenuEntryProto>} menuEntries
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @instance
             */
            StructuredMenuOrderProto.prototype.menuEntries = $util.emptyArray;

            /**
             * StructuredMenuOrderProto toGo.
             * @member {boolean} toGo
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @instance
             */
            StructuredMenuOrderProto.prototype.toGo = false;

            /**
             * StructuredMenuOrderProto includeCutlery.
             * @member {boolean} includeCutlery
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @instance
             */
            StructuredMenuOrderProto.prototype.includeCutlery = false;

            /**
             * Creates a new StructuredMenuOrderProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {waiternow.common.IStructuredMenuOrderProto=} [properties] Properties to set
             * @returns {waiternow.common.StructuredMenuOrderProto} StructuredMenuOrderProto instance
             */
            StructuredMenuOrderProto.create = function create(properties) {
                return new StructuredMenuOrderProto(properties);
            };

            /**
             * Encodes the specified StructuredMenuOrderProto message. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {waiternow.common.IStructuredMenuOrderProto} message StructuredMenuOrderProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredMenuOrderProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.menuEntries != null && message.menuEntries.length)
                    for (var i = 0; i < message.menuEntries.length; ++i)
                        $root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto.encode(message.menuEntries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.toGo != null && Object.hasOwnProperty.call(message, "toGo"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.toGo);
                if (message.includeCutlery != null && Object.hasOwnProperty.call(message, "includeCutlery"))
                    writer.uint32(/* id 3, wireType 0 =*/24).bool(message.includeCutlery);
                return writer;
            };

            /**
             * Encodes the specified StructuredMenuOrderProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {waiternow.common.IStructuredMenuOrderProto} message StructuredMenuOrderProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StructuredMenuOrderProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StructuredMenuOrderProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.StructuredMenuOrderProto} StructuredMenuOrderProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredMenuOrderProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuOrderProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.menuEntries && message.menuEntries.length))
                                message.menuEntries = [];
                            message.menuEntries.push($root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.toGo = reader.bool();
                            break;
                        }
                    case 3: {
                            message.includeCutlery = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StructuredMenuOrderProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.StructuredMenuOrderProto} StructuredMenuOrderProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StructuredMenuOrderProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StructuredMenuOrderProto message.
             * @function verify
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StructuredMenuOrderProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.menuEntries != null && message.hasOwnProperty("menuEntries")) {
                    if (!Array.isArray(message.menuEntries))
                        return "menuEntries: array expected";
                    for (var i = 0; i < message.menuEntries.length; ++i) {
                        var error = $root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto.verify(message.menuEntries[i]);
                        if (error)
                            return "menuEntries." + error;
                    }
                }
                if (message.toGo != null && message.hasOwnProperty("toGo"))
                    if (typeof message.toGo !== "boolean")
                        return "toGo: boolean expected";
                if (message.includeCutlery != null && message.hasOwnProperty("includeCutlery"))
                    if (typeof message.includeCutlery !== "boolean")
                        return "includeCutlery: boolean expected";
                return null;
            };

            /**
             * Creates a StructuredMenuOrderProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.StructuredMenuOrderProto} StructuredMenuOrderProto
             */
            StructuredMenuOrderProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.StructuredMenuOrderProto)
                    return object;
                var message = new $root.waiternow.common.StructuredMenuOrderProto();
                if (object.menuEntries) {
                    if (!Array.isArray(object.menuEntries))
                        throw TypeError(".waiternow.common.StructuredMenuOrderProto.menuEntries: array expected");
                    message.menuEntries = [];
                    for (var i = 0; i < object.menuEntries.length; ++i) {
                        if (typeof object.menuEntries[i] !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.menuEntries: object expected");
                        message.menuEntries[i] = $root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto.fromObject(object.menuEntries[i]);
                    }
                }
                if (object.toGo != null)
                    message.toGo = Boolean(object.toGo);
                if (object.includeCutlery != null)
                    message.includeCutlery = Boolean(object.includeCutlery);
                return message;
            };

            /**
             * Creates a plain object from a StructuredMenuOrderProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {waiternow.common.StructuredMenuOrderProto} message StructuredMenuOrderProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StructuredMenuOrderProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.menuEntries = [];
                if (options.defaults) {
                    object.toGo = false;
                    object.includeCutlery = false;
                }
                if (message.menuEntries && message.menuEntries.length) {
                    object.menuEntries = [];
                    for (var j = 0; j < message.menuEntries.length; ++j)
                        object.menuEntries[j] = $root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto.toObject(message.menuEntries[j], options);
                }
                if (message.toGo != null && message.hasOwnProperty("toGo"))
                    object.toGo = message.toGo;
                if (message.includeCutlery != null && message.hasOwnProperty("includeCutlery"))
                    object.includeCutlery = message.includeCutlery;
                return object;
            };

            /**
             * Converts this StructuredMenuOrderProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StructuredMenuOrderProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StructuredMenuOrderProto
             * @function getTypeUrl
             * @memberof waiternow.common.StructuredMenuOrderProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StructuredMenuOrderProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.StructuredMenuOrderProto";
            };

            StructuredMenuOrderProto.ItemProto = (function() {

                /**
                 * Properties of an ItemProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @interface IItemProto
                 * @property {string|null} [id] ItemProto id
                 * @property {waiternow.common.ITextProto|null} [name] ItemProto name
                 * @property {waiternow.common.IMoneyProto|null} [price] ItemProto price
                 */

                /**
                 * Constructs a new ItemProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @classdesc Represents an ItemProto.
                 * @implements IItemProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemProto=} [properties] Properties to set
                 */
                function ItemProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.id = "";

                /**
                 * ItemProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.name = null;

                /**
                 * ItemProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @instance
                 */
                ItemProto.prototype.price = null;

                /**
                 * Creates a new ItemProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemProto} ItemProto instance
                 */
                ItemProto.create = function create(properties) {
                    return new ItemProto(properties);
                };

                /**
                 * Encodes the specified ItemProto message. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.ItemProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemProto} message ItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified ItemProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.ItemProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemProto} message ItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ItemProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemProto} ItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuOrderProto.ItemProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ItemProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemProto} ItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ItemProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    return null;
                };

                /**
                 * Creates an ItemProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemProto} ItemProto
                 */
                ItemProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuOrderProto.ItemProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuOrderProto.ItemProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ItemProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ItemProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ItemProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.ItemProto} message ItemProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.name = null;
                        object.price = null;
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this ItemProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ItemProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ItemProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuOrderProto.ItemProto";
                };

                return ItemProto;
            })();

            StructuredMenuOrderProto.ItemSelectionProto = (function() {

                /**
                 * Properties of an ItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @interface IItemSelectionProto
                 * @property {string|null} [id] ItemSelectionProto id
                 * @property {waiternow.common.ITextProto|null} [title] ItemSelectionProto title
                 * @property {Array.<waiternow.common.StructuredMenuOrderProto.IItemProto>|null} [selectedItems] ItemSelectionProto selectedItems
                 */

                /**
                 * Constructs a new ItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @classdesc Represents an ItemSelectionProto.
                 * @implements IItemSelectionProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemSelectionProto=} [properties] Properties to set
                 */
                function ItemSelectionProto(properties) {
                    this.selectedItems = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ItemSelectionProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.id = "";

                /**
                 * ItemSelectionProto title.
                 * @member {waiternow.common.ITextProto|null|undefined} title
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.title = null;

                /**
                 * ItemSelectionProto selectedItems.
                 * @member {Array.<waiternow.common.StructuredMenuOrderProto.IItemProto>} selectedItems
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @instance
                 */
                ItemSelectionProto.prototype.selectedItems = $util.emptyArray;

                /**
                 * Creates a new ItemSelectionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemSelectionProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemSelectionProto} ItemSelectionProto instance
                 */
                ItemSelectionProto.create = function create(properties) {
                    return new ItemSelectionProto(properties);
                };

                /**
                 * Encodes the specified ItemSelectionProto message. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemSelectionProto} message ItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemSelectionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        $root.waiternow.common.TextProto.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.selectedItems != null && message.selectedItems.length)
                        for (var i = 0; i < message.selectedItems.length; ++i)
                            $root.waiternow.common.StructuredMenuOrderProto.ItemProto.encode(message.selectedItems[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified ItemSelectionProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IItemSelectionProto} message ItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ItemSelectionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an ItemSelectionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemSelectionProto} ItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemSelectionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.title = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.selectedItems && message.selectedItems.length))
                                    message.selectedItems = [];
                                message.selectedItems.push($root.waiternow.common.StructuredMenuOrderProto.ItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an ItemSelectionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemSelectionProto} ItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ItemSelectionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an ItemSelectionProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ItemSelectionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.waiternow.common.TextProto.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.selectedItems != null && message.hasOwnProperty("selectedItems")) {
                        if (!Array.isArray(message.selectedItems))
                            return "selectedItems: array expected";
                        for (var i = 0; i < message.selectedItems.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.ItemProto.verify(message.selectedItems[i]);
                            if (error)
                                return "selectedItems." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates an ItemSelectionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuOrderProto.ItemSelectionProto} ItemSelectionProto
                 */
                ItemSelectionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.title: object expected");
                        message.title = $root.waiternow.common.TextProto.fromObject(object.title);
                    }
                    if (object.selectedItems) {
                        if (!Array.isArray(object.selectedItems))
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.selectedItems: array expected");
                        message.selectedItems = [];
                        for (var i = 0; i < object.selectedItems.length; ++i) {
                            if (typeof object.selectedItems[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.selectedItems: object expected");
                            message.selectedItems[i] = $root.waiternow.common.StructuredMenuOrderProto.ItemProto.fromObject(object.selectedItems[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an ItemSelectionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.ItemSelectionProto} message ItemSelectionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ItemSelectionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.selectedItems = [];
                    if (options.defaults) {
                        object.title = null;
                        object.id = "";
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.waiternow.common.TextProto.toObject(message.title, options);
                    if (message.selectedItems && message.selectedItems.length) {
                        object.selectedItems = [];
                        for (var j = 0; j < message.selectedItems.length; ++j)
                            object.selectedItems[j] = $root.waiternow.common.StructuredMenuOrderProto.ItemProto.toObject(message.selectedItems[j], options);
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this ItemSelectionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ItemSelectionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ItemSelectionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuOrderProto.ItemSelectionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ItemSelectionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuOrderProto.ItemSelectionProto";
                };

                return ItemSelectionProto;
            })();

            StructuredMenuOrderProto.MenuItemProto = (function() {

                /**
                 * Properties of a MenuItemProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @interface IMenuItemProto
                 * @property {string|null} [id] MenuItemProto id
                 * @property {waiternow.common.ITextProto|null} [name] MenuItemProto name
                 * @property {Array.<waiternow.common.StructuredMenuOrderProto.IItemProto>|null} [withoutIngredients] MenuItemProto withoutIngredients
                 * @property {Array.<waiternow.common.StructuredMenuOrderProto.IItemSelectionProto>|null} [itemSelections] MenuItemProto itemSelections
                 * @property {boolean|null} [containsAlcohol] MenuItemProto containsAlcohol
                 * @property {waiternow.common.IMoneyProto|null} [price] MenuItemProto price
                 * @property {waiternow.common.IMoneyProto|null} [priceInCombo] MenuItemProto priceInCombo
                 * @property {string|null} [station] MenuItemProto station
                 * @property {number|null} [quantity] MenuItemProto quantity
                 * @property {string|null} [specialInstructions] MenuItemProto specialInstructions
                 */

                /**
                 * Constructs a new MenuItemProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @classdesc Represents a MenuItemProto.
                 * @implements IMenuItemProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemProto=} [properties] Properties to set
                 */
                function MenuItemProto(properties) {
                    this.withoutIngredients = [];
                    this.itemSelections = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuItemProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.id = "";

                /**
                 * MenuItemProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.name = null;

                /**
                 * MenuItemProto withoutIngredients.
                 * @member {Array.<waiternow.common.StructuredMenuOrderProto.IItemProto>} withoutIngredients
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.withoutIngredients = $util.emptyArray;

                /**
                 * MenuItemProto itemSelections.
                 * @member {Array.<waiternow.common.StructuredMenuOrderProto.IItemSelectionProto>} itemSelections
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.itemSelections = $util.emptyArray;

                /**
                 * MenuItemProto containsAlcohol.
                 * @member {boolean} containsAlcohol
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.containsAlcohol = false;

                /**
                 * MenuItemProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.price = null;

                /**
                 * MenuItemProto priceInCombo.
                 * @member {waiternow.common.IMoneyProto|null|undefined} priceInCombo
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.priceInCombo = null;

                /**
                 * MenuItemProto station.
                 * @member {string} station
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.station = "";

                /**
                 * MenuItemProto quantity.
                 * @member {number} quantity
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.quantity = 0;

                /**
                 * MenuItemProto specialInstructions.
                 * @member {string} specialInstructions
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 */
                MenuItemProto.prototype.specialInstructions = "";

                /**
                 * Creates a new MenuItemProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemProto} MenuItemProto instance
                 */
                MenuItemProto.create = function create(properties) {
                    return new MenuItemProto(properties);
                };

                /**
                 * Encodes the specified MenuItemProto message. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.MenuItemProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemProto} message MenuItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.withoutIngredients != null && message.withoutIngredients.length)
                        for (var i = 0; i < message.withoutIngredients.length; ++i)
                            $root.waiternow.common.StructuredMenuOrderProto.ItemProto.encode(message.withoutIngredients[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.itemSelections != null && message.itemSelections.length)
                        for (var i = 0; i < message.itemSelections.length; ++i)
                            $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.encode(message.itemSelections[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.containsAlcohol != null && Object.hasOwnProperty.call(message, "containsAlcohol"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.containsAlcohol);
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.priceInCombo != null && Object.hasOwnProperty.call(message, "priceInCombo"))
                        $root.waiternow.common.MoneyProto.encode(message.priceInCombo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.station != null && Object.hasOwnProperty.call(message, "station"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.station);
                    if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.quantity);
                    if (message.specialInstructions != null && Object.hasOwnProperty.call(message, "specialInstructions"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.specialInstructions);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 10, wireType 2 =*/82).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified MenuItemProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.MenuItemProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemProto} message MenuItemProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuItemProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemProto} MenuItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 10: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.withoutIngredients && message.withoutIngredients.length))
                                    message.withoutIngredients = [];
                                message.withoutIngredients.push($root.waiternow.common.StructuredMenuOrderProto.ItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.itemSelections && message.itemSelections.length))
                                    message.itemSelections = [];
                                message.itemSelections.push($root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                message.containsAlcohol = reader.bool();
                                break;
                            }
                        case 5: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.priceInCombo = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.station = reader.string();
                                break;
                            }
                        case 8: {
                                message.quantity = reader.int32();
                                break;
                            }
                        case 9: {
                                message.specialInstructions = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuItemProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemProto} MenuItemProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuItemProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuItemProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.withoutIngredients != null && message.hasOwnProperty("withoutIngredients")) {
                        if (!Array.isArray(message.withoutIngredients))
                            return "withoutIngredients: array expected";
                        for (var i = 0; i < message.withoutIngredients.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.ItemProto.verify(message.withoutIngredients[i]);
                            if (error)
                                return "withoutIngredients." + error;
                        }
                    }
                    if (message.itemSelections != null && message.hasOwnProperty("itemSelections")) {
                        if (!Array.isArray(message.itemSelections))
                            return "itemSelections: array expected";
                        for (var i = 0; i < message.itemSelections.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.verify(message.itemSelections[i]);
                            if (error)
                                return "itemSelections." + error;
                        }
                    }
                    if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                        if (typeof message.containsAlcohol !== "boolean")
                            return "containsAlcohol: boolean expected";
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.priceInCombo != null && message.hasOwnProperty("priceInCombo")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.priceInCombo);
                        if (error)
                            return "priceInCombo." + error;
                    }
                    if (message.station != null && message.hasOwnProperty("station"))
                        if (!$util.isString(message.station))
                            return "station: string expected";
                    if (message.quantity != null && message.hasOwnProperty("quantity"))
                        if (!$util.isInteger(message.quantity))
                            return "quantity: integer expected";
                    if (message.specialInstructions != null && message.hasOwnProperty("specialInstructions"))
                        if (!$util.isString(message.specialInstructions))
                            return "specialInstructions: string expected";
                    return null;
                };

                /**
                 * Creates a MenuItemProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemProto} MenuItemProto
                 */
                MenuItemProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.withoutIngredients) {
                        if (!Array.isArray(object.withoutIngredients))
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemProto.withoutIngredients: array expected");
                        message.withoutIngredients = [];
                        for (var i = 0; i < object.withoutIngredients.length; ++i) {
                            if (typeof object.withoutIngredients[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemProto.withoutIngredients: object expected");
                            message.withoutIngredients[i] = $root.waiternow.common.StructuredMenuOrderProto.ItemProto.fromObject(object.withoutIngredients[i]);
                        }
                    }
                    if (object.itemSelections) {
                        if (!Array.isArray(object.itemSelections))
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemProto.itemSelections: array expected");
                        message.itemSelections = [];
                        for (var i = 0; i < object.itemSelections.length; ++i) {
                            if (typeof object.itemSelections[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemProto.itemSelections: object expected");
                            message.itemSelections[i] = $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.fromObject(object.itemSelections[i]);
                        }
                    }
                    if (object.containsAlcohol != null)
                        message.containsAlcohol = Boolean(object.containsAlcohol);
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    if (object.priceInCombo != null) {
                        if (typeof object.priceInCombo !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemProto.priceInCombo: object expected");
                        message.priceInCombo = $root.waiternow.common.MoneyProto.fromObject(object.priceInCombo);
                    }
                    if (object.station != null)
                        message.station = String(object.station);
                    if (object.quantity != null)
                        message.quantity = object.quantity | 0;
                    if (object.specialInstructions != null)
                        message.specialInstructions = String(object.specialInstructions);
                    return message;
                };

                /**
                 * Creates a plain object from a MenuItemProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.MenuItemProto} message MenuItemProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuItemProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.withoutIngredients = [];
                        object.itemSelections = [];
                    }
                    if (options.defaults) {
                        object.name = null;
                        object.containsAlcohol = false;
                        object.price = null;
                        object.priceInCombo = null;
                        object.station = "";
                        object.quantity = 0;
                        object.specialInstructions = "";
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.withoutIngredients && message.withoutIngredients.length) {
                        object.withoutIngredients = [];
                        for (var j = 0; j < message.withoutIngredients.length; ++j)
                            object.withoutIngredients[j] = $root.waiternow.common.StructuredMenuOrderProto.ItemProto.toObject(message.withoutIngredients[j], options);
                    }
                    if (message.itemSelections && message.itemSelections.length) {
                        object.itemSelections = [];
                        for (var j = 0; j < message.itemSelections.length; ++j)
                            object.itemSelections[j] = $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.toObject(message.itemSelections[j], options);
                    }
                    if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                        object.containsAlcohol = message.containsAlcohol;
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.priceInCombo != null && message.hasOwnProperty("priceInCombo"))
                        object.priceInCombo = $root.waiternow.common.MoneyProto.toObject(message.priceInCombo, options);
                    if (message.station != null && message.hasOwnProperty("station"))
                        object.station = message.station;
                    if (message.quantity != null && message.hasOwnProperty("quantity"))
                        object.quantity = message.quantity;
                    if (message.specialInstructions != null && message.hasOwnProperty("specialInstructions"))
                        object.specialInstructions = message.specialInstructions;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this MenuItemProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuItemProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuItemProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuItemProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuOrderProto.MenuItemProto";
                };

                return MenuItemProto;
            })();

            StructuredMenuOrderProto.MenuItemSelectionProto = (function() {

                /**
                 * Properties of a MenuItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @interface IMenuItemSelectionProto
                 * @property {string|null} [id] MenuItemSelectionProto id
                 * @property {waiternow.common.ITextProto|null} [title] MenuItemSelectionProto title
                 * @property {Array.<waiternow.common.StructuredMenuOrderProto.IMenuItemProto>|null} [selectedMenuItems] MenuItemSelectionProto selectedMenuItems
                 */

                /**
                 * Constructs a new MenuItemSelectionProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @classdesc Represents a MenuItemSelectionProto.
                 * @implements IMenuItemSelectionProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemSelectionProto=} [properties] Properties to set
                 */
                function MenuItemSelectionProto(properties) {
                    this.selectedMenuItems = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuItemSelectionProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.id = "";

                /**
                 * MenuItemSelectionProto title.
                 * @member {waiternow.common.ITextProto|null|undefined} title
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.title = null;

                /**
                 * MenuItemSelectionProto selectedMenuItems.
                 * @member {Array.<waiternow.common.StructuredMenuOrderProto.IMenuItemProto>} selectedMenuItems
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @instance
                 */
                MenuItemSelectionProto.prototype.selectedMenuItems = $util.emptyArray;

                /**
                 * Creates a new MenuItemSelectionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemSelectionProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto} MenuItemSelectionProto instance
                 */
                MenuItemSelectionProto.create = function create(properties) {
                    return new MenuItemSelectionProto(properties);
                };

                /**
                 * Encodes the specified MenuItemSelectionProto message. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemSelectionProto} message MenuItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemSelectionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        $root.waiternow.common.TextProto.encode(message.title, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.selectedMenuItems != null && message.selectedMenuItems.length)
                        for (var i = 0; i < message.selectedMenuItems.length; ++i)
                            $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.encode(message.selectedMenuItems[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified MenuItemSelectionProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuItemSelectionProto} message MenuItemSelectionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuItemSelectionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuItemSelectionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto} MenuItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemSelectionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 3: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.title = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.selectedMenuItems && message.selectedMenuItems.length))
                                    message.selectedMenuItems = [];
                                message.selectedMenuItems.push($root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuItemSelectionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto} MenuItemSelectionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuItemSelectionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuItemSelectionProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuItemSelectionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.title != null && message.hasOwnProperty("title")) {
                        var error = $root.waiternow.common.TextProto.verify(message.title);
                        if (error)
                            return "title." + error;
                    }
                    if (message.selectedMenuItems != null && message.hasOwnProperty("selectedMenuItems")) {
                        if (!Array.isArray(message.selectedMenuItems))
                            return "selectedMenuItems: array expected";
                        for (var i = 0; i < message.selectedMenuItems.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.verify(message.selectedMenuItems[i]);
                            if (error)
                                return "selectedMenuItems." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MenuItemSelectionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto} MenuItemSelectionProto
                 */
                MenuItemSelectionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.title != null) {
                        if (typeof object.title !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.title: object expected");
                        message.title = $root.waiternow.common.TextProto.fromObject(object.title);
                    }
                    if (object.selectedMenuItems) {
                        if (!Array.isArray(object.selectedMenuItems))
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.selectedMenuItems: array expected");
                        message.selectedMenuItems = [];
                        for (var i = 0; i < object.selectedMenuItems.length; ++i) {
                            if (typeof object.selectedMenuItems[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.selectedMenuItems: object expected");
                            message.selectedMenuItems[i] = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.fromObject(object.selectedMenuItems[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MenuItemSelectionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto} message MenuItemSelectionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuItemSelectionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.selectedMenuItems = [];
                    if (options.defaults) {
                        object.title = null;
                        object.id = "";
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = $root.waiternow.common.TextProto.toObject(message.title, options);
                    if (message.selectedMenuItems && message.selectedMenuItems.length) {
                        object.selectedMenuItems = [];
                        for (var j = 0; j < message.selectedMenuItems.length; ++j)
                            object.selectedMenuItems[j] = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.toObject(message.selectedMenuItems[j], options);
                    }
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this MenuItemSelectionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuItemSelectionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuItemSelectionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuItemSelectionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto";
                };

                return MenuItemSelectionProto;
            })();

            StructuredMenuOrderProto.ComboProto = (function() {

                /**
                 * Properties of a ComboProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @interface IComboProto
                 * @property {string|null} [id] ComboProto id
                 * @property {waiternow.common.ITextProto|null} [name] ComboProto name
                 * @property {Array.<waiternow.common.StructuredMenuOrderProto.IMenuItemProto>|null} [menuItems] ComboProto menuItems
                 * @property {Array.<waiternow.common.StructuredMenuOrderProto.IMenuItemSelectionProto>|null} [menuItemSelections] ComboProto menuItemSelections
                 * @property {Array.<waiternow.common.StructuredMenuOrderProto.IItemSelectionProto>|null} [itemSelections] ComboProto itemSelections
                 * @property {waiternow.common.IMoneyProto|null} [price] ComboProto price
                 * @property {number|null} [quantity] ComboProto quantity
                 * @property {string|null} [specialInstructions] ComboProto specialInstructions
                 */

                /**
                 * Constructs a new ComboProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @classdesc Represents a ComboProto.
                 * @implements IComboProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuOrderProto.IComboProto=} [properties] Properties to set
                 */
                function ComboProto(properties) {
                    this.menuItems = [];
                    this.menuItemSelections = [];
                    this.itemSelections = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ComboProto id.
                 * @member {string} id
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.id = "";

                /**
                 * ComboProto name.
                 * @member {waiternow.common.ITextProto|null|undefined} name
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.name = null;

                /**
                 * ComboProto menuItems.
                 * @member {Array.<waiternow.common.StructuredMenuOrderProto.IMenuItemProto>} menuItems
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.menuItems = $util.emptyArray;

                /**
                 * ComboProto menuItemSelections.
                 * @member {Array.<waiternow.common.StructuredMenuOrderProto.IMenuItemSelectionProto>} menuItemSelections
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.menuItemSelections = $util.emptyArray;

                /**
                 * ComboProto itemSelections.
                 * @member {Array.<waiternow.common.StructuredMenuOrderProto.IItemSelectionProto>} itemSelections
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.itemSelections = $util.emptyArray;

                /**
                 * ComboProto price.
                 * @member {waiternow.common.IMoneyProto|null|undefined} price
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.price = null;

                /**
                 * ComboProto quantity.
                 * @member {number} quantity
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.quantity = 0;

                /**
                 * ComboProto specialInstructions.
                 * @member {string} specialInstructions
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 */
                ComboProto.prototype.specialInstructions = "";

                /**
                 * Creates a new ComboProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IComboProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuOrderProto.ComboProto} ComboProto instance
                 */
                ComboProto.create = function create(properties) {
                    return new ComboProto(properties);
                };

                /**
                 * Encodes the specified ComboProto message. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.ComboProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IComboProto} message ComboProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComboProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menuItems != null && message.menuItems.length)
                        for (var i = 0; i < message.menuItems.length; ++i)
                            $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.encode(message.menuItems[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.itemSelections != null && message.itemSelections.length)
                        for (var i = 0; i < message.itemSelections.length; ++i)
                            $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.encode(message.itemSelections[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.menuItemSelections != null && message.menuItemSelections.length)
                        for (var i = 0; i < message.menuItemSelections.length; ++i)
                            $root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.encode(message.menuItemSelections[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.price != null && Object.hasOwnProperty.call(message, "price"))
                        $root.waiternow.common.MoneyProto.encode(message.price, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.quantity != null && Object.hasOwnProperty.call(message, "quantity"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.quantity);
                    if (message.specialInstructions != null && Object.hasOwnProperty.call(message, "specialInstructions"))
                        writer.uint32(/* id 7, wireType 2 =*/58).string(message.specialInstructions);
                    if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.id);
                    return writer;
                };

                /**
                 * Encodes the specified ComboProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.ComboProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IComboProto} message ComboProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ComboProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ComboProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuOrderProto.ComboProto} ComboProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComboProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuOrderProto.ComboProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 8: {
                                message.id = reader.string();
                                break;
                            }
                        case 1: {
                                message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.menuItems && message.menuItems.length))
                                    message.menuItems = [];
                                message.menuItems.push($root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 4: {
                                if (!(message.menuItemSelections && message.menuItemSelections.length))
                                    message.menuItemSelections = [];
                                message.menuItemSelections.push($root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 3: {
                                if (!(message.itemSelections && message.itemSelections.length))
                                    message.itemSelections = [];
                                message.itemSelections.push($root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.decode(reader, reader.uint32()));
                                break;
                            }
                        case 5: {
                                message.price = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.quantity = reader.int32();
                                break;
                            }
                        case 7: {
                                message.specialInstructions = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ComboProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuOrderProto.ComboProto} ComboProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ComboProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ComboProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ComboProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.id != null && message.hasOwnProperty("id"))
                        if (!$util.isString(message.id))
                            return "id: string expected";
                    if (message.name != null && message.hasOwnProperty("name")) {
                        var error = $root.waiternow.common.TextProto.verify(message.name);
                        if (error)
                            return "name." + error;
                    }
                    if (message.menuItems != null && message.hasOwnProperty("menuItems")) {
                        if (!Array.isArray(message.menuItems))
                            return "menuItems: array expected";
                        for (var i = 0; i < message.menuItems.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.verify(message.menuItems[i]);
                            if (error)
                                return "menuItems." + error;
                        }
                    }
                    if (message.menuItemSelections != null && message.hasOwnProperty("menuItemSelections")) {
                        if (!Array.isArray(message.menuItemSelections))
                            return "menuItemSelections: array expected";
                        for (var i = 0; i < message.menuItemSelections.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.verify(message.menuItemSelections[i]);
                            if (error)
                                return "menuItemSelections." + error;
                        }
                    }
                    if (message.itemSelections != null && message.hasOwnProperty("itemSelections")) {
                        if (!Array.isArray(message.itemSelections))
                            return "itemSelections: array expected";
                        for (var i = 0; i < message.itemSelections.length; ++i) {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.verify(message.itemSelections[i]);
                            if (error)
                                return "itemSelections." + error;
                        }
                    }
                    if (message.price != null && message.hasOwnProperty("price")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.price);
                        if (error)
                            return "price." + error;
                    }
                    if (message.quantity != null && message.hasOwnProperty("quantity"))
                        if (!$util.isInteger(message.quantity))
                            return "quantity: integer expected";
                    if (message.specialInstructions != null && message.hasOwnProperty("specialInstructions"))
                        if (!$util.isString(message.specialInstructions))
                            return "specialInstructions: string expected";
                    return null;
                };

                /**
                 * Creates a ComboProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuOrderProto.ComboProto} ComboProto
                 */
                ComboProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuOrderProto.ComboProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuOrderProto.ComboProto();
                    if (object.id != null)
                        message.id = String(object.id);
                    if (object.name != null) {
                        if (typeof object.name !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.name: object expected");
                        message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                    }
                    if (object.menuItems) {
                        if (!Array.isArray(object.menuItems))
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.menuItems: array expected");
                        message.menuItems = [];
                        for (var i = 0; i < object.menuItems.length; ++i) {
                            if (typeof object.menuItems[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.menuItems: object expected");
                            message.menuItems[i] = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.fromObject(object.menuItems[i]);
                        }
                    }
                    if (object.menuItemSelections) {
                        if (!Array.isArray(object.menuItemSelections))
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.menuItemSelections: array expected");
                        message.menuItemSelections = [];
                        for (var i = 0; i < object.menuItemSelections.length; ++i) {
                            if (typeof object.menuItemSelections[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.menuItemSelections: object expected");
                            message.menuItemSelections[i] = $root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.fromObject(object.menuItemSelections[i]);
                        }
                    }
                    if (object.itemSelections) {
                        if (!Array.isArray(object.itemSelections))
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.itemSelections: array expected");
                        message.itemSelections = [];
                        for (var i = 0; i < object.itemSelections.length; ++i) {
                            if (typeof object.itemSelections[i] !== "object")
                                throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.itemSelections: object expected");
                            message.itemSelections[i] = $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.fromObject(object.itemSelections[i]);
                        }
                    }
                    if (object.price != null) {
                        if (typeof object.price !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.ComboProto.price: object expected");
                        message.price = $root.waiternow.common.MoneyProto.fromObject(object.price);
                    }
                    if (object.quantity != null)
                        message.quantity = object.quantity | 0;
                    if (object.specialInstructions != null)
                        message.specialInstructions = String(object.specialInstructions);
                    return message;
                };

                /**
                 * Creates a plain object from a ComboProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.ComboProto} message ComboProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ComboProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.menuItems = [];
                        object.itemSelections = [];
                        object.menuItemSelections = [];
                    }
                    if (options.defaults) {
                        object.name = null;
                        object.price = null;
                        object.quantity = 0;
                        object.specialInstructions = "";
                        object.id = "";
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                    if (message.menuItems && message.menuItems.length) {
                        object.menuItems = [];
                        for (var j = 0; j < message.menuItems.length; ++j)
                            object.menuItems[j] = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.toObject(message.menuItems[j], options);
                    }
                    if (message.itemSelections && message.itemSelections.length) {
                        object.itemSelections = [];
                        for (var j = 0; j < message.itemSelections.length; ++j)
                            object.itemSelections[j] = $root.waiternow.common.StructuredMenuOrderProto.ItemSelectionProto.toObject(message.itemSelections[j], options);
                    }
                    if (message.menuItemSelections && message.menuItemSelections.length) {
                        object.menuItemSelections = [];
                        for (var j = 0; j < message.menuItemSelections.length; ++j)
                            object.menuItemSelections[j] = $root.waiternow.common.StructuredMenuOrderProto.MenuItemSelectionProto.toObject(message.menuItemSelections[j], options);
                    }
                    if (message.price != null && message.hasOwnProperty("price"))
                        object.price = $root.waiternow.common.MoneyProto.toObject(message.price, options);
                    if (message.quantity != null && message.hasOwnProperty("quantity"))
                        object.quantity = message.quantity;
                    if (message.specialInstructions != null && message.hasOwnProperty("specialInstructions"))
                        object.specialInstructions = message.specialInstructions;
                    if (message.id != null && message.hasOwnProperty("id"))
                        object.id = message.id;
                    return object;
                };

                /**
                 * Converts this ComboProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ComboProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ComboProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuOrderProto.ComboProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ComboProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuOrderProto.ComboProto";
                };

                return ComboProto;
            })();

            StructuredMenuOrderProto.MenuEntryProto = (function() {

                /**
                 * Properties of a MenuEntryProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @interface IMenuEntryProto
                 * @property {waiternow.common.StructuredMenuOrderProto.IMenuItemProto|null} [menuItem] MenuEntryProto menuItem
                 * @property {waiternow.common.StructuredMenuOrderProto.IComboProto|null} [combo] MenuEntryProto combo
                 */

                /**
                 * Constructs a new MenuEntryProto.
                 * @memberof waiternow.common.StructuredMenuOrderProto
                 * @classdesc Represents a MenuEntryProto.
                 * @implements IMenuEntryProto
                 * @constructor
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuEntryProto=} [properties] Properties to set
                 */
                function MenuEntryProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuEntryProto menuItem.
                 * @member {waiternow.common.StructuredMenuOrderProto.IMenuItemProto|null|undefined} menuItem
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @instance
                 */
                MenuEntryProto.prototype.menuItem = null;

                /**
                 * MenuEntryProto combo.
                 * @member {waiternow.common.StructuredMenuOrderProto.IComboProto|null|undefined} combo
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @instance
                 */
                MenuEntryProto.prototype.combo = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * MenuEntryProto entryType.
                 * @member {"menuItem"|"combo"|undefined} entryType
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @instance
                 */
                Object.defineProperty(MenuEntryProto.prototype, "entryType", {
                    get: $util.oneOfGetter($oneOfFields = ["menuItem", "combo"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new MenuEntryProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuEntryProto=} [properties] Properties to set
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuEntryProto} MenuEntryProto instance
                 */
                MenuEntryProto.create = function create(properties) {
                    return new MenuEntryProto(properties);
                };

                /**
                 * Encodes the specified MenuEntryProto message. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.MenuEntryProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuEntryProto} message MenuEntryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuEntryProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.menuItem != null && Object.hasOwnProperty.call(message, "menuItem"))
                        $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.encode(message.menuItem, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.combo != null && Object.hasOwnProperty.call(message, "combo"))
                        $root.waiternow.common.StructuredMenuOrderProto.ComboProto.encode(message.combo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MenuEntryProto message, length delimited. Does not implicitly {@link waiternow.common.StructuredMenuOrderProto.MenuEntryProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.IMenuEntryProto} message MenuEntryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuEntryProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuEntryProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuEntryProto} MenuEntryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuEntryProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.menuItem = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.combo = $root.waiternow.common.StructuredMenuOrderProto.ComboProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuEntryProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuEntryProto} MenuEntryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuEntryProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuEntryProto message.
                 * @function verify
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuEntryProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.menuItem != null && message.hasOwnProperty("menuItem")) {
                        properties.entryType = 1;
                        {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.verify(message.menuItem);
                            if (error)
                                return "menuItem." + error;
                        }
                    }
                    if (message.combo != null && message.hasOwnProperty("combo")) {
                        if (properties.entryType === 1)
                            return "entryType: multiple values";
                        properties.entryType = 1;
                        {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.ComboProto.verify(message.combo);
                            if (error)
                                return "combo." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a MenuEntryProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StructuredMenuOrderProto.MenuEntryProto} MenuEntryProto
                 */
                MenuEntryProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto)
                        return object;
                    var message = new $root.waiternow.common.StructuredMenuOrderProto.MenuEntryProto();
                    if (object.menuItem != null) {
                        if (typeof object.menuItem !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuEntryProto.menuItem: object expected");
                        message.menuItem = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.fromObject(object.menuItem);
                    }
                    if (object.combo != null) {
                        if (typeof object.combo !== "object")
                            throw TypeError(".waiternow.common.StructuredMenuOrderProto.MenuEntryProto.combo: object expected");
                        message.combo = $root.waiternow.common.StructuredMenuOrderProto.ComboProto.fromObject(object.combo);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MenuEntryProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {waiternow.common.StructuredMenuOrderProto.MenuEntryProto} message MenuEntryProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuEntryProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.menuItem != null && message.hasOwnProperty("menuItem")) {
                        object.menuItem = $root.waiternow.common.StructuredMenuOrderProto.MenuItemProto.toObject(message.menuItem, options);
                        if (options.oneofs)
                            object.entryType = "menuItem";
                    }
                    if (message.combo != null && message.hasOwnProperty("combo")) {
                        object.combo = $root.waiternow.common.StructuredMenuOrderProto.ComboProto.toObject(message.combo, options);
                        if (options.oneofs)
                            object.entryType = "combo";
                    }
                    return object;
                };

                /**
                 * Converts this MenuEntryProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuEntryProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuEntryProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.StructuredMenuOrderProto.MenuEntryProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuEntryProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StructuredMenuOrderProto.MenuEntryProto";
                };

                return MenuEntryProto;
            })();

            return StructuredMenuOrderProto;
        })();

        common.MenuEntryAvailabilityProto = (function() {

            /**
             * Properties of a MenuEntryAvailabilityProto.
             * @memberof waiternow.common
             * @interface IMenuEntryAvailabilityProto
             * @property {string|null} [id] MenuEntryAvailabilityProto id
             * @property {waiternow.common.ITextProto|null} [name] MenuEntryAvailabilityProto name
             * @property {waiternow.common.MenuEntryAvailabilityProto.MenuEntryType|null} [menuEntryType] MenuEntryAvailabilityProto menuEntryType
             * @property {boolean|null} [isUnavailable] MenuEntryAvailabilityProto isUnavailable
             */

            /**
             * Constructs a new MenuEntryAvailabilityProto.
             * @memberof waiternow.common
             * @classdesc Represents a MenuEntryAvailabilityProto.
             * @implements IMenuEntryAvailabilityProto
             * @constructor
             * @param {waiternow.common.IMenuEntryAvailabilityProto=} [properties] Properties to set
             */
            function MenuEntryAvailabilityProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MenuEntryAvailabilityProto id.
             * @member {string} id
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @instance
             */
            MenuEntryAvailabilityProto.prototype.id = "";

            /**
             * MenuEntryAvailabilityProto name.
             * @member {waiternow.common.ITextProto|null|undefined} name
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @instance
             */
            MenuEntryAvailabilityProto.prototype.name = null;

            /**
             * MenuEntryAvailabilityProto menuEntryType.
             * @member {waiternow.common.MenuEntryAvailabilityProto.MenuEntryType} menuEntryType
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @instance
             */
            MenuEntryAvailabilityProto.prototype.menuEntryType = 0;

            /**
             * MenuEntryAvailabilityProto isUnavailable.
             * @member {boolean} isUnavailable
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @instance
             */
            MenuEntryAvailabilityProto.prototype.isUnavailable = false;

            /**
             * Creates a new MenuEntryAvailabilityProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {waiternow.common.IMenuEntryAvailabilityProto=} [properties] Properties to set
             * @returns {waiternow.common.MenuEntryAvailabilityProto} MenuEntryAvailabilityProto instance
             */
            MenuEntryAvailabilityProto.create = function create(properties) {
                return new MenuEntryAvailabilityProto(properties);
            };

            /**
             * Encodes the specified MenuEntryAvailabilityProto message. Does not implicitly {@link waiternow.common.MenuEntryAvailabilityProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {waiternow.common.IMenuEntryAvailabilityProto} message MenuEntryAvailabilityProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MenuEntryAvailabilityProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    $root.waiternow.common.TextProto.encode(message.name, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.menuEntryType != null && Object.hasOwnProperty.call(message, "menuEntryType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.menuEntryType);
                if (message.isUnavailable != null && Object.hasOwnProperty.call(message, "isUnavailable"))
                    writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isUnavailable);
                return writer;
            };

            /**
             * Encodes the specified MenuEntryAvailabilityProto message, length delimited. Does not implicitly {@link waiternow.common.MenuEntryAvailabilityProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {waiternow.common.IMenuEntryAvailabilityProto} message MenuEntryAvailabilityProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MenuEntryAvailabilityProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MenuEntryAvailabilityProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MenuEntryAvailabilityProto} MenuEntryAvailabilityProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MenuEntryAvailabilityProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MenuEntryAvailabilityProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.name = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.menuEntryType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.isUnavailable = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MenuEntryAvailabilityProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MenuEntryAvailabilityProto} MenuEntryAvailabilityProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MenuEntryAvailabilityProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MenuEntryAvailabilityProto message.
             * @function verify
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MenuEntryAvailabilityProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.name != null && message.hasOwnProperty("name")) {
                    var error = $root.waiternow.common.TextProto.verify(message.name);
                    if (error)
                        return "name." + error;
                }
                if (message.menuEntryType != null && message.hasOwnProperty("menuEntryType"))
                    switch (message.menuEntryType) {
                    default:
                        return "menuEntryType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                    if (typeof message.isUnavailable !== "boolean")
                        return "isUnavailable: boolean expected";
                return null;
            };

            /**
             * Creates a MenuEntryAvailabilityProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MenuEntryAvailabilityProto} MenuEntryAvailabilityProto
             */
            MenuEntryAvailabilityProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MenuEntryAvailabilityProto)
                    return object;
                var message = new $root.waiternow.common.MenuEntryAvailabilityProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.name != null) {
                    if (typeof object.name !== "object")
                        throw TypeError(".waiternow.common.MenuEntryAvailabilityProto.name: object expected");
                    message.name = $root.waiternow.common.TextProto.fromObject(object.name);
                }
                switch (object.menuEntryType) {
                default:
                    if (typeof object.menuEntryType === "number") {
                        message.menuEntryType = object.menuEntryType;
                        break;
                    }
                    break;
                case "MENU_ENTRY_TYPE_UNSPECIFIED":
                case 0:
                    message.menuEntryType = 0;
                    break;
                case "MENU_ITEM":
                case 1:
                    message.menuEntryType = 1;
                    break;
                case "COMBO":
                case 2:
                    message.menuEntryType = 2;
                    break;
                }
                if (object.isUnavailable != null)
                    message.isUnavailable = Boolean(object.isUnavailable);
                return message;
            };

            /**
             * Creates a plain object from a MenuEntryAvailabilityProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {waiternow.common.MenuEntryAvailabilityProto} message MenuEntryAvailabilityProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MenuEntryAvailabilityProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.name = null;
                    object.menuEntryType = options.enums === String ? "MENU_ENTRY_TYPE_UNSPECIFIED" : 0;
                    object.isUnavailable = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = $root.waiternow.common.TextProto.toObject(message.name, options);
                if (message.menuEntryType != null && message.hasOwnProperty("menuEntryType"))
                    object.menuEntryType = options.enums === String ? $root.waiternow.common.MenuEntryAvailabilityProto.MenuEntryType[message.menuEntryType] === undefined ? message.menuEntryType : $root.waiternow.common.MenuEntryAvailabilityProto.MenuEntryType[message.menuEntryType] : message.menuEntryType;
                if (message.isUnavailable != null && message.hasOwnProperty("isUnavailable"))
                    object.isUnavailable = message.isUnavailable;
                return object;
            };

            /**
             * Converts this MenuEntryAvailabilityProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MenuEntryAvailabilityProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MenuEntryAvailabilityProto
             * @function getTypeUrl
             * @memberof waiternow.common.MenuEntryAvailabilityProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MenuEntryAvailabilityProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MenuEntryAvailabilityProto";
            };

            /**
             * MenuEntryType enum.
             * @name waiternow.common.MenuEntryAvailabilityProto.MenuEntryType
             * @enum {number}
             * @property {number} MENU_ENTRY_TYPE_UNSPECIFIED=0 MENU_ENTRY_TYPE_UNSPECIFIED value
             * @property {number} MENU_ITEM=1 MENU_ITEM value
             * @property {number} COMBO=2 COMBO value
             */
            MenuEntryAvailabilityProto.MenuEntryType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "MENU_ENTRY_TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "MENU_ITEM"] = 1;
                values[valuesById[2] = "COMBO"] = 2;
                return values;
            })();

            return MenuEntryAvailabilityProto;
        })();

        common.DailyRecurrenceProto = (function() {

            /**
             * Properties of a DailyRecurrenceProto.
             * @memberof waiternow.common
             * @interface IDailyRecurrenceProto
             * @property {waiternow.common.DailyRecurrenceProto.RecurrenceType|null} [recurrenceType] DailyRecurrenceProto recurrenceType
             * @property {waiternow.common.ITimeRangeProto|null} [timeRange] DailyRecurrenceProto timeRange
             */

            /**
             * Constructs a new DailyRecurrenceProto.
             * @memberof waiternow.common
             * @classdesc Represents a DailyRecurrenceProto.
             * @implements IDailyRecurrenceProto
             * @constructor
             * @param {waiternow.common.IDailyRecurrenceProto=} [properties] Properties to set
             */
            function DailyRecurrenceProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DailyRecurrenceProto recurrenceType.
             * @member {waiternow.common.DailyRecurrenceProto.RecurrenceType} recurrenceType
             * @memberof waiternow.common.DailyRecurrenceProto
             * @instance
             */
            DailyRecurrenceProto.prototype.recurrenceType = 0;

            /**
             * DailyRecurrenceProto timeRange.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} timeRange
             * @memberof waiternow.common.DailyRecurrenceProto
             * @instance
             */
            DailyRecurrenceProto.prototype.timeRange = null;

            /**
             * Creates a new DailyRecurrenceProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {waiternow.common.IDailyRecurrenceProto=} [properties] Properties to set
             * @returns {waiternow.common.DailyRecurrenceProto} DailyRecurrenceProto instance
             */
            DailyRecurrenceProto.create = function create(properties) {
                return new DailyRecurrenceProto(properties);
            };

            /**
             * Encodes the specified DailyRecurrenceProto message. Does not implicitly {@link waiternow.common.DailyRecurrenceProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {waiternow.common.IDailyRecurrenceProto} message DailyRecurrenceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DailyRecurrenceProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.recurrenceType != null && Object.hasOwnProperty.call(message, "recurrenceType"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.recurrenceType);
                if (message.timeRange != null && Object.hasOwnProperty.call(message, "timeRange"))
                    $root.waiternow.common.TimeRangeProto.encode(message.timeRange, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DailyRecurrenceProto message, length delimited. Does not implicitly {@link waiternow.common.DailyRecurrenceProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {waiternow.common.IDailyRecurrenceProto} message DailyRecurrenceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DailyRecurrenceProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DailyRecurrenceProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DailyRecurrenceProto} DailyRecurrenceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DailyRecurrenceProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DailyRecurrenceProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.recurrenceType = reader.int32();
                            break;
                        }
                    case 2: {
                            message.timeRange = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DailyRecurrenceProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DailyRecurrenceProto} DailyRecurrenceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DailyRecurrenceProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DailyRecurrenceProto message.
             * @function verify
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DailyRecurrenceProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.recurrenceType != null && message.hasOwnProperty("recurrenceType"))
                    switch (message.recurrenceType) {
                    default:
                        return "recurrenceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                    case 13:
                    case 14:
                    case 15:
                    case 16:
                    case 17:
                    case 18:
                    case 19:
                    case 20:
                    case 21:
                    case 22:
                    case 23:
                    case 24:
                    case 25:
                    case 26:
                    case 27:
                    case 28:
                    case 29:
                    case 30:
                    case 31:
                    case 32:
                    case 33:
                    case 34:
                    case 35:
                    case 36:
                    case 37:
                    case 38:
                        break;
                    }
                if (message.timeRange != null && message.hasOwnProperty("timeRange")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.timeRange);
                    if (error)
                        return "timeRange." + error;
                }
                return null;
            };

            /**
             * Creates a DailyRecurrenceProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DailyRecurrenceProto} DailyRecurrenceProto
             */
            DailyRecurrenceProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DailyRecurrenceProto)
                    return object;
                var message = new $root.waiternow.common.DailyRecurrenceProto();
                switch (object.recurrenceType) {
                default:
                    if (typeof object.recurrenceType === "number") {
                        message.recurrenceType = object.recurrenceType;
                        break;
                    }
                    break;
                case "RECURRENCE_TYPE_UNSPECIFIED":
                case 0:
                    message.recurrenceType = 0;
                    break;
                case "DAILY":
                case 1:
                    message.recurrenceType = 1;
                    break;
                case "WEEKDAYS":
                case 2:
                    message.recurrenceType = 2;
                    break;
                case "WEEKENDS":
                case 3:
                    message.recurrenceType = 3;
                    break;
                case "WEEKLY_ON_SUNDAY":
                case 4:
                    message.recurrenceType = 4;
                    break;
                case "WEEKLY_ON_MONDAY":
                case 5:
                    message.recurrenceType = 5;
                    break;
                case "WEEKLY_ON_TUESDAY":
                case 6:
                    message.recurrenceType = 6;
                    break;
                case "WEEKLY_ON_WEDNESDAY":
                case 7:
                    message.recurrenceType = 7;
                    break;
                case "WEEKLY_ON_THURSDAY":
                case 8:
                    message.recurrenceType = 8;
                    break;
                case "WEEKLY_ON_FRIDAY":
                case 9:
                    message.recurrenceType = 9;
                    break;
                case "WEEKLY_ON_SATURDAY":
                case 10:
                    message.recurrenceType = 10;
                    break;
                case "MONTHLY_ON_FIRST_SUNDAY":
                case 11:
                    message.recurrenceType = 11;
                    break;
                case "MONTHLY_ON_FIRST_MONDAY":
                case 12:
                    message.recurrenceType = 12;
                    break;
                case "MONTHLY_ON_FIRST_TUESDAY":
                case 13:
                    message.recurrenceType = 13;
                    break;
                case "MONTHLY_ON_FIRST_WEDNESDAY":
                case 14:
                    message.recurrenceType = 14;
                    break;
                case "MONTHLY_ON_FIRST_THURSDAY":
                case 15:
                    message.recurrenceType = 15;
                    break;
                case "MONTHLY_ON_FIRST_FRIDAY":
                case 16:
                    message.recurrenceType = 16;
                    break;
                case "MONTHLY_ON_FIRST_SATURDAY":
                case 17:
                    message.recurrenceType = 17;
                    break;
                case "MONTHLY_ON_SECOND_SUNDAY":
                case 18:
                    message.recurrenceType = 18;
                    break;
                case "MONTHLY_ON_SECOND_MONDAY":
                case 19:
                    message.recurrenceType = 19;
                    break;
                case "MONTHLY_ON_SECOND_TUESDAY":
                case 20:
                    message.recurrenceType = 20;
                    break;
                case "MONTHLY_ON_SECOND_WEDNESDAY":
                case 21:
                    message.recurrenceType = 21;
                    break;
                case "MONTHLY_ON_SECOND_THURSDAY":
                case 22:
                    message.recurrenceType = 22;
                    break;
                case "MONTHLY_ON_SECOND_FRIDAY":
                case 23:
                    message.recurrenceType = 23;
                    break;
                case "MONTHLY_ON_SECOND_SATURDAY":
                case 24:
                    message.recurrenceType = 24;
                    break;
                case "MONTHLY_ON_THIRD_SUNDAY":
                case 25:
                    message.recurrenceType = 25;
                    break;
                case "MONTHLY_ON_THIRD_MONDAY":
                case 26:
                    message.recurrenceType = 26;
                    break;
                case "MONTHLY_ON_THIRD_TUESDAY":
                case 27:
                    message.recurrenceType = 27;
                    break;
                case "MONTHLY_ON_THIRD_WEDNESDAY":
                case 28:
                    message.recurrenceType = 28;
                    break;
                case "MONTHLY_ON_THIRD_THURSDAY":
                case 29:
                    message.recurrenceType = 29;
                    break;
                case "MONTHLY_ON_THIRD_FRIDAY":
                case 30:
                    message.recurrenceType = 30;
                    break;
                case "MONTHLY_ON_THIRD_SATURDAY":
                case 31:
                    message.recurrenceType = 31;
                    break;
                case "MONTHLY_ON_LAST_SUNDAY":
                case 32:
                    message.recurrenceType = 32;
                    break;
                case "MONTHLY_ON_LAST_MONDAY":
                case 33:
                    message.recurrenceType = 33;
                    break;
                case "MONTHLY_ON_LAST_TUESDAY":
                case 34:
                    message.recurrenceType = 34;
                    break;
                case "MONTHLY_ON_LAST_WEDNESDAY":
                case 35:
                    message.recurrenceType = 35;
                    break;
                case "MONTHLY_ON_LAST_THURSDAY":
                case 36:
                    message.recurrenceType = 36;
                    break;
                case "MONTHLY_ON_LAST_FRIDAY":
                case 37:
                    message.recurrenceType = 37;
                    break;
                case "MONTHLY_ON_LAST_SATURDAY":
                case 38:
                    message.recurrenceType = 38;
                    break;
                }
                if (object.timeRange != null) {
                    if (typeof object.timeRange !== "object")
                        throw TypeError(".waiternow.common.DailyRecurrenceProto.timeRange: object expected");
                    message.timeRange = $root.waiternow.common.TimeRangeProto.fromObject(object.timeRange);
                }
                return message;
            };

            /**
             * Creates a plain object from a DailyRecurrenceProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {waiternow.common.DailyRecurrenceProto} message DailyRecurrenceProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DailyRecurrenceProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.recurrenceType = options.enums === String ? "RECURRENCE_TYPE_UNSPECIFIED" : 0;
                    object.timeRange = null;
                }
                if (message.recurrenceType != null && message.hasOwnProperty("recurrenceType"))
                    object.recurrenceType = options.enums === String ? $root.waiternow.common.DailyRecurrenceProto.RecurrenceType[message.recurrenceType] === undefined ? message.recurrenceType : $root.waiternow.common.DailyRecurrenceProto.RecurrenceType[message.recurrenceType] : message.recurrenceType;
                if (message.timeRange != null && message.hasOwnProperty("timeRange"))
                    object.timeRange = $root.waiternow.common.TimeRangeProto.toObject(message.timeRange, options);
                return object;
            };

            /**
             * Converts this DailyRecurrenceProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DailyRecurrenceProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DailyRecurrenceProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DailyRecurrenceProto
             * @function getTypeUrl
             * @memberof waiternow.common.DailyRecurrenceProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DailyRecurrenceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DailyRecurrenceProto";
            };

            /**
             * RecurrenceType enum.
             * @name waiternow.common.DailyRecurrenceProto.RecurrenceType
             * @enum {number}
             * @property {number} RECURRENCE_TYPE_UNSPECIFIED=0 RECURRENCE_TYPE_UNSPECIFIED value
             * @property {number} DAILY=1 DAILY value
             * @property {number} WEEKDAYS=2 WEEKDAYS value
             * @property {number} WEEKENDS=3 WEEKENDS value
             * @property {number} WEEKLY_ON_SUNDAY=4 WEEKLY_ON_SUNDAY value
             * @property {number} WEEKLY_ON_MONDAY=5 WEEKLY_ON_MONDAY value
             * @property {number} WEEKLY_ON_TUESDAY=6 WEEKLY_ON_TUESDAY value
             * @property {number} WEEKLY_ON_WEDNESDAY=7 WEEKLY_ON_WEDNESDAY value
             * @property {number} WEEKLY_ON_THURSDAY=8 WEEKLY_ON_THURSDAY value
             * @property {number} WEEKLY_ON_FRIDAY=9 WEEKLY_ON_FRIDAY value
             * @property {number} WEEKLY_ON_SATURDAY=10 WEEKLY_ON_SATURDAY value
             * @property {number} MONTHLY_ON_FIRST_SUNDAY=11 MONTHLY_ON_FIRST_SUNDAY value
             * @property {number} MONTHLY_ON_FIRST_MONDAY=12 MONTHLY_ON_FIRST_MONDAY value
             * @property {number} MONTHLY_ON_FIRST_TUESDAY=13 MONTHLY_ON_FIRST_TUESDAY value
             * @property {number} MONTHLY_ON_FIRST_WEDNESDAY=14 MONTHLY_ON_FIRST_WEDNESDAY value
             * @property {number} MONTHLY_ON_FIRST_THURSDAY=15 MONTHLY_ON_FIRST_THURSDAY value
             * @property {number} MONTHLY_ON_FIRST_FRIDAY=16 MONTHLY_ON_FIRST_FRIDAY value
             * @property {number} MONTHLY_ON_FIRST_SATURDAY=17 MONTHLY_ON_FIRST_SATURDAY value
             * @property {number} MONTHLY_ON_SECOND_SUNDAY=18 MONTHLY_ON_SECOND_SUNDAY value
             * @property {number} MONTHLY_ON_SECOND_MONDAY=19 MONTHLY_ON_SECOND_MONDAY value
             * @property {number} MONTHLY_ON_SECOND_TUESDAY=20 MONTHLY_ON_SECOND_TUESDAY value
             * @property {number} MONTHLY_ON_SECOND_WEDNESDAY=21 MONTHLY_ON_SECOND_WEDNESDAY value
             * @property {number} MONTHLY_ON_SECOND_THURSDAY=22 MONTHLY_ON_SECOND_THURSDAY value
             * @property {number} MONTHLY_ON_SECOND_FRIDAY=23 MONTHLY_ON_SECOND_FRIDAY value
             * @property {number} MONTHLY_ON_SECOND_SATURDAY=24 MONTHLY_ON_SECOND_SATURDAY value
             * @property {number} MONTHLY_ON_THIRD_SUNDAY=25 MONTHLY_ON_THIRD_SUNDAY value
             * @property {number} MONTHLY_ON_THIRD_MONDAY=26 MONTHLY_ON_THIRD_MONDAY value
             * @property {number} MONTHLY_ON_THIRD_TUESDAY=27 MONTHLY_ON_THIRD_TUESDAY value
             * @property {number} MONTHLY_ON_THIRD_WEDNESDAY=28 MONTHLY_ON_THIRD_WEDNESDAY value
             * @property {number} MONTHLY_ON_THIRD_THURSDAY=29 MONTHLY_ON_THIRD_THURSDAY value
             * @property {number} MONTHLY_ON_THIRD_FRIDAY=30 MONTHLY_ON_THIRD_FRIDAY value
             * @property {number} MONTHLY_ON_THIRD_SATURDAY=31 MONTHLY_ON_THIRD_SATURDAY value
             * @property {number} MONTHLY_ON_LAST_SUNDAY=32 MONTHLY_ON_LAST_SUNDAY value
             * @property {number} MONTHLY_ON_LAST_MONDAY=33 MONTHLY_ON_LAST_MONDAY value
             * @property {number} MONTHLY_ON_LAST_TUESDAY=34 MONTHLY_ON_LAST_TUESDAY value
             * @property {number} MONTHLY_ON_LAST_WEDNESDAY=35 MONTHLY_ON_LAST_WEDNESDAY value
             * @property {number} MONTHLY_ON_LAST_THURSDAY=36 MONTHLY_ON_LAST_THURSDAY value
             * @property {number} MONTHLY_ON_LAST_FRIDAY=37 MONTHLY_ON_LAST_FRIDAY value
             * @property {number} MONTHLY_ON_LAST_SATURDAY=38 MONTHLY_ON_LAST_SATURDAY value
             */
            DailyRecurrenceProto.RecurrenceType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "RECURRENCE_TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "DAILY"] = 1;
                values[valuesById[2] = "WEEKDAYS"] = 2;
                values[valuesById[3] = "WEEKENDS"] = 3;
                values[valuesById[4] = "WEEKLY_ON_SUNDAY"] = 4;
                values[valuesById[5] = "WEEKLY_ON_MONDAY"] = 5;
                values[valuesById[6] = "WEEKLY_ON_TUESDAY"] = 6;
                values[valuesById[7] = "WEEKLY_ON_WEDNESDAY"] = 7;
                values[valuesById[8] = "WEEKLY_ON_THURSDAY"] = 8;
                values[valuesById[9] = "WEEKLY_ON_FRIDAY"] = 9;
                values[valuesById[10] = "WEEKLY_ON_SATURDAY"] = 10;
                values[valuesById[11] = "MONTHLY_ON_FIRST_SUNDAY"] = 11;
                values[valuesById[12] = "MONTHLY_ON_FIRST_MONDAY"] = 12;
                values[valuesById[13] = "MONTHLY_ON_FIRST_TUESDAY"] = 13;
                values[valuesById[14] = "MONTHLY_ON_FIRST_WEDNESDAY"] = 14;
                values[valuesById[15] = "MONTHLY_ON_FIRST_THURSDAY"] = 15;
                values[valuesById[16] = "MONTHLY_ON_FIRST_FRIDAY"] = 16;
                values[valuesById[17] = "MONTHLY_ON_FIRST_SATURDAY"] = 17;
                values[valuesById[18] = "MONTHLY_ON_SECOND_SUNDAY"] = 18;
                values[valuesById[19] = "MONTHLY_ON_SECOND_MONDAY"] = 19;
                values[valuesById[20] = "MONTHLY_ON_SECOND_TUESDAY"] = 20;
                values[valuesById[21] = "MONTHLY_ON_SECOND_WEDNESDAY"] = 21;
                values[valuesById[22] = "MONTHLY_ON_SECOND_THURSDAY"] = 22;
                values[valuesById[23] = "MONTHLY_ON_SECOND_FRIDAY"] = 23;
                values[valuesById[24] = "MONTHLY_ON_SECOND_SATURDAY"] = 24;
                values[valuesById[25] = "MONTHLY_ON_THIRD_SUNDAY"] = 25;
                values[valuesById[26] = "MONTHLY_ON_THIRD_MONDAY"] = 26;
                values[valuesById[27] = "MONTHLY_ON_THIRD_TUESDAY"] = 27;
                values[valuesById[28] = "MONTHLY_ON_THIRD_WEDNESDAY"] = 28;
                values[valuesById[29] = "MONTHLY_ON_THIRD_THURSDAY"] = 29;
                values[valuesById[30] = "MONTHLY_ON_THIRD_FRIDAY"] = 30;
                values[valuesById[31] = "MONTHLY_ON_THIRD_SATURDAY"] = 31;
                values[valuesById[32] = "MONTHLY_ON_LAST_SUNDAY"] = 32;
                values[valuesById[33] = "MONTHLY_ON_LAST_MONDAY"] = 33;
                values[valuesById[34] = "MONTHLY_ON_LAST_TUESDAY"] = 34;
                values[valuesById[35] = "MONTHLY_ON_LAST_WEDNESDAY"] = 35;
                values[valuesById[36] = "MONTHLY_ON_LAST_THURSDAY"] = 36;
                values[valuesById[37] = "MONTHLY_ON_LAST_FRIDAY"] = 37;
                values[valuesById[38] = "MONTHLY_ON_LAST_SATURDAY"] = 38;
                return values;
            })();

            return DailyRecurrenceProto;
        })();

        common.MonthlyRecurrenceProto = (function() {

            /**
             * Properties of a MonthlyRecurrenceProto.
             * @memberof waiternow.common
             * @interface IMonthlyRecurrenceProto
             * @property {number|null} [montlyOnDay] MonthlyRecurrenceProto montlyOnDay
             * @property {waiternow.common.ITimeRangeProto|null} [timeRange] MonthlyRecurrenceProto timeRange
             */

            /**
             * Constructs a new MonthlyRecurrenceProto.
             * @memberof waiternow.common
             * @classdesc Represents a MonthlyRecurrenceProto.
             * @implements IMonthlyRecurrenceProto
             * @constructor
             * @param {waiternow.common.IMonthlyRecurrenceProto=} [properties] Properties to set
             */
            function MonthlyRecurrenceProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MonthlyRecurrenceProto montlyOnDay.
             * @member {number} montlyOnDay
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @instance
             */
            MonthlyRecurrenceProto.prototype.montlyOnDay = 0;

            /**
             * MonthlyRecurrenceProto timeRange.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} timeRange
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @instance
             */
            MonthlyRecurrenceProto.prototype.timeRange = null;

            /**
             * Creates a new MonthlyRecurrenceProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {waiternow.common.IMonthlyRecurrenceProto=} [properties] Properties to set
             * @returns {waiternow.common.MonthlyRecurrenceProto} MonthlyRecurrenceProto instance
             */
            MonthlyRecurrenceProto.create = function create(properties) {
                return new MonthlyRecurrenceProto(properties);
            };

            /**
             * Encodes the specified MonthlyRecurrenceProto message. Does not implicitly {@link waiternow.common.MonthlyRecurrenceProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {waiternow.common.IMonthlyRecurrenceProto} message MonthlyRecurrenceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MonthlyRecurrenceProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.montlyOnDay != null && Object.hasOwnProperty.call(message, "montlyOnDay"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.montlyOnDay);
                if (message.timeRange != null && Object.hasOwnProperty.call(message, "timeRange"))
                    $root.waiternow.common.TimeRangeProto.encode(message.timeRange, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MonthlyRecurrenceProto message, length delimited. Does not implicitly {@link waiternow.common.MonthlyRecurrenceProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {waiternow.common.IMonthlyRecurrenceProto} message MonthlyRecurrenceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MonthlyRecurrenceProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MonthlyRecurrenceProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MonthlyRecurrenceProto} MonthlyRecurrenceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MonthlyRecurrenceProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MonthlyRecurrenceProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.montlyOnDay = reader.int32();
                            break;
                        }
                    case 2: {
                            message.timeRange = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MonthlyRecurrenceProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MonthlyRecurrenceProto} MonthlyRecurrenceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MonthlyRecurrenceProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MonthlyRecurrenceProto message.
             * @function verify
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MonthlyRecurrenceProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.montlyOnDay != null && message.hasOwnProperty("montlyOnDay"))
                    if (!$util.isInteger(message.montlyOnDay))
                        return "montlyOnDay: integer expected";
                if (message.timeRange != null && message.hasOwnProperty("timeRange")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.timeRange);
                    if (error)
                        return "timeRange." + error;
                }
                return null;
            };

            /**
             * Creates a MonthlyRecurrenceProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MonthlyRecurrenceProto} MonthlyRecurrenceProto
             */
            MonthlyRecurrenceProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MonthlyRecurrenceProto)
                    return object;
                var message = new $root.waiternow.common.MonthlyRecurrenceProto();
                if (object.montlyOnDay != null)
                    message.montlyOnDay = object.montlyOnDay | 0;
                if (object.timeRange != null) {
                    if (typeof object.timeRange !== "object")
                        throw TypeError(".waiternow.common.MonthlyRecurrenceProto.timeRange: object expected");
                    message.timeRange = $root.waiternow.common.TimeRangeProto.fromObject(object.timeRange);
                }
                return message;
            };

            /**
             * Creates a plain object from a MonthlyRecurrenceProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {waiternow.common.MonthlyRecurrenceProto} message MonthlyRecurrenceProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MonthlyRecurrenceProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.montlyOnDay = 0;
                    object.timeRange = null;
                }
                if (message.montlyOnDay != null && message.hasOwnProperty("montlyOnDay"))
                    object.montlyOnDay = message.montlyOnDay;
                if (message.timeRange != null && message.hasOwnProperty("timeRange"))
                    object.timeRange = $root.waiternow.common.TimeRangeProto.toObject(message.timeRange, options);
                return object;
            };

            /**
             * Converts this MonthlyRecurrenceProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MonthlyRecurrenceProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MonthlyRecurrenceProto
             * @function getTypeUrl
             * @memberof waiternow.common.MonthlyRecurrenceProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MonthlyRecurrenceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MonthlyRecurrenceProto";
            };

            return MonthlyRecurrenceProto;
        })();

        common.YearlyRecurrenceProto = (function() {

            /**
             * Properties of a YearlyRecurrenceProto.
             * @memberof waiternow.common
             * @interface IYearlyRecurrenceProto
             * @property {waiternow.common.Month|null} [month] YearlyRecurrenceProto month
             * @property {number|null} [day] YearlyRecurrenceProto day
             * @property {waiternow.common.ITimeRangeProto|null} [timeRange] YearlyRecurrenceProto timeRange
             */

            /**
             * Constructs a new YearlyRecurrenceProto.
             * @memberof waiternow.common
             * @classdesc Represents a YearlyRecurrenceProto.
             * @implements IYearlyRecurrenceProto
             * @constructor
             * @param {waiternow.common.IYearlyRecurrenceProto=} [properties] Properties to set
             */
            function YearlyRecurrenceProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * YearlyRecurrenceProto month.
             * @member {waiternow.common.Month} month
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @instance
             */
            YearlyRecurrenceProto.prototype.month = 0;

            /**
             * YearlyRecurrenceProto day.
             * @member {number} day
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @instance
             */
            YearlyRecurrenceProto.prototype.day = 0;

            /**
             * YearlyRecurrenceProto timeRange.
             * @member {waiternow.common.ITimeRangeProto|null|undefined} timeRange
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @instance
             */
            YearlyRecurrenceProto.prototype.timeRange = null;

            /**
             * Creates a new YearlyRecurrenceProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {waiternow.common.IYearlyRecurrenceProto=} [properties] Properties to set
             * @returns {waiternow.common.YearlyRecurrenceProto} YearlyRecurrenceProto instance
             */
            YearlyRecurrenceProto.create = function create(properties) {
                return new YearlyRecurrenceProto(properties);
            };

            /**
             * Encodes the specified YearlyRecurrenceProto message. Does not implicitly {@link waiternow.common.YearlyRecurrenceProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {waiternow.common.IYearlyRecurrenceProto} message YearlyRecurrenceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            YearlyRecurrenceProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.month != null && Object.hasOwnProperty.call(message, "month"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.month);
                if (message.day != null && Object.hasOwnProperty.call(message, "day"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.day);
                if (message.timeRange != null && Object.hasOwnProperty.call(message, "timeRange"))
                    $root.waiternow.common.TimeRangeProto.encode(message.timeRange, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified YearlyRecurrenceProto message, length delimited. Does not implicitly {@link waiternow.common.YearlyRecurrenceProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {waiternow.common.IYearlyRecurrenceProto} message YearlyRecurrenceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            YearlyRecurrenceProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a YearlyRecurrenceProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.YearlyRecurrenceProto} YearlyRecurrenceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            YearlyRecurrenceProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.YearlyRecurrenceProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.month = reader.int32();
                            break;
                        }
                    case 2: {
                            message.day = reader.int32();
                            break;
                        }
                    case 3: {
                            message.timeRange = $root.waiternow.common.TimeRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a YearlyRecurrenceProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.YearlyRecurrenceProto} YearlyRecurrenceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            YearlyRecurrenceProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a YearlyRecurrenceProto message.
             * @function verify
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            YearlyRecurrenceProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.month != null && message.hasOwnProperty("month"))
                    switch (message.month) {
                    default:
                        return "month: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                    case 9:
                    case 10:
                    case 11:
                    case 12:
                        break;
                    }
                if (message.day != null && message.hasOwnProperty("day"))
                    if (!$util.isInteger(message.day))
                        return "day: integer expected";
                if (message.timeRange != null && message.hasOwnProperty("timeRange")) {
                    var error = $root.waiternow.common.TimeRangeProto.verify(message.timeRange);
                    if (error)
                        return "timeRange." + error;
                }
                return null;
            };

            /**
             * Creates a YearlyRecurrenceProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.YearlyRecurrenceProto} YearlyRecurrenceProto
             */
            YearlyRecurrenceProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.YearlyRecurrenceProto)
                    return object;
                var message = new $root.waiternow.common.YearlyRecurrenceProto();
                switch (object.month) {
                default:
                    if (typeof object.month === "number") {
                        message.month = object.month;
                        break;
                    }
                    break;
                case "MONTH_UNSPECIFIED":
                case 0:
                    message.month = 0;
                    break;
                case "JANUARY":
                case 1:
                    message.month = 1;
                    break;
                case "FEBRUARY":
                case 2:
                    message.month = 2;
                    break;
                case "MARCH":
                case 3:
                    message.month = 3;
                    break;
                case "APRIL":
                case 4:
                    message.month = 4;
                    break;
                case "MAY":
                case 5:
                    message.month = 5;
                    break;
                case "JUNE":
                case 6:
                    message.month = 6;
                    break;
                case "JULY":
                case 7:
                    message.month = 7;
                    break;
                case "AUGUST":
                case 8:
                    message.month = 8;
                    break;
                case "SEPTEMBER":
                case 9:
                    message.month = 9;
                    break;
                case "OCTOBER":
                case 10:
                    message.month = 10;
                    break;
                case "NOVEMBER":
                case 11:
                    message.month = 11;
                    break;
                case "DECEMBER":
                case 12:
                    message.month = 12;
                    break;
                }
                if (object.day != null)
                    message.day = object.day | 0;
                if (object.timeRange != null) {
                    if (typeof object.timeRange !== "object")
                        throw TypeError(".waiternow.common.YearlyRecurrenceProto.timeRange: object expected");
                    message.timeRange = $root.waiternow.common.TimeRangeProto.fromObject(object.timeRange);
                }
                return message;
            };

            /**
             * Creates a plain object from a YearlyRecurrenceProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {waiternow.common.YearlyRecurrenceProto} message YearlyRecurrenceProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            YearlyRecurrenceProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.month = options.enums === String ? "MONTH_UNSPECIFIED" : 0;
                    object.day = 0;
                    object.timeRange = null;
                }
                if (message.month != null && message.hasOwnProperty("month"))
                    object.month = options.enums === String ? $root.waiternow.common.Month[message.month] === undefined ? message.month : $root.waiternow.common.Month[message.month] : message.month;
                if (message.day != null && message.hasOwnProperty("day"))
                    object.day = message.day;
                if (message.timeRange != null && message.hasOwnProperty("timeRange"))
                    object.timeRange = $root.waiternow.common.TimeRangeProto.toObject(message.timeRange, options);
                return object;
            };

            /**
             * Converts this YearlyRecurrenceProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            YearlyRecurrenceProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for YearlyRecurrenceProto
             * @function getTypeUrl
             * @memberof waiternow.common.YearlyRecurrenceProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            YearlyRecurrenceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.YearlyRecurrenceProto";
            };

            return YearlyRecurrenceProto;
        })();

        common.ScheduleProto = (function() {

            /**
             * Properties of a ScheduleProto.
             * @memberof waiternow.common
             * @interface IScheduleProto
             * @property {Array.<waiternow.common.IDateTimeRangeProto>|null} [dateTimeRanges] ScheduleProto dateTimeRanges
             * @property {Array.<waiternow.common.IDailyRecurrenceProto>|null} [dailyRecurrences] ScheduleProto dailyRecurrences
             * @property {Array.<waiternow.common.IMonthlyRecurrenceProto>|null} [monthlyRecurrences] ScheduleProto monthlyRecurrences
             * @property {Array.<waiternow.common.IYearlyRecurrenceProto>|null} [yearlyRecurrences] ScheduleProto yearlyRecurrences
             * @property {boolean|null} [isInverted] ScheduleProto isInverted
             */

            /**
             * Constructs a new ScheduleProto.
             * @memberof waiternow.common
             * @classdesc Represents a ScheduleProto.
             * @implements IScheduleProto
             * @constructor
             * @param {waiternow.common.IScheduleProto=} [properties] Properties to set
             */
            function ScheduleProto(properties) {
                this.dateTimeRanges = [];
                this.dailyRecurrences = [];
                this.monthlyRecurrences = [];
                this.yearlyRecurrences = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ScheduleProto dateTimeRanges.
             * @member {Array.<waiternow.common.IDateTimeRangeProto>} dateTimeRanges
             * @memberof waiternow.common.ScheduleProto
             * @instance
             */
            ScheduleProto.prototype.dateTimeRanges = $util.emptyArray;

            /**
             * ScheduleProto dailyRecurrences.
             * @member {Array.<waiternow.common.IDailyRecurrenceProto>} dailyRecurrences
             * @memberof waiternow.common.ScheduleProto
             * @instance
             */
            ScheduleProto.prototype.dailyRecurrences = $util.emptyArray;

            /**
             * ScheduleProto monthlyRecurrences.
             * @member {Array.<waiternow.common.IMonthlyRecurrenceProto>} monthlyRecurrences
             * @memberof waiternow.common.ScheduleProto
             * @instance
             */
            ScheduleProto.prototype.monthlyRecurrences = $util.emptyArray;

            /**
             * ScheduleProto yearlyRecurrences.
             * @member {Array.<waiternow.common.IYearlyRecurrenceProto>} yearlyRecurrences
             * @memberof waiternow.common.ScheduleProto
             * @instance
             */
            ScheduleProto.prototype.yearlyRecurrences = $util.emptyArray;

            /**
             * ScheduleProto isInverted.
             * @member {boolean} isInverted
             * @memberof waiternow.common.ScheduleProto
             * @instance
             */
            ScheduleProto.prototype.isInverted = false;

            /**
             * Creates a new ScheduleProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {waiternow.common.IScheduleProto=} [properties] Properties to set
             * @returns {waiternow.common.ScheduleProto} ScheduleProto instance
             */
            ScheduleProto.create = function create(properties) {
                return new ScheduleProto(properties);
            };

            /**
             * Encodes the specified ScheduleProto message. Does not implicitly {@link waiternow.common.ScheduleProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {waiternow.common.IScheduleProto} message ScheduleProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScheduleProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.dateTimeRanges != null && message.dateTimeRanges.length)
                    for (var i = 0; i < message.dateTimeRanges.length; ++i)
                        $root.waiternow.common.DateTimeRangeProto.encode(message.dateTimeRanges[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.dailyRecurrences != null && message.dailyRecurrences.length)
                    for (var i = 0; i < message.dailyRecurrences.length; ++i)
                        $root.waiternow.common.DailyRecurrenceProto.encode(message.dailyRecurrences[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.monthlyRecurrences != null && message.monthlyRecurrences.length)
                    for (var i = 0; i < message.monthlyRecurrences.length; ++i)
                        $root.waiternow.common.MonthlyRecurrenceProto.encode(message.monthlyRecurrences[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.yearlyRecurrences != null && message.yearlyRecurrences.length)
                    for (var i = 0; i < message.yearlyRecurrences.length; ++i)
                        $root.waiternow.common.YearlyRecurrenceProto.encode(message.yearlyRecurrences[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.isInverted != null && Object.hasOwnProperty.call(message, "isInverted"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isInverted);
                return writer;
            };

            /**
             * Encodes the specified ScheduleProto message, length delimited. Does not implicitly {@link waiternow.common.ScheduleProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {waiternow.common.IScheduleProto} message ScheduleProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ScheduleProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ScheduleProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ScheduleProto} ScheduleProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScheduleProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ScheduleProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.dateTimeRanges && message.dateTimeRanges.length))
                                message.dateTimeRanges = [];
                            message.dateTimeRanges.push($root.waiternow.common.DateTimeRangeProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            if (!(message.dailyRecurrences && message.dailyRecurrences.length))
                                message.dailyRecurrences = [];
                            message.dailyRecurrences.push($root.waiternow.common.DailyRecurrenceProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            if (!(message.monthlyRecurrences && message.monthlyRecurrences.length))
                                message.monthlyRecurrences = [];
                            message.monthlyRecurrences.push($root.waiternow.common.MonthlyRecurrenceProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 4: {
                            if (!(message.yearlyRecurrences && message.yearlyRecurrences.length))
                                message.yearlyRecurrences = [];
                            message.yearlyRecurrences.push($root.waiternow.common.YearlyRecurrenceProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 5: {
                            message.isInverted = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ScheduleProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ScheduleProto} ScheduleProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ScheduleProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ScheduleProto message.
             * @function verify
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ScheduleProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.dateTimeRanges != null && message.hasOwnProperty("dateTimeRanges")) {
                    if (!Array.isArray(message.dateTimeRanges))
                        return "dateTimeRanges: array expected";
                    for (var i = 0; i < message.dateTimeRanges.length; ++i) {
                        var error = $root.waiternow.common.DateTimeRangeProto.verify(message.dateTimeRanges[i]);
                        if (error)
                            return "dateTimeRanges." + error;
                    }
                }
                if (message.dailyRecurrences != null && message.hasOwnProperty("dailyRecurrences")) {
                    if (!Array.isArray(message.dailyRecurrences))
                        return "dailyRecurrences: array expected";
                    for (var i = 0; i < message.dailyRecurrences.length; ++i) {
                        var error = $root.waiternow.common.DailyRecurrenceProto.verify(message.dailyRecurrences[i]);
                        if (error)
                            return "dailyRecurrences." + error;
                    }
                }
                if (message.monthlyRecurrences != null && message.hasOwnProperty("monthlyRecurrences")) {
                    if (!Array.isArray(message.monthlyRecurrences))
                        return "monthlyRecurrences: array expected";
                    for (var i = 0; i < message.monthlyRecurrences.length; ++i) {
                        var error = $root.waiternow.common.MonthlyRecurrenceProto.verify(message.monthlyRecurrences[i]);
                        if (error)
                            return "monthlyRecurrences." + error;
                    }
                }
                if (message.yearlyRecurrences != null && message.hasOwnProperty("yearlyRecurrences")) {
                    if (!Array.isArray(message.yearlyRecurrences))
                        return "yearlyRecurrences: array expected";
                    for (var i = 0; i < message.yearlyRecurrences.length; ++i) {
                        var error = $root.waiternow.common.YearlyRecurrenceProto.verify(message.yearlyRecurrences[i]);
                        if (error)
                            return "yearlyRecurrences." + error;
                    }
                }
                if (message.isInverted != null && message.hasOwnProperty("isInverted"))
                    if (typeof message.isInverted !== "boolean")
                        return "isInverted: boolean expected";
                return null;
            };

            /**
             * Creates a ScheduleProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ScheduleProto} ScheduleProto
             */
            ScheduleProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ScheduleProto)
                    return object;
                var message = new $root.waiternow.common.ScheduleProto();
                if (object.dateTimeRanges) {
                    if (!Array.isArray(object.dateTimeRanges))
                        throw TypeError(".waiternow.common.ScheduleProto.dateTimeRanges: array expected");
                    message.dateTimeRanges = [];
                    for (var i = 0; i < object.dateTimeRanges.length; ++i) {
                        if (typeof object.dateTimeRanges[i] !== "object")
                            throw TypeError(".waiternow.common.ScheduleProto.dateTimeRanges: object expected");
                        message.dateTimeRanges[i] = $root.waiternow.common.DateTimeRangeProto.fromObject(object.dateTimeRanges[i]);
                    }
                }
                if (object.dailyRecurrences) {
                    if (!Array.isArray(object.dailyRecurrences))
                        throw TypeError(".waiternow.common.ScheduleProto.dailyRecurrences: array expected");
                    message.dailyRecurrences = [];
                    for (var i = 0; i < object.dailyRecurrences.length; ++i) {
                        if (typeof object.dailyRecurrences[i] !== "object")
                            throw TypeError(".waiternow.common.ScheduleProto.dailyRecurrences: object expected");
                        message.dailyRecurrences[i] = $root.waiternow.common.DailyRecurrenceProto.fromObject(object.dailyRecurrences[i]);
                    }
                }
                if (object.monthlyRecurrences) {
                    if (!Array.isArray(object.monthlyRecurrences))
                        throw TypeError(".waiternow.common.ScheduleProto.monthlyRecurrences: array expected");
                    message.monthlyRecurrences = [];
                    for (var i = 0; i < object.monthlyRecurrences.length; ++i) {
                        if (typeof object.monthlyRecurrences[i] !== "object")
                            throw TypeError(".waiternow.common.ScheduleProto.monthlyRecurrences: object expected");
                        message.monthlyRecurrences[i] = $root.waiternow.common.MonthlyRecurrenceProto.fromObject(object.monthlyRecurrences[i]);
                    }
                }
                if (object.yearlyRecurrences) {
                    if (!Array.isArray(object.yearlyRecurrences))
                        throw TypeError(".waiternow.common.ScheduleProto.yearlyRecurrences: array expected");
                    message.yearlyRecurrences = [];
                    for (var i = 0; i < object.yearlyRecurrences.length; ++i) {
                        if (typeof object.yearlyRecurrences[i] !== "object")
                            throw TypeError(".waiternow.common.ScheduleProto.yearlyRecurrences: object expected");
                        message.yearlyRecurrences[i] = $root.waiternow.common.YearlyRecurrenceProto.fromObject(object.yearlyRecurrences[i]);
                    }
                }
                if (object.isInverted != null)
                    message.isInverted = Boolean(object.isInverted);
                return message;
            };

            /**
             * Creates a plain object from a ScheduleProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {waiternow.common.ScheduleProto} message ScheduleProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ScheduleProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.dateTimeRanges = [];
                    object.dailyRecurrences = [];
                    object.monthlyRecurrences = [];
                    object.yearlyRecurrences = [];
                }
                if (options.defaults)
                    object.isInverted = false;
                if (message.dateTimeRanges && message.dateTimeRanges.length) {
                    object.dateTimeRanges = [];
                    for (var j = 0; j < message.dateTimeRanges.length; ++j)
                        object.dateTimeRanges[j] = $root.waiternow.common.DateTimeRangeProto.toObject(message.dateTimeRanges[j], options);
                }
                if (message.dailyRecurrences && message.dailyRecurrences.length) {
                    object.dailyRecurrences = [];
                    for (var j = 0; j < message.dailyRecurrences.length; ++j)
                        object.dailyRecurrences[j] = $root.waiternow.common.DailyRecurrenceProto.toObject(message.dailyRecurrences[j], options);
                }
                if (message.monthlyRecurrences && message.monthlyRecurrences.length) {
                    object.monthlyRecurrences = [];
                    for (var j = 0; j < message.monthlyRecurrences.length; ++j)
                        object.monthlyRecurrences[j] = $root.waiternow.common.MonthlyRecurrenceProto.toObject(message.monthlyRecurrences[j], options);
                }
                if (message.yearlyRecurrences && message.yearlyRecurrences.length) {
                    object.yearlyRecurrences = [];
                    for (var j = 0; j < message.yearlyRecurrences.length; ++j)
                        object.yearlyRecurrences[j] = $root.waiternow.common.YearlyRecurrenceProto.toObject(message.yearlyRecurrences[j], options);
                }
                if (message.isInverted != null && message.hasOwnProperty("isInverted"))
                    object.isInverted = message.isInverted;
                return object;
            };

            /**
             * Converts this ScheduleProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ScheduleProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ScheduleProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ScheduleProto
             * @function getTypeUrl
             * @memberof waiternow.common.ScheduleProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ScheduleProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ScheduleProto";
            };

            return ScheduleProto;
        })();

        common.PrinterProto = (function() {

            /**
             * Properties of a PrinterProto.
             * @memberof waiternow.common
             * @interface IPrinterProto
             * @property {waiternow.common.PrinterProto.Brand|null} [brand] PrinterProto brand
             * @property {string|null} [model] PrinterProto model
             * @property {string|null} [station] PrinterProto station
             * @property {waiternow.common.PrinterProto.ILanSettingsProto|null} [lanSettings] PrinterProto lanSettings
             * @property {waiternow.common.PrinterProto.ICalibrationProto|null} [orderCalibration] PrinterProto orderCalibration
             * @property {waiternow.common.PrinterProto.ICalibrationProto|null} [orderReceiptCalibration] PrinterProto orderReceiptCalibration
             * @property {boolean|null} [isDisabled] PrinterProto isDisabled
             */

            /**
             * Constructs a new PrinterProto.
             * @memberof waiternow.common
             * @classdesc Represents a PrinterProto.
             * @implements IPrinterProto
             * @constructor
             * @param {waiternow.common.IPrinterProto=} [properties] Properties to set
             */
            function PrinterProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PrinterProto brand.
             * @member {waiternow.common.PrinterProto.Brand} brand
             * @memberof waiternow.common.PrinterProto
             * @instance
             */
            PrinterProto.prototype.brand = 0;

            /**
             * PrinterProto model.
             * @member {string} model
             * @memberof waiternow.common.PrinterProto
             * @instance
             */
            PrinterProto.prototype.model = "";

            /**
             * PrinterProto station.
             * @member {string} station
             * @memberof waiternow.common.PrinterProto
             * @instance
             */
            PrinterProto.prototype.station = "";

            /**
             * PrinterProto lanSettings.
             * @member {waiternow.common.PrinterProto.ILanSettingsProto|null|undefined} lanSettings
             * @memberof waiternow.common.PrinterProto
             * @instance
             */
            PrinterProto.prototype.lanSettings = null;

            /**
             * PrinterProto orderCalibration.
             * @member {waiternow.common.PrinterProto.ICalibrationProto|null|undefined} orderCalibration
             * @memberof waiternow.common.PrinterProto
             * @instance
             */
            PrinterProto.prototype.orderCalibration = null;

            /**
             * PrinterProto orderReceiptCalibration.
             * @member {waiternow.common.PrinterProto.ICalibrationProto|null|undefined} orderReceiptCalibration
             * @memberof waiternow.common.PrinterProto
             * @instance
             */
            PrinterProto.prototype.orderReceiptCalibration = null;

            /**
             * PrinterProto isDisabled.
             * @member {boolean} isDisabled
             * @memberof waiternow.common.PrinterProto
             * @instance
             */
            PrinterProto.prototype.isDisabled = false;

            /**
             * Creates a new PrinterProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {waiternow.common.IPrinterProto=} [properties] Properties to set
             * @returns {waiternow.common.PrinterProto} PrinterProto instance
             */
            PrinterProto.create = function create(properties) {
                return new PrinterProto(properties);
            };

            /**
             * Encodes the specified PrinterProto message. Does not implicitly {@link waiternow.common.PrinterProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {waiternow.common.IPrinterProto} message PrinterProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrinterProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.brand != null && Object.hasOwnProperty.call(message, "brand"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.brand);
                if (message.station != null && Object.hasOwnProperty.call(message, "station"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.station);
                if (message.lanSettings != null && Object.hasOwnProperty.call(message, "lanSettings"))
                    $root.waiternow.common.PrinterProto.LanSettingsProto.encode(message.lanSettings, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.orderCalibration != null && Object.hasOwnProperty.call(message, "orderCalibration"))
                    $root.waiternow.common.PrinterProto.CalibrationProto.encode(message.orderCalibration, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.orderReceiptCalibration != null && Object.hasOwnProperty.call(message, "orderReceiptCalibration"))
                    $root.waiternow.common.PrinterProto.CalibrationProto.encode(message.orderReceiptCalibration, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.isDisabled != null && Object.hasOwnProperty.call(message, "isDisabled"))
                    writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isDisabled);
                if (message.model != null && Object.hasOwnProperty.call(message, "model"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.model);
                return writer;
            };

            /**
             * Encodes the specified PrinterProto message, length delimited. Does not implicitly {@link waiternow.common.PrinterProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {waiternow.common.IPrinterProto} message PrinterProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PrinterProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PrinterProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PrinterProto} PrinterProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrinterProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PrinterProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.brand = reader.int32();
                            break;
                        }
                    case 7: {
                            message.model = reader.string();
                            break;
                        }
                    case 2: {
                            message.station = reader.string();
                            break;
                        }
                    case 3: {
                            message.lanSettings = $root.waiternow.common.PrinterProto.LanSettingsProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.orderCalibration = $root.waiternow.common.PrinterProto.CalibrationProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.orderReceiptCalibration = $root.waiternow.common.PrinterProto.CalibrationProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.isDisabled = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PrinterProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PrinterProto} PrinterProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PrinterProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PrinterProto message.
             * @function verify
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PrinterProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.brand != null && message.hasOwnProperty("brand"))
                    switch (message.brand) {
                    default:
                        return "brand: enum value expected";
                    case 0:
                    case 1:
                        break;
                    }
                if (message.model != null && message.hasOwnProperty("model"))
                    if (!$util.isString(message.model))
                        return "model: string expected";
                if (message.station != null && message.hasOwnProperty("station"))
                    if (!$util.isString(message.station))
                        return "station: string expected";
                if (message.lanSettings != null && message.hasOwnProperty("lanSettings")) {
                    var error = $root.waiternow.common.PrinterProto.LanSettingsProto.verify(message.lanSettings);
                    if (error)
                        return "lanSettings." + error;
                }
                if (message.orderCalibration != null && message.hasOwnProperty("orderCalibration")) {
                    var error = $root.waiternow.common.PrinterProto.CalibrationProto.verify(message.orderCalibration);
                    if (error)
                        return "orderCalibration." + error;
                }
                if (message.orderReceiptCalibration != null && message.hasOwnProperty("orderReceiptCalibration")) {
                    var error = $root.waiternow.common.PrinterProto.CalibrationProto.verify(message.orderReceiptCalibration);
                    if (error)
                        return "orderReceiptCalibration." + error;
                }
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    if (typeof message.isDisabled !== "boolean")
                        return "isDisabled: boolean expected";
                return null;
            };

            /**
             * Creates a PrinterProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PrinterProto} PrinterProto
             */
            PrinterProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PrinterProto)
                    return object;
                var message = new $root.waiternow.common.PrinterProto();
                switch (object.brand) {
                default:
                    if (typeof object.brand === "number") {
                        message.brand = object.brand;
                        break;
                    }
                    break;
                case "BRAND_UNSPECIFIED":
                case 0:
                    message.brand = 0;
                    break;
                case "STAR_MICRONICS":
                case 1:
                    message.brand = 1;
                    break;
                }
                if (object.model != null)
                    message.model = String(object.model);
                if (object.station != null)
                    message.station = String(object.station);
                if (object.lanSettings != null) {
                    if (typeof object.lanSettings !== "object")
                        throw TypeError(".waiternow.common.PrinterProto.lanSettings: object expected");
                    message.lanSettings = $root.waiternow.common.PrinterProto.LanSettingsProto.fromObject(object.lanSettings);
                }
                if (object.orderCalibration != null) {
                    if (typeof object.orderCalibration !== "object")
                        throw TypeError(".waiternow.common.PrinterProto.orderCalibration: object expected");
                    message.orderCalibration = $root.waiternow.common.PrinterProto.CalibrationProto.fromObject(object.orderCalibration);
                }
                if (object.orderReceiptCalibration != null) {
                    if (typeof object.orderReceiptCalibration !== "object")
                        throw TypeError(".waiternow.common.PrinterProto.orderReceiptCalibration: object expected");
                    message.orderReceiptCalibration = $root.waiternow.common.PrinterProto.CalibrationProto.fromObject(object.orderReceiptCalibration);
                }
                if (object.isDisabled != null)
                    message.isDisabled = Boolean(object.isDisabled);
                return message;
            };

            /**
             * Creates a plain object from a PrinterProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {waiternow.common.PrinterProto} message PrinterProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PrinterProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.brand = options.enums === String ? "BRAND_UNSPECIFIED" : 0;
                    object.station = "";
                    object.lanSettings = null;
                    object.orderCalibration = null;
                    object.orderReceiptCalibration = null;
                    object.isDisabled = false;
                    object.model = "";
                }
                if (message.brand != null && message.hasOwnProperty("brand"))
                    object.brand = options.enums === String ? $root.waiternow.common.PrinterProto.Brand[message.brand] === undefined ? message.brand : $root.waiternow.common.PrinterProto.Brand[message.brand] : message.brand;
                if (message.station != null && message.hasOwnProperty("station"))
                    object.station = message.station;
                if (message.lanSettings != null && message.hasOwnProperty("lanSettings"))
                    object.lanSettings = $root.waiternow.common.PrinterProto.LanSettingsProto.toObject(message.lanSettings, options);
                if (message.orderCalibration != null && message.hasOwnProperty("orderCalibration"))
                    object.orderCalibration = $root.waiternow.common.PrinterProto.CalibrationProto.toObject(message.orderCalibration, options);
                if (message.orderReceiptCalibration != null && message.hasOwnProperty("orderReceiptCalibration"))
                    object.orderReceiptCalibration = $root.waiternow.common.PrinterProto.CalibrationProto.toObject(message.orderReceiptCalibration, options);
                if (message.isDisabled != null && message.hasOwnProperty("isDisabled"))
                    object.isDisabled = message.isDisabled;
                if (message.model != null && message.hasOwnProperty("model"))
                    object.model = message.model;
                return object;
            };

            /**
             * Converts this PrinterProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PrinterProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PrinterProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PrinterProto
             * @function getTypeUrl
             * @memberof waiternow.common.PrinterProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PrinterProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PrinterProto";
            };

            /**
             * Brand enum.
             * @name waiternow.common.PrinterProto.Brand
             * @enum {number}
             * @property {number} BRAND_UNSPECIFIED=0 BRAND_UNSPECIFIED value
             * @property {number} STAR_MICRONICS=1 STAR_MICRONICS value
             */
            PrinterProto.Brand = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "BRAND_UNSPECIFIED"] = 0;
                values[valuesById[1] = "STAR_MICRONICS"] = 1;
                return values;
            })();

            PrinterProto.LanSettingsProto = (function() {

                /**
                 * Properties of a LanSettingsProto.
                 * @memberof waiternow.common.PrinterProto
                 * @interface ILanSettingsProto
                 * @property {string|null} [ipAddress] LanSettingsProto ipAddress
                 */

                /**
                 * Constructs a new LanSettingsProto.
                 * @memberof waiternow.common.PrinterProto
                 * @classdesc Represents a LanSettingsProto.
                 * @implements ILanSettingsProto
                 * @constructor
                 * @param {waiternow.common.PrinterProto.ILanSettingsProto=} [properties] Properties to set
                 */
                function LanSettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LanSettingsProto ipAddress.
                 * @member {string} ipAddress
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @instance
                 */
                LanSettingsProto.prototype.ipAddress = "";

                /**
                 * Creates a new LanSettingsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {waiternow.common.PrinterProto.ILanSettingsProto=} [properties] Properties to set
                 * @returns {waiternow.common.PrinterProto.LanSettingsProto} LanSettingsProto instance
                 */
                LanSettingsProto.create = function create(properties) {
                    return new LanSettingsProto(properties);
                };

                /**
                 * Encodes the specified LanSettingsProto message. Does not implicitly {@link waiternow.common.PrinterProto.LanSettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {waiternow.common.PrinterProto.ILanSettingsProto} message LanSettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LanSettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.ipAddress != null && Object.hasOwnProperty.call(message, "ipAddress"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.ipAddress);
                    return writer;
                };

                /**
                 * Encodes the specified LanSettingsProto message, length delimited. Does not implicitly {@link waiternow.common.PrinterProto.LanSettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {waiternow.common.PrinterProto.ILanSettingsProto} message LanSettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LanSettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LanSettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PrinterProto.LanSettingsProto} LanSettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LanSettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PrinterProto.LanSettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.ipAddress = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LanSettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PrinterProto.LanSettingsProto} LanSettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LanSettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LanSettingsProto message.
                 * @function verify
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LanSettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.ipAddress != null && message.hasOwnProperty("ipAddress"))
                        if (!$util.isString(message.ipAddress))
                            return "ipAddress: string expected";
                    return null;
                };

                /**
                 * Creates a LanSettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PrinterProto.LanSettingsProto} LanSettingsProto
                 */
                LanSettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PrinterProto.LanSettingsProto)
                        return object;
                    var message = new $root.waiternow.common.PrinterProto.LanSettingsProto();
                    if (object.ipAddress != null)
                        message.ipAddress = String(object.ipAddress);
                    return message;
                };

                /**
                 * Creates a plain object from a LanSettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {waiternow.common.PrinterProto.LanSettingsProto} message LanSettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LanSettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.ipAddress = "";
                    if (message.ipAddress != null && message.hasOwnProperty("ipAddress"))
                        object.ipAddress = message.ipAddress;
                    return object;
                };

                /**
                 * Converts this LanSettingsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LanSettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LanSettingsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PrinterProto.LanSettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LanSettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PrinterProto.LanSettingsProto";
                };

                return LanSettingsProto;
            })();

            PrinterProto.CalibrationProto = (function() {

                /**
                 * Properties of a CalibrationProto.
                 * @memberof waiternow.common.PrinterProto
                 * @interface ICalibrationProto
                 * @property {number|null} [titleFontSize] CalibrationProto titleFontSize
                 * @property {number|null} [subtitleFontSize] CalibrationProto subtitleFontSize
                 * @property {number|null} [normalFontSize] CalibrationProto normalFontSize
                 * @property {number|null} [lineMaxCharsInNormalFontSize] CalibrationProto lineMaxCharsInNormalFontSize
                 */

                /**
                 * Constructs a new CalibrationProto.
                 * @memberof waiternow.common.PrinterProto
                 * @classdesc Represents a CalibrationProto.
                 * @implements ICalibrationProto
                 * @constructor
                 * @param {waiternow.common.PrinterProto.ICalibrationProto=} [properties] Properties to set
                 */
                function CalibrationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * CalibrationProto titleFontSize.
                 * @member {number} titleFontSize
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @instance
                 */
                CalibrationProto.prototype.titleFontSize = 0;

                /**
                 * CalibrationProto subtitleFontSize.
                 * @member {number} subtitleFontSize
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @instance
                 */
                CalibrationProto.prototype.subtitleFontSize = 0;

                /**
                 * CalibrationProto normalFontSize.
                 * @member {number} normalFontSize
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @instance
                 */
                CalibrationProto.prototype.normalFontSize = 0;

                /**
                 * CalibrationProto lineMaxCharsInNormalFontSize.
                 * @member {number} lineMaxCharsInNormalFontSize
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @instance
                 */
                CalibrationProto.prototype.lineMaxCharsInNormalFontSize = 0;

                /**
                 * Creates a new CalibrationProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {waiternow.common.PrinterProto.ICalibrationProto=} [properties] Properties to set
                 * @returns {waiternow.common.PrinterProto.CalibrationProto} CalibrationProto instance
                 */
                CalibrationProto.create = function create(properties) {
                    return new CalibrationProto(properties);
                };

                /**
                 * Encodes the specified CalibrationProto message. Does not implicitly {@link waiternow.common.PrinterProto.CalibrationProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {waiternow.common.PrinterProto.ICalibrationProto} message CalibrationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalibrationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.titleFontSize != null && Object.hasOwnProperty.call(message, "titleFontSize"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.titleFontSize);
                    if (message.subtitleFontSize != null && Object.hasOwnProperty.call(message, "subtitleFontSize"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.subtitleFontSize);
                    if (message.normalFontSize != null && Object.hasOwnProperty.call(message, "normalFontSize"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.normalFontSize);
                    if (message.lineMaxCharsInNormalFontSize != null && Object.hasOwnProperty.call(message, "lineMaxCharsInNormalFontSize"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.lineMaxCharsInNormalFontSize);
                    return writer;
                };

                /**
                 * Encodes the specified CalibrationProto message, length delimited. Does not implicitly {@link waiternow.common.PrinterProto.CalibrationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {waiternow.common.PrinterProto.ICalibrationProto} message CalibrationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                CalibrationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a CalibrationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PrinterProto.CalibrationProto} CalibrationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalibrationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PrinterProto.CalibrationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.titleFontSize = reader.int32();
                                break;
                            }
                        case 2: {
                                message.subtitleFontSize = reader.int32();
                                break;
                            }
                        case 3: {
                                message.normalFontSize = reader.int32();
                                break;
                            }
                        case 4: {
                                message.lineMaxCharsInNormalFontSize = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a CalibrationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PrinterProto.CalibrationProto} CalibrationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                CalibrationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a CalibrationProto message.
                 * @function verify
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                CalibrationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.titleFontSize != null && message.hasOwnProperty("titleFontSize"))
                        if (!$util.isInteger(message.titleFontSize))
                            return "titleFontSize: integer expected";
                    if (message.subtitleFontSize != null && message.hasOwnProperty("subtitleFontSize"))
                        if (!$util.isInteger(message.subtitleFontSize))
                            return "subtitleFontSize: integer expected";
                    if (message.normalFontSize != null && message.hasOwnProperty("normalFontSize"))
                        if (!$util.isInteger(message.normalFontSize))
                            return "normalFontSize: integer expected";
                    if (message.lineMaxCharsInNormalFontSize != null && message.hasOwnProperty("lineMaxCharsInNormalFontSize"))
                        if (!$util.isInteger(message.lineMaxCharsInNormalFontSize))
                            return "lineMaxCharsInNormalFontSize: integer expected";
                    return null;
                };

                /**
                 * Creates a CalibrationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PrinterProto.CalibrationProto} CalibrationProto
                 */
                CalibrationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PrinterProto.CalibrationProto)
                        return object;
                    var message = new $root.waiternow.common.PrinterProto.CalibrationProto();
                    if (object.titleFontSize != null)
                        message.titleFontSize = object.titleFontSize | 0;
                    if (object.subtitleFontSize != null)
                        message.subtitleFontSize = object.subtitleFontSize | 0;
                    if (object.normalFontSize != null)
                        message.normalFontSize = object.normalFontSize | 0;
                    if (object.lineMaxCharsInNormalFontSize != null)
                        message.lineMaxCharsInNormalFontSize = object.lineMaxCharsInNormalFontSize | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a CalibrationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {waiternow.common.PrinterProto.CalibrationProto} message CalibrationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                CalibrationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.titleFontSize = 0;
                        object.subtitleFontSize = 0;
                        object.normalFontSize = 0;
                        object.lineMaxCharsInNormalFontSize = 0;
                    }
                    if (message.titleFontSize != null && message.hasOwnProperty("titleFontSize"))
                        object.titleFontSize = message.titleFontSize;
                    if (message.subtitleFontSize != null && message.hasOwnProperty("subtitleFontSize"))
                        object.subtitleFontSize = message.subtitleFontSize;
                    if (message.normalFontSize != null && message.hasOwnProperty("normalFontSize"))
                        object.normalFontSize = message.normalFontSize;
                    if (message.lineMaxCharsInNormalFontSize != null && message.hasOwnProperty("lineMaxCharsInNormalFontSize"))
                        object.lineMaxCharsInNormalFontSize = message.lineMaxCharsInNormalFontSize;
                    return object;
                };

                /**
                 * Converts this CalibrationProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                CalibrationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for CalibrationProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PrinterProto.CalibrationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                CalibrationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PrinterProto.CalibrationProto";
                };

                return CalibrationProto;
            })();

            return PrinterProto;
        })();

        common.UserProto = (function() {

            /**
             * Properties of a UserProto.
             * @memberof waiternow.common
             * @interface IUserProto
             * @property {string|null} [id] UserProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] UserProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] UserProto lastUpdateTime
             * @property {string|null} [email] UserProto email
             * @property {string|null} [password] UserProto password
             * @property {string|null} [passwordHash] UserProto passwordHash
             * @property {Uint8Array|null} [salt] UserProto salt
             * @property {string|null} [name] UserProto name
             * @property {string|null} [lastName] UserProto lastName
             * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] UserProto phoneNumber
             * @property {boolean|null} [isSuspended] UserProto isSuspended
             * @property {waiternow.common.UserProto.SuspensionCause|null} [suspensionCause] UserProto suspensionCause
             * @property {waiternow.common.UserProto.ISettingsProto|null} [settings] UserProto settings
             * @property {waiternow.common.UserProto.IMetadataProto|null} [metadata] UserProto metadata
             */

            /**
             * Constructs a new UserProto.
             * @memberof waiternow.common
             * @classdesc Represents a UserProto.
             * @implements IUserProto
             * @constructor
             * @param {waiternow.common.IUserProto=} [properties] Properties to set
             */
            function UserProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserProto id.
             * @member {string} id
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.id = "";

            /**
             * UserProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.creationTime = null;

            /**
             * UserProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.lastUpdateTime = null;

            /**
             * UserProto email.
             * @member {string} email
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.email = "";

            /**
             * UserProto password.
             * @member {string} password
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.password = "";

            /**
             * UserProto passwordHash.
             * @member {string} passwordHash
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.passwordHash = "";

            /**
             * UserProto salt.
             * @member {Uint8Array} salt
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.salt = $util.newBuffer([]);

            /**
             * UserProto name.
             * @member {string} name
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.name = "";

            /**
             * UserProto lastName.
             * @member {string} lastName
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.lastName = "";

            /**
             * UserProto phoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.phoneNumber = null;

            /**
             * UserProto isSuspended.
             * @member {boolean} isSuspended
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.isSuspended = false;

            /**
             * UserProto suspensionCause.
             * @member {waiternow.common.UserProto.SuspensionCause} suspensionCause
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.suspensionCause = 0;

            /**
             * UserProto settings.
             * @member {waiternow.common.UserProto.ISettingsProto|null|undefined} settings
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.settings = null;

            /**
             * UserProto metadata.
             * @member {waiternow.common.UserProto.IMetadataProto|null|undefined} metadata
             * @memberof waiternow.common.UserProto
             * @instance
             */
            UserProto.prototype.metadata = null;

            /**
             * Creates a new UserProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UserProto
             * @static
             * @param {waiternow.common.IUserProto=} [properties] Properties to set
             * @returns {waiternow.common.UserProto} UserProto instance
             */
            UserProto.create = function create(properties) {
                return new UserProto(properties);
            };

            /**
             * Encodes the specified UserProto message. Does not implicitly {@link waiternow.common.UserProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UserProto
             * @static
             * @param {waiternow.common.IUserProto} message UserProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastName);
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.isSuspended != null && Object.hasOwnProperty.call(message, "isSuspended"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isSuspended);
                if (message.suspensionCause != null && Object.hasOwnProperty.call(message, "suspensionCause"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.suspensionCause);
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.waiternow.common.UserProto.SettingsProto.encode(message.settings, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                    $root.waiternow.common.UserProto.MetadataProto.encode(message.metadata, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.passwordHash != null && Object.hasOwnProperty.call(message, "passwordHash"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.passwordHash);
                if (message.salt != null && Object.hasOwnProperty.call(message, "salt"))
                    writer.uint32(/* id 14, wireType 2 =*/114).bytes(message.salt);
                return writer;
            };

            /**
             * Encodes the specified UserProto message, length delimited. Does not implicitly {@link waiternow.common.UserProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UserProto
             * @static
             * @param {waiternow.common.IUserProto} message UserProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UserProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UserProto} UserProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 10: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.email = reader.string();
                            break;
                        }
                    case 3: {
                            message.password = reader.string();
                            break;
                        }
                    case 13: {
                            message.passwordHash = reader.string();
                            break;
                        }
                    case 14: {
                            message.salt = reader.bytes();
                            break;
                        }
                    case 4: {
                            message.name = reader.string();
                            break;
                        }
                    case 5: {
                            message.lastName = reader.string();
                            break;
                        }
                    case 6: {
                            message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.isSuspended = reader.bool();
                            break;
                        }
                    case 8: {
                            message.suspensionCause = reader.int32();
                            break;
                        }
                    case 9: {
                            message.settings = $root.waiternow.common.UserProto.SettingsProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.metadata = $root.waiternow.common.UserProto.MetadataProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UserProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UserProto} UserProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserProto message.
             * @function verify
             * @memberof waiternow.common.UserProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.password != null && message.hasOwnProperty("password"))
                    if (!$util.isString(message.password))
                        return "password: string expected";
                if (message.passwordHash != null && message.hasOwnProperty("passwordHash"))
                    if (!$util.isString(message.passwordHash))
                        return "passwordHash: string expected";
                if (message.salt != null && message.hasOwnProperty("salt"))
                    if (!(message.salt && typeof message.salt.length === "number" || $util.isString(message.salt)))
                        return "salt: buffer expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                    if (error)
                        return "phoneNumber." + error;
                }
                if (message.isSuspended != null && message.hasOwnProperty("isSuspended"))
                    if (typeof message.isSuspended !== "boolean")
                        return "isSuspended: boolean expected";
                if (message.suspensionCause != null && message.hasOwnProperty("suspensionCause"))
                    switch (message.suspensionCause) {
                    default:
                        return "suspensionCause: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    var error = $root.waiternow.common.UserProto.SettingsProto.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
                if (message.metadata != null && message.hasOwnProperty("metadata")) {
                    var error = $root.waiternow.common.UserProto.MetadataProto.verify(message.metadata);
                    if (error)
                        return "metadata." + error;
                }
                return null;
            };

            /**
             * Creates a UserProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UserProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UserProto} UserProto
             */
            UserProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UserProto)
                    return object;
                var message = new $root.waiternow.common.UserProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.UserProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.UserProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.email != null)
                    message.email = String(object.email);
                if (object.password != null)
                    message.password = String(object.password);
                if (object.passwordHash != null)
                    message.passwordHash = String(object.passwordHash);
                if (object.salt != null)
                    if (typeof object.salt === "string")
                        $util.base64.decode(object.salt, message.salt = $util.newBuffer($util.base64.length(object.salt)), 0);
                    else if (object.salt.length >= 0)
                        message.salt = object.salt;
                if (object.name != null)
                    message.name = String(object.name);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                if (object.phoneNumber != null) {
                    if (typeof object.phoneNumber !== "object")
                        throw TypeError(".waiternow.common.UserProto.phoneNumber: object expected");
                    message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                }
                if (object.isSuspended != null)
                    message.isSuspended = Boolean(object.isSuspended);
                switch (object.suspensionCause) {
                default:
                    if (typeof object.suspensionCause === "number") {
                        message.suspensionCause = object.suspensionCause;
                        break;
                    }
                    break;
                case "SUSPENSION_CAUSE_UNSPECIFIED":
                case 0:
                    message.suspensionCause = 0;
                    break;
                case "SPAM_USER":
                case 1:
                    message.suspensionCause = 1;
                    break;
                case "WAITING_EMAIL_VERIFICATION":
                case 2:
                    message.suspensionCause = 2;
                    break;
                }
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".waiternow.common.UserProto.settings: object expected");
                    message.settings = $root.waiternow.common.UserProto.SettingsProto.fromObject(object.settings);
                }
                if (object.metadata != null) {
                    if (typeof object.metadata !== "object")
                        throw TypeError(".waiternow.common.UserProto.metadata: object expected");
                    message.metadata = $root.waiternow.common.UserProto.MetadataProto.fromObject(object.metadata);
                }
                return message;
            };

            /**
             * Creates a plain object from a UserProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UserProto
             * @static
             * @param {waiternow.common.UserProto} message UserProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.email = "";
                    object.password = "";
                    object.name = "";
                    object.lastName = "";
                    object.phoneNumber = null;
                    object.isSuspended = false;
                    object.suspensionCause = options.enums === String ? "SUSPENSION_CAUSE_UNSPECIFIED" : 0;
                    object.settings = null;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.metadata = null;
                    object.passwordHash = "";
                    if (options.bytes === String)
                        object.salt = "";
                    else {
                        object.salt = [];
                        if (options.bytes !== Array)
                            object.salt = $util.newBuffer(object.salt);
                    }
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.password != null && message.hasOwnProperty("password"))
                    object.password = message.password;
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                if (message.isSuspended != null && message.hasOwnProperty("isSuspended"))
                    object.isSuspended = message.isSuspended;
                if (message.suspensionCause != null && message.hasOwnProperty("suspensionCause"))
                    object.suspensionCause = options.enums === String ? $root.waiternow.common.UserProto.SuspensionCause[message.suspensionCause] === undefined ? message.suspensionCause : $root.waiternow.common.UserProto.SuspensionCause[message.suspensionCause] : message.suspensionCause;
                if (message.settings != null && message.hasOwnProperty("settings"))
                    object.settings = $root.waiternow.common.UserProto.SettingsProto.toObject(message.settings, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.metadata != null && message.hasOwnProperty("metadata"))
                    object.metadata = $root.waiternow.common.UserProto.MetadataProto.toObject(message.metadata, options);
                if (message.passwordHash != null && message.hasOwnProperty("passwordHash"))
                    object.passwordHash = message.passwordHash;
                if (message.salt != null && message.hasOwnProperty("salt"))
                    object.salt = options.bytes === String ? $util.base64.encode(message.salt, 0, message.salt.length) : options.bytes === Array ? Array.prototype.slice.call(message.salt) : message.salt;
                return object;
            };

            /**
             * Converts this UserProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UserProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserProto
             * @function getTypeUrl
             * @memberof waiternow.common.UserProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UserProto";
            };

            /**
             * SuspensionCause enum.
             * @name waiternow.common.UserProto.SuspensionCause
             * @enum {number}
             * @property {number} SUSPENSION_CAUSE_UNSPECIFIED=0 SUSPENSION_CAUSE_UNSPECIFIED value
             * @property {number} SPAM_USER=1 SPAM_USER value
             * @property {number} WAITING_EMAIL_VERIFICATION=2 WAITING_EMAIL_VERIFICATION value
             */
            UserProto.SuspensionCause = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SUSPENSION_CAUSE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "SPAM_USER"] = 1;
                values[valuesById[2] = "WAITING_EMAIL_VERIFICATION"] = 2;
                return values;
            })();

            UserProto.SettingsProto = (function() {

                /**
                 * Properties of a SettingsProto.
                 * @memberof waiternow.common.UserProto
                 * @interface ISettingsProto
                 * @property {waiternow.common.ContactMedium|null} [preferredContactMedium] SettingsProto preferredContactMedium
                 * @property {waiternow.common.Language|null} [language] SettingsProto language
                 */

                /**
                 * Constructs a new SettingsProto.
                 * @memberof waiternow.common.UserProto
                 * @classdesc Represents a SettingsProto.
                 * @implements ISettingsProto
                 * @constructor
                 * @param {waiternow.common.UserProto.ISettingsProto=} [properties] Properties to set
                 */
                function SettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SettingsProto preferredContactMedium.
                 * @member {waiternow.common.ContactMedium} preferredContactMedium
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.preferredContactMedium = 0;

                /**
                 * SettingsProto language.
                 * @member {waiternow.common.Language} language
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.language = 0;

                /**
                 * Creates a new SettingsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {waiternow.common.UserProto.ISettingsProto=} [properties] Properties to set
                 * @returns {waiternow.common.UserProto.SettingsProto} SettingsProto instance
                 */
                SettingsProto.create = function create(properties) {
                    return new SettingsProto(properties);
                };

                /**
                 * Encodes the specified SettingsProto message. Does not implicitly {@link waiternow.common.UserProto.SettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {waiternow.common.UserProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.preferredContactMedium != null && Object.hasOwnProperty.call(message, "preferredContactMedium"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.preferredContactMedium);
                    if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.language);
                    return writer;
                };

                /**
                 * Encodes the specified SettingsProto message, length delimited. Does not implicitly {@link waiternow.common.UserProto.SettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {waiternow.common.UserProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UserProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserProto.SettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.preferredContactMedium = reader.int32();
                                break;
                            }
                        case 2: {
                                message.language = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UserProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SettingsProto message.
                 * @function verify
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.preferredContactMedium != null && message.hasOwnProperty("preferredContactMedium"))
                        switch (message.preferredContactMedium) {
                        default:
                            return "preferredContactMedium: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.language != null && message.hasOwnProperty("language"))
                        switch (message.language) {
                        default:
                            return "language: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a SettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UserProto.SettingsProto} SettingsProto
                 */
                SettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UserProto.SettingsProto)
                        return object;
                    var message = new $root.waiternow.common.UserProto.SettingsProto();
                    switch (object.preferredContactMedium) {
                    default:
                        if (typeof object.preferredContactMedium === "number") {
                            message.preferredContactMedium = object.preferredContactMedium;
                            break;
                        }
                        break;
                    case "CONTACT_MEDIUM_UNSPECIFIED":
                    case 0:
                        message.preferredContactMedium = 0;
                        break;
                    case "EMAIL":
                    case 1:
                        message.preferredContactMedium = 1;
                        break;
                    case "PHONE_CALL":
                    case 2:
                        message.preferredContactMedium = 2;
                        break;
                    case "TEXT_MESSAGE":
                    case 3:
                        message.preferredContactMedium = 3;
                        break;
                    case "WHATS_APP":
                    case 4:
                        message.preferredContactMedium = 4;
                        break;
                    }
                    switch (object.language) {
                    default:
                        if (typeof object.language === "number") {
                            message.language = object.language;
                            break;
                        }
                        break;
                    case "LANGUAGE_UNSPECIFIED":
                    case 0:
                        message.language = 0;
                        break;
                    case "ENGLISH":
                    case 1:
                        message.language = 1;
                        break;
                    case "SPANISH":
                    case 2:
                        message.language = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {waiternow.common.UserProto.SettingsProto} message SettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.preferredContactMedium = options.enums === String ? "CONTACT_MEDIUM_UNSPECIFIED" : 0;
                        object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                    }
                    if (message.preferredContactMedium != null && message.hasOwnProperty("preferredContactMedium"))
                        object.preferredContactMedium = options.enums === String ? $root.waiternow.common.ContactMedium[message.preferredContactMedium] === undefined ? message.preferredContactMedium : $root.waiternow.common.ContactMedium[message.preferredContactMedium] : message.preferredContactMedium;
                    if (message.language != null && message.hasOwnProperty("language"))
                        object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                    return object;
                };

                /**
                 * Converts this SettingsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SettingsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.UserProto.SettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UserProto.SettingsProto";
                };

                return SettingsProto;
            })();

            UserProto.MetadataProto = (function() {

                /**
                 * Properties of a MetadataProto.
                 * @memberof waiternow.common.UserProto
                 * @interface IMetadataProto
                 * @property {waiternow.common.RecordType|null} [recordType] MetadataProto recordType
                 * @property {waiternow.common.ReachMedium|null} [reachMedium] MetadataProto reachMedium
                 */

                /**
                 * Constructs a new MetadataProto.
                 * @memberof waiternow.common.UserProto
                 * @classdesc Represents a MetadataProto.
                 * @implements IMetadataProto
                 * @constructor
                 * @param {waiternow.common.UserProto.IMetadataProto=} [properties] Properties to set
                 */
                function MetadataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MetadataProto recordType.
                 * @member {waiternow.common.RecordType} recordType
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.recordType = 0;

                /**
                 * MetadataProto reachMedium.
                 * @member {waiternow.common.ReachMedium} reachMedium
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.reachMedium = 0;

                /**
                 * Creates a new MetadataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {waiternow.common.UserProto.IMetadataProto=} [properties] Properties to set
                 * @returns {waiternow.common.UserProto.MetadataProto} MetadataProto instance
                 */
                MetadataProto.create = function create(properties) {
                    return new MetadataProto(properties);
                };

                /**
                 * Encodes the specified MetadataProto message. Does not implicitly {@link waiternow.common.UserProto.MetadataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {waiternow.common.UserProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.recordType != null && Object.hasOwnProperty.call(message, "recordType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.recordType);
                    if (message.reachMedium != null && Object.hasOwnProperty.call(message, "reachMedium"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.reachMedium);
                    return writer;
                };

                /**
                 * Encodes the specified MetadataProto message, length delimited. Does not implicitly {@link waiternow.common.UserProto.MetadataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {waiternow.common.UserProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UserProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserProto.MetadataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.recordType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.reachMedium = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UserProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MetadataProto message.
                 * @function verify
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MetadataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.recordType != null && message.hasOwnProperty("recordType"))
                        switch (message.recordType) {
                        default:
                            return "recordType: enum value expected";
                        case 0:
                        case 1:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.reachMedium != null && message.hasOwnProperty("reachMedium"))
                        switch (message.reachMedium) {
                        default:
                            return "reachMedium: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a MetadataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UserProto.MetadataProto} MetadataProto
                 */
                MetadataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UserProto.MetadataProto)
                        return object;
                    var message = new $root.waiternow.common.UserProto.MetadataProto();
                    switch (object.recordType) {
                    default:
                        if (typeof object.recordType === "number") {
                            message.recordType = object.recordType;
                            break;
                        }
                        break;
                    case "RECORD_TYPE_UNSPECIFIED":
                    case 0:
                        message.recordType = 0;
                        break;
                    case "REGULAR":
                    case 1:
                        message.recordType = 1;
                        break;
                    case "PRE_CREATED":
                    case 3:
                        message.recordType = 3;
                        break;
                    case "TEST":
                    case 4:
                        message.recordType = 4;
                        break;
                    case "DEMO":
                    case 5:
                        message.recordType = 5;
                        break;
                    }
                    switch (object.reachMedium) {
                    default:
                        if (typeof object.reachMedium === "number") {
                            message.reachMedium = object.reachMedium;
                            break;
                        }
                        break;
                    case "REACH_MEDIUM_UNDEFINED":
                    case 0:
                        message.reachMedium = 0;
                        break;
                    case "GOOGLE":
                    case 1:
                        message.reachMedium = 1;
                        break;
                    case "FACEBOOK":
                    case 2:
                        message.reachMedium = 2;
                        break;
                    case "INSTRAGRAM":
                    case 3:
                        message.reachMedium = 3;
                        break;
                    case "LINKED_IN":
                    case 4:
                        message.reachMedium = 4;
                        break;
                    case "RECOMMENDATION":
                    case 5:
                        message.reachMedium = 5;
                        break;
                    case "SALESPERSON":
                    case 6:
                        message.reachMedium = 6;
                        break;
                    case "OTHER":
                    case 7:
                        message.reachMedium = 7;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MetadataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {waiternow.common.UserProto.MetadataProto} message MetadataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MetadataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.recordType = options.enums === String ? "RECORD_TYPE_UNSPECIFIED" : 0;
                        object.reachMedium = options.enums === String ? "REACH_MEDIUM_UNDEFINED" : 0;
                    }
                    if (message.recordType != null && message.hasOwnProperty("recordType"))
                        object.recordType = options.enums === String ? $root.waiternow.common.RecordType[message.recordType] === undefined ? message.recordType : $root.waiternow.common.RecordType[message.recordType] : message.recordType;
                    if (message.reachMedium != null && message.hasOwnProperty("reachMedium"))
                        object.reachMedium = options.enums === String ? $root.waiternow.common.ReachMedium[message.reachMedium] === undefined ? message.reachMedium : $root.waiternow.common.ReachMedium[message.reachMedium] : message.reachMedium;
                    return object;
                };

                /**
                 * Converts this MetadataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MetadataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MetadataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.UserProto.MetadataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MetadataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UserProto.MetadataProto";
                };

                return MetadataProto;
            })();

            return UserProto;
        })();

        common.UserRoleProto = (function() {

            /**
             * Properties of a UserRoleProto.
             * @memberof waiternow.common
             * @interface IUserRoleProto
             * @property {string|null} [userId] UserRoleProto userId
             * @property {waiternow.common.UserRoleProto.IResourceProto|null} [resource] UserRoleProto resource
             * @property {waiternow.common.Role|null} [role] UserRoleProto role
             * @property {google.protobuf.ITimestamp|null} [creationTime] UserRoleProto creationTime
             */

            /**
             * Constructs a new UserRoleProto.
             * @memberof waiternow.common
             * @classdesc Represents a UserRoleProto.
             * @implements IUserRoleProto
             * @constructor
             * @param {waiternow.common.IUserRoleProto=} [properties] Properties to set
             */
            function UserRoleProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * UserRoleProto userId.
             * @member {string} userId
             * @memberof waiternow.common.UserRoleProto
             * @instance
             */
            UserRoleProto.prototype.userId = "";

            /**
             * UserRoleProto resource.
             * @member {waiternow.common.UserRoleProto.IResourceProto|null|undefined} resource
             * @memberof waiternow.common.UserRoleProto
             * @instance
             */
            UserRoleProto.prototype.resource = null;

            /**
             * UserRoleProto role.
             * @member {waiternow.common.Role} role
             * @memberof waiternow.common.UserRoleProto
             * @instance
             */
            UserRoleProto.prototype.role = 0;

            /**
             * UserRoleProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.UserRoleProto
             * @instance
             */
            UserRoleProto.prototype.creationTime = null;

            /**
             * Creates a new UserRoleProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {waiternow.common.IUserRoleProto=} [properties] Properties to set
             * @returns {waiternow.common.UserRoleProto} UserRoleProto instance
             */
            UserRoleProto.create = function create(properties) {
                return new UserRoleProto(properties);
            };

            /**
             * Encodes the specified UserRoleProto message. Does not implicitly {@link waiternow.common.UserRoleProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {waiternow.common.IUserRoleProto} message UserRoleProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserRoleProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.resource != null && Object.hasOwnProperty.call(message, "resource"))
                    $root.waiternow.common.UserRoleProto.ResourceProto.encode(message.resource, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.role != null && Object.hasOwnProperty.call(message, "role"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.role);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified UserRoleProto message, length delimited. Does not implicitly {@link waiternow.common.UserRoleProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {waiternow.common.IUserRoleProto} message UserRoleProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UserRoleProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a UserRoleProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UserRoleProto} UserRoleProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserRoleProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserRoleProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            message.resource = $root.waiternow.common.UserRoleProto.ResourceProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.role = reader.int32();
                            break;
                        }
                    case 4: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a UserRoleProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UserRoleProto} UserRoleProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UserRoleProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a UserRoleProto message.
             * @function verify
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UserRoleProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.resource != null && message.hasOwnProperty("resource")) {
                    var error = $root.waiternow.common.UserRoleProto.ResourceProto.verify(message.resource);
                    if (error)
                        return "resource." + error;
                }
                if (message.role != null && message.hasOwnProperty("role"))
                    switch (message.role) {
                    default:
                        return "role: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                return null;
            };

            /**
             * Creates a UserRoleProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UserRoleProto} UserRoleProto
             */
            UserRoleProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UserRoleProto)
                    return object;
                var message = new $root.waiternow.common.UserRoleProto();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.resource != null) {
                    if (typeof object.resource !== "object")
                        throw TypeError(".waiternow.common.UserRoleProto.resource: object expected");
                    message.resource = $root.waiternow.common.UserRoleProto.ResourceProto.fromObject(object.resource);
                }
                switch (object.role) {
                default:
                    if (typeof object.role === "number") {
                        message.role = object.role;
                        break;
                    }
                    break;
                case "ROLE_UNSPECIFIED":
                case 0:
                    message.role = 0;
                    break;
                case "OWNER":
                case 1:
                    message.role = 1;
                    break;
                case "ADMIN":
                case 2:
                    message.role = 2;
                    break;
                case "VIEWER":
                case 3:
                    message.role = 3;
                    break;
                }
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.UserRoleProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                return message;
            };

            /**
             * Creates a plain object from a UserRoleProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {waiternow.common.UserRoleProto} message UserRoleProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UserRoleProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.userId = "";
                    object.resource = null;
                    object.role = options.enums === String ? "ROLE_UNSPECIFIED" : 0;
                    object.creationTime = null;
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.resource != null && message.hasOwnProperty("resource"))
                    object.resource = $root.waiternow.common.UserRoleProto.ResourceProto.toObject(message.resource, options);
                if (message.role != null && message.hasOwnProperty("role"))
                    object.role = options.enums === String ? $root.waiternow.common.Role[message.role] === undefined ? message.role : $root.waiternow.common.Role[message.role] : message.role;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                return object;
            };

            /**
             * Converts this UserRoleProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UserRoleProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UserRoleProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UserRoleProto
             * @function getTypeUrl
             * @memberof waiternow.common.UserRoleProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UserRoleProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UserRoleProto";
            };

            UserRoleProto.ResourceProto = (function() {

                /**
                 * Properties of a ResourceProto.
                 * @memberof waiternow.common.UserRoleProto
                 * @interface IResourceProto
                 * @property {string|null} [businessId] ResourceProto businessId
                 * @property {string|null} [locationId] ResourceProto locationId
                 */

                /**
                 * Constructs a new ResourceProto.
                 * @memberof waiternow.common.UserRoleProto
                 * @classdesc Represents a ResourceProto.
                 * @implements IResourceProto
                 * @constructor
                 * @param {waiternow.common.UserRoleProto.IResourceProto=} [properties] Properties to set
                 */
                function ResourceProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ResourceProto businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @instance
                 */
                ResourceProto.prototype.businessId = null;

                /**
                 * ResourceProto locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @instance
                 */
                ResourceProto.prototype.locationId = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * ResourceProto resource.
                 * @member {"businessId"|"locationId"|undefined} resource
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @instance
                 */
                Object.defineProperty(ResourceProto.prototype, "resource", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new ResourceProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {waiternow.common.UserRoleProto.IResourceProto=} [properties] Properties to set
                 * @returns {waiternow.common.UserRoleProto.ResourceProto} ResourceProto instance
                 */
                ResourceProto.create = function create(properties) {
                    return new ResourceProto(properties);
                };

                /**
                 * Encodes the specified ResourceProto message. Does not implicitly {@link waiternow.common.UserRoleProto.ResourceProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {waiternow.common.UserRoleProto.IResourceProto} message ResourceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResourceProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified ResourceProto message, length delimited. Does not implicitly {@link waiternow.common.UserRoleProto.ResourceProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {waiternow.common.UserRoleProto.IResourceProto} message ResourceProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ResourceProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ResourceProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UserRoleProto.ResourceProto} ResourceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResourceProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UserRoleProto.ResourceProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 2: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 3: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ResourceProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UserRoleProto.ResourceProto} ResourceProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ResourceProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ResourceProto message.
                 * @function verify
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ResourceProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.resource = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.resource === 1)
                            return "resource: multiple values";
                        properties.resource = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a ResourceProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UserRoleProto.ResourceProto} ResourceProto
                 */
                ResourceProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UserRoleProto.ResourceProto)
                        return object;
                    var message = new $root.waiternow.common.UserRoleProto.ResourceProto();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a ResourceProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {waiternow.common.UserRoleProto.ResourceProto} message ResourceProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ResourceProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.resource = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.resource = "locationId";
                    }
                    return object;
                };

                /**
                 * Converts this ResourceProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ResourceProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ResourceProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.UserRoleProto.ResourceProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ResourceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UserRoleProto.ResourceProto";
                };

                return ResourceProto;
            })();

            return UserRoleProto;
        })();

        /**
         * Role enum.
         * @name waiternow.common.Role
         * @enum {number}
         * @property {number} ROLE_UNSPECIFIED=0 ROLE_UNSPECIFIED value
         * @property {number} OWNER=1 OWNER value
         * @property {number} ADMIN=2 ADMIN value
         * @property {number} VIEWER=3 VIEWER value
         */
        common.Role = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "ROLE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "OWNER"] = 1;
            values[valuesById[2] = "ADMIN"] = 2;
            values[valuesById[3] = "VIEWER"] = 3;
            return values;
        })();

        common.EmailVerificationProto = (function() {

            /**
             * Properties of an EmailVerificationProto.
             * @memberof waiternow.common
             * @interface IEmailVerificationProto
             * @property {string|null} [userId] EmailVerificationProto userId
             * @property {google.protobuf.ITimestamp|null} [creationTime] EmailVerificationProto creationTime
             */

            /**
             * Constructs a new EmailVerificationProto.
             * @memberof waiternow.common
             * @classdesc Represents an EmailVerificationProto.
             * @implements IEmailVerificationProto
             * @constructor
             * @param {waiternow.common.IEmailVerificationProto=} [properties] Properties to set
             */
            function EmailVerificationProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EmailVerificationProto userId.
             * @member {string} userId
             * @memberof waiternow.common.EmailVerificationProto
             * @instance
             */
            EmailVerificationProto.prototype.userId = "";

            /**
             * EmailVerificationProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.EmailVerificationProto
             * @instance
             */
            EmailVerificationProto.prototype.creationTime = null;

            /**
             * Creates a new EmailVerificationProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {waiternow.common.IEmailVerificationProto=} [properties] Properties to set
             * @returns {waiternow.common.EmailVerificationProto} EmailVerificationProto instance
             */
            EmailVerificationProto.create = function create(properties) {
                return new EmailVerificationProto(properties);
            };

            /**
             * Encodes the specified EmailVerificationProto message. Does not implicitly {@link waiternow.common.EmailVerificationProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {waiternow.common.IEmailVerificationProto} message EmailVerificationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailVerificationProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EmailVerificationProto message, length delimited. Does not implicitly {@link waiternow.common.EmailVerificationProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {waiternow.common.IEmailVerificationProto} message EmailVerificationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailVerificationProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EmailVerificationProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EmailVerificationProto} EmailVerificationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailVerificationProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EmailVerificationProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EmailVerificationProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EmailVerificationProto} EmailVerificationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailVerificationProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EmailVerificationProto message.
             * @function verify
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EmailVerificationProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                return null;
            };

            /**
             * Creates an EmailVerificationProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EmailVerificationProto} EmailVerificationProto
             */
            EmailVerificationProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EmailVerificationProto)
                    return object;
                var message = new $root.waiternow.common.EmailVerificationProto();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.EmailVerificationProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                return message;
            };

            /**
             * Creates a plain object from an EmailVerificationProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {waiternow.common.EmailVerificationProto} message EmailVerificationProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EmailVerificationProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.userId = "";
                    object.creationTime = null;
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                return object;
            };

            /**
             * Converts this EmailVerificationProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EmailVerificationProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EmailVerificationProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EmailVerificationProto
             * @function getTypeUrl
             * @memberof waiternow.common.EmailVerificationProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EmailVerificationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EmailVerificationProto";
            };

            return EmailVerificationProto;
        })();

        common.EmailUpdateVerificationProto = (function() {

            /**
             * Properties of an EmailUpdateVerificationProto.
             * @memberof waiternow.common
             * @interface IEmailUpdateVerificationProto
             * @property {string|null} [userId] EmailUpdateVerificationProto userId
             * @property {string|null} [newEmail] EmailUpdateVerificationProto newEmail
             * @property {google.protobuf.ITimestamp|null} [creationTime] EmailUpdateVerificationProto creationTime
             */

            /**
             * Constructs a new EmailUpdateVerificationProto.
             * @memberof waiternow.common
             * @classdesc Represents an EmailUpdateVerificationProto.
             * @implements IEmailUpdateVerificationProto
             * @constructor
             * @param {waiternow.common.IEmailUpdateVerificationProto=} [properties] Properties to set
             */
            function EmailUpdateVerificationProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * EmailUpdateVerificationProto userId.
             * @member {string} userId
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @instance
             */
            EmailUpdateVerificationProto.prototype.userId = "";

            /**
             * EmailUpdateVerificationProto newEmail.
             * @member {string} newEmail
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @instance
             */
            EmailUpdateVerificationProto.prototype.newEmail = "";

            /**
             * EmailUpdateVerificationProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @instance
             */
            EmailUpdateVerificationProto.prototype.creationTime = null;

            /**
             * Creates a new EmailUpdateVerificationProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {waiternow.common.IEmailUpdateVerificationProto=} [properties] Properties to set
             * @returns {waiternow.common.EmailUpdateVerificationProto} EmailUpdateVerificationProto instance
             */
            EmailUpdateVerificationProto.create = function create(properties) {
                return new EmailUpdateVerificationProto(properties);
            };

            /**
             * Encodes the specified EmailUpdateVerificationProto message. Does not implicitly {@link waiternow.common.EmailUpdateVerificationProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {waiternow.common.IEmailUpdateVerificationProto} message EmailUpdateVerificationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailUpdateVerificationProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.newEmail != null && Object.hasOwnProperty.call(message, "newEmail"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.newEmail);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified EmailUpdateVerificationProto message, length delimited. Does not implicitly {@link waiternow.common.EmailUpdateVerificationProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {waiternow.common.IEmailUpdateVerificationProto} message EmailUpdateVerificationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EmailUpdateVerificationProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EmailUpdateVerificationProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EmailUpdateVerificationProto} EmailUpdateVerificationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailUpdateVerificationProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EmailUpdateVerificationProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            message.newEmail = reader.string();
                            break;
                        }
                    case 3: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EmailUpdateVerificationProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EmailUpdateVerificationProto} EmailUpdateVerificationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EmailUpdateVerificationProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EmailUpdateVerificationProto message.
             * @function verify
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EmailUpdateVerificationProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                    if (!$util.isString(message.newEmail))
                        return "newEmail: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                return null;
            };

            /**
             * Creates an EmailUpdateVerificationProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EmailUpdateVerificationProto} EmailUpdateVerificationProto
             */
            EmailUpdateVerificationProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EmailUpdateVerificationProto)
                    return object;
                var message = new $root.waiternow.common.EmailUpdateVerificationProto();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.newEmail != null)
                    message.newEmail = String(object.newEmail);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.EmailUpdateVerificationProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                return message;
            };

            /**
             * Creates a plain object from an EmailUpdateVerificationProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {waiternow.common.EmailUpdateVerificationProto} message EmailUpdateVerificationProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EmailUpdateVerificationProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.userId = "";
                    object.newEmail = "";
                    object.creationTime = null;
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                    object.newEmail = message.newEmail;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                return object;
            };

            /**
             * Converts this EmailUpdateVerificationProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EmailUpdateVerificationProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EmailUpdateVerificationProto
             * @function getTypeUrl
             * @memberof waiternow.common.EmailUpdateVerificationProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EmailUpdateVerificationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EmailUpdateVerificationProto";
            };

            return EmailUpdateVerificationProto;
        })();

        common.ResetPasswordAuthenticityProto = (function() {

            /**
             * Properties of a ResetPasswordAuthenticityProto.
             * @memberof waiternow.common
             * @interface IResetPasswordAuthenticityProto
             * @property {string|null} [userId] ResetPasswordAuthenticityProto userId
             * @property {google.protobuf.ITimestamp|null} [creationTime] ResetPasswordAuthenticityProto creationTime
             */

            /**
             * Constructs a new ResetPasswordAuthenticityProto.
             * @memberof waiternow.common
             * @classdesc Represents a ResetPasswordAuthenticityProto.
             * @implements IResetPasswordAuthenticityProto
             * @constructor
             * @param {waiternow.common.IResetPasswordAuthenticityProto=} [properties] Properties to set
             */
            function ResetPasswordAuthenticityProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ResetPasswordAuthenticityProto userId.
             * @member {string} userId
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @instance
             */
            ResetPasswordAuthenticityProto.prototype.userId = "";

            /**
             * ResetPasswordAuthenticityProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @instance
             */
            ResetPasswordAuthenticityProto.prototype.creationTime = null;

            /**
             * Creates a new ResetPasswordAuthenticityProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {waiternow.common.IResetPasswordAuthenticityProto=} [properties] Properties to set
             * @returns {waiternow.common.ResetPasswordAuthenticityProto} ResetPasswordAuthenticityProto instance
             */
            ResetPasswordAuthenticityProto.create = function create(properties) {
                return new ResetPasswordAuthenticityProto(properties);
            };

            /**
             * Encodes the specified ResetPasswordAuthenticityProto message. Does not implicitly {@link waiternow.common.ResetPasswordAuthenticityProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {waiternow.common.IResetPasswordAuthenticityProto} message ResetPasswordAuthenticityProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetPasswordAuthenticityProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ResetPasswordAuthenticityProto message, length delimited. Does not implicitly {@link waiternow.common.ResetPasswordAuthenticityProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {waiternow.common.IResetPasswordAuthenticityProto} message ResetPasswordAuthenticityProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetPasswordAuthenticityProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ResetPasswordAuthenticityProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ResetPasswordAuthenticityProto} ResetPasswordAuthenticityProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetPasswordAuthenticityProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ResetPasswordAuthenticityProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.userId = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ResetPasswordAuthenticityProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ResetPasswordAuthenticityProto} ResetPasswordAuthenticityProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetPasswordAuthenticityProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ResetPasswordAuthenticityProto message.
             * @function verify
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResetPasswordAuthenticityProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                return null;
            };

            /**
             * Creates a ResetPasswordAuthenticityProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ResetPasswordAuthenticityProto} ResetPasswordAuthenticityProto
             */
            ResetPasswordAuthenticityProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ResetPasswordAuthenticityProto)
                    return object;
                var message = new $root.waiternow.common.ResetPasswordAuthenticityProto();
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.ResetPasswordAuthenticityProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                return message;
            };

            /**
             * Creates a plain object from a ResetPasswordAuthenticityProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {waiternow.common.ResetPasswordAuthenticityProto} message ResetPasswordAuthenticityProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResetPasswordAuthenticityProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.userId = "";
                    object.creationTime = null;
                }
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                return object;
            };

            /**
             * Converts this ResetPasswordAuthenticityProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResetPasswordAuthenticityProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ResetPasswordAuthenticityProto
             * @function getTypeUrl
             * @memberof waiternow.common.ResetPasswordAuthenticityProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResetPasswordAuthenticityProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ResetPasswordAuthenticityProto";
            };

            return ResetPasswordAuthenticityProto;
        })();

        common.CredentialsProto = (function() {

            /**
             * Properties of a CredentialsProto.
             * @memberof waiternow.common
             * @interface ICredentialsProto
             * @property {string|null} [email] CredentialsProto email
             * @property {string|null} [passwordHash] CredentialsProto passwordHash
             * @property {google.protobuf.ITimestamp|null} [creationTime] CredentialsProto creationTime
             * @property {google.protobuf.IDuration|null} [expiration] CredentialsProto expiration
             */

            /**
             * Constructs a new CredentialsProto.
             * @memberof waiternow.common
             * @classdesc Represents a CredentialsProto.
             * @implements ICredentialsProto
             * @constructor
             * @param {waiternow.common.ICredentialsProto=} [properties] Properties to set
             */
            function CredentialsProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CredentialsProto email.
             * @member {string} email
             * @memberof waiternow.common.CredentialsProto
             * @instance
             */
            CredentialsProto.prototype.email = "";

            /**
             * CredentialsProto passwordHash.
             * @member {string} passwordHash
             * @memberof waiternow.common.CredentialsProto
             * @instance
             */
            CredentialsProto.prototype.passwordHash = "";

            /**
             * CredentialsProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.CredentialsProto
             * @instance
             */
            CredentialsProto.prototype.creationTime = null;

            /**
             * CredentialsProto expiration.
             * @member {google.protobuf.IDuration|null|undefined} expiration
             * @memberof waiternow.common.CredentialsProto
             * @instance
             */
            CredentialsProto.prototype.expiration = null;

            /**
             * Creates a new CredentialsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {waiternow.common.ICredentialsProto=} [properties] Properties to set
             * @returns {waiternow.common.CredentialsProto} CredentialsProto instance
             */
            CredentialsProto.create = function create(properties) {
                return new CredentialsProto(properties);
            };

            /**
             * Encodes the specified CredentialsProto message. Does not implicitly {@link waiternow.common.CredentialsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {waiternow.common.ICredentialsProto} message CredentialsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CredentialsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                if (message.passwordHash != null && Object.hasOwnProperty.call(message, "passwordHash"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.passwordHash);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                    $root.google.protobuf.Duration.encode(message.expiration, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CredentialsProto message, length delimited. Does not implicitly {@link waiternow.common.CredentialsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {waiternow.common.ICredentialsProto} message CredentialsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CredentialsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CredentialsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CredentialsProto} CredentialsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CredentialsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CredentialsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.email = reader.string();
                            break;
                        }
                    case 2: {
                            message.passwordHash = reader.string();
                            break;
                        }
                    case 3: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.expiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CredentialsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CredentialsProto} CredentialsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CredentialsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CredentialsProto message.
             * @function verify
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CredentialsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.passwordHash != null && message.hasOwnProperty("passwordHash"))
                    if (!$util.isString(message.passwordHash))
                        return "passwordHash: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.expiration != null && message.hasOwnProperty("expiration")) {
                    var error = $root.google.protobuf.Duration.verify(message.expiration);
                    if (error)
                        return "expiration." + error;
                }
                return null;
            };

            /**
             * Creates a CredentialsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CredentialsProto} CredentialsProto
             */
            CredentialsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CredentialsProto)
                    return object;
                var message = new $root.waiternow.common.CredentialsProto();
                if (object.email != null)
                    message.email = String(object.email);
                if (object.passwordHash != null)
                    message.passwordHash = String(object.passwordHash);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.CredentialsProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.expiration != null) {
                    if (typeof object.expiration !== "object")
                        throw TypeError(".waiternow.common.CredentialsProto.expiration: object expected");
                    message.expiration = $root.google.protobuf.Duration.fromObject(object.expiration);
                }
                return message;
            };

            /**
             * Creates a plain object from a CredentialsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {waiternow.common.CredentialsProto} message CredentialsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CredentialsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.email = "";
                    object.passwordHash = "";
                    object.creationTime = null;
                    object.expiration = null;
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.passwordHash != null && message.hasOwnProperty("passwordHash"))
                    object.passwordHash = message.passwordHash;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.expiration != null && message.hasOwnProperty("expiration"))
                    object.expiration = $root.google.protobuf.Duration.toObject(message.expiration, options);
                return object;
            };

            /**
             * Converts this CredentialsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CredentialsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CredentialsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CredentialsProto
             * @function getTypeUrl
             * @memberof waiternow.common.CredentialsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CredentialsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CredentialsProto";
            };

            return CredentialsProto;
        })();

        common.CampaignProto = (function() {

            /**
             * Properties of a CampaignProto.
             * @memberof waiternow.common
             * @interface ICampaignProto
             * @property {string|null} [id] CampaignProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] CampaignProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] CampaignProto lastUpdateTime
             * @property {string|null} [businessId] CampaignProto businessId
             * @property {string|null} [locationId] CampaignProto locationId
             * @property {string|null} [code] CampaignProto code
             * @property {waiternow.common.ITextProto|null} [description] CampaignProto description
             * @property {waiternow.common.IDerivedQuantityConfigProto|null} [discount] CampaignProto discount
             * @property {Array.<waiternow.common.CampaignProto.IConditionProto>|null} [conditions] CampaignProto conditions
             * @property {boolean|null} [isActive] CampaignProto isActive
             */

            /**
             * Constructs a new CampaignProto.
             * @memberof waiternow.common
             * @classdesc Represents a CampaignProto.
             * @implements ICampaignProto
             * @constructor
             * @param {waiternow.common.ICampaignProto=} [properties] Properties to set
             */
            function CampaignProto(properties) {
                this.conditions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CampaignProto id.
             * @member {string} id
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.id = "";

            /**
             * CampaignProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.creationTime = null;

            /**
             * CampaignProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.lastUpdateTime = null;

            /**
             * CampaignProto businessId.
             * @member {string|null|undefined} businessId
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.businessId = null;

            /**
             * CampaignProto locationId.
             * @member {string|null|undefined} locationId
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.locationId = null;

            /**
             * CampaignProto code.
             * @member {string} code
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.code = "";

            /**
             * CampaignProto description.
             * @member {waiternow.common.ITextProto|null|undefined} description
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.description = null;

            /**
             * CampaignProto discount.
             * @member {waiternow.common.IDerivedQuantityConfigProto|null|undefined} discount
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.discount = null;

            /**
             * CampaignProto conditions.
             * @member {Array.<waiternow.common.CampaignProto.IConditionProto>} conditions
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.conditions = $util.emptyArray;

            /**
             * CampaignProto isActive.
             * @member {boolean} isActive
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            CampaignProto.prototype.isActive = false;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CampaignProto campaignScope.
             * @member {"businessId"|"locationId"|undefined} campaignScope
             * @memberof waiternow.common.CampaignProto
             * @instance
             */
            Object.defineProperty(CampaignProto.prototype, "campaignScope", {
                get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CampaignProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {waiternow.common.ICampaignProto=} [properties] Properties to set
             * @returns {waiternow.common.CampaignProto} CampaignProto instance
             */
            CampaignProto.create = function create(properties) {
                return new CampaignProto(properties);
            };

            /**
             * Encodes the specified CampaignProto message. Does not implicitly {@link waiternow.common.CampaignProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {waiternow.common.ICampaignProto} message CampaignProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.businessId);
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.locationId);
                if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.code);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    $root.waiternow.common.TextProto.encode(message.description, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.discount != null && Object.hasOwnProperty.call(message, "discount"))
                    $root.waiternow.common.DerivedQuantityConfigProto.encode(message.discount, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.conditions != null && message.conditions.length)
                    for (var i = 0; i < message.conditions.length; ++i)
                        $root.waiternow.common.CampaignProto.ConditionProto.encode(message.conditions[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.isActive != null && Object.hasOwnProperty.call(message, "isActive"))
                    writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isActive);
                return writer;
            };

            /**
             * Encodes the specified CampaignProto message, length delimited. Does not implicitly {@link waiternow.common.CampaignProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {waiternow.common.ICampaignProto} message CampaignProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CampaignProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CampaignProto} CampaignProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CampaignProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.businessId = reader.string();
                            break;
                        }
                    case 5: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 6: {
                            message.code = reader.string();
                            break;
                        }
                    case 7: {
                            message.description = $root.waiternow.common.TextProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.discount = $root.waiternow.common.DerivedQuantityConfigProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            if (!(message.conditions && message.conditions.length))
                                message.conditions = [];
                            message.conditions.push($root.waiternow.common.CampaignProto.ConditionProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            message.isActive = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CampaignProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CampaignProto} CampaignProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CampaignProto message.
             * @function verify
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CampaignProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.businessId != null && message.hasOwnProperty("businessId")) {
                    properties.campaignScope = 1;
                    if (!$util.isString(message.businessId))
                        return "businessId: string expected";
                }
                if (message.locationId != null && message.hasOwnProperty("locationId")) {
                    if (properties.campaignScope === 1)
                        return "campaignScope: multiple values";
                    properties.campaignScope = 1;
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    if (!$util.isString(message.code))
                        return "code: string expected";
                if (message.description != null && message.hasOwnProperty("description")) {
                    var error = $root.waiternow.common.TextProto.verify(message.description);
                    if (error)
                        return "description." + error;
                }
                if (message.discount != null && message.hasOwnProperty("discount")) {
                    var error = $root.waiternow.common.DerivedQuantityConfigProto.verify(message.discount);
                    if (error)
                        return "discount." + error;
                }
                if (message.conditions != null && message.hasOwnProperty("conditions")) {
                    if (!Array.isArray(message.conditions))
                        return "conditions: array expected";
                    for (var i = 0; i < message.conditions.length; ++i) {
                        var error = $root.waiternow.common.CampaignProto.ConditionProto.verify(message.conditions[i]);
                        if (error)
                            return "conditions." + error;
                    }
                }
                if (message.isActive != null && message.hasOwnProperty("isActive"))
                    if (typeof message.isActive !== "boolean")
                        return "isActive: boolean expected";
                return null;
            };

            /**
             * Creates a CampaignProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CampaignProto} CampaignProto
             */
            CampaignProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CampaignProto)
                    return object;
                var message = new $root.waiternow.common.CampaignProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.CampaignProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.CampaignProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.businessId != null)
                    message.businessId = String(object.businessId);
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.code != null)
                    message.code = String(object.code);
                if (object.description != null) {
                    if (typeof object.description !== "object")
                        throw TypeError(".waiternow.common.CampaignProto.description: object expected");
                    message.description = $root.waiternow.common.TextProto.fromObject(object.description);
                }
                if (object.discount != null) {
                    if (typeof object.discount !== "object")
                        throw TypeError(".waiternow.common.CampaignProto.discount: object expected");
                    message.discount = $root.waiternow.common.DerivedQuantityConfigProto.fromObject(object.discount);
                }
                if (object.conditions) {
                    if (!Array.isArray(object.conditions))
                        throw TypeError(".waiternow.common.CampaignProto.conditions: array expected");
                    message.conditions = [];
                    for (var i = 0; i < object.conditions.length; ++i) {
                        if (typeof object.conditions[i] !== "object")
                            throw TypeError(".waiternow.common.CampaignProto.conditions: object expected");
                        message.conditions[i] = $root.waiternow.common.CampaignProto.ConditionProto.fromObject(object.conditions[i]);
                    }
                }
                if (object.isActive != null)
                    message.isActive = Boolean(object.isActive);
                return message;
            };

            /**
             * Creates a plain object from a CampaignProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {waiternow.common.CampaignProto} message CampaignProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CampaignProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.conditions = [];
                if (options.defaults) {
                    object.id = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.code = "";
                    object.description = null;
                    object.discount = null;
                    object.isActive = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.businessId != null && message.hasOwnProperty("businessId")) {
                    object.businessId = message.businessId;
                    if (options.oneofs)
                        object.campaignScope = "businessId";
                }
                if (message.locationId != null && message.hasOwnProperty("locationId")) {
                    object.locationId = message.locationId;
                    if (options.oneofs)
                        object.campaignScope = "locationId";
                }
                if (message.code != null && message.hasOwnProperty("code"))
                    object.code = message.code;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = $root.waiternow.common.TextProto.toObject(message.description, options);
                if (message.discount != null && message.hasOwnProperty("discount"))
                    object.discount = $root.waiternow.common.DerivedQuantityConfigProto.toObject(message.discount, options);
                if (message.conditions && message.conditions.length) {
                    object.conditions = [];
                    for (var j = 0; j < message.conditions.length; ++j)
                        object.conditions[j] = $root.waiternow.common.CampaignProto.ConditionProto.toObject(message.conditions[j], options);
                }
                if (message.isActive != null && message.hasOwnProperty("isActive"))
                    object.isActive = message.isActive;
                return object;
            };

            /**
             * Converts this CampaignProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CampaignProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CampaignProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CampaignProto
             * @function getTypeUrl
             * @memberof waiternow.common.CampaignProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CampaignProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CampaignProto";
            };

            CampaignProto.ConditionProto = (function() {

                /**
                 * Properties of a ConditionProto.
                 * @memberof waiternow.common.CampaignProto
                 * @interface IConditionProto
                 * @property {waiternow.common.CampaignProto.ConditionProto.Rule|null} [rule] ConditionProto rule
                 * @property {waiternow.common.IScheduleProto|null} [schedule] ConditionProto schedule
                 */

                /**
                 * Constructs a new ConditionProto.
                 * @memberof waiternow.common.CampaignProto
                 * @classdesc Represents a ConditionProto.
                 * @implements IConditionProto
                 * @constructor
                 * @param {waiternow.common.CampaignProto.IConditionProto=} [properties] Properties to set
                 */
                function ConditionProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ConditionProto rule.
                 * @member {waiternow.common.CampaignProto.ConditionProto.Rule} rule
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @instance
                 */
                ConditionProto.prototype.rule = 0;

                /**
                 * ConditionProto schedule.
                 * @member {waiternow.common.IScheduleProto|null|undefined} schedule
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @instance
                 */
                ConditionProto.prototype.schedule = null;

                /**
                 * Creates a new ConditionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {waiternow.common.CampaignProto.IConditionProto=} [properties] Properties to set
                 * @returns {waiternow.common.CampaignProto.ConditionProto} ConditionProto instance
                 */
                ConditionProto.create = function create(properties) {
                    return new ConditionProto(properties);
                };

                /**
                 * Encodes the specified ConditionProto message. Does not implicitly {@link waiternow.common.CampaignProto.ConditionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {waiternow.common.CampaignProto.IConditionProto} message ConditionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConditionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.rule != null && Object.hasOwnProperty.call(message, "rule"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.rule);
                    if (message.schedule != null && Object.hasOwnProperty.call(message, "schedule"))
                        $root.waiternow.common.ScheduleProto.encode(message.schedule, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ConditionProto message, length delimited. Does not implicitly {@link waiternow.common.CampaignProto.ConditionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {waiternow.common.CampaignProto.IConditionProto} message ConditionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConditionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ConditionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CampaignProto.ConditionProto} ConditionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConditionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CampaignProto.ConditionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.rule = reader.int32();
                                break;
                            }
                        case 2: {
                                message.schedule = $root.waiternow.common.ScheduleProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ConditionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CampaignProto.ConditionProto} ConditionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConditionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ConditionProto message.
                 * @function verify
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConditionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.rule != null && message.hasOwnProperty("rule"))
                        switch (message.rule) {
                        default:
                            return "rule: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.schedule != null && message.hasOwnProperty("schedule")) {
                        var error = $root.waiternow.common.ScheduleProto.verify(message.schedule);
                        if (error)
                            return "schedule." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ConditionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CampaignProto.ConditionProto} ConditionProto
                 */
                ConditionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CampaignProto.ConditionProto)
                        return object;
                    var message = new $root.waiternow.common.CampaignProto.ConditionProto();
                    switch (object.rule) {
                    default:
                        if (typeof object.rule === "number") {
                            message.rule = object.rule;
                            break;
                        }
                        break;
                    case "RULE_UNSPECIFIED":
                    case 0:
                        message.rule = 0;
                        break;
                    case "FIRST_PURCHASE":
                    case 1:
                        message.rule = 1;
                        break;
                    case "EXCLUDE_ONLINE_ORDERS":
                    case 2:
                        message.rule = 2;
                        break;
                    }
                    if (object.schedule != null) {
                        if (typeof object.schedule !== "object")
                            throw TypeError(".waiternow.common.CampaignProto.ConditionProto.schedule: object expected");
                        message.schedule = $root.waiternow.common.ScheduleProto.fromObject(object.schedule);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ConditionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {waiternow.common.CampaignProto.ConditionProto} message ConditionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConditionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.rule = options.enums === String ? "RULE_UNSPECIFIED" : 0;
                        object.schedule = null;
                    }
                    if (message.rule != null && message.hasOwnProperty("rule"))
                        object.rule = options.enums === String ? $root.waiternow.common.CampaignProto.ConditionProto.Rule[message.rule] === undefined ? message.rule : $root.waiternow.common.CampaignProto.ConditionProto.Rule[message.rule] : message.rule;
                    if (message.schedule != null && message.hasOwnProperty("schedule"))
                        object.schedule = $root.waiternow.common.ScheduleProto.toObject(message.schedule, options);
                    return object;
                };

                /**
                 * Converts this ConditionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConditionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ConditionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CampaignProto.ConditionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConditionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CampaignProto.ConditionProto";
                };

                /**
                 * Rule enum.
                 * @name waiternow.common.CampaignProto.ConditionProto.Rule
                 * @enum {number}
                 * @property {number} RULE_UNSPECIFIED=0 RULE_UNSPECIFIED value
                 * @property {number} FIRST_PURCHASE=1 FIRST_PURCHASE value
                 * @property {number} EXCLUDE_ONLINE_ORDERS=2 EXCLUDE_ONLINE_ORDERS value
                 */
                ConditionProto.Rule = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "RULE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "FIRST_PURCHASE"] = 1;
                    values[valuesById[2] = "EXCLUDE_ONLINE_ORDERS"] = 2;
                    return values;
                })();

                return ConditionProto;
            })();

            return CampaignProto;
        })();

        common.CampaignsProto = (function() {

            /**
             * Properties of a CampaignsProto.
             * @memberof waiternow.common
             * @interface ICampaignsProto
             * @property {Array.<waiternow.common.ICampaignProto>|null} [campaigns] CampaignsProto campaigns
             * @property {string|null} [continuationToken] CampaignsProto continuationToken
             */

            /**
             * Constructs a new CampaignsProto.
             * @memberof waiternow.common
             * @classdesc Represents a CampaignsProto.
             * @implements ICampaignsProto
             * @constructor
             * @param {waiternow.common.ICampaignsProto=} [properties] Properties to set
             */
            function CampaignsProto(properties) {
                this.campaigns = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CampaignsProto campaigns.
             * @member {Array.<waiternow.common.ICampaignProto>} campaigns
             * @memberof waiternow.common.CampaignsProto
             * @instance
             */
            CampaignsProto.prototype.campaigns = $util.emptyArray;

            /**
             * CampaignsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.CampaignsProto
             * @instance
             */
            CampaignsProto.prototype.continuationToken = "";

            /**
             * Creates a new CampaignsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {waiternow.common.ICampaignsProto=} [properties] Properties to set
             * @returns {waiternow.common.CampaignsProto} CampaignsProto instance
             */
            CampaignsProto.create = function create(properties) {
                return new CampaignsProto(properties);
            };

            /**
             * Encodes the specified CampaignsProto message. Does not implicitly {@link waiternow.common.CampaignsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {waiternow.common.ICampaignsProto} message CampaignsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.campaigns != null && message.campaigns.length)
                    for (var i = 0; i < message.campaigns.length; ++i)
                        $root.waiternow.common.CampaignProto.encode(message.campaigns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified CampaignsProto message, length delimited. Does not implicitly {@link waiternow.common.CampaignsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {waiternow.common.ICampaignsProto} message CampaignsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CampaignsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CampaignsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CampaignsProto} CampaignsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CampaignsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.campaigns && message.campaigns.length))
                                message.campaigns = [];
                            message.campaigns.push($root.waiternow.common.CampaignProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CampaignsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CampaignsProto} CampaignsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CampaignsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CampaignsProto message.
             * @function verify
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CampaignsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.campaigns != null && message.hasOwnProperty("campaigns")) {
                    if (!Array.isArray(message.campaigns))
                        return "campaigns: array expected";
                    for (var i = 0; i < message.campaigns.length; ++i) {
                        var error = $root.waiternow.common.CampaignProto.verify(message.campaigns[i]);
                        if (error)
                            return "campaigns." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a CampaignsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CampaignsProto} CampaignsProto
             */
            CampaignsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CampaignsProto)
                    return object;
                var message = new $root.waiternow.common.CampaignsProto();
                if (object.campaigns) {
                    if (!Array.isArray(object.campaigns))
                        throw TypeError(".waiternow.common.CampaignsProto.campaigns: array expected");
                    message.campaigns = [];
                    for (var i = 0; i < object.campaigns.length; ++i) {
                        if (typeof object.campaigns[i] !== "object")
                            throw TypeError(".waiternow.common.CampaignsProto.campaigns: object expected");
                        message.campaigns[i] = $root.waiternow.common.CampaignProto.fromObject(object.campaigns[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a CampaignsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {waiternow.common.CampaignsProto} message CampaignsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CampaignsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.campaigns = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.campaigns && message.campaigns.length) {
                    object.campaigns = [];
                    for (var j = 0; j < message.campaigns.length; ++j)
                        object.campaigns[j] = $root.waiternow.common.CampaignProto.toObject(message.campaigns[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this CampaignsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CampaignsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CampaignsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CampaignsProto
             * @function getTypeUrl
             * @memberof waiternow.common.CampaignsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CampaignsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CampaignsProto";
            };

            return CampaignsProto;
        })();

        common.CachedDataProto = (function() {

            /**
             * Properties of a CachedDataProto.
             * @memberof waiternow.common
             * @interface ICachedDataProto
             * @property {waiternow.common.CachedDataProto.IIdProto|null} [id] CachedDataProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] CachedDataProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] CachedDataProto lastUpdateTime
             * @property {string|null} [dataString] CachedDataProto dataString
             * @property {boolean|null} [dataBool] CachedDataProto dataBool
             * @property {number|null} [dataInt] CachedDataProto dataInt
             * @property {number|null} [dataLong] CachedDataProto dataLong
             * @property {number|null} [dataUnsignedInt] CachedDataProto dataUnsignedInt
             * @property {number|null} [dataUnsignedLong] CachedDataProto dataUnsignedLong
             * @property {number|null} [dataFloat] CachedDataProto dataFloat
             * @property {number|null} [dataDouble] CachedDataProto dataDouble
             * @property {Uint8Array|null} [dataBytes] CachedDataProto dataBytes
             * @property {google.protobuf.IAny|null} [dataAny] CachedDataProto dataAny
             */

            /**
             * Constructs a new CachedDataProto.
             * @memberof waiternow.common
             * @classdesc Represents a CachedDataProto.
             * @implements ICachedDataProto
             * @constructor
             * @param {waiternow.common.ICachedDataProto=} [properties] Properties to set
             */
            function CachedDataProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CachedDataProto id.
             * @member {waiternow.common.CachedDataProto.IIdProto|null|undefined} id
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.id = null;

            /**
             * CachedDataProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.creationTime = null;

            /**
             * CachedDataProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.lastUpdateTime = null;

            /**
             * CachedDataProto dataString.
             * @member {string|null|undefined} dataString
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataString = null;

            /**
             * CachedDataProto dataBool.
             * @member {boolean|null|undefined} dataBool
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataBool = null;

            /**
             * CachedDataProto dataInt.
             * @member {number|null|undefined} dataInt
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataInt = null;

            /**
             * CachedDataProto dataLong.
             * @member {number|null|undefined} dataLong
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataLong = null;

            /**
             * CachedDataProto dataUnsignedInt.
             * @member {number|null|undefined} dataUnsignedInt
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataUnsignedInt = null;

            /**
             * CachedDataProto dataUnsignedLong.
             * @member {number|null|undefined} dataUnsignedLong
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataUnsignedLong = null;

            /**
             * CachedDataProto dataFloat.
             * @member {number|null|undefined} dataFloat
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataFloat = null;

            /**
             * CachedDataProto dataDouble.
             * @member {number|null|undefined} dataDouble
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataDouble = null;

            /**
             * CachedDataProto dataBytes.
             * @member {Uint8Array|null|undefined} dataBytes
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataBytes = null;

            /**
             * CachedDataProto dataAny.
             * @member {google.protobuf.IAny|null|undefined} dataAny
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            CachedDataProto.prototype.dataAny = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * CachedDataProto valueType.
             * @member {"dataString"|"dataBool"|"dataInt"|"dataLong"|"dataUnsignedInt"|"dataUnsignedLong"|"dataFloat"|"dataDouble"|"dataBytes"|"dataAny"|undefined} valueType
             * @memberof waiternow.common.CachedDataProto
             * @instance
             */
            Object.defineProperty(CachedDataProto.prototype, "valueType", {
                get: $util.oneOfGetter($oneOfFields = ["dataString", "dataBool", "dataInt", "dataLong", "dataUnsignedInt", "dataUnsignedLong", "dataFloat", "dataDouble", "dataBytes", "dataAny"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new CachedDataProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {waiternow.common.ICachedDataProto=} [properties] Properties to set
             * @returns {waiternow.common.CachedDataProto} CachedDataProto instance
             */
            CachedDataProto.create = function create(properties) {
                return new CachedDataProto(properties);
            };

            /**
             * Encodes the specified CachedDataProto message. Does not implicitly {@link waiternow.common.CachedDataProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {waiternow.common.ICachedDataProto} message CachedDataProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CachedDataProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.waiternow.common.CachedDataProto.IdProto.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.dataString != null && Object.hasOwnProperty.call(message, "dataString"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.dataString);
                if (message.dataBool != null && Object.hasOwnProperty.call(message, "dataBool"))
                    writer.uint32(/* id 5, wireType 0 =*/40).bool(message.dataBool);
                if (message.dataInt != null && Object.hasOwnProperty.call(message, "dataInt"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.dataInt);
                if (message.dataLong != null && Object.hasOwnProperty.call(message, "dataLong"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.dataLong);
                if (message.dataUnsignedInt != null && Object.hasOwnProperty.call(message, "dataUnsignedInt"))
                    writer.uint32(/* id 8, wireType 0 =*/64).uint32(message.dataUnsignedInt);
                if (message.dataUnsignedLong != null && Object.hasOwnProperty.call(message, "dataUnsignedLong"))
                    writer.uint32(/* id 9, wireType 0 =*/72).uint64(message.dataUnsignedLong);
                if (message.dataFloat != null && Object.hasOwnProperty.call(message, "dataFloat"))
                    writer.uint32(/* id 10, wireType 5 =*/85).float(message.dataFloat);
                if (message.dataDouble != null && Object.hasOwnProperty.call(message, "dataDouble"))
                    writer.uint32(/* id 11, wireType 1 =*/89).double(message.dataDouble);
                if (message.dataBytes != null && Object.hasOwnProperty.call(message, "dataBytes"))
                    writer.uint32(/* id 12, wireType 2 =*/98).bytes(message.dataBytes);
                if (message.dataAny != null && Object.hasOwnProperty.call(message, "dataAny"))
                    $root.google.protobuf.Any.encode(message.dataAny, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CachedDataProto message, length delimited. Does not implicitly {@link waiternow.common.CachedDataProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {waiternow.common.ICachedDataProto} message CachedDataProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CachedDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CachedDataProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CachedDataProto} CachedDataProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CachedDataProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CachedDataProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = $root.waiternow.common.CachedDataProto.IdProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.dataString = reader.string();
                            break;
                        }
                    case 5: {
                            message.dataBool = reader.bool();
                            break;
                        }
                    case 6: {
                            message.dataInt = reader.int32();
                            break;
                        }
                    case 7: {
                            message.dataLong = reader.int64();
                            break;
                        }
                    case 8: {
                            message.dataUnsignedInt = reader.uint32();
                            break;
                        }
                    case 9: {
                            message.dataUnsignedLong = reader.uint64();
                            break;
                        }
                    case 10: {
                            message.dataFloat = reader.float();
                            break;
                        }
                    case 11: {
                            message.dataDouble = reader.double();
                            break;
                        }
                    case 12: {
                            message.dataBytes = reader.bytes();
                            break;
                        }
                    case 13: {
                            message.dataAny = $root.google.protobuf.Any.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CachedDataProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CachedDataProto} CachedDataProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CachedDataProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CachedDataProto message.
             * @function verify
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CachedDataProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id")) {
                    var error = $root.waiternow.common.CachedDataProto.IdProto.verify(message.id);
                    if (error)
                        return "id." + error;
                }
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.dataString != null && message.hasOwnProperty("dataString")) {
                    properties.valueType = 1;
                    if (!$util.isString(message.dataString))
                        return "dataString: string expected";
                }
                if (message.dataBool != null && message.hasOwnProperty("dataBool")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (typeof message.dataBool !== "boolean")
                        return "dataBool: boolean expected";
                }
                if (message.dataInt != null && message.hasOwnProperty("dataInt")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (!$util.isInteger(message.dataInt))
                        return "dataInt: integer expected";
                }
                if (message.dataLong != null && message.hasOwnProperty("dataLong")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (!$util.isInteger(message.dataLong) && !(message.dataLong && $util.isInteger(message.dataLong.low) && $util.isInteger(message.dataLong.high)))
                        return "dataLong: integer|Long expected";
                }
                if (message.dataUnsignedInt != null && message.hasOwnProperty("dataUnsignedInt")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (!$util.isInteger(message.dataUnsignedInt))
                        return "dataUnsignedInt: integer expected";
                }
                if (message.dataUnsignedLong != null && message.hasOwnProperty("dataUnsignedLong")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (!$util.isInteger(message.dataUnsignedLong) && !(message.dataUnsignedLong && $util.isInteger(message.dataUnsignedLong.low) && $util.isInteger(message.dataUnsignedLong.high)))
                        return "dataUnsignedLong: integer|Long expected";
                }
                if (message.dataFloat != null && message.hasOwnProperty("dataFloat")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (typeof message.dataFloat !== "number")
                        return "dataFloat: number expected";
                }
                if (message.dataDouble != null && message.hasOwnProperty("dataDouble")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (typeof message.dataDouble !== "number")
                        return "dataDouble: number expected";
                }
                if (message.dataBytes != null && message.hasOwnProperty("dataBytes")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    if (!(message.dataBytes && typeof message.dataBytes.length === "number" || $util.isString(message.dataBytes)))
                        return "dataBytes: buffer expected";
                }
                if (message.dataAny != null && message.hasOwnProperty("dataAny")) {
                    if (properties.valueType === 1)
                        return "valueType: multiple values";
                    properties.valueType = 1;
                    {
                        var error = $root.google.protobuf.Any.verify(message.dataAny);
                        if (error)
                            return "dataAny." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a CachedDataProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CachedDataProto} CachedDataProto
             */
            CachedDataProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CachedDataProto)
                    return object;
                var message = new $root.waiternow.common.CachedDataProto();
                if (object.id != null) {
                    if (typeof object.id !== "object")
                        throw TypeError(".waiternow.common.CachedDataProto.id: object expected");
                    message.id = $root.waiternow.common.CachedDataProto.IdProto.fromObject(object.id);
                }
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.CachedDataProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.CachedDataProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.dataString != null)
                    message.dataString = String(object.dataString);
                if (object.dataBool != null)
                    message.dataBool = Boolean(object.dataBool);
                if (object.dataInt != null)
                    message.dataInt = object.dataInt | 0;
                if (object.dataLong != null)
                    if ($util.Long)
                        (message.dataLong = $util.Long.fromValue(object.dataLong)).unsigned = false;
                    else if (typeof object.dataLong === "string")
                        message.dataLong = parseInt(object.dataLong, 10);
                    else if (typeof object.dataLong === "number")
                        message.dataLong = object.dataLong;
                    else if (typeof object.dataLong === "object")
                        message.dataLong = new $util.LongBits(object.dataLong.low >>> 0, object.dataLong.high >>> 0).toNumber();
                if (object.dataUnsignedInt != null)
                    message.dataUnsignedInt = object.dataUnsignedInt >>> 0;
                if (object.dataUnsignedLong != null)
                    if ($util.Long)
                        (message.dataUnsignedLong = $util.Long.fromValue(object.dataUnsignedLong)).unsigned = true;
                    else if (typeof object.dataUnsignedLong === "string")
                        message.dataUnsignedLong = parseInt(object.dataUnsignedLong, 10);
                    else if (typeof object.dataUnsignedLong === "number")
                        message.dataUnsignedLong = object.dataUnsignedLong;
                    else if (typeof object.dataUnsignedLong === "object")
                        message.dataUnsignedLong = new $util.LongBits(object.dataUnsignedLong.low >>> 0, object.dataUnsignedLong.high >>> 0).toNumber(true);
                if (object.dataFloat != null)
                    message.dataFloat = Number(object.dataFloat);
                if (object.dataDouble != null)
                    message.dataDouble = Number(object.dataDouble);
                if (object.dataBytes != null)
                    if (typeof object.dataBytes === "string")
                        $util.base64.decode(object.dataBytes, message.dataBytes = $util.newBuffer($util.base64.length(object.dataBytes)), 0);
                    else if (object.dataBytes.length >= 0)
                        message.dataBytes = object.dataBytes;
                if (object.dataAny != null) {
                    if (typeof object.dataAny !== "object")
                        throw TypeError(".waiternow.common.CachedDataProto.dataAny: object expected");
                    message.dataAny = $root.google.protobuf.Any.fromObject(object.dataAny);
                }
                return message;
            };

            /**
             * Creates a plain object from a CachedDataProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {waiternow.common.CachedDataProto} message CachedDataProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CachedDataProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = null;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = $root.waiternow.common.CachedDataProto.IdProto.toObject(message.id, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.dataString != null && message.hasOwnProperty("dataString")) {
                    object.dataString = message.dataString;
                    if (options.oneofs)
                        object.valueType = "dataString";
                }
                if (message.dataBool != null && message.hasOwnProperty("dataBool")) {
                    object.dataBool = message.dataBool;
                    if (options.oneofs)
                        object.valueType = "dataBool";
                }
                if (message.dataInt != null && message.hasOwnProperty("dataInt")) {
                    object.dataInt = message.dataInt;
                    if (options.oneofs)
                        object.valueType = "dataInt";
                }
                if (message.dataLong != null && message.hasOwnProperty("dataLong")) {
                    if (typeof message.dataLong === "number")
                        object.dataLong = options.longs === String ? String(message.dataLong) : message.dataLong;
                    else
                        object.dataLong = options.longs === String ? $util.Long.prototype.toString.call(message.dataLong) : options.longs === Number ? new $util.LongBits(message.dataLong.low >>> 0, message.dataLong.high >>> 0).toNumber() : message.dataLong;
                    if (options.oneofs)
                        object.valueType = "dataLong";
                }
                if (message.dataUnsignedInt != null && message.hasOwnProperty("dataUnsignedInt")) {
                    object.dataUnsignedInt = message.dataUnsignedInt;
                    if (options.oneofs)
                        object.valueType = "dataUnsignedInt";
                }
                if (message.dataUnsignedLong != null && message.hasOwnProperty("dataUnsignedLong")) {
                    if (typeof message.dataUnsignedLong === "number")
                        object.dataUnsignedLong = options.longs === String ? String(message.dataUnsignedLong) : message.dataUnsignedLong;
                    else
                        object.dataUnsignedLong = options.longs === String ? $util.Long.prototype.toString.call(message.dataUnsignedLong) : options.longs === Number ? new $util.LongBits(message.dataUnsignedLong.low >>> 0, message.dataUnsignedLong.high >>> 0).toNumber(true) : message.dataUnsignedLong;
                    if (options.oneofs)
                        object.valueType = "dataUnsignedLong";
                }
                if (message.dataFloat != null && message.hasOwnProperty("dataFloat")) {
                    object.dataFloat = options.json && !isFinite(message.dataFloat) ? String(message.dataFloat) : message.dataFloat;
                    if (options.oneofs)
                        object.valueType = "dataFloat";
                }
                if (message.dataDouble != null && message.hasOwnProperty("dataDouble")) {
                    object.dataDouble = options.json && !isFinite(message.dataDouble) ? String(message.dataDouble) : message.dataDouble;
                    if (options.oneofs)
                        object.valueType = "dataDouble";
                }
                if (message.dataBytes != null && message.hasOwnProperty("dataBytes")) {
                    object.dataBytes = options.bytes === String ? $util.base64.encode(message.dataBytes, 0, message.dataBytes.length) : options.bytes === Array ? Array.prototype.slice.call(message.dataBytes) : message.dataBytes;
                    if (options.oneofs)
                        object.valueType = "dataBytes";
                }
                if (message.dataAny != null && message.hasOwnProperty("dataAny")) {
                    object.dataAny = $root.google.protobuf.Any.toObject(message.dataAny, options);
                    if (options.oneofs)
                        object.valueType = "dataAny";
                }
                return object;
            };

            /**
             * Converts this CachedDataProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CachedDataProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CachedDataProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CachedDataProto
             * @function getTypeUrl
             * @memberof waiternow.common.CachedDataProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CachedDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CachedDataProto";
            };

            CachedDataProto.IdProto = (function() {

                /**
                 * Properties of an IdProto.
                 * @memberof waiternow.common.CachedDataProto
                 * @interface IIdProto
                 * @property {string|null} [namespace] IdProto namespace
                 * @property {string|null} [key] IdProto key
                 */

                /**
                 * Constructs a new IdProto.
                 * @memberof waiternow.common.CachedDataProto
                 * @classdesc Represents an IdProto.
                 * @implements IIdProto
                 * @constructor
                 * @param {waiternow.common.CachedDataProto.IIdProto=} [properties] Properties to set
                 */
                function IdProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * IdProto namespace.
                 * @member {string} namespace
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @instance
                 */
                IdProto.prototype.namespace = "";

                /**
                 * IdProto key.
                 * @member {string} key
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @instance
                 */
                IdProto.prototype.key = "";

                /**
                 * Creates a new IdProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {waiternow.common.CachedDataProto.IIdProto=} [properties] Properties to set
                 * @returns {waiternow.common.CachedDataProto.IdProto} IdProto instance
                 */
                IdProto.create = function create(properties) {
                    return new IdProto(properties);
                };

                /**
                 * Encodes the specified IdProto message. Does not implicitly {@link waiternow.common.CachedDataProto.IdProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {waiternow.common.CachedDataProto.IIdProto} message IdProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IdProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.namespace != null && Object.hasOwnProperty.call(message, "namespace"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.namespace);
                    if (message.key != null && Object.hasOwnProperty.call(message, "key"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.key);
                    return writer;
                };

                /**
                 * Encodes the specified IdProto message, length delimited. Does not implicitly {@link waiternow.common.CachedDataProto.IdProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {waiternow.common.CachedDataProto.IIdProto} message IdProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IdProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an IdProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CachedDataProto.IdProto} IdProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IdProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CachedDataProto.IdProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.namespace = reader.string();
                                break;
                            }
                        case 2: {
                                message.key = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an IdProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CachedDataProto.IdProto} IdProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IdProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an IdProto message.
                 * @function verify
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IdProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.namespace != null && message.hasOwnProperty("namespace"))
                        if (!$util.isString(message.namespace))
                            return "namespace: string expected";
                    if (message.key != null && message.hasOwnProperty("key"))
                        if (!$util.isString(message.key))
                            return "key: string expected";
                    return null;
                };

                /**
                 * Creates an IdProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CachedDataProto.IdProto} IdProto
                 */
                IdProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CachedDataProto.IdProto)
                        return object;
                    var message = new $root.waiternow.common.CachedDataProto.IdProto();
                    if (object.namespace != null)
                        message.namespace = String(object.namespace);
                    if (object.key != null)
                        message.key = String(object.key);
                    return message;
                };

                /**
                 * Creates a plain object from an IdProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {waiternow.common.CachedDataProto.IdProto} message IdProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IdProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.namespace = "";
                        object.key = "";
                    }
                    if (message.namespace != null && message.hasOwnProperty("namespace"))
                        object.namespace = message.namespace;
                    if (message.key != null && message.hasOwnProperty("key"))
                        object.key = message.key;
                    return object;
                };

                /**
                 * Converts this IdProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IdProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for IdProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CachedDataProto.IdProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IdProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CachedDataProto.IdProto";
                };

                return IdProto;
            })();

            return CachedDataProto;
        })();

        common.CheckInProto = (function() {

            /**
             * Properties of a CheckInProto.
             * @memberof waiternow.common
             * @interface ICheckInProto
             * @property {string|null} [id] CheckInProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] CheckInProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] CheckInProto lastUpdateTime
             * @property {string|null} [deviceId] CheckInProto deviceId
             * @property {string|null} [pointOfServiceId] CheckInProto pointOfServiceId
             * @property {waiternow.common.CheckInProto.IRedundantDataProto|null} [redundantData] CheckInProto redundantData
             */

            /**
             * Constructs a new CheckInProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckInProto.
             * @implements ICheckInProto
             * @constructor
             * @param {waiternow.common.ICheckInProto=} [properties] Properties to set
             */
            function CheckInProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CheckInProto id.
             * @member {string} id
             * @memberof waiternow.common.CheckInProto
             * @instance
             */
            CheckInProto.prototype.id = "";

            /**
             * CheckInProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.CheckInProto
             * @instance
             */
            CheckInProto.prototype.creationTime = null;

            /**
             * CheckInProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.CheckInProto
             * @instance
             */
            CheckInProto.prototype.lastUpdateTime = null;

            /**
             * CheckInProto deviceId.
             * @member {string} deviceId
             * @memberof waiternow.common.CheckInProto
             * @instance
             */
            CheckInProto.prototype.deviceId = "";

            /**
             * CheckInProto pointOfServiceId.
             * @member {string} pointOfServiceId
             * @memberof waiternow.common.CheckInProto
             * @instance
             */
            CheckInProto.prototype.pointOfServiceId = "";

            /**
             * CheckInProto redundantData.
             * @member {waiternow.common.CheckInProto.IRedundantDataProto|null|undefined} redundantData
             * @memberof waiternow.common.CheckInProto
             * @instance
             */
            CheckInProto.prototype.redundantData = null;

            /**
             * Creates a new CheckInProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {waiternow.common.ICheckInProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckInProto} CheckInProto instance
             */
            CheckInProto.create = function create(properties) {
                return new CheckInProto(properties);
            };

            /**
             * Encodes the specified CheckInProto message. Does not implicitly {@link waiternow.common.CheckInProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {waiternow.common.ICheckInProto} message CheckInProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.deviceId);
                if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.pointOfServiceId);
                if (message.redundantData != null && Object.hasOwnProperty.call(message, "redundantData"))
                    $root.waiternow.common.CheckInProto.RedundantDataProto.encode(message.redundantData, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CheckInProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {waiternow.common.ICheckInProto} message CheckInProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckInProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckInProto} CheckInProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.deviceId = reader.string();
                            break;
                        }
                    case 5: {
                            message.pointOfServiceId = reader.string();
                            break;
                        }
                    case 6: {
                            message.redundantData = $root.waiternow.common.CheckInProto.RedundantDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckInProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckInProto} CheckInProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckInProto message.
             * @function verify
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckInProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    if (!$util.isString(message.deviceId))
                        return "deviceId: string expected";
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    if (!$util.isString(message.pointOfServiceId))
                        return "pointOfServiceId: string expected";
                if (message.redundantData != null && message.hasOwnProperty("redundantData")) {
                    var error = $root.waiternow.common.CheckInProto.RedundantDataProto.verify(message.redundantData);
                    if (error)
                        return "redundantData." + error;
                }
                return null;
            };

            /**
             * Creates a CheckInProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckInProto} CheckInProto
             */
            CheckInProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckInProto)
                    return object;
                var message = new $root.waiternow.common.CheckInProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.CheckInProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.CheckInProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.deviceId != null)
                    message.deviceId = String(object.deviceId);
                if (object.pointOfServiceId != null)
                    message.pointOfServiceId = String(object.pointOfServiceId);
                if (object.redundantData != null) {
                    if (typeof object.redundantData !== "object")
                        throw TypeError(".waiternow.common.CheckInProto.redundantData: object expected");
                    message.redundantData = $root.waiternow.common.CheckInProto.RedundantDataProto.fromObject(object.redundantData);
                }
                return message;
            };

            /**
             * Creates a plain object from a CheckInProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {waiternow.common.CheckInProto} message CheckInProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckInProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.deviceId = "";
                    object.pointOfServiceId = "";
                    object.redundantData = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    object.deviceId = message.deviceId;
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    object.pointOfServiceId = message.pointOfServiceId;
                if (message.redundantData != null && message.hasOwnProperty("redundantData"))
                    object.redundantData = $root.waiternow.common.CheckInProto.RedundantDataProto.toObject(message.redundantData, options);
                return object;
            };

            /**
             * Converts this CheckInProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckInProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckInProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckInProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckInProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckInProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckInProto";
            };

            CheckInProto.RedundantDataProto = (function() {

                /**
                 * Properties of a RedundantDataProto.
                 * @memberof waiternow.common.CheckInProto
                 * @interface IRedundantDataProto
                 * @property {string|null} [consumerId] RedundantDataProto consumerId
                 * @property {string|null} [locationId] RedundantDataProto locationId
                 * @property {string|null} [businessId] RedundantDataProto businessId
                 * @property {waiternow.common.Country|null} [locationCountry] RedundantDataProto locationCountry
                 */

                /**
                 * Constructs a new RedundantDataProto.
                 * @memberof waiternow.common.CheckInProto
                 * @classdesc Represents a RedundantDataProto.
                 * @implements IRedundantDataProto
                 * @constructor
                 * @param {waiternow.common.CheckInProto.IRedundantDataProto=} [properties] Properties to set
                 */
                function RedundantDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RedundantDataProto consumerId.
                 * @member {string} consumerId
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.consumerId = "";

                /**
                 * RedundantDataProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationId = "";

                /**
                 * RedundantDataProto businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessId = "";

                /**
                 * RedundantDataProto locationCountry.
                 * @member {waiternow.common.Country} locationCountry
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationCountry = 0;

                /**
                 * Creates a new RedundantDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.CheckInProto.IRedundantDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInProto.RedundantDataProto} RedundantDataProto instance
                 */
                RedundantDataProto.create = function create(properties) {
                    return new RedundantDataProto(properties);
                };

                /**
                 * Encodes the specified RedundantDataProto message. Does not implicitly {@link waiternow.common.CheckInProto.RedundantDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.CheckInProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.consumerId != null && Object.hasOwnProperty.call(message, "consumerId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.consumerId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessId);
                    if (message.locationCountry != null && Object.hasOwnProperty.call(message, "locationCountry"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.locationCountry);
                    return writer;
                };

                /**
                 * Encodes the specified RedundantDataProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInProto.RedundantDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.CheckInProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInProto.RedundantDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.consumerId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 4: {
                                message.locationCountry = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RedundantDataProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedundantDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                        if (!$util.isString(message.consumerId))
                            return "consumerId: string expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.locationCountry != null && message.hasOwnProperty("locationCountry"))
                        switch (message.locationCountry) {
                        default:
                            return "locationCountry: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a RedundantDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInProto.RedundantDataProto} RedundantDataProto
                 */
                RedundantDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInProto.RedundantDataProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInProto.RedundantDataProto();
                    if (object.consumerId != null)
                        message.consumerId = String(object.consumerId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    switch (object.locationCountry) {
                    default:
                        if (typeof object.locationCountry === "number") {
                            message.locationCountry = object.locationCountry;
                            break;
                        }
                        break;
                    case "COUNTRY_UNSPECIFIED":
                    case 0:
                        message.locationCountry = 0;
                        break;
                    case "UNITED_STATES":
                    case 1:
                        message.locationCountry = 1;
                        break;
                    case "MEXICO":
                    case 2:
                        message.locationCountry = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RedundantDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.CheckInProto.RedundantDataProto} message RedundantDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedundantDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.consumerId = "";
                        object.locationId = "";
                        object.businessId = "";
                        object.locationCountry = options.enums === String ? "COUNTRY_UNSPECIFIED" : 0;
                    }
                    if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                        object.consumerId = message.consumerId;
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.locationCountry != null && message.hasOwnProperty("locationCountry"))
                        object.locationCountry = options.enums === String ? $root.waiternow.common.Country[message.locationCountry] === undefined ? message.locationCountry : $root.waiternow.common.Country[message.locationCountry] : message.locationCountry;
                    return object;
                };

                /**
                 * Converts this RedundantDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedundantDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RedundantDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInProto.RedundantDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedundantDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInProto.RedundantDataProto";
                };

                return RedundantDataProto;
            })();

            return CheckInProto;
        })();

        common.CheckInsProto = (function() {

            /**
             * Properties of a CheckInsProto.
             * @memberof waiternow.common
             * @interface ICheckInsProto
             * @property {Array.<waiternow.common.ICheckInProto>|null} [checkIns] CheckInsProto checkIns
             * @property {string|null} [continuationToken] CheckInsProto continuationToken
             */

            /**
             * Constructs a new CheckInsProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckInsProto.
             * @implements ICheckInsProto
             * @constructor
             * @param {waiternow.common.ICheckInsProto=} [properties] Properties to set
             */
            function CheckInsProto(properties) {
                this.checkIns = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CheckInsProto checkIns.
             * @member {Array.<waiternow.common.ICheckInProto>} checkIns
             * @memberof waiternow.common.CheckInsProto
             * @instance
             */
            CheckInsProto.prototype.checkIns = $util.emptyArray;

            /**
             * CheckInsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.CheckInsProto
             * @instance
             */
            CheckInsProto.prototype.continuationToken = "";

            /**
             * Creates a new CheckInsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {waiternow.common.ICheckInsProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckInsProto} CheckInsProto instance
             */
            CheckInsProto.create = function create(properties) {
                return new CheckInsProto(properties);
            };

            /**
             * Encodes the specified CheckInsProto message. Does not implicitly {@link waiternow.common.CheckInsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {waiternow.common.ICheckInsProto} message CheckInsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.checkIns != null && message.checkIns.length)
                    for (var i = 0; i < message.checkIns.length; ++i)
                        $root.waiternow.common.CheckInProto.encode(message.checkIns[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified CheckInsProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {waiternow.common.ICheckInsProto} message CheckInsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckInsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckInsProto} CheckInsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.checkIns && message.checkIns.length))
                                message.checkIns = [];
                            message.checkIns.push($root.waiternow.common.CheckInProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckInsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckInsProto} CheckInsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckInsProto message.
             * @function verify
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckInsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.checkIns != null && message.hasOwnProperty("checkIns")) {
                    if (!Array.isArray(message.checkIns))
                        return "checkIns: array expected";
                    for (var i = 0; i < message.checkIns.length; ++i) {
                        var error = $root.waiternow.common.CheckInProto.verify(message.checkIns[i]);
                        if (error)
                            return "checkIns." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a CheckInsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckInsProto} CheckInsProto
             */
            CheckInsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckInsProto)
                    return object;
                var message = new $root.waiternow.common.CheckInsProto();
                if (object.checkIns) {
                    if (!Array.isArray(object.checkIns))
                        throw TypeError(".waiternow.common.CheckInsProto.checkIns: array expected");
                    message.checkIns = [];
                    for (var i = 0; i < object.checkIns.length; ++i) {
                        if (typeof object.checkIns[i] !== "object")
                            throw TypeError(".waiternow.common.CheckInsProto.checkIns: object expected");
                        message.checkIns[i] = $root.waiternow.common.CheckInProto.fromObject(object.checkIns[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a CheckInsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {waiternow.common.CheckInsProto} message CheckInsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckInsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.checkIns = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.checkIns && message.checkIns.length) {
                    object.checkIns = [];
                    for (var j = 0; j < message.checkIns.length; ++j)
                        object.checkIns[j] = $root.waiternow.common.CheckInProto.toObject(message.checkIns[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this CheckInsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckInsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckInsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckInsProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckInsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckInsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckInsProto";
            };

            return CheckInsProto;
        })();

        common.CheckInDataProto = (function() {

            /**
             * Properties of a CheckInDataProto.
             * @memberof waiternow.common
             * @interface ICheckInDataProto
             * @property {waiternow.common.CheckInDataProto.IBusinessDataProto|null} [businessData] CheckInDataProto businessData
             * @property {waiternow.common.CheckInDataProto.IMenuDataProto|null} [menuData] CheckInDataProto menuData
             * @property {waiternow.common.CheckInDataProto.IPaymentsDataProto|null} [paymentsData] CheckInDataProto paymentsData
             * @property {waiternow.common.CheckInDataProto.IStatusProto|null} [status] CheckInDataProto status
             * @property {waiternow.common.CheckInDataProto.IEnabledFeaturesProto|null} [enabledFeatures] CheckInDataProto enabledFeatures
             * @property {waiternow.common.CheckInDataProto.IConfigurationProto|null} [configuration] CheckInDataProto configuration
             * @property {waiternow.common.CheckInDataProto.IViewSettingsProto|null} [viewSettings] CheckInDataProto viewSettings
             */

            /**
             * Constructs a new CheckInDataProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckInDataProto.
             * @implements ICheckInDataProto
             * @constructor
             * @param {waiternow.common.ICheckInDataProto=} [properties] Properties to set
             */
            function CheckInDataProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CheckInDataProto businessData.
             * @member {waiternow.common.CheckInDataProto.IBusinessDataProto|null|undefined} businessData
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             */
            CheckInDataProto.prototype.businessData = null;

            /**
             * CheckInDataProto menuData.
             * @member {waiternow.common.CheckInDataProto.IMenuDataProto|null|undefined} menuData
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             */
            CheckInDataProto.prototype.menuData = null;

            /**
             * CheckInDataProto paymentsData.
             * @member {waiternow.common.CheckInDataProto.IPaymentsDataProto|null|undefined} paymentsData
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             */
            CheckInDataProto.prototype.paymentsData = null;

            /**
             * CheckInDataProto status.
             * @member {waiternow.common.CheckInDataProto.IStatusProto|null|undefined} status
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             */
            CheckInDataProto.prototype.status = null;

            /**
             * CheckInDataProto enabledFeatures.
             * @member {waiternow.common.CheckInDataProto.IEnabledFeaturesProto|null|undefined} enabledFeatures
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             */
            CheckInDataProto.prototype.enabledFeatures = null;

            /**
             * CheckInDataProto configuration.
             * @member {waiternow.common.CheckInDataProto.IConfigurationProto|null|undefined} configuration
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             */
            CheckInDataProto.prototype.configuration = null;

            /**
             * CheckInDataProto viewSettings.
             * @member {waiternow.common.CheckInDataProto.IViewSettingsProto|null|undefined} viewSettings
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             */
            CheckInDataProto.prototype.viewSettings = null;

            /**
             * Creates a new CheckInDataProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {waiternow.common.ICheckInDataProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckInDataProto} CheckInDataProto instance
             */
            CheckInDataProto.create = function create(properties) {
                return new CheckInDataProto(properties);
            };

            /**
             * Encodes the specified CheckInDataProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {waiternow.common.ICheckInDataProto} message CheckInDataProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInDataProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.businessData != null && Object.hasOwnProperty.call(message, "businessData"))
                    $root.waiternow.common.CheckInDataProto.BusinessDataProto.encode(message.businessData, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.menuData != null && Object.hasOwnProperty.call(message, "menuData"))
                    $root.waiternow.common.CheckInDataProto.MenuDataProto.encode(message.menuData, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.paymentsData != null && Object.hasOwnProperty.call(message, "paymentsData"))
                    $root.waiternow.common.CheckInDataProto.PaymentsDataProto.encode(message.paymentsData, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.waiternow.common.CheckInDataProto.StatusProto.encode(message.status, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.enabledFeatures != null && Object.hasOwnProperty.call(message, "enabledFeatures"))
                    $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto.encode(message.enabledFeatures, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.configuration != null && Object.hasOwnProperty.call(message, "configuration"))
                    $root.waiternow.common.CheckInDataProto.ConfigurationProto.encode(message.configuration, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.viewSettings != null && Object.hasOwnProperty.call(message, "viewSettings"))
                    $root.waiternow.common.CheckInDataProto.ViewSettingsProto.encode(message.viewSettings, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified CheckInDataProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {waiternow.common.ICheckInDataProto} message CheckInDataProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckInDataProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckInDataProto} CheckInDataProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInDataProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.businessData = $root.waiternow.common.CheckInDataProto.BusinessDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.menuData = $root.waiternow.common.CheckInDataProto.MenuDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.paymentsData = $root.waiternow.common.CheckInDataProto.PaymentsDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.status = $root.waiternow.common.CheckInDataProto.StatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.enabledFeatures = $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.configuration = $root.waiternow.common.CheckInDataProto.ConfigurationProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.viewSettings = $root.waiternow.common.CheckInDataProto.ViewSettingsProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckInDataProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckInDataProto} CheckInDataProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInDataProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckInDataProto message.
             * @function verify
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckInDataProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.businessData != null && message.hasOwnProperty("businessData")) {
                    var error = $root.waiternow.common.CheckInDataProto.BusinessDataProto.verify(message.businessData);
                    if (error)
                        return "businessData." + error;
                }
                if (message.menuData != null && message.hasOwnProperty("menuData")) {
                    var error = $root.waiternow.common.CheckInDataProto.MenuDataProto.verify(message.menuData);
                    if (error)
                        return "menuData." + error;
                }
                if (message.paymentsData != null && message.hasOwnProperty("paymentsData")) {
                    var error = $root.waiternow.common.CheckInDataProto.PaymentsDataProto.verify(message.paymentsData);
                    if (error)
                        return "paymentsData." + error;
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    var error = $root.waiternow.common.CheckInDataProto.StatusProto.verify(message.status);
                    if (error)
                        return "status." + error;
                }
                if (message.enabledFeatures != null && message.hasOwnProperty("enabledFeatures")) {
                    var error = $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto.verify(message.enabledFeatures);
                    if (error)
                        return "enabledFeatures." + error;
                }
                if (message.configuration != null && message.hasOwnProperty("configuration")) {
                    var error = $root.waiternow.common.CheckInDataProto.ConfigurationProto.verify(message.configuration);
                    if (error)
                        return "configuration." + error;
                }
                if (message.viewSettings != null && message.hasOwnProperty("viewSettings")) {
                    var error = $root.waiternow.common.CheckInDataProto.ViewSettingsProto.verify(message.viewSettings);
                    if (error)
                        return "viewSettings." + error;
                }
                return null;
            };

            /**
             * Creates a CheckInDataProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckInDataProto} CheckInDataProto
             */
            CheckInDataProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckInDataProto)
                    return object;
                var message = new $root.waiternow.common.CheckInDataProto();
                if (object.businessData != null) {
                    if (typeof object.businessData !== "object")
                        throw TypeError(".waiternow.common.CheckInDataProto.businessData: object expected");
                    message.businessData = $root.waiternow.common.CheckInDataProto.BusinessDataProto.fromObject(object.businessData);
                }
                if (object.menuData != null) {
                    if (typeof object.menuData !== "object")
                        throw TypeError(".waiternow.common.CheckInDataProto.menuData: object expected");
                    message.menuData = $root.waiternow.common.CheckInDataProto.MenuDataProto.fromObject(object.menuData);
                }
                if (object.paymentsData != null) {
                    if (typeof object.paymentsData !== "object")
                        throw TypeError(".waiternow.common.CheckInDataProto.paymentsData: object expected");
                    message.paymentsData = $root.waiternow.common.CheckInDataProto.PaymentsDataProto.fromObject(object.paymentsData);
                }
                if (object.status != null) {
                    if (typeof object.status !== "object")
                        throw TypeError(".waiternow.common.CheckInDataProto.status: object expected");
                    message.status = $root.waiternow.common.CheckInDataProto.StatusProto.fromObject(object.status);
                }
                if (object.enabledFeatures != null) {
                    if (typeof object.enabledFeatures !== "object")
                        throw TypeError(".waiternow.common.CheckInDataProto.enabledFeatures: object expected");
                    message.enabledFeatures = $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto.fromObject(object.enabledFeatures);
                }
                if (object.configuration != null) {
                    if (typeof object.configuration !== "object")
                        throw TypeError(".waiternow.common.CheckInDataProto.configuration: object expected");
                    message.configuration = $root.waiternow.common.CheckInDataProto.ConfigurationProto.fromObject(object.configuration);
                }
                if (object.viewSettings != null) {
                    if (typeof object.viewSettings !== "object")
                        throw TypeError(".waiternow.common.CheckInDataProto.viewSettings: object expected");
                    message.viewSettings = $root.waiternow.common.CheckInDataProto.ViewSettingsProto.fromObject(object.viewSettings);
                }
                return message;
            };

            /**
             * Creates a plain object from a CheckInDataProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {waiternow.common.CheckInDataProto} message CheckInDataProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckInDataProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.businessData = null;
                    object.menuData = null;
                    object.paymentsData = null;
                    object.status = null;
                    object.enabledFeatures = null;
                    object.configuration = null;
                    object.viewSettings = null;
                }
                if (message.businessData != null && message.hasOwnProperty("businessData"))
                    object.businessData = $root.waiternow.common.CheckInDataProto.BusinessDataProto.toObject(message.businessData, options);
                if (message.menuData != null && message.hasOwnProperty("menuData"))
                    object.menuData = $root.waiternow.common.CheckInDataProto.MenuDataProto.toObject(message.menuData, options);
                if (message.paymentsData != null && message.hasOwnProperty("paymentsData"))
                    object.paymentsData = $root.waiternow.common.CheckInDataProto.PaymentsDataProto.toObject(message.paymentsData, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = $root.waiternow.common.CheckInDataProto.StatusProto.toObject(message.status, options);
                if (message.enabledFeatures != null && message.hasOwnProperty("enabledFeatures"))
                    object.enabledFeatures = $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto.toObject(message.enabledFeatures, options);
                if (message.configuration != null && message.hasOwnProperty("configuration"))
                    object.configuration = $root.waiternow.common.CheckInDataProto.ConfigurationProto.toObject(message.configuration, options);
                if (message.viewSettings != null && message.hasOwnProperty("viewSettings"))
                    object.viewSettings = $root.waiternow.common.CheckInDataProto.ViewSettingsProto.toObject(message.viewSettings, options);
                return object;
            };

            /**
             * Converts this CheckInDataProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckInDataProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckInDataProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckInDataProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckInDataProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckInDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckInDataProto";
            };

            CheckInDataProto.BusinessDataProto = (function() {

                /**
                 * Properties of a BusinessDataProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @interface IBusinessDataProto
                 * @property {string|null} [pointOfServiceId] BusinessDataProto pointOfServiceId
                 * @property {string|null} [locationId] BusinessDataProto locationId
                 * @property {string|null} [businessName] BusinessDataProto businessName
                 * @property {string|null} [locationName] BusinessDataProto locationName
                 * @property {string|null} [pointOfServiceFriendlyName] BusinessDataProto pointOfServiceFriendlyName
                 * @property {waiternow.common.IImageProto|null} [businessLogo] BusinessDataProto businessLogo
                 * @property {waiternow.common.IImageProto|null} [headerForLandscapeScreen] BusinessDataProto headerForLandscapeScreen
                 * @property {waiternow.common.IImageProto|null} [headerForPortraitScreen] BusinessDataProto headerForPortraitScreen
                 * @property {waiternow.common.IAddressProto|null} [locationAddress] BusinessDataProto locationAddress
                 * @property {waiternow.common.Language|null} [language] BusinessDataProto language
                 * @property {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto|null} [externalLinks] BusinessDataProto externalLinks
                 * @property {waiternow.common.IBusinessHoursProto|null} [businessHours] BusinessDataProto businessHours
                 * @property {waiternow.common.IPhoneNumberProto|null} [locationPhoneNumber] BusinessDataProto locationPhoneNumber
                 * @property {string|null} [description] BusinessDataProto description
                 */

                /**
                 * Constructs a new BusinessDataProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @classdesc Represents a BusinessDataProto.
                 * @implements IBusinessDataProto
                 * @constructor
                 * @param {waiternow.common.CheckInDataProto.IBusinessDataProto=} [properties] Properties to set
                 */
                function BusinessDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * BusinessDataProto pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.pointOfServiceId = "";

                /**
                 * BusinessDataProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.locationId = "";

                /**
                 * BusinessDataProto businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.businessName = "";

                /**
                 * BusinessDataProto locationName.
                 * @member {string} locationName
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.locationName = "";

                /**
                 * BusinessDataProto pointOfServiceFriendlyName.
                 * @member {string} pointOfServiceFriendlyName
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.pointOfServiceFriendlyName = "";

                /**
                 * BusinessDataProto businessLogo.
                 * @member {waiternow.common.IImageProto|null|undefined} businessLogo
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.businessLogo = null;

                /**
                 * BusinessDataProto headerForLandscapeScreen.
                 * @member {waiternow.common.IImageProto|null|undefined} headerForLandscapeScreen
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.headerForLandscapeScreen = null;

                /**
                 * BusinessDataProto headerForPortraitScreen.
                 * @member {waiternow.common.IImageProto|null|undefined} headerForPortraitScreen
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.headerForPortraitScreen = null;

                /**
                 * BusinessDataProto locationAddress.
                 * @member {waiternow.common.IAddressProto|null|undefined} locationAddress
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.locationAddress = null;

                /**
                 * BusinessDataProto language.
                 * @member {waiternow.common.Language} language
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.language = 0;

                /**
                 * BusinessDataProto externalLinks.
                 * @member {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto|null|undefined} externalLinks
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.externalLinks = null;

                /**
                 * BusinessDataProto businessHours.
                 * @member {waiternow.common.IBusinessHoursProto|null|undefined} businessHours
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.businessHours = null;

                /**
                 * BusinessDataProto locationPhoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} locationPhoneNumber
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.locationPhoneNumber = null;

                /**
                 * BusinessDataProto description.
                 * @member {string} description
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 */
                BusinessDataProto.prototype.description = "";

                /**
                 * Creates a new BusinessDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IBusinessDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInDataProto.BusinessDataProto} BusinessDataProto instance
                 */
                BusinessDataProto.create = function create(properties) {
                    return new BusinessDataProto(properties);
                };

                /**
                 * Encodes the specified BusinessDataProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.BusinessDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IBusinessDataProto} message BusinessDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessName);
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.locationName);
                    if (message.pointOfServiceFriendlyName != null && Object.hasOwnProperty.call(message, "pointOfServiceFriendlyName"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.pointOfServiceFriendlyName);
                    if (message.businessLogo != null && Object.hasOwnProperty.call(message, "businessLogo"))
                        $root.waiternow.common.ImageProto.encode(message.businessLogo, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.headerForLandscapeScreen != null && Object.hasOwnProperty.call(message, "headerForLandscapeScreen"))
                        $root.waiternow.common.ImageProto.encode(message.headerForLandscapeScreen, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.headerForPortraitScreen != null && Object.hasOwnProperty.call(message, "headerForPortraitScreen"))
                        $root.waiternow.common.ImageProto.encode(message.headerForPortraitScreen, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.locationAddress != null && Object.hasOwnProperty.call(message, "locationAddress"))
                        $root.waiternow.common.AddressProto.encode(message.locationAddress, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int32(message.language);
                    if (message.externalLinks != null && Object.hasOwnProperty.call(message, "externalLinks"))
                        $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.encode(message.externalLinks, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.businessHours != null && Object.hasOwnProperty.call(message, "businessHours"))
                        $root.waiternow.common.BusinessHoursProto.encode(message.businessHours, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.locationPhoneNumber != null && Object.hasOwnProperty.call(message, "locationPhoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.locationPhoneNumber, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                    if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.description);
                    return writer;
                };

                /**
                 * Encodes the specified BusinessDataProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.BusinessDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IBusinessDataProto} message BusinessDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                BusinessDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a BusinessDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInDataProto.BusinessDataProto} BusinessDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto.BusinessDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 4: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 5: {
                                message.pointOfServiceFriendlyName = reader.string();
                                break;
                            }
                        case 6: {
                                message.businessLogo = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.headerForLandscapeScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.headerForPortraitScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.locationAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.language = reader.int32();
                                break;
                            }
                        case 11: {
                                message.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.businessHours = $root.waiternow.common.BusinessHoursProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 14: {
                                message.description = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a BusinessDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInDataProto.BusinessDataProto} BusinessDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                BusinessDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a BusinessDataProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                BusinessDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                        if (!$util.isString(message.pointOfServiceFriendlyName))
                            return "pointOfServiceFriendlyName: string expected";
                    if (message.businessLogo != null && message.hasOwnProperty("businessLogo")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.businessLogo);
                        if (error)
                            return "businessLogo." + error;
                    }
                    if (message.headerForLandscapeScreen != null && message.hasOwnProperty("headerForLandscapeScreen")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.headerForLandscapeScreen);
                        if (error)
                            return "headerForLandscapeScreen." + error;
                    }
                    if (message.headerForPortraitScreen != null && message.hasOwnProperty("headerForPortraitScreen")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.headerForPortraitScreen);
                        if (error)
                            return "headerForPortraitScreen." + error;
                    }
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.locationAddress);
                        if (error)
                            return "locationAddress." + error;
                    }
                    if (message.language != null && message.hasOwnProperty("language"))
                        switch (message.language) {
                        default:
                            return "language: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.externalLinks != null && message.hasOwnProperty("externalLinks")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.verify(message.externalLinks);
                        if (error)
                            return "externalLinks." + error;
                    }
                    if (message.businessHours != null && message.hasOwnProperty("businessHours")) {
                        var error = $root.waiternow.common.BusinessHoursProto.verify(message.businessHours);
                        if (error)
                            return "businessHours." + error;
                    }
                    if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.locationPhoneNumber);
                        if (error)
                            return "locationPhoneNumber." + error;
                    }
                    if (message.description != null && message.hasOwnProperty("description"))
                        if (!$util.isString(message.description))
                            return "description: string expected";
                    return null;
                };

                /**
                 * Creates a BusinessDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInDataProto.BusinessDataProto} BusinessDataProto
                 */
                BusinessDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInDataProto.BusinessDataProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInDataProto.BusinessDataProto();
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.pointOfServiceFriendlyName != null)
                        message.pointOfServiceFriendlyName = String(object.pointOfServiceFriendlyName);
                    if (object.businessLogo != null) {
                        if (typeof object.businessLogo !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.BusinessDataProto.businessLogo: object expected");
                        message.businessLogo = $root.waiternow.common.ImageProto.fromObject(object.businessLogo);
                    }
                    if (object.headerForLandscapeScreen != null) {
                        if (typeof object.headerForLandscapeScreen !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.BusinessDataProto.headerForLandscapeScreen: object expected");
                        message.headerForLandscapeScreen = $root.waiternow.common.ImageProto.fromObject(object.headerForLandscapeScreen);
                    }
                    if (object.headerForPortraitScreen != null) {
                        if (typeof object.headerForPortraitScreen !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.BusinessDataProto.headerForPortraitScreen: object expected");
                        message.headerForPortraitScreen = $root.waiternow.common.ImageProto.fromObject(object.headerForPortraitScreen);
                    }
                    if (object.locationAddress != null) {
                        if (typeof object.locationAddress !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.BusinessDataProto.locationAddress: object expected");
                        message.locationAddress = $root.waiternow.common.AddressProto.fromObject(object.locationAddress);
                    }
                    switch (object.language) {
                    default:
                        if (typeof object.language === "number") {
                            message.language = object.language;
                            break;
                        }
                        break;
                    case "LANGUAGE_UNSPECIFIED":
                    case 0:
                        message.language = 0;
                        break;
                    case "ENGLISH":
                    case 1:
                        message.language = 1;
                        break;
                    case "SPANISH":
                    case 2:
                        message.language = 2;
                        break;
                    }
                    if (object.externalLinks != null) {
                        if (typeof object.externalLinks !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.BusinessDataProto.externalLinks: object expected");
                        message.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.fromObject(object.externalLinks);
                    }
                    if (object.businessHours != null) {
                        if (typeof object.businessHours !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.BusinessDataProto.businessHours: object expected");
                        message.businessHours = $root.waiternow.common.BusinessHoursProto.fromObject(object.businessHours);
                    }
                    if (object.locationPhoneNumber != null) {
                        if (typeof object.locationPhoneNumber !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.BusinessDataProto.locationPhoneNumber: object expected");
                        message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.locationPhoneNumber);
                    }
                    if (object.description != null)
                        message.description = String(object.description);
                    return message;
                };

                /**
                 * Creates a plain object from a BusinessDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.BusinessDataProto} message BusinessDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                BusinessDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.pointOfServiceId = "";
                        object.locationId = "";
                        object.businessName = "";
                        object.locationName = "";
                        object.pointOfServiceFriendlyName = "";
                        object.businessLogo = null;
                        object.headerForLandscapeScreen = null;
                        object.headerForPortraitScreen = null;
                        object.locationAddress = null;
                        object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                        object.externalLinks = null;
                        object.businessHours = null;
                        object.locationPhoneNumber = null;
                        object.description = "";
                    }
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                        object.pointOfServiceFriendlyName = message.pointOfServiceFriendlyName;
                    if (message.businessLogo != null && message.hasOwnProperty("businessLogo"))
                        object.businessLogo = $root.waiternow.common.ImageProto.toObject(message.businessLogo, options);
                    if (message.headerForLandscapeScreen != null && message.hasOwnProperty("headerForLandscapeScreen"))
                        object.headerForLandscapeScreen = $root.waiternow.common.ImageProto.toObject(message.headerForLandscapeScreen, options);
                    if (message.headerForPortraitScreen != null && message.hasOwnProperty("headerForPortraitScreen"))
                        object.headerForPortraitScreen = $root.waiternow.common.ImageProto.toObject(message.headerForPortraitScreen, options);
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress"))
                        object.locationAddress = $root.waiternow.common.AddressProto.toObject(message.locationAddress, options);
                    if (message.language != null && message.hasOwnProperty("language"))
                        object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                    if (message.externalLinks != null && message.hasOwnProperty("externalLinks"))
                        object.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.toObject(message.externalLinks, options);
                    if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                        object.businessHours = $root.waiternow.common.BusinessHoursProto.toObject(message.businessHours, options);
                    if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber"))
                        object.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.locationPhoneNumber, options);
                    if (message.description != null && message.hasOwnProperty("description"))
                        object.description = message.description;
                    return object;
                };

                /**
                 * Converts this BusinessDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                BusinessDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for BusinessDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInDataProto.BusinessDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                BusinessDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInDataProto.BusinessDataProto";
                };

                return BusinessDataProto;
            })();

            CheckInDataProto.MenuDataProto = (function() {

                /**
                 * Properties of a MenuDataProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @interface IMenuDataProto
                 * @property {waiternow.common.IMenuProto|null} [menu] MenuDataProto menu
                 * @property {waiternow.common.IStructuredMenuProto|null} [structuredMenu] MenuDataProto structuredMenu
                 * @property {google.protobuf.IDuration|null} [orderPreparationDuration] MenuDataProto orderPreparationDuration
                 */

                /**
                 * Constructs a new MenuDataProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @classdesc Represents a MenuDataProto.
                 * @implements IMenuDataProto
                 * @constructor
                 * @param {waiternow.common.CheckInDataProto.IMenuDataProto=} [properties] Properties to set
                 */
                function MenuDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuDataProto menu.
                 * @member {waiternow.common.IMenuProto|null|undefined} menu
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @instance
                 */
                MenuDataProto.prototype.menu = null;

                /**
                 * MenuDataProto structuredMenu.
                 * @member {waiternow.common.IStructuredMenuProto|null|undefined} structuredMenu
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @instance
                 */
                MenuDataProto.prototype.structuredMenu = null;

                /**
                 * MenuDataProto orderPreparationDuration.
                 * @member {google.protobuf.IDuration|null|undefined} orderPreparationDuration
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @instance
                 */
                MenuDataProto.prototype.orderPreparationDuration = null;

                /**
                 * Creates a new MenuDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IMenuDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInDataProto.MenuDataProto} MenuDataProto instance
                 */
                MenuDataProto.create = function create(properties) {
                    return new MenuDataProto(properties);
                };

                /**
                 * Encodes the specified MenuDataProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.MenuDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IMenuDataProto} message MenuDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.MenuProto.encode(message.menu, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.structuredMenu != null && Object.hasOwnProperty.call(message, "structuredMenu"))
                        $root.waiternow.common.StructuredMenuProto.encode(message.structuredMenu, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.orderPreparationDuration != null && Object.hasOwnProperty.call(message, "orderPreparationDuration"))
                        $root.google.protobuf.Duration.encode(message.orderPreparationDuration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified MenuDataProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.MenuDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IMenuDataProto} message MenuDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInDataProto.MenuDataProto} MenuDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto.MenuDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.menu = $root.waiternow.common.MenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.structuredMenu = $root.waiternow.common.StructuredMenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.orderPreparationDuration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInDataProto.MenuDataProto} MenuDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuDataProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.MenuProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    if (message.structuredMenu != null && message.hasOwnProperty("structuredMenu")) {
                        var error = $root.waiternow.common.StructuredMenuProto.verify(message.structuredMenu);
                        if (error)
                            return "structuredMenu." + error;
                    }
                    if (message.orderPreparationDuration != null && message.hasOwnProperty("orderPreparationDuration")) {
                        var error = $root.google.protobuf.Duration.verify(message.orderPreparationDuration);
                        if (error)
                            return "orderPreparationDuration." + error;
                    }
                    return null;
                };

                /**
                 * Creates a MenuDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInDataProto.MenuDataProto} MenuDataProto
                 */
                MenuDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInDataProto.MenuDataProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInDataProto.MenuDataProto();
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.MenuDataProto.menu: object expected");
                        message.menu = $root.waiternow.common.MenuProto.fromObject(object.menu);
                    }
                    if (object.structuredMenu != null) {
                        if (typeof object.structuredMenu !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.MenuDataProto.structuredMenu: object expected");
                        message.structuredMenu = $root.waiternow.common.StructuredMenuProto.fromObject(object.structuredMenu);
                    }
                    if (object.orderPreparationDuration != null) {
                        if (typeof object.orderPreparationDuration !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.MenuDataProto.orderPreparationDuration: object expected");
                        message.orderPreparationDuration = $root.google.protobuf.Duration.fromObject(object.orderPreparationDuration);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a MenuDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.MenuDataProto} message MenuDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.menu = null;
                        object.structuredMenu = null;
                        object.orderPreparationDuration = null;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.MenuProto.toObject(message.menu, options);
                    if (message.structuredMenu != null && message.hasOwnProperty("structuredMenu"))
                        object.structuredMenu = $root.waiternow.common.StructuredMenuProto.toObject(message.structuredMenu, options);
                    if (message.orderPreparationDuration != null && message.hasOwnProperty("orderPreparationDuration"))
                        object.orderPreparationDuration = $root.google.protobuf.Duration.toObject(message.orderPreparationDuration, options);
                    return object;
                };

                /**
                 * Converts this MenuDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInDataProto.MenuDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInDataProto.MenuDataProto";
                };

                return MenuDataProto;
            })();

            CheckInDataProto.PaymentsDataProto = (function() {

                /**
                 * Properties of a PaymentsDataProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @interface IPaymentsDataProto
                 * @property {string|null} [stripePublishableKey] PaymentsDataProto stripePublishableKey
                 * @property {string|null} [currencyCode] PaymentsDataProto currencyCode
                 * @property {waiternow.common.IDecimalProto|null} [salesTax] PaymentsDataProto salesTax
                 * @property {number|null} [maxAttemptsToMarkOrderAsPaid] PaymentsDataProto maxAttemptsToMarkOrderAsPaid
                 * @property {number|null} [timeBetweenAttemptsToMarkOrderAsPaidSeconds] PaymentsDataProto timeBetweenAttemptsToMarkOrderAsPaidSeconds
                 */

                /**
                 * Constructs a new PaymentsDataProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @classdesc Represents a PaymentsDataProto.
                 * @implements IPaymentsDataProto
                 * @constructor
                 * @param {waiternow.common.CheckInDataProto.IPaymentsDataProto=} [properties] Properties to set
                 */
                function PaymentsDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * PaymentsDataProto stripePublishableKey.
                 * @member {string} stripePublishableKey
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @instance
                 */
                PaymentsDataProto.prototype.stripePublishableKey = "";

                /**
                 * PaymentsDataProto currencyCode.
                 * @member {string} currencyCode
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @instance
                 */
                PaymentsDataProto.prototype.currencyCode = "";

                /**
                 * PaymentsDataProto salesTax.
                 * @member {waiternow.common.IDecimalProto|null|undefined} salesTax
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @instance
                 */
                PaymentsDataProto.prototype.salesTax = null;

                /**
                 * PaymentsDataProto maxAttemptsToMarkOrderAsPaid.
                 * @member {number} maxAttemptsToMarkOrderAsPaid
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @instance
                 */
                PaymentsDataProto.prototype.maxAttemptsToMarkOrderAsPaid = 0;

                /**
                 * PaymentsDataProto timeBetweenAttemptsToMarkOrderAsPaidSeconds.
                 * @member {number} timeBetweenAttemptsToMarkOrderAsPaidSeconds
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @instance
                 */
                PaymentsDataProto.prototype.timeBetweenAttemptsToMarkOrderAsPaidSeconds = 0;

                /**
                 * Creates a new PaymentsDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IPaymentsDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInDataProto.PaymentsDataProto} PaymentsDataProto instance
                 */
                PaymentsDataProto.create = function create(properties) {
                    return new PaymentsDataProto(properties);
                };

                /**
                 * Encodes the specified PaymentsDataProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.PaymentsDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IPaymentsDataProto} message PaymentsDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PaymentsDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.stripePublishableKey != null && Object.hasOwnProperty.call(message, "stripePublishableKey"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.stripePublishableKey);
                    if (message.currencyCode != null && Object.hasOwnProperty.call(message, "currencyCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.currencyCode);
                    if (message.salesTax != null && Object.hasOwnProperty.call(message, "salesTax"))
                        $root.waiternow.common.DecimalProto.encode(message.salesTax, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.maxAttemptsToMarkOrderAsPaid != null && Object.hasOwnProperty.call(message, "maxAttemptsToMarkOrderAsPaid"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.maxAttemptsToMarkOrderAsPaid);
                    if (message.timeBetweenAttemptsToMarkOrderAsPaidSeconds != null && Object.hasOwnProperty.call(message, "timeBetweenAttemptsToMarkOrderAsPaidSeconds"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.timeBetweenAttemptsToMarkOrderAsPaidSeconds);
                    return writer;
                };

                /**
                 * Encodes the specified PaymentsDataProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.PaymentsDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IPaymentsDataProto} message PaymentsDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                PaymentsDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a PaymentsDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInDataProto.PaymentsDataProto} PaymentsDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PaymentsDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto.PaymentsDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.stripePublishableKey = reader.string();
                                break;
                            }
                        case 2: {
                                message.currencyCode = reader.string();
                                break;
                            }
                        case 3: {
                                message.salesTax = $root.waiternow.common.DecimalProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.maxAttemptsToMarkOrderAsPaid = reader.int32();
                                break;
                            }
                        case 5: {
                                message.timeBetweenAttemptsToMarkOrderAsPaidSeconds = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a PaymentsDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInDataProto.PaymentsDataProto} PaymentsDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                PaymentsDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a PaymentsDataProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                PaymentsDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.stripePublishableKey != null && message.hasOwnProperty("stripePublishableKey"))
                        if (!$util.isString(message.stripePublishableKey))
                            return "stripePublishableKey: string expected";
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        if (!$util.isString(message.currencyCode))
                            return "currencyCode: string expected";
                    if (message.salesTax != null && message.hasOwnProperty("salesTax")) {
                        var error = $root.waiternow.common.DecimalProto.verify(message.salesTax);
                        if (error)
                            return "salesTax." + error;
                    }
                    if (message.maxAttemptsToMarkOrderAsPaid != null && message.hasOwnProperty("maxAttemptsToMarkOrderAsPaid"))
                        if (!$util.isInteger(message.maxAttemptsToMarkOrderAsPaid))
                            return "maxAttemptsToMarkOrderAsPaid: integer expected";
                    if (message.timeBetweenAttemptsToMarkOrderAsPaidSeconds != null && message.hasOwnProperty("timeBetweenAttemptsToMarkOrderAsPaidSeconds"))
                        if (!$util.isInteger(message.timeBetweenAttemptsToMarkOrderAsPaidSeconds))
                            return "timeBetweenAttemptsToMarkOrderAsPaidSeconds: integer expected";
                    return null;
                };

                /**
                 * Creates a PaymentsDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInDataProto.PaymentsDataProto} PaymentsDataProto
                 */
                PaymentsDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInDataProto.PaymentsDataProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInDataProto.PaymentsDataProto();
                    if (object.stripePublishableKey != null)
                        message.stripePublishableKey = String(object.stripePublishableKey);
                    if (object.currencyCode != null)
                        message.currencyCode = String(object.currencyCode);
                    if (object.salesTax != null) {
                        if (typeof object.salesTax !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.PaymentsDataProto.salesTax: object expected");
                        message.salesTax = $root.waiternow.common.DecimalProto.fromObject(object.salesTax);
                    }
                    if (object.maxAttemptsToMarkOrderAsPaid != null)
                        message.maxAttemptsToMarkOrderAsPaid = object.maxAttemptsToMarkOrderAsPaid | 0;
                    if (object.timeBetweenAttemptsToMarkOrderAsPaidSeconds != null)
                        message.timeBetweenAttemptsToMarkOrderAsPaidSeconds = object.timeBetweenAttemptsToMarkOrderAsPaidSeconds | 0;
                    return message;
                };

                /**
                 * Creates a plain object from a PaymentsDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.PaymentsDataProto} message PaymentsDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                PaymentsDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.stripePublishableKey = "";
                        object.currencyCode = "";
                        object.salesTax = null;
                        object.maxAttemptsToMarkOrderAsPaid = 0;
                        object.timeBetweenAttemptsToMarkOrderAsPaidSeconds = 0;
                    }
                    if (message.stripePublishableKey != null && message.hasOwnProperty("stripePublishableKey"))
                        object.stripePublishableKey = message.stripePublishableKey;
                    if (message.currencyCode != null && message.hasOwnProperty("currencyCode"))
                        object.currencyCode = message.currencyCode;
                    if (message.salesTax != null && message.hasOwnProperty("salesTax"))
                        object.salesTax = $root.waiternow.common.DecimalProto.toObject(message.salesTax, options);
                    if (message.maxAttemptsToMarkOrderAsPaid != null && message.hasOwnProperty("maxAttemptsToMarkOrderAsPaid"))
                        object.maxAttemptsToMarkOrderAsPaid = message.maxAttemptsToMarkOrderAsPaid;
                    if (message.timeBetweenAttemptsToMarkOrderAsPaidSeconds != null && message.hasOwnProperty("timeBetweenAttemptsToMarkOrderAsPaidSeconds"))
                        object.timeBetweenAttemptsToMarkOrderAsPaidSeconds = message.timeBetweenAttemptsToMarkOrderAsPaidSeconds;
                    return object;
                };

                /**
                 * Converts this PaymentsDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                PaymentsDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for PaymentsDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInDataProto.PaymentsDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                PaymentsDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInDataProto.PaymentsDataProto";
                };

                return PaymentsDataProto;
            })();

            CheckInDataProto.StatusProto = (function() {

                /**
                 * Properties of a StatusProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @interface IStatusProto
                 * @property {boolean|null} [isLocationOnline] StatusProto isLocationOnline
                 */

                /**
                 * Constructs a new StatusProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @classdesc Represents a StatusProto.
                 * @implements IStatusProto
                 * @constructor
                 * @param {waiternow.common.CheckInDataProto.IStatusProto=} [properties] Properties to set
                 */
                function StatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatusProto isLocationOnline.
                 * @member {boolean} isLocationOnline
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.isLocationOnline = false;

                /**
                 * Creates a new StatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInDataProto.StatusProto} StatusProto instance
                 */
                StatusProto.create = function create(properties) {
                    return new StatusProto(properties);
                };

                /**
                 * Encodes the specified StatusProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.StatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isLocationOnline != null && Object.hasOwnProperty.call(message, "isLocationOnline"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isLocationOnline);
                    return writer;
                };

                /**
                 * Encodes the specified StatusProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.StatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInDataProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto.StatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isLocationOnline = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInDataProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatusProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                        if (typeof message.isLocationOnline !== "boolean")
                            return "isLocationOnline: boolean expected";
                    return null;
                };

                /**
                 * Creates a StatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInDataProto.StatusProto} StatusProto
                 */
                StatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInDataProto.StatusProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInDataProto.StatusProto();
                    if (object.isLocationOnline != null)
                        message.isLocationOnline = Boolean(object.isLocationOnline);
                    return message;
                };

                /**
                 * Creates a plain object from a StatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.StatusProto} message StatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.isLocationOnline = false;
                    if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                        object.isLocationOnline = message.isLocationOnline;
                    return object;
                };

                /**
                 * Converts this StatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInDataProto.StatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInDataProto.StatusProto";
                };

                return StatusProto;
            })();

            CheckInDataProto.EnabledFeaturesProto = (function() {

                /**
                 * Properties of an EnabledFeaturesProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @interface IEnabledFeaturesProto
                 * @property {boolean|null} [isPaymentsEnabled] EnabledFeaturesProto isPaymentsEnabled
                 * @property {boolean|null} [isTipsEnabled] EnabledFeaturesProto isTipsEnabled
                 * @property {boolean|null} [isImageMenuEnabled] EnabledFeaturesProto isImageMenuEnabled
                 * @property {boolean|null} [isStructuredMenuEnabled] EnabledFeaturesProto isStructuredMenuEnabled
                 * @property {boolean|null} [isPokeWaiterEnabled] EnabledFeaturesProto isPokeWaiterEnabled
                 * @property {boolean|null} [isMessageWaiterEnabled] EnabledFeaturesProto isMessageWaiterEnabled
                 * @property {boolean|null} [isSpecialInstructionsEnabled] EnabledFeaturesProto isSpecialInstructionsEnabled
                 * @property {boolean|null} [isCompletedStatusForPaidOrdersEnabled] EnabledFeaturesProto isCompletedStatusForPaidOrdersEnabled
                 * @property {boolean|null} [isEmailRequiredToOrder] EnabledFeaturesProto isEmailRequiredToOrder
                 * @property {boolean|null} [isPhoneNumberRequiredToOrder] EnabledFeaturesProto isPhoneNumberRequiredToOrder
                 * @property {boolean|null} [goToStructuredMenuPageOnCheckIn] EnabledFeaturesProto goToStructuredMenuPageOnCheckIn
                 * @property {boolean|null} [isOrderToGoEnabled] EnabledFeaturesProto isOrderToGoEnabled
                 * @property {boolean|null} [isOrderForLaterEnabled] EnabledFeaturesProto isOrderForLaterEnabled
                 * @property {boolean|null} [isDeliveryEnabled] EnabledFeaturesProto isDeliveryEnabled
                 * @property {boolean|null} [isIncludeCutleryEnabled] EnabledFeaturesProto isIncludeCutleryEnabled
                 * @property {boolean|null} [isDeliveryTipsEnabled] EnabledFeaturesProto isDeliveryTipsEnabled
                 * @property {boolean|null} [isAskForCheckEnabled] EnabledFeaturesProto isAskForCheckEnabled
                 * @property {boolean|null} [isOrderForLaterDayEnabled] EnabledFeaturesProto isOrderForLaterDayEnabled
                 * @property {boolean|null} [isOnsiteClient] EnabledFeaturesProto isOnsiteClient
                 * @property {boolean|null} [isOnlineClient] EnabledFeaturesProto isOnlineClient
                 * @property {boolean|null} [isConsumerNameRequiredToOrder] EnabledFeaturesProto isConsumerNameRequiredToOrder
                 */

                /**
                 * Constructs a new EnabledFeaturesProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @classdesc Represents an EnabledFeaturesProto.
                 * @implements IEnabledFeaturesProto
                 * @constructor
                 * @param {waiternow.common.CheckInDataProto.IEnabledFeaturesProto=} [properties] Properties to set
                 */
                function EnabledFeaturesProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * EnabledFeaturesProto isPaymentsEnabled.
                 * @member {boolean} isPaymentsEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isPaymentsEnabled = false;

                /**
                 * EnabledFeaturesProto isTipsEnabled.
                 * @member {boolean} isTipsEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isTipsEnabled = false;

                /**
                 * EnabledFeaturesProto isImageMenuEnabled.
                 * @member {boolean} isImageMenuEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isImageMenuEnabled = false;

                /**
                 * EnabledFeaturesProto isStructuredMenuEnabled.
                 * @member {boolean} isStructuredMenuEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isStructuredMenuEnabled = false;

                /**
                 * EnabledFeaturesProto isPokeWaiterEnabled.
                 * @member {boolean} isPokeWaiterEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isPokeWaiterEnabled = false;

                /**
                 * EnabledFeaturesProto isMessageWaiterEnabled.
                 * @member {boolean} isMessageWaiterEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isMessageWaiterEnabled = false;

                /**
                 * EnabledFeaturesProto isSpecialInstructionsEnabled.
                 * @member {boolean} isSpecialInstructionsEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isSpecialInstructionsEnabled = false;

                /**
                 * EnabledFeaturesProto isCompletedStatusForPaidOrdersEnabled.
                 * @member {boolean} isCompletedStatusForPaidOrdersEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isCompletedStatusForPaidOrdersEnabled = false;

                /**
                 * EnabledFeaturesProto isEmailRequiredToOrder.
                 * @member {boolean} isEmailRequiredToOrder
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isEmailRequiredToOrder = false;

                /**
                 * EnabledFeaturesProto isPhoneNumberRequiredToOrder.
                 * @member {boolean} isPhoneNumberRequiredToOrder
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isPhoneNumberRequiredToOrder = false;

                /**
                 * EnabledFeaturesProto goToStructuredMenuPageOnCheckIn.
                 * @member {boolean} goToStructuredMenuPageOnCheckIn
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.goToStructuredMenuPageOnCheckIn = false;

                /**
                 * EnabledFeaturesProto isOrderToGoEnabled.
                 * @member {boolean} isOrderToGoEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isOrderToGoEnabled = false;

                /**
                 * EnabledFeaturesProto isOrderForLaterEnabled.
                 * @member {boolean} isOrderForLaterEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isOrderForLaterEnabled = false;

                /**
                 * EnabledFeaturesProto isDeliveryEnabled.
                 * @member {boolean} isDeliveryEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isDeliveryEnabled = false;

                /**
                 * EnabledFeaturesProto isIncludeCutleryEnabled.
                 * @member {boolean} isIncludeCutleryEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isIncludeCutleryEnabled = false;

                /**
                 * EnabledFeaturesProto isDeliveryTipsEnabled.
                 * @member {boolean} isDeliveryTipsEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isDeliveryTipsEnabled = false;

                /**
                 * EnabledFeaturesProto isAskForCheckEnabled.
                 * @member {boolean} isAskForCheckEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isAskForCheckEnabled = false;

                /**
                 * EnabledFeaturesProto isOrderForLaterDayEnabled.
                 * @member {boolean} isOrderForLaterDayEnabled
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isOrderForLaterDayEnabled = false;

                /**
                 * EnabledFeaturesProto isOnsiteClient.
                 * @member {boolean} isOnsiteClient
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isOnsiteClient = false;

                /**
                 * EnabledFeaturesProto isOnlineClient.
                 * @member {boolean} isOnlineClient
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isOnlineClient = false;

                /**
                 * EnabledFeaturesProto isConsumerNameRequiredToOrder.
                 * @member {boolean} isConsumerNameRequiredToOrder
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 */
                EnabledFeaturesProto.prototype.isConsumerNameRequiredToOrder = false;

                /**
                 * Creates a new EnabledFeaturesProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IEnabledFeaturesProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInDataProto.EnabledFeaturesProto} EnabledFeaturesProto instance
                 */
                EnabledFeaturesProto.create = function create(properties) {
                    return new EnabledFeaturesProto(properties);
                };

                /**
                 * Encodes the specified EnabledFeaturesProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.EnabledFeaturesProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IEnabledFeaturesProto} message EnabledFeaturesProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnabledFeaturesProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isPaymentsEnabled != null && Object.hasOwnProperty.call(message, "isPaymentsEnabled"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isPaymentsEnabled);
                    if (message.isTipsEnabled != null && Object.hasOwnProperty.call(message, "isTipsEnabled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isTipsEnabled);
                    if (message.isImageMenuEnabled != null && Object.hasOwnProperty.call(message, "isImageMenuEnabled"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isImageMenuEnabled);
                    if (message.isStructuredMenuEnabled != null && Object.hasOwnProperty.call(message, "isStructuredMenuEnabled"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isStructuredMenuEnabled);
                    if (message.isPokeWaiterEnabled != null && Object.hasOwnProperty.call(message, "isPokeWaiterEnabled"))
                        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.isPokeWaiterEnabled);
                    if (message.isMessageWaiterEnabled != null && Object.hasOwnProperty.call(message, "isMessageWaiterEnabled"))
                        writer.uint32(/* id 6, wireType 0 =*/48).bool(message.isMessageWaiterEnabled);
                    if (message.isSpecialInstructionsEnabled != null && Object.hasOwnProperty.call(message, "isSpecialInstructionsEnabled"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isSpecialInstructionsEnabled);
                    if (message.isCompletedStatusForPaidOrdersEnabled != null && Object.hasOwnProperty.call(message, "isCompletedStatusForPaidOrdersEnabled"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isCompletedStatusForPaidOrdersEnabled);
                    if (message.isEmailRequiredToOrder != null && Object.hasOwnProperty.call(message, "isEmailRequiredToOrder"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isEmailRequiredToOrder);
                    if (message.isPhoneNumberRequiredToOrder != null && Object.hasOwnProperty.call(message, "isPhoneNumberRequiredToOrder"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isPhoneNumberRequiredToOrder);
                    if (message.goToStructuredMenuPageOnCheckIn != null && Object.hasOwnProperty.call(message, "goToStructuredMenuPageOnCheckIn"))
                        writer.uint32(/* id 11, wireType 0 =*/88).bool(message.goToStructuredMenuPageOnCheckIn);
                    if (message.isOrderToGoEnabled != null && Object.hasOwnProperty.call(message, "isOrderToGoEnabled"))
                        writer.uint32(/* id 12, wireType 0 =*/96).bool(message.isOrderToGoEnabled);
                    if (message.isOrderForLaterEnabled != null && Object.hasOwnProperty.call(message, "isOrderForLaterEnabled"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isOrderForLaterEnabled);
                    if (message.isDeliveryEnabled != null && Object.hasOwnProperty.call(message, "isDeliveryEnabled"))
                        writer.uint32(/* id 14, wireType 0 =*/112).bool(message.isDeliveryEnabled);
                    if (message.isIncludeCutleryEnabled != null && Object.hasOwnProperty.call(message, "isIncludeCutleryEnabled"))
                        writer.uint32(/* id 15, wireType 0 =*/120).bool(message.isIncludeCutleryEnabled);
                    if (message.isDeliveryTipsEnabled != null && Object.hasOwnProperty.call(message, "isDeliveryTipsEnabled"))
                        writer.uint32(/* id 16, wireType 0 =*/128).bool(message.isDeliveryTipsEnabled);
                    if (message.isAskForCheckEnabled != null && Object.hasOwnProperty.call(message, "isAskForCheckEnabled"))
                        writer.uint32(/* id 17, wireType 0 =*/136).bool(message.isAskForCheckEnabled);
                    if (message.isOrderForLaterDayEnabled != null && Object.hasOwnProperty.call(message, "isOrderForLaterDayEnabled"))
                        writer.uint32(/* id 18, wireType 0 =*/144).bool(message.isOrderForLaterDayEnabled);
                    if (message.isOnsiteClient != null && Object.hasOwnProperty.call(message, "isOnsiteClient"))
                        writer.uint32(/* id 19, wireType 0 =*/152).bool(message.isOnsiteClient);
                    if (message.isOnlineClient != null && Object.hasOwnProperty.call(message, "isOnlineClient"))
                        writer.uint32(/* id 20, wireType 0 =*/160).bool(message.isOnlineClient);
                    if (message.isConsumerNameRequiredToOrder != null && Object.hasOwnProperty.call(message, "isConsumerNameRequiredToOrder"))
                        writer.uint32(/* id 21, wireType 0 =*/168).bool(message.isConsumerNameRequiredToOrder);
                    return writer;
                };

                /**
                 * Encodes the specified EnabledFeaturesProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.EnabledFeaturesProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IEnabledFeaturesProto} message EnabledFeaturesProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                EnabledFeaturesProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an EnabledFeaturesProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInDataProto.EnabledFeaturesProto} EnabledFeaturesProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnabledFeaturesProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isPaymentsEnabled = reader.bool();
                                break;
                            }
                        case 2: {
                                message.isTipsEnabled = reader.bool();
                                break;
                            }
                        case 3: {
                                message.isImageMenuEnabled = reader.bool();
                                break;
                            }
                        case 4: {
                                message.isStructuredMenuEnabled = reader.bool();
                                break;
                            }
                        case 5: {
                                message.isPokeWaiterEnabled = reader.bool();
                                break;
                            }
                        case 6: {
                                message.isMessageWaiterEnabled = reader.bool();
                                break;
                            }
                        case 7: {
                                message.isSpecialInstructionsEnabled = reader.bool();
                                break;
                            }
                        case 8: {
                                message.isCompletedStatusForPaidOrdersEnabled = reader.bool();
                                break;
                            }
                        case 9: {
                                message.isEmailRequiredToOrder = reader.bool();
                                break;
                            }
                        case 10: {
                                message.isPhoneNumberRequiredToOrder = reader.bool();
                                break;
                            }
                        case 11: {
                                message.goToStructuredMenuPageOnCheckIn = reader.bool();
                                break;
                            }
                        case 12: {
                                message.isOrderToGoEnabled = reader.bool();
                                break;
                            }
                        case 13: {
                                message.isOrderForLaterEnabled = reader.bool();
                                break;
                            }
                        case 14: {
                                message.isDeliveryEnabled = reader.bool();
                                break;
                            }
                        case 15: {
                                message.isIncludeCutleryEnabled = reader.bool();
                                break;
                            }
                        case 16: {
                                message.isDeliveryTipsEnabled = reader.bool();
                                break;
                            }
                        case 17: {
                                message.isAskForCheckEnabled = reader.bool();
                                break;
                            }
                        case 18: {
                                message.isOrderForLaterDayEnabled = reader.bool();
                                break;
                            }
                        case 19: {
                                message.isOnsiteClient = reader.bool();
                                break;
                            }
                        case 20: {
                                message.isOnlineClient = reader.bool();
                                break;
                            }
                        case 21: {
                                message.isConsumerNameRequiredToOrder = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an EnabledFeaturesProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInDataProto.EnabledFeaturesProto} EnabledFeaturesProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                EnabledFeaturesProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an EnabledFeaturesProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                EnabledFeaturesProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isPaymentsEnabled != null && message.hasOwnProperty("isPaymentsEnabled"))
                        if (typeof message.isPaymentsEnabled !== "boolean")
                            return "isPaymentsEnabled: boolean expected";
                    if (message.isTipsEnabled != null && message.hasOwnProperty("isTipsEnabled"))
                        if (typeof message.isTipsEnabled !== "boolean")
                            return "isTipsEnabled: boolean expected";
                    if (message.isImageMenuEnabled != null && message.hasOwnProperty("isImageMenuEnabled"))
                        if (typeof message.isImageMenuEnabled !== "boolean")
                            return "isImageMenuEnabled: boolean expected";
                    if (message.isStructuredMenuEnabled != null && message.hasOwnProperty("isStructuredMenuEnabled"))
                        if (typeof message.isStructuredMenuEnabled !== "boolean")
                            return "isStructuredMenuEnabled: boolean expected";
                    if (message.isPokeWaiterEnabled != null && message.hasOwnProperty("isPokeWaiterEnabled"))
                        if (typeof message.isPokeWaiterEnabled !== "boolean")
                            return "isPokeWaiterEnabled: boolean expected";
                    if (message.isMessageWaiterEnabled != null && message.hasOwnProperty("isMessageWaiterEnabled"))
                        if (typeof message.isMessageWaiterEnabled !== "boolean")
                            return "isMessageWaiterEnabled: boolean expected";
                    if (message.isSpecialInstructionsEnabled != null && message.hasOwnProperty("isSpecialInstructionsEnabled"))
                        if (typeof message.isSpecialInstructionsEnabled !== "boolean")
                            return "isSpecialInstructionsEnabled: boolean expected";
                    if (message.isCompletedStatusForPaidOrdersEnabled != null && message.hasOwnProperty("isCompletedStatusForPaidOrdersEnabled"))
                        if (typeof message.isCompletedStatusForPaidOrdersEnabled !== "boolean")
                            return "isCompletedStatusForPaidOrdersEnabled: boolean expected";
                    if (message.isEmailRequiredToOrder != null && message.hasOwnProperty("isEmailRequiredToOrder"))
                        if (typeof message.isEmailRequiredToOrder !== "boolean")
                            return "isEmailRequiredToOrder: boolean expected";
                    if (message.isPhoneNumberRequiredToOrder != null && message.hasOwnProperty("isPhoneNumberRequiredToOrder"))
                        if (typeof message.isPhoneNumberRequiredToOrder !== "boolean")
                            return "isPhoneNumberRequiredToOrder: boolean expected";
                    if (message.goToStructuredMenuPageOnCheckIn != null && message.hasOwnProperty("goToStructuredMenuPageOnCheckIn"))
                        if (typeof message.goToStructuredMenuPageOnCheckIn !== "boolean")
                            return "goToStructuredMenuPageOnCheckIn: boolean expected";
                    if (message.isOrderToGoEnabled != null && message.hasOwnProperty("isOrderToGoEnabled"))
                        if (typeof message.isOrderToGoEnabled !== "boolean")
                            return "isOrderToGoEnabled: boolean expected";
                    if (message.isOrderForLaterEnabled != null && message.hasOwnProperty("isOrderForLaterEnabled"))
                        if (typeof message.isOrderForLaterEnabled !== "boolean")
                            return "isOrderForLaterEnabled: boolean expected";
                    if (message.isDeliveryEnabled != null && message.hasOwnProperty("isDeliveryEnabled"))
                        if (typeof message.isDeliveryEnabled !== "boolean")
                            return "isDeliveryEnabled: boolean expected";
                    if (message.isIncludeCutleryEnabled != null && message.hasOwnProperty("isIncludeCutleryEnabled"))
                        if (typeof message.isIncludeCutleryEnabled !== "boolean")
                            return "isIncludeCutleryEnabled: boolean expected";
                    if (message.isDeliveryTipsEnabled != null && message.hasOwnProperty("isDeliveryTipsEnabled"))
                        if (typeof message.isDeliveryTipsEnabled !== "boolean")
                            return "isDeliveryTipsEnabled: boolean expected";
                    if (message.isAskForCheckEnabled != null && message.hasOwnProperty("isAskForCheckEnabled"))
                        if (typeof message.isAskForCheckEnabled !== "boolean")
                            return "isAskForCheckEnabled: boolean expected";
                    if (message.isOrderForLaterDayEnabled != null && message.hasOwnProperty("isOrderForLaterDayEnabled"))
                        if (typeof message.isOrderForLaterDayEnabled !== "boolean")
                            return "isOrderForLaterDayEnabled: boolean expected";
                    if (message.isOnsiteClient != null && message.hasOwnProperty("isOnsiteClient"))
                        if (typeof message.isOnsiteClient !== "boolean")
                            return "isOnsiteClient: boolean expected";
                    if (message.isOnlineClient != null && message.hasOwnProperty("isOnlineClient"))
                        if (typeof message.isOnlineClient !== "boolean")
                            return "isOnlineClient: boolean expected";
                    if (message.isConsumerNameRequiredToOrder != null && message.hasOwnProperty("isConsumerNameRequiredToOrder"))
                        if (typeof message.isConsumerNameRequiredToOrder !== "boolean")
                            return "isConsumerNameRequiredToOrder: boolean expected";
                    return null;
                };

                /**
                 * Creates an EnabledFeaturesProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInDataProto.EnabledFeaturesProto} EnabledFeaturesProto
                 */
                EnabledFeaturesProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInDataProto.EnabledFeaturesProto();
                    if (object.isPaymentsEnabled != null)
                        message.isPaymentsEnabled = Boolean(object.isPaymentsEnabled);
                    if (object.isTipsEnabled != null)
                        message.isTipsEnabled = Boolean(object.isTipsEnabled);
                    if (object.isImageMenuEnabled != null)
                        message.isImageMenuEnabled = Boolean(object.isImageMenuEnabled);
                    if (object.isStructuredMenuEnabled != null)
                        message.isStructuredMenuEnabled = Boolean(object.isStructuredMenuEnabled);
                    if (object.isPokeWaiterEnabled != null)
                        message.isPokeWaiterEnabled = Boolean(object.isPokeWaiterEnabled);
                    if (object.isMessageWaiterEnabled != null)
                        message.isMessageWaiterEnabled = Boolean(object.isMessageWaiterEnabled);
                    if (object.isSpecialInstructionsEnabled != null)
                        message.isSpecialInstructionsEnabled = Boolean(object.isSpecialInstructionsEnabled);
                    if (object.isCompletedStatusForPaidOrdersEnabled != null)
                        message.isCompletedStatusForPaidOrdersEnabled = Boolean(object.isCompletedStatusForPaidOrdersEnabled);
                    if (object.isEmailRequiredToOrder != null)
                        message.isEmailRequiredToOrder = Boolean(object.isEmailRequiredToOrder);
                    if (object.isPhoneNumberRequiredToOrder != null)
                        message.isPhoneNumberRequiredToOrder = Boolean(object.isPhoneNumberRequiredToOrder);
                    if (object.goToStructuredMenuPageOnCheckIn != null)
                        message.goToStructuredMenuPageOnCheckIn = Boolean(object.goToStructuredMenuPageOnCheckIn);
                    if (object.isOrderToGoEnabled != null)
                        message.isOrderToGoEnabled = Boolean(object.isOrderToGoEnabled);
                    if (object.isOrderForLaterEnabled != null)
                        message.isOrderForLaterEnabled = Boolean(object.isOrderForLaterEnabled);
                    if (object.isDeliveryEnabled != null)
                        message.isDeliveryEnabled = Boolean(object.isDeliveryEnabled);
                    if (object.isIncludeCutleryEnabled != null)
                        message.isIncludeCutleryEnabled = Boolean(object.isIncludeCutleryEnabled);
                    if (object.isDeliveryTipsEnabled != null)
                        message.isDeliveryTipsEnabled = Boolean(object.isDeliveryTipsEnabled);
                    if (object.isAskForCheckEnabled != null)
                        message.isAskForCheckEnabled = Boolean(object.isAskForCheckEnabled);
                    if (object.isOrderForLaterDayEnabled != null)
                        message.isOrderForLaterDayEnabled = Boolean(object.isOrderForLaterDayEnabled);
                    if (object.isOnsiteClient != null)
                        message.isOnsiteClient = Boolean(object.isOnsiteClient);
                    if (object.isOnlineClient != null)
                        message.isOnlineClient = Boolean(object.isOnlineClient);
                    if (object.isConsumerNameRequiredToOrder != null)
                        message.isConsumerNameRequiredToOrder = Boolean(object.isConsumerNameRequiredToOrder);
                    return message;
                };

                /**
                 * Creates a plain object from an EnabledFeaturesProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.EnabledFeaturesProto} message EnabledFeaturesProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                EnabledFeaturesProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isPaymentsEnabled = false;
                        object.isTipsEnabled = false;
                        object.isImageMenuEnabled = false;
                        object.isStructuredMenuEnabled = false;
                        object.isPokeWaiterEnabled = false;
                        object.isMessageWaiterEnabled = false;
                        object.isSpecialInstructionsEnabled = false;
                        object.isCompletedStatusForPaidOrdersEnabled = false;
                        object.isEmailRequiredToOrder = false;
                        object.isPhoneNumberRequiredToOrder = false;
                        object.goToStructuredMenuPageOnCheckIn = false;
                        object.isOrderToGoEnabled = false;
                        object.isOrderForLaterEnabled = false;
                        object.isDeliveryEnabled = false;
                        object.isIncludeCutleryEnabled = false;
                        object.isDeliveryTipsEnabled = false;
                        object.isAskForCheckEnabled = false;
                        object.isOrderForLaterDayEnabled = false;
                        object.isOnsiteClient = false;
                        object.isOnlineClient = false;
                        object.isConsumerNameRequiredToOrder = false;
                    }
                    if (message.isPaymentsEnabled != null && message.hasOwnProperty("isPaymentsEnabled"))
                        object.isPaymentsEnabled = message.isPaymentsEnabled;
                    if (message.isTipsEnabled != null && message.hasOwnProperty("isTipsEnabled"))
                        object.isTipsEnabled = message.isTipsEnabled;
                    if (message.isImageMenuEnabled != null && message.hasOwnProperty("isImageMenuEnabled"))
                        object.isImageMenuEnabled = message.isImageMenuEnabled;
                    if (message.isStructuredMenuEnabled != null && message.hasOwnProperty("isStructuredMenuEnabled"))
                        object.isStructuredMenuEnabled = message.isStructuredMenuEnabled;
                    if (message.isPokeWaiterEnabled != null && message.hasOwnProperty("isPokeWaiterEnabled"))
                        object.isPokeWaiterEnabled = message.isPokeWaiterEnabled;
                    if (message.isMessageWaiterEnabled != null && message.hasOwnProperty("isMessageWaiterEnabled"))
                        object.isMessageWaiterEnabled = message.isMessageWaiterEnabled;
                    if (message.isSpecialInstructionsEnabled != null && message.hasOwnProperty("isSpecialInstructionsEnabled"))
                        object.isSpecialInstructionsEnabled = message.isSpecialInstructionsEnabled;
                    if (message.isCompletedStatusForPaidOrdersEnabled != null && message.hasOwnProperty("isCompletedStatusForPaidOrdersEnabled"))
                        object.isCompletedStatusForPaidOrdersEnabled = message.isCompletedStatusForPaidOrdersEnabled;
                    if (message.isEmailRequiredToOrder != null && message.hasOwnProperty("isEmailRequiredToOrder"))
                        object.isEmailRequiredToOrder = message.isEmailRequiredToOrder;
                    if (message.isPhoneNumberRequiredToOrder != null && message.hasOwnProperty("isPhoneNumberRequiredToOrder"))
                        object.isPhoneNumberRequiredToOrder = message.isPhoneNumberRequiredToOrder;
                    if (message.goToStructuredMenuPageOnCheckIn != null && message.hasOwnProperty("goToStructuredMenuPageOnCheckIn"))
                        object.goToStructuredMenuPageOnCheckIn = message.goToStructuredMenuPageOnCheckIn;
                    if (message.isOrderToGoEnabled != null && message.hasOwnProperty("isOrderToGoEnabled"))
                        object.isOrderToGoEnabled = message.isOrderToGoEnabled;
                    if (message.isOrderForLaterEnabled != null && message.hasOwnProperty("isOrderForLaterEnabled"))
                        object.isOrderForLaterEnabled = message.isOrderForLaterEnabled;
                    if (message.isDeliveryEnabled != null && message.hasOwnProperty("isDeliveryEnabled"))
                        object.isDeliveryEnabled = message.isDeliveryEnabled;
                    if (message.isIncludeCutleryEnabled != null && message.hasOwnProperty("isIncludeCutleryEnabled"))
                        object.isIncludeCutleryEnabled = message.isIncludeCutleryEnabled;
                    if (message.isDeliveryTipsEnabled != null && message.hasOwnProperty("isDeliveryTipsEnabled"))
                        object.isDeliveryTipsEnabled = message.isDeliveryTipsEnabled;
                    if (message.isAskForCheckEnabled != null && message.hasOwnProperty("isAskForCheckEnabled"))
                        object.isAskForCheckEnabled = message.isAskForCheckEnabled;
                    if (message.isOrderForLaterDayEnabled != null && message.hasOwnProperty("isOrderForLaterDayEnabled"))
                        object.isOrderForLaterDayEnabled = message.isOrderForLaterDayEnabled;
                    if (message.isOnsiteClient != null && message.hasOwnProperty("isOnsiteClient"))
                        object.isOnsiteClient = message.isOnsiteClient;
                    if (message.isOnlineClient != null && message.hasOwnProperty("isOnlineClient"))
                        object.isOnlineClient = message.isOnlineClient;
                    if (message.isConsumerNameRequiredToOrder != null && message.hasOwnProperty("isConsumerNameRequiredToOrder"))
                        object.isConsumerNameRequiredToOrder = message.isConsumerNameRequiredToOrder;
                    return object;
                };

                /**
                 * Converts this EnabledFeaturesProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                EnabledFeaturesProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for EnabledFeaturesProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInDataProto.EnabledFeaturesProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                EnabledFeaturesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInDataProto.EnabledFeaturesProto";
                };

                return EnabledFeaturesProto;
            })();

            CheckInDataProto.ConfigurationProto = (function() {

                /**
                 * Properties of a ConfigurationProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @interface IConfigurationProto
                 * @property {google.protobuf.IDuration|null} [sessionExpiration] ConfigurationProto sessionExpiration
                 * @property {google.protobuf.IDuration|null} [orderStatusPollRate] ConfigurationProto orderStatusPollRate
                 * @property {google.protobuf.IDuration|null} [locationOnlineStatusPollRate] ConfigurationProto locationOnlineStatusPollRate
                 * @property {number|null} [orderForLaterMaxDaysInTheFuture] ConfigurationProto orderForLaterMaxDaysInTheFuture
                 * @property {string|null} [googleMapsServicesApiKey] ConfigurationProto googleMapsServicesApiKey
                 */

                /**
                 * Constructs a new ConfigurationProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @classdesc Represents a ConfigurationProto.
                 * @implements IConfigurationProto
                 * @constructor
                 * @param {waiternow.common.CheckInDataProto.IConfigurationProto=} [properties] Properties to set
                 */
                function ConfigurationProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ConfigurationProto sessionExpiration.
                 * @member {google.protobuf.IDuration|null|undefined} sessionExpiration
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @instance
                 */
                ConfigurationProto.prototype.sessionExpiration = null;

                /**
                 * ConfigurationProto orderStatusPollRate.
                 * @member {google.protobuf.IDuration|null|undefined} orderStatusPollRate
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @instance
                 */
                ConfigurationProto.prototype.orderStatusPollRate = null;

                /**
                 * ConfigurationProto locationOnlineStatusPollRate.
                 * @member {google.protobuf.IDuration|null|undefined} locationOnlineStatusPollRate
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @instance
                 */
                ConfigurationProto.prototype.locationOnlineStatusPollRate = null;

                /**
                 * ConfigurationProto orderForLaterMaxDaysInTheFuture.
                 * @member {number} orderForLaterMaxDaysInTheFuture
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @instance
                 */
                ConfigurationProto.prototype.orderForLaterMaxDaysInTheFuture = 0;

                /**
                 * ConfigurationProto googleMapsServicesApiKey.
                 * @member {string} googleMapsServicesApiKey
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @instance
                 */
                ConfigurationProto.prototype.googleMapsServicesApiKey = "";

                /**
                 * Creates a new ConfigurationProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IConfigurationProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInDataProto.ConfigurationProto} ConfigurationProto instance
                 */
                ConfigurationProto.create = function create(properties) {
                    return new ConfigurationProto(properties);
                };

                /**
                 * Encodes the specified ConfigurationProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.ConfigurationProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IConfigurationProto} message ConfigurationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfigurationProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.sessionExpiration != null && Object.hasOwnProperty.call(message, "sessionExpiration"))
                        $root.google.protobuf.Duration.encode(message.sessionExpiration, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.orderStatusPollRate != null && Object.hasOwnProperty.call(message, "orderStatusPollRate"))
                        $root.google.protobuf.Duration.encode(message.orderStatusPollRate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.locationOnlineStatusPollRate != null && Object.hasOwnProperty.call(message, "locationOnlineStatusPollRate"))
                        $root.google.protobuf.Duration.encode(message.locationOnlineStatusPollRate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.orderForLaterMaxDaysInTheFuture != null && Object.hasOwnProperty.call(message, "orderForLaterMaxDaysInTheFuture"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.orderForLaterMaxDaysInTheFuture);
                    if (message.googleMapsServicesApiKey != null && Object.hasOwnProperty.call(message, "googleMapsServicesApiKey"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.googleMapsServicesApiKey);
                    return writer;
                };

                /**
                 * Encodes the specified ConfigurationProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.ConfigurationProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IConfigurationProto} message ConfigurationProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ConfigurationProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ConfigurationProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInDataProto.ConfigurationProto} ConfigurationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfigurationProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto.ConfigurationProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.sessionExpiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.orderStatusPollRate = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.locationOnlineStatusPollRate = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.orderForLaterMaxDaysInTheFuture = reader.int32();
                                break;
                            }
                        case 5: {
                                message.googleMapsServicesApiKey = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ConfigurationProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInDataProto.ConfigurationProto} ConfigurationProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ConfigurationProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ConfigurationProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ConfigurationProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.sessionExpiration != null && message.hasOwnProperty("sessionExpiration")) {
                        var error = $root.google.protobuf.Duration.verify(message.sessionExpiration);
                        if (error)
                            return "sessionExpiration." + error;
                    }
                    if (message.orderStatusPollRate != null && message.hasOwnProperty("orderStatusPollRate")) {
                        var error = $root.google.protobuf.Duration.verify(message.orderStatusPollRate);
                        if (error)
                            return "orderStatusPollRate." + error;
                    }
                    if (message.locationOnlineStatusPollRate != null && message.hasOwnProperty("locationOnlineStatusPollRate")) {
                        var error = $root.google.protobuf.Duration.verify(message.locationOnlineStatusPollRate);
                        if (error)
                            return "locationOnlineStatusPollRate." + error;
                    }
                    if (message.orderForLaterMaxDaysInTheFuture != null && message.hasOwnProperty("orderForLaterMaxDaysInTheFuture"))
                        if (!$util.isInteger(message.orderForLaterMaxDaysInTheFuture))
                            return "orderForLaterMaxDaysInTheFuture: integer expected";
                    if (message.googleMapsServicesApiKey != null && message.hasOwnProperty("googleMapsServicesApiKey"))
                        if (!$util.isString(message.googleMapsServicesApiKey))
                            return "googleMapsServicesApiKey: string expected";
                    return null;
                };

                /**
                 * Creates a ConfigurationProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInDataProto.ConfigurationProto} ConfigurationProto
                 */
                ConfigurationProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInDataProto.ConfigurationProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInDataProto.ConfigurationProto();
                    if (object.sessionExpiration != null) {
                        if (typeof object.sessionExpiration !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.ConfigurationProto.sessionExpiration: object expected");
                        message.sessionExpiration = $root.google.protobuf.Duration.fromObject(object.sessionExpiration);
                    }
                    if (object.orderStatusPollRate != null) {
                        if (typeof object.orderStatusPollRate !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.ConfigurationProto.orderStatusPollRate: object expected");
                        message.orderStatusPollRate = $root.google.protobuf.Duration.fromObject(object.orderStatusPollRate);
                    }
                    if (object.locationOnlineStatusPollRate != null) {
                        if (typeof object.locationOnlineStatusPollRate !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.ConfigurationProto.locationOnlineStatusPollRate: object expected");
                        message.locationOnlineStatusPollRate = $root.google.protobuf.Duration.fromObject(object.locationOnlineStatusPollRate);
                    }
                    if (object.orderForLaterMaxDaysInTheFuture != null)
                        message.orderForLaterMaxDaysInTheFuture = object.orderForLaterMaxDaysInTheFuture | 0;
                    if (object.googleMapsServicesApiKey != null)
                        message.googleMapsServicesApiKey = String(object.googleMapsServicesApiKey);
                    return message;
                };

                /**
                 * Creates a plain object from a ConfigurationProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.ConfigurationProto} message ConfigurationProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ConfigurationProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sessionExpiration = null;
                        object.orderStatusPollRate = null;
                        object.locationOnlineStatusPollRate = null;
                        object.orderForLaterMaxDaysInTheFuture = 0;
                        object.googleMapsServicesApiKey = "";
                    }
                    if (message.sessionExpiration != null && message.hasOwnProperty("sessionExpiration"))
                        object.sessionExpiration = $root.google.protobuf.Duration.toObject(message.sessionExpiration, options);
                    if (message.orderStatusPollRate != null && message.hasOwnProperty("orderStatusPollRate"))
                        object.orderStatusPollRate = $root.google.protobuf.Duration.toObject(message.orderStatusPollRate, options);
                    if (message.locationOnlineStatusPollRate != null && message.hasOwnProperty("locationOnlineStatusPollRate"))
                        object.locationOnlineStatusPollRate = $root.google.protobuf.Duration.toObject(message.locationOnlineStatusPollRate, options);
                    if (message.orderForLaterMaxDaysInTheFuture != null && message.hasOwnProperty("orderForLaterMaxDaysInTheFuture"))
                        object.orderForLaterMaxDaysInTheFuture = message.orderForLaterMaxDaysInTheFuture;
                    if (message.googleMapsServicesApiKey != null && message.hasOwnProperty("googleMapsServicesApiKey"))
                        object.googleMapsServicesApiKey = message.googleMapsServicesApiKey;
                    return object;
                };

                /**
                 * Converts this ConfigurationProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ConfigurationProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ConfigurationProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInDataProto.ConfigurationProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ConfigurationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInDataProto.ConfigurationProto";
                };

                return ConfigurationProto;
            })();

            CheckInDataProto.ViewSettingsProto = (function() {

                /**
                 * Properties of a ViewSettingsProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @interface IViewSettingsProto
                 * @property {waiternow.common.Theme|null} [theme] ViewSettingsProto theme
                 * @property {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto|null} [colorPalette] ViewSettingsProto colorPalette
                 */

                /**
                 * Constructs a new ViewSettingsProto.
                 * @memberof waiternow.common.CheckInDataProto
                 * @classdesc Represents a ViewSettingsProto.
                 * @implements IViewSettingsProto
                 * @constructor
                 * @param {waiternow.common.CheckInDataProto.IViewSettingsProto=} [properties] Properties to set
                 */
                function ViewSettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * ViewSettingsProto theme.
                 * @member {waiternow.common.Theme} theme
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @instance
                 */
                ViewSettingsProto.prototype.theme = 0;

                /**
                 * ViewSettingsProto colorPalette.
                 * @member {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto|null|undefined} colorPalette
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @instance
                 */
                ViewSettingsProto.prototype.colorPalette = null;

                /**
                 * Creates a new ViewSettingsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IViewSettingsProto=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInDataProto.ViewSettingsProto} ViewSettingsProto instance
                 */
                ViewSettingsProto.create = function create(properties) {
                    return new ViewSettingsProto(properties);
                };

                /**
                 * Encodes the specified ViewSettingsProto message. Does not implicitly {@link waiternow.common.CheckInDataProto.ViewSettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IViewSettingsProto} message ViewSettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ViewSettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.theme != null && Object.hasOwnProperty.call(message, "theme"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.theme);
                    if (message.colorPalette != null && Object.hasOwnProperty.call(message, "colorPalette"))
                        $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.encode(message.colorPalette, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified ViewSettingsProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInDataProto.ViewSettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.IViewSettingsProto} message ViewSettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                ViewSettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a ViewSettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInDataProto.ViewSettingsProto} ViewSettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ViewSettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInDataProto.ViewSettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.theme = reader.int32();
                                break;
                            }
                        case 2: {
                                message.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a ViewSettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInDataProto.ViewSettingsProto} ViewSettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                ViewSettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a ViewSettingsProto message.
                 * @function verify
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                ViewSettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.theme != null && message.hasOwnProperty("theme"))
                        switch (message.theme) {
                        default:
                            return "theme: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.colorPalette != null && message.hasOwnProperty("colorPalette")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.verify(message.colorPalette);
                        if (error)
                            return "colorPalette." + error;
                    }
                    return null;
                };

                /**
                 * Creates a ViewSettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInDataProto.ViewSettingsProto} ViewSettingsProto
                 */
                ViewSettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInDataProto.ViewSettingsProto)
                        return object;
                    var message = new $root.waiternow.common.CheckInDataProto.ViewSettingsProto();
                    switch (object.theme) {
                    default:
                        if (typeof object.theme === "number") {
                            message.theme = object.theme;
                            break;
                        }
                        break;
                    case "THEME_UNSPECIFIED":
                    case 0:
                        message.theme = 0;
                        break;
                    case "DARK":
                    case 1:
                        message.theme = 1;
                        break;
                    case "LIGHT":
                    case 2:
                        message.theme = 2;
                        break;
                    }
                    if (object.colorPalette != null) {
                        if (typeof object.colorPalette !== "object")
                            throw TypeError(".waiternow.common.CheckInDataProto.ViewSettingsProto.colorPalette: object expected");
                        message.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.fromObject(object.colorPalette);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a ViewSettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {waiternow.common.CheckInDataProto.ViewSettingsProto} message ViewSettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                ViewSettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.theme = options.enums === String ? "THEME_UNSPECIFIED" : 0;
                        object.colorPalette = null;
                    }
                    if (message.theme != null && message.hasOwnProperty("theme"))
                        object.theme = options.enums === String ? $root.waiternow.common.Theme[message.theme] === undefined ? message.theme : $root.waiternow.common.Theme[message.theme] : message.theme;
                    if (message.colorPalette != null && message.hasOwnProperty("colorPalette"))
                        object.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.toObject(message.colorPalette, options);
                    return object;
                };

                /**
                 * Converts this ViewSettingsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                ViewSettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for ViewSettingsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInDataProto.ViewSettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                ViewSettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInDataProto.ViewSettingsProto";
                };

                return ViewSettingsProto;
            })();

            return CheckInDataProto;
        })();

        common.RegisterClientLogActionProto = (function() {

            /**
             * Properties of a RegisterClientLogActionProto.
             * @memberof waiternow.common
             * @interface IRegisterClientLogActionProto
             */

            /**
             * Constructs a new RegisterClientLogActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RegisterClientLogActionProto.
             * @implements IRegisterClientLogActionProto
             * @constructor
             * @param {waiternow.common.IRegisterClientLogActionProto=} [properties] Properties to set
             */
            function RegisterClientLogActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RegisterClientLogActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {waiternow.common.IRegisterClientLogActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RegisterClientLogActionProto} RegisterClientLogActionProto instance
             */
            RegisterClientLogActionProto.create = function create(properties) {
                return new RegisterClientLogActionProto(properties);
            };

            /**
             * Encodes the specified RegisterClientLogActionProto message. Does not implicitly {@link waiternow.common.RegisterClientLogActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {waiternow.common.IRegisterClientLogActionProto} message RegisterClientLogActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterClientLogActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RegisterClientLogActionProto message, length delimited. Does not implicitly {@link waiternow.common.RegisterClientLogActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {waiternow.common.IRegisterClientLogActionProto} message RegisterClientLogActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterClientLogActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RegisterClientLogActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RegisterClientLogActionProto} RegisterClientLogActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterClientLogActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RegisterClientLogActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RegisterClientLogActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RegisterClientLogActionProto} RegisterClientLogActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterClientLogActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RegisterClientLogActionProto message.
             * @function verify
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegisterClientLogActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RegisterClientLogActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RegisterClientLogActionProto} RegisterClientLogActionProto
             */
            RegisterClientLogActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RegisterClientLogActionProto)
                    return object;
                return new $root.waiternow.common.RegisterClientLogActionProto();
            };

            /**
             * Creates a plain object from a RegisterClientLogActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {waiternow.common.RegisterClientLogActionProto} message RegisterClientLogActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegisterClientLogActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RegisterClientLogActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegisterClientLogActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RegisterClientLogActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RegisterClientLogActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RegisterClientLogActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RegisterClientLogActionProto";
            };

            RegisterClientLogActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RegisterClientLogActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IClientLogProto|null} [clientLog] Request clientLog
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RegisterClientLogActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RegisterClientLogActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request clientLog.
                 * @member {waiternow.common.IClientLogProto|null|undefined} clientLog
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @instance
                 */
                Request.prototype.clientLog = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RegisterClientLogActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RegisterClientLogActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientLog != null && Object.hasOwnProperty.call(message, "clientLog"))
                        $root.waiternow.common.ClientLogProto.encode(message.clientLog, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RegisterClientLogActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RegisterClientLogActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RegisterClientLogActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.clientLog = $root.waiternow.common.ClientLogProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RegisterClientLogActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientLog != null && message.hasOwnProperty("clientLog")) {
                        var error = $root.waiternow.common.ClientLogProto.verify(message.clientLog);
                        if (error)
                            return "clientLog." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RegisterClientLogActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RegisterClientLogActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RegisterClientLogActionProto.Request();
                    if (object.clientLog != null) {
                        if (typeof object.clientLog !== "object")
                            throw TypeError(".waiternow.common.RegisterClientLogActionProto.Request.clientLog: object expected");
                        message.clientLog = $root.waiternow.common.ClientLogProto.fromObject(object.clientLog);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.clientLog = null;
                    if (message.clientLog != null && message.hasOwnProperty("clientLog"))
                        object.clientLog = $root.waiternow.common.ClientLogProto.toObject(message.clientLog, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RegisterClientLogActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RegisterClientLogActionProto.Request";
                };

                return Request;
            })();

            RegisterClientLogActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RegisterClientLogActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RegisterClientLogActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RegisterClientLogActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RegisterClientLogActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RegisterClientLogActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RegisterClientLogActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RegisterClientLogActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RegisterClientLogActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RegisterClientLogActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RegisterClientLogActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RegisterClientLogActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RegisterClientLogActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RegisterClientLogActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterClientLogActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RegisterClientLogActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RegisterClientLogActionProto.Response";
                };

                return Response;
            })();

            return RegisterClientLogActionProto;
        })();

        common.PostFeedbackActionProto = (function() {

            /**
             * Properties of a PostFeedbackActionProto.
             * @memberof waiternow.common
             * @interface IPostFeedbackActionProto
             */

            /**
             * Constructs a new PostFeedbackActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a PostFeedbackActionProto.
             * @implements IPostFeedbackActionProto
             * @constructor
             * @param {waiternow.common.IPostFeedbackActionProto=} [properties] Properties to set
             */
            function PostFeedbackActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new PostFeedbackActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {waiternow.common.IPostFeedbackActionProto=} [properties] Properties to set
             * @returns {waiternow.common.PostFeedbackActionProto} PostFeedbackActionProto instance
             */
            PostFeedbackActionProto.create = function create(properties) {
                return new PostFeedbackActionProto(properties);
            };

            /**
             * Encodes the specified PostFeedbackActionProto message. Does not implicitly {@link waiternow.common.PostFeedbackActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {waiternow.common.IPostFeedbackActionProto} message PostFeedbackActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PostFeedbackActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified PostFeedbackActionProto message, length delimited. Does not implicitly {@link waiternow.common.PostFeedbackActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {waiternow.common.IPostFeedbackActionProto} message PostFeedbackActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PostFeedbackActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PostFeedbackActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PostFeedbackActionProto} PostFeedbackActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PostFeedbackActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PostFeedbackActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PostFeedbackActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PostFeedbackActionProto} PostFeedbackActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PostFeedbackActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PostFeedbackActionProto message.
             * @function verify
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PostFeedbackActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a PostFeedbackActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PostFeedbackActionProto} PostFeedbackActionProto
             */
            PostFeedbackActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PostFeedbackActionProto)
                    return object;
                return new $root.waiternow.common.PostFeedbackActionProto();
            };

            /**
             * Creates a plain object from a PostFeedbackActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {waiternow.common.PostFeedbackActionProto} message PostFeedbackActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PostFeedbackActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this PostFeedbackActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PostFeedbackActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PostFeedbackActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PostFeedbackActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.PostFeedbackActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PostFeedbackActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PostFeedbackActionProto";
            };

            PostFeedbackActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.PostFeedbackActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IFeedbackProto|null} [feedback] Request feedback
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.PostFeedbackActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.PostFeedbackActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request feedback.
                 * @member {waiternow.common.IFeedbackProto|null|undefined} feedback
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @instance
                 */
                Request.prototype.feedback = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.PostFeedbackActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.PostFeedbackActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.feedback != null && Object.hasOwnProperty.call(message, "feedback"))
                        $root.waiternow.common.FeedbackProto.encode(message.feedback, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.PostFeedbackActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PostFeedbackActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PostFeedbackActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.feedback = $root.waiternow.common.FeedbackProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PostFeedbackActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.feedback != null && message.hasOwnProperty("feedback")) {
                        var error = $root.waiternow.common.FeedbackProto.verify(message.feedback);
                        if (error)
                            return "feedback." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PostFeedbackActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PostFeedbackActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.PostFeedbackActionProto.Request();
                    if (object.feedback != null) {
                        if (typeof object.feedback !== "object")
                            throw TypeError(".waiternow.common.PostFeedbackActionProto.Request.feedback: object expected");
                        message.feedback = $root.waiternow.common.FeedbackProto.fromObject(object.feedback);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.feedback = null;
                    if (message.feedback != null && message.hasOwnProperty("feedback"))
                        object.feedback = $root.waiternow.common.FeedbackProto.toObject(message.feedback, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.PostFeedbackActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PostFeedbackActionProto.Request";
                };

                return Request;
            })();

            PostFeedbackActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.PostFeedbackActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.PostFeedbackActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.PostFeedbackActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.PostFeedbackActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.PostFeedbackActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.PostFeedbackActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PostFeedbackActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PostFeedbackActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PostFeedbackActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PostFeedbackActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PostFeedbackActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.PostFeedbackActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.PostFeedbackActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {waiternow.common.PostFeedbackActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.PostFeedbackActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PostFeedbackActionProto.Response";
                };

                return Response;
            })();

            return PostFeedbackActionProto;
        })();

        common.RegisterDeviceActionProto = (function() {

            /**
             * Properties of a RegisterDeviceActionProto.
             * @memberof waiternow.common
             * @interface IRegisterDeviceActionProto
             */

            /**
             * Constructs a new RegisterDeviceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RegisterDeviceActionProto.
             * @implements IRegisterDeviceActionProto
             * @constructor
             * @param {waiternow.common.IRegisterDeviceActionProto=} [properties] Properties to set
             */
            function RegisterDeviceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RegisterDeviceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {waiternow.common.IRegisterDeviceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RegisterDeviceActionProto} RegisterDeviceActionProto instance
             */
            RegisterDeviceActionProto.create = function create(properties) {
                return new RegisterDeviceActionProto(properties);
            };

            /**
             * Encodes the specified RegisterDeviceActionProto message. Does not implicitly {@link waiternow.common.RegisterDeviceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {waiternow.common.IRegisterDeviceActionProto} message RegisterDeviceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterDeviceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RegisterDeviceActionProto message, length delimited. Does not implicitly {@link waiternow.common.RegisterDeviceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {waiternow.common.IRegisterDeviceActionProto} message RegisterDeviceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RegisterDeviceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RegisterDeviceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RegisterDeviceActionProto} RegisterDeviceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterDeviceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RegisterDeviceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RegisterDeviceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RegisterDeviceActionProto} RegisterDeviceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RegisterDeviceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RegisterDeviceActionProto message.
             * @function verify
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RegisterDeviceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RegisterDeviceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RegisterDeviceActionProto} RegisterDeviceActionProto
             */
            RegisterDeviceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RegisterDeviceActionProto)
                    return object;
                return new $root.waiternow.common.RegisterDeviceActionProto();
            };

            /**
             * Creates a plain object from a RegisterDeviceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {waiternow.common.RegisterDeviceActionProto} message RegisterDeviceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RegisterDeviceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RegisterDeviceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RegisterDeviceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RegisterDeviceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RegisterDeviceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RegisterDeviceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RegisterDeviceActionProto";
            };

            RegisterDeviceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RegisterDeviceActionProto
                 * @interface IRequest
                 * @property {waiternow.common.DeviceProto.ClientType|null} [clientType] Request clientType
                 * @property {waiternow.common.DeviceProto.DeviceType|null} [deviceType] Request deviceType
                 * @property {string|null} [version] Request version
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RegisterDeviceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RegisterDeviceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request clientType.
                 * @member {waiternow.common.DeviceProto.ClientType} clientType
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @instance
                 */
                Request.prototype.clientType = 0;

                /**
                 * Request deviceType.
                 * @member {waiternow.common.DeviceProto.DeviceType} deviceType
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceType = 0;

                /**
                 * Request version.
                 * @member {string} version
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @instance
                 */
                Request.prototype.version = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RegisterDeviceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RegisterDeviceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.clientType != null && Object.hasOwnProperty.call(message, "clientType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.clientType);
                    if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.deviceType);
                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.version);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RegisterDeviceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RegisterDeviceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RegisterDeviceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.clientType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.deviceType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.version = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RegisterDeviceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.clientType != null && message.hasOwnProperty("clientType"))
                        switch (message.clientType) {
                        default:
                            return "clientType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                        switch (message.deviceType) {
                        default:
                            return "deviceType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.version != null && message.hasOwnProperty("version"))
                        if (!$util.isString(message.version))
                            return "version: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RegisterDeviceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RegisterDeviceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RegisterDeviceActionProto.Request();
                    switch (object.clientType) {
                    default:
                        if (typeof object.clientType === "number") {
                            message.clientType = object.clientType;
                            break;
                        }
                        break;
                    case "CLIENT_TYPE_UNSPECIFIED":
                    case 0:
                        message.clientType = 0;
                        break;
                    case "BUSINESS":
                    case 1:
                        message.clientType = 1;
                        break;
                    case "CONSUMER":
                    case 2:
                        message.clientType = 2;
                        break;
                    }
                    switch (object.deviceType) {
                    default:
                        if (typeof object.deviceType === "number") {
                            message.deviceType = object.deviceType;
                            break;
                        }
                        break;
                    case "DEVICE_TYPE_UNSPECIFIED":
                    case 0:
                        message.deviceType = 0;
                        break;
                    case "WEB":
                    case 1:
                        message.deviceType = 1;
                        break;
                    case "ANDROID":
                    case 2:
                        message.deviceType = 2;
                        break;
                    case "APPLE":
                    case 3:
                        message.deviceType = 3;
                        break;
                    }
                    if (object.version != null)
                        message.version = String(object.version);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.clientType = options.enums === String ? "CLIENT_TYPE_UNSPECIFIED" : 0;
                        object.deviceType = options.enums === String ? "DEVICE_TYPE_UNSPECIFIED" : 0;
                        object.version = "";
                    }
                    if (message.clientType != null && message.hasOwnProperty("clientType"))
                        object.clientType = options.enums === String ? $root.waiternow.common.DeviceProto.ClientType[message.clientType] === undefined ? message.clientType : $root.waiternow.common.DeviceProto.ClientType[message.clientType] : message.clientType;
                    if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                        object.deviceType = options.enums === String ? $root.waiternow.common.DeviceProto.DeviceType[message.deviceType] === undefined ? message.deviceType : $root.waiternow.common.DeviceProto.DeviceType[message.deviceType] : message.deviceType;
                    if (message.version != null && message.hasOwnProperty("version"))
                        object.version = message.version;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RegisterDeviceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RegisterDeviceActionProto.Request";
                };

                return Request;
            })();

            RegisterDeviceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RegisterDeviceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IDeviceProto|null} [device] Response device
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RegisterDeviceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RegisterDeviceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response device.
                 * @member {waiternow.common.IDeviceProto|null|undefined} device
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @instance
                 */
                Response.prototype.device = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RegisterDeviceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RegisterDeviceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.device != null && Object.hasOwnProperty.call(message, "device"))
                        $root.waiternow.common.DeviceProto.encode(message.device, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RegisterDeviceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RegisterDeviceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RegisterDeviceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.device = $root.waiternow.common.DeviceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RegisterDeviceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.device != null && message.hasOwnProperty("device")) {
                        var error = $root.waiternow.common.DeviceProto.verify(message.device);
                        if (error)
                            return "device." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RegisterDeviceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RegisterDeviceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RegisterDeviceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RegisterDeviceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.device != null) {
                        if (typeof object.device !== "object")
                            throw TypeError(".waiternow.common.RegisterDeviceActionProto.Response.device: object expected");
                        message.device = $root.waiternow.common.DeviceProto.fromObject(object.device);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {waiternow.common.RegisterDeviceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.device = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.device != null && message.hasOwnProperty("device"))
                        object.device = $root.waiternow.common.DeviceProto.toObject(message.device, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RegisterDeviceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RegisterDeviceActionProto.Response";
                };

                return Response;
            })();

            return RegisterDeviceActionProto;
        })();

        common.StartDeviceSessionActionProto = (function() {

            /**
             * Properties of a StartDeviceSessionActionProto.
             * @memberof waiternow.common
             * @interface IStartDeviceSessionActionProto
             */

            /**
             * Constructs a new StartDeviceSessionActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a StartDeviceSessionActionProto.
             * @implements IStartDeviceSessionActionProto
             * @constructor
             * @param {waiternow.common.IStartDeviceSessionActionProto=} [properties] Properties to set
             */
            function StartDeviceSessionActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new StartDeviceSessionActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IStartDeviceSessionActionProto=} [properties] Properties to set
             * @returns {waiternow.common.StartDeviceSessionActionProto} StartDeviceSessionActionProto instance
             */
            StartDeviceSessionActionProto.create = function create(properties) {
                return new StartDeviceSessionActionProto(properties);
            };

            /**
             * Encodes the specified StartDeviceSessionActionProto message. Does not implicitly {@link waiternow.common.StartDeviceSessionActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IStartDeviceSessionActionProto} message StartDeviceSessionActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartDeviceSessionActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified StartDeviceSessionActionProto message, length delimited. Does not implicitly {@link waiternow.common.StartDeviceSessionActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IStartDeviceSessionActionProto} message StartDeviceSessionActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartDeviceSessionActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StartDeviceSessionActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.StartDeviceSessionActionProto} StartDeviceSessionActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartDeviceSessionActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StartDeviceSessionActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StartDeviceSessionActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.StartDeviceSessionActionProto} StartDeviceSessionActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartDeviceSessionActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StartDeviceSessionActionProto message.
             * @function verify
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartDeviceSessionActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a StartDeviceSessionActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.StartDeviceSessionActionProto} StartDeviceSessionActionProto
             */
            StartDeviceSessionActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.StartDeviceSessionActionProto)
                    return object;
                return new $root.waiternow.common.StartDeviceSessionActionProto();
            };

            /**
             * Creates a plain object from a StartDeviceSessionActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {waiternow.common.StartDeviceSessionActionProto} message StartDeviceSessionActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartDeviceSessionActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this StartDeviceSessionActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartDeviceSessionActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartDeviceSessionActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.StartDeviceSessionActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartDeviceSessionActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.StartDeviceSessionActionProto";
            };

            StartDeviceSessionActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.StartDeviceSessionActionProto
                 * @interface IRequest
                 * @property {string|null} [deviceId] Request deviceId
                 * @property {string|null} [firebaseMessagingToken] Request firebaseMessagingToken
                 * @property {waiternow.common.DeviceProto.IInterestsProto|null} [interests] Request interests
                 * @property {string|null} [version] Request version
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.StartDeviceSessionActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.StartDeviceSessionActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Request firebaseMessagingToken.
                 * @member {string} firebaseMessagingToken
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @instance
                 */
                Request.prototype.firebaseMessagingToken = "";

                /**
                 * Request interests.
                 * @member {waiternow.common.DeviceProto.IInterestsProto|null|undefined} interests
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @instance
                 */
                Request.prototype.interests = null;

                /**
                 * Request version.
                 * @member {string} version
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @instance
                 */
                Request.prototype.version = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.StartDeviceSessionActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    if (message.firebaseMessagingToken != null && Object.hasOwnProperty.call(message, "firebaseMessagingToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.firebaseMessagingToken);
                    if (message.interests != null && Object.hasOwnProperty.call(message, "interests"))
                        $root.waiternow.common.DeviceProto.InterestsProto.encode(message.interests, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.version);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.StartDeviceSessionActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StartDeviceSessionActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.firebaseMessagingToken = reader.string();
                                break;
                            }
                        case 3: {
                                message.interests = $root.waiternow.common.DeviceProto.InterestsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.version = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    if (message.firebaseMessagingToken != null && message.hasOwnProperty("firebaseMessagingToken"))
                        if (!$util.isString(message.firebaseMessagingToken))
                            return "firebaseMessagingToken: string expected";
                    if (message.interests != null && message.hasOwnProperty("interests")) {
                        var error = $root.waiternow.common.DeviceProto.InterestsProto.verify(message.interests);
                        if (error)
                            return "interests." + error;
                    }
                    if (message.version != null && message.hasOwnProperty("version"))
                        if (!$util.isString(message.version))
                            return "version: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StartDeviceSessionActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.StartDeviceSessionActionProto.Request();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    if (object.firebaseMessagingToken != null)
                        message.firebaseMessagingToken = String(object.firebaseMessagingToken);
                    if (object.interests != null) {
                        if (typeof object.interests !== "object")
                            throw TypeError(".waiternow.common.StartDeviceSessionActionProto.Request.interests: object expected");
                        message.interests = $root.waiternow.common.DeviceProto.InterestsProto.fromObject(object.interests);
                    }
                    if (object.version != null)
                        message.version = String(object.version);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.deviceId = "";
                        object.firebaseMessagingToken = "";
                        object.interests = null;
                        object.version = "";
                    }
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    if (message.firebaseMessagingToken != null && message.hasOwnProperty("firebaseMessagingToken"))
                        object.firebaseMessagingToken = message.firebaseMessagingToken;
                    if (message.interests != null && message.hasOwnProperty("interests"))
                        object.interests = $root.waiternow.common.DeviceProto.InterestsProto.toObject(message.interests, options);
                    if (message.version != null && message.hasOwnProperty("version"))
                        object.version = message.version;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StartDeviceSessionActionProto.Request";
                };

                return Request;
            })();

            StartDeviceSessionActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.StartDeviceSessionActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IDeviceSessionInfoProto|null} [deviceSessionInfo] Response deviceSessionInfo
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.StartDeviceSessionActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.StartDeviceSessionActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response deviceSessionInfo.
                 * @member {waiternow.common.IDeviceSessionInfoProto|null|undefined} deviceSessionInfo
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @instance
                 */
                Response.prototype.deviceSessionInfo = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.StartDeviceSessionActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.deviceSessionInfo != null && Object.hasOwnProperty.call(message, "deviceSessionInfo"))
                        $root.waiternow.common.DeviceSessionInfoProto.encode(message.deviceSessionInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.StartDeviceSessionActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StartDeviceSessionActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.deviceSessionInfo = $root.waiternow.common.DeviceSessionInfoProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.deviceSessionInfo != null && message.hasOwnProperty("deviceSessionInfo")) {
                        var error = $root.waiternow.common.DeviceSessionInfoProto.verify(message.deviceSessionInfo);
                        if (error)
                            return "deviceSessionInfo." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StartDeviceSessionActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StartDeviceSessionActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.StartDeviceSessionActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.StartDeviceSessionActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.deviceSessionInfo != null) {
                        if (typeof object.deviceSessionInfo !== "object")
                            throw TypeError(".waiternow.common.StartDeviceSessionActionProto.Response.deviceSessionInfo: object expected");
                        message.deviceSessionInfo = $root.waiternow.common.DeviceSessionInfoProto.fromObject(object.deviceSessionInfo);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartDeviceSessionActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.deviceSessionInfo = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.deviceSessionInfo != null && message.hasOwnProperty("deviceSessionInfo"))
                        object.deviceSessionInfo = $root.waiternow.common.DeviceSessionInfoProto.toObject(message.deviceSessionInfo, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.StartDeviceSessionActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StartDeviceSessionActionProto.Response";
                };

                return Response;
            })();

            return StartDeviceSessionActionProto;
        })();

        common.DeviceHeartbeatActionProto = (function() {

            /**
             * Properties of a DeviceHeartbeatActionProto.
             * @memberof waiternow.common
             * @interface IDeviceHeartbeatActionProto
             */

            /**
             * Constructs a new DeviceHeartbeatActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeviceHeartbeatActionProto.
             * @implements IDeviceHeartbeatActionProto
             * @constructor
             * @param {waiternow.common.IDeviceHeartbeatActionProto=} [properties] Properties to set
             */
            function DeviceHeartbeatActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DeviceHeartbeatActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {waiternow.common.IDeviceHeartbeatActionProto=} [properties] Properties to set
             * @returns {waiternow.common.DeviceHeartbeatActionProto} DeviceHeartbeatActionProto instance
             */
            DeviceHeartbeatActionProto.create = function create(properties) {
                return new DeviceHeartbeatActionProto(properties);
            };

            /**
             * Encodes the specified DeviceHeartbeatActionProto message. Does not implicitly {@link waiternow.common.DeviceHeartbeatActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {waiternow.common.IDeviceHeartbeatActionProto} message DeviceHeartbeatActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceHeartbeatActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DeviceHeartbeatActionProto message, length delimited. Does not implicitly {@link waiternow.common.DeviceHeartbeatActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {waiternow.common.IDeviceHeartbeatActionProto} message DeviceHeartbeatActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceHeartbeatActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeviceHeartbeatActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeviceHeartbeatActionProto} DeviceHeartbeatActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceHeartbeatActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceHeartbeatActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeviceHeartbeatActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeviceHeartbeatActionProto} DeviceHeartbeatActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceHeartbeatActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeviceHeartbeatActionProto message.
             * @function verify
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceHeartbeatActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DeviceHeartbeatActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeviceHeartbeatActionProto} DeviceHeartbeatActionProto
             */
            DeviceHeartbeatActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeviceHeartbeatActionProto)
                    return object;
                return new $root.waiternow.common.DeviceHeartbeatActionProto();
            };

            /**
             * Creates a plain object from a DeviceHeartbeatActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {waiternow.common.DeviceHeartbeatActionProto} message DeviceHeartbeatActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceHeartbeatActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeviceHeartbeatActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceHeartbeatActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeviceHeartbeatActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeviceHeartbeatActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeviceHeartbeatActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeviceHeartbeatActionProto";
            };

            DeviceHeartbeatActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.DeviceHeartbeatActionProto
                 * @interface IRequest
                 * @property {string|null} [deviceId] Request deviceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.DeviceHeartbeatActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.DeviceHeartbeatActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.DeviceHeartbeatActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceHeartbeatActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeviceHeartbeatActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.DeviceHeartbeatActionProto.Request();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.deviceId = "";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeviceHeartbeatActionProto.Request";
                };

                return Request;
            })();

            DeviceHeartbeatActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.DeviceHeartbeatActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.DeviceHeartbeatActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.DeviceHeartbeatActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.DeviceHeartbeatActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceHeartbeatActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeviceHeartbeatActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeviceHeartbeatActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.DeviceHeartbeatActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.DeviceHeartbeatActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {waiternow.common.DeviceHeartbeatActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeviceHeartbeatActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeviceHeartbeatActionProto.Response";
                };

                return Response;
            })();

            return DeviceHeartbeatActionProto;
        })();

        common.StartGracePeriodToEndDeviceSessionActionProto = (function() {

            /**
             * Properties of a StartGracePeriodToEndDeviceSessionActionProto.
             * @memberof waiternow.common
             * @interface IStartGracePeriodToEndDeviceSessionActionProto
             */

            /**
             * Constructs a new StartGracePeriodToEndDeviceSessionActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a StartGracePeriodToEndDeviceSessionActionProto.
             * @implements IStartGracePeriodToEndDeviceSessionActionProto
             * @constructor
             * @param {waiternow.common.IStartGracePeriodToEndDeviceSessionActionProto=} [properties] Properties to set
             */
            function StartGracePeriodToEndDeviceSessionActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new StartGracePeriodToEndDeviceSessionActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IStartGracePeriodToEndDeviceSessionActionProto=} [properties] Properties to set
             * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto} StartGracePeriodToEndDeviceSessionActionProto instance
             */
            StartGracePeriodToEndDeviceSessionActionProto.create = function create(properties) {
                return new StartGracePeriodToEndDeviceSessionActionProto(properties);
            };

            /**
             * Encodes the specified StartGracePeriodToEndDeviceSessionActionProto message. Does not implicitly {@link waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IStartGracePeriodToEndDeviceSessionActionProto} message StartGracePeriodToEndDeviceSessionActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartGracePeriodToEndDeviceSessionActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified StartGracePeriodToEndDeviceSessionActionProto message, length delimited. Does not implicitly {@link waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IStartGracePeriodToEndDeviceSessionActionProto} message StartGracePeriodToEndDeviceSessionActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            StartGracePeriodToEndDeviceSessionActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a StartGracePeriodToEndDeviceSessionActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto} StartGracePeriodToEndDeviceSessionActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartGracePeriodToEndDeviceSessionActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a StartGracePeriodToEndDeviceSessionActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto} StartGracePeriodToEndDeviceSessionActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            StartGracePeriodToEndDeviceSessionActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a StartGracePeriodToEndDeviceSessionActionProto message.
             * @function verify
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            StartGracePeriodToEndDeviceSessionActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a StartGracePeriodToEndDeviceSessionActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto} StartGracePeriodToEndDeviceSessionActionProto
             */
            StartGracePeriodToEndDeviceSessionActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto)
                    return object;
                return new $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto();
            };

            /**
             * Creates a plain object from a StartGracePeriodToEndDeviceSessionActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto} message StartGracePeriodToEndDeviceSessionActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            StartGracePeriodToEndDeviceSessionActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this StartGracePeriodToEndDeviceSessionActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            StartGracePeriodToEndDeviceSessionActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for StartGracePeriodToEndDeviceSessionActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            StartGracePeriodToEndDeviceSessionActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.StartGracePeriodToEndDeviceSessionActionProto";
            };

            StartGracePeriodToEndDeviceSessionActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
                 * @interface IRequest
                 * @property {string|null} [deviceId] Request deviceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.deviceId = "";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Request";
                };

                return Request;
            })();

            StartGracePeriodToEndDeviceSessionActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.StartGracePeriodToEndDeviceSessionActionProto.Response";
                };

                return Response;
            })();

            return StartGracePeriodToEndDeviceSessionActionProto;
        })();

        common.EndDeviceSessionActionProto = (function() {

            /**
             * Properties of an EndDeviceSessionActionProto.
             * @memberof waiternow.common
             * @interface IEndDeviceSessionActionProto
             */

            /**
             * Constructs a new EndDeviceSessionActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an EndDeviceSessionActionProto.
             * @implements IEndDeviceSessionActionProto
             * @constructor
             * @param {waiternow.common.IEndDeviceSessionActionProto=} [properties] Properties to set
             */
            function EndDeviceSessionActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new EndDeviceSessionActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IEndDeviceSessionActionProto=} [properties] Properties to set
             * @returns {waiternow.common.EndDeviceSessionActionProto} EndDeviceSessionActionProto instance
             */
            EndDeviceSessionActionProto.create = function create(properties) {
                return new EndDeviceSessionActionProto(properties);
            };

            /**
             * Encodes the specified EndDeviceSessionActionProto message. Does not implicitly {@link waiternow.common.EndDeviceSessionActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IEndDeviceSessionActionProto} message EndDeviceSessionActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EndDeviceSessionActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified EndDeviceSessionActionProto message, length delimited. Does not implicitly {@link waiternow.common.EndDeviceSessionActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.IEndDeviceSessionActionProto} message EndDeviceSessionActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EndDeviceSessionActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EndDeviceSessionActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EndDeviceSessionActionProto} EndDeviceSessionActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EndDeviceSessionActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EndDeviceSessionActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EndDeviceSessionActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EndDeviceSessionActionProto} EndDeviceSessionActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EndDeviceSessionActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EndDeviceSessionActionProto message.
             * @function verify
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EndDeviceSessionActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an EndDeviceSessionActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EndDeviceSessionActionProto} EndDeviceSessionActionProto
             */
            EndDeviceSessionActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EndDeviceSessionActionProto)
                    return object;
                return new $root.waiternow.common.EndDeviceSessionActionProto();
            };

            /**
             * Creates a plain object from an EndDeviceSessionActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {waiternow.common.EndDeviceSessionActionProto} message EndDeviceSessionActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EndDeviceSessionActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this EndDeviceSessionActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EndDeviceSessionActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EndDeviceSessionActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.EndDeviceSessionActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EndDeviceSessionActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EndDeviceSessionActionProto";
            };

            EndDeviceSessionActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.EndDeviceSessionActionProto
                 * @interface IRequest
                 * @property {string|null} [deviceId] Request deviceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.EndDeviceSessionActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.EndDeviceSessionActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.EndDeviceSessionActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.EndDeviceSessionActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EndDeviceSessionActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.EndDeviceSessionActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.EndDeviceSessionActionProto.Request();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.deviceId = "";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.EndDeviceSessionActionProto.Request";
                };

                return Request;
            })();

            EndDeviceSessionActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.EndDeviceSessionActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.EndDeviceSessionActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.EndDeviceSessionActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.EndDeviceSessionActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.EndDeviceSessionActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EndDeviceSessionActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.EndDeviceSessionActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.EndDeviceSessionActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.EndDeviceSessionActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.EndDeviceSessionActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {waiternow.common.EndDeviceSessionActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.EndDeviceSessionActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.EndDeviceSessionActionProto.Response";
                };

                return Response;
            })();

            return EndDeviceSessionActionProto;
        })();

        common.SendEmailActionProto = (function() {

            /**
             * Properties of a SendEmailActionProto.
             * @memberof waiternow.common
             * @interface ISendEmailActionProto
             */

            /**
             * Constructs a new SendEmailActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SendEmailActionProto.
             * @implements ISendEmailActionProto
             * @constructor
             * @param {waiternow.common.ISendEmailActionProto=} [properties] Properties to set
             */
            function SendEmailActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SendEmailActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto instance
             */
            SendEmailActionProto.create = function create(properties) {
                return new SendEmailActionProto(properties);
            };

            /**
             * Encodes the specified SendEmailActionProto message. Does not implicitly {@link waiternow.common.SendEmailActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto} message SendEmailActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SendEmailActionProto message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.ISendEmailActionProto} message SendEmailActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SendEmailActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SendEmailActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SendEmailActionProto message.
             * @function verify
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SendEmailActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SendEmailActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SendEmailActionProto} SendEmailActionProto
             */
            SendEmailActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SendEmailActionProto)
                    return object;
                return new $root.waiternow.common.SendEmailActionProto();
            };

            /**
             * Creates a plain object from a SendEmailActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {waiternow.common.SendEmailActionProto} message SendEmailActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendEmailActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SendEmailActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SendEmailActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendEmailActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendEmailActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SendEmailActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendEmailActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SendEmailActionProto";
            };

            SendEmailActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IEmailAddressProto|null} [from] Request from
                 * @property {waiternow.common.EmailRecipient|null} [to] Request to
                 * @property {string|null} [subject] Request subject
                 * @property {string|null} [body] Request body
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SendEmailActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request from.
                 * @member {waiternow.common.IEmailAddressProto|null|undefined} from
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.from = null;

                /**
                 * Request to.
                 * @member {waiternow.common.EmailRecipient} to
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.to = 0;

                /**
                 * Request subject.
                 * @member {string} subject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.subject = "";

                /**
                 * Request body.
                 * @member {string} body
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 */
                Request.prototype.body = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SendEmailActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                        $root.waiternow.common.EmailAddressProto.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.to);
                    if (message.subject != null && Object.hasOwnProperty.call(message, "subject"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.subject);
                    if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.body);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.from = $root.waiternow.common.EmailAddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.to = reader.int32();
                                break;
                            }
                        case 3: {
                                message.subject = reader.string();
                                break;
                            }
                        case 4: {
                                message.body = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.from != null && message.hasOwnProperty("from")) {
                        var error = $root.waiternow.common.EmailAddressProto.verify(message.from);
                        if (error)
                            return "from." + error;
                    }
                    if (message.to != null && message.hasOwnProperty("to"))
                        switch (message.to) {
                        default:
                            return "to: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        if (!$util.isString(message.subject))
                            return "subject: string expected";
                    if (message.body != null && message.hasOwnProperty("body"))
                        if (!$util.isString(message.body))
                            return "body: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SendEmailActionProto.Request();
                    if (object.from != null) {
                        if (typeof object.from !== "object")
                            throw TypeError(".waiternow.common.SendEmailActionProto.Request.from: object expected");
                        message.from = $root.waiternow.common.EmailAddressProto.fromObject(object.from);
                    }
                    switch (object.to) {
                    default:
                        if (typeof object.to === "number") {
                            message.to = object.to;
                            break;
                        }
                        break;
                    case "EMAIL_RECIPIENT_UNSPECIFIED":
                    case 0:
                        message.to = 0;
                        break;
                    case "INFO":
                    case 1:
                        message.to = 1;
                        break;
                    case "SYSTEM":
                    case 2:
                        message.to = 2;
                        break;
                    case "SUPPORT":
                    case 3:
                        message.to = 3;
                        break;
                    case "CONTACT":
                    case 4:
                        message.to = 4;
                        break;
                    case "FEEDBACK":
                    case 5:
                        message.to = 5;
                        break;
                    case "SALES":
                    case 6:
                        message.to = 6;
                        break;
                    case "BILLING":
                    case 7:
                        message.to = 7;
                        break;
                    case "QR_CODE_SALES":
                    case 8:
                        message.to = 8;
                        break;
                    }
                    if (object.subject != null)
                        message.subject = String(object.subject);
                    if (object.body != null)
                        message.body = String(object.body);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.from = null;
                        object.to = options.enums === String ? "EMAIL_RECIPIENT_UNSPECIFIED" : 0;
                        object.subject = "";
                        object.body = "";
                    }
                    if (message.from != null && message.hasOwnProperty("from"))
                        object.from = $root.waiternow.common.EmailAddressProto.toObject(message.from, options);
                    if (message.to != null && message.hasOwnProperty("to"))
                        object.to = options.enums === String ? $root.waiternow.common.EmailRecipient[message.to] === undefined ? message.to : $root.waiternow.common.EmailRecipient[message.to] : message.to;
                    if (message.subject != null && message.hasOwnProperty("subject"))
                        object.subject = message.subject;
                    if (message.body != null && message.hasOwnProperty("body"))
                        object.body = message.body;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailActionProto.Request";
                };

                return Request;
            })();

            SendEmailActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SendEmailActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SendEmailActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SendEmailActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SendEmailActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SendEmailActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SendEmailActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailActionProto.Response";
                };

                return Response;
            })();

            return SendEmailActionProto;
        })();

        common.GetVersionsActionProto = (function() {

            /**
             * Properties of a GetVersionsActionProto.
             * @memberof waiternow.common
             * @interface IGetVersionsActionProto
             */

            /**
             * Constructs a new GetVersionsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetVersionsActionProto.
             * @implements IGetVersionsActionProto
             * @constructor
             * @param {waiternow.common.IGetVersionsActionProto=} [properties] Properties to set
             */
            function GetVersionsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetVersionsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.IGetVersionsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto instance
             */
            GetVersionsActionProto.create = function create(properties) {
                return new GetVersionsActionProto(properties);
            };

            /**
             * Encodes the specified GetVersionsActionProto message. Does not implicitly {@link waiternow.common.GetVersionsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.IGetVersionsActionProto} message GetVersionsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetVersionsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetVersionsActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetVersionsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.IGetVersionsActionProto} message GetVersionsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetVersionsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetVersionsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetVersionsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetVersionsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetVersionsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetVersionsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetVersionsActionProto message.
             * @function verify
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetVersionsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetVersionsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetVersionsActionProto} GetVersionsActionProto
             */
            GetVersionsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetVersionsActionProto)
                    return object;
                return new $root.waiternow.common.GetVersionsActionProto();
            };

            /**
             * Creates a plain object from a GetVersionsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {waiternow.common.GetVersionsActionProto} message GetVersionsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetVersionsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetVersionsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetVersionsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetVersionsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetVersionsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetVersionsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetVersionsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetVersionsActionProto";
            };

            GetVersionsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetVersionsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetVersionsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetVersionsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetVersionsActionProto.Request)
                        return object;
                    return new $root.waiternow.common.GetVersionsActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetVersionsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetVersionsActionProto.Request";
                };

                return Request;
            })();

            GetVersionsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IVersionProto|null} [consumerVersion] Response consumerVersion
                 * @property {waiternow.common.IVersionProto|null} [businessWebVersion] Response businessWebVersion
                 * @property {waiternow.common.IVersionProto|null} [adminVersion] Response adminVersion
                 * @property {waiternow.common.IVersionProto|null} [supportVersion] Response supportVersion
                 * @property {waiternow.common.IVersionProto|null} [landingPageVersion] Response landingPageVersion
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetVersionsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetVersionsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response consumerVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} consumerVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.consumerVersion = null;

                /**
                 * Response businessWebVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} businessWebVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.businessWebVersion = null;

                /**
                 * Response adminVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} adminVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.adminVersion = null;

                /**
                 * Response supportVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} supportVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.supportVersion = null;

                /**
                 * Response landingPageVersion.
                 * @member {waiternow.common.IVersionProto|null|undefined} landingPageVersion
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 */
                Response.prototype.landingPageVersion = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.consumerVersion != null && Object.hasOwnProperty.call(message, "consumerVersion"))
                        $root.waiternow.common.VersionProto.encode(message.consumerVersion, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.businessWebVersion != null && Object.hasOwnProperty.call(message, "businessWebVersion"))
                        $root.waiternow.common.VersionProto.encode(message.businessWebVersion, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.adminVersion != null && Object.hasOwnProperty.call(message, "adminVersion"))
                        $root.waiternow.common.VersionProto.encode(message.adminVersion, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.supportVersion != null && Object.hasOwnProperty.call(message, "supportVersion"))
                        $root.waiternow.common.VersionProto.encode(message.supportVersion, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.landingPageVersion != null && Object.hasOwnProperty.call(message, "landingPageVersion"))
                        $root.waiternow.common.VersionProto.encode(message.landingPageVersion, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetVersionsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetVersionsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.consumerVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.businessWebVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.adminVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.supportVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.landingPageVersion = $root.waiternow.common.VersionProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.consumerVersion != null && message.hasOwnProperty("consumerVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.consumerVersion);
                        if (error)
                            return "consumerVersion." + error;
                    }
                    if (message.businessWebVersion != null && message.hasOwnProperty("businessWebVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.businessWebVersion);
                        if (error)
                            return "businessWebVersion." + error;
                    }
                    if (message.adminVersion != null && message.hasOwnProperty("adminVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.adminVersion);
                        if (error)
                            return "adminVersion." + error;
                    }
                    if (message.supportVersion != null && message.hasOwnProperty("supportVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.supportVersion);
                        if (error)
                            return "supportVersion." + error;
                    }
                    if (message.landingPageVersion != null && message.hasOwnProperty("landingPageVersion")) {
                        var error = $root.waiternow.common.VersionProto.verify(message.landingPageVersion);
                        if (error)
                            return "landingPageVersion." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetVersionsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetVersionsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetVersionsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.consumerVersion != null) {
                        if (typeof object.consumerVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.consumerVersion: object expected");
                        message.consumerVersion = $root.waiternow.common.VersionProto.fromObject(object.consumerVersion);
                    }
                    if (object.businessWebVersion != null) {
                        if (typeof object.businessWebVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.businessWebVersion: object expected");
                        message.businessWebVersion = $root.waiternow.common.VersionProto.fromObject(object.businessWebVersion);
                    }
                    if (object.adminVersion != null) {
                        if (typeof object.adminVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.adminVersion: object expected");
                        message.adminVersion = $root.waiternow.common.VersionProto.fromObject(object.adminVersion);
                    }
                    if (object.supportVersion != null) {
                        if (typeof object.supportVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.supportVersion: object expected");
                        message.supportVersion = $root.waiternow.common.VersionProto.fromObject(object.supportVersion);
                    }
                    if (object.landingPageVersion != null) {
                        if (typeof object.landingPageVersion !== "object")
                            throw TypeError(".waiternow.common.GetVersionsActionProto.Response.landingPageVersion: object expected");
                        message.landingPageVersion = $root.waiternow.common.VersionProto.fromObject(object.landingPageVersion);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetVersionsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.consumerVersion = null;
                        object.businessWebVersion = null;
                        object.adminVersion = null;
                        object.supportVersion = null;
                        object.landingPageVersion = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.consumerVersion != null && message.hasOwnProperty("consumerVersion"))
                        object.consumerVersion = $root.waiternow.common.VersionProto.toObject(message.consumerVersion, options);
                    if (message.businessWebVersion != null && message.hasOwnProperty("businessWebVersion"))
                        object.businessWebVersion = $root.waiternow.common.VersionProto.toObject(message.businessWebVersion, options);
                    if (message.adminVersion != null && message.hasOwnProperty("adminVersion"))
                        object.adminVersion = $root.waiternow.common.VersionProto.toObject(message.adminVersion, options);
                    if (message.supportVersion != null && message.hasOwnProperty("supportVersion"))
                        object.supportVersion = $root.waiternow.common.VersionProto.toObject(message.supportVersion, options);
                    if (message.landingPageVersion != null && message.hasOwnProperty("landingPageVersion"))
                        object.landingPageVersion = $root.waiternow.common.VersionProto.toObject(message.landingPageVersion, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetVersionsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetVersionsActionProto.Response";
                };

                return Response;
            })();

            return GetVersionsActionProto;
        })();

        common.DeviceProto = (function() {

            /**
             * Properties of a DeviceProto.
             * @memberof waiternow.common
             * @interface IDeviceProto
             * @property {string|null} [id] DeviceProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] DeviceProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] DeviceProto lastUpdateTime
             * @property {string|null} [firebaseMessagingToken] DeviceProto firebaseMessagingToken
             * @property {string|null} [userId] DeviceProto userId
             * @property {string|null} [consumerId] DeviceProto consumerId
             * @property {waiternow.common.DeviceProto.ClientType|null} [clientType] DeviceProto clientType
             * @property {waiternow.common.DeviceProto.DeviceType|null} [deviceType] DeviceProto deviceType
             * @property {waiternow.common.DeviceProto.IInterestsProto|null} [interests] DeviceProto interests
             * @property {waiternow.common.DeviceProto.IStatusProto|null} [status] DeviceProto status
             * @property {string|null} [version] DeviceProto version
             */

            /**
             * Constructs a new DeviceProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeviceProto.
             * @implements IDeviceProto
             * @constructor
             * @param {waiternow.common.IDeviceProto=} [properties] Properties to set
             */
            function DeviceProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeviceProto id.
             * @member {string} id
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.id = "";

            /**
             * DeviceProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.creationTime = null;

            /**
             * DeviceProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.lastUpdateTime = null;

            /**
             * DeviceProto firebaseMessagingToken.
             * @member {string} firebaseMessagingToken
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.firebaseMessagingToken = "";

            /**
             * DeviceProto userId.
             * @member {string} userId
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.userId = "";

            /**
             * DeviceProto consumerId.
             * @member {string} consumerId
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.consumerId = "";

            /**
             * DeviceProto clientType.
             * @member {waiternow.common.DeviceProto.ClientType} clientType
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.clientType = 0;

            /**
             * DeviceProto deviceType.
             * @member {waiternow.common.DeviceProto.DeviceType} deviceType
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.deviceType = 0;

            /**
             * DeviceProto interests.
             * @member {waiternow.common.DeviceProto.IInterestsProto|null|undefined} interests
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.interests = null;

            /**
             * DeviceProto status.
             * @member {waiternow.common.DeviceProto.IStatusProto|null|undefined} status
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.status = null;

            /**
             * DeviceProto version.
             * @member {string} version
             * @memberof waiternow.common.DeviceProto
             * @instance
             */
            DeviceProto.prototype.version = "";

            /**
             * Creates a new DeviceProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {waiternow.common.IDeviceProto=} [properties] Properties to set
             * @returns {waiternow.common.DeviceProto} DeviceProto instance
             */
            DeviceProto.create = function create(properties) {
                return new DeviceProto(properties);
            };

            /**
             * Encodes the specified DeviceProto message. Does not implicitly {@link waiternow.common.DeviceProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {waiternow.common.IDeviceProto} message DeviceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.firebaseMessagingToken != null && Object.hasOwnProperty.call(message, "firebaseMessagingToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.firebaseMessagingToken);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.userId);
                if (message.consumerId != null && Object.hasOwnProperty.call(message, "consumerId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.consumerId);
                if (message.clientType != null && Object.hasOwnProperty.call(message, "clientType"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.clientType);
                if (message.deviceType != null && Object.hasOwnProperty.call(message, "deviceType"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int32(message.deviceType);
                if (message.interests != null && Object.hasOwnProperty.call(message, "interests"))
                    $root.waiternow.common.DeviceProto.InterestsProto.encode(message.interests, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.waiternow.common.DeviceProto.StatusProto.encode(message.status, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.version != null && Object.hasOwnProperty.call(message, "version"))
                    writer.uint32(/* id 11, wireType 2 =*/90).string(message.version);
                return writer;
            };

            /**
             * Encodes the specified DeviceProto message, length delimited. Does not implicitly {@link waiternow.common.DeviceProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {waiternow.common.IDeviceProto} message DeviceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeviceProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeviceProto} DeviceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 9: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.firebaseMessagingToken = reader.string();
                            break;
                        }
                    case 3: {
                            message.userId = reader.string();
                            break;
                        }
                    case 4: {
                            message.consumerId = reader.string();
                            break;
                        }
                    case 5: {
                            message.clientType = reader.int32();
                            break;
                        }
                    case 6: {
                            message.deviceType = reader.int32();
                            break;
                        }
                    case 7: {
                            message.interests = $root.waiternow.common.DeviceProto.InterestsProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.status = $root.waiternow.common.DeviceProto.StatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.version = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeviceProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeviceProto} DeviceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeviceProto message.
             * @function verify
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.firebaseMessagingToken != null && message.hasOwnProperty("firebaseMessagingToken"))
                    if (!$util.isString(message.firebaseMessagingToken))
                        return "firebaseMessagingToken: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    if (!$util.isString(message.consumerId))
                        return "consumerId: string expected";
                if (message.clientType != null && message.hasOwnProperty("clientType"))
                    switch (message.clientType) {
                    default:
                        return "clientType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                    switch (message.deviceType) {
                    default:
                        return "deviceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.interests != null && message.hasOwnProperty("interests")) {
                    var error = $root.waiternow.common.DeviceProto.InterestsProto.verify(message.interests);
                    if (error)
                        return "interests." + error;
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    var error = $root.waiternow.common.DeviceProto.StatusProto.verify(message.status);
                    if (error)
                        return "status." + error;
                }
                if (message.version != null && message.hasOwnProperty("version"))
                    if (!$util.isString(message.version))
                        return "version: string expected";
                return null;
            };

            /**
             * Creates a DeviceProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeviceProto} DeviceProto
             */
            DeviceProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeviceProto)
                    return object;
                var message = new $root.waiternow.common.DeviceProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.DeviceProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.DeviceProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.firebaseMessagingToken != null)
                    message.firebaseMessagingToken = String(object.firebaseMessagingToken);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.consumerId != null)
                    message.consumerId = String(object.consumerId);
                switch (object.clientType) {
                default:
                    if (typeof object.clientType === "number") {
                        message.clientType = object.clientType;
                        break;
                    }
                    break;
                case "CLIENT_TYPE_UNSPECIFIED":
                case 0:
                    message.clientType = 0;
                    break;
                case "BUSINESS":
                case 1:
                    message.clientType = 1;
                    break;
                case "CONSUMER":
                case 2:
                    message.clientType = 2;
                    break;
                }
                switch (object.deviceType) {
                default:
                    if (typeof object.deviceType === "number") {
                        message.deviceType = object.deviceType;
                        break;
                    }
                    break;
                case "DEVICE_TYPE_UNSPECIFIED":
                case 0:
                    message.deviceType = 0;
                    break;
                case "WEB":
                case 1:
                    message.deviceType = 1;
                    break;
                case "ANDROID":
                case 2:
                    message.deviceType = 2;
                    break;
                case "APPLE":
                case 3:
                    message.deviceType = 3;
                    break;
                }
                if (object.interests != null) {
                    if (typeof object.interests !== "object")
                        throw TypeError(".waiternow.common.DeviceProto.interests: object expected");
                    message.interests = $root.waiternow.common.DeviceProto.InterestsProto.fromObject(object.interests);
                }
                if (object.status != null) {
                    if (typeof object.status !== "object")
                        throw TypeError(".waiternow.common.DeviceProto.status: object expected");
                    message.status = $root.waiternow.common.DeviceProto.StatusProto.fromObject(object.status);
                }
                if (object.version != null)
                    message.version = String(object.version);
                return message;
            };

            /**
             * Creates a plain object from a DeviceProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {waiternow.common.DeviceProto} message DeviceProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.firebaseMessagingToken = "";
                    object.userId = "";
                    object.consumerId = "";
                    object.clientType = options.enums === String ? "CLIENT_TYPE_UNSPECIFIED" : 0;
                    object.deviceType = options.enums === String ? "DEVICE_TYPE_UNSPECIFIED" : 0;
                    object.interests = null;
                    object.status = null;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.version = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.firebaseMessagingToken != null && message.hasOwnProperty("firebaseMessagingToken"))
                    object.firebaseMessagingToken = message.firebaseMessagingToken;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    object.consumerId = message.consumerId;
                if (message.clientType != null && message.hasOwnProperty("clientType"))
                    object.clientType = options.enums === String ? $root.waiternow.common.DeviceProto.ClientType[message.clientType] === undefined ? message.clientType : $root.waiternow.common.DeviceProto.ClientType[message.clientType] : message.clientType;
                if (message.deviceType != null && message.hasOwnProperty("deviceType"))
                    object.deviceType = options.enums === String ? $root.waiternow.common.DeviceProto.DeviceType[message.deviceType] === undefined ? message.deviceType : $root.waiternow.common.DeviceProto.DeviceType[message.deviceType] : message.deviceType;
                if (message.interests != null && message.hasOwnProperty("interests"))
                    object.interests = $root.waiternow.common.DeviceProto.InterestsProto.toObject(message.interests, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = $root.waiternow.common.DeviceProto.StatusProto.toObject(message.status, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.version != null && message.hasOwnProperty("version"))
                    object.version = message.version;
                return object;
            };

            /**
             * Converts this DeviceProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeviceProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeviceProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeviceProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeviceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeviceProto";
            };

            /**
             * ClientType enum.
             * @name waiternow.common.DeviceProto.ClientType
             * @enum {number}
             * @property {number} CLIENT_TYPE_UNSPECIFIED=0 CLIENT_TYPE_UNSPECIFIED value
             * @property {number} BUSINESS=1 BUSINESS value
             * @property {number} CONSUMER=2 CONSUMER value
             */
            DeviceProto.ClientType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "CLIENT_TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "BUSINESS"] = 1;
                values[valuesById[2] = "CONSUMER"] = 2;
                return values;
            })();

            /**
             * DeviceType enum.
             * @name waiternow.common.DeviceProto.DeviceType
             * @enum {number}
             * @property {number} DEVICE_TYPE_UNSPECIFIED=0 DEVICE_TYPE_UNSPECIFIED value
             * @property {number} WEB=1 WEB value
             * @property {number} ANDROID=2 ANDROID value
             * @property {number} APPLE=3 APPLE value
             */
            DeviceProto.DeviceType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "DEVICE_TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "WEB"] = 1;
                values[valuesById[2] = "ANDROID"] = 2;
                values[valuesById[3] = "APPLE"] = 3;
                return values;
            })();

            DeviceProto.InterestsProto = (function() {

                /**
                 * Properties of an InterestsProto.
                 * @memberof waiternow.common.DeviceProto
                 * @interface IInterestsProto
                 * @property {string|null} [locationId] InterestsProto locationId
                 * @property {string|null} [pointOfServiceId] InterestsProto pointOfServiceId
                 */

                /**
                 * Constructs a new InterestsProto.
                 * @memberof waiternow.common.DeviceProto
                 * @classdesc Represents an InterestsProto.
                 * @implements IInterestsProto
                 * @constructor
                 * @param {waiternow.common.DeviceProto.IInterestsProto=} [properties] Properties to set
                 */
                function InterestsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * InterestsProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @instance
                 */
                InterestsProto.prototype.locationId = "";

                /**
                 * InterestsProto pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @instance
                 */
                InterestsProto.prototype.pointOfServiceId = "";

                /**
                 * Creates a new InterestsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {waiternow.common.DeviceProto.IInterestsProto=} [properties] Properties to set
                 * @returns {waiternow.common.DeviceProto.InterestsProto} InterestsProto instance
                 */
                InterestsProto.create = function create(properties) {
                    return new InterestsProto(properties);
                };

                /**
                 * Encodes the specified InterestsProto message. Does not implicitly {@link waiternow.common.DeviceProto.InterestsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {waiternow.common.DeviceProto.IInterestsProto} message InterestsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterestsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.pointOfServiceId);
                    return writer;
                };

                /**
                 * Encodes the specified InterestsProto message, length delimited. Does not implicitly {@link waiternow.common.DeviceProto.InterestsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {waiternow.common.DeviceProto.IInterestsProto} message InterestsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                InterestsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an InterestsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeviceProto.InterestsProto} InterestsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterestsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceProto.InterestsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an InterestsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeviceProto.InterestsProto} InterestsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                InterestsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an InterestsProto message.
                 * @function verify
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                InterestsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    return null;
                };

                /**
                 * Creates an InterestsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeviceProto.InterestsProto} InterestsProto
                 */
                InterestsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeviceProto.InterestsProto)
                        return object;
                    var message = new $root.waiternow.common.DeviceProto.InterestsProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    return message;
                };

                /**
                 * Creates a plain object from an InterestsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {waiternow.common.DeviceProto.InterestsProto} message InterestsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                InterestsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.pointOfServiceId = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    return object;
                };

                /**
                 * Converts this InterestsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                InterestsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for InterestsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeviceProto.InterestsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                InterestsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeviceProto.InterestsProto";
                };

                return InterestsProto;
            })();

            DeviceProto.StatusProto = (function() {

                /**
                 * Properties of a StatusProto.
                 * @memberof waiternow.common.DeviceProto
                 * @interface IStatusProto
                 * @property {waiternow.common.DeviceProto.StatusProto.State|null} [state] StatusProto state
                 * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] StatusProto lastUpdateTime
                 */

                /**
                 * Constructs a new StatusProto.
                 * @memberof waiternow.common.DeviceProto
                 * @classdesc Represents a StatusProto.
                 * @implements IStatusProto
                 * @constructor
                 * @param {waiternow.common.DeviceProto.IStatusProto=} [properties] Properties to set
                 */
                function StatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatusProto state.
                 * @member {waiternow.common.DeviceProto.StatusProto.State} state
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.state = 0;

                /**
                 * StatusProto lastUpdateTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.lastUpdateTime = null;

                /**
                 * Creates a new StatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {waiternow.common.DeviceProto.IStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.DeviceProto.StatusProto} StatusProto instance
                 */
                StatusProto.create = function create(properties) {
                    return new StatusProto(properties);
                };

                /**
                 * Encodes the specified StatusProto message. Does not implicitly {@link waiternow.common.DeviceProto.StatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {waiternow.common.DeviceProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
                    if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                        $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified StatusProto message, length delimited. Does not implicitly {@link waiternow.common.DeviceProto.StatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {waiternow.common.DeviceProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeviceProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceProto.StatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = reader.int32();
                                break;
                            }
                        case 2: {
                                message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeviceProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatusProto message.
                 * @function verify
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                        if (error)
                            return "lastUpdateTime." + error;
                    }
                    return null;
                };

                /**
                 * Creates a StatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeviceProto.StatusProto} StatusProto
                 */
                StatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeviceProto.StatusProto)
                        return object;
                    var message = new $root.waiternow.common.DeviceProto.StatusProto();
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATE_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "ACTIVE":
                    case 1:
                        message.state = 1;
                        break;
                    case "INACTIVE":
                    case 2:
                        message.state = 2;
                        break;
                    case "GRACE_PERIOD_TO_INACTIVE":
                    case 3:
                        message.state = 3;
                        break;
                    }
                    if (object.lastUpdateTime != null) {
                        if (typeof object.lastUpdateTime !== "object")
                            throw TypeError(".waiternow.common.DeviceProto.StatusProto.lastUpdateTime: object expected");
                        message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a StatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {waiternow.common.DeviceProto.StatusProto} message StatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.state = options.enums === String ? "STATE_UNSPECIFIED" : 0;
                        object.lastUpdateTime = null;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.DeviceProto.StatusProto.State[message.state] === undefined ? message.state : $root.waiternow.common.DeviceProto.StatusProto.State[message.state] : message.state;
                    if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                        object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                    return object;
                };

                /**
                 * Converts this StatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeviceProto.StatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeviceProto.StatusProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.DeviceProto.StatusProto.State
                 * @enum {number}
                 * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
                 * @property {number} ACTIVE=1 ACTIVE value
                 * @property {number} INACTIVE=2 INACTIVE value
                 * @property {number} GRACE_PERIOD_TO_INACTIVE=3 GRACE_PERIOD_TO_INACTIVE value
                 */
                StatusProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "ACTIVE"] = 1;
                    values[valuesById[2] = "INACTIVE"] = 2;
                    values[valuesById[3] = "GRACE_PERIOD_TO_INACTIVE"] = 3;
                    return values;
                })();

                return StatusProto;
            })();

            return DeviceProto;
        })();

        common.DeviceSessionInfoProto = (function() {

            /**
             * Properties of a DeviceSessionInfoProto.
             * @memberof waiternow.common
             * @interface IDeviceSessionInfoProto
             * @property {google.protobuf.IDuration|null} [expiration] DeviceSessionInfoProto expiration
             */

            /**
             * Constructs a new DeviceSessionInfoProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeviceSessionInfoProto.
             * @implements IDeviceSessionInfoProto
             * @constructor
             * @param {waiternow.common.IDeviceSessionInfoProto=} [properties] Properties to set
             */
            function DeviceSessionInfoProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeviceSessionInfoProto expiration.
             * @member {google.protobuf.IDuration|null|undefined} expiration
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @instance
             */
            DeviceSessionInfoProto.prototype.expiration = null;

            /**
             * Creates a new DeviceSessionInfoProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {waiternow.common.IDeviceSessionInfoProto=} [properties] Properties to set
             * @returns {waiternow.common.DeviceSessionInfoProto} DeviceSessionInfoProto instance
             */
            DeviceSessionInfoProto.create = function create(properties) {
                return new DeviceSessionInfoProto(properties);
            };

            /**
             * Encodes the specified DeviceSessionInfoProto message. Does not implicitly {@link waiternow.common.DeviceSessionInfoProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {waiternow.common.IDeviceSessionInfoProto} message DeviceSessionInfoProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceSessionInfoProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.expiration != null && Object.hasOwnProperty.call(message, "expiration"))
                    $root.google.protobuf.Duration.encode(message.expiration, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified DeviceSessionInfoProto message, length delimited. Does not implicitly {@link waiternow.common.DeviceSessionInfoProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {waiternow.common.IDeviceSessionInfoProto} message DeviceSessionInfoProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceSessionInfoProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeviceSessionInfoProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeviceSessionInfoProto} DeviceSessionInfoProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceSessionInfoProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceSessionInfoProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.expiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeviceSessionInfoProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeviceSessionInfoProto} DeviceSessionInfoProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceSessionInfoProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeviceSessionInfoProto message.
             * @function verify
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceSessionInfoProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.expiration != null && message.hasOwnProperty("expiration")) {
                    var error = $root.google.protobuf.Duration.verify(message.expiration);
                    if (error)
                        return "expiration." + error;
                }
                return null;
            };

            /**
             * Creates a DeviceSessionInfoProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeviceSessionInfoProto} DeviceSessionInfoProto
             */
            DeviceSessionInfoProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeviceSessionInfoProto)
                    return object;
                var message = new $root.waiternow.common.DeviceSessionInfoProto();
                if (object.expiration != null) {
                    if (typeof object.expiration !== "object")
                        throw TypeError(".waiternow.common.DeviceSessionInfoProto.expiration: object expected");
                    message.expiration = $root.google.protobuf.Duration.fromObject(object.expiration);
                }
                return message;
            };

            /**
             * Creates a plain object from a DeviceSessionInfoProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {waiternow.common.DeviceSessionInfoProto} message DeviceSessionInfoProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceSessionInfoProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.expiration = null;
                if (message.expiration != null && message.hasOwnProperty("expiration"))
                    object.expiration = $root.google.protobuf.Duration.toObject(message.expiration, options);
                return object;
            };

            /**
             * Converts this DeviceSessionInfoProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceSessionInfoProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeviceSessionInfoProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeviceSessionInfoProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeviceSessionInfoProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeviceSessionInfoProto";
            };

            return DeviceSessionInfoProto;
        })();

        common.LocationDevicesProto = (function() {

            /**
             * Properties of a LocationDevicesProto.
             * @memberof waiternow.common
             * @interface ILocationDevicesProto
             * @property {string|null} [locationId] LocationDevicesProto locationId
             * @property {Array.<waiternow.common.IDeviceProto>|null} [devices] LocationDevicesProto devices
             */

            /**
             * Constructs a new LocationDevicesProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationDevicesProto.
             * @implements ILocationDevicesProto
             * @constructor
             * @param {waiternow.common.ILocationDevicesProto=} [properties] Properties to set
             */
            function LocationDevicesProto(properties) {
                this.devices = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationDevicesProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.LocationDevicesProto
             * @instance
             */
            LocationDevicesProto.prototype.locationId = "";

            /**
             * LocationDevicesProto devices.
             * @member {Array.<waiternow.common.IDeviceProto>} devices
             * @memberof waiternow.common.LocationDevicesProto
             * @instance
             */
            LocationDevicesProto.prototype.devices = $util.emptyArray;

            /**
             * Creates a new LocationDevicesProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {waiternow.common.ILocationDevicesProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationDevicesProto} LocationDevicesProto instance
             */
            LocationDevicesProto.create = function create(properties) {
                return new LocationDevicesProto(properties);
            };

            /**
             * Encodes the specified LocationDevicesProto message. Does not implicitly {@link waiternow.common.LocationDevicesProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {waiternow.common.ILocationDevicesProto} message LocationDevicesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationDevicesProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.devices != null && message.devices.length)
                    for (var i = 0; i < message.devices.length; ++i)
                        $root.waiternow.common.DeviceProto.encode(message.devices[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LocationDevicesProto message, length delimited. Does not implicitly {@link waiternow.common.LocationDevicesProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {waiternow.common.ILocationDevicesProto} message LocationDevicesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationDevicesProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationDevicesProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationDevicesProto} LocationDevicesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationDevicesProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationDevicesProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.devices && message.devices.length))
                                message.devices = [];
                            message.devices.push($root.waiternow.common.DeviceProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationDevicesProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationDevicesProto} LocationDevicesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationDevicesProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationDevicesProto message.
             * @function verify
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationDevicesProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.devices != null && message.hasOwnProperty("devices")) {
                    if (!Array.isArray(message.devices))
                        return "devices: array expected";
                    for (var i = 0; i < message.devices.length; ++i) {
                        var error = $root.waiternow.common.DeviceProto.verify(message.devices[i]);
                        if (error)
                            return "devices." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a LocationDevicesProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationDevicesProto} LocationDevicesProto
             */
            LocationDevicesProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationDevicesProto)
                    return object;
                var message = new $root.waiternow.common.LocationDevicesProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.devices) {
                    if (!Array.isArray(object.devices))
                        throw TypeError(".waiternow.common.LocationDevicesProto.devices: array expected");
                    message.devices = [];
                    for (var i = 0; i < object.devices.length; ++i) {
                        if (typeof object.devices[i] !== "object")
                            throw TypeError(".waiternow.common.LocationDevicesProto.devices: object expected");
                        message.devices[i] = $root.waiternow.common.DeviceProto.fromObject(object.devices[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a LocationDevicesProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {waiternow.common.LocationDevicesProto} message LocationDevicesProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationDevicesProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.devices = [];
                if (options.defaults)
                    object.locationId = "";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.devices && message.devices.length) {
                    object.devices = [];
                    for (var j = 0; j < message.devices.length; ++j)
                        object.devices[j] = $root.waiternow.common.DeviceProto.toObject(message.devices[j], options);
                }
                return object;
            };

            /**
             * Converts this LocationDevicesProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationDevicesProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationDevicesProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationDevicesProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationDevicesProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationDevicesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationDevicesProto";
            };

            return LocationDevicesProto;
        })();

        common.PointOfServiceDevicesProto = (function() {

            /**
             * Properties of a PointOfServiceDevicesProto.
             * @memberof waiternow.common
             * @interface IPointOfServiceDevicesProto
             * @property {string|null} [pointOfServiceId] PointOfServiceDevicesProto pointOfServiceId
             * @property {Array.<waiternow.common.IDeviceProto>|null} [devices] PointOfServiceDevicesProto devices
             */

            /**
             * Constructs a new PointOfServiceDevicesProto.
             * @memberof waiternow.common
             * @classdesc Represents a PointOfServiceDevicesProto.
             * @implements IPointOfServiceDevicesProto
             * @constructor
             * @param {waiternow.common.IPointOfServiceDevicesProto=} [properties] Properties to set
             */
            function PointOfServiceDevicesProto(properties) {
                this.devices = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PointOfServiceDevicesProto pointOfServiceId.
             * @member {string} pointOfServiceId
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @instance
             */
            PointOfServiceDevicesProto.prototype.pointOfServiceId = "";

            /**
             * PointOfServiceDevicesProto devices.
             * @member {Array.<waiternow.common.IDeviceProto>} devices
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @instance
             */
            PointOfServiceDevicesProto.prototype.devices = $util.emptyArray;

            /**
             * Creates a new PointOfServiceDevicesProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {waiternow.common.IPointOfServiceDevicesProto=} [properties] Properties to set
             * @returns {waiternow.common.PointOfServiceDevicesProto} PointOfServiceDevicesProto instance
             */
            PointOfServiceDevicesProto.create = function create(properties) {
                return new PointOfServiceDevicesProto(properties);
            };

            /**
             * Encodes the specified PointOfServiceDevicesProto message. Does not implicitly {@link waiternow.common.PointOfServiceDevicesProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {waiternow.common.IPointOfServiceDevicesProto} message PointOfServiceDevicesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PointOfServiceDevicesProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                if (message.devices != null && message.devices.length)
                    for (var i = 0; i < message.devices.length; ++i)
                        $root.waiternow.common.DeviceProto.encode(message.devices[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PointOfServiceDevicesProto message, length delimited. Does not implicitly {@link waiternow.common.PointOfServiceDevicesProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {waiternow.common.IPointOfServiceDevicesProto} message PointOfServiceDevicesProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PointOfServiceDevicesProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PointOfServiceDevicesProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PointOfServiceDevicesProto} PointOfServiceDevicesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PointOfServiceDevicesProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PointOfServiceDevicesProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.pointOfServiceId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.devices && message.devices.length))
                                message.devices = [];
                            message.devices.push($root.waiternow.common.DeviceProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PointOfServiceDevicesProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PointOfServiceDevicesProto} PointOfServiceDevicesProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PointOfServiceDevicesProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PointOfServiceDevicesProto message.
             * @function verify
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PointOfServiceDevicesProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    if (!$util.isString(message.pointOfServiceId))
                        return "pointOfServiceId: string expected";
                if (message.devices != null && message.hasOwnProperty("devices")) {
                    if (!Array.isArray(message.devices))
                        return "devices: array expected";
                    for (var i = 0; i < message.devices.length; ++i) {
                        var error = $root.waiternow.common.DeviceProto.verify(message.devices[i]);
                        if (error)
                            return "devices." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PointOfServiceDevicesProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PointOfServiceDevicesProto} PointOfServiceDevicesProto
             */
            PointOfServiceDevicesProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PointOfServiceDevicesProto)
                    return object;
                var message = new $root.waiternow.common.PointOfServiceDevicesProto();
                if (object.pointOfServiceId != null)
                    message.pointOfServiceId = String(object.pointOfServiceId);
                if (object.devices) {
                    if (!Array.isArray(object.devices))
                        throw TypeError(".waiternow.common.PointOfServiceDevicesProto.devices: array expected");
                    message.devices = [];
                    for (var i = 0; i < object.devices.length; ++i) {
                        if (typeof object.devices[i] !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceDevicesProto.devices: object expected");
                        message.devices[i] = $root.waiternow.common.DeviceProto.fromObject(object.devices[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PointOfServiceDevicesProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {waiternow.common.PointOfServiceDevicesProto} message PointOfServiceDevicesProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PointOfServiceDevicesProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.devices = [];
                if (options.defaults)
                    object.pointOfServiceId = "";
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    object.pointOfServiceId = message.pointOfServiceId;
                if (message.devices && message.devices.length) {
                    object.devices = [];
                    for (var j = 0; j < message.devices.length; ++j)
                        object.devices[j] = $root.waiternow.common.DeviceProto.toObject(message.devices[j], options);
                }
                return object;
            };

            /**
             * Converts this PointOfServiceDevicesProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PointOfServiceDevicesProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PointOfServiceDevicesProto
             * @function getTypeUrl
             * @memberof waiternow.common.PointOfServiceDevicesProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PointOfServiceDevicesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PointOfServiceDevicesProto";
            };

            return PointOfServiceDevicesProto;
        })();

        common.FeedbackProto = (function() {

            /**
             * Properties of a FeedbackProto.
             * @memberof waiternow.common
             * @interface IFeedbackProto
             * @property {string|null} [id] FeedbackProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] FeedbackProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] FeedbackProto lastUpdateTime
             * @property {string|null} [locationId] FeedbackProto locationId
             * @property {string|null} [userId] FeedbackProto userId
             * @property {string|null} [consumerId] FeedbackProto consumerId
             * @property {string|null} [deviceId] FeedbackProto deviceId
             * @property {string|null} [summary] FeedbackProto summary
             * @property {string|null} [description] FeedbackProto description
             * @property {waiternow.common.FeedbackProto.Target|null} [target] FeedbackProto target
             * @property {waiternow.common.FeedbackProto.Type|null} [type] FeedbackProto type
             * @property {waiternow.common.FeedbackProto.Product|null} [product] FeedbackProto product
             * @property {waiternow.common.FeedbackProto.Browser|null} [browser] FeedbackProto browser
             * @property {waiternow.common.FeedbackProto.Environment|null} [environment] FeedbackProto environment
             * @property {waiternow.common.FeedbackProto.Priority|null} [priority] FeedbackProto priority
             * @property {waiternow.common.FeedbackProto.Severity|null} [severity] FeedbackProto severity
             * @property {waiternow.common.FeedbackProto.IStatusProto|null} [status] FeedbackProto status
             * @property {Array.<waiternow.common.FeedbackProto.IReply>|null} [replies] FeedbackProto replies
             */

            /**
             * Constructs a new FeedbackProto.
             * @memberof waiternow.common
             * @classdesc Represents a FeedbackProto.
             * @implements IFeedbackProto
             * @constructor
             * @param {waiternow.common.IFeedbackProto=} [properties] Properties to set
             */
            function FeedbackProto(properties) {
                this.replies = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * FeedbackProto id.
             * @member {string} id
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.id = "";

            /**
             * FeedbackProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.creationTime = null;

            /**
             * FeedbackProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.lastUpdateTime = null;

            /**
             * FeedbackProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.locationId = "";

            /**
             * FeedbackProto userId.
             * @member {string} userId
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.userId = "";

            /**
             * FeedbackProto consumerId.
             * @member {string} consumerId
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.consumerId = "";

            /**
             * FeedbackProto deviceId.
             * @member {string} deviceId
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.deviceId = "";

            /**
             * FeedbackProto summary.
             * @member {string} summary
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.summary = "";

            /**
             * FeedbackProto description.
             * @member {string} description
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.description = "";

            /**
             * FeedbackProto target.
             * @member {waiternow.common.FeedbackProto.Target} target
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.target = 0;

            /**
             * FeedbackProto type.
             * @member {waiternow.common.FeedbackProto.Type} type
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.type = 0;

            /**
             * FeedbackProto product.
             * @member {waiternow.common.FeedbackProto.Product} product
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.product = 0;

            /**
             * FeedbackProto browser.
             * @member {waiternow.common.FeedbackProto.Browser} browser
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.browser = 0;

            /**
             * FeedbackProto environment.
             * @member {waiternow.common.FeedbackProto.Environment} environment
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.environment = 0;

            /**
             * FeedbackProto priority.
             * @member {waiternow.common.FeedbackProto.Priority} priority
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.priority = 0;

            /**
             * FeedbackProto severity.
             * @member {waiternow.common.FeedbackProto.Severity} severity
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.severity = 0;

            /**
             * FeedbackProto status.
             * @member {waiternow.common.FeedbackProto.IStatusProto|null|undefined} status
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.status = null;

            /**
             * FeedbackProto replies.
             * @member {Array.<waiternow.common.FeedbackProto.IReply>} replies
             * @memberof waiternow.common.FeedbackProto
             * @instance
             */
            FeedbackProto.prototype.replies = $util.emptyArray;

            /**
             * Creates a new FeedbackProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {waiternow.common.IFeedbackProto=} [properties] Properties to set
             * @returns {waiternow.common.FeedbackProto} FeedbackProto instance
             */
            FeedbackProto.create = function create(properties) {
                return new FeedbackProto(properties);
            };

            /**
             * Encodes the specified FeedbackProto message. Does not implicitly {@link waiternow.common.FeedbackProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {waiternow.common.IFeedbackProto} message FeedbackProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FeedbackProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.locationId);
                if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.userId);
                if (message.consumerId != null && Object.hasOwnProperty.call(message, "consumerId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.consumerId);
                if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.deviceId);
                if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.summary);
                if (message.description != null && Object.hasOwnProperty.call(message, "description"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.description);
                if (message.target != null && Object.hasOwnProperty.call(message, "target"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int32(message.target);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.type);
                if (message.product != null && Object.hasOwnProperty.call(message, "product"))
                    writer.uint32(/* id 12, wireType 0 =*/96).int32(message.product);
                if (message.browser != null && Object.hasOwnProperty.call(message, "browser"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.browser);
                if (message.environment != null && Object.hasOwnProperty.call(message, "environment"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.environment);
                if (message.priority != null && Object.hasOwnProperty.call(message, "priority"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int32(message.priority);
                if (message.severity != null && Object.hasOwnProperty.call(message, "severity"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int32(message.severity);
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.waiternow.common.FeedbackProto.StatusProto.encode(message.status, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.replies != null && message.replies.length)
                    for (var i = 0; i < message.replies.length; ++i)
                        $root.waiternow.common.FeedbackProto.Reply.encode(message.replies[i], writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified FeedbackProto message, length delimited. Does not implicitly {@link waiternow.common.FeedbackProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {waiternow.common.IFeedbackProto} message FeedbackProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FeedbackProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FeedbackProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FeedbackProto} FeedbackProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FeedbackProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FeedbackProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 5: {
                            message.userId = reader.string();
                            break;
                        }
                    case 6: {
                            message.consumerId = reader.string();
                            break;
                        }
                    case 7: {
                            message.deviceId = reader.string();
                            break;
                        }
                    case 8: {
                            message.summary = reader.string();
                            break;
                        }
                    case 9: {
                            message.description = reader.string();
                            break;
                        }
                    case 10: {
                            message.target = reader.int32();
                            break;
                        }
                    case 11: {
                            message.type = reader.int32();
                            break;
                        }
                    case 12: {
                            message.product = reader.int32();
                            break;
                        }
                    case 13: {
                            message.browser = reader.int32();
                            break;
                        }
                    case 14: {
                            message.environment = reader.int32();
                            break;
                        }
                    case 15: {
                            message.priority = reader.int32();
                            break;
                        }
                    case 16: {
                            message.severity = reader.int32();
                            break;
                        }
                    case 17: {
                            message.status = $root.waiternow.common.FeedbackProto.StatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            if (!(message.replies && message.replies.length))
                                message.replies = [];
                            message.replies.push($root.waiternow.common.FeedbackProto.Reply.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FeedbackProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FeedbackProto} FeedbackProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FeedbackProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FeedbackProto message.
             * @function verify
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FeedbackProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.userId != null && message.hasOwnProperty("userId"))
                    if (!$util.isString(message.userId))
                        return "userId: string expected";
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    if (!$util.isString(message.consumerId))
                        return "consumerId: string expected";
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    if (!$util.isString(message.deviceId))
                        return "deviceId: string expected";
                if (message.summary != null && message.hasOwnProperty("summary"))
                    if (!$util.isString(message.summary))
                        return "summary: string expected";
                if (message.description != null && message.hasOwnProperty("description"))
                    if (!$util.isString(message.description))
                        return "description: string expected";
                if (message.target != null && message.hasOwnProperty("target"))
                    switch (message.target) {
                    default:
                        return "target: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.product != null && message.hasOwnProperty("product"))
                    switch (message.product) {
                    default:
                        return "product: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.browser != null && message.hasOwnProperty("browser"))
                    switch (message.browser) {
                    default:
                        return "browser: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.environment != null && message.hasOwnProperty("environment"))
                    switch (message.environment) {
                    default:
                        return "environment: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                if (message.priority != null && message.hasOwnProperty("priority"))
                    switch (message.priority) {
                    default:
                        return "priority: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.severity != null && message.hasOwnProperty("severity"))
                    switch (message.severity) {
                    default:
                        return "severity: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.status != null && message.hasOwnProperty("status")) {
                    var error = $root.waiternow.common.FeedbackProto.StatusProto.verify(message.status);
                    if (error)
                        return "status." + error;
                }
                if (message.replies != null && message.hasOwnProperty("replies")) {
                    if (!Array.isArray(message.replies))
                        return "replies: array expected";
                    for (var i = 0; i < message.replies.length; ++i) {
                        var error = $root.waiternow.common.FeedbackProto.Reply.verify(message.replies[i]);
                        if (error)
                            return "replies." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a FeedbackProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FeedbackProto} FeedbackProto
             */
            FeedbackProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FeedbackProto)
                    return object;
                var message = new $root.waiternow.common.FeedbackProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.FeedbackProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.FeedbackProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.userId != null)
                    message.userId = String(object.userId);
                if (object.consumerId != null)
                    message.consumerId = String(object.consumerId);
                if (object.deviceId != null)
                    message.deviceId = String(object.deviceId);
                if (object.summary != null)
                    message.summary = String(object.summary);
                if (object.description != null)
                    message.description = String(object.description);
                switch (object.target) {
                default:
                    if (typeof object.target === "number") {
                        message.target = object.target;
                        break;
                    }
                    break;
                case "TARGET_UNSPECIFIED":
                case 0:
                    message.target = 0;
                    break;
                case "PLATFORM":
                case 1:
                    message.target = 1;
                    break;
                case "BUSINESS":
                case 2:
                    message.target = 2;
                    break;
                }
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "TYPE_UNSPECIFIED":
                case 0:
                    message.type = 0;
                    break;
                case "FEATURE_REQUEST":
                case 1:
                    message.type = 1;
                    break;
                case "ISSUE":
                case 2:
                    message.type = 2;
                    break;
                }
                switch (object.product) {
                default:
                    if (typeof object.product === "number") {
                        message.product = object.product;
                        break;
                    }
                    break;
                case "PRODUCT_UNSPECIFIED":
                case 0:
                    message.product = 0;
                    break;
                case "CONSUMER_APP_WEB":
                case 1:
                    message.product = 1;
                    break;
                case "BUSINESS_APP_WEB":
                case 2:
                    message.product = 2;
                    break;
                case "BUSINESS_APP_ANDROID":
                case 3:
                    message.product = 3;
                    break;
                case "ADMIN_APP_WEB":
                case 4:
                    message.product = 4;
                    break;
                case "GSHEET_MENU_EDITOR_WEB":
                case 5:
                    message.product = 5;
                    break;
                }
                switch (object.browser) {
                default:
                    if (typeof object.browser === "number") {
                        message.browser = object.browser;
                        break;
                    }
                    break;
                case "BROWSER_UNSPECIFIED":
                case 0:
                    message.browser = 0;
                    break;
                case "CHROME":
                case 1:
                    message.browser = 1;
                    break;
                case "FIREFOX":
                case 2:
                    message.browser = 2;
                    break;
                case "SAFARI":
                case 3:
                    message.browser = 3;
                    break;
                }
                switch (object.environment) {
                default:
                    if (typeof object.environment === "number") {
                        message.environment = object.environment;
                        break;
                    }
                    break;
                case "ENVIRONMENT_UNSPECIFIED":
                case 0:
                    message.environment = 0;
                    break;
                case "LINUX":
                case 1:
                    message.environment = 1;
                    break;
                case "WINDOWS":
                case 2:
                    message.environment = 2;
                    break;
                case "MAC":
                case 3:
                    message.environment = 3;
                    break;
                case "ANDROID":
                case 4:
                    message.environment = 4;
                    break;
                case "IOS":
                case 5:
                    message.environment = 5;
                    break;
                }
                switch (object.priority) {
                default:
                    if (typeof object.priority === "number") {
                        message.priority = object.priority;
                        break;
                    }
                    break;
                case "PRIORITY_UNSPECIFIED":
                case 0:
                    message.priority = 0;
                    break;
                case "P0":
                case 1:
                    message.priority = 1;
                    break;
                case "P1":
                case 2:
                    message.priority = 2;
                    break;
                case "P2":
                case 3:
                    message.priority = 3;
                    break;
                case "P3":
                case 4:
                    message.priority = 4;
                    break;
                }
                switch (object.severity) {
                default:
                    if (typeof object.severity === "number") {
                        message.severity = object.severity;
                        break;
                    }
                    break;
                case "SEVERITY_UNSPECIFIED":
                case 0:
                    message.severity = 0;
                    break;
                case "LOW":
                case 1:
                    message.severity = 1;
                    break;
                case "MEDIUM":
                case 2:
                    message.severity = 2;
                    break;
                case "HIGH":
                case 3:
                    message.severity = 3;
                    break;
                case "CRITICAL":
                case 4:
                    message.severity = 4;
                    break;
                }
                if (object.status != null) {
                    if (typeof object.status !== "object")
                        throw TypeError(".waiternow.common.FeedbackProto.status: object expected");
                    message.status = $root.waiternow.common.FeedbackProto.StatusProto.fromObject(object.status);
                }
                if (object.replies) {
                    if (!Array.isArray(object.replies))
                        throw TypeError(".waiternow.common.FeedbackProto.replies: array expected");
                    message.replies = [];
                    for (var i = 0; i < object.replies.length; ++i) {
                        if (typeof object.replies[i] !== "object")
                            throw TypeError(".waiternow.common.FeedbackProto.replies: object expected");
                        message.replies[i] = $root.waiternow.common.FeedbackProto.Reply.fromObject(object.replies[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a FeedbackProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {waiternow.common.FeedbackProto} message FeedbackProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FeedbackProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.replies = [];
                if (options.defaults) {
                    object.id = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.locationId = "";
                    object.userId = "";
                    object.consumerId = "";
                    object.deviceId = "";
                    object.summary = "";
                    object.description = "";
                    object.target = options.enums === String ? "TARGET_UNSPECIFIED" : 0;
                    object.type = options.enums === String ? "TYPE_UNSPECIFIED" : 0;
                    object.product = options.enums === String ? "PRODUCT_UNSPECIFIED" : 0;
                    object.browser = options.enums === String ? "BROWSER_UNSPECIFIED" : 0;
                    object.environment = options.enums === String ? "ENVIRONMENT_UNSPECIFIED" : 0;
                    object.priority = options.enums === String ? "PRIORITY_UNSPECIFIED" : 0;
                    object.severity = options.enums === String ? "SEVERITY_UNSPECIFIED" : 0;
                    object.status = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.userId != null && message.hasOwnProperty("userId"))
                    object.userId = message.userId;
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    object.consumerId = message.consumerId;
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    object.deviceId = message.deviceId;
                if (message.summary != null && message.hasOwnProperty("summary"))
                    object.summary = message.summary;
                if (message.description != null && message.hasOwnProperty("description"))
                    object.description = message.description;
                if (message.target != null && message.hasOwnProperty("target"))
                    object.target = options.enums === String ? $root.waiternow.common.FeedbackProto.Target[message.target] === undefined ? message.target : $root.waiternow.common.FeedbackProto.Target[message.target] : message.target;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.waiternow.common.FeedbackProto.Type[message.type] === undefined ? message.type : $root.waiternow.common.FeedbackProto.Type[message.type] : message.type;
                if (message.product != null && message.hasOwnProperty("product"))
                    object.product = options.enums === String ? $root.waiternow.common.FeedbackProto.Product[message.product] === undefined ? message.product : $root.waiternow.common.FeedbackProto.Product[message.product] : message.product;
                if (message.browser != null && message.hasOwnProperty("browser"))
                    object.browser = options.enums === String ? $root.waiternow.common.FeedbackProto.Browser[message.browser] === undefined ? message.browser : $root.waiternow.common.FeedbackProto.Browser[message.browser] : message.browser;
                if (message.environment != null && message.hasOwnProperty("environment"))
                    object.environment = options.enums === String ? $root.waiternow.common.FeedbackProto.Environment[message.environment] === undefined ? message.environment : $root.waiternow.common.FeedbackProto.Environment[message.environment] : message.environment;
                if (message.priority != null && message.hasOwnProperty("priority"))
                    object.priority = options.enums === String ? $root.waiternow.common.FeedbackProto.Priority[message.priority] === undefined ? message.priority : $root.waiternow.common.FeedbackProto.Priority[message.priority] : message.priority;
                if (message.severity != null && message.hasOwnProperty("severity"))
                    object.severity = options.enums === String ? $root.waiternow.common.FeedbackProto.Severity[message.severity] === undefined ? message.severity : $root.waiternow.common.FeedbackProto.Severity[message.severity] : message.severity;
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = $root.waiternow.common.FeedbackProto.StatusProto.toObject(message.status, options);
                if (message.replies && message.replies.length) {
                    object.replies = [];
                    for (var j = 0; j < message.replies.length; ++j)
                        object.replies[j] = $root.waiternow.common.FeedbackProto.Reply.toObject(message.replies[j], options);
                }
                return object;
            };

            /**
             * Converts this FeedbackProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FeedbackProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FeedbackProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FeedbackProto
             * @function getTypeUrl
             * @memberof waiternow.common.FeedbackProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FeedbackProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FeedbackProto";
            };

            /**
             * Target enum.
             * @name waiternow.common.FeedbackProto.Target
             * @enum {number}
             * @property {number} TARGET_UNSPECIFIED=0 TARGET_UNSPECIFIED value
             * @property {number} PLATFORM=1 PLATFORM value
             * @property {number} BUSINESS=2 BUSINESS value
             */
            FeedbackProto.Target = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TARGET_UNSPECIFIED"] = 0;
                values[valuesById[1] = "PLATFORM"] = 1;
                values[valuesById[2] = "BUSINESS"] = 2;
                return values;
            })();

            /**
             * Type enum.
             * @name waiternow.common.FeedbackProto.Type
             * @enum {number}
             * @property {number} TYPE_UNSPECIFIED=0 TYPE_UNSPECIFIED value
             * @property {number} FEATURE_REQUEST=1 FEATURE_REQUEST value
             * @property {number} ISSUE=2 ISSUE value
             */
            FeedbackProto.Type = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "FEATURE_REQUEST"] = 1;
                values[valuesById[2] = "ISSUE"] = 2;
                return values;
            })();

            /**
             * Product enum.
             * @name waiternow.common.FeedbackProto.Product
             * @enum {number}
             * @property {number} PRODUCT_UNSPECIFIED=0 PRODUCT_UNSPECIFIED value
             * @property {number} CONSUMER_APP_WEB=1 CONSUMER_APP_WEB value
             * @property {number} BUSINESS_APP_WEB=2 BUSINESS_APP_WEB value
             * @property {number} BUSINESS_APP_ANDROID=3 BUSINESS_APP_ANDROID value
             * @property {number} ADMIN_APP_WEB=4 ADMIN_APP_WEB value
             * @property {number} GSHEET_MENU_EDITOR_WEB=5 GSHEET_MENU_EDITOR_WEB value
             */
            FeedbackProto.Product = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PRODUCT_UNSPECIFIED"] = 0;
                values[valuesById[1] = "CONSUMER_APP_WEB"] = 1;
                values[valuesById[2] = "BUSINESS_APP_WEB"] = 2;
                values[valuesById[3] = "BUSINESS_APP_ANDROID"] = 3;
                values[valuesById[4] = "ADMIN_APP_WEB"] = 4;
                values[valuesById[5] = "GSHEET_MENU_EDITOR_WEB"] = 5;
                return values;
            })();

            /**
             * Browser enum.
             * @name waiternow.common.FeedbackProto.Browser
             * @enum {number}
             * @property {number} BROWSER_UNSPECIFIED=0 BROWSER_UNSPECIFIED value
             * @property {number} CHROME=1 CHROME value
             * @property {number} FIREFOX=2 FIREFOX value
             * @property {number} SAFARI=3 SAFARI value
             */
            FeedbackProto.Browser = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "BROWSER_UNSPECIFIED"] = 0;
                values[valuesById[1] = "CHROME"] = 1;
                values[valuesById[2] = "FIREFOX"] = 2;
                values[valuesById[3] = "SAFARI"] = 3;
                return values;
            })();

            /**
             * Environment enum.
             * @name waiternow.common.FeedbackProto.Environment
             * @enum {number}
             * @property {number} ENVIRONMENT_UNSPECIFIED=0 ENVIRONMENT_UNSPECIFIED value
             * @property {number} LINUX=1 LINUX value
             * @property {number} WINDOWS=2 WINDOWS value
             * @property {number} MAC=3 MAC value
             * @property {number} ANDROID=4 ANDROID value
             * @property {number} IOS=5 IOS value
             */
            FeedbackProto.Environment = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "ENVIRONMENT_UNSPECIFIED"] = 0;
                values[valuesById[1] = "LINUX"] = 1;
                values[valuesById[2] = "WINDOWS"] = 2;
                values[valuesById[3] = "MAC"] = 3;
                values[valuesById[4] = "ANDROID"] = 4;
                values[valuesById[5] = "IOS"] = 5;
                return values;
            })();

            /**
             * Priority enum.
             * @name waiternow.common.FeedbackProto.Priority
             * @enum {number}
             * @property {number} PRIORITY_UNSPECIFIED=0 PRIORITY_UNSPECIFIED value
             * @property {number} P0=1 P0 value
             * @property {number} P1=2 P1 value
             * @property {number} P2=3 P2 value
             * @property {number} P3=4 P3 value
             */
            FeedbackProto.Priority = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "PRIORITY_UNSPECIFIED"] = 0;
                values[valuesById[1] = "P0"] = 1;
                values[valuesById[2] = "P1"] = 2;
                values[valuesById[3] = "P2"] = 3;
                values[valuesById[4] = "P3"] = 4;
                return values;
            })();

            /**
             * Severity enum.
             * @name waiternow.common.FeedbackProto.Severity
             * @enum {number}
             * @property {number} SEVERITY_UNSPECIFIED=0 SEVERITY_UNSPECIFIED value
             * @property {number} LOW=1 LOW value
             * @property {number} MEDIUM=2 MEDIUM value
             * @property {number} HIGH=3 HIGH value
             * @property {number} CRITICAL=4 CRITICAL value
             */
            FeedbackProto.Severity = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "SEVERITY_UNSPECIFIED"] = 0;
                values[valuesById[1] = "LOW"] = 1;
                values[valuesById[2] = "MEDIUM"] = 2;
                values[valuesById[3] = "HIGH"] = 3;
                values[valuesById[4] = "CRITICAL"] = 4;
                return values;
            })();

            FeedbackProto.StatusProto = (function() {

                /**
                 * Properties of a StatusProto.
                 * @memberof waiternow.common.FeedbackProto
                 * @interface IStatusProto
                 * @property {waiternow.common.FeedbackProto.StatusProto.State|null} [state] StatusProto state
                 * @property {waiternow.common.FeedbackProto.StatusProto.Substate|null} [substate] StatusProto substate
                 * @property {string|null} [duplicateOfId] StatusProto duplicateOfId
                 */

                /**
                 * Constructs a new StatusProto.
                 * @memberof waiternow.common.FeedbackProto
                 * @classdesc Represents a StatusProto.
                 * @implements IStatusProto
                 * @constructor
                 * @param {waiternow.common.FeedbackProto.IStatusProto=} [properties] Properties to set
                 */
                function StatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatusProto state.
                 * @member {waiternow.common.FeedbackProto.StatusProto.State} state
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.state = 0;

                /**
                 * StatusProto substate.
                 * @member {waiternow.common.FeedbackProto.StatusProto.Substate} substate
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.substate = 0;

                /**
                 * StatusProto duplicateOfId.
                 * @member {string} duplicateOfId
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.duplicateOfId = "";

                /**
                 * Creates a new StatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {waiternow.common.FeedbackProto.IStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.FeedbackProto.StatusProto} StatusProto instance
                 */
                StatusProto.create = function create(properties) {
                    return new StatusProto(properties);
                };

                /**
                 * Encodes the specified StatusProto message. Does not implicitly {@link waiternow.common.FeedbackProto.StatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {waiternow.common.FeedbackProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
                    if (message.substate != null && Object.hasOwnProperty.call(message, "substate"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.substate);
                    if (message.duplicateOfId != null && Object.hasOwnProperty.call(message, "duplicateOfId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.duplicateOfId);
                    return writer;
                };

                /**
                 * Encodes the specified StatusProto message, length delimited. Does not implicitly {@link waiternow.common.FeedbackProto.StatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {waiternow.common.FeedbackProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FeedbackProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FeedbackProto.StatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = reader.int32();
                                break;
                            }
                        case 2: {
                                message.substate = reader.int32();
                                break;
                            }
                        case 3: {
                                message.duplicateOfId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FeedbackProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatusProto message.
                 * @function verify
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.substate != null && message.hasOwnProperty("substate"))
                        switch (message.substate) {
                        default:
                            return "substate: enum value expected";
                        case 0:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                    if (message.duplicateOfId != null && message.hasOwnProperty("duplicateOfId"))
                        if (!$util.isString(message.duplicateOfId))
                            return "duplicateOfId: string expected";
                    return null;
                };

                /**
                 * Creates a StatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FeedbackProto.StatusProto} StatusProto
                 */
                StatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FeedbackProto.StatusProto)
                        return object;
                    var message = new $root.waiternow.common.FeedbackProto.StatusProto();
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATE_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "NEW":
                    case 1:
                        message.state = 1;
                        break;
                    case "IN_PROGRESS":
                    case 2:
                        message.state = 2;
                        break;
                    case "FIXED":
                    case 3:
                        message.state = 3;
                        break;
                    case "WONT_FIX":
                    case 4:
                        message.state = 4;
                        break;
                    }
                    switch (object.substate) {
                    default:
                        if (typeof object.substate === "number") {
                            message.substate = object.substate;
                            break;
                        }
                        break;
                    case "SUBSTATE_UNSPECIFIED":
                    case 0:
                        message.substate = 0;
                        break;
                    case "WONT_FIX_DUPLICATE":
                    case 4:
                        message.substate = 4;
                        break;
                    case "WONT_FIX_NOT_REPRODUCIBLE":
                    case 5:
                        message.substate = 5;
                        break;
                    case "WONT_FIX_OBSOLETE":
                    case 6:
                        message.substate = 6;
                        break;
                    case "WONT_FIX_WORKING_AS_INTENDED":
                    case 7:
                        message.substate = 7;
                        break;
                    }
                    if (object.duplicateOfId != null)
                        message.duplicateOfId = String(object.duplicateOfId);
                    return message;
                };

                /**
                 * Creates a plain object from a StatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {waiternow.common.FeedbackProto.StatusProto} message StatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.state = options.enums === String ? "STATE_UNSPECIFIED" : 0;
                        object.substate = options.enums === String ? "SUBSTATE_UNSPECIFIED" : 0;
                        object.duplicateOfId = "";
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.FeedbackProto.StatusProto.State[message.state] === undefined ? message.state : $root.waiternow.common.FeedbackProto.StatusProto.State[message.state] : message.state;
                    if (message.substate != null && message.hasOwnProperty("substate"))
                        object.substate = options.enums === String ? $root.waiternow.common.FeedbackProto.StatusProto.Substate[message.substate] === undefined ? message.substate : $root.waiternow.common.FeedbackProto.StatusProto.Substate[message.substate] : message.substate;
                    if (message.duplicateOfId != null && message.hasOwnProperty("duplicateOfId"))
                        object.duplicateOfId = message.duplicateOfId;
                    return object;
                };

                /**
                 * Converts this StatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.FeedbackProto.StatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FeedbackProto.StatusProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.FeedbackProto.StatusProto.State
                 * @enum {number}
                 * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
                 * @property {number} NEW=1 NEW value
                 * @property {number} IN_PROGRESS=2 IN_PROGRESS value
                 * @property {number} FIXED=3 FIXED value
                 * @property {number} WONT_FIX=4 WONT_FIX value
                 */
                StatusProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "NEW"] = 1;
                    values[valuesById[2] = "IN_PROGRESS"] = 2;
                    values[valuesById[3] = "FIXED"] = 3;
                    values[valuesById[4] = "WONT_FIX"] = 4;
                    return values;
                })();

                /**
                 * Substate enum.
                 * @name waiternow.common.FeedbackProto.StatusProto.Substate
                 * @enum {number}
                 * @property {number} SUBSTATE_UNSPECIFIED=0 SUBSTATE_UNSPECIFIED value
                 * @property {number} WONT_FIX_DUPLICATE=4 WONT_FIX_DUPLICATE value
                 * @property {number} WONT_FIX_NOT_REPRODUCIBLE=5 WONT_FIX_NOT_REPRODUCIBLE value
                 * @property {number} WONT_FIX_OBSOLETE=6 WONT_FIX_OBSOLETE value
                 * @property {number} WONT_FIX_WORKING_AS_INTENDED=7 WONT_FIX_WORKING_AS_INTENDED value
                 */
                StatusProto.Substate = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SUBSTATE_UNSPECIFIED"] = 0;
                    values[valuesById[4] = "WONT_FIX_DUPLICATE"] = 4;
                    values[valuesById[5] = "WONT_FIX_NOT_REPRODUCIBLE"] = 5;
                    values[valuesById[6] = "WONT_FIX_OBSOLETE"] = 6;
                    values[valuesById[7] = "WONT_FIX_WORKING_AS_INTENDED"] = 7;
                    return values;
                })();

                return StatusProto;
            })();

            FeedbackProto.Reply = (function() {

                /**
                 * Properties of a Reply.
                 * @memberof waiternow.common.FeedbackProto
                 * @interface IReply
                 * @property {string|null} [repliedById] Reply repliedById
                 * @property {string|null} [repliedByEmail] Reply repliedByEmail
                 * @property {string|null} [repliedByName] Reply repliedByName
                 * @property {google.protobuf.ITimestamp|null} [timestamp] Reply timestamp
                 * @property {string|null} [message] Reply message
                 */

                /**
                 * Constructs a new Reply.
                 * @memberof waiternow.common.FeedbackProto
                 * @classdesc Represents a Reply.
                 * @implements IReply
                 * @constructor
                 * @param {waiternow.common.FeedbackProto.IReply=} [properties] Properties to set
                 */
                function Reply(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Reply repliedById.
                 * @member {string} repliedById
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @instance
                 */
                Reply.prototype.repliedById = "";

                /**
                 * Reply repliedByEmail.
                 * @member {string} repliedByEmail
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @instance
                 */
                Reply.prototype.repliedByEmail = "";

                /**
                 * Reply repliedByName.
                 * @member {string} repliedByName
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @instance
                 */
                Reply.prototype.repliedByName = "";

                /**
                 * Reply timestamp.
                 * @member {google.protobuf.ITimestamp|null|undefined} timestamp
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @instance
                 */
                Reply.prototype.timestamp = null;

                /**
                 * Reply message.
                 * @member {string} message
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @instance
                 */
                Reply.prototype.message = "";

                /**
                 * Creates a new Reply instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {waiternow.common.FeedbackProto.IReply=} [properties] Properties to set
                 * @returns {waiternow.common.FeedbackProto.Reply} Reply instance
                 */
                Reply.create = function create(properties) {
                    return new Reply(properties);
                };

                /**
                 * Encodes the specified Reply message. Does not implicitly {@link waiternow.common.FeedbackProto.Reply.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {waiternow.common.FeedbackProto.IReply} message Reply message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Reply.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.repliedById != null && Object.hasOwnProperty.call(message, "repliedById"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.repliedById);
                    if (message.repliedByEmail != null && Object.hasOwnProperty.call(message, "repliedByEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.repliedByEmail);
                    if (message.repliedByName != null && Object.hasOwnProperty.call(message, "repliedByName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.repliedByName);
                    if (message.timestamp != null && Object.hasOwnProperty.call(message, "timestamp"))
                        $root.google.protobuf.Timestamp.encode(message.timestamp, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.message);
                    return writer;
                };

                /**
                 * Encodes the specified Reply message, length delimited. Does not implicitly {@link waiternow.common.FeedbackProto.Reply.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {waiternow.common.FeedbackProto.IReply} message Reply message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Reply.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Reply message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FeedbackProto.Reply} Reply
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Reply.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FeedbackProto.Reply();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.repliedById = reader.string();
                                break;
                            }
                        case 2: {
                                message.repliedByEmail = reader.string();
                                break;
                            }
                        case 3: {
                                message.repliedByName = reader.string();
                                break;
                            }
                        case 4: {
                                message.timestamp = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.message = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Reply message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FeedbackProto.Reply} Reply
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Reply.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Reply message.
                 * @function verify
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Reply.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.repliedById != null && message.hasOwnProperty("repliedById"))
                        if (!$util.isString(message.repliedById))
                            return "repliedById: string expected";
                    if (message.repliedByEmail != null && message.hasOwnProperty("repliedByEmail"))
                        if (!$util.isString(message.repliedByEmail))
                            return "repliedByEmail: string expected";
                    if (message.repliedByName != null && message.hasOwnProperty("repliedByName"))
                        if (!$util.isString(message.repliedByName))
                            return "repliedByName: string expected";
                    if (message.timestamp != null && message.hasOwnProperty("timestamp")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.timestamp);
                        if (error)
                            return "timestamp." + error;
                    }
                    if (message.message != null && message.hasOwnProperty("message"))
                        if (!$util.isString(message.message))
                            return "message: string expected";
                    return null;
                };

                /**
                 * Creates a Reply message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FeedbackProto.Reply} Reply
                 */
                Reply.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FeedbackProto.Reply)
                        return object;
                    var message = new $root.waiternow.common.FeedbackProto.Reply();
                    if (object.repliedById != null)
                        message.repliedById = String(object.repliedById);
                    if (object.repliedByEmail != null)
                        message.repliedByEmail = String(object.repliedByEmail);
                    if (object.repliedByName != null)
                        message.repliedByName = String(object.repliedByName);
                    if (object.timestamp != null) {
                        if (typeof object.timestamp !== "object")
                            throw TypeError(".waiternow.common.FeedbackProto.Reply.timestamp: object expected");
                        message.timestamp = $root.google.protobuf.Timestamp.fromObject(object.timestamp);
                    }
                    if (object.message != null)
                        message.message = String(object.message);
                    return message;
                };

                /**
                 * Creates a plain object from a Reply message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {waiternow.common.FeedbackProto.Reply} message Reply
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Reply.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.repliedById = "";
                        object.repliedByEmail = "";
                        object.repliedByName = "";
                        object.timestamp = null;
                        object.message = "";
                    }
                    if (message.repliedById != null && message.hasOwnProperty("repliedById"))
                        object.repliedById = message.repliedById;
                    if (message.repliedByEmail != null && message.hasOwnProperty("repliedByEmail"))
                        object.repliedByEmail = message.repliedByEmail;
                    if (message.repliedByName != null && message.hasOwnProperty("repliedByName"))
                        object.repliedByName = message.repliedByName;
                    if (message.timestamp != null && message.hasOwnProperty("timestamp"))
                        object.timestamp = $root.google.protobuf.Timestamp.toObject(message.timestamp, options);
                    if (message.message != null && message.hasOwnProperty("message"))
                        object.message = message.message;
                    return object;
                };

                /**
                 * Converts this Reply to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Reply.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Reply
                 * @function getTypeUrl
                 * @memberof waiternow.common.FeedbackProto.Reply
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Reply.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FeedbackProto.Reply";
                };

                return Reply;
            })();

            return FeedbackProto;
        })();

        common.ClientLogProto = (function() {

            /**
             * Properties of a ClientLogProto.
             * @memberof waiternow.common
             * @interface IClientLogProto
             * @property {google.protobuf.ITimestamp|null} [creationTime] ClientLogProto creationTime
             * @property {string|null} [message] ClientLogProto message
             * @property {waiternow.common.ClientLogProto.Level|null} [level] ClientLogProto level
             * @property {string|null} [stackTrace] ClientLogProto stackTrace
             * @property {waiternow.common.ClientLogProto.ISystemInfoProto|null} [systemInfo] ClientLogProto systemInfo
             */

            /**
             * Constructs a new ClientLogProto.
             * @memberof waiternow.common
             * @classdesc Represents a ClientLogProto.
             * @implements IClientLogProto
             * @constructor
             * @param {waiternow.common.IClientLogProto=} [properties] Properties to set
             */
            function ClientLogProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClientLogProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.ClientLogProto
             * @instance
             */
            ClientLogProto.prototype.creationTime = null;

            /**
             * ClientLogProto message.
             * @member {string} message
             * @memberof waiternow.common.ClientLogProto
             * @instance
             */
            ClientLogProto.prototype.message = "";

            /**
             * ClientLogProto level.
             * @member {waiternow.common.ClientLogProto.Level} level
             * @memberof waiternow.common.ClientLogProto
             * @instance
             */
            ClientLogProto.prototype.level = 0;

            /**
             * ClientLogProto stackTrace.
             * @member {string} stackTrace
             * @memberof waiternow.common.ClientLogProto
             * @instance
             */
            ClientLogProto.prototype.stackTrace = "";

            /**
             * ClientLogProto systemInfo.
             * @member {waiternow.common.ClientLogProto.ISystemInfoProto|null|undefined} systemInfo
             * @memberof waiternow.common.ClientLogProto
             * @instance
             */
            ClientLogProto.prototype.systemInfo = null;

            /**
             * Creates a new ClientLogProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {waiternow.common.IClientLogProto=} [properties] Properties to set
             * @returns {waiternow.common.ClientLogProto} ClientLogProto instance
             */
            ClientLogProto.create = function create(properties) {
                return new ClientLogProto(properties);
            };

            /**
             * Encodes the specified ClientLogProto message. Does not implicitly {@link waiternow.common.ClientLogProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {waiternow.common.IClientLogProto} message ClientLogProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientLogProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                if (message.level != null && Object.hasOwnProperty.call(message, "level"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.level);
                if (message.stackTrace != null && Object.hasOwnProperty.call(message, "stackTrace"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.stackTrace);
                if (message.systemInfo != null && Object.hasOwnProperty.call(message, "systemInfo"))
                    $root.waiternow.common.ClientLogProto.SystemInfoProto.encode(message.systemInfo, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ClientLogProto message, length delimited. Does not implicitly {@link waiternow.common.ClientLogProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {waiternow.common.IClientLogProto} message ClientLogProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientLogProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ClientLogProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ClientLogProto} ClientLogProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientLogProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientLogProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.message = reader.string();
                            break;
                        }
                    case 3: {
                            message.level = reader.int32();
                            break;
                        }
                    case 4: {
                            message.stackTrace = reader.string();
                            break;
                        }
                    case 5: {
                            message.systemInfo = $root.waiternow.common.ClientLogProto.SystemInfoProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ClientLogProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ClientLogProto} ClientLogProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientLogProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ClientLogProto message.
             * @function verify
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientLogProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.message != null && message.hasOwnProperty("message"))
                    if (!$util.isString(message.message))
                        return "message: string expected";
                if (message.level != null && message.hasOwnProperty("level"))
                    switch (message.level) {
                    default:
                        return "level: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.stackTrace != null && message.hasOwnProperty("stackTrace"))
                    if (!$util.isString(message.stackTrace))
                        return "stackTrace: string expected";
                if (message.systemInfo != null && message.hasOwnProperty("systemInfo")) {
                    var error = $root.waiternow.common.ClientLogProto.SystemInfoProto.verify(message.systemInfo);
                    if (error)
                        return "systemInfo." + error;
                }
                return null;
            };

            /**
             * Creates a ClientLogProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ClientLogProto} ClientLogProto
             */
            ClientLogProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ClientLogProto)
                    return object;
                var message = new $root.waiternow.common.ClientLogProto();
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.ClientLogProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.message != null)
                    message.message = String(object.message);
                switch (object.level) {
                default:
                    if (typeof object.level === "number") {
                        message.level = object.level;
                        break;
                    }
                    break;
                case "LEVEL_UNSPECIFIED":
                case 0:
                    message.level = 0;
                    break;
                case "ERROR":
                case 1:
                    message.level = 1;
                    break;
                case "WARNING":
                case 2:
                    message.level = 2;
                    break;
                case "INFO":
                case 3:
                    message.level = 3;
                    break;
                case "DEBUG":
                case 4:
                    message.level = 4;
                    break;
                }
                if (object.stackTrace != null)
                    message.stackTrace = String(object.stackTrace);
                if (object.systemInfo != null) {
                    if (typeof object.systemInfo !== "object")
                        throw TypeError(".waiternow.common.ClientLogProto.systemInfo: object expected");
                    message.systemInfo = $root.waiternow.common.ClientLogProto.SystemInfoProto.fromObject(object.systemInfo);
                }
                return message;
            };

            /**
             * Creates a plain object from a ClientLogProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {waiternow.common.ClientLogProto} message ClientLogProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientLogProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.creationTime = null;
                    object.message = "";
                    object.level = options.enums === String ? "LEVEL_UNSPECIFIED" : 0;
                    object.stackTrace = "";
                    object.systemInfo = null;
                }
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.message != null && message.hasOwnProperty("message"))
                    object.message = message.message;
                if (message.level != null && message.hasOwnProperty("level"))
                    object.level = options.enums === String ? $root.waiternow.common.ClientLogProto.Level[message.level] === undefined ? message.level : $root.waiternow.common.ClientLogProto.Level[message.level] : message.level;
                if (message.stackTrace != null && message.hasOwnProperty("stackTrace"))
                    object.stackTrace = message.stackTrace;
                if (message.systemInfo != null && message.hasOwnProperty("systemInfo"))
                    object.systemInfo = $root.waiternow.common.ClientLogProto.SystemInfoProto.toObject(message.systemInfo, options);
                return object;
            };

            /**
             * Converts this ClientLogProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ClientLogProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientLogProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ClientLogProto
             * @function getTypeUrl
             * @memberof waiternow.common.ClientLogProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ClientLogProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ClientLogProto";
            };

            /**
             * Level enum.
             * @name waiternow.common.ClientLogProto.Level
             * @enum {number}
             * @property {number} LEVEL_UNSPECIFIED=0 LEVEL_UNSPECIFIED value
             * @property {number} ERROR=1 ERROR value
             * @property {number} WARNING=2 WARNING value
             * @property {number} INFO=3 INFO value
             * @property {number} DEBUG=4 DEBUG value
             */
            ClientLogProto.Level = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "LEVEL_UNSPECIFIED"] = 0;
                values[valuesById[1] = "ERROR"] = 1;
                values[valuesById[2] = "WARNING"] = 2;
                values[valuesById[3] = "INFO"] = 3;
                values[valuesById[4] = "DEBUG"] = 4;
                return values;
            })();

            ClientLogProto.SystemInfoProto = (function() {

                /**
                 * Properties of a SystemInfoProto.
                 * @memberof waiternow.common.ClientLogProto
                 * @interface ISystemInfoProto
                 * @property {string|null} [operatingSystem] SystemInfoProto operatingSystem
                 */

                /**
                 * Constructs a new SystemInfoProto.
                 * @memberof waiternow.common.ClientLogProto
                 * @classdesc Represents a SystemInfoProto.
                 * @implements ISystemInfoProto
                 * @constructor
                 * @param {waiternow.common.ClientLogProto.ISystemInfoProto=} [properties] Properties to set
                 */
                function SystemInfoProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SystemInfoProto operatingSystem.
                 * @member {string} operatingSystem
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @instance
                 */
                SystemInfoProto.prototype.operatingSystem = "";

                /**
                 * Creates a new SystemInfoProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {waiternow.common.ClientLogProto.ISystemInfoProto=} [properties] Properties to set
                 * @returns {waiternow.common.ClientLogProto.SystemInfoProto} SystemInfoProto instance
                 */
                SystemInfoProto.create = function create(properties) {
                    return new SystemInfoProto(properties);
                };

                /**
                 * Encodes the specified SystemInfoProto message. Does not implicitly {@link waiternow.common.ClientLogProto.SystemInfoProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {waiternow.common.ClientLogProto.ISystemInfoProto} message SystemInfoProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SystemInfoProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operatingSystem != null && Object.hasOwnProperty.call(message, "operatingSystem"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.operatingSystem);
                    return writer;
                };

                /**
                 * Encodes the specified SystemInfoProto message, length delimited. Does not implicitly {@link waiternow.common.ClientLogProto.SystemInfoProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {waiternow.common.ClientLogProto.ISystemInfoProto} message SystemInfoProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SystemInfoProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SystemInfoProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ClientLogProto.SystemInfoProto} SystemInfoProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SystemInfoProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientLogProto.SystemInfoProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operatingSystem = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SystemInfoProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ClientLogProto.SystemInfoProto} SystemInfoProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SystemInfoProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SystemInfoProto message.
                 * @function verify
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SystemInfoProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operatingSystem != null && message.hasOwnProperty("operatingSystem"))
                        if (!$util.isString(message.operatingSystem))
                            return "operatingSystem: string expected";
                    return null;
                };

                /**
                 * Creates a SystemInfoProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ClientLogProto.SystemInfoProto} SystemInfoProto
                 */
                SystemInfoProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ClientLogProto.SystemInfoProto)
                        return object;
                    var message = new $root.waiternow.common.ClientLogProto.SystemInfoProto();
                    if (object.operatingSystem != null)
                        message.operatingSystem = String(object.operatingSystem);
                    return message;
                };

                /**
                 * Creates a plain object from a SystemInfoProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {waiternow.common.ClientLogProto.SystemInfoProto} message SystemInfoProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SystemInfoProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operatingSystem = "";
                    if (message.operatingSystem != null && message.hasOwnProperty("operatingSystem"))
                        object.operatingSystem = message.operatingSystem;
                    return object;
                };

                /**
                 * Converts this SystemInfoProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SystemInfoProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SystemInfoProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ClientLogProto.SystemInfoProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SystemInfoProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ClientLogProto.SystemInfoProto";
                };

                return SystemInfoProto;
            })();

            return ClientLogProto;
        })();

        common.OperationStatusProto = (function() {

            /**
             * Properties of an OperationStatusProto.
             * @memberof waiternow.common
             * @interface IOperationStatusProto
             * @property {boolean|null} [isSuccess] OperationStatusProto isSuccess
             * @property {boolean|null} [isFailure] OperationStatusProto isFailure
             * @property {string|null} [errorCode] OperationStatusProto errorCode
             * @property {string|null} [errorMessage] OperationStatusProto errorMessage
             */

            /**
             * Constructs a new OperationStatusProto.
             * @memberof waiternow.common
             * @classdesc Represents an OperationStatusProto.
             * @implements IOperationStatusProto
             * @constructor
             * @param {waiternow.common.IOperationStatusProto=} [properties] Properties to set
             */
            function OperationStatusProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OperationStatusProto isSuccess.
             * @member {boolean} isSuccess
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.isSuccess = false;

            /**
             * OperationStatusProto isFailure.
             * @member {boolean} isFailure
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.isFailure = false;

            /**
             * OperationStatusProto errorCode.
             * @member {string} errorCode
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.errorCode = "";

            /**
             * OperationStatusProto errorMessage.
             * @member {string} errorMessage
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             */
            OperationStatusProto.prototype.errorMessage = "";

            /**
             * Creates a new OperationStatusProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto=} [properties] Properties to set
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto instance
             */
            OperationStatusProto.create = function create(properties) {
                return new OperationStatusProto(properties);
            };

            /**
             * Encodes the specified OperationStatusProto message. Does not implicitly {@link waiternow.common.OperationStatusProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto} message OperationStatusProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperationStatusProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.isSuccess != null && Object.hasOwnProperty.call(message, "isSuccess"))
                    writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isSuccess);
                if (message.isFailure != null && Object.hasOwnProperty.call(message, "isFailure"))
                    writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isFailure);
                if (message.errorCode != null && Object.hasOwnProperty.call(message, "errorCode"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.errorCode);
                if (message.errorMessage != null && Object.hasOwnProperty.call(message, "errorMessage"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.errorMessage);
                return writer;
            };

            /**
             * Encodes the specified OperationStatusProto message, length delimited. Does not implicitly {@link waiternow.common.OperationStatusProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.IOperationStatusProto} message OperationStatusProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OperationStatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OperationStatusProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperationStatusProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OperationStatusProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.isSuccess = reader.bool();
                            break;
                        }
                    case 2: {
                            message.isFailure = reader.bool();
                            break;
                        }
                    case 3: {
                            message.errorCode = reader.string();
                            break;
                        }
                    case 4: {
                            message.errorMessage = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OperationStatusProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OperationStatusProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OperationStatusProto message.
             * @function verify
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OperationStatusProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                    if (typeof message.isSuccess !== "boolean")
                        return "isSuccess: boolean expected";
                if (message.isFailure != null && message.hasOwnProperty("isFailure"))
                    if (typeof message.isFailure !== "boolean")
                        return "isFailure: boolean expected";
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    if (!$util.isString(message.errorCode))
                        return "errorCode: string expected";
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    if (!$util.isString(message.errorMessage))
                        return "errorMessage: string expected";
                return null;
            };

            /**
             * Creates an OperationStatusProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OperationStatusProto} OperationStatusProto
             */
            OperationStatusProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OperationStatusProto)
                    return object;
                var message = new $root.waiternow.common.OperationStatusProto();
                if (object.isSuccess != null)
                    message.isSuccess = Boolean(object.isSuccess);
                if (object.isFailure != null)
                    message.isFailure = Boolean(object.isFailure);
                if (object.errorCode != null)
                    message.errorCode = String(object.errorCode);
                if (object.errorMessage != null)
                    message.errorMessage = String(object.errorMessage);
                return message;
            };

            /**
             * Creates a plain object from an OperationStatusProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {waiternow.common.OperationStatusProto} message OperationStatusProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OperationStatusProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.isSuccess = false;
                    object.isFailure = false;
                    object.errorCode = "";
                    object.errorMessage = "";
                }
                if (message.isSuccess != null && message.hasOwnProperty("isSuccess"))
                    object.isSuccess = message.isSuccess;
                if (message.isFailure != null && message.hasOwnProperty("isFailure"))
                    object.isFailure = message.isFailure;
                if (message.errorCode != null && message.hasOwnProperty("errorCode"))
                    object.errorCode = message.errorCode;
                if (message.errorMessage != null && message.hasOwnProperty("errorMessage"))
                    object.errorMessage = message.errorMessage;
                return object;
            };

            /**
             * Converts this OperationStatusProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OperationStatusProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OperationStatusProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OperationStatusProto
             * @function getTypeUrl
             * @memberof waiternow.common.OperationStatusProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OperationStatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OperationStatusProto";
            };

            return OperationStatusProto;
        })();

        common.VersionProto = (function() {

            /**
             * Properties of a VersionProto.
             * @memberof waiternow.common
             * @interface IVersionProto
             * @property {google.protobuf.ITimestamp|null} [buildTime] VersionProto buildTime
             * @property {google.protobuf.ITimestamp|null} [releaseTime] VersionProto releaseTime
             * @property {number|null} [major] VersionProto major
             * @property {number|null} [minor] VersionProto minor
             * @property {number|null} [patch] VersionProto patch
             */

            /**
             * Constructs a new VersionProto.
             * @memberof waiternow.common
             * @classdesc Represents a VersionProto.
             * @implements IVersionProto
             * @constructor
             * @param {waiternow.common.IVersionProto=} [properties] Properties to set
             */
            function VersionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * VersionProto buildTime.
             * @member {google.protobuf.ITimestamp|null|undefined} buildTime
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.buildTime = null;

            /**
             * VersionProto releaseTime.
             * @member {google.protobuf.ITimestamp|null|undefined} releaseTime
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.releaseTime = null;

            /**
             * VersionProto major.
             * @member {number} major
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.major = 0;

            /**
             * VersionProto minor.
             * @member {number} minor
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.minor = 0;

            /**
             * VersionProto patch.
             * @member {number} patch
             * @memberof waiternow.common.VersionProto
             * @instance
             */
            VersionProto.prototype.patch = 0;

            /**
             * Creates a new VersionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.IVersionProto=} [properties] Properties to set
             * @returns {waiternow.common.VersionProto} VersionProto instance
             */
            VersionProto.create = function create(properties) {
                return new VersionProto(properties);
            };

            /**
             * Encodes the specified VersionProto message. Does not implicitly {@link waiternow.common.VersionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.IVersionProto} message VersionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VersionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.buildTime != null && Object.hasOwnProperty.call(message, "buildTime"))
                    $root.google.protobuf.Timestamp.encode(message.buildTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.releaseTime != null && Object.hasOwnProperty.call(message, "releaseTime"))
                    $root.google.protobuf.Timestamp.encode(message.releaseTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.major != null && Object.hasOwnProperty.call(message, "major"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.major);
                if (message.minor != null && Object.hasOwnProperty.call(message, "minor"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.minor);
                if (message.patch != null && Object.hasOwnProperty.call(message, "patch"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.patch);
                return writer;
            };

            /**
             * Encodes the specified VersionProto message, length delimited. Does not implicitly {@link waiternow.common.VersionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.IVersionProto} message VersionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VersionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VersionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.VersionProto} VersionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VersionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VersionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.buildTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.releaseTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.major = reader.int32();
                            break;
                        }
                    case 4: {
                            message.minor = reader.int32();
                            break;
                        }
                    case 5: {
                            message.patch = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VersionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.VersionProto} VersionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VersionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VersionProto message.
             * @function verify
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VersionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.buildTime != null && message.hasOwnProperty("buildTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.buildTime);
                    if (error)
                        return "buildTime." + error;
                }
                if (message.releaseTime != null && message.hasOwnProperty("releaseTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.releaseTime);
                    if (error)
                        return "releaseTime." + error;
                }
                if (message.major != null && message.hasOwnProperty("major"))
                    if (!$util.isInteger(message.major))
                        return "major: integer expected";
                if (message.minor != null && message.hasOwnProperty("minor"))
                    if (!$util.isInteger(message.minor))
                        return "minor: integer expected";
                if (message.patch != null && message.hasOwnProperty("patch"))
                    if (!$util.isInteger(message.patch))
                        return "patch: integer expected";
                return null;
            };

            /**
             * Creates a VersionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.VersionProto} VersionProto
             */
            VersionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.VersionProto)
                    return object;
                var message = new $root.waiternow.common.VersionProto();
                if (object.buildTime != null) {
                    if (typeof object.buildTime !== "object")
                        throw TypeError(".waiternow.common.VersionProto.buildTime: object expected");
                    message.buildTime = $root.google.protobuf.Timestamp.fromObject(object.buildTime);
                }
                if (object.releaseTime != null) {
                    if (typeof object.releaseTime !== "object")
                        throw TypeError(".waiternow.common.VersionProto.releaseTime: object expected");
                    message.releaseTime = $root.google.protobuf.Timestamp.fromObject(object.releaseTime);
                }
                if (object.major != null)
                    message.major = object.major | 0;
                if (object.minor != null)
                    message.minor = object.minor | 0;
                if (object.patch != null)
                    message.patch = object.patch | 0;
                return message;
            };

            /**
             * Creates a plain object from a VersionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {waiternow.common.VersionProto} message VersionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VersionProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.buildTime = null;
                    object.releaseTime = null;
                    object.major = 0;
                    object.minor = 0;
                    object.patch = 0;
                }
                if (message.buildTime != null && message.hasOwnProperty("buildTime"))
                    object.buildTime = $root.google.protobuf.Timestamp.toObject(message.buildTime, options);
                if (message.releaseTime != null && message.hasOwnProperty("releaseTime"))
                    object.releaseTime = $root.google.protobuf.Timestamp.toObject(message.releaseTime, options);
                if (message.major != null && message.hasOwnProperty("major"))
                    object.major = message.major;
                if (message.minor != null && message.hasOwnProperty("minor"))
                    object.minor = message.minor;
                if (message.patch != null && message.hasOwnProperty("patch"))
                    object.patch = message.patch;
                return object;
            };

            /**
             * Converts this VersionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.VersionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VersionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for VersionProto
             * @function getTypeUrl
             * @memberof waiternow.common.VersionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VersionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.VersionProto";
            };

            return VersionProto;
        })();

        common.ConsumerProto = (function() {

            /**
             * Properties of a ConsumerProto.
             * @memberof waiternow.common
             * @interface IConsumerProto
             * @property {string|null} [id] ConsumerProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] ConsumerProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] ConsumerProto lastUpdateTime
             * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] ConsumerProto phoneNumber
             * @property {string|null} [email] ConsumerProto email
             * @property {string|null} [name] ConsumerProto name
             */

            /**
             * Constructs a new ConsumerProto.
             * @memberof waiternow.common
             * @classdesc Represents a ConsumerProto.
             * @implements IConsumerProto
             * @constructor
             * @param {waiternow.common.IConsumerProto=} [properties] Properties to set
             */
            function ConsumerProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ConsumerProto id.
             * @member {string} id
             * @memberof waiternow.common.ConsumerProto
             * @instance
             */
            ConsumerProto.prototype.id = "";

            /**
             * ConsumerProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.ConsumerProto
             * @instance
             */
            ConsumerProto.prototype.creationTime = null;

            /**
             * ConsumerProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.ConsumerProto
             * @instance
             */
            ConsumerProto.prototype.lastUpdateTime = null;

            /**
             * ConsumerProto phoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
             * @memberof waiternow.common.ConsumerProto
             * @instance
             */
            ConsumerProto.prototype.phoneNumber = null;

            /**
             * ConsumerProto email.
             * @member {string} email
             * @memberof waiternow.common.ConsumerProto
             * @instance
             */
            ConsumerProto.prototype.email = "";

            /**
             * ConsumerProto name.
             * @member {string} name
             * @memberof waiternow.common.ConsumerProto
             * @instance
             */
            ConsumerProto.prototype.name = "";

            /**
             * Creates a new ConsumerProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {waiternow.common.IConsumerProto=} [properties] Properties to set
             * @returns {waiternow.common.ConsumerProto} ConsumerProto instance
             */
            ConsumerProto.create = function create(properties) {
                return new ConsumerProto(properties);
            };

            /**
             * Encodes the specified ConsumerProto message. Does not implicitly {@link waiternow.common.ConsumerProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {waiternow.common.IConsumerProto} message ConsumerProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConsumerProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 7, wireType 2 =*/58).string(message.email);
                return writer;
            };

            /**
             * Encodes the specified ConsumerProto message, length delimited. Does not implicitly {@link waiternow.common.ConsumerProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {waiternow.common.IConsumerProto} message ConsumerProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ConsumerProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ConsumerProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ConsumerProto} ConsumerProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConsumerProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ConsumerProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 4: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.email = reader.string();
                            break;
                        }
                    case 3: {
                            message.name = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ConsumerProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ConsumerProto} ConsumerProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ConsumerProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ConsumerProto message.
             * @function verify
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ConsumerProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                    if (error)
                        return "phoneNumber." + error;
                }
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                return null;
            };

            /**
             * Creates a ConsumerProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ConsumerProto} ConsumerProto
             */
            ConsumerProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ConsumerProto)
                    return object;
                var message = new $root.waiternow.common.ConsumerProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.ConsumerProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.ConsumerProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.phoneNumber != null) {
                    if (typeof object.phoneNumber !== "object")
                        throw TypeError(".waiternow.common.ConsumerProto.phoneNumber: object expected");
                    message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                }
                if (object.email != null)
                    message.email = String(object.email);
                if (object.name != null)
                    message.name = String(object.name);
                return message;
            };

            /**
             * Creates a plain object from a ConsumerProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {waiternow.common.ConsumerProto} message ConsumerProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ConsumerProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.phoneNumber = null;
                    object.name = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.email = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                return object;
            };

            /**
             * Converts this ConsumerProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ConsumerProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ConsumerProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ConsumerProto
             * @function getTypeUrl
             * @memberof waiternow.common.ConsumerProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ConsumerProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ConsumerProto";
            };

            return ConsumerProto;
        })();

        common.LocationCustomerProto = (function() {

            /**
             * Properties of a LocationCustomerProto.
             * @memberof waiternow.common
             * @interface ILocationCustomerProto
             * @property {waiternow.common.LocationCustomerProto.IIdProto|null} [id] LocationCustomerProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] LocationCustomerProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] LocationCustomerProto lastUpdateTime
             * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] LocationCustomerProto phoneNumber
             * @property {string|null} [name] LocationCustomerProto name
             * @property {number|null} [purchasesCount] LocationCustomerProto purchasesCount
             * @property {boolean|null} [isUnsubscribedFromCampaigns] LocationCustomerProto isUnsubscribedFromCampaigns
             */

            /**
             * Constructs a new LocationCustomerProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationCustomerProto.
             * @implements ILocationCustomerProto
             * @constructor
             * @param {waiternow.common.ILocationCustomerProto=} [properties] Properties to set
             */
            function LocationCustomerProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationCustomerProto id.
             * @member {waiternow.common.LocationCustomerProto.IIdProto|null|undefined} id
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             */
            LocationCustomerProto.prototype.id = null;

            /**
             * LocationCustomerProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             */
            LocationCustomerProto.prototype.creationTime = null;

            /**
             * LocationCustomerProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             */
            LocationCustomerProto.prototype.lastUpdateTime = null;

            /**
             * LocationCustomerProto phoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             */
            LocationCustomerProto.prototype.phoneNumber = null;

            /**
             * LocationCustomerProto name.
             * @member {string} name
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             */
            LocationCustomerProto.prototype.name = "";

            /**
             * LocationCustomerProto purchasesCount.
             * @member {number} purchasesCount
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             */
            LocationCustomerProto.prototype.purchasesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationCustomerProto isUnsubscribedFromCampaigns.
             * @member {boolean} isUnsubscribedFromCampaigns
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             */
            LocationCustomerProto.prototype.isUnsubscribedFromCampaigns = false;

            /**
             * Creates a new LocationCustomerProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {waiternow.common.ILocationCustomerProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationCustomerProto} LocationCustomerProto instance
             */
            LocationCustomerProto.create = function create(properties) {
                return new LocationCustomerProto(properties);
            };

            /**
             * Encodes the specified LocationCustomerProto message. Does not implicitly {@link waiternow.common.LocationCustomerProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {waiternow.common.ILocationCustomerProto} message LocationCustomerProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationCustomerProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.waiternow.common.LocationCustomerProto.IdProto.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                if (message.purchasesCount != null && Object.hasOwnProperty.call(message, "purchasesCount"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.purchasesCount);
                if (message.isUnsubscribedFromCampaigns != null && Object.hasOwnProperty.call(message, "isUnsubscribedFromCampaigns"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isUnsubscribedFromCampaigns);
                return writer;
            };

            /**
             * Encodes the specified LocationCustomerProto message, length delimited. Does not implicitly {@link waiternow.common.LocationCustomerProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {waiternow.common.ILocationCustomerProto} message LocationCustomerProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationCustomerProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationCustomerProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationCustomerProto} LocationCustomerProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationCustomerProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationCustomerProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = $root.waiternow.common.LocationCustomerProto.IdProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.name = reader.string();
                            break;
                        }
                    case 6: {
                            message.purchasesCount = reader.int64();
                            break;
                        }
                    case 7: {
                            message.isUnsubscribedFromCampaigns = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationCustomerProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationCustomerProto} LocationCustomerProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationCustomerProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationCustomerProto message.
             * @function verify
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationCustomerProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id")) {
                    var error = $root.waiternow.common.LocationCustomerProto.IdProto.verify(message.id);
                    if (error)
                        return "id." + error;
                }
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                    if (error)
                        return "phoneNumber." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.purchasesCount != null && message.hasOwnProperty("purchasesCount"))
                    if (!$util.isInteger(message.purchasesCount) && !(message.purchasesCount && $util.isInteger(message.purchasesCount.low) && $util.isInteger(message.purchasesCount.high)))
                        return "purchasesCount: integer|Long expected";
                if (message.isUnsubscribedFromCampaigns != null && message.hasOwnProperty("isUnsubscribedFromCampaigns"))
                    if (typeof message.isUnsubscribedFromCampaigns !== "boolean")
                        return "isUnsubscribedFromCampaigns: boolean expected";
                return null;
            };

            /**
             * Creates a LocationCustomerProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationCustomerProto} LocationCustomerProto
             */
            LocationCustomerProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationCustomerProto)
                    return object;
                var message = new $root.waiternow.common.LocationCustomerProto();
                if (object.id != null) {
                    if (typeof object.id !== "object")
                        throw TypeError(".waiternow.common.LocationCustomerProto.id: object expected");
                    message.id = $root.waiternow.common.LocationCustomerProto.IdProto.fromObject(object.id);
                }
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.LocationCustomerProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.LocationCustomerProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.phoneNumber != null) {
                    if (typeof object.phoneNumber !== "object")
                        throw TypeError(".waiternow.common.LocationCustomerProto.phoneNumber: object expected");
                    message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.purchasesCount != null)
                    if ($util.Long)
                        (message.purchasesCount = $util.Long.fromValue(object.purchasesCount)).unsigned = false;
                    else if (typeof object.purchasesCount === "string")
                        message.purchasesCount = parseInt(object.purchasesCount, 10);
                    else if (typeof object.purchasesCount === "number")
                        message.purchasesCount = object.purchasesCount;
                    else if (typeof object.purchasesCount === "object")
                        message.purchasesCount = new $util.LongBits(object.purchasesCount.low >>> 0, object.purchasesCount.high >>> 0).toNumber();
                if (object.isUnsubscribedFromCampaigns != null)
                    message.isUnsubscribedFromCampaigns = Boolean(object.isUnsubscribedFromCampaigns);
                return message;
            };

            /**
             * Creates a plain object from a LocationCustomerProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {waiternow.common.LocationCustomerProto} message LocationCustomerProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationCustomerProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = null;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.phoneNumber = null;
                    object.name = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.purchasesCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.purchasesCount = options.longs === String ? "0" : 0;
                    object.isUnsubscribedFromCampaigns = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = $root.waiternow.common.LocationCustomerProto.IdProto.toObject(message.id, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.purchasesCount != null && message.hasOwnProperty("purchasesCount"))
                    if (typeof message.purchasesCount === "number")
                        object.purchasesCount = options.longs === String ? String(message.purchasesCount) : message.purchasesCount;
                    else
                        object.purchasesCount = options.longs === String ? $util.Long.prototype.toString.call(message.purchasesCount) : options.longs === Number ? new $util.LongBits(message.purchasesCount.low >>> 0, message.purchasesCount.high >>> 0).toNumber() : message.purchasesCount;
                if (message.isUnsubscribedFromCampaigns != null && message.hasOwnProperty("isUnsubscribedFromCampaigns"))
                    object.isUnsubscribedFromCampaigns = message.isUnsubscribedFromCampaigns;
                return object;
            };

            /**
             * Converts this LocationCustomerProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationCustomerProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationCustomerProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationCustomerProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationCustomerProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationCustomerProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationCustomerProto";
            };

            LocationCustomerProto.IdProto = (function() {

                /**
                 * Properties of an IdProto.
                 * @memberof waiternow.common.LocationCustomerProto
                 * @interface IIdProto
                 * @property {string|null} [locationId] IdProto locationId
                 * @property {string|null} [email] IdProto email
                 */

                /**
                 * Constructs a new IdProto.
                 * @memberof waiternow.common.LocationCustomerProto
                 * @classdesc Represents an IdProto.
                 * @implements IIdProto
                 * @constructor
                 * @param {waiternow.common.LocationCustomerProto.IIdProto=} [properties] Properties to set
                 */
                function IdProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * IdProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @instance
                 */
                IdProto.prototype.locationId = "";

                /**
                 * IdProto email.
                 * @member {string} email
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @instance
                 */
                IdProto.prototype.email = "";

                /**
                 * Creates a new IdProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.LocationCustomerProto.IIdProto=} [properties] Properties to set
                 * @returns {waiternow.common.LocationCustomerProto.IdProto} IdProto instance
                 */
                IdProto.create = function create(properties) {
                    return new IdProto(properties);
                };

                /**
                 * Encodes the specified IdProto message. Does not implicitly {@link waiternow.common.LocationCustomerProto.IdProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.LocationCustomerProto.IIdProto} message IdProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IdProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified IdProto message, length delimited. Does not implicitly {@link waiternow.common.LocationCustomerProto.IdProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.LocationCustomerProto.IIdProto} message IdProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IdProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an IdProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.LocationCustomerProto.IdProto} IdProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IdProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationCustomerProto.IdProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.email = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an IdProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.LocationCustomerProto.IdProto} IdProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IdProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an IdProto message.
                 * @function verify
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IdProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates an IdProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.LocationCustomerProto.IdProto} IdProto
                 */
                IdProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.LocationCustomerProto.IdProto)
                        return object;
                    var message = new $root.waiternow.common.LocationCustomerProto.IdProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from an IdProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.LocationCustomerProto.IdProto} message IdProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IdProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.email = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this IdProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IdProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for IdProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.LocationCustomerProto.IdProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IdProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.LocationCustomerProto.IdProto";
                };

                return IdProto;
            })();

            return LocationCustomerProto;
        })();

        common.BusinessCustomerProto = (function() {

            /**
             * Properties of a BusinessCustomerProto.
             * @memberof waiternow.common
             * @interface IBusinessCustomerProto
             * @property {waiternow.common.BusinessCustomerProto.IIdProto|null} [id] BusinessCustomerProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] BusinessCustomerProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] BusinessCustomerProto lastUpdateTime
             * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] BusinessCustomerProto phoneNumber
             * @property {string|null} [name] BusinessCustomerProto name
             * @property {number|null} [purchasesCount] BusinessCustomerProto purchasesCount
             * @property {boolean|null} [isUnsubscribedFromCampaigns] BusinessCustomerProto isUnsubscribedFromCampaigns
             */

            /**
             * Constructs a new BusinessCustomerProto.
             * @memberof waiternow.common
             * @classdesc Represents a BusinessCustomerProto.
             * @implements IBusinessCustomerProto
             * @constructor
             * @param {waiternow.common.IBusinessCustomerProto=} [properties] Properties to set
             */
            function BusinessCustomerProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * BusinessCustomerProto id.
             * @member {waiternow.common.BusinessCustomerProto.IIdProto|null|undefined} id
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             */
            BusinessCustomerProto.prototype.id = null;

            /**
             * BusinessCustomerProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             */
            BusinessCustomerProto.prototype.creationTime = null;

            /**
             * BusinessCustomerProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             */
            BusinessCustomerProto.prototype.lastUpdateTime = null;

            /**
             * BusinessCustomerProto phoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             */
            BusinessCustomerProto.prototype.phoneNumber = null;

            /**
             * BusinessCustomerProto name.
             * @member {string} name
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             */
            BusinessCustomerProto.prototype.name = "";

            /**
             * BusinessCustomerProto purchasesCount.
             * @member {number} purchasesCount
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             */
            BusinessCustomerProto.prototype.purchasesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * BusinessCustomerProto isUnsubscribedFromCampaigns.
             * @member {boolean} isUnsubscribedFromCampaigns
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             */
            BusinessCustomerProto.prototype.isUnsubscribedFromCampaigns = false;

            /**
             * Creates a new BusinessCustomerProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {waiternow.common.IBusinessCustomerProto=} [properties] Properties to set
             * @returns {waiternow.common.BusinessCustomerProto} BusinessCustomerProto instance
             */
            BusinessCustomerProto.create = function create(properties) {
                return new BusinessCustomerProto(properties);
            };

            /**
             * Encodes the specified BusinessCustomerProto message. Does not implicitly {@link waiternow.common.BusinessCustomerProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {waiternow.common.IBusinessCustomerProto} message BusinessCustomerProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessCustomerProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    $root.waiternow.common.BusinessCustomerProto.IdProto.encode(message.id, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.name);
                if (message.purchasesCount != null && Object.hasOwnProperty.call(message, "purchasesCount"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.purchasesCount);
                if (message.isUnsubscribedFromCampaigns != null && Object.hasOwnProperty.call(message, "isUnsubscribedFromCampaigns"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isUnsubscribedFromCampaigns);
                return writer;
            };

            /**
             * Encodes the specified BusinessCustomerProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessCustomerProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {waiternow.common.IBusinessCustomerProto} message BusinessCustomerProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            BusinessCustomerProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a BusinessCustomerProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.BusinessCustomerProto} BusinessCustomerProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessCustomerProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessCustomerProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = $root.waiternow.common.BusinessCustomerProto.IdProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.name = reader.string();
                            break;
                        }
                    case 6: {
                            message.purchasesCount = reader.int64();
                            break;
                        }
                    case 7: {
                            message.isUnsubscribedFromCampaigns = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a BusinessCustomerProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.BusinessCustomerProto} BusinessCustomerProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            BusinessCustomerProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a BusinessCustomerProto message.
             * @function verify
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            BusinessCustomerProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id")) {
                    var error = $root.waiternow.common.BusinessCustomerProto.IdProto.verify(message.id);
                    if (error)
                        return "id." + error;
                }
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                    if (error)
                        return "phoneNumber." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.purchasesCount != null && message.hasOwnProperty("purchasesCount"))
                    if (!$util.isInteger(message.purchasesCount) && !(message.purchasesCount && $util.isInteger(message.purchasesCount.low) && $util.isInteger(message.purchasesCount.high)))
                        return "purchasesCount: integer|Long expected";
                if (message.isUnsubscribedFromCampaigns != null && message.hasOwnProperty("isUnsubscribedFromCampaigns"))
                    if (typeof message.isUnsubscribedFromCampaigns !== "boolean")
                        return "isUnsubscribedFromCampaigns: boolean expected";
                return null;
            };

            /**
             * Creates a BusinessCustomerProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.BusinessCustomerProto} BusinessCustomerProto
             */
            BusinessCustomerProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.BusinessCustomerProto)
                    return object;
                var message = new $root.waiternow.common.BusinessCustomerProto();
                if (object.id != null) {
                    if (typeof object.id !== "object")
                        throw TypeError(".waiternow.common.BusinessCustomerProto.id: object expected");
                    message.id = $root.waiternow.common.BusinessCustomerProto.IdProto.fromObject(object.id);
                }
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.BusinessCustomerProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.BusinessCustomerProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.phoneNumber != null) {
                    if (typeof object.phoneNumber !== "object")
                        throw TypeError(".waiternow.common.BusinessCustomerProto.phoneNumber: object expected");
                    message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.purchasesCount != null)
                    if ($util.Long)
                        (message.purchasesCount = $util.Long.fromValue(object.purchasesCount)).unsigned = false;
                    else if (typeof object.purchasesCount === "string")
                        message.purchasesCount = parseInt(object.purchasesCount, 10);
                    else if (typeof object.purchasesCount === "number")
                        message.purchasesCount = object.purchasesCount;
                    else if (typeof object.purchasesCount === "object")
                        message.purchasesCount = new $util.LongBits(object.purchasesCount.low >>> 0, object.purchasesCount.high >>> 0).toNumber();
                if (object.isUnsubscribedFromCampaigns != null)
                    message.isUnsubscribedFromCampaigns = Boolean(object.isUnsubscribedFromCampaigns);
                return message;
            };

            /**
             * Creates a plain object from a BusinessCustomerProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {waiternow.common.BusinessCustomerProto} message BusinessCustomerProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            BusinessCustomerProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = null;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.phoneNumber = null;
                    object.name = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.purchasesCount = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.purchasesCount = options.longs === String ? "0" : 0;
                    object.isUnsubscribedFromCampaigns = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = $root.waiternow.common.BusinessCustomerProto.IdProto.toObject(message.id, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.purchasesCount != null && message.hasOwnProperty("purchasesCount"))
                    if (typeof message.purchasesCount === "number")
                        object.purchasesCount = options.longs === String ? String(message.purchasesCount) : message.purchasesCount;
                    else
                        object.purchasesCount = options.longs === String ? $util.Long.prototype.toString.call(message.purchasesCount) : options.longs === Number ? new $util.LongBits(message.purchasesCount.low >>> 0, message.purchasesCount.high >>> 0).toNumber() : message.purchasesCount;
                if (message.isUnsubscribedFromCampaigns != null && message.hasOwnProperty("isUnsubscribedFromCampaigns"))
                    object.isUnsubscribedFromCampaigns = message.isUnsubscribedFromCampaigns;
                return object;
            };

            /**
             * Converts this BusinessCustomerProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.BusinessCustomerProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            BusinessCustomerProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for BusinessCustomerProto
             * @function getTypeUrl
             * @memberof waiternow.common.BusinessCustomerProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            BusinessCustomerProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.BusinessCustomerProto";
            };

            BusinessCustomerProto.IdProto = (function() {

                /**
                 * Properties of an IdProto.
                 * @memberof waiternow.common.BusinessCustomerProto
                 * @interface IIdProto
                 * @property {string|null} [businessId] IdProto businessId
                 * @property {string|null} [email] IdProto email
                 */

                /**
                 * Constructs a new IdProto.
                 * @memberof waiternow.common.BusinessCustomerProto
                 * @classdesc Represents an IdProto.
                 * @implements IIdProto
                 * @constructor
                 * @param {waiternow.common.BusinessCustomerProto.IIdProto=} [properties] Properties to set
                 */
                function IdProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * IdProto businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @instance
                 */
                IdProto.prototype.businessId = "";

                /**
                 * IdProto email.
                 * @member {string} email
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @instance
                 */
                IdProto.prototype.email = "";

                /**
                 * Creates a new IdProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.BusinessCustomerProto.IIdProto=} [properties] Properties to set
                 * @returns {waiternow.common.BusinessCustomerProto.IdProto} IdProto instance
                 */
                IdProto.create = function create(properties) {
                    return new IdProto(properties);
                };

                /**
                 * Encodes the specified IdProto message. Does not implicitly {@link waiternow.common.BusinessCustomerProto.IdProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.BusinessCustomerProto.IIdProto} message IdProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IdProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified IdProto message, length delimited. Does not implicitly {@link waiternow.common.BusinessCustomerProto.IdProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.BusinessCustomerProto.IIdProto} message IdProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                IdProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an IdProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.BusinessCustomerProto.IdProto} IdProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IdProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.BusinessCustomerProto.IdProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.email = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an IdProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.BusinessCustomerProto.IdProto} IdProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                IdProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an IdProto message.
                 * @function verify
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                IdProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates an IdProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.BusinessCustomerProto.IdProto} IdProto
                 */
                IdProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.BusinessCustomerProto.IdProto)
                        return object;
                    var message = new $root.waiternow.common.BusinessCustomerProto.IdProto();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from an IdProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {waiternow.common.BusinessCustomerProto.IdProto} message IdProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                IdProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessId = "";
                        object.email = "";
                    }
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this IdProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                IdProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for IdProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.BusinessCustomerProto.IdProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                IdProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.BusinessCustomerProto.IdProto";
                };

                return IdProto;
            })();

            return BusinessCustomerProto;
        })();

        common.CheckInActionProto = (function() {

            /**
             * Properties of a CheckInActionProto.
             * @memberof waiternow.common
             * @interface ICheckInActionProto
             */

            /**
             * Constructs a new CheckInActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckInActionProto.
             * @implements ICheckInActionProto
             * @constructor
             * @param {waiternow.common.ICheckInActionProto=} [properties] Properties to set
             */
            function CheckInActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CheckInActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {waiternow.common.ICheckInActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckInActionProto} CheckInActionProto instance
             */
            CheckInActionProto.create = function create(properties) {
                return new CheckInActionProto(properties);
            };

            /**
             * Encodes the specified CheckInActionProto message. Does not implicitly {@link waiternow.common.CheckInActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {waiternow.common.ICheckInActionProto} message CheckInActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CheckInActionProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {waiternow.common.ICheckInActionProto} message CheckInActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckInActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckInActionProto} CheckInActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckInActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckInActionProto} CheckInActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckInActionProto message.
             * @function verify
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckInActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CheckInActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckInActionProto} CheckInActionProto
             */
            CheckInActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckInActionProto)
                    return object;
                return new $root.waiternow.common.CheckInActionProto();
            };

            /**
             * Creates a plain object from a CheckInActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {waiternow.common.CheckInActionProto} message CheckInActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckInActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CheckInActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckInActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckInActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckInActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckInActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckInActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckInActionProto";
            };

            CheckInActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CheckInActionProto
                 * @interface IRequest
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 * @property {waiternow.common.IConsumerProto|null} [consumer] Request consumer
                 * @property {string|null} [deviceId] Request deviceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CheckInActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CheckInActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = "";

                /**
                 * Request consumer.
                 * @member {waiternow.common.IConsumerProto|null|undefined} consumer
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @instance
                 */
                Request.prototype.consumer = null;

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckInActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CheckInActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckInActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                    if (message.consumer != null && Object.hasOwnProperty.call(message, "consumer"))
                        $root.waiternow.common.ConsumerProto.encode(message.consumer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.deviceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CheckInActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckInActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.consumer = $root.waiternow.common.ConsumerProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.deviceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    if (message.consumer != null && message.hasOwnProperty("consumer")) {
                        var error = $root.waiternow.common.ConsumerProto.verify(message.consumer);
                        if (error)
                            return "consumer." + error;
                    }
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CheckInActionProto.Request();
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    if (object.consumer != null) {
                        if (typeof object.consumer !== "object")
                            throw TypeError(".waiternow.common.CheckInActionProto.Request.consumer: object expected");
                        message.consumer = $root.waiternow.common.ConsumerProto.fromObject(object.consumer);
                    }
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckInActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.pointOfServiceId = "";
                        object.consumer = null;
                        object.deviceId = "";
                    }
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    if (message.consumer != null && message.hasOwnProperty("consumer"))
                        object.consumer = $root.waiternow.common.ConsumerProto.toObject(message.consumer, options);
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInActionProto.Request";
                };

                return Request;
            })();

            CheckInActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CheckInActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ICheckInInfoProto|null} [checkInInfo] Response checkInInfo
                 * @property {waiternow.common.ICheckInDataProto|null} [checkInData] Response checkInData
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CheckInActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CheckInActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response checkInInfo.
                 * @member {waiternow.common.ICheckInInfoProto|null|undefined} checkInInfo
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @instance
                 */
                Response.prototype.checkInInfo = null;

                /**
                 * Response checkInData.
                 * @member {waiternow.common.ICheckInDataProto|null|undefined} checkInData
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @instance
                 */
                Response.prototype.checkInData = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckInActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CheckInActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CheckInActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckInActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.checkInInfo != null && Object.hasOwnProperty.call(message, "checkInInfo"))
                        $root.waiternow.common.CheckInInfoProto.encode(message.checkInInfo, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.checkInData != null && Object.hasOwnProperty.call(message, "checkInData"))
                        $root.waiternow.common.CheckInDataProto.encode(message.checkInData, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CheckInActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckInActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckInActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.checkInInfo = $root.waiternow.common.CheckInInfoProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.checkInData = $root.waiternow.common.CheckInDataProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckInActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.checkInInfo != null && message.hasOwnProperty("checkInInfo")) {
                        var error = $root.waiternow.common.CheckInInfoProto.verify(message.checkInInfo);
                        if (error)
                            return "checkInInfo." + error;
                    }
                    if (message.checkInData != null && message.hasOwnProperty("checkInData")) {
                        var error = $root.waiternow.common.CheckInDataProto.verify(message.checkInData);
                        if (error)
                            return "checkInData." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckInActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckInActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CheckInActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CheckInActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.checkInInfo != null) {
                        if (typeof object.checkInInfo !== "object")
                            throw TypeError(".waiternow.common.CheckInActionProto.Response.checkInInfo: object expected");
                        message.checkInInfo = $root.waiternow.common.CheckInInfoProto.fromObject(object.checkInInfo);
                    }
                    if (object.checkInData != null) {
                        if (typeof object.checkInData !== "object")
                            throw TypeError(".waiternow.common.CheckInActionProto.Response.checkInData: object expected");
                        message.checkInData = $root.waiternow.common.CheckInDataProto.fromObject(object.checkInData);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckInActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.checkInInfo = null;
                        object.checkInData = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.checkInInfo != null && message.hasOwnProperty("checkInInfo"))
                        object.checkInInfo = $root.waiternow.common.CheckInInfoProto.toObject(message.checkInInfo, options);
                    if (message.checkInData != null && message.hasOwnProperty("checkInData"))
                        object.checkInData = $root.waiternow.common.CheckInDataProto.toObject(message.checkInData, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckInActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckInActionProto.Response";
                };

                return Response;
            })();

            return CheckInActionProto;
        })();

        common.CheckLocationOnlineStatusActionProto = (function() {

            /**
             * Properties of a CheckLocationOnlineStatusActionProto.
             * @memberof waiternow.common
             * @interface ICheckLocationOnlineStatusActionProto
             */

            /**
             * Constructs a new CheckLocationOnlineStatusActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckLocationOnlineStatusActionProto.
             * @implements ICheckLocationOnlineStatusActionProto
             * @constructor
             * @param {waiternow.common.ICheckLocationOnlineStatusActionProto=} [properties] Properties to set
             */
            function CheckLocationOnlineStatusActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CheckLocationOnlineStatusActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {waiternow.common.ICheckLocationOnlineStatusActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckLocationOnlineStatusActionProto} CheckLocationOnlineStatusActionProto instance
             */
            CheckLocationOnlineStatusActionProto.create = function create(properties) {
                return new CheckLocationOnlineStatusActionProto(properties);
            };

            /**
             * Encodes the specified CheckLocationOnlineStatusActionProto message. Does not implicitly {@link waiternow.common.CheckLocationOnlineStatusActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {waiternow.common.ICheckLocationOnlineStatusActionProto} message CheckLocationOnlineStatusActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckLocationOnlineStatusActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CheckLocationOnlineStatusActionProto message, length delimited. Does not implicitly {@link waiternow.common.CheckLocationOnlineStatusActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {waiternow.common.ICheckLocationOnlineStatusActionProto} message CheckLocationOnlineStatusActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckLocationOnlineStatusActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckLocationOnlineStatusActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckLocationOnlineStatusActionProto} CheckLocationOnlineStatusActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckLocationOnlineStatusActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckLocationOnlineStatusActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckLocationOnlineStatusActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckLocationOnlineStatusActionProto} CheckLocationOnlineStatusActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckLocationOnlineStatusActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckLocationOnlineStatusActionProto message.
             * @function verify
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckLocationOnlineStatusActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CheckLocationOnlineStatusActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckLocationOnlineStatusActionProto} CheckLocationOnlineStatusActionProto
             */
            CheckLocationOnlineStatusActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckLocationOnlineStatusActionProto)
                    return object;
                return new $root.waiternow.common.CheckLocationOnlineStatusActionProto();
            };

            /**
             * Creates a plain object from a CheckLocationOnlineStatusActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {waiternow.common.CheckLocationOnlineStatusActionProto} message CheckLocationOnlineStatusActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckLocationOnlineStatusActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CheckLocationOnlineStatusActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckLocationOnlineStatusActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckLocationOnlineStatusActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckLocationOnlineStatusActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckLocationOnlineStatusActionProto";
            };

            CheckLocationOnlineStatusActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CheckLocationOnlineStatusActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CheckLocationOnlineStatusActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckLocationOnlineStatusActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckLocationOnlineStatusActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CheckLocationOnlineStatusActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckLocationOnlineStatusActionProto.Request";
                };

                return Request;
            })();

            CheckLocationOnlineStatusActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {boolean|null} [isLocationOnline] Response isLocationOnline
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response isLocationOnline.
                 * @member {boolean} isLocationOnline
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @instance
                 */
                Response.prototype.isLocationOnline = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CheckLocationOnlineStatusActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.isLocationOnline != null && Object.hasOwnProperty.call(message, "isLocationOnline"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isLocationOnline);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CheckLocationOnlineStatusActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckLocationOnlineStatusActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.isLocationOnline = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                        if (typeof message.isLocationOnline !== "boolean")
                            return "isLocationOnline: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckLocationOnlineStatusActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckLocationOnlineStatusActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CheckLocationOnlineStatusActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CheckLocationOnlineStatusActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.isLocationOnline != null)
                        message.isLocationOnline = Boolean(object.isLocationOnline);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckLocationOnlineStatusActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.isLocationOnline = false;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                        object.isLocationOnline = message.isLocationOnline;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckLocationOnlineStatusActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckLocationOnlineStatusActionProto.Response";
                };

                return Response;
            })();

            return CheckLocationOnlineStatusActionProto;
        })();

        common.PlaceOrderActionProto = (function() {

            /**
             * Properties of a PlaceOrderActionProto.
             * @memberof waiternow.common
             * @interface IPlaceOrderActionProto
             */

            /**
             * Constructs a new PlaceOrderActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a PlaceOrderActionProto.
             * @implements IPlaceOrderActionProto
             * @constructor
             * @param {waiternow.common.IPlaceOrderActionProto=} [properties] Properties to set
             */
            function PlaceOrderActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new PlaceOrderActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {waiternow.common.IPlaceOrderActionProto=} [properties] Properties to set
             * @returns {waiternow.common.PlaceOrderActionProto} PlaceOrderActionProto instance
             */
            PlaceOrderActionProto.create = function create(properties) {
                return new PlaceOrderActionProto(properties);
            };

            /**
             * Encodes the specified PlaceOrderActionProto message. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {waiternow.common.IPlaceOrderActionProto} message PlaceOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlaceOrderActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified PlaceOrderActionProto message, length delimited. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {waiternow.common.IPlaceOrderActionProto} message PlaceOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PlaceOrderActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PlaceOrderActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PlaceOrderActionProto} PlaceOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlaceOrderActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PlaceOrderActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PlaceOrderActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PlaceOrderActionProto} PlaceOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PlaceOrderActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PlaceOrderActionProto message.
             * @function verify
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PlaceOrderActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a PlaceOrderActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PlaceOrderActionProto} PlaceOrderActionProto
             */
            PlaceOrderActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PlaceOrderActionProto)
                    return object;
                return new $root.waiternow.common.PlaceOrderActionProto();
            };

            /**
             * Creates a plain object from a PlaceOrderActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {waiternow.common.PlaceOrderActionProto} message PlaceOrderActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PlaceOrderActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this PlaceOrderActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PlaceOrderActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PlaceOrderActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PlaceOrderActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.PlaceOrderActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PlaceOrderActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PlaceOrderActionProto";
            };

            PlaceOrderActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.PlaceOrderActionProto
                 * @interface IRequest
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 * @property {string|null} [deviceId] Request deviceId
                 * @property {waiternow.common.IConsumerProto|null} [consumer] Request consumer
                 * @property {waiternow.common.OrderProto.IWaiterRequestProto|null} [waiterRequest] Request waiterRequest
                 * @property {waiternow.common.IStructuredMenuOrderProto|null} [structuredMenuOrder] Request structuredMenuOrder
                 * @property {waiternow.common.IMoneyProto|null} [tip] Request tip
                 * @property {boolean|null} [generatePaymentIntent] Request generatePaymentIntent
                 * @property {waiternow.common.IDateTimeProto|null} [orderTime] Request orderTime
                 * @property {string|null} [deliveryId] Request deliveryId
                 * @property {waiternow.common.PlaceOrderActionProto.Request.ITestProto|null} [test] Request test
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.PlaceOrderActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.PlaceOrderActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = "";

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Request consumer.
                 * @member {waiternow.common.IConsumerProto|null|undefined} consumer
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.consumer = null;

                /**
                 * Request waiterRequest.
                 * @member {waiternow.common.OrderProto.IWaiterRequestProto|null|undefined} waiterRequest
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.waiterRequest = null;

                /**
                 * Request structuredMenuOrder.
                 * @member {waiternow.common.IStructuredMenuOrderProto|null|undefined} structuredMenuOrder
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.structuredMenuOrder = null;

                /**
                 * Request tip.
                 * @member {waiternow.common.IMoneyProto|null|undefined} tip
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.tip = null;

                /**
                 * Request generatePaymentIntent.
                 * @member {boolean} generatePaymentIntent
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.generatePaymentIntent = false;

                /**
                 * Request orderTime.
                 * @member {waiternow.common.IDateTimeProto|null|undefined} orderTime
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.orderTime = null;

                /**
                 * Request deliveryId.
                 * @member {string} deliveryId
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.deliveryId = "";

                /**
                 * Request test.
                 * @member {waiternow.common.PlaceOrderActionProto.Request.ITestProto|null|undefined} test
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.test = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request orderType.
                 * @member {"waiterRequest"|"structuredMenuOrder"|undefined} orderType
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "orderType", {
                    get: $util.oneOfGetter($oneOfFields = ["waiterRequest", "structuredMenuOrder"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.PlaceOrderActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                    if (message.consumer != null && Object.hasOwnProperty.call(message, "consumer"))
                        $root.waiternow.common.ConsumerProto.encode(message.consumer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.waiterRequest != null && Object.hasOwnProperty.call(message, "waiterRequest"))
                        $root.waiternow.common.OrderProto.WaiterRequestProto.encode(message.waiterRequest, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.deviceId);
                    if (message.structuredMenuOrder != null && Object.hasOwnProperty.call(message, "structuredMenuOrder"))
                        $root.waiternow.common.StructuredMenuOrderProto.encode(message.structuredMenuOrder, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.tip != null && Object.hasOwnProperty.call(message, "tip"))
                        $root.waiternow.common.MoneyProto.encode(message.tip, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.generatePaymentIntent != null && Object.hasOwnProperty.call(message, "generatePaymentIntent"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.generatePaymentIntent);
                    if (message.orderTime != null && Object.hasOwnProperty.call(message, "orderTime"))
                        $root.waiternow.common.DateTimeProto.encode(message.orderTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.deliveryId != null && Object.hasOwnProperty.call(message, "deliveryId"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.deliveryId);
                    if (message.test != null && Object.hasOwnProperty.call(message, "test"))
                        $root.waiternow.common.PlaceOrderActionProto.Request.TestProto.encode(message.test, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PlaceOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PlaceOrderActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        case 4: {
                                message.deviceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.consumer = $root.waiternow.common.ConsumerProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.waiterRequest = $root.waiternow.common.OrderProto.WaiterRequestProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.tip = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.generatePaymentIntent = reader.bool();
                                break;
                            }
                        case 10: {
                                message.orderTime = $root.waiternow.common.DateTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.deliveryId = reader.string();
                                break;
                            }
                        case 12: {
                                message.test = $root.waiternow.common.PlaceOrderActionProto.Request.TestProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PlaceOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    if (message.consumer != null && message.hasOwnProperty("consumer")) {
                        var error = $root.waiternow.common.ConsumerProto.verify(message.consumer);
                        if (error)
                            return "consumer." + error;
                    }
                    if (message.waiterRequest != null && message.hasOwnProperty("waiterRequest")) {
                        properties.orderType = 1;
                        {
                            var error = $root.waiternow.common.OrderProto.WaiterRequestProto.verify(message.waiterRequest);
                            if (error)
                                return "waiterRequest." + error;
                        }
                    }
                    if (message.structuredMenuOrder != null && message.hasOwnProperty("structuredMenuOrder")) {
                        if (properties.orderType === 1)
                            return "orderType: multiple values";
                        properties.orderType = 1;
                        {
                            var error = $root.waiternow.common.StructuredMenuOrderProto.verify(message.structuredMenuOrder);
                            if (error)
                                return "structuredMenuOrder." + error;
                        }
                    }
                    if (message.tip != null && message.hasOwnProperty("tip")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.tip);
                        if (error)
                            return "tip." + error;
                    }
                    if (message.generatePaymentIntent != null && message.hasOwnProperty("generatePaymentIntent"))
                        if (typeof message.generatePaymentIntent !== "boolean")
                            return "generatePaymentIntent: boolean expected";
                    if (message.orderTime != null && message.hasOwnProperty("orderTime")) {
                        var error = $root.waiternow.common.DateTimeProto.verify(message.orderTime);
                        if (error)
                            return "orderTime." + error;
                    }
                    if (message.deliveryId != null && message.hasOwnProperty("deliveryId"))
                        if (!$util.isString(message.deliveryId))
                            return "deliveryId: string expected";
                    if (message.test != null && message.hasOwnProperty("test")) {
                        var error = $root.waiternow.common.PlaceOrderActionProto.Request.TestProto.verify(message.test);
                        if (error)
                            return "test." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PlaceOrderActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PlaceOrderActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.PlaceOrderActionProto.Request();
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    if (object.consumer != null) {
                        if (typeof object.consumer !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Request.consumer: object expected");
                        message.consumer = $root.waiternow.common.ConsumerProto.fromObject(object.consumer);
                    }
                    if (object.waiterRequest != null) {
                        if (typeof object.waiterRequest !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Request.waiterRequest: object expected");
                        message.waiterRequest = $root.waiternow.common.OrderProto.WaiterRequestProto.fromObject(object.waiterRequest);
                    }
                    if (object.structuredMenuOrder != null) {
                        if (typeof object.structuredMenuOrder !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Request.structuredMenuOrder: object expected");
                        message.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.fromObject(object.structuredMenuOrder);
                    }
                    if (object.tip != null) {
                        if (typeof object.tip !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Request.tip: object expected");
                        message.tip = $root.waiternow.common.MoneyProto.fromObject(object.tip);
                    }
                    if (object.generatePaymentIntent != null)
                        message.generatePaymentIntent = Boolean(object.generatePaymentIntent);
                    if (object.orderTime != null) {
                        if (typeof object.orderTime !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Request.orderTime: object expected");
                        message.orderTime = $root.waiternow.common.DateTimeProto.fromObject(object.orderTime);
                    }
                    if (object.deliveryId != null)
                        message.deliveryId = String(object.deliveryId);
                    if (object.test != null) {
                        if (typeof object.test !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Request.test: object expected");
                        message.test = $root.waiternow.common.PlaceOrderActionProto.Request.TestProto.fromObject(object.test);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.pointOfServiceId = "";
                        object.consumer = null;
                        object.deviceId = "";
                        object.tip = null;
                        object.generatePaymentIntent = false;
                        object.orderTime = null;
                        object.deliveryId = "";
                        object.test = null;
                    }
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    if (message.consumer != null && message.hasOwnProperty("consumer"))
                        object.consumer = $root.waiternow.common.ConsumerProto.toObject(message.consumer, options);
                    if (message.waiterRequest != null && message.hasOwnProperty("waiterRequest")) {
                        object.waiterRequest = $root.waiternow.common.OrderProto.WaiterRequestProto.toObject(message.waiterRequest, options);
                        if (options.oneofs)
                            object.orderType = "waiterRequest";
                    }
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    if (message.structuredMenuOrder != null && message.hasOwnProperty("structuredMenuOrder")) {
                        object.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.toObject(message.structuredMenuOrder, options);
                        if (options.oneofs)
                            object.orderType = "structuredMenuOrder";
                    }
                    if (message.tip != null && message.hasOwnProperty("tip"))
                        object.tip = $root.waiternow.common.MoneyProto.toObject(message.tip, options);
                    if (message.generatePaymentIntent != null && message.hasOwnProperty("generatePaymentIntent"))
                        object.generatePaymentIntent = message.generatePaymentIntent;
                    if (message.orderTime != null && message.hasOwnProperty("orderTime"))
                        object.orderTime = $root.waiternow.common.DateTimeProto.toObject(message.orderTime, options);
                    if (message.deliveryId != null && message.hasOwnProperty("deliveryId"))
                        object.deliveryId = message.deliveryId;
                    if (message.test != null && message.hasOwnProperty("test"))
                        object.test = $root.waiternow.common.PlaceOrderActionProto.Request.TestProto.toObject(message.test, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.PlaceOrderActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PlaceOrderActionProto.Request";
                };

                Request.TestProto = (function() {

                    /**
                     * Properties of a TestProto.
                     * @memberof waiternow.common.PlaceOrderActionProto.Request
                     * @interface ITestProto
                     * @property {boolean|null} [isStressTest] TestProto isStressTest
                     * @property {boolean|null} [emulatePrinter] TestProto emulatePrinter
                     */

                    /**
                     * Constructs a new TestProto.
                     * @memberof waiternow.common.PlaceOrderActionProto.Request
                     * @classdesc Represents a TestProto.
                     * @implements ITestProto
                     * @constructor
                     * @param {waiternow.common.PlaceOrderActionProto.Request.ITestProto=} [properties] Properties to set
                     */
                    function TestProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TestProto isStressTest.
                     * @member {boolean} isStressTest
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @instance
                     */
                    TestProto.prototype.isStressTest = false;

                    /**
                     * TestProto emulatePrinter.
                     * @member {boolean} emulatePrinter
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @instance
                     */
                    TestProto.prototype.emulatePrinter = false;

                    /**
                     * Creates a new TestProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.PlaceOrderActionProto.Request.ITestProto=} [properties] Properties to set
                     * @returns {waiternow.common.PlaceOrderActionProto.Request.TestProto} TestProto instance
                     */
                    TestProto.create = function create(properties) {
                        return new TestProto(properties);
                    };

                    /**
                     * Encodes the specified TestProto message. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.Request.TestProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.PlaceOrderActionProto.Request.ITestProto} message TestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isStressTest != null && Object.hasOwnProperty.call(message, "isStressTest"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isStressTest);
                        if (message.emulatePrinter != null && Object.hasOwnProperty.call(message, "emulatePrinter"))
                            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.emulatePrinter);
                        return writer;
                    };

                    /**
                     * Encodes the specified TestProto message, length delimited. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.Request.TestProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.PlaceOrderActionProto.Request.ITestProto} message TestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TestProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.PlaceOrderActionProto.Request.TestProto} TestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PlaceOrderActionProto.Request.TestProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.isStressTest = reader.bool();
                                    break;
                                }
                            case 2: {
                                    message.emulatePrinter = reader.bool();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TestProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.PlaceOrderActionProto.Request.TestProto} TestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TestProto message.
                     * @function verify
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TestProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isStressTest != null && message.hasOwnProperty("isStressTest"))
                            if (typeof message.isStressTest !== "boolean")
                                return "isStressTest: boolean expected";
                        if (message.emulatePrinter != null && message.hasOwnProperty("emulatePrinter"))
                            if (typeof message.emulatePrinter !== "boolean")
                                return "emulatePrinter: boolean expected";
                        return null;
                    };

                    /**
                     * Creates a TestProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.PlaceOrderActionProto.Request.TestProto} TestProto
                     */
                    TestProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.PlaceOrderActionProto.Request.TestProto)
                            return object;
                        var message = new $root.waiternow.common.PlaceOrderActionProto.Request.TestProto();
                        if (object.isStressTest != null)
                            message.isStressTest = Boolean(object.isStressTest);
                        if (object.emulatePrinter != null)
                            message.emulatePrinter = Boolean(object.emulatePrinter);
                        return message;
                    };

                    /**
                     * Creates a plain object from a TestProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.PlaceOrderActionProto.Request.TestProto} message TestProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TestProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isStressTest = false;
                            object.emulatePrinter = false;
                        }
                        if (message.isStressTest != null && message.hasOwnProperty("isStressTest"))
                            object.isStressTest = message.isStressTest;
                        if (message.emulatePrinter != null && message.hasOwnProperty("emulatePrinter"))
                            object.emulatePrinter = message.emulatePrinter;
                        return object;
                    };

                    /**
                     * Converts this TestProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TestProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for TestProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.PlaceOrderActionProto.Request.TestProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.PlaceOrderActionProto.Request.TestProto";
                    };

                    return TestProto;
                })();

                return Request;
            })();

            PlaceOrderActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.PlaceOrderActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IOrderProto|null} [order] Response order
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.PlaceOrderActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.PlaceOrderActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response order.
                 * @member {waiternow.common.IOrderProto|null|undefined} order
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @instance
                 */
                Response.prototype.order = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.PlaceOrderActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                        $root.waiternow.common.OrderProto.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.PlaceOrderActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PlaceOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PlaceOrderActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.order = $root.waiternow.common.OrderProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PlaceOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.order != null && message.hasOwnProperty("order")) {
                        var error = $root.waiternow.common.OrderProto.verify(message.order);
                        if (error)
                            return "order." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PlaceOrderActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PlaceOrderActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.PlaceOrderActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.order != null) {
                        if (typeof object.order !== "object")
                            throw TypeError(".waiternow.common.PlaceOrderActionProto.Response.order: object expected");
                        message.order = $root.waiternow.common.OrderProto.fromObject(object.order);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.PlaceOrderActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.order = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.order != null && message.hasOwnProperty("order"))
                        object.order = $root.waiternow.common.OrderProto.toObject(message.order, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.PlaceOrderActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PlaceOrderActionProto.Response";
                };

                return Response;
            })();

            return PlaceOrderActionProto;
        })();

        common.CheckOrderStatusActionProto = (function() {

            /**
             * Properties of a CheckOrderStatusActionProto.
             * @memberof waiternow.common
             * @interface ICheckOrderStatusActionProto
             */

            /**
             * Constructs a new CheckOrderStatusActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckOrderStatusActionProto.
             * @implements ICheckOrderStatusActionProto
             * @constructor
             * @param {waiternow.common.ICheckOrderStatusActionProto=} [properties] Properties to set
             */
            function CheckOrderStatusActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CheckOrderStatusActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {waiternow.common.ICheckOrderStatusActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckOrderStatusActionProto} CheckOrderStatusActionProto instance
             */
            CheckOrderStatusActionProto.create = function create(properties) {
                return new CheckOrderStatusActionProto(properties);
            };

            /**
             * Encodes the specified CheckOrderStatusActionProto message. Does not implicitly {@link waiternow.common.CheckOrderStatusActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {waiternow.common.ICheckOrderStatusActionProto} message CheckOrderStatusActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckOrderStatusActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CheckOrderStatusActionProto message, length delimited. Does not implicitly {@link waiternow.common.CheckOrderStatusActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {waiternow.common.ICheckOrderStatusActionProto} message CheckOrderStatusActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckOrderStatusActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckOrderStatusActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckOrderStatusActionProto} CheckOrderStatusActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckOrderStatusActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckOrderStatusActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckOrderStatusActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckOrderStatusActionProto} CheckOrderStatusActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckOrderStatusActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckOrderStatusActionProto message.
             * @function verify
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckOrderStatusActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CheckOrderStatusActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckOrderStatusActionProto} CheckOrderStatusActionProto
             */
            CheckOrderStatusActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckOrderStatusActionProto)
                    return object;
                return new $root.waiternow.common.CheckOrderStatusActionProto();
            };

            /**
             * Creates a plain object from a CheckOrderStatusActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {waiternow.common.CheckOrderStatusActionProto} message CheckOrderStatusActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckOrderStatusActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CheckOrderStatusActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckOrderStatusActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckOrderStatusActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckOrderStatusActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckOrderStatusActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckOrderStatusActionProto";
            };

            CheckOrderStatusActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CheckOrderStatusActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 * @property {waiternow.common.IConsumerProto|null} [consumer] Request consumer
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CheckOrderStatusActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CheckOrderStatusActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Request consumer.
                 * @member {waiternow.common.IConsumerProto|null|undefined} consumer
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @instance
                 */
                Request.prototype.consumer = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CheckOrderStatusActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    if (message.consumer != null && Object.hasOwnProperty.call(message, "consumer"))
                        $root.waiternow.common.ConsumerProto.encode(message.consumer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CheckOrderStatusActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckOrderStatusActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        case 2: {
                                message.consumer = $root.waiternow.common.ConsumerProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    if (message.consumer != null && message.hasOwnProperty("consumer")) {
                        var error = $root.waiternow.common.ConsumerProto.verify(message.consumer);
                        if (error)
                            return "consumer." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckOrderStatusActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CheckOrderStatusActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    if (object.consumer != null) {
                        if (typeof object.consumer !== "object")
                            throw TypeError(".waiternow.common.CheckOrderStatusActionProto.Request.consumer: object expected");
                        message.consumer = $root.waiternow.common.ConsumerProto.fromObject(object.consumer);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orderId = "";
                        object.consumer = null;
                    }
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    if (message.consumer != null && message.hasOwnProperty("consumer"))
                        object.consumer = $root.waiternow.common.ConsumerProto.toObject(message.consumer, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckOrderStatusActionProto.Request";
                };

                return Request;
            })();

            CheckOrderStatusActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CheckOrderStatusActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IOrderProto|null} [order] Response order
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CheckOrderStatusActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CheckOrderStatusActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response order.
                 * @member {waiternow.common.IOrderProto|null|undefined} order
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @instance
                 */
                Response.prototype.order = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CheckOrderStatusActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                        $root.waiternow.common.OrderProto.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CheckOrderStatusActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckOrderStatusActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.order = $root.waiternow.common.OrderProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.order != null && message.hasOwnProperty("order")) {
                        var error = $root.waiternow.common.OrderProto.verify(message.order);
                        if (error)
                            return "order." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckOrderStatusActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckOrderStatusActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CheckOrderStatusActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CheckOrderStatusActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.order != null) {
                        if (typeof object.order !== "object")
                            throw TypeError(".waiternow.common.CheckOrderStatusActionProto.Response.order: object expected");
                        message.order = $root.waiternow.common.OrderProto.fromObject(object.order);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrderStatusActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.order = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.order != null && message.hasOwnProperty("order"))
                        object.order = $root.waiternow.common.OrderProto.toObject(message.order, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckOrderStatusActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckOrderStatusActionProto.Response";
                };

                return Response;
            })();

            return CheckOrderStatusActionProto;
        })();

        common.CheckOrdersStatusActionProto = (function() {

            /**
             * Properties of a CheckOrdersStatusActionProto.
             * @memberof waiternow.common
             * @interface ICheckOrdersStatusActionProto
             */

            /**
             * Constructs a new CheckOrdersStatusActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckOrdersStatusActionProto.
             * @implements ICheckOrdersStatusActionProto
             * @constructor
             * @param {waiternow.common.ICheckOrdersStatusActionProto=} [properties] Properties to set
             */
            function CheckOrdersStatusActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CheckOrdersStatusActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {waiternow.common.ICheckOrdersStatusActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckOrdersStatusActionProto} CheckOrdersStatusActionProto instance
             */
            CheckOrdersStatusActionProto.create = function create(properties) {
                return new CheckOrdersStatusActionProto(properties);
            };

            /**
             * Encodes the specified CheckOrdersStatusActionProto message. Does not implicitly {@link waiternow.common.CheckOrdersStatusActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {waiternow.common.ICheckOrdersStatusActionProto} message CheckOrdersStatusActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckOrdersStatusActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CheckOrdersStatusActionProto message, length delimited. Does not implicitly {@link waiternow.common.CheckOrdersStatusActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {waiternow.common.ICheckOrdersStatusActionProto} message CheckOrdersStatusActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckOrdersStatusActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckOrdersStatusActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckOrdersStatusActionProto} CheckOrdersStatusActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckOrdersStatusActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckOrdersStatusActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckOrdersStatusActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckOrdersStatusActionProto} CheckOrdersStatusActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckOrdersStatusActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckOrdersStatusActionProto message.
             * @function verify
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckOrdersStatusActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CheckOrdersStatusActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckOrdersStatusActionProto} CheckOrdersStatusActionProto
             */
            CheckOrdersStatusActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckOrdersStatusActionProto)
                    return object;
                return new $root.waiternow.common.CheckOrdersStatusActionProto();
            };

            /**
             * Creates a plain object from a CheckOrdersStatusActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {waiternow.common.CheckOrdersStatusActionProto} message CheckOrdersStatusActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckOrdersStatusActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CheckOrdersStatusActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckOrdersStatusActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckOrdersStatusActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckOrdersStatusActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckOrdersStatusActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckOrdersStatusActionProto";
            };

            CheckOrdersStatusActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CheckOrdersStatusActionProto
                 * @interface IRequest
                 * @property {Array.<string>|null} [orderIds] Request orderIds
                 * @property {waiternow.common.IConsumerProto|null} [consumer] Request consumer
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CheckOrdersStatusActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.orderIds = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderIds.
                 * @member {Array.<string>} orderIds
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @instance
                 */
                Request.prototype.orderIds = $util.emptyArray;

                /**
                 * Request consumer.
                 * @member {waiternow.common.IConsumerProto|null|undefined} consumer
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @instance
                 */
                Request.prototype.consumer = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CheckOrdersStatusActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderIds != null && message.orderIds.length)
                        for (var i = 0; i < message.orderIds.length; ++i)
                            writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderIds[i]);
                    if (message.consumer != null && Object.hasOwnProperty.call(message, "consumer"))
                        $root.waiternow.common.ConsumerProto.encode(message.consumer, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CheckOrdersStatusActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckOrdersStatusActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                if (!(message.orderIds && message.orderIds.length))
                                    message.orderIds = [];
                                message.orderIds.push(reader.string());
                                break;
                            }
                        case 2: {
                                message.consumer = $root.waiternow.common.ConsumerProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderIds != null && message.hasOwnProperty("orderIds")) {
                        if (!Array.isArray(message.orderIds))
                            return "orderIds: array expected";
                        for (var i = 0; i < message.orderIds.length; ++i)
                            if (!$util.isString(message.orderIds[i]))
                                return "orderIds: string[] expected";
                    }
                    if (message.consumer != null && message.hasOwnProperty("consumer")) {
                        var error = $root.waiternow.common.ConsumerProto.verify(message.consumer);
                        if (error)
                            return "consumer." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckOrdersStatusActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CheckOrdersStatusActionProto.Request();
                    if (object.orderIds) {
                        if (!Array.isArray(object.orderIds))
                            throw TypeError(".waiternow.common.CheckOrdersStatusActionProto.Request.orderIds: array expected");
                        message.orderIds = [];
                        for (var i = 0; i < object.orderIds.length; ++i)
                            message.orderIds[i] = String(object.orderIds[i]);
                    }
                    if (object.consumer != null) {
                        if (typeof object.consumer !== "object")
                            throw TypeError(".waiternow.common.CheckOrdersStatusActionProto.Request.consumer: object expected");
                        message.consumer = $root.waiternow.common.ConsumerProto.fromObject(object.consumer);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orderIds = [];
                    if (options.defaults)
                        object.consumer = null;
                    if (message.orderIds && message.orderIds.length) {
                        object.orderIds = [];
                        for (var j = 0; j < message.orderIds.length; ++j)
                            object.orderIds[j] = message.orderIds[j];
                    }
                    if (message.consumer != null && message.hasOwnProperty("consumer"))
                        object.consumer = $root.waiternow.common.ConsumerProto.toObject(message.consumer, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckOrdersStatusActionProto.Request";
                };

                return Request;
            })();

            CheckOrdersStatusActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CheckOrdersStatusActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {Array.<waiternow.common.IOrderProto>|null} [orders] Response orders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CheckOrdersStatusActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    this.orders = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response orders.
                 * @member {Array.<waiternow.common.IOrderProto>} orders
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @instance
                 */
                Response.prototype.orders = $util.emptyArray;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CheckOrdersStatusActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.orders != null && message.orders.length)
                        for (var i = 0; i < message.orders.length; ++i)
                            $root.waiternow.common.OrderProto.encode(message.orders[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CheckOrdersStatusActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckOrdersStatusActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.orders && message.orders.length))
                                    message.orders = [];
                                message.orders.push($root.waiternow.common.OrderProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.orders != null && message.hasOwnProperty("orders")) {
                        if (!Array.isArray(message.orders))
                            return "orders: array expected";
                        for (var i = 0; i < message.orders.length; ++i) {
                            var error = $root.waiternow.common.OrderProto.verify(message.orders[i]);
                            if (error)
                                return "orders." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CheckOrdersStatusActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CheckOrdersStatusActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CheckOrdersStatusActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CheckOrdersStatusActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.orders) {
                        if (!Array.isArray(object.orders))
                            throw TypeError(".waiternow.common.CheckOrdersStatusActionProto.Response.orders: array expected");
                        message.orders = [];
                        for (var i = 0; i < object.orders.length; ++i) {
                            if (typeof object.orders[i] !== "object")
                                throw TypeError(".waiternow.common.CheckOrdersStatusActionProto.Response.orders: object expected");
                            message.orders[i] = $root.waiternow.common.OrderProto.fromObject(object.orders[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {waiternow.common.CheckOrdersStatusActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.orders = [];
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.orders && message.orders.length) {
                        object.orders = [];
                        for (var j = 0; j < message.orders.length; ++j)
                            object.orders[j] = $root.waiternow.common.OrderProto.toObject(message.orders[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CheckOrdersStatusActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CheckOrdersStatusActionProto.Response";
                };

                return Response;
            })();

            return CheckOrdersStatusActionProto;
        })();

        common.PostReviewActionProto = (function() {

            /**
             * Properties of a PostReviewActionProto.
             * @memberof waiternow.common
             * @interface IPostReviewActionProto
             */

            /**
             * Constructs a new PostReviewActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a PostReviewActionProto.
             * @implements IPostReviewActionProto
             * @constructor
             * @param {waiternow.common.IPostReviewActionProto=} [properties] Properties to set
             */
            function PostReviewActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new PostReviewActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {waiternow.common.IPostReviewActionProto=} [properties] Properties to set
             * @returns {waiternow.common.PostReviewActionProto} PostReviewActionProto instance
             */
            PostReviewActionProto.create = function create(properties) {
                return new PostReviewActionProto(properties);
            };

            /**
             * Encodes the specified PostReviewActionProto message. Does not implicitly {@link waiternow.common.PostReviewActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {waiternow.common.IPostReviewActionProto} message PostReviewActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PostReviewActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified PostReviewActionProto message, length delimited. Does not implicitly {@link waiternow.common.PostReviewActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {waiternow.common.IPostReviewActionProto} message PostReviewActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PostReviewActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PostReviewActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PostReviewActionProto} PostReviewActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PostReviewActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PostReviewActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PostReviewActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PostReviewActionProto} PostReviewActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PostReviewActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PostReviewActionProto message.
             * @function verify
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PostReviewActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a PostReviewActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PostReviewActionProto} PostReviewActionProto
             */
            PostReviewActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PostReviewActionProto)
                    return object;
                return new $root.waiternow.common.PostReviewActionProto();
            };

            /**
             * Creates a plain object from a PostReviewActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {waiternow.common.PostReviewActionProto} message PostReviewActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PostReviewActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this PostReviewActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PostReviewActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PostReviewActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PostReviewActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.PostReviewActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PostReviewActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PostReviewActionProto";
            };

            PostReviewActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.PostReviewActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 * @property {waiternow.common.IReviewProto|null} [review] Request review
                 * @property {waiternow.common.IConsumerProto|null} [consumer] Request consumer
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.PostReviewActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.PostReviewActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request pointOfServiceId.
                 * @member {string|null|undefined} pointOfServiceId
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = null;

                /**
                 * Request review.
                 * @member {waiternow.common.IReviewProto|null|undefined} review
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @instance
                 */
                Request.prototype.review = null;

                /**
                 * Request consumer.
                 * @member {waiternow.common.IConsumerProto|null|undefined} consumer
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @instance
                 */
                Request.prototype.consumer = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request locationInfo.
                 * @member {"locationId"|"pointOfServiceId"|undefined} locationInfo
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "locationInfo", {
                    get: $util.oneOfGetter($oneOfFields = ["locationId", "pointOfServiceId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.PostReviewActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.PostReviewActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.pointOfServiceId);
                    if (message.review != null && Object.hasOwnProperty.call(message, "review"))
                        $root.waiternow.common.ReviewProto.encode(message.review, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.consumer != null && Object.hasOwnProperty.call(message, "consumer"))
                        $root.waiternow.common.ConsumerProto.encode(message.consumer, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.PostReviewActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PostReviewActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PostReviewActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        case 3: {
                                message.review = $root.waiternow.common.ReviewProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.consumer = $root.waiternow.common.ConsumerProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PostReviewActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        properties.locationInfo = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId")) {
                        if (properties.locationInfo === 1)
                            return "locationInfo: multiple values";
                        properties.locationInfo = 1;
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    }
                    if (message.review != null && message.hasOwnProperty("review")) {
                        var error = $root.waiternow.common.ReviewProto.verify(message.review);
                        if (error)
                            return "review." + error;
                    }
                    if (message.consumer != null && message.hasOwnProperty("consumer")) {
                        var error = $root.waiternow.common.ConsumerProto.verify(message.consumer);
                        if (error)
                            return "consumer." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PostReviewActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PostReviewActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.PostReviewActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    if (object.review != null) {
                        if (typeof object.review !== "object")
                            throw TypeError(".waiternow.common.PostReviewActionProto.Request.review: object expected");
                        message.review = $root.waiternow.common.ReviewProto.fromObject(object.review);
                    }
                    if (object.consumer != null) {
                        if (typeof object.consumer !== "object")
                            throw TypeError(".waiternow.common.PostReviewActionProto.Request.consumer: object expected");
                        message.consumer = $root.waiternow.common.ConsumerProto.fromObject(object.consumer);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.review = null;
                        object.consumer = null;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.locationInfo = "locationId";
                    }
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId")) {
                        object.pointOfServiceId = message.pointOfServiceId;
                        if (options.oneofs)
                            object.locationInfo = "pointOfServiceId";
                    }
                    if (message.review != null && message.hasOwnProperty("review"))
                        object.review = $root.waiternow.common.ReviewProto.toObject(message.review, options);
                    if (message.consumer != null && message.hasOwnProperty("consumer"))
                        object.consumer = $root.waiternow.common.ConsumerProto.toObject(message.consumer, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.PostReviewActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PostReviewActionProto.Request";
                };

                return Request;
            })();

            PostReviewActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.PostReviewActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IReviewProto|null} [review] Response review
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.PostReviewActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.PostReviewActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response review.
                 * @member {waiternow.common.IReviewProto|null|undefined} review
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @instance
                 */
                Response.prototype.review = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.PostReviewActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.PostReviewActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.review != null && Object.hasOwnProperty.call(message, "review"))
                        $root.waiternow.common.ReviewProto.encode(message.review, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.PostReviewActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PostReviewActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PostReviewActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.review = $root.waiternow.common.ReviewProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PostReviewActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.review != null && message.hasOwnProperty("review")) {
                        var error = $root.waiternow.common.ReviewProto.verify(message.review);
                        if (error)
                            return "review." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PostReviewActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PostReviewActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.PostReviewActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.PostReviewActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.review != null) {
                        if (typeof object.review !== "object")
                            throw TypeError(".waiternow.common.PostReviewActionProto.Response.review: object expected");
                        message.review = $root.waiternow.common.ReviewProto.fromObject(object.review);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {waiternow.common.PostReviewActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.review = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.review != null && message.hasOwnProperty("review"))
                        object.review = $root.waiternow.common.ReviewProto.toObject(message.review, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.PostReviewActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PostReviewActionProto.Response";
                };

                return Response;
            })();

            return PostReviewActionProto;
        })();

        common.FindDeviceOrdersActionProto = (function() {

            /**
             * Properties of a FindDeviceOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindDeviceOrdersActionProto
             */

            /**
             * Constructs a new FindDeviceOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindDeviceOrdersActionProto.
             * @implements IFindDeviceOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindDeviceOrdersActionProto=} [properties] Properties to set
             */
            function FindDeviceOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindDeviceOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {waiternow.common.IFindDeviceOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindDeviceOrdersActionProto} FindDeviceOrdersActionProto instance
             */
            FindDeviceOrdersActionProto.create = function create(properties) {
                return new FindDeviceOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindDeviceOrdersActionProto message. Does not implicitly {@link waiternow.common.FindDeviceOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {waiternow.common.IFindDeviceOrdersActionProto} message FindDeviceOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDeviceOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindDeviceOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindDeviceOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {waiternow.common.IFindDeviceOrdersActionProto} message FindDeviceOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDeviceOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindDeviceOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindDeviceOrdersActionProto} FindDeviceOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDeviceOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDeviceOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindDeviceOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindDeviceOrdersActionProto} FindDeviceOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDeviceOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindDeviceOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindDeviceOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindDeviceOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindDeviceOrdersActionProto} FindDeviceOrdersActionProto
             */
            FindDeviceOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindDeviceOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindDeviceOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindDeviceOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {waiternow.common.FindDeviceOrdersActionProto} message FindDeviceOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindDeviceOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindDeviceOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindDeviceOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindDeviceOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindDeviceOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindDeviceOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindDeviceOrdersActionProto";
            };

            FindDeviceOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindDeviceOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [deviceId] Request deviceId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindDeviceOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindDeviceOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindDeviceOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDeviceOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDeviceOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindDeviceOrdersActionProto.Request();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.deviceId = "";
                        object.continuationToken = "";
                    }
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDeviceOrdersActionProto.Request";
                };

                return Request;
            })();

            FindDeviceOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindDeviceOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IDeviceOrdersProto|null} [deviceOrders] Response deviceOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindDeviceOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response deviceOrders.
                 * @member {waiternow.common.IDeviceOrdersProto|null|undefined} deviceOrders
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.deviceOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindDeviceOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.deviceOrders != null && Object.hasOwnProperty.call(message, "deviceOrders"))
                        $root.waiternow.common.DeviceOrdersProto.encode(message.deviceOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindDeviceOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDeviceOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.deviceOrders = $root.waiternow.common.DeviceOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.deviceOrders != null && message.hasOwnProperty("deviceOrders")) {
                        var error = $root.waiternow.common.DeviceOrdersProto.verify(message.deviceOrders);
                        if (error)
                            return "deviceOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDeviceOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDeviceOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindDeviceOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindDeviceOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.deviceOrders != null) {
                        if (typeof object.deviceOrders !== "object")
                            throw TypeError(".waiternow.common.FindDeviceOrdersActionProto.Response.deviceOrders: object expected");
                        message.deviceOrders = $root.waiternow.common.DeviceOrdersProto.fromObject(object.deviceOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDeviceOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.deviceOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.deviceOrders != null && message.hasOwnProperty("deviceOrders"))
                        object.deviceOrders = $root.waiternow.common.DeviceOrdersProto.toObject(message.deviceOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDeviceOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDeviceOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindDeviceOrdersActionProto;
        })();

        common.FindDevicePaidOrdersByLocationActionProto = (function() {

            /**
             * Properties of a FindDevicePaidOrdersByLocationActionProto.
             * @memberof waiternow.common
             * @interface IFindDevicePaidOrdersByLocationActionProto
             */

            /**
             * Constructs a new FindDevicePaidOrdersByLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindDevicePaidOrdersByLocationActionProto.
             * @implements IFindDevicePaidOrdersByLocationActionProto
             * @constructor
             * @param {waiternow.common.IFindDevicePaidOrdersByLocationActionProto=} [properties] Properties to set
             */
            function FindDevicePaidOrdersByLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindDevicePaidOrdersByLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {waiternow.common.IFindDevicePaidOrdersByLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto} FindDevicePaidOrdersByLocationActionProto instance
             */
            FindDevicePaidOrdersByLocationActionProto.create = function create(properties) {
                return new FindDevicePaidOrdersByLocationActionProto(properties);
            };

            /**
             * Encodes the specified FindDevicePaidOrdersByLocationActionProto message. Does not implicitly {@link waiternow.common.FindDevicePaidOrdersByLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {waiternow.common.IFindDevicePaidOrdersByLocationActionProto} message FindDevicePaidOrdersByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDevicePaidOrdersByLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindDevicePaidOrdersByLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindDevicePaidOrdersByLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {waiternow.common.IFindDevicePaidOrdersByLocationActionProto} message FindDevicePaidOrdersByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDevicePaidOrdersByLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindDevicePaidOrdersByLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto} FindDevicePaidOrdersByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDevicePaidOrdersByLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindDevicePaidOrdersByLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto} FindDevicePaidOrdersByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDevicePaidOrdersByLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindDevicePaidOrdersByLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindDevicePaidOrdersByLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindDevicePaidOrdersByLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto} FindDevicePaidOrdersByLocationActionProto
             */
            FindDevicePaidOrdersByLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto)
                    return object;
                return new $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto();
            };

            /**
             * Creates a plain object from a FindDevicePaidOrdersByLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto} message FindDevicePaidOrdersByLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindDevicePaidOrdersByLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindDevicePaidOrdersByLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindDevicePaidOrdersByLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindDevicePaidOrdersByLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindDevicePaidOrdersByLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindDevicePaidOrdersByLocationActionProto";
            };

            FindDevicePaidOrdersByLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [deviceId] Request deviceId
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.deviceId = "";
                        object.locationId = "";
                        object.continuationToken = "";
                    }
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDevicePaidOrdersByLocationActionProto.Request";
                };

                return Request;
            })();

            FindDevicePaidOrdersByLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IOrdersProto|null} [orders] Response orders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response orders.
                 * @member {waiternow.common.IOrdersProto|null|undefined} orders
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.orders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                        $root.waiternow.common.OrdersProto.encode(message.orders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.orders = $root.waiternow.common.OrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.orders != null && message.hasOwnProperty("orders")) {
                        var error = $root.waiternow.common.OrdersProto.verify(message.orders);
                        if (error)
                            return "orders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.orders != null) {
                        if (typeof object.orders !== "object")
                            throw TypeError(".waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response.orders: object expected");
                        message.orders = $root.waiternow.common.OrdersProto.fromObject(object.orders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.orders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.orders != null && message.hasOwnProperty("orders"))
                        object.orders = $root.waiternow.common.OrdersProto.toObject(message.orders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDevicePaidOrdersByLocationActionProto.Response";
                };

                return Response;
            })();

            return FindDevicePaidOrdersByLocationActionProto;
        })();

        common.MarkOrderAsPaidActionProto = (function() {

            /**
             * Properties of a MarkOrderAsPaidActionProto.
             * @memberof waiternow.common
             * @interface IMarkOrderAsPaidActionProto
             */

            /**
             * Constructs a new MarkOrderAsPaidActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a MarkOrderAsPaidActionProto.
             * @implements IMarkOrderAsPaidActionProto
             * @constructor
             * @param {waiternow.common.IMarkOrderAsPaidActionProto=} [properties] Properties to set
             */
            function MarkOrderAsPaidActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new MarkOrderAsPaidActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsPaidActionProto=} [properties] Properties to set
             * @returns {waiternow.common.MarkOrderAsPaidActionProto} MarkOrderAsPaidActionProto instance
             */
            MarkOrderAsPaidActionProto.create = function create(properties) {
                return new MarkOrderAsPaidActionProto(properties);
            };

            /**
             * Encodes the specified MarkOrderAsPaidActionProto message. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsPaidActionProto} message MarkOrderAsPaidActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsPaidActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified MarkOrderAsPaidActionProto message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsPaidActionProto} message MarkOrderAsPaidActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsPaidActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MarkOrderAsPaidActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MarkOrderAsPaidActionProto} MarkOrderAsPaidActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsPaidActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsPaidActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MarkOrderAsPaidActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MarkOrderAsPaidActionProto} MarkOrderAsPaidActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsPaidActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MarkOrderAsPaidActionProto message.
             * @function verify
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkOrderAsPaidActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a MarkOrderAsPaidActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MarkOrderAsPaidActionProto} MarkOrderAsPaidActionProto
             */
            MarkOrderAsPaidActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MarkOrderAsPaidActionProto)
                    return object;
                return new $root.waiternow.common.MarkOrderAsPaidActionProto();
            };

            /**
             * Creates a plain object from a MarkOrderAsPaidActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {waiternow.common.MarkOrderAsPaidActionProto} message MarkOrderAsPaidActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkOrderAsPaidActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this MarkOrderAsPaidActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkOrderAsPaidActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MarkOrderAsPaidActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.MarkOrderAsPaidActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarkOrderAsPaidActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MarkOrderAsPaidActionProto";
            };

            MarkOrderAsPaidActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 * @property {string|null} [emailAddress] Request emailAddress
                 * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] Request phoneNumber
                 * @property {string|null} [name] Request name
                 * @property {number|null} [attempt] Request attempt
                 * @property {waiternow.common.MarkOrderAsPaidActionProto.Request.ITestProto|null} [test] Request test
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Request emailAddress.
                 * @member {string} emailAddress
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.emailAddress = "";

                /**
                 * Request phoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumber = null;

                /**
                 * Request name.
                 * @member {string} name
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.name = "";

                /**
                 * Request attempt.
                 * @member {number} attempt
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.attempt = 0;

                /**
                 * Request test.
                 * @member {waiternow.common.MarkOrderAsPaidActionProto.Request.ITestProto|null|undefined} test
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.test = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    if (message.emailAddress != null && Object.hasOwnProperty.call(message, "emailAddress"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.emailAddress);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                    if (message.attempt != null && Object.hasOwnProperty.call(message, "attempt"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.attempt);
                    if (message.test != null && Object.hasOwnProperty.call(message, "test"))
                        $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto.encode(message.test, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsPaidActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        case 2: {
                                message.emailAddress = reader.string();
                                break;
                            }
                        case 3: {
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.name = reader.string();
                                break;
                            }
                        case 5: {
                                message.attempt = reader.int32();
                                break;
                            }
                        case 6: {
                                message.test = $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                        if (!$util.isString(message.emailAddress))
                            return "emailAddress: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.attempt != null && message.hasOwnProperty("attempt"))
                        if (!$util.isInteger(message.attempt))
                            return "attempt: integer expected";
                    if (message.test != null && message.hasOwnProperty("test")) {
                        var error = $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto.verify(message.test);
                        if (error)
                            return "test." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsPaidActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsPaidActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    if (object.emailAddress != null)
                        message.emailAddress = String(object.emailAddress);
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsPaidActionProto.Request.phoneNumber: object expected");
                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                    }
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.attempt != null)
                        message.attempt = object.attempt | 0;
                    if (object.test != null) {
                        if (typeof object.test !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsPaidActionProto.Request.test: object expected");
                        message.test = $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto.fromObject(object.test);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orderId = "";
                        object.emailAddress = "";
                        object.phoneNumber = null;
                        object.name = "";
                        object.attempt = 0;
                        object.test = null;
                    }
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    if (message.emailAddress != null && message.hasOwnProperty("emailAddress"))
                        object.emailAddress = message.emailAddress;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.attempt != null && message.hasOwnProperty("attempt"))
                        object.attempt = message.attempt;
                    if (message.test != null && message.hasOwnProperty("test"))
                        object.test = $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto.toObject(message.test, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsPaidActionProto.Request";
                };

                Request.TestProto = (function() {

                    /**
                     * Properties of a TestProto.
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                     * @interface ITestProto
                     * @property {boolean|null} [isFailureTest] TestProto isFailureTest
                     * @property {number|null} [numberOfAttemptsToFail] TestProto numberOfAttemptsToFail
                     */

                    /**
                     * Constructs a new TestProto.
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request
                     * @classdesc Represents a TestProto.
                     * @implements ITestProto
                     * @constructor
                     * @param {waiternow.common.MarkOrderAsPaidActionProto.Request.ITestProto=} [properties] Properties to set
                     */
                    function TestProto(properties) {
                        if (properties)
                            for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                                if (properties[keys[i]] != null)
                                    this[keys[i]] = properties[keys[i]];
                    }

                    /**
                     * TestProto isFailureTest.
                     * @member {boolean} isFailureTest
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @instance
                     */
                    TestProto.prototype.isFailureTest = false;

                    /**
                     * TestProto numberOfAttemptsToFail.
                     * @member {number} numberOfAttemptsToFail
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @instance
                     */
                    TestProto.prototype.numberOfAttemptsToFail = 0;

                    /**
                     * Creates a new TestProto instance using the specified properties.
                     * @function create
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.MarkOrderAsPaidActionProto.Request.ITestProto=} [properties] Properties to set
                     * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto} TestProto instance
                     */
                    TestProto.create = function create(properties) {
                        return new TestProto(properties);
                    };

                    /**
                     * Encodes the specified TestProto message. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto.verify|verify} messages.
                     * @function encode
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.MarkOrderAsPaidActionProto.Request.ITestProto} message TestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestProto.encode = function encode(message, writer) {
                        if (!writer)
                            writer = $Writer.create();
                        if (message.isFailureTest != null && Object.hasOwnProperty.call(message, "isFailureTest"))
                            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isFailureTest);
                        if (message.numberOfAttemptsToFail != null && Object.hasOwnProperty.call(message, "numberOfAttemptsToFail"))
                            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.numberOfAttemptsToFail);
                        return writer;
                    };

                    /**
                     * Encodes the specified TestProto message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto.verify|verify} messages.
                     * @function encodeDelimited
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.MarkOrderAsPaidActionProto.Request.ITestProto} message TestProto message or plain object to encode
                     * @param {$protobuf.Writer} [writer] Writer to encode to
                     * @returns {$protobuf.Writer} Writer
                     */
                    TestProto.encodeDelimited = function encodeDelimited(message, writer) {
                        return this.encode(message, writer).ldelim();
                    };

                    /**
                     * Decodes a TestProto message from the specified reader or buffer.
                     * @function decode
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @param {number} [length] Message length if known beforehand
                     * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto} TestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestProto.decode = function decode(reader, length) {
                        if (!(reader instanceof $Reader))
                            reader = $Reader.create(reader);
                        var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto();
                        while (reader.pos < end) {
                            var tag = reader.uint32();
                            switch (tag >>> 3) {
                            case 1: {
                                    message.isFailureTest = reader.bool();
                                    break;
                                }
                            case 2: {
                                    message.numberOfAttemptsToFail = reader.int32();
                                    break;
                                }
                            default:
                                reader.skipType(tag & 7);
                                break;
                            }
                        }
                        return message;
                    };

                    /**
                     * Decodes a TestProto message from the specified reader or buffer, length delimited.
                     * @function decodeDelimited
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                     * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto} TestProto
                     * @throws {Error} If the payload is not a reader or valid buffer
                     * @throws {$protobuf.util.ProtocolError} If required fields are missing
                     */
                    TestProto.decodeDelimited = function decodeDelimited(reader) {
                        if (!(reader instanceof $Reader))
                            reader = new $Reader(reader);
                        return this.decode(reader, reader.uint32());
                    };

                    /**
                     * Verifies a TestProto message.
                     * @function verify
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {Object.<string,*>} message Plain object to verify
                     * @returns {string|null} `null` if valid, otherwise the reason why it is not
                     */
                    TestProto.verify = function verify(message) {
                        if (typeof message !== "object" || message === null)
                            return "object expected";
                        if (message.isFailureTest != null && message.hasOwnProperty("isFailureTest"))
                            if (typeof message.isFailureTest !== "boolean")
                                return "isFailureTest: boolean expected";
                        if (message.numberOfAttemptsToFail != null && message.hasOwnProperty("numberOfAttemptsToFail"))
                            if (!$util.isInteger(message.numberOfAttemptsToFail))
                                return "numberOfAttemptsToFail: integer expected";
                        return null;
                    };

                    /**
                     * Creates a TestProto message from a plain object. Also converts values to their respective internal types.
                     * @function fromObject
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {Object.<string,*>} object Plain object
                     * @returns {waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto} TestProto
                     */
                    TestProto.fromObject = function fromObject(object) {
                        if (object instanceof $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto)
                            return object;
                        var message = new $root.waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto();
                        if (object.isFailureTest != null)
                            message.isFailureTest = Boolean(object.isFailureTest);
                        if (object.numberOfAttemptsToFail != null)
                            message.numberOfAttemptsToFail = object.numberOfAttemptsToFail | 0;
                        return message;
                    };

                    /**
                     * Creates a plain object from a TestProto message. Also converts values to other types if specified.
                     * @function toObject
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto} message TestProto
                     * @param {$protobuf.IConversionOptions} [options] Conversion options
                     * @returns {Object.<string,*>} Plain object
                     */
                    TestProto.toObject = function toObject(message, options) {
                        if (!options)
                            options = {};
                        var object = {};
                        if (options.defaults) {
                            object.isFailureTest = false;
                            object.numberOfAttemptsToFail = 0;
                        }
                        if (message.isFailureTest != null && message.hasOwnProperty("isFailureTest"))
                            object.isFailureTest = message.isFailureTest;
                        if (message.numberOfAttemptsToFail != null && message.hasOwnProperty("numberOfAttemptsToFail"))
                            object.numberOfAttemptsToFail = message.numberOfAttemptsToFail;
                        return object;
                    };

                    /**
                     * Converts this TestProto to JSON.
                     * @function toJSON
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @instance
                     * @returns {Object.<string,*>} JSON object
                     */
                    TestProto.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    /**
                     * Gets the default type url for TestProto
                     * @function getTypeUrl
                     * @memberof waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto
                     * @static
                     * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                     * @returns {string} The default type url
                     */
                    TestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                        if (typeUrlPrefix === undefined) {
                            typeUrlPrefix = "type.googleapis.com";
                        }
                        return typeUrlPrefix + "/waiternow.common.MarkOrderAsPaidActionProto.Request.TestProto";
                    };

                    return TestProto;
                })();

                return Request;
            })();

            MarkOrderAsPaidActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IPaymentProto|null} [payment] Response payment
                 * @property {string|null} [deliveryTrackingUrl] Response deliveryTrackingUrl
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response payment.
                 * @member {waiternow.common.IPaymentProto|null|undefined} payment
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @instance
                 */
                Response.prototype.payment = null;

                /**
                 * Response deliveryTrackingUrl.
                 * @member {string} deliveryTrackingUrl
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @instance
                 */
                Response.prototype.deliveryTrackingUrl = "";

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.payment != null && Object.hasOwnProperty.call(message, "payment"))
                        $root.waiternow.common.PaymentProto.encode(message.payment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.deliveryTrackingUrl != null && Object.hasOwnProperty.call(message, "deliveryTrackingUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.deliveryTrackingUrl);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsPaidActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsPaidActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.payment = $root.waiternow.common.PaymentProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.deliveryTrackingUrl = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.payment != null && message.hasOwnProperty("payment")) {
                        var error = $root.waiternow.common.PaymentProto.verify(message.payment);
                        if (error)
                            return "payment." + error;
                    }
                    if (message.deliveryTrackingUrl != null && message.hasOwnProperty("deliveryTrackingUrl"))
                        if (!$util.isString(message.deliveryTrackingUrl))
                            return "deliveryTrackingUrl: string expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsPaidActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsPaidActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsPaidActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsPaidActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.payment != null) {
                        if (typeof object.payment !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsPaidActionProto.Response.payment: object expected");
                        message.payment = $root.waiternow.common.PaymentProto.fromObject(object.payment);
                    }
                    if (object.deliveryTrackingUrl != null)
                        message.deliveryTrackingUrl = String(object.deliveryTrackingUrl);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPaidActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.payment = null;
                        object.deliveryTrackingUrl = "";
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.payment != null && message.hasOwnProperty("payment"))
                        object.payment = $root.waiternow.common.PaymentProto.toObject(message.payment, options);
                    if (message.deliveryTrackingUrl != null && message.hasOwnProperty("deliveryTrackingUrl"))
                        object.deliveryTrackingUrl = message.deliveryTrackingUrl;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsPaidActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsPaidActionProto.Response";
                };

                return Response;
            })();

            return MarkOrderAsPaidActionProto;
        })();

        common.GetStructuredMenuDraftDenormalizedActionProto = (function() {

            /**
             * Properties of a GetStructuredMenuDraftDenormalizedActionProto.
             * @memberof waiternow.common
             * @interface IGetStructuredMenuDraftDenormalizedActionProto
             */

            /**
             * Constructs a new GetStructuredMenuDraftDenormalizedActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetStructuredMenuDraftDenormalizedActionProto.
             * @implements IGetStructuredMenuDraftDenormalizedActionProto
             * @constructor
             * @param {waiternow.common.IGetStructuredMenuDraftDenormalizedActionProto=} [properties] Properties to set
             */
            function GetStructuredMenuDraftDenormalizedActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetStructuredMenuDraftDenormalizedActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuDraftDenormalizedActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto} GetStructuredMenuDraftDenormalizedActionProto instance
             */
            GetStructuredMenuDraftDenormalizedActionProto.create = function create(properties) {
                return new GetStructuredMenuDraftDenormalizedActionProto(properties);
            };

            /**
             * Encodes the specified GetStructuredMenuDraftDenormalizedActionProto message. Does not implicitly {@link waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuDraftDenormalizedActionProto} message GetStructuredMenuDraftDenormalizedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuDraftDenormalizedActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetStructuredMenuDraftDenormalizedActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuDraftDenormalizedActionProto} message GetStructuredMenuDraftDenormalizedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuDraftDenormalizedActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetStructuredMenuDraftDenormalizedActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto} GetStructuredMenuDraftDenormalizedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuDraftDenormalizedActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetStructuredMenuDraftDenormalizedActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto} GetStructuredMenuDraftDenormalizedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuDraftDenormalizedActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetStructuredMenuDraftDenormalizedActionProto message.
             * @function verify
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetStructuredMenuDraftDenormalizedActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetStructuredMenuDraftDenormalizedActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto} GetStructuredMenuDraftDenormalizedActionProto
             */
            GetStructuredMenuDraftDenormalizedActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto)
                    return object;
                return new $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto();
            };

            /**
             * Creates a plain object from a GetStructuredMenuDraftDenormalizedActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto} message GetStructuredMenuDraftDenormalizedActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStructuredMenuDraftDenormalizedActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetStructuredMenuDraftDenormalizedActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStructuredMenuDraftDenormalizedActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStructuredMenuDraftDenormalizedActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStructuredMenuDraftDenormalizedActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetStructuredMenuDraftDenormalizedActionProto";
            };

            GetStructuredMenuDraftDenormalizedActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Request";
                };

                return Request;
            })();

            GetStructuredMenuDraftDenormalizedActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IStructuredMenuProto|null} [menu] Response menu
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response menu.
                 * @member {waiternow.common.IStructuredMenuProto|null|undefined} menu
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @instance
                 */
                Response.prototype.menu = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuProto.encode(message.menu, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.menu = $root.waiternow.common.StructuredMenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.menu = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuDraftDenormalizedActionProto.Response";
                };

                return Response;
            })();

            return GetStructuredMenuDraftDenormalizedActionProto;
        })();

        common.GetUpdatedStructuredMenuDenormalizedActionProto = (function() {

            /**
             * Properties of a GetUpdatedStructuredMenuDenormalizedActionProto.
             * @memberof waiternow.common
             * @interface IGetUpdatedStructuredMenuDenormalizedActionProto
             */

            /**
             * Constructs a new GetUpdatedStructuredMenuDenormalizedActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetUpdatedStructuredMenuDenormalizedActionProto.
             * @implements IGetUpdatedStructuredMenuDenormalizedActionProto
             * @constructor
             * @param {waiternow.common.IGetUpdatedStructuredMenuDenormalizedActionProto=} [properties] Properties to set
             */
            function GetUpdatedStructuredMenuDenormalizedActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetUpdatedStructuredMenuDenormalizedActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetUpdatedStructuredMenuDenormalizedActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto} GetUpdatedStructuredMenuDenormalizedActionProto instance
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.create = function create(properties) {
                return new GetUpdatedStructuredMenuDenormalizedActionProto(properties);
            };

            /**
             * Encodes the specified GetUpdatedStructuredMenuDenormalizedActionProto message. Does not implicitly {@link waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetUpdatedStructuredMenuDenormalizedActionProto} message GetUpdatedStructuredMenuDenormalizedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetUpdatedStructuredMenuDenormalizedActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetUpdatedStructuredMenuDenormalizedActionProto} message GetUpdatedStructuredMenuDenormalizedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetUpdatedStructuredMenuDenormalizedActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto} GetUpdatedStructuredMenuDenormalizedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetUpdatedStructuredMenuDenormalizedActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto} GetUpdatedStructuredMenuDenormalizedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetUpdatedStructuredMenuDenormalizedActionProto message.
             * @function verify
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetUpdatedStructuredMenuDenormalizedActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto} GetUpdatedStructuredMenuDenormalizedActionProto
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto)
                    return object;
                return new $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto();
            };

            /**
             * Creates a plain object from a GetUpdatedStructuredMenuDenormalizedActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto} message GetUpdatedStructuredMenuDenormalizedActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetUpdatedStructuredMenuDenormalizedActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUpdatedStructuredMenuDenormalizedActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUpdatedStructuredMenuDenormalizedActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto";
            };

            GetUpdatedStructuredMenuDenormalizedActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
                 * @interface IRequest
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request();
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.pointOfServiceId = "";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Request";
                };

                return Request;
            })();

            GetUpdatedStructuredMenuDenormalizedActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IStructuredMenuProto|null} [menu] Response menu
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response menu.
                 * @member {waiternow.common.IStructuredMenuProto|null|undefined} menu
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @instance
                 */
                Response.prototype.menu = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuProto.encode(message.menu, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.menu = $root.waiternow.common.StructuredMenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.menu = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetUpdatedStructuredMenuDenormalizedActionProto.Response";
                };

                return Response;
            })();

            return GetUpdatedStructuredMenuDenormalizedActionProto;
        })();

        common.GetDeliveryQuoteActionProto = (function() {

            /**
             * Properties of a GetDeliveryQuoteActionProto.
             * @memberof waiternow.common
             * @interface IGetDeliveryQuoteActionProto
             */

            /**
             * Constructs a new GetDeliveryQuoteActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetDeliveryQuoteActionProto.
             * @implements IGetDeliveryQuoteActionProto
             * @constructor
             * @param {waiternow.common.IGetDeliveryQuoteActionProto=} [properties] Properties to set
             */
            function GetDeliveryQuoteActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetDeliveryQuoteActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {waiternow.common.IGetDeliveryQuoteActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetDeliveryQuoteActionProto} GetDeliveryQuoteActionProto instance
             */
            GetDeliveryQuoteActionProto.create = function create(properties) {
                return new GetDeliveryQuoteActionProto(properties);
            };

            /**
             * Encodes the specified GetDeliveryQuoteActionProto message. Does not implicitly {@link waiternow.common.GetDeliveryQuoteActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {waiternow.common.IGetDeliveryQuoteActionProto} message GetDeliveryQuoteActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDeliveryQuoteActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetDeliveryQuoteActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetDeliveryQuoteActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {waiternow.common.IGetDeliveryQuoteActionProto} message GetDeliveryQuoteActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDeliveryQuoteActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetDeliveryQuoteActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetDeliveryQuoteActionProto} GetDeliveryQuoteActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDeliveryQuoteActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetDeliveryQuoteActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetDeliveryQuoteActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetDeliveryQuoteActionProto} GetDeliveryQuoteActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDeliveryQuoteActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetDeliveryQuoteActionProto message.
             * @function verify
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDeliveryQuoteActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetDeliveryQuoteActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetDeliveryQuoteActionProto} GetDeliveryQuoteActionProto
             */
            GetDeliveryQuoteActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetDeliveryQuoteActionProto)
                    return object;
                return new $root.waiternow.common.GetDeliveryQuoteActionProto();
            };

            /**
             * Creates a plain object from a GetDeliveryQuoteActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {waiternow.common.GetDeliveryQuoteActionProto} message GetDeliveryQuoteActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDeliveryQuoteActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetDeliveryQuoteActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDeliveryQuoteActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetDeliveryQuoteActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetDeliveryQuoteActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetDeliveryQuoteActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetDeliveryQuoteActionProto";
            };

            GetDeliveryQuoteActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.IAddressProto|null} [deliveryAddress] Request deliveryAddress
                 * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] Request phoneNumber
                 * @property {string|null} [email] Request email
                 * @property {waiternow.common.IDateTimeProto|null} [orderTime] Request orderTime
                 * @property {waiternow.common.IStructuredMenuOrderProto|null} [structuredMenuOrder] Request structuredMenuOrder
                 * @property {waiternow.common.IMoneyProto|null} [deliveryTip] Request deliveryTip
                 * @property {string|null} [consumerName] Request consumerName
                 * @property {string|null} [dropoffInstructions] Request dropoffInstructions
                 * @property {boolean|null} [contactlessDropoff] Request contactlessDropoff
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request deliveryAddress.
                 * @member {waiternow.common.IAddressProto|null|undefined} deliveryAddress
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.deliveryAddress = null;

                /**
                 * Request phoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumber = null;

                /**
                 * Request email.
                 * @member {string} email
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.email = "";

                /**
                 * Request orderTime.
                 * @member {waiternow.common.IDateTimeProto|null|undefined} orderTime
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.orderTime = null;

                /**
                 * Request structuredMenuOrder.
                 * @member {waiternow.common.IStructuredMenuOrderProto|null|undefined} structuredMenuOrder
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.structuredMenuOrder = null;

                /**
                 * Request deliveryTip.
                 * @member {waiternow.common.IMoneyProto|null|undefined} deliveryTip
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.deliveryTip = null;

                /**
                 * Request consumerName.
                 * @member {string} consumerName
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.consumerName = "";

                /**
                 * Request dropoffInstructions.
                 * @member {string} dropoffInstructions
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.dropoffInstructions = "";

                /**
                 * Request contactlessDropoff.
                 * @member {boolean} contactlessDropoff
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 */
                Request.prototype.contactlessDropoff = false;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetDeliveryQuoteActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.deliveryAddress != null && Object.hasOwnProperty.call(message, "deliveryAddress"))
                        $root.waiternow.common.AddressProto.encode(message.deliveryAddress, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.email);
                    if (message.orderTime != null && Object.hasOwnProperty.call(message, "orderTime"))
                        $root.waiternow.common.DateTimeProto.encode(message.orderTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.structuredMenuOrder != null && Object.hasOwnProperty.call(message, "structuredMenuOrder"))
                        $root.waiternow.common.StructuredMenuOrderProto.encode(message.structuredMenuOrder, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.deliveryTip != null && Object.hasOwnProperty.call(message, "deliveryTip"))
                        $root.waiternow.common.MoneyProto.encode(message.deliveryTip, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.consumerName != null && Object.hasOwnProperty.call(message, "consumerName"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.consumerName);
                    if (message.dropoffInstructions != null && Object.hasOwnProperty.call(message, "dropoffInstructions"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.dropoffInstructions);
                    if (message.contactlessDropoff != null && Object.hasOwnProperty.call(message, "contactlessDropoff"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.contactlessDropoff);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetDeliveryQuoteActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetDeliveryQuoteActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.deliveryAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.email = reader.string();
                                break;
                            }
                        case 5: {
                                message.orderTime = $root.waiternow.common.DateTimeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.deliveryTip = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.consumerName = reader.string();
                                break;
                            }
                        case 9: {
                                message.dropoffInstructions = reader.string();
                                break;
                            }
                        case 10: {
                                message.contactlessDropoff = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.deliveryAddress);
                        if (error)
                            return "deliveryAddress." + error;
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.orderTime != null && message.hasOwnProperty("orderTime")) {
                        var error = $root.waiternow.common.DateTimeProto.verify(message.orderTime);
                        if (error)
                            return "orderTime." + error;
                    }
                    if (message.structuredMenuOrder != null && message.hasOwnProperty("structuredMenuOrder")) {
                        var error = $root.waiternow.common.StructuredMenuOrderProto.verify(message.structuredMenuOrder);
                        if (error)
                            return "structuredMenuOrder." + error;
                    }
                    if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.deliveryTip);
                        if (error)
                            return "deliveryTip." + error;
                    }
                    if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                        if (!$util.isString(message.consumerName))
                            return "consumerName: string expected";
                    if (message.dropoffInstructions != null && message.hasOwnProperty("dropoffInstructions"))
                        if (!$util.isString(message.dropoffInstructions))
                            return "dropoffInstructions: string expected";
                    if (message.contactlessDropoff != null && message.hasOwnProperty("contactlessDropoff"))
                        if (typeof message.contactlessDropoff !== "boolean")
                            return "contactlessDropoff: boolean expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetDeliveryQuoteActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetDeliveryQuoteActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.deliveryAddress != null) {
                        if (typeof object.deliveryAddress !== "object")
                            throw TypeError(".waiternow.common.GetDeliveryQuoteActionProto.Request.deliveryAddress: object expected");
                        message.deliveryAddress = $root.waiternow.common.AddressProto.fromObject(object.deliveryAddress);
                    }
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".waiternow.common.GetDeliveryQuoteActionProto.Request.phoneNumber: object expected");
                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                    }
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.orderTime != null) {
                        if (typeof object.orderTime !== "object")
                            throw TypeError(".waiternow.common.GetDeliveryQuoteActionProto.Request.orderTime: object expected");
                        message.orderTime = $root.waiternow.common.DateTimeProto.fromObject(object.orderTime);
                    }
                    if (object.structuredMenuOrder != null) {
                        if (typeof object.structuredMenuOrder !== "object")
                            throw TypeError(".waiternow.common.GetDeliveryQuoteActionProto.Request.structuredMenuOrder: object expected");
                        message.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.fromObject(object.structuredMenuOrder);
                    }
                    if (object.deliveryTip != null) {
                        if (typeof object.deliveryTip !== "object")
                            throw TypeError(".waiternow.common.GetDeliveryQuoteActionProto.Request.deliveryTip: object expected");
                        message.deliveryTip = $root.waiternow.common.MoneyProto.fromObject(object.deliveryTip);
                    }
                    if (object.consumerName != null)
                        message.consumerName = String(object.consumerName);
                    if (object.dropoffInstructions != null)
                        message.dropoffInstructions = String(object.dropoffInstructions);
                    if (object.contactlessDropoff != null)
                        message.contactlessDropoff = Boolean(object.contactlessDropoff);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.deliveryAddress = null;
                        object.phoneNumber = null;
                        object.email = "";
                        object.orderTime = null;
                        object.structuredMenuOrder = null;
                        object.deliveryTip = null;
                        object.consumerName = "";
                        object.dropoffInstructions = "";
                        object.contactlessDropoff = false;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress"))
                        object.deliveryAddress = $root.waiternow.common.AddressProto.toObject(message.deliveryAddress, options);
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.orderTime != null && message.hasOwnProperty("orderTime"))
                        object.orderTime = $root.waiternow.common.DateTimeProto.toObject(message.orderTime, options);
                    if (message.structuredMenuOrder != null && message.hasOwnProperty("structuredMenuOrder"))
                        object.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.toObject(message.structuredMenuOrder, options);
                    if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip"))
                        object.deliveryTip = $root.waiternow.common.MoneyProto.toObject(message.deliveryTip, options);
                    if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                        object.consumerName = message.consumerName;
                    if (message.dropoffInstructions != null && message.hasOwnProperty("dropoffInstructions"))
                        object.dropoffInstructions = message.dropoffInstructions;
                    if (message.contactlessDropoff != null && message.hasOwnProperty("contactlessDropoff"))
                        object.contactlessDropoff = message.contactlessDropoff;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetDeliveryQuoteActionProto.Request";
                };

                return Request;
            })();

            GetDeliveryQuoteActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IDeliveryProto|null} [delivery] Response delivery
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response delivery.
                 * @member {waiternow.common.IDeliveryProto|null|undefined} delivery
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @instance
                 */
                Response.prototype.delivery = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetDeliveryQuoteActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.delivery != null && Object.hasOwnProperty.call(message, "delivery"))
                        $root.waiternow.common.DeliveryProto.encode(message.delivery, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetDeliveryQuoteActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetDeliveryQuoteActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.delivery = $root.waiternow.common.DeliveryProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.delivery != null && message.hasOwnProperty("delivery")) {
                        var error = $root.waiternow.common.DeliveryProto.verify(message.delivery);
                        if (error)
                            return "delivery." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetDeliveryQuoteActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetDeliveryQuoteActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetDeliveryQuoteActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetDeliveryQuoteActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.delivery != null) {
                        if (typeof object.delivery !== "object")
                            throw TypeError(".waiternow.common.GetDeliveryQuoteActionProto.Response.delivery: object expected");
                        message.delivery = $root.waiternow.common.DeliveryProto.fromObject(object.delivery);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDeliveryQuoteActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.delivery = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.delivery != null && message.hasOwnProperty("delivery"))
                        object.delivery = $root.waiternow.common.DeliveryProto.toObject(message.delivery, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetDeliveryQuoteActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetDeliveryQuoteActionProto.Response";
                };

                return Response;
            })();

            return GetDeliveryQuoteActionProto;
        })();

        common.DeliveryProto = (function() {

            /**
             * Properties of a DeliveryProto.
             * @memberof waiternow.common
             * @interface IDeliveryProto
             * @property {string|null} [id] DeliveryProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] DeliveryProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] DeliveryProto lastUpdateTime
             * @property {string|null} [locationId] DeliveryProto locationId
             * @property {string|null} [businessName] DeliveryProto businessName
             * @property {waiternow.common.IAddressProto|null} [locationAddress] DeliveryProto locationAddress
             * @property {waiternow.common.IPhoneNumberProto|null} [locationPhoneNumber] DeliveryProto locationPhoneNumber
             * @property {waiternow.common.IAddressProto|null} [deliveryAddress] DeliveryProto deliveryAddress
             * @property {waiternow.common.IPhoneNumberProto|null} [consumerPhoneNumber] DeliveryProto consumerPhoneNumber
             * @property {string|null} [consumerEmail] DeliveryProto consumerEmail
             * @property {google.protobuf.ITimestamp|null} [requestedPickupTime] DeliveryProto requestedPickupTime
             * @property {google.protobuf.ITimestamp|null} [requestedDropoffTime] DeliveryProto requestedDropoffTime
             * @property {string|null} [deliveryCarrierId] DeliveryProto deliveryCarrierId
             * @property {waiternow.common.DeliveryCarrier|null} [deliveryCarrier] DeliveryProto deliveryCarrier
             * @property {waiternow.common.IDistanceQuantityProto|null} [distance] DeliveryProto distance
             * @property {waiternow.common.IMoneyProto|null} [fee] DeliveryProto fee
             * @property {google.protobuf.IDuration|null} [quoteExpiration] DeliveryProto quoteExpiration
             * @property {waiternow.common.DeliveryProto.Status|null} [status] DeliveryProto status
             * @property {string|null} [trackingUrl] DeliveryProto trackingUrl
             * @property {boolean|null} [containsAlcohol] DeliveryProto containsAlcohol
             * @property {google.protobuf.ITimestamp|null} [estimatedPickupTime] DeliveryProto estimatedPickupTime
             * @property {google.protobuf.ITimestamp|null} [estimatedDropoffTime] DeliveryProto estimatedDropoffTime
             * @property {waiternow.common.IMoneyProto|null} [deliveryTip] DeliveryProto deliveryTip
             * @property {string|null} [consumerName] DeliveryProto consumerName
             * @property {string|null} [dropoffInstructions] DeliveryProto dropoffInstructions
             * @property {boolean|null} [contactlessDropoff] DeliveryProto contactlessDropoff
             */

            /**
             * Constructs a new DeliveryProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeliveryProto.
             * @implements IDeliveryProto
             * @constructor
             * @param {waiternow.common.IDeliveryProto=} [properties] Properties to set
             */
            function DeliveryProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeliveryProto id.
             * @member {string} id
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.id = "";

            /**
             * DeliveryProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.creationTime = null;

            /**
             * DeliveryProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.lastUpdateTime = null;

            /**
             * DeliveryProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.locationId = "";

            /**
             * DeliveryProto businessName.
             * @member {string} businessName
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.businessName = "";

            /**
             * DeliveryProto locationAddress.
             * @member {waiternow.common.IAddressProto|null|undefined} locationAddress
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.locationAddress = null;

            /**
             * DeliveryProto locationPhoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} locationPhoneNumber
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.locationPhoneNumber = null;

            /**
             * DeliveryProto deliveryAddress.
             * @member {waiternow.common.IAddressProto|null|undefined} deliveryAddress
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.deliveryAddress = null;

            /**
             * DeliveryProto consumerPhoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} consumerPhoneNumber
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.consumerPhoneNumber = null;

            /**
             * DeliveryProto consumerEmail.
             * @member {string} consumerEmail
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.consumerEmail = "";

            /**
             * DeliveryProto requestedPickupTime.
             * @member {google.protobuf.ITimestamp|null|undefined} requestedPickupTime
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.requestedPickupTime = null;

            /**
             * DeliveryProto requestedDropoffTime.
             * @member {google.protobuf.ITimestamp|null|undefined} requestedDropoffTime
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.requestedDropoffTime = null;

            /**
             * DeliveryProto deliveryCarrierId.
             * @member {string} deliveryCarrierId
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.deliveryCarrierId = "";

            /**
             * DeliveryProto deliveryCarrier.
             * @member {waiternow.common.DeliveryCarrier} deliveryCarrier
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.deliveryCarrier = 0;

            /**
             * DeliveryProto distance.
             * @member {waiternow.common.IDistanceQuantityProto|null|undefined} distance
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.distance = null;

            /**
             * DeliveryProto fee.
             * @member {waiternow.common.IMoneyProto|null|undefined} fee
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.fee = null;

            /**
             * DeliveryProto quoteExpiration.
             * @member {google.protobuf.IDuration|null|undefined} quoteExpiration
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.quoteExpiration = null;

            /**
             * DeliveryProto status.
             * @member {waiternow.common.DeliveryProto.Status} status
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.status = 0;

            /**
             * DeliveryProto trackingUrl.
             * @member {string} trackingUrl
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.trackingUrl = "";

            /**
             * DeliveryProto containsAlcohol.
             * @member {boolean} containsAlcohol
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.containsAlcohol = false;

            /**
             * DeliveryProto estimatedPickupTime.
             * @member {google.protobuf.ITimestamp|null|undefined} estimatedPickupTime
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.estimatedPickupTime = null;

            /**
             * DeliveryProto estimatedDropoffTime.
             * @member {google.protobuf.ITimestamp|null|undefined} estimatedDropoffTime
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.estimatedDropoffTime = null;

            /**
             * DeliveryProto deliveryTip.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryTip
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.deliveryTip = null;

            /**
             * DeliveryProto consumerName.
             * @member {string} consumerName
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.consumerName = "";

            /**
             * DeliveryProto dropoffInstructions.
             * @member {string} dropoffInstructions
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.dropoffInstructions = "";

            /**
             * DeliveryProto contactlessDropoff.
             * @member {boolean} contactlessDropoff
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            DeliveryProto.prototype.contactlessDropoff = false;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * DeliveryProto pickupOrDropoffTimeRequest.
             * @member {"requestedPickupTime"|"requestedDropoffTime"|undefined} pickupOrDropoffTimeRequest
             * @memberof waiternow.common.DeliveryProto
             * @instance
             */
            Object.defineProperty(DeliveryProto.prototype, "pickupOrDropoffTimeRequest", {
                get: $util.oneOfGetter($oneOfFields = ["requestedPickupTime", "requestedDropoffTime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new DeliveryProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {waiternow.common.IDeliveryProto=} [properties] Properties to set
             * @returns {waiternow.common.DeliveryProto} DeliveryProto instance
             */
            DeliveryProto.create = function create(properties) {
                return new DeliveryProto(properties);
            };

            /**
             * Encodes the specified DeliveryProto message. Does not implicitly {@link waiternow.common.DeliveryProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {waiternow.common.IDeliveryProto} message DeliveryProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.locationId);
                if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.businessName);
                if (message.locationAddress != null && Object.hasOwnProperty.call(message, "locationAddress"))
                    $root.waiternow.common.AddressProto.encode(message.locationAddress, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.locationPhoneNumber != null && Object.hasOwnProperty.call(message, "locationPhoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.locationPhoneNumber, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.deliveryAddress != null && Object.hasOwnProperty.call(message, "deliveryAddress"))
                    $root.waiternow.common.AddressProto.encode(message.deliveryAddress, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.consumerPhoneNumber != null && Object.hasOwnProperty.call(message, "consumerPhoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.consumerPhoneNumber, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.consumerEmail != null && Object.hasOwnProperty.call(message, "consumerEmail"))
                    writer.uint32(/* id 10, wireType 2 =*/82).string(message.consumerEmail);
                if (message.requestedPickupTime != null && Object.hasOwnProperty.call(message, "requestedPickupTime"))
                    $root.google.protobuf.Timestamp.encode(message.requestedPickupTime, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.requestedDropoffTime != null && Object.hasOwnProperty.call(message, "requestedDropoffTime"))
                    $root.google.protobuf.Timestamp.encode(message.requestedDropoffTime, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.deliveryCarrierId != null && Object.hasOwnProperty.call(message, "deliveryCarrierId"))
                    writer.uint32(/* id 13, wireType 2 =*/106).string(message.deliveryCarrierId);
                if (message.deliveryCarrier != null && Object.hasOwnProperty.call(message, "deliveryCarrier"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.deliveryCarrier);
                if (message.distance != null && Object.hasOwnProperty.call(message, "distance"))
                    $root.waiternow.common.DistanceQuantityProto.encode(message.distance, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.fee != null && Object.hasOwnProperty.call(message, "fee"))
                    $root.waiternow.common.MoneyProto.encode(message.fee, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.quoteExpiration != null && Object.hasOwnProperty.call(message, "quoteExpiration"))
                    $root.google.protobuf.Duration.encode(message.quoteExpiration, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int32(message.status);
                if (message.trackingUrl != null && Object.hasOwnProperty.call(message, "trackingUrl"))
                    writer.uint32(/* id 19, wireType 2 =*/154).string(message.trackingUrl);
                if (message.containsAlcohol != null && Object.hasOwnProperty.call(message, "containsAlcohol"))
                    writer.uint32(/* id 20, wireType 0 =*/160).bool(message.containsAlcohol);
                if (message.estimatedPickupTime != null && Object.hasOwnProperty.call(message, "estimatedPickupTime"))
                    $root.google.protobuf.Timestamp.encode(message.estimatedPickupTime, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                if (message.estimatedDropoffTime != null && Object.hasOwnProperty.call(message, "estimatedDropoffTime"))
                    $root.google.protobuf.Timestamp.encode(message.estimatedDropoffTime, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
                if (message.deliveryTip != null && Object.hasOwnProperty.call(message, "deliveryTip"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryTip, writer.uint32(/* id 23, wireType 2 =*/186).fork()).ldelim();
                if (message.consumerName != null && Object.hasOwnProperty.call(message, "consumerName"))
                    writer.uint32(/* id 25, wireType 2 =*/202).string(message.consumerName);
                if (message.dropoffInstructions != null && Object.hasOwnProperty.call(message, "dropoffInstructions"))
                    writer.uint32(/* id 26, wireType 2 =*/210).string(message.dropoffInstructions);
                if (message.contactlessDropoff != null && Object.hasOwnProperty.call(message, "contactlessDropoff"))
                    writer.uint32(/* id 27, wireType 0 =*/216).bool(message.contactlessDropoff);
                return writer;
            };

            /**
             * Encodes the specified DeliveryProto message, length delimited. Does not implicitly {@link waiternow.common.DeliveryProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {waiternow.common.IDeliveryProto} message DeliveryProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeliveryProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeliveryProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeliveryProto} DeliveryProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeliveryProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 5: {
                            message.businessName = reader.string();
                            break;
                        }
                    case 6: {
                            message.locationAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.deliveryAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.consumerPhoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.consumerEmail = reader.string();
                            break;
                        }
                    case 11: {
                            message.requestedPickupTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.requestedDropoffTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.deliveryCarrierId = reader.string();
                            break;
                        }
                    case 14: {
                            message.deliveryCarrier = reader.int32();
                            break;
                        }
                    case 15: {
                            message.distance = $root.waiternow.common.DistanceQuantityProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.fee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.quoteExpiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.status = reader.int32();
                            break;
                        }
                    case 19: {
                            message.trackingUrl = reader.string();
                            break;
                        }
                    case 20: {
                            message.containsAlcohol = reader.bool();
                            break;
                        }
                    case 21: {
                            message.estimatedPickupTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 22: {
                            message.estimatedDropoffTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 23: {
                            message.deliveryTip = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 25: {
                            message.consumerName = reader.string();
                            break;
                        }
                    case 26: {
                            message.dropoffInstructions = reader.string();
                            break;
                        }
                    case 27: {
                            message.contactlessDropoff = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeliveryProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeliveryProto} DeliveryProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeliveryProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeliveryProto message.
             * @function verify
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeliveryProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    if (!$util.isString(message.businessName))
                        return "businessName: string expected";
                if (message.locationAddress != null && message.hasOwnProperty("locationAddress")) {
                    var error = $root.waiternow.common.AddressProto.verify(message.locationAddress);
                    if (error)
                        return "locationAddress." + error;
                }
                if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.locationPhoneNumber);
                    if (error)
                        return "locationPhoneNumber." + error;
                }
                if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress")) {
                    var error = $root.waiternow.common.AddressProto.verify(message.deliveryAddress);
                    if (error)
                        return "deliveryAddress." + error;
                }
                if (message.consumerPhoneNumber != null && message.hasOwnProperty("consumerPhoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.consumerPhoneNumber);
                    if (error)
                        return "consumerPhoneNumber." + error;
                }
                if (message.consumerEmail != null && message.hasOwnProperty("consumerEmail"))
                    if (!$util.isString(message.consumerEmail))
                        return "consumerEmail: string expected";
                if (message.requestedPickupTime != null && message.hasOwnProperty("requestedPickupTime")) {
                    properties.pickupOrDropoffTimeRequest = 1;
                    {
                        var error = $root.google.protobuf.Timestamp.verify(message.requestedPickupTime);
                        if (error)
                            return "requestedPickupTime." + error;
                    }
                }
                if (message.requestedDropoffTime != null && message.hasOwnProperty("requestedDropoffTime")) {
                    if (properties.pickupOrDropoffTimeRequest === 1)
                        return "pickupOrDropoffTimeRequest: multiple values";
                    properties.pickupOrDropoffTimeRequest = 1;
                    {
                        var error = $root.google.protobuf.Timestamp.verify(message.requestedDropoffTime);
                        if (error)
                            return "requestedDropoffTime." + error;
                    }
                }
                if (message.deliveryCarrierId != null && message.hasOwnProperty("deliveryCarrierId"))
                    if (!$util.isString(message.deliveryCarrierId))
                        return "deliveryCarrierId: string expected";
                if (message.deliveryCarrier != null && message.hasOwnProperty("deliveryCarrier"))
                    switch (message.deliveryCarrier) {
                    default:
                        return "deliveryCarrier: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.distance != null && message.hasOwnProperty("distance")) {
                    var error = $root.waiternow.common.DistanceQuantityProto.verify(message.distance);
                    if (error)
                        return "distance." + error;
                }
                if (message.fee != null && message.hasOwnProperty("fee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.fee);
                    if (error)
                        return "fee." + error;
                }
                if (message.quoteExpiration != null && message.hasOwnProperty("quoteExpiration")) {
                    var error = $root.google.protobuf.Duration.verify(message.quoteExpiration);
                    if (error)
                        return "quoteExpiration." + error;
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    switch (message.status) {
                    default:
                        return "status: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                        break;
                    }
                if (message.trackingUrl != null && message.hasOwnProperty("trackingUrl"))
                    if (!$util.isString(message.trackingUrl))
                        return "trackingUrl: string expected";
                if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                    if (typeof message.containsAlcohol !== "boolean")
                        return "containsAlcohol: boolean expected";
                if (message.estimatedPickupTime != null && message.hasOwnProperty("estimatedPickupTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.estimatedPickupTime);
                    if (error)
                        return "estimatedPickupTime." + error;
                }
                if (message.estimatedDropoffTime != null && message.hasOwnProperty("estimatedDropoffTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.estimatedDropoffTime);
                    if (error)
                        return "estimatedDropoffTime." + error;
                }
                if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryTip);
                    if (error)
                        return "deliveryTip." + error;
                }
                if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                    if (!$util.isString(message.consumerName))
                        return "consumerName: string expected";
                if (message.dropoffInstructions != null && message.hasOwnProperty("dropoffInstructions"))
                    if (!$util.isString(message.dropoffInstructions))
                        return "dropoffInstructions: string expected";
                if (message.contactlessDropoff != null && message.hasOwnProperty("contactlessDropoff"))
                    if (typeof message.contactlessDropoff !== "boolean")
                        return "contactlessDropoff: boolean expected";
                return null;
            };

            /**
             * Creates a DeliveryProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeliveryProto} DeliveryProto
             */
            DeliveryProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeliveryProto)
                    return object;
                var message = new $root.waiternow.common.DeliveryProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.businessName != null)
                    message.businessName = String(object.businessName);
                if (object.locationAddress != null) {
                    if (typeof object.locationAddress !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.locationAddress: object expected");
                    message.locationAddress = $root.waiternow.common.AddressProto.fromObject(object.locationAddress);
                }
                if (object.locationPhoneNumber != null) {
                    if (typeof object.locationPhoneNumber !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.locationPhoneNumber: object expected");
                    message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.locationPhoneNumber);
                }
                if (object.deliveryAddress != null) {
                    if (typeof object.deliveryAddress !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.deliveryAddress: object expected");
                    message.deliveryAddress = $root.waiternow.common.AddressProto.fromObject(object.deliveryAddress);
                }
                if (object.consumerPhoneNumber != null) {
                    if (typeof object.consumerPhoneNumber !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.consumerPhoneNumber: object expected");
                    message.consumerPhoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.consumerPhoneNumber);
                }
                if (object.consumerEmail != null)
                    message.consumerEmail = String(object.consumerEmail);
                if (object.requestedPickupTime != null) {
                    if (typeof object.requestedPickupTime !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.requestedPickupTime: object expected");
                    message.requestedPickupTime = $root.google.protobuf.Timestamp.fromObject(object.requestedPickupTime);
                }
                if (object.requestedDropoffTime != null) {
                    if (typeof object.requestedDropoffTime !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.requestedDropoffTime: object expected");
                    message.requestedDropoffTime = $root.google.protobuf.Timestamp.fromObject(object.requestedDropoffTime);
                }
                if (object.deliveryCarrierId != null)
                    message.deliveryCarrierId = String(object.deliveryCarrierId);
                switch (object.deliveryCarrier) {
                default:
                    if (typeof object.deliveryCarrier === "number") {
                        message.deliveryCarrier = object.deliveryCarrier;
                        break;
                    }
                    break;
                case "DELIVERY_CARRIER_UNSPECIFIED":
                case 0:
                    message.deliveryCarrier = 0;
                    break;
                case "SELF":
                case 1:
                    message.deliveryCarrier = 1;
                    break;
                case "DOORDASH":
                case 2:
                    message.deliveryCarrier = 2;
                    break;
                }
                if (object.distance != null) {
                    if (typeof object.distance !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.distance: object expected");
                    message.distance = $root.waiternow.common.DistanceQuantityProto.fromObject(object.distance);
                }
                if (object.fee != null) {
                    if (typeof object.fee !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.fee: object expected");
                    message.fee = $root.waiternow.common.MoneyProto.fromObject(object.fee);
                }
                if (object.quoteExpiration != null) {
                    if (typeof object.quoteExpiration !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.quoteExpiration: object expected");
                    message.quoteExpiration = $root.google.protobuf.Duration.fromObject(object.quoteExpiration);
                }
                switch (object.status) {
                default:
                    if (typeof object.status === "number") {
                        message.status = object.status;
                        break;
                    }
                    break;
                case "STATE_UNSPECIFIED":
                case 0:
                    message.status = 0;
                    break;
                case "QUOTE":
                case 1:
                    message.status = 1;
                    break;
                case "ACCEPTED":
                case 2:
                    message.status = 2;
                    break;
                case "FAILED":
                case 3:
                    message.status = 3;
                    break;
                case "CANCELED":
                case 4:
                    message.status = 4;
                    break;
                }
                if (object.trackingUrl != null)
                    message.trackingUrl = String(object.trackingUrl);
                if (object.containsAlcohol != null)
                    message.containsAlcohol = Boolean(object.containsAlcohol);
                if (object.estimatedPickupTime != null) {
                    if (typeof object.estimatedPickupTime !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.estimatedPickupTime: object expected");
                    message.estimatedPickupTime = $root.google.protobuf.Timestamp.fromObject(object.estimatedPickupTime);
                }
                if (object.estimatedDropoffTime != null) {
                    if (typeof object.estimatedDropoffTime !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.estimatedDropoffTime: object expected");
                    message.estimatedDropoffTime = $root.google.protobuf.Timestamp.fromObject(object.estimatedDropoffTime);
                }
                if (object.deliveryTip != null) {
                    if (typeof object.deliveryTip !== "object")
                        throw TypeError(".waiternow.common.DeliveryProto.deliveryTip: object expected");
                    message.deliveryTip = $root.waiternow.common.MoneyProto.fromObject(object.deliveryTip);
                }
                if (object.consumerName != null)
                    message.consumerName = String(object.consumerName);
                if (object.dropoffInstructions != null)
                    message.dropoffInstructions = String(object.dropoffInstructions);
                if (object.contactlessDropoff != null)
                    message.contactlessDropoff = Boolean(object.contactlessDropoff);
                return message;
            };

            /**
             * Creates a plain object from a DeliveryProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {waiternow.common.DeliveryProto} message DeliveryProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeliveryProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.locationId = "";
                    object.businessName = "";
                    object.locationAddress = null;
                    object.locationPhoneNumber = null;
                    object.deliveryAddress = null;
                    object.consumerPhoneNumber = null;
                    object.consumerEmail = "";
                    object.deliveryCarrierId = "";
                    object.deliveryCarrier = options.enums === String ? "DELIVERY_CARRIER_UNSPECIFIED" : 0;
                    object.distance = null;
                    object.fee = null;
                    object.quoteExpiration = null;
                    object.status = options.enums === String ? "STATE_UNSPECIFIED" : 0;
                    object.trackingUrl = "";
                    object.containsAlcohol = false;
                    object.estimatedPickupTime = null;
                    object.estimatedDropoffTime = null;
                    object.deliveryTip = null;
                    object.consumerName = "";
                    object.dropoffInstructions = "";
                    object.contactlessDropoff = false;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    object.businessName = message.businessName;
                if (message.locationAddress != null && message.hasOwnProperty("locationAddress"))
                    object.locationAddress = $root.waiternow.common.AddressProto.toObject(message.locationAddress, options);
                if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber"))
                    object.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.locationPhoneNumber, options);
                if (message.deliveryAddress != null && message.hasOwnProperty("deliveryAddress"))
                    object.deliveryAddress = $root.waiternow.common.AddressProto.toObject(message.deliveryAddress, options);
                if (message.consumerPhoneNumber != null && message.hasOwnProperty("consumerPhoneNumber"))
                    object.consumerPhoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.consumerPhoneNumber, options);
                if (message.consumerEmail != null && message.hasOwnProperty("consumerEmail"))
                    object.consumerEmail = message.consumerEmail;
                if (message.requestedPickupTime != null && message.hasOwnProperty("requestedPickupTime")) {
                    object.requestedPickupTime = $root.google.protobuf.Timestamp.toObject(message.requestedPickupTime, options);
                    if (options.oneofs)
                        object.pickupOrDropoffTimeRequest = "requestedPickupTime";
                }
                if (message.requestedDropoffTime != null && message.hasOwnProperty("requestedDropoffTime")) {
                    object.requestedDropoffTime = $root.google.protobuf.Timestamp.toObject(message.requestedDropoffTime, options);
                    if (options.oneofs)
                        object.pickupOrDropoffTimeRequest = "requestedDropoffTime";
                }
                if (message.deliveryCarrierId != null && message.hasOwnProperty("deliveryCarrierId"))
                    object.deliveryCarrierId = message.deliveryCarrierId;
                if (message.deliveryCarrier != null && message.hasOwnProperty("deliveryCarrier"))
                    object.deliveryCarrier = options.enums === String ? $root.waiternow.common.DeliveryCarrier[message.deliveryCarrier] === undefined ? message.deliveryCarrier : $root.waiternow.common.DeliveryCarrier[message.deliveryCarrier] : message.deliveryCarrier;
                if (message.distance != null && message.hasOwnProperty("distance"))
                    object.distance = $root.waiternow.common.DistanceQuantityProto.toObject(message.distance, options);
                if (message.fee != null && message.hasOwnProperty("fee"))
                    object.fee = $root.waiternow.common.MoneyProto.toObject(message.fee, options);
                if (message.quoteExpiration != null && message.hasOwnProperty("quoteExpiration"))
                    object.quoteExpiration = $root.google.protobuf.Duration.toObject(message.quoteExpiration, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = options.enums === String ? $root.waiternow.common.DeliveryProto.Status[message.status] === undefined ? message.status : $root.waiternow.common.DeliveryProto.Status[message.status] : message.status;
                if (message.trackingUrl != null && message.hasOwnProperty("trackingUrl"))
                    object.trackingUrl = message.trackingUrl;
                if (message.containsAlcohol != null && message.hasOwnProperty("containsAlcohol"))
                    object.containsAlcohol = message.containsAlcohol;
                if (message.estimatedPickupTime != null && message.hasOwnProperty("estimatedPickupTime"))
                    object.estimatedPickupTime = $root.google.protobuf.Timestamp.toObject(message.estimatedPickupTime, options);
                if (message.estimatedDropoffTime != null && message.hasOwnProperty("estimatedDropoffTime"))
                    object.estimatedDropoffTime = $root.google.protobuf.Timestamp.toObject(message.estimatedDropoffTime, options);
                if (message.deliveryTip != null && message.hasOwnProperty("deliveryTip"))
                    object.deliveryTip = $root.waiternow.common.MoneyProto.toObject(message.deliveryTip, options);
                if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                    object.consumerName = message.consumerName;
                if (message.dropoffInstructions != null && message.hasOwnProperty("dropoffInstructions"))
                    object.dropoffInstructions = message.dropoffInstructions;
                if (message.contactlessDropoff != null && message.hasOwnProperty("contactlessDropoff"))
                    object.contactlessDropoff = message.contactlessDropoff;
                return object;
            };

            /**
             * Converts this DeliveryProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeliveryProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeliveryProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeliveryProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeliveryProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeliveryProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeliveryProto";
            };

            /**
             * Status enum.
             * @name waiternow.common.DeliveryProto.Status
             * @enum {number}
             * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
             * @property {number} QUOTE=1 QUOTE value
             * @property {number} ACCEPTED=2 ACCEPTED value
             * @property {number} FAILED=3 FAILED value
             * @property {number} CANCELED=4 CANCELED value
             */
            DeliveryProto.Status = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "QUOTE"] = 1;
                values[valuesById[2] = "ACCEPTED"] = 2;
                values[valuesById[3] = "FAILED"] = 3;
                values[valuesById[4] = "CANCELED"] = 4;
                return values;
            })();

            return DeliveryProto;
        })();

        common.OrderProto = (function() {

            /**
             * Properties of an OrderProto.
             * @memberof waiternow.common
             * @interface IOrderProto
             * @property {string|null} [id] OrderProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] OrderProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] OrderProto lastUpdateTime
             * @property {string|null} [pointOfServiceId] OrderProto pointOfServiceId
             * @property {string|null} [consumerId] OrderProto consumerId
             * @property {string|null} [deviceId] OrderProto deviceId
             * @property {waiternow.common.OrderProto.IWaiterRequestProto|null} [waiterRequest] OrderProto waiterRequest
             * @property {waiternow.common.IStructuredMenuOrderProto|null} [structuredMenuOrder] OrderProto structuredMenuOrder
             * @property {waiternow.common.OrderProto.IStatusProto|null} [status] OrderProto status
             * @property {waiternow.common.OrderProto.IRedundantDataProto|null} [redundantData] OrderProto redundantData
             * @property {number|null} [orderNumber] OrderProto orderNumber
             * @property {waiternow.common.OrderProto.IMetadataProto|null} [metadata] OrderProto metadata
             * @property {waiternow.common.IDateTimeProto|null} [orderTime] OrderProto orderTime
             * @property {waiternow.common.IDeliveryProto|null} [delivery] OrderProto delivery
             * @property {waiternow.common.IDeliveryProto|null} [retriedDelivery] OrderProto retriedDelivery
             */

            /**
             * Constructs a new OrderProto.
             * @memberof waiternow.common
             * @classdesc Represents an OrderProto.
             * @implements IOrderProto
             * @constructor
             * @param {waiternow.common.IOrderProto=} [properties] Properties to set
             */
            function OrderProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderProto id.
             * @member {string} id
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.id = "";

            /**
             * OrderProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.creationTime = null;

            /**
             * OrderProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.lastUpdateTime = null;

            /**
             * OrderProto pointOfServiceId.
             * @member {string} pointOfServiceId
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.pointOfServiceId = "";

            /**
             * OrderProto consumerId.
             * @member {string} consumerId
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.consumerId = "";

            /**
             * OrderProto deviceId.
             * @member {string} deviceId
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.deviceId = "";

            /**
             * OrderProto waiterRequest.
             * @member {waiternow.common.OrderProto.IWaiterRequestProto|null|undefined} waiterRequest
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.waiterRequest = null;

            /**
             * OrderProto structuredMenuOrder.
             * @member {waiternow.common.IStructuredMenuOrderProto|null|undefined} structuredMenuOrder
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.structuredMenuOrder = null;

            /**
             * OrderProto status.
             * @member {waiternow.common.OrderProto.IStatusProto|null|undefined} status
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.status = null;

            /**
             * OrderProto redundantData.
             * @member {waiternow.common.OrderProto.IRedundantDataProto|null|undefined} redundantData
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.redundantData = null;

            /**
             * OrderProto orderNumber.
             * @member {number} orderNumber
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.orderNumber = 0;

            /**
             * OrderProto metadata.
             * @member {waiternow.common.OrderProto.IMetadataProto|null|undefined} metadata
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.metadata = null;

            /**
             * OrderProto orderTime.
             * @member {waiternow.common.IDateTimeProto|null|undefined} orderTime
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.orderTime = null;

            /**
             * OrderProto delivery.
             * @member {waiternow.common.IDeliveryProto|null|undefined} delivery
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.delivery = null;

            /**
             * OrderProto retriedDelivery.
             * @member {waiternow.common.IDeliveryProto|null|undefined} retriedDelivery
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            OrderProto.prototype.retriedDelivery = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * OrderProto orderType.
             * @member {"waiterRequest"|"structuredMenuOrder"|undefined} orderType
             * @memberof waiternow.common.OrderProto
             * @instance
             */
            Object.defineProperty(OrderProto.prototype, "orderType", {
                get: $util.oneOfGetter($oneOfFields = ["waiterRequest", "structuredMenuOrder"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new OrderProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {waiternow.common.IOrderProto=} [properties] Properties to set
             * @returns {waiternow.common.OrderProto} OrderProto instance
             */
            OrderProto.create = function create(properties) {
                return new OrderProto(properties);
            };

            /**
             * Encodes the specified OrderProto message. Does not implicitly {@link waiternow.common.OrderProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {waiternow.common.IOrderProto} message OrderProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.pointOfServiceId);
                if (message.consumerId != null && Object.hasOwnProperty.call(message, "consumerId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.consumerId);
                if (message.waiterRequest != null && Object.hasOwnProperty.call(message, "waiterRequest"))
                    $root.waiternow.common.OrderProto.WaiterRequestProto.encode(message.waiterRequest, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.structuredMenuOrder != null && Object.hasOwnProperty.call(message, "structuredMenuOrder"))
                    $root.waiternow.common.StructuredMenuOrderProto.encode(message.structuredMenuOrder, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.waiternow.common.OrderProto.StatusProto.encode(message.status, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.redundantData != null && Object.hasOwnProperty.call(message, "redundantData"))
                    $root.waiternow.common.OrderProto.RedundantDataProto.encode(message.redundantData, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                    writer.uint32(/* id 9, wireType 2 =*/74).string(message.deviceId);
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.orderNumber != null && Object.hasOwnProperty.call(message, "orderNumber"))
                    writer.uint32(/* id 11, wireType 0 =*/88).int32(message.orderNumber);
                if (message.metadata != null && Object.hasOwnProperty.call(message, "metadata"))
                    $root.waiternow.common.OrderProto.MetadataProto.encode(message.metadata, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.orderTime != null && Object.hasOwnProperty.call(message, "orderTime"))
                    $root.waiternow.common.DateTimeProto.encode(message.orderTime, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.delivery != null && Object.hasOwnProperty.call(message, "delivery"))
                    $root.waiternow.common.DeliveryProto.encode(message.delivery, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.retriedDelivery != null && Object.hasOwnProperty.call(message, "retriedDelivery"))
                    $root.waiternow.common.DeliveryProto.encode(message.retriedDelivery, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OrderProto message, length delimited. Does not implicitly {@link waiternow.common.OrderProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {waiternow.common.IOrderProto} message OrderProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrderProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OrderProto} OrderProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 7: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.pointOfServiceId = reader.string();
                            break;
                        }
                    case 3: {
                            message.consumerId = reader.string();
                            break;
                        }
                    case 9: {
                            message.deviceId = reader.string();
                            break;
                        }
                    case 4: {
                            message.waiterRequest = $root.waiternow.common.OrderProto.WaiterRequestProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.status = $root.waiternow.common.OrderProto.StatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.redundantData = $root.waiternow.common.OrderProto.RedundantDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.orderNumber = reader.int32();
                            break;
                        }
                    case 12: {
                            message.metadata = $root.waiternow.common.OrderProto.MetadataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.orderTime = $root.waiternow.common.DateTimeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.delivery = $root.waiternow.common.DeliveryProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.retriedDelivery = $root.waiternow.common.DeliveryProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrderProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OrderProto} OrderProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrderProto message.
             * @function verify
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    if (!$util.isString(message.pointOfServiceId))
                        return "pointOfServiceId: string expected";
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    if (!$util.isString(message.consumerId))
                        return "consumerId: string expected";
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    if (!$util.isString(message.deviceId))
                        return "deviceId: string expected";
                if (message.waiterRequest != null && message.hasOwnProperty("waiterRequest")) {
                    properties.orderType = 1;
                    {
                        var error = $root.waiternow.common.OrderProto.WaiterRequestProto.verify(message.waiterRequest);
                        if (error)
                            return "waiterRequest." + error;
                    }
                }
                if (message.structuredMenuOrder != null && message.hasOwnProperty("structuredMenuOrder")) {
                    if (properties.orderType === 1)
                        return "orderType: multiple values";
                    properties.orderType = 1;
                    {
                        var error = $root.waiternow.common.StructuredMenuOrderProto.verify(message.structuredMenuOrder);
                        if (error)
                            return "structuredMenuOrder." + error;
                    }
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    var error = $root.waiternow.common.OrderProto.StatusProto.verify(message.status);
                    if (error)
                        return "status." + error;
                }
                if (message.redundantData != null && message.hasOwnProperty("redundantData")) {
                    var error = $root.waiternow.common.OrderProto.RedundantDataProto.verify(message.redundantData);
                    if (error)
                        return "redundantData." + error;
                }
                if (message.orderNumber != null && message.hasOwnProperty("orderNumber"))
                    if (!$util.isInteger(message.orderNumber))
                        return "orderNumber: integer expected";
                if (message.metadata != null && message.hasOwnProperty("metadata")) {
                    var error = $root.waiternow.common.OrderProto.MetadataProto.verify(message.metadata);
                    if (error)
                        return "metadata." + error;
                }
                if (message.orderTime != null && message.hasOwnProperty("orderTime")) {
                    var error = $root.waiternow.common.DateTimeProto.verify(message.orderTime);
                    if (error)
                        return "orderTime." + error;
                }
                if (message.delivery != null && message.hasOwnProperty("delivery")) {
                    var error = $root.waiternow.common.DeliveryProto.verify(message.delivery);
                    if (error)
                        return "delivery." + error;
                }
                if (message.retriedDelivery != null && message.hasOwnProperty("retriedDelivery")) {
                    var error = $root.waiternow.common.DeliveryProto.verify(message.retriedDelivery);
                    if (error)
                        return "retriedDelivery." + error;
                }
                return null;
            };

            /**
             * Creates an OrderProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OrderProto} OrderProto
             */
            OrderProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OrderProto)
                    return object;
                var message = new $root.waiternow.common.OrderProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.OrderProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.OrderProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.pointOfServiceId != null)
                    message.pointOfServiceId = String(object.pointOfServiceId);
                if (object.consumerId != null)
                    message.consumerId = String(object.consumerId);
                if (object.deviceId != null)
                    message.deviceId = String(object.deviceId);
                if (object.waiterRequest != null) {
                    if (typeof object.waiterRequest !== "object")
                        throw TypeError(".waiternow.common.OrderProto.waiterRequest: object expected");
                    message.waiterRequest = $root.waiternow.common.OrderProto.WaiterRequestProto.fromObject(object.waiterRequest);
                }
                if (object.structuredMenuOrder != null) {
                    if (typeof object.structuredMenuOrder !== "object")
                        throw TypeError(".waiternow.common.OrderProto.structuredMenuOrder: object expected");
                    message.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.fromObject(object.structuredMenuOrder);
                }
                if (object.status != null) {
                    if (typeof object.status !== "object")
                        throw TypeError(".waiternow.common.OrderProto.status: object expected");
                    message.status = $root.waiternow.common.OrderProto.StatusProto.fromObject(object.status);
                }
                if (object.redundantData != null) {
                    if (typeof object.redundantData !== "object")
                        throw TypeError(".waiternow.common.OrderProto.redundantData: object expected");
                    message.redundantData = $root.waiternow.common.OrderProto.RedundantDataProto.fromObject(object.redundantData);
                }
                if (object.orderNumber != null)
                    message.orderNumber = object.orderNumber | 0;
                if (object.metadata != null) {
                    if (typeof object.metadata !== "object")
                        throw TypeError(".waiternow.common.OrderProto.metadata: object expected");
                    message.metadata = $root.waiternow.common.OrderProto.MetadataProto.fromObject(object.metadata);
                }
                if (object.orderTime != null) {
                    if (typeof object.orderTime !== "object")
                        throw TypeError(".waiternow.common.OrderProto.orderTime: object expected");
                    message.orderTime = $root.waiternow.common.DateTimeProto.fromObject(object.orderTime);
                }
                if (object.delivery != null) {
                    if (typeof object.delivery !== "object")
                        throw TypeError(".waiternow.common.OrderProto.delivery: object expected");
                    message.delivery = $root.waiternow.common.DeliveryProto.fromObject(object.delivery);
                }
                if (object.retriedDelivery != null) {
                    if (typeof object.retriedDelivery !== "object")
                        throw TypeError(".waiternow.common.OrderProto.retriedDelivery: object expected");
                    message.retriedDelivery = $root.waiternow.common.DeliveryProto.fromObject(object.retriedDelivery);
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {waiternow.common.OrderProto} message OrderProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.pointOfServiceId = "";
                    object.consumerId = "";
                    object.status = null;
                    object.creationTime = null;
                    object.redundantData = null;
                    object.deviceId = "";
                    object.lastUpdateTime = null;
                    object.orderNumber = 0;
                    object.metadata = null;
                    object.orderTime = null;
                    object.delivery = null;
                    object.retriedDelivery = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    object.pointOfServiceId = message.pointOfServiceId;
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    object.consumerId = message.consumerId;
                if (message.waiterRequest != null && message.hasOwnProperty("waiterRequest")) {
                    object.waiterRequest = $root.waiternow.common.OrderProto.WaiterRequestProto.toObject(message.waiterRequest, options);
                    if (options.oneofs)
                        object.orderType = "waiterRequest";
                }
                if (message.structuredMenuOrder != null && message.hasOwnProperty("structuredMenuOrder")) {
                    object.structuredMenuOrder = $root.waiternow.common.StructuredMenuOrderProto.toObject(message.structuredMenuOrder, options);
                    if (options.oneofs)
                        object.orderType = "structuredMenuOrder";
                }
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = $root.waiternow.common.OrderProto.StatusProto.toObject(message.status, options);
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.redundantData != null && message.hasOwnProperty("redundantData"))
                    object.redundantData = $root.waiternow.common.OrderProto.RedundantDataProto.toObject(message.redundantData, options);
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    object.deviceId = message.deviceId;
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.orderNumber != null && message.hasOwnProperty("orderNumber"))
                    object.orderNumber = message.orderNumber;
                if (message.metadata != null && message.hasOwnProperty("metadata"))
                    object.metadata = $root.waiternow.common.OrderProto.MetadataProto.toObject(message.metadata, options);
                if (message.orderTime != null && message.hasOwnProperty("orderTime"))
                    object.orderTime = $root.waiternow.common.DateTimeProto.toObject(message.orderTime, options);
                if (message.delivery != null && message.hasOwnProperty("delivery"))
                    object.delivery = $root.waiternow.common.DeliveryProto.toObject(message.delivery, options);
                if (message.retriedDelivery != null && message.hasOwnProperty("retriedDelivery"))
                    object.retriedDelivery = $root.waiternow.common.DeliveryProto.toObject(message.retriedDelivery, options);
                return object;
            };

            /**
             * Converts this OrderProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OrderProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrderProto
             * @function getTypeUrl
             * @memberof waiternow.common.OrderProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrderProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OrderProto";
            };

            OrderProto.WaiterRequestProto = (function() {

                /**
                 * Properties of a WaiterRequestProto.
                 * @memberof waiternow.common.OrderProto
                 * @interface IWaiterRequestProto
                 * @property {waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction|null} [predefinedAction] WaiterRequestProto predefinedAction
                 * @property {string|null} [customMessage] WaiterRequestProto customMessage
                 * @property {waiternow.common.OrderProto.WaiterRequestProto.CheckOption|null} [checkOption] WaiterRequestProto checkOption
                 */

                /**
                 * Constructs a new WaiterRequestProto.
                 * @memberof waiternow.common.OrderProto
                 * @classdesc Represents a WaiterRequestProto.
                 * @implements IWaiterRequestProto
                 * @constructor
                 * @param {waiternow.common.OrderProto.IWaiterRequestProto=} [properties] Properties to set
                 */
                function WaiterRequestProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * WaiterRequestProto predefinedAction.
                 * @member {waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction} predefinedAction
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @instance
                 */
                WaiterRequestProto.prototype.predefinedAction = 0;

                /**
                 * WaiterRequestProto customMessage.
                 * @member {string} customMessage
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @instance
                 */
                WaiterRequestProto.prototype.customMessage = "";

                /**
                 * WaiterRequestProto checkOption.
                 * @member {waiternow.common.OrderProto.WaiterRequestProto.CheckOption} checkOption
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @instance
                 */
                WaiterRequestProto.prototype.checkOption = 0;

                /**
                 * Creates a new WaiterRequestProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {waiternow.common.OrderProto.IWaiterRequestProto=} [properties] Properties to set
                 * @returns {waiternow.common.OrderProto.WaiterRequestProto} WaiterRequestProto instance
                 */
                WaiterRequestProto.create = function create(properties) {
                    return new WaiterRequestProto(properties);
                };

                /**
                 * Encodes the specified WaiterRequestProto message. Does not implicitly {@link waiternow.common.OrderProto.WaiterRequestProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {waiternow.common.OrderProto.IWaiterRequestProto} message WaiterRequestProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WaiterRequestProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.predefinedAction != null && Object.hasOwnProperty.call(message, "predefinedAction"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.predefinedAction);
                    if (message.customMessage != null && Object.hasOwnProperty.call(message, "customMessage"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.customMessage);
                    if (message.checkOption != null && Object.hasOwnProperty.call(message, "checkOption"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.checkOption);
                    return writer;
                };

                /**
                 * Encodes the specified WaiterRequestProto message, length delimited. Does not implicitly {@link waiternow.common.OrderProto.WaiterRequestProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {waiternow.common.OrderProto.IWaiterRequestProto} message WaiterRequestProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                WaiterRequestProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a WaiterRequestProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.OrderProto.WaiterRequestProto} WaiterRequestProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WaiterRequestProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderProto.WaiterRequestProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.predefinedAction = reader.int32();
                                break;
                            }
                        case 2: {
                                message.customMessage = reader.string();
                                break;
                            }
                        case 3: {
                                message.checkOption = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a WaiterRequestProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.OrderProto.WaiterRequestProto} WaiterRequestProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                WaiterRequestProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a WaiterRequestProto message.
                 * @function verify
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                WaiterRequestProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.predefinedAction != null && message.hasOwnProperty("predefinedAction"))
                        switch (message.predefinedAction) {
                        default:
                            return "predefinedAction: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.customMessage != null && message.hasOwnProperty("customMessage"))
                        if (!$util.isString(message.customMessage))
                            return "customMessage: string expected";
                    if (message.checkOption != null && message.hasOwnProperty("checkOption"))
                        switch (message.checkOption) {
                        default:
                            return "checkOption: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a WaiterRequestProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.OrderProto.WaiterRequestProto} WaiterRequestProto
                 */
                WaiterRequestProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.OrderProto.WaiterRequestProto)
                        return object;
                    var message = new $root.waiternow.common.OrderProto.WaiterRequestProto();
                    switch (object.predefinedAction) {
                    default:
                        if (typeof object.predefinedAction === "number") {
                            message.predefinedAction = object.predefinedAction;
                            break;
                        }
                        break;
                    case "PREDEFINED_ACTION_UNSPECIFIED":
                    case 0:
                        message.predefinedAction = 0;
                        break;
                    case "CALL_WAITER":
                    case 1:
                        message.predefinedAction = 1;
                        break;
                    case "CHECK":
                    case 2:
                        message.predefinedAction = 2;
                        break;
                    case "CLEAN_TABLE":
                    case 3:
                        message.predefinedAction = 3;
                        break;
                    case "NAPKINGS":
                    case 4:
                        message.predefinedAction = 4;
                        break;
                    case "UTENSILS":
                    case 5:
                        message.predefinedAction = 5;
                        break;
                    }
                    if (object.customMessage != null)
                        message.customMessage = String(object.customMessage);
                    switch (object.checkOption) {
                    default:
                        if (typeof object.checkOption === "number") {
                            message.checkOption = object.checkOption;
                            break;
                        }
                        break;
                    case "CHECK_OPTION_UNSPECIFIED":
                    case 0:
                        message.checkOption = 0;
                        break;
                    case "CREDIT_CARD_PAYMENT":
                    case 1:
                        message.checkOption = 1;
                        break;
                    case "CASH_PAYMENT":
                    case 2:
                        message.checkOption = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a WaiterRequestProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {waiternow.common.OrderProto.WaiterRequestProto} message WaiterRequestProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                WaiterRequestProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.predefinedAction = options.enums === String ? "PREDEFINED_ACTION_UNSPECIFIED" : 0;
                        object.customMessage = "";
                        object.checkOption = options.enums === String ? "CHECK_OPTION_UNSPECIFIED" : 0;
                    }
                    if (message.predefinedAction != null && message.hasOwnProperty("predefinedAction"))
                        object.predefinedAction = options.enums === String ? $root.waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction[message.predefinedAction] === undefined ? message.predefinedAction : $root.waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction[message.predefinedAction] : message.predefinedAction;
                    if (message.customMessage != null && message.hasOwnProperty("customMessage"))
                        object.customMessage = message.customMessage;
                    if (message.checkOption != null && message.hasOwnProperty("checkOption"))
                        object.checkOption = options.enums === String ? $root.waiternow.common.OrderProto.WaiterRequestProto.CheckOption[message.checkOption] === undefined ? message.checkOption : $root.waiternow.common.OrderProto.WaiterRequestProto.CheckOption[message.checkOption] : message.checkOption;
                    return object;
                };

                /**
                 * Converts this WaiterRequestProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                WaiterRequestProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for WaiterRequestProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.OrderProto.WaiterRequestProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                WaiterRequestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.OrderProto.WaiterRequestProto";
                };

                /**
                 * PredefinedAction enum.
                 * @name waiternow.common.OrderProto.WaiterRequestProto.PredefinedAction
                 * @enum {number}
                 * @property {number} PREDEFINED_ACTION_UNSPECIFIED=0 PREDEFINED_ACTION_UNSPECIFIED value
                 * @property {number} CALL_WAITER=1 CALL_WAITER value
                 * @property {number} CHECK=2 CHECK value
                 * @property {number} CLEAN_TABLE=3 CLEAN_TABLE value
                 * @property {number} NAPKINGS=4 NAPKINGS value
                 * @property {number} UTENSILS=5 UTENSILS value
                 */
                WaiterRequestProto.PredefinedAction = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "PREDEFINED_ACTION_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "CALL_WAITER"] = 1;
                    values[valuesById[2] = "CHECK"] = 2;
                    values[valuesById[3] = "CLEAN_TABLE"] = 3;
                    values[valuesById[4] = "NAPKINGS"] = 4;
                    values[valuesById[5] = "UTENSILS"] = 5;
                    return values;
                })();

                /**
                 * CheckOption enum.
                 * @name waiternow.common.OrderProto.WaiterRequestProto.CheckOption
                 * @enum {number}
                 * @property {number} CHECK_OPTION_UNSPECIFIED=0 CHECK_OPTION_UNSPECIFIED value
                 * @property {number} CREDIT_CARD_PAYMENT=1 CREDIT_CARD_PAYMENT value
                 * @property {number} CASH_PAYMENT=2 CASH_PAYMENT value
                 */
                WaiterRequestProto.CheckOption = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "CHECK_OPTION_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "CREDIT_CARD_PAYMENT"] = 1;
                    values[valuesById[2] = "CASH_PAYMENT"] = 2;
                    return values;
                })();

                return WaiterRequestProto;
            })();

            OrderProto.StatusProto = (function() {

                /**
                 * Properties of a StatusProto.
                 * @memberof waiternow.common.OrderProto
                 * @interface IStatusProto
                 * @property {google.protobuf.ITimestamp|null} [received] StatusProto received
                 * @property {google.protobuf.ITimestamp|null} [acked] StatusProto acked
                 * @property {string|null} [ackedByUserId] StatusProto ackedByUserId
                 * @property {google.protobuf.ITimestamp|null} [printed] StatusProto printed
                 * @property {string|null} [printedByUserId] StatusProto printedByUserId
                 * @property {waiternow.common.IPaymentProto|null} [payment] StatusProto payment
                 * @property {google.protobuf.ITimestamp|null} [completed] StatusProto completed
                 * @property {string|null} [completedByUserId] StatusProto completedByUserId
                 */

                /**
                 * Constructs a new StatusProto.
                 * @memberof waiternow.common.OrderProto
                 * @classdesc Represents a StatusProto.
                 * @implements IStatusProto
                 * @constructor
                 * @param {waiternow.common.OrderProto.IStatusProto=} [properties] Properties to set
                 */
                function StatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatusProto received.
                 * @member {google.protobuf.ITimestamp|null|undefined} received
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.received = null;

                /**
                 * StatusProto acked.
                 * @member {google.protobuf.ITimestamp|null|undefined} acked
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.acked = null;

                /**
                 * StatusProto ackedByUserId.
                 * @member {string} ackedByUserId
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.ackedByUserId = "";

                /**
                 * StatusProto printed.
                 * @member {google.protobuf.ITimestamp|null|undefined} printed
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.printed = null;

                /**
                 * StatusProto printedByUserId.
                 * @member {string} printedByUserId
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.printedByUserId = "";

                /**
                 * StatusProto payment.
                 * @member {waiternow.common.IPaymentProto|null|undefined} payment
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.payment = null;

                /**
                 * StatusProto completed.
                 * @member {google.protobuf.ITimestamp|null|undefined} completed
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.completed = null;

                /**
                 * StatusProto completedByUserId.
                 * @member {string} completedByUserId
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.completedByUserId = "";

                /**
                 * Creates a new StatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {waiternow.common.OrderProto.IStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.OrderProto.StatusProto} StatusProto instance
                 */
                StatusProto.create = function create(properties) {
                    return new StatusProto(properties);
                };

                /**
                 * Encodes the specified StatusProto message. Does not implicitly {@link waiternow.common.OrderProto.StatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {waiternow.common.OrderProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.received != null && Object.hasOwnProperty.call(message, "received"))
                        $root.google.protobuf.Timestamp.encode(message.received, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.acked != null && Object.hasOwnProperty.call(message, "acked"))
                        $root.google.protobuf.Timestamp.encode(message.acked, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.ackedByUserId != null && Object.hasOwnProperty.call(message, "ackedByUserId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.ackedByUserId);
                    if (message.printed != null && Object.hasOwnProperty.call(message, "printed"))
                        $root.google.protobuf.Timestamp.encode(message.printed, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.printedByUserId != null && Object.hasOwnProperty.call(message, "printedByUserId"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.printedByUserId);
                    if (message.payment != null && Object.hasOwnProperty.call(message, "payment"))
                        $root.waiternow.common.PaymentProto.encode(message.payment, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.completed != null && Object.hasOwnProperty.call(message, "completed"))
                        $root.google.protobuf.Timestamp.encode(message.completed, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.completedByUserId != null && Object.hasOwnProperty.call(message, "completedByUserId"))
                        writer.uint32(/* id 8, wireType 2 =*/66).string(message.completedByUserId);
                    return writer;
                };

                /**
                 * Encodes the specified StatusProto message, length delimited. Does not implicitly {@link waiternow.common.OrderProto.StatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {waiternow.common.OrderProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.OrderProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderProto.StatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.received = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.acked = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.ackedByUserId = reader.string();
                                break;
                            }
                        case 4: {
                                message.printed = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.printedByUserId = reader.string();
                                break;
                            }
                        case 6: {
                                message.payment = $root.waiternow.common.PaymentProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.completed = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.completedByUserId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.OrderProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatusProto message.
                 * @function verify
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.received != null && message.hasOwnProperty("received")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.received);
                        if (error)
                            return "received." + error;
                    }
                    if (message.acked != null && message.hasOwnProperty("acked")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.acked);
                        if (error)
                            return "acked." + error;
                    }
                    if (message.ackedByUserId != null && message.hasOwnProperty("ackedByUserId"))
                        if (!$util.isString(message.ackedByUserId))
                            return "ackedByUserId: string expected";
                    if (message.printed != null && message.hasOwnProperty("printed")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.printed);
                        if (error)
                            return "printed." + error;
                    }
                    if (message.printedByUserId != null && message.hasOwnProperty("printedByUserId"))
                        if (!$util.isString(message.printedByUserId))
                            return "printedByUserId: string expected";
                    if (message.payment != null && message.hasOwnProperty("payment")) {
                        var error = $root.waiternow.common.PaymentProto.verify(message.payment);
                        if (error)
                            return "payment." + error;
                    }
                    if (message.completed != null && message.hasOwnProperty("completed")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.completed);
                        if (error)
                            return "completed." + error;
                    }
                    if (message.completedByUserId != null && message.hasOwnProperty("completedByUserId"))
                        if (!$util.isString(message.completedByUserId))
                            return "completedByUserId: string expected";
                    return null;
                };

                /**
                 * Creates a StatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.OrderProto.StatusProto} StatusProto
                 */
                StatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.OrderProto.StatusProto)
                        return object;
                    var message = new $root.waiternow.common.OrderProto.StatusProto();
                    if (object.received != null) {
                        if (typeof object.received !== "object")
                            throw TypeError(".waiternow.common.OrderProto.StatusProto.received: object expected");
                        message.received = $root.google.protobuf.Timestamp.fromObject(object.received);
                    }
                    if (object.acked != null) {
                        if (typeof object.acked !== "object")
                            throw TypeError(".waiternow.common.OrderProto.StatusProto.acked: object expected");
                        message.acked = $root.google.protobuf.Timestamp.fromObject(object.acked);
                    }
                    if (object.ackedByUserId != null)
                        message.ackedByUserId = String(object.ackedByUserId);
                    if (object.printed != null) {
                        if (typeof object.printed !== "object")
                            throw TypeError(".waiternow.common.OrderProto.StatusProto.printed: object expected");
                        message.printed = $root.google.protobuf.Timestamp.fromObject(object.printed);
                    }
                    if (object.printedByUserId != null)
                        message.printedByUserId = String(object.printedByUserId);
                    if (object.payment != null) {
                        if (typeof object.payment !== "object")
                            throw TypeError(".waiternow.common.OrderProto.StatusProto.payment: object expected");
                        message.payment = $root.waiternow.common.PaymentProto.fromObject(object.payment);
                    }
                    if (object.completed != null) {
                        if (typeof object.completed !== "object")
                            throw TypeError(".waiternow.common.OrderProto.StatusProto.completed: object expected");
                        message.completed = $root.google.protobuf.Timestamp.fromObject(object.completed);
                    }
                    if (object.completedByUserId != null)
                        message.completedByUserId = String(object.completedByUserId);
                    return message;
                };

                /**
                 * Creates a plain object from a StatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {waiternow.common.OrderProto.StatusProto} message StatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.received = null;
                        object.acked = null;
                        object.ackedByUserId = "";
                        object.printed = null;
                        object.printedByUserId = "";
                        object.payment = null;
                        object.completed = null;
                        object.completedByUserId = "";
                    }
                    if (message.received != null && message.hasOwnProperty("received"))
                        object.received = $root.google.protobuf.Timestamp.toObject(message.received, options);
                    if (message.acked != null && message.hasOwnProperty("acked"))
                        object.acked = $root.google.protobuf.Timestamp.toObject(message.acked, options);
                    if (message.ackedByUserId != null && message.hasOwnProperty("ackedByUserId"))
                        object.ackedByUserId = message.ackedByUserId;
                    if (message.printed != null && message.hasOwnProperty("printed"))
                        object.printed = $root.google.protobuf.Timestamp.toObject(message.printed, options);
                    if (message.printedByUserId != null && message.hasOwnProperty("printedByUserId"))
                        object.printedByUserId = message.printedByUserId;
                    if (message.payment != null && message.hasOwnProperty("payment"))
                        object.payment = $root.waiternow.common.PaymentProto.toObject(message.payment, options);
                    if (message.completed != null && message.hasOwnProperty("completed"))
                        object.completed = $root.google.protobuf.Timestamp.toObject(message.completed, options);
                    if (message.completedByUserId != null && message.hasOwnProperty("completedByUserId"))
                        object.completedByUserId = message.completedByUserId;
                    return object;
                };

                /**
                 * Converts this StatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.OrderProto.StatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.OrderProto.StatusProto";
                };

                return StatusProto;
            })();

            OrderProto.RedundantDataProto = (function() {

                /**
                 * Properties of a RedundantDataProto.
                 * @memberof waiternow.common.OrderProto
                 * @interface IRedundantDataProto
                 * @property {string|null} [locationId] RedundantDataProto locationId
                 * @property {string|null} [businessId] RedundantDataProto businessId
                 * @property {string|null} [pointOfServiceFriendlyName] RedundantDataProto pointOfServiceFriendlyName
                 * @property {string|null} [consumerName] RedundantDataProto consumerName
                 * @property {waiternow.common.Language|null} [language] RedundantDataProto language
                 * @property {string|null} [businessName] RedundantDataProto businessName
                 * @property {waiternow.common.IAddressProto|null} [locationAddress] RedundantDataProto locationAddress
                 * @property {boolean|null} [isOnlinePickup] RedundantDataProto isOnlinePickup
                 * @property {boolean|null} [isOnsitePickup] RedundantDataProto isOnsitePickup
                 * @property {boolean|null} [isDelivery] RedundantDataProto isDelivery
                 * @property {string|null} [locationName] RedundantDataProto locationName
                 * @property {waiternow.common.IPhoneNumberProto|null} [locationPhoneNumber] RedundantDataProto locationPhoneNumber
                 * @property {boolean|null} [isCompletedStatusExpected] RedundantDataProto isCompletedStatusExpected
                 */

                /**
                 * Constructs a new RedundantDataProto.
                 * @memberof waiternow.common.OrderProto
                 * @classdesc Represents a RedundantDataProto.
                 * @implements IRedundantDataProto
                 * @constructor
                 * @param {waiternow.common.OrderProto.IRedundantDataProto=} [properties] Properties to set
                 */
                function RedundantDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RedundantDataProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationId = "";

                /**
                 * RedundantDataProto businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessId = "";

                /**
                 * RedundantDataProto pointOfServiceFriendlyName.
                 * @member {string} pointOfServiceFriendlyName
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.pointOfServiceFriendlyName = "";

                /**
                 * RedundantDataProto consumerName.
                 * @member {string} consumerName
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.consumerName = "";

                /**
                 * RedundantDataProto language.
                 * @member {waiternow.common.Language} language
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.language = 0;

                /**
                 * RedundantDataProto businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessName = "";

                /**
                 * RedundantDataProto locationAddress.
                 * @member {waiternow.common.IAddressProto|null|undefined} locationAddress
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationAddress = null;

                /**
                 * RedundantDataProto isOnlinePickup.
                 * @member {boolean} isOnlinePickup
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.isOnlinePickup = false;

                /**
                 * RedundantDataProto isOnsitePickup.
                 * @member {boolean} isOnsitePickup
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.isOnsitePickup = false;

                /**
                 * RedundantDataProto isDelivery.
                 * @member {boolean} isDelivery
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.isDelivery = false;

                /**
                 * RedundantDataProto locationName.
                 * @member {string} locationName
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationName = "";

                /**
                 * RedundantDataProto locationPhoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} locationPhoneNumber
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationPhoneNumber = null;

                /**
                 * RedundantDataProto isCompletedStatusExpected.
                 * @member {boolean} isCompletedStatusExpected
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.isCompletedStatusExpected = false;

                /**
                 * Creates a new RedundantDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.OrderProto.IRedundantDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.OrderProto.RedundantDataProto} RedundantDataProto instance
                 */
                RedundantDataProto.create = function create(properties) {
                    return new RedundantDataProto(properties);
                };

                /**
                 * Encodes the specified RedundantDataProto message. Does not implicitly {@link waiternow.common.OrderProto.RedundantDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.OrderProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
                    if (message.pointOfServiceFriendlyName != null && Object.hasOwnProperty.call(message, "pointOfServiceFriendlyName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.pointOfServiceFriendlyName);
                    if (message.consumerName != null && Object.hasOwnProperty.call(message, "consumerName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.consumerName);
                    if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.language);
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.businessName);
                    if (message.locationAddress != null && Object.hasOwnProperty.call(message, "locationAddress"))
                        $root.waiternow.common.AddressProto.encode(message.locationAddress, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.isOnlinePickup != null && Object.hasOwnProperty.call(message, "isOnlinePickup"))
                        writer.uint32(/* id 8, wireType 0 =*/64).bool(message.isOnlinePickup);
                    if (message.isOnsitePickup != null && Object.hasOwnProperty.call(message, "isOnsitePickup"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isOnsitePickup);
                    if (message.isDelivery != null && Object.hasOwnProperty.call(message, "isDelivery"))
                        writer.uint32(/* id 10, wireType 0 =*/80).bool(message.isDelivery);
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 11, wireType 2 =*/90).string(message.locationName);
                    if (message.locationPhoneNumber != null && Object.hasOwnProperty.call(message, "locationPhoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.locationPhoneNumber, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.isCompletedStatusExpected != null && Object.hasOwnProperty.call(message, "isCompletedStatusExpected"))
                        writer.uint32(/* id 13, wireType 0 =*/104).bool(message.isCompletedStatusExpected);
                    return writer;
                };

                /**
                 * Encodes the specified RedundantDataProto message, length delimited. Does not implicitly {@link waiternow.common.OrderProto.RedundantDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.OrderProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.OrderProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderProto.RedundantDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 3: {
                                message.pointOfServiceFriendlyName = reader.string();
                                break;
                            }
                        case 4: {
                                message.consumerName = reader.string();
                                break;
                            }
                        case 5: {
                                message.language = reader.int32();
                                break;
                            }
                        case 6: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 7: {
                                message.locationAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.isOnlinePickup = reader.bool();
                                break;
                            }
                        case 9: {
                                message.isOnsitePickup = reader.bool();
                                break;
                            }
                        case 10: {
                                message.isDelivery = reader.bool();
                                break;
                            }
                        case 11: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 12: {
                                message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.isCompletedStatusExpected = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.OrderProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RedundantDataProto message.
                 * @function verify
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedundantDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                        if (!$util.isString(message.pointOfServiceFriendlyName))
                            return "pointOfServiceFriendlyName: string expected";
                    if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                        if (!$util.isString(message.consumerName))
                            return "consumerName: string expected";
                    if (message.language != null && message.hasOwnProperty("language"))
                        switch (message.language) {
                        default:
                            return "language: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.locationAddress);
                        if (error)
                            return "locationAddress." + error;
                    }
                    if (message.isOnlinePickup != null && message.hasOwnProperty("isOnlinePickup"))
                        if (typeof message.isOnlinePickup !== "boolean")
                            return "isOnlinePickup: boolean expected";
                    if (message.isOnsitePickup != null && message.hasOwnProperty("isOnsitePickup"))
                        if (typeof message.isOnsitePickup !== "boolean")
                            return "isOnsitePickup: boolean expected";
                    if (message.isDelivery != null && message.hasOwnProperty("isDelivery"))
                        if (typeof message.isDelivery !== "boolean")
                            return "isDelivery: boolean expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.locationPhoneNumber);
                        if (error)
                            return "locationPhoneNumber." + error;
                    }
                    if (message.isCompletedStatusExpected != null && message.hasOwnProperty("isCompletedStatusExpected"))
                        if (typeof message.isCompletedStatusExpected !== "boolean")
                            return "isCompletedStatusExpected: boolean expected";
                    return null;
                };

                /**
                 * Creates a RedundantDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.OrderProto.RedundantDataProto} RedundantDataProto
                 */
                RedundantDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.OrderProto.RedundantDataProto)
                        return object;
                    var message = new $root.waiternow.common.OrderProto.RedundantDataProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.pointOfServiceFriendlyName != null)
                        message.pointOfServiceFriendlyName = String(object.pointOfServiceFriendlyName);
                    if (object.consumerName != null)
                        message.consumerName = String(object.consumerName);
                    switch (object.language) {
                    default:
                        if (typeof object.language === "number") {
                            message.language = object.language;
                            break;
                        }
                        break;
                    case "LANGUAGE_UNSPECIFIED":
                    case 0:
                        message.language = 0;
                        break;
                    case "ENGLISH":
                    case 1:
                        message.language = 1;
                        break;
                    case "SPANISH":
                    case 2:
                        message.language = 2;
                        break;
                    }
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.locationAddress != null) {
                        if (typeof object.locationAddress !== "object")
                            throw TypeError(".waiternow.common.OrderProto.RedundantDataProto.locationAddress: object expected");
                        message.locationAddress = $root.waiternow.common.AddressProto.fromObject(object.locationAddress);
                    }
                    if (object.isOnlinePickup != null)
                        message.isOnlinePickup = Boolean(object.isOnlinePickup);
                    if (object.isOnsitePickup != null)
                        message.isOnsitePickup = Boolean(object.isOnsitePickup);
                    if (object.isDelivery != null)
                        message.isDelivery = Boolean(object.isDelivery);
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.locationPhoneNumber != null) {
                        if (typeof object.locationPhoneNumber !== "object")
                            throw TypeError(".waiternow.common.OrderProto.RedundantDataProto.locationPhoneNumber: object expected");
                        message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.locationPhoneNumber);
                    }
                    if (object.isCompletedStatusExpected != null)
                        message.isCompletedStatusExpected = Boolean(object.isCompletedStatusExpected);
                    return message;
                };

                /**
                 * Creates a plain object from a RedundantDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.OrderProto.RedundantDataProto} message RedundantDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedundantDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.businessId = "";
                        object.pointOfServiceFriendlyName = "";
                        object.consumerName = "";
                        object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                        object.businessName = "";
                        object.locationAddress = null;
                        object.isOnlinePickup = false;
                        object.isOnsitePickup = false;
                        object.isDelivery = false;
                        object.locationName = "";
                        object.locationPhoneNumber = null;
                        object.isCompletedStatusExpected = false;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                        object.pointOfServiceFriendlyName = message.pointOfServiceFriendlyName;
                    if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                        object.consumerName = message.consumerName;
                    if (message.language != null && message.hasOwnProperty("language"))
                        object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress"))
                        object.locationAddress = $root.waiternow.common.AddressProto.toObject(message.locationAddress, options);
                    if (message.isOnlinePickup != null && message.hasOwnProperty("isOnlinePickup"))
                        object.isOnlinePickup = message.isOnlinePickup;
                    if (message.isOnsitePickup != null && message.hasOwnProperty("isOnsitePickup"))
                        object.isOnsitePickup = message.isOnsitePickup;
                    if (message.isDelivery != null && message.hasOwnProperty("isDelivery"))
                        object.isDelivery = message.isDelivery;
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber"))
                        object.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.locationPhoneNumber, options);
                    if (message.isCompletedStatusExpected != null && message.hasOwnProperty("isCompletedStatusExpected"))
                        object.isCompletedStatusExpected = message.isCompletedStatusExpected;
                    return object;
                };

                /**
                 * Converts this RedundantDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedundantDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RedundantDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.OrderProto.RedundantDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedundantDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.OrderProto.RedundantDataProto";
                };

                return RedundantDataProto;
            })();

            OrderProto.MetadataProto = (function() {

                /**
                 * Properties of a MetadataProto.
                 * @memberof waiternow.common.OrderProto
                 * @interface IMetadataProto
                 * @property {boolean|null} [isStressTest] MetadataProto isStressTest
                 * @property {boolean|null} [emulatePrinter] MetadataProto emulatePrinter
                 */

                /**
                 * Constructs a new MetadataProto.
                 * @memberof waiternow.common.OrderProto
                 * @classdesc Represents a MetadataProto.
                 * @implements IMetadataProto
                 * @constructor
                 * @param {waiternow.common.OrderProto.IMetadataProto=} [properties] Properties to set
                 */
                function MetadataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MetadataProto isStressTest.
                 * @member {boolean} isStressTest
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.isStressTest = false;

                /**
                 * MetadataProto emulatePrinter.
                 * @member {boolean} emulatePrinter
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @instance
                 */
                MetadataProto.prototype.emulatePrinter = false;

                /**
                 * Creates a new MetadataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {waiternow.common.OrderProto.IMetadataProto=} [properties] Properties to set
                 * @returns {waiternow.common.OrderProto.MetadataProto} MetadataProto instance
                 */
                MetadataProto.create = function create(properties) {
                    return new MetadataProto(properties);
                };

                /**
                 * Encodes the specified MetadataProto message. Does not implicitly {@link waiternow.common.OrderProto.MetadataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {waiternow.common.OrderProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.isStressTest != null && Object.hasOwnProperty.call(message, "isStressTest"))
                        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.isStressTest);
                    if (message.emulatePrinter != null && Object.hasOwnProperty.call(message, "emulatePrinter"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.emulatePrinter);
                    return writer;
                };

                /**
                 * Encodes the specified MetadataProto message, length delimited. Does not implicitly {@link waiternow.common.OrderProto.MetadataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {waiternow.common.OrderProto.IMetadataProto} message MetadataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetadataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.OrderProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderProto.MetadataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.isStressTest = reader.bool();
                                break;
                            }
                        case 2: {
                                message.emulatePrinter = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MetadataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.OrderProto.MetadataProto} MetadataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetadataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MetadataProto message.
                 * @function verify
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MetadataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.isStressTest != null && message.hasOwnProperty("isStressTest"))
                        if (typeof message.isStressTest !== "boolean")
                            return "isStressTest: boolean expected";
                    if (message.emulatePrinter != null && message.hasOwnProperty("emulatePrinter"))
                        if (typeof message.emulatePrinter !== "boolean")
                            return "emulatePrinter: boolean expected";
                    return null;
                };

                /**
                 * Creates a MetadataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.OrderProto.MetadataProto} MetadataProto
                 */
                MetadataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.OrderProto.MetadataProto)
                        return object;
                    var message = new $root.waiternow.common.OrderProto.MetadataProto();
                    if (object.isStressTest != null)
                        message.isStressTest = Boolean(object.isStressTest);
                    if (object.emulatePrinter != null)
                        message.emulatePrinter = Boolean(object.emulatePrinter);
                    return message;
                };

                /**
                 * Creates a plain object from a MetadataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {waiternow.common.OrderProto.MetadataProto} message MetadataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MetadataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.isStressTest = false;
                        object.emulatePrinter = false;
                    }
                    if (message.isStressTest != null && message.hasOwnProperty("isStressTest"))
                        object.isStressTest = message.isStressTest;
                    if (message.emulatePrinter != null && message.hasOwnProperty("emulatePrinter"))
                        object.emulatePrinter = message.emulatePrinter;
                    return object;
                };

                /**
                 * Converts this MetadataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MetadataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MetadataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.OrderProto.MetadataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MetadataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.OrderProto.MetadataProto";
                };

                return MetadataProto;
            })();

            return OrderProto;
        })();

        common.OrdersProto = (function() {

            /**
             * Properties of an OrdersProto.
             * @memberof waiternow.common
             * @interface IOrdersProto
             * @property {Array.<waiternow.common.IOrderProto>|null} [orders] OrdersProto orders
             * @property {string|null} [continuationToken] OrdersProto continuationToken
             */

            /**
             * Constructs a new OrdersProto.
             * @memberof waiternow.common
             * @classdesc Represents an OrdersProto.
             * @implements IOrdersProto
             * @constructor
             * @param {waiternow.common.IOrdersProto=} [properties] Properties to set
             */
            function OrdersProto(properties) {
                this.orders = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrdersProto orders.
             * @member {Array.<waiternow.common.IOrderProto>} orders
             * @memberof waiternow.common.OrdersProto
             * @instance
             */
            OrdersProto.prototype.orders = $util.emptyArray;

            /**
             * OrdersProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.OrdersProto
             * @instance
             */
            OrdersProto.prototype.continuationToken = "";

            /**
             * Creates a new OrdersProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {waiternow.common.IOrdersProto=} [properties] Properties to set
             * @returns {waiternow.common.OrdersProto} OrdersProto instance
             */
            OrdersProto.create = function create(properties) {
                return new OrdersProto(properties);
            };

            /**
             * Encodes the specified OrdersProto message. Does not implicitly {@link waiternow.common.OrdersProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {waiternow.common.IOrdersProto} message OrdersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrdersProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orders != null && message.orders.length)
                    for (var i = 0; i < message.orders.length; ++i)
                        $root.waiternow.common.OrderProto.encode(message.orders[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified OrdersProto message, length delimited. Does not implicitly {@link waiternow.common.OrdersProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {waiternow.common.IOrdersProto} message OrdersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrdersProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrdersProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OrdersProto} OrdersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrdersProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrdersProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.orders && message.orders.length))
                                message.orders = [];
                            message.orders.push($root.waiternow.common.OrderProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrdersProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OrdersProto} OrdersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrdersProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrdersProto message.
             * @function verify
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrdersProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orders != null && message.hasOwnProperty("orders")) {
                    if (!Array.isArray(message.orders))
                        return "orders: array expected";
                    for (var i = 0; i < message.orders.length; ++i) {
                        var error = $root.waiternow.common.OrderProto.verify(message.orders[i]);
                        if (error)
                            return "orders." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates an OrdersProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OrdersProto} OrdersProto
             */
            OrdersProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OrdersProto)
                    return object;
                var message = new $root.waiternow.common.OrdersProto();
                if (object.orders) {
                    if (!Array.isArray(object.orders))
                        throw TypeError(".waiternow.common.OrdersProto.orders: array expected");
                    message.orders = [];
                    for (var i = 0; i < object.orders.length; ++i) {
                        if (typeof object.orders[i] !== "object")
                            throw TypeError(".waiternow.common.OrdersProto.orders: object expected");
                        message.orders[i] = $root.waiternow.common.OrderProto.fromObject(object.orders[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from an OrdersProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {waiternow.common.OrdersProto} message OrdersProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrdersProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orders = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.orders && message.orders.length) {
                    object.orders = [];
                    for (var j = 0; j < message.orders.length; ++j)
                        object.orders[j] = $root.waiternow.common.OrderProto.toObject(message.orders[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this OrdersProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OrdersProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrdersProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrdersProto
             * @function getTypeUrl
             * @memberof waiternow.common.OrdersProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrdersProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OrdersProto";
            };

            return OrdersProto;
        })();

        common.OrderSplitByStationProto = (function() {

            /**
             * Properties of an OrderSplitByStationProto.
             * @memberof waiternow.common
             * @interface IOrderSplitByStationProto
             * @property {Array.<waiternow.common.OrderSplitByStationProto.IOrderPartProto>|null} [orderParts] OrderSplitByStationProto orderParts
             */

            /**
             * Constructs a new OrderSplitByStationProto.
             * @memberof waiternow.common
             * @classdesc Represents an OrderSplitByStationProto.
             * @implements IOrderSplitByStationProto
             * @constructor
             * @param {waiternow.common.IOrderSplitByStationProto=} [properties] Properties to set
             */
            function OrderSplitByStationProto(properties) {
                this.orderParts = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderSplitByStationProto orderParts.
             * @member {Array.<waiternow.common.OrderSplitByStationProto.IOrderPartProto>} orderParts
             * @memberof waiternow.common.OrderSplitByStationProto
             * @instance
             */
            OrderSplitByStationProto.prototype.orderParts = $util.emptyArray;

            /**
             * Creates a new OrderSplitByStationProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {waiternow.common.IOrderSplitByStationProto=} [properties] Properties to set
             * @returns {waiternow.common.OrderSplitByStationProto} OrderSplitByStationProto instance
             */
            OrderSplitByStationProto.create = function create(properties) {
                return new OrderSplitByStationProto(properties);
            };

            /**
             * Encodes the specified OrderSplitByStationProto message. Does not implicitly {@link waiternow.common.OrderSplitByStationProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {waiternow.common.IOrderSplitByStationProto} message OrderSplitByStationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderSplitByStationProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.orderParts != null && message.orderParts.length)
                    for (var i = 0; i < message.orderParts.length; ++i)
                        $root.waiternow.common.OrderSplitByStationProto.OrderPartProto.encode(message.orderParts[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OrderSplitByStationProto message, length delimited. Does not implicitly {@link waiternow.common.OrderSplitByStationProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {waiternow.common.IOrderSplitByStationProto} message OrderSplitByStationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderSplitByStationProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrderSplitByStationProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OrderSplitByStationProto} OrderSplitByStationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderSplitByStationProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderSplitByStationProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.orderParts && message.orderParts.length))
                                message.orderParts = [];
                            message.orderParts.push($root.waiternow.common.OrderSplitByStationProto.OrderPartProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrderSplitByStationProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OrderSplitByStationProto} OrderSplitByStationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderSplitByStationProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrderSplitByStationProto message.
             * @function verify
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderSplitByStationProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.orderParts != null && message.hasOwnProperty("orderParts")) {
                    if (!Array.isArray(message.orderParts))
                        return "orderParts: array expected";
                    for (var i = 0; i < message.orderParts.length; ++i) {
                        var error = $root.waiternow.common.OrderSplitByStationProto.OrderPartProto.verify(message.orderParts[i]);
                        if (error)
                            return "orderParts." + error;
                    }
                }
                return null;
            };

            /**
             * Creates an OrderSplitByStationProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OrderSplitByStationProto} OrderSplitByStationProto
             */
            OrderSplitByStationProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OrderSplitByStationProto)
                    return object;
                var message = new $root.waiternow.common.OrderSplitByStationProto();
                if (object.orderParts) {
                    if (!Array.isArray(object.orderParts))
                        throw TypeError(".waiternow.common.OrderSplitByStationProto.orderParts: array expected");
                    message.orderParts = [];
                    for (var i = 0; i < object.orderParts.length; ++i) {
                        if (typeof object.orderParts[i] !== "object")
                            throw TypeError(".waiternow.common.OrderSplitByStationProto.orderParts: object expected");
                        message.orderParts[i] = $root.waiternow.common.OrderSplitByStationProto.OrderPartProto.fromObject(object.orderParts[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderSplitByStationProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {waiternow.common.OrderSplitByStationProto} message OrderSplitByStationProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderSplitByStationProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orderParts = [];
                if (message.orderParts && message.orderParts.length) {
                    object.orderParts = [];
                    for (var j = 0; j < message.orderParts.length; ++j)
                        object.orderParts[j] = $root.waiternow.common.OrderSplitByStationProto.OrderPartProto.toObject(message.orderParts[j], options);
                }
                return object;
            };

            /**
             * Converts this OrderSplitByStationProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OrderSplitByStationProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderSplitByStationProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrderSplitByStationProto
             * @function getTypeUrl
             * @memberof waiternow.common.OrderSplitByStationProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrderSplitByStationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OrderSplitByStationProto";
            };

            OrderSplitByStationProto.OrderPartProto = (function() {

                /**
                 * Properties of an OrderPartProto.
                 * @memberof waiternow.common.OrderSplitByStationProto
                 * @interface IOrderPartProto
                 * @property {waiternow.common.IOrderProto|null} [order] OrderPartProto order
                 * @property {string|null} [station] OrderPartProto station
                 */

                /**
                 * Constructs a new OrderPartProto.
                 * @memberof waiternow.common.OrderSplitByStationProto
                 * @classdesc Represents an OrderPartProto.
                 * @implements IOrderPartProto
                 * @constructor
                 * @param {waiternow.common.OrderSplitByStationProto.IOrderPartProto=} [properties] Properties to set
                 */
                function OrderPartProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OrderPartProto order.
                 * @member {waiternow.common.IOrderProto|null|undefined} order
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @instance
                 */
                OrderPartProto.prototype.order = null;

                /**
                 * OrderPartProto station.
                 * @member {string} station
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @instance
                 */
                OrderPartProto.prototype.station = "";

                /**
                 * Creates a new OrderPartProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {waiternow.common.OrderSplitByStationProto.IOrderPartProto=} [properties] Properties to set
                 * @returns {waiternow.common.OrderSplitByStationProto.OrderPartProto} OrderPartProto instance
                 */
                OrderPartProto.create = function create(properties) {
                    return new OrderPartProto(properties);
                };

                /**
                 * Encodes the specified OrderPartProto message. Does not implicitly {@link waiternow.common.OrderSplitByStationProto.OrderPartProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {waiternow.common.OrderSplitByStationProto.IOrderPartProto} message OrderPartProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrderPartProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                        $root.waiternow.common.OrderProto.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.station != null && Object.hasOwnProperty.call(message, "station"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.station);
                    return writer;
                };

                /**
                 * Encodes the specified OrderPartProto message, length delimited. Does not implicitly {@link waiternow.common.OrderSplitByStationProto.OrderPartProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {waiternow.common.OrderSplitByStationProto.IOrderPartProto} message OrderPartProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrderPartProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an OrderPartProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.OrderSplitByStationProto.OrderPartProto} OrderPartProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrderPartProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderSplitByStationProto.OrderPartProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.order = $root.waiternow.common.OrderProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.station = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an OrderPartProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.OrderSplitByStationProto.OrderPartProto} OrderPartProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrderPartProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an OrderPartProto message.
                 * @function verify
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrderPartProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.order != null && message.hasOwnProperty("order")) {
                        var error = $root.waiternow.common.OrderProto.verify(message.order);
                        if (error)
                            return "order." + error;
                    }
                    if (message.station != null && message.hasOwnProperty("station"))
                        if (!$util.isString(message.station))
                            return "station: string expected";
                    return null;
                };

                /**
                 * Creates an OrderPartProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.OrderSplitByStationProto.OrderPartProto} OrderPartProto
                 */
                OrderPartProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.OrderSplitByStationProto.OrderPartProto)
                        return object;
                    var message = new $root.waiternow.common.OrderSplitByStationProto.OrderPartProto();
                    if (object.order != null) {
                        if (typeof object.order !== "object")
                            throw TypeError(".waiternow.common.OrderSplitByStationProto.OrderPartProto.order: object expected");
                        message.order = $root.waiternow.common.OrderProto.fromObject(object.order);
                    }
                    if (object.station != null)
                        message.station = String(object.station);
                    return message;
                };

                /**
                 * Creates a plain object from an OrderPartProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {waiternow.common.OrderSplitByStationProto.OrderPartProto} message OrderPartProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrderPartProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.order = null;
                        object.station = "";
                    }
                    if (message.order != null && message.hasOwnProperty("order"))
                        object.order = $root.waiternow.common.OrderProto.toObject(message.order, options);
                    if (message.station != null && message.hasOwnProperty("station"))
                        object.station = message.station;
                    return object;
                };

                /**
                 * Converts this OrderPartProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrderPartProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for OrderPartProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.OrderSplitByStationProto.OrderPartProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrderPartProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.OrderSplitByStationProto.OrderPartProto";
                };

                return OrderPartProto;
            })();

            return OrderSplitByStationProto;
        })();

        common.LocationOrdersProto = (function() {

            /**
             * Properties of a LocationOrdersProto.
             * @memberof waiternow.common
             * @interface ILocationOrdersProto
             * @property {string|null} [locationId] LocationOrdersProto locationId
             * @property {Array.<waiternow.common.IOrderProto>|null} [orders] LocationOrdersProto orders
             * @property {string|null} [continuationToken] LocationOrdersProto continuationToken
             * @property {Array.<waiternow.common.IOrderSplitByStationProto>|null} [ordersSplitByStation] LocationOrdersProto ordersSplitByStation
             */

            /**
             * Constructs a new LocationOrdersProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationOrdersProto.
             * @implements ILocationOrdersProto
             * @constructor
             * @param {waiternow.common.ILocationOrdersProto=} [properties] Properties to set
             */
            function LocationOrdersProto(properties) {
                this.orders = [];
                this.ordersSplitByStation = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationOrdersProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.LocationOrdersProto
             * @instance
             */
            LocationOrdersProto.prototype.locationId = "";

            /**
             * LocationOrdersProto orders.
             * @member {Array.<waiternow.common.IOrderProto>} orders
             * @memberof waiternow.common.LocationOrdersProto
             * @instance
             */
            LocationOrdersProto.prototype.orders = $util.emptyArray;

            /**
             * LocationOrdersProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.LocationOrdersProto
             * @instance
             */
            LocationOrdersProto.prototype.continuationToken = "";

            /**
             * LocationOrdersProto ordersSplitByStation.
             * @member {Array.<waiternow.common.IOrderSplitByStationProto>} ordersSplitByStation
             * @memberof waiternow.common.LocationOrdersProto
             * @instance
             */
            LocationOrdersProto.prototype.ordersSplitByStation = $util.emptyArray;

            /**
             * Creates a new LocationOrdersProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {waiternow.common.ILocationOrdersProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationOrdersProto} LocationOrdersProto instance
             */
            LocationOrdersProto.create = function create(properties) {
                return new LocationOrdersProto(properties);
            };

            /**
             * Encodes the specified LocationOrdersProto message. Does not implicitly {@link waiternow.common.LocationOrdersProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {waiternow.common.ILocationOrdersProto} message LocationOrdersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationOrdersProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.orders != null && message.orders.length)
                    for (var i = 0; i < message.orders.length; ++i)
                        $root.waiternow.common.OrderProto.encode(message.orders[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.continuationToken);
                if (message.ordersSplitByStation != null && message.ordersSplitByStation.length)
                    for (var i = 0; i < message.ordersSplitByStation.length; ++i)
                        $root.waiternow.common.OrderSplitByStationProto.encode(message.ordersSplitByStation[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LocationOrdersProto message, length delimited. Does not implicitly {@link waiternow.common.LocationOrdersProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {waiternow.common.ILocationOrdersProto} message LocationOrdersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationOrdersProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationOrdersProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationOrdersProto} LocationOrdersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationOrdersProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationOrdersProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.orders && message.orders.length))
                                message.orders = [];
                            message.orders.push($root.waiternow.common.OrderProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    case 4: {
                            if (!(message.ordersSplitByStation && message.ordersSplitByStation.length))
                                message.ordersSplitByStation = [];
                            message.ordersSplitByStation.push($root.waiternow.common.OrderSplitByStationProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationOrdersProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationOrdersProto} LocationOrdersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationOrdersProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationOrdersProto message.
             * @function verify
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationOrdersProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.orders != null && message.hasOwnProperty("orders")) {
                    if (!Array.isArray(message.orders))
                        return "orders: array expected";
                    for (var i = 0; i < message.orders.length; ++i) {
                        var error = $root.waiternow.common.OrderProto.verify(message.orders[i]);
                        if (error)
                            return "orders." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                if (message.ordersSplitByStation != null && message.hasOwnProperty("ordersSplitByStation")) {
                    if (!Array.isArray(message.ordersSplitByStation))
                        return "ordersSplitByStation: array expected";
                    for (var i = 0; i < message.ordersSplitByStation.length; ++i) {
                        var error = $root.waiternow.common.OrderSplitByStationProto.verify(message.ordersSplitByStation[i]);
                        if (error)
                            return "ordersSplitByStation." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a LocationOrdersProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationOrdersProto} LocationOrdersProto
             */
            LocationOrdersProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationOrdersProto)
                    return object;
                var message = new $root.waiternow.common.LocationOrdersProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.orders) {
                    if (!Array.isArray(object.orders))
                        throw TypeError(".waiternow.common.LocationOrdersProto.orders: array expected");
                    message.orders = [];
                    for (var i = 0; i < object.orders.length; ++i) {
                        if (typeof object.orders[i] !== "object")
                            throw TypeError(".waiternow.common.LocationOrdersProto.orders: object expected");
                        message.orders[i] = $root.waiternow.common.OrderProto.fromObject(object.orders[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                if (object.ordersSplitByStation) {
                    if (!Array.isArray(object.ordersSplitByStation))
                        throw TypeError(".waiternow.common.LocationOrdersProto.ordersSplitByStation: array expected");
                    message.ordersSplitByStation = [];
                    for (var i = 0; i < object.ordersSplitByStation.length; ++i) {
                        if (typeof object.ordersSplitByStation[i] !== "object")
                            throw TypeError(".waiternow.common.LocationOrdersProto.ordersSplitByStation: object expected");
                        message.ordersSplitByStation[i] = $root.waiternow.common.OrderSplitByStationProto.fromObject(object.ordersSplitByStation[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a LocationOrdersProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {waiternow.common.LocationOrdersProto} message LocationOrdersProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationOrdersProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults) {
                    object.orders = [];
                    object.ordersSplitByStation = [];
                }
                if (options.defaults) {
                    object.locationId = "";
                    object.continuationToken = "";
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.orders && message.orders.length) {
                    object.orders = [];
                    for (var j = 0; j < message.orders.length; ++j)
                        object.orders[j] = $root.waiternow.common.OrderProto.toObject(message.orders[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                if (message.ordersSplitByStation && message.ordersSplitByStation.length) {
                    object.ordersSplitByStation = [];
                    for (var j = 0; j < message.ordersSplitByStation.length; ++j)
                        object.ordersSplitByStation[j] = $root.waiternow.common.OrderSplitByStationProto.toObject(message.ordersSplitByStation[j], options);
                }
                return object;
            };

            /**
             * Converts this LocationOrdersProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationOrdersProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationOrdersProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationOrdersProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationOrdersProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationOrdersProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationOrdersProto";
            };

            return LocationOrdersProto;
        })();

        common.DeviceOrdersProto = (function() {

            /**
             * Properties of a DeviceOrdersProto.
             * @memberof waiternow.common
             * @interface IDeviceOrdersProto
             * @property {string|null} [deviceId] DeviceOrdersProto deviceId
             * @property {Array.<waiternow.common.IOrderProto>|null} [orders] DeviceOrdersProto orders
             * @property {string|null} [continuationToken] DeviceOrdersProto continuationToken
             */

            /**
             * Constructs a new DeviceOrdersProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeviceOrdersProto.
             * @implements IDeviceOrdersProto
             * @constructor
             * @param {waiternow.common.IDeviceOrdersProto=} [properties] Properties to set
             */
            function DeviceOrdersProto(properties) {
                this.orders = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * DeviceOrdersProto deviceId.
             * @member {string} deviceId
             * @memberof waiternow.common.DeviceOrdersProto
             * @instance
             */
            DeviceOrdersProto.prototype.deviceId = "";

            /**
             * DeviceOrdersProto orders.
             * @member {Array.<waiternow.common.IOrderProto>} orders
             * @memberof waiternow.common.DeviceOrdersProto
             * @instance
             */
            DeviceOrdersProto.prototype.orders = $util.emptyArray;

            /**
             * DeviceOrdersProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.DeviceOrdersProto
             * @instance
             */
            DeviceOrdersProto.prototype.continuationToken = "";

            /**
             * Creates a new DeviceOrdersProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {waiternow.common.IDeviceOrdersProto=} [properties] Properties to set
             * @returns {waiternow.common.DeviceOrdersProto} DeviceOrdersProto instance
             */
            DeviceOrdersProto.create = function create(properties) {
                return new DeviceOrdersProto(properties);
            };

            /**
             * Encodes the specified DeviceOrdersProto message. Does not implicitly {@link waiternow.common.DeviceOrdersProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {waiternow.common.IDeviceOrdersProto} message DeviceOrdersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceOrdersProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                if (message.orders != null && message.orders.length)
                    for (var i = 0; i < message.orders.length; ++i)
                        $root.waiternow.common.OrderProto.encode(message.orders[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified DeviceOrdersProto message, length delimited. Does not implicitly {@link waiternow.common.DeviceOrdersProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {waiternow.common.IDeviceOrdersProto} message DeviceOrdersProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeviceOrdersProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeviceOrdersProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeviceOrdersProto} DeviceOrdersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceOrdersProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeviceOrdersProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.deviceId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.orders && message.orders.length))
                                message.orders = [];
                            message.orders.push($root.waiternow.common.OrderProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeviceOrdersProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeviceOrdersProto} DeviceOrdersProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeviceOrdersProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeviceOrdersProto message.
             * @function verify
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeviceOrdersProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    if (!$util.isString(message.deviceId))
                        return "deviceId: string expected";
                if (message.orders != null && message.hasOwnProperty("orders")) {
                    if (!Array.isArray(message.orders))
                        return "orders: array expected";
                    for (var i = 0; i < message.orders.length; ++i) {
                        var error = $root.waiternow.common.OrderProto.verify(message.orders[i]);
                        if (error)
                            return "orders." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a DeviceOrdersProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeviceOrdersProto} DeviceOrdersProto
             */
            DeviceOrdersProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeviceOrdersProto)
                    return object;
                var message = new $root.waiternow.common.DeviceOrdersProto();
                if (object.deviceId != null)
                    message.deviceId = String(object.deviceId);
                if (object.orders) {
                    if (!Array.isArray(object.orders))
                        throw TypeError(".waiternow.common.DeviceOrdersProto.orders: array expected");
                    message.orders = [];
                    for (var i = 0; i < object.orders.length; ++i) {
                        if (typeof object.orders[i] !== "object")
                            throw TypeError(".waiternow.common.DeviceOrdersProto.orders: object expected");
                        message.orders[i] = $root.waiternow.common.OrderProto.fromObject(object.orders[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a DeviceOrdersProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {waiternow.common.DeviceOrdersProto} message DeviceOrdersProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeviceOrdersProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.orders = [];
                if (options.defaults) {
                    object.deviceId = "";
                    object.continuationToken = "";
                }
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    object.deviceId = message.deviceId;
                if (message.orders && message.orders.length) {
                    object.orders = [];
                    for (var j = 0; j < message.orders.length; ++j)
                        object.orders[j] = $root.waiternow.common.OrderProto.toObject(message.orders[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this DeviceOrdersProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeviceOrdersProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeviceOrdersProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeviceOrdersProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeviceOrdersProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeviceOrdersProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeviceOrdersProto";
            };

            return DeviceOrdersProto;
        })();

        common.OrderCountProto = (function() {

            /**
             * Properties of an OrderCountProto.
             * @memberof waiternow.common
             * @interface IOrderCountProto
             * @property {string|null} [locationId] OrderCountProto locationId
             * @property {number|null} [orderCount] OrderCountProto orderCount
             * @property {string|null} [concurrencyToken] OrderCountProto concurrencyToken
             * @property {waiternow.common.IDateProto|null} [date] OrderCountProto date
             */

            /**
             * Constructs a new OrderCountProto.
             * @memberof waiternow.common
             * @classdesc Represents an OrderCountProto.
             * @implements IOrderCountProto
             * @constructor
             * @param {waiternow.common.IOrderCountProto=} [properties] Properties to set
             */
            function OrderCountProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderCountProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.OrderCountProto
             * @instance
             */
            OrderCountProto.prototype.locationId = "";

            /**
             * OrderCountProto orderCount.
             * @member {number} orderCount
             * @memberof waiternow.common.OrderCountProto
             * @instance
             */
            OrderCountProto.prototype.orderCount = 0;

            /**
             * OrderCountProto concurrencyToken.
             * @member {string} concurrencyToken
             * @memberof waiternow.common.OrderCountProto
             * @instance
             */
            OrderCountProto.prototype.concurrencyToken = "";

            /**
             * OrderCountProto date.
             * @member {waiternow.common.IDateProto|null|undefined} date
             * @memberof waiternow.common.OrderCountProto
             * @instance
             */
            OrderCountProto.prototype.date = null;

            /**
             * Creates a new OrderCountProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {waiternow.common.IOrderCountProto=} [properties] Properties to set
             * @returns {waiternow.common.OrderCountProto} OrderCountProto instance
             */
            OrderCountProto.create = function create(properties) {
                return new OrderCountProto(properties);
            };

            /**
             * Encodes the specified OrderCountProto message. Does not implicitly {@link waiternow.common.OrderCountProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {waiternow.common.IOrderCountProto} message OrderCountProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderCountProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.orderCount != null && Object.hasOwnProperty.call(message, "orderCount"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.orderCount);
                if (message.concurrencyToken != null && Object.hasOwnProperty.call(message, "concurrencyToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.concurrencyToken);
                if (message.date != null && Object.hasOwnProperty.call(message, "date"))
                    $root.waiternow.common.DateProto.encode(message.date, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OrderCountProto message, length delimited. Does not implicitly {@link waiternow.common.OrderCountProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {waiternow.common.IOrderCountProto} message OrderCountProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderCountProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrderCountProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OrderCountProto} OrderCountProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderCountProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderCountProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            message.orderCount = reader.int32();
                            break;
                        }
                    case 3: {
                            message.concurrencyToken = reader.string();
                            break;
                        }
                    case 4: {
                            message.date = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrderCountProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OrderCountProto} OrderCountProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderCountProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrderCountProto message.
             * @function verify
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderCountProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.orderCount != null && message.hasOwnProperty("orderCount"))
                    if (!$util.isInteger(message.orderCount))
                        return "orderCount: integer expected";
                if (message.concurrencyToken != null && message.hasOwnProperty("concurrencyToken"))
                    if (!$util.isString(message.concurrencyToken))
                        return "concurrencyToken: string expected";
                if (message.date != null && message.hasOwnProperty("date")) {
                    var error = $root.waiternow.common.DateProto.verify(message.date);
                    if (error)
                        return "date." + error;
                }
                return null;
            };

            /**
             * Creates an OrderCountProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OrderCountProto} OrderCountProto
             */
            OrderCountProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OrderCountProto)
                    return object;
                var message = new $root.waiternow.common.OrderCountProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.orderCount != null)
                    message.orderCount = object.orderCount | 0;
                if (object.concurrencyToken != null)
                    message.concurrencyToken = String(object.concurrencyToken);
                if (object.date != null) {
                    if (typeof object.date !== "object")
                        throw TypeError(".waiternow.common.OrderCountProto.date: object expected");
                    message.date = $root.waiternow.common.DateProto.fromObject(object.date);
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderCountProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {waiternow.common.OrderCountProto} message OrderCountProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderCountProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.locationId = "";
                    object.orderCount = 0;
                    object.concurrencyToken = "";
                    object.date = null;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.orderCount != null && message.hasOwnProperty("orderCount"))
                    object.orderCount = message.orderCount;
                if (message.concurrencyToken != null && message.hasOwnProperty("concurrencyToken"))
                    object.concurrencyToken = message.concurrencyToken;
                if (message.date != null && message.hasOwnProperty("date"))
                    object.date = $root.waiternow.common.DateProto.toObject(message.date, options);
                return object;
            };

            /**
             * Converts this OrderCountProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OrderCountProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderCountProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrderCountProto
             * @function getTypeUrl
             * @memberof waiternow.common.OrderCountProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrderCountProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OrderCountProto";
            };

            return OrderCountProto;
        })();

        common.OrderMaybeSplitByStationProto = (function() {

            /**
             * Properties of an OrderMaybeSplitByStationProto.
             * @memberof waiternow.common
             * @interface IOrderMaybeSplitByStationProto
             * @property {waiternow.common.IOrderProto|null} [order] OrderMaybeSplitByStationProto order
             * @property {waiternow.common.IOrderSplitByStationProto|null} [orderSplitByStation] OrderMaybeSplitByStationProto orderSplitByStation
             */

            /**
             * Constructs a new OrderMaybeSplitByStationProto.
             * @memberof waiternow.common
             * @classdesc Represents an OrderMaybeSplitByStationProto.
             * @implements IOrderMaybeSplitByStationProto
             * @constructor
             * @param {waiternow.common.IOrderMaybeSplitByStationProto=} [properties] Properties to set
             */
            function OrderMaybeSplitByStationProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * OrderMaybeSplitByStationProto order.
             * @member {waiternow.common.IOrderProto|null|undefined} order
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @instance
             */
            OrderMaybeSplitByStationProto.prototype.order = null;

            /**
             * OrderMaybeSplitByStationProto orderSplitByStation.
             * @member {waiternow.common.IOrderSplitByStationProto|null|undefined} orderSplitByStation
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @instance
             */
            OrderMaybeSplitByStationProto.prototype.orderSplitByStation = null;

            /**
             * Creates a new OrderMaybeSplitByStationProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {waiternow.common.IOrderMaybeSplitByStationProto=} [properties] Properties to set
             * @returns {waiternow.common.OrderMaybeSplitByStationProto} OrderMaybeSplitByStationProto instance
             */
            OrderMaybeSplitByStationProto.create = function create(properties) {
                return new OrderMaybeSplitByStationProto(properties);
            };

            /**
             * Encodes the specified OrderMaybeSplitByStationProto message. Does not implicitly {@link waiternow.common.OrderMaybeSplitByStationProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {waiternow.common.IOrderMaybeSplitByStationProto} message OrderMaybeSplitByStationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderMaybeSplitByStationProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                    $root.waiternow.common.OrderProto.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.orderSplitByStation != null && Object.hasOwnProperty.call(message, "orderSplitByStation"))
                    $root.waiternow.common.OrderSplitByStationProto.encode(message.orderSplitByStation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified OrderMaybeSplitByStationProto message, length delimited. Does not implicitly {@link waiternow.common.OrderMaybeSplitByStationProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {waiternow.common.IOrderMaybeSplitByStationProto} message OrderMaybeSplitByStationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            OrderMaybeSplitByStationProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an OrderMaybeSplitByStationProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.OrderMaybeSplitByStationProto} OrderMaybeSplitByStationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderMaybeSplitByStationProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.OrderMaybeSplitByStationProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.order = $root.waiternow.common.OrderProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.orderSplitByStation = $root.waiternow.common.OrderSplitByStationProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an OrderMaybeSplitByStationProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.OrderMaybeSplitByStationProto} OrderMaybeSplitByStationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            OrderMaybeSplitByStationProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an OrderMaybeSplitByStationProto message.
             * @function verify
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            OrderMaybeSplitByStationProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.order != null && message.hasOwnProperty("order")) {
                    var error = $root.waiternow.common.OrderProto.verify(message.order);
                    if (error)
                        return "order." + error;
                }
                if (message.orderSplitByStation != null && message.hasOwnProperty("orderSplitByStation")) {
                    var error = $root.waiternow.common.OrderSplitByStationProto.verify(message.orderSplitByStation);
                    if (error)
                        return "orderSplitByStation." + error;
                }
                return null;
            };

            /**
             * Creates an OrderMaybeSplitByStationProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.OrderMaybeSplitByStationProto} OrderMaybeSplitByStationProto
             */
            OrderMaybeSplitByStationProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.OrderMaybeSplitByStationProto)
                    return object;
                var message = new $root.waiternow.common.OrderMaybeSplitByStationProto();
                if (object.order != null) {
                    if (typeof object.order !== "object")
                        throw TypeError(".waiternow.common.OrderMaybeSplitByStationProto.order: object expected");
                    message.order = $root.waiternow.common.OrderProto.fromObject(object.order);
                }
                if (object.orderSplitByStation != null) {
                    if (typeof object.orderSplitByStation !== "object")
                        throw TypeError(".waiternow.common.OrderMaybeSplitByStationProto.orderSplitByStation: object expected");
                    message.orderSplitByStation = $root.waiternow.common.OrderSplitByStationProto.fromObject(object.orderSplitByStation);
                }
                return message;
            };

            /**
             * Creates a plain object from an OrderMaybeSplitByStationProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {waiternow.common.OrderMaybeSplitByStationProto} message OrderMaybeSplitByStationProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            OrderMaybeSplitByStationProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.order = null;
                    object.orderSplitByStation = null;
                }
                if (message.order != null && message.hasOwnProperty("order"))
                    object.order = $root.waiternow.common.OrderProto.toObject(message.order, options);
                if (message.orderSplitByStation != null && message.hasOwnProperty("orderSplitByStation"))
                    object.orderSplitByStation = $root.waiternow.common.OrderSplitByStationProto.toObject(message.orderSplitByStation, options);
                return object;
            };

            /**
             * Converts this OrderMaybeSplitByStationProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            OrderMaybeSplitByStationProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for OrderMaybeSplitByStationProto
             * @function getTypeUrl
             * @memberof waiternow.common.OrderMaybeSplitByStationProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            OrderMaybeSplitByStationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.OrderMaybeSplitByStationProto";
            };

            return OrderMaybeSplitByStationProto;
        })();

        common.SalesSummaryReportProto = (function() {

            /**
             * Properties of a SalesSummaryReportProto.
             * @memberof waiternow.common
             * @interface ISalesSummaryReportProto
             * @property {string|null} [locationId] SalesSummaryReportProto locationId
             * @property {waiternow.common.IDateRangeProto|null} [period] SalesSummaryReportProto period
             * @property {number|null} [orders] SalesSummaryReportProto orders
             * @property {number|null} [partiallyRefundedOrders] SalesSummaryReportProto partiallyRefundedOrders
             * @property {number|null} [refundedOrders] SalesSummaryReportProto refundedOrders
             * @property {waiternow.common.IMoneyProto|null} [subtotal] SalesSummaryReportProto subtotal
             * @property {waiternow.common.IMoneyProto|null} [discounts] SalesSummaryReportProto discounts
             * @property {waiternow.common.IMoneyProto|null} [tax] SalesSummaryReportProto tax
             * @property {waiternow.common.IMoneyProto|null} [tips] SalesSummaryReportProto tips
             * @property {waiternow.common.IMoneyProto|null} [deliveryFeesSelf] SalesSummaryReportProto deliveryFeesSelf
             * @property {waiternow.common.IMoneyProto|null} [deliveryTipsSelf] SalesSummaryReportProto deliveryTipsSelf
             * @property {waiternow.common.IMoneyProto|null} [deliveryFeesDoorDash] SalesSummaryReportProto deliveryFeesDoorDash
             * @property {waiternow.common.IMoneyProto|null} [deliveryTipsDoorDash] SalesSummaryReportProto deliveryTipsDoorDash
             * @property {waiternow.common.IMoneyProto|null} [creditCardFeeAdjustment] SalesSummaryReportProto creditCardFeeAdjustment
             * @property {waiternow.common.IMoneyProto|null} [stripeFee] SalesSummaryReportProto stripeFee
             * @property {waiternow.common.IMoneyProto|null} [waiterNowFee] SalesSummaryReportProto waiterNowFee
             * @property {waiternow.common.IMoneyProto|null} [total] SalesSummaryReportProto total
             * @property {waiternow.common.IMoneyProto|null} [netPaidToBusiness] SalesSummaryReportProto netPaidToBusiness
             * @property {waiternow.common.IMoneyProto|null} [refunds] SalesSummaryReportProto refunds
             */

            /**
             * Constructs a new SalesSummaryReportProto.
             * @memberof waiternow.common
             * @classdesc Represents a SalesSummaryReportProto.
             * @implements ISalesSummaryReportProto
             * @constructor
             * @param {waiternow.common.ISalesSummaryReportProto=} [properties] Properties to set
             */
            function SalesSummaryReportProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SalesSummaryReportProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.locationId = "";

            /**
             * SalesSummaryReportProto period.
             * @member {waiternow.common.IDateRangeProto|null|undefined} period
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.period = null;

            /**
             * SalesSummaryReportProto orders.
             * @member {number} orders
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.orders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SalesSummaryReportProto partiallyRefundedOrders.
             * @member {number} partiallyRefundedOrders
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.partiallyRefundedOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SalesSummaryReportProto refundedOrders.
             * @member {number} refundedOrders
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.refundedOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * SalesSummaryReportProto subtotal.
             * @member {waiternow.common.IMoneyProto|null|undefined} subtotal
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.subtotal = null;

            /**
             * SalesSummaryReportProto discounts.
             * @member {waiternow.common.IMoneyProto|null|undefined} discounts
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.discounts = null;

            /**
             * SalesSummaryReportProto tax.
             * @member {waiternow.common.IMoneyProto|null|undefined} tax
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.tax = null;

            /**
             * SalesSummaryReportProto tips.
             * @member {waiternow.common.IMoneyProto|null|undefined} tips
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.tips = null;

            /**
             * SalesSummaryReportProto deliveryFeesSelf.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryFeesSelf
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.deliveryFeesSelf = null;

            /**
             * SalesSummaryReportProto deliveryTipsSelf.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryTipsSelf
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.deliveryTipsSelf = null;

            /**
             * SalesSummaryReportProto deliveryFeesDoorDash.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryFeesDoorDash
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.deliveryFeesDoorDash = null;

            /**
             * SalesSummaryReportProto deliveryTipsDoorDash.
             * @member {waiternow.common.IMoneyProto|null|undefined} deliveryTipsDoorDash
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.deliveryTipsDoorDash = null;

            /**
             * SalesSummaryReportProto creditCardFeeAdjustment.
             * @member {waiternow.common.IMoneyProto|null|undefined} creditCardFeeAdjustment
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.creditCardFeeAdjustment = null;

            /**
             * SalesSummaryReportProto stripeFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} stripeFee
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.stripeFee = null;

            /**
             * SalesSummaryReportProto waiterNowFee.
             * @member {waiternow.common.IMoneyProto|null|undefined} waiterNowFee
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.waiterNowFee = null;

            /**
             * SalesSummaryReportProto total.
             * @member {waiternow.common.IMoneyProto|null|undefined} total
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.total = null;

            /**
             * SalesSummaryReportProto netPaidToBusiness.
             * @member {waiternow.common.IMoneyProto|null|undefined} netPaidToBusiness
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.netPaidToBusiness = null;

            /**
             * SalesSummaryReportProto refunds.
             * @member {waiternow.common.IMoneyProto|null|undefined} refunds
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             */
            SalesSummaryReportProto.prototype.refunds = null;

            /**
             * Creates a new SalesSummaryReportProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {waiternow.common.ISalesSummaryReportProto=} [properties] Properties to set
             * @returns {waiternow.common.SalesSummaryReportProto} SalesSummaryReportProto instance
             */
            SalesSummaryReportProto.create = function create(properties) {
                return new SalesSummaryReportProto(properties);
            };

            /**
             * Encodes the specified SalesSummaryReportProto message. Does not implicitly {@link waiternow.common.SalesSummaryReportProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {waiternow.common.ISalesSummaryReportProto} message SalesSummaryReportProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SalesSummaryReportProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                    $root.waiternow.common.DateRangeProto.encode(message.period, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int64(message.orders);
                if (message.partiallyRefundedOrders != null && Object.hasOwnProperty.call(message, "partiallyRefundedOrders"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int64(message.partiallyRefundedOrders);
                if (message.refundedOrders != null && Object.hasOwnProperty.call(message, "refundedOrders"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int64(message.refundedOrders);
                if (message.subtotal != null && Object.hasOwnProperty.call(message, "subtotal"))
                    $root.waiternow.common.MoneyProto.encode(message.subtotal, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.discounts != null && Object.hasOwnProperty.call(message, "discounts"))
                    $root.waiternow.common.MoneyProto.encode(message.discounts, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.tax != null && Object.hasOwnProperty.call(message, "tax"))
                    $root.waiternow.common.MoneyProto.encode(message.tax, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.tips != null && Object.hasOwnProperty.call(message, "tips"))
                    $root.waiternow.common.MoneyProto.encode(message.tips, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.deliveryFeesSelf != null && Object.hasOwnProperty.call(message, "deliveryFeesSelf"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryFeesSelf, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.deliveryTipsSelf != null && Object.hasOwnProperty.call(message, "deliveryTipsSelf"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryTipsSelf, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.deliveryFeesDoorDash != null && Object.hasOwnProperty.call(message, "deliveryFeesDoorDash"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryFeesDoorDash, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.deliveryTipsDoorDash != null && Object.hasOwnProperty.call(message, "deliveryTipsDoorDash"))
                    $root.waiternow.common.MoneyProto.encode(message.deliveryTipsDoorDash, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.creditCardFeeAdjustment != null && Object.hasOwnProperty.call(message, "creditCardFeeAdjustment"))
                    $root.waiternow.common.MoneyProto.encode(message.creditCardFeeAdjustment, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.stripeFee != null && Object.hasOwnProperty.call(message, "stripeFee"))
                    $root.waiternow.common.MoneyProto.encode(message.stripeFee, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.waiterNowFee != null && Object.hasOwnProperty.call(message, "waiterNowFee"))
                    $root.waiternow.common.MoneyProto.encode(message.waiterNowFee, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.total != null && Object.hasOwnProperty.call(message, "total"))
                    $root.waiternow.common.MoneyProto.encode(message.total, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.netPaidToBusiness != null && Object.hasOwnProperty.call(message, "netPaidToBusiness"))
                    $root.waiternow.common.MoneyProto.encode(message.netPaidToBusiness, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.refunds != null && Object.hasOwnProperty.call(message, "refunds"))
                    $root.waiternow.common.MoneyProto.encode(message.refunds, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SalesSummaryReportProto message, length delimited. Does not implicitly {@link waiternow.common.SalesSummaryReportProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {waiternow.common.ISalesSummaryReportProto} message SalesSummaryReportProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SalesSummaryReportProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SalesSummaryReportProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SalesSummaryReportProto} SalesSummaryReportProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SalesSummaryReportProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SalesSummaryReportProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            message.period = $root.waiternow.common.DateRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.orders = reader.int64();
                            break;
                        }
                    case 4: {
                            message.partiallyRefundedOrders = reader.int64();
                            break;
                        }
                    case 5: {
                            message.refundedOrders = reader.int64();
                            break;
                        }
                    case 6: {
                            message.subtotal = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 7: {
                            message.discounts = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.tax = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.tips = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.deliveryFeesSelf = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.deliveryTipsSelf = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.deliveryFeesDoorDash = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.deliveryTipsDoorDash = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.stripeFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.waiterNowFee = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 17: {
                            message.total = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.netPaidToBusiness = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            message.refunds = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SalesSummaryReportProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SalesSummaryReportProto} SalesSummaryReportProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SalesSummaryReportProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SalesSummaryReportProto message.
             * @function verify
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SalesSummaryReportProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.period != null && message.hasOwnProperty("period")) {
                    var error = $root.waiternow.common.DateRangeProto.verify(message.period);
                    if (error)
                        return "period." + error;
                }
                if (message.orders != null && message.hasOwnProperty("orders"))
                    if (!$util.isInteger(message.orders) && !(message.orders && $util.isInteger(message.orders.low) && $util.isInteger(message.orders.high)))
                        return "orders: integer|Long expected";
                if (message.partiallyRefundedOrders != null && message.hasOwnProperty("partiallyRefundedOrders"))
                    if (!$util.isInteger(message.partiallyRefundedOrders) && !(message.partiallyRefundedOrders && $util.isInteger(message.partiallyRefundedOrders.low) && $util.isInteger(message.partiallyRefundedOrders.high)))
                        return "partiallyRefundedOrders: integer|Long expected";
                if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                    if (!$util.isInteger(message.refundedOrders) && !(message.refundedOrders && $util.isInteger(message.refundedOrders.low) && $util.isInteger(message.refundedOrders.high)))
                        return "refundedOrders: integer|Long expected";
                if (message.subtotal != null && message.hasOwnProperty("subtotal")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.subtotal);
                    if (error)
                        return "subtotal." + error;
                }
                if (message.discounts != null && message.hasOwnProperty("discounts")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.discounts);
                    if (error)
                        return "discounts." + error;
                }
                if (message.tax != null && message.hasOwnProperty("tax")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.tax);
                    if (error)
                        return "tax." + error;
                }
                if (message.tips != null && message.hasOwnProperty("tips")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.tips);
                    if (error)
                        return "tips." + error;
                }
                if (message.deliveryFeesSelf != null && message.hasOwnProperty("deliveryFeesSelf")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryFeesSelf);
                    if (error)
                        return "deliveryFeesSelf." + error;
                }
                if (message.deliveryTipsSelf != null && message.hasOwnProperty("deliveryTipsSelf")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryTipsSelf);
                    if (error)
                        return "deliveryTipsSelf." + error;
                }
                if (message.deliveryFeesDoorDash != null && message.hasOwnProperty("deliveryFeesDoorDash")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryFeesDoorDash);
                    if (error)
                        return "deliveryFeesDoorDash." + error;
                }
                if (message.deliveryTipsDoorDash != null && message.hasOwnProperty("deliveryTipsDoorDash")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.deliveryTipsDoorDash);
                    if (error)
                        return "deliveryTipsDoorDash." + error;
                }
                if (message.creditCardFeeAdjustment != null && message.hasOwnProperty("creditCardFeeAdjustment")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.creditCardFeeAdjustment);
                    if (error)
                        return "creditCardFeeAdjustment." + error;
                }
                if (message.stripeFee != null && message.hasOwnProperty("stripeFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.stripeFee);
                    if (error)
                        return "stripeFee." + error;
                }
                if (message.waiterNowFee != null && message.hasOwnProperty("waiterNowFee")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.waiterNowFee);
                    if (error)
                        return "waiterNowFee." + error;
                }
                if (message.total != null && message.hasOwnProperty("total")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.total);
                    if (error)
                        return "total." + error;
                }
                if (message.netPaidToBusiness != null && message.hasOwnProperty("netPaidToBusiness")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.netPaidToBusiness);
                    if (error)
                        return "netPaidToBusiness." + error;
                }
                if (message.refunds != null && message.hasOwnProperty("refunds")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.refunds);
                    if (error)
                        return "refunds." + error;
                }
                return null;
            };

            /**
             * Creates a SalesSummaryReportProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SalesSummaryReportProto} SalesSummaryReportProto
             */
            SalesSummaryReportProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SalesSummaryReportProto)
                    return object;
                var message = new $root.waiternow.common.SalesSummaryReportProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.period != null) {
                    if (typeof object.period !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.period: object expected");
                    message.period = $root.waiternow.common.DateRangeProto.fromObject(object.period);
                }
                if (object.orders != null)
                    if ($util.Long)
                        (message.orders = $util.Long.fromValue(object.orders)).unsigned = false;
                    else if (typeof object.orders === "string")
                        message.orders = parseInt(object.orders, 10);
                    else if (typeof object.orders === "number")
                        message.orders = object.orders;
                    else if (typeof object.orders === "object")
                        message.orders = new $util.LongBits(object.orders.low >>> 0, object.orders.high >>> 0).toNumber();
                if (object.partiallyRefundedOrders != null)
                    if ($util.Long)
                        (message.partiallyRefundedOrders = $util.Long.fromValue(object.partiallyRefundedOrders)).unsigned = false;
                    else if (typeof object.partiallyRefundedOrders === "string")
                        message.partiallyRefundedOrders = parseInt(object.partiallyRefundedOrders, 10);
                    else if (typeof object.partiallyRefundedOrders === "number")
                        message.partiallyRefundedOrders = object.partiallyRefundedOrders;
                    else if (typeof object.partiallyRefundedOrders === "object")
                        message.partiallyRefundedOrders = new $util.LongBits(object.partiallyRefundedOrders.low >>> 0, object.partiallyRefundedOrders.high >>> 0).toNumber();
                if (object.refundedOrders != null)
                    if ($util.Long)
                        (message.refundedOrders = $util.Long.fromValue(object.refundedOrders)).unsigned = false;
                    else if (typeof object.refundedOrders === "string")
                        message.refundedOrders = parseInt(object.refundedOrders, 10);
                    else if (typeof object.refundedOrders === "number")
                        message.refundedOrders = object.refundedOrders;
                    else if (typeof object.refundedOrders === "object")
                        message.refundedOrders = new $util.LongBits(object.refundedOrders.low >>> 0, object.refundedOrders.high >>> 0).toNumber();
                if (object.subtotal != null) {
                    if (typeof object.subtotal !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.subtotal: object expected");
                    message.subtotal = $root.waiternow.common.MoneyProto.fromObject(object.subtotal);
                }
                if (object.discounts != null) {
                    if (typeof object.discounts !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.discounts: object expected");
                    message.discounts = $root.waiternow.common.MoneyProto.fromObject(object.discounts);
                }
                if (object.tax != null) {
                    if (typeof object.tax !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.tax: object expected");
                    message.tax = $root.waiternow.common.MoneyProto.fromObject(object.tax);
                }
                if (object.tips != null) {
                    if (typeof object.tips !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.tips: object expected");
                    message.tips = $root.waiternow.common.MoneyProto.fromObject(object.tips);
                }
                if (object.deliveryFeesSelf != null) {
                    if (typeof object.deliveryFeesSelf !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.deliveryFeesSelf: object expected");
                    message.deliveryFeesSelf = $root.waiternow.common.MoneyProto.fromObject(object.deliveryFeesSelf);
                }
                if (object.deliveryTipsSelf != null) {
                    if (typeof object.deliveryTipsSelf !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.deliveryTipsSelf: object expected");
                    message.deliveryTipsSelf = $root.waiternow.common.MoneyProto.fromObject(object.deliveryTipsSelf);
                }
                if (object.deliveryFeesDoorDash != null) {
                    if (typeof object.deliveryFeesDoorDash !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.deliveryFeesDoorDash: object expected");
                    message.deliveryFeesDoorDash = $root.waiternow.common.MoneyProto.fromObject(object.deliveryFeesDoorDash);
                }
                if (object.deliveryTipsDoorDash != null) {
                    if (typeof object.deliveryTipsDoorDash !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.deliveryTipsDoorDash: object expected");
                    message.deliveryTipsDoorDash = $root.waiternow.common.MoneyProto.fromObject(object.deliveryTipsDoorDash);
                }
                if (object.creditCardFeeAdjustment != null) {
                    if (typeof object.creditCardFeeAdjustment !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.creditCardFeeAdjustment: object expected");
                    message.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.fromObject(object.creditCardFeeAdjustment);
                }
                if (object.stripeFee != null) {
                    if (typeof object.stripeFee !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.stripeFee: object expected");
                    message.stripeFee = $root.waiternow.common.MoneyProto.fromObject(object.stripeFee);
                }
                if (object.waiterNowFee != null) {
                    if (typeof object.waiterNowFee !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.waiterNowFee: object expected");
                    message.waiterNowFee = $root.waiternow.common.MoneyProto.fromObject(object.waiterNowFee);
                }
                if (object.total != null) {
                    if (typeof object.total !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.total: object expected");
                    message.total = $root.waiternow.common.MoneyProto.fromObject(object.total);
                }
                if (object.netPaidToBusiness != null) {
                    if (typeof object.netPaidToBusiness !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.netPaidToBusiness: object expected");
                    message.netPaidToBusiness = $root.waiternow.common.MoneyProto.fromObject(object.netPaidToBusiness);
                }
                if (object.refunds != null) {
                    if (typeof object.refunds !== "object")
                        throw TypeError(".waiternow.common.SalesSummaryReportProto.refunds: object expected");
                    message.refunds = $root.waiternow.common.MoneyProto.fromObject(object.refunds);
                }
                return message;
            };

            /**
             * Creates a plain object from a SalesSummaryReportProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {waiternow.common.SalesSummaryReportProto} message SalesSummaryReportProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SalesSummaryReportProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.locationId = "";
                    object.period = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.partiallyRefundedOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.partiallyRefundedOrders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.refundedOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.refundedOrders = options.longs === String ? "0" : 0;
                    object.subtotal = null;
                    object.discounts = null;
                    object.tax = null;
                    object.tips = null;
                    object.deliveryFeesSelf = null;
                    object.deliveryTipsSelf = null;
                    object.deliveryFeesDoorDash = null;
                    object.deliveryTipsDoorDash = null;
                    object.creditCardFeeAdjustment = null;
                    object.stripeFee = null;
                    object.waiterNowFee = null;
                    object.total = null;
                    object.netPaidToBusiness = null;
                    object.refunds = null;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.period != null && message.hasOwnProperty("period"))
                    object.period = $root.waiternow.common.DateRangeProto.toObject(message.period, options);
                if (message.orders != null && message.hasOwnProperty("orders"))
                    if (typeof message.orders === "number")
                        object.orders = options.longs === String ? String(message.orders) : message.orders;
                    else
                        object.orders = options.longs === String ? $util.Long.prototype.toString.call(message.orders) : options.longs === Number ? new $util.LongBits(message.orders.low >>> 0, message.orders.high >>> 0).toNumber() : message.orders;
                if (message.partiallyRefundedOrders != null && message.hasOwnProperty("partiallyRefundedOrders"))
                    if (typeof message.partiallyRefundedOrders === "number")
                        object.partiallyRefundedOrders = options.longs === String ? String(message.partiallyRefundedOrders) : message.partiallyRefundedOrders;
                    else
                        object.partiallyRefundedOrders = options.longs === String ? $util.Long.prototype.toString.call(message.partiallyRefundedOrders) : options.longs === Number ? new $util.LongBits(message.partiallyRefundedOrders.low >>> 0, message.partiallyRefundedOrders.high >>> 0).toNumber() : message.partiallyRefundedOrders;
                if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                    if (typeof message.refundedOrders === "number")
                        object.refundedOrders = options.longs === String ? String(message.refundedOrders) : message.refundedOrders;
                    else
                        object.refundedOrders = options.longs === String ? $util.Long.prototype.toString.call(message.refundedOrders) : options.longs === Number ? new $util.LongBits(message.refundedOrders.low >>> 0, message.refundedOrders.high >>> 0).toNumber() : message.refundedOrders;
                if (message.subtotal != null && message.hasOwnProperty("subtotal"))
                    object.subtotal = $root.waiternow.common.MoneyProto.toObject(message.subtotal, options);
                if (message.discounts != null && message.hasOwnProperty("discounts"))
                    object.discounts = $root.waiternow.common.MoneyProto.toObject(message.discounts, options);
                if (message.tax != null && message.hasOwnProperty("tax"))
                    object.tax = $root.waiternow.common.MoneyProto.toObject(message.tax, options);
                if (message.tips != null && message.hasOwnProperty("tips"))
                    object.tips = $root.waiternow.common.MoneyProto.toObject(message.tips, options);
                if (message.deliveryFeesSelf != null && message.hasOwnProperty("deliveryFeesSelf"))
                    object.deliveryFeesSelf = $root.waiternow.common.MoneyProto.toObject(message.deliveryFeesSelf, options);
                if (message.deliveryTipsSelf != null && message.hasOwnProperty("deliveryTipsSelf"))
                    object.deliveryTipsSelf = $root.waiternow.common.MoneyProto.toObject(message.deliveryTipsSelf, options);
                if (message.deliveryFeesDoorDash != null && message.hasOwnProperty("deliveryFeesDoorDash"))
                    object.deliveryFeesDoorDash = $root.waiternow.common.MoneyProto.toObject(message.deliveryFeesDoorDash, options);
                if (message.deliveryTipsDoorDash != null && message.hasOwnProperty("deliveryTipsDoorDash"))
                    object.deliveryTipsDoorDash = $root.waiternow.common.MoneyProto.toObject(message.deliveryTipsDoorDash, options);
                if (message.creditCardFeeAdjustment != null && message.hasOwnProperty("creditCardFeeAdjustment"))
                    object.creditCardFeeAdjustment = $root.waiternow.common.MoneyProto.toObject(message.creditCardFeeAdjustment, options);
                if (message.stripeFee != null && message.hasOwnProperty("stripeFee"))
                    object.stripeFee = $root.waiternow.common.MoneyProto.toObject(message.stripeFee, options);
                if (message.waiterNowFee != null && message.hasOwnProperty("waiterNowFee"))
                    object.waiterNowFee = $root.waiternow.common.MoneyProto.toObject(message.waiterNowFee, options);
                if (message.total != null && message.hasOwnProperty("total"))
                    object.total = $root.waiternow.common.MoneyProto.toObject(message.total, options);
                if (message.netPaidToBusiness != null && message.hasOwnProperty("netPaidToBusiness"))
                    object.netPaidToBusiness = $root.waiternow.common.MoneyProto.toObject(message.netPaidToBusiness, options);
                if (message.refunds != null && message.hasOwnProperty("refunds"))
                    object.refunds = $root.waiternow.common.MoneyProto.toObject(message.refunds, options);
                return object;
            };

            /**
             * Converts this SalesSummaryReportProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SalesSummaryReportProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SalesSummaryReportProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SalesSummaryReportProto
             * @function getTypeUrl
             * @memberof waiternow.common.SalesSummaryReportProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SalesSummaryReportProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SalesSummaryReportProto";
            };

            return SalesSummaryReportProto;
        })();

        common.PointOfServiceProto = (function() {

            /**
             * Properties of a PointOfServiceProto.
             * @memberof waiternow.common
             * @interface IPointOfServiceProto
             * @property {string|null} [id] PointOfServiceProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] PointOfServiceProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] PointOfServiceProto lastUpdateTime
             * @property {string|null} [locationId] PointOfServiceProto locationId
             * @property {string|null} [friendlyName] PointOfServiceProto friendlyName
             * @property {waiternow.common.PointOfServiceProto.PointOfServiceType|null} [type] PointOfServiceProto type
             * @property {waiternow.common.PointOfServiceProto.IRedundantDataProto|null} [redundantData] PointOfServiceProto redundantData
             * @property {string|null} [vanityUrl] PointOfServiceProto vanityUrl
             */

            /**
             * Constructs a new PointOfServiceProto.
             * @memberof waiternow.common
             * @classdesc Represents a PointOfServiceProto.
             * @implements IPointOfServiceProto
             * @constructor
             * @param {waiternow.common.IPointOfServiceProto=} [properties] Properties to set
             */
            function PointOfServiceProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PointOfServiceProto id.
             * @member {string} id
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.id = "";

            /**
             * PointOfServiceProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.creationTime = null;

            /**
             * PointOfServiceProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.lastUpdateTime = null;

            /**
             * PointOfServiceProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.locationId = "";

            /**
             * PointOfServiceProto friendlyName.
             * @member {string} friendlyName
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.friendlyName = "";

            /**
             * PointOfServiceProto type.
             * @member {waiternow.common.PointOfServiceProto.PointOfServiceType} type
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.type = 0;

            /**
             * PointOfServiceProto redundantData.
             * @member {waiternow.common.PointOfServiceProto.IRedundantDataProto|null|undefined} redundantData
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.redundantData = null;

            /**
             * PointOfServiceProto vanityUrl.
             * @member {string} vanityUrl
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             */
            PointOfServiceProto.prototype.vanityUrl = "";

            /**
             * Creates a new PointOfServiceProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {waiternow.common.IPointOfServiceProto=} [properties] Properties to set
             * @returns {waiternow.common.PointOfServiceProto} PointOfServiceProto instance
             */
            PointOfServiceProto.create = function create(properties) {
                return new PointOfServiceProto(properties);
            };

            /**
             * Encodes the specified PointOfServiceProto message. Does not implicitly {@link waiternow.common.PointOfServiceProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {waiternow.common.IPointOfServiceProto} message PointOfServiceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PointOfServiceProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                if (message.friendlyName != null && Object.hasOwnProperty.call(message, "friendlyName"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.friendlyName);
                if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                    writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                if (message.redundantData != null && Object.hasOwnProperty.call(message, "redundantData"))
                    $root.waiternow.common.PointOfServiceProto.RedundantDataProto.encode(message.redundantData, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.vanityUrl != null && Object.hasOwnProperty.call(message, "vanityUrl"))
                    writer.uint32(/* id 8, wireType 2 =*/66).string(message.vanityUrl);
                return writer;
            };

            /**
             * Encodes the specified PointOfServiceProto message, length delimited. Does not implicitly {@link waiternow.common.PointOfServiceProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {waiternow.common.IPointOfServiceProto} message PointOfServiceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PointOfServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PointOfServiceProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PointOfServiceProto} PointOfServiceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PointOfServiceProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PointOfServiceProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 5: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 3: {
                            message.friendlyName = reader.string();
                            break;
                        }
                    case 4: {
                            message.type = reader.int32();
                            break;
                        }
                    case 7: {
                            message.redundantData = $root.waiternow.common.PointOfServiceProto.RedundantDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.vanityUrl = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PointOfServiceProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PointOfServiceProto} PointOfServiceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PointOfServiceProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PointOfServiceProto message.
             * @function verify
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PointOfServiceProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.friendlyName != null && message.hasOwnProperty("friendlyName"))
                    if (!$util.isString(message.friendlyName))
                        return "friendlyName: string expected";
                if (message.type != null && message.hasOwnProperty("type"))
                    switch (message.type) {
                    default:
                        return "type: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.redundantData != null && message.hasOwnProperty("redundantData")) {
                    var error = $root.waiternow.common.PointOfServiceProto.RedundantDataProto.verify(message.redundantData);
                    if (error)
                        return "redundantData." + error;
                }
                if (message.vanityUrl != null && message.hasOwnProperty("vanityUrl"))
                    if (!$util.isString(message.vanityUrl))
                        return "vanityUrl: string expected";
                return null;
            };

            /**
             * Creates a PointOfServiceProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PointOfServiceProto} PointOfServiceProto
             */
            PointOfServiceProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PointOfServiceProto)
                    return object;
                var message = new $root.waiternow.common.PointOfServiceProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.PointOfServiceProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.PointOfServiceProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.friendlyName != null)
                    message.friendlyName = String(object.friendlyName);
                switch (object.type) {
                default:
                    if (typeof object.type === "number") {
                        message.type = object.type;
                        break;
                    }
                    break;
                case "POINT_OF_SERVICE_TYPE_UNSPECIFIED":
                case 0:
                    message.type = 0;
                    break;
                case "TABLE":
                case 1:
                    message.type = 1;
                    break;
                case "ROOM":
                case 2:
                    message.type = 2;
                    break;
                case "TERRACE":
                case 3:
                    message.type = 3;
                    break;
                case "POOL":
                case 4:
                    message.type = 4;
                    break;
                case "MENU":
                case 5:
                    message.type = 5;
                    break;
                case "ONLINE_PICKUP":
                case 6:
                    message.type = 6;
                    break;
                case "ONSITE_PICKUP":
                case 7:
                    message.type = 7;
                    break;
                case "ONLINE":
                case 8:
                    message.type = 8;
                    break;
                }
                if (object.redundantData != null) {
                    if (typeof object.redundantData !== "object")
                        throw TypeError(".waiternow.common.PointOfServiceProto.redundantData: object expected");
                    message.redundantData = $root.waiternow.common.PointOfServiceProto.RedundantDataProto.fromObject(object.redundantData);
                }
                if (object.vanityUrl != null)
                    message.vanityUrl = String(object.vanityUrl);
                return message;
            };

            /**
             * Creates a plain object from a PointOfServiceProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {waiternow.common.PointOfServiceProto} message PointOfServiceProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PointOfServiceProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.locationId = "";
                    object.friendlyName = "";
                    object.type = options.enums === String ? "POINT_OF_SERVICE_TYPE_UNSPECIFIED" : 0;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.redundantData = null;
                    object.vanityUrl = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.friendlyName != null && message.hasOwnProperty("friendlyName"))
                    object.friendlyName = message.friendlyName;
                if (message.type != null && message.hasOwnProperty("type"))
                    object.type = options.enums === String ? $root.waiternow.common.PointOfServiceProto.PointOfServiceType[message.type] === undefined ? message.type : $root.waiternow.common.PointOfServiceProto.PointOfServiceType[message.type] : message.type;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.redundantData != null && message.hasOwnProperty("redundantData"))
                    object.redundantData = $root.waiternow.common.PointOfServiceProto.RedundantDataProto.toObject(message.redundantData, options);
                if (message.vanityUrl != null && message.hasOwnProperty("vanityUrl"))
                    object.vanityUrl = message.vanityUrl;
                return object;
            };

            /**
             * Converts this PointOfServiceProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PointOfServiceProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PointOfServiceProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PointOfServiceProto
             * @function getTypeUrl
             * @memberof waiternow.common.PointOfServiceProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PointOfServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PointOfServiceProto";
            };

            /**
             * PointOfServiceType enum.
             * @name waiternow.common.PointOfServiceProto.PointOfServiceType
             * @enum {number}
             * @property {number} POINT_OF_SERVICE_TYPE_UNSPECIFIED=0 POINT_OF_SERVICE_TYPE_UNSPECIFIED value
             * @property {number} TABLE=1 TABLE value
             * @property {number} ROOM=2 ROOM value
             * @property {number} TERRACE=3 TERRACE value
             * @property {number} POOL=4 POOL value
             * @property {number} MENU=5 MENU value
             * @property {number} ONLINE_PICKUP=6 ONLINE_PICKUP value
             * @property {number} ONSITE_PICKUP=7 ONSITE_PICKUP value
             * @property {number} ONLINE=8 ONLINE value
             */
            PointOfServiceProto.PointOfServiceType = (function() {
                var valuesById = {}, values = Object.create(valuesById);
                values[valuesById[0] = "POINT_OF_SERVICE_TYPE_UNSPECIFIED"] = 0;
                values[valuesById[1] = "TABLE"] = 1;
                values[valuesById[2] = "ROOM"] = 2;
                values[valuesById[3] = "TERRACE"] = 3;
                values[valuesById[4] = "POOL"] = 4;
                values[valuesById[5] = "MENU"] = 5;
                values[valuesById[6] = "ONLINE_PICKUP"] = 6;
                values[valuesById[7] = "ONSITE_PICKUP"] = 7;
                values[valuesById[8] = "ONLINE"] = 8;
                return values;
            })();

            PointOfServiceProto.RedundantDataProto = (function() {

                /**
                 * Properties of a RedundantDataProto.
                 * @memberof waiternow.common.PointOfServiceProto
                 * @interface IRedundantDataProto
                 * @property {string|null} [businessId] RedundantDataProto businessId
                 * @property {string|null} [businessName] RedundantDataProto businessName
                 * @property {waiternow.common.IImageProto|null} [businessLogo] RedundantDataProto businessLogo
                 * @property {boolean|null} [isBusinessSuspended] RedundantDataProto isBusinessSuspended
                 * @property {waiternow.common.LocationProto.ISettingsProto|null} [businessLocationSettings] RedundantDataProto businessLocationSettings
                 * @property {string|null} [locationName] RedundantDataProto locationName
                 * @property {waiternow.common.LocationProto.ISettingsProto|null} [locationSettings] RedundantDataProto locationSettings
                 * @property {waiternow.common.IAddressProto|null} [locationAddress] RedundantDataProto locationAddress
                 * @property {waiternow.common.IImageProto|null} [businessHeaderForLandscapeScreen] RedundantDataProto businessHeaderForLandscapeScreen
                 * @property {waiternow.common.IImageProto|null} [businessHeaderForPortraitScreen] RedundantDataProto businessHeaderForPortraitScreen
                 * @property {waiternow.common.IPhoneNumberProto|null} [locationPhoneNumber] RedundantDataProto locationPhoneNumber
                 * @property {string|null} [businessDescription] RedundantDataProto businessDescription
                 */

                /**
                 * Constructs a new RedundantDataProto.
                 * @memberof waiternow.common.PointOfServiceProto
                 * @classdesc Represents a RedundantDataProto.
                 * @implements IRedundantDataProto
                 * @constructor
                 * @param {waiternow.common.PointOfServiceProto.IRedundantDataProto=} [properties] Properties to set
                 */
                function RedundantDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RedundantDataProto businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessId = "";

                /**
                 * RedundantDataProto businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessName = "";

                /**
                 * RedundantDataProto businessLogo.
                 * @member {waiternow.common.IImageProto|null|undefined} businessLogo
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessLogo = null;

                /**
                 * RedundantDataProto isBusinessSuspended.
                 * @member {boolean} isBusinessSuspended
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.isBusinessSuspended = false;

                /**
                 * RedundantDataProto businessLocationSettings.
                 * @member {waiternow.common.LocationProto.ISettingsProto|null|undefined} businessLocationSettings
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessLocationSettings = null;

                /**
                 * RedundantDataProto locationName.
                 * @member {string} locationName
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationName = "";

                /**
                 * RedundantDataProto locationSettings.
                 * @member {waiternow.common.LocationProto.ISettingsProto|null|undefined} locationSettings
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationSettings = null;

                /**
                 * RedundantDataProto locationAddress.
                 * @member {waiternow.common.IAddressProto|null|undefined} locationAddress
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationAddress = null;

                /**
                 * RedundantDataProto businessHeaderForLandscapeScreen.
                 * @member {waiternow.common.IImageProto|null|undefined} businessHeaderForLandscapeScreen
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessHeaderForLandscapeScreen = null;

                /**
                 * RedundantDataProto businessHeaderForPortraitScreen.
                 * @member {waiternow.common.IImageProto|null|undefined} businessHeaderForPortraitScreen
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessHeaderForPortraitScreen = null;

                /**
                 * RedundantDataProto locationPhoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} locationPhoneNumber
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationPhoneNumber = null;

                /**
                 * RedundantDataProto businessDescription.
                 * @member {string} businessDescription
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.businessDescription = "";

                /**
                 * Creates a new RedundantDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.PointOfServiceProto.IRedundantDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.PointOfServiceProto.RedundantDataProto} RedundantDataProto instance
                 */
                RedundantDataProto.create = function create(properties) {
                    return new RedundantDataProto(properties);
                };

                /**
                 * Encodes the specified RedundantDataProto message. Does not implicitly {@link waiternow.common.PointOfServiceProto.RedundantDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.PointOfServiceProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessName);
                    if (message.isBusinessSuspended != null && Object.hasOwnProperty.call(message, "isBusinessSuspended"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isBusinessSuspended);
                    if (message.businessLocationSettings != null && Object.hasOwnProperty.call(message, "businessLocationSettings"))
                        $root.waiternow.common.LocationProto.SettingsProto.encode(message.businessLocationSettings, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.locationName);
                    if (message.locationSettings != null && Object.hasOwnProperty.call(message, "locationSettings"))
                        $root.waiternow.common.LocationProto.SettingsProto.encode(message.locationSettings, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.businessLogo != null && Object.hasOwnProperty.call(message, "businessLogo"))
                        $root.waiternow.common.ImageProto.encode(message.businessLogo, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.locationAddress != null && Object.hasOwnProperty.call(message, "locationAddress"))
                        $root.waiternow.common.AddressProto.encode(message.locationAddress, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                    if (message.businessHeaderForLandscapeScreen != null && Object.hasOwnProperty.call(message, "businessHeaderForLandscapeScreen"))
                        $root.waiternow.common.ImageProto.encode(message.businessHeaderForLandscapeScreen, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.businessHeaderForPortraitScreen != null && Object.hasOwnProperty.call(message, "businessHeaderForPortraitScreen"))
                        $root.waiternow.common.ImageProto.encode(message.businessHeaderForPortraitScreen, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                    if (message.locationPhoneNumber != null && Object.hasOwnProperty.call(message, "locationPhoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.locationPhoneNumber, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                    if (message.businessDescription != null && Object.hasOwnProperty.call(message, "businessDescription"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.businessDescription);
                    return writer;
                };

                /**
                 * Encodes the specified RedundantDataProto message, length delimited. Does not implicitly {@link waiternow.common.PointOfServiceProto.RedundantDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.PointOfServiceProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PointOfServiceProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PointOfServiceProto.RedundantDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 8: {
                                message.businessLogo = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.isBusinessSuspended = reader.bool();
                                break;
                            }
                        case 5: {
                                message.businessLocationSettings = $root.waiternow.common.LocationProto.SettingsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 7: {
                                message.locationSettings = $root.waiternow.common.LocationProto.SettingsProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.locationAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.businessHeaderForLandscapeScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.businessHeaderForPortraitScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 12: {
                                message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 13: {
                                message.businessDescription = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PointOfServiceProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RedundantDataProto message.
                 * @function verify
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedundantDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessLogo != null && message.hasOwnProperty("businessLogo")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.businessLogo);
                        if (error)
                            return "businessLogo." + error;
                    }
                    if (message.isBusinessSuspended != null && message.hasOwnProperty("isBusinessSuspended"))
                        if (typeof message.isBusinessSuspended !== "boolean")
                            return "isBusinessSuspended: boolean expected";
                    if (message.businessLocationSettings != null && message.hasOwnProperty("businessLocationSettings")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.verify(message.businessLocationSettings);
                        if (error)
                            return "businessLocationSettings." + error;
                    }
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.locationSettings != null && message.hasOwnProperty("locationSettings")) {
                        var error = $root.waiternow.common.LocationProto.SettingsProto.verify(message.locationSettings);
                        if (error)
                            return "locationSettings." + error;
                    }
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.locationAddress);
                        if (error)
                            return "locationAddress." + error;
                    }
                    if (message.businessHeaderForLandscapeScreen != null && message.hasOwnProperty("businessHeaderForLandscapeScreen")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.businessHeaderForLandscapeScreen);
                        if (error)
                            return "businessHeaderForLandscapeScreen." + error;
                    }
                    if (message.businessHeaderForPortraitScreen != null && message.hasOwnProperty("businessHeaderForPortraitScreen")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.businessHeaderForPortraitScreen);
                        if (error)
                            return "businessHeaderForPortraitScreen." + error;
                    }
                    if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.locationPhoneNumber);
                        if (error)
                            return "locationPhoneNumber." + error;
                    }
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        if (!$util.isString(message.businessDescription))
                            return "businessDescription: string expected";
                    return null;
                };

                /**
                 * Creates a RedundantDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PointOfServiceProto.RedundantDataProto} RedundantDataProto
                 */
                RedundantDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PointOfServiceProto.RedundantDataProto)
                        return object;
                    var message = new $root.waiternow.common.PointOfServiceProto.RedundantDataProto();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessLogo != null) {
                        if (typeof object.businessLogo !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceProto.RedundantDataProto.businessLogo: object expected");
                        message.businessLogo = $root.waiternow.common.ImageProto.fromObject(object.businessLogo);
                    }
                    if (object.isBusinessSuspended != null)
                        message.isBusinessSuspended = Boolean(object.isBusinessSuspended);
                    if (object.businessLocationSettings != null) {
                        if (typeof object.businessLocationSettings !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceProto.RedundantDataProto.businessLocationSettings: object expected");
                        message.businessLocationSettings = $root.waiternow.common.LocationProto.SettingsProto.fromObject(object.businessLocationSettings);
                    }
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.locationSettings != null) {
                        if (typeof object.locationSettings !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceProto.RedundantDataProto.locationSettings: object expected");
                        message.locationSettings = $root.waiternow.common.LocationProto.SettingsProto.fromObject(object.locationSettings);
                    }
                    if (object.locationAddress != null) {
                        if (typeof object.locationAddress !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceProto.RedundantDataProto.locationAddress: object expected");
                        message.locationAddress = $root.waiternow.common.AddressProto.fromObject(object.locationAddress);
                    }
                    if (object.businessHeaderForLandscapeScreen != null) {
                        if (typeof object.businessHeaderForLandscapeScreen !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceProto.RedundantDataProto.businessHeaderForLandscapeScreen: object expected");
                        message.businessHeaderForLandscapeScreen = $root.waiternow.common.ImageProto.fromObject(object.businessHeaderForLandscapeScreen);
                    }
                    if (object.businessHeaderForPortraitScreen != null) {
                        if (typeof object.businessHeaderForPortraitScreen !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceProto.RedundantDataProto.businessHeaderForPortraitScreen: object expected");
                        message.businessHeaderForPortraitScreen = $root.waiternow.common.ImageProto.fromObject(object.businessHeaderForPortraitScreen);
                    }
                    if (object.locationPhoneNumber != null) {
                        if (typeof object.locationPhoneNumber !== "object")
                            throw TypeError(".waiternow.common.PointOfServiceProto.RedundantDataProto.locationPhoneNumber: object expected");
                        message.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.locationPhoneNumber);
                    }
                    if (object.businessDescription != null)
                        message.businessDescription = String(object.businessDescription);
                    return message;
                };

                /**
                 * Creates a plain object from a RedundantDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.PointOfServiceProto.RedundantDataProto} message RedundantDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedundantDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessId = "";
                        object.businessName = "";
                        object.isBusinessSuspended = false;
                        object.businessLocationSettings = null;
                        object.locationName = "";
                        object.locationSettings = null;
                        object.businessLogo = null;
                        object.locationAddress = null;
                        object.businessHeaderForLandscapeScreen = null;
                        object.businessHeaderForPortraitScreen = null;
                        object.locationPhoneNumber = null;
                        object.businessDescription = "";
                    }
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.isBusinessSuspended != null && message.hasOwnProperty("isBusinessSuspended"))
                        object.isBusinessSuspended = message.isBusinessSuspended;
                    if (message.businessLocationSettings != null && message.hasOwnProperty("businessLocationSettings"))
                        object.businessLocationSettings = $root.waiternow.common.LocationProto.SettingsProto.toObject(message.businessLocationSettings, options);
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.locationSettings != null && message.hasOwnProperty("locationSettings"))
                        object.locationSettings = $root.waiternow.common.LocationProto.SettingsProto.toObject(message.locationSettings, options);
                    if (message.businessLogo != null && message.hasOwnProperty("businessLogo"))
                        object.businessLogo = $root.waiternow.common.ImageProto.toObject(message.businessLogo, options);
                    if (message.locationAddress != null && message.hasOwnProperty("locationAddress"))
                        object.locationAddress = $root.waiternow.common.AddressProto.toObject(message.locationAddress, options);
                    if (message.businessHeaderForLandscapeScreen != null && message.hasOwnProperty("businessHeaderForLandscapeScreen"))
                        object.businessHeaderForLandscapeScreen = $root.waiternow.common.ImageProto.toObject(message.businessHeaderForLandscapeScreen, options);
                    if (message.businessHeaderForPortraitScreen != null && message.hasOwnProperty("businessHeaderForPortraitScreen"))
                        object.businessHeaderForPortraitScreen = $root.waiternow.common.ImageProto.toObject(message.businessHeaderForPortraitScreen, options);
                    if (message.locationPhoneNumber != null && message.hasOwnProperty("locationPhoneNumber"))
                        object.locationPhoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.locationPhoneNumber, options);
                    if (message.businessDescription != null && message.hasOwnProperty("businessDescription"))
                        object.businessDescription = message.businessDescription;
                    return object;
                };

                /**
                 * Converts this RedundantDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedundantDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RedundantDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PointOfServiceProto.RedundantDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedundantDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PointOfServiceProto.RedundantDataProto";
                };

                return RedundantDataProto;
            })();

            return PointOfServiceProto;
        })();

        common.LocationPointsOfServiceProto = (function() {

            /**
             * Properties of a LocationPointsOfServiceProto.
             * @memberof waiternow.common
             * @interface ILocationPointsOfServiceProto
             * @property {string|null} [locationId] LocationPointsOfServiceProto locationId
             * @property {Array.<waiternow.common.IPointOfServiceProto>|null} [pointsOfService] LocationPointsOfServiceProto pointsOfService
             */

            /**
             * Constructs a new LocationPointsOfServiceProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationPointsOfServiceProto.
             * @implements ILocationPointsOfServiceProto
             * @constructor
             * @param {waiternow.common.ILocationPointsOfServiceProto=} [properties] Properties to set
             */
            function LocationPointsOfServiceProto(properties) {
                this.pointsOfService = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationPointsOfServiceProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @instance
             */
            LocationPointsOfServiceProto.prototype.locationId = "";

            /**
             * LocationPointsOfServiceProto pointsOfService.
             * @member {Array.<waiternow.common.IPointOfServiceProto>} pointsOfService
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @instance
             */
            LocationPointsOfServiceProto.prototype.pointsOfService = $util.emptyArray;

            /**
             * Creates a new LocationPointsOfServiceProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {waiternow.common.ILocationPointsOfServiceProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationPointsOfServiceProto} LocationPointsOfServiceProto instance
             */
            LocationPointsOfServiceProto.create = function create(properties) {
                return new LocationPointsOfServiceProto(properties);
            };

            /**
             * Encodes the specified LocationPointsOfServiceProto message. Does not implicitly {@link waiternow.common.LocationPointsOfServiceProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {waiternow.common.ILocationPointsOfServiceProto} message LocationPointsOfServiceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationPointsOfServiceProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.pointsOfService != null && message.pointsOfService.length)
                    for (var i = 0; i < message.pointsOfService.length; ++i)
                        $root.waiternow.common.PointOfServiceProto.encode(message.pointsOfService[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified LocationPointsOfServiceProto message, length delimited. Does not implicitly {@link waiternow.common.LocationPointsOfServiceProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {waiternow.common.ILocationPointsOfServiceProto} message LocationPointsOfServiceProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationPointsOfServiceProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationPointsOfServiceProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationPointsOfServiceProto} LocationPointsOfServiceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationPointsOfServiceProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationPointsOfServiceProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.pointsOfService && message.pointsOfService.length))
                                message.pointsOfService = [];
                            message.pointsOfService.push($root.waiternow.common.PointOfServiceProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationPointsOfServiceProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationPointsOfServiceProto} LocationPointsOfServiceProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationPointsOfServiceProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationPointsOfServiceProto message.
             * @function verify
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationPointsOfServiceProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.pointsOfService != null && message.hasOwnProperty("pointsOfService")) {
                    if (!Array.isArray(message.pointsOfService))
                        return "pointsOfService: array expected";
                    for (var i = 0; i < message.pointsOfService.length; ++i) {
                        var error = $root.waiternow.common.PointOfServiceProto.verify(message.pointsOfService[i]);
                        if (error)
                            return "pointsOfService." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a LocationPointsOfServiceProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationPointsOfServiceProto} LocationPointsOfServiceProto
             */
            LocationPointsOfServiceProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationPointsOfServiceProto)
                    return object;
                var message = new $root.waiternow.common.LocationPointsOfServiceProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.pointsOfService) {
                    if (!Array.isArray(object.pointsOfService))
                        throw TypeError(".waiternow.common.LocationPointsOfServiceProto.pointsOfService: array expected");
                    message.pointsOfService = [];
                    for (var i = 0; i < object.pointsOfService.length; ++i) {
                        if (typeof object.pointsOfService[i] !== "object")
                            throw TypeError(".waiternow.common.LocationPointsOfServiceProto.pointsOfService: object expected");
                        message.pointsOfService[i] = $root.waiternow.common.PointOfServiceProto.fromObject(object.pointsOfService[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a LocationPointsOfServiceProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {waiternow.common.LocationPointsOfServiceProto} message LocationPointsOfServiceProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationPointsOfServiceProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.pointsOfService = [];
                if (options.defaults)
                    object.locationId = "";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.pointsOfService && message.pointsOfService.length) {
                    object.pointsOfService = [];
                    for (var j = 0; j < message.pointsOfService.length; ++j)
                        object.pointsOfService[j] = $root.waiternow.common.PointOfServiceProto.toObject(message.pointsOfService[j], options);
                }
                return object;
            };

            /**
             * Converts this LocationPointsOfServiceProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationPointsOfServiceProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationPointsOfServiceProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationPointsOfServiceProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationPointsOfServiceProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationPointsOfServiceProto";
            };

            return LocationPointsOfServiceProto;
        })();

        common.CheckInInfoProto = (function() {

            /**
             * Properties of a CheckInInfoProto.
             * @memberof waiternow.common
             * @interface ICheckInInfoProto
             * @property {string|null} [pointOfServiceId] CheckInInfoProto pointOfServiceId
             * @property {string|null} [locationId] CheckInInfoProto locationId
             * @property {string|null} [businessName] CheckInInfoProto businessName
             * @property {string|null} [businessLogoImageUrl] CheckInInfoProto businessLogoImageUrl
             * @property {waiternow.common.IMenuProto|null} [menu] CheckInInfoProto menu
             * @property {waiternow.common.IStructuredMenuProto|null} [structuredMenu] CheckInInfoProto structuredMenu
             * @property {waiternow.common.Language|null} [language] CheckInInfoProto language
             * @property {boolean|null} [isLocationOnline] CheckInInfoProto isLocationOnline
             * @property {google.protobuf.IDuration|null} [sessionExpiration] CheckInInfoProto sessionExpiration
             * @property {waiternow.common.Theme|null} [theme] CheckInInfoProto theme
             * @property {waiternow.common.IImageProto|null} [businessLogo] CheckInInfoProto businessLogo
             * @property {waiternow.common.IAddressProto|null} [locationAddress] CheckInInfoProto locationAddress
             * @property {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto|null} [externalLinks] CheckInInfoProto externalLinks
             * @property {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode|null} [appMode] CheckInInfoProto appMode
             * @property {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto|null} [colorPalette] CheckInInfoProto colorPalette
             * @property {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto|null} [enabledFeatures] CheckInInfoProto enabledFeatures
             * @property {waiternow.common.PointOfServiceProto.PointOfServiceType|null} [pointOfServiceType] CheckInInfoProto pointOfServiceType
             * @property {google.protobuf.IDuration|null} [orderStatusPollRate] CheckInInfoProto orderStatusPollRate
             * @property {google.protobuf.IDuration|null} [locationOnlineStatusPollRate] CheckInInfoProto locationOnlineStatusPollRate
             * @property {waiternow.common.ITaxesProto|null} [taxes] CheckInInfoProto taxes
             * @property {string|null} [stripePublishableKey] CheckInInfoProto stripePublishableKey
             * @property {string|null} [locationName] CheckInInfoProto locationName
             * @property {string|null} [pointOfServiceFriendlyName] CheckInInfoProto pointOfServiceFriendlyName
             * @property {boolean|null} [isLocationEnrolledOnPayments] CheckInInfoProto isLocationEnrolledOnPayments
             * @property {google.protobuf.IDuration|null} [orderPreparationDuration] CheckInInfoProto orderPreparationDuration
             * @property {waiternow.common.IBusinessHoursProto|null} [businessHours] CheckInInfoProto businessHours
             * @property {waiternow.common.IImageProto|null} [headerForLandscapeScreen] CheckInInfoProto headerForLandscapeScreen
             * @property {waiternow.common.IImageProto|null} [headerForPortraitScreen] CheckInInfoProto headerForPortraitScreen
             * @property {boolean|null} [isTipsEnabled] CheckInInfoProto isTipsEnabled
             */

            /**
             * Constructs a new CheckInInfoProto.
             * @memberof waiternow.common
             * @classdesc Represents a CheckInInfoProto.
             * @implements ICheckInInfoProto
             * @constructor
             * @param {waiternow.common.ICheckInInfoProto=} [properties] Properties to set
             */
            function CheckInInfoProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * CheckInInfoProto pointOfServiceId.
             * @member {string} pointOfServiceId
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.pointOfServiceId = "";

            /**
             * CheckInInfoProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.locationId = "";

            /**
             * CheckInInfoProto businessName.
             * @member {string} businessName
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.businessName = "";

            /**
             * CheckInInfoProto businessLogoImageUrl.
             * @member {string} businessLogoImageUrl
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.businessLogoImageUrl = "";

            /**
             * CheckInInfoProto menu.
             * @member {waiternow.common.IMenuProto|null|undefined} menu
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.menu = null;

            /**
             * CheckInInfoProto structuredMenu.
             * @member {waiternow.common.IStructuredMenuProto|null|undefined} structuredMenu
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.structuredMenu = null;

            /**
             * CheckInInfoProto language.
             * @member {waiternow.common.Language} language
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.language = 0;

            /**
             * CheckInInfoProto isLocationOnline.
             * @member {boolean} isLocationOnline
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.isLocationOnline = false;

            /**
             * CheckInInfoProto sessionExpiration.
             * @member {google.protobuf.IDuration|null|undefined} sessionExpiration
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.sessionExpiration = null;

            /**
             * CheckInInfoProto theme.
             * @member {waiternow.common.Theme} theme
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.theme = 0;

            /**
             * CheckInInfoProto businessLogo.
             * @member {waiternow.common.IImageProto|null|undefined} businessLogo
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.businessLogo = null;

            /**
             * CheckInInfoProto locationAddress.
             * @member {waiternow.common.IAddressProto|null|undefined} locationAddress
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.locationAddress = null;

            /**
             * CheckInInfoProto externalLinks.
             * @member {waiternow.common.LocationProto.SettingsProto.IExternalLinksProto|null|undefined} externalLinks
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.externalLinks = null;

            /**
             * CheckInInfoProto appMode.
             * @member {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode} appMode
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.appMode = 0;

            /**
             * CheckInInfoProto colorPalette.
             * @member {waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.IColorPaletteProto|null|undefined} colorPalette
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.colorPalette = null;

            /**
             * CheckInInfoProto enabledFeatures.
             * @member {waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.IEnabledFeaturesProto|null|undefined} enabledFeatures
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.enabledFeatures = null;

            /**
             * CheckInInfoProto pointOfServiceType.
             * @member {waiternow.common.PointOfServiceProto.PointOfServiceType} pointOfServiceType
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.pointOfServiceType = 0;

            /**
             * CheckInInfoProto orderStatusPollRate.
             * @member {google.protobuf.IDuration|null|undefined} orderStatusPollRate
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.orderStatusPollRate = null;

            /**
             * CheckInInfoProto locationOnlineStatusPollRate.
             * @member {google.protobuf.IDuration|null|undefined} locationOnlineStatusPollRate
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.locationOnlineStatusPollRate = null;

            /**
             * CheckInInfoProto taxes.
             * @member {waiternow.common.ITaxesProto|null|undefined} taxes
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.taxes = null;

            /**
             * CheckInInfoProto stripePublishableKey.
             * @member {string} stripePublishableKey
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.stripePublishableKey = "";

            /**
             * CheckInInfoProto locationName.
             * @member {string} locationName
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.locationName = "";

            /**
             * CheckInInfoProto pointOfServiceFriendlyName.
             * @member {string} pointOfServiceFriendlyName
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.pointOfServiceFriendlyName = "";

            /**
             * CheckInInfoProto isLocationEnrolledOnPayments.
             * @member {boolean} isLocationEnrolledOnPayments
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.isLocationEnrolledOnPayments = false;

            /**
             * CheckInInfoProto orderPreparationDuration.
             * @member {google.protobuf.IDuration|null|undefined} orderPreparationDuration
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.orderPreparationDuration = null;

            /**
             * CheckInInfoProto businessHours.
             * @member {waiternow.common.IBusinessHoursProto|null|undefined} businessHours
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.businessHours = null;

            /**
             * CheckInInfoProto headerForLandscapeScreen.
             * @member {waiternow.common.IImageProto|null|undefined} headerForLandscapeScreen
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.headerForLandscapeScreen = null;

            /**
             * CheckInInfoProto headerForPortraitScreen.
             * @member {waiternow.common.IImageProto|null|undefined} headerForPortraitScreen
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.headerForPortraitScreen = null;

            /**
             * CheckInInfoProto isTipsEnabled.
             * @member {boolean} isTipsEnabled
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             */
            CheckInInfoProto.prototype.isTipsEnabled = false;

            /**
             * Creates a new CheckInInfoProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {waiternow.common.ICheckInInfoProto=} [properties] Properties to set
             * @returns {waiternow.common.CheckInInfoProto} CheckInInfoProto instance
             */
            CheckInInfoProto.create = function create(properties) {
                return new CheckInInfoProto(properties);
            };

            /**
             * Encodes the specified CheckInInfoProto message. Does not implicitly {@link waiternow.common.CheckInInfoProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {waiternow.common.ICheckInInfoProto} message CheckInInfoProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInInfoProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessName);
                if (message.businessLogoImageUrl != null && Object.hasOwnProperty.call(message, "businessLogoImageUrl"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessLogoImageUrl);
                if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                    $root.waiternow.common.MenuProto.encode(message.menu, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                    writer.uint32(/* id 5, wireType 0 =*/40).int32(message.language);
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.locationId);
                if (message.isLocationOnline != null && Object.hasOwnProperty.call(message, "isLocationOnline"))
                    writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isLocationOnline);
                if (message.sessionExpiration != null && Object.hasOwnProperty.call(message, "sessionExpiration"))
                    $root.google.protobuf.Duration.encode(message.sessionExpiration, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.theme != null && Object.hasOwnProperty.call(message, "theme"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.theme);
                if (message.businessLogo != null && Object.hasOwnProperty.call(message, "businessLogo"))
                    $root.waiternow.common.ImageProto.encode(message.businessLogo, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.locationAddress != null && Object.hasOwnProperty.call(message, "locationAddress"))
                    $root.waiternow.common.AddressProto.encode(message.locationAddress, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.externalLinks != null && Object.hasOwnProperty.call(message, "externalLinks"))
                    $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.encode(message.externalLinks, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.appMode != null && Object.hasOwnProperty.call(message, "appMode"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int32(message.appMode);
                if (message.pointOfServiceType != null && Object.hasOwnProperty.call(message, "pointOfServiceType"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int32(message.pointOfServiceType);
                if (message.orderStatusPollRate != null && Object.hasOwnProperty.call(message, "orderStatusPollRate"))
                    $root.google.protobuf.Duration.encode(message.orderStatusPollRate, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
                if (message.locationOnlineStatusPollRate != null && Object.hasOwnProperty.call(message, "locationOnlineStatusPollRate"))
                    $root.google.protobuf.Duration.encode(message.locationOnlineStatusPollRate, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
                if (message.colorPalette != null && Object.hasOwnProperty.call(message, "colorPalette"))
                    $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.encode(message.colorPalette, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
                if (message.structuredMenu != null && Object.hasOwnProperty.call(message, "structuredMenu"))
                    $root.waiternow.common.StructuredMenuProto.encode(message.structuredMenu, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
                if (message.enabledFeatures != null && Object.hasOwnProperty.call(message, "enabledFeatures"))
                    $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.encode(message.enabledFeatures, writer.uint32(/* id 19, wireType 2 =*/154).fork()).ldelim();
                if (message.taxes != null && Object.hasOwnProperty.call(message, "taxes"))
                    $root.waiternow.common.TaxesProto.encode(message.taxes, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.stripePublishableKey != null && Object.hasOwnProperty.call(message, "stripePublishableKey"))
                    writer.uint32(/* id 21, wireType 2 =*/170).string(message.stripePublishableKey);
                if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                    writer.uint32(/* id 22, wireType 2 =*/178).string(message.locationName);
                if (message.pointOfServiceFriendlyName != null && Object.hasOwnProperty.call(message, "pointOfServiceFriendlyName"))
                    writer.uint32(/* id 23, wireType 2 =*/186).string(message.pointOfServiceFriendlyName);
                if (message.isLocationEnrolledOnPayments != null && Object.hasOwnProperty.call(message, "isLocationEnrolledOnPayments"))
                    writer.uint32(/* id 24, wireType 0 =*/192).bool(message.isLocationEnrolledOnPayments);
                if (message.orderPreparationDuration != null && Object.hasOwnProperty.call(message, "orderPreparationDuration"))
                    $root.google.protobuf.Duration.encode(message.orderPreparationDuration, writer.uint32(/* id 25, wireType 2 =*/202).fork()).ldelim();
                if (message.businessHours != null && Object.hasOwnProperty.call(message, "businessHours"))
                    $root.waiternow.common.BusinessHoursProto.encode(message.businessHours, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
                if (message.headerForLandscapeScreen != null && Object.hasOwnProperty.call(message, "headerForLandscapeScreen"))
                    $root.waiternow.common.ImageProto.encode(message.headerForLandscapeScreen, writer.uint32(/* id 27, wireType 2 =*/218).fork()).ldelim();
                if (message.headerForPortraitScreen != null && Object.hasOwnProperty.call(message, "headerForPortraitScreen"))
                    $root.waiternow.common.ImageProto.encode(message.headerForPortraitScreen, writer.uint32(/* id 28, wireType 2 =*/226).fork()).ldelim();
                if (message.isTipsEnabled != null && Object.hasOwnProperty.call(message, "isTipsEnabled"))
                    writer.uint32(/* id 29, wireType 0 =*/232).bool(message.isTipsEnabled);
                return writer;
            };

            /**
             * Encodes the specified CheckInInfoProto message, length delimited. Does not implicitly {@link waiternow.common.CheckInInfoProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {waiternow.common.ICheckInInfoProto} message CheckInInfoProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CheckInInfoProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CheckInInfoProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CheckInInfoProto} CheckInInfoProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInInfoProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CheckInInfoProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.pointOfServiceId = reader.string();
                            break;
                        }
                    case 6: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            message.businessName = reader.string();
                            break;
                        }
                    case 3: {
                            message.businessLogoImageUrl = reader.string();
                            break;
                        }
                    case 4: {
                            message.menu = $root.waiternow.common.MenuProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 18: {
                            message.structuredMenu = $root.waiternow.common.StructuredMenuProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.language = reader.int32();
                            break;
                        }
                    case 7: {
                            message.isLocationOnline = reader.bool();
                            break;
                        }
                    case 8: {
                            message.sessionExpiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.theme = reader.int32();
                            break;
                        }
                    case 10: {
                            message.businessLogo = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.locationAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.appMode = reader.int32();
                            break;
                        }
                    case 17: {
                            message.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 19: {
                            message.enabledFeatures = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.pointOfServiceType = reader.int32();
                            break;
                        }
                    case 15: {
                            message.orderStatusPollRate = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    case 16: {
                            message.locationOnlineStatusPollRate = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    case 20: {
                            message.taxes = $root.waiternow.common.TaxesProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            message.stripePublishableKey = reader.string();
                            break;
                        }
                    case 22: {
                            message.locationName = reader.string();
                            break;
                        }
                    case 23: {
                            message.pointOfServiceFriendlyName = reader.string();
                            break;
                        }
                    case 24: {
                            message.isLocationEnrolledOnPayments = reader.bool();
                            break;
                        }
                    case 25: {
                            message.orderPreparationDuration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                            break;
                        }
                    case 26: {
                            message.businessHours = $root.waiternow.common.BusinessHoursProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 27: {
                            message.headerForLandscapeScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 28: {
                            message.headerForPortraitScreen = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 29: {
                            message.isTipsEnabled = reader.bool();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CheckInInfoProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CheckInInfoProto} CheckInInfoProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CheckInInfoProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CheckInInfoProto message.
             * @function verify
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CheckInInfoProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    if (!$util.isString(message.pointOfServiceId))
                        return "pointOfServiceId: string expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    if (!$util.isString(message.businessName))
                        return "businessName: string expected";
                if (message.businessLogoImageUrl != null && message.hasOwnProperty("businessLogoImageUrl"))
                    if (!$util.isString(message.businessLogoImageUrl))
                        return "businessLogoImageUrl: string expected";
                if (message.menu != null && message.hasOwnProperty("menu")) {
                    var error = $root.waiternow.common.MenuProto.verify(message.menu);
                    if (error)
                        return "menu." + error;
                }
                if (message.structuredMenu != null && message.hasOwnProperty("structuredMenu")) {
                    var error = $root.waiternow.common.StructuredMenuProto.verify(message.structuredMenu);
                    if (error)
                        return "structuredMenu." + error;
                }
                if (message.language != null && message.hasOwnProperty("language"))
                    switch (message.language) {
                    default:
                        return "language: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                    if (typeof message.isLocationOnline !== "boolean")
                        return "isLocationOnline: boolean expected";
                if (message.sessionExpiration != null && message.hasOwnProperty("sessionExpiration")) {
                    var error = $root.google.protobuf.Duration.verify(message.sessionExpiration);
                    if (error)
                        return "sessionExpiration." + error;
                }
                if (message.theme != null && message.hasOwnProperty("theme"))
                    switch (message.theme) {
                    default:
                        return "theme: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.businessLogo != null && message.hasOwnProperty("businessLogo")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.businessLogo);
                    if (error)
                        return "businessLogo." + error;
                }
                if (message.locationAddress != null && message.hasOwnProperty("locationAddress")) {
                    var error = $root.waiternow.common.AddressProto.verify(message.locationAddress);
                    if (error)
                        return "locationAddress." + error;
                }
                if (message.externalLinks != null && message.hasOwnProperty("externalLinks")) {
                    var error = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.verify(message.externalLinks);
                    if (error)
                        return "externalLinks." + error;
                }
                if (message.appMode != null && message.hasOwnProperty("appMode"))
                    switch (message.appMode) {
                    default:
                        return "appMode: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                if (message.colorPalette != null && message.hasOwnProperty("colorPalette")) {
                    var error = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.verify(message.colorPalette);
                    if (error)
                        return "colorPalette." + error;
                }
                if (message.enabledFeatures != null && message.hasOwnProperty("enabledFeatures")) {
                    var error = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.verify(message.enabledFeatures);
                    if (error)
                        return "enabledFeatures." + error;
                }
                if (message.pointOfServiceType != null && message.hasOwnProperty("pointOfServiceType"))
                    switch (message.pointOfServiceType) {
                    default:
                        return "pointOfServiceType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                    case 7:
                    case 8:
                        break;
                    }
                if (message.orderStatusPollRate != null && message.hasOwnProperty("orderStatusPollRate")) {
                    var error = $root.google.protobuf.Duration.verify(message.orderStatusPollRate);
                    if (error)
                        return "orderStatusPollRate." + error;
                }
                if (message.locationOnlineStatusPollRate != null && message.hasOwnProperty("locationOnlineStatusPollRate")) {
                    var error = $root.google.protobuf.Duration.verify(message.locationOnlineStatusPollRate);
                    if (error)
                        return "locationOnlineStatusPollRate." + error;
                }
                if (message.taxes != null && message.hasOwnProperty("taxes")) {
                    var error = $root.waiternow.common.TaxesProto.verify(message.taxes);
                    if (error)
                        return "taxes." + error;
                }
                if (message.stripePublishableKey != null && message.hasOwnProperty("stripePublishableKey"))
                    if (!$util.isString(message.stripePublishableKey))
                        return "stripePublishableKey: string expected";
                if (message.locationName != null && message.hasOwnProperty("locationName"))
                    if (!$util.isString(message.locationName))
                        return "locationName: string expected";
                if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                    if (!$util.isString(message.pointOfServiceFriendlyName))
                        return "pointOfServiceFriendlyName: string expected";
                if (message.isLocationEnrolledOnPayments != null && message.hasOwnProperty("isLocationEnrolledOnPayments"))
                    if (typeof message.isLocationEnrolledOnPayments !== "boolean")
                        return "isLocationEnrolledOnPayments: boolean expected";
                if (message.orderPreparationDuration != null && message.hasOwnProperty("orderPreparationDuration")) {
                    var error = $root.google.protobuf.Duration.verify(message.orderPreparationDuration);
                    if (error)
                        return "orderPreparationDuration." + error;
                }
                if (message.businessHours != null && message.hasOwnProperty("businessHours")) {
                    var error = $root.waiternow.common.BusinessHoursProto.verify(message.businessHours);
                    if (error)
                        return "businessHours." + error;
                }
                if (message.headerForLandscapeScreen != null && message.hasOwnProperty("headerForLandscapeScreen")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.headerForLandscapeScreen);
                    if (error)
                        return "headerForLandscapeScreen." + error;
                }
                if (message.headerForPortraitScreen != null && message.hasOwnProperty("headerForPortraitScreen")) {
                    var error = $root.waiternow.common.ImageProto.verify(message.headerForPortraitScreen);
                    if (error)
                        return "headerForPortraitScreen." + error;
                }
                if (message.isTipsEnabled != null && message.hasOwnProperty("isTipsEnabled"))
                    if (typeof message.isTipsEnabled !== "boolean")
                        return "isTipsEnabled: boolean expected";
                return null;
            };

            /**
             * Creates a CheckInInfoProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CheckInInfoProto} CheckInInfoProto
             */
            CheckInInfoProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CheckInInfoProto)
                    return object;
                var message = new $root.waiternow.common.CheckInInfoProto();
                if (object.pointOfServiceId != null)
                    message.pointOfServiceId = String(object.pointOfServiceId);
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.businessName != null)
                    message.businessName = String(object.businessName);
                if (object.businessLogoImageUrl != null)
                    message.businessLogoImageUrl = String(object.businessLogoImageUrl);
                if (object.menu != null) {
                    if (typeof object.menu !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.menu: object expected");
                    message.menu = $root.waiternow.common.MenuProto.fromObject(object.menu);
                }
                if (object.structuredMenu != null) {
                    if (typeof object.structuredMenu !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.structuredMenu: object expected");
                    message.structuredMenu = $root.waiternow.common.StructuredMenuProto.fromObject(object.structuredMenu);
                }
                switch (object.language) {
                default:
                    if (typeof object.language === "number") {
                        message.language = object.language;
                        break;
                    }
                    break;
                case "LANGUAGE_UNSPECIFIED":
                case 0:
                    message.language = 0;
                    break;
                case "ENGLISH":
                case 1:
                    message.language = 1;
                    break;
                case "SPANISH":
                case 2:
                    message.language = 2;
                    break;
                }
                if (object.isLocationOnline != null)
                    message.isLocationOnline = Boolean(object.isLocationOnline);
                if (object.sessionExpiration != null) {
                    if (typeof object.sessionExpiration !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.sessionExpiration: object expected");
                    message.sessionExpiration = $root.google.protobuf.Duration.fromObject(object.sessionExpiration);
                }
                switch (object.theme) {
                default:
                    if (typeof object.theme === "number") {
                        message.theme = object.theme;
                        break;
                    }
                    break;
                case "THEME_UNSPECIFIED":
                case 0:
                    message.theme = 0;
                    break;
                case "DARK":
                case 1:
                    message.theme = 1;
                    break;
                case "LIGHT":
                case 2:
                    message.theme = 2;
                    break;
                }
                if (object.businessLogo != null) {
                    if (typeof object.businessLogo !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.businessLogo: object expected");
                    message.businessLogo = $root.waiternow.common.ImageProto.fromObject(object.businessLogo);
                }
                if (object.locationAddress != null) {
                    if (typeof object.locationAddress !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.locationAddress: object expected");
                    message.locationAddress = $root.waiternow.common.AddressProto.fromObject(object.locationAddress);
                }
                if (object.externalLinks != null) {
                    if (typeof object.externalLinks !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.externalLinks: object expected");
                    message.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.fromObject(object.externalLinks);
                }
                switch (object.appMode) {
                default:
                    if (typeof object.appMode === "number") {
                        message.appMode = object.appMode;
                        break;
                    }
                    break;
                case "APP_MODE_UNSPECIFIED":
                case 0:
                    message.appMode = 0;
                    break;
                case "FULL":
                case 1:
                    message.appMode = 1;
                    break;
                case "REQUEST_WAITER_ONLY":
                case 2:
                    message.appMode = 2;
                    break;
                }
                if (object.colorPalette != null) {
                    if (typeof object.colorPalette !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.colorPalette: object expected");
                    message.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.fromObject(object.colorPalette);
                }
                if (object.enabledFeatures != null) {
                    if (typeof object.enabledFeatures !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.enabledFeatures: object expected");
                    message.enabledFeatures = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.fromObject(object.enabledFeatures);
                }
                switch (object.pointOfServiceType) {
                default:
                    if (typeof object.pointOfServiceType === "number") {
                        message.pointOfServiceType = object.pointOfServiceType;
                        break;
                    }
                    break;
                case "POINT_OF_SERVICE_TYPE_UNSPECIFIED":
                case 0:
                    message.pointOfServiceType = 0;
                    break;
                case "TABLE":
                case 1:
                    message.pointOfServiceType = 1;
                    break;
                case "ROOM":
                case 2:
                    message.pointOfServiceType = 2;
                    break;
                case "TERRACE":
                case 3:
                    message.pointOfServiceType = 3;
                    break;
                case "POOL":
                case 4:
                    message.pointOfServiceType = 4;
                    break;
                case "MENU":
                case 5:
                    message.pointOfServiceType = 5;
                    break;
                case "ONLINE_PICKUP":
                case 6:
                    message.pointOfServiceType = 6;
                    break;
                case "ONSITE_PICKUP":
                case 7:
                    message.pointOfServiceType = 7;
                    break;
                case "ONLINE":
                case 8:
                    message.pointOfServiceType = 8;
                    break;
                }
                if (object.orderStatusPollRate != null) {
                    if (typeof object.orderStatusPollRate !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.orderStatusPollRate: object expected");
                    message.orderStatusPollRate = $root.google.protobuf.Duration.fromObject(object.orderStatusPollRate);
                }
                if (object.locationOnlineStatusPollRate != null) {
                    if (typeof object.locationOnlineStatusPollRate !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.locationOnlineStatusPollRate: object expected");
                    message.locationOnlineStatusPollRate = $root.google.protobuf.Duration.fromObject(object.locationOnlineStatusPollRate);
                }
                if (object.taxes != null) {
                    if (typeof object.taxes !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.taxes: object expected");
                    message.taxes = $root.waiternow.common.TaxesProto.fromObject(object.taxes);
                }
                if (object.stripePublishableKey != null)
                    message.stripePublishableKey = String(object.stripePublishableKey);
                if (object.locationName != null)
                    message.locationName = String(object.locationName);
                if (object.pointOfServiceFriendlyName != null)
                    message.pointOfServiceFriendlyName = String(object.pointOfServiceFriendlyName);
                if (object.isLocationEnrolledOnPayments != null)
                    message.isLocationEnrolledOnPayments = Boolean(object.isLocationEnrolledOnPayments);
                if (object.orderPreparationDuration != null) {
                    if (typeof object.orderPreparationDuration !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.orderPreparationDuration: object expected");
                    message.orderPreparationDuration = $root.google.protobuf.Duration.fromObject(object.orderPreparationDuration);
                }
                if (object.businessHours != null) {
                    if (typeof object.businessHours !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.businessHours: object expected");
                    message.businessHours = $root.waiternow.common.BusinessHoursProto.fromObject(object.businessHours);
                }
                if (object.headerForLandscapeScreen != null) {
                    if (typeof object.headerForLandscapeScreen !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.headerForLandscapeScreen: object expected");
                    message.headerForLandscapeScreen = $root.waiternow.common.ImageProto.fromObject(object.headerForLandscapeScreen);
                }
                if (object.headerForPortraitScreen != null) {
                    if (typeof object.headerForPortraitScreen !== "object")
                        throw TypeError(".waiternow.common.CheckInInfoProto.headerForPortraitScreen: object expected");
                    message.headerForPortraitScreen = $root.waiternow.common.ImageProto.fromObject(object.headerForPortraitScreen);
                }
                if (object.isTipsEnabled != null)
                    message.isTipsEnabled = Boolean(object.isTipsEnabled);
                return message;
            };

            /**
             * Creates a plain object from a CheckInInfoProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {waiternow.common.CheckInInfoProto} message CheckInInfoProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CheckInInfoProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.pointOfServiceId = "";
                    object.businessName = "";
                    object.businessLogoImageUrl = "";
                    object.menu = null;
                    object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                    object.locationId = "";
                    object.isLocationOnline = false;
                    object.sessionExpiration = null;
                    object.theme = options.enums === String ? "THEME_UNSPECIFIED" : 0;
                    object.businessLogo = null;
                    object.locationAddress = null;
                    object.externalLinks = null;
                    object.appMode = options.enums === String ? "APP_MODE_UNSPECIFIED" : 0;
                    object.pointOfServiceType = options.enums === String ? "POINT_OF_SERVICE_TYPE_UNSPECIFIED" : 0;
                    object.orderStatusPollRate = null;
                    object.locationOnlineStatusPollRate = null;
                    object.colorPalette = null;
                    object.structuredMenu = null;
                    object.enabledFeatures = null;
                    object.taxes = null;
                    object.stripePublishableKey = "";
                    object.locationName = "";
                    object.pointOfServiceFriendlyName = "";
                    object.isLocationEnrolledOnPayments = false;
                    object.orderPreparationDuration = null;
                    object.businessHours = null;
                    object.headerForLandscapeScreen = null;
                    object.headerForPortraitScreen = null;
                    object.isTipsEnabled = false;
                }
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    object.pointOfServiceId = message.pointOfServiceId;
                if (message.businessName != null && message.hasOwnProperty("businessName"))
                    object.businessName = message.businessName;
                if (message.businessLogoImageUrl != null && message.hasOwnProperty("businessLogoImageUrl"))
                    object.businessLogoImageUrl = message.businessLogoImageUrl;
                if (message.menu != null && message.hasOwnProperty("menu"))
                    object.menu = $root.waiternow.common.MenuProto.toObject(message.menu, options);
                if (message.language != null && message.hasOwnProperty("language"))
                    object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                    object.isLocationOnline = message.isLocationOnline;
                if (message.sessionExpiration != null && message.hasOwnProperty("sessionExpiration"))
                    object.sessionExpiration = $root.google.protobuf.Duration.toObject(message.sessionExpiration, options);
                if (message.theme != null && message.hasOwnProperty("theme"))
                    object.theme = options.enums === String ? $root.waiternow.common.Theme[message.theme] === undefined ? message.theme : $root.waiternow.common.Theme[message.theme] : message.theme;
                if (message.businessLogo != null && message.hasOwnProperty("businessLogo"))
                    object.businessLogo = $root.waiternow.common.ImageProto.toObject(message.businessLogo, options);
                if (message.locationAddress != null && message.hasOwnProperty("locationAddress"))
                    object.locationAddress = $root.waiternow.common.AddressProto.toObject(message.locationAddress, options);
                if (message.externalLinks != null && message.hasOwnProperty("externalLinks"))
                    object.externalLinks = $root.waiternow.common.LocationProto.SettingsProto.ExternalLinksProto.toObject(message.externalLinks, options);
                if (message.appMode != null && message.hasOwnProperty("appMode"))
                    object.appMode = options.enums === String ? $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode[message.appMode] === undefined ? message.appMode : $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.AppMode[message.appMode] : message.appMode;
                if (message.pointOfServiceType != null && message.hasOwnProperty("pointOfServiceType"))
                    object.pointOfServiceType = options.enums === String ? $root.waiternow.common.PointOfServiceProto.PointOfServiceType[message.pointOfServiceType] === undefined ? message.pointOfServiceType : $root.waiternow.common.PointOfServiceProto.PointOfServiceType[message.pointOfServiceType] : message.pointOfServiceType;
                if (message.orderStatusPollRate != null && message.hasOwnProperty("orderStatusPollRate"))
                    object.orderStatusPollRate = $root.google.protobuf.Duration.toObject(message.orderStatusPollRate, options);
                if (message.locationOnlineStatusPollRate != null && message.hasOwnProperty("locationOnlineStatusPollRate"))
                    object.locationOnlineStatusPollRate = $root.google.protobuf.Duration.toObject(message.locationOnlineStatusPollRate, options);
                if (message.colorPalette != null && message.hasOwnProperty("colorPalette"))
                    object.colorPalette = $root.waiternow.common.LocationProto.SettingsProto.ViewSettingsProto.ColorPaletteProto.toObject(message.colorPalette, options);
                if (message.structuredMenu != null && message.hasOwnProperty("structuredMenu"))
                    object.structuredMenu = $root.waiternow.common.StructuredMenuProto.toObject(message.structuredMenu, options);
                if (message.enabledFeatures != null && message.hasOwnProperty("enabledFeatures"))
                    object.enabledFeatures = $root.waiternow.common.LocationProto.SettingsProto.OperationSettingsProto.EnabledFeaturesProto.toObject(message.enabledFeatures, options);
                if (message.taxes != null && message.hasOwnProperty("taxes"))
                    object.taxes = $root.waiternow.common.TaxesProto.toObject(message.taxes, options);
                if (message.stripePublishableKey != null && message.hasOwnProperty("stripePublishableKey"))
                    object.stripePublishableKey = message.stripePublishableKey;
                if (message.locationName != null && message.hasOwnProperty("locationName"))
                    object.locationName = message.locationName;
                if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                    object.pointOfServiceFriendlyName = message.pointOfServiceFriendlyName;
                if (message.isLocationEnrolledOnPayments != null && message.hasOwnProperty("isLocationEnrolledOnPayments"))
                    object.isLocationEnrolledOnPayments = message.isLocationEnrolledOnPayments;
                if (message.orderPreparationDuration != null && message.hasOwnProperty("orderPreparationDuration"))
                    object.orderPreparationDuration = $root.google.protobuf.Duration.toObject(message.orderPreparationDuration, options);
                if (message.businessHours != null && message.hasOwnProperty("businessHours"))
                    object.businessHours = $root.waiternow.common.BusinessHoursProto.toObject(message.businessHours, options);
                if (message.headerForLandscapeScreen != null && message.hasOwnProperty("headerForLandscapeScreen"))
                    object.headerForLandscapeScreen = $root.waiternow.common.ImageProto.toObject(message.headerForLandscapeScreen, options);
                if (message.headerForPortraitScreen != null && message.hasOwnProperty("headerForPortraitScreen"))
                    object.headerForPortraitScreen = $root.waiternow.common.ImageProto.toObject(message.headerForPortraitScreen, options);
                if (message.isTipsEnabled != null && message.hasOwnProperty("isTipsEnabled"))
                    object.isTipsEnabled = message.isTipsEnabled;
                return object;
            };

            /**
             * Converts this CheckInInfoProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CheckInInfoProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CheckInInfoProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CheckInInfoProto
             * @function getTypeUrl
             * @memberof waiternow.common.CheckInInfoProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CheckInInfoProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CheckInInfoProto";
            };

            return CheckInInfoProto;
        })();

        common.ReviewProto = (function() {

            /**
             * Properties of a ReviewProto.
             * @memberof waiternow.common
             * @interface IReviewProto
             * @property {string|null} [id] ReviewProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] ReviewProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] ReviewProto lastUpdateTime
             * @property {string|null} [locationId] ReviewProto locationId
             * @property {string|null} [pointOfServiceId] ReviewProto pointOfServiceId
             * @property {string|null} [consumerId] ReviewProto consumerId
             * @property {string|null} [deviceId] ReviewProto deviceId
             * @property {string|null} [summary] ReviewProto summary
             * @property {string|null} [comments] ReviewProto comments
             * @property {waiternow.common.Rating|null} [rating] ReviewProto rating
             * @property {waiternow.common.ThumbSignal|null} [thumbSignal] ReviewProto thumbSignal
             * @property {waiternow.common.ReviewProto.IRedundantDataProto|null} [redundantData] ReviewProto redundantData
             */

            /**
             * Constructs a new ReviewProto.
             * @memberof waiternow.common
             * @classdesc Represents a ReviewProto.
             * @implements IReviewProto
             * @constructor
             * @param {waiternow.common.IReviewProto=} [properties] Properties to set
             */
            function ReviewProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReviewProto id.
             * @member {string} id
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.id = "";

            /**
             * ReviewProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.creationTime = null;

            /**
             * ReviewProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.lastUpdateTime = null;

            /**
             * ReviewProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.locationId = "";

            /**
             * ReviewProto pointOfServiceId.
             * @member {string} pointOfServiceId
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.pointOfServiceId = "";

            /**
             * ReviewProto consumerId.
             * @member {string} consumerId
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.consumerId = "";

            /**
             * ReviewProto deviceId.
             * @member {string} deviceId
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.deviceId = "";

            /**
             * ReviewProto summary.
             * @member {string} summary
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.summary = "";

            /**
             * ReviewProto comments.
             * @member {string} comments
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.comments = "";

            /**
             * ReviewProto rating.
             * @member {waiternow.common.Rating|null|undefined} rating
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.rating = null;

            /**
             * ReviewProto thumbSignal.
             * @member {waiternow.common.ThumbSignal|null|undefined} thumbSignal
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.thumbSignal = null;

            /**
             * ReviewProto redundantData.
             * @member {waiternow.common.ReviewProto.IRedundantDataProto|null|undefined} redundantData
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            ReviewProto.prototype.redundantData = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ReviewProto ratingType.
             * @member {"rating"|"thumbSignal"|undefined} ratingType
             * @memberof waiternow.common.ReviewProto
             * @instance
             */
            Object.defineProperty(ReviewProto.prototype, "ratingType", {
                get: $util.oneOfGetter($oneOfFields = ["rating", "thumbSignal"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ReviewProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {waiternow.common.IReviewProto=} [properties] Properties to set
             * @returns {waiternow.common.ReviewProto} ReviewProto instance
             */
            ReviewProto.create = function create(properties) {
                return new ReviewProto(properties);
            };

            /**
             * Encodes the specified ReviewProto message. Does not implicitly {@link waiternow.common.ReviewProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {waiternow.common.IReviewProto} message ReviewProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReviewProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.pointOfServiceId);
                if (message.consumerId != null && Object.hasOwnProperty.call(message, "consumerId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.consumerId);
                if (message.summary != null && Object.hasOwnProperty.call(message, "summary"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.summary);
                if (message.comments != null && Object.hasOwnProperty.call(message, "comments"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.comments);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.rating != null && Object.hasOwnProperty.call(message, "rating"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int32(message.rating);
                if (message.thumbSignal != null && Object.hasOwnProperty.call(message, "thumbSignal"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int32(message.thumbSignal);
                if (message.redundantData != null && Object.hasOwnProperty.call(message, "redundantData"))
                    $root.waiternow.common.ReviewProto.RedundantDataProto.encode(message.redundantData, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                    writer.uint32(/* id 12, wireType 2 =*/98).string(message.deviceId);
                return writer;
            };

            /**
             * Encodes the specified ReviewProto message, length delimited. Does not implicitly {@link waiternow.common.ReviewProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {waiternow.common.IReviewProto} message ReviewProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReviewProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReviewProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ReviewProto} ReviewProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReviewProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ReviewProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 7: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 3: {
                            message.pointOfServiceId = reader.string();
                            break;
                        }
                    case 4: {
                            message.consumerId = reader.string();
                            break;
                        }
                    case 12: {
                            message.deviceId = reader.string();
                            break;
                        }
                    case 5: {
                            message.summary = reader.string();
                            break;
                        }
                    case 6: {
                            message.comments = reader.string();
                            break;
                        }
                    case 8: {
                            message.rating = reader.int32();
                            break;
                        }
                    case 9: {
                            message.thumbSignal = reader.int32();
                            break;
                        }
                    case 10: {
                            message.redundantData = $root.waiternow.common.ReviewProto.RedundantDataProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReviewProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ReviewProto} ReviewProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReviewProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReviewProto message.
             * @function verify
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReviewProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    if (!$util.isString(message.pointOfServiceId))
                        return "pointOfServiceId: string expected";
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    if (!$util.isString(message.consumerId))
                        return "consumerId: string expected";
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    if (!$util.isString(message.deviceId))
                        return "deviceId: string expected";
                if (message.summary != null && message.hasOwnProperty("summary"))
                    if (!$util.isString(message.summary))
                        return "summary: string expected";
                if (message.comments != null && message.hasOwnProperty("comments"))
                    if (!$util.isString(message.comments))
                        return "comments: string expected";
                if (message.rating != null && message.hasOwnProperty("rating")) {
                    properties.ratingType = 1;
                    switch (message.rating) {
                    default:
                        return "rating: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        break;
                    }
                }
                if (message.thumbSignal != null && message.hasOwnProperty("thumbSignal")) {
                    if (properties.ratingType === 1)
                        return "ratingType: multiple values";
                    properties.ratingType = 1;
                    switch (message.thumbSignal) {
                    default:
                        return "thumbSignal: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                        break;
                    }
                }
                if (message.redundantData != null && message.hasOwnProperty("redundantData")) {
                    var error = $root.waiternow.common.ReviewProto.RedundantDataProto.verify(message.redundantData);
                    if (error)
                        return "redundantData." + error;
                }
                return null;
            };

            /**
             * Creates a ReviewProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ReviewProto} ReviewProto
             */
            ReviewProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ReviewProto)
                    return object;
                var message = new $root.waiternow.common.ReviewProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.ReviewProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.ReviewProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.pointOfServiceId != null)
                    message.pointOfServiceId = String(object.pointOfServiceId);
                if (object.consumerId != null)
                    message.consumerId = String(object.consumerId);
                if (object.deviceId != null)
                    message.deviceId = String(object.deviceId);
                if (object.summary != null)
                    message.summary = String(object.summary);
                if (object.comments != null)
                    message.comments = String(object.comments);
                switch (object.rating) {
                default:
                    if (typeof object.rating === "number") {
                        message.rating = object.rating;
                        break;
                    }
                    break;
                case "RATING_UNSPECIFIED":
                case 0:
                    message.rating = 0;
                    break;
                case "ONE_STAR":
                case 1:
                    message.rating = 1;
                    break;
                case "TWO_STARS":
                case 2:
                    message.rating = 2;
                    break;
                case "THREE_STARS":
                case 3:
                    message.rating = 3;
                    break;
                case "FOUR_STARS":
                case 4:
                    message.rating = 4;
                    break;
                case "FIVE_STARS":
                case 5:
                    message.rating = 5;
                    break;
                }
                switch (object.thumbSignal) {
                default:
                    if (typeof object.thumbSignal === "number") {
                        message.thumbSignal = object.thumbSignal;
                        break;
                    }
                    break;
                case "THUMB_SIGNAL_UNSPECIFIED":
                case 0:
                    message.thumbSignal = 0;
                    break;
                case "THUMB_UP":
                case 1:
                    message.thumbSignal = 1;
                    break;
                case "THUMB_DOWN":
                case 2:
                    message.thumbSignal = 2;
                    break;
                }
                if (object.redundantData != null) {
                    if (typeof object.redundantData !== "object")
                        throw TypeError(".waiternow.common.ReviewProto.redundantData: object expected");
                    message.redundantData = $root.waiternow.common.ReviewProto.RedundantDataProto.fromObject(object.redundantData);
                }
                return message;
            };

            /**
             * Creates a plain object from a ReviewProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {waiternow.common.ReviewProto} message ReviewProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReviewProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.locationId = "";
                    object.pointOfServiceId = "";
                    object.consumerId = "";
                    object.summary = "";
                    object.comments = "";
                    object.creationTime = null;
                    object.redundantData = null;
                    object.lastUpdateTime = null;
                    object.deviceId = "";
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                    object.pointOfServiceId = message.pointOfServiceId;
                if (message.consumerId != null && message.hasOwnProperty("consumerId"))
                    object.consumerId = message.consumerId;
                if (message.summary != null && message.hasOwnProperty("summary"))
                    object.summary = message.summary;
                if (message.comments != null && message.hasOwnProperty("comments"))
                    object.comments = message.comments;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.rating != null && message.hasOwnProperty("rating")) {
                    object.rating = options.enums === String ? $root.waiternow.common.Rating[message.rating] === undefined ? message.rating : $root.waiternow.common.Rating[message.rating] : message.rating;
                    if (options.oneofs)
                        object.ratingType = "rating";
                }
                if (message.thumbSignal != null && message.hasOwnProperty("thumbSignal")) {
                    object.thumbSignal = options.enums === String ? $root.waiternow.common.ThumbSignal[message.thumbSignal] === undefined ? message.thumbSignal : $root.waiternow.common.ThumbSignal[message.thumbSignal] : message.thumbSignal;
                    if (options.oneofs)
                        object.ratingType = "thumbSignal";
                }
                if (message.redundantData != null && message.hasOwnProperty("redundantData"))
                    object.redundantData = $root.waiternow.common.ReviewProto.RedundantDataProto.toObject(message.redundantData, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                    object.deviceId = message.deviceId;
                return object;
            };

            /**
             * Converts this ReviewProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ReviewProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReviewProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReviewProto
             * @function getTypeUrl
             * @memberof waiternow.common.ReviewProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReviewProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ReviewProto";
            };

            ReviewProto.RedundantDataProto = (function() {

                /**
                 * Properties of a RedundantDataProto.
                 * @memberof waiternow.common.ReviewProto
                 * @interface IRedundantDataProto
                 * @property {string|null} [pointOfServiceFriendlyName] RedundantDataProto pointOfServiceFriendlyName
                 * @property {string|null} [consumerName] RedundantDataProto consumerName
                 * @property {waiternow.common.Country|null} [locationCountry] RedundantDataProto locationCountry
                 */

                /**
                 * Constructs a new RedundantDataProto.
                 * @memberof waiternow.common.ReviewProto
                 * @classdesc Represents a RedundantDataProto.
                 * @implements IRedundantDataProto
                 * @constructor
                 * @param {waiternow.common.ReviewProto.IRedundantDataProto=} [properties] Properties to set
                 */
                function RedundantDataProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * RedundantDataProto pointOfServiceFriendlyName.
                 * @member {string} pointOfServiceFriendlyName
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.pointOfServiceFriendlyName = "";

                /**
                 * RedundantDataProto consumerName.
                 * @member {string} consumerName
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.consumerName = "";

                /**
                 * RedundantDataProto locationCountry.
                 * @member {waiternow.common.Country} locationCountry
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @instance
                 */
                RedundantDataProto.prototype.locationCountry = 0;

                /**
                 * Creates a new RedundantDataProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.ReviewProto.IRedundantDataProto=} [properties] Properties to set
                 * @returns {waiternow.common.ReviewProto.RedundantDataProto} RedundantDataProto instance
                 */
                RedundantDataProto.create = function create(properties) {
                    return new RedundantDataProto(properties);
                };

                /**
                 * Encodes the specified RedundantDataProto message. Does not implicitly {@link waiternow.common.ReviewProto.RedundantDataProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.ReviewProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceFriendlyName != null && Object.hasOwnProperty.call(message, "pointOfServiceFriendlyName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceFriendlyName);
                    if (message.consumerName != null && Object.hasOwnProperty.call(message, "consumerName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.consumerName);
                    if (message.locationCountry != null && Object.hasOwnProperty.call(message, "locationCountry"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.locationCountry);
                    return writer;
                };

                /**
                 * Encodes the specified RedundantDataProto message, length delimited. Does not implicitly {@link waiternow.common.ReviewProto.RedundantDataProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.ReviewProto.IRedundantDataProto} message RedundantDataProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                RedundantDataProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ReviewProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ReviewProto.RedundantDataProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceFriendlyName = reader.string();
                                break;
                            }
                        case 2: {
                                message.consumerName = reader.string();
                                break;
                            }
                        case 3: {
                                message.locationCountry = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a RedundantDataProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ReviewProto.RedundantDataProto} RedundantDataProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                RedundantDataProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a RedundantDataProto message.
                 * @function verify
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                RedundantDataProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                        if (!$util.isString(message.pointOfServiceFriendlyName))
                            return "pointOfServiceFriendlyName: string expected";
                    if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                        if (!$util.isString(message.consumerName))
                            return "consumerName: string expected";
                    if (message.locationCountry != null && message.hasOwnProperty("locationCountry"))
                        switch (message.locationCountry) {
                        default:
                            return "locationCountry: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a RedundantDataProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ReviewProto.RedundantDataProto} RedundantDataProto
                 */
                RedundantDataProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ReviewProto.RedundantDataProto)
                        return object;
                    var message = new $root.waiternow.common.ReviewProto.RedundantDataProto();
                    if (object.pointOfServiceFriendlyName != null)
                        message.pointOfServiceFriendlyName = String(object.pointOfServiceFriendlyName);
                    if (object.consumerName != null)
                        message.consumerName = String(object.consumerName);
                    switch (object.locationCountry) {
                    default:
                        if (typeof object.locationCountry === "number") {
                            message.locationCountry = object.locationCountry;
                            break;
                        }
                        break;
                    case "COUNTRY_UNSPECIFIED":
                    case 0:
                        message.locationCountry = 0;
                        break;
                    case "UNITED_STATES":
                    case 1:
                        message.locationCountry = 1;
                        break;
                    case "MEXICO":
                    case 2:
                        message.locationCountry = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a RedundantDataProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {waiternow.common.ReviewProto.RedundantDataProto} message RedundantDataProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                RedundantDataProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.pointOfServiceFriendlyName = "";
                        object.consumerName = "";
                        object.locationCountry = options.enums === String ? "COUNTRY_UNSPECIFIED" : 0;
                    }
                    if (message.pointOfServiceFriendlyName != null && message.hasOwnProperty("pointOfServiceFriendlyName"))
                        object.pointOfServiceFriendlyName = message.pointOfServiceFriendlyName;
                    if (message.consumerName != null && message.hasOwnProperty("consumerName"))
                        object.consumerName = message.consumerName;
                    if (message.locationCountry != null && message.hasOwnProperty("locationCountry"))
                        object.locationCountry = options.enums === String ? $root.waiternow.common.Country[message.locationCountry] === undefined ? message.locationCountry : $root.waiternow.common.Country[message.locationCountry] : message.locationCountry;
                    return object;
                };

                /**
                 * Converts this RedundantDataProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                RedundantDataProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for RedundantDataProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ReviewProto.RedundantDataProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                RedundantDataProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ReviewProto.RedundantDataProto";
                };

                return RedundantDataProto;
            })();

            return ReviewProto;
        })();

        common.ReviewsProto = (function() {

            /**
             * Properties of a ReviewsProto.
             * @memberof waiternow.common
             * @interface IReviewsProto
             * @property {Array.<waiternow.common.IReviewProto>|null} [reviews] ReviewsProto reviews
             * @property {string|null} [continuationToken] ReviewsProto continuationToken
             */

            /**
             * Constructs a new ReviewsProto.
             * @memberof waiternow.common
             * @classdesc Represents a ReviewsProto.
             * @implements IReviewsProto
             * @constructor
             * @param {waiternow.common.IReviewsProto=} [properties] Properties to set
             */
            function ReviewsProto(properties) {
                this.reviews = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ReviewsProto reviews.
             * @member {Array.<waiternow.common.IReviewProto>} reviews
             * @memberof waiternow.common.ReviewsProto
             * @instance
             */
            ReviewsProto.prototype.reviews = $util.emptyArray;

            /**
             * ReviewsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.ReviewsProto
             * @instance
             */
            ReviewsProto.prototype.continuationToken = "";

            /**
             * Creates a new ReviewsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {waiternow.common.IReviewsProto=} [properties] Properties to set
             * @returns {waiternow.common.ReviewsProto} ReviewsProto instance
             */
            ReviewsProto.create = function create(properties) {
                return new ReviewsProto(properties);
            };

            /**
             * Encodes the specified ReviewsProto message. Does not implicitly {@link waiternow.common.ReviewsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {waiternow.common.IReviewsProto} message ReviewsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReviewsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.reviews != null && message.reviews.length)
                    for (var i = 0; i < message.reviews.length; ++i)
                        $root.waiternow.common.ReviewProto.encode(message.reviews[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified ReviewsProto message, length delimited. Does not implicitly {@link waiternow.common.ReviewsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {waiternow.common.IReviewsProto} message ReviewsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ReviewsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ReviewsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ReviewsProto} ReviewsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReviewsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ReviewsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.reviews && message.reviews.length))
                                message.reviews = [];
                            message.reviews.push($root.waiternow.common.ReviewProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ReviewsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ReviewsProto} ReviewsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ReviewsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ReviewsProto message.
             * @function verify
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ReviewsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.reviews != null && message.hasOwnProperty("reviews")) {
                    if (!Array.isArray(message.reviews))
                        return "reviews: array expected";
                    for (var i = 0; i < message.reviews.length; ++i) {
                        var error = $root.waiternow.common.ReviewProto.verify(message.reviews[i]);
                        if (error)
                            return "reviews." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a ReviewsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ReviewsProto} ReviewsProto
             */
            ReviewsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ReviewsProto)
                    return object;
                var message = new $root.waiternow.common.ReviewsProto();
                if (object.reviews) {
                    if (!Array.isArray(object.reviews))
                        throw TypeError(".waiternow.common.ReviewsProto.reviews: array expected");
                    message.reviews = [];
                    for (var i = 0; i < object.reviews.length; ++i) {
                        if (typeof object.reviews[i] !== "object")
                            throw TypeError(".waiternow.common.ReviewsProto.reviews: object expected");
                        message.reviews[i] = $root.waiternow.common.ReviewProto.fromObject(object.reviews[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a ReviewsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {waiternow.common.ReviewsProto} message ReviewsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ReviewsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.reviews = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.reviews && message.reviews.length) {
                    object.reviews = [];
                    for (var j = 0; j < message.reviews.length; ++j)
                        object.reviews[j] = $root.waiternow.common.ReviewProto.toObject(message.reviews[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this ReviewsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ReviewsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ReviewsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ReviewsProto
             * @function getTypeUrl
             * @memberof waiternow.common.ReviewsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ReviewsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ReviewsProto";
            };

            return ReviewsProto;
        })();

        common.LocationReviewsProto = (function() {

            /**
             * Properties of a LocationReviewsProto.
             * @memberof waiternow.common
             * @interface ILocationReviewsProto
             * @property {string|null} [locationId] LocationReviewsProto locationId
             * @property {Array.<waiternow.common.IReviewProto>|null} [reviews] LocationReviewsProto reviews
             * @property {string|null} [continuationToken] LocationReviewsProto continuationToken
             */

            /**
             * Constructs a new LocationReviewsProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationReviewsProto.
             * @implements ILocationReviewsProto
             * @constructor
             * @param {waiternow.common.ILocationReviewsProto=} [properties] Properties to set
             */
            function LocationReviewsProto(properties) {
                this.reviews = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationReviewsProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.LocationReviewsProto
             * @instance
             */
            LocationReviewsProto.prototype.locationId = "";

            /**
             * LocationReviewsProto reviews.
             * @member {Array.<waiternow.common.IReviewProto>} reviews
             * @memberof waiternow.common.LocationReviewsProto
             * @instance
             */
            LocationReviewsProto.prototype.reviews = $util.emptyArray;

            /**
             * LocationReviewsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.LocationReviewsProto
             * @instance
             */
            LocationReviewsProto.prototype.continuationToken = "";

            /**
             * Creates a new LocationReviewsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {waiternow.common.ILocationReviewsProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationReviewsProto} LocationReviewsProto instance
             */
            LocationReviewsProto.create = function create(properties) {
                return new LocationReviewsProto(properties);
            };

            /**
             * Encodes the specified LocationReviewsProto message. Does not implicitly {@link waiternow.common.LocationReviewsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {waiternow.common.ILocationReviewsProto} message LocationReviewsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationReviewsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                if (message.reviews != null && message.reviews.length)
                    for (var i = 0; i < message.reviews.length; ++i)
                        $root.waiternow.common.ReviewProto.encode(message.reviews[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 3, wireType 2 =*/26).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified LocationReviewsProto message, length delimited. Does not implicitly {@link waiternow.common.LocationReviewsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {waiternow.common.ILocationReviewsProto} message LocationReviewsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationReviewsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationReviewsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationReviewsProto} LocationReviewsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationReviewsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationReviewsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 2: {
                            if (!(message.reviews && message.reviews.length))
                                message.reviews = [];
                            message.reviews.push($root.waiternow.common.ReviewProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 3: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationReviewsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationReviewsProto} LocationReviewsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationReviewsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationReviewsProto message.
             * @function verify
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationReviewsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.reviews != null && message.hasOwnProperty("reviews")) {
                    if (!Array.isArray(message.reviews))
                        return "reviews: array expected";
                    for (var i = 0; i < message.reviews.length; ++i) {
                        var error = $root.waiternow.common.ReviewProto.verify(message.reviews[i]);
                        if (error)
                            return "reviews." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a LocationReviewsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationReviewsProto} LocationReviewsProto
             */
            LocationReviewsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationReviewsProto)
                    return object;
                var message = new $root.waiternow.common.LocationReviewsProto();
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.reviews) {
                    if (!Array.isArray(object.reviews))
                        throw TypeError(".waiternow.common.LocationReviewsProto.reviews: array expected");
                    message.reviews = [];
                    for (var i = 0; i < object.reviews.length; ++i) {
                        if (typeof object.reviews[i] !== "object")
                            throw TypeError(".waiternow.common.LocationReviewsProto.reviews: object expected");
                        message.reviews[i] = $root.waiternow.common.ReviewProto.fromObject(object.reviews[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a LocationReviewsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {waiternow.common.LocationReviewsProto} message LocationReviewsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationReviewsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.reviews = [];
                if (options.defaults) {
                    object.locationId = "";
                    object.continuationToken = "";
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.reviews && message.reviews.length) {
                    object.reviews = [];
                    for (var j = 0; j < message.reviews.length; ++j)
                        object.reviews[j] = $root.waiternow.common.ReviewProto.toObject(message.reviews[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this LocationReviewsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationReviewsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationReviewsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationReviewsProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationReviewsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationReviewsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationReviewsProto";
            };

            return LocationReviewsProto;
        })();

        /**
         * PeriodType enum.
         * @name waiternow.common.PeriodType
         * @enum {number}
         * @property {number} PERIOD_TYPE_UNSPECIFIED=0 PERIOD_TYPE_UNSPECIFIED value
         * @property {number} DAILY=1 DAILY value
         * @property {number} MONTHLY=2 MONTHLY value
         * @property {number} YEARLY=3 YEARLY value
         */
        common.PeriodType = (function() {
            var valuesById = {}, values = Object.create(valuesById);
            values[valuesById[0] = "PERIOD_TYPE_UNSPECIFIED"] = 0;
            values[valuesById[1] = "DAILY"] = 1;
            values[valuesById[2] = "MONTHLY"] = 2;
            values[valuesById[3] = "YEARLY"] = 3;
            return values;
        })();

        common.MetricProto = (function() {

            /**
             * Properties of a MetricProto.
             * @memberof waiternow.common
             * @interface IMetricProto
             * @property {string|null} [id] MetricProto id
             * @property {waiternow.common.IDateRangeProto|null} [period] MetricProto period
             * @property {waiternow.common.PeriodType|null} [periodType] MetricProto periodType
             * @property {google.protobuf.ITimestamp|null} [creationTime] MetricProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] MetricProto lastUpdateTime
             * @property {number|null} [newLocations] MetricProto newLocations
             * @property {number|null} [locationsEnrolledOnPayments] MetricProto locationsEnrolledOnPayments
             * @property {number|null} [paidOrders] MetricProto paidOrders
             * @property {number|null} [refundedOrders] MetricProto refundedOrders
             * @property {waiternow.common.IMoneyProto|null} [orderPaymentsVolume] MetricProto orderPaymentsVolume
             * @property {waiternow.common.IMoneyProto|null} [waiternowOrderPaymentsRevenue] MetricProto waiternowOrderPaymentsRevenue
             * @property {waiternow.common.IMoneyProto|null} [refundedOrdersVolume] MetricProto refundedOrdersVolume
             * @property {number|null} [checkIns] MetricProto checkIns
             * @property {number|null} [orders] MetricProto orders
             * @property {number|null} [reviews] MetricProto reviews
             * @property {number|null} [reviewsWithComments] MetricProto reviewsWithComments
             * @property {number|null} [incompleteOrders] MetricProto incompleteOrders
             * @property {number|null} [onlinePickupOrders] MetricProto onlinePickupOrders
             * @property {number|null} [deliveryOrders] MetricProto deliveryOrders
             * @property {waiternow.common.MetricProto.IMetricValuesProto|null} [metricValuesUs] MetricProto metricValuesUs
             * @property {waiternow.common.MetricProto.IMetricValuesProto|null} [metricValuesMx] MetricProto metricValuesMx
             */

            /**
             * Constructs a new MetricProto.
             * @memberof waiternow.common
             * @classdesc Represents a MetricProto.
             * @implements IMetricProto
             * @constructor
             * @param {waiternow.common.IMetricProto=} [properties] Properties to set
             */
            function MetricProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MetricProto id.
             * @member {string} id
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.id = "";

            /**
             * MetricProto period.
             * @member {waiternow.common.IDateRangeProto|null|undefined} period
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.period = null;

            /**
             * MetricProto periodType.
             * @member {waiternow.common.PeriodType} periodType
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.periodType = 0;

            /**
             * MetricProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.creationTime = null;

            /**
             * MetricProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.lastUpdateTime = null;

            /**
             * MetricProto newLocations.
             * @member {number} newLocations
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.newLocations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto locationsEnrolledOnPayments.
             * @member {number} locationsEnrolledOnPayments
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.locationsEnrolledOnPayments = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto paidOrders.
             * @member {number} paidOrders
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.paidOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto refundedOrders.
             * @member {number} refundedOrders
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.refundedOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto orderPaymentsVolume.
             * @member {waiternow.common.IMoneyProto|null|undefined} orderPaymentsVolume
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.orderPaymentsVolume = null;

            /**
             * MetricProto waiternowOrderPaymentsRevenue.
             * @member {waiternow.common.IMoneyProto|null|undefined} waiternowOrderPaymentsRevenue
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.waiternowOrderPaymentsRevenue = null;

            /**
             * MetricProto refundedOrdersVolume.
             * @member {waiternow.common.IMoneyProto|null|undefined} refundedOrdersVolume
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.refundedOrdersVolume = null;

            /**
             * MetricProto checkIns.
             * @member {number} checkIns
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.checkIns = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto orders.
             * @member {number} orders
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.orders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto reviews.
             * @member {number} reviews
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.reviews = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto reviewsWithComments.
             * @member {number} reviewsWithComments
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.reviewsWithComments = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto incompleteOrders.
             * @member {number} incompleteOrders
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.incompleteOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto onlinePickupOrders.
             * @member {number} onlinePickupOrders
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.onlinePickupOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto deliveryOrders.
             * @member {number} deliveryOrders
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.deliveryOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * MetricProto metricValuesUs.
             * @member {waiternow.common.MetricProto.IMetricValuesProto|null|undefined} metricValuesUs
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.metricValuesUs = null;

            /**
             * MetricProto metricValuesMx.
             * @member {waiternow.common.MetricProto.IMetricValuesProto|null|undefined} metricValuesMx
             * @memberof waiternow.common.MetricProto
             * @instance
             */
            MetricProto.prototype.metricValuesMx = null;

            /**
             * Creates a new MetricProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {waiternow.common.IMetricProto=} [properties] Properties to set
             * @returns {waiternow.common.MetricProto} MetricProto instance
             */
            MetricProto.create = function create(properties) {
                return new MetricProto(properties);
            };

            /**
             * Encodes the specified MetricProto message. Does not implicitly {@link waiternow.common.MetricProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {waiternow.common.IMetricProto} message MetricProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MetricProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                    $root.waiternow.common.DateRangeProto.encode(message.period, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.periodType);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.newLocations != null && Object.hasOwnProperty.call(message, "newLocations"))
                    writer.uint32(/* id 6, wireType 0 =*/48).int64(message.newLocations);
                if (message.locationsEnrolledOnPayments != null && Object.hasOwnProperty.call(message, "locationsEnrolledOnPayments"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.locationsEnrolledOnPayments);
                if (message.paidOrders != null && Object.hasOwnProperty.call(message, "paidOrders"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.paidOrders);
                if (message.refundedOrders != null && Object.hasOwnProperty.call(message, "refundedOrders"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.refundedOrders);
                if (message.orderPaymentsVolume != null && Object.hasOwnProperty.call(message, "orderPaymentsVolume"))
                    $root.waiternow.common.MoneyProto.encode(message.orderPaymentsVolume, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.waiternowOrderPaymentsRevenue != null && Object.hasOwnProperty.call(message, "waiternowOrderPaymentsRevenue"))
                    $root.waiternow.common.MoneyProto.encode(message.waiternowOrderPaymentsRevenue, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.refundedOrdersVolume != null && Object.hasOwnProperty.call(message, "refundedOrdersVolume"))
                    $root.waiternow.common.MoneyProto.encode(message.refundedOrdersVolume, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.checkIns != null && Object.hasOwnProperty.call(message, "checkIns"))
                    writer.uint32(/* id 13, wireType 0 =*/104).int64(message.checkIns);
                if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                    writer.uint32(/* id 14, wireType 0 =*/112).int64(message.orders);
                if (message.reviews != null && Object.hasOwnProperty.call(message, "reviews"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int64(message.reviews);
                if (message.reviewsWithComments != null && Object.hasOwnProperty.call(message, "reviewsWithComments"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int64(message.reviewsWithComments);
                if (message.incompleteOrders != null && Object.hasOwnProperty.call(message, "incompleteOrders"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int64(message.incompleteOrders);
                if (message.onlinePickupOrders != null && Object.hasOwnProperty.call(message, "onlinePickupOrders"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.onlinePickupOrders);
                if (message.deliveryOrders != null && Object.hasOwnProperty.call(message, "deliveryOrders"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.deliveryOrders);
                if (message.metricValuesUs != null && Object.hasOwnProperty.call(message, "metricValuesUs"))
                    $root.waiternow.common.MetricProto.MetricValuesProto.encode(message.metricValuesUs, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
                if (message.metricValuesMx != null && Object.hasOwnProperty.call(message, "metricValuesMx"))
                    $root.waiternow.common.MetricProto.MetricValuesProto.encode(message.metricValuesMx, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified MetricProto message, length delimited. Does not implicitly {@link waiternow.common.MetricProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {waiternow.common.IMetricProto} message MetricProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MetricProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MetricProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MetricProto} MetricProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MetricProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MetricProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.period = $root.waiternow.common.DateRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.periodType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.newLocations = reader.int64();
                            break;
                        }
                    case 7: {
                            message.locationsEnrolledOnPayments = reader.int64();
                            break;
                        }
                    case 8: {
                            message.paidOrders = reader.int64();
                            break;
                        }
                    case 9: {
                            message.refundedOrders = reader.int64();
                            break;
                        }
                    case 10: {
                            message.orderPaymentsVolume = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.refundedOrdersVolume = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.checkIns = reader.int64();
                            break;
                        }
                    case 14: {
                            message.orders = reader.int64();
                            break;
                        }
                    case 15: {
                            message.reviews = reader.int64();
                            break;
                        }
                    case 16: {
                            message.reviewsWithComments = reader.int64();
                            break;
                        }
                    case 17: {
                            message.incompleteOrders = reader.int64();
                            break;
                        }
                    case 18: {
                            message.onlinePickupOrders = reader.int64();
                            break;
                        }
                    case 19: {
                            message.deliveryOrders = reader.int64();
                            break;
                        }
                    case 20: {
                            message.metricValuesUs = $root.waiternow.common.MetricProto.MetricValuesProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 21: {
                            message.metricValuesMx = $root.waiternow.common.MetricProto.MetricValuesProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MetricProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MetricProto} MetricProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MetricProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MetricProto message.
             * @function verify
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MetricProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.period != null && message.hasOwnProperty("period")) {
                    var error = $root.waiternow.common.DateRangeProto.verify(message.period);
                    if (error)
                        return "period." + error;
                }
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    switch (message.periodType) {
                    default:
                        return "periodType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.newLocations != null && message.hasOwnProperty("newLocations"))
                    if (!$util.isInteger(message.newLocations) && !(message.newLocations && $util.isInteger(message.newLocations.low) && $util.isInteger(message.newLocations.high)))
                        return "newLocations: integer|Long expected";
                if (message.locationsEnrolledOnPayments != null && message.hasOwnProperty("locationsEnrolledOnPayments"))
                    if (!$util.isInteger(message.locationsEnrolledOnPayments) && !(message.locationsEnrolledOnPayments && $util.isInteger(message.locationsEnrolledOnPayments.low) && $util.isInteger(message.locationsEnrolledOnPayments.high)))
                        return "locationsEnrolledOnPayments: integer|Long expected";
                if (message.paidOrders != null && message.hasOwnProperty("paidOrders"))
                    if (!$util.isInteger(message.paidOrders) && !(message.paidOrders && $util.isInteger(message.paidOrders.low) && $util.isInteger(message.paidOrders.high)))
                        return "paidOrders: integer|Long expected";
                if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                    if (!$util.isInteger(message.refundedOrders) && !(message.refundedOrders && $util.isInteger(message.refundedOrders.low) && $util.isInteger(message.refundedOrders.high)))
                        return "refundedOrders: integer|Long expected";
                if (message.orderPaymentsVolume != null && message.hasOwnProperty("orderPaymentsVolume")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.orderPaymentsVolume);
                    if (error)
                        return "orderPaymentsVolume." + error;
                }
                if (message.waiternowOrderPaymentsRevenue != null && message.hasOwnProperty("waiternowOrderPaymentsRevenue")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.waiternowOrderPaymentsRevenue);
                    if (error)
                        return "waiternowOrderPaymentsRevenue." + error;
                }
                if (message.refundedOrdersVolume != null && message.hasOwnProperty("refundedOrdersVolume")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.refundedOrdersVolume);
                    if (error)
                        return "refundedOrdersVolume." + error;
                }
                if (message.checkIns != null && message.hasOwnProperty("checkIns"))
                    if (!$util.isInteger(message.checkIns) && !(message.checkIns && $util.isInteger(message.checkIns.low) && $util.isInteger(message.checkIns.high)))
                        return "checkIns: integer|Long expected";
                if (message.orders != null && message.hasOwnProperty("orders"))
                    if (!$util.isInteger(message.orders) && !(message.orders && $util.isInteger(message.orders.low) && $util.isInteger(message.orders.high)))
                        return "orders: integer|Long expected";
                if (message.reviews != null && message.hasOwnProperty("reviews"))
                    if (!$util.isInteger(message.reviews) && !(message.reviews && $util.isInteger(message.reviews.low) && $util.isInteger(message.reviews.high)))
                        return "reviews: integer|Long expected";
                if (message.reviewsWithComments != null && message.hasOwnProperty("reviewsWithComments"))
                    if (!$util.isInteger(message.reviewsWithComments) && !(message.reviewsWithComments && $util.isInteger(message.reviewsWithComments.low) && $util.isInteger(message.reviewsWithComments.high)))
                        return "reviewsWithComments: integer|Long expected";
                if (message.incompleteOrders != null && message.hasOwnProperty("incompleteOrders"))
                    if (!$util.isInteger(message.incompleteOrders) && !(message.incompleteOrders && $util.isInteger(message.incompleteOrders.low) && $util.isInteger(message.incompleteOrders.high)))
                        return "incompleteOrders: integer|Long expected";
                if (message.onlinePickupOrders != null && message.hasOwnProperty("onlinePickupOrders"))
                    if (!$util.isInteger(message.onlinePickupOrders) && !(message.onlinePickupOrders && $util.isInteger(message.onlinePickupOrders.low) && $util.isInteger(message.onlinePickupOrders.high)))
                        return "onlinePickupOrders: integer|Long expected";
                if (message.deliveryOrders != null && message.hasOwnProperty("deliveryOrders"))
                    if (!$util.isInteger(message.deliveryOrders) && !(message.deliveryOrders && $util.isInteger(message.deliveryOrders.low) && $util.isInteger(message.deliveryOrders.high)))
                        return "deliveryOrders: integer|Long expected";
                if (message.metricValuesUs != null && message.hasOwnProperty("metricValuesUs")) {
                    var error = $root.waiternow.common.MetricProto.MetricValuesProto.verify(message.metricValuesUs);
                    if (error)
                        return "metricValuesUs." + error;
                }
                if (message.metricValuesMx != null && message.hasOwnProperty("metricValuesMx")) {
                    var error = $root.waiternow.common.MetricProto.MetricValuesProto.verify(message.metricValuesMx);
                    if (error)
                        return "metricValuesMx." + error;
                }
                return null;
            };

            /**
             * Creates a MetricProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MetricProto} MetricProto
             */
            MetricProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MetricProto)
                    return object;
                var message = new $root.waiternow.common.MetricProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.period != null) {
                    if (typeof object.period !== "object")
                        throw TypeError(".waiternow.common.MetricProto.period: object expected");
                    message.period = $root.waiternow.common.DateRangeProto.fromObject(object.period);
                }
                switch (object.periodType) {
                default:
                    if (typeof object.periodType === "number") {
                        message.periodType = object.periodType;
                        break;
                    }
                    break;
                case "PERIOD_TYPE_UNSPECIFIED":
                case 0:
                    message.periodType = 0;
                    break;
                case "DAILY":
                case 1:
                    message.periodType = 1;
                    break;
                case "MONTHLY":
                case 2:
                    message.periodType = 2;
                    break;
                case "YEARLY":
                case 3:
                    message.periodType = 3;
                    break;
                }
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.MetricProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.MetricProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.newLocations != null)
                    if ($util.Long)
                        (message.newLocations = $util.Long.fromValue(object.newLocations)).unsigned = false;
                    else if (typeof object.newLocations === "string")
                        message.newLocations = parseInt(object.newLocations, 10);
                    else if (typeof object.newLocations === "number")
                        message.newLocations = object.newLocations;
                    else if (typeof object.newLocations === "object")
                        message.newLocations = new $util.LongBits(object.newLocations.low >>> 0, object.newLocations.high >>> 0).toNumber();
                if (object.locationsEnrolledOnPayments != null)
                    if ($util.Long)
                        (message.locationsEnrolledOnPayments = $util.Long.fromValue(object.locationsEnrolledOnPayments)).unsigned = false;
                    else if (typeof object.locationsEnrolledOnPayments === "string")
                        message.locationsEnrolledOnPayments = parseInt(object.locationsEnrolledOnPayments, 10);
                    else if (typeof object.locationsEnrolledOnPayments === "number")
                        message.locationsEnrolledOnPayments = object.locationsEnrolledOnPayments;
                    else if (typeof object.locationsEnrolledOnPayments === "object")
                        message.locationsEnrolledOnPayments = new $util.LongBits(object.locationsEnrolledOnPayments.low >>> 0, object.locationsEnrolledOnPayments.high >>> 0).toNumber();
                if (object.paidOrders != null)
                    if ($util.Long)
                        (message.paidOrders = $util.Long.fromValue(object.paidOrders)).unsigned = false;
                    else if (typeof object.paidOrders === "string")
                        message.paidOrders = parseInt(object.paidOrders, 10);
                    else if (typeof object.paidOrders === "number")
                        message.paidOrders = object.paidOrders;
                    else if (typeof object.paidOrders === "object")
                        message.paidOrders = new $util.LongBits(object.paidOrders.low >>> 0, object.paidOrders.high >>> 0).toNumber();
                if (object.refundedOrders != null)
                    if ($util.Long)
                        (message.refundedOrders = $util.Long.fromValue(object.refundedOrders)).unsigned = false;
                    else if (typeof object.refundedOrders === "string")
                        message.refundedOrders = parseInt(object.refundedOrders, 10);
                    else if (typeof object.refundedOrders === "number")
                        message.refundedOrders = object.refundedOrders;
                    else if (typeof object.refundedOrders === "object")
                        message.refundedOrders = new $util.LongBits(object.refundedOrders.low >>> 0, object.refundedOrders.high >>> 0).toNumber();
                if (object.orderPaymentsVolume != null) {
                    if (typeof object.orderPaymentsVolume !== "object")
                        throw TypeError(".waiternow.common.MetricProto.orderPaymentsVolume: object expected");
                    message.orderPaymentsVolume = $root.waiternow.common.MoneyProto.fromObject(object.orderPaymentsVolume);
                }
                if (object.waiternowOrderPaymentsRevenue != null) {
                    if (typeof object.waiternowOrderPaymentsRevenue !== "object")
                        throw TypeError(".waiternow.common.MetricProto.waiternowOrderPaymentsRevenue: object expected");
                    message.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.fromObject(object.waiternowOrderPaymentsRevenue);
                }
                if (object.refundedOrdersVolume != null) {
                    if (typeof object.refundedOrdersVolume !== "object")
                        throw TypeError(".waiternow.common.MetricProto.refundedOrdersVolume: object expected");
                    message.refundedOrdersVolume = $root.waiternow.common.MoneyProto.fromObject(object.refundedOrdersVolume);
                }
                if (object.checkIns != null)
                    if ($util.Long)
                        (message.checkIns = $util.Long.fromValue(object.checkIns)).unsigned = false;
                    else if (typeof object.checkIns === "string")
                        message.checkIns = parseInt(object.checkIns, 10);
                    else if (typeof object.checkIns === "number")
                        message.checkIns = object.checkIns;
                    else if (typeof object.checkIns === "object")
                        message.checkIns = new $util.LongBits(object.checkIns.low >>> 0, object.checkIns.high >>> 0).toNumber();
                if (object.orders != null)
                    if ($util.Long)
                        (message.orders = $util.Long.fromValue(object.orders)).unsigned = false;
                    else if (typeof object.orders === "string")
                        message.orders = parseInt(object.orders, 10);
                    else if (typeof object.orders === "number")
                        message.orders = object.orders;
                    else if (typeof object.orders === "object")
                        message.orders = new $util.LongBits(object.orders.low >>> 0, object.orders.high >>> 0).toNumber();
                if (object.reviews != null)
                    if ($util.Long)
                        (message.reviews = $util.Long.fromValue(object.reviews)).unsigned = false;
                    else if (typeof object.reviews === "string")
                        message.reviews = parseInt(object.reviews, 10);
                    else if (typeof object.reviews === "number")
                        message.reviews = object.reviews;
                    else if (typeof object.reviews === "object")
                        message.reviews = new $util.LongBits(object.reviews.low >>> 0, object.reviews.high >>> 0).toNumber();
                if (object.reviewsWithComments != null)
                    if ($util.Long)
                        (message.reviewsWithComments = $util.Long.fromValue(object.reviewsWithComments)).unsigned = false;
                    else if (typeof object.reviewsWithComments === "string")
                        message.reviewsWithComments = parseInt(object.reviewsWithComments, 10);
                    else if (typeof object.reviewsWithComments === "number")
                        message.reviewsWithComments = object.reviewsWithComments;
                    else if (typeof object.reviewsWithComments === "object")
                        message.reviewsWithComments = new $util.LongBits(object.reviewsWithComments.low >>> 0, object.reviewsWithComments.high >>> 0).toNumber();
                if (object.incompleteOrders != null)
                    if ($util.Long)
                        (message.incompleteOrders = $util.Long.fromValue(object.incompleteOrders)).unsigned = false;
                    else if (typeof object.incompleteOrders === "string")
                        message.incompleteOrders = parseInt(object.incompleteOrders, 10);
                    else if (typeof object.incompleteOrders === "number")
                        message.incompleteOrders = object.incompleteOrders;
                    else if (typeof object.incompleteOrders === "object")
                        message.incompleteOrders = new $util.LongBits(object.incompleteOrders.low >>> 0, object.incompleteOrders.high >>> 0).toNumber();
                if (object.onlinePickupOrders != null)
                    if ($util.Long)
                        (message.onlinePickupOrders = $util.Long.fromValue(object.onlinePickupOrders)).unsigned = false;
                    else if (typeof object.onlinePickupOrders === "string")
                        message.onlinePickupOrders = parseInt(object.onlinePickupOrders, 10);
                    else if (typeof object.onlinePickupOrders === "number")
                        message.onlinePickupOrders = object.onlinePickupOrders;
                    else if (typeof object.onlinePickupOrders === "object")
                        message.onlinePickupOrders = new $util.LongBits(object.onlinePickupOrders.low >>> 0, object.onlinePickupOrders.high >>> 0).toNumber();
                if (object.deliveryOrders != null)
                    if ($util.Long)
                        (message.deliveryOrders = $util.Long.fromValue(object.deliveryOrders)).unsigned = false;
                    else if (typeof object.deliveryOrders === "string")
                        message.deliveryOrders = parseInt(object.deliveryOrders, 10);
                    else if (typeof object.deliveryOrders === "number")
                        message.deliveryOrders = object.deliveryOrders;
                    else if (typeof object.deliveryOrders === "object")
                        message.deliveryOrders = new $util.LongBits(object.deliveryOrders.low >>> 0, object.deliveryOrders.high >>> 0).toNumber();
                if (object.metricValuesUs != null) {
                    if (typeof object.metricValuesUs !== "object")
                        throw TypeError(".waiternow.common.MetricProto.metricValuesUs: object expected");
                    message.metricValuesUs = $root.waiternow.common.MetricProto.MetricValuesProto.fromObject(object.metricValuesUs);
                }
                if (object.metricValuesMx != null) {
                    if (typeof object.metricValuesMx !== "object")
                        throw TypeError(".waiternow.common.MetricProto.metricValuesMx: object expected");
                    message.metricValuesMx = $root.waiternow.common.MetricProto.MetricValuesProto.fromObject(object.metricValuesMx);
                }
                return message;
            };

            /**
             * Creates a plain object from a MetricProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {waiternow.common.MetricProto} message MetricProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MetricProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.period = null;
                    object.periodType = options.enums === String ? "PERIOD_TYPE_UNSPECIFIED" : 0;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.newLocations = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.newLocations = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.locationsEnrolledOnPayments = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.locationsEnrolledOnPayments = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.paidOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.paidOrders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.refundedOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.refundedOrders = options.longs === String ? "0" : 0;
                    object.orderPaymentsVolume = null;
                    object.waiternowOrderPaymentsRevenue = null;
                    object.refundedOrdersVolume = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.checkIns = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.checkIns = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.reviews = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.reviews = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.reviewsWithComments = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.reviewsWithComments = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.incompleteOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.incompleteOrders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.onlinePickupOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.onlinePickupOrders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.deliveryOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deliveryOrders = options.longs === String ? "0" : 0;
                    object.metricValuesUs = null;
                    object.metricValuesMx = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.period != null && message.hasOwnProperty("period"))
                    object.period = $root.waiternow.common.DateRangeProto.toObject(message.period, options);
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    object.periodType = options.enums === String ? $root.waiternow.common.PeriodType[message.periodType] === undefined ? message.periodType : $root.waiternow.common.PeriodType[message.periodType] : message.periodType;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.newLocations != null && message.hasOwnProperty("newLocations"))
                    if (typeof message.newLocations === "number")
                        object.newLocations = options.longs === String ? String(message.newLocations) : message.newLocations;
                    else
                        object.newLocations = options.longs === String ? $util.Long.prototype.toString.call(message.newLocations) : options.longs === Number ? new $util.LongBits(message.newLocations.low >>> 0, message.newLocations.high >>> 0).toNumber() : message.newLocations;
                if (message.locationsEnrolledOnPayments != null && message.hasOwnProperty("locationsEnrolledOnPayments"))
                    if (typeof message.locationsEnrolledOnPayments === "number")
                        object.locationsEnrolledOnPayments = options.longs === String ? String(message.locationsEnrolledOnPayments) : message.locationsEnrolledOnPayments;
                    else
                        object.locationsEnrolledOnPayments = options.longs === String ? $util.Long.prototype.toString.call(message.locationsEnrolledOnPayments) : options.longs === Number ? new $util.LongBits(message.locationsEnrolledOnPayments.low >>> 0, message.locationsEnrolledOnPayments.high >>> 0).toNumber() : message.locationsEnrolledOnPayments;
                if (message.paidOrders != null && message.hasOwnProperty("paidOrders"))
                    if (typeof message.paidOrders === "number")
                        object.paidOrders = options.longs === String ? String(message.paidOrders) : message.paidOrders;
                    else
                        object.paidOrders = options.longs === String ? $util.Long.prototype.toString.call(message.paidOrders) : options.longs === Number ? new $util.LongBits(message.paidOrders.low >>> 0, message.paidOrders.high >>> 0).toNumber() : message.paidOrders;
                if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                    if (typeof message.refundedOrders === "number")
                        object.refundedOrders = options.longs === String ? String(message.refundedOrders) : message.refundedOrders;
                    else
                        object.refundedOrders = options.longs === String ? $util.Long.prototype.toString.call(message.refundedOrders) : options.longs === Number ? new $util.LongBits(message.refundedOrders.low >>> 0, message.refundedOrders.high >>> 0).toNumber() : message.refundedOrders;
                if (message.orderPaymentsVolume != null && message.hasOwnProperty("orderPaymentsVolume"))
                    object.orderPaymentsVolume = $root.waiternow.common.MoneyProto.toObject(message.orderPaymentsVolume, options);
                if (message.waiternowOrderPaymentsRevenue != null && message.hasOwnProperty("waiternowOrderPaymentsRevenue"))
                    object.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.toObject(message.waiternowOrderPaymentsRevenue, options);
                if (message.refundedOrdersVolume != null && message.hasOwnProperty("refundedOrdersVolume"))
                    object.refundedOrdersVolume = $root.waiternow.common.MoneyProto.toObject(message.refundedOrdersVolume, options);
                if (message.checkIns != null && message.hasOwnProperty("checkIns"))
                    if (typeof message.checkIns === "number")
                        object.checkIns = options.longs === String ? String(message.checkIns) : message.checkIns;
                    else
                        object.checkIns = options.longs === String ? $util.Long.prototype.toString.call(message.checkIns) : options.longs === Number ? new $util.LongBits(message.checkIns.low >>> 0, message.checkIns.high >>> 0).toNumber() : message.checkIns;
                if (message.orders != null && message.hasOwnProperty("orders"))
                    if (typeof message.orders === "number")
                        object.orders = options.longs === String ? String(message.orders) : message.orders;
                    else
                        object.orders = options.longs === String ? $util.Long.prototype.toString.call(message.orders) : options.longs === Number ? new $util.LongBits(message.orders.low >>> 0, message.orders.high >>> 0).toNumber() : message.orders;
                if (message.reviews != null && message.hasOwnProperty("reviews"))
                    if (typeof message.reviews === "number")
                        object.reviews = options.longs === String ? String(message.reviews) : message.reviews;
                    else
                        object.reviews = options.longs === String ? $util.Long.prototype.toString.call(message.reviews) : options.longs === Number ? new $util.LongBits(message.reviews.low >>> 0, message.reviews.high >>> 0).toNumber() : message.reviews;
                if (message.reviewsWithComments != null && message.hasOwnProperty("reviewsWithComments"))
                    if (typeof message.reviewsWithComments === "number")
                        object.reviewsWithComments = options.longs === String ? String(message.reviewsWithComments) : message.reviewsWithComments;
                    else
                        object.reviewsWithComments = options.longs === String ? $util.Long.prototype.toString.call(message.reviewsWithComments) : options.longs === Number ? new $util.LongBits(message.reviewsWithComments.low >>> 0, message.reviewsWithComments.high >>> 0).toNumber() : message.reviewsWithComments;
                if (message.incompleteOrders != null && message.hasOwnProperty("incompleteOrders"))
                    if (typeof message.incompleteOrders === "number")
                        object.incompleteOrders = options.longs === String ? String(message.incompleteOrders) : message.incompleteOrders;
                    else
                        object.incompleteOrders = options.longs === String ? $util.Long.prototype.toString.call(message.incompleteOrders) : options.longs === Number ? new $util.LongBits(message.incompleteOrders.low >>> 0, message.incompleteOrders.high >>> 0).toNumber() : message.incompleteOrders;
                if (message.onlinePickupOrders != null && message.hasOwnProperty("onlinePickupOrders"))
                    if (typeof message.onlinePickupOrders === "number")
                        object.onlinePickupOrders = options.longs === String ? String(message.onlinePickupOrders) : message.onlinePickupOrders;
                    else
                        object.onlinePickupOrders = options.longs === String ? $util.Long.prototype.toString.call(message.onlinePickupOrders) : options.longs === Number ? new $util.LongBits(message.onlinePickupOrders.low >>> 0, message.onlinePickupOrders.high >>> 0).toNumber() : message.onlinePickupOrders;
                if (message.deliveryOrders != null && message.hasOwnProperty("deliveryOrders"))
                    if (typeof message.deliveryOrders === "number")
                        object.deliveryOrders = options.longs === String ? String(message.deliveryOrders) : message.deliveryOrders;
                    else
                        object.deliveryOrders = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryOrders) : options.longs === Number ? new $util.LongBits(message.deliveryOrders.low >>> 0, message.deliveryOrders.high >>> 0).toNumber() : message.deliveryOrders;
                if (message.metricValuesUs != null && message.hasOwnProperty("metricValuesUs"))
                    object.metricValuesUs = $root.waiternow.common.MetricProto.MetricValuesProto.toObject(message.metricValuesUs, options);
                if (message.metricValuesMx != null && message.hasOwnProperty("metricValuesMx"))
                    object.metricValuesMx = $root.waiternow.common.MetricProto.MetricValuesProto.toObject(message.metricValuesMx, options);
                return object;
            };

            /**
             * Converts this MetricProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MetricProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MetricProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MetricProto
             * @function getTypeUrl
             * @memberof waiternow.common.MetricProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MetricProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MetricProto";
            };

            MetricProto.MetricValuesProto = (function() {

                /**
                 * Properties of a MetricValuesProto.
                 * @memberof waiternow.common.MetricProto
                 * @interface IMetricValuesProto
                 * @property {number|null} [newLocations] MetricValuesProto newLocations
                 * @property {number|null} [locationsEnrolledOnPayments] MetricValuesProto locationsEnrolledOnPayments
                 * @property {number|null} [paidOrders] MetricValuesProto paidOrders
                 * @property {number|null} [refundedOrders] MetricValuesProto refundedOrders
                 * @property {waiternow.common.IMoneyProto|null} [orderPaymentsVolume] MetricValuesProto orderPaymentsVolume
                 * @property {waiternow.common.IMoneyProto|null} [waiternowOrderPaymentsRevenue] MetricValuesProto waiternowOrderPaymentsRevenue
                 * @property {waiternow.common.IMoneyProto|null} [refundedOrdersVolume] MetricValuesProto refundedOrdersVolume
                 * @property {number|null} [checkIns] MetricValuesProto checkIns
                 * @property {number|null} [orders] MetricValuesProto orders
                 * @property {number|null} [reviews] MetricValuesProto reviews
                 * @property {number|null} [reviewsWithComments] MetricValuesProto reviewsWithComments
                 * @property {number|null} [incompleteOrders] MetricValuesProto incompleteOrders
                 * @property {number|null} [onlinePickupOrders] MetricValuesProto onlinePickupOrders
                 * @property {number|null} [deliveryOrders] MetricValuesProto deliveryOrders
                 */

                /**
                 * Constructs a new MetricValuesProto.
                 * @memberof waiternow.common.MetricProto
                 * @classdesc Represents a MetricValuesProto.
                 * @implements IMetricValuesProto
                 * @constructor
                 * @param {waiternow.common.MetricProto.IMetricValuesProto=} [properties] Properties to set
                 */
                function MetricValuesProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MetricValuesProto newLocations.
                 * @member {number} newLocations
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.newLocations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto locationsEnrolledOnPayments.
                 * @member {number} locationsEnrolledOnPayments
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.locationsEnrolledOnPayments = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto paidOrders.
                 * @member {number} paidOrders
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.paidOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto refundedOrders.
                 * @member {number} refundedOrders
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.refundedOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto orderPaymentsVolume.
                 * @member {waiternow.common.IMoneyProto|null|undefined} orderPaymentsVolume
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.orderPaymentsVolume = null;

                /**
                 * MetricValuesProto waiternowOrderPaymentsRevenue.
                 * @member {waiternow.common.IMoneyProto|null|undefined} waiternowOrderPaymentsRevenue
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.waiternowOrderPaymentsRevenue = null;

                /**
                 * MetricValuesProto refundedOrdersVolume.
                 * @member {waiternow.common.IMoneyProto|null|undefined} refundedOrdersVolume
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.refundedOrdersVolume = null;

                /**
                 * MetricValuesProto checkIns.
                 * @member {number} checkIns
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.checkIns = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto orders.
                 * @member {number} orders
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.orders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto reviews.
                 * @member {number} reviews
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.reviews = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto reviewsWithComments.
                 * @member {number} reviewsWithComments
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.reviewsWithComments = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto incompleteOrders.
                 * @member {number} incompleteOrders
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.incompleteOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto onlinePickupOrders.
                 * @member {number} onlinePickupOrders
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.onlinePickupOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * MetricValuesProto deliveryOrders.
                 * @member {number} deliveryOrders
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 */
                MetricValuesProto.prototype.deliveryOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new MetricValuesProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {waiternow.common.MetricProto.IMetricValuesProto=} [properties] Properties to set
                 * @returns {waiternow.common.MetricProto.MetricValuesProto} MetricValuesProto instance
                 */
                MetricValuesProto.create = function create(properties) {
                    return new MetricValuesProto(properties);
                };

                /**
                 * Encodes the specified MetricValuesProto message. Does not implicitly {@link waiternow.common.MetricProto.MetricValuesProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {waiternow.common.MetricProto.IMetricValuesProto} message MetricValuesProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetricValuesProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.newLocations != null && Object.hasOwnProperty.call(message, "newLocations"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.newLocations);
                    if (message.locationsEnrolledOnPayments != null && Object.hasOwnProperty.call(message, "locationsEnrolledOnPayments"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int64(message.locationsEnrolledOnPayments);
                    if (message.paidOrders != null && Object.hasOwnProperty.call(message, "paidOrders"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int64(message.paidOrders);
                    if (message.refundedOrders != null && Object.hasOwnProperty.call(message, "refundedOrders"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.refundedOrders);
                    if (message.orderPaymentsVolume != null && Object.hasOwnProperty.call(message, "orderPaymentsVolume"))
                        $root.waiternow.common.MoneyProto.encode(message.orderPaymentsVolume, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.waiternowOrderPaymentsRevenue != null && Object.hasOwnProperty.call(message, "waiternowOrderPaymentsRevenue"))
                        $root.waiternow.common.MoneyProto.encode(message.waiternowOrderPaymentsRevenue, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.refundedOrdersVolume != null && Object.hasOwnProperty.call(message, "refundedOrdersVolume"))
                        $root.waiternow.common.MoneyProto.encode(message.refundedOrdersVolume, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    if (message.checkIns != null && Object.hasOwnProperty.call(message, "checkIns"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int64(message.checkIns);
                    if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                        writer.uint32(/* id 9, wireType 0 =*/72).int64(message.orders);
                    if (message.reviews != null && Object.hasOwnProperty.call(message, "reviews"))
                        writer.uint32(/* id 10, wireType 0 =*/80).int64(message.reviews);
                    if (message.reviewsWithComments != null && Object.hasOwnProperty.call(message, "reviewsWithComments"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int64(message.reviewsWithComments);
                    if (message.incompleteOrders != null && Object.hasOwnProperty.call(message, "incompleteOrders"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int64(message.incompleteOrders);
                    if (message.onlinePickupOrders != null && Object.hasOwnProperty.call(message, "onlinePickupOrders"))
                        writer.uint32(/* id 13, wireType 0 =*/104).int64(message.onlinePickupOrders);
                    if (message.deliveryOrders != null && Object.hasOwnProperty.call(message, "deliveryOrders"))
                        writer.uint32(/* id 14, wireType 0 =*/112).int64(message.deliveryOrders);
                    return writer;
                };

                /**
                 * Encodes the specified MetricValuesProto message, length delimited. Does not implicitly {@link waiternow.common.MetricProto.MetricValuesProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {waiternow.common.MetricProto.IMetricValuesProto} message MetricValuesProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MetricValuesProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MetricValuesProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MetricProto.MetricValuesProto} MetricValuesProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetricValuesProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MetricProto.MetricValuesProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.newLocations = reader.int64();
                                break;
                            }
                        case 2: {
                                message.locationsEnrolledOnPayments = reader.int64();
                                break;
                            }
                        case 3: {
                                message.paidOrders = reader.int64();
                                break;
                            }
                        case 4: {
                                message.refundedOrders = reader.int64();
                                break;
                            }
                        case 5: {
                                message.orderPaymentsVolume = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.refundedOrdersVolume = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 8: {
                                message.checkIns = reader.int64();
                                break;
                            }
                        case 9: {
                                message.orders = reader.int64();
                                break;
                            }
                        case 10: {
                                message.reviews = reader.int64();
                                break;
                            }
                        case 11: {
                                message.reviewsWithComments = reader.int64();
                                break;
                            }
                        case 12: {
                                message.incompleteOrders = reader.int64();
                                break;
                            }
                        case 13: {
                                message.onlinePickupOrders = reader.int64();
                                break;
                            }
                        case 14: {
                                message.deliveryOrders = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MetricValuesProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MetricProto.MetricValuesProto} MetricValuesProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MetricValuesProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MetricValuesProto message.
                 * @function verify
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MetricValuesProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.newLocations != null && message.hasOwnProperty("newLocations"))
                        if (!$util.isInteger(message.newLocations) && !(message.newLocations && $util.isInteger(message.newLocations.low) && $util.isInteger(message.newLocations.high)))
                            return "newLocations: integer|Long expected";
                    if (message.locationsEnrolledOnPayments != null && message.hasOwnProperty("locationsEnrolledOnPayments"))
                        if (!$util.isInteger(message.locationsEnrolledOnPayments) && !(message.locationsEnrolledOnPayments && $util.isInteger(message.locationsEnrolledOnPayments.low) && $util.isInteger(message.locationsEnrolledOnPayments.high)))
                            return "locationsEnrolledOnPayments: integer|Long expected";
                    if (message.paidOrders != null && message.hasOwnProperty("paidOrders"))
                        if (!$util.isInteger(message.paidOrders) && !(message.paidOrders && $util.isInteger(message.paidOrders.low) && $util.isInteger(message.paidOrders.high)))
                            return "paidOrders: integer|Long expected";
                    if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                        if (!$util.isInteger(message.refundedOrders) && !(message.refundedOrders && $util.isInteger(message.refundedOrders.low) && $util.isInteger(message.refundedOrders.high)))
                            return "refundedOrders: integer|Long expected";
                    if (message.orderPaymentsVolume != null && message.hasOwnProperty("orderPaymentsVolume")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.orderPaymentsVolume);
                        if (error)
                            return "orderPaymentsVolume." + error;
                    }
                    if (message.waiternowOrderPaymentsRevenue != null && message.hasOwnProperty("waiternowOrderPaymentsRevenue")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.waiternowOrderPaymentsRevenue);
                        if (error)
                            return "waiternowOrderPaymentsRevenue." + error;
                    }
                    if (message.refundedOrdersVolume != null && message.hasOwnProperty("refundedOrdersVolume")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.refundedOrdersVolume);
                        if (error)
                            return "refundedOrdersVolume." + error;
                    }
                    if (message.checkIns != null && message.hasOwnProperty("checkIns"))
                        if (!$util.isInteger(message.checkIns) && !(message.checkIns && $util.isInteger(message.checkIns.low) && $util.isInteger(message.checkIns.high)))
                            return "checkIns: integer|Long expected";
                    if (message.orders != null && message.hasOwnProperty("orders"))
                        if (!$util.isInteger(message.orders) && !(message.orders && $util.isInteger(message.orders.low) && $util.isInteger(message.orders.high)))
                            return "orders: integer|Long expected";
                    if (message.reviews != null && message.hasOwnProperty("reviews"))
                        if (!$util.isInteger(message.reviews) && !(message.reviews && $util.isInteger(message.reviews.low) && $util.isInteger(message.reviews.high)))
                            return "reviews: integer|Long expected";
                    if (message.reviewsWithComments != null && message.hasOwnProperty("reviewsWithComments"))
                        if (!$util.isInteger(message.reviewsWithComments) && !(message.reviewsWithComments && $util.isInteger(message.reviewsWithComments.low) && $util.isInteger(message.reviewsWithComments.high)))
                            return "reviewsWithComments: integer|Long expected";
                    if (message.incompleteOrders != null && message.hasOwnProperty("incompleteOrders"))
                        if (!$util.isInteger(message.incompleteOrders) && !(message.incompleteOrders && $util.isInteger(message.incompleteOrders.low) && $util.isInteger(message.incompleteOrders.high)))
                            return "incompleteOrders: integer|Long expected";
                    if (message.onlinePickupOrders != null && message.hasOwnProperty("onlinePickupOrders"))
                        if (!$util.isInteger(message.onlinePickupOrders) && !(message.onlinePickupOrders && $util.isInteger(message.onlinePickupOrders.low) && $util.isInteger(message.onlinePickupOrders.high)))
                            return "onlinePickupOrders: integer|Long expected";
                    if (message.deliveryOrders != null && message.hasOwnProperty("deliveryOrders"))
                        if (!$util.isInteger(message.deliveryOrders) && !(message.deliveryOrders && $util.isInteger(message.deliveryOrders.low) && $util.isInteger(message.deliveryOrders.high)))
                            return "deliveryOrders: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a MetricValuesProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MetricProto.MetricValuesProto} MetricValuesProto
                 */
                MetricValuesProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MetricProto.MetricValuesProto)
                        return object;
                    var message = new $root.waiternow.common.MetricProto.MetricValuesProto();
                    if (object.newLocations != null)
                        if ($util.Long)
                            (message.newLocations = $util.Long.fromValue(object.newLocations)).unsigned = false;
                        else if (typeof object.newLocations === "string")
                            message.newLocations = parseInt(object.newLocations, 10);
                        else if (typeof object.newLocations === "number")
                            message.newLocations = object.newLocations;
                        else if (typeof object.newLocations === "object")
                            message.newLocations = new $util.LongBits(object.newLocations.low >>> 0, object.newLocations.high >>> 0).toNumber();
                    if (object.locationsEnrolledOnPayments != null)
                        if ($util.Long)
                            (message.locationsEnrolledOnPayments = $util.Long.fromValue(object.locationsEnrolledOnPayments)).unsigned = false;
                        else if (typeof object.locationsEnrolledOnPayments === "string")
                            message.locationsEnrolledOnPayments = parseInt(object.locationsEnrolledOnPayments, 10);
                        else if (typeof object.locationsEnrolledOnPayments === "number")
                            message.locationsEnrolledOnPayments = object.locationsEnrolledOnPayments;
                        else if (typeof object.locationsEnrolledOnPayments === "object")
                            message.locationsEnrolledOnPayments = new $util.LongBits(object.locationsEnrolledOnPayments.low >>> 0, object.locationsEnrolledOnPayments.high >>> 0).toNumber();
                    if (object.paidOrders != null)
                        if ($util.Long)
                            (message.paidOrders = $util.Long.fromValue(object.paidOrders)).unsigned = false;
                        else if (typeof object.paidOrders === "string")
                            message.paidOrders = parseInt(object.paidOrders, 10);
                        else if (typeof object.paidOrders === "number")
                            message.paidOrders = object.paidOrders;
                        else if (typeof object.paidOrders === "object")
                            message.paidOrders = new $util.LongBits(object.paidOrders.low >>> 0, object.paidOrders.high >>> 0).toNumber();
                    if (object.refundedOrders != null)
                        if ($util.Long)
                            (message.refundedOrders = $util.Long.fromValue(object.refundedOrders)).unsigned = false;
                        else if (typeof object.refundedOrders === "string")
                            message.refundedOrders = parseInt(object.refundedOrders, 10);
                        else if (typeof object.refundedOrders === "number")
                            message.refundedOrders = object.refundedOrders;
                        else if (typeof object.refundedOrders === "object")
                            message.refundedOrders = new $util.LongBits(object.refundedOrders.low >>> 0, object.refundedOrders.high >>> 0).toNumber();
                    if (object.orderPaymentsVolume != null) {
                        if (typeof object.orderPaymentsVolume !== "object")
                            throw TypeError(".waiternow.common.MetricProto.MetricValuesProto.orderPaymentsVolume: object expected");
                        message.orderPaymentsVolume = $root.waiternow.common.MoneyProto.fromObject(object.orderPaymentsVolume);
                    }
                    if (object.waiternowOrderPaymentsRevenue != null) {
                        if (typeof object.waiternowOrderPaymentsRevenue !== "object")
                            throw TypeError(".waiternow.common.MetricProto.MetricValuesProto.waiternowOrderPaymentsRevenue: object expected");
                        message.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.fromObject(object.waiternowOrderPaymentsRevenue);
                    }
                    if (object.refundedOrdersVolume != null) {
                        if (typeof object.refundedOrdersVolume !== "object")
                            throw TypeError(".waiternow.common.MetricProto.MetricValuesProto.refundedOrdersVolume: object expected");
                        message.refundedOrdersVolume = $root.waiternow.common.MoneyProto.fromObject(object.refundedOrdersVolume);
                    }
                    if (object.checkIns != null)
                        if ($util.Long)
                            (message.checkIns = $util.Long.fromValue(object.checkIns)).unsigned = false;
                        else if (typeof object.checkIns === "string")
                            message.checkIns = parseInt(object.checkIns, 10);
                        else if (typeof object.checkIns === "number")
                            message.checkIns = object.checkIns;
                        else if (typeof object.checkIns === "object")
                            message.checkIns = new $util.LongBits(object.checkIns.low >>> 0, object.checkIns.high >>> 0).toNumber();
                    if (object.orders != null)
                        if ($util.Long)
                            (message.orders = $util.Long.fromValue(object.orders)).unsigned = false;
                        else if (typeof object.orders === "string")
                            message.orders = parseInt(object.orders, 10);
                        else if (typeof object.orders === "number")
                            message.orders = object.orders;
                        else if (typeof object.orders === "object")
                            message.orders = new $util.LongBits(object.orders.low >>> 0, object.orders.high >>> 0).toNumber();
                    if (object.reviews != null)
                        if ($util.Long)
                            (message.reviews = $util.Long.fromValue(object.reviews)).unsigned = false;
                        else if (typeof object.reviews === "string")
                            message.reviews = parseInt(object.reviews, 10);
                        else if (typeof object.reviews === "number")
                            message.reviews = object.reviews;
                        else if (typeof object.reviews === "object")
                            message.reviews = new $util.LongBits(object.reviews.low >>> 0, object.reviews.high >>> 0).toNumber();
                    if (object.reviewsWithComments != null)
                        if ($util.Long)
                            (message.reviewsWithComments = $util.Long.fromValue(object.reviewsWithComments)).unsigned = false;
                        else if (typeof object.reviewsWithComments === "string")
                            message.reviewsWithComments = parseInt(object.reviewsWithComments, 10);
                        else if (typeof object.reviewsWithComments === "number")
                            message.reviewsWithComments = object.reviewsWithComments;
                        else if (typeof object.reviewsWithComments === "object")
                            message.reviewsWithComments = new $util.LongBits(object.reviewsWithComments.low >>> 0, object.reviewsWithComments.high >>> 0).toNumber();
                    if (object.incompleteOrders != null)
                        if ($util.Long)
                            (message.incompleteOrders = $util.Long.fromValue(object.incompleteOrders)).unsigned = false;
                        else if (typeof object.incompleteOrders === "string")
                            message.incompleteOrders = parseInt(object.incompleteOrders, 10);
                        else if (typeof object.incompleteOrders === "number")
                            message.incompleteOrders = object.incompleteOrders;
                        else if (typeof object.incompleteOrders === "object")
                            message.incompleteOrders = new $util.LongBits(object.incompleteOrders.low >>> 0, object.incompleteOrders.high >>> 0).toNumber();
                    if (object.onlinePickupOrders != null)
                        if ($util.Long)
                            (message.onlinePickupOrders = $util.Long.fromValue(object.onlinePickupOrders)).unsigned = false;
                        else if (typeof object.onlinePickupOrders === "string")
                            message.onlinePickupOrders = parseInt(object.onlinePickupOrders, 10);
                        else if (typeof object.onlinePickupOrders === "number")
                            message.onlinePickupOrders = object.onlinePickupOrders;
                        else if (typeof object.onlinePickupOrders === "object")
                            message.onlinePickupOrders = new $util.LongBits(object.onlinePickupOrders.low >>> 0, object.onlinePickupOrders.high >>> 0).toNumber();
                    if (object.deliveryOrders != null)
                        if ($util.Long)
                            (message.deliveryOrders = $util.Long.fromValue(object.deliveryOrders)).unsigned = false;
                        else if (typeof object.deliveryOrders === "string")
                            message.deliveryOrders = parseInt(object.deliveryOrders, 10);
                        else if (typeof object.deliveryOrders === "number")
                            message.deliveryOrders = object.deliveryOrders;
                        else if (typeof object.deliveryOrders === "object")
                            message.deliveryOrders = new $util.LongBits(object.deliveryOrders.low >>> 0, object.deliveryOrders.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a MetricValuesProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {waiternow.common.MetricProto.MetricValuesProto} message MetricValuesProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MetricValuesProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.newLocations = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.newLocations = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.locationsEnrolledOnPayments = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.locationsEnrolledOnPayments = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.paidOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.paidOrders = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.refundedOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.refundedOrders = options.longs === String ? "0" : 0;
                        object.orderPaymentsVolume = null;
                        object.waiternowOrderPaymentsRevenue = null;
                        object.refundedOrdersVolume = null;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.checkIns = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.checkIns = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.orders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.orders = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviews = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviews = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.reviewsWithComments = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.reviewsWithComments = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.incompleteOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.incompleteOrders = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.onlinePickupOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.onlinePickupOrders = options.longs === String ? "0" : 0;
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.deliveryOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.deliveryOrders = options.longs === String ? "0" : 0;
                    }
                    if (message.newLocations != null && message.hasOwnProperty("newLocations"))
                        if (typeof message.newLocations === "number")
                            object.newLocations = options.longs === String ? String(message.newLocations) : message.newLocations;
                        else
                            object.newLocations = options.longs === String ? $util.Long.prototype.toString.call(message.newLocations) : options.longs === Number ? new $util.LongBits(message.newLocations.low >>> 0, message.newLocations.high >>> 0).toNumber() : message.newLocations;
                    if (message.locationsEnrolledOnPayments != null && message.hasOwnProperty("locationsEnrolledOnPayments"))
                        if (typeof message.locationsEnrolledOnPayments === "number")
                            object.locationsEnrolledOnPayments = options.longs === String ? String(message.locationsEnrolledOnPayments) : message.locationsEnrolledOnPayments;
                        else
                            object.locationsEnrolledOnPayments = options.longs === String ? $util.Long.prototype.toString.call(message.locationsEnrolledOnPayments) : options.longs === Number ? new $util.LongBits(message.locationsEnrolledOnPayments.low >>> 0, message.locationsEnrolledOnPayments.high >>> 0).toNumber() : message.locationsEnrolledOnPayments;
                    if (message.paidOrders != null && message.hasOwnProperty("paidOrders"))
                        if (typeof message.paidOrders === "number")
                            object.paidOrders = options.longs === String ? String(message.paidOrders) : message.paidOrders;
                        else
                            object.paidOrders = options.longs === String ? $util.Long.prototype.toString.call(message.paidOrders) : options.longs === Number ? new $util.LongBits(message.paidOrders.low >>> 0, message.paidOrders.high >>> 0).toNumber() : message.paidOrders;
                    if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                        if (typeof message.refundedOrders === "number")
                            object.refundedOrders = options.longs === String ? String(message.refundedOrders) : message.refundedOrders;
                        else
                            object.refundedOrders = options.longs === String ? $util.Long.prototype.toString.call(message.refundedOrders) : options.longs === Number ? new $util.LongBits(message.refundedOrders.low >>> 0, message.refundedOrders.high >>> 0).toNumber() : message.refundedOrders;
                    if (message.orderPaymentsVolume != null && message.hasOwnProperty("orderPaymentsVolume"))
                        object.orderPaymentsVolume = $root.waiternow.common.MoneyProto.toObject(message.orderPaymentsVolume, options);
                    if (message.waiternowOrderPaymentsRevenue != null && message.hasOwnProperty("waiternowOrderPaymentsRevenue"))
                        object.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.toObject(message.waiternowOrderPaymentsRevenue, options);
                    if (message.refundedOrdersVolume != null && message.hasOwnProperty("refundedOrdersVolume"))
                        object.refundedOrdersVolume = $root.waiternow.common.MoneyProto.toObject(message.refundedOrdersVolume, options);
                    if (message.checkIns != null && message.hasOwnProperty("checkIns"))
                        if (typeof message.checkIns === "number")
                            object.checkIns = options.longs === String ? String(message.checkIns) : message.checkIns;
                        else
                            object.checkIns = options.longs === String ? $util.Long.prototype.toString.call(message.checkIns) : options.longs === Number ? new $util.LongBits(message.checkIns.low >>> 0, message.checkIns.high >>> 0).toNumber() : message.checkIns;
                    if (message.orders != null && message.hasOwnProperty("orders"))
                        if (typeof message.orders === "number")
                            object.orders = options.longs === String ? String(message.orders) : message.orders;
                        else
                            object.orders = options.longs === String ? $util.Long.prototype.toString.call(message.orders) : options.longs === Number ? new $util.LongBits(message.orders.low >>> 0, message.orders.high >>> 0).toNumber() : message.orders;
                    if (message.reviews != null && message.hasOwnProperty("reviews"))
                        if (typeof message.reviews === "number")
                            object.reviews = options.longs === String ? String(message.reviews) : message.reviews;
                        else
                            object.reviews = options.longs === String ? $util.Long.prototype.toString.call(message.reviews) : options.longs === Number ? new $util.LongBits(message.reviews.low >>> 0, message.reviews.high >>> 0).toNumber() : message.reviews;
                    if (message.reviewsWithComments != null && message.hasOwnProperty("reviewsWithComments"))
                        if (typeof message.reviewsWithComments === "number")
                            object.reviewsWithComments = options.longs === String ? String(message.reviewsWithComments) : message.reviewsWithComments;
                        else
                            object.reviewsWithComments = options.longs === String ? $util.Long.prototype.toString.call(message.reviewsWithComments) : options.longs === Number ? new $util.LongBits(message.reviewsWithComments.low >>> 0, message.reviewsWithComments.high >>> 0).toNumber() : message.reviewsWithComments;
                    if (message.incompleteOrders != null && message.hasOwnProperty("incompleteOrders"))
                        if (typeof message.incompleteOrders === "number")
                            object.incompleteOrders = options.longs === String ? String(message.incompleteOrders) : message.incompleteOrders;
                        else
                            object.incompleteOrders = options.longs === String ? $util.Long.prototype.toString.call(message.incompleteOrders) : options.longs === Number ? new $util.LongBits(message.incompleteOrders.low >>> 0, message.incompleteOrders.high >>> 0).toNumber() : message.incompleteOrders;
                    if (message.onlinePickupOrders != null && message.hasOwnProperty("onlinePickupOrders"))
                        if (typeof message.onlinePickupOrders === "number")
                            object.onlinePickupOrders = options.longs === String ? String(message.onlinePickupOrders) : message.onlinePickupOrders;
                        else
                            object.onlinePickupOrders = options.longs === String ? $util.Long.prototype.toString.call(message.onlinePickupOrders) : options.longs === Number ? new $util.LongBits(message.onlinePickupOrders.low >>> 0, message.onlinePickupOrders.high >>> 0).toNumber() : message.onlinePickupOrders;
                    if (message.deliveryOrders != null && message.hasOwnProperty("deliveryOrders"))
                        if (typeof message.deliveryOrders === "number")
                            object.deliveryOrders = options.longs === String ? String(message.deliveryOrders) : message.deliveryOrders;
                        else
                            object.deliveryOrders = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryOrders) : options.longs === Number ? new $util.LongBits(message.deliveryOrders.low >>> 0, message.deliveryOrders.high >>> 0).toNumber() : message.deliveryOrders;
                    return object;
                };

                /**
                 * Converts this MetricValuesProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MetricValuesProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MetricValuesProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.MetricProto.MetricValuesProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MetricValuesProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MetricProto.MetricValuesProto";
                };

                return MetricValuesProto;
            })();

            return MetricProto;
        })();

        common.MetricsProto = (function() {

            /**
             * Properties of a MetricsProto.
             * @memberof waiternow.common
             * @interface IMetricsProto
             * @property {Array.<waiternow.common.IMetricProto>|null} [metrics] MetricsProto metrics
             * @property {string|null} [continuationToken] MetricsProto continuationToken
             */

            /**
             * Constructs a new MetricsProto.
             * @memberof waiternow.common
             * @classdesc Represents a MetricsProto.
             * @implements IMetricsProto
             * @constructor
             * @param {waiternow.common.IMetricsProto=} [properties] Properties to set
             */
            function MetricsProto(properties) {
                this.metrics = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * MetricsProto metrics.
             * @member {Array.<waiternow.common.IMetricProto>} metrics
             * @memberof waiternow.common.MetricsProto
             * @instance
             */
            MetricsProto.prototype.metrics = $util.emptyArray;

            /**
             * MetricsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.MetricsProto
             * @instance
             */
            MetricsProto.prototype.continuationToken = "";

            /**
             * Creates a new MetricsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {waiternow.common.IMetricsProto=} [properties] Properties to set
             * @returns {waiternow.common.MetricsProto} MetricsProto instance
             */
            MetricsProto.create = function create(properties) {
                return new MetricsProto(properties);
            };

            /**
             * Encodes the specified MetricsProto message. Does not implicitly {@link waiternow.common.MetricsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {waiternow.common.IMetricsProto} message MetricsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MetricsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.metrics != null && message.metrics.length)
                    for (var i = 0; i < message.metrics.length; ++i)
                        $root.waiternow.common.MetricProto.encode(message.metrics[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified MetricsProto message, length delimited. Does not implicitly {@link waiternow.common.MetricsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {waiternow.common.IMetricsProto} message MetricsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MetricsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MetricsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MetricsProto} MetricsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MetricsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MetricsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.metrics && message.metrics.length))
                                message.metrics = [];
                            message.metrics.push($root.waiternow.common.MetricProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MetricsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MetricsProto} MetricsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MetricsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MetricsProto message.
             * @function verify
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MetricsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.metrics != null && message.hasOwnProperty("metrics")) {
                    if (!Array.isArray(message.metrics))
                        return "metrics: array expected";
                    for (var i = 0; i < message.metrics.length; ++i) {
                        var error = $root.waiternow.common.MetricProto.verify(message.metrics[i]);
                        if (error)
                            return "metrics." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a MetricsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MetricsProto} MetricsProto
             */
            MetricsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MetricsProto)
                    return object;
                var message = new $root.waiternow.common.MetricsProto();
                if (object.metrics) {
                    if (!Array.isArray(object.metrics))
                        throw TypeError(".waiternow.common.MetricsProto.metrics: array expected");
                    message.metrics = [];
                    for (var i = 0; i < object.metrics.length; ++i) {
                        if (typeof object.metrics[i] !== "object")
                            throw TypeError(".waiternow.common.MetricsProto.metrics: object expected");
                        message.metrics[i] = $root.waiternow.common.MetricProto.fromObject(object.metrics[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a MetricsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {waiternow.common.MetricsProto} message MetricsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MetricsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.metrics = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.metrics && message.metrics.length) {
                    object.metrics = [];
                    for (var j = 0; j < message.metrics.length; ++j)
                        object.metrics[j] = $root.waiternow.common.MetricProto.toObject(message.metrics[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this MetricsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MetricsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MetricsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MetricsProto
             * @function getTypeUrl
             * @memberof waiternow.common.MetricsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MetricsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MetricsProto";
            };

            return MetricsProto;
        })();

        common.LocationMetricProto = (function() {

            /**
             * Properties of a LocationMetricProto.
             * @memberof waiternow.common
             * @interface ILocationMetricProto
             * @property {string|null} [id] LocationMetricProto id
             * @property {waiternow.common.IDateRangeProto|null} [period] LocationMetricProto period
             * @property {waiternow.common.PeriodType|null} [periodType] LocationMetricProto periodType
             * @property {google.protobuf.ITimestamp|null} [creationTime] LocationMetricProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] LocationMetricProto lastUpdateTime
             * @property {string|null} [locationId] LocationMetricProto locationId
             * @property {number|null} [checkIns] LocationMetricProto checkIns
             * @property {number|null} [orders] LocationMetricProto orders
             * @property {number|null} [paidOrders] LocationMetricProto paidOrders
             * @property {number|null} [refundedOrders] LocationMetricProto refundedOrders
             * @property {waiternow.common.IMoneyProto|null} [orderPaymentsVolume] LocationMetricProto orderPaymentsVolume
             * @property {waiternow.common.IMoneyProto|null} [waiternowOrderPaymentsRevenue] LocationMetricProto waiternowOrderPaymentsRevenue
             * @property {waiternow.common.IMoneyProto|null} [tips] LocationMetricProto tips
             * @property {waiternow.common.IMoneyProto|null} [refundedOrdersVolume] LocationMetricProto refundedOrdersVolume
             * @property {number|null} [thumbsUp] LocationMetricProto thumbsUp
             * @property {number|null} [thumbsDown] LocationMetricProto thumbsDown
             * @property {number|null} [reviewsWithComments] LocationMetricProto reviewsWithComments
             * @property {number|null} [incompleteOrders] LocationMetricProto incompleteOrders
             * @property {number|null} [onlinePickupOrders] LocationMetricProto onlinePickupOrders
             * @property {number|null} [deliveryOrders] LocationMetricProto deliveryOrders
             */

            /**
             * Constructs a new LocationMetricProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationMetricProto.
             * @implements ILocationMetricProto
             * @constructor
             * @param {waiternow.common.ILocationMetricProto=} [properties] Properties to set
             */
            function LocationMetricProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationMetricProto id.
             * @member {string} id
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.id = "";

            /**
             * LocationMetricProto period.
             * @member {waiternow.common.IDateRangeProto|null|undefined} period
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.period = null;

            /**
             * LocationMetricProto periodType.
             * @member {waiternow.common.PeriodType} periodType
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.periodType = 0;

            /**
             * LocationMetricProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.creationTime = null;

            /**
             * LocationMetricProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.lastUpdateTime = null;

            /**
             * LocationMetricProto locationId.
             * @member {string} locationId
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.locationId = "";

            /**
             * LocationMetricProto checkIns.
             * @member {number} checkIns
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.checkIns = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto orders.
             * @member {number} orders
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.orders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto paidOrders.
             * @member {number} paidOrders
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.paidOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto refundedOrders.
             * @member {number} refundedOrders
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.refundedOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto orderPaymentsVolume.
             * @member {waiternow.common.IMoneyProto|null|undefined} orderPaymentsVolume
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.orderPaymentsVolume = null;

            /**
             * LocationMetricProto waiternowOrderPaymentsRevenue.
             * @member {waiternow.common.IMoneyProto|null|undefined} waiternowOrderPaymentsRevenue
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.waiternowOrderPaymentsRevenue = null;

            /**
             * LocationMetricProto tips.
             * @member {waiternow.common.IMoneyProto|null|undefined} tips
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.tips = null;

            /**
             * LocationMetricProto refundedOrdersVolume.
             * @member {waiternow.common.IMoneyProto|null|undefined} refundedOrdersVolume
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.refundedOrdersVolume = null;

            /**
             * LocationMetricProto thumbsUp.
             * @member {number} thumbsUp
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.thumbsUp = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto thumbsDown.
             * @member {number} thumbsDown
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.thumbsDown = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto reviewsWithComments.
             * @member {number} reviewsWithComments
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.reviewsWithComments = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto incompleteOrders.
             * @member {number} incompleteOrders
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.incompleteOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto onlinePickupOrders.
             * @member {number} onlinePickupOrders
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.onlinePickupOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * LocationMetricProto deliveryOrders.
             * @member {number} deliveryOrders
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             */
            LocationMetricProto.prototype.deliveryOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Creates a new LocationMetricProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {waiternow.common.ILocationMetricProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationMetricProto} LocationMetricProto instance
             */
            LocationMetricProto.create = function create(properties) {
                return new LocationMetricProto(properties);
            };

            /**
             * Encodes the specified LocationMetricProto message. Does not implicitly {@link waiternow.common.LocationMetricProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {waiternow.common.ILocationMetricProto} message LocationMetricProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationMetricProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                    $root.waiternow.common.DateRangeProto.encode(message.period, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                    writer.uint32(/* id 3, wireType 0 =*/24).int32(message.periodType);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.locationId);
                if (message.checkIns != null && Object.hasOwnProperty.call(message, "checkIns"))
                    writer.uint32(/* id 7, wireType 0 =*/56).int64(message.checkIns);
                if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                    writer.uint32(/* id 8, wireType 0 =*/64).int64(message.orders);
                if (message.paidOrders != null && Object.hasOwnProperty.call(message, "paidOrders"))
                    writer.uint32(/* id 9, wireType 0 =*/72).int64(message.paidOrders);
                if (message.refundedOrders != null && Object.hasOwnProperty.call(message, "refundedOrders"))
                    writer.uint32(/* id 10, wireType 0 =*/80).int64(message.refundedOrders);
                if (message.orderPaymentsVolume != null && Object.hasOwnProperty.call(message, "orderPaymentsVolume"))
                    $root.waiternow.common.MoneyProto.encode(message.orderPaymentsVolume, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                if (message.waiternowOrderPaymentsRevenue != null && Object.hasOwnProperty.call(message, "waiternowOrderPaymentsRevenue"))
                    $root.waiternow.common.MoneyProto.encode(message.waiternowOrderPaymentsRevenue, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
                if (message.tips != null && Object.hasOwnProperty.call(message, "tips"))
                    $root.waiternow.common.MoneyProto.encode(message.tips, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
                if (message.refundedOrdersVolume != null && Object.hasOwnProperty.call(message, "refundedOrdersVolume"))
                    $root.waiternow.common.MoneyProto.encode(message.refundedOrdersVolume, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
                if (message.thumbsUp != null && Object.hasOwnProperty.call(message, "thumbsUp"))
                    writer.uint32(/* id 15, wireType 0 =*/120).int64(message.thumbsUp);
                if (message.thumbsDown != null && Object.hasOwnProperty.call(message, "thumbsDown"))
                    writer.uint32(/* id 16, wireType 0 =*/128).int64(message.thumbsDown);
                if (message.reviewsWithComments != null && Object.hasOwnProperty.call(message, "reviewsWithComments"))
                    writer.uint32(/* id 17, wireType 0 =*/136).int64(message.reviewsWithComments);
                if (message.incompleteOrders != null && Object.hasOwnProperty.call(message, "incompleteOrders"))
                    writer.uint32(/* id 18, wireType 0 =*/144).int64(message.incompleteOrders);
                if (message.onlinePickupOrders != null && Object.hasOwnProperty.call(message, "onlinePickupOrders"))
                    writer.uint32(/* id 19, wireType 0 =*/152).int64(message.onlinePickupOrders);
                if (message.deliveryOrders != null && Object.hasOwnProperty.call(message, "deliveryOrders"))
                    writer.uint32(/* id 20, wireType 0 =*/160).int64(message.deliveryOrders);
                return writer;
            };

            /**
             * Encodes the specified LocationMetricProto message, length delimited. Does not implicitly {@link waiternow.common.LocationMetricProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {waiternow.common.ILocationMetricProto} message LocationMetricProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationMetricProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationMetricProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationMetricProto} LocationMetricProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationMetricProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationMetricProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.period = $root.waiternow.common.DateRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.periodType = reader.int32();
                            break;
                        }
                    case 4: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.locationId = reader.string();
                            break;
                        }
                    case 7: {
                            message.checkIns = reader.int64();
                            break;
                        }
                    case 8: {
                            message.orders = reader.int64();
                            break;
                        }
                    case 9: {
                            message.paidOrders = reader.int64();
                            break;
                        }
                    case 10: {
                            message.refundedOrders = reader.int64();
                            break;
                        }
                    case 11: {
                            message.orderPaymentsVolume = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 12: {
                            message.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 13: {
                            message.tips = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 14: {
                            message.refundedOrdersVolume = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 15: {
                            message.thumbsUp = reader.int64();
                            break;
                        }
                    case 16: {
                            message.thumbsDown = reader.int64();
                            break;
                        }
                    case 17: {
                            message.reviewsWithComments = reader.int64();
                            break;
                        }
                    case 18: {
                            message.incompleteOrders = reader.int64();
                            break;
                        }
                    case 19: {
                            message.onlinePickupOrders = reader.int64();
                            break;
                        }
                    case 20: {
                            message.deliveryOrders = reader.int64();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationMetricProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationMetricProto} LocationMetricProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationMetricProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationMetricProto message.
             * @function verify
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationMetricProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.period != null && message.hasOwnProperty("period")) {
                    var error = $root.waiternow.common.DateRangeProto.verify(message.period);
                    if (error)
                        return "period." + error;
                }
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    switch (message.periodType) {
                    default:
                        return "periodType: enum value expected";
                    case 0:
                    case 1:
                    case 2:
                    case 3:
                        break;
                    }
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    if (!$util.isString(message.locationId))
                        return "locationId: string expected";
                if (message.checkIns != null && message.hasOwnProperty("checkIns"))
                    if (!$util.isInteger(message.checkIns) && !(message.checkIns && $util.isInteger(message.checkIns.low) && $util.isInteger(message.checkIns.high)))
                        return "checkIns: integer|Long expected";
                if (message.orders != null && message.hasOwnProperty("orders"))
                    if (!$util.isInteger(message.orders) && !(message.orders && $util.isInteger(message.orders.low) && $util.isInteger(message.orders.high)))
                        return "orders: integer|Long expected";
                if (message.paidOrders != null && message.hasOwnProperty("paidOrders"))
                    if (!$util.isInteger(message.paidOrders) && !(message.paidOrders && $util.isInteger(message.paidOrders.low) && $util.isInteger(message.paidOrders.high)))
                        return "paidOrders: integer|Long expected";
                if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                    if (!$util.isInteger(message.refundedOrders) && !(message.refundedOrders && $util.isInteger(message.refundedOrders.low) && $util.isInteger(message.refundedOrders.high)))
                        return "refundedOrders: integer|Long expected";
                if (message.orderPaymentsVolume != null && message.hasOwnProperty("orderPaymentsVolume")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.orderPaymentsVolume);
                    if (error)
                        return "orderPaymentsVolume." + error;
                }
                if (message.waiternowOrderPaymentsRevenue != null && message.hasOwnProperty("waiternowOrderPaymentsRevenue")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.waiternowOrderPaymentsRevenue);
                    if (error)
                        return "waiternowOrderPaymentsRevenue." + error;
                }
                if (message.tips != null && message.hasOwnProperty("tips")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.tips);
                    if (error)
                        return "tips." + error;
                }
                if (message.refundedOrdersVolume != null && message.hasOwnProperty("refundedOrdersVolume")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.refundedOrdersVolume);
                    if (error)
                        return "refundedOrdersVolume." + error;
                }
                if (message.thumbsUp != null && message.hasOwnProperty("thumbsUp"))
                    if (!$util.isInteger(message.thumbsUp) && !(message.thumbsUp && $util.isInteger(message.thumbsUp.low) && $util.isInteger(message.thumbsUp.high)))
                        return "thumbsUp: integer|Long expected";
                if (message.thumbsDown != null && message.hasOwnProperty("thumbsDown"))
                    if (!$util.isInteger(message.thumbsDown) && !(message.thumbsDown && $util.isInteger(message.thumbsDown.low) && $util.isInteger(message.thumbsDown.high)))
                        return "thumbsDown: integer|Long expected";
                if (message.reviewsWithComments != null && message.hasOwnProperty("reviewsWithComments"))
                    if (!$util.isInteger(message.reviewsWithComments) && !(message.reviewsWithComments && $util.isInteger(message.reviewsWithComments.low) && $util.isInteger(message.reviewsWithComments.high)))
                        return "reviewsWithComments: integer|Long expected";
                if (message.incompleteOrders != null && message.hasOwnProperty("incompleteOrders"))
                    if (!$util.isInteger(message.incompleteOrders) && !(message.incompleteOrders && $util.isInteger(message.incompleteOrders.low) && $util.isInteger(message.incompleteOrders.high)))
                        return "incompleteOrders: integer|Long expected";
                if (message.onlinePickupOrders != null && message.hasOwnProperty("onlinePickupOrders"))
                    if (!$util.isInteger(message.onlinePickupOrders) && !(message.onlinePickupOrders && $util.isInteger(message.onlinePickupOrders.low) && $util.isInteger(message.onlinePickupOrders.high)))
                        return "onlinePickupOrders: integer|Long expected";
                if (message.deliveryOrders != null && message.hasOwnProperty("deliveryOrders"))
                    if (!$util.isInteger(message.deliveryOrders) && !(message.deliveryOrders && $util.isInteger(message.deliveryOrders.low) && $util.isInteger(message.deliveryOrders.high)))
                        return "deliveryOrders: integer|Long expected";
                return null;
            };

            /**
             * Creates a LocationMetricProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationMetricProto} LocationMetricProto
             */
            LocationMetricProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationMetricProto)
                    return object;
                var message = new $root.waiternow.common.LocationMetricProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.period != null) {
                    if (typeof object.period !== "object")
                        throw TypeError(".waiternow.common.LocationMetricProto.period: object expected");
                    message.period = $root.waiternow.common.DateRangeProto.fromObject(object.period);
                }
                switch (object.periodType) {
                default:
                    if (typeof object.periodType === "number") {
                        message.periodType = object.periodType;
                        break;
                    }
                    break;
                case "PERIOD_TYPE_UNSPECIFIED":
                case 0:
                    message.periodType = 0;
                    break;
                case "DAILY":
                case 1:
                    message.periodType = 1;
                    break;
                case "MONTHLY":
                case 2:
                    message.periodType = 2;
                    break;
                case "YEARLY":
                case 3:
                    message.periodType = 3;
                    break;
                }
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.LocationMetricProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.LocationMetricProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.locationId != null)
                    message.locationId = String(object.locationId);
                if (object.checkIns != null)
                    if ($util.Long)
                        (message.checkIns = $util.Long.fromValue(object.checkIns)).unsigned = false;
                    else if (typeof object.checkIns === "string")
                        message.checkIns = parseInt(object.checkIns, 10);
                    else if (typeof object.checkIns === "number")
                        message.checkIns = object.checkIns;
                    else if (typeof object.checkIns === "object")
                        message.checkIns = new $util.LongBits(object.checkIns.low >>> 0, object.checkIns.high >>> 0).toNumber();
                if (object.orders != null)
                    if ($util.Long)
                        (message.orders = $util.Long.fromValue(object.orders)).unsigned = false;
                    else if (typeof object.orders === "string")
                        message.orders = parseInt(object.orders, 10);
                    else if (typeof object.orders === "number")
                        message.orders = object.orders;
                    else if (typeof object.orders === "object")
                        message.orders = new $util.LongBits(object.orders.low >>> 0, object.orders.high >>> 0).toNumber();
                if (object.paidOrders != null)
                    if ($util.Long)
                        (message.paidOrders = $util.Long.fromValue(object.paidOrders)).unsigned = false;
                    else if (typeof object.paidOrders === "string")
                        message.paidOrders = parseInt(object.paidOrders, 10);
                    else if (typeof object.paidOrders === "number")
                        message.paidOrders = object.paidOrders;
                    else if (typeof object.paidOrders === "object")
                        message.paidOrders = new $util.LongBits(object.paidOrders.low >>> 0, object.paidOrders.high >>> 0).toNumber();
                if (object.refundedOrders != null)
                    if ($util.Long)
                        (message.refundedOrders = $util.Long.fromValue(object.refundedOrders)).unsigned = false;
                    else if (typeof object.refundedOrders === "string")
                        message.refundedOrders = parseInt(object.refundedOrders, 10);
                    else if (typeof object.refundedOrders === "number")
                        message.refundedOrders = object.refundedOrders;
                    else if (typeof object.refundedOrders === "object")
                        message.refundedOrders = new $util.LongBits(object.refundedOrders.low >>> 0, object.refundedOrders.high >>> 0).toNumber();
                if (object.orderPaymentsVolume != null) {
                    if (typeof object.orderPaymentsVolume !== "object")
                        throw TypeError(".waiternow.common.LocationMetricProto.orderPaymentsVolume: object expected");
                    message.orderPaymentsVolume = $root.waiternow.common.MoneyProto.fromObject(object.orderPaymentsVolume);
                }
                if (object.waiternowOrderPaymentsRevenue != null) {
                    if (typeof object.waiternowOrderPaymentsRevenue !== "object")
                        throw TypeError(".waiternow.common.LocationMetricProto.waiternowOrderPaymentsRevenue: object expected");
                    message.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.fromObject(object.waiternowOrderPaymentsRevenue);
                }
                if (object.tips != null) {
                    if (typeof object.tips !== "object")
                        throw TypeError(".waiternow.common.LocationMetricProto.tips: object expected");
                    message.tips = $root.waiternow.common.MoneyProto.fromObject(object.tips);
                }
                if (object.refundedOrdersVolume != null) {
                    if (typeof object.refundedOrdersVolume !== "object")
                        throw TypeError(".waiternow.common.LocationMetricProto.refundedOrdersVolume: object expected");
                    message.refundedOrdersVolume = $root.waiternow.common.MoneyProto.fromObject(object.refundedOrdersVolume);
                }
                if (object.thumbsUp != null)
                    if ($util.Long)
                        (message.thumbsUp = $util.Long.fromValue(object.thumbsUp)).unsigned = false;
                    else if (typeof object.thumbsUp === "string")
                        message.thumbsUp = parseInt(object.thumbsUp, 10);
                    else if (typeof object.thumbsUp === "number")
                        message.thumbsUp = object.thumbsUp;
                    else if (typeof object.thumbsUp === "object")
                        message.thumbsUp = new $util.LongBits(object.thumbsUp.low >>> 0, object.thumbsUp.high >>> 0).toNumber();
                if (object.thumbsDown != null)
                    if ($util.Long)
                        (message.thumbsDown = $util.Long.fromValue(object.thumbsDown)).unsigned = false;
                    else if (typeof object.thumbsDown === "string")
                        message.thumbsDown = parseInt(object.thumbsDown, 10);
                    else if (typeof object.thumbsDown === "number")
                        message.thumbsDown = object.thumbsDown;
                    else if (typeof object.thumbsDown === "object")
                        message.thumbsDown = new $util.LongBits(object.thumbsDown.low >>> 0, object.thumbsDown.high >>> 0).toNumber();
                if (object.reviewsWithComments != null)
                    if ($util.Long)
                        (message.reviewsWithComments = $util.Long.fromValue(object.reviewsWithComments)).unsigned = false;
                    else if (typeof object.reviewsWithComments === "string")
                        message.reviewsWithComments = parseInt(object.reviewsWithComments, 10);
                    else if (typeof object.reviewsWithComments === "number")
                        message.reviewsWithComments = object.reviewsWithComments;
                    else if (typeof object.reviewsWithComments === "object")
                        message.reviewsWithComments = new $util.LongBits(object.reviewsWithComments.low >>> 0, object.reviewsWithComments.high >>> 0).toNumber();
                if (object.incompleteOrders != null)
                    if ($util.Long)
                        (message.incompleteOrders = $util.Long.fromValue(object.incompleteOrders)).unsigned = false;
                    else if (typeof object.incompleteOrders === "string")
                        message.incompleteOrders = parseInt(object.incompleteOrders, 10);
                    else if (typeof object.incompleteOrders === "number")
                        message.incompleteOrders = object.incompleteOrders;
                    else if (typeof object.incompleteOrders === "object")
                        message.incompleteOrders = new $util.LongBits(object.incompleteOrders.low >>> 0, object.incompleteOrders.high >>> 0).toNumber();
                if (object.onlinePickupOrders != null)
                    if ($util.Long)
                        (message.onlinePickupOrders = $util.Long.fromValue(object.onlinePickupOrders)).unsigned = false;
                    else if (typeof object.onlinePickupOrders === "string")
                        message.onlinePickupOrders = parseInt(object.onlinePickupOrders, 10);
                    else if (typeof object.onlinePickupOrders === "number")
                        message.onlinePickupOrders = object.onlinePickupOrders;
                    else if (typeof object.onlinePickupOrders === "object")
                        message.onlinePickupOrders = new $util.LongBits(object.onlinePickupOrders.low >>> 0, object.onlinePickupOrders.high >>> 0).toNumber();
                if (object.deliveryOrders != null)
                    if ($util.Long)
                        (message.deliveryOrders = $util.Long.fromValue(object.deliveryOrders)).unsigned = false;
                    else if (typeof object.deliveryOrders === "string")
                        message.deliveryOrders = parseInt(object.deliveryOrders, 10);
                    else if (typeof object.deliveryOrders === "number")
                        message.deliveryOrders = object.deliveryOrders;
                    else if (typeof object.deliveryOrders === "object")
                        message.deliveryOrders = new $util.LongBits(object.deliveryOrders.low >>> 0, object.deliveryOrders.high >>> 0).toNumber();
                return message;
            };

            /**
             * Creates a plain object from a LocationMetricProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {waiternow.common.LocationMetricProto} message LocationMetricProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationMetricProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.period = null;
                    object.periodType = options.enums === String ? "PERIOD_TYPE_UNSPECIFIED" : 0;
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.locationId = "";
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.checkIns = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.checkIns = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.orders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.orders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.paidOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.paidOrders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.refundedOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.refundedOrders = options.longs === String ? "0" : 0;
                    object.orderPaymentsVolume = null;
                    object.waiternowOrderPaymentsRevenue = null;
                    object.tips = null;
                    object.refundedOrdersVolume = null;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.thumbsUp = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.thumbsUp = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.thumbsDown = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.thumbsDown = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.reviewsWithComments = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.reviewsWithComments = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.incompleteOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.incompleteOrders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.onlinePickupOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.onlinePickupOrders = options.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.deliveryOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.deliveryOrders = options.longs === String ? "0" : 0;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.period != null && message.hasOwnProperty("period"))
                    object.period = $root.waiternow.common.DateRangeProto.toObject(message.period, options);
                if (message.periodType != null && message.hasOwnProperty("periodType"))
                    object.periodType = options.enums === String ? $root.waiternow.common.PeriodType[message.periodType] === undefined ? message.periodType : $root.waiternow.common.PeriodType[message.periodType] : message.periodType;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.locationId != null && message.hasOwnProperty("locationId"))
                    object.locationId = message.locationId;
                if (message.checkIns != null && message.hasOwnProperty("checkIns"))
                    if (typeof message.checkIns === "number")
                        object.checkIns = options.longs === String ? String(message.checkIns) : message.checkIns;
                    else
                        object.checkIns = options.longs === String ? $util.Long.prototype.toString.call(message.checkIns) : options.longs === Number ? new $util.LongBits(message.checkIns.low >>> 0, message.checkIns.high >>> 0).toNumber() : message.checkIns;
                if (message.orders != null && message.hasOwnProperty("orders"))
                    if (typeof message.orders === "number")
                        object.orders = options.longs === String ? String(message.orders) : message.orders;
                    else
                        object.orders = options.longs === String ? $util.Long.prototype.toString.call(message.orders) : options.longs === Number ? new $util.LongBits(message.orders.low >>> 0, message.orders.high >>> 0).toNumber() : message.orders;
                if (message.paidOrders != null && message.hasOwnProperty("paidOrders"))
                    if (typeof message.paidOrders === "number")
                        object.paidOrders = options.longs === String ? String(message.paidOrders) : message.paidOrders;
                    else
                        object.paidOrders = options.longs === String ? $util.Long.prototype.toString.call(message.paidOrders) : options.longs === Number ? new $util.LongBits(message.paidOrders.low >>> 0, message.paidOrders.high >>> 0).toNumber() : message.paidOrders;
                if (message.refundedOrders != null && message.hasOwnProperty("refundedOrders"))
                    if (typeof message.refundedOrders === "number")
                        object.refundedOrders = options.longs === String ? String(message.refundedOrders) : message.refundedOrders;
                    else
                        object.refundedOrders = options.longs === String ? $util.Long.prototype.toString.call(message.refundedOrders) : options.longs === Number ? new $util.LongBits(message.refundedOrders.low >>> 0, message.refundedOrders.high >>> 0).toNumber() : message.refundedOrders;
                if (message.orderPaymentsVolume != null && message.hasOwnProperty("orderPaymentsVolume"))
                    object.orderPaymentsVolume = $root.waiternow.common.MoneyProto.toObject(message.orderPaymentsVolume, options);
                if (message.waiternowOrderPaymentsRevenue != null && message.hasOwnProperty("waiternowOrderPaymentsRevenue"))
                    object.waiternowOrderPaymentsRevenue = $root.waiternow.common.MoneyProto.toObject(message.waiternowOrderPaymentsRevenue, options);
                if (message.tips != null && message.hasOwnProperty("tips"))
                    object.tips = $root.waiternow.common.MoneyProto.toObject(message.tips, options);
                if (message.refundedOrdersVolume != null && message.hasOwnProperty("refundedOrdersVolume"))
                    object.refundedOrdersVolume = $root.waiternow.common.MoneyProto.toObject(message.refundedOrdersVolume, options);
                if (message.thumbsUp != null && message.hasOwnProperty("thumbsUp"))
                    if (typeof message.thumbsUp === "number")
                        object.thumbsUp = options.longs === String ? String(message.thumbsUp) : message.thumbsUp;
                    else
                        object.thumbsUp = options.longs === String ? $util.Long.prototype.toString.call(message.thumbsUp) : options.longs === Number ? new $util.LongBits(message.thumbsUp.low >>> 0, message.thumbsUp.high >>> 0).toNumber() : message.thumbsUp;
                if (message.thumbsDown != null && message.hasOwnProperty("thumbsDown"))
                    if (typeof message.thumbsDown === "number")
                        object.thumbsDown = options.longs === String ? String(message.thumbsDown) : message.thumbsDown;
                    else
                        object.thumbsDown = options.longs === String ? $util.Long.prototype.toString.call(message.thumbsDown) : options.longs === Number ? new $util.LongBits(message.thumbsDown.low >>> 0, message.thumbsDown.high >>> 0).toNumber() : message.thumbsDown;
                if (message.reviewsWithComments != null && message.hasOwnProperty("reviewsWithComments"))
                    if (typeof message.reviewsWithComments === "number")
                        object.reviewsWithComments = options.longs === String ? String(message.reviewsWithComments) : message.reviewsWithComments;
                    else
                        object.reviewsWithComments = options.longs === String ? $util.Long.prototype.toString.call(message.reviewsWithComments) : options.longs === Number ? new $util.LongBits(message.reviewsWithComments.low >>> 0, message.reviewsWithComments.high >>> 0).toNumber() : message.reviewsWithComments;
                if (message.incompleteOrders != null && message.hasOwnProperty("incompleteOrders"))
                    if (typeof message.incompleteOrders === "number")
                        object.incompleteOrders = options.longs === String ? String(message.incompleteOrders) : message.incompleteOrders;
                    else
                        object.incompleteOrders = options.longs === String ? $util.Long.prototype.toString.call(message.incompleteOrders) : options.longs === Number ? new $util.LongBits(message.incompleteOrders.low >>> 0, message.incompleteOrders.high >>> 0).toNumber() : message.incompleteOrders;
                if (message.onlinePickupOrders != null && message.hasOwnProperty("onlinePickupOrders"))
                    if (typeof message.onlinePickupOrders === "number")
                        object.onlinePickupOrders = options.longs === String ? String(message.onlinePickupOrders) : message.onlinePickupOrders;
                    else
                        object.onlinePickupOrders = options.longs === String ? $util.Long.prototype.toString.call(message.onlinePickupOrders) : options.longs === Number ? new $util.LongBits(message.onlinePickupOrders.low >>> 0, message.onlinePickupOrders.high >>> 0).toNumber() : message.onlinePickupOrders;
                if (message.deliveryOrders != null && message.hasOwnProperty("deliveryOrders"))
                    if (typeof message.deliveryOrders === "number")
                        object.deliveryOrders = options.longs === String ? String(message.deliveryOrders) : message.deliveryOrders;
                    else
                        object.deliveryOrders = options.longs === String ? $util.Long.prototype.toString.call(message.deliveryOrders) : options.longs === Number ? new $util.LongBits(message.deliveryOrders.low >>> 0, message.deliveryOrders.high >>> 0).toNumber() : message.deliveryOrders;
                return object;
            };

            /**
             * Converts this LocationMetricProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationMetricProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationMetricProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationMetricProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationMetricProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationMetricProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationMetricProto";
            };

            return LocationMetricProto;
        })();

        common.LocationMetricsProto = (function() {

            /**
             * Properties of a LocationMetricsProto.
             * @memberof waiternow.common
             * @interface ILocationMetricsProto
             * @property {Array.<waiternow.common.ILocationMetricProto>|null} [metrics] LocationMetricsProto metrics
             * @property {string|null} [continuationToken] LocationMetricsProto continuationToken
             */

            /**
             * Constructs a new LocationMetricsProto.
             * @memberof waiternow.common
             * @classdesc Represents a LocationMetricsProto.
             * @implements ILocationMetricsProto
             * @constructor
             * @param {waiternow.common.ILocationMetricsProto=} [properties] Properties to set
             */
            function LocationMetricsProto(properties) {
                this.metrics = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * LocationMetricsProto metrics.
             * @member {Array.<waiternow.common.ILocationMetricProto>} metrics
             * @memberof waiternow.common.LocationMetricsProto
             * @instance
             */
            LocationMetricsProto.prototype.metrics = $util.emptyArray;

            /**
             * LocationMetricsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.LocationMetricsProto
             * @instance
             */
            LocationMetricsProto.prototype.continuationToken = "";

            /**
             * Creates a new LocationMetricsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {waiternow.common.ILocationMetricsProto=} [properties] Properties to set
             * @returns {waiternow.common.LocationMetricsProto} LocationMetricsProto instance
             */
            LocationMetricsProto.create = function create(properties) {
                return new LocationMetricsProto(properties);
            };

            /**
             * Encodes the specified LocationMetricsProto message. Does not implicitly {@link waiternow.common.LocationMetricsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {waiternow.common.ILocationMetricsProto} message LocationMetricsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationMetricsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.metrics != null && message.metrics.length)
                    for (var i = 0; i < message.metrics.length; ++i)
                        $root.waiternow.common.LocationMetricProto.encode(message.metrics[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified LocationMetricsProto message, length delimited. Does not implicitly {@link waiternow.common.LocationMetricsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {waiternow.common.ILocationMetricsProto} message LocationMetricsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            LocationMetricsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a LocationMetricsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.LocationMetricsProto} LocationMetricsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationMetricsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.LocationMetricsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.metrics && message.metrics.length))
                                message.metrics = [];
                            message.metrics.push($root.waiternow.common.LocationMetricProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a LocationMetricsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.LocationMetricsProto} LocationMetricsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            LocationMetricsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a LocationMetricsProto message.
             * @function verify
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            LocationMetricsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.metrics != null && message.hasOwnProperty("metrics")) {
                    if (!Array.isArray(message.metrics))
                        return "metrics: array expected";
                    for (var i = 0; i < message.metrics.length; ++i) {
                        var error = $root.waiternow.common.LocationMetricProto.verify(message.metrics[i]);
                        if (error)
                            return "metrics." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a LocationMetricsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.LocationMetricsProto} LocationMetricsProto
             */
            LocationMetricsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.LocationMetricsProto)
                    return object;
                var message = new $root.waiternow.common.LocationMetricsProto();
                if (object.metrics) {
                    if (!Array.isArray(object.metrics))
                        throw TypeError(".waiternow.common.LocationMetricsProto.metrics: array expected");
                    message.metrics = [];
                    for (var i = 0; i < object.metrics.length; ++i) {
                        if (typeof object.metrics[i] !== "object")
                            throw TypeError(".waiternow.common.LocationMetricsProto.metrics: object expected");
                        message.metrics[i] = $root.waiternow.common.LocationMetricProto.fromObject(object.metrics[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a LocationMetricsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {waiternow.common.LocationMetricsProto} message LocationMetricsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            LocationMetricsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.metrics = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.metrics && message.metrics.length) {
                    object.metrics = [];
                    for (var j = 0; j < message.metrics.length; ++j)
                        object.metrics[j] = $root.waiternow.common.LocationMetricProto.toObject(message.metrics[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this LocationMetricsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.LocationMetricsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            LocationMetricsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for LocationMetricsProto
             * @function getTypeUrl
             * @memberof waiternow.common.LocationMetricsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            LocationMetricsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.LocationMetricsProto";
            };

            return LocationMetricsProto;
        })();

        common.ClientNotificationProto = (function() {

            /**
             * Properties of a ClientNotificationProto.
             * @memberof waiternow.common
             * @interface IClientNotificationProto
             * @property {waiternow.common.ClientNotificationProto.INotificationAlertProto|null} [notificationAlert] ClientNotificationProto notificationAlert
             * @property {waiternow.common.ClientNotificationProto.INewOrderProto|null} [newOrder] ClientNotificationProto newOrder
             * @property {waiternow.common.ClientNotificationProto.IOrderStatusUpdatedProto|null} [orderStatusUpdated] ClientNotificationProto orderStatusUpdated
             * @property {waiternow.common.ClientNotificationProto.ILocationOnlineStatusUpdatedProto|null} [locationOnlineStatusUpdated] ClientNotificationProto locationOnlineStatusUpdated
             * @property {waiternow.common.ClientNotificationProto.IMenuUpdatedProto|null} [menuUpdated] ClientNotificationProto menuUpdated
             * @property {waiternow.common.ClientNotificationProto.ITerminateDeviceSessionRequestProto|null} [terminateDeviceSessionRequest] ClientNotificationProto terminateDeviceSessionRequest
             */

            /**
             * Constructs a new ClientNotificationProto.
             * @memberof waiternow.common
             * @classdesc Represents a ClientNotificationProto.
             * @implements IClientNotificationProto
             * @constructor
             * @param {waiternow.common.IClientNotificationProto=} [properties] Properties to set
             */
            function ClientNotificationProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * ClientNotificationProto notificationAlert.
             * @member {waiternow.common.ClientNotificationProto.INotificationAlertProto|null|undefined} notificationAlert
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             */
            ClientNotificationProto.prototype.notificationAlert = null;

            /**
             * ClientNotificationProto newOrder.
             * @member {waiternow.common.ClientNotificationProto.INewOrderProto|null|undefined} newOrder
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             */
            ClientNotificationProto.prototype.newOrder = null;

            /**
             * ClientNotificationProto orderStatusUpdated.
             * @member {waiternow.common.ClientNotificationProto.IOrderStatusUpdatedProto|null|undefined} orderStatusUpdated
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             */
            ClientNotificationProto.prototype.orderStatusUpdated = null;

            /**
             * ClientNotificationProto locationOnlineStatusUpdated.
             * @member {waiternow.common.ClientNotificationProto.ILocationOnlineStatusUpdatedProto|null|undefined} locationOnlineStatusUpdated
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             */
            ClientNotificationProto.prototype.locationOnlineStatusUpdated = null;

            /**
             * ClientNotificationProto menuUpdated.
             * @member {waiternow.common.ClientNotificationProto.IMenuUpdatedProto|null|undefined} menuUpdated
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             */
            ClientNotificationProto.prototype.menuUpdated = null;

            /**
             * ClientNotificationProto terminateDeviceSessionRequest.
             * @member {waiternow.common.ClientNotificationProto.ITerminateDeviceSessionRequestProto|null|undefined} terminateDeviceSessionRequest
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             */
            ClientNotificationProto.prototype.terminateDeviceSessionRequest = null;

            // OneOf field names bound to virtual getters and setters
            var $oneOfFields;

            /**
             * ClientNotificationProto notificationType.
             * @member {"newOrder"|"orderStatusUpdated"|"locationOnlineStatusUpdated"|"menuUpdated"|"terminateDeviceSessionRequest"|undefined} notificationType
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             */
            Object.defineProperty(ClientNotificationProto.prototype, "notificationType", {
                get: $util.oneOfGetter($oneOfFields = ["newOrder", "orderStatusUpdated", "locationOnlineStatusUpdated", "menuUpdated", "terminateDeviceSessionRequest"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            /**
             * Creates a new ClientNotificationProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {waiternow.common.IClientNotificationProto=} [properties] Properties to set
             * @returns {waiternow.common.ClientNotificationProto} ClientNotificationProto instance
             */
            ClientNotificationProto.create = function create(properties) {
                return new ClientNotificationProto(properties);
            };

            /**
             * Encodes the specified ClientNotificationProto message. Does not implicitly {@link waiternow.common.ClientNotificationProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {waiternow.common.IClientNotificationProto} message ClientNotificationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientNotificationProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.notificationAlert != null && Object.hasOwnProperty.call(message, "notificationAlert"))
                    $root.waiternow.common.ClientNotificationProto.NotificationAlertProto.encode(message.notificationAlert, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.newOrder != null && Object.hasOwnProperty.call(message, "newOrder"))
                    $root.waiternow.common.ClientNotificationProto.NewOrderProto.encode(message.newOrder, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.orderStatusUpdated != null && Object.hasOwnProperty.call(message, "orderStatusUpdated"))
                    $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.encode(message.orderStatusUpdated, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.locationOnlineStatusUpdated != null && Object.hasOwnProperty.call(message, "locationOnlineStatusUpdated"))
                    $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto.encode(message.locationOnlineStatusUpdated, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                if (message.menuUpdated != null && Object.hasOwnProperty.call(message, "menuUpdated"))
                    $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto.encode(message.menuUpdated, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                if (message.terminateDeviceSessionRequest != null && Object.hasOwnProperty.call(message, "terminateDeviceSessionRequest"))
                    $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto.encode(message.terminateDeviceSessionRequest, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified ClientNotificationProto message, length delimited. Does not implicitly {@link waiternow.common.ClientNotificationProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {waiternow.common.IClientNotificationProto} message ClientNotificationProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ClientNotificationProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ClientNotificationProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ClientNotificationProto} ClientNotificationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientNotificationProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientNotificationProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.notificationAlert = $root.waiternow.common.ClientNotificationProto.NotificationAlertProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 2: {
                            message.newOrder = $root.waiternow.common.ClientNotificationProto.NewOrderProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.orderStatusUpdated = $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.locationOnlineStatusUpdated = $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 5: {
                            message.menuUpdated = $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 6: {
                            message.terminateDeviceSessionRequest = $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ClientNotificationProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ClientNotificationProto} ClientNotificationProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ClientNotificationProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ClientNotificationProto message.
             * @function verify
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ClientNotificationProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                var properties = {};
                if (message.notificationAlert != null && message.hasOwnProperty("notificationAlert")) {
                    var error = $root.waiternow.common.ClientNotificationProto.NotificationAlertProto.verify(message.notificationAlert);
                    if (error)
                        return "notificationAlert." + error;
                }
                if (message.newOrder != null && message.hasOwnProperty("newOrder")) {
                    properties.notificationType = 1;
                    {
                        var error = $root.waiternow.common.ClientNotificationProto.NewOrderProto.verify(message.newOrder);
                        if (error)
                            return "newOrder." + error;
                    }
                }
                if (message.orderStatusUpdated != null && message.hasOwnProperty("orderStatusUpdated")) {
                    if (properties.notificationType === 1)
                        return "notificationType: multiple values";
                    properties.notificationType = 1;
                    {
                        var error = $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.verify(message.orderStatusUpdated);
                        if (error)
                            return "orderStatusUpdated." + error;
                    }
                }
                if (message.locationOnlineStatusUpdated != null && message.hasOwnProperty("locationOnlineStatusUpdated")) {
                    if (properties.notificationType === 1)
                        return "notificationType: multiple values";
                    properties.notificationType = 1;
                    {
                        var error = $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto.verify(message.locationOnlineStatusUpdated);
                        if (error)
                            return "locationOnlineStatusUpdated." + error;
                    }
                }
                if (message.menuUpdated != null && message.hasOwnProperty("menuUpdated")) {
                    if (properties.notificationType === 1)
                        return "notificationType: multiple values";
                    properties.notificationType = 1;
                    {
                        var error = $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto.verify(message.menuUpdated);
                        if (error)
                            return "menuUpdated." + error;
                    }
                }
                if (message.terminateDeviceSessionRequest != null && message.hasOwnProperty("terminateDeviceSessionRequest")) {
                    if (properties.notificationType === 1)
                        return "notificationType: multiple values";
                    properties.notificationType = 1;
                    {
                        var error = $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto.verify(message.terminateDeviceSessionRequest);
                        if (error)
                            return "terminateDeviceSessionRequest." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a ClientNotificationProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ClientNotificationProto} ClientNotificationProto
             */
            ClientNotificationProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ClientNotificationProto)
                    return object;
                var message = new $root.waiternow.common.ClientNotificationProto();
                if (object.notificationAlert != null) {
                    if (typeof object.notificationAlert !== "object")
                        throw TypeError(".waiternow.common.ClientNotificationProto.notificationAlert: object expected");
                    message.notificationAlert = $root.waiternow.common.ClientNotificationProto.NotificationAlertProto.fromObject(object.notificationAlert);
                }
                if (object.newOrder != null) {
                    if (typeof object.newOrder !== "object")
                        throw TypeError(".waiternow.common.ClientNotificationProto.newOrder: object expected");
                    message.newOrder = $root.waiternow.common.ClientNotificationProto.NewOrderProto.fromObject(object.newOrder);
                }
                if (object.orderStatusUpdated != null) {
                    if (typeof object.orderStatusUpdated !== "object")
                        throw TypeError(".waiternow.common.ClientNotificationProto.orderStatusUpdated: object expected");
                    message.orderStatusUpdated = $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.fromObject(object.orderStatusUpdated);
                }
                if (object.locationOnlineStatusUpdated != null) {
                    if (typeof object.locationOnlineStatusUpdated !== "object")
                        throw TypeError(".waiternow.common.ClientNotificationProto.locationOnlineStatusUpdated: object expected");
                    message.locationOnlineStatusUpdated = $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto.fromObject(object.locationOnlineStatusUpdated);
                }
                if (object.menuUpdated != null) {
                    if (typeof object.menuUpdated !== "object")
                        throw TypeError(".waiternow.common.ClientNotificationProto.menuUpdated: object expected");
                    message.menuUpdated = $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto.fromObject(object.menuUpdated);
                }
                if (object.terminateDeviceSessionRequest != null) {
                    if (typeof object.terminateDeviceSessionRequest !== "object")
                        throw TypeError(".waiternow.common.ClientNotificationProto.terminateDeviceSessionRequest: object expected");
                    message.terminateDeviceSessionRequest = $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto.fromObject(object.terminateDeviceSessionRequest);
                }
                return message;
            };

            /**
             * Creates a plain object from a ClientNotificationProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {waiternow.common.ClientNotificationProto} message ClientNotificationProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ClientNotificationProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults)
                    object.notificationAlert = null;
                if (message.notificationAlert != null && message.hasOwnProperty("notificationAlert"))
                    object.notificationAlert = $root.waiternow.common.ClientNotificationProto.NotificationAlertProto.toObject(message.notificationAlert, options);
                if (message.newOrder != null && message.hasOwnProperty("newOrder")) {
                    object.newOrder = $root.waiternow.common.ClientNotificationProto.NewOrderProto.toObject(message.newOrder, options);
                    if (options.oneofs)
                        object.notificationType = "newOrder";
                }
                if (message.orderStatusUpdated != null && message.hasOwnProperty("orderStatusUpdated")) {
                    object.orderStatusUpdated = $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.toObject(message.orderStatusUpdated, options);
                    if (options.oneofs)
                        object.notificationType = "orderStatusUpdated";
                }
                if (message.locationOnlineStatusUpdated != null && message.hasOwnProperty("locationOnlineStatusUpdated")) {
                    object.locationOnlineStatusUpdated = $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto.toObject(message.locationOnlineStatusUpdated, options);
                    if (options.oneofs)
                        object.notificationType = "locationOnlineStatusUpdated";
                }
                if (message.menuUpdated != null && message.hasOwnProperty("menuUpdated")) {
                    object.menuUpdated = $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto.toObject(message.menuUpdated, options);
                    if (options.oneofs)
                        object.notificationType = "menuUpdated";
                }
                if (message.terminateDeviceSessionRequest != null && message.hasOwnProperty("terminateDeviceSessionRequest")) {
                    object.terminateDeviceSessionRequest = $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto.toObject(message.terminateDeviceSessionRequest, options);
                    if (options.oneofs)
                        object.notificationType = "terminateDeviceSessionRequest";
                }
                return object;
            };

            /**
             * Converts this ClientNotificationProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ClientNotificationProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ClientNotificationProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ClientNotificationProto
             * @function getTypeUrl
             * @memberof waiternow.common.ClientNotificationProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ClientNotificationProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ClientNotificationProto";
            };

            ClientNotificationProto.NotificationAlertProto = (function() {

                /**
                 * Properties of a NotificationAlertProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @interface INotificationAlertProto
                 * @property {string|null} [title] NotificationAlertProto title
                 * @property {string|null} [body] NotificationAlertProto body
                 * @property {string|null} [imageUrl] NotificationAlertProto imageUrl
                 * @property {string|null} [redirectLink] NotificationAlertProto redirectLink
                 */

                /**
                 * Constructs a new NotificationAlertProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @classdesc Represents a NotificationAlertProto.
                 * @implements INotificationAlertProto
                 * @constructor
                 * @param {waiternow.common.ClientNotificationProto.INotificationAlertProto=} [properties] Properties to set
                 */
                function NotificationAlertProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NotificationAlertProto title.
                 * @member {string} title
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @instance
                 */
                NotificationAlertProto.prototype.title = "";

                /**
                 * NotificationAlertProto body.
                 * @member {string} body
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @instance
                 */
                NotificationAlertProto.prototype.body = "";

                /**
                 * NotificationAlertProto imageUrl.
                 * @member {string} imageUrl
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @instance
                 */
                NotificationAlertProto.prototype.imageUrl = "";

                /**
                 * NotificationAlertProto redirectLink.
                 * @member {string} redirectLink
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @instance
                 */
                NotificationAlertProto.prototype.redirectLink = "";

                /**
                 * Creates a new NotificationAlertProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.INotificationAlertProto=} [properties] Properties to set
                 * @returns {waiternow.common.ClientNotificationProto.NotificationAlertProto} NotificationAlertProto instance
                 */
                NotificationAlertProto.create = function create(properties) {
                    return new NotificationAlertProto(properties);
                };

                /**
                 * Encodes the specified NotificationAlertProto message. Does not implicitly {@link waiternow.common.ClientNotificationProto.NotificationAlertProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.INotificationAlertProto} message NotificationAlertProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NotificationAlertProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.title != null && Object.hasOwnProperty.call(message, "title"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.title);
                    if (message.body != null && Object.hasOwnProperty.call(message, "body"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.body);
                    if (message.imageUrl != null && Object.hasOwnProperty.call(message, "imageUrl"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.imageUrl);
                    if (message.redirectLink != null && Object.hasOwnProperty.call(message, "redirectLink"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.redirectLink);
                    return writer;
                };

                /**
                 * Encodes the specified NotificationAlertProto message, length delimited. Does not implicitly {@link waiternow.common.ClientNotificationProto.NotificationAlertProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.INotificationAlertProto} message NotificationAlertProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NotificationAlertProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NotificationAlertProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ClientNotificationProto.NotificationAlertProto} NotificationAlertProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NotificationAlertProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientNotificationProto.NotificationAlertProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.title = reader.string();
                                break;
                            }
                        case 2: {
                                message.body = reader.string();
                                break;
                            }
                        case 3: {
                                message.imageUrl = reader.string();
                                break;
                            }
                        case 4: {
                                message.redirectLink = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a NotificationAlertProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ClientNotificationProto.NotificationAlertProto} NotificationAlertProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NotificationAlertProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NotificationAlertProto message.
                 * @function verify
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NotificationAlertProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.title != null && message.hasOwnProperty("title"))
                        if (!$util.isString(message.title))
                            return "title: string expected";
                    if (message.body != null && message.hasOwnProperty("body"))
                        if (!$util.isString(message.body))
                            return "body: string expected";
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        if (!$util.isString(message.imageUrl))
                            return "imageUrl: string expected";
                    if (message.redirectLink != null && message.hasOwnProperty("redirectLink"))
                        if (!$util.isString(message.redirectLink))
                            return "redirectLink: string expected";
                    return null;
                };

                /**
                 * Creates a NotificationAlertProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ClientNotificationProto.NotificationAlertProto} NotificationAlertProto
                 */
                NotificationAlertProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ClientNotificationProto.NotificationAlertProto)
                        return object;
                    var message = new $root.waiternow.common.ClientNotificationProto.NotificationAlertProto();
                    if (object.title != null)
                        message.title = String(object.title);
                    if (object.body != null)
                        message.body = String(object.body);
                    if (object.imageUrl != null)
                        message.imageUrl = String(object.imageUrl);
                    if (object.redirectLink != null)
                        message.redirectLink = String(object.redirectLink);
                    return message;
                };

                /**
                 * Creates a plain object from a NotificationAlertProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.NotificationAlertProto} message NotificationAlertProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NotificationAlertProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.title = "";
                        object.body = "";
                        object.imageUrl = "";
                        object.redirectLink = "";
                    }
                    if (message.title != null && message.hasOwnProperty("title"))
                        object.title = message.title;
                    if (message.body != null && message.hasOwnProperty("body"))
                        object.body = message.body;
                    if (message.imageUrl != null && message.hasOwnProperty("imageUrl"))
                        object.imageUrl = message.imageUrl;
                    if (message.redirectLink != null && message.hasOwnProperty("redirectLink"))
                        object.redirectLink = message.redirectLink;
                    return object;
                };

                /**
                 * Converts this NotificationAlertProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NotificationAlertProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NotificationAlertProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ClientNotificationProto.NotificationAlertProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NotificationAlertProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ClientNotificationProto.NotificationAlertProto";
                };

                return NotificationAlertProto;
            })();

            ClientNotificationProto.NewOrderProto = (function() {

                /**
                 * Properties of a NewOrderProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @interface INewOrderProto
                 * @property {string|null} [locationId] NewOrderProto locationId
                 * @property {string|null} [pointOfServiceId] NewOrderProto pointOfServiceId
                 * @property {string|null} [orderId] NewOrderProto orderId
                 */

                /**
                 * Constructs a new NewOrderProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @classdesc Represents a NewOrderProto.
                 * @implements INewOrderProto
                 * @constructor
                 * @param {waiternow.common.ClientNotificationProto.INewOrderProto=} [properties] Properties to set
                 */
                function NewOrderProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * NewOrderProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @instance
                 */
                NewOrderProto.prototype.locationId = "";

                /**
                 * NewOrderProto pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @instance
                 */
                NewOrderProto.prototype.pointOfServiceId = "";

                /**
                 * NewOrderProto orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @instance
                 */
                NewOrderProto.prototype.orderId = "";

                /**
                 * Creates a new NewOrderProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.INewOrderProto=} [properties] Properties to set
                 * @returns {waiternow.common.ClientNotificationProto.NewOrderProto} NewOrderProto instance
                 */
                NewOrderProto.create = function create(properties) {
                    return new NewOrderProto(properties);
                };

                /**
                 * Encodes the specified NewOrderProto message. Does not implicitly {@link waiternow.common.ClientNotificationProto.NewOrderProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.INewOrderProto} message NewOrderProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NewOrderProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.pointOfServiceId);
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.orderId);
                    return writer;
                };

                /**
                 * Encodes the specified NewOrderProto message, length delimited. Does not implicitly {@link waiternow.common.ClientNotificationProto.NewOrderProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.INewOrderProto} message NewOrderProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                NewOrderProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a NewOrderProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ClientNotificationProto.NewOrderProto} NewOrderProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NewOrderProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientNotificationProto.NewOrderProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        case 3: {
                                message.orderId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a NewOrderProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ClientNotificationProto.NewOrderProto} NewOrderProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                NewOrderProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a NewOrderProto message.
                 * @function verify
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                NewOrderProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    return null;
                };

                /**
                 * Creates a NewOrderProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ClientNotificationProto.NewOrderProto} NewOrderProto
                 */
                NewOrderProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ClientNotificationProto.NewOrderProto)
                        return object;
                    var message = new $root.waiternow.common.ClientNotificationProto.NewOrderProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    return message;
                };

                /**
                 * Creates a plain object from a NewOrderProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.NewOrderProto} message NewOrderProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                NewOrderProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.pointOfServiceId = "";
                        object.orderId = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    return object;
                };

                /**
                 * Converts this NewOrderProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                NewOrderProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for NewOrderProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ClientNotificationProto.NewOrderProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                NewOrderProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ClientNotificationProto.NewOrderProto";
                };

                return NewOrderProto;
            })();

            ClientNotificationProto.OrderStatusUpdatedProto = (function() {

                /**
                 * Properties of an OrderStatusUpdatedProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @interface IOrderStatusUpdatedProto
                 * @property {waiternow.common.IOrderProto|null} [order] OrderStatusUpdatedProto order
                 */

                /**
                 * Constructs a new OrderStatusUpdatedProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @classdesc Represents an OrderStatusUpdatedProto.
                 * @implements IOrderStatusUpdatedProto
                 * @constructor
                 * @param {waiternow.common.ClientNotificationProto.IOrderStatusUpdatedProto=} [properties] Properties to set
                 */
                function OrderStatusUpdatedProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * OrderStatusUpdatedProto order.
                 * @member {waiternow.common.IOrderProto|null|undefined} order
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @instance
                 */
                OrderStatusUpdatedProto.prototype.order = null;

                /**
                 * Creates a new OrderStatusUpdatedProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.IOrderStatusUpdatedProto=} [properties] Properties to set
                 * @returns {waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto} OrderStatusUpdatedProto instance
                 */
                OrderStatusUpdatedProto.create = function create(properties) {
                    return new OrderStatusUpdatedProto(properties);
                };

                /**
                 * Encodes the specified OrderStatusUpdatedProto message. Does not implicitly {@link waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.IOrderStatusUpdatedProto} message OrderStatusUpdatedProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrderStatusUpdatedProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                        $root.waiternow.common.OrderProto.encode(message.order, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified OrderStatusUpdatedProto message, length delimited. Does not implicitly {@link waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.IOrderStatusUpdatedProto} message OrderStatusUpdatedProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                OrderStatusUpdatedProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes an OrderStatusUpdatedProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto} OrderStatusUpdatedProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrderStatusUpdatedProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.order = $root.waiternow.common.OrderProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes an OrderStatusUpdatedProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto} OrderStatusUpdatedProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                OrderStatusUpdatedProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies an OrderStatusUpdatedProto message.
                 * @function verify
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                OrderStatusUpdatedProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.order != null && message.hasOwnProperty("order")) {
                        var error = $root.waiternow.common.OrderProto.verify(message.order);
                        if (error)
                            return "order." + error;
                    }
                    return null;
                };

                /**
                 * Creates an OrderStatusUpdatedProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto} OrderStatusUpdatedProto
                 */
                OrderStatusUpdatedProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto)
                        return object;
                    var message = new $root.waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto();
                    if (object.order != null) {
                        if (typeof object.order !== "object")
                            throw TypeError(".waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto.order: object expected");
                        message.order = $root.waiternow.common.OrderProto.fromObject(object.order);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from an OrderStatusUpdatedProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto} message OrderStatusUpdatedProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                OrderStatusUpdatedProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.order = null;
                    if (message.order != null && message.hasOwnProperty("order"))
                        object.order = $root.waiternow.common.OrderProto.toObject(message.order, options);
                    return object;
                };

                /**
                 * Converts this OrderStatusUpdatedProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                OrderStatusUpdatedProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for OrderStatusUpdatedProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                OrderStatusUpdatedProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ClientNotificationProto.OrderStatusUpdatedProto";
                };

                return OrderStatusUpdatedProto;
            })();

            ClientNotificationProto.LocationOnlineStatusUpdatedProto = (function() {

                /**
                 * Properties of a LocationOnlineStatusUpdatedProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @interface ILocationOnlineStatusUpdatedProto
                 * @property {string|null} [locationId] LocationOnlineStatusUpdatedProto locationId
                 * @property {boolean|null} [isLocationOnline] LocationOnlineStatusUpdatedProto isLocationOnline
                 */

                /**
                 * Constructs a new LocationOnlineStatusUpdatedProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @classdesc Represents a LocationOnlineStatusUpdatedProto.
                 * @implements ILocationOnlineStatusUpdatedProto
                 * @constructor
                 * @param {waiternow.common.ClientNotificationProto.ILocationOnlineStatusUpdatedProto=} [properties] Properties to set
                 */
                function LocationOnlineStatusUpdatedProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocationOnlineStatusUpdatedProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @instance
                 */
                LocationOnlineStatusUpdatedProto.prototype.locationId = "";

                /**
                 * LocationOnlineStatusUpdatedProto isLocationOnline.
                 * @member {boolean} isLocationOnline
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @instance
                 */
                LocationOnlineStatusUpdatedProto.prototype.isLocationOnline = false;

                /**
                 * Creates a new LocationOnlineStatusUpdatedProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.ILocationOnlineStatusUpdatedProto=} [properties] Properties to set
                 * @returns {waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto} LocationOnlineStatusUpdatedProto instance
                 */
                LocationOnlineStatusUpdatedProto.create = function create(properties) {
                    return new LocationOnlineStatusUpdatedProto(properties);
                };

                /**
                 * Encodes the specified LocationOnlineStatusUpdatedProto message. Does not implicitly {@link waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.ILocationOnlineStatusUpdatedProto} message LocationOnlineStatusUpdatedProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationOnlineStatusUpdatedProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.isLocationOnline != null && Object.hasOwnProperty.call(message, "isLocationOnline"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isLocationOnline);
                    return writer;
                };

                /**
                 * Encodes the specified LocationOnlineStatusUpdatedProto message, length delimited. Does not implicitly {@link waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.ILocationOnlineStatusUpdatedProto} message LocationOnlineStatusUpdatedProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationOnlineStatusUpdatedProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocationOnlineStatusUpdatedProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto} LocationOnlineStatusUpdatedProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationOnlineStatusUpdatedProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.isLocationOnline = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocationOnlineStatusUpdatedProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto} LocationOnlineStatusUpdatedProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationOnlineStatusUpdatedProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocationOnlineStatusUpdatedProto message.
                 * @function verify
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocationOnlineStatusUpdatedProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                        if (typeof message.isLocationOnline !== "boolean")
                            return "isLocationOnline: boolean expected";
                    return null;
                };

                /**
                 * Creates a LocationOnlineStatusUpdatedProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto} LocationOnlineStatusUpdatedProto
                 */
                LocationOnlineStatusUpdatedProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto)
                        return object;
                    var message = new $root.waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.isLocationOnline != null)
                        message.isLocationOnline = Boolean(object.isLocationOnline);
                    return message;
                };

                /**
                 * Creates a plain object from a LocationOnlineStatusUpdatedProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto} message LocationOnlineStatusUpdatedProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocationOnlineStatusUpdatedProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.isLocationOnline = false;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.isLocationOnline != null && message.hasOwnProperty("isLocationOnline"))
                        object.isLocationOnline = message.isLocationOnline;
                    return object;
                };

                /**
                 * Converts this LocationOnlineStatusUpdatedProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocationOnlineStatusUpdatedProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocationOnlineStatusUpdatedProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocationOnlineStatusUpdatedProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ClientNotificationProto.LocationOnlineStatusUpdatedProto";
                };

                return LocationOnlineStatusUpdatedProto;
            })();

            ClientNotificationProto.MenuUpdatedProto = (function() {

                /**
                 * Properties of a MenuUpdatedProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @interface IMenuUpdatedProto
                 * @property {string|null} [locationId] MenuUpdatedProto locationId
                 */

                /**
                 * Constructs a new MenuUpdatedProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @classdesc Represents a MenuUpdatedProto.
                 * @implements IMenuUpdatedProto
                 * @constructor
                 * @param {waiternow.common.ClientNotificationProto.IMenuUpdatedProto=} [properties] Properties to set
                 */
                function MenuUpdatedProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * MenuUpdatedProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @instance
                 */
                MenuUpdatedProto.prototype.locationId = "";

                /**
                 * Creates a new MenuUpdatedProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.IMenuUpdatedProto=} [properties] Properties to set
                 * @returns {waiternow.common.ClientNotificationProto.MenuUpdatedProto} MenuUpdatedProto instance
                 */
                MenuUpdatedProto.create = function create(properties) {
                    return new MenuUpdatedProto(properties);
                };

                /**
                 * Encodes the specified MenuUpdatedProto message. Does not implicitly {@link waiternow.common.ClientNotificationProto.MenuUpdatedProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.IMenuUpdatedProto} message MenuUpdatedProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuUpdatedProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified MenuUpdatedProto message, length delimited. Does not implicitly {@link waiternow.common.ClientNotificationProto.MenuUpdatedProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.IMenuUpdatedProto} message MenuUpdatedProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                MenuUpdatedProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a MenuUpdatedProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ClientNotificationProto.MenuUpdatedProto} MenuUpdatedProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuUpdatedProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a MenuUpdatedProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ClientNotificationProto.MenuUpdatedProto} MenuUpdatedProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                MenuUpdatedProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a MenuUpdatedProto message.
                 * @function verify
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                MenuUpdatedProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a MenuUpdatedProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ClientNotificationProto.MenuUpdatedProto} MenuUpdatedProto
                 */
                MenuUpdatedProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto)
                        return object;
                    var message = new $root.waiternow.common.ClientNotificationProto.MenuUpdatedProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a MenuUpdatedProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.MenuUpdatedProto} message MenuUpdatedProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                MenuUpdatedProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this MenuUpdatedProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                MenuUpdatedProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for MenuUpdatedProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ClientNotificationProto.MenuUpdatedProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                MenuUpdatedProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ClientNotificationProto.MenuUpdatedProto";
                };

                return MenuUpdatedProto;
            })();

            ClientNotificationProto.TerminateDeviceSessionRequestProto = (function() {

                /**
                 * Properties of a TerminateDeviceSessionRequestProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @interface ITerminateDeviceSessionRequestProto
                 * @property {string|null} [deviceId] TerminateDeviceSessionRequestProto deviceId
                 */

                /**
                 * Constructs a new TerminateDeviceSessionRequestProto.
                 * @memberof waiternow.common.ClientNotificationProto
                 * @classdesc Represents a TerminateDeviceSessionRequestProto.
                 * @implements ITerminateDeviceSessionRequestProto
                 * @constructor
                 * @param {waiternow.common.ClientNotificationProto.ITerminateDeviceSessionRequestProto=} [properties] Properties to set
                 */
                function TerminateDeviceSessionRequestProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * TerminateDeviceSessionRequestProto deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @instance
                 */
                TerminateDeviceSessionRequestProto.prototype.deviceId = "";

                /**
                 * Creates a new TerminateDeviceSessionRequestProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.ITerminateDeviceSessionRequestProto=} [properties] Properties to set
                 * @returns {waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto} TerminateDeviceSessionRequestProto instance
                 */
                TerminateDeviceSessionRequestProto.create = function create(properties) {
                    return new TerminateDeviceSessionRequestProto(properties);
                };

                /**
                 * Encodes the specified TerminateDeviceSessionRequestProto message. Does not implicitly {@link waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.ITerminateDeviceSessionRequestProto} message TerminateDeviceSessionRequestProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TerminateDeviceSessionRequestProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    return writer;
                };

                /**
                 * Encodes the specified TerminateDeviceSessionRequestProto message, length delimited. Does not implicitly {@link waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.ITerminateDeviceSessionRequestProto} message TerminateDeviceSessionRequestProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                TerminateDeviceSessionRequestProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a TerminateDeviceSessionRequestProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto} TerminateDeviceSessionRequestProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TerminateDeviceSessionRequestProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a TerminateDeviceSessionRequestProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto} TerminateDeviceSessionRequestProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                TerminateDeviceSessionRequestProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a TerminateDeviceSessionRequestProto message.
                 * @function verify
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                TerminateDeviceSessionRequestProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    return null;
                };

                /**
                 * Creates a TerminateDeviceSessionRequestProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto} TerminateDeviceSessionRequestProto
                 */
                TerminateDeviceSessionRequestProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto)
                        return object;
                    var message = new $root.waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    return message;
                };

                /**
                 * Creates a plain object from a TerminateDeviceSessionRequestProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto} message TerminateDeviceSessionRequestProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                TerminateDeviceSessionRequestProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.deviceId = "";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    return object;
                };

                /**
                 * Converts this TerminateDeviceSessionRequestProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                TerminateDeviceSessionRequestProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for TerminateDeviceSessionRequestProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                TerminateDeviceSessionRequestProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ClientNotificationProto.TerminateDeviceSessionRequestProto";
                };

                return TerminateDeviceSessionRequestProto;
            })();

            return ClientNotificationProto;
        })();

        common.PaycheckProto = (function() {

            /**
             * Properties of a PaycheckProto.
             * @memberof waiternow.common
             * @interface IPaycheckProto
             * @property {string|null} [id] PaycheckProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] PaycheckProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] PaycheckProto lastUpdateTime
             * @property {string|null} [salespersonId] PaycheckProto salespersonId
             * @property {string|null} [salespersonName] PaycheckProto salespersonName
             * @property {string|null} [salespersonLastName] PaycheckProto salespersonLastName
             * @property {waiternow.common.IDateRangeProto|null} [period] PaycheckProto period
             * @property {waiternow.common.IMoneyProto|null} [commissionPerPaidOrder] PaycheckProto commissionPerPaidOrder
             * @property {Array.<waiternow.common.PaycheckProto.ILocationCommissionProto>|null} [locationCommissions] PaycheckProto locationCommissions
             * @property {waiternow.common.IMoneyProto|null} [totalCommission] PaycheckProto totalCommission
             * @property {waiternow.common.PaycheckProto.IStatusProto|null} [status] PaycheckProto status
             */

            /**
             * Constructs a new PaycheckProto.
             * @memberof waiternow.common
             * @classdesc Represents a PaycheckProto.
             * @implements IPaycheckProto
             * @constructor
             * @param {waiternow.common.IPaycheckProto=} [properties] Properties to set
             */
            function PaycheckProto(properties) {
                this.locationCommissions = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PaycheckProto id.
             * @member {string} id
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.id = "";

            /**
             * PaycheckProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.creationTime = null;

            /**
             * PaycheckProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.lastUpdateTime = null;

            /**
             * PaycheckProto salespersonId.
             * @member {string} salespersonId
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.salespersonId = "";

            /**
             * PaycheckProto salespersonName.
             * @member {string} salespersonName
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.salespersonName = "";

            /**
             * PaycheckProto salespersonLastName.
             * @member {string} salespersonLastName
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.salespersonLastName = "";

            /**
             * PaycheckProto period.
             * @member {waiternow.common.IDateRangeProto|null|undefined} period
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.period = null;

            /**
             * PaycheckProto commissionPerPaidOrder.
             * @member {waiternow.common.IMoneyProto|null|undefined} commissionPerPaidOrder
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.commissionPerPaidOrder = null;

            /**
             * PaycheckProto locationCommissions.
             * @member {Array.<waiternow.common.PaycheckProto.ILocationCommissionProto>} locationCommissions
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.locationCommissions = $util.emptyArray;

            /**
             * PaycheckProto totalCommission.
             * @member {waiternow.common.IMoneyProto|null|undefined} totalCommission
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.totalCommission = null;

            /**
             * PaycheckProto status.
             * @member {waiternow.common.PaycheckProto.IStatusProto|null|undefined} status
             * @memberof waiternow.common.PaycheckProto
             * @instance
             */
            PaycheckProto.prototype.status = null;

            /**
             * Creates a new PaycheckProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {waiternow.common.IPaycheckProto=} [properties] Properties to set
             * @returns {waiternow.common.PaycheckProto} PaycheckProto instance
             */
            PaycheckProto.create = function create(properties) {
                return new PaycheckProto(properties);
            };

            /**
             * Encodes the specified PaycheckProto message. Does not implicitly {@link waiternow.common.PaycheckProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {waiternow.common.IPaycheckProto} message PaycheckProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaycheckProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.salespersonId != null && Object.hasOwnProperty.call(message, "salespersonId"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.salespersonId);
                if (message.salespersonName != null && Object.hasOwnProperty.call(message, "salespersonName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.salespersonName);
                if (message.salespersonLastName != null && Object.hasOwnProperty.call(message, "salespersonLastName"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.salespersonLastName);
                if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                    $root.waiternow.common.DateRangeProto.encode(message.period, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.commissionPerPaidOrder != null && Object.hasOwnProperty.call(message, "commissionPerPaidOrder"))
                    $root.waiternow.common.MoneyProto.encode(message.commissionPerPaidOrder, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.locationCommissions != null && message.locationCommissions.length)
                    for (var i = 0; i < message.locationCommissions.length; ++i)
                        $root.waiternow.common.PaycheckProto.LocationCommissionProto.encode(message.locationCommissions[i], writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.totalCommission != null && Object.hasOwnProperty.call(message, "totalCommission"))
                    $root.waiternow.common.MoneyProto.encode(message.totalCommission, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.waiternow.common.PaycheckProto.StatusProto.encode(message.status, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PaycheckProto message, length delimited. Does not implicitly {@link waiternow.common.PaycheckProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {waiternow.common.IPaycheckProto} message PaycheckProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaycheckProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PaycheckProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PaycheckProto} PaycheckProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaycheckProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaycheckProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.salespersonId = reader.string();
                            break;
                        }
                    case 5: {
                            message.salespersonName = reader.string();
                            break;
                        }
                    case 6: {
                            message.salespersonLastName = reader.string();
                            break;
                        }
                    case 7: {
                            message.period = $root.waiternow.common.DateRangeProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.commissionPerPaidOrder = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            if (!(message.locationCommissions && message.locationCommissions.length))
                                message.locationCommissions = [];
                            message.locationCommissions.push($root.waiternow.common.PaycheckProto.LocationCommissionProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 10: {
                            message.totalCommission = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 11: {
                            message.status = $root.waiternow.common.PaycheckProto.StatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PaycheckProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PaycheckProto} PaycheckProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaycheckProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PaycheckProto message.
             * @function verify
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaycheckProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                    if (!$util.isString(message.salespersonId))
                        return "salespersonId: string expected";
                if (message.salespersonName != null && message.hasOwnProperty("salespersonName"))
                    if (!$util.isString(message.salespersonName))
                        return "salespersonName: string expected";
                if (message.salespersonLastName != null && message.hasOwnProperty("salespersonLastName"))
                    if (!$util.isString(message.salespersonLastName))
                        return "salespersonLastName: string expected";
                if (message.period != null && message.hasOwnProperty("period")) {
                    var error = $root.waiternow.common.DateRangeProto.verify(message.period);
                    if (error)
                        return "period." + error;
                }
                if (message.commissionPerPaidOrder != null && message.hasOwnProperty("commissionPerPaidOrder")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.commissionPerPaidOrder);
                    if (error)
                        return "commissionPerPaidOrder." + error;
                }
                if (message.locationCommissions != null && message.hasOwnProperty("locationCommissions")) {
                    if (!Array.isArray(message.locationCommissions))
                        return "locationCommissions: array expected";
                    for (var i = 0; i < message.locationCommissions.length; ++i) {
                        var error = $root.waiternow.common.PaycheckProto.LocationCommissionProto.verify(message.locationCommissions[i]);
                        if (error)
                            return "locationCommissions." + error;
                    }
                }
                if (message.totalCommission != null && message.hasOwnProperty("totalCommission")) {
                    var error = $root.waiternow.common.MoneyProto.verify(message.totalCommission);
                    if (error)
                        return "totalCommission." + error;
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    var error = $root.waiternow.common.PaycheckProto.StatusProto.verify(message.status);
                    if (error)
                        return "status." + error;
                }
                return null;
            };

            /**
             * Creates a PaycheckProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PaycheckProto} PaycheckProto
             */
            PaycheckProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PaycheckProto)
                    return object;
                var message = new $root.waiternow.common.PaycheckProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.PaycheckProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.PaycheckProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.salespersonId != null)
                    message.salespersonId = String(object.salespersonId);
                if (object.salespersonName != null)
                    message.salespersonName = String(object.salespersonName);
                if (object.salespersonLastName != null)
                    message.salespersonLastName = String(object.salespersonLastName);
                if (object.period != null) {
                    if (typeof object.period !== "object")
                        throw TypeError(".waiternow.common.PaycheckProto.period: object expected");
                    message.period = $root.waiternow.common.DateRangeProto.fromObject(object.period);
                }
                if (object.commissionPerPaidOrder != null) {
                    if (typeof object.commissionPerPaidOrder !== "object")
                        throw TypeError(".waiternow.common.PaycheckProto.commissionPerPaidOrder: object expected");
                    message.commissionPerPaidOrder = $root.waiternow.common.MoneyProto.fromObject(object.commissionPerPaidOrder);
                }
                if (object.locationCommissions) {
                    if (!Array.isArray(object.locationCommissions))
                        throw TypeError(".waiternow.common.PaycheckProto.locationCommissions: array expected");
                    message.locationCommissions = [];
                    for (var i = 0; i < object.locationCommissions.length; ++i) {
                        if (typeof object.locationCommissions[i] !== "object")
                            throw TypeError(".waiternow.common.PaycheckProto.locationCommissions: object expected");
                        message.locationCommissions[i] = $root.waiternow.common.PaycheckProto.LocationCommissionProto.fromObject(object.locationCommissions[i]);
                    }
                }
                if (object.totalCommission != null) {
                    if (typeof object.totalCommission !== "object")
                        throw TypeError(".waiternow.common.PaycheckProto.totalCommission: object expected");
                    message.totalCommission = $root.waiternow.common.MoneyProto.fromObject(object.totalCommission);
                }
                if (object.status != null) {
                    if (typeof object.status !== "object")
                        throw TypeError(".waiternow.common.PaycheckProto.status: object expected");
                    message.status = $root.waiternow.common.PaycheckProto.StatusProto.fromObject(object.status);
                }
                return message;
            };

            /**
             * Creates a plain object from a PaycheckProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {waiternow.common.PaycheckProto} message PaycheckProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaycheckProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.locationCommissions = [];
                if (options.defaults) {
                    object.id = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.salespersonId = "";
                    object.salespersonName = "";
                    object.salespersonLastName = "";
                    object.period = null;
                    object.commissionPerPaidOrder = null;
                    object.totalCommission = null;
                    object.status = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                    object.salespersonId = message.salespersonId;
                if (message.salespersonName != null && message.hasOwnProperty("salespersonName"))
                    object.salespersonName = message.salespersonName;
                if (message.salespersonLastName != null && message.hasOwnProperty("salespersonLastName"))
                    object.salespersonLastName = message.salespersonLastName;
                if (message.period != null && message.hasOwnProperty("period"))
                    object.period = $root.waiternow.common.DateRangeProto.toObject(message.period, options);
                if (message.commissionPerPaidOrder != null && message.hasOwnProperty("commissionPerPaidOrder"))
                    object.commissionPerPaidOrder = $root.waiternow.common.MoneyProto.toObject(message.commissionPerPaidOrder, options);
                if (message.locationCommissions && message.locationCommissions.length) {
                    object.locationCommissions = [];
                    for (var j = 0; j < message.locationCommissions.length; ++j)
                        object.locationCommissions[j] = $root.waiternow.common.PaycheckProto.LocationCommissionProto.toObject(message.locationCommissions[j], options);
                }
                if (message.totalCommission != null && message.hasOwnProperty("totalCommission"))
                    object.totalCommission = $root.waiternow.common.MoneyProto.toObject(message.totalCommission, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = $root.waiternow.common.PaycheckProto.StatusProto.toObject(message.status, options);
                return object;
            };

            /**
             * Converts this PaycheckProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PaycheckProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaycheckProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PaycheckProto
             * @function getTypeUrl
             * @memberof waiternow.common.PaycheckProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaycheckProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PaycheckProto";
            };

            PaycheckProto.LocationCommissionProto = (function() {

                /**
                 * Properties of a LocationCommissionProto.
                 * @memberof waiternow.common.PaycheckProto
                 * @interface ILocationCommissionProto
                 * @property {string|null} [locationId] LocationCommissionProto locationId
                 * @property {string|null} [locationName] LocationCommissionProto locationName
                 * @property {string|null} [businessName] LocationCommissionProto businessName
                 * @property {number|null} [eligibleOrders] LocationCommissionProto eligibleOrders
                 */

                /**
                 * Constructs a new LocationCommissionProto.
                 * @memberof waiternow.common.PaycheckProto
                 * @classdesc Represents a LocationCommissionProto.
                 * @implements ILocationCommissionProto
                 * @constructor
                 * @param {waiternow.common.PaycheckProto.ILocationCommissionProto=} [properties] Properties to set
                 */
                function LocationCommissionProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * LocationCommissionProto locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @instance
                 */
                LocationCommissionProto.prototype.locationId = "";

                /**
                 * LocationCommissionProto locationName.
                 * @member {string} locationName
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @instance
                 */
                LocationCommissionProto.prototype.locationName = "";

                /**
                 * LocationCommissionProto businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @instance
                 */
                LocationCommissionProto.prototype.businessName = "";

                /**
                 * LocationCommissionProto eligibleOrders.
                 * @member {number} eligibleOrders
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @instance
                 */
                LocationCommissionProto.prototype.eligibleOrders = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

                /**
                 * Creates a new LocationCommissionProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.ILocationCommissionProto=} [properties] Properties to set
                 * @returns {waiternow.common.PaycheckProto.LocationCommissionProto} LocationCommissionProto instance
                 */
                LocationCommissionProto.create = function create(properties) {
                    return new LocationCommissionProto(properties);
                };

                /**
                 * Encodes the specified LocationCommissionProto message. Does not implicitly {@link waiternow.common.PaycheckProto.LocationCommissionProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.ILocationCommissionProto} message LocationCommissionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationCommissionProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.locationName != null && Object.hasOwnProperty.call(message, "locationName"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationName);
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessName);
                    if (message.eligibleOrders != null && Object.hasOwnProperty.call(message, "eligibleOrders"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int64(message.eligibleOrders);
                    return writer;
                };

                /**
                 * Encodes the specified LocationCommissionProto message, length delimited. Does not implicitly {@link waiternow.common.PaycheckProto.LocationCommissionProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.ILocationCommissionProto} message LocationCommissionProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                LocationCommissionProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a LocationCommissionProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PaycheckProto.LocationCommissionProto} LocationCommissionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationCommissionProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaycheckProto.LocationCommissionProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationName = reader.string();
                                break;
                            }
                        case 3: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 4: {
                                message.eligibleOrders = reader.int64();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a LocationCommissionProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PaycheckProto.LocationCommissionProto} LocationCommissionProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                LocationCommissionProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a LocationCommissionProto message.
                 * @function verify
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                LocationCommissionProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        if (!$util.isString(message.locationName))
                            return "locationName: string expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.eligibleOrders != null && message.hasOwnProperty("eligibleOrders"))
                        if (!$util.isInteger(message.eligibleOrders) && !(message.eligibleOrders && $util.isInteger(message.eligibleOrders.low) && $util.isInteger(message.eligibleOrders.high)))
                            return "eligibleOrders: integer|Long expected";
                    return null;
                };

                /**
                 * Creates a LocationCommissionProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PaycheckProto.LocationCommissionProto} LocationCommissionProto
                 */
                LocationCommissionProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PaycheckProto.LocationCommissionProto)
                        return object;
                    var message = new $root.waiternow.common.PaycheckProto.LocationCommissionProto();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.locationName != null)
                        message.locationName = String(object.locationName);
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.eligibleOrders != null)
                        if ($util.Long)
                            (message.eligibleOrders = $util.Long.fromValue(object.eligibleOrders)).unsigned = false;
                        else if (typeof object.eligibleOrders === "string")
                            message.eligibleOrders = parseInt(object.eligibleOrders, 10);
                        else if (typeof object.eligibleOrders === "number")
                            message.eligibleOrders = object.eligibleOrders;
                        else if (typeof object.eligibleOrders === "object")
                            message.eligibleOrders = new $util.LongBits(object.eligibleOrders.low >>> 0, object.eligibleOrders.high >>> 0).toNumber();
                    return message;
                };

                /**
                 * Creates a plain object from a LocationCommissionProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.LocationCommissionProto} message LocationCommissionProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                LocationCommissionProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.locationName = "";
                        object.businessName = "";
                        if ($util.Long) {
                            var long = new $util.Long(0, 0, false);
                            object.eligibleOrders = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                        } else
                            object.eligibleOrders = options.longs === String ? "0" : 0;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.locationName != null && message.hasOwnProperty("locationName"))
                        object.locationName = message.locationName;
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.eligibleOrders != null && message.hasOwnProperty("eligibleOrders"))
                        if (typeof message.eligibleOrders === "number")
                            object.eligibleOrders = options.longs === String ? String(message.eligibleOrders) : message.eligibleOrders;
                        else
                            object.eligibleOrders = options.longs === String ? $util.Long.prototype.toString.call(message.eligibleOrders) : options.longs === Number ? new $util.LongBits(message.eligibleOrders.low >>> 0, message.eligibleOrders.high >>> 0).toNumber() : message.eligibleOrders;
                    return object;
                };

                /**
                 * Converts this LocationCommissionProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                LocationCommissionProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for LocationCommissionProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PaycheckProto.LocationCommissionProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                LocationCommissionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PaycheckProto.LocationCommissionProto";
                };

                return LocationCommissionProto;
            })();

            PaycheckProto.StatusProto = (function() {

                /**
                 * Properties of a StatusProto.
                 * @memberof waiternow.common.PaycheckProto
                 * @interface IStatusProto
                 * @property {waiternow.common.PaycheckProto.StatusProto.State|null} [state] StatusProto state
                 * @property {google.protobuf.ITimestamp|null} [paidOn] StatusProto paidOn
                 */

                /**
                 * Constructs a new StatusProto.
                 * @memberof waiternow.common.PaycheckProto
                 * @classdesc Represents a StatusProto.
                 * @implements IStatusProto
                 * @constructor
                 * @param {waiternow.common.PaycheckProto.IStatusProto=} [properties] Properties to set
                 */
                function StatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatusProto state.
                 * @member {waiternow.common.PaycheckProto.StatusProto.State} state
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.state = 0;

                /**
                 * StatusProto paidOn.
                 * @member {google.protobuf.ITimestamp|null|undefined} paidOn
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.paidOn = null;

                /**
                 * Creates a new StatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.IStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.PaycheckProto.StatusProto} StatusProto instance
                 */
                StatusProto.create = function create(properties) {
                    return new StatusProto(properties);
                };

                /**
                 * Encodes the specified StatusProto message. Does not implicitly {@link waiternow.common.PaycheckProto.StatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
                    if (message.paidOn != null && Object.hasOwnProperty.call(message, "paidOn"))
                        $root.google.protobuf.Timestamp.encode(message.paidOn, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified StatusProto message, length delimited. Does not implicitly {@link waiternow.common.PaycheckProto.StatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PaycheckProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaycheckProto.StatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = reader.int32();
                                break;
                            }
                        case 3: {
                                message.paidOn = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PaycheckProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatusProto message.
                 * @function verify
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.paidOn != null && message.hasOwnProperty("paidOn")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.paidOn);
                        if (error)
                            return "paidOn." + error;
                    }
                    return null;
                };

                /**
                 * Creates a StatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PaycheckProto.StatusProto} StatusProto
                 */
                StatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PaycheckProto.StatusProto)
                        return object;
                    var message = new $root.waiternow.common.PaycheckProto.StatusProto();
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATE_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "PENDING":
                    case 1:
                        message.state = 1;
                        break;
                    case "PAID":
                    case 2:
                        message.state = 2;
                        break;
                    case "NO_PAYMENT_REQUIRED":
                    case 3:
                        message.state = 3;
                        break;
                    }
                    if (object.paidOn != null) {
                        if (typeof object.paidOn !== "object")
                            throw TypeError(".waiternow.common.PaycheckProto.StatusProto.paidOn: object expected");
                        message.paidOn = $root.google.protobuf.Timestamp.fromObject(object.paidOn);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a StatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {waiternow.common.PaycheckProto.StatusProto} message StatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.state = options.enums === String ? "STATE_UNSPECIFIED" : 0;
                        object.paidOn = null;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.PaycheckProto.StatusProto.State[message.state] === undefined ? message.state : $root.waiternow.common.PaycheckProto.StatusProto.State[message.state] : message.state;
                    if (message.paidOn != null && message.hasOwnProperty("paidOn"))
                        object.paidOn = $root.google.protobuf.Timestamp.toObject(message.paidOn, options);
                    return object;
                };

                /**
                 * Converts this StatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.PaycheckProto.StatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PaycheckProto.StatusProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.PaycheckProto.StatusProto.State
                 * @enum {number}
                 * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
                 * @property {number} PENDING=1 PENDING value
                 * @property {number} PAID=2 PAID value
                 * @property {number} NO_PAYMENT_REQUIRED=3 NO_PAYMENT_REQUIRED value
                 */
                StatusProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "PENDING"] = 1;
                    values[valuesById[2] = "PAID"] = 2;
                    values[valuesById[3] = "NO_PAYMENT_REQUIRED"] = 3;
                    return values;
                })();

                return StatusProto;
            })();

            return PaycheckProto;
        })();

        common.PaychecksProto = (function() {

            /**
             * Properties of a PaychecksProto.
             * @memberof waiternow.common
             * @interface IPaychecksProto
             * @property {Array.<waiternow.common.IPaycheckProto>|null} [paychecks] PaychecksProto paychecks
             */

            /**
             * Constructs a new PaychecksProto.
             * @memberof waiternow.common
             * @classdesc Represents a PaychecksProto.
             * @implements IPaychecksProto
             * @constructor
             * @param {waiternow.common.IPaychecksProto=} [properties] Properties to set
             */
            function PaychecksProto(properties) {
                this.paychecks = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * PaychecksProto paychecks.
             * @member {Array.<waiternow.common.IPaycheckProto>} paychecks
             * @memberof waiternow.common.PaychecksProto
             * @instance
             */
            PaychecksProto.prototype.paychecks = $util.emptyArray;

            /**
             * Creates a new PaychecksProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {waiternow.common.IPaychecksProto=} [properties] Properties to set
             * @returns {waiternow.common.PaychecksProto} PaychecksProto instance
             */
            PaychecksProto.create = function create(properties) {
                return new PaychecksProto(properties);
            };

            /**
             * Encodes the specified PaychecksProto message. Does not implicitly {@link waiternow.common.PaychecksProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {waiternow.common.IPaychecksProto} message PaychecksProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaychecksProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.paychecks != null && message.paychecks.length)
                    for (var i = 0; i < message.paychecks.length; ++i)
                        $root.waiternow.common.PaycheckProto.encode(message.paychecks[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified PaychecksProto message, length delimited. Does not implicitly {@link waiternow.common.PaychecksProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {waiternow.common.IPaychecksProto} message PaychecksProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PaychecksProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PaychecksProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PaychecksProto} PaychecksProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaychecksProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PaychecksProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 2: {
                            if (!(message.paychecks && message.paychecks.length))
                                message.paychecks = [];
                            message.paychecks.push($root.waiternow.common.PaycheckProto.decode(reader, reader.uint32()));
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PaychecksProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PaychecksProto} PaychecksProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PaychecksProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PaychecksProto message.
             * @function verify
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PaychecksProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.paychecks != null && message.hasOwnProperty("paychecks")) {
                    if (!Array.isArray(message.paychecks))
                        return "paychecks: array expected";
                    for (var i = 0; i < message.paychecks.length; ++i) {
                        var error = $root.waiternow.common.PaycheckProto.verify(message.paychecks[i]);
                        if (error)
                            return "paychecks." + error;
                    }
                }
                return null;
            };

            /**
             * Creates a PaychecksProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PaychecksProto} PaychecksProto
             */
            PaychecksProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PaychecksProto)
                    return object;
                var message = new $root.waiternow.common.PaychecksProto();
                if (object.paychecks) {
                    if (!Array.isArray(object.paychecks))
                        throw TypeError(".waiternow.common.PaychecksProto.paychecks: array expected");
                    message.paychecks = [];
                    for (var i = 0; i < object.paychecks.length; ++i) {
                        if (typeof object.paychecks[i] !== "object")
                            throw TypeError(".waiternow.common.PaychecksProto.paychecks: object expected");
                        message.paychecks[i] = $root.waiternow.common.PaycheckProto.fromObject(object.paychecks[i]);
                    }
                }
                return message;
            };

            /**
             * Creates a plain object from a PaychecksProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {waiternow.common.PaychecksProto} message PaychecksProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PaychecksProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.paychecks = [];
                if (message.paychecks && message.paychecks.length) {
                    object.paychecks = [];
                    for (var j = 0; j < message.paychecks.length; ++j)
                        object.paychecks[j] = $root.waiternow.common.PaycheckProto.toObject(message.paychecks[j], options);
                }
                return object;
            };

            /**
             * Converts this PaychecksProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PaychecksProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PaychecksProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PaychecksProto
             * @function getTypeUrl
             * @memberof waiternow.common.PaychecksProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PaychecksProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PaychecksProto";
            };

            return PaychecksProto;
        })();

        common.SalespersonProto = (function() {

            /**
             * Properties of a SalespersonProto.
             * @memberof waiternow.common
             * @interface ISalespersonProto
             * @property {string|null} [id] SalespersonProto id
             * @property {google.protobuf.ITimestamp|null} [creationTime] SalespersonProto creationTime
             * @property {google.protobuf.ITimestamp|null} [lastUpdateTime] SalespersonProto lastUpdateTime
             * @property {string|null} [name] SalespersonProto name
             * @property {string|null} [lastName] SalespersonProto lastName
             * @property {string|null} [email] SalespersonProto email
             * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] SalespersonProto phoneNumber
             * @property {waiternow.common.SalespersonProto.IStatusProto|null} [status] SalespersonProto status
             * @property {waiternow.common.SalespersonProto.ISettingsProto|null} [settings] SalespersonProto settings
             * @property {waiternow.common.SalespersonProto.ISalaryProto|null} [salary] SalespersonProto salary
             */

            /**
             * Constructs a new SalespersonProto.
             * @memberof waiternow.common
             * @classdesc Represents a SalespersonProto.
             * @implements ISalespersonProto
             * @constructor
             * @param {waiternow.common.ISalespersonProto=} [properties] Properties to set
             */
            function SalespersonProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SalespersonProto id.
             * @member {string} id
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.id = "";

            /**
             * SalespersonProto creationTime.
             * @member {google.protobuf.ITimestamp|null|undefined} creationTime
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.creationTime = null;

            /**
             * SalespersonProto lastUpdateTime.
             * @member {google.protobuf.ITimestamp|null|undefined} lastUpdateTime
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.lastUpdateTime = null;

            /**
             * SalespersonProto name.
             * @member {string} name
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.name = "";

            /**
             * SalespersonProto lastName.
             * @member {string} lastName
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.lastName = "";

            /**
             * SalespersonProto email.
             * @member {string} email
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.email = "";

            /**
             * SalespersonProto phoneNumber.
             * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.phoneNumber = null;

            /**
             * SalespersonProto status.
             * @member {waiternow.common.SalespersonProto.IStatusProto|null|undefined} status
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.status = null;

            /**
             * SalespersonProto settings.
             * @member {waiternow.common.SalespersonProto.ISettingsProto|null|undefined} settings
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.settings = null;

            /**
             * SalespersonProto salary.
             * @member {waiternow.common.SalespersonProto.ISalaryProto|null|undefined} salary
             * @memberof waiternow.common.SalespersonProto
             * @instance
             */
            SalespersonProto.prototype.salary = null;

            /**
             * Creates a new SalespersonProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {waiternow.common.ISalespersonProto=} [properties] Properties to set
             * @returns {waiternow.common.SalespersonProto} SalespersonProto instance
             */
            SalespersonProto.create = function create(properties) {
                return new SalespersonProto(properties);
            };

            /**
             * Encodes the specified SalespersonProto message. Does not implicitly {@link waiternow.common.SalespersonProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {waiternow.common.ISalespersonProto} message SalespersonProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SalespersonProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.id != null && Object.hasOwnProperty.call(message, "id"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.id);
                if (message.creationTime != null && Object.hasOwnProperty.call(message, "creationTime"))
                    $root.google.protobuf.Timestamp.encode(message.creationTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                if (message.lastUpdateTime != null && Object.hasOwnProperty.call(message, "lastUpdateTime"))
                    $root.google.protobuf.Timestamp.encode(message.lastUpdateTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                    writer.uint32(/* id 4, wireType 2 =*/34).string(message.name);
                if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                    writer.uint32(/* id 5, wireType 2 =*/42).string(message.lastName);
                if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                    writer.uint32(/* id 6, wireType 2 =*/50).string(message.email);
                if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                    $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                if (message.status != null && Object.hasOwnProperty.call(message, "status"))
                    $root.waiternow.common.SalespersonProto.StatusProto.encode(message.status, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
                    $root.waiternow.common.SalespersonProto.SettingsProto.encode(message.settings, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
                if (message.salary != null && Object.hasOwnProperty.call(message, "salary"))
                    $root.waiternow.common.SalespersonProto.SalaryProto.encode(message.salary, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                return writer;
            };

            /**
             * Encodes the specified SalespersonProto message, length delimited. Does not implicitly {@link waiternow.common.SalespersonProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {waiternow.common.ISalespersonProto} message SalespersonProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SalespersonProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SalespersonProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SalespersonProto} SalespersonProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SalespersonProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SalespersonProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.id = reader.string();
                            break;
                        }
                    case 2: {
                            message.creationTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 3: {
                            message.lastUpdateTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                            break;
                        }
                    case 4: {
                            message.name = reader.string();
                            break;
                        }
                    case 5: {
                            message.lastName = reader.string();
                            break;
                        }
                    case 6: {
                            message.email = reader.string();
                            break;
                        }
                    case 7: {
                            message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 8: {
                            message.status = $root.waiternow.common.SalespersonProto.StatusProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 9: {
                            message.settings = $root.waiternow.common.SalespersonProto.SettingsProto.decode(reader, reader.uint32());
                            break;
                        }
                    case 10: {
                            message.salary = $root.waiternow.common.SalespersonProto.SalaryProto.decode(reader, reader.uint32());
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SalespersonProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SalespersonProto} SalespersonProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SalespersonProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SalespersonProto message.
             * @function verify
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SalespersonProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.id != null && message.hasOwnProperty("id"))
                    if (!$util.isString(message.id))
                        return "id: string expected";
                if (message.creationTime != null && message.hasOwnProperty("creationTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.creationTime);
                    if (error)
                        return "creationTime." + error;
                }
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime")) {
                    var error = $root.google.protobuf.Timestamp.verify(message.lastUpdateTime);
                    if (error)
                        return "lastUpdateTime." + error;
                }
                if (message.name != null && message.hasOwnProperty("name"))
                    if (!$util.isString(message.name))
                        return "name: string expected";
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    if (!$util.isString(message.lastName))
                        return "lastName: string expected";
                if (message.email != null && message.hasOwnProperty("email"))
                    if (!$util.isString(message.email))
                        return "email: string expected";
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                    var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                    if (error)
                        return "phoneNumber." + error;
                }
                if (message.status != null && message.hasOwnProperty("status")) {
                    var error = $root.waiternow.common.SalespersonProto.StatusProto.verify(message.status);
                    if (error)
                        return "status." + error;
                }
                if (message.settings != null && message.hasOwnProperty("settings")) {
                    var error = $root.waiternow.common.SalespersonProto.SettingsProto.verify(message.settings);
                    if (error)
                        return "settings." + error;
                }
                if (message.salary != null && message.hasOwnProperty("salary")) {
                    var error = $root.waiternow.common.SalespersonProto.SalaryProto.verify(message.salary);
                    if (error)
                        return "salary." + error;
                }
                return null;
            };

            /**
             * Creates a SalespersonProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SalespersonProto} SalespersonProto
             */
            SalespersonProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SalespersonProto)
                    return object;
                var message = new $root.waiternow.common.SalespersonProto();
                if (object.id != null)
                    message.id = String(object.id);
                if (object.creationTime != null) {
                    if (typeof object.creationTime !== "object")
                        throw TypeError(".waiternow.common.SalespersonProto.creationTime: object expected");
                    message.creationTime = $root.google.protobuf.Timestamp.fromObject(object.creationTime);
                }
                if (object.lastUpdateTime != null) {
                    if (typeof object.lastUpdateTime !== "object")
                        throw TypeError(".waiternow.common.SalespersonProto.lastUpdateTime: object expected");
                    message.lastUpdateTime = $root.google.protobuf.Timestamp.fromObject(object.lastUpdateTime);
                }
                if (object.name != null)
                    message.name = String(object.name);
                if (object.lastName != null)
                    message.lastName = String(object.lastName);
                if (object.email != null)
                    message.email = String(object.email);
                if (object.phoneNumber != null) {
                    if (typeof object.phoneNumber !== "object")
                        throw TypeError(".waiternow.common.SalespersonProto.phoneNumber: object expected");
                    message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                }
                if (object.status != null) {
                    if (typeof object.status !== "object")
                        throw TypeError(".waiternow.common.SalespersonProto.status: object expected");
                    message.status = $root.waiternow.common.SalespersonProto.StatusProto.fromObject(object.status);
                }
                if (object.settings != null) {
                    if (typeof object.settings !== "object")
                        throw TypeError(".waiternow.common.SalespersonProto.settings: object expected");
                    message.settings = $root.waiternow.common.SalespersonProto.SettingsProto.fromObject(object.settings);
                }
                if (object.salary != null) {
                    if (typeof object.salary !== "object")
                        throw TypeError(".waiternow.common.SalespersonProto.salary: object expected");
                    message.salary = $root.waiternow.common.SalespersonProto.SalaryProto.fromObject(object.salary);
                }
                return message;
            };

            /**
             * Creates a plain object from a SalespersonProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {waiternow.common.SalespersonProto} message SalespersonProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SalespersonProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.id = "";
                    object.creationTime = null;
                    object.lastUpdateTime = null;
                    object.name = "";
                    object.lastName = "";
                    object.email = "";
                    object.phoneNumber = null;
                    object.status = null;
                    object.settings = null;
                    object.salary = null;
                }
                if (message.id != null && message.hasOwnProperty("id"))
                    object.id = message.id;
                if (message.creationTime != null && message.hasOwnProperty("creationTime"))
                    object.creationTime = $root.google.protobuf.Timestamp.toObject(message.creationTime, options);
                if (message.lastUpdateTime != null && message.hasOwnProperty("lastUpdateTime"))
                    object.lastUpdateTime = $root.google.protobuf.Timestamp.toObject(message.lastUpdateTime, options);
                if (message.name != null && message.hasOwnProperty("name"))
                    object.name = message.name;
                if (message.lastName != null && message.hasOwnProperty("lastName"))
                    object.lastName = message.lastName;
                if (message.email != null && message.hasOwnProperty("email"))
                    object.email = message.email;
                if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                    object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                if (message.status != null && message.hasOwnProperty("status"))
                    object.status = $root.waiternow.common.SalespersonProto.StatusProto.toObject(message.status, options);
                if (message.settings != null && message.hasOwnProperty("settings"))
                    object.settings = $root.waiternow.common.SalespersonProto.SettingsProto.toObject(message.settings, options);
                if (message.salary != null && message.hasOwnProperty("salary"))
                    object.salary = $root.waiternow.common.SalespersonProto.SalaryProto.toObject(message.salary, options);
                return object;
            };

            /**
             * Converts this SalespersonProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SalespersonProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SalespersonProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SalespersonProto
             * @function getTypeUrl
             * @memberof waiternow.common.SalespersonProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SalespersonProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SalespersonProto";
            };

            SalespersonProto.StatusProto = (function() {

                /**
                 * Properties of a StatusProto.
                 * @memberof waiternow.common.SalespersonProto
                 * @interface IStatusProto
                 * @property {waiternow.common.SalespersonProto.StatusProto.State|null} [state] StatusProto state
                 * @property {google.protobuf.ITimestamp|null} [stateUpdatedOn] StatusProto stateUpdatedOn
                 */

                /**
                 * Constructs a new StatusProto.
                 * @memberof waiternow.common.SalespersonProto
                 * @classdesc Represents a StatusProto.
                 * @implements IStatusProto
                 * @constructor
                 * @param {waiternow.common.SalespersonProto.IStatusProto=} [properties] Properties to set
                 */
                function StatusProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * StatusProto state.
                 * @member {waiternow.common.SalespersonProto.StatusProto.State} state
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.state = 0;

                /**
                 * StatusProto stateUpdatedOn.
                 * @member {google.protobuf.ITimestamp|null|undefined} stateUpdatedOn
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @instance
                 */
                StatusProto.prototype.stateUpdatedOn = null;

                /**
                 * Creates a new StatusProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.IStatusProto=} [properties] Properties to set
                 * @returns {waiternow.common.SalespersonProto.StatusProto} StatusProto instance
                 */
                StatusProto.create = function create(properties) {
                    return new StatusProto(properties);
                };

                /**
                 * Encodes the specified StatusProto message. Does not implicitly {@link waiternow.common.SalespersonProto.StatusProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.state != null && Object.hasOwnProperty.call(message, "state"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.state);
                    if (message.stateUpdatedOn != null && Object.hasOwnProperty.call(message, "stateUpdatedOn"))
                        $root.google.protobuf.Timestamp.encode(message.stateUpdatedOn, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified StatusProto message, length delimited. Does not implicitly {@link waiternow.common.SalespersonProto.StatusProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.IStatusProto} message StatusProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                StatusProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SalespersonProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SalespersonProto.StatusProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.state = reader.int32();
                                break;
                            }
                        case 2: {
                                message.stateUpdatedOn = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a StatusProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SalespersonProto.StatusProto} StatusProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                StatusProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a StatusProto message.
                 * @function verify
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                StatusProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.state != null && message.hasOwnProperty("state"))
                        switch (message.state) {
                        default:
                            return "state: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.stateUpdatedOn != null && message.hasOwnProperty("stateUpdatedOn")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.stateUpdatedOn);
                        if (error)
                            return "stateUpdatedOn." + error;
                    }
                    return null;
                };

                /**
                 * Creates a StatusProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SalespersonProto.StatusProto} StatusProto
                 */
                StatusProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SalespersonProto.StatusProto)
                        return object;
                    var message = new $root.waiternow.common.SalespersonProto.StatusProto();
                    switch (object.state) {
                    default:
                        if (typeof object.state === "number") {
                            message.state = object.state;
                            break;
                        }
                        break;
                    case "STATE_UNSPECIFIED":
                    case 0:
                        message.state = 0;
                        break;
                    case "ACTIVE":
                    case 1:
                        message.state = 1;
                        break;
                    case "INACTIVE":
                    case 2:
                        message.state = 2;
                        break;
                    }
                    if (object.stateUpdatedOn != null) {
                        if (typeof object.stateUpdatedOn !== "object")
                            throw TypeError(".waiternow.common.SalespersonProto.StatusProto.stateUpdatedOn: object expected");
                        message.stateUpdatedOn = $root.google.protobuf.Timestamp.fromObject(object.stateUpdatedOn);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a StatusProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.StatusProto} message StatusProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                StatusProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.state = options.enums === String ? "STATE_UNSPECIFIED" : 0;
                        object.stateUpdatedOn = null;
                    }
                    if (message.state != null && message.hasOwnProperty("state"))
                        object.state = options.enums === String ? $root.waiternow.common.SalespersonProto.StatusProto.State[message.state] === undefined ? message.state : $root.waiternow.common.SalespersonProto.StatusProto.State[message.state] : message.state;
                    if (message.stateUpdatedOn != null && message.hasOwnProperty("stateUpdatedOn"))
                        object.stateUpdatedOn = $root.google.protobuf.Timestamp.toObject(message.stateUpdatedOn, options);
                    return object;
                };

                /**
                 * Converts this StatusProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                StatusProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for StatusProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.SalespersonProto.StatusProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                StatusProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SalespersonProto.StatusProto";
                };

                /**
                 * State enum.
                 * @name waiternow.common.SalespersonProto.StatusProto.State
                 * @enum {number}
                 * @property {number} STATE_UNSPECIFIED=0 STATE_UNSPECIFIED value
                 * @property {number} ACTIVE=1 ACTIVE value
                 * @property {number} INACTIVE=2 INACTIVE value
                 */
                StatusProto.State = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "STATE_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "ACTIVE"] = 1;
                    values[valuesById[2] = "INACTIVE"] = 2;
                    return values;
                })();

                return StatusProto;
            })();

            SalespersonProto.SettingsProto = (function() {

                /**
                 * Properties of a SettingsProto.
                 * @memberof waiternow.common.SalespersonProto
                 * @interface ISettingsProto
                 * @property {string|null} [code] SettingsProto code
                 */

                /**
                 * Constructs a new SettingsProto.
                 * @memberof waiternow.common.SalespersonProto
                 * @classdesc Represents a SettingsProto.
                 * @implements ISettingsProto
                 * @constructor
                 * @param {waiternow.common.SalespersonProto.ISettingsProto=} [properties] Properties to set
                 */
                function SettingsProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SettingsProto code.
                 * @member {string} code
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @instance
                 */
                SettingsProto.prototype.code = "";

                /**
                 * Creates a new SettingsProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.ISettingsProto=} [properties] Properties to set
                 * @returns {waiternow.common.SalespersonProto.SettingsProto} SettingsProto instance
                 */
                SettingsProto.create = function create(properties) {
                    return new SettingsProto(properties);
                };

                /**
                 * Encodes the specified SettingsProto message. Does not implicitly {@link waiternow.common.SalespersonProto.SettingsProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.code != null && Object.hasOwnProperty.call(message, "code"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.code);
                    return writer;
                };

                /**
                 * Encodes the specified SettingsProto message, length delimited. Does not implicitly {@link waiternow.common.SalespersonProto.SettingsProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.ISettingsProto} message SettingsProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SettingsProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SalespersonProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SalespersonProto.SettingsProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.code = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SettingsProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SalespersonProto.SettingsProto} SettingsProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SettingsProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SettingsProto message.
                 * @function verify
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SettingsProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.code != null && message.hasOwnProperty("code"))
                        if (!$util.isString(message.code))
                            return "code: string expected";
                    return null;
                };

                /**
                 * Creates a SettingsProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SalespersonProto.SettingsProto} SettingsProto
                 */
                SettingsProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SalespersonProto.SettingsProto)
                        return object;
                    var message = new $root.waiternow.common.SalespersonProto.SettingsProto();
                    if (object.code != null)
                        message.code = String(object.code);
                    return message;
                };

                /**
                 * Creates a plain object from a SettingsProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.SettingsProto} message SettingsProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SettingsProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.code = "";
                    if (message.code != null && message.hasOwnProperty("code"))
                        object.code = message.code;
                    return object;
                };

                /**
                 * Converts this SettingsProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SettingsProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SettingsProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.SalespersonProto.SettingsProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SettingsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SalespersonProto.SettingsProto";
                };

                return SettingsProto;
            })();

            SalespersonProto.SalaryProto = (function() {

                /**
                 * Properties of a SalaryProto.
                 * @memberof waiternow.common.SalespersonProto
                 * @interface ISalaryProto
                 * @property {waiternow.common.IMoneyProto|null} [commissionPerPaidOrder] SalaryProto commissionPerPaidOrder
                 */

                /**
                 * Constructs a new SalaryProto.
                 * @memberof waiternow.common.SalespersonProto
                 * @classdesc Represents a SalaryProto.
                 * @implements ISalaryProto
                 * @constructor
                 * @param {waiternow.common.SalespersonProto.ISalaryProto=} [properties] Properties to set
                 */
                function SalaryProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * SalaryProto commissionPerPaidOrder.
                 * @member {waiternow.common.IMoneyProto|null|undefined} commissionPerPaidOrder
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @instance
                 */
                SalaryProto.prototype.commissionPerPaidOrder = null;

                /**
                 * Creates a new SalaryProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.ISalaryProto=} [properties] Properties to set
                 * @returns {waiternow.common.SalespersonProto.SalaryProto} SalaryProto instance
                 */
                SalaryProto.create = function create(properties) {
                    return new SalaryProto(properties);
                };

                /**
                 * Encodes the specified SalaryProto message. Does not implicitly {@link waiternow.common.SalespersonProto.SalaryProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.ISalaryProto} message SalaryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SalaryProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.commissionPerPaidOrder != null && Object.hasOwnProperty.call(message, "commissionPerPaidOrder"))
                        $root.waiternow.common.MoneyProto.encode(message.commissionPerPaidOrder, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified SalaryProto message, length delimited. Does not implicitly {@link waiternow.common.SalespersonProto.SalaryProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.ISalaryProto} message SalaryProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                SalaryProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a SalaryProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SalespersonProto.SalaryProto} SalaryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SalaryProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SalespersonProto.SalaryProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.commissionPerPaidOrder = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a SalaryProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SalespersonProto.SalaryProto} SalaryProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                SalaryProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a SalaryProto message.
                 * @function verify
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                SalaryProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.commissionPerPaidOrder != null && message.hasOwnProperty("commissionPerPaidOrder")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.commissionPerPaidOrder);
                        if (error)
                            return "commissionPerPaidOrder." + error;
                    }
                    return null;
                };

                /**
                 * Creates a SalaryProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SalespersonProto.SalaryProto} SalaryProto
                 */
                SalaryProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SalespersonProto.SalaryProto)
                        return object;
                    var message = new $root.waiternow.common.SalespersonProto.SalaryProto();
                    if (object.commissionPerPaidOrder != null) {
                        if (typeof object.commissionPerPaidOrder !== "object")
                            throw TypeError(".waiternow.common.SalespersonProto.SalaryProto.commissionPerPaidOrder: object expected");
                        message.commissionPerPaidOrder = $root.waiternow.common.MoneyProto.fromObject(object.commissionPerPaidOrder);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a SalaryProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {waiternow.common.SalespersonProto.SalaryProto} message SalaryProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                SalaryProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.commissionPerPaidOrder = null;
                    if (message.commissionPerPaidOrder != null && message.hasOwnProperty("commissionPerPaidOrder"))
                        object.commissionPerPaidOrder = $root.waiternow.common.MoneyProto.toObject(message.commissionPerPaidOrder, options);
                    return object;
                };

                /**
                 * Converts this SalaryProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                SalaryProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for SalaryProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.SalespersonProto.SalaryProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                SalaryProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SalespersonProto.SalaryProto";
                };

                return SalaryProto;
            })();

            return SalespersonProto;
        })();

        common.SalespersonsProto = (function() {

            /**
             * Properties of a SalespersonsProto.
             * @memberof waiternow.common
             * @interface ISalespersonsProto
             * @property {Array.<waiternow.common.ISalespersonProto>|null} [salespersons] SalespersonsProto salespersons
             * @property {string|null} [continuationToken] SalespersonsProto continuationToken
             */

            /**
             * Constructs a new SalespersonsProto.
             * @memberof waiternow.common
             * @classdesc Represents a SalespersonsProto.
             * @implements ISalespersonsProto
             * @constructor
             * @param {waiternow.common.ISalespersonsProto=} [properties] Properties to set
             */
            function SalespersonsProto(properties) {
                this.salespersons = [];
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * SalespersonsProto salespersons.
             * @member {Array.<waiternow.common.ISalespersonProto>} salespersons
             * @memberof waiternow.common.SalespersonsProto
             * @instance
             */
            SalespersonsProto.prototype.salespersons = $util.emptyArray;

            /**
             * SalespersonsProto continuationToken.
             * @member {string} continuationToken
             * @memberof waiternow.common.SalespersonsProto
             * @instance
             */
            SalespersonsProto.prototype.continuationToken = "";

            /**
             * Creates a new SalespersonsProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {waiternow.common.ISalespersonsProto=} [properties] Properties to set
             * @returns {waiternow.common.SalespersonsProto} SalespersonsProto instance
             */
            SalespersonsProto.create = function create(properties) {
                return new SalespersonsProto(properties);
            };

            /**
             * Encodes the specified SalespersonsProto message. Does not implicitly {@link waiternow.common.SalespersonsProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {waiternow.common.ISalespersonsProto} message SalespersonsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SalespersonsProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.salespersons != null && message.salespersons.length)
                    for (var i = 0; i < message.salespersons.length; ++i)
                        $root.waiternow.common.SalespersonProto.encode(message.salespersons[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                    writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                return writer;
            };

            /**
             * Encodes the specified SalespersonsProto message, length delimited. Does not implicitly {@link waiternow.common.SalespersonsProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {waiternow.common.ISalespersonsProto} message SalespersonsProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SalespersonsProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SalespersonsProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SalespersonsProto} SalespersonsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SalespersonsProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SalespersonsProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            if (!(message.salespersons && message.salespersons.length))
                                message.salespersons = [];
                            message.salespersons.push($root.waiternow.common.SalespersonProto.decode(reader, reader.uint32()));
                            break;
                        }
                    case 2: {
                            message.continuationToken = reader.string();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SalespersonsProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SalespersonsProto} SalespersonsProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SalespersonsProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SalespersonsProto message.
             * @function verify
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SalespersonsProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.salespersons != null && message.hasOwnProperty("salespersons")) {
                    if (!Array.isArray(message.salespersons))
                        return "salespersons: array expected";
                    for (var i = 0; i < message.salespersons.length; ++i) {
                        var error = $root.waiternow.common.SalespersonProto.verify(message.salespersons[i]);
                        if (error)
                            return "salespersons." + error;
                    }
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    if (!$util.isString(message.continuationToken))
                        return "continuationToken: string expected";
                return null;
            };

            /**
             * Creates a SalespersonsProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SalespersonsProto} SalespersonsProto
             */
            SalespersonsProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SalespersonsProto)
                    return object;
                var message = new $root.waiternow.common.SalespersonsProto();
                if (object.salespersons) {
                    if (!Array.isArray(object.salespersons))
                        throw TypeError(".waiternow.common.SalespersonsProto.salespersons: array expected");
                    message.salespersons = [];
                    for (var i = 0; i < object.salespersons.length; ++i) {
                        if (typeof object.salespersons[i] !== "object")
                            throw TypeError(".waiternow.common.SalespersonsProto.salespersons: object expected");
                        message.salespersons[i] = $root.waiternow.common.SalespersonProto.fromObject(object.salespersons[i]);
                    }
                }
                if (object.continuationToken != null)
                    message.continuationToken = String(object.continuationToken);
                return message;
            };

            /**
             * Creates a plain object from a SalespersonsProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {waiternow.common.SalespersonsProto} message SalespersonsProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SalespersonsProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.arrays || options.defaults)
                    object.salespersons = [];
                if (options.defaults)
                    object.continuationToken = "";
                if (message.salespersons && message.salespersons.length) {
                    object.salespersons = [];
                    for (var j = 0; j < message.salespersons.length; ++j)
                        object.salespersons[j] = $root.waiternow.common.SalespersonProto.toObject(message.salespersons[j], options);
                }
                if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                    object.continuationToken = message.continuationToken;
                return object;
            };

            /**
             * Converts this SalespersonsProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SalespersonsProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SalespersonsProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SalespersonsProto
             * @function getTypeUrl
             * @memberof waiternow.common.SalespersonsProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SalespersonsProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SalespersonsProto";
            };

            return SalespersonsProto;
        })();

        common.IntRangeProto = (function() {

            /**
             * Properties of an IntRangeProto.
             * @memberof waiternow.common
             * @interface IIntRangeProto
             * @property {number|null} [from] IntRangeProto from
             * @property {number|null} [to] IntRangeProto to
             */

            /**
             * Constructs a new IntRangeProto.
             * @memberof waiternow.common
             * @classdesc Represents an IntRangeProto.
             * @implements IIntRangeProto
             * @constructor
             * @param {waiternow.common.IIntRangeProto=} [properties] Properties to set
             */
            function IntRangeProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * IntRangeProto from.
             * @member {number} from
             * @memberof waiternow.common.IntRangeProto
             * @instance
             */
            IntRangeProto.prototype.from = 0;

            /**
             * IntRangeProto to.
             * @member {number} to
             * @memberof waiternow.common.IntRangeProto
             * @instance
             */
            IntRangeProto.prototype.to = 0;

            /**
             * Creates a new IntRangeProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {waiternow.common.IIntRangeProto=} [properties] Properties to set
             * @returns {waiternow.common.IntRangeProto} IntRangeProto instance
             */
            IntRangeProto.create = function create(properties) {
                return new IntRangeProto(properties);
            };

            /**
             * Encodes the specified IntRangeProto message. Does not implicitly {@link waiternow.common.IntRangeProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {waiternow.common.IIntRangeProto} message IntRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntRangeProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int32(message.from);
                if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.to);
                return writer;
            };

            /**
             * Encodes the specified IntRangeProto message, length delimited. Does not implicitly {@link waiternow.common.IntRangeProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {waiternow.common.IIntRangeProto} message IntRangeProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            IntRangeProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an IntRangeProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.IntRangeProto} IntRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntRangeProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.IntRangeProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.from = reader.int32();
                            break;
                        }
                    case 2: {
                            message.to = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an IntRangeProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.IntRangeProto} IntRangeProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            IntRangeProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an IntRangeProto message.
             * @function verify
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            IntRangeProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.from != null && message.hasOwnProperty("from"))
                    if (!$util.isInteger(message.from))
                        return "from: integer expected";
                if (message.to != null && message.hasOwnProperty("to"))
                    if (!$util.isInteger(message.to))
                        return "to: integer expected";
                return null;
            };

            /**
             * Creates an IntRangeProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.IntRangeProto} IntRangeProto
             */
            IntRangeProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.IntRangeProto)
                    return object;
                var message = new $root.waiternow.common.IntRangeProto();
                if (object.from != null)
                    message.from = object.from | 0;
                if (object.to != null)
                    message.to = object.to | 0;
                return message;
            };

            /**
             * Creates a plain object from an IntRangeProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {waiternow.common.IntRangeProto} message IntRangeProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            IntRangeProto.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.from = 0;
                    object.to = 0;
                }
                if (message.from != null && message.hasOwnProperty("from"))
                    object.from = message.from;
                if (message.to != null && message.hasOwnProperty("to"))
                    object.to = message.to;
                return object;
            };

            /**
             * Converts this IntRangeProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.IntRangeProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            IntRangeProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for IntRangeProto
             * @function getTypeUrl
             * @memberof waiternow.common.IntRangeProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            IntRangeProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.IntRangeProto";
            };

            return IntRangeProto;
        })();

        common.SuperuserUpdateActionProto = (function() {

            /**
             * Properties of a SuperuserUpdateActionProto.
             * @memberof waiternow.common
             * @interface ISuperuserUpdateActionProto
             */

            /**
             * Constructs a new SuperuserUpdateActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SuperuserUpdateActionProto.
             * @implements ISuperuserUpdateActionProto
             * @constructor
             * @param {waiternow.common.ISuperuserUpdateActionProto=} [properties] Properties to set
             */
            function SuperuserUpdateActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SuperuserUpdateActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {waiternow.common.ISuperuserUpdateActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SuperuserUpdateActionProto} SuperuserUpdateActionProto instance
             */
            SuperuserUpdateActionProto.create = function create(properties) {
                return new SuperuserUpdateActionProto(properties);
            };

            /**
             * Encodes the specified SuperuserUpdateActionProto message. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {waiternow.common.ISuperuserUpdateActionProto} message SuperuserUpdateActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SuperuserUpdateActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SuperuserUpdateActionProto message, length delimited. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {waiternow.common.ISuperuserUpdateActionProto} message SuperuserUpdateActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SuperuserUpdateActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SuperuserUpdateActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SuperuserUpdateActionProto} SuperuserUpdateActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SuperuserUpdateActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SuperuserUpdateActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SuperuserUpdateActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SuperuserUpdateActionProto} SuperuserUpdateActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SuperuserUpdateActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SuperuserUpdateActionProto message.
             * @function verify
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SuperuserUpdateActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SuperuserUpdateActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SuperuserUpdateActionProto} SuperuserUpdateActionProto
             */
            SuperuserUpdateActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SuperuserUpdateActionProto)
                    return object;
                return new $root.waiternow.common.SuperuserUpdateActionProto();
            };

            /**
             * Creates a plain object from a SuperuserUpdateActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {waiternow.common.SuperuserUpdateActionProto} message SuperuserUpdateActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SuperuserUpdateActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SuperuserUpdateActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SuperuserUpdateActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SuperuserUpdateActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SuperuserUpdateActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SuperuserUpdateActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SuperuserUpdateActionProto";
            };

            SuperuserUpdateActionProto.UserUpdateProto = (function() {

                /**
                 * Properties of a UserUpdateProto.
                 * @memberof waiternow.common.SuperuserUpdateActionProto
                 * @interface IUserUpdateProto
                 * @property {string|null} [userId] UserUpdateProto userId
                 * @property {string|null} [newEmail] UserUpdateProto newEmail
                 * @property {string|null} [newPassword] UserUpdateProto newPassword
                 */

                /**
                 * Constructs a new UserUpdateProto.
                 * @memberof waiternow.common.SuperuserUpdateActionProto
                 * @classdesc Represents a UserUpdateProto.
                 * @implements IUserUpdateProto
                 * @constructor
                 * @param {waiternow.common.SuperuserUpdateActionProto.IUserUpdateProto=} [properties] Properties to set
                 */
                function UserUpdateProto(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * UserUpdateProto userId.
                 * @member {string} userId
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @instance
                 */
                UserUpdateProto.prototype.userId = "";

                /**
                 * UserUpdateProto newEmail.
                 * @member {string} newEmail
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @instance
                 */
                UserUpdateProto.prototype.newEmail = "";

                /**
                 * UserUpdateProto newPassword.
                 * @member {string} newPassword
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @instance
                 */
                UserUpdateProto.prototype.newPassword = "";

                /**
                 * Creates a new UserUpdateProto instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IUserUpdateProto=} [properties] Properties to set
                 * @returns {waiternow.common.SuperuserUpdateActionProto.UserUpdateProto} UserUpdateProto instance
                 */
                UserUpdateProto.create = function create(properties) {
                    return new UserUpdateProto(properties);
                };

                /**
                 * Encodes the specified UserUpdateProto message. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.UserUpdateProto.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IUserUpdateProto} message UserUpdateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserUpdateProto.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.newEmail != null && Object.hasOwnProperty.call(message, "newEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.newEmail);
                    if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.newPassword);
                    return writer;
                };

                /**
                 * Encodes the specified UserUpdateProto message, length delimited. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.UserUpdateProto.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IUserUpdateProto} message UserUpdateProto message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                UserUpdateProto.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a UserUpdateProto message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SuperuserUpdateActionProto.UserUpdateProto} UserUpdateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserUpdateProto.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.newEmail = reader.string();
                                break;
                            }
                        case 3: {
                                message.newPassword = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a UserUpdateProto message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SuperuserUpdateActionProto.UserUpdateProto} UserUpdateProto
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                UserUpdateProto.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a UserUpdateProto message.
                 * @function verify
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                UserUpdateProto.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                        if (!$util.isString(message.newEmail))
                            return "newEmail: string expected";
                    if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                        if (!$util.isString(message.newPassword))
                            return "newPassword: string expected";
                    return null;
                };

                /**
                 * Creates a UserUpdateProto message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SuperuserUpdateActionProto.UserUpdateProto} UserUpdateProto
                 */
                UserUpdateProto.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto)
                        return object;
                    var message = new $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.newEmail != null)
                        message.newEmail = String(object.newEmail);
                    if (object.newPassword != null)
                        message.newPassword = String(object.newPassword);
                    return message;
                };

                /**
                 * Creates a plain object from a UserUpdateProto message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.UserUpdateProto} message UserUpdateProto
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                UserUpdateProto.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userId = "";
                        object.newEmail = "";
                        object.newPassword = "";
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.newEmail != null && message.hasOwnProperty("newEmail"))
                        object.newEmail = message.newEmail;
                    if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                        object.newPassword = message.newPassword;
                    return object;
                };

                /**
                 * Converts this UserUpdateProto to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                UserUpdateProto.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for UserUpdateProto
                 * @function getTypeUrl
                 * @memberof waiternow.common.SuperuserUpdateActionProto.UserUpdateProto
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                UserUpdateProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SuperuserUpdateActionProto.UserUpdateProto";
                };

                return UserUpdateProto;
            })();

            SuperuserUpdateActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SuperuserUpdateActionProto
                 * @interface IRequest
                 * @property {waiternow.common.SuperuserUpdateActionProto.IUserUpdateProto|null} [userUpdate] Request userUpdate
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SuperuserUpdateActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SuperuserUpdateActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request userUpdate.
                 * @member {waiternow.common.SuperuserUpdateActionProto.IUserUpdateProto|null|undefined} userUpdate
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @instance
                 */
                Request.prototype.userUpdate = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userUpdate != null && Object.hasOwnProperty.call(message, "userUpdate"))
                        $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto.encode(message.userUpdate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SuperuserUpdateActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userUpdate = $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userUpdate != null && message.hasOwnProperty("userUpdate")) {
                        var error = $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto.verify(message.userUpdate);
                        if (error)
                            return "userUpdate." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SuperuserUpdateActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SuperuserUpdateActionProto.Request();
                    if (object.userUpdate != null) {
                        if (typeof object.userUpdate !== "object")
                            throw TypeError(".waiternow.common.SuperuserUpdateActionProto.Request.userUpdate: object expected");
                        message.userUpdate = $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto.fromObject(object.userUpdate);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userUpdate = null;
                    if (message.userUpdate != null && message.hasOwnProperty("userUpdate"))
                        object.userUpdate = $root.waiternow.common.SuperuserUpdateActionProto.UserUpdateProto.toObject(message.userUpdate, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SuperuserUpdateActionProto.Request";
                };

                return Request;
            })();

            SuperuserUpdateActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SuperuserUpdateActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SuperuserUpdateActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SuperuserUpdateActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SuperuserUpdateActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SuperuserUpdateActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SuperuserUpdateActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SuperuserUpdateActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SuperuserUpdateActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SuperuserUpdateActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {waiternow.common.SuperuserUpdateActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SuperuserUpdateActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SuperuserUpdateActionProto.Response";
                };

                return Response;
            })();

            return SuperuserUpdateActionProto;
        })();

        common.SendSmsActionProto = (function() {

            /**
             * Properties of a SendSmsActionProto.
             * @memberof waiternow.common
             * @interface ISendSmsActionProto
             */

            /**
             * Constructs a new SendSmsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SendSmsActionProto.
             * @implements ISendSmsActionProto
             * @constructor
             * @param {waiternow.common.ISendSmsActionProto=} [properties] Properties to set
             */
            function SendSmsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SendSmsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {waiternow.common.ISendSmsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SendSmsActionProto} SendSmsActionProto instance
             */
            SendSmsActionProto.create = function create(properties) {
                return new SendSmsActionProto(properties);
            };

            /**
             * Encodes the specified SendSmsActionProto message. Does not implicitly {@link waiternow.common.SendSmsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {waiternow.common.ISendSmsActionProto} message SendSmsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendSmsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SendSmsActionProto message, length delimited. Does not implicitly {@link waiternow.common.SendSmsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {waiternow.common.ISendSmsActionProto} message SendSmsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendSmsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SendSmsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SendSmsActionProto} SendSmsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendSmsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendSmsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SendSmsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SendSmsActionProto} SendSmsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendSmsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SendSmsActionProto message.
             * @function verify
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SendSmsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SendSmsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SendSmsActionProto} SendSmsActionProto
             */
            SendSmsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SendSmsActionProto)
                    return object;
                return new $root.waiternow.common.SendSmsActionProto();
            };

            /**
             * Creates a plain object from a SendSmsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {waiternow.common.SendSmsActionProto} message SendSmsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendSmsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SendSmsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SendSmsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendSmsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendSmsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SendSmsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendSmsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SendSmsActionProto";
            };

            SendSmsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SendSmsActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IPhoneNumberProto|null} [to] Request to
                 * @property {string|null} [message] Request message
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SendSmsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SendSmsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request to.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} to
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @instance
                 */
                Request.prototype.to = null;

                /**
                 * Request message.
                 * @member {string} message
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @instance
                 */
                Request.prototype.message = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SendSmsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SendSmsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.to, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.message != null && Object.hasOwnProperty.call(message, "message"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.message);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SendSmsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendSmsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendSmsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.to = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.message = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendSmsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.to != null && message.hasOwnProperty("to")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.to);
                        if (error)
                            return "to." + error;
                    }
                    if (message.message != null && message.hasOwnProperty("message"))
                        if (!$util.isString(message.message))
                            return "message: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendSmsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendSmsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SendSmsActionProto.Request();
                    if (object.to != null) {
                        if (typeof object.to !== "object")
                            throw TypeError(".waiternow.common.SendSmsActionProto.Request.to: object expected");
                        message.to = $root.waiternow.common.PhoneNumberProto.fromObject(object.to);
                    }
                    if (object.message != null)
                        message.message = String(object.message);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.to = null;
                        object.message = "";
                    }
                    if (message.to != null && message.hasOwnProperty("to"))
                        object.to = $root.waiternow.common.PhoneNumberProto.toObject(message.to, options);
                    if (message.message != null && message.hasOwnProperty("message"))
                        object.message = message.message;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendSmsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendSmsActionProto.Request";
                };

                return Request;
            })();

            SendSmsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SendSmsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SendSmsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SendSmsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SendSmsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SendSmsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SendSmsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendSmsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendSmsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendSmsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendSmsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendSmsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SendSmsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SendSmsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {waiternow.common.SendSmsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendSmsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendSmsActionProto.Response";
                };

                return Response;
            })();

            return SendSmsActionProto;
        })();

        common.ValidateAddressActionProto = (function() {

            /**
             * Properties of a ValidateAddressActionProto.
             * @memberof waiternow.common
             * @interface IValidateAddressActionProto
             */

            /**
             * Constructs a new ValidateAddressActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a ValidateAddressActionProto.
             * @implements IValidateAddressActionProto
             * @constructor
             * @param {waiternow.common.IValidateAddressActionProto=} [properties] Properties to set
             */
            function ValidateAddressActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ValidateAddressActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {waiternow.common.IValidateAddressActionProto=} [properties] Properties to set
             * @returns {waiternow.common.ValidateAddressActionProto} ValidateAddressActionProto instance
             */
            ValidateAddressActionProto.create = function create(properties) {
                return new ValidateAddressActionProto(properties);
            };

            /**
             * Encodes the specified ValidateAddressActionProto message. Does not implicitly {@link waiternow.common.ValidateAddressActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {waiternow.common.IValidateAddressActionProto} message ValidateAddressActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateAddressActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ValidateAddressActionProto message, length delimited. Does not implicitly {@link waiternow.common.ValidateAddressActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {waiternow.common.IValidateAddressActionProto} message ValidateAddressActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateAddressActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ValidateAddressActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ValidateAddressActionProto} ValidateAddressActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateAddressActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidateAddressActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ValidateAddressActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ValidateAddressActionProto} ValidateAddressActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateAddressActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ValidateAddressActionProto message.
             * @function verify
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ValidateAddressActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ValidateAddressActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ValidateAddressActionProto} ValidateAddressActionProto
             */
            ValidateAddressActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ValidateAddressActionProto)
                    return object;
                return new $root.waiternow.common.ValidateAddressActionProto();
            };

            /**
             * Creates a plain object from a ValidateAddressActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {waiternow.common.ValidateAddressActionProto} message ValidateAddressActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ValidateAddressActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ValidateAddressActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ValidateAddressActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ValidateAddressActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ValidateAddressActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.ValidateAddressActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ValidateAddressActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ValidateAddressActionProto";
            };

            ValidateAddressActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.ValidateAddressActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IAddressProto|null} [address] Request address
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.ValidateAddressActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.ValidateAddressActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request address.
                 * @member {waiternow.common.IAddressProto|null|undefined} address
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @instance
                 */
                Request.prototype.address = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.ValidateAddressActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.ValidateAddressActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.address != null && Object.hasOwnProperty.call(message, "address"))
                        $root.waiternow.common.AddressProto.encode(message.address, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.ValidateAddressActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ValidateAddressActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidateAddressActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.address = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ValidateAddressActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.address != null && message.hasOwnProperty("address")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.address);
                        if (error)
                            return "address." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ValidateAddressActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ValidateAddressActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.ValidateAddressActionProto.Request();
                    if (object.address != null) {
                        if (typeof object.address !== "object")
                            throw TypeError(".waiternow.common.ValidateAddressActionProto.Request.address: object expected");
                        message.address = $root.waiternow.common.AddressProto.fromObject(object.address);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.address = null;
                    if (message.address != null && message.hasOwnProperty("address"))
                        object.address = $root.waiternow.common.AddressProto.toObject(message.address, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.ValidateAddressActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ValidateAddressActionProto.Request";
                };

                return Request;
            })();

            ValidateAddressActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.ValidateAddressActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {boolean|null} [isAddressValid] Response isAddressValid
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.ValidateAddressActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.ValidateAddressActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response isAddressValid.
                 * @member {boolean} isAddressValid
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @instance
                 */
                Response.prototype.isAddressValid = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.ValidateAddressActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.ValidateAddressActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.isAddressValid != null && Object.hasOwnProperty.call(message, "isAddressValid"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isAddressValid);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.ValidateAddressActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ValidateAddressActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidateAddressActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.isAddressValid = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ValidateAddressActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.isAddressValid != null && message.hasOwnProperty("isAddressValid"))
                        if (typeof message.isAddressValid !== "boolean")
                            return "isAddressValid: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ValidateAddressActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ValidateAddressActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.ValidateAddressActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.ValidateAddressActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.isAddressValid != null)
                        message.isAddressValid = Boolean(object.isAddressValid);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateAddressActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.isAddressValid = false;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.isAddressValid != null && message.hasOwnProperty("isAddressValid"))
                        object.isAddressValid = message.isAddressValid;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.ValidateAddressActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ValidateAddressActionProto.Response";
                };

                return Response;
            })();

            return ValidateAddressActionProto;
        })();

        common.CalculateGeoDistanceActionProto = (function() {

            /**
             * Properties of a CalculateGeoDistanceActionProto.
             * @memberof waiternow.common
             * @interface ICalculateGeoDistanceActionProto
             */

            /**
             * Constructs a new CalculateGeoDistanceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CalculateGeoDistanceActionProto.
             * @implements ICalculateGeoDistanceActionProto
             * @constructor
             * @param {waiternow.common.ICalculateGeoDistanceActionProto=} [properties] Properties to set
             */
            function CalculateGeoDistanceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CalculateGeoDistanceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {waiternow.common.ICalculateGeoDistanceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CalculateGeoDistanceActionProto} CalculateGeoDistanceActionProto instance
             */
            CalculateGeoDistanceActionProto.create = function create(properties) {
                return new CalculateGeoDistanceActionProto(properties);
            };

            /**
             * Encodes the specified CalculateGeoDistanceActionProto message. Does not implicitly {@link waiternow.common.CalculateGeoDistanceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {waiternow.common.ICalculateGeoDistanceActionProto} message CalculateGeoDistanceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CalculateGeoDistanceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CalculateGeoDistanceActionProto message, length delimited. Does not implicitly {@link waiternow.common.CalculateGeoDistanceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {waiternow.common.ICalculateGeoDistanceActionProto} message CalculateGeoDistanceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CalculateGeoDistanceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CalculateGeoDistanceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CalculateGeoDistanceActionProto} CalculateGeoDistanceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CalculateGeoDistanceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CalculateGeoDistanceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CalculateGeoDistanceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CalculateGeoDistanceActionProto} CalculateGeoDistanceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CalculateGeoDistanceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CalculateGeoDistanceActionProto message.
             * @function verify
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CalculateGeoDistanceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CalculateGeoDistanceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CalculateGeoDistanceActionProto} CalculateGeoDistanceActionProto
             */
            CalculateGeoDistanceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CalculateGeoDistanceActionProto)
                    return object;
                return new $root.waiternow.common.CalculateGeoDistanceActionProto();
            };

            /**
             * Creates a plain object from a CalculateGeoDistanceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {waiternow.common.CalculateGeoDistanceActionProto} message CalculateGeoDistanceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CalculateGeoDistanceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CalculateGeoDistanceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CalculateGeoDistanceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CalculateGeoDistanceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CalculateGeoDistanceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CalculateGeoDistanceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CalculateGeoDistanceActionProto";
            };

            CalculateGeoDistanceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IAddressProto|null} [from] Request from
                 * @property {waiternow.common.IAddressProto|null} [to] Request to
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request from.
                 * @member {waiternow.common.IAddressProto|null|undefined} from
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @instance
                 */
                Request.prototype.from = null;

                /**
                 * Request to.
                 * @member {waiternow.common.IAddressProto|null|undefined} to
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @instance
                 */
                Request.prototype.to = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CalculateGeoDistanceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.from != null && Object.hasOwnProperty.call(message, "from"))
                        $root.waiternow.common.AddressProto.encode(message.from, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.to != null && Object.hasOwnProperty.call(message, "to"))
                        $root.waiternow.common.AddressProto.encode(message.to, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CalculateGeoDistanceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CalculateGeoDistanceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.from = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.to = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.from != null && message.hasOwnProperty("from")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.from);
                        if (error)
                            return "from." + error;
                    }
                    if (message.to != null && message.hasOwnProperty("to")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.to);
                        if (error)
                            return "to." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CalculateGeoDistanceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CalculateGeoDistanceActionProto.Request();
                    if (object.from != null) {
                        if (typeof object.from !== "object")
                            throw TypeError(".waiternow.common.CalculateGeoDistanceActionProto.Request.from: object expected");
                        message.from = $root.waiternow.common.AddressProto.fromObject(object.from);
                    }
                    if (object.to != null) {
                        if (typeof object.to !== "object")
                            throw TypeError(".waiternow.common.CalculateGeoDistanceActionProto.Request.to: object expected");
                        message.to = $root.waiternow.common.AddressProto.fromObject(object.to);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.from = null;
                        object.to = null;
                    }
                    if (message.from != null && message.hasOwnProperty("from"))
                        object.from = $root.waiternow.common.AddressProto.toObject(message.from, options);
                    if (message.to != null && message.hasOwnProperty("to"))
                        object.to = $root.waiternow.common.AddressProto.toObject(message.to, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CalculateGeoDistanceActionProto.Request";
                };

                return Request;
            })();

            CalculateGeoDistanceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IGeoDistanceProto|null} [geoDistance] Response geoDistance
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response geoDistance.
                 * @member {waiternow.common.IGeoDistanceProto|null|undefined} geoDistance
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @instance
                 */
                Response.prototype.geoDistance = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CalculateGeoDistanceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.geoDistance != null && Object.hasOwnProperty.call(message, "geoDistance"))
                        $root.waiternow.common.GeoDistanceProto.encode(message.geoDistance, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CalculateGeoDistanceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CalculateGeoDistanceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.geoDistance = $root.waiternow.common.GeoDistanceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.geoDistance != null && message.hasOwnProperty("geoDistance")) {
                        var error = $root.waiternow.common.GeoDistanceProto.verify(message.geoDistance);
                        if (error)
                            return "geoDistance." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CalculateGeoDistanceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CalculateGeoDistanceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CalculateGeoDistanceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CalculateGeoDistanceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.geoDistance != null) {
                        if (typeof object.geoDistance !== "object")
                            throw TypeError(".waiternow.common.CalculateGeoDistanceActionProto.Response.geoDistance: object expected");
                        message.geoDistance = $root.waiternow.common.GeoDistanceProto.fromObject(object.geoDistance);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {waiternow.common.CalculateGeoDistanceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.geoDistance = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.geoDistance != null && message.hasOwnProperty("geoDistance"))
                        object.geoDistance = $root.waiternow.common.GeoDistanceProto.toObject(message.geoDistance, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CalculateGeoDistanceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CalculateGeoDistanceActionProto.Response";
                };

                return Response;
            })();

            return CalculateGeoDistanceActionProto;
        })();

        common.TerminateDeviceSessionRequestActionProto = (function() {

            /**
             * Properties of a TerminateDeviceSessionRequestActionProto.
             * @memberof waiternow.common
             * @interface ITerminateDeviceSessionRequestActionProto
             */

            /**
             * Constructs a new TerminateDeviceSessionRequestActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a TerminateDeviceSessionRequestActionProto.
             * @implements ITerminateDeviceSessionRequestActionProto
             * @constructor
             * @param {waiternow.common.ITerminateDeviceSessionRequestActionProto=} [properties] Properties to set
             */
            function TerminateDeviceSessionRequestActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new TerminateDeviceSessionRequestActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {waiternow.common.ITerminateDeviceSessionRequestActionProto=} [properties] Properties to set
             * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto} TerminateDeviceSessionRequestActionProto instance
             */
            TerminateDeviceSessionRequestActionProto.create = function create(properties) {
                return new TerminateDeviceSessionRequestActionProto(properties);
            };

            /**
             * Encodes the specified TerminateDeviceSessionRequestActionProto message. Does not implicitly {@link waiternow.common.TerminateDeviceSessionRequestActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {waiternow.common.ITerminateDeviceSessionRequestActionProto} message TerminateDeviceSessionRequestActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TerminateDeviceSessionRequestActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified TerminateDeviceSessionRequestActionProto message, length delimited. Does not implicitly {@link waiternow.common.TerminateDeviceSessionRequestActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {waiternow.common.ITerminateDeviceSessionRequestActionProto} message TerminateDeviceSessionRequestActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            TerminateDeviceSessionRequestActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a TerminateDeviceSessionRequestActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto} TerminateDeviceSessionRequestActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TerminateDeviceSessionRequestActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TerminateDeviceSessionRequestActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a TerminateDeviceSessionRequestActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto} TerminateDeviceSessionRequestActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            TerminateDeviceSessionRequestActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a TerminateDeviceSessionRequestActionProto message.
             * @function verify
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            TerminateDeviceSessionRequestActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a TerminateDeviceSessionRequestActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto} TerminateDeviceSessionRequestActionProto
             */
            TerminateDeviceSessionRequestActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.TerminateDeviceSessionRequestActionProto)
                    return object;
                return new $root.waiternow.common.TerminateDeviceSessionRequestActionProto();
            };

            /**
             * Creates a plain object from a TerminateDeviceSessionRequestActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {waiternow.common.TerminateDeviceSessionRequestActionProto} message TerminateDeviceSessionRequestActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            TerminateDeviceSessionRequestActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this TerminateDeviceSessionRequestActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            TerminateDeviceSessionRequestActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for TerminateDeviceSessionRequestActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            TerminateDeviceSessionRequestActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.TerminateDeviceSessionRequestActionProto";
            };

            TerminateDeviceSessionRequestActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
                 * @interface IRequest
                 * @property {string|null} [deviceId] Request deviceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request deviceId.
                 * @member {string} deviceId
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @instance
                 */
                Request.prototype.deviceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.TerminateDeviceSessionRequestActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.deviceId != null && Object.hasOwnProperty.call(message, "deviceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.deviceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.TerminateDeviceSessionRequestActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TerminateDeviceSessionRequestActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.deviceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        if (!$util.isString(message.deviceId))
                            return "deviceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.TerminateDeviceSessionRequestActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.TerminateDeviceSessionRequestActionProto.Request();
                    if (object.deviceId != null)
                        message.deviceId = String(object.deviceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.deviceId = "";
                    if (message.deviceId != null && message.hasOwnProperty("deviceId"))
                        object.deviceId = message.deviceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.TerminateDeviceSessionRequestActionProto.Request";
                };

                return Request;
            })();

            TerminateDeviceSessionRequestActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.TerminateDeviceSessionRequestActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.TerminateDeviceSessionRequestActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.TerminateDeviceSessionRequestActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.TerminateDeviceSessionRequestActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.TerminateDeviceSessionRequestActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.TerminateDeviceSessionRequestActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.TerminateDeviceSessionRequestActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.TerminateDeviceSessionRequestActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.TerminateDeviceSessionRequestActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.TerminateDeviceSessionRequestActionProto.Response";
                };

                return Response;
            })();

            return TerminateDeviceSessionRequestActionProto;
        })();

        common.GetEmailVerificationCodeActionProto = (function() {

            /**
             * Properties of a GetEmailVerificationCodeActionProto.
             * @memberof waiternow.common
             * @interface IGetEmailVerificationCodeActionProto
             */

            /**
             * Constructs a new GetEmailVerificationCodeActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetEmailVerificationCodeActionProto.
             * @implements IGetEmailVerificationCodeActionProto
             * @constructor
             * @param {waiternow.common.IGetEmailVerificationCodeActionProto=} [properties] Properties to set
             */
            function GetEmailVerificationCodeActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetEmailVerificationCodeActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IGetEmailVerificationCodeActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetEmailVerificationCodeActionProto} GetEmailVerificationCodeActionProto instance
             */
            GetEmailVerificationCodeActionProto.create = function create(properties) {
                return new GetEmailVerificationCodeActionProto(properties);
            };

            /**
             * Encodes the specified GetEmailVerificationCodeActionProto message. Does not implicitly {@link waiternow.common.GetEmailVerificationCodeActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IGetEmailVerificationCodeActionProto} message GetEmailVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetEmailVerificationCodeActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetEmailVerificationCodeActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetEmailVerificationCodeActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IGetEmailVerificationCodeActionProto} message GetEmailVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetEmailVerificationCodeActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetEmailVerificationCodeActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetEmailVerificationCodeActionProto} GetEmailVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetEmailVerificationCodeActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetEmailVerificationCodeActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetEmailVerificationCodeActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetEmailVerificationCodeActionProto} GetEmailVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetEmailVerificationCodeActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetEmailVerificationCodeActionProto message.
             * @function verify
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetEmailVerificationCodeActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetEmailVerificationCodeActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetEmailVerificationCodeActionProto} GetEmailVerificationCodeActionProto
             */
            GetEmailVerificationCodeActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetEmailVerificationCodeActionProto)
                    return object;
                return new $root.waiternow.common.GetEmailVerificationCodeActionProto();
            };

            /**
             * Creates a plain object from a GetEmailVerificationCodeActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.GetEmailVerificationCodeActionProto} message GetEmailVerificationCodeActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetEmailVerificationCodeActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetEmailVerificationCodeActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetEmailVerificationCodeActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetEmailVerificationCodeActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetEmailVerificationCodeActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetEmailVerificationCodeActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetEmailVerificationCodeActionProto";
            };

            GetEmailVerificationCodeActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto
                 * @interface IRequest
                 * @property {string|null} [email] Request email
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request email.
                 * @member {string} email
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.email = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetEmailVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetEmailVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetEmailVerificationCodeActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetEmailVerificationCodeActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetEmailVerificationCodeActionProto.Request();
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.email = "";
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetEmailVerificationCodeActionProto.Request";
                };

                return Request;
            })();

            GetEmailVerificationCodeActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {string|null} [verificationCode] Response verificationCode
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response verificationCode.
                 * @member {string} verificationCode
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.verificationCode = "";

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetEmailVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.verificationCode != null && Object.hasOwnProperty.call(message, "verificationCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.verificationCode);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetEmailVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetEmailVerificationCodeActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.verificationCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        if (!$util.isString(message.verificationCode))
                            return "verificationCode: string expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetEmailVerificationCodeActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetEmailVerificationCodeActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetEmailVerificationCodeActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetEmailVerificationCodeActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.verificationCode != null)
                        message.verificationCode = String(object.verificationCode);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetEmailVerificationCodeActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.verificationCode = "";
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        object.verificationCode = message.verificationCode;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetEmailVerificationCodeActionProto.Response";
                };

                return Response;
            })();

            return GetEmailVerificationCodeActionProto;
        })();

        common.GetPhoneNumberVerificationCodeActionProto = (function() {

            /**
             * Properties of a GetPhoneNumberVerificationCodeActionProto.
             * @memberof waiternow.common
             * @interface IGetPhoneNumberVerificationCodeActionProto
             */

            /**
             * Constructs a new GetPhoneNumberVerificationCodeActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetPhoneNumberVerificationCodeActionProto.
             * @implements IGetPhoneNumberVerificationCodeActionProto
             * @constructor
             * @param {waiternow.common.IGetPhoneNumberVerificationCodeActionProto=} [properties] Properties to set
             */
            function GetPhoneNumberVerificationCodeActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetPhoneNumberVerificationCodeActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IGetPhoneNumberVerificationCodeActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto} GetPhoneNumberVerificationCodeActionProto instance
             */
            GetPhoneNumberVerificationCodeActionProto.create = function create(properties) {
                return new GetPhoneNumberVerificationCodeActionProto(properties);
            };

            /**
             * Encodes the specified GetPhoneNumberVerificationCodeActionProto message. Does not implicitly {@link waiternow.common.GetPhoneNumberVerificationCodeActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IGetPhoneNumberVerificationCodeActionProto} message GetPhoneNumberVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPhoneNumberVerificationCodeActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetPhoneNumberVerificationCodeActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetPhoneNumberVerificationCodeActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IGetPhoneNumberVerificationCodeActionProto} message GetPhoneNumberVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPhoneNumberVerificationCodeActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPhoneNumberVerificationCodeActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto} GetPhoneNumberVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPhoneNumberVerificationCodeActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPhoneNumberVerificationCodeActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto} GetPhoneNumberVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPhoneNumberVerificationCodeActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPhoneNumberVerificationCodeActionProto message.
             * @function verify
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPhoneNumberVerificationCodeActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetPhoneNumberVerificationCodeActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto} GetPhoneNumberVerificationCodeActionProto
             */
            GetPhoneNumberVerificationCodeActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto)
                    return object;
                return new $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto();
            };

            /**
             * Creates a plain object from a GetPhoneNumberVerificationCodeActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto} message GetPhoneNumberVerificationCodeActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPhoneNumberVerificationCodeActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetPhoneNumberVerificationCodeActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPhoneNumberVerificationCodeActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPhoneNumberVerificationCodeActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPhoneNumberVerificationCodeActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetPhoneNumberVerificationCodeActionProto";
            };

            GetPhoneNumberVerificationCodeActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] Request phoneNumber
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request phoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumber = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request();
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request.phoneNumber: object expected");
                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.phoneNumber = null;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetPhoneNumberVerificationCodeActionProto.Request";
                };

                return Request;
            })();

            GetPhoneNumberVerificationCodeActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {string|null} [verificationCode] Response verificationCode
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response verificationCode.
                 * @member {string} verificationCode
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.verificationCode = "";

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.verificationCode != null && Object.hasOwnProperty.call(message, "verificationCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.verificationCode);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.verificationCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        if (!$util.isString(message.verificationCode))
                            return "verificationCode: string expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.verificationCode != null)
                        message.verificationCode = String(object.verificationCode);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.verificationCode = "";
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        object.verificationCode = message.verificationCode;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetPhoneNumberVerificationCodeActionProto.Response";
                };

                return Response;
            })();

            return GetPhoneNumberVerificationCodeActionProto;
        })();

        common.SendEmailVerificationCodeActionProto = (function() {

            /**
             * Properties of a SendEmailVerificationCodeActionProto.
             * @memberof waiternow.common
             * @interface ISendEmailVerificationCodeActionProto
             */

            /**
             * Constructs a new SendEmailVerificationCodeActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SendEmailVerificationCodeActionProto.
             * @implements ISendEmailVerificationCodeActionProto
             * @constructor
             * @param {waiternow.common.ISendEmailVerificationCodeActionProto=} [properties] Properties to set
             */
            function SendEmailVerificationCodeActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SendEmailVerificationCodeActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ISendEmailVerificationCodeActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SendEmailVerificationCodeActionProto} SendEmailVerificationCodeActionProto instance
             */
            SendEmailVerificationCodeActionProto.create = function create(properties) {
                return new SendEmailVerificationCodeActionProto(properties);
            };

            /**
             * Encodes the specified SendEmailVerificationCodeActionProto message. Does not implicitly {@link waiternow.common.SendEmailVerificationCodeActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ISendEmailVerificationCodeActionProto} message SendEmailVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailVerificationCodeActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SendEmailVerificationCodeActionProto message, length delimited. Does not implicitly {@link waiternow.common.SendEmailVerificationCodeActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ISendEmailVerificationCodeActionProto} message SendEmailVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendEmailVerificationCodeActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SendEmailVerificationCodeActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SendEmailVerificationCodeActionProto} SendEmailVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailVerificationCodeActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailVerificationCodeActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SendEmailVerificationCodeActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SendEmailVerificationCodeActionProto} SendEmailVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendEmailVerificationCodeActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SendEmailVerificationCodeActionProto message.
             * @function verify
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SendEmailVerificationCodeActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SendEmailVerificationCodeActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SendEmailVerificationCodeActionProto} SendEmailVerificationCodeActionProto
             */
            SendEmailVerificationCodeActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SendEmailVerificationCodeActionProto)
                    return object;
                return new $root.waiternow.common.SendEmailVerificationCodeActionProto();
            };

            /**
             * Creates a plain object from a SendEmailVerificationCodeActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.SendEmailVerificationCodeActionProto} message SendEmailVerificationCodeActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendEmailVerificationCodeActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SendEmailVerificationCodeActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendEmailVerificationCodeActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendEmailVerificationCodeActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SendEmailVerificationCodeActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendEmailVerificationCodeActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SendEmailVerificationCodeActionProto";
            };

            SendEmailVerificationCodeActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto
                 * @interface IRequest
                 * @property {string|null} [email] Request email
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request email.
                 * @member {string} email
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.email = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SendEmailVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SendEmailVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailVerificationCodeActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailVerificationCodeActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SendEmailVerificationCodeActionProto.Request();
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.email = "";
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailVerificationCodeActionProto.Request";
                };

                return Request;
            })();

            SendEmailVerificationCodeActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SendEmailVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SendEmailVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendEmailVerificationCodeActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendEmailVerificationCodeActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendEmailVerificationCodeActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SendEmailVerificationCodeActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SendEmailVerificationCodeActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendEmailVerificationCodeActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendEmailVerificationCodeActionProto.Response";
                };

                return Response;
            })();

            return SendEmailVerificationCodeActionProto;
        })();

        common.ValidateEmailVerificationCodeActionProto = (function() {

            /**
             * Properties of a ValidateEmailVerificationCodeActionProto.
             * @memberof waiternow.common
             * @interface IValidateEmailVerificationCodeActionProto
             */

            /**
             * Constructs a new ValidateEmailVerificationCodeActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a ValidateEmailVerificationCodeActionProto.
             * @implements IValidateEmailVerificationCodeActionProto
             * @constructor
             * @param {waiternow.common.IValidateEmailVerificationCodeActionProto=} [properties] Properties to set
             */
            function ValidateEmailVerificationCodeActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ValidateEmailVerificationCodeActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IValidateEmailVerificationCodeActionProto=} [properties] Properties to set
             * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto} ValidateEmailVerificationCodeActionProto instance
             */
            ValidateEmailVerificationCodeActionProto.create = function create(properties) {
                return new ValidateEmailVerificationCodeActionProto(properties);
            };

            /**
             * Encodes the specified ValidateEmailVerificationCodeActionProto message. Does not implicitly {@link waiternow.common.ValidateEmailVerificationCodeActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IValidateEmailVerificationCodeActionProto} message ValidateEmailVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateEmailVerificationCodeActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ValidateEmailVerificationCodeActionProto message, length delimited. Does not implicitly {@link waiternow.common.ValidateEmailVerificationCodeActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IValidateEmailVerificationCodeActionProto} message ValidateEmailVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidateEmailVerificationCodeActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ValidateEmailVerificationCodeActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto} ValidateEmailVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateEmailVerificationCodeActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidateEmailVerificationCodeActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ValidateEmailVerificationCodeActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto} ValidateEmailVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidateEmailVerificationCodeActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ValidateEmailVerificationCodeActionProto message.
             * @function verify
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ValidateEmailVerificationCodeActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ValidateEmailVerificationCodeActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto} ValidateEmailVerificationCodeActionProto
             */
            ValidateEmailVerificationCodeActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ValidateEmailVerificationCodeActionProto)
                    return object;
                return new $root.waiternow.common.ValidateEmailVerificationCodeActionProto();
            };

            /**
             * Creates a plain object from a ValidateEmailVerificationCodeActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ValidateEmailVerificationCodeActionProto} message ValidateEmailVerificationCodeActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ValidateEmailVerificationCodeActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ValidateEmailVerificationCodeActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ValidateEmailVerificationCodeActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ValidateEmailVerificationCodeActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ValidateEmailVerificationCodeActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ValidateEmailVerificationCodeActionProto";
            };

            ValidateEmailVerificationCodeActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
                 * @interface IRequest
                 * @property {string|null} [email] Request email
                 * @property {string|null} [verificationCode] Request verificationCode
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request email.
                 * @member {string} email
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.email = "";

                /**
                 * Request verificationCode.
                 * @member {string} verificationCode
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.verificationCode = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.ValidateEmailVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.verificationCode != null && Object.hasOwnProperty.call(message, "verificationCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.verificationCode);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.ValidateEmailVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidateEmailVerificationCodeActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        case 2: {
                                message.verificationCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        if (!$util.isString(message.verificationCode))
                            return "verificationCode: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ValidateEmailVerificationCodeActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.ValidateEmailVerificationCodeActionProto.Request();
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.verificationCode != null)
                        message.verificationCode = String(object.verificationCode);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.verificationCode = "";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        object.verificationCode = message.verificationCode;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ValidateEmailVerificationCodeActionProto.Request";
                };

                return Request;
            })();

            ValidateEmailVerificationCodeActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {boolean|null} [isVerificationCodeValid] Response isVerificationCodeValid
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response isVerificationCodeValid.
                 * @member {boolean} isVerificationCodeValid
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.isVerificationCodeValid = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.ValidateEmailVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.isVerificationCodeValid != null && Object.hasOwnProperty.call(message, "isVerificationCodeValid"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isVerificationCodeValid);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.ValidateEmailVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidateEmailVerificationCodeActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.isVerificationCodeValid = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.isVerificationCodeValid != null && message.hasOwnProperty("isVerificationCodeValid"))
                        if (typeof message.isVerificationCodeValid !== "boolean")
                            return "isVerificationCodeValid: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ValidateEmailVerificationCodeActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ValidateEmailVerificationCodeActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.ValidateEmailVerificationCodeActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.ValidateEmailVerificationCodeActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.isVerificationCodeValid != null)
                        message.isVerificationCodeValid = Boolean(object.isVerificationCodeValid);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidateEmailVerificationCodeActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.isVerificationCodeValid = false;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.isVerificationCodeValid != null && message.hasOwnProperty("isVerificationCodeValid"))
                        object.isVerificationCodeValid = message.isVerificationCodeValid;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.ValidateEmailVerificationCodeActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ValidateEmailVerificationCodeActionProto.Response";
                };

                return Response;
            })();

            return ValidateEmailVerificationCodeActionProto;
        })();

        common.SendPhoneNumberVerificationCodeActionProto = (function() {

            /**
             * Properties of a SendPhoneNumberVerificationCodeActionProto.
             * @memberof waiternow.common
             * @interface ISendPhoneNumberVerificationCodeActionProto
             */

            /**
             * Constructs a new SendPhoneNumberVerificationCodeActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SendPhoneNumberVerificationCodeActionProto.
             * @implements ISendPhoneNumberVerificationCodeActionProto
             * @constructor
             * @param {waiternow.common.ISendPhoneNumberVerificationCodeActionProto=} [properties] Properties to set
             */
            function SendPhoneNumberVerificationCodeActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SendPhoneNumberVerificationCodeActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ISendPhoneNumberVerificationCodeActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto} SendPhoneNumberVerificationCodeActionProto instance
             */
            SendPhoneNumberVerificationCodeActionProto.create = function create(properties) {
                return new SendPhoneNumberVerificationCodeActionProto(properties);
            };

            /**
             * Encodes the specified SendPhoneNumberVerificationCodeActionProto message. Does not implicitly {@link waiternow.common.SendPhoneNumberVerificationCodeActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ISendPhoneNumberVerificationCodeActionProto} message SendPhoneNumberVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendPhoneNumberVerificationCodeActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SendPhoneNumberVerificationCodeActionProto message, length delimited. Does not implicitly {@link waiternow.common.SendPhoneNumberVerificationCodeActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ISendPhoneNumberVerificationCodeActionProto} message SendPhoneNumberVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SendPhoneNumberVerificationCodeActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SendPhoneNumberVerificationCodeActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto} SendPhoneNumberVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendPhoneNumberVerificationCodeActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SendPhoneNumberVerificationCodeActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto} SendPhoneNumberVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SendPhoneNumberVerificationCodeActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SendPhoneNumberVerificationCodeActionProto message.
             * @function verify
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SendPhoneNumberVerificationCodeActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SendPhoneNumberVerificationCodeActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto} SendPhoneNumberVerificationCodeActionProto
             */
            SendPhoneNumberVerificationCodeActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto)
                    return object;
                return new $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto();
            };

            /**
             * Creates a plain object from a SendPhoneNumberVerificationCodeActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto} message SendPhoneNumberVerificationCodeActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SendPhoneNumberVerificationCodeActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SendPhoneNumberVerificationCodeActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SendPhoneNumberVerificationCodeActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SendPhoneNumberVerificationCodeActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SendPhoneNumberVerificationCodeActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SendPhoneNumberVerificationCodeActionProto";
            };

            SendPhoneNumberVerificationCodeActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] Request phoneNumber
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request phoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumber = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request();
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request.phoneNumber: object expected");
                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.phoneNumber = null;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendPhoneNumberVerificationCodeActionProto.Request";
                };

                return Request;
            })();

            SendPhoneNumberVerificationCodeActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SendPhoneNumberVerificationCodeActionProto.Response";
                };

                return Response;
            })();

            return SendPhoneNumberVerificationCodeActionProto;
        })();

        common.ValidatePhoneNumberVerificationCodeActionProto = (function() {

            /**
             * Properties of a ValidatePhoneNumberVerificationCodeActionProto.
             * @memberof waiternow.common
             * @interface IValidatePhoneNumberVerificationCodeActionProto
             */

            /**
             * Constructs a new ValidatePhoneNumberVerificationCodeActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a ValidatePhoneNumberVerificationCodeActionProto.
             * @implements IValidatePhoneNumberVerificationCodeActionProto
             * @constructor
             * @param {waiternow.common.IValidatePhoneNumberVerificationCodeActionProto=} [properties] Properties to set
             */
            function ValidatePhoneNumberVerificationCodeActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ValidatePhoneNumberVerificationCodeActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IValidatePhoneNumberVerificationCodeActionProto=} [properties] Properties to set
             * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto} ValidatePhoneNumberVerificationCodeActionProto instance
             */
            ValidatePhoneNumberVerificationCodeActionProto.create = function create(properties) {
                return new ValidatePhoneNumberVerificationCodeActionProto(properties);
            };

            /**
             * Encodes the specified ValidatePhoneNumberVerificationCodeActionProto message. Does not implicitly {@link waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IValidatePhoneNumberVerificationCodeActionProto} message ValidatePhoneNumberVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidatePhoneNumberVerificationCodeActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ValidatePhoneNumberVerificationCodeActionProto message, length delimited. Does not implicitly {@link waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.IValidatePhoneNumberVerificationCodeActionProto} message ValidatePhoneNumberVerificationCodeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ValidatePhoneNumberVerificationCodeActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ValidatePhoneNumberVerificationCodeActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto} ValidatePhoneNumberVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidatePhoneNumberVerificationCodeActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ValidatePhoneNumberVerificationCodeActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto} ValidatePhoneNumberVerificationCodeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ValidatePhoneNumberVerificationCodeActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ValidatePhoneNumberVerificationCodeActionProto message.
             * @function verify
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ValidatePhoneNumberVerificationCodeActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ValidatePhoneNumberVerificationCodeActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto} ValidatePhoneNumberVerificationCodeActionProto
             */
            ValidatePhoneNumberVerificationCodeActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto)
                    return object;
                return new $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto();
            };

            /**
             * Creates a plain object from a ValidatePhoneNumberVerificationCodeActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto} message ValidatePhoneNumberVerificationCodeActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ValidatePhoneNumberVerificationCodeActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ValidatePhoneNumberVerificationCodeActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ValidatePhoneNumberVerificationCodeActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ValidatePhoneNumberVerificationCodeActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ValidatePhoneNumberVerificationCodeActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ValidatePhoneNumberVerificationCodeActionProto";
            };

            ValidatePhoneNumberVerificationCodeActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] Request phoneNumber
                 * @property {string|null} [verificationCode] Request verificationCode
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request phoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumber = null;

                /**
                 * Request verificationCode.
                 * @member {string} verificationCode
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @instance
                 */
                Request.prototype.verificationCode = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.verificationCode != null && Object.hasOwnProperty.call(message, "verificationCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.verificationCode);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.verificationCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        if (!$util.isString(message.verificationCode))
                            return "verificationCode: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request();
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request.phoneNumber: object expected");
                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                    }
                    if (object.verificationCode != null)
                        message.verificationCode = String(object.verificationCode);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.phoneNumber = null;
                        object.verificationCode = "";
                    }
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                    if (message.verificationCode != null && message.hasOwnProperty("verificationCode"))
                        object.verificationCode = message.verificationCode;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Request";
                };

                return Request;
            })();

            ValidatePhoneNumberVerificationCodeActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {boolean|null} [isVerificationCodeValid] Response isVerificationCodeValid
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response isVerificationCodeValid.
                 * @member {boolean} isVerificationCodeValid
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 */
                Response.prototype.isVerificationCodeValid = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.isVerificationCodeValid != null && Object.hasOwnProperty.call(message, "isVerificationCodeValid"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isVerificationCodeValid);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.isVerificationCodeValid = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.isVerificationCodeValid != null && message.hasOwnProperty("isVerificationCodeValid"))
                        if (typeof message.isVerificationCodeValid !== "boolean")
                            return "isVerificationCodeValid: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.isVerificationCodeValid != null)
                        message.isVerificationCodeValid = Boolean(object.isVerificationCodeValid);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.isVerificationCodeValid = false;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.isVerificationCodeValid != null && message.hasOwnProperty("isVerificationCodeValid"))
                        object.isVerificationCodeValid = message.isVerificationCodeValid;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ValidatePhoneNumberVerificationCodeActionProto.Response";
                };

                return Response;
            })();

            return ValidatePhoneNumberVerificationCodeActionProto;
        })();

        common.SignUpActionProto = (function() {

            /**
             * Properties of a SignUpActionProto.
             * @memberof waiternow.common
             * @interface ISignUpActionProto
             */

            /**
             * Constructs a new SignUpActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SignUpActionProto.
             * @implements ISignUpActionProto
             * @constructor
             * @param {waiternow.common.ISignUpActionProto=} [properties] Properties to set
             */
            function SignUpActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SignUpActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {waiternow.common.ISignUpActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SignUpActionProto} SignUpActionProto instance
             */
            SignUpActionProto.create = function create(properties) {
                return new SignUpActionProto(properties);
            };

            /**
             * Encodes the specified SignUpActionProto message. Does not implicitly {@link waiternow.common.SignUpActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {waiternow.common.ISignUpActionProto} message SignUpActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignUpActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SignUpActionProto message, length delimited. Does not implicitly {@link waiternow.common.SignUpActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {waiternow.common.ISignUpActionProto} message SignUpActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignUpActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SignUpActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SignUpActionProto} SignUpActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignUpActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignUpActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SignUpActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SignUpActionProto} SignUpActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignUpActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SignUpActionProto message.
             * @function verify
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SignUpActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SignUpActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SignUpActionProto} SignUpActionProto
             */
            SignUpActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SignUpActionProto)
                    return object;
                return new $root.waiternow.common.SignUpActionProto();
            };

            /**
             * Creates a plain object from a SignUpActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {waiternow.common.SignUpActionProto} message SignUpActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SignUpActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SignUpActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SignUpActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SignUpActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SignUpActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SignUpActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SignUpActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SignUpActionProto";
            };

            SignUpActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SignUpActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IUserProto|null} [user] Request user
                 * @property {string|null} [password] Request password
                 * @property {string|null} [emailVerificationCode] Request emailVerificationCode
                 * @property {string|null} [phoneNumberVerificationCode] Request phoneNumberVerificationCode
                 * @property {string|null} [devAuthorizationCode] Request devAuthorizationCode
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SignUpActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SignUpActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request user.
                 * @member {waiternow.common.IUserProto|null|undefined} user
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.user = null;

                /**
                 * Request password.
                 * @member {string} password
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.password = "";

                /**
                 * Request emailVerificationCode.
                 * @member {string} emailVerificationCode
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.emailVerificationCode = "";

                /**
                 * Request phoneNumberVerificationCode.
                 * @member {string} phoneNumberVerificationCode
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumberVerificationCode = "";

                /**
                 * Request devAuthorizationCode.
                 * @member {string} devAuthorizationCode
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.devAuthorizationCode = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.SignUpActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SignUpActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SignUpActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.SignUpActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.waiternow.common.UserProto.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.devAuthorizationCode != null && Object.hasOwnProperty.call(message, "devAuthorizationCode"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.devAuthorizationCode);
                    if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.password);
                    if (message.emailVerificationCode != null && Object.hasOwnProperty.call(message, "emailVerificationCode"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.emailVerificationCode);
                    if (message.phoneNumberVerificationCode != null && Object.hasOwnProperty.call(message, "phoneNumberVerificationCode"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.phoneNumberVerificationCode);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SignUpActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.SignUpActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SignUpActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignUpActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.user = $root.waiternow.common.UserProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.password = reader.string();
                                break;
                            }
                        case 4: {
                                message.emailVerificationCode = reader.string();
                                break;
                            }
                        case 5: {
                                message.phoneNumberVerificationCode = reader.string();
                                break;
                            }
                        case 2: {
                                message.devAuthorizationCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SignUpActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.waiternow.common.UserProto.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.password != null && message.hasOwnProperty("password"))
                        if (!$util.isString(message.password))
                            return "password: string expected";
                    if (message.emailVerificationCode != null && message.hasOwnProperty("emailVerificationCode"))
                        if (!$util.isString(message.emailVerificationCode))
                            return "emailVerificationCode: string expected";
                    if (message.phoneNumberVerificationCode != null && message.hasOwnProperty("phoneNumberVerificationCode"))
                        if (!$util.isString(message.phoneNumberVerificationCode))
                            return "phoneNumberVerificationCode: string expected";
                    if (message.devAuthorizationCode != null && message.hasOwnProperty("devAuthorizationCode"))
                        if (!$util.isString(message.devAuthorizationCode))
                            return "devAuthorizationCode: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SignUpActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SignUpActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SignUpActionProto.Request();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".waiternow.common.SignUpActionProto.Request.user: object expected");
                        message.user = $root.waiternow.common.UserProto.fromObject(object.user);
                    }
                    if (object.password != null)
                        message.password = String(object.password);
                    if (object.emailVerificationCode != null)
                        message.emailVerificationCode = String(object.emailVerificationCode);
                    if (object.phoneNumberVerificationCode != null)
                        message.phoneNumberVerificationCode = String(object.phoneNumberVerificationCode);
                    if (object.devAuthorizationCode != null)
                        message.devAuthorizationCode = String(object.devAuthorizationCode);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.SignUpActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.user = null;
                        object.devAuthorizationCode = "";
                        object.password = "";
                        object.emailVerificationCode = "";
                        object.phoneNumberVerificationCode = "";
                    }
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.waiternow.common.UserProto.toObject(message.user, options);
                    if (message.devAuthorizationCode != null && message.hasOwnProperty("devAuthorizationCode"))
                        object.devAuthorizationCode = message.devAuthorizationCode;
                    if (message.password != null && message.hasOwnProperty("password"))
                        object.password = message.password;
                    if (message.emailVerificationCode != null && message.hasOwnProperty("emailVerificationCode"))
                        object.emailVerificationCode = message.emailVerificationCode;
                    if (message.phoneNumberVerificationCode != null && message.hasOwnProperty("phoneNumberVerificationCode"))
                        object.phoneNumberVerificationCode = message.phoneNumberVerificationCode;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SignUpActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SignUpActionProto.Request";
                };

                return Request;
            })();

            SignUpActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SignUpActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {string|null} [authToken] Response authToken
                 * @property {google.protobuf.IDuration|null} [authTokenExpiration] Response authTokenExpiration
                 * @property {string|null} [userId] Response userId
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SignUpActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SignUpActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response authToken.
                 * @member {string} authToken
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.authToken = "";

                /**
                 * Response authTokenExpiration.
                 * @member {google.protobuf.IDuration|null|undefined} authTokenExpiration
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.authTokenExpiration = null;

                /**
                 * Response userId.
                 * @member {string} userId
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.userId = "";

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.SignUpActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SignUpActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SignUpActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.SignUpActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.authToken);
                    if (message.authTokenExpiration != null && Object.hasOwnProperty.call(message, "authTokenExpiration"))
                        $root.google.protobuf.Duration.encode(message.authTokenExpiration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userId);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SignUpActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.SignUpActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SignUpActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignUpActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.authToken = reader.string();
                                break;
                            }
                        case 3: {
                                message.authTokenExpiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.userId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SignUpActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.authToken != null && message.hasOwnProperty("authToken"))
                        if (!$util.isString(message.authToken))
                            return "authToken: string expected";
                    if (message.authTokenExpiration != null && message.hasOwnProperty("authTokenExpiration")) {
                        var error = $root.google.protobuf.Duration.verify(message.authTokenExpiration);
                        if (error)
                            return "authTokenExpiration." + error;
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SignUpActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SignUpActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SignUpActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SignUpActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.authToken != null)
                        message.authToken = String(object.authToken);
                    if (object.authTokenExpiration != null) {
                        if (typeof object.authTokenExpiration !== "object")
                            throw TypeError(".waiternow.common.SignUpActionProto.Response.authTokenExpiration: object expected");
                        message.authTokenExpiration = $root.google.protobuf.Duration.fromObject(object.authTokenExpiration);
                    }
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.SignUpActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.authToken = "";
                        object.authTokenExpiration = null;
                        object.userId = "";
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.authToken != null && message.hasOwnProperty("authToken"))
                        object.authToken = message.authToken;
                    if (message.authTokenExpiration != null && message.hasOwnProperty("authTokenExpiration"))
                        object.authTokenExpiration = $root.google.protobuf.Duration.toObject(message.authTokenExpiration, options);
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SignUpActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SignUpActionProto.Response";
                };

                return Response;
            })();

            return SignUpActionProto;
        })();

        common.FullSignUpActionProto = (function() {

            /**
             * Properties of a FullSignUpActionProto.
             * @memberof waiternow.common
             * @interface IFullSignUpActionProto
             */

            /**
             * Constructs a new FullSignUpActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FullSignUpActionProto.
             * @implements IFullSignUpActionProto
             * @constructor
             * @param {waiternow.common.IFullSignUpActionProto=} [properties] Properties to set
             */
            function FullSignUpActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FullSignUpActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {waiternow.common.IFullSignUpActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FullSignUpActionProto} FullSignUpActionProto instance
             */
            FullSignUpActionProto.create = function create(properties) {
                return new FullSignUpActionProto(properties);
            };

            /**
             * Encodes the specified FullSignUpActionProto message. Does not implicitly {@link waiternow.common.FullSignUpActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {waiternow.common.IFullSignUpActionProto} message FullSignUpActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FullSignUpActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FullSignUpActionProto message, length delimited. Does not implicitly {@link waiternow.common.FullSignUpActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {waiternow.common.IFullSignUpActionProto} message FullSignUpActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FullSignUpActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FullSignUpActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FullSignUpActionProto} FullSignUpActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FullSignUpActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FullSignUpActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FullSignUpActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FullSignUpActionProto} FullSignUpActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FullSignUpActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FullSignUpActionProto message.
             * @function verify
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FullSignUpActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FullSignUpActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FullSignUpActionProto} FullSignUpActionProto
             */
            FullSignUpActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FullSignUpActionProto)
                    return object;
                return new $root.waiternow.common.FullSignUpActionProto();
            };

            /**
             * Creates a plain object from a FullSignUpActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {waiternow.common.FullSignUpActionProto} message FullSignUpActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FullSignUpActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FullSignUpActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FullSignUpActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FullSignUpActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FullSignUpActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FullSignUpActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FullSignUpActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FullSignUpActionProto";
            };

            FullSignUpActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FullSignUpActionProto
                 * @interface IRequest
                 * @property {string|null} [email] Request email
                 * @property {string|null} [password] Request password
                 * @property {string|null} [name] Request name
                 * @property {string|null} [lastName] Request lastName
                 * @property {waiternow.common.IPhoneNumberProto|null} [phoneNumber] Request phoneNumber
                 * @property {waiternow.common.ContactMedium|null} [preferredContactMedium] Request preferredContactMedium
                 * @property {waiternow.common.ReachMedium|null} [reachMedium] Request reachMedium
                 * @property {waiternow.common.Language|null} [language] Request language
                 * @property {string|null} [businessName] Request businessName
                 * @property {waiternow.common.IAddressProto|null} [businessAddress] Request businessAddress
                 * @property {number|null} [numberOfPointsOfService] Request numberOfPointsOfService
                 * @property {waiternow.common.RecordType|null} [recordType] Request recordType
                 * @property {string|null} [devAuthorizationCode] Request devAuthorizationCode
                 * @property {string|null} [emailVerificationCode] Request emailVerificationCode
                 * @property {string|null} [phoneNumberVerificationCode] Request phoneNumberVerificationCode
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FullSignUpActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FullSignUpActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request email.
                 * @member {string} email
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.email = "";

                /**
                 * Request password.
                 * @member {string} password
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.password = "";

                /**
                 * Request name.
                 * @member {string} name
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.name = "";

                /**
                 * Request lastName.
                 * @member {string} lastName
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.lastName = "";

                /**
                 * Request phoneNumber.
                 * @member {waiternow.common.IPhoneNumberProto|null|undefined} phoneNumber
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumber = null;

                /**
                 * Request preferredContactMedium.
                 * @member {waiternow.common.ContactMedium} preferredContactMedium
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.preferredContactMedium = 0;

                /**
                 * Request reachMedium.
                 * @member {waiternow.common.ReachMedium} reachMedium
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.reachMedium = 0;

                /**
                 * Request language.
                 * @member {waiternow.common.Language} language
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.language = 0;

                /**
                 * Request businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.businessName = "";

                /**
                 * Request businessAddress.
                 * @member {waiternow.common.IAddressProto|null|undefined} businessAddress
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.businessAddress = null;

                /**
                 * Request numberOfPointsOfService.
                 * @member {number} numberOfPointsOfService
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.numberOfPointsOfService = 0;

                /**
                 * Request recordType.
                 * @member {waiternow.common.RecordType} recordType
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.recordType = 0;

                /**
                 * Request devAuthorizationCode.
                 * @member {string} devAuthorizationCode
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.devAuthorizationCode = "";

                /**
                 * Request emailVerificationCode.
                 * @member {string} emailVerificationCode
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.emailVerificationCode = "";

                /**
                 * Request phoneNumberVerificationCode.
                 * @member {string} phoneNumberVerificationCode
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 */
                Request.prototype.phoneNumberVerificationCode = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FullSignUpActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FullSignUpActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                    if (message.name != null && Object.hasOwnProperty.call(message, "name"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.name);
                    if (message.lastName != null && Object.hasOwnProperty.call(message, "lastName"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.lastName);
                    if (message.phoneNumber != null && Object.hasOwnProperty.call(message, "phoneNumber"))
                        $root.waiternow.common.PhoneNumberProto.encode(message.phoneNumber, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.preferredContactMedium != null && Object.hasOwnProperty.call(message, "preferredContactMedium"))
                        writer.uint32(/* id 6, wireType 0 =*/48).int32(message.preferredContactMedium);
                    if (message.reachMedium != null && Object.hasOwnProperty.call(message, "reachMedium"))
                        writer.uint32(/* id 7, wireType 0 =*/56).int32(message.reachMedium);
                    if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                        writer.uint32(/* id 8, wireType 0 =*/64).int32(message.language);
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 9, wireType 2 =*/74).string(message.businessName);
                    if (message.businessAddress != null && Object.hasOwnProperty.call(message, "businessAddress"))
                        $root.waiternow.common.AddressProto.encode(message.businessAddress, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    if (message.numberOfPointsOfService != null && Object.hasOwnProperty.call(message, "numberOfPointsOfService"))
                        writer.uint32(/* id 11, wireType 0 =*/88).int32(message.numberOfPointsOfService);
                    if (message.recordType != null && Object.hasOwnProperty.call(message, "recordType"))
                        writer.uint32(/* id 12, wireType 0 =*/96).int32(message.recordType);
                    if (message.devAuthorizationCode != null && Object.hasOwnProperty.call(message, "devAuthorizationCode"))
                        writer.uint32(/* id 13, wireType 2 =*/106).string(message.devAuthorizationCode);
                    if (message.emailVerificationCode != null && Object.hasOwnProperty.call(message, "emailVerificationCode"))
                        writer.uint32(/* id 14, wireType 2 =*/114).string(message.emailVerificationCode);
                    if (message.phoneNumberVerificationCode != null && Object.hasOwnProperty.call(message, "phoneNumberVerificationCode"))
                        writer.uint32(/* id 15, wireType 2 =*/122).string(message.phoneNumberVerificationCode);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FullSignUpActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FullSignUpActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FullSignUpActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        case 2: {
                                message.password = reader.string();
                                break;
                            }
                        case 3: {
                                message.name = reader.string();
                                break;
                            }
                        case 4: {
                                message.lastName = reader.string();
                                break;
                            }
                        case 5: {
                                message.phoneNumber = $root.waiternow.common.PhoneNumberProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.preferredContactMedium = reader.int32();
                                break;
                            }
                        case 7: {
                                message.reachMedium = reader.int32();
                                break;
                            }
                        case 8: {
                                message.language = reader.int32();
                                break;
                            }
                        case 9: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 10: {
                                message.businessAddress = $root.waiternow.common.AddressProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 11: {
                                message.numberOfPointsOfService = reader.int32();
                                break;
                            }
                        case 12: {
                                message.recordType = reader.int32();
                                break;
                            }
                        case 13: {
                                message.devAuthorizationCode = reader.string();
                                break;
                            }
                        case 14: {
                                message.emailVerificationCode = reader.string();
                                break;
                            }
                        case 15: {
                                message.phoneNumberVerificationCode = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FullSignUpActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.password != null && message.hasOwnProperty("password"))
                        if (!$util.isString(message.password))
                            return "password: string expected";
                    if (message.name != null && message.hasOwnProperty("name"))
                        if (!$util.isString(message.name))
                            return "name: string expected";
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        if (!$util.isString(message.lastName))
                            return "lastName: string expected";
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber")) {
                        var error = $root.waiternow.common.PhoneNumberProto.verify(message.phoneNumber);
                        if (error)
                            return "phoneNumber." + error;
                    }
                    if (message.preferredContactMedium != null && message.hasOwnProperty("preferredContactMedium"))
                        switch (message.preferredContactMedium) {
                        default:
                            return "preferredContactMedium: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                            break;
                        }
                    if (message.reachMedium != null && message.hasOwnProperty("reachMedium"))
                        switch (message.reachMedium) {
                        default:
                            return "reachMedium: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                            break;
                        }
                    if (message.language != null && message.hasOwnProperty("language"))
                        switch (message.language) {
                        default:
                            return "language: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.businessAddress != null && message.hasOwnProperty("businessAddress")) {
                        var error = $root.waiternow.common.AddressProto.verify(message.businessAddress);
                        if (error)
                            return "businessAddress." + error;
                    }
                    if (message.numberOfPointsOfService != null && message.hasOwnProperty("numberOfPointsOfService"))
                        if (!$util.isInteger(message.numberOfPointsOfService))
                            return "numberOfPointsOfService: integer expected";
                    if (message.recordType != null && message.hasOwnProperty("recordType"))
                        switch (message.recordType) {
                        default:
                            return "recordType: enum value expected";
                        case 0:
                        case 1:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.devAuthorizationCode != null && message.hasOwnProperty("devAuthorizationCode"))
                        if (!$util.isString(message.devAuthorizationCode))
                            return "devAuthorizationCode: string expected";
                    if (message.emailVerificationCode != null && message.hasOwnProperty("emailVerificationCode"))
                        if (!$util.isString(message.emailVerificationCode))
                            return "emailVerificationCode: string expected";
                    if (message.phoneNumberVerificationCode != null && message.hasOwnProperty("phoneNumberVerificationCode"))
                        if (!$util.isString(message.phoneNumberVerificationCode))
                            return "phoneNumberVerificationCode: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FullSignUpActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FullSignUpActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FullSignUpActionProto.Request();
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.password != null)
                        message.password = String(object.password);
                    if (object.name != null)
                        message.name = String(object.name);
                    if (object.lastName != null)
                        message.lastName = String(object.lastName);
                    if (object.phoneNumber != null) {
                        if (typeof object.phoneNumber !== "object")
                            throw TypeError(".waiternow.common.FullSignUpActionProto.Request.phoneNumber: object expected");
                        message.phoneNumber = $root.waiternow.common.PhoneNumberProto.fromObject(object.phoneNumber);
                    }
                    switch (object.preferredContactMedium) {
                    default:
                        if (typeof object.preferredContactMedium === "number") {
                            message.preferredContactMedium = object.preferredContactMedium;
                            break;
                        }
                        break;
                    case "CONTACT_MEDIUM_UNSPECIFIED":
                    case 0:
                        message.preferredContactMedium = 0;
                        break;
                    case "EMAIL":
                    case 1:
                        message.preferredContactMedium = 1;
                        break;
                    case "PHONE_CALL":
                    case 2:
                        message.preferredContactMedium = 2;
                        break;
                    case "TEXT_MESSAGE":
                    case 3:
                        message.preferredContactMedium = 3;
                        break;
                    case "WHATS_APP":
                    case 4:
                        message.preferredContactMedium = 4;
                        break;
                    }
                    switch (object.reachMedium) {
                    default:
                        if (typeof object.reachMedium === "number") {
                            message.reachMedium = object.reachMedium;
                            break;
                        }
                        break;
                    case "REACH_MEDIUM_UNDEFINED":
                    case 0:
                        message.reachMedium = 0;
                        break;
                    case "GOOGLE":
                    case 1:
                        message.reachMedium = 1;
                        break;
                    case "FACEBOOK":
                    case 2:
                        message.reachMedium = 2;
                        break;
                    case "INSTRAGRAM":
                    case 3:
                        message.reachMedium = 3;
                        break;
                    case "LINKED_IN":
                    case 4:
                        message.reachMedium = 4;
                        break;
                    case "RECOMMENDATION":
                    case 5:
                        message.reachMedium = 5;
                        break;
                    case "SALESPERSON":
                    case 6:
                        message.reachMedium = 6;
                        break;
                    case "OTHER":
                    case 7:
                        message.reachMedium = 7;
                        break;
                    }
                    switch (object.language) {
                    default:
                        if (typeof object.language === "number") {
                            message.language = object.language;
                            break;
                        }
                        break;
                    case "LANGUAGE_UNSPECIFIED":
                    case 0:
                        message.language = 0;
                        break;
                    case "ENGLISH":
                    case 1:
                        message.language = 1;
                        break;
                    case "SPANISH":
                    case 2:
                        message.language = 2;
                        break;
                    }
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.businessAddress != null) {
                        if (typeof object.businessAddress !== "object")
                            throw TypeError(".waiternow.common.FullSignUpActionProto.Request.businessAddress: object expected");
                        message.businessAddress = $root.waiternow.common.AddressProto.fromObject(object.businessAddress);
                    }
                    if (object.numberOfPointsOfService != null)
                        message.numberOfPointsOfService = object.numberOfPointsOfService | 0;
                    switch (object.recordType) {
                    default:
                        if (typeof object.recordType === "number") {
                            message.recordType = object.recordType;
                            break;
                        }
                        break;
                    case "RECORD_TYPE_UNSPECIFIED":
                    case 0:
                        message.recordType = 0;
                        break;
                    case "REGULAR":
                    case 1:
                        message.recordType = 1;
                        break;
                    case "PRE_CREATED":
                    case 3:
                        message.recordType = 3;
                        break;
                    case "TEST":
                    case 4:
                        message.recordType = 4;
                        break;
                    case "DEMO":
                    case 5:
                        message.recordType = 5;
                        break;
                    }
                    if (object.devAuthorizationCode != null)
                        message.devAuthorizationCode = String(object.devAuthorizationCode);
                    if (object.emailVerificationCode != null)
                        message.emailVerificationCode = String(object.emailVerificationCode);
                    if (object.phoneNumberVerificationCode != null)
                        message.phoneNumberVerificationCode = String(object.phoneNumberVerificationCode);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.password = "";
                        object.name = "";
                        object.lastName = "";
                        object.phoneNumber = null;
                        object.preferredContactMedium = options.enums === String ? "CONTACT_MEDIUM_UNSPECIFIED" : 0;
                        object.reachMedium = options.enums === String ? "REACH_MEDIUM_UNDEFINED" : 0;
                        object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                        object.businessName = "";
                        object.businessAddress = null;
                        object.numberOfPointsOfService = 0;
                        object.recordType = options.enums === String ? "RECORD_TYPE_UNSPECIFIED" : 0;
                        object.devAuthorizationCode = "";
                        object.emailVerificationCode = "";
                        object.phoneNumberVerificationCode = "";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.password != null && message.hasOwnProperty("password"))
                        object.password = message.password;
                    if (message.name != null && message.hasOwnProperty("name"))
                        object.name = message.name;
                    if (message.lastName != null && message.hasOwnProperty("lastName"))
                        object.lastName = message.lastName;
                    if (message.phoneNumber != null && message.hasOwnProperty("phoneNumber"))
                        object.phoneNumber = $root.waiternow.common.PhoneNumberProto.toObject(message.phoneNumber, options);
                    if (message.preferredContactMedium != null && message.hasOwnProperty("preferredContactMedium"))
                        object.preferredContactMedium = options.enums === String ? $root.waiternow.common.ContactMedium[message.preferredContactMedium] === undefined ? message.preferredContactMedium : $root.waiternow.common.ContactMedium[message.preferredContactMedium] : message.preferredContactMedium;
                    if (message.reachMedium != null && message.hasOwnProperty("reachMedium"))
                        object.reachMedium = options.enums === String ? $root.waiternow.common.ReachMedium[message.reachMedium] === undefined ? message.reachMedium : $root.waiternow.common.ReachMedium[message.reachMedium] : message.reachMedium;
                    if (message.language != null && message.hasOwnProperty("language"))
                        object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.businessAddress != null && message.hasOwnProperty("businessAddress"))
                        object.businessAddress = $root.waiternow.common.AddressProto.toObject(message.businessAddress, options);
                    if (message.numberOfPointsOfService != null && message.hasOwnProperty("numberOfPointsOfService"))
                        object.numberOfPointsOfService = message.numberOfPointsOfService;
                    if (message.recordType != null && message.hasOwnProperty("recordType"))
                        object.recordType = options.enums === String ? $root.waiternow.common.RecordType[message.recordType] === undefined ? message.recordType : $root.waiternow.common.RecordType[message.recordType] : message.recordType;
                    if (message.devAuthorizationCode != null && message.hasOwnProperty("devAuthorizationCode"))
                        object.devAuthorizationCode = message.devAuthorizationCode;
                    if (message.emailVerificationCode != null && message.hasOwnProperty("emailVerificationCode"))
                        object.emailVerificationCode = message.emailVerificationCode;
                    if (message.phoneNumberVerificationCode != null && message.hasOwnProperty("phoneNumberVerificationCode"))
                        object.phoneNumberVerificationCode = message.phoneNumberVerificationCode;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FullSignUpActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FullSignUpActionProto.Request";
                };

                return Request;
            })();

            FullSignUpActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FullSignUpActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {string|null} [authToken] Response authToken
                 * @property {google.protobuf.IDuration|null} [authTokenExpiration] Response authTokenExpiration
                 * @property {string|null} [userId] Response userId
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FullSignUpActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FullSignUpActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response authToken.
                 * @member {string} authToken
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.authToken = "";

                /**
                 * Response authTokenExpiration.
                 * @member {google.protobuf.IDuration|null|undefined} authTokenExpiration
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.authTokenExpiration = null;

                /**
                 * Response userId.
                 * @member {string} userId
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @instance
                 */
                Response.prototype.userId = "";

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FullSignUpActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FullSignUpActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.authToken);
                    if (message.authTokenExpiration != null && Object.hasOwnProperty.call(message, "authTokenExpiration"))
                        $root.google.protobuf.Duration.encode(message.authTokenExpiration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userId);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FullSignUpActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FullSignUpActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FullSignUpActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.authToken = reader.string();
                                break;
                            }
                        case 3: {
                                message.authTokenExpiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.userId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FullSignUpActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.authToken != null && message.hasOwnProperty("authToken"))
                        if (!$util.isString(message.authToken))
                            return "authToken: string expected";
                    if (message.authTokenExpiration != null && message.hasOwnProperty("authTokenExpiration")) {
                        var error = $root.google.protobuf.Duration.verify(message.authTokenExpiration);
                        if (error)
                            return "authTokenExpiration." + error;
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FullSignUpActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FullSignUpActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FullSignUpActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FullSignUpActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.authToken != null)
                        message.authToken = String(object.authToken);
                    if (object.authTokenExpiration != null) {
                        if (typeof object.authTokenExpiration !== "object")
                            throw TypeError(".waiternow.common.FullSignUpActionProto.Response.authTokenExpiration: object expected");
                        message.authTokenExpiration = $root.google.protobuf.Duration.fromObject(object.authTokenExpiration);
                    }
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {waiternow.common.FullSignUpActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.authToken = "";
                        object.authTokenExpiration = null;
                        object.userId = "";
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.authToken != null && message.hasOwnProperty("authToken"))
                        object.authToken = message.authToken;
                    if (message.authTokenExpiration != null && message.hasOwnProperty("authTokenExpiration"))
                        object.authTokenExpiration = $root.google.protobuf.Duration.toObject(message.authTokenExpiration, options);
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FullSignUpActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FullSignUpActionProto.Response";
                };

                return Response;
            })();

            return FullSignUpActionProto;
        })();

        common.SignInActionProto = (function() {

            /**
             * Properties of a SignInActionProto.
             * @memberof waiternow.common
             * @interface ISignInActionProto
             */

            /**
             * Constructs a new SignInActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SignInActionProto.
             * @implements ISignInActionProto
             * @constructor
             * @param {waiternow.common.ISignInActionProto=} [properties] Properties to set
             */
            function SignInActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SignInActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {waiternow.common.ISignInActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SignInActionProto} SignInActionProto instance
             */
            SignInActionProto.create = function create(properties) {
                return new SignInActionProto(properties);
            };

            /**
             * Encodes the specified SignInActionProto message. Does not implicitly {@link waiternow.common.SignInActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {waiternow.common.ISignInActionProto} message SignInActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignInActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SignInActionProto message, length delimited. Does not implicitly {@link waiternow.common.SignInActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {waiternow.common.ISignInActionProto} message SignInActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignInActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SignInActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SignInActionProto} SignInActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignInActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignInActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SignInActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SignInActionProto} SignInActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignInActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SignInActionProto message.
             * @function verify
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SignInActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SignInActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SignInActionProto} SignInActionProto
             */
            SignInActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SignInActionProto)
                    return object;
                return new $root.waiternow.common.SignInActionProto();
            };

            /**
             * Creates a plain object from a SignInActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {waiternow.common.SignInActionProto} message SignInActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SignInActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SignInActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SignInActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SignInActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SignInActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SignInActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SignInActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SignInActionProto";
            };

            SignInActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SignInActionProto
                 * @interface IRequest
                 * @property {string|null} [email] Request email
                 * @property {string|null} [password] Request password
                 * @property {string|null} [encodedCredentials] Request encodedCredentials
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SignInActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SignInActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request email.
                 * @member {string} email
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @instance
                 */
                Request.prototype.email = "";

                /**
                 * Request password.
                 * @member {string} password
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @instance
                 */
                Request.prototype.password = "";

                /**
                 * Request encodedCredentials.
                 * @member {string} encodedCredentials
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @instance
                 */
                Request.prototype.encodedCredentials = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {waiternow.common.SignInActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SignInActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SignInActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {waiternow.common.SignInActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    if (message.password != null && Object.hasOwnProperty.call(message, "password"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.password);
                    if (message.encodedCredentials != null && Object.hasOwnProperty.call(message, "encodedCredentials"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.encodedCredentials);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SignInActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {waiternow.common.SignInActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SignInActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignInActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        case 2: {
                                message.password = reader.string();
                                break;
                            }
                        case 3: {
                                message.encodedCredentials = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SignInActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    if (message.password != null && message.hasOwnProperty("password"))
                        if (!$util.isString(message.password))
                            return "password: string expected";
                    if (message.encodedCredentials != null && message.hasOwnProperty("encodedCredentials"))
                        if (!$util.isString(message.encodedCredentials))
                            return "encodedCredentials: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SignInActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SignInActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SignInActionProto.Request();
                    if (object.email != null)
                        message.email = String(object.email);
                    if (object.password != null)
                        message.password = String(object.password);
                    if (object.encodedCredentials != null)
                        message.encodedCredentials = String(object.encodedCredentials);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {waiternow.common.SignInActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.email = "";
                        object.password = "";
                        object.encodedCredentials = "";
                    }
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    if (message.password != null && message.hasOwnProperty("password"))
                        object.password = message.password;
                    if (message.encodedCredentials != null && message.hasOwnProperty("encodedCredentials"))
                        object.encodedCredentials = message.encodedCredentials;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SignInActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SignInActionProto.Request";
                };

                return Request;
            })();

            SignInActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SignInActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {string|null} [authToken] Response authToken
                 * @property {google.protobuf.IDuration|null} [authTokenExpiration] Response authTokenExpiration
                 * @property {string|null} [userId] Response userId
                 * @property {google.protobuf.IDuration|null} [businessSafetyCheckOrdersPollRate] Response businessSafetyCheckOrdersPollRate
                 * @property {google.protobuf.IDuration|null} [businessCheckOrdersPollRate] Response businessCheckOrdersPollRate
                 * @property {google.protobuf.ITimestamp|null} [since] Response since
                 * @property {boolean|null} [isImpersonationAllowed] Response isImpersonationAllowed
                 * @property {google.protobuf.IDuration|null} [businessDeviceHeartbeatRate] Response businessDeviceHeartbeatRate
                 * @property {boolean|null} [isAdminUser] Response isAdminUser
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SignInActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SignInActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response authToken.
                 * @member {string} authToken
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.authToken = "";

                /**
                 * Response authTokenExpiration.
                 * @member {google.protobuf.IDuration|null|undefined} authTokenExpiration
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.authTokenExpiration = null;

                /**
                 * Response userId.
                 * @member {string} userId
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.userId = "";

                /**
                 * Response businessSafetyCheckOrdersPollRate.
                 * @member {google.protobuf.IDuration|null|undefined} businessSafetyCheckOrdersPollRate
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.businessSafetyCheckOrdersPollRate = null;

                /**
                 * Response businessCheckOrdersPollRate.
                 * @member {google.protobuf.IDuration|null|undefined} businessCheckOrdersPollRate
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.businessCheckOrdersPollRate = null;

                /**
                 * Response since.
                 * @member {google.protobuf.ITimestamp|null|undefined} since
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.since = null;

                /**
                 * Response isImpersonationAllowed.
                 * @member {boolean} isImpersonationAllowed
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.isImpersonationAllowed = false;

                /**
                 * Response businessDeviceHeartbeatRate.
                 * @member {google.protobuf.IDuration|null|undefined} businessDeviceHeartbeatRate
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.businessDeviceHeartbeatRate = null;

                /**
                 * Response isAdminUser.
                 * @member {boolean} isAdminUser
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 */
                Response.prototype.isAdminUser = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {waiternow.common.SignInActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SignInActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SignInActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {waiternow.common.SignInActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.authToken != null && Object.hasOwnProperty.call(message, "authToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.authToken);
                    if (message.authTokenExpiration != null && Object.hasOwnProperty.call(message, "authTokenExpiration"))
                        $root.google.protobuf.Duration.encode(message.authTokenExpiration, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.userId);
                    if (message.businessSafetyCheckOrdersPollRate != null && Object.hasOwnProperty.call(message, "businessSafetyCheckOrdersPollRate"))
                        $root.google.protobuf.Duration.encode(message.businessSafetyCheckOrdersPollRate, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
                    if (message.since != null && Object.hasOwnProperty.call(message, "since"))
                        $root.google.protobuf.Timestamp.encode(message.since, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
                    if (message.isImpersonationAllowed != null && Object.hasOwnProperty.call(message, "isImpersonationAllowed"))
                        writer.uint32(/* id 7, wireType 0 =*/56).bool(message.isImpersonationAllowed);
                    if (message.businessDeviceHeartbeatRate != null && Object.hasOwnProperty.call(message, "businessDeviceHeartbeatRate"))
                        $root.google.protobuf.Duration.encode(message.businessDeviceHeartbeatRate, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
                    if (message.isAdminUser != null && Object.hasOwnProperty.call(message, "isAdminUser"))
                        writer.uint32(/* id 9, wireType 0 =*/72).bool(message.isAdminUser);
                    if (message.businessCheckOrdersPollRate != null && Object.hasOwnProperty.call(message, "businessCheckOrdersPollRate"))
                        $root.google.protobuf.Duration.encode(message.businessCheckOrdersPollRate, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SignInActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {waiternow.common.SignInActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SignInActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignInActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.authToken = reader.string();
                                break;
                            }
                        case 3: {
                                message.authTokenExpiration = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.userId = reader.string();
                                break;
                            }
                        case 5: {
                                message.businessSafetyCheckOrdersPollRate = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 10: {
                                message.businessCheckOrdersPollRate = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 6: {
                                message.since = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 7: {
                                message.isImpersonationAllowed = reader.bool();
                                break;
                            }
                        case 8: {
                                message.businessDeviceHeartbeatRate = $root.google.protobuf.Duration.decode(reader, reader.uint32());
                                break;
                            }
                        case 9: {
                                message.isAdminUser = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SignInActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.authToken != null && message.hasOwnProperty("authToken"))
                        if (!$util.isString(message.authToken))
                            return "authToken: string expected";
                    if (message.authTokenExpiration != null && message.hasOwnProperty("authTokenExpiration")) {
                        var error = $root.google.protobuf.Duration.verify(message.authTokenExpiration);
                        if (error)
                            return "authTokenExpiration." + error;
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.businessSafetyCheckOrdersPollRate != null && message.hasOwnProperty("businessSafetyCheckOrdersPollRate")) {
                        var error = $root.google.protobuf.Duration.verify(message.businessSafetyCheckOrdersPollRate);
                        if (error)
                            return "businessSafetyCheckOrdersPollRate." + error;
                    }
                    if (message.businessCheckOrdersPollRate != null && message.hasOwnProperty("businessCheckOrdersPollRate")) {
                        var error = $root.google.protobuf.Duration.verify(message.businessCheckOrdersPollRate);
                        if (error)
                            return "businessCheckOrdersPollRate." + error;
                    }
                    if (message.since != null && message.hasOwnProperty("since")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.since);
                        if (error)
                            return "since." + error;
                    }
                    if (message.isImpersonationAllowed != null && message.hasOwnProperty("isImpersonationAllowed"))
                        if (typeof message.isImpersonationAllowed !== "boolean")
                            return "isImpersonationAllowed: boolean expected";
                    if (message.businessDeviceHeartbeatRate != null && message.hasOwnProperty("businessDeviceHeartbeatRate")) {
                        var error = $root.google.protobuf.Duration.verify(message.businessDeviceHeartbeatRate);
                        if (error)
                            return "businessDeviceHeartbeatRate." + error;
                    }
                    if (message.isAdminUser != null && message.hasOwnProperty("isAdminUser"))
                        if (typeof message.isAdminUser !== "boolean")
                            return "isAdminUser: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SignInActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SignInActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SignInActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SignInActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.authToken != null)
                        message.authToken = String(object.authToken);
                    if (object.authTokenExpiration != null) {
                        if (typeof object.authTokenExpiration !== "object")
                            throw TypeError(".waiternow.common.SignInActionProto.Response.authTokenExpiration: object expected");
                        message.authTokenExpiration = $root.google.protobuf.Duration.fromObject(object.authTokenExpiration);
                    }
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.businessSafetyCheckOrdersPollRate != null) {
                        if (typeof object.businessSafetyCheckOrdersPollRate !== "object")
                            throw TypeError(".waiternow.common.SignInActionProto.Response.businessSafetyCheckOrdersPollRate: object expected");
                        message.businessSafetyCheckOrdersPollRate = $root.google.protobuf.Duration.fromObject(object.businessSafetyCheckOrdersPollRate);
                    }
                    if (object.businessCheckOrdersPollRate != null) {
                        if (typeof object.businessCheckOrdersPollRate !== "object")
                            throw TypeError(".waiternow.common.SignInActionProto.Response.businessCheckOrdersPollRate: object expected");
                        message.businessCheckOrdersPollRate = $root.google.protobuf.Duration.fromObject(object.businessCheckOrdersPollRate);
                    }
                    if (object.since != null) {
                        if (typeof object.since !== "object")
                            throw TypeError(".waiternow.common.SignInActionProto.Response.since: object expected");
                        message.since = $root.google.protobuf.Timestamp.fromObject(object.since);
                    }
                    if (object.isImpersonationAllowed != null)
                        message.isImpersonationAllowed = Boolean(object.isImpersonationAllowed);
                    if (object.businessDeviceHeartbeatRate != null) {
                        if (typeof object.businessDeviceHeartbeatRate !== "object")
                            throw TypeError(".waiternow.common.SignInActionProto.Response.businessDeviceHeartbeatRate: object expected");
                        message.businessDeviceHeartbeatRate = $root.google.protobuf.Duration.fromObject(object.businessDeviceHeartbeatRate);
                    }
                    if (object.isAdminUser != null)
                        message.isAdminUser = Boolean(object.isAdminUser);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {waiternow.common.SignInActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.authToken = "";
                        object.authTokenExpiration = null;
                        object.userId = "";
                        object.businessSafetyCheckOrdersPollRate = null;
                        object.since = null;
                        object.isImpersonationAllowed = false;
                        object.businessDeviceHeartbeatRate = null;
                        object.isAdminUser = false;
                        object.businessCheckOrdersPollRate = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.authToken != null && message.hasOwnProperty("authToken"))
                        object.authToken = message.authToken;
                    if (message.authTokenExpiration != null && message.hasOwnProperty("authTokenExpiration"))
                        object.authTokenExpiration = $root.google.protobuf.Duration.toObject(message.authTokenExpiration, options);
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.businessSafetyCheckOrdersPollRate != null && message.hasOwnProperty("businessSafetyCheckOrdersPollRate"))
                        object.businessSafetyCheckOrdersPollRate = $root.google.protobuf.Duration.toObject(message.businessSafetyCheckOrdersPollRate, options);
                    if (message.since != null && message.hasOwnProperty("since"))
                        object.since = $root.google.protobuf.Timestamp.toObject(message.since, options);
                    if (message.isImpersonationAllowed != null && message.hasOwnProperty("isImpersonationAllowed"))
                        object.isImpersonationAllowed = message.isImpersonationAllowed;
                    if (message.businessDeviceHeartbeatRate != null && message.hasOwnProperty("businessDeviceHeartbeatRate"))
                        object.businessDeviceHeartbeatRate = $root.google.protobuf.Duration.toObject(message.businessDeviceHeartbeatRate, options);
                    if (message.isAdminUser != null && message.hasOwnProperty("isAdminUser"))
                        object.isAdminUser = message.isAdminUser;
                    if (message.businessCheckOrdersPollRate != null && message.hasOwnProperty("businessCheckOrdersPollRate"))
                        object.businessCheckOrdersPollRate = $root.google.protobuf.Duration.toObject(message.businessCheckOrdersPollRate, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SignInActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SignInActionProto.Response";
                };

                return Response;
            })();

            return SignInActionProto;
        })();

        common.SignOutActionProto = (function() {

            /**
             * Properties of a SignOutActionProto.
             * @memberof waiternow.common
             * @interface ISignOutActionProto
             */

            /**
             * Constructs a new SignOutActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SignOutActionProto.
             * @implements ISignOutActionProto
             * @constructor
             * @param {waiternow.common.ISignOutActionProto=} [properties] Properties to set
             */
            function SignOutActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SignOutActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {waiternow.common.ISignOutActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SignOutActionProto} SignOutActionProto instance
             */
            SignOutActionProto.create = function create(properties) {
                return new SignOutActionProto(properties);
            };

            /**
             * Encodes the specified SignOutActionProto message. Does not implicitly {@link waiternow.common.SignOutActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {waiternow.common.ISignOutActionProto} message SignOutActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignOutActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SignOutActionProto message, length delimited. Does not implicitly {@link waiternow.common.SignOutActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {waiternow.common.ISignOutActionProto} message SignOutActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SignOutActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SignOutActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SignOutActionProto} SignOutActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignOutActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignOutActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SignOutActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SignOutActionProto} SignOutActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SignOutActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SignOutActionProto message.
             * @function verify
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SignOutActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SignOutActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SignOutActionProto} SignOutActionProto
             */
            SignOutActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SignOutActionProto)
                    return object;
                return new $root.waiternow.common.SignOutActionProto();
            };

            /**
             * Creates a plain object from a SignOutActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {waiternow.common.SignOutActionProto} message SignOutActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SignOutActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SignOutActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SignOutActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SignOutActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SignOutActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SignOutActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SignOutActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SignOutActionProto";
            };

            SignOutActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SignOutActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SignOutActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SignOutActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {waiternow.common.SignOutActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SignOutActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SignOutActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {waiternow.common.SignOutActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SignOutActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {waiternow.common.SignOutActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SignOutActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignOutActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SignOutActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SignOutActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SignOutActionProto.Request)
                        return object;
                    return new $root.waiternow.common.SignOutActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {waiternow.common.SignOutActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SignOutActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SignOutActionProto.Request";
                };

                return Request;
            })();

            SignOutActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SignOutActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SignOutActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SignOutActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {waiternow.common.SignOutActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SignOutActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SignOutActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {waiternow.common.SignOutActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SignOutActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {waiternow.common.SignOutActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SignOutActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SignOutActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SignOutActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SignOutActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SignOutActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SignOutActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SignOutActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {waiternow.common.SignOutActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SignOutActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SignOutActionProto.Response";
                };

                return Response;
            })();

            return SignOutActionProto;
        })();

        common.GetAuthenticatedUserActionProto = (function() {

            /**
             * Properties of a GetAuthenticatedUserActionProto.
             * @memberof waiternow.common
             * @interface IGetAuthenticatedUserActionProto
             */

            /**
             * Constructs a new GetAuthenticatedUserActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetAuthenticatedUserActionProto.
             * @implements IGetAuthenticatedUserActionProto
             * @constructor
             * @param {waiternow.common.IGetAuthenticatedUserActionProto=} [properties] Properties to set
             */
            function GetAuthenticatedUserActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetAuthenticatedUserActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {waiternow.common.IGetAuthenticatedUserActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetAuthenticatedUserActionProto} GetAuthenticatedUserActionProto instance
             */
            GetAuthenticatedUserActionProto.create = function create(properties) {
                return new GetAuthenticatedUserActionProto(properties);
            };

            /**
             * Encodes the specified GetAuthenticatedUserActionProto message. Does not implicitly {@link waiternow.common.GetAuthenticatedUserActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {waiternow.common.IGetAuthenticatedUserActionProto} message GetAuthenticatedUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAuthenticatedUserActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetAuthenticatedUserActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetAuthenticatedUserActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {waiternow.common.IGetAuthenticatedUserActionProto} message GetAuthenticatedUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetAuthenticatedUserActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetAuthenticatedUserActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetAuthenticatedUserActionProto} GetAuthenticatedUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAuthenticatedUserActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetAuthenticatedUserActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetAuthenticatedUserActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetAuthenticatedUserActionProto} GetAuthenticatedUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetAuthenticatedUserActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetAuthenticatedUserActionProto message.
             * @function verify
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetAuthenticatedUserActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetAuthenticatedUserActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetAuthenticatedUserActionProto} GetAuthenticatedUserActionProto
             */
            GetAuthenticatedUserActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetAuthenticatedUserActionProto)
                    return object;
                return new $root.waiternow.common.GetAuthenticatedUserActionProto();
            };

            /**
             * Creates a plain object from a GetAuthenticatedUserActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {waiternow.common.GetAuthenticatedUserActionProto} message GetAuthenticatedUserActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetAuthenticatedUserActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetAuthenticatedUserActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetAuthenticatedUserActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetAuthenticatedUserActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetAuthenticatedUserActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetAuthenticatedUserActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetAuthenticatedUserActionProto";
            };

            GetAuthenticatedUserActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetAuthenticatedUserActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetAuthenticatedUserActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetAuthenticatedUserActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetAuthenticatedUserActionProto.Request)
                        return object;
                    return new $root.waiternow.common.GetAuthenticatedUserActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetAuthenticatedUserActionProto.Request";
                };

                return Request;
            })();

            GetAuthenticatedUserActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IUserProto|null} [user] Response user
                 * @property {boolean|null} [isImpersonationAllowed] Response isImpersonationAllowed
                 * @property {boolean|null} [isAdminUser] Response isAdminUser
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response user.
                 * @member {waiternow.common.IUserProto|null|undefined} user
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @instance
                 */
                Response.prototype.user = null;

                /**
                 * Response isImpersonationAllowed.
                 * @member {boolean} isImpersonationAllowed
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @instance
                 */
                Response.prototype.isImpersonationAllowed = false;

                /**
                 * Response isAdminUser.
                 * @member {boolean} isAdminUser
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @instance
                 */
                Response.prototype.isAdminUser = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetAuthenticatedUserActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.waiternow.common.UserProto.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.isImpersonationAllowed != null && Object.hasOwnProperty.call(message, "isImpersonationAllowed"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isImpersonationAllowed);
                    if (message.isAdminUser != null && Object.hasOwnProperty.call(message, "isAdminUser"))
                        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.isAdminUser);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetAuthenticatedUserActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetAuthenticatedUserActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.user = $root.waiternow.common.UserProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.isImpersonationAllowed = reader.bool();
                                break;
                            }
                        case 4: {
                                message.isAdminUser = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.waiternow.common.UserProto.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.isImpersonationAllowed != null && message.hasOwnProperty("isImpersonationAllowed"))
                        if (typeof message.isImpersonationAllowed !== "boolean")
                            return "isImpersonationAllowed: boolean expected";
                    if (message.isAdminUser != null && message.hasOwnProperty("isAdminUser"))
                        if (typeof message.isAdminUser !== "boolean")
                            return "isAdminUser: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetAuthenticatedUserActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetAuthenticatedUserActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetAuthenticatedUserActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetAuthenticatedUserActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".waiternow.common.GetAuthenticatedUserActionProto.Response.user: object expected");
                        message.user = $root.waiternow.common.UserProto.fromObject(object.user);
                    }
                    if (object.isImpersonationAllowed != null)
                        message.isImpersonationAllowed = Boolean(object.isImpersonationAllowed);
                    if (object.isAdminUser != null)
                        message.isAdminUser = Boolean(object.isAdminUser);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetAuthenticatedUserActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.user = null;
                        object.isImpersonationAllowed = false;
                        object.isAdminUser = false;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.waiternow.common.UserProto.toObject(message.user, options);
                    if (message.isImpersonationAllowed != null && message.hasOwnProperty("isImpersonationAllowed"))
                        object.isImpersonationAllowed = message.isImpersonationAllowed;
                    if (message.isAdminUser != null && message.hasOwnProperty("isAdminUser"))
                        object.isAdminUser = message.isAdminUser;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetAuthenticatedUserActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetAuthenticatedUserActionProto.Response";
                };

                return Response;
            })();

            return GetAuthenticatedUserActionProto;
        })();

        common.GetUserActionProto = (function() {

            /**
             * Properties of a GetUserActionProto.
             * @memberof waiternow.common
             * @interface IGetUserActionProto
             */

            /**
             * Constructs a new GetUserActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetUserActionProto.
             * @implements IGetUserActionProto
             * @constructor
             * @param {waiternow.common.IGetUserActionProto=} [properties] Properties to set
             */
            function GetUserActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetUserActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {waiternow.common.IGetUserActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetUserActionProto} GetUserActionProto instance
             */
            GetUserActionProto.create = function create(properties) {
                return new GetUserActionProto(properties);
            };

            /**
             * Encodes the specified GetUserActionProto message. Does not implicitly {@link waiternow.common.GetUserActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {waiternow.common.IGetUserActionProto} message GetUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetUserActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetUserActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {waiternow.common.IGetUserActionProto} message GetUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetUserActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetUserActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetUserActionProto} GetUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetUserActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetUserActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetUserActionProto} GetUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetUserActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetUserActionProto message.
             * @function verify
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetUserActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetUserActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetUserActionProto} GetUserActionProto
             */
            GetUserActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetUserActionProto)
                    return object;
                return new $root.waiternow.common.GetUserActionProto();
            };

            /**
             * Creates a plain object from a GetUserActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {waiternow.common.GetUserActionProto} message GetUserActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetUserActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetUserActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetUserActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetUserActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetUserActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetUserActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetUserActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetUserActionProto";
            };

            GetUserActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetUserActionProto
                 * @interface IRequest
                 * @property {string|null} [userId] Request userId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetUserActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetUserActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request userId.
                 * @member {string} userId
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @instance
                 */
                Request.prototype.userId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUserActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetUserActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetUserActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetUserActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetUserActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetUserActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetUserActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetUserActionProto.Request();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {waiternow.common.GetUserActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userId = "";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetUserActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetUserActionProto.Request";
                };

                return Request;
            })();

            GetUserActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetUserActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IUserProto|null} [user] Response user
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetUserActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetUserActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response user.
                 * @member {waiternow.common.IUserProto|null|undefined} user
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @instance
                 */
                Response.prototype.user = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUserActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetUserActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetUserActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.waiternow.common.UserProto.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetUserActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetUserActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.user = $root.waiternow.common.UserProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.waiternow.common.UserProto.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetUserActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetUserActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetUserActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetUserActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".waiternow.common.GetUserActionProto.Response.user: object expected");
                        message.user = $root.waiternow.common.UserProto.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {waiternow.common.GetUserActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.user = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.waiternow.common.UserProto.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetUserActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetUserActionProto.Response";
                };

                return Response;
            })();

            return GetUserActionProto;
        })();

        common.FindUserActionProto = (function() {

            /**
             * Properties of a FindUserActionProto.
             * @memberof waiternow.common
             * @interface IFindUserActionProto
             */

            /**
             * Constructs a new FindUserActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindUserActionProto.
             * @implements IFindUserActionProto
             * @constructor
             * @param {waiternow.common.IFindUserActionProto=} [properties] Properties to set
             */
            function FindUserActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindUserActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {waiternow.common.IFindUserActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindUserActionProto} FindUserActionProto instance
             */
            FindUserActionProto.create = function create(properties) {
                return new FindUserActionProto(properties);
            };

            /**
             * Encodes the specified FindUserActionProto message. Does not implicitly {@link waiternow.common.FindUserActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {waiternow.common.IFindUserActionProto} message FindUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUserActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindUserActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindUserActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {waiternow.common.IFindUserActionProto} message FindUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUserActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindUserActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindUserActionProto} FindUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUserActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUserActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindUserActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindUserActionProto} FindUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUserActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindUserActionProto message.
             * @function verify
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindUserActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindUserActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindUserActionProto} FindUserActionProto
             */
            FindUserActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindUserActionProto)
                    return object;
                return new $root.waiternow.common.FindUserActionProto();
            };

            /**
             * Creates a plain object from a FindUserActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {waiternow.common.FindUserActionProto} message FindUserActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindUserActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindUserActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindUserActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindUserActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindUserActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindUserActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindUserActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindUserActionProto";
            };

            FindUserActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindUserActionProto
                 * @interface IRequest
                 * @property {string|null} [userEmail] Request userEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindUserActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindUserActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request userEmail.
                 * @member {string} userEmail
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @instance
                 */
                Request.prototype.userEmail = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUserActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindUserActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindUserActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindUserActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUserActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUserActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUserActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindUserActionProto.Request();
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUserActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userEmail = "";
                    if (message.userEmail != null && message.hasOwnProperty("userEmail"))
                        object.userEmail = message.userEmail;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUserActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUserActionProto.Request";
                };

                return Request;
            })();

            FindUserActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindUserActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IUserProto|null} [user] Response user
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindUserActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindUserActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response user.
                 * @member {waiternow.common.IUserProto|null|undefined} user
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @instance
                 */
                Response.prototype.user = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUserActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindUserActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindUserActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.waiternow.common.UserProto.encode(message.user, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindUserActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUserActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.user = $root.waiternow.common.UserProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.waiternow.common.UserProto.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUserActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUserActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindUserActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindUserActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".waiternow.common.FindUserActionProto.Response.user: object expected");
                        message.user = $root.waiternow.common.UserProto.fromObject(object.user);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUserActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.user = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.waiternow.common.UserProto.toObject(message.user, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUserActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUserActionProto.Response";
                };

                return Response;
            })();

            return FindUserActionProto;
        })();

        common.DeleteUserActionProto = (function() {

            /**
             * Properties of a DeleteUserActionProto.
             * @memberof waiternow.common
             * @interface IDeleteUserActionProto
             */

            /**
             * Constructs a new DeleteUserActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeleteUserActionProto.
             * @implements IDeleteUserActionProto
             * @constructor
             * @param {waiternow.common.IDeleteUserActionProto=} [properties] Properties to set
             */
            function DeleteUserActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DeleteUserActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {waiternow.common.IDeleteUserActionProto=} [properties] Properties to set
             * @returns {waiternow.common.DeleteUserActionProto} DeleteUserActionProto instance
             */
            DeleteUserActionProto.create = function create(properties) {
                return new DeleteUserActionProto(properties);
            };

            /**
             * Encodes the specified DeleteUserActionProto message. Does not implicitly {@link waiternow.common.DeleteUserActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {waiternow.common.IDeleteUserActionProto} message DeleteUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteUserActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DeleteUserActionProto message, length delimited. Does not implicitly {@link waiternow.common.DeleteUserActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {waiternow.common.IDeleteUserActionProto} message DeleteUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteUserActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteUserActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeleteUserActionProto} DeleteUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteUserActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteUserActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteUserActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeleteUserActionProto} DeleteUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteUserActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteUserActionProto message.
             * @function verify
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteUserActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DeleteUserActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeleteUserActionProto} DeleteUserActionProto
             */
            DeleteUserActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeleteUserActionProto)
                    return object;
                return new $root.waiternow.common.DeleteUserActionProto();
            };

            /**
             * Creates a plain object from a DeleteUserActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {waiternow.common.DeleteUserActionProto} message DeleteUserActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteUserActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteUserActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeleteUserActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteUserActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteUserActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeleteUserActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteUserActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeleteUserActionProto";
            };

            DeleteUserActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.DeleteUserActionProto
                 * @interface IRequest
                 * @property {string|null} [userId] Request userId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.DeleteUserActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.DeleteUserActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request userId.
                 * @member {string} userId
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @instance
                 */
                Request.prototype.userId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteUserActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.DeleteUserActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.DeleteUserActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteUserActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteUserActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteUserActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.DeleteUserActionProto.Request();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userId = "";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteUserActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteUserActionProto.Request";
                };

                return Request;
            })();

            DeleteUserActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.DeleteUserActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.DeleteUserActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.DeleteUserActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteUserActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.DeleteUserActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.DeleteUserActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteUserActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteUserActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteUserActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.DeleteUserActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.DeleteUserActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteUserActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteUserActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteUserActionProto.Response";
                };

                return Response;
            })();

            return DeleteUserActionProto;
        })();

        common.UpdateUserActionProto = (function() {

            /**
             * Properties of an UpdateUserActionProto.
             * @memberof waiternow.common
             * @interface IUpdateUserActionProto
             */

            /**
             * Constructs a new UpdateUserActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateUserActionProto.
             * @implements IUpdateUserActionProto
             * @constructor
             * @param {waiternow.common.IUpdateUserActionProto=} [properties] Properties to set
             */
            function UpdateUserActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateUserActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {waiternow.common.IUpdateUserActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateUserActionProto} UpdateUserActionProto instance
             */
            UpdateUserActionProto.create = function create(properties) {
                return new UpdateUserActionProto(properties);
            };

            /**
             * Encodes the specified UpdateUserActionProto message. Does not implicitly {@link waiternow.common.UpdateUserActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {waiternow.common.IUpdateUserActionProto} message UpdateUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateUserActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateUserActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {waiternow.common.IUpdateUserActionProto} message UpdateUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateUserActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateUserActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateUserActionProto} UpdateUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateUserActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateUserActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateUserActionProto} UpdateUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateUserActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateUserActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateUserActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateUserActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateUserActionProto} UpdateUserActionProto
             */
            UpdateUserActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateUserActionProto)
                    return object;
                return new $root.waiternow.common.UpdateUserActionProto();
            };

            /**
             * Creates a plain object from an UpdateUserActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {waiternow.common.UpdateUserActionProto} message UpdateUserActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateUserActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateUserActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateUserActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateUserActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateUserActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateUserActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateUserActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateUserActionProto";
            };

            UpdateUserActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateUserActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IUserProto|null} [user] Request user
                 * @property {Array.<waiternow.common.UpdateUserActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateUserActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateUserActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request user.
                 * @member {waiternow.common.IUserProto|null|undefined} user
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @instance
                 */
                Request.prototype.user = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateUserActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateUserActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateUserActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.user != null && Object.hasOwnProperty.call(message, "user"))
                        $root.waiternow.common.UserProto.encode(message.user, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateUserActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateUserActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.user = $root.waiternow.common.UserProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.user != null && message.hasOwnProperty("user")) {
                        var error = $root.waiternow.common.UserProto.verify(message.user);
                        if (error)
                            return "user." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateUserActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateUserActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdateUserActionProto.Request();
                    if (object.user != null) {
                        if (typeof object.user !== "object")
                            throw TypeError(".waiternow.common.UpdateUserActionProto.Request.user: object expected");
                        message.user = $root.waiternow.common.UserProto.fromObject(object.user);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateUserActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            case "PHONE_NUMBER":
                            case 1:
                                message.fieldsToRemove[i] = 1;
                                break;
                            case "SETTINGS_PREFERRED_CONTACT_MEDIUM":
                            case 2:
                                message.fieldsToRemove[i] = 2;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldsToRemove = [];
                    if (options.defaults)
                        object.user = null;
                    if (message.user != null && message.hasOwnProperty("user"))
                        object.user = $root.waiternow.common.UserProto.toObject(message.user, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateUserActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdateUserActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateUserActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateUserActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdateUserActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 * @property {number} PHONE_NUMBER=1 PHONE_NUMBER value
                 * @property {number} SETTINGS_PREFERRED_CONTACT_MEDIUM=2 SETTINGS_PREFERRED_CONTACT_MEDIUM value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "PHONE_NUMBER"] = 1;
                    values[valuesById[2] = "SETTINGS_PREFERRED_CONTACT_MEDIUM"] = 2;
                    return values;
                })();

                return Request;
            })();

            UpdateUserActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateUserActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateUserActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateUserActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateUserActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateUserActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateUserActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateUserActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateUserActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateUserActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateUserActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateUserActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateUserActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateUserActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateUserActionProto.Response";
                };

                return Response;
            })();

            return UpdateUserActionProto;
        })();

        common.ChangePasswordActionProto = (function() {

            /**
             * Properties of a ChangePasswordActionProto.
             * @memberof waiternow.common
             * @interface IChangePasswordActionProto
             */

            /**
             * Constructs a new ChangePasswordActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a ChangePasswordActionProto.
             * @implements IChangePasswordActionProto
             * @constructor
             * @param {waiternow.common.IChangePasswordActionProto=} [properties] Properties to set
             */
            function ChangePasswordActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ChangePasswordActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {waiternow.common.IChangePasswordActionProto=} [properties] Properties to set
             * @returns {waiternow.common.ChangePasswordActionProto} ChangePasswordActionProto instance
             */
            ChangePasswordActionProto.create = function create(properties) {
                return new ChangePasswordActionProto(properties);
            };

            /**
             * Encodes the specified ChangePasswordActionProto message. Does not implicitly {@link waiternow.common.ChangePasswordActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {waiternow.common.IChangePasswordActionProto} message ChangePasswordActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangePasswordActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ChangePasswordActionProto message, length delimited. Does not implicitly {@link waiternow.common.ChangePasswordActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {waiternow.common.IChangePasswordActionProto} message ChangePasswordActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ChangePasswordActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ChangePasswordActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ChangePasswordActionProto} ChangePasswordActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangePasswordActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ChangePasswordActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ChangePasswordActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ChangePasswordActionProto} ChangePasswordActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ChangePasswordActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ChangePasswordActionProto message.
             * @function verify
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ChangePasswordActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ChangePasswordActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ChangePasswordActionProto} ChangePasswordActionProto
             */
            ChangePasswordActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ChangePasswordActionProto)
                    return object;
                return new $root.waiternow.common.ChangePasswordActionProto();
            };

            /**
             * Creates a plain object from a ChangePasswordActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {waiternow.common.ChangePasswordActionProto} message ChangePasswordActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ChangePasswordActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ChangePasswordActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ChangePasswordActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ChangePasswordActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ChangePasswordActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.ChangePasswordActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ChangePasswordActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ChangePasswordActionProto";
            };

            ChangePasswordActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.ChangePasswordActionProto
                 * @interface IRequest
                 * @property {string|null} [currentPassword] Request currentPassword
                 * @property {string|null} [newPassword] Request newPassword
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.ChangePasswordActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.ChangePasswordActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request currentPassword.
                 * @member {string} currentPassword
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @instance
                 */
                Request.prototype.currentPassword = "";

                /**
                 * Request newPassword.
                 * @member {string} newPassword
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @instance
                 */
                Request.prototype.newPassword = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.ChangePasswordActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.ChangePasswordActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.currentPassword != null && Object.hasOwnProperty.call(message, "currentPassword"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.currentPassword);
                    if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPassword);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.ChangePasswordActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ChangePasswordActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ChangePasswordActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.currentPassword = reader.string();
                                break;
                            }
                        case 2: {
                                message.newPassword = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ChangePasswordActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.currentPassword != null && message.hasOwnProperty("currentPassword"))
                        if (!$util.isString(message.currentPassword))
                            return "currentPassword: string expected";
                    if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                        if (!$util.isString(message.newPassword))
                            return "newPassword: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ChangePasswordActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ChangePasswordActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.ChangePasswordActionProto.Request();
                    if (object.currentPassword != null)
                        message.currentPassword = String(object.currentPassword);
                    if (object.newPassword != null)
                        message.newPassword = String(object.newPassword);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.currentPassword = "";
                        object.newPassword = "";
                    }
                    if (message.currentPassword != null && message.hasOwnProperty("currentPassword"))
                        object.currentPassword = message.currentPassword;
                    if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                        object.newPassword = message.newPassword;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.ChangePasswordActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ChangePasswordActionProto.Request";
                };

                return Request;
            })();

            ChangePasswordActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.ChangePasswordActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.ChangePasswordActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.ChangePasswordActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.ChangePasswordActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.ChangePasswordActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.ChangePasswordActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ChangePasswordActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ChangePasswordActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ChangePasswordActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ChangePasswordActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ChangePasswordActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.ChangePasswordActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.ChangePasswordActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ChangePasswordActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.ChangePasswordActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ChangePasswordActionProto.Response";
                };

                return Response;
            })();

            return ChangePasswordActionProto;
        })();

        common.ResetPasswordActionProto = (function() {

            /**
             * Properties of a ResetPasswordActionProto.
             * @memberof waiternow.common
             * @interface IResetPasswordActionProto
             */

            /**
             * Constructs a new ResetPasswordActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a ResetPasswordActionProto.
             * @implements IResetPasswordActionProto
             * @constructor
             * @param {waiternow.common.IResetPasswordActionProto=} [properties] Properties to set
             */
            function ResetPasswordActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new ResetPasswordActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {waiternow.common.IResetPasswordActionProto=} [properties] Properties to set
             * @returns {waiternow.common.ResetPasswordActionProto} ResetPasswordActionProto instance
             */
            ResetPasswordActionProto.create = function create(properties) {
                return new ResetPasswordActionProto(properties);
            };

            /**
             * Encodes the specified ResetPasswordActionProto message. Does not implicitly {@link waiternow.common.ResetPasswordActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {waiternow.common.IResetPasswordActionProto} message ResetPasswordActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetPasswordActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified ResetPasswordActionProto message, length delimited. Does not implicitly {@link waiternow.common.ResetPasswordActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {waiternow.common.IResetPasswordActionProto} message ResetPasswordActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            ResetPasswordActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a ResetPasswordActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.ResetPasswordActionProto} ResetPasswordActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetPasswordActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ResetPasswordActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a ResetPasswordActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.ResetPasswordActionProto} ResetPasswordActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            ResetPasswordActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a ResetPasswordActionProto message.
             * @function verify
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            ResetPasswordActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a ResetPasswordActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.ResetPasswordActionProto} ResetPasswordActionProto
             */
            ResetPasswordActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.ResetPasswordActionProto)
                    return object;
                return new $root.waiternow.common.ResetPasswordActionProto();
            };

            /**
             * Creates a plain object from a ResetPasswordActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {waiternow.common.ResetPasswordActionProto} message ResetPasswordActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            ResetPasswordActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this ResetPasswordActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.ResetPasswordActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            ResetPasswordActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for ResetPasswordActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.ResetPasswordActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            ResetPasswordActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.ResetPasswordActionProto";
            };

            ResetPasswordActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.ResetPasswordActionProto
                 * @interface IRequest
                 * @property {string|null} [email] Request email
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.ResetPasswordActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.ResetPasswordActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request email.
                 * @member {string} email
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @instance
                 */
                Request.prototype.email = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.ResetPasswordActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.ResetPasswordActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.email != null && Object.hasOwnProperty.call(message, "email"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.email);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.ResetPasswordActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ResetPasswordActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ResetPasswordActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.email = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ResetPasswordActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.email != null && message.hasOwnProperty("email"))
                        if (!$util.isString(message.email))
                            return "email: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ResetPasswordActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ResetPasswordActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.ResetPasswordActionProto.Request();
                    if (object.email != null)
                        message.email = String(object.email);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.email = "";
                    if (message.email != null && message.hasOwnProperty("email"))
                        object.email = message.email;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.ResetPasswordActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ResetPasswordActionProto.Request";
                };

                return Request;
            })();

            ResetPasswordActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.ResetPasswordActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.ResetPasswordActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.ResetPasswordActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.ResetPasswordActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.ResetPasswordActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.ResetPasswordActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.ResetPasswordActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.ResetPasswordActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.ResetPasswordActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.ResetPasswordActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.ResetPasswordActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.ResetPasswordActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.ResetPasswordActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.ResetPasswordActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.ResetPasswordActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.ResetPasswordActionProto.Response";
                };

                return Response;
            })();

            return ResetPasswordActionProto;
        })();

        common.VerifyResetPasswordActionProto = (function() {

            /**
             * Properties of a VerifyResetPasswordActionProto.
             * @memberof waiternow.common
             * @interface IVerifyResetPasswordActionProto
             */

            /**
             * Constructs a new VerifyResetPasswordActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a VerifyResetPasswordActionProto.
             * @implements IVerifyResetPasswordActionProto
             * @constructor
             * @param {waiternow.common.IVerifyResetPasswordActionProto=} [properties] Properties to set
             */
            function VerifyResetPasswordActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new VerifyResetPasswordActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {waiternow.common.IVerifyResetPasswordActionProto=} [properties] Properties to set
             * @returns {waiternow.common.VerifyResetPasswordActionProto} VerifyResetPasswordActionProto instance
             */
            VerifyResetPasswordActionProto.create = function create(properties) {
                return new VerifyResetPasswordActionProto(properties);
            };

            /**
             * Encodes the specified VerifyResetPasswordActionProto message. Does not implicitly {@link waiternow.common.VerifyResetPasswordActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {waiternow.common.IVerifyResetPasswordActionProto} message VerifyResetPasswordActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyResetPasswordActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified VerifyResetPasswordActionProto message, length delimited. Does not implicitly {@link waiternow.common.VerifyResetPasswordActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {waiternow.common.IVerifyResetPasswordActionProto} message VerifyResetPasswordActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyResetPasswordActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VerifyResetPasswordActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.VerifyResetPasswordActionProto} VerifyResetPasswordActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyResetPasswordActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VerifyResetPasswordActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VerifyResetPasswordActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.VerifyResetPasswordActionProto} VerifyResetPasswordActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyResetPasswordActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VerifyResetPasswordActionProto message.
             * @function verify
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerifyResetPasswordActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a VerifyResetPasswordActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.VerifyResetPasswordActionProto} VerifyResetPasswordActionProto
             */
            VerifyResetPasswordActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.VerifyResetPasswordActionProto)
                    return object;
                return new $root.waiternow.common.VerifyResetPasswordActionProto();
            };

            /**
             * Creates a plain object from a VerifyResetPasswordActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {waiternow.common.VerifyResetPasswordActionProto} message VerifyResetPasswordActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerifyResetPasswordActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this VerifyResetPasswordActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerifyResetPasswordActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for VerifyResetPasswordActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.VerifyResetPasswordActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VerifyResetPasswordActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.VerifyResetPasswordActionProto";
            };

            VerifyResetPasswordActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.VerifyResetPasswordActionProto
                 * @interface IRequest
                 * @property {string|null} [authenticityToken] Request authenticityToken
                 * @property {string|null} [newPassword] Request newPassword
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.VerifyResetPasswordActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request authenticityToken.
                 * @member {string} authenticityToken
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @instance
                 */
                Request.prototype.authenticityToken = "";

                /**
                 * Request newPassword.
                 * @member {string} newPassword
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @instance
                 */
                Request.prototype.newPassword = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.VerifyResetPasswordActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.authenticityToken != null && Object.hasOwnProperty.call(message, "authenticityToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.authenticityToken);
                    if (message.newPassword != null && Object.hasOwnProperty.call(message, "newPassword"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.newPassword);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.VerifyResetPasswordActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VerifyResetPasswordActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.authenticityToken = reader.string();
                                break;
                            }
                        case 2: {
                                message.newPassword = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.authenticityToken != null && message.hasOwnProperty("authenticityToken"))
                        if (!$util.isString(message.authenticityToken))
                            return "authenticityToken: string expected";
                    if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                        if (!$util.isString(message.newPassword))
                            return "newPassword: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.VerifyResetPasswordActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.VerifyResetPasswordActionProto.Request();
                    if (object.authenticityToken != null)
                        message.authenticityToken = String(object.authenticityToken);
                    if (object.newPassword != null)
                        message.newPassword = String(object.newPassword);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.authenticityToken = "";
                        object.newPassword = "";
                    }
                    if (message.authenticityToken != null && message.hasOwnProperty("authenticityToken"))
                        object.authenticityToken = message.authenticityToken;
                    if (message.newPassword != null && message.hasOwnProperty("newPassword"))
                        object.newPassword = message.newPassword;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.VerifyResetPasswordActionProto.Request";
                };

                return Request;
            })();

            VerifyResetPasswordActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.VerifyResetPasswordActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.VerifyResetPasswordActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.VerifyResetPasswordActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.VerifyResetPasswordActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VerifyResetPasswordActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.VerifyResetPasswordActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.VerifyResetPasswordActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.VerifyResetPasswordActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.VerifyResetPasswordActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyResetPasswordActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.VerifyResetPasswordActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.VerifyResetPasswordActionProto.Response";
                };

                return Response;
            })();

            return VerifyResetPasswordActionProto;
        })();

        common.CreateBusinessActionProto = (function() {

            /**
             * Properties of a CreateBusinessActionProto.
             * @memberof waiternow.common
             * @interface ICreateBusinessActionProto
             */

            /**
             * Constructs a new CreateBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CreateBusinessActionProto.
             * @implements ICreateBusinessActionProto
             * @constructor
             * @param {waiternow.common.ICreateBusinessActionProto=} [properties] Properties to set
             */
            function CreateBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CreateBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {waiternow.common.ICreateBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CreateBusinessActionProto} CreateBusinessActionProto instance
             */
            CreateBusinessActionProto.create = function create(properties) {
                return new CreateBusinessActionProto(properties);
            };

            /**
             * Encodes the specified CreateBusinessActionProto message. Does not implicitly {@link waiternow.common.CreateBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {waiternow.common.ICreateBusinessActionProto} message CreateBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CreateBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.CreateBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {waiternow.common.ICreateBusinessActionProto} message CreateBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CreateBusinessActionProto} CreateBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CreateBusinessActionProto} CreateBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CreateBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CreateBusinessActionProto} CreateBusinessActionProto
             */
            CreateBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CreateBusinessActionProto)
                    return object;
                return new $root.waiternow.common.CreateBusinessActionProto();
            };

            /**
             * Creates a plain object from a CreateBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {waiternow.common.CreateBusinessActionProto} message CreateBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreateBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CreateBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CreateBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CreateBusinessActionProto";
            };

            CreateBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CreateBusinessActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IBusinessProto|null} [business] Request business
                 * @property {string|null} [ownerUserId] Request ownerUserId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CreateBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CreateBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request business.
                 * @member {waiternow.common.IBusinessProto|null|undefined} business
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.business = null;

                /**
                 * Request ownerUserId.
                 * @member {string} ownerUserId
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.ownerUserId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CreateBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CreateBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.business != null && Object.hasOwnProperty.call(message, "business"))
                        $root.waiternow.common.BusinessProto.encode(message.business, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.ownerUserId != null && Object.hasOwnProperty.call(message, "ownerUserId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.ownerUserId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CreateBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.business = $root.waiternow.common.BusinessProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.ownerUserId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.business != null && message.hasOwnProperty("business")) {
                        var error = $root.waiternow.common.BusinessProto.verify(message.business);
                        if (error)
                            return "business." + error;
                    }
                    if (message.ownerUserId != null && message.hasOwnProperty("ownerUserId"))
                        if (!$util.isString(message.ownerUserId))
                            return "ownerUserId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CreateBusinessActionProto.Request();
                    if (object.business != null) {
                        if (typeof object.business !== "object")
                            throw TypeError(".waiternow.common.CreateBusinessActionProto.Request.business: object expected");
                        message.business = $root.waiternow.common.BusinessProto.fromObject(object.business);
                    }
                    if (object.ownerUserId != null)
                        message.ownerUserId = String(object.ownerUserId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.business = null;
                        object.ownerUserId = "";
                    }
                    if (message.business != null && message.hasOwnProperty("business"))
                        object.business = $root.waiternow.common.BusinessProto.toObject(message.business, options);
                    if (message.ownerUserId != null && message.hasOwnProperty("ownerUserId"))
                        object.ownerUserId = message.ownerUserId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateBusinessActionProto.Request";
                };

                return Request;
            })();

            CreateBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CreateBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IBusinessProto|null} [business] Response business
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CreateBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CreateBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response business.
                 * @member {waiternow.common.IBusinessProto|null|undefined} business
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.business = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CreateBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CreateBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.business != null && Object.hasOwnProperty.call(message, "business"))
                        $root.waiternow.common.BusinessProto.encode(message.business, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CreateBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.business = $root.waiternow.common.BusinessProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.business != null && message.hasOwnProperty("business")) {
                        var error = $root.waiternow.common.BusinessProto.verify(message.business);
                        if (error)
                            return "business." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CreateBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CreateBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.business != null) {
                        if (typeof object.business !== "object")
                            throw TypeError(".waiternow.common.CreateBusinessActionProto.Response.business: object expected");
                        message.business = $root.waiternow.common.BusinessProto.fromObject(object.business);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.business = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.business != null && message.hasOwnProperty("business"))
                        object.business = $root.waiternow.common.BusinessProto.toObject(message.business, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateBusinessActionProto.Response";
                };

                return Response;
            })();

            return CreateBusinessActionProto;
        })();

        common.UpdateBusinessActionProto = (function() {

            /**
             * Properties of an UpdateBusinessActionProto.
             * @memberof waiternow.common
             * @interface IUpdateBusinessActionProto
             */

            /**
             * Constructs a new UpdateBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateBusinessActionProto.
             * @implements IUpdateBusinessActionProto
             * @constructor
             * @param {waiternow.common.IUpdateBusinessActionProto=} [properties] Properties to set
             */
            function UpdateBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateBusinessActionProto} UpdateBusinessActionProto instance
             */
            UpdateBusinessActionProto.create = function create(properties) {
                return new UpdateBusinessActionProto(properties);
            };

            /**
             * Encodes the specified UpdateBusinessActionProto message. Does not implicitly {@link waiternow.common.UpdateBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessActionProto} message UpdateBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessActionProto} message UpdateBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateBusinessActionProto} UpdateBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateBusinessActionProto} UpdateBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateBusinessActionProto} UpdateBusinessActionProto
             */
            UpdateBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateBusinessActionProto)
                    return object;
                return new $root.waiternow.common.UpdateBusinessActionProto();
            };

            /**
             * Creates a plain object from an UpdateBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {waiternow.common.UpdateBusinessActionProto} message UpdateBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateBusinessActionProto";
            };

            UpdateBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateBusinessActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IBusinessProto|null} [business] Request business
                 * @property {Array.<waiternow.common.UpdateBusinessActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 * @property {Array.<waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField>|null} [locationSettingsFieldsToRemove] Request locationSettingsFieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    this.locationSettingsFieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request business.
                 * @member {waiternow.common.IBusinessProto|null|undefined} business
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.business = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateBusinessActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Request locationSettingsFieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField>} locationSettingsFieldsToRemove
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.locationSettingsFieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.business != null && Object.hasOwnProperty.call(message, "business"))
                        $root.waiternow.common.BusinessProto.encode(message.business, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    if (message.locationSettingsFieldsToRemove != null && message.locationSettingsFieldsToRemove.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.locationSettingsFieldsToRemove.length; ++i)
                            writer.int32(message.locationSettingsFieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.business = $root.waiternow.common.BusinessProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        case 3: {
                                if (!(message.locationSettingsFieldsToRemove && message.locationSettingsFieldsToRemove.length))
                                    message.locationSettingsFieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.locationSettingsFieldsToRemove.push(reader.int32());
                                } else
                                    message.locationSettingsFieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.business != null && message.hasOwnProperty("business")) {
                        var error = $root.waiternow.common.BusinessProto.verify(message.business);
                        if (error)
                            return "business." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    if (message.locationSettingsFieldsToRemove != null && message.hasOwnProperty("locationSettingsFieldsToRemove")) {
                        if (!Array.isArray(message.locationSettingsFieldsToRemove))
                            return "locationSettingsFieldsToRemove: array expected";
                        for (var i = 0; i < message.locationSettingsFieldsToRemove.length; ++i)
                            switch (message.locationSettingsFieldsToRemove[i]) {
                            default:
                                return "locationSettingsFieldsToRemove: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 22:
                            case 23:
                            case 28:
                            case 29:
                            case 31:
                            case 32:
                            case 33:
                            case 34:
                            case 35:
                            case 36:
                            case 37:
                            case 38:
                            case 20:
                            case 21:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 30:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdateBusinessActionProto.Request();
                    if (object.business != null) {
                        if (typeof object.business !== "object")
                            throw TypeError(".waiternow.common.UpdateBusinessActionProto.Request.business: object expected");
                        message.business = $root.waiternow.common.BusinessProto.fromObject(object.business);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateBusinessActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            case "SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_ID":
                            case 1:
                                message.fieldsToRemove[i] = 1;
                                break;
                            case "SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_NAME":
                            case 2:
                                message.fieldsToRemove[i] = 2;
                                break;
                            case "SETTINGS_BILLING_INFO_BILLING_ADDRESS":
                            case 3:
                                message.fieldsToRemove[i] = 3;
                                break;
                            case "DESCRIPTION":
                            case 4:
                                message.fieldsToRemove[i] = 4;
                                break;
                            }
                    }
                    if (object.locationSettingsFieldsToRemove) {
                        if (!Array.isArray(object.locationSettingsFieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateBusinessActionProto.Request.locationSettingsFieldsToRemove: array expected");
                        message.locationSettingsFieldsToRemove = [];
                        for (var i = 0; i < object.locationSettingsFieldsToRemove.length; ++i)
                            switch (object.locationSettingsFieldsToRemove[i]) {
                            default:
                                if (typeof object.locationSettingsFieldsToRemove[i] === "number") {
                                    message.locationSettingsFieldsToRemove[i] = object.locationSettingsFieldsToRemove[i];
                                    break;
                                }
                            case "SETTINGS_REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.locationSettingsFieldsToRemove[i] = 0;
                                break;
                            case "LOCALE_LANGUAGE":
                            case 1:
                                message.locationSettingsFieldsToRemove[i] = 1;
                                break;
                            case "LOCALE_CURRENCY_CODE":
                            case 2:
                                message.locationSettingsFieldsToRemove[i] = 2;
                                break;
                            case "LOCALE_TIMEZONE":
                            case 3:
                                message.locationSettingsFieldsToRemove[i] = 3;
                                break;
                            case "VIEW_THEME":
                            case 4:
                                message.locationSettingsFieldsToRemove[i] = 4;
                                break;
                            case "VIEW_APP_MODE":
                            case 5:
                                message.locationSettingsFieldsToRemove[i] = 5;
                                break;
                            case "EXTERNAL_LINKS_WEBSITE":
                            case 6:
                                message.locationSettingsFieldsToRemove[i] = 6;
                                break;
                            case "EXTERNAL_LINKS_YOUTUBE":
                            case 7:
                                message.locationSettingsFieldsToRemove[i] = 7;
                                break;
                            case "EXTERNAL_LINKS_FACEBOOK":
                            case 8:
                                message.locationSettingsFieldsToRemove[i] = 8;
                                break;
                            case "EXTERNAL_LINKS_INSTAGRAM":
                            case 9:
                                message.locationSettingsFieldsToRemove[i] = 9;
                                break;
                            case "EXTERNAL_LINKS_TWITTER":
                            case 10:
                                message.locationSettingsFieldsToRemove[i] = 10;
                                break;
                            case "EXTERNAL_LINKS_LINKED_IN":
                            case 11:
                                message.locationSettingsFieldsToRemove[i] = 11;
                                break;
                            case "EXTERNAL_LINKS_TIKTOK":
                            case 12:
                                message.locationSettingsFieldsToRemove[i] = 12;
                                break;
                            case "COLOR_PALETTE":
                            case 13:
                                message.locationSettingsFieldsToRemove[i] = 13;
                                break;
                            case "ENABLED_FEATURES_PAYMENT_REQUIRED_FOR_ORDERS":
                            case 14:
                                message.locationSettingsFieldsToRemove[i] = 14;
                                break;
                            case "ENABLED_FEATURES_POKE_WAITER_DISABLED":
                            case 15:
                                message.locationSettingsFieldsToRemove[i] = 15;
                                break;
                            case "ENABLED_FEATURES_STRUCTURED_MENU_DISABLED":
                            case 16:
                                message.locationSettingsFieldsToRemove[i] = 16;
                                break;
                            case "ENABLED_FEATURES_IMAGE_MENU_DISABLED":
                            case 17:
                                message.locationSettingsFieldsToRemove[i] = 17;
                                break;
                            case "ENABLED_FEATURES_MESSAGE_WAITER_DISABLED":
                            case 18:
                                message.locationSettingsFieldsToRemove[i] = 18;
                                break;
                            case "ENABLED_FEATURES_ASK_FOR_CHECK_DISABLED":
                            case 19:
                                message.locationSettingsFieldsToRemove[i] = 19;
                                break;
                            case "ENABLED_FEATURES_SPECIAL_INSTRUCTIONS_DISABLED":
                            case 22:
                                message.locationSettingsFieldsToRemove[i] = 22;
                                break;
                            case "ENABLED_FEATURES_COMPLETED_STATUS_SUPPORTED_FOR_PAID_ORDERS":
                            case 23:
                                message.locationSettingsFieldsToRemove[i] = 23;
                                break;
                            case "ENABLED_FEATURES_IS_ONSITE_TIPS_DISABLED":
                            case 28:
                                message.locationSettingsFieldsToRemove[i] = 28;
                                break;
                            case "ENABLED_FEATURES_IS_ONLINE_TIPS_DISABLED":
                            case 29:
                                message.locationSettingsFieldsToRemove[i] = 29;
                                break;
                            case "ENABLED_FEATURES_IS_DELIVERY_DISABLED":
                            case 31:
                                message.locationSettingsFieldsToRemove[i] = 31;
                                break;
                            case "ENABLED_FEATURES_CHARGE_CREDIT_CARD_FEE_TO_CONSUMER":
                            case 32:
                                message.locationSettingsFieldsToRemove[i] = 32;
                                break;
                            case "ENABLED_FEATURES_IS_ALCOHOL_FOR_PICKUP_ALLOWED":
                            case 33:
                                message.locationSettingsFieldsToRemove[i] = 33;
                                break;
                            case "ENABLED_FEATURES_IS_ALCOHOL_FOR_DELIVERY_ALLOWED":
                            case 34:
                                message.locationSettingsFieldsToRemove[i] = 34;
                                break;
                            case "ENABLED_FEATURES_PRINT_ORDER_AND_RECEIPT":
                            case 35:
                                message.locationSettingsFieldsToRemove[i] = 35;
                                break;
                            case "ENABLED_FEATURES_IS_ORDER_FOR_LATER_DISABLED":
                            case 36:
                                message.locationSettingsFieldsToRemove[i] = 36;
                                break;
                            case "ENABLED_FEATURES_IS_ORDER_FOR_LATER_DAY_DISABLED":
                            case 37:
                                message.locationSettingsFieldsToRemove[i] = 37;
                                break;
                            case "ENABLED_FEATURES_IS_SMS_ON_NEW_ORDERS_FOR_BUSINESS_WEB_ENABLED":
                            case 38:
                                message.locationSettingsFieldsToRemove[i] = 38;
                                break;
                            case "TAXES":
                            case 20:
                                message.locationSettingsFieldsToRemove[i] = 20;
                                break;
                            case "PRINTERS":
                            case 21:
                                message.locationSettingsFieldsToRemove[i] = 21;
                                break;
                            case "ORDER_PAYMENT_WAITERNOW_FEE":
                            case 24:
                                message.locationSettingsFieldsToRemove[i] = 24;
                                break;
                            case "BUSINESS_HOURS":
                            case 25:
                                message.locationSettingsFieldsToRemove[i] = 25;
                                break;
                            case "ALERT_CONFIG":
                            case 26:
                                message.locationSettingsFieldsToRemove[i] = 26;
                                break;
                            case "ORDER_PREPARATION_DURATION":
                            case 27:
                                message.locationSettingsFieldsToRemove[i] = 27;
                                break;
                            case "DELIVERY_CONFIG":
                            case 30:
                                message.locationSettingsFieldsToRemove[i] = 30;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.fieldsToRemove = [];
                        object.locationSettingsFieldsToRemove = [];
                    }
                    if (options.defaults)
                        object.business = null;
                    if (message.business != null && message.hasOwnProperty("business"))
                        object.business = $root.waiternow.common.BusinessProto.toObject(message.business, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateBusinessActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdateBusinessActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    if (message.locationSettingsFieldsToRemove && message.locationSettingsFieldsToRemove.length) {
                        object.locationSettingsFieldsToRemove = [];
                        for (var j = 0; j < message.locationSettingsFieldsToRemove.length; ++j)
                            object.locationSettingsFieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField[message.locationSettingsFieldsToRemove[j]] === undefined ? message.locationSettingsFieldsToRemove[j] : $root.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField[message.locationSettingsFieldsToRemove[j]] : message.locationSettingsFieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdateBusinessActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 * @property {number} SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_ID=1 SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_ID value
                 * @property {number} SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_NAME=2 SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_NAME value
                 * @property {number} SETTINGS_BILLING_INFO_BILLING_ADDRESS=3 SETTINGS_BILLING_INFO_BILLING_ADDRESS value
                 * @property {number} DESCRIPTION=4 DESCRIPTION value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_ID"] = 1;
                    values[valuesById[2] = "SETTINGS_BILLING_INFO_BUSINESS_OFFICIAL_NAME"] = 2;
                    values[valuesById[3] = "SETTINGS_BILLING_INFO_BILLING_ADDRESS"] = 3;
                    values[valuesById[4] = "DESCRIPTION"] = 4;
                    return values;
                })();

                return Request;
            })();

            UpdateBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessActionProto.Response";
                };

                return Response;
            })();

            return UpdateBusinessActionProto;
        })();

        common.UpdateBusinessLogoActionProto = (function() {

            /**
             * Properties of an UpdateBusinessLogoActionProto.
             * @memberof waiternow.common
             * @interface IUpdateBusinessLogoActionProto
             */

            /**
             * Constructs a new UpdateBusinessLogoActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateBusinessLogoActionProto.
             * @implements IUpdateBusinessLogoActionProto
             * @constructor
             * @param {waiternow.common.IUpdateBusinessLogoActionProto=} [properties] Properties to set
             */
            function UpdateBusinessLogoActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateBusinessLogoActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessLogoActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateBusinessLogoActionProto} UpdateBusinessLogoActionProto instance
             */
            UpdateBusinessLogoActionProto.create = function create(properties) {
                return new UpdateBusinessLogoActionProto(properties);
            };

            /**
             * Encodes the specified UpdateBusinessLogoActionProto message. Does not implicitly {@link waiternow.common.UpdateBusinessLogoActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessLogoActionProto} message UpdateBusinessLogoActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessLogoActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateBusinessLogoActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessLogoActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessLogoActionProto} message UpdateBusinessLogoActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessLogoActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateBusinessLogoActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateBusinessLogoActionProto} UpdateBusinessLogoActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessLogoActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessLogoActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateBusinessLogoActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateBusinessLogoActionProto} UpdateBusinessLogoActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessLogoActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateBusinessLogoActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateBusinessLogoActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateBusinessLogoActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateBusinessLogoActionProto} UpdateBusinessLogoActionProto
             */
            UpdateBusinessLogoActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateBusinessLogoActionProto)
                    return object;
                return new $root.waiternow.common.UpdateBusinessLogoActionProto();
            };

            /**
             * Creates a plain object from an UpdateBusinessLogoActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {waiternow.common.UpdateBusinessLogoActionProto} message UpdateBusinessLogoActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateBusinessLogoActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateBusinessLogoActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateBusinessLogoActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateBusinessLogoActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateBusinessLogoActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateBusinessLogoActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateBusinessLogoActionProto";
            };

            UpdateBusinessLogoActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateBusinessLogoActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessLogoActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessLogoActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessLogoActionProto.Request)
                        return object;
                    return new $root.waiternow.common.UpdateBusinessLogoActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessLogoActionProto.Request";
                };

                return Request;
            })();

            UpdateBusinessLogoActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateBusinessLogoActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessLogoActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessLogoActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessLogoActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessLogoActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateBusinessLogoActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateBusinessLogoActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessLogoActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessLogoActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessLogoActionProto.Response";
                };

                return Response;
            })();

            return UpdateBusinessLogoActionProto;
        })();

        common.UpdateBusinessHeaderImageForLandscapeScreenActionProto = (function() {

            /**
             * Properties of an UpdateBusinessHeaderImageForLandscapeScreenActionProto.
             * @memberof waiternow.common
             * @interface IUpdateBusinessHeaderImageForLandscapeScreenActionProto
             */

            /**
             * Constructs a new UpdateBusinessHeaderImageForLandscapeScreenActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateBusinessHeaderImageForLandscapeScreenActionProto.
             * @implements IUpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @constructor
             * @param {waiternow.common.IUpdateBusinessHeaderImageForLandscapeScreenActionProto=} [properties] Properties to set
             */
            function UpdateBusinessHeaderImageForLandscapeScreenActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateBusinessHeaderImageForLandscapeScreenActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessHeaderImageForLandscapeScreenActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto} UpdateBusinessHeaderImageForLandscapeScreenActionProto instance
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.create = function create(properties) {
                return new UpdateBusinessHeaderImageForLandscapeScreenActionProto(properties);
            };

            /**
             * Encodes the specified UpdateBusinessHeaderImageForLandscapeScreenActionProto message. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessHeaderImageForLandscapeScreenActionProto} message UpdateBusinessHeaderImageForLandscapeScreenActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateBusinessHeaderImageForLandscapeScreenActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessHeaderImageForLandscapeScreenActionProto} message UpdateBusinessHeaderImageForLandscapeScreenActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateBusinessHeaderImageForLandscapeScreenActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto} UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateBusinessHeaderImageForLandscapeScreenActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto} UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateBusinessHeaderImageForLandscapeScreenActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateBusinessHeaderImageForLandscapeScreenActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto} UpdateBusinessHeaderImageForLandscapeScreenActionProto
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto)
                    return object;
                return new $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto();
            };

            /**
             * Creates a plain object from an UpdateBusinessHeaderImageForLandscapeScreenActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto} message UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateBusinessHeaderImageForLandscapeScreenActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateBusinessHeaderImageForLandscapeScreenActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto";
            };

            UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request)
                        return object;
                    return new $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Request";
                };

                return Request;
            })();

            UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessHeaderImageForLandscapeScreenActionProto.Response";
                };

                return Response;
            })();

            return UpdateBusinessHeaderImageForLandscapeScreenActionProto;
        })();

        common.UpdateBusinessHeaderImageForPortraitScreenActionProto = (function() {

            /**
             * Properties of an UpdateBusinessHeaderImageForPortraitScreenActionProto.
             * @memberof waiternow.common
             * @interface IUpdateBusinessHeaderImageForPortraitScreenActionProto
             */

            /**
             * Constructs a new UpdateBusinessHeaderImageForPortraitScreenActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateBusinessHeaderImageForPortraitScreenActionProto.
             * @implements IUpdateBusinessHeaderImageForPortraitScreenActionProto
             * @constructor
             * @param {waiternow.common.IUpdateBusinessHeaderImageForPortraitScreenActionProto=} [properties] Properties to set
             */
            function UpdateBusinessHeaderImageForPortraitScreenActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateBusinessHeaderImageForPortraitScreenActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessHeaderImageForPortraitScreenActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto} UpdateBusinessHeaderImageForPortraitScreenActionProto instance
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.create = function create(properties) {
                return new UpdateBusinessHeaderImageForPortraitScreenActionProto(properties);
            };

            /**
             * Encodes the specified UpdateBusinessHeaderImageForPortraitScreenActionProto message. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessHeaderImageForPortraitScreenActionProto} message UpdateBusinessHeaderImageForPortraitScreenActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateBusinessHeaderImageForPortraitScreenActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {waiternow.common.IUpdateBusinessHeaderImageForPortraitScreenActionProto} message UpdateBusinessHeaderImageForPortraitScreenActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateBusinessHeaderImageForPortraitScreenActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto} UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateBusinessHeaderImageForPortraitScreenActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto} UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateBusinessHeaderImageForPortraitScreenActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateBusinessHeaderImageForPortraitScreenActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto} UpdateBusinessHeaderImageForPortraitScreenActionProto
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto)
                    return object;
                return new $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto();
            };

            /**
             * Creates a plain object from an UpdateBusinessHeaderImageForPortraitScreenActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto} message UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateBusinessHeaderImageForPortraitScreenActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateBusinessHeaderImageForPortraitScreenActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto";
            };

            UpdateBusinessHeaderImageForPortraitScreenActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request)
                        return object;
                    return new $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Request";
                };

                return Request;
            })();

            UpdateBusinessHeaderImageForPortraitScreenActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBusinessHeaderImageForPortraitScreenActionProto.Response";
                };

                return Response;
            })();

            return UpdateBusinessHeaderImageForPortraitScreenActionProto;
        })();

        common.DeleteBusinessActionProto = (function() {

            /**
             * Properties of a DeleteBusinessActionProto.
             * @memberof waiternow.common
             * @interface IDeleteBusinessActionProto
             */

            /**
             * Constructs a new DeleteBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeleteBusinessActionProto.
             * @implements IDeleteBusinessActionProto
             * @constructor
             * @param {waiternow.common.IDeleteBusinessActionProto=} [properties] Properties to set
             */
            function DeleteBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DeleteBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {waiternow.common.IDeleteBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.DeleteBusinessActionProto} DeleteBusinessActionProto instance
             */
            DeleteBusinessActionProto.create = function create(properties) {
                return new DeleteBusinessActionProto(properties);
            };

            /**
             * Encodes the specified DeleteBusinessActionProto message. Does not implicitly {@link waiternow.common.DeleteBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {waiternow.common.IDeleteBusinessActionProto} message DeleteBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DeleteBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.DeleteBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {waiternow.common.IDeleteBusinessActionProto} message DeleteBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeleteBusinessActionProto} DeleteBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeleteBusinessActionProto} DeleteBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DeleteBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeleteBusinessActionProto} DeleteBusinessActionProto
             */
            DeleteBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeleteBusinessActionProto)
                    return object;
                return new $root.waiternow.common.DeleteBusinessActionProto();
            };

            /**
             * Creates a plain object from a DeleteBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {waiternow.common.DeleteBusinessActionProto} message DeleteBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeleteBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeleteBusinessActionProto";
            };

            DeleteBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.DeleteBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.DeleteBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.DeleteBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.DeleteBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.DeleteBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.DeleteBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteBusinessActionProto.Request";
                };

                return Request;
            })();

            DeleteBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.DeleteBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.DeleteBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.DeleteBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.DeleteBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.DeleteBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.DeleteBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.DeleteBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteBusinessActionProto.Response";
                };

                return Response;
            })();

            return DeleteBusinessActionProto;
        })();

        common.GetBusinessActionProto = (function() {

            /**
             * Properties of a GetBusinessActionProto.
             * @memberof waiternow.common
             * @interface IGetBusinessActionProto
             */

            /**
             * Constructs a new GetBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetBusinessActionProto.
             * @implements IGetBusinessActionProto
             * @constructor
             * @param {waiternow.common.IGetBusinessActionProto=} [properties] Properties to set
             */
            function GetBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {waiternow.common.IGetBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetBusinessActionProto} GetBusinessActionProto instance
             */
            GetBusinessActionProto.create = function create(properties) {
                return new GetBusinessActionProto(properties);
            };

            /**
             * Encodes the specified GetBusinessActionProto message. Does not implicitly {@link waiternow.common.GetBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {waiternow.common.IGetBusinessActionProto} message GetBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {waiternow.common.IGetBusinessActionProto} message GetBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetBusinessActionProto} GetBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetBusinessActionProto} GetBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetBusinessActionProto} GetBusinessActionProto
             */
            GetBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetBusinessActionProto)
                    return object;
                return new $root.waiternow.common.GetBusinessActionProto();
            };

            /**
             * Creates a plain object from a GetBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {waiternow.common.GetBusinessActionProto} message GetBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetBusinessActionProto";
            };

            GetBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetBusinessActionProto.Request";
                };

                return Request;
            })();

            GetBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IBusinessProto|null} [business] Response business
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response business.
                 * @member {waiternow.common.IBusinessProto|null|undefined} business
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.business = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.business != null && Object.hasOwnProperty.call(message, "business"))
                        $root.waiternow.common.BusinessProto.encode(message.business, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.business = $root.waiternow.common.BusinessProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.business != null && message.hasOwnProperty("business")) {
                        var error = $root.waiternow.common.BusinessProto.verify(message.business);
                        if (error)
                            return "business." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.business != null) {
                        if (typeof object.business !== "object")
                            throw TypeError(".waiternow.common.GetBusinessActionProto.Response.business: object expected");
                        message.business = $root.waiternow.common.BusinessProto.fromObject(object.business);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.business = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.business != null && message.hasOwnProperty("business"))
                        object.business = $root.waiternow.common.BusinessProto.toObject(message.business, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetBusinessActionProto.Response";
                };

                return Response;
            })();

            return GetBusinessActionProto;
        })();

        common.FindBusinessesByNameActionProto = (function() {

            /**
             * Properties of a FindBusinessesByNameActionProto.
             * @memberof waiternow.common
             * @interface IFindBusinessesByNameActionProto
             */

            /**
             * Constructs a new FindBusinessesByNameActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindBusinessesByNameActionProto.
             * @implements IFindBusinessesByNameActionProto
             * @constructor
             * @param {waiternow.common.IFindBusinessesByNameActionProto=} [properties] Properties to set
             */
            function FindBusinessesByNameActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindBusinessesByNameActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {waiternow.common.IFindBusinessesByNameActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindBusinessesByNameActionProto} FindBusinessesByNameActionProto instance
             */
            FindBusinessesByNameActionProto.create = function create(properties) {
                return new FindBusinessesByNameActionProto(properties);
            };

            /**
             * Encodes the specified FindBusinessesByNameActionProto message. Does not implicitly {@link waiternow.common.FindBusinessesByNameActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {waiternow.common.IFindBusinessesByNameActionProto} message FindBusinessesByNameActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindBusinessesByNameActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindBusinessesByNameActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindBusinessesByNameActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {waiternow.common.IFindBusinessesByNameActionProto} message FindBusinessesByNameActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindBusinessesByNameActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindBusinessesByNameActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindBusinessesByNameActionProto} FindBusinessesByNameActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindBusinessesByNameActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBusinessesByNameActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindBusinessesByNameActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindBusinessesByNameActionProto} FindBusinessesByNameActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindBusinessesByNameActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindBusinessesByNameActionProto message.
             * @function verify
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindBusinessesByNameActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindBusinessesByNameActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindBusinessesByNameActionProto} FindBusinessesByNameActionProto
             */
            FindBusinessesByNameActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindBusinessesByNameActionProto)
                    return object;
                return new $root.waiternow.common.FindBusinessesByNameActionProto();
            };

            /**
             * Creates a plain object from a FindBusinessesByNameActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {waiternow.common.FindBusinessesByNameActionProto} message FindBusinessesByNameActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindBusinessesByNameActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindBusinessesByNameActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindBusinessesByNameActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindBusinessesByNameActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindBusinessesByNameActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindBusinessesByNameActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindBusinessesByNameActionProto";
            };

            FindBusinessesByNameActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindBusinessesByNameActionProto
                 * @interface IRequest
                 * @property {string|null} [businessName] Request businessName
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindBusinessesByNameActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessName.
                 * @member {string} businessName
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @instance
                 */
                Request.prototype.businessName = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindBusinessesByNameActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessName != null && Object.hasOwnProperty.call(message, "businessName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessName);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindBusinessesByNameActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBusinessesByNameActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessName = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        if (!$util.isString(message.businessName))
                            return "businessName: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindBusinessesByNameActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindBusinessesByNameActionProto.Request();
                    if (object.businessName != null)
                        message.businessName = String(object.businessName);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessName = "";
                        object.continuationToken = "";
                    }
                    if (message.businessName != null && message.hasOwnProperty("businessName"))
                        object.businessName = message.businessName;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindBusinessesByNameActionProto.Request";
                };

                return Request;
            })();

            FindBusinessesByNameActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindBusinessesByNameActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IBusinessesProto|null} [businesses] Response businesses
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindBusinessesByNameActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response businesses.
                 * @member {waiternow.common.IBusinessesProto|null|undefined} businesses
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @instance
                 */
                Response.prototype.businesses = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindBusinessesByNameActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.businesses != null && Object.hasOwnProperty.call(message, "businesses"))
                        $root.waiternow.common.BusinessesProto.encode(message.businesses, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindBusinessesByNameActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBusinessesByNameActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.businesses = $root.waiternow.common.BusinessesProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.businesses != null && message.hasOwnProperty("businesses")) {
                        var error = $root.waiternow.common.BusinessesProto.verify(message.businesses);
                        if (error)
                            return "businesses." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindBusinessesByNameActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindBusinessesByNameActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindBusinessesByNameActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindBusinessesByNameActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.businesses != null) {
                        if (typeof object.businesses !== "object")
                            throw TypeError(".waiternow.common.FindBusinessesByNameActionProto.Response.businesses: object expected");
                        message.businesses = $root.waiternow.common.BusinessesProto.fromObject(object.businesses);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByNameActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.businesses = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.businesses != null && message.hasOwnProperty("businesses"))
                        object.businesses = $root.waiternow.common.BusinessesProto.toObject(message.businesses, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindBusinessesByNameActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindBusinessesByNameActionProto.Response";
                };

                return Response;
            })();

            return FindBusinessesByNameActionProto;
        })();

        common.FindBusinessesByUserActionProto = (function() {

            /**
             * Properties of a FindBusinessesByUserActionProto.
             * @memberof waiternow.common
             * @interface IFindBusinessesByUserActionProto
             */

            /**
             * Constructs a new FindBusinessesByUserActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindBusinessesByUserActionProto.
             * @implements IFindBusinessesByUserActionProto
             * @constructor
             * @param {waiternow.common.IFindBusinessesByUserActionProto=} [properties] Properties to set
             */
            function FindBusinessesByUserActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindBusinessesByUserActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {waiternow.common.IFindBusinessesByUserActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindBusinessesByUserActionProto} FindBusinessesByUserActionProto instance
             */
            FindBusinessesByUserActionProto.create = function create(properties) {
                return new FindBusinessesByUserActionProto(properties);
            };

            /**
             * Encodes the specified FindBusinessesByUserActionProto message. Does not implicitly {@link waiternow.common.FindBusinessesByUserActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {waiternow.common.IFindBusinessesByUserActionProto} message FindBusinessesByUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindBusinessesByUserActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindBusinessesByUserActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindBusinessesByUserActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {waiternow.common.IFindBusinessesByUserActionProto} message FindBusinessesByUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindBusinessesByUserActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindBusinessesByUserActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindBusinessesByUserActionProto} FindBusinessesByUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindBusinessesByUserActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBusinessesByUserActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindBusinessesByUserActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindBusinessesByUserActionProto} FindBusinessesByUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindBusinessesByUserActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindBusinessesByUserActionProto message.
             * @function verify
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindBusinessesByUserActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindBusinessesByUserActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindBusinessesByUserActionProto} FindBusinessesByUserActionProto
             */
            FindBusinessesByUserActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindBusinessesByUserActionProto)
                    return object;
                return new $root.waiternow.common.FindBusinessesByUserActionProto();
            };

            /**
             * Creates a plain object from a FindBusinessesByUserActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {waiternow.common.FindBusinessesByUserActionProto} message FindBusinessesByUserActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindBusinessesByUserActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindBusinessesByUserActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindBusinessesByUserActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindBusinessesByUserActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindBusinessesByUserActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindBusinessesByUserActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindBusinessesByUserActionProto";
            };

            FindBusinessesByUserActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindBusinessesByUserActionProto
                 * @interface IRequest
                 * @property {string|null} [userId] Request userId
                 * @property {string|null} [userEmail] Request userEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindBusinessesByUserActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request userId.
                 * @member {string|null|undefined} userId
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @instance
                 */
                Request.prototype.userId = null;

                /**
                 * Request userEmail.
                 * @member {string|null|undefined} userEmail
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @instance
                 */
                Request.prototype.userEmail = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request user.
                 * @member {"userId"|"userEmail"|undefined} user
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "user", {
                    get: $util.oneOfGetter($oneOfFields = ["userId", "userEmail"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindBusinessesByUserActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.userEmail != null && Object.hasOwnProperty.call(message, "userEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.userEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindBusinessesByUserActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBusinessesByUserActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.userEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.userId != null && message.hasOwnProperty("userId")) {
                        properties.user = 1;
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail")) {
                        if (properties.user === 1)
                            return "user: multiple values";
                        properties.user = 1;
                        if (!$util.isString(message.userEmail))
                            return "userEmail: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindBusinessesByUserActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindBusinessesByUserActionProto.Request();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.userEmail != null)
                        message.userEmail = String(object.userEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.userId != null && message.hasOwnProperty("userId")) {
                        object.userId = message.userId;
                        if (options.oneofs)
                            object.user = "userId";
                    }
                    if (message.userEmail != null && message.hasOwnProperty("userEmail")) {
                        object.userEmail = message.userEmail;
                        if (options.oneofs)
                            object.user = "userEmail";
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindBusinessesByUserActionProto.Request";
                };

                return Request;
            })();

            FindBusinessesByUserActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindBusinessesByUserActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IUserBusinessesProto|null} [userBusinesses] Response userBusinesses
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindBusinessesByUserActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response userBusinesses.
                 * @member {waiternow.common.IUserBusinessesProto|null|undefined} userBusinesses
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @instance
                 */
                Response.prototype.userBusinesses = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindBusinessesByUserActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.userBusinesses != null && Object.hasOwnProperty.call(message, "userBusinesses"))
                        $root.waiternow.common.UserBusinessesProto.encode(message.userBusinesses, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindBusinessesByUserActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBusinessesByUserActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.userBusinesses = $root.waiternow.common.UserBusinessesProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.userBusinesses != null && message.hasOwnProperty("userBusinesses")) {
                        var error = $root.waiternow.common.UserBusinessesProto.verify(message.userBusinesses);
                        if (error)
                            return "userBusinesses." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindBusinessesByUserActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindBusinessesByUserActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindBusinessesByUserActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindBusinessesByUserActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.userBusinesses != null) {
                        if (typeof object.userBusinesses !== "object")
                            throw TypeError(".waiternow.common.FindBusinessesByUserActionProto.Response.userBusinesses: object expected");
                        message.userBusinesses = $root.waiternow.common.UserBusinessesProto.fromObject(object.userBusinesses);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBusinessesByUserActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.userBusinesses = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.userBusinesses != null && message.hasOwnProperty("userBusinesses"))
                        object.userBusinesses = $root.waiternow.common.UserBusinessesProto.toObject(message.userBusinesses, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindBusinessesByUserActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindBusinessesByUserActionProto.Response";
                };

                return Response;
            })();

            return FindBusinessesByUserActionProto;
        })();

        common.FindUsersByBusinessActionProto = (function() {

            /**
             * Properties of a FindUsersByBusinessActionProto.
             * @memberof waiternow.common
             * @interface IFindUsersByBusinessActionProto
             */

            /**
             * Constructs a new FindUsersByBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindUsersByBusinessActionProto.
             * @implements IFindUsersByBusinessActionProto
             * @constructor
             * @param {waiternow.common.IFindUsersByBusinessActionProto=} [properties] Properties to set
             */
            function FindUsersByBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindUsersByBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindUsersByBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindUsersByBusinessActionProto} FindUsersByBusinessActionProto instance
             */
            FindUsersByBusinessActionProto.create = function create(properties) {
                return new FindUsersByBusinessActionProto(properties);
            };

            /**
             * Encodes the specified FindUsersByBusinessActionProto message. Does not implicitly {@link waiternow.common.FindUsersByBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindUsersByBusinessActionProto} message FindUsersByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUsersByBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindUsersByBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindUsersByBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindUsersByBusinessActionProto} message FindUsersByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUsersByBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindUsersByBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindUsersByBusinessActionProto} FindUsersByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUsersByBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUsersByBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindUsersByBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindUsersByBusinessActionProto} FindUsersByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUsersByBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindUsersByBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindUsersByBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindUsersByBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindUsersByBusinessActionProto} FindUsersByBusinessActionProto
             */
            FindUsersByBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindUsersByBusinessActionProto)
                    return object;
                return new $root.waiternow.common.FindUsersByBusinessActionProto();
            };

            /**
             * Creates a plain object from a FindUsersByBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {waiternow.common.FindUsersByBusinessActionProto} message FindUsersByBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindUsersByBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindUsersByBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindUsersByBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindUsersByBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindUsersByBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindUsersByBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindUsersByBusinessActionProto";
            };

            FindUsersByBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindUsersByBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindUsersByBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindUsersByBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindUsersByBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUsersByBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUsersByBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindUsersByBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUsersByBusinessActionProto.Request";
                };

                return Request;
            })();

            FindUsersByBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindUsersByBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IBusinessUsersProto|null} [businessUsers] Response businessUsers
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindUsersByBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response businessUsers.
                 * @member {waiternow.common.IBusinessUsersProto|null|undefined} businessUsers
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.businessUsers = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindUsersByBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.businessUsers != null && Object.hasOwnProperty.call(message, "businessUsers"))
                        $root.waiternow.common.BusinessUsersProto.encode(message.businessUsers, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindUsersByBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUsersByBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.businessUsers = $root.waiternow.common.BusinessUsersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.businessUsers != null && message.hasOwnProperty("businessUsers")) {
                        var error = $root.waiternow.common.BusinessUsersProto.verify(message.businessUsers);
                        if (error)
                            return "businessUsers." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUsersByBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUsersByBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindUsersByBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindUsersByBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.businessUsers != null) {
                        if (typeof object.businessUsers !== "object")
                            throw TypeError(".waiternow.common.FindUsersByBusinessActionProto.Response.businessUsers: object expected");
                        message.businessUsers = $root.waiternow.common.BusinessUsersProto.fromObject(object.businessUsers);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.businessUsers = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.businessUsers != null && message.hasOwnProperty("businessUsers"))
                        object.businessUsers = $root.waiternow.common.BusinessUsersProto.toObject(message.businessUsers, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUsersByBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUsersByBusinessActionProto.Response";
                };

                return Response;
            })();

            return FindUsersByBusinessActionProto;
        })();

        common.AddOwnerToBusinessActionProto = (function() {

            /**
             * Properties of an AddOwnerToBusinessActionProto.
             * @memberof waiternow.common
             * @interface IAddOwnerToBusinessActionProto
             */

            /**
             * Constructs a new AddOwnerToBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AddOwnerToBusinessActionProto.
             * @implements IAddOwnerToBusinessActionProto
             * @constructor
             * @param {waiternow.common.IAddOwnerToBusinessActionProto=} [properties] Properties to set
             */
            function AddOwnerToBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AddOwnerToBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {waiternow.common.IAddOwnerToBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AddOwnerToBusinessActionProto} AddOwnerToBusinessActionProto instance
             */
            AddOwnerToBusinessActionProto.create = function create(properties) {
                return new AddOwnerToBusinessActionProto(properties);
            };

            /**
             * Encodes the specified AddOwnerToBusinessActionProto message. Does not implicitly {@link waiternow.common.AddOwnerToBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {waiternow.common.IAddOwnerToBusinessActionProto} message AddOwnerToBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddOwnerToBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AddOwnerToBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.AddOwnerToBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {waiternow.common.IAddOwnerToBusinessActionProto} message AddOwnerToBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddOwnerToBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddOwnerToBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AddOwnerToBusinessActionProto} AddOwnerToBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddOwnerToBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddOwnerToBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddOwnerToBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AddOwnerToBusinessActionProto} AddOwnerToBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddOwnerToBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddOwnerToBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddOwnerToBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AddOwnerToBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AddOwnerToBusinessActionProto} AddOwnerToBusinessActionProto
             */
            AddOwnerToBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AddOwnerToBusinessActionProto)
                    return object;
                return new $root.waiternow.common.AddOwnerToBusinessActionProto();
            };

            /**
             * Creates a plain object from an AddOwnerToBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {waiternow.common.AddOwnerToBusinessActionProto} message AddOwnerToBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddOwnerToBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AddOwnerToBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddOwnerToBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddOwnerToBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AddOwnerToBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddOwnerToBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AddOwnerToBusinessActionProto";
            };

            AddOwnerToBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [affectedUserEmail] Request affectedUserEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Request affectedUserEmail.
                 * @member {string} affectedUserEmail
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.affectedUserEmail = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AddOwnerToBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.affectedUserEmail != null && Object.hasOwnProperty.call(message, "affectedUserEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.affectedUserEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AddOwnerToBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddOwnerToBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.affectedUserEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        if (!$util.isString(message.affectedUserEmail))
                            return "affectedUserEmail: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddOwnerToBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.AddOwnerToBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.affectedUserEmail != null)
                        message.affectedUserEmail = String(object.affectedUserEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessId = "";
                        object.affectedUserEmail = "";
                    }
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        object.affectedUserEmail = message.affectedUserEmail;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddOwnerToBusinessActionProto.Request";
                };

                return Request;
            })();

            AddOwnerToBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AddOwnerToBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AddOwnerToBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddOwnerToBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddOwnerToBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddOwnerToBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AddOwnerToBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AddOwnerToBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.AddOwnerToBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddOwnerToBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddOwnerToBusinessActionProto.Response";
                };

                return Response;
            })();

            return AddOwnerToBusinessActionProto;
        })();

        common.RemoveUserFromBusinessActionProto = (function() {

            /**
             * Properties of a RemoveUserFromBusinessActionProto.
             * @memberof waiternow.common
             * @interface IRemoveUserFromBusinessActionProto
             */

            /**
             * Constructs a new RemoveUserFromBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RemoveUserFromBusinessActionProto.
             * @implements IRemoveUserFromBusinessActionProto
             * @constructor
             * @param {waiternow.common.IRemoveUserFromBusinessActionProto=} [properties] Properties to set
             */
            function RemoveUserFromBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RemoveUserFromBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {waiternow.common.IRemoveUserFromBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RemoveUserFromBusinessActionProto} RemoveUserFromBusinessActionProto instance
             */
            RemoveUserFromBusinessActionProto.create = function create(properties) {
                return new RemoveUserFromBusinessActionProto(properties);
            };

            /**
             * Encodes the specified RemoveUserFromBusinessActionProto message. Does not implicitly {@link waiternow.common.RemoveUserFromBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {waiternow.common.IRemoveUserFromBusinessActionProto} message RemoveUserFromBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveUserFromBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RemoveUserFromBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.RemoveUserFromBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {waiternow.common.IRemoveUserFromBusinessActionProto} message RemoveUserFromBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveUserFromBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveUserFromBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RemoveUserFromBusinessActionProto} RemoveUserFromBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveUserFromBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveUserFromBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveUserFromBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RemoveUserFromBusinessActionProto} RemoveUserFromBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveUserFromBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveUserFromBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveUserFromBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RemoveUserFromBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RemoveUserFromBusinessActionProto} RemoveUserFromBusinessActionProto
             */
            RemoveUserFromBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RemoveUserFromBusinessActionProto)
                    return object;
                return new $root.waiternow.common.RemoveUserFromBusinessActionProto();
            };

            /**
             * Creates a plain object from a RemoveUserFromBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {waiternow.common.RemoveUserFromBusinessActionProto} message RemoveUserFromBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveUserFromBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RemoveUserFromBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveUserFromBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RemoveUserFromBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RemoveUserFromBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RemoveUserFromBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RemoveUserFromBusinessActionProto";
            };

            RemoveUserFromBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [affectedUserEmail] Request affectedUserEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Request affectedUserEmail.
                 * @member {string} affectedUserEmail
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.affectedUserEmail = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RemoveUserFromBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.affectedUserEmail != null && Object.hasOwnProperty.call(message, "affectedUserEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.affectedUserEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RemoveUserFromBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveUserFromBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.affectedUserEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        if (!$util.isString(message.affectedUserEmail))
                            return "affectedUserEmail: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveUserFromBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RemoveUserFromBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.affectedUserEmail != null)
                        message.affectedUserEmail = String(object.affectedUserEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.businessId = "";
                        object.affectedUserEmail = "";
                    }
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        object.affectedUserEmail = message.affectedUserEmail;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveUserFromBusinessActionProto.Request";
                };

                return Request;
            })();

            RemoveUserFromBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RemoveUserFromBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RemoveUserFromBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveUserFromBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveUserFromBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveUserFromBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RemoveUserFromBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RemoveUserFromBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveUserFromBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveUserFromBusinessActionProto.Response";
                };

                return Response;
            })();

            return RemoveUserFromBusinessActionProto;
        })();

        common.CreateLocationActionProto = (function() {

            /**
             * Properties of a CreateLocationActionProto.
             * @memberof waiternow.common
             * @interface ICreateLocationActionProto
             */

            /**
             * Constructs a new CreateLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CreateLocationActionProto.
             * @implements ICreateLocationActionProto
             * @constructor
             * @param {waiternow.common.ICreateLocationActionProto=} [properties] Properties to set
             */
            function CreateLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CreateLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {waiternow.common.ICreateLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CreateLocationActionProto} CreateLocationActionProto instance
             */
            CreateLocationActionProto.create = function create(properties) {
                return new CreateLocationActionProto(properties);
            };

            /**
             * Encodes the specified CreateLocationActionProto message. Does not implicitly {@link waiternow.common.CreateLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {waiternow.common.ICreateLocationActionProto} message CreateLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CreateLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.CreateLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {waiternow.common.ICreateLocationActionProto} message CreateLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CreateLocationActionProto} CreateLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CreateLocationActionProto} CreateLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CreateLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CreateLocationActionProto} CreateLocationActionProto
             */
            CreateLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CreateLocationActionProto)
                    return object;
                return new $root.waiternow.common.CreateLocationActionProto();
            };

            /**
             * Creates a plain object from a CreateLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {waiternow.common.CreateLocationActionProto} message CreateLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreateLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CreateLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CreateLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CreateLocationActionProto";
            };

            CreateLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CreateLocationActionProto
                 * @interface IRequest
                 * @property {waiternow.common.ILocationProto|null} [location] Request location
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CreateLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CreateLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request location.
                 * @member {waiternow.common.ILocationProto|null|undefined} location
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.location = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CreateLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CreateLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                        $root.waiternow.common.LocationProto.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CreateLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.location = $root.waiternow.common.LocationProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.location != null && message.hasOwnProperty("location")) {
                        var error = $root.waiternow.common.LocationProto.verify(message.location);
                        if (error)
                            return "location." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CreateLocationActionProto.Request();
                    if (object.location != null) {
                        if (typeof object.location !== "object")
                            throw TypeError(".waiternow.common.CreateLocationActionProto.Request.location: object expected");
                        message.location = $root.waiternow.common.LocationProto.fromObject(object.location);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.location = null;
                    if (message.location != null && message.hasOwnProperty("location"))
                        object.location = $root.waiternow.common.LocationProto.toObject(message.location, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateLocationActionProto.Request";
                };

                return Request;
            })();

            CreateLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CreateLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationProto|null} [location] Response location
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CreateLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CreateLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response location.
                 * @member {waiternow.common.ILocationProto|null|undefined} location
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.location = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CreateLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CreateLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                        $root.waiternow.common.LocationProto.encode(message.location, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CreateLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.location = $root.waiternow.common.LocationProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.location != null && message.hasOwnProperty("location")) {
                        var error = $root.waiternow.common.LocationProto.verify(message.location);
                        if (error)
                            return "location." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CreateLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CreateLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.location != null) {
                        if (typeof object.location !== "object")
                            throw TypeError(".waiternow.common.CreateLocationActionProto.Response.location: object expected");
                        message.location = $root.waiternow.common.LocationProto.fromObject(object.location);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.location = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.location != null && message.hasOwnProperty("location"))
                        object.location = $root.waiternow.common.LocationProto.toObject(message.location, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateLocationActionProto.Response";
                };

                return Response;
            })();

            return CreateLocationActionProto;
        })();

        common.UpdateLocationActionProto = (function() {

            /**
             * Properties of an UpdateLocationActionProto.
             * @memberof waiternow.common
             * @interface IUpdateLocationActionProto
             */

            /**
             * Constructs a new UpdateLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateLocationActionProto.
             * @implements IUpdateLocationActionProto
             * @constructor
             * @param {waiternow.common.IUpdateLocationActionProto=} [properties] Properties to set
             */
            function UpdateLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {waiternow.common.IUpdateLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateLocationActionProto} UpdateLocationActionProto instance
             */
            UpdateLocationActionProto.create = function create(properties) {
                return new UpdateLocationActionProto(properties);
            };

            /**
             * Encodes the specified UpdateLocationActionProto message. Does not implicitly {@link waiternow.common.UpdateLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {waiternow.common.IUpdateLocationActionProto} message UpdateLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {waiternow.common.IUpdateLocationActionProto} message UpdateLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateLocationActionProto} UpdateLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateLocationActionProto} UpdateLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateLocationActionProto} UpdateLocationActionProto
             */
            UpdateLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateLocationActionProto)
                    return object;
                return new $root.waiternow.common.UpdateLocationActionProto();
            };

            /**
             * Creates a plain object from an UpdateLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {waiternow.common.UpdateLocationActionProto} message UpdateLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateLocationActionProto";
            };

            UpdateLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateLocationActionProto
                 * @interface IRequest
                 * @property {waiternow.common.ILocationProto|null} [location] Request location
                 * @property {Array.<waiternow.common.UpdateLocationActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 * @property {Array.<waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField>|null} [settingsFieldsToRemove] Request settingsFieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    this.settingsFieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request location.
                 * @member {waiternow.common.ILocationProto|null|undefined} location
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.location = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateLocationActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Request settingsFieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField>} settingsFieldsToRemove
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.settingsFieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                        $root.waiternow.common.LocationProto.encode(message.location, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    if (message.settingsFieldsToRemove != null && message.settingsFieldsToRemove.length) {
                        writer.uint32(/* id 3, wireType 2 =*/26).fork();
                        for (var i = 0; i < message.settingsFieldsToRemove.length; ++i)
                            writer.int32(message.settingsFieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.location = $root.waiternow.common.LocationProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        case 3: {
                                if (!(message.settingsFieldsToRemove && message.settingsFieldsToRemove.length))
                                    message.settingsFieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.settingsFieldsToRemove.push(reader.int32());
                                } else
                                    message.settingsFieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.location != null && message.hasOwnProperty("location")) {
                        var error = $root.waiternow.common.LocationProto.verify(message.location);
                        if (error)
                            return "location." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                                break;
                            }
                    }
                    if (message.settingsFieldsToRemove != null && message.hasOwnProperty("settingsFieldsToRemove")) {
                        if (!Array.isArray(message.settingsFieldsToRemove))
                            return "settingsFieldsToRemove: array expected";
                        for (var i = 0; i < message.settingsFieldsToRemove.length; ++i)
                            switch (message.settingsFieldsToRemove[i]) {
                            default:
                                return "settingsFieldsToRemove: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                            case 6:
                            case 7:
                            case 8:
                            case 9:
                            case 10:
                            case 11:
                            case 12:
                            case 13:
                            case 14:
                            case 15:
                            case 16:
                            case 17:
                            case 18:
                            case 19:
                            case 22:
                            case 23:
                            case 28:
                            case 29:
                            case 31:
                            case 32:
                            case 33:
                            case 34:
                            case 35:
                            case 36:
                            case 37:
                            case 38:
                            case 20:
                            case 21:
                            case 24:
                            case 25:
                            case 26:
                            case 27:
                            case 30:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdateLocationActionProto.Request();
                    if (object.location != null) {
                        if (typeof object.location !== "object")
                            throw TypeError(".waiternow.common.UpdateLocationActionProto.Request.location: object expected");
                        message.location = $root.waiternow.common.LocationProto.fromObject(object.location);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateLocationActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            }
                    }
                    if (object.settingsFieldsToRemove) {
                        if (!Array.isArray(object.settingsFieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateLocationActionProto.Request.settingsFieldsToRemove: array expected");
                        message.settingsFieldsToRemove = [];
                        for (var i = 0; i < object.settingsFieldsToRemove.length; ++i)
                            switch (object.settingsFieldsToRemove[i]) {
                            default:
                                if (typeof object.settingsFieldsToRemove[i] === "number") {
                                    message.settingsFieldsToRemove[i] = object.settingsFieldsToRemove[i];
                                    break;
                                }
                            case "SETTINGS_REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.settingsFieldsToRemove[i] = 0;
                                break;
                            case "LOCALE_LANGUAGE":
                            case 1:
                                message.settingsFieldsToRemove[i] = 1;
                                break;
                            case "LOCALE_CURRENCY_CODE":
                            case 2:
                                message.settingsFieldsToRemove[i] = 2;
                                break;
                            case "LOCALE_TIMEZONE":
                            case 3:
                                message.settingsFieldsToRemove[i] = 3;
                                break;
                            case "VIEW_THEME":
                            case 4:
                                message.settingsFieldsToRemove[i] = 4;
                                break;
                            case "VIEW_APP_MODE":
                            case 5:
                                message.settingsFieldsToRemove[i] = 5;
                                break;
                            case "EXTERNAL_LINKS_WEBSITE":
                            case 6:
                                message.settingsFieldsToRemove[i] = 6;
                                break;
                            case "EXTERNAL_LINKS_YOUTUBE":
                            case 7:
                                message.settingsFieldsToRemove[i] = 7;
                                break;
                            case "EXTERNAL_LINKS_FACEBOOK":
                            case 8:
                                message.settingsFieldsToRemove[i] = 8;
                                break;
                            case "EXTERNAL_LINKS_INSTAGRAM":
                            case 9:
                                message.settingsFieldsToRemove[i] = 9;
                                break;
                            case "EXTERNAL_LINKS_TWITTER":
                            case 10:
                                message.settingsFieldsToRemove[i] = 10;
                                break;
                            case "EXTERNAL_LINKS_LINKED_IN":
                            case 11:
                                message.settingsFieldsToRemove[i] = 11;
                                break;
                            case "EXTERNAL_LINKS_TIKTOK":
                            case 12:
                                message.settingsFieldsToRemove[i] = 12;
                                break;
                            case "COLOR_PALETTE":
                            case 13:
                                message.settingsFieldsToRemove[i] = 13;
                                break;
                            case "ENABLED_FEATURES_PAYMENT_REQUIRED_FOR_ORDERS":
                            case 14:
                                message.settingsFieldsToRemove[i] = 14;
                                break;
                            case "ENABLED_FEATURES_POKE_WAITER_DISABLED":
                            case 15:
                                message.settingsFieldsToRemove[i] = 15;
                                break;
                            case "ENABLED_FEATURES_STRUCTURED_MENU_DISABLED":
                            case 16:
                                message.settingsFieldsToRemove[i] = 16;
                                break;
                            case "ENABLED_FEATURES_IMAGE_MENU_DISABLED":
                            case 17:
                                message.settingsFieldsToRemove[i] = 17;
                                break;
                            case "ENABLED_FEATURES_MESSAGE_WAITER_DISABLED":
                            case 18:
                                message.settingsFieldsToRemove[i] = 18;
                                break;
                            case "ENABLED_FEATURES_ASK_FOR_CHECK_DISABLED":
                            case 19:
                                message.settingsFieldsToRemove[i] = 19;
                                break;
                            case "ENABLED_FEATURES_SPECIAL_INSTRUCTIONS_DISABLED":
                            case 22:
                                message.settingsFieldsToRemove[i] = 22;
                                break;
                            case "ENABLED_FEATURES_COMPLETED_STATUS_SUPPORTED_FOR_PAID_ORDERS":
                            case 23:
                                message.settingsFieldsToRemove[i] = 23;
                                break;
                            case "ENABLED_FEATURES_IS_ONSITE_TIPS_DISABLED":
                            case 28:
                                message.settingsFieldsToRemove[i] = 28;
                                break;
                            case "ENABLED_FEATURES_IS_ONLINE_TIPS_DISABLED":
                            case 29:
                                message.settingsFieldsToRemove[i] = 29;
                                break;
                            case "ENABLED_FEATURES_IS_DELIVERY_DISABLED":
                            case 31:
                                message.settingsFieldsToRemove[i] = 31;
                                break;
                            case "ENABLED_FEATURES_CHARGE_CREDIT_CARD_FEE_TO_CONSUMER":
                            case 32:
                                message.settingsFieldsToRemove[i] = 32;
                                break;
                            case "ENABLED_FEATURES_IS_ALCOHOL_FOR_PICKUP_ALLOWED":
                            case 33:
                                message.settingsFieldsToRemove[i] = 33;
                                break;
                            case "ENABLED_FEATURES_IS_ALCOHOL_FOR_DELIVERY_ALLOWED":
                            case 34:
                                message.settingsFieldsToRemove[i] = 34;
                                break;
                            case "ENABLED_FEATURES_PRINT_ORDER_AND_RECEIPT":
                            case 35:
                                message.settingsFieldsToRemove[i] = 35;
                                break;
                            case "ENABLED_FEATURES_IS_ORDER_FOR_LATER_DISABLED":
                            case 36:
                                message.settingsFieldsToRemove[i] = 36;
                                break;
                            case "ENABLED_FEATURES_IS_ORDER_FOR_LATER_DAY_DISABLED":
                            case 37:
                                message.settingsFieldsToRemove[i] = 37;
                                break;
                            case "ENABLED_FEATURES_IS_SMS_ON_NEW_ORDERS_FOR_BUSINESS_WEB_ENABLED":
                            case 38:
                                message.settingsFieldsToRemove[i] = 38;
                                break;
                            case "TAXES":
                            case 20:
                                message.settingsFieldsToRemove[i] = 20;
                                break;
                            case "PRINTERS":
                            case 21:
                                message.settingsFieldsToRemove[i] = 21;
                                break;
                            case "ORDER_PAYMENT_WAITERNOW_FEE":
                            case 24:
                                message.settingsFieldsToRemove[i] = 24;
                                break;
                            case "BUSINESS_HOURS":
                            case 25:
                                message.settingsFieldsToRemove[i] = 25;
                                break;
                            case "ALERT_CONFIG":
                            case 26:
                                message.settingsFieldsToRemove[i] = 26;
                                break;
                            case "ORDER_PREPARATION_DURATION":
                            case 27:
                                message.settingsFieldsToRemove[i] = 27;
                                break;
                            case "DELIVERY_CONFIG":
                            case 30:
                                message.settingsFieldsToRemove[i] = 30;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults) {
                        object.fieldsToRemove = [];
                        object.settingsFieldsToRemove = [];
                    }
                    if (options.defaults)
                        object.location = null;
                    if (message.location != null && message.hasOwnProperty("location"))
                        object.location = $root.waiternow.common.LocationProto.toObject(message.location, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateLocationActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdateLocationActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    if (message.settingsFieldsToRemove && message.settingsFieldsToRemove.length) {
                        object.settingsFieldsToRemove = [];
                        for (var j = 0; j < message.settingsFieldsToRemove.length; ++j)
                            object.settingsFieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField[message.settingsFieldsToRemove[j]] === undefined ? message.settingsFieldsToRemove[j] : $root.waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField[message.settingsFieldsToRemove[j]] : message.settingsFieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateLocationActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdateLocationActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    return values;
                })();

                /**
                 * SettingsRemovableField enum.
                 * @name waiternow.common.UpdateLocationActionProto.Request.SettingsRemovableField
                 * @enum {number}
                 * @property {number} SETTINGS_REMOVABLE_FIELD_UNSPECIFIED=0 SETTINGS_REMOVABLE_FIELD_UNSPECIFIED value
                 * @property {number} LOCALE_LANGUAGE=1 LOCALE_LANGUAGE value
                 * @property {number} LOCALE_CURRENCY_CODE=2 LOCALE_CURRENCY_CODE value
                 * @property {number} LOCALE_TIMEZONE=3 LOCALE_TIMEZONE value
                 * @property {number} VIEW_THEME=4 VIEW_THEME value
                 * @property {number} VIEW_APP_MODE=5 VIEW_APP_MODE value
                 * @property {number} EXTERNAL_LINKS_WEBSITE=6 EXTERNAL_LINKS_WEBSITE value
                 * @property {number} EXTERNAL_LINKS_YOUTUBE=7 EXTERNAL_LINKS_YOUTUBE value
                 * @property {number} EXTERNAL_LINKS_FACEBOOK=8 EXTERNAL_LINKS_FACEBOOK value
                 * @property {number} EXTERNAL_LINKS_INSTAGRAM=9 EXTERNAL_LINKS_INSTAGRAM value
                 * @property {number} EXTERNAL_LINKS_TWITTER=10 EXTERNAL_LINKS_TWITTER value
                 * @property {number} EXTERNAL_LINKS_LINKED_IN=11 EXTERNAL_LINKS_LINKED_IN value
                 * @property {number} EXTERNAL_LINKS_TIKTOK=12 EXTERNAL_LINKS_TIKTOK value
                 * @property {number} COLOR_PALETTE=13 COLOR_PALETTE value
                 * @property {number} ENABLED_FEATURES_PAYMENT_REQUIRED_FOR_ORDERS=14 ENABLED_FEATURES_PAYMENT_REQUIRED_FOR_ORDERS value
                 * @property {number} ENABLED_FEATURES_POKE_WAITER_DISABLED=15 ENABLED_FEATURES_POKE_WAITER_DISABLED value
                 * @property {number} ENABLED_FEATURES_STRUCTURED_MENU_DISABLED=16 ENABLED_FEATURES_STRUCTURED_MENU_DISABLED value
                 * @property {number} ENABLED_FEATURES_IMAGE_MENU_DISABLED=17 ENABLED_FEATURES_IMAGE_MENU_DISABLED value
                 * @property {number} ENABLED_FEATURES_MESSAGE_WAITER_DISABLED=18 ENABLED_FEATURES_MESSAGE_WAITER_DISABLED value
                 * @property {number} ENABLED_FEATURES_ASK_FOR_CHECK_DISABLED=19 ENABLED_FEATURES_ASK_FOR_CHECK_DISABLED value
                 * @property {number} ENABLED_FEATURES_SPECIAL_INSTRUCTIONS_DISABLED=22 ENABLED_FEATURES_SPECIAL_INSTRUCTIONS_DISABLED value
                 * @property {number} ENABLED_FEATURES_COMPLETED_STATUS_SUPPORTED_FOR_PAID_ORDERS=23 ENABLED_FEATURES_COMPLETED_STATUS_SUPPORTED_FOR_PAID_ORDERS value
                 * @property {number} ENABLED_FEATURES_IS_ONSITE_TIPS_DISABLED=28 ENABLED_FEATURES_IS_ONSITE_TIPS_DISABLED value
                 * @property {number} ENABLED_FEATURES_IS_ONLINE_TIPS_DISABLED=29 ENABLED_FEATURES_IS_ONLINE_TIPS_DISABLED value
                 * @property {number} ENABLED_FEATURES_IS_DELIVERY_DISABLED=31 ENABLED_FEATURES_IS_DELIVERY_DISABLED value
                 * @property {number} ENABLED_FEATURES_CHARGE_CREDIT_CARD_FEE_TO_CONSUMER=32 ENABLED_FEATURES_CHARGE_CREDIT_CARD_FEE_TO_CONSUMER value
                 * @property {number} ENABLED_FEATURES_IS_ALCOHOL_FOR_PICKUP_ALLOWED=33 ENABLED_FEATURES_IS_ALCOHOL_FOR_PICKUP_ALLOWED value
                 * @property {number} ENABLED_FEATURES_IS_ALCOHOL_FOR_DELIVERY_ALLOWED=34 ENABLED_FEATURES_IS_ALCOHOL_FOR_DELIVERY_ALLOWED value
                 * @property {number} ENABLED_FEATURES_PRINT_ORDER_AND_RECEIPT=35 ENABLED_FEATURES_PRINT_ORDER_AND_RECEIPT value
                 * @property {number} ENABLED_FEATURES_IS_ORDER_FOR_LATER_DISABLED=36 ENABLED_FEATURES_IS_ORDER_FOR_LATER_DISABLED value
                 * @property {number} ENABLED_FEATURES_IS_ORDER_FOR_LATER_DAY_DISABLED=37 ENABLED_FEATURES_IS_ORDER_FOR_LATER_DAY_DISABLED value
                 * @property {number} ENABLED_FEATURES_IS_SMS_ON_NEW_ORDERS_FOR_BUSINESS_WEB_ENABLED=38 ENABLED_FEATURES_IS_SMS_ON_NEW_ORDERS_FOR_BUSINESS_WEB_ENABLED value
                 * @property {number} TAXES=20 TAXES value
                 * @property {number} PRINTERS=21 PRINTERS value
                 * @property {number} ORDER_PAYMENT_WAITERNOW_FEE=24 ORDER_PAYMENT_WAITERNOW_FEE value
                 * @property {number} BUSINESS_HOURS=25 BUSINESS_HOURS value
                 * @property {number} ALERT_CONFIG=26 ALERT_CONFIG value
                 * @property {number} ORDER_PREPARATION_DURATION=27 ORDER_PREPARATION_DURATION value
                 * @property {number} DELIVERY_CONFIG=30 DELIVERY_CONFIG value
                 */
                Request.SettingsRemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "SETTINGS_REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "LOCALE_LANGUAGE"] = 1;
                    values[valuesById[2] = "LOCALE_CURRENCY_CODE"] = 2;
                    values[valuesById[3] = "LOCALE_TIMEZONE"] = 3;
                    values[valuesById[4] = "VIEW_THEME"] = 4;
                    values[valuesById[5] = "VIEW_APP_MODE"] = 5;
                    values[valuesById[6] = "EXTERNAL_LINKS_WEBSITE"] = 6;
                    values[valuesById[7] = "EXTERNAL_LINKS_YOUTUBE"] = 7;
                    values[valuesById[8] = "EXTERNAL_LINKS_FACEBOOK"] = 8;
                    values[valuesById[9] = "EXTERNAL_LINKS_INSTAGRAM"] = 9;
                    values[valuesById[10] = "EXTERNAL_LINKS_TWITTER"] = 10;
                    values[valuesById[11] = "EXTERNAL_LINKS_LINKED_IN"] = 11;
                    values[valuesById[12] = "EXTERNAL_LINKS_TIKTOK"] = 12;
                    values[valuesById[13] = "COLOR_PALETTE"] = 13;
                    values[valuesById[14] = "ENABLED_FEATURES_PAYMENT_REQUIRED_FOR_ORDERS"] = 14;
                    values[valuesById[15] = "ENABLED_FEATURES_POKE_WAITER_DISABLED"] = 15;
                    values[valuesById[16] = "ENABLED_FEATURES_STRUCTURED_MENU_DISABLED"] = 16;
                    values[valuesById[17] = "ENABLED_FEATURES_IMAGE_MENU_DISABLED"] = 17;
                    values[valuesById[18] = "ENABLED_FEATURES_MESSAGE_WAITER_DISABLED"] = 18;
                    values[valuesById[19] = "ENABLED_FEATURES_ASK_FOR_CHECK_DISABLED"] = 19;
                    values[valuesById[22] = "ENABLED_FEATURES_SPECIAL_INSTRUCTIONS_DISABLED"] = 22;
                    values[valuesById[23] = "ENABLED_FEATURES_COMPLETED_STATUS_SUPPORTED_FOR_PAID_ORDERS"] = 23;
                    values[valuesById[28] = "ENABLED_FEATURES_IS_ONSITE_TIPS_DISABLED"] = 28;
                    values[valuesById[29] = "ENABLED_FEATURES_IS_ONLINE_TIPS_DISABLED"] = 29;
                    values[valuesById[31] = "ENABLED_FEATURES_IS_DELIVERY_DISABLED"] = 31;
                    values[valuesById[32] = "ENABLED_FEATURES_CHARGE_CREDIT_CARD_FEE_TO_CONSUMER"] = 32;
                    values[valuesById[33] = "ENABLED_FEATURES_IS_ALCOHOL_FOR_PICKUP_ALLOWED"] = 33;
                    values[valuesById[34] = "ENABLED_FEATURES_IS_ALCOHOL_FOR_DELIVERY_ALLOWED"] = 34;
                    values[valuesById[35] = "ENABLED_FEATURES_PRINT_ORDER_AND_RECEIPT"] = 35;
                    values[valuesById[36] = "ENABLED_FEATURES_IS_ORDER_FOR_LATER_DISABLED"] = 36;
                    values[valuesById[37] = "ENABLED_FEATURES_IS_ORDER_FOR_LATER_DAY_DISABLED"] = 37;
                    values[valuesById[38] = "ENABLED_FEATURES_IS_SMS_ON_NEW_ORDERS_FOR_BUSINESS_WEB_ENABLED"] = 38;
                    values[valuesById[20] = "TAXES"] = 20;
                    values[valuesById[21] = "PRINTERS"] = 21;
                    values[valuesById[24] = "ORDER_PAYMENT_WAITERNOW_FEE"] = 24;
                    values[valuesById[25] = "BUSINESS_HOURS"] = 25;
                    values[valuesById[26] = "ALERT_CONFIG"] = 26;
                    values[valuesById[27] = "ORDER_PREPARATION_DURATION"] = 27;
                    values[valuesById[30] = "DELIVERY_CONFIG"] = 30;
                    return values;
                })();

                return Request;
            })();

            UpdateLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateLocationActionProto.Response";
                };

                return Response;
            })();

            return UpdateLocationActionProto;
        })();

        common.DeleteLocationActionProto = (function() {

            /**
             * Properties of a DeleteLocationActionProto.
             * @memberof waiternow.common
             * @interface IDeleteLocationActionProto
             */

            /**
             * Constructs a new DeleteLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeleteLocationActionProto.
             * @implements IDeleteLocationActionProto
             * @constructor
             * @param {waiternow.common.IDeleteLocationActionProto=} [properties] Properties to set
             */
            function DeleteLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DeleteLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {waiternow.common.IDeleteLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.DeleteLocationActionProto} DeleteLocationActionProto instance
             */
            DeleteLocationActionProto.create = function create(properties) {
                return new DeleteLocationActionProto(properties);
            };

            /**
             * Encodes the specified DeleteLocationActionProto message. Does not implicitly {@link waiternow.common.DeleteLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {waiternow.common.IDeleteLocationActionProto} message DeleteLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DeleteLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.DeleteLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {waiternow.common.IDeleteLocationActionProto} message DeleteLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeleteLocationActionProto} DeleteLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeleteLocationActionProto} DeleteLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DeleteLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeleteLocationActionProto} DeleteLocationActionProto
             */
            DeleteLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeleteLocationActionProto)
                    return object;
                return new $root.waiternow.common.DeleteLocationActionProto();
            };

            /**
             * Creates a plain object from a DeleteLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {waiternow.common.DeleteLocationActionProto} message DeleteLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeleteLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeleteLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeleteLocationActionProto";
            };

            DeleteLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.DeleteLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.DeleteLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.DeleteLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.DeleteLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.DeleteLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.DeleteLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteLocationActionProto.Request";
                };

                return Request;
            })();

            DeleteLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.DeleteLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.DeleteLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.DeleteLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.DeleteLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.DeleteLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.DeleteLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.DeleteLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteLocationActionProto.Response";
                };

                return Response;
            })();

            return DeleteLocationActionProto;
        })();

        common.GetLocationActionProto = (function() {

            /**
             * Properties of a GetLocationActionProto.
             * @memberof waiternow.common
             * @interface IGetLocationActionProto
             */

            /**
             * Constructs a new GetLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetLocationActionProto.
             * @implements IGetLocationActionProto
             * @constructor
             * @param {waiternow.common.IGetLocationActionProto=} [properties] Properties to set
             */
            function GetLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {waiternow.common.IGetLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetLocationActionProto} GetLocationActionProto instance
             */
            GetLocationActionProto.create = function create(properties) {
                return new GetLocationActionProto(properties);
            };

            /**
             * Encodes the specified GetLocationActionProto message. Does not implicitly {@link waiternow.common.GetLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {waiternow.common.IGetLocationActionProto} message GetLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {waiternow.common.IGetLocationActionProto} message GetLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetLocationActionProto} GetLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetLocationActionProto} GetLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetLocationActionProto} GetLocationActionProto
             */
            GetLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetLocationActionProto)
                    return object;
                return new $root.waiternow.common.GetLocationActionProto();
            };

            /**
             * Creates a plain object from a GetLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {waiternow.common.GetLocationActionProto} message GetLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetLocationActionProto";
            };

            GetLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetLocationActionProto.Request";
                };

                return Request;
            })();

            GetLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationProto|null} [location] Response location
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response location.
                 * @member {waiternow.common.ILocationProto|null|undefined} location
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.location = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.location != null && Object.hasOwnProperty.call(message, "location"))
                        $root.waiternow.common.LocationProto.encode(message.location, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.location = $root.waiternow.common.LocationProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.location != null && message.hasOwnProperty("location")) {
                        var error = $root.waiternow.common.LocationProto.verify(message.location);
                        if (error)
                            return "location." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.location != null) {
                        if (typeof object.location !== "object")
                            throw TypeError(".waiternow.common.GetLocationActionProto.Response.location: object expected");
                        message.location = $root.waiternow.common.LocationProto.fromObject(object.location);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.location = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.location != null && message.hasOwnProperty("location"))
                        object.location = $root.waiternow.common.LocationProto.toObject(message.location, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetLocationActionProto.Response";
                };

                return Response;
            })();

            return GetLocationActionProto;
        })();

        common.GetLocationAnalyticsActionProto = (function() {

            /**
             * Properties of a GetLocationAnalyticsActionProto.
             * @memberof waiternow.common
             * @interface IGetLocationAnalyticsActionProto
             */

            /**
             * Constructs a new GetLocationAnalyticsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetLocationAnalyticsActionProto.
             * @implements IGetLocationAnalyticsActionProto
             * @constructor
             * @param {waiternow.common.IGetLocationAnalyticsActionProto=} [properties] Properties to set
             */
            function GetLocationAnalyticsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetLocationAnalyticsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {waiternow.common.IGetLocationAnalyticsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetLocationAnalyticsActionProto} GetLocationAnalyticsActionProto instance
             */
            GetLocationAnalyticsActionProto.create = function create(properties) {
                return new GetLocationAnalyticsActionProto(properties);
            };

            /**
             * Encodes the specified GetLocationAnalyticsActionProto message. Does not implicitly {@link waiternow.common.GetLocationAnalyticsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {waiternow.common.IGetLocationAnalyticsActionProto} message GetLocationAnalyticsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLocationAnalyticsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetLocationAnalyticsActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetLocationAnalyticsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {waiternow.common.IGetLocationAnalyticsActionProto} message GetLocationAnalyticsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetLocationAnalyticsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetLocationAnalyticsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetLocationAnalyticsActionProto} GetLocationAnalyticsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLocationAnalyticsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetLocationAnalyticsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetLocationAnalyticsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetLocationAnalyticsActionProto} GetLocationAnalyticsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetLocationAnalyticsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetLocationAnalyticsActionProto message.
             * @function verify
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetLocationAnalyticsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetLocationAnalyticsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetLocationAnalyticsActionProto} GetLocationAnalyticsActionProto
             */
            GetLocationAnalyticsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetLocationAnalyticsActionProto)
                    return object;
                return new $root.waiternow.common.GetLocationAnalyticsActionProto();
            };

            /**
             * Creates a plain object from a GetLocationAnalyticsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {waiternow.common.GetLocationAnalyticsActionProto} message GetLocationAnalyticsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetLocationAnalyticsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetLocationAnalyticsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetLocationAnalyticsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetLocationAnalyticsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetLocationAnalyticsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetLocationAnalyticsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetLocationAnalyticsActionProto";
            };

            GetLocationAnalyticsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetLocationAnalyticsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetLocationAnalyticsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetLocationAnalyticsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetLocationAnalyticsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetLocationAnalyticsActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetLocationAnalyticsActionProto.Request";
                };

                return Request;
            })();

            GetLocationAnalyticsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IAnalyticsProto|null} [analytics] Response analytics
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response analytics.
                 * @member {waiternow.common.IAnalyticsProto|null|undefined} analytics
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @instance
                 */
                Response.prototype.analytics = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetLocationAnalyticsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.analytics != null && Object.hasOwnProperty.call(message, "analytics"))
                        $root.waiternow.common.AnalyticsProto.encode(message.analytics, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetLocationAnalyticsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetLocationAnalyticsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.analytics = $root.waiternow.common.AnalyticsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.analytics != null && message.hasOwnProperty("analytics")) {
                        var error = $root.waiternow.common.AnalyticsProto.verify(message.analytics);
                        if (error)
                            return "analytics." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetLocationAnalyticsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetLocationAnalyticsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetLocationAnalyticsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetLocationAnalyticsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.analytics != null) {
                        if (typeof object.analytics !== "object")
                            throw TypeError(".waiternow.common.GetLocationAnalyticsActionProto.Response.analytics: object expected");
                        message.analytics = $root.waiternow.common.AnalyticsProto.fromObject(object.analytics);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetLocationAnalyticsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.analytics = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.analytics != null && message.hasOwnProperty("analytics"))
                        object.analytics = $root.waiternow.common.AnalyticsProto.toObject(message.analytics, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetLocationAnalyticsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetLocationAnalyticsActionProto.Response";
                };

                return Response;
            })();

            return GetLocationAnalyticsActionProto;
        })();

        common.GetBusinessLocationsActionProto = (function() {

            /**
             * Properties of a GetBusinessLocationsActionProto.
             * @memberof waiternow.common
             * @interface IGetBusinessLocationsActionProto
             */

            /**
             * Constructs a new GetBusinessLocationsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetBusinessLocationsActionProto.
             * @implements IGetBusinessLocationsActionProto
             * @constructor
             * @param {waiternow.common.IGetBusinessLocationsActionProto=} [properties] Properties to set
             */
            function GetBusinessLocationsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetBusinessLocationsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {waiternow.common.IGetBusinessLocationsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetBusinessLocationsActionProto} GetBusinessLocationsActionProto instance
             */
            GetBusinessLocationsActionProto.create = function create(properties) {
                return new GetBusinessLocationsActionProto(properties);
            };

            /**
             * Encodes the specified GetBusinessLocationsActionProto message. Does not implicitly {@link waiternow.common.GetBusinessLocationsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {waiternow.common.IGetBusinessLocationsActionProto} message GetBusinessLocationsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBusinessLocationsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetBusinessLocationsActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetBusinessLocationsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {waiternow.common.IGetBusinessLocationsActionProto} message GetBusinessLocationsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetBusinessLocationsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetBusinessLocationsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetBusinessLocationsActionProto} GetBusinessLocationsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBusinessLocationsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetBusinessLocationsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetBusinessLocationsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetBusinessLocationsActionProto} GetBusinessLocationsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetBusinessLocationsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetBusinessLocationsActionProto message.
             * @function verify
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetBusinessLocationsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetBusinessLocationsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetBusinessLocationsActionProto} GetBusinessLocationsActionProto
             */
            GetBusinessLocationsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetBusinessLocationsActionProto)
                    return object;
                return new $root.waiternow.common.GetBusinessLocationsActionProto();
            };

            /**
             * Creates a plain object from a GetBusinessLocationsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {waiternow.common.GetBusinessLocationsActionProto} message GetBusinessLocationsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetBusinessLocationsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetBusinessLocationsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetBusinessLocationsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetBusinessLocationsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetBusinessLocationsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetBusinessLocationsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetBusinessLocationsActionProto";
            };

            GetBusinessLocationsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetBusinessLocationsActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetBusinessLocationsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetBusinessLocationsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetBusinessLocationsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetBusinessLocationsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetBusinessLocationsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetBusinessLocationsActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetBusinessLocationsActionProto.Request";
                };

                return Request;
            })();

            GetBusinessLocationsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetBusinessLocationsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IBusinessLocationsProto|null} [businessLocations] Response businessLocations
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetBusinessLocationsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response businessLocations.
                 * @member {waiternow.common.IBusinessLocationsProto|null|undefined} businessLocations
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @instance
                 */
                Response.prototype.businessLocations = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetBusinessLocationsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.businessLocations != null && Object.hasOwnProperty.call(message, "businessLocations"))
                        $root.waiternow.common.BusinessLocationsProto.encode(message.businessLocations, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetBusinessLocationsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetBusinessLocationsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.businessLocations = $root.waiternow.common.BusinessLocationsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.businessLocations != null && message.hasOwnProperty("businessLocations")) {
                        var error = $root.waiternow.common.BusinessLocationsProto.verify(message.businessLocations);
                        if (error)
                            return "businessLocations." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetBusinessLocationsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetBusinessLocationsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetBusinessLocationsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetBusinessLocationsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.businessLocations != null) {
                        if (typeof object.businessLocations !== "object")
                            throw TypeError(".waiternow.common.GetBusinessLocationsActionProto.Response.businessLocations: object expected");
                        message.businessLocations = $root.waiternow.common.BusinessLocationsProto.fromObject(object.businessLocations);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.GetBusinessLocationsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.businessLocations = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.businessLocations != null && message.hasOwnProperty("businessLocations"))
                        object.businessLocations = $root.waiternow.common.BusinessLocationsProto.toObject(message.businessLocations, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetBusinessLocationsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetBusinessLocationsActionProto.Response";
                };

                return Response;
            })();

            return GetBusinessLocationsActionProto;
        })();

        common.FindLocationsByUserActionProto = (function() {

            /**
             * Properties of a FindLocationsByUserActionProto.
             * @memberof waiternow.common
             * @interface IFindLocationsByUserActionProto
             */

            /**
             * Constructs a new FindLocationsByUserActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindLocationsByUserActionProto.
             * @implements IFindLocationsByUserActionProto
             * @constructor
             * @param {waiternow.common.IFindLocationsByUserActionProto=} [properties] Properties to set
             */
            function FindLocationsByUserActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindLocationsByUserActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByUserActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindLocationsByUserActionProto} FindLocationsByUserActionProto instance
             */
            FindLocationsByUserActionProto.create = function create(properties) {
                return new FindLocationsByUserActionProto(properties);
            };

            /**
             * Encodes the specified FindLocationsByUserActionProto message. Does not implicitly {@link waiternow.common.FindLocationsByUserActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByUserActionProto} message FindLocationsByUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsByUserActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindLocationsByUserActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByUserActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByUserActionProto} message FindLocationsByUserActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsByUserActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindLocationsByUserActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindLocationsByUserActionProto} FindLocationsByUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsByUserActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByUserActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindLocationsByUserActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindLocationsByUserActionProto} FindLocationsByUserActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsByUserActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindLocationsByUserActionProto message.
             * @function verify
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindLocationsByUserActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindLocationsByUserActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindLocationsByUserActionProto} FindLocationsByUserActionProto
             */
            FindLocationsByUserActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindLocationsByUserActionProto)
                    return object;
                return new $root.waiternow.common.FindLocationsByUserActionProto();
            };

            /**
             * Creates a plain object from a FindLocationsByUserActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {waiternow.common.FindLocationsByUserActionProto} message FindLocationsByUserActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindLocationsByUserActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindLocationsByUserActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindLocationsByUserActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindLocationsByUserActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindLocationsByUserActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindLocationsByUserActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindLocationsByUserActionProto";
            };

            FindLocationsByUserActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindLocationsByUserActionProto
                 * @interface IRequest
                 * @property {string|null} [userId] Request userId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindLocationsByUserActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindLocationsByUserActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request userId.
                 * @member {string} userId
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @instance
                 */
                Request.prototype.userId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindLocationsByUserActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByUserActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByUserActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsByUserActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsByUserActionProto.Request();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.userId = "";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsByUserActionProto.Request";
                };

                return Request;
            })();

            FindLocationsByUserActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindLocationsByUserActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IUserLocationsProto|null} [userLocations] Response userLocations
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindLocationsByUserActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindLocationsByUserActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response userLocations.
                 * @member {waiternow.common.IUserLocationsProto|null|undefined} userLocations
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @instance
                 */
                Response.prototype.userLocations = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindLocationsByUserActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.userLocations != null && Object.hasOwnProperty.call(message, "userLocations"))
                        $root.waiternow.common.UserLocationsProto.encode(message.userLocations, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByUserActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByUserActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.userLocations = $root.waiternow.common.UserLocationsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.userLocations != null && message.hasOwnProperty("userLocations")) {
                        var error = $root.waiternow.common.UserLocationsProto.verify(message.userLocations);
                        if (error)
                            return "userLocations." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsByUserActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsByUserActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsByUserActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindLocationsByUserActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.userLocations != null) {
                        if (typeof object.userLocations !== "object")
                            throw TypeError(".waiternow.common.FindLocationsByUserActionProto.Response.userLocations: object expected");
                        message.userLocations = $root.waiternow.common.UserLocationsProto.fromObject(object.userLocations);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.userLocations = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.userLocations != null && message.hasOwnProperty("userLocations"))
                        object.userLocations = $root.waiternow.common.UserLocationsProto.toObject(message.userLocations, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsByUserActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsByUserActionProto.Response";
                };

                return Response;
            })();

            return FindLocationsByUserActionProto;
        })();

        common.FindLocationsByUserAndBusinessActionProto = (function() {

            /**
             * Properties of a FindLocationsByUserAndBusinessActionProto.
             * @memberof waiternow.common
             * @interface IFindLocationsByUserAndBusinessActionProto
             */

            /**
             * Constructs a new FindLocationsByUserAndBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindLocationsByUserAndBusinessActionProto.
             * @implements IFindLocationsByUserAndBusinessActionProto
             * @constructor
             * @param {waiternow.common.IFindLocationsByUserAndBusinessActionProto=} [properties] Properties to set
             */
            function FindLocationsByUserAndBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindLocationsByUserAndBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByUserAndBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto} FindLocationsByUserAndBusinessActionProto instance
             */
            FindLocationsByUserAndBusinessActionProto.create = function create(properties) {
                return new FindLocationsByUserAndBusinessActionProto(properties);
            };

            /**
             * Encodes the specified FindLocationsByUserAndBusinessActionProto message. Does not implicitly {@link waiternow.common.FindLocationsByUserAndBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByUserAndBusinessActionProto} message FindLocationsByUserAndBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsByUserAndBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindLocationsByUserAndBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByUserAndBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByUserAndBusinessActionProto} message FindLocationsByUserAndBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsByUserAndBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindLocationsByUserAndBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto} FindLocationsByUserAndBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsByUserAndBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByUserAndBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindLocationsByUserAndBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto} FindLocationsByUserAndBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsByUserAndBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindLocationsByUserAndBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindLocationsByUserAndBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindLocationsByUserAndBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto} FindLocationsByUserAndBusinessActionProto
             */
            FindLocationsByUserAndBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindLocationsByUserAndBusinessActionProto)
                    return object;
                return new $root.waiternow.common.FindLocationsByUserAndBusinessActionProto();
            };

            /**
             * Creates a plain object from a FindLocationsByUserAndBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto} message FindLocationsByUserAndBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindLocationsByUserAndBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindLocationsByUserAndBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindLocationsByUserAndBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindLocationsByUserAndBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindLocationsByUserAndBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindLocationsByUserAndBusinessActionProto";
            };

            FindLocationsByUserAndBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [userId] Request userId
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request userId.
                 * @member {string} userId
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.userId = "";

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindLocationsByUserAndBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.userId != null && Object.hasOwnProperty.call(message, "userId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.userId);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByUserAndBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByUserAndBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.userId = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        if (!$util.isString(message.userId))
                            return "userId: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsByUserAndBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsByUserAndBusinessActionProto.Request();
                    if (object.userId != null)
                        message.userId = String(object.userId);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.userId = "";
                        object.businessId = "";
                    }
                    if (message.userId != null && message.hasOwnProperty("userId"))
                        object.userId = message.userId;
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsByUserAndBusinessActionProto.Request";
                };

                return Request;
            })();

            FindLocationsByUserAndBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IUserLocationsProto|null} [userLocations] Response userLocations
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response userLocations.
                 * @member {waiternow.common.IUserLocationsProto|null|undefined} userLocations
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.userLocations = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindLocationsByUserAndBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.userLocations != null && Object.hasOwnProperty.call(message, "userLocations"))
                        $root.waiternow.common.UserLocationsProto.encode(message.userLocations, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByUserAndBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByUserAndBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.userLocations = $root.waiternow.common.UserLocationsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.userLocations != null && message.hasOwnProperty("userLocations")) {
                        var error = $root.waiternow.common.UserLocationsProto.verify(message.userLocations);
                        if (error)
                            return "userLocations." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsByUserAndBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsByUserAndBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsByUserAndBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindLocationsByUserAndBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.userLocations != null) {
                        if (typeof object.userLocations !== "object")
                            throw TypeError(".waiternow.common.FindLocationsByUserAndBusinessActionProto.Response.userLocations: object expected");
                        message.userLocations = $root.waiternow.common.UserLocationsProto.fromObject(object.userLocations);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByUserAndBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.userLocations = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.userLocations != null && message.hasOwnProperty("userLocations"))
                        object.userLocations = $root.waiternow.common.UserLocationsProto.toObject(message.userLocations, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsByUserAndBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsByUserAndBusinessActionProto.Response";
                };

                return Response;
            })();

            return FindLocationsByUserAndBusinessActionProto;
        })();

        common.FindLocationsByBusinessActionProto = (function() {

            /**
             * Properties of a FindLocationsByBusinessActionProto.
             * @memberof waiternow.common
             * @interface IFindLocationsByBusinessActionProto
             */

            /**
             * Constructs a new FindLocationsByBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindLocationsByBusinessActionProto.
             * @implements IFindLocationsByBusinessActionProto
             * @constructor
             * @param {waiternow.common.IFindLocationsByBusinessActionProto=} [properties] Properties to set
             */
            function FindLocationsByBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindLocationsByBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindLocationsByBusinessActionProto} FindLocationsByBusinessActionProto instance
             */
            FindLocationsByBusinessActionProto.create = function create(properties) {
                return new FindLocationsByBusinessActionProto(properties);
            };

            /**
             * Encodes the specified FindLocationsByBusinessActionProto message. Does not implicitly {@link waiternow.common.FindLocationsByBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByBusinessActionProto} message FindLocationsByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsByBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindLocationsByBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindLocationsByBusinessActionProto} message FindLocationsByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsByBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindLocationsByBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindLocationsByBusinessActionProto} FindLocationsByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsByBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindLocationsByBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindLocationsByBusinessActionProto} FindLocationsByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsByBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindLocationsByBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindLocationsByBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindLocationsByBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindLocationsByBusinessActionProto} FindLocationsByBusinessActionProto
             */
            FindLocationsByBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindLocationsByBusinessActionProto)
                    return object;
                return new $root.waiternow.common.FindLocationsByBusinessActionProto();
            };

            /**
             * Creates a plain object from a FindLocationsByBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {waiternow.common.FindLocationsByBusinessActionProto} message FindLocationsByBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindLocationsByBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindLocationsByBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindLocationsByBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindLocationsByBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindLocationsByBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindLocationsByBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindLocationsByBusinessActionProto";
            };

            FindLocationsByBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindLocationsByBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsByBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsByBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsByBusinessActionProto.Request";
                };

                return Request;
            })();

            FindLocationsByBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationsProto|null} [locations] Response locations
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locations.
                 * @member {waiternow.common.ILocationsProto|null|undefined} locations
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.locations = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindLocationsByBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locations != null && Object.hasOwnProperty.call(message, "locations"))
                        $root.waiternow.common.LocationsProto.encode(message.locations, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsByBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsByBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locations = $root.waiternow.common.LocationsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locations != null && message.hasOwnProperty("locations")) {
                        var error = $root.waiternow.common.LocationsProto.verify(message.locations);
                        if (error)
                            return "locations." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsByBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsByBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsByBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindLocationsByBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locations != null) {
                        if (typeof object.locations !== "object")
                            throw TypeError(".waiternow.common.FindLocationsByBusinessActionProto.Response.locations: object expected");
                        message.locations = $root.waiternow.common.LocationsProto.fromObject(object.locations);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsByBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locations = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locations != null && message.hasOwnProperty("locations"))
                        object.locations = $root.waiternow.common.LocationsProto.toObject(message.locations, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsByBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsByBusinessActionProto.Response";
                };

                return Response;
            })();

            return FindLocationsByBusinessActionProto;
        })();

        common.FindUsersByLocationActionProto = (function() {

            /**
             * Properties of a FindUsersByLocationActionProto.
             * @memberof waiternow.common
             * @interface IFindUsersByLocationActionProto
             */

            /**
             * Constructs a new FindUsersByLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindUsersByLocationActionProto.
             * @implements IFindUsersByLocationActionProto
             * @constructor
             * @param {waiternow.common.IFindUsersByLocationActionProto=} [properties] Properties to set
             */
            function FindUsersByLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindUsersByLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {waiternow.common.IFindUsersByLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindUsersByLocationActionProto} FindUsersByLocationActionProto instance
             */
            FindUsersByLocationActionProto.create = function create(properties) {
                return new FindUsersByLocationActionProto(properties);
            };

            /**
             * Encodes the specified FindUsersByLocationActionProto message. Does not implicitly {@link waiternow.common.FindUsersByLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {waiternow.common.IFindUsersByLocationActionProto} message FindUsersByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUsersByLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindUsersByLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindUsersByLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {waiternow.common.IFindUsersByLocationActionProto} message FindUsersByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUsersByLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindUsersByLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindUsersByLocationActionProto} FindUsersByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUsersByLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUsersByLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindUsersByLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindUsersByLocationActionProto} FindUsersByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUsersByLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindUsersByLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindUsersByLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindUsersByLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindUsersByLocationActionProto} FindUsersByLocationActionProto
             */
            FindUsersByLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindUsersByLocationActionProto)
                    return object;
                return new $root.waiternow.common.FindUsersByLocationActionProto();
            };

            /**
             * Creates a plain object from a FindUsersByLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {waiternow.common.FindUsersByLocationActionProto} message FindUsersByLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindUsersByLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindUsersByLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindUsersByLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindUsersByLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindUsersByLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindUsersByLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindUsersByLocationActionProto";
            };

            FindUsersByLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindUsersByLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindUsersByLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindUsersByLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindUsersByLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindUsersByLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUsersByLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUsersByLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindUsersByLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUsersByLocationActionProto.Request";
                };

                return Request;
            })();

            FindUsersByLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindUsersByLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationUsersProto|null} [locationUsers] Response locationUsers
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindUsersByLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindUsersByLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationUsers.
                 * @member {waiternow.common.ILocationUsersProto|null|undefined} locationUsers
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.locationUsers = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindUsersByLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationUsers != null && Object.hasOwnProperty.call(message, "locationUsers"))
                        $root.waiternow.common.LocationUsersProto.encode(message.locationUsers, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindUsersByLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUsersByLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationUsers = $root.waiternow.common.LocationUsersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationUsers != null && message.hasOwnProperty("locationUsers")) {
                        var error = $root.waiternow.common.LocationUsersProto.verify(message.locationUsers);
                        if (error)
                            return "locationUsers." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUsersByLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUsersByLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindUsersByLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindUsersByLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationUsers != null) {
                        if (typeof object.locationUsers !== "object")
                            throw TypeError(".waiternow.common.FindUsersByLocationActionProto.Response.locationUsers: object expected");
                        message.locationUsers = $root.waiternow.common.LocationUsersProto.fromObject(object.locationUsers);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUsersByLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationUsers = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationUsers != null && message.hasOwnProperty("locationUsers"))
                        object.locationUsers = $root.waiternow.common.LocationUsersProto.toObject(message.locationUsers, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUsersByLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUsersByLocationActionProto.Response";
                };

                return Response;
            })();

            return FindUsersByLocationActionProto;
        })();

        common.AddAdminToLocationActionProto = (function() {

            /**
             * Properties of an AddAdminToLocationActionProto.
             * @memberof waiternow.common
             * @interface IAddAdminToLocationActionProto
             */

            /**
             * Constructs a new AddAdminToLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AddAdminToLocationActionProto.
             * @implements IAddAdminToLocationActionProto
             * @constructor
             * @param {waiternow.common.IAddAdminToLocationActionProto=} [properties] Properties to set
             */
            function AddAdminToLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AddAdminToLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {waiternow.common.IAddAdminToLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AddAdminToLocationActionProto} AddAdminToLocationActionProto instance
             */
            AddAdminToLocationActionProto.create = function create(properties) {
                return new AddAdminToLocationActionProto(properties);
            };

            /**
             * Encodes the specified AddAdminToLocationActionProto message. Does not implicitly {@link waiternow.common.AddAdminToLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {waiternow.common.IAddAdminToLocationActionProto} message AddAdminToLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAdminToLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AddAdminToLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.AddAdminToLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {waiternow.common.IAddAdminToLocationActionProto} message AddAdminToLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddAdminToLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddAdminToLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AddAdminToLocationActionProto} AddAdminToLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAdminToLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddAdminToLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddAdminToLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AddAdminToLocationActionProto} AddAdminToLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddAdminToLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddAdminToLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddAdminToLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AddAdminToLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AddAdminToLocationActionProto} AddAdminToLocationActionProto
             */
            AddAdminToLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AddAdminToLocationActionProto)
                    return object;
                return new $root.waiternow.common.AddAdminToLocationActionProto();
            };

            /**
             * Creates a plain object from an AddAdminToLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {waiternow.common.AddAdminToLocationActionProto} message AddAdminToLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddAdminToLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AddAdminToLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddAdminToLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddAdminToLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AddAdminToLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddAdminToLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AddAdminToLocationActionProto";
            };

            AddAdminToLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AddAdminToLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [affectedUserEmail] Request affectedUserEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AddAdminToLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AddAdminToLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request affectedUserEmail.
                 * @member {string} affectedUserEmail
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.affectedUserEmail = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AddAdminToLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.affectedUserEmail != null && Object.hasOwnProperty.call(message, "affectedUserEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.affectedUserEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AddAdminToLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddAdminToLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.affectedUserEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        if (!$util.isString(message.affectedUserEmail))
                            return "affectedUserEmail: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddAdminToLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.AddAdminToLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.affectedUserEmail != null)
                        message.affectedUserEmail = String(object.affectedUserEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.affectedUserEmail = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        object.affectedUserEmail = message.affectedUserEmail;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddAdminToLocationActionProto.Request";
                };

                return Request;
            })();

            AddAdminToLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AddAdminToLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AddAdminToLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AddAdminToLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AddAdminToLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AddAdminToLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddAdminToLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddAdminToLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddAdminToLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AddAdminToLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AddAdminToLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddAdminToLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddAdminToLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddAdminToLocationActionProto.Response";
                };

                return Response;
            })();

            return AddAdminToLocationActionProto;
        })();

        common.AddViewerToLocationActionProto = (function() {

            /**
             * Properties of an AddViewerToLocationActionProto.
             * @memberof waiternow.common
             * @interface IAddViewerToLocationActionProto
             */

            /**
             * Constructs a new AddViewerToLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AddViewerToLocationActionProto.
             * @implements IAddViewerToLocationActionProto
             * @constructor
             * @param {waiternow.common.IAddViewerToLocationActionProto=} [properties] Properties to set
             */
            function AddViewerToLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AddViewerToLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {waiternow.common.IAddViewerToLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AddViewerToLocationActionProto} AddViewerToLocationActionProto instance
             */
            AddViewerToLocationActionProto.create = function create(properties) {
                return new AddViewerToLocationActionProto(properties);
            };

            /**
             * Encodes the specified AddViewerToLocationActionProto message. Does not implicitly {@link waiternow.common.AddViewerToLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {waiternow.common.IAddViewerToLocationActionProto} message AddViewerToLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddViewerToLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AddViewerToLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.AddViewerToLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {waiternow.common.IAddViewerToLocationActionProto} message AddViewerToLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddViewerToLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddViewerToLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AddViewerToLocationActionProto} AddViewerToLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddViewerToLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddViewerToLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddViewerToLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AddViewerToLocationActionProto} AddViewerToLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddViewerToLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddViewerToLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddViewerToLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AddViewerToLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AddViewerToLocationActionProto} AddViewerToLocationActionProto
             */
            AddViewerToLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AddViewerToLocationActionProto)
                    return object;
                return new $root.waiternow.common.AddViewerToLocationActionProto();
            };

            /**
             * Creates a plain object from an AddViewerToLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {waiternow.common.AddViewerToLocationActionProto} message AddViewerToLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddViewerToLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AddViewerToLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddViewerToLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddViewerToLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AddViewerToLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddViewerToLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AddViewerToLocationActionProto";
            };

            AddViewerToLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AddViewerToLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [affectedUserEmail] Request affectedUserEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AddViewerToLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AddViewerToLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request affectedUserEmail.
                 * @member {string} affectedUserEmail
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.affectedUserEmail = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AddViewerToLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.affectedUserEmail != null && Object.hasOwnProperty.call(message, "affectedUserEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.affectedUserEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AddViewerToLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddViewerToLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.affectedUserEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        if (!$util.isString(message.affectedUserEmail))
                            return "affectedUserEmail: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddViewerToLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.AddViewerToLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.affectedUserEmail != null)
                        message.affectedUserEmail = String(object.affectedUserEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.affectedUserEmail = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        object.affectedUserEmail = message.affectedUserEmail;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddViewerToLocationActionProto.Request";
                };

                return Request;
            })();

            AddViewerToLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AddViewerToLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AddViewerToLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AddViewerToLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AddViewerToLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AddViewerToLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddViewerToLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddViewerToLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddViewerToLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AddViewerToLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AddViewerToLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AddViewerToLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddViewerToLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddViewerToLocationActionProto.Response";
                };

                return Response;
            })();

            return AddViewerToLocationActionProto;
        })();

        common.RemoveUserFromLocationActionProto = (function() {

            /**
             * Properties of a RemoveUserFromLocationActionProto.
             * @memberof waiternow.common
             * @interface IRemoveUserFromLocationActionProto
             */

            /**
             * Constructs a new RemoveUserFromLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RemoveUserFromLocationActionProto.
             * @implements IRemoveUserFromLocationActionProto
             * @constructor
             * @param {waiternow.common.IRemoveUserFromLocationActionProto=} [properties] Properties to set
             */
            function RemoveUserFromLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RemoveUserFromLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {waiternow.common.IRemoveUserFromLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RemoveUserFromLocationActionProto} RemoveUserFromLocationActionProto instance
             */
            RemoveUserFromLocationActionProto.create = function create(properties) {
                return new RemoveUserFromLocationActionProto(properties);
            };

            /**
             * Encodes the specified RemoveUserFromLocationActionProto message. Does not implicitly {@link waiternow.common.RemoveUserFromLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {waiternow.common.IRemoveUserFromLocationActionProto} message RemoveUserFromLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveUserFromLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RemoveUserFromLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.RemoveUserFromLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {waiternow.common.IRemoveUserFromLocationActionProto} message RemoveUserFromLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveUserFromLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveUserFromLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RemoveUserFromLocationActionProto} RemoveUserFromLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveUserFromLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveUserFromLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveUserFromLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RemoveUserFromLocationActionProto} RemoveUserFromLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveUserFromLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveUserFromLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveUserFromLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RemoveUserFromLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RemoveUserFromLocationActionProto} RemoveUserFromLocationActionProto
             */
            RemoveUserFromLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RemoveUserFromLocationActionProto)
                    return object;
                return new $root.waiternow.common.RemoveUserFromLocationActionProto();
            };

            /**
             * Creates a plain object from a RemoveUserFromLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {waiternow.common.RemoveUserFromLocationActionProto} message RemoveUserFromLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveUserFromLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RemoveUserFromLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveUserFromLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RemoveUserFromLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RemoveUserFromLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RemoveUserFromLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RemoveUserFromLocationActionProto";
            };

            RemoveUserFromLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [affectedUserEmail] Request affectedUserEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request affectedUserEmail.
                 * @member {string} affectedUserEmail
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.affectedUserEmail = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RemoveUserFromLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.affectedUserEmail != null && Object.hasOwnProperty.call(message, "affectedUserEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.affectedUserEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RemoveUserFromLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveUserFromLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.affectedUserEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        if (!$util.isString(message.affectedUserEmail))
                            return "affectedUserEmail: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveUserFromLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RemoveUserFromLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.affectedUserEmail != null)
                        message.affectedUserEmail = String(object.affectedUserEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.affectedUserEmail = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        object.affectedUserEmail = message.affectedUserEmail;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveUserFromLocationActionProto.Request";
                };

                return Request;
            })();

            RemoveUserFromLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RemoveUserFromLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RemoveUserFromLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveUserFromLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveUserFromLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveUserFromLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RemoveUserFromLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RemoveUserFromLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveUserFromLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveUserFromLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveUserFromLocationActionProto.Response";
                };

                return Response;
            })();

            return RemoveUserFromLocationActionProto;
        })();

        common.RemoveViewerFromLocationActionProto = (function() {

            /**
             * Properties of a RemoveViewerFromLocationActionProto.
             * @memberof waiternow.common
             * @interface IRemoveViewerFromLocationActionProto
             */

            /**
             * Constructs a new RemoveViewerFromLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RemoveViewerFromLocationActionProto.
             * @implements IRemoveViewerFromLocationActionProto
             * @constructor
             * @param {waiternow.common.IRemoveViewerFromLocationActionProto=} [properties] Properties to set
             */
            function RemoveViewerFromLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RemoveViewerFromLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {waiternow.common.IRemoveViewerFromLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RemoveViewerFromLocationActionProto} RemoveViewerFromLocationActionProto instance
             */
            RemoveViewerFromLocationActionProto.create = function create(properties) {
                return new RemoveViewerFromLocationActionProto(properties);
            };

            /**
             * Encodes the specified RemoveViewerFromLocationActionProto message. Does not implicitly {@link waiternow.common.RemoveViewerFromLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {waiternow.common.IRemoveViewerFromLocationActionProto} message RemoveViewerFromLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveViewerFromLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RemoveViewerFromLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.RemoveViewerFromLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {waiternow.common.IRemoveViewerFromLocationActionProto} message RemoveViewerFromLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveViewerFromLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveViewerFromLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RemoveViewerFromLocationActionProto} RemoveViewerFromLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveViewerFromLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveViewerFromLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveViewerFromLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RemoveViewerFromLocationActionProto} RemoveViewerFromLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveViewerFromLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveViewerFromLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveViewerFromLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RemoveViewerFromLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RemoveViewerFromLocationActionProto} RemoveViewerFromLocationActionProto
             */
            RemoveViewerFromLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RemoveViewerFromLocationActionProto)
                    return object;
                return new $root.waiternow.common.RemoveViewerFromLocationActionProto();
            };

            /**
             * Creates a plain object from a RemoveViewerFromLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {waiternow.common.RemoveViewerFromLocationActionProto} message RemoveViewerFromLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveViewerFromLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RemoveViewerFromLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveViewerFromLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RemoveViewerFromLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RemoveViewerFromLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RemoveViewerFromLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RemoveViewerFromLocationActionProto";
            };

            RemoveViewerFromLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [affectedUserEmail] Request affectedUserEmail
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request affectedUserEmail.
                 * @member {string} affectedUserEmail
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.affectedUserEmail = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RemoveViewerFromLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.affectedUserEmail != null && Object.hasOwnProperty.call(message, "affectedUserEmail"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.affectedUserEmail);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RemoveViewerFromLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveViewerFromLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.affectedUserEmail = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        if (!$util.isString(message.affectedUserEmail))
                            return "affectedUserEmail: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveViewerFromLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RemoveViewerFromLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.affectedUserEmail != null)
                        message.affectedUserEmail = String(object.affectedUserEmail);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.affectedUserEmail = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.affectedUserEmail != null && message.hasOwnProperty("affectedUserEmail"))
                        object.affectedUserEmail = message.affectedUserEmail;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveViewerFromLocationActionProto.Request";
                };

                return Request;
            })();

            RemoveViewerFromLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RemoveViewerFromLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RemoveViewerFromLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveViewerFromLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveViewerFromLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveViewerFromLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RemoveViewerFromLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RemoveViewerFromLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveViewerFromLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveViewerFromLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveViewerFromLocationActionProto.Response";
                };

                return Response;
            })();

            return RemoveViewerFromLocationActionProto;
        })();

        common.EnrollLocationInMarketplacePaymentsActionProto = (function() {

            /**
             * Properties of an EnrollLocationInMarketplacePaymentsActionProto.
             * @memberof waiternow.common
             * @interface IEnrollLocationInMarketplacePaymentsActionProto
             */

            /**
             * Constructs a new EnrollLocationInMarketplacePaymentsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an EnrollLocationInMarketplacePaymentsActionProto.
             * @implements IEnrollLocationInMarketplacePaymentsActionProto
             * @constructor
             * @param {waiternow.common.IEnrollLocationInMarketplacePaymentsActionProto=} [properties] Properties to set
             */
            function EnrollLocationInMarketplacePaymentsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new EnrollLocationInMarketplacePaymentsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {waiternow.common.IEnrollLocationInMarketplacePaymentsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto} EnrollLocationInMarketplacePaymentsActionProto instance
             */
            EnrollLocationInMarketplacePaymentsActionProto.create = function create(properties) {
                return new EnrollLocationInMarketplacePaymentsActionProto(properties);
            };

            /**
             * Encodes the specified EnrollLocationInMarketplacePaymentsActionProto message. Does not implicitly {@link waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {waiternow.common.IEnrollLocationInMarketplacePaymentsActionProto} message EnrollLocationInMarketplacePaymentsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnrollLocationInMarketplacePaymentsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified EnrollLocationInMarketplacePaymentsActionProto message, length delimited. Does not implicitly {@link waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {waiternow.common.IEnrollLocationInMarketplacePaymentsActionProto} message EnrollLocationInMarketplacePaymentsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnrollLocationInMarketplacePaymentsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnrollLocationInMarketplacePaymentsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto} EnrollLocationInMarketplacePaymentsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnrollLocationInMarketplacePaymentsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnrollLocationInMarketplacePaymentsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto} EnrollLocationInMarketplacePaymentsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnrollLocationInMarketplacePaymentsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnrollLocationInMarketplacePaymentsActionProto message.
             * @function verify
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnrollLocationInMarketplacePaymentsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an EnrollLocationInMarketplacePaymentsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto} EnrollLocationInMarketplacePaymentsActionProto
             */
            EnrollLocationInMarketplacePaymentsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto)
                    return object;
                return new $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto();
            };

            /**
             * Creates a plain object from an EnrollLocationInMarketplacePaymentsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto} message EnrollLocationInMarketplacePaymentsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnrollLocationInMarketplacePaymentsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this EnrollLocationInMarketplacePaymentsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnrollLocationInMarketplacePaymentsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnrollLocationInMarketplacePaymentsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnrollLocationInMarketplacePaymentsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EnrollLocationInMarketplacePaymentsActionProto";
            };

            EnrollLocationInMarketplacePaymentsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Request";
                };

                return Request;
            })();

            EnrollLocationInMarketplacePaymentsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IMarketplacePaymentsLocationEnrollmentProto|null} [enrollment] Response enrollment
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response enrollment.
                 * @member {waiternow.common.IMarketplacePaymentsLocationEnrollmentProto|null|undefined} enrollment
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @instance
                 */
                Response.prototype.enrollment = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.enrollment != null && Object.hasOwnProperty.call(message, "enrollment"))
                        $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto.encode(message.enrollment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.enrollment = $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.enrollment != null && message.hasOwnProperty("enrollment")) {
                        var error = $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto.verify(message.enrollment);
                        if (error)
                            return "enrollment." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.enrollment != null) {
                        if (typeof object.enrollment !== "object")
                            throw TypeError(".waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response.enrollment: object expected");
                        message.enrollment = $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto.fromObject(object.enrollment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.enrollment = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.enrollment != null && message.hasOwnProperty("enrollment"))
                        object.enrollment = $root.waiternow.common.MarketplacePaymentsLocationEnrollmentProto.toObject(message.enrollment, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.EnrollLocationInMarketplacePaymentsActionProto.Response";
                };

                return Response;
            })();

            return EnrollLocationInMarketplacePaymentsActionProto;
        })();

        common.VerifyMarketplacePaymentsEnrollmentActionProto = (function() {

            /**
             * Properties of a VerifyMarketplacePaymentsEnrollmentActionProto.
             * @memberof waiternow.common
             * @interface IVerifyMarketplacePaymentsEnrollmentActionProto
             */

            /**
             * Constructs a new VerifyMarketplacePaymentsEnrollmentActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a VerifyMarketplacePaymentsEnrollmentActionProto.
             * @implements IVerifyMarketplacePaymentsEnrollmentActionProto
             * @constructor
             * @param {waiternow.common.IVerifyMarketplacePaymentsEnrollmentActionProto=} [properties] Properties to set
             */
            function VerifyMarketplacePaymentsEnrollmentActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new VerifyMarketplacePaymentsEnrollmentActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {waiternow.common.IVerifyMarketplacePaymentsEnrollmentActionProto=} [properties] Properties to set
             * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto} VerifyMarketplacePaymentsEnrollmentActionProto instance
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.create = function create(properties) {
                return new VerifyMarketplacePaymentsEnrollmentActionProto(properties);
            };

            /**
             * Encodes the specified VerifyMarketplacePaymentsEnrollmentActionProto message. Does not implicitly {@link waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {waiternow.common.IVerifyMarketplacePaymentsEnrollmentActionProto} message VerifyMarketplacePaymentsEnrollmentActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified VerifyMarketplacePaymentsEnrollmentActionProto message, length delimited. Does not implicitly {@link waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {waiternow.common.IVerifyMarketplacePaymentsEnrollmentActionProto} message VerifyMarketplacePaymentsEnrollmentActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a VerifyMarketplacePaymentsEnrollmentActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto} VerifyMarketplacePaymentsEnrollmentActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a VerifyMarketplacePaymentsEnrollmentActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto} VerifyMarketplacePaymentsEnrollmentActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a VerifyMarketplacePaymentsEnrollmentActionProto message.
             * @function verify
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a VerifyMarketplacePaymentsEnrollmentActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto} VerifyMarketplacePaymentsEnrollmentActionProto
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto)
                    return object;
                return new $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto();
            };

            /**
             * Creates a plain object from a VerifyMarketplacePaymentsEnrollmentActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto} message VerifyMarketplacePaymentsEnrollmentActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this VerifyMarketplacePaymentsEnrollmentActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for VerifyMarketplacePaymentsEnrollmentActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            VerifyMarketplacePaymentsEnrollmentActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto";
            };

            VerifyMarketplacePaymentsEnrollmentActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Request";
                };

                return Request;
            })();

            VerifyMarketplacePaymentsEnrollmentActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {boolean|null} [isEnrolled] Response isEnrolled
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response isEnrolled.
                 * @member {boolean} isEnrolled
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @instance
                 */
                Response.prototype.isEnrolled = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.isEnrolled != null && Object.hasOwnProperty.call(message, "isEnrolled"))
                        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.isEnrolled);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.isEnrolled = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.isEnrolled != null && message.hasOwnProperty("isEnrolled"))
                        if (typeof message.isEnrolled !== "boolean")
                            return "isEnrolled: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.isEnrolled != null)
                        message.isEnrolled = Boolean(object.isEnrolled);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.isEnrolled = false;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.isEnrolled != null && message.hasOwnProperty("isEnrolled"))
                        object.isEnrolled = message.isEnrolled;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.VerifyMarketplacePaymentsEnrollmentActionProto.Response";
                };

                return Response;
            })();

            return VerifyMarketplacePaymentsEnrollmentActionProto;
        })();

        common.EnrollLocationInDeliveryActionProto = (function() {

            /**
             * Properties of an EnrollLocationInDeliveryActionProto.
             * @memberof waiternow.common
             * @interface IEnrollLocationInDeliveryActionProto
             */

            /**
             * Constructs a new EnrollLocationInDeliveryActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an EnrollLocationInDeliveryActionProto.
             * @implements IEnrollLocationInDeliveryActionProto
             * @constructor
             * @param {waiternow.common.IEnrollLocationInDeliveryActionProto=} [properties] Properties to set
             */
            function EnrollLocationInDeliveryActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new EnrollLocationInDeliveryActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {waiternow.common.IEnrollLocationInDeliveryActionProto=} [properties] Properties to set
             * @returns {waiternow.common.EnrollLocationInDeliveryActionProto} EnrollLocationInDeliveryActionProto instance
             */
            EnrollLocationInDeliveryActionProto.create = function create(properties) {
                return new EnrollLocationInDeliveryActionProto(properties);
            };

            /**
             * Encodes the specified EnrollLocationInDeliveryActionProto message. Does not implicitly {@link waiternow.common.EnrollLocationInDeliveryActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {waiternow.common.IEnrollLocationInDeliveryActionProto} message EnrollLocationInDeliveryActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnrollLocationInDeliveryActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified EnrollLocationInDeliveryActionProto message, length delimited. Does not implicitly {@link waiternow.common.EnrollLocationInDeliveryActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {waiternow.common.IEnrollLocationInDeliveryActionProto} message EnrollLocationInDeliveryActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            EnrollLocationInDeliveryActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an EnrollLocationInDeliveryActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.EnrollLocationInDeliveryActionProto} EnrollLocationInDeliveryActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnrollLocationInDeliveryActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EnrollLocationInDeliveryActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an EnrollLocationInDeliveryActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.EnrollLocationInDeliveryActionProto} EnrollLocationInDeliveryActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            EnrollLocationInDeliveryActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an EnrollLocationInDeliveryActionProto message.
             * @function verify
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            EnrollLocationInDeliveryActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an EnrollLocationInDeliveryActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.EnrollLocationInDeliveryActionProto} EnrollLocationInDeliveryActionProto
             */
            EnrollLocationInDeliveryActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.EnrollLocationInDeliveryActionProto)
                    return object;
                return new $root.waiternow.common.EnrollLocationInDeliveryActionProto();
            };

            /**
             * Creates a plain object from an EnrollLocationInDeliveryActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {waiternow.common.EnrollLocationInDeliveryActionProto} message EnrollLocationInDeliveryActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            EnrollLocationInDeliveryActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this EnrollLocationInDeliveryActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            EnrollLocationInDeliveryActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for EnrollLocationInDeliveryActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            EnrollLocationInDeliveryActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.EnrollLocationInDeliveryActionProto";
            };

            EnrollLocationInDeliveryActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.EnrollLocationInDeliveryActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.EnrollLocationInDeliveryActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EnrollLocationInDeliveryActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.EnrollLocationInDeliveryActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.EnrollLocationInDeliveryActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.EnrollLocationInDeliveryActionProto.Request";
                };

                return Request;
            })();

            EnrollLocationInDeliveryActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.EnrollLocationInDeliveryActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.EnrollLocationInDeliveryActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.EnrollLocationInDeliveryActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.EnrollLocationInDeliveryActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.EnrollLocationInDeliveryActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.EnrollLocationInDeliveryActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.EnrollLocationInDeliveryActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {waiternow.common.EnrollLocationInDeliveryActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.EnrollLocationInDeliveryActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.EnrollLocationInDeliveryActionProto.Response";
                };

                return Response;
            })();

            return EnrollLocationInDeliveryActionProto;
        })();

        common.FindLocationsBySalespersonActionProto = (function() {

            /**
             * Properties of a FindLocationsBySalespersonActionProto.
             * @memberof waiternow.common
             * @interface IFindLocationsBySalespersonActionProto
             */

            /**
             * Constructs a new FindLocationsBySalespersonActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindLocationsBySalespersonActionProto.
             * @implements IFindLocationsBySalespersonActionProto
             * @constructor
             * @param {waiternow.common.IFindLocationsBySalespersonActionProto=} [properties] Properties to set
             */
            function FindLocationsBySalespersonActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindLocationsBySalespersonActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {waiternow.common.IFindLocationsBySalespersonActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindLocationsBySalespersonActionProto} FindLocationsBySalespersonActionProto instance
             */
            FindLocationsBySalespersonActionProto.create = function create(properties) {
                return new FindLocationsBySalespersonActionProto(properties);
            };

            /**
             * Encodes the specified FindLocationsBySalespersonActionProto message. Does not implicitly {@link waiternow.common.FindLocationsBySalespersonActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {waiternow.common.IFindLocationsBySalespersonActionProto} message FindLocationsBySalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsBySalespersonActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindLocationsBySalespersonActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsBySalespersonActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {waiternow.common.IFindLocationsBySalespersonActionProto} message FindLocationsBySalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationsBySalespersonActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindLocationsBySalespersonActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindLocationsBySalespersonActionProto} FindLocationsBySalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsBySalespersonActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsBySalespersonActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindLocationsBySalespersonActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindLocationsBySalespersonActionProto} FindLocationsBySalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationsBySalespersonActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindLocationsBySalespersonActionProto message.
             * @function verify
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindLocationsBySalespersonActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindLocationsBySalespersonActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindLocationsBySalespersonActionProto} FindLocationsBySalespersonActionProto
             */
            FindLocationsBySalespersonActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindLocationsBySalespersonActionProto)
                    return object;
                return new $root.waiternow.common.FindLocationsBySalespersonActionProto();
            };

            /**
             * Creates a plain object from a FindLocationsBySalespersonActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {waiternow.common.FindLocationsBySalespersonActionProto} message FindLocationsBySalespersonActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindLocationsBySalespersonActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindLocationsBySalespersonActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindLocationsBySalespersonActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindLocationsBySalespersonActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindLocationsBySalespersonActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindLocationsBySalespersonActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindLocationsBySalespersonActionProto";
            };

            FindLocationsBySalespersonActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto
                 * @interface IRequest
                 * @property {string|null} [salespersonId] Request salespersonId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request salespersonId.
                 * @member {string} salespersonId
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @instance
                 */
                Request.prototype.salespersonId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindLocationsBySalespersonActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.salespersonId != null && Object.hasOwnProperty.call(message, "salespersonId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.salespersonId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsBySalespersonActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsBySalespersonActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.salespersonId = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        if (!$util.isString(message.salespersonId))
                            return "salespersonId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsBySalespersonActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsBySalespersonActionProto.Request();
                    if (object.salespersonId != null)
                        message.salespersonId = String(object.salespersonId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.salespersonId = "";
                        object.continuationToken = "";
                    }
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        object.salespersonId = message.salespersonId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsBySalespersonActionProto.Request";
                };

                return Request;
            })();

            FindLocationsBySalespersonActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationsProto|null} [locations] Response locations
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locations.
                 * @member {waiternow.common.ILocationsProto|null|undefined} locations
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @instance
                 */
                Response.prototype.locations = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindLocationsBySalespersonActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locations != null && Object.hasOwnProperty.call(message, "locations"))
                        $root.waiternow.common.LocationsProto.encode(message.locations, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindLocationsBySalespersonActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationsBySalespersonActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locations = $root.waiternow.common.LocationsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locations != null && message.hasOwnProperty("locations")) {
                        var error = $root.waiternow.common.LocationsProto.verify(message.locations);
                        if (error)
                            return "locations." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationsBySalespersonActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationsBySalespersonActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindLocationsBySalespersonActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindLocationsBySalespersonActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locations != null) {
                        if (typeof object.locations !== "object")
                            throw TypeError(".waiternow.common.FindLocationsBySalespersonActionProto.Response.locations: object expected");
                        message.locations = $root.waiternow.common.LocationsProto.fromObject(object.locations);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationsBySalespersonActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locations = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locations != null && message.hasOwnProperty("locations"))
                        object.locations = $root.waiternow.common.LocationsProto.toObject(message.locations, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationsBySalespersonActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationsBySalespersonActionProto.Response";
                };

                return Response;
            })();

            return FindLocationsBySalespersonActionProto;
        })();

        common.AddImageToSubmenuActionProto = (function() {

            /**
             * Properties of an AddImageToSubmenuActionProto.
             * @memberof waiternow.common
             * @interface IAddImageToSubmenuActionProto
             */

            /**
             * Constructs a new AddImageToSubmenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AddImageToSubmenuActionProto.
             * @implements IAddImageToSubmenuActionProto
             * @constructor
             * @param {waiternow.common.IAddImageToSubmenuActionProto=} [properties] Properties to set
             */
            function AddImageToSubmenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AddImageToSubmenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {waiternow.common.IAddImageToSubmenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AddImageToSubmenuActionProto} AddImageToSubmenuActionProto instance
             */
            AddImageToSubmenuActionProto.create = function create(properties) {
                return new AddImageToSubmenuActionProto(properties);
            };

            /**
             * Encodes the specified AddImageToSubmenuActionProto message. Does not implicitly {@link waiternow.common.AddImageToSubmenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {waiternow.common.IAddImageToSubmenuActionProto} message AddImageToSubmenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddImageToSubmenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AddImageToSubmenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.AddImageToSubmenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {waiternow.common.IAddImageToSubmenuActionProto} message AddImageToSubmenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddImageToSubmenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddImageToSubmenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AddImageToSubmenuActionProto} AddImageToSubmenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddImageToSubmenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToSubmenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddImageToSubmenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AddImageToSubmenuActionProto} AddImageToSubmenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddImageToSubmenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddImageToSubmenuActionProto message.
             * @function verify
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddImageToSubmenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AddImageToSubmenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AddImageToSubmenuActionProto} AddImageToSubmenuActionProto
             */
            AddImageToSubmenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AddImageToSubmenuActionProto)
                    return object;
                return new $root.waiternow.common.AddImageToSubmenuActionProto();
            };

            /**
             * Creates a plain object from an AddImageToSubmenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {waiternow.common.AddImageToSubmenuActionProto} message AddImageToSubmenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddImageToSubmenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AddImageToSubmenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddImageToSubmenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddImageToSubmenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AddImageToSubmenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddImageToSubmenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AddImageToSubmenuActionProto";
            };

            AddImageToSubmenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AddImageToSubmenuActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AddImageToSubmenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AddImageToSubmenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AddImageToSubmenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToSubmenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddImageToSubmenuActionProto.Request)
                        return object;
                    return new $root.waiternow.common.AddImageToSubmenuActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddImageToSubmenuActionProto.Request";
                };

                return Request;
            })();

            AddImageToSubmenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AddImageToSubmenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IImageProto|null} [image] Response image
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AddImageToSubmenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @instance
                 */
                Response.prototype.image = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AddImageToSubmenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AddImageToSubmenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToSubmenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddImageToSubmenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddImageToSubmenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AddImageToSubmenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AddImageToSubmenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.AddImageToSubmenuActionProto.Response.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToSubmenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.image = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddImageToSubmenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddImageToSubmenuActionProto.Response";
                };

                return Response;
            })();

            return AddImageToSubmenuActionProto;
        })();

        common.RemoveSubmenuActionProto = (function() {

            /**
             * Properties of a RemoveSubmenuActionProto.
             * @memberof waiternow.common
             * @interface IRemoveSubmenuActionProto
             */

            /**
             * Constructs a new RemoveSubmenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RemoveSubmenuActionProto.
             * @implements IRemoveSubmenuActionProto
             * @constructor
             * @param {waiternow.common.IRemoveSubmenuActionProto=} [properties] Properties to set
             */
            function RemoveSubmenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RemoveSubmenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {waiternow.common.IRemoveSubmenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RemoveSubmenuActionProto} RemoveSubmenuActionProto instance
             */
            RemoveSubmenuActionProto.create = function create(properties) {
                return new RemoveSubmenuActionProto(properties);
            };

            /**
             * Encodes the specified RemoveSubmenuActionProto message. Does not implicitly {@link waiternow.common.RemoveSubmenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {waiternow.common.IRemoveSubmenuActionProto} message RemoveSubmenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveSubmenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RemoveSubmenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.RemoveSubmenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {waiternow.common.IRemoveSubmenuActionProto} message RemoveSubmenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveSubmenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveSubmenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RemoveSubmenuActionProto} RemoveSubmenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveSubmenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveSubmenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveSubmenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RemoveSubmenuActionProto} RemoveSubmenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveSubmenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveSubmenuActionProto message.
             * @function verify
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveSubmenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RemoveSubmenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RemoveSubmenuActionProto} RemoveSubmenuActionProto
             */
            RemoveSubmenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RemoveSubmenuActionProto)
                    return object;
                return new $root.waiternow.common.RemoveSubmenuActionProto();
            };

            /**
             * Creates a plain object from a RemoveSubmenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {waiternow.common.RemoveSubmenuActionProto} message RemoveSubmenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveSubmenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RemoveSubmenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveSubmenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RemoveSubmenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RemoveSubmenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RemoveSubmenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RemoveSubmenuActionProto";
            };

            RemoveSubmenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RemoveSubmenuActionProto
                 * @interface IRequest
                 * @property {string|null} [submenuName] Request submenuName
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [imageId] Request imageId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RemoveSubmenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RemoveSubmenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request submenuName.
                 * @member {string} submenuName
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @instance
                 */
                Request.prototype.submenuName = "";

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request imageId.
                 * @member {string} imageId
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @instance
                 */
                Request.prototype.imageId = "";

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RemoveSubmenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.submenuName != null && Object.hasOwnProperty.call(message, "submenuName"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.submenuName);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.locationId);
                    if (message.imageId != null && Object.hasOwnProperty.call(message, "imageId"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.imageId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RemoveSubmenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveSubmenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.submenuName = reader.string();
                                break;
                            }
                        case 2: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 3: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 4: {
                                message.imageId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.submenuName != null && message.hasOwnProperty("submenuName"))
                        if (!$util.isString(message.submenuName))
                            return "submenuName: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.imageId != null && message.hasOwnProperty("imageId"))
                        if (!$util.isString(message.imageId))
                            return "imageId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveSubmenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RemoveSubmenuActionProto.Request();
                    if (object.submenuName != null)
                        message.submenuName = String(object.submenuName);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.imageId != null)
                        message.imageId = String(object.imageId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.submenuName = "";
                        object.imageId = "";
                    }
                    if (message.submenuName != null && message.hasOwnProperty("submenuName"))
                        object.submenuName = message.submenuName;
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    if (message.imageId != null && message.hasOwnProperty("imageId"))
                        object.imageId = message.imageId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveSubmenuActionProto.Request";
                };

                return Request;
            })();

            RemoveSubmenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RemoveSubmenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RemoveSubmenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RemoveSubmenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RemoveSubmenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RemoveSubmenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveSubmenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveSubmenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveSubmenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RemoveSubmenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RemoveSubmenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveSubmenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveSubmenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveSubmenuActionProto.Response";
                };

                return Response;
            })();

            return RemoveSubmenuActionProto;
        })();

        common.SortSubmenuActionProto = (function() {

            /**
             * Properties of a SortSubmenuActionProto.
             * @memberof waiternow.common
             * @interface ISortSubmenuActionProto
             */

            /**
             * Constructs a new SortSubmenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SortSubmenuActionProto.
             * @implements ISortSubmenuActionProto
             * @constructor
             * @param {waiternow.common.ISortSubmenuActionProto=} [properties] Properties to set
             */
            function SortSubmenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SortSubmenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {waiternow.common.ISortSubmenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SortSubmenuActionProto} SortSubmenuActionProto instance
             */
            SortSubmenuActionProto.create = function create(properties) {
                return new SortSubmenuActionProto(properties);
            };

            /**
             * Encodes the specified SortSubmenuActionProto message. Does not implicitly {@link waiternow.common.SortSubmenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {waiternow.common.ISortSubmenuActionProto} message SortSubmenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SortSubmenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SortSubmenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.SortSubmenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {waiternow.common.ISortSubmenuActionProto} message SortSubmenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SortSubmenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SortSubmenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SortSubmenuActionProto} SortSubmenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SortSubmenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SortSubmenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SortSubmenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SortSubmenuActionProto} SortSubmenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SortSubmenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SortSubmenuActionProto message.
             * @function verify
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SortSubmenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SortSubmenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SortSubmenuActionProto} SortSubmenuActionProto
             */
            SortSubmenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SortSubmenuActionProto)
                    return object;
                return new $root.waiternow.common.SortSubmenuActionProto();
            };

            /**
             * Creates a plain object from a SortSubmenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {waiternow.common.SortSubmenuActionProto} message SortSubmenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SortSubmenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SortSubmenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SortSubmenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SortSubmenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SortSubmenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SortSubmenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SortSubmenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SortSubmenuActionProto";
            };

            SortSubmenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SortSubmenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.MenuProto.ISubmenuProto|null} [sortedSubmenu] Request sortedSubmenu
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SortSubmenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SortSubmenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request sortedSubmenu.
                 * @member {waiternow.common.MenuProto.ISubmenuProto|null|undefined} sortedSubmenu
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @instance
                 */
                Request.prototype.sortedSubmenu = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SortSubmenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SortSubmenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.sortedSubmenu != null && Object.hasOwnProperty.call(message, "sortedSubmenu"))
                        $root.waiternow.common.MenuProto.SubmenuProto.encode(message.sortedSubmenu, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SortSubmenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SortSubmenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SortSubmenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.sortedSubmenu = $root.waiternow.common.MenuProto.SubmenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SortSubmenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.sortedSubmenu != null && message.hasOwnProperty("sortedSubmenu")) {
                        var error = $root.waiternow.common.MenuProto.SubmenuProto.verify(message.sortedSubmenu);
                        if (error)
                            return "sortedSubmenu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SortSubmenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SortSubmenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SortSubmenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.sortedSubmenu != null) {
                        if (typeof object.sortedSubmenu !== "object")
                            throw TypeError(".waiternow.common.SortSubmenuActionProto.Request.sortedSubmenu: object expected");
                        message.sortedSubmenu = $root.waiternow.common.MenuProto.SubmenuProto.fromObject(object.sortedSubmenu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.sortedSubmenu = null;
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    if (message.sortedSubmenu != null && message.hasOwnProperty("sortedSubmenu"))
                        object.sortedSubmenu = $root.waiternow.common.MenuProto.SubmenuProto.toObject(message.sortedSubmenu, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SortSubmenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SortSubmenuActionProto.Request";
                };

                return Request;
            })();

            SortSubmenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SortSubmenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SortSubmenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SortSubmenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SortSubmenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SortSubmenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SortSubmenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SortSubmenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SortSubmenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SortSubmenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SortSubmenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SortSubmenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SortSubmenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SortSubmenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SortSubmenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SortSubmenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SortSubmenuActionProto.Response";
                };

                return Response;
            })();

            return SortSubmenuActionProto;
        })();

        common.AddImageToStructuredMenuActionProto = (function() {

            /**
             * Properties of an AddImageToStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface IAddImageToStructuredMenuActionProto
             */

            /**
             * Constructs a new AddImageToStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AddImageToStructuredMenuActionProto.
             * @implements IAddImageToStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.IAddImageToStructuredMenuActionProto=} [properties] Properties to set
             */
            function AddImageToStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AddImageToStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IAddImageToStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AddImageToStructuredMenuActionProto} AddImageToStructuredMenuActionProto instance
             */
            AddImageToStructuredMenuActionProto.create = function create(properties) {
                return new AddImageToStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified AddImageToStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IAddImageToStructuredMenuActionProto} message AddImageToStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddImageToStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AddImageToStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IAddImageToStructuredMenuActionProto} message AddImageToStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddImageToStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddImageToStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AddImageToStructuredMenuActionProto} AddImageToStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddImageToStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddImageToStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AddImageToStructuredMenuActionProto} AddImageToStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddImageToStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddImageToStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddImageToStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AddImageToStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AddImageToStructuredMenuActionProto} AddImageToStructuredMenuActionProto
             */
            AddImageToStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AddImageToStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.AddImageToStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from an AddImageToStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {waiternow.common.AddImageToStructuredMenuActionProto} message AddImageToStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddImageToStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AddImageToStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddImageToStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddImageToStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AddImageToStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddImageToStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AddImageToStructuredMenuActionProto";
            };

            AddImageToStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 * @property {Uint8Array|null} [image] Request image
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request image.
                 * @member {Uint8Array} image
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.image = $util.newBuffer([]);

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.image);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.image = reader.bytes();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.image != null && message.hasOwnProperty("image"))
                        if (!(message.image && typeof message.image.length === "number" || $util.isString(message.image)))
                            return "image: buffer expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddImageToStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.AddImageToStructuredMenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.image != null)
                        if (typeof object.image === "string")
                            $util.base64.decode(object.image, message.image = $util.newBuffer($util.base64.length(object.image)), 0);
                        else if (object.image.length >= 0)
                            message.image = object.image;
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        if (options.bytes === String)
                            object.image = "";
                        else {
                            object.image = [];
                            if (options.bytes !== Array)
                                object.image = $util.newBuffer(object.image);
                        }
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = options.bytes === String ? $util.base64.encode(message.image, 0, message.image.length) : options.bytes === Array ? Array.prototype.slice.call(message.image) : message.image;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddImageToStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            AddImageToStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IImageProto|null} [image] Response image
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.image = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddImageToStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddImageToStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AddImageToStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AddImageToStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.AddImageToStructuredMenuActionProto.Response.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.image = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddImageToStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddImageToStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return AddImageToStructuredMenuActionProto;
        })();

        common.AddImageToStructuredMenuMultiPartFormRequestActionProto = (function() {

            /**
             * Properties of an AddImageToStructuredMenuMultiPartFormRequestActionProto.
             * @memberof waiternow.common
             * @interface IAddImageToStructuredMenuMultiPartFormRequestActionProto
             */

            /**
             * Constructs a new AddImageToStructuredMenuMultiPartFormRequestActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AddImageToStructuredMenuMultiPartFormRequestActionProto.
             * @implements IAddImageToStructuredMenuMultiPartFormRequestActionProto
             * @constructor
             * @param {waiternow.common.IAddImageToStructuredMenuMultiPartFormRequestActionProto=} [properties] Properties to set
             */
            function AddImageToStructuredMenuMultiPartFormRequestActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AddImageToStructuredMenuMultiPartFormRequestActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {waiternow.common.IAddImageToStructuredMenuMultiPartFormRequestActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto} AddImageToStructuredMenuMultiPartFormRequestActionProto instance
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.create = function create(properties) {
                return new AddImageToStructuredMenuMultiPartFormRequestActionProto(properties);
            };

            /**
             * Encodes the specified AddImageToStructuredMenuMultiPartFormRequestActionProto message. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {waiternow.common.IAddImageToStructuredMenuMultiPartFormRequestActionProto} message AddImageToStructuredMenuMultiPartFormRequestActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AddImageToStructuredMenuMultiPartFormRequestActionProto message, length delimited. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {waiternow.common.IAddImageToStructuredMenuMultiPartFormRequestActionProto} message AddImageToStructuredMenuMultiPartFormRequestActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AddImageToStructuredMenuMultiPartFormRequestActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto} AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AddImageToStructuredMenuMultiPartFormRequestActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto} AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AddImageToStructuredMenuMultiPartFormRequestActionProto message.
             * @function verify
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AddImageToStructuredMenuMultiPartFormRequestActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto} AddImageToStructuredMenuMultiPartFormRequestActionProto
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto)
                    return object;
                return new $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto();
            };

            /**
             * Creates a plain object from an AddImageToStructuredMenuMultiPartFormRequestActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto} message AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AddImageToStructuredMenuMultiPartFormRequestActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AddImageToStructuredMenuMultiPartFormRequestActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto";
            };

            AddImageToStructuredMenuMultiPartFormRequestActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
                 * @interface IRequest
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request)
                        return object;
                    return new $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request();
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Request";
                };

                return Request;
            })();

            AddImageToStructuredMenuMultiPartFormRequestActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IImageProto|null} [image] Response image
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response image.
                 * @member {waiternow.common.IImageProto|null|undefined} image
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @instance
                 */
                Response.prototype.image = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.image != null && Object.hasOwnProperty.call(message, "image"))
                        $root.waiternow.common.ImageProto.encode(message.image, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.image = $root.waiternow.common.ImageProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.image != null && message.hasOwnProperty("image")) {
                        var error = $root.waiternow.common.ImageProto.verify(message.image);
                        if (error)
                            return "image." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.image != null) {
                        if (typeof object.image !== "object")
                            throw TypeError(".waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response.image: object expected");
                        message.image = $root.waiternow.common.ImageProto.fromObject(object.image);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.image = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.image != null && message.hasOwnProperty("image"))
                        object.image = $root.waiternow.common.ImageProto.toObject(message.image, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AddImageToStructuredMenuMultiPartFormRequestActionProto.Response";
                };

                return Response;
            })();

            return AddImageToStructuredMenuMultiPartFormRequestActionProto;
        })();

        common.SaveDraftOfStructuredMenuActionProto = (function() {

            /**
             * Properties of a SaveDraftOfStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface ISaveDraftOfStructuredMenuActionProto
             */

            /**
             * Constructs a new SaveDraftOfStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a SaveDraftOfStructuredMenuActionProto.
             * @implements ISaveDraftOfStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.ISaveDraftOfStructuredMenuActionProto=} [properties] Properties to set
             */
            function SaveDraftOfStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new SaveDraftOfStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.ISaveDraftOfStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto} SaveDraftOfStructuredMenuActionProto instance
             */
            SaveDraftOfStructuredMenuActionProto.create = function create(properties) {
                return new SaveDraftOfStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified SaveDraftOfStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.SaveDraftOfStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.ISaveDraftOfStructuredMenuActionProto} message SaveDraftOfStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveDraftOfStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified SaveDraftOfStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.SaveDraftOfStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.ISaveDraftOfStructuredMenuActionProto} message SaveDraftOfStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            SaveDraftOfStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a SaveDraftOfStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto} SaveDraftOfStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveDraftOfStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SaveDraftOfStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a SaveDraftOfStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto} SaveDraftOfStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            SaveDraftOfStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a SaveDraftOfStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            SaveDraftOfStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a SaveDraftOfStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto} SaveDraftOfStructuredMenuActionProto
             */
            SaveDraftOfStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.SaveDraftOfStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.SaveDraftOfStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from a SaveDraftOfStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto} message SaveDraftOfStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            SaveDraftOfStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this SaveDraftOfStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            SaveDraftOfStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for SaveDraftOfStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            SaveDraftOfStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.SaveDraftOfStructuredMenuActionProto";
            };

            SaveDraftOfStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.IStructuredMenuSpecProto|null} [menu] Request menu
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request menu.
                 * @member {waiternow.common.IStructuredMenuSpecProto|null|undefined} menu
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.menu = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.SaveDraftOfStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuSpecProto.encode(message.menu, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.SaveDraftOfStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SaveDraftOfStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.menu = $root.waiternow.common.StructuredMenuSpecProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SaveDraftOfStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.SaveDraftOfStructuredMenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.SaveDraftOfStructuredMenuActionProto.Request.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuSpecProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.menu = null;
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuSpecProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SaveDraftOfStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            SaveDraftOfStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.SaveDraftOfStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.SaveDraftOfStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.SaveDraftOfStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.SaveDraftOfStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.SaveDraftOfStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.SaveDraftOfStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.SaveDraftOfStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.SaveDraftOfStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.SaveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.SaveDraftOfStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return SaveDraftOfStructuredMenuActionProto;
        })();

        common.PublishStructuredMenuActionProto = (function() {

            /**
             * Properties of a PublishStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface IPublishStructuredMenuActionProto
             */

            /**
             * Constructs a new PublishStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a PublishStructuredMenuActionProto.
             * @implements IPublishStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.IPublishStructuredMenuActionProto=} [properties] Properties to set
             */
            function PublishStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new PublishStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IPublishStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.PublishStructuredMenuActionProto} PublishStructuredMenuActionProto instance
             */
            PublishStructuredMenuActionProto.create = function create(properties) {
                return new PublishStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified PublishStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.PublishStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IPublishStructuredMenuActionProto} message PublishStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified PublishStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.PublishStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IPublishStructuredMenuActionProto} message PublishStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            PublishStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a PublishStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.PublishStructuredMenuActionProto} PublishStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PublishStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a PublishStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.PublishStructuredMenuActionProto} PublishStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            PublishStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a PublishStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            PublishStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a PublishStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.PublishStructuredMenuActionProto} PublishStructuredMenuActionProto
             */
            PublishStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.PublishStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.PublishStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from a PublishStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {waiternow.common.PublishStructuredMenuActionProto} message PublishStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            PublishStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this PublishStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            PublishStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for PublishStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.PublishStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            PublishStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.PublishStructuredMenuActionProto";
            };

            PublishStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.PublishStructuredMenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.IStructuredMenuSpecProto|null} [menu] Request menu
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.PublishStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request menu.
                 * @member {waiternow.common.IStructuredMenuSpecProto|null|undefined} menu
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.menu = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.PublishStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuSpecProto.encode(message.menu, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.PublishStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PublishStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.menu = $root.waiternow.common.StructuredMenuSpecProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PublishStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.PublishStructuredMenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.PublishStructuredMenuActionProto.Request.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuSpecProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.menu = null;
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuSpecProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PublishStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            PublishStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.PublishStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.PublishStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.PublishStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.PublishStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.PublishStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.PublishStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.PublishStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.PublishStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.PublishStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.PublishStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.PublishStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.PublishStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return PublishStructuredMenuActionProto;
        })();

        common.GetDraftOfStructuredMenuActionProto = (function() {

            /**
             * Properties of a GetDraftOfStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface IGetDraftOfStructuredMenuActionProto
             */

            /**
             * Constructs a new GetDraftOfStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetDraftOfStructuredMenuActionProto.
             * @implements IGetDraftOfStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.IGetDraftOfStructuredMenuActionProto=} [properties] Properties to set
             */
            function GetDraftOfStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetDraftOfStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IGetDraftOfStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto} GetDraftOfStructuredMenuActionProto instance
             */
            GetDraftOfStructuredMenuActionProto.create = function create(properties) {
                return new GetDraftOfStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified GetDraftOfStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.GetDraftOfStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IGetDraftOfStructuredMenuActionProto} message GetDraftOfStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDraftOfStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetDraftOfStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetDraftOfStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IGetDraftOfStructuredMenuActionProto} message GetDraftOfStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetDraftOfStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetDraftOfStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto} GetDraftOfStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDraftOfStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetDraftOfStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetDraftOfStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto} GetDraftOfStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetDraftOfStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetDraftOfStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetDraftOfStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetDraftOfStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto} GetDraftOfStructuredMenuActionProto
             */
            GetDraftOfStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetDraftOfStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.GetDraftOfStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from a GetDraftOfStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.GetDraftOfStructuredMenuActionProto} message GetDraftOfStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetDraftOfStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetDraftOfStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetDraftOfStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetDraftOfStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetDraftOfStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetDraftOfStructuredMenuActionProto";
            };

            GetDraftOfStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetDraftOfStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetDraftOfStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetDraftOfStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetDraftOfStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetDraftOfStructuredMenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetDraftOfStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            GetDraftOfStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IStructuredMenuSpecProto|null} [menu] Response menu
                 * @property {boolean|null} [draftGeneratedFromParentBusiness] Response draftGeneratedFromParentBusiness
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response menu.
                 * @member {waiternow.common.IStructuredMenuSpecProto|null|undefined} menu
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.menu = null;

                /**
                 * Response draftGeneratedFromParentBusiness.
                 * @member {boolean} draftGeneratedFromParentBusiness
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.draftGeneratedFromParentBusiness = false;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetDraftOfStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuSpecProto.encode(message.menu, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.draftGeneratedFromParentBusiness != null && Object.hasOwnProperty.call(message, "draftGeneratedFromParentBusiness"))
                        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.draftGeneratedFromParentBusiness);
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetDraftOfStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetDraftOfStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.menu = $root.waiternow.common.StructuredMenuSpecProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.draftGeneratedFromParentBusiness = reader.bool();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    if (message.draftGeneratedFromParentBusiness != null && message.hasOwnProperty("draftGeneratedFromParentBusiness"))
                        if (typeof message.draftGeneratedFromParentBusiness !== "boolean")
                            return "draftGeneratedFromParentBusiness: boolean expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetDraftOfStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetDraftOfStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetDraftOfStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetDraftOfStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.GetDraftOfStructuredMenuActionProto.Response.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuSpecProto.fromObject(object.menu);
                    }
                    if (object.draftGeneratedFromParentBusiness != null)
                        message.draftGeneratedFromParentBusiness = Boolean(object.draftGeneratedFromParentBusiness);
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetDraftOfStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.menu = null;
                        object.draftGeneratedFromParentBusiness = false;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuSpecProto.toObject(message.menu, options);
                    if (message.draftGeneratedFromParentBusiness != null && message.hasOwnProperty("draftGeneratedFromParentBusiness"))
                        object.draftGeneratedFromParentBusiness = message.draftGeneratedFromParentBusiness;
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetDraftOfStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return GetDraftOfStructuredMenuActionProto;
        })();

        common.GetStructuredMenuActionProto = (function() {

            /**
             * Properties of a GetStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface IGetStructuredMenuActionProto
             */

            /**
             * Constructs a new GetStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetStructuredMenuActionProto.
             * @implements IGetStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.IGetStructuredMenuActionProto=} [properties] Properties to set
             */
            function GetStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetStructuredMenuActionProto} GetStructuredMenuActionProto instance
             */
            GetStructuredMenuActionProto.create = function create(properties) {
                return new GetStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified GetStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.GetStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuActionProto} message GetStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuActionProto} message GetStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetStructuredMenuActionProto} GetStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetStructuredMenuActionProto} GetStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetStructuredMenuActionProto} GetStructuredMenuActionProto
             */
            GetStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.GetStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from a GetStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {waiternow.common.GetStructuredMenuActionProto} message GetStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetStructuredMenuActionProto";
            };

            GetStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetStructuredMenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            GetStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IStructuredMenuSpecProto|null} [menu] Response menu
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response menu.
                 * @member {waiternow.common.IStructuredMenuSpecProto|null|undefined} menu
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.menu = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuSpecProto.encode(message.menu, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.menu = $root.waiternow.common.StructuredMenuSpecProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.GetStructuredMenuActionProto.Response.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuSpecProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.menu = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuSpecProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return GetStructuredMenuActionProto;
        })();

        common.GetStructuredMenuDenormalizedActionProto = (function() {

            /**
             * Properties of a GetStructuredMenuDenormalizedActionProto.
             * @memberof waiternow.common
             * @interface IGetStructuredMenuDenormalizedActionProto
             */

            /**
             * Constructs a new GetStructuredMenuDenormalizedActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetStructuredMenuDenormalizedActionProto.
             * @implements IGetStructuredMenuDenormalizedActionProto
             * @constructor
             * @param {waiternow.common.IGetStructuredMenuDenormalizedActionProto=} [properties] Properties to set
             */
            function GetStructuredMenuDenormalizedActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetStructuredMenuDenormalizedActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuDenormalizedActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto} GetStructuredMenuDenormalizedActionProto instance
             */
            GetStructuredMenuDenormalizedActionProto.create = function create(properties) {
                return new GetStructuredMenuDenormalizedActionProto(properties);
            };

            /**
             * Encodes the specified GetStructuredMenuDenormalizedActionProto message. Does not implicitly {@link waiternow.common.GetStructuredMenuDenormalizedActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuDenormalizedActionProto} message GetStructuredMenuDenormalizedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuDenormalizedActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetStructuredMenuDenormalizedActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuDenormalizedActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuDenormalizedActionProto} message GetStructuredMenuDenormalizedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuDenormalizedActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetStructuredMenuDenormalizedActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto} GetStructuredMenuDenormalizedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuDenormalizedActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuDenormalizedActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetStructuredMenuDenormalizedActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto} GetStructuredMenuDenormalizedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuDenormalizedActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetStructuredMenuDenormalizedActionProto message.
             * @function verify
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetStructuredMenuDenormalizedActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetStructuredMenuDenormalizedActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto} GetStructuredMenuDenormalizedActionProto
             */
            GetStructuredMenuDenormalizedActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetStructuredMenuDenormalizedActionProto)
                    return object;
                return new $root.waiternow.common.GetStructuredMenuDenormalizedActionProto();
            };

            /**
             * Creates a plain object from a GetStructuredMenuDenormalizedActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto} message GetStructuredMenuDenormalizedActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStructuredMenuDenormalizedActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetStructuredMenuDenormalizedActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStructuredMenuDenormalizedActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStructuredMenuDenormalizedActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStructuredMenuDenormalizedActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetStructuredMenuDenormalizedActionProto";
            };

            GetStructuredMenuDenormalizedActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetStructuredMenuDenormalizedActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuDenormalizedActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuDenormalizedActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuDenormalizedActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuDenormalizedActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuDenormalizedActionProto.Request";
                };

                return Request;
            })();

            GetStructuredMenuDenormalizedActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IStructuredMenuProto|null} [menu] Response menu
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response menu.
                 * @member {waiternow.common.IStructuredMenuProto|null|undefined} menu
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @instance
                 */
                Response.prototype.menu = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetStructuredMenuDenormalizedActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuProto.encode(message.menu, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuDenormalizedActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuDenormalizedActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.menu = $root.waiternow.common.StructuredMenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuDenormalizedActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuDenormalizedActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuDenormalizedActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetStructuredMenuDenormalizedActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.GetStructuredMenuDenormalizedActionProto.Response.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuDenormalizedActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.menu = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuDenormalizedActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuDenormalizedActionProto.Response";
                };

                return Response;
            })();

            return GetStructuredMenuDenormalizedActionProto;
        })();

        common.RemoveDraftOfStructuredMenuActionProto = (function() {

            /**
             * Properties of a RemoveDraftOfStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface IRemoveDraftOfStructuredMenuActionProto
             */

            /**
             * Constructs a new RemoveDraftOfStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RemoveDraftOfStructuredMenuActionProto.
             * @implements IRemoveDraftOfStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.IRemoveDraftOfStructuredMenuActionProto=} [properties] Properties to set
             */
            function RemoveDraftOfStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RemoveDraftOfStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IRemoveDraftOfStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto} RemoveDraftOfStructuredMenuActionProto instance
             */
            RemoveDraftOfStructuredMenuActionProto.create = function create(properties) {
                return new RemoveDraftOfStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified RemoveDraftOfStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.RemoveDraftOfStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IRemoveDraftOfStructuredMenuActionProto} message RemoveDraftOfStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveDraftOfStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RemoveDraftOfStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.RemoveDraftOfStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IRemoveDraftOfStructuredMenuActionProto} message RemoveDraftOfStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveDraftOfStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveDraftOfStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto} RemoveDraftOfStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveDraftOfStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveDraftOfStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto} RemoveDraftOfStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveDraftOfStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveDraftOfStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveDraftOfStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RemoveDraftOfStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto} RemoveDraftOfStructuredMenuActionProto
             */
            RemoveDraftOfStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from a RemoveDraftOfStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto} message RemoveDraftOfStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveDraftOfStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RemoveDraftOfStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveDraftOfStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RemoveDraftOfStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RemoveDraftOfStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RemoveDraftOfStructuredMenuActionProto";
            };

            RemoveDraftOfStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveDraftOfStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            RemoveDraftOfStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveDraftOfStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return RemoveDraftOfStructuredMenuActionProto;
        })();

        common.RemoveStructuredMenuActionProto = (function() {

            /**
             * Properties of a RemoveStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface IRemoveStructuredMenuActionProto
             */

            /**
             * Constructs a new RemoveStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RemoveStructuredMenuActionProto.
             * @implements IRemoveStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.IRemoveStructuredMenuActionProto=} [properties] Properties to set
             */
            function RemoveStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RemoveStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IRemoveStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RemoveStructuredMenuActionProto} RemoveStructuredMenuActionProto instance
             */
            RemoveStructuredMenuActionProto.create = function create(properties) {
                return new RemoveStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified RemoveStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.RemoveStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IRemoveStructuredMenuActionProto} message RemoveStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RemoveStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.RemoveStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IRemoveStructuredMenuActionProto} message RemoveStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RemoveStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RemoveStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RemoveStructuredMenuActionProto} RemoveStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RemoveStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RemoveStructuredMenuActionProto} RemoveStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RemoveStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RemoveStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RemoveStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RemoveStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RemoveStructuredMenuActionProto} RemoveStructuredMenuActionProto
             */
            RemoveStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RemoveStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.RemoveStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from a RemoveStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {waiternow.common.RemoveStructuredMenuActionProto} message RemoveStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RemoveStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RemoveStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RemoveStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RemoveStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RemoveStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RemoveStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RemoveStructuredMenuActionProto";
            };

            RemoveStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"businessId"|"locationId"|undefined} subject
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RemoveStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RemoveStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RemoveStructuredMenuActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.subject = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            RemoveStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RemoveStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RemoveStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RemoveStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RemoveStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RemoveStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RemoveStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RemoveStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.RemoveStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RemoveStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RemoveStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return RemoveStructuredMenuActionProto;
        })();

        common.GetStructuredMenuAvailabilityActionProto = (function() {

            /**
             * Properties of a GetStructuredMenuAvailabilityActionProto.
             * @memberof waiternow.common
             * @interface IGetStructuredMenuAvailabilityActionProto
             */

            /**
             * Constructs a new GetStructuredMenuAvailabilityActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetStructuredMenuAvailabilityActionProto.
             * @implements IGetStructuredMenuAvailabilityActionProto
             * @constructor
             * @param {waiternow.common.IGetStructuredMenuAvailabilityActionProto=} [properties] Properties to set
             */
            function GetStructuredMenuAvailabilityActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetStructuredMenuAvailabilityActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuAvailabilityActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto} GetStructuredMenuAvailabilityActionProto instance
             */
            GetStructuredMenuAvailabilityActionProto.create = function create(properties) {
                return new GetStructuredMenuAvailabilityActionProto(properties);
            };

            /**
             * Encodes the specified GetStructuredMenuAvailabilityActionProto message. Does not implicitly {@link waiternow.common.GetStructuredMenuAvailabilityActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuAvailabilityActionProto} message GetStructuredMenuAvailabilityActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuAvailabilityActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetStructuredMenuAvailabilityActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuAvailabilityActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.IGetStructuredMenuAvailabilityActionProto} message GetStructuredMenuAvailabilityActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetStructuredMenuAvailabilityActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetStructuredMenuAvailabilityActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto} GetStructuredMenuAvailabilityActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuAvailabilityActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuAvailabilityActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetStructuredMenuAvailabilityActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto} GetStructuredMenuAvailabilityActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetStructuredMenuAvailabilityActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetStructuredMenuAvailabilityActionProto message.
             * @function verify
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetStructuredMenuAvailabilityActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetStructuredMenuAvailabilityActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto} GetStructuredMenuAvailabilityActionProto
             */
            GetStructuredMenuAvailabilityActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetStructuredMenuAvailabilityActionProto)
                    return object;
                return new $root.waiternow.common.GetStructuredMenuAvailabilityActionProto();
            };

            /**
             * Creates a plain object from a GetStructuredMenuAvailabilityActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto} message GetStructuredMenuAvailabilityActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetStructuredMenuAvailabilityActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetStructuredMenuAvailabilityActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetStructuredMenuAvailabilityActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetStructuredMenuAvailabilityActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetStructuredMenuAvailabilityActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetStructuredMenuAvailabilityActionProto";
            };

            GetStructuredMenuAvailabilityActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetStructuredMenuAvailabilityActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuAvailabilityActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuAvailabilityActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuAvailabilityActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuAvailabilityActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuAvailabilityActionProto.Request";
                };

                return Request;
            })();

            GetStructuredMenuAvailabilityActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {Array.<waiternow.common.IMenuEntryAvailabilityProto>|null} [menuEntriesAvailability] Response menuEntriesAvailability
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    this.menuEntriesAvailability = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response menuEntriesAvailability.
                 * @member {Array.<waiternow.common.IMenuEntryAvailabilityProto>} menuEntriesAvailability
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @instance
                 */
                Response.prototype.menuEntriesAvailability = $util.emptyArray;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetStructuredMenuAvailabilityActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menuEntriesAvailability != null && message.menuEntriesAvailability.length)
                        for (var i = 0; i < message.menuEntriesAvailability.length; ++i)
                            $root.waiternow.common.MenuEntryAvailabilityProto.encode(message.menuEntriesAvailability[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetStructuredMenuAvailabilityActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetStructuredMenuAvailabilityActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.menuEntriesAvailability && message.menuEntriesAvailability.length))
                                    message.menuEntriesAvailability = [];
                                message.menuEntriesAvailability.push($root.waiternow.common.MenuEntryAvailabilityProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.menuEntriesAvailability != null && message.hasOwnProperty("menuEntriesAvailability")) {
                        if (!Array.isArray(message.menuEntriesAvailability))
                            return "menuEntriesAvailability: array expected";
                        for (var i = 0; i < message.menuEntriesAvailability.length; ++i) {
                            var error = $root.waiternow.common.MenuEntryAvailabilityProto.verify(message.menuEntriesAvailability[i]);
                            if (error)
                                return "menuEntriesAvailability." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetStructuredMenuAvailabilityActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetStructuredMenuAvailabilityActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetStructuredMenuAvailabilityActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetStructuredMenuAvailabilityActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.menuEntriesAvailability) {
                        if (!Array.isArray(object.menuEntriesAvailability))
                            throw TypeError(".waiternow.common.GetStructuredMenuAvailabilityActionProto.Response.menuEntriesAvailability: array expected");
                        message.menuEntriesAvailability = [];
                        for (var i = 0; i < object.menuEntriesAvailability.length; ++i) {
                            if (typeof object.menuEntriesAvailability[i] !== "object")
                                throw TypeError(".waiternow.common.GetStructuredMenuAvailabilityActionProto.Response.menuEntriesAvailability: object expected");
                            message.menuEntriesAvailability[i] = $root.waiternow.common.MenuEntryAvailabilityProto.fromObject(object.menuEntriesAvailability[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.GetStructuredMenuAvailabilityActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.menuEntriesAvailability = [];
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.menuEntriesAvailability && message.menuEntriesAvailability.length) {
                        object.menuEntriesAvailability = [];
                        for (var j = 0; j < message.menuEntriesAvailability.length; ++j)
                            object.menuEntriesAvailability[j] = $root.waiternow.common.MenuEntryAvailabilityProto.toObject(message.menuEntriesAvailability[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetStructuredMenuAvailabilityActionProto.Response";
                };

                return Response;
            })();

            return GetStructuredMenuAvailabilityActionProto;
        })();

        common.UpdateStructuredMenuAvailabilityActionProto = (function() {

            /**
             * Properties of an UpdateStructuredMenuAvailabilityActionProto.
             * @memberof waiternow.common
             * @interface IUpdateStructuredMenuAvailabilityActionProto
             */

            /**
             * Constructs a new UpdateStructuredMenuAvailabilityActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateStructuredMenuAvailabilityActionProto.
             * @implements IUpdateStructuredMenuAvailabilityActionProto
             * @constructor
             * @param {waiternow.common.IUpdateStructuredMenuAvailabilityActionProto=} [properties] Properties to set
             */
            function UpdateStructuredMenuAvailabilityActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateStructuredMenuAvailabilityActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.IUpdateStructuredMenuAvailabilityActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto} UpdateStructuredMenuAvailabilityActionProto instance
             */
            UpdateStructuredMenuAvailabilityActionProto.create = function create(properties) {
                return new UpdateStructuredMenuAvailabilityActionProto(properties);
            };

            /**
             * Encodes the specified UpdateStructuredMenuAvailabilityActionProto message. Does not implicitly {@link waiternow.common.UpdateStructuredMenuAvailabilityActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.IUpdateStructuredMenuAvailabilityActionProto} message UpdateStructuredMenuAvailabilityActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateStructuredMenuAvailabilityActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateStructuredMenuAvailabilityActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateStructuredMenuAvailabilityActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.IUpdateStructuredMenuAvailabilityActionProto} message UpdateStructuredMenuAvailabilityActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateStructuredMenuAvailabilityActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateStructuredMenuAvailabilityActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto} UpdateStructuredMenuAvailabilityActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateStructuredMenuAvailabilityActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateStructuredMenuAvailabilityActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto} UpdateStructuredMenuAvailabilityActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateStructuredMenuAvailabilityActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateStructuredMenuAvailabilityActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateStructuredMenuAvailabilityActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateStructuredMenuAvailabilityActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto} UpdateStructuredMenuAvailabilityActionProto
             */
            UpdateStructuredMenuAvailabilityActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto)
                    return object;
                return new $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto();
            };

            /**
             * Creates a plain object from an UpdateStructuredMenuAvailabilityActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto} message UpdateStructuredMenuAvailabilityActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateStructuredMenuAvailabilityActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateStructuredMenuAvailabilityActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateStructuredMenuAvailabilityActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateStructuredMenuAvailabilityActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateStructuredMenuAvailabilityActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateStructuredMenuAvailabilityActionProto";
            };

            UpdateStructuredMenuAvailabilityActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {Array.<waiternow.common.IMenuEntryAvailabilityProto>|null} [menuEntriesAvailability] Request menuEntriesAvailability
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.menuEntriesAvailability = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request menuEntriesAvailability.
                 * @member {Array.<waiternow.common.IMenuEntryAvailabilityProto>} menuEntriesAvailability
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @instance
                 */
                Request.prototype.menuEntriesAvailability = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.menuEntriesAvailability != null && message.menuEntriesAvailability.length)
                        for (var i = 0; i < message.menuEntriesAvailability.length; ++i)
                            $root.waiternow.common.MenuEntryAvailabilityProto.encode(message.menuEntriesAvailability[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                if (!(message.menuEntriesAvailability && message.menuEntriesAvailability.length))
                                    message.menuEntriesAvailability = [];
                                message.menuEntriesAvailability.push($root.waiternow.common.MenuEntryAvailabilityProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.menuEntriesAvailability != null && message.hasOwnProperty("menuEntriesAvailability")) {
                        if (!Array.isArray(message.menuEntriesAvailability))
                            return "menuEntriesAvailability: array expected";
                        for (var i = 0; i < message.menuEntriesAvailability.length; ++i) {
                            var error = $root.waiternow.common.MenuEntryAvailabilityProto.verify(message.menuEntriesAvailability[i]);
                            if (error)
                                return "menuEntriesAvailability." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.menuEntriesAvailability) {
                        if (!Array.isArray(object.menuEntriesAvailability))
                            throw TypeError(".waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request.menuEntriesAvailability: array expected");
                        message.menuEntriesAvailability = [];
                        for (var i = 0; i < object.menuEntriesAvailability.length; ++i) {
                            if (typeof object.menuEntriesAvailability[i] !== "object")
                                throw TypeError(".waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request.menuEntriesAvailability: object expected");
                            message.menuEntriesAvailability[i] = $root.waiternow.common.MenuEntryAvailabilityProto.fromObject(object.menuEntriesAvailability[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.menuEntriesAvailability = [];
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.menuEntriesAvailability && message.menuEntriesAvailability.length) {
                        object.menuEntriesAvailability = [];
                        for (var j = 0; j < message.menuEntriesAvailability.length; ++j)
                            object.menuEntriesAvailability[j] = $root.waiternow.common.MenuEntryAvailabilityProto.toObject(message.menuEntriesAvailability[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Request";
                };

                return Request;
            })();

            UpdateStructuredMenuAvailabilityActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateStructuredMenuAvailabilityActionProto.Response";
                };

                return Response;
            })();

            return UpdateStructuredMenuAvailabilityActionProto;
        })();

        common.DenormalizeStructuredMenuActionProto = (function() {

            /**
             * Properties of a DenormalizeStructuredMenuActionProto.
             * @memberof waiternow.common
             * @interface IDenormalizeStructuredMenuActionProto
             */

            /**
             * Constructs a new DenormalizeStructuredMenuActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a DenormalizeStructuredMenuActionProto.
             * @implements IDenormalizeStructuredMenuActionProto
             * @constructor
             * @param {waiternow.common.IDenormalizeStructuredMenuActionProto=} [properties] Properties to set
             */
            function DenormalizeStructuredMenuActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DenormalizeStructuredMenuActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IDenormalizeStructuredMenuActionProto=} [properties] Properties to set
             * @returns {waiternow.common.DenormalizeStructuredMenuActionProto} DenormalizeStructuredMenuActionProto instance
             */
            DenormalizeStructuredMenuActionProto.create = function create(properties) {
                return new DenormalizeStructuredMenuActionProto(properties);
            };

            /**
             * Encodes the specified DenormalizeStructuredMenuActionProto message. Does not implicitly {@link waiternow.common.DenormalizeStructuredMenuActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IDenormalizeStructuredMenuActionProto} message DenormalizeStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DenormalizeStructuredMenuActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DenormalizeStructuredMenuActionProto message, length delimited. Does not implicitly {@link waiternow.common.DenormalizeStructuredMenuActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {waiternow.common.IDenormalizeStructuredMenuActionProto} message DenormalizeStructuredMenuActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DenormalizeStructuredMenuActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DenormalizeStructuredMenuActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DenormalizeStructuredMenuActionProto} DenormalizeStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DenormalizeStructuredMenuActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DenormalizeStructuredMenuActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DenormalizeStructuredMenuActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DenormalizeStructuredMenuActionProto} DenormalizeStructuredMenuActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DenormalizeStructuredMenuActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DenormalizeStructuredMenuActionProto message.
             * @function verify
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DenormalizeStructuredMenuActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DenormalizeStructuredMenuActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DenormalizeStructuredMenuActionProto} DenormalizeStructuredMenuActionProto
             */
            DenormalizeStructuredMenuActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DenormalizeStructuredMenuActionProto)
                    return object;
                return new $root.waiternow.common.DenormalizeStructuredMenuActionProto();
            };

            /**
             * Creates a plain object from a DenormalizeStructuredMenuActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {waiternow.common.DenormalizeStructuredMenuActionProto} message DenormalizeStructuredMenuActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DenormalizeStructuredMenuActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DenormalizeStructuredMenuActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DenormalizeStructuredMenuActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DenormalizeStructuredMenuActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DenormalizeStructuredMenuActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DenormalizeStructuredMenuActionProto";
            };

            DenormalizeStructuredMenuActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IStructuredMenuSpecProto|null} [menu] Request menu
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request menu.
                 * @member {waiternow.common.IStructuredMenuSpecProto|null|undefined} menu
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @instance
                 */
                Request.prototype.menu = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.DenormalizeStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuSpecProto.encode(message.menu, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.DenormalizeStructuredMenuActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DenormalizeStructuredMenuActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.menu = $root.waiternow.common.StructuredMenuSpecProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuSpecProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DenormalizeStructuredMenuActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.DenormalizeStructuredMenuActionProto.Request();
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.DenormalizeStructuredMenuActionProto.Request.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuSpecProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.menu = null;
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuSpecProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DenormalizeStructuredMenuActionProto.Request";
                };

                return Request;
            })();

            DenormalizeStructuredMenuActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IStructuredMenuProto|null} [menu] Response menu
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response menu.
                 * @member {waiternow.common.IStructuredMenuProto|null|undefined} menu
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @instance
                 */
                Response.prototype.menu = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.DenormalizeStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.menu != null && Object.hasOwnProperty.call(message, "menu"))
                        $root.waiternow.common.StructuredMenuProto.encode(message.menu, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.DenormalizeStructuredMenuActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DenormalizeStructuredMenuActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.menu = $root.waiternow.common.StructuredMenuProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.menu != null && message.hasOwnProperty("menu")) {
                        var error = $root.waiternow.common.StructuredMenuProto.verify(message.menu);
                        if (error)
                            return "menu." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DenormalizeStructuredMenuActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DenormalizeStructuredMenuActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.DenormalizeStructuredMenuActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.DenormalizeStructuredMenuActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.menu != null) {
                        if (typeof object.menu !== "object")
                            throw TypeError(".waiternow.common.DenormalizeStructuredMenuActionProto.Response.menu: object expected");
                        message.menu = $root.waiternow.common.StructuredMenuProto.fromObject(object.menu);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {waiternow.common.DenormalizeStructuredMenuActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.menu = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.menu != null && message.hasOwnProperty("menu"))
                        object.menu = $root.waiternow.common.StructuredMenuProto.toObject(message.menu, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.DenormalizeStructuredMenuActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DenormalizeStructuredMenuActionProto.Response";
                };

                return Response;
            })();

            return DenormalizeStructuredMenuActionProto;
        })();

        common.CreatePointOfServiceActionProto = (function() {

            /**
             * Properties of a CreatePointOfServiceActionProto.
             * @memberof waiternow.common
             * @interface ICreatePointOfServiceActionProto
             */

            /**
             * Constructs a new CreatePointOfServiceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CreatePointOfServiceActionProto.
             * @implements ICreatePointOfServiceActionProto
             * @constructor
             * @param {waiternow.common.ICreatePointOfServiceActionProto=} [properties] Properties to set
             */
            function CreatePointOfServiceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CreatePointOfServiceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.ICreatePointOfServiceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CreatePointOfServiceActionProto} CreatePointOfServiceActionProto instance
             */
            CreatePointOfServiceActionProto.create = function create(properties) {
                return new CreatePointOfServiceActionProto(properties);
            };

            /**
             * Encodes the specified CreatePointOfServiceActionProto message. Does not implicitly {@link waiternow.common.CreatePointOfServiceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.ICreatePointOfServiceActionProto} message CreatePointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreatePointOfServiceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CreatePointOfServiceActionProto message, length delimited. Does not implicitly {@link waiternow.common.CreatePointOfServiceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.ICreatePointOfServiceActionProto} message CreatePointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreatePointOfServiceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreatePointOfServiceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CreatePointOfServiceActionProto} CreatePointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreatePointOfServiceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreatePointOfServiceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreatePointOfServiceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CreatePointOfServiceActionProto} CreatePointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreatePointOfServiceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreatePointOfServiceActionProto message.
             * @function verify
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreatePointOfServiceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CreatePointOfServiceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CreatePointOfServiceActionProto} CreatePointOfServiceActionProto
             */
            CreatePointOfServiceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CreatePointOfServiceActionProto)
                    return object;
                return new $root.waiternow.common.CreatePointOfServiceActionProto();
            };

            /**
             * Creates a plain object from a CreatePointOfServiceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.CreatePointOfServiceActionProto} message CreatePointOfServiceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreatePointOfServiceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreatePointOfServiceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreatePointOfServiceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreatePointOfServiceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CreatePointOfServiceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreatePointOfServiceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CreatePointOfServiceActionProto";
            };

            CreatePointOfServiceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CreatePointOfServiceActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IPointOfServiceProto|null} [pointOfService] Request pointOfService
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CreatePointOfServiceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfService.
                 * @member {waiternow.common.IPointOfServiceProto|null|undefined} pointOfService
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfService = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CreatePointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfService != null && Object.hasOwnProperty.call(message, "pointOfService"))
                        $root.waiternow.common.PointOfServiceProto.encode(message.pointOfService, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CreatePointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreatePointOfServiceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfService = $root.waiternow.common.PointOfServiceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService")) {
                        var error = $root.waiternow.common.PointOfServiceProto.verify(message.pointOfService);
                        if (error)
                            return "pointOfService." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreatePointOfServiceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CreatePointOfServiceActionProto.Request();
                    if (object.pointOfService != null) {
                        if (typeof object.pointOfService !== "object")
                            throw TypeError(".waiternow.common.CreatePointOfServiceActionProto.Request.pointOfService: object expected");
                        message.pointOfService = $root.waiternow.common.PointOfServiceProto.fromObject(object.pointOfService);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.pointOfService = null;
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService"))
                        object.pointOfService = $root.waiternow.common.PointOfServiceProto.toObject(message.pointOfService, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreatePointOfServiceActionProto.Request";
                };

                return Request;
            })();

            CreatePointOfServiceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CreatePointOfServiceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IPointOfServiceProto|null} [pointOfService] Response pointOfService
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CreatePointOfServiceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response pointOfService.
                 * @member {waiternow.common.IPointOfServiceProto|null|undefined} pointOfService
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.pointOfService = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CreatePointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.pointOfService != null && Object.hasOwnProperty.call(message, "pointOfService"))
                        $root.waiternow.common.PointOfServiceProto.encode(message.pointOfService, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CreatePointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreatePointOfServiceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.pointOfService = $root.waiternow.common.PointOfServiceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService")) {
                        var error = $root.waiternow.common.PointOfServiceProto.verify(message.pointOfService);
                        if (error)
                            return "pointOfService." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreatePointOfServiceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreatePointOfServiceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CreatePointOfServiceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CreatePointOfServiceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.pointOfService != null) {
                        if (typeof object.pointOfService !== "object")
                            throw TypeError(".waiternow.common.CreatePointOfServiceActionProto.Response.pointOfService: object expected");
                        message.pointOfService = $root.waiternow.common.PointOfServiceProto.fromObject(object.pointOfService);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointOfServiceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.pointOfService = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService"))
                        object.pointOfService = $root.waiternow.common.PointOfServiceProto.toObject(message.pointOfService, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreatePointOfServiceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreatePointOfServiceActionProto.Response";
                };

                return Response;
            })();

            return CreatePointOfServiceActionProto;
        })();

        common.CreatePointsOfServiceActionProto = (function() {

            /**
             * Properties of a CreatePointsOfServiceActionProto.
             * @memberof waiternow.common
             * @interface ICreatePointsOfServiceActionProto
             */

            /**
             * Constructs a new CreatePointsOfServiceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CreatePointsOfServiceActionProto.
             * @implements ICreatePointsOfServiceActionProto
             * @constructor
             * @param {waiternow.common.ICreatePointsOfServiceActionProto=} [properties] Properties to set
             */
            function CreatePointsOfServiceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CreatePointsOfServiceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {waiternow.common.ICreatePointsOfServiceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CreatePointsOfServiceActionProto} CreatePointsOfServiceActionProto instance
             */
            CreatePointsOfServiceActionProto.create = function create(properties) {
                return new CreatePointsOfServiceActionProto(properties);
            };

            /**
             * Encodes the specified CreatePointsOfServiceActionProto message. Does not implicitly {@link waiternow.common.CreatePointsOfServiceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {waiternow.common.ICreatePointsOfServiceActionProto} message CreatePointsOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreatePointsOfServiceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CreatePointsOfServiceActionProto message, length delimited. Does not implicitly {@link waiternow.common.CreatePointsOfServiceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {waiternow.common.ICreatePointsOfServiceActionProto} message CreatePointsOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreatePointsOfServiceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreatePointsOfServiceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CreatePointsOfServiceActionProto} CreatePointsOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreatePointsOfServiceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreatePointsOfServiceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreatePointsOfServiceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CreatePointsOfServiceActionProto} CreatePointsOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreatePointsOfServiceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreatePointsOfServiceActionProto message.
             * @function verify
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreatePointsOfServiceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CreatePointsOfServiceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CreatePointsOfServiceActionProto} CreatePointsOfServiceActionProto
             */
            CreatePointsOfServiceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CreatePointsOfServiceActionProto)
                    return object;
                return new $root.waiternow.common.CreatePointsOfServiceActionProto();
            };

            /**
             * Creates a plain object from a CreatePointsOfServiceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {waiternow.common.CreatePointsOfServiceActionProto} message CreatePointsOfServiceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreatePointsOfServiceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreatePointsOfServiceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreatePointsOfServiceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreatePointsOfServiceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CreatePointsOfServiceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreatePointsOfServiceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CreatePointsOfServiceActionProto";
            };

            CreatePointsOfServiceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [friendlyNamePrefix] Request friendlyNamePrefix
                 * @property {waiternow.common.IIntRangeProto|null} [friendlyNameSuffix] Request friendlyNameSuffix
                 * @property {waiternow.common.PointOfServiceProto.PointOfServiceType|null} [type] Request type
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request friendlyNamePrefix.
                 * @member {string} friendlyNamePrefix
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.friendlyNamePrefix = "";

                /**
                 * Request friendlyNameSuffix.
                 * @member {waiternow.common.IIntRangeProto|null|undefined} friendlyNameSuffix
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.friendlyNameSuffix = null;

                /**
                 * Request type.
                 * @member {waiternow.common.PointOfServiceProto.PointOfServiceType} type
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.type = 0;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CreatePointsOfServiceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.friendlyNamePrefix != null && Object.hasOwnProperty.call(message, "friendlyNamePrefix"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.friendlyNamePrefix);
                    if (message.friendlyNameSuffix != null && Object.hasOwnProperty.call(message, "friendlyNameSuffix"))
                        $root.waiternow.common.IntRangeProto.encode(message.friendlyNameSuffix, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.type != null && Object.hasOwnProperty.call(message, "type"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.type);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CreatePointsOfServiceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreatePointsOfServiceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.friendlyNamePrefix = reader.string();
                                break;
                            }
                        case 3: {
                                message.friendlyNameSuffix = $root.waiternow.common.IntRangeProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.type = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.friendlyNamePrefix != null && message.hasOwnProperty("friendlyNamePrefix"))
                        if (!$util.isString(message.friendlyNamePrefix))
                            return "friendlyNamePrefix: string expected";
                    if (message.friendlyNameSuffix != null && message.hasOwnProperty("friendlyNameSuffix")) {
                        var error = $root.waiternow.common.IntRangeProto.verify(message.friendlyNameSuffix);
                        if (error)
                            return "friendlyNameSuffix." + error;
                    }
                    if (message.type != null && message.hasOwnProperty("type"))
                        switch (message.type) {
                        default:
                            return "type: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                        case 6:
                        case 7:
                        case 8:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreatePointsOfServiceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CreatePointsOfServiceActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.friendlyNamePrefix != null)
                        message.friendlyNamePrefix = String(object.friendlyNamePrefix);
                    if (object.friendlyNameSuffix != null) {
                        if (typeof object.friendlyNameSuffix !== "object")
                            throw TypeError(".waiternow.common.CreatePointsOfServiceActionProto.Request.friendlyNameSuffix: object expected");
                        message.friendlyNameSuffix = $root.waiternow.common.IntRangeProto.fromObject(object.friendlyNameSuffix);
                    }
                    switch (object.type) {
                    default:
                        if (typeof object.type === "number") {
                            message.type = object.type;
                            break;
                        }
                        break;
                    case "POINT_OF_SERVICE_TYPE_UNSPECIFIED":
                    case 0:
                        message.type = 0;
                        break;
                    case "TABLE":
                    case 1:
                        message.type = 1;
                        break;
                    case "ROOM":
                    case 2:
                        message.type = 2;
                        break;
                    case "TERRACE":
                    case 3:
                        message.type = 3;
                        break;
                    case "POOL":
                    case 4:
                        message.type = 4;
                        break;
                    case "MENU":
                    case 5:
                        message.type = 5;
                        break;
                    case "ONLINE_PICKUP":
                    case 6:
                        message.type = 6;
                        break;
                    case "ONSITE_PICKUP":
                    case 7:
                        message.type = 7;
                        break;
                    case "ONLINE":
                    case 8:
                        message.type = 8;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.friendlyNamePrefix = "";
                        object.friendlyNameSuffix = null;
                        object.type = options.enums === String ? "POINT_OF_SERVICE_TYPE_UNSPECIFIED" : 0;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.friendlyNamePrefix != null && message.hasOwnProperty("friendlyNamePrefix"))
                        object.friendlyNamePrefix = message.friendlyNamePrefix;
                    if (message.friendlyNameSuffix != null && message.hasOwnProperty("friendlyNameSuffix"))
                        object.friendlyNameSuffix = $root.waiternow.common.IntRangeProto.toObject(message.friendlyNameSuffix, options);
                    if (message.type != null && message.hasOwnProperty("type"))
                        object.type = options.enums === String ? $root.waiternow.common.PointOfServiceProto.PointOfServiceType[message.type] === undefined ? message.type : $root.waiternow.common.PointOfServiceProto.PointOfServiceType[message.type] : message.type;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreatePointsOfServiceActionProto.Request";
                };

                return Request;
            })();

            CreatePointsOfServiceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {Array.<waiternow.common.IPointOfServiceProto>|null} [pointsOfService] Response pointsOfService
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    this.pointsOfService = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response pointsOfService.
                 * @member {Array.<waiternow.common.IPointOfServiceProto>} pointsOfService
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.pointsOfService = $util.emptyArray;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CreatePointsOfServiceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.pointsOfService != null && message.pointsOfService.length)
                        for (var i = 0; i < message.pointsOfService.length; ++i)
                            $root.waiternow.common.PointOfServiceProto.encode(message.pointsOfService[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CreatePointsOfServiceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreatePointsOfServiceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.pointsOfService && message.pointsOfService.length))
                                    message.pointsOfService = [];
                                message.pointsOfService.push($root.waiternow.common.PointOfServiceProto.decode(reader, reader.uint32()));
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.pointsOfService != null && message.hasOwnProperty("pointsOfService")) {
                        if (!Array.isArray(message.pointsOfService))
                            return "pointsOfService: array expected";
                        for (var i = 0; i < message.pointsOfService.length; ++i) {
                            var error = $root.waiternow.common.PointOfServiceProto.verify(message.pointsOfService[i]);
                            if (error)
                                return "pointsOfService." + error;
                        }
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreatePointsOfServiceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreatePointsOfServiceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CreatePointsOfServiceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CreatePointsOfServiceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.pointsOfService) {
                        if (!Array.isArray(object.pointsOfService))
                            throw TypeError(".waiternow.common.CreatePointsOfServiceActionProto.Response.pointsOfService: array expected");
                        message.pointsOfService = [];
                        for (var i = 0; i < object.pointsOfService.length; ++i) {
                            if (typeof object.pointsOfService[i] !== "object")
                                throw TypeError(".waiternow.common.CreatePointsOfServiceActionProto.Response.pointsOfService: object expected");
                            message.pointsOfService[i] = $root.waiternow.common.PointOfServiceProto.fromObject(object.pointsOfService[i]);
                        }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.CreatePointsOfServiceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.pointsOfService = [];
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.pointsOfService && message.pointsOfService.length) {
                        object.pointsOfService = [];
                        for (var j = 0; j < message.pointsOfService.length; ++j)
                            object.pointsOfService[j] = $root.waiternow.common.PointOfServiceProto.toObject(message.pointsOfService[j], options);
                    }
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreatePointsOfServiceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreatePointsOfServiceActionProto.Response";
                };

                return Response;
            })();

            return CreatePointsOfServiceActionProto;
        })();

        common.UpdatePointOfServiceActionProto = (function() {

            /**
             * Properties of an UpdatePointOfServiceActionProto.
             * @memberof waiternow.common
             * @interface IUpdatePointOfServiceActionProto
             */

            /**
             * Constructs a new UpdatePointOfServiceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdatePointOfServiceActionProto.
             * @implements IUpdatePointOfServiceActionProto
             * @constructor
             * @param {waiternow.common.IUpdatePointOfServiceActionProto=} [properties] Properties to set
             */
            function UpdatePointOfServiceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdatePointOfServiceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.IUpdatePointOfServiceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdatePointOfServiceActionProto} UpdatePointOfServiceActionProto instance
             */
            UpdatePointOfServiceActionProto.create = function create(properties) {
                return new UpdatePointOfServiceActionProto(properties);
            };

            /**
             * Encodes the specified UpdatePointOfServiceActionProto message. Does not implicitly {@link waiternow.common.UpdatePointOfServiceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.IUpdatePointOfServiceActionProto} message UpdatePointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePointOfServiceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdatePointOfServiceActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdatePointOfServiceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.IUpdatePointOfServiceActionProto} message UpdatePointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePointOfServiceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdatePointOfServiceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdatePointOfServiceActionProto} UpdatePointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePointOfServiceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdatePointOfServiceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdatePointOfServiceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdatePointOfServiceActionProto} UpdatePointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePointOfServiceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdatePointOfServiceActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdatePointOfServiceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdatePointOfServiceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdatePointOfServiceActionProto} UpdatePointOfServiceActionProto
             */
            UpdatePointOfServiceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdatePointOfServiceActionProto)
                    return object;
                return new $root.waiternow.common.UpdatePointOfServiceActionProto();
            };

            /**
             * Creates a plain object from an UpdatePointOfServiceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {waiternow.common.UpdatePointOfServiceActionProto} message UpdatePointOfServiceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdatePointOfServiceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdatePointOfServiceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdatePointOfServiceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdatePointOfServiceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdatePointOfServiceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdatePointOfServiceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdatePointOfServiceActionProto";
            };

            UpdatePointOfServiceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IPointOfServiceProto|null} [pointOfService] Request pointOfService
                 * @property {Array.<waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfService.
                 * @member {waiternow.common.IPointOfServiceProto|null|undefined} pointOfService
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfService = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdatePointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfService != null && Object.hasOwnProperty.call(message, "pointOfService"))
                        $root.waiternow.common.PointOfServiceProto.encode(message.pointOfService, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdatePointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdatePointOfServiceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfService = $root.waiternow.common.PointOfServiceProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService")) {
                        var error = $root.waiternow.common.PointOfServiceProto.verify(message.pointOfService);
                        if (error)
                            return "pointOfService." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdatePointOfServiceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdatePointOfServiceActionProto.Request();
                    if (object.pointOfService != null) {
                        if (typeof object.pointOfService !== "object")
                            throw TypeError(".waiternow.common.UpdatePointOfServiceActionProto.Request.pointOfService: object expected");
                        message.pointOfService = $root.waiternow.common.PointOfServiceProto.fromObject(object.pointOfService);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdatePointOfServiceActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            case "TYPE":
                            case 1:
                                message.fieldsToRemove[i] = 1;
                                break;
                            case "VANITY_URL":
                            case 2:
                                message.fieldsToRemove[i] = 2;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldsToRemove = [];
                    if (options.defaults)
                        object.pointOfService = null;
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService"))
                        object.pointOfService = $root.waiternow.common.PointOfServiceProto.toObject(message.pointOfService, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdatePointOfServiceActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdatePointOfServiceActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 * @property {number} TYPE=1 TYPE value
                 * @property {number} VANITY_URL=2 VANITY_URL value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "TYPE"] = 1;
                    values[valuesById[2] = "VANITY_URL"] = 2;
                    return values;
                })();

                return Request;
            })();

            UpdatePointOfServiceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdatePointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdatePointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdatePointOfServiceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdatePointOfServiceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdatePointOfServiceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdatePointOfServiceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdatePointOfServiceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePointOfServiceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdatePointOfServiceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdatePointOfServiceActionProto.Response";
                };

                return Response;
            })();

            return UpdatePointOfServiceActionProto;
        })();

        common.DeletePointOfServiceActionProto = (function() {

            /**
             * Properties of a DeletePointOfServiceActionProto.
             * @memberof waiternow.common
             * @interface IDeletePointOfServiceActionProto
             */

            /**
             * Constructs a new DeletePointOfServiceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeletePointOfServiceActionProto.
             * @implements IDeletePointOfServiceActionProto
             * @constructor
             * @param {waiternow.common.IDeletePointOfServiceActionProto=} [properties] Properties to set
             */
            function DeletePointOfServiceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DeletePointOfServiceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {waiternow.common.IDeletePointOfServiceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.DeletePointOfServiceActionProto} DeletePointOfServiceActionProto instance
             */
            DeletePointOfServiceActionProto.create = function create(properties) {
                return new DeletePointOfServiceActionProto(properties);
            };

            /**
             * Encodes the specified DeletePointOfServiceActionProto message. Does not implicitly {@link waiternow.common.DeletePointOfServiceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {waiternow.common.IDeletePointOfServiceActionProto} message DeletePointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePointOfServiceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DeletePointOfServiceActionProto message, length delimited. Does not implicitly {@link waiternow.common.DeletePointOfServiceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {waiternow.common.IDeletePointOfServiceActionProto} message DeletePointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeletePointOfServiceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeletePointOfServiceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeletePointOfServiceActionProto} DeletePointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePointOfServiceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeletePointOfServiceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeletePointOfServiceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeletePointOfServiceActionProto} DeletePointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeletePointOfServiceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeletePointOfServiceActionProto message.
             * @function verify
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeletePointOfServiceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DeletePointOfServiceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeletePointOfServiceActionProto} DeletePointOfServiceActionProto
             */
            DeletePointOfServiceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeletePointOfServiceActionProto)
                    return object;
                return new $root.waiternow.common.DeletePointOfServiceActionProto();
            };

            /**
             * Creates a plain object from a DeletePointOfServiceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {waiternow.common.DeletePointOfServiceActionProto} message DeletePointOfServiceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeletePointOfServiceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeletePointOfServiceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeletePointOfServiceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeletePointOfServiceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeletePointOfServiceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeletePointOfServiceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeletePointOfServiceActionProto";
            };

            DeletePointOfServiceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.DeletePointOfServiceActionProto
                 * @interface IRequest
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.DeletePointOfServiceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.DeletePointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.DeletePointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeletePointOfServiceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeletePointOfServiceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.DeletePointOfServiceActionProto.Request();
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.pointOfServiceId = "";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeletePointOfServiceActionProto.Request";
                };

                return Request;
            })();

            DeletePointOfServiceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.DeletePointOfServiceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.DeletePointOfServiceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.DeletePointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.DeletePointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeletePointOfServiceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeletePointOfServiceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeletePointOfServiceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.DeletePointOfServiceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.DeletePointOfServiceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.DeletePointOfServiceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeletePointOfServiceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeletePointOfServiceActionProto.Response";
                };

                return Response;
            })();

            return DeletePointOfServiceActionProto;
        })();

        common.GetPointOfServiceActionProto = (function() {

            /**
             * Properties of a GetPointOfServiceActionProto.
             * @memberof waiternow.common
             * @interface IGetPointOfServiceActionProto
             */

            /**
             * Constructs a new GetPointOfServiceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetPointOfServiceActionProto.
             * @implements IGetPointOfServiceActionProto
             * @constructor
             * @param {waiternow.common.IGetPointOfServiceActionProto=} [properties] Properties to set
             */
            function GetPointOfServiceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetPointOfServiceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {waiternow.common.IGetPointOfServiceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetPointOfServiceActionProto} GetPointOfServiceActionProto instance
             */
            GetPointOfServiceActionProto.create = function create(properties) {
                return new GetPointOfServiceActionProto(properties);
            };

            /**
             * Encodes the specified GetPointOfServiceActionProto message. Does not implicitly {@link waiternow.common.GetPointOfServiceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {waiternow.common.IGetPointOfServiceActionProto} message GetPointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPointOfServiceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetPointOfServiceActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetPointOfServiceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {waiternow.common.IGetPointOfServiceActionProto} message GetPointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetPointOfServiceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetPointOfServiceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetPointOfServiceActionProto} GetPointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPointOfServiceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetPointOfServiceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetPointOfServiceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetPointOfServiceActionProto} GetPointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetPointOfServiceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetPointOfServiceActionProto message.
             * @function verify
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetPointOfServiceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetPointOfServiceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetPointOfServiceActionProto} GetPointOfServiceActionProto
             */
            GetPointOfServiceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetPointOfServiceActionProto)
                    return object;
                return new $root.waiternow.common.GetPointOfServiceActionProto();
            };

            /**
             * Creates a plain object from a GetPointOfServiceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {waiternow.common.GetPointOfServiceActionProto} message GetPointOfServiceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetPointOfServiceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetPointOfServiceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetPointOfServiceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetPointOfServiceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetPointOfServiceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetPointOfServiceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetPointOfServiceActionProto";
            };

            GetPointOfServiceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetPointOfServiceActionProto
                 * @interface IRequest
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetPointOfServiceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetPointOfServiceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetPointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetPointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetPointOfServiceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetPointOfServiceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetPointOfServiceActionProto.Request();
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.pointOfServiceId = "";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetPointOfServiceActionProto.Request";
                };

                return Request;
            })();

            GetPointOfServiceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetPointOfServiceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IPointOfServiceProto|null} [pointOfService] Response pointOfService
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetPointOfServiceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetPointOfServiceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response pointOfService.
                 * @member {waiternow.common.IPointOfServiceProto|null|undefined} pointOfService
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.pointOfService = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetPointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.pointOfService != null && Object.hasOwnProperty.call(message, "pointOfService"))
                        $root.waiternow.common.PointOfServiceProto.encode(message.pointOfService, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetPointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetPointOfServiceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.pointOfService = $root.waiternow.common.PointOfServiceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService")) {
                        var error = $root.waiternow.common.PointOfServiceProto.verify(message.pointOfService);
                        if (error)
                            return "pointOfService." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetPointOfServiceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetPointOfServiceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetPointOfServiceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetPointOfServiceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.pointOfService != null) {
                        if (typeof object.pointOfService !== "object")
                            throw TypeError(".waiternow.common.GetPointOfServiceActionProto.Response.pointOfService: object expected");
                        message.pointOfService = $root.waiternow.common.PointOfServiceProto.fromObject(object.pointOfService);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.GetPointOfServiceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.pointOfService = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.pointOfService != null && message.hasOwnProperty("pointOfService"))
                        object.pointOfService = $root.waiternow.common.PointOfServiceProto.toObject(message.pointOfService, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetPointOfServiceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetPointOfServiceActionProto.Response";
                };

                return Response;
            })();

            return GetPointOfServiceActionProto;
        })();

        common.FindPointsOfServiceByLocationActionProto = (function() {

            /**
             * Properties of a FindPointsOfServiceByLocationActionProto.
             * @memberof waiternow.common
             * @interface IFindPointsOfServiceByLocationActionProto
             */

            /**
             * Constructs a new FindPointsOfServiceByLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindPointsOfServiceByLocationActionProto.
             * @implements IFindPointsOfServiceByLocationActionProto
             * @constructor
             * @param {waiternow.common.IFindPointsOfServiceByLocationActionProto=} [properties] Properties to set
             */
            function FindPointsOfServiceByLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindPointsOfServiceByLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {waiternow.common.IFindPointsOfServiceByLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto} FindPointsOfServiceByLocationActionProto instance
             */
            FindPointsOfServiceByLocationActionProto.create = function create(properties) {
                return new FindPointsOfServiceByLocationActionProto(properties);
            };

            /**
             * Encodes the specified FindPointsOfServiceByLocationActionProto message. Does not implicitly {@link waiternow.common.FindPointsOfServiceByLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {waiternow.common.IFindPointsOfServiceByLocationActionProto} message FindPointsOfServiceByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPointsOfServiceByLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindPointsOfServiceByLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindPointsOfServiceByLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {waiternow.common.IFindPointsOfServiceByLocationActionProto} message FindPointsOfServiceByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPointsOfServiceByLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindPointsOfServiceByLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto} FindPointsOfServiceByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPointsOfServiceByLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPointsOfServiceByLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindPointsOfServiceByLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto} FindPointsOfServiceByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPointsOfServiceByLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindPointsOfServiceByLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindPointsOfServiceByLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindPointsOfServiceByLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto} FindPointsOfServiceByLocationActionProto
             */
            FindPointsOfServiceByLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindPointsOfServiceByLocationActionProto)
                    return object;
                return new $root.waiternow.common.FindPointsOfServiceByLocationActionProto();
            };

            /**
             * Creates a plain object from a FindPointsOfServiceByLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {waiternow.common.FindPointsOfServiceByLocationActionProto} message FindPointsOfServiceByLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindPointsOfServiceByLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindPointsOfServiceByLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindPointsOfServiceByLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindPointsOfServiceByLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindPointsOfServiceByLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindPointsOfServiceByLocationActionProto";
            };

            FindPointsOfServiceByLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindPointsOfServiceByLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindPointsOfServiceByLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPointsOfServiceByLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPointsOfServiceByLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindPointsOfServiceByLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPointsOfServiceByLocationActionProto.Request";
                };

                return Request;
            })();

            FindPointsOfServiceByLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationPointsOfServiceProto|null} [locationPointsOfService] Response locationPointsOfService
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationPointsOfService.
                 * @member {waiternow.common.ILocationPointsOfServiceProto|null|undefined} locationPointsOfService
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.locationPointsOfService = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindPointsOfServiceByLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationPointsOfService != null && Object.hasOwnProperty.call(message, "locationPointsOfService"))
                        $root.waiternow.common.LocationPointsOfServiceProto.encode(message.locationPointsOfService, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindPointsOfServiceByLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPointsOfServiceByLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationPointsOfService = $root.waiternow.common.LocationPointsOfServiceProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationPointsOfService != null && message.hasOwnProperty("locationPointsOfService")) {
                        var error = $root.waiternow.common.LocationPointsOfServiceProto.verify(message.locationPointsOfService);
                        if (error)
                            return "locationPointsOfService." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPointsOfServiceByLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPointsOfServiceByLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindPointsOfServiceByLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindPointsOfServiceByLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationPointsOfService != null) {
                        if (typeof object.locationPointsOfService !== "object")
                            throw TypeError(".waiternow.common.FindPointsOfServiceByLocationActionProto.Response.locationPointsOfService: object expected");
                        message.locationPointsOfService = $root.waiternow.common.LocationPointsOfServiceProto.fromObject(object.locationPointsOfService);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPointsOfServiceByLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationPointsOfService = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationPointsOfService != null && message.hasOwnProperty("locationPointsOfService"))
                        object.locationPointsOfService = $root.waiternow.common.LocationPointsOfServiceProto.toObject(message.locationPointsOfService, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPointsOfServiceByLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPointsOfServiceByLocationActionProto.Response";
                };

                return Response;
            })();

            return FindPointsOfServiceByLocationActionProto;
        })();

        common.GetQrCodesActionProto = (function() {

            /**
             * Properties of a GetQrCodesActionProto.
             * @memberof waiternow.common
             * @interface IGetQrCodesActionProto
             */

            /**
             * Constructs a new GetQrCodesActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetQrCodesActionProto.
             * @implements IGetQrCodesActionProto
             * @constructor
             * @param {waiternow.common.IGetQrCodesActionProto=} [properties] Properties to set
             */
            function GetQrCodesActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetQrCodesActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {waiternow.common.IGetQrCodesActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetQrCodesActionProto} GetQrCodesActionProto instance
             */
            GetQrCodesActionProto.create = function create(properties) {
                return new GetQrCodesActionProto(properties);
            };

            /**
             * Encodes the specified GetQrCodesActionProto message. Does not implicitly {@link waiternow.common.GetQrCodesActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {waiternow.common.IGetQrCodesActionProto} message GetQrCodesActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetQrCodesActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetQrCodesActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetQrCodesActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {waiternow.common.IGetQrCodesActionProto} message GetQrCodesActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetQrCodesActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetQrCodesActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetQrCodesActionProto} GetQrCodesActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetQrCodesActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetQrCodesActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetQrCodesActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetQrCodesActionProto} GetQrCodesActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetQrCodesActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetQrCodesActionProto message.
             * @function verify
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetQrCodesActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetQrCodesActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetQrCodesActionProto} GetQrCodesActionProto
             */
            GetQrCodesActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetQrCodesActionProto)
                    return object;
                return new $root.waiternow.common.GetQrCodesActionProto();
            };

            /**
             * Creates a plain object from a GetQrCodesActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {waiternow.common.GetQrCodesActionProto} message GetQrCodesActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetQrCodesActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetQrCodesActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetQrCodesActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetQrCodesActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetQrCodesActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetQrCodesActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetQrCodesActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetQrCodesActionProto";
            };

            GetQrCodesActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetQrCodesActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 * @property {waiternow.common.QrCodeSize|null} [qrCodeSize] Request qrCodeSize
                 * @property {waiternow.common.Language|null} [language] Request language
                 * @property {waiternow.common.Theme|null} [theme] Request theme
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetQrCodesActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetQrCodesActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request pointOfServiceId.
                 * @member {string|null|undefined} pointOfServiceId
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = null;

                /**
                 * Request qrCodeSize.
                 * @member {waiternow.common.QrCodeSize} qrCodeSize
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @instance
                 */
                Request.prototype.qrCodeSize = 0;

                /**
                 * Request language.
                 * @member {waiternow.common.Language} language
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @instance
                 */
                Request.prototype.language = 0;

                /**
                 * Request theme.
                 * @member {waiternow.common.Theme} theme
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @instance
                 */
                Request.prototype.theme = 0;

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request subject.
                 * @member {"locationId"|"pointOfServiceId"|undefined} subject
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "subject", {
                    get: $util.oneOfGetter($oneOfFields = ["locationId", "pointOfServiceId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetQrCodesActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetQrCodesActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.pointOfServiceId);
                    if (message.qrCodeSize != null && Object.hasOwnProperty.call(message, "qrCodeSize"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.qrCodeSize);
                    if (message.language != null && Object.hasOwnProperty.call(message, "language"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.language);
                    if (message.theme != null && Object.hasOwnProperty.call(message, "theme"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.theme);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetQrCodesActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetQrCodesActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetQrCodesActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        case 3: {
                                message.qrCodeSize = reader.int32();
                                break;
                            }
                        case 4: {
                                message.language = reader.int32();
                                break;
                            }
                        case 5: {
                                message.theme = reader.int32();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetQrCodesActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        properties.subject = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId")) {
                        if (properties.subject === 1)
                            return "subject: multiple values";
                        properties.subject = 1;
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    }
                    if (message.qrCodeSize != null && message.hasOwnProperty("qrCodeSize"))
                        switch (message.qrCodeSize) {
                        default:
                            return "qrCodeSize: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            break;
                        }
                    if (message.language != null && message.hasOwnProperty("language"))
                        switch (message.language) {
                        default:
                            return "language: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.theme != null && message.hasOwnProperty("theme"))
                        switch (message.theme) {
                        default:
                            return "theme: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetQrCodesActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetQrCodesActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetQrCodesActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    switch (object.qrCodeSize) {
                    default:
                        if (typeof object.qrCodeSize === "number") {
                            message.qrCodeSize = object.qrCodeSize;
                            break;
                        }
                        break;
                    case "QR_CODE_SIZE_UNSPECIFIED":
                    case 0:
                        message.qrCodeSize = 0;
                        break;
                    case "SMALL":
                    case 1:
                        message.qrCodeSize = 1;
                        break;
                    case "SMALL_RAW":
                    case 2:
                        message.qrCodeSize = 2;
                        break;
                    case "SIZE_4_BY_3_INCH":
                    case 3:
                        message.qrCodeSize = 3;
                        break;
                    case "SIZE_4_BY_4_INCH":
                    case 4:
                        message.qrCodeSize = 4;
                        break;
                    case "SIZE_4_BY_6_INCH":
                    case 5:
                        message.qrCodeSize = 5;
                        break;
                    }
                    switch (object.language) {
                    default:
                        if (typeof object.language === "number") {
                            message.language = object.language;
                            break;
                        }
                        break;
                    case "LANGUAGE_UNSPECIFIED":
                    case 0:
                        message.language = 0;
                        break;
                    case "ENGLISH":
                    case 1:
                        message.language = 1;
                        break;
                    case "SPANISH":
                    case 2:
                        message.language = 2;
                        break;
                    }
                    switch (object.theme) {
                    default:
                        if (typeof object.theme === "number") {
                            message.theme = object.theme;
                            break;
                        }
                        break;
                    case "THEME_UNSPECIFIED":
                    case 0:
                        message.theme = 0;
                        break;
                    case "DARK":
                    case 1:
                        message.theme = 1;
                        break;
                    case "LIGHT":
                    case 2:
                        message.theme = 2;
                        break;
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.qrCodeSize = options.enums === String ? "QR_CODE_SIZE_UNSPECIFIED" : 0;
                        object.language = options.enums === String ? "LANGUAGE_UNSPECIFIED" : 0;
                        object.theme = options.enums === String ? "THEME_UNSPECIFIED" : 0;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.subject = "locationId";
                    }
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId")) {
                        object.pointOfServiceId = message.pointOfServiceId;
                        if (options.oneofs)
                            object.subject = "pointOfServiceId";
                    }
                    if (message.qrCodeSize != null && message.hasOwnProperty("qrCodeSize"))
                        object.qrCodeSize = options.enums === String ? $root.waiternow.common.QrCodeSize[message.qrCodeSize] === undefined ? message.qrCodeSize : $root.waiternow.common.QrCodeSize[message.qrCodeSize] : message.qrCodeSize;
                    if (message.language != null && message.hasOwnProperty("language"))
                        object.language = options.enums === String ? $root.waiternow.common.Language[message.language] === undefined ? message.language : $root.waiternow.common.Language[message.language] : message.language;
                    if (message.theme != null && message.hasOwnProperty("theme"))
                        object.theme = options.enums === String ? $root.waiternow.common.Theme[message.theme] === undefined ? message.theme : $root.waiternow.common.Theme[message.theme] : message.theme;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetQrCodesActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetQrCodesActionProto.Request";
                };

                return Request;
            })();

            GetQrCodesActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetQrCodesActionProto
                 * @interface IResponse
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetQrCodesActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetQrCodesActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetQrCodesActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetQrCodesActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetQrCodesActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetQrCodesActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetQrCodesActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetQrCodesActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetQrCodesActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetQrCodesActionProto.Response)
                        return object;
                    return new $root.waiternow.common.GetQrCodesActionProto.Response();
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {waiternow.common.GetQrCodesActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject() {
                    return {};
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetQrCodesActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetQrCodesActionProto.Response";
                };

                return Response;
            })();

            return GetQrCodesActionProto;
        })();

        common.GetOrderActionProto = (function() {

            /**
             * Properties of a GetOrderActionProto.
             * @memberof waiternow.common
             * @interface IGetOrderActionProto
             */

            /**
             * Constructs a new GetOrderActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetOrderActionProto.
             * @implements IGetOrderActionProto
             * @constructor
             * @param {waiternow.common.IGetOrderActionProto=} [properties] Properties to set
             */
            function GetOrderActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetOrderActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {waiternow.common.IGetOrderActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetOrderActionProto} GetOrderActionProto instance
             */
            GetOrderActionProto.create = function create(properties) {
                return new GetOrderActionProto(properties);
            };

            /**
             * Encodes the specified GetOrderActionProto message. Does not implicitly {@link waiternow.common.GetOrderActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {waiternow.common.IGetOrderActionProto} message GetOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetOrderActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetOrderActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {waiternow.common.IGetOrderActionProto} message GetOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetOrderActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetOrderActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetOrderActionProto} GetOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetOrderActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetOrderActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetOrderActionProto} GetOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetOrderActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetOrderActionProto message.
             * @function verify
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetOrderActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetOrderActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetOrderActionProto} GetOrderActionProto
             */
            GetOrderActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetOrderActionProto)
                    return object;
                return new $root.waiternow.common.GetOrderActionProto();
            };

            /**
             * Creates a plain object from a GetOrderActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {waiternow.common.GetOrderActionProto} message GetOrderActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetOrderActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetOrderActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetOrderActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetOrderActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetOrderActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetOrderActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetOrderActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetOrderActionProto";
            };

            GetOrderActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetOrderActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetOrderActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetOrderActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetOrderActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetOrderActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetOrderActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetOrderActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetOrderActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetOrderActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetOrderActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.orderId = "";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetOrderActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetOrderActionProto.Request";
                };

                return Request;
            })();

            GetOrderActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetOrderActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IOrderProto|null} [order] Response order
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetOrderActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetOrderActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response order.
                 * @member {waiternow.common.IOrderProto|null|undefined} order
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @instance
                 */
                Response.prototype.order = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetOrderActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetOrderActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.order != null && Object.hasOwnProperty.call(message, "order"))
                        $root.waiternow.common.OrderProto.encode(message.order, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetOrderActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetOrderActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.order = $root.waiternow.common.OrderProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.order != null && message.hasOwnProperty("order")) {
                        var error = $root.waiternow.common.OrderProto.verify(message.order);
                        if (error)
                            return "order." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetOrderActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetOrderActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetOrderActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetOrderActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.order != null) {
                        if (typeof object.order !== "object")
                            throw TypeError(".waiternow.common.GetOrderActionProto.Response.order: object expected");
                        message.order = $root.waiternow.common.OrderProto.fromObject(object.order);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.GetOrderActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.order = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.order != null && message.hasOwnProperty("order"))
                        object.order = $root.waiternow.common.OrderProto.toObject(message.order, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetOrderActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetOrderActionProto.Response";
                };

                return Response;
            })();

            return GetOrderActionProto;
        })();

        common.AckOrderActionProto = (function() {

            /**
             * Properties of an AckOrderActionProto.
             * @memberof waiternow.common
             * @interface IAckOrderActionProto
             */

            /**
             * Constructs a new AckOrderActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AckOrderActionProto.
             * @implements IAckOrderActionProto
             * @constructor
             * @param {waiternow.common.IAckOrderActionProto=} [properties] Properties to set
             */
            function AckOrderActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AckOrderActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {waiternow.common.IAckOrderActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AckOrderActionProto} AckOrderActionProto instance
             */
            AckOrderActionProto.create = function create(properties) {
                return new AckOrderActionProto(properties);
            };

            /**
             * Encodes the specified AckOrderActionProto message. Does not implicitly {@link waiternow.common.AckOrderActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {waiternow.common.IAckOrderActionProto} message AckOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AckOrderActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AckOrderActionProto message, length delimited. Does not implicitly {@link waiternow.common.AckOrderActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {waiternow.common.IAckOrderActionProto} message AckOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AckOrderActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AckOrderActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AckOrderActionProto} AckOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AckOrderActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AckOrderActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AckOrderActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AckOrderActionProto} AckOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AckOrderActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AckOrderActionProto message.
             * @function verify
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AckOrderActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AckOrderActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AckOrderActionProto} AckOrderActionProto
             */
            AckOrderActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AckOrderActionProto)
                    return object;
                return new $root.waiternow.common.AckOrderActionProto();
            };

            /**
             * Creates a plain object from an AckOrderActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {waiternow.common.AckOrderActionProto} message AckOrderActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AckOrderActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AckOrderActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AckOrderActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AckOrderActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AckOrderActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AckOrderActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AckOrderActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AckOrderActionProto";
            };

            AckOrderActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AckOrderActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AckOrderActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AckOrderActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AckOrderActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AckOrderActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AckOrderActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AckOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AckOrderActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AckOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AckOrderActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AckOrderActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.AckOrderActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.orderId = "";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AckOrderActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AckOrderActionProto.Request";
                };

                return Request;
            })();

            AckOrderActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AckOrderActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AckOrderActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AckOrderActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AckOrderActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AckOrderActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AckOrderActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AckOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AckOrderActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AckOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AckOrderActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AckOrderActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AckOrderActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AckOrderActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.AckOrderActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AckOrderActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AckOrderActionProto.Response";
                };

                return Response;
            })();

            return AckOrderActionProto;
        })();

        common.MarkOrderAsPrintedActionProto = (function() {

            /**
             * Properties of a MarkOrderAsPrintedActionProto.
             * @memberof waiternow.common
             * @interface IMarkOrderAsPrintedActionProto
             */

            /**
             * Constructs a new MarkOrderAsPrintedActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a MarkOrderAsPrintedActionProto.
             * @implements IMarkOrderAsPrintedActionProto
             * @constructor
             * @param {waiternow.common.IMarkOrderAsPrintedActionProto=} [properties] Properties to set
             */
            function MarkOrderAsPrintedActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new MarkOrderAsPrintedActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsPrintedActionProto=} [properties] Properties to set
             * @returns {waiternow.common.MarkOrderAsPrintedActionProto} MarkOrderAsPrintedActionProto instance
             */
            MarkOrderAsPrintedActionProto.create = function create(properties) {
                return new MarkOrderAsPrintedActionProto(properties);
            };

            /**
             * Encodes the specified MarkOrderAsPrintedActionProto message. Does not implicitly {@link waiternow.common.MarkOrderAsPrintedActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsPrintedActionProto} message MarkOrderAsPrintedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsPrintedActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified MarkOrderAsPrintedActionProto message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsPrintedActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsPrintedActionProto} message MarkOrderAsPrintedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsPrintedActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MarkOrderAsPrintedActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MarkOrderAsPrintedActionProto} MarkOrderAsPrintedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsPrintedActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsPrintedActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MarkOrderAsPrintedActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MarkOrderAsPrintedActionProto} MarkOrderAsPrintedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsPrintedActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MarkOrderAsPrintedActionProto message.
             * @function verify
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkOrderAsPrintedActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a MarkOrderAsPrintedActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MarkOrderAsPrintedActionProto} MarkOrderAsPrintedActionProto
             */
            MarkOrderAsPrintedActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MarkOrderAsPrintedActionProto)
                    return object;
                return new $root.waiternow.common.MarkOrderAsPrintedActionProto();
            };

            /**
             * Creates a plain object from a MarkOrderAsPrintedActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {waiternow.common.MarkOrderAsPrintedActionProto} message MarkOrderAsPrintedActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkOrderAsPrintedActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this MarkOrderAsPrintedActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkOrderAsPrintedActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MarkOrderAsPrintedActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.MarkOrderAsPrintedActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarkOrderAsPrintedActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MarkOrderAsPrintedActionProto";
            };

            MarkOrderAsPrintedActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 * @property {google.protobuf.ITimestamp|null} [printedAt] Request printedAt
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Request printedAt.
                 * @member {google.protobuf.ITimestamp|null|undefined} printedAt
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @instance
                 */
                Request.prototype.printedAt = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.MarkOrderAsPrintedActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    if (message.printedAt != null && Object.hasOwnProperty.call(message, "printedAt"))
                        $root.google.protobuf.Timestamp.encode(message.printedAt, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsPrintedActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsPrintedActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        case 2: {
                                message.printedAt = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    if (message.printedAt != null && message.hasOwnProperty("printedAt")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.printedAt);
                        if (error)
                            return "printedAt." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsPrintedActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsPrintedActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    if (object.printedAt != null) {
                        if (typeof object.printedAt !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsPrintedActionProto.Request.printedAt: object expected");
                        message.printedAt = $root.google.protobuf.Timestamp.fromObject(object.printedAt);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orderId = "";
                        object.printedAt = null;
                    }
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    if (message.printedAt != null && message.hasOwnProperty("printedAt"))
                        object.printedAt = $root.google.protobuf.Timestamp.toObject(message.printedAt, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsPrintedActionProto.Request";
                };

                return Request;
            })();

            MarkOrderAsPrintedActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.MarkOrderAsPrintedActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsPrintedActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsPrintedActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsPrintedActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsPrintedActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsPrintedActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsPrintedActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsPrintedActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsPrintedActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsPrintedActionProto.Response";
                };

                return Response;
            })();

            return MarkOrderAsPrintedActionProto;
        })();

        common.MarkOrderAsCompletedActionProto = (function() {

            /**
             * Properties of a MarkOrderAsCompletedActionProto.
             * @memberof waiternow.common
             * @interface IMarkOrderAsCompletedActionProto
             */

            /**
             * Constructs a new MarkOrderAsCompletedActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a MarkOrderAsCompletedActionProto.
             * @implements IMarkOrderAsCompletedActionProto
             * @constructor
             * @param {waiternow.common.IMarkOrderAsCompletedActionProto=} [properties] Properties to set
             */
            function MarkOrderAsCompletedActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new MarkOrderAsCompletedActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsCompletedActionProto=} [properties] Properties to set
             * @returns {waiternow.common.MarkOrderAsCompletedActionProto} MarkOrderAsCompletedActionProto instance
             */
            MarkOrderAsCompletedActionProto.create = function create(properties) {
                return new MarkOrderAsCompletedActionProto(properties);
            };

            /**
             * Encodes the specified MarkOrderAsCompletedActionProto message. Does not implicitly {@link waiternow.common.MarkOrderAsCompletedActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsCompletedActionProto} message MarkOrderAsCompletedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsCompletedActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified MarkOrderAsCompletedActionProto message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsCompletedActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsCompletedActionProto} message MarkOrderAsCompletedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsCompletedActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MarkOrderAsCompletedActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MarkOrderAsCompletedActionProto} MarkOrderAsCompletedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsCompletedActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsCompletedActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MarkOrderAsCompletedActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MarkOrderAsCompletedActionProto} MarkOrderAsCompletedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsCompletedActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MarkOrderAsCompletedActionProto message.
             * @function verify
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkOrderAsCompletedActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a MarkOrderAsCompletedActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MarkOrderAsCompletedActionProto} MarkOrderAsCompletedActionProto
             */
            MarkOrderAsCompletedActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MarkOrderAsCompletedActionProto)
                    return object;
                return new $root.waiternow.common.MarkOrderAsCompletedActionProto();
            };

            /**
             * Creates a plain object from a MarkOrderAsCompletedActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {waiternow.common.MarkOrderAsCompletedActionProto} message MarkOrderAsCompletedActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkOrderAsCompletedActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this MarkOrderAsCompletedActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkOrderAsCompletedActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MarkOrderAsCompletedActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.MarkOrderAsCompletedActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarkOrderAsCompletedActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MarkOrderAsCompletedActionProto";
            };

            MarkOrderAsCompletedActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.MarkOrderAsCompletedActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsCompletedActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsCompletedActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsCompletedActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsCompletedActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.orderId = "";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsCompletedActionProto.Request";
                };

                return Request;
            })();

            MarkOrderAsCompletedActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.MarkOrderAsCompletedActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsCompletedActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsCompletedActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsCompletedActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsCompletedActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsCompletedActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsCompletedActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsCompletedActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsCompletedActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsCompletedActionProto.Response";
                };

                return Response;
            })();

            return MarkOrderAsCompletedActionProto;
        })();

        common.MarkOrderAsDisputedActionProto = (function() {

            /**
             * Properties of a MarkOrderAsDisputedActionProto.
             * @memberof waiternow.common
             * @interface IMarkOrderAsDisputedActionProto
             */

            /**
             * Constructs a new MarkOrderAsDisputedActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a MarkOrderAsDisputedActionProto.
             * @implements IMarkOrderAsDisputedActionProto
             * @constructor
             * @param {waiternow.common.IMarkOrderAsDisputedActionProto=} [properties] Properties to set
             */
            function MarkOrderAsDisputedActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new MarkOrderAsDisputedActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsDisputedActionProto=} [properties] Properties to set
             * @returns {waiternow.common.MarkOrderAsDisputedActionProto} MarkOrderAsDisputedActionProto instance
             */
            MarkOrderAsDisputedActionProto.create = function create(properties) {
                return new MarkOrderAsDisputedActionProto(properties);
            };

            /**
             * Encodes the specified MarkOrderAsDisputedActionProto message. Does not implicitly {@link waiternow.common.MarkOrderAsDisputedActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsDisputedActionProto} message MarkOrderAsDisputedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsDisputedActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified MarkOrderAsDisputedActionProto message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsDisputedActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {waiternow.common.IMarkOrderAsDisputedActionProto} message MarkOrderAsDisputedActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkOrderAsDisputedActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MarkOrderAsDisputedActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MarkOrderAsDisputedActionProto} MarkOrderAsDisputedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsDisputedActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsDisputedActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MarkOrderAsDisputedActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MarkOrderAsDisputedActionProto} MarkOrderAsDisputedActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkOrderAsDisputedActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MarkOrderAsDisputedActionProto message.
             * @function verify
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkOrderAsDisputedActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a MarkOrderAsDisputedActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MarkOrderAsDisputedActionProto} MarkOrderAsDisputedActionProto
             */
            MarkOrderAsDisputedActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MarkOrderAsDisputedActionProto)
                    return object;
                return new $root.waiternow.common.MarkOrderAsDisputedActionProto();
            };

            /**
             * Creates a plain object from a MarkOrderAsDisputedActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {waiternow.common.MarkOrderAsDisputedActionProto} message MarkOrderAsDisputedActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkOrderAsDisputedActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this MarkOrderAsDisputedActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkOrderAsDisputedActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MarkOrderAsDisputedActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.MarkOrderAsDisputedActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarkOrderAsDisputedActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MarkOrderAsDisputedActionProto";
            };

            MarkOrderAsDisputedActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 * @property {waiternow.common.IDateProto|null} [disputeDate] Request disputeDate
                 * @property {string|null} [reason] Request reason
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Request disputeDate.
                 * @member {waiternow.common.IDateProto|null|undefined} disputeDate
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @instance
                 */
                Request.prototype.disputeDate = null;

                /**
                 * Request reason.
                 * @member {string} reason
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @instance
                 */
                Request.prototype.reason = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.MarkOrderAsDisputedActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    if (message.disputeDate != null && Object.hasOwnProperty.call(message, "disputeDate"))
                        $root.waiternow.common.DateProto.encode(message.disputeDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.reason != null && Object.hasOwnProperty.call(message, "reason"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.reason);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsDisputedActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsDisputedActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        case 2: {
                                message.disputeDate = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.reason = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    if (message.disputeDate != null && message.hasOwnProperty("disputeDate")) {
                        var error = $root.waiternow.common.DateProto.verify(message.disputeDate);
                        if (error)
                            return "disputeDate." + error;
                    }
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        if (!$util.isString(message.reason))
                            return "reason: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsDisputedActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsDisputedActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    if (object.disputeDate != null) {
                        if (typeof object.disputeDate !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsDisputedActionProto.Request.disputeDate: object expected");
                        message.disputeDate = $root.waiternow.common.DateProto.fromObject(object.disputeDate);
                    }
                    if (object.reason != null)
                        message.reason = String(object.reason);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orderId = "";
                        object.disputeDate = null;
                        object.reason = "";
                    }
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    if (message.disputeDate != null && message.hasOwnProperty("disputeDate"))
                        object.disputeDate = $root.waiternow.common.DateProto.toObject(message.disputeDate, options);
                    if (message.reason != null && message.hasOwnProperty("reason"))
                        object.reason = message.reason;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsDisputedActionProto.Request";
                };

                return Request;
            })();

            MarkOrderAsDisputedActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.MarkOrderAsDisputedActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.MarkOrderAsDisputedActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkOrderAsDisputedActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkOrderAsDisputedActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkOrderAsDisputedActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.MarkOrderAsDisputedActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.MarkOrderAsDisputedActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkOrderAsDisputedActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkOrderAsDisputedActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkOrderAsDisputedActionProto.Response";
                };

                return Response;
            })();

            return MarkOrderAsDisputedActionProto;
        })();

        common.FindUnackedOrdersActionProto = (function() {

            /**
             * Properties of a FindUnackedOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindUnackedOrdersActionProto
             */

            /**
             * Constructs a new FindUnackedOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindUnackedOrdersActionProto.
             * @implements IFindUnackedOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindUnackedOrdersActionProto=} [properties] Properties to set
             */
            function FindUnackedOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindUnackedOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindUnackedOrdersActionProto} FindUnackedOrdersActionProto instance
             */
            FindUnackedOrdersActionProto.create = function create(properties) {
                return new FindUnackedOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindUnackedOrdersActionProto message. Does not implicitly {@link waiternow.common.FindUnackedOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedOrdersActionProto} message FindUnackedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindUnackedOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedOrdersActionProto} message FindUnackedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindUnackedOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindUnackedOrdersActionProto} FindUnackedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindUnackedOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindUnackedOrdersActionProto} FindUnackedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindUnackedOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindUnackedOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindUnackedOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindUnackedOrdersActionProto} FindUnackedOrdersActionProto
             */
            FindUnackedOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindUnackedOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindUnackedOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindUnackedOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {waiternow.common.FindUnackedOrdersActionProto} message FindUnackedOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindUnackedOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindUnackedOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindUnackedOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindUnackedOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindUnackedOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindUnackedOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindUnackedOrdersActionProto";
            };

            FindUnackedOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindUnackedOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindUnackedOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindUnackedOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedOrdersActionProto.Request";
                };

                return Request;
            })();

            FindUnackedOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindUnackedOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindUnackedOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindUnackedOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindUnackedOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindUnackedOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindUnackedOrdersActionProto;
        })();

        common.FindUnackedAndPaidOrdersActionProto = (function() {

            /**
             * Properties of a FindUnackedAndPaidOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindUnackedAndPaidOrdersActionProto
             */

            /**
             * Constructs a new FindUnackedAndPaidOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindUnackedAndPaidOrdersActionProto.
             * @implements IFindUnackedAndPaidOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindUnackedAndPaidOrdersActionProto=} [properties] Properties to set
             */
            function FindUnackedAndPaidOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindUnackedAndPaidOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedAndPaidOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto} FindUnackedAndPaidOrdersActionProto instance
             */
            FindUnackedAndPaidOrdersActionProto.create = function create(properties) {
                return new FindUnackedAndPaidOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindUnackedAndPaidOrdersActionProto message. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedAndPaidOrdersActionProto} message FindUnackedAndPaidOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedAndPaidOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindUnackedAndPaidOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedAndPaidOrdersActionProto} message FindUnackedAndPaidOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedAndPaidOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindUnackedAndPaidOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto} FindUnackedAndPaidOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedAndPaidOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedAndPaidOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindUnackedAndPaidOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto} FindUnackedAndPaidOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedAndPaidOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindUnackedAndPaidOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindUnackedAndPaidOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindUnackedAndPaidOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto} FindUnackedAndPaidOrdersActionProto
             */
            FindUnackedAndPaidOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindUnackedAndPaidOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindUnackedAndPaidOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindUnackedAndPaidOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto} message FindUnackedAndPaidOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindUnackedAndPaidOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindUnackedAndPaidOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindUnackedAndPaidOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindUnackedAndPaidOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindUnackedAndPaidOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindUnackedAndPaidOrdersActionProto";
            };

            FindUnackedAndPaidOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedAndPaidOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedAndPaidOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedAndPaidOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedAndPaidOrdersActionProto.Request";
                };

                return Request;
            })();

            FindUnackedAndPaidOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedAndPaidOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedAndPaidOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedAndPaidOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindUnackedAndPaidOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindUnackedAndPaidOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedAndPaidOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindUnackedAndPaidOrdersActionProto;
        })();

        common.FindUnackedExcludingWithPaymentIntentOrdersActionProto = (function() {

            /**
             * Properties of a FindUnackedExcludingWithPaymentIntentOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindUnackedExcludingWithPaymentIntentOrdersActionProto
             */

            /**
             * Constructs a new FindUnackedExcludingWithPaymentIntentOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindUnackedExcludingWithPaymentIntentOrdersActionProto.
             * @implements IFindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindUnackedExcludingWithPaymentIntentOrdersActionProto=} [properties] Properties to set
             */
            function FindUnackedExcludingWithPaymentIntentOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindUnackedExcludingWithPaymentIntentOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedExcludingWithPaymentIntentOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto} FindUnackedExcludingWithPaymentIntentOrdersActionProto instance
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.create = function create(properties) {
                return new FindUnackedExcludingWithPaymentIntentOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindUnackedExcludingWithPaymentIntentOrdersActionProto message. Does not implicitly {@link waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedExcludingWithPaymentIntentOrdersActionProto} message FindUnackedExcludingWithPaymentIntentOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindUnackedExcludingWithPaymentIntentOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.IFindUnackedExcludingWithPaymentIntentOrdersActionProto} message FindUnackedExcludingWithPaymentIntentOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindUnackedExcludingWithPaymentIntentOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto} FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindUnackedExcludingWithPaymentIntentOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto} FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindUnackedExcludingWithPaymentIntentOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindUnackedExcludingWithPaymentIntentOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto} FindUnackedExcludingWithPaymentIntentOrdersActionProto
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindUnackedExcludingWithPaymentIntentOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto} message FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindUnackedExcludingWithPaymentIntentOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindUnackedExcludingWithPaymentIntentOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto";
            };

            FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Request";
                };

                return Request;
            })();

            FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedExcludingWithPaymentIntentOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindUnackedExcludingWithPaymentIntentOrdersActionProto;
        })();

        common.FindAckedOrdersActionProto = (function() {

            /**
             * Properties of a FindAckedOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindAckedOrdersActionProto
             */

            /**
             * Constructs a new FindAckedOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindAckedOrdersActionProto.
             * @implements IFindAckedOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindAckedOrdersActionProto=} [properties] Properties to set
             */
            function FindAckedOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindAckedOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindAckedOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindAckedOrdersActionProto} FindAckedOrdersActionProto instance
             */
            FindAckedOrdersActionProto.create = function create(properties) {
                return new FindAckedOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindAckedOrdersActionProto message. Does not implicitly {@link waiternow.common.FindAckedOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindAckedOrdersActionProto} message FindAckedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAckedOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindAckedOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindAckedOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindAckedOrdersActionProto} message FindAckedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAckedOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindAckedOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindAckedOrdersActionProto} FindAckedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAckedOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAckedOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindAckedOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindAckedOrdersActionProto} FindAckedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAckedOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindAckedOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindAckedOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindAckedOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindAckedOrdersActionProto} FindAckedOrdersActionProto
             */
            FindAckedOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindAckedOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindAckedOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindAckedOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {waiternow.common.FindAckedOrdersActionProto} message FindAckedOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindAckedOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindAckedOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindAckedOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindAckedOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindAckedOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindAckedOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindAckedOrdersActionProto";
            };

            FindAckedOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindAckedOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindAckedOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindAckedOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindAckedOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindAckedOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAckedOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindAckedOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindAckedOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindAckedOrdersActionProto.Request";
                };

                return Request;
            })();

            FindAckedOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindAckedOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindAckedOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindAckedOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindAckedOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindAckedOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAckedOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindAckedOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindAckedOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindAckedOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindAckedOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindAckedOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindAckedOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindAckedOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindAckedOrdersActionProto;
        })();

        common.FindAckedExcludingWithPaymentIntentOrdersActionProto = (function() {

            /**
             * Properties of a FindAckedExcludingWithPaymentIntentOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindAckedExcludingWithPaymentIntentOrdersActionProto
             */

            /**
             * Constructs a new FindAckedExcludingWithPaymentIntentOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindAckedExcludingWithPaymentIntentOrdersActionProto.
             * @implements IFindAckedExcludingWithPaymentIntentOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindAckedExcludingWithPaymentIntentOrdersActionProto=} [properties] Properties to set
             */
            function FindAckedExcludingWithPaymentIntentOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindAckedExcludingWithPaymentIntentOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.IFindAckedExcludingWithPaymentIntentOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto} FindAckedExcludingWithPaymentIntentOrdersActionProto instance
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.create = function create(properties) {
                return new FindAckedExcludingWithPaymentIntentOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindAckedExcludingWithPaymentIntentOrdersActionProto message. Does not implicitly {@link waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.IFindAckedExcludingWithPaymentIntentOrdersActionProto} message FindAckedExcludingWithPaymentIntentOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindAckedExcludingWithPaymentIntentOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.IFindAckedExcludingWithPaymentIntentOrdersActionProto} message FindAckedExcludingWithPaymentIntentOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindAckedExcludingWithPaymentIntentOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto} FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindAckedExcludingWithPaymentIntentOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto} FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindAckedExcludingWithPaymentIntentOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindAckedExcludingWithPaymentIntentOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto} FindAckedExcludingWithPaymentIntentOrdersActionProto
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindAckedExcludingWithPaymentIntentOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto} message FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindAckedExcludingWithPaymentIntentOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindAckedExcludingWithPaymentIntentOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto";
            };

            FindAckedExcludingWithPaymentIntentOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Request";
                };

                return Request;
            })();

            FindAckedExcludingWithPaymentIntentOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindAckedExcludingWithPaymentIntentOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindAckedExcludingWithPaymentIntentOrdersActionProto;
        })();

        common.FindPaidOrdersActionProto = (function() {

            /**
             * Properties of a FindPaidOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindPaidOrdersActionProto
             */

            /**
             * Constructs a new FindPaidOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindPaidOrdersActionProto.
             * @implements IFindPaidOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindPaidOrdersActionProto=} [properties] Properties to set
             */
            function FindPaidOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindPaidOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {waiternow.common.IFindPaidOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindPaidOrdersActionProto} FindPaidOrdersActionProto instance
             */
            FindPaidOrdersActionProto.create = function create(properties) {
                return new FindPaidOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindPaidOrdersActionProto message. Does not implicitly {@link waiternow.common.FindPaidOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {waiternow.common.IFindPaidOrdersActionProto} message FindPaidOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPaidOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindPaidOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindPaidOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {waiternow.common.IFindPaidOrdersActionProto} message FindPaidOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPaidOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindPaidOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindPaidOrdersActionProto} FindPaidOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPaidOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaidOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindPaidOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindPaidOrdersActionProto} FindPaidOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPaidOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindPaidOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindPaidOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindPaidOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindPaidOrdersActionProto} FindPaidOrdersActionProto
             */
            FindPaidOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindPaidOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindPaidOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindPaidOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {waiternow.common.FindPaidOrdersActionProto} message FindPaidOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindPaidOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindPaidOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindPaidOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindPaidOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindPaidOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindPaidOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindPaidOrdersActionProto";
            };

            FindPaidOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindPaidOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {google.protobuf.ITimestamp|null} [afterTime] Request afterTime
                 * @property {google.protobuf.ITimestamp|null} [beforeTime] Request beforeTime
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindPaidOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindPaidOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request afterTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} afterTime
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.afterTime = null;

                /**
                 * Request beforeTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} beforeTime
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeTime = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindPaidOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.afterTime != null && Object.hasOwnProperty.call(message, "afterTime"))
                        $root.google.protobuf.Timestamp.encode(message.afterTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.beforeTime != null && Object.hasOwnProperty.call(message, "beforeTime"))
                        $root.google.protobuf.Timestamp.encode(message.beforeTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindPaidOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaidOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.afterTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.beforeTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 5: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.afterTime != null && message.hasOwnProperty("afterTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.afterTime);
                        if (error)
                            return "afterTime." + error;
                    }
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.beforeTime);
                        if (error)
                            return "beforeTime." + error;
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPaidOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindPaidOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.afterTime != null) {
                        if (typeof object.afterTime !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersActionProto.Request.afterTime: object expected");
                        message.afterTime = $root.google.protobuf.Timestamp.fromObject(object.afterTime);
                    }
                    if (object.beforeTime != null) {
                        if (typeof object.beforeTime !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersActionProto.Request.beforeTime: object expected");
                        message.beforeTime = $root.google.protobuf.Timestamp.fromObject(object.beforeTime);
                    }
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.afterTime = null;
                        object.beforeTime = null;
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.afterTime != null && message.hasOwnProperty("afterTime"))
                        object.afterTime = $root.google.protobuf.Timestamp.toObject(message.afterTime, options);
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime"))
                        object.beforeTime = $root.google.protobuf.Timestamp.toObject(message.beforeTime, options);
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPaidOrdersActionProto.Request";
                };

                return Request;
            })();

            FindPaidOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindPaidOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindPaidOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindPaidOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindPaidOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindPaidOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaidOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPaidOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPaidOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindPaidOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPaidOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPaidOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindPaidOrdersActionProto;
        })();

        common.RefundPaidOrderActionProto = (function() {

            /**
             * Properties of a RefundPaidOrderActionProto.
             * @memberof waiternow.common
             * @interface IRefundPaidOrderActionProto
             */

            /**
             * Constructs a new RefundPaidOrderActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a RefundPaidOrderActionProto.
             * @implements IRefundPaidOrderActionProto
             * @constructor
             * @param {waiternow.common.IRefundPaidOrderActionProto=} [properties] Properties to set
             */
            function RefundPaidOrderActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new RefundPaidOrderActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {waiternow.common.IRefundPaidOrderActionProto=} [properties] Properties to set
             * @returns {waiternow.common.RefundPaidOrderActionProto} RefundPaidOrderActionProto instance
             */
            RefundPaidOrderActionProto.create = function create(properties) {
                return new RefundPaidOrderActionProto(properties);
            };

            /**
             * Encodes the specified RefundPaidOrderActionProto message. Does not implicitly {@link waiternow.common.RefundPaidOrderActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {waiternow.common.IRefundPaidOrderActionProto} message RefundPaidOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RefundPaidOrderActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified RefundPaidOrderActionProto message, length delimited. Does not implicitly {@link waiternow.common.RefundPaidOrderActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {waiternow.common.IRefundPaidOrderActionProto} message RefundPaidOrderActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            RefundPaidOrderActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a RefundPaidOrderActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.RefundPaidOrderActionProto} RefundPaidOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RefundPaidOrderActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RefundPaidOrderActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a RefundPaidOrderActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.RefundPaidOrderActionProto} RefundPaidOrderActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            RefundPaidOrderActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a RefundPaidOrderActionProto message.
             * @function verify
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            RefundPaidOrderActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a RefundPaidOrderActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.RefundPaidOrderActionProto} RefundPaidOrderActionProto
             */
            RefundPaidOrderActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.RefundPaidOrderActionProto)
                    return object;
                return new $root.waiternow.common.RefundPaidOrderActionProto();
            };

            /**
             * Creates a plain object from a RefundPaidOrderActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {waiternow.common.RefundPaidOrderActionProto} message RefundPaidOrderActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            RefundPaidOrderActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this RefundPaidOrderActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            RefundPaidOrderActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for RefundPaidOrderActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.RefundPaidOrderActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            RefundPaidOrderActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.RefundPaidOrderActionProto";
            };

            RefundPaidOrderActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.RefundPaidOrderActionProto
                 * @interface IRequest
                 * @property {string|null} [orderId] Request orderId
                 * @property {waiternow.common.IMoneyProto|null} [amount] Request amount
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.RefundPaidOrderActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.RefundPaidOrderActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request orderId.
                 * @member {string} orderId
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.orderId = "";

                /**
                 * Request amount.
                 * @member {waiternow.common.IMoneyProto|null|undefined} amount
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @instance
                 */
                Request.prototype.amount = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.RefundPaidOrderActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.orderId != null && Object.hasOwnProperty.call(message, "orderId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.orderId);
                    if (message.amount != null && Object.hasOwnProperty.call(message, "amount"))
                        $root.waiternow.common.MoneyProto.encode(message.amount, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.RefundPaidOrderActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RefundPaidOrderActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.orderId = reader.string();
                                break;
                            }
                        case 7: {
                                message.amount = $root.waiternow.common.MoneyProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        if (!$util.isString(message.orderId))
                            return "orderId: string expected";
                    if (message.amount != null && message.hasOwnProperty("amount")) {
                        var error = $root.waiternow.common.MoneyProto.verify(message.amount);
                        if (error)
                            return "amount." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RefundPaidOrderActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.RefundPaidOrderActionProto.Request();
                    if (object.orderId != null)
                        message.orderId = String(object.orderId);
                    if (object.amount != null) {
                        if (typeof object.amount !== "object")
                            throw TypeError(".waiternow.common.RefundPaidOrderActionProto.Request.amount: object expected");
                        message.amount = $root.waiternow.common.MoneyProto.fromObject(object.amount);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.orderId = "";
                        object.amount = null;
                    }
                    if (message.orderId != null && message.hasOwnProperty("orderId"))
                        object.orderId = message.orderId;
                    if (message.amount != null && message.hasOwnProperty("amount"))
                        object.amount = $root.waiternow.common.MoneyProto.toObject(message.amount, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RefundPaidOrderActionProto.Request";
                };

                return Request;
            })();

            RefundPaidOrderActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.RefundPaidOrderActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IPaymentProto|null} [payment] Response payment
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.RefundPaidOrderActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.RefundPaidOrderActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response payment.
                 * @member {waiternow.common.IPaymentProto|null|undefined} payment
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @instance
                 */
                Response.prototype.payment = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.RefundPaidOrderActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.payment != null && Object.hasOwnProperty.call(message, "payment"))
                        $root.waiternow.common.PaymentProto.encode(message.payment, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.RefundPaidOrderActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.RefundPaidOrderActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.payment = $root.waiternow.common.PaymentProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.payment != null && message.hasOwnProperty("payment")) {
                        var error = $root.waiternow.common.PaymentProto.verify(message.payment);
                        if (error)
                            return "payment." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.RefundPaidOrderActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.RefundPaidOrderActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.RefundPaidOrderActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.RefundPaidOrderActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.payment != null) {
                        if (typeof object.payment !== "object")
                            throw TypeError(".waiternow.common.RefundPaidOrderActionProto.Response.payment: object expected");
                        message.payment = $root.waiternow.common.PaymentProto.fromObject(object.payment);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {waiternow.common.RefundPaidOrderActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.payment = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.payment != null && message.hasOwnProperty("payment"))
                        object.payment = $root.waiternow.common.PaymentProto.toObject(message.payment, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.RefundPaidOrderActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.RefundPaidOrderActionProto.Response";
                };

                return Response;
            })();

            return RefundPaidOrderActionProto;
        })();

        common.FindRefundedOrdersActionProto = (function() {

            /**
             * Properties of a FindRefundedOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindRefundedOrdersActionProto
             */

            /**
             * Constructs a new FindRefundedOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindRefundedOrdersActionProto.
             * @implements IFindRefundedOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindRefundedOrdersActionProto=} [properties] Properties to set
             */
            function FindRefundedOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindRefundedOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindRefundedOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindRefundedOrdersActionProto} FindRefundedOrdersActionProto instance
             */
            FindRefundedOrdersActionProto.create = function create(properties) {
                return new FindRefundedOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindRefundedOrdersActionProto message. Does not implicitly {@link waiternow.common.FindRefundedOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindRefundedOrdersActionProto} message FindRefundedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindRefundedOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindRefundedOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindRefundedOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindRefundedOrdersActionProto} message FindRefundedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindRefundedOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindRefundedOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindRefundedOrdersActionProto} FindRefundedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindRefundedOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindRefundedOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindRefundedOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindRefundedOrdersActionProto} FindRefundedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindRefundedOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindRefundedOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindRefundedOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindRefundedOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindRefundedOrdersActionProto} FindRefundedOrdersActionProto
             */
            FindRefundedOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindRefundedOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindRefundedOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindRefundedOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {waiternow.common.FindRefundedOrdersActionProto} message FindRefundedOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindRefundedOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindRefundedOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindRefundedOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindRefundedOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindRefundedOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindRefundedOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindRefundedOrdersActionProto";
            };

            FindRefundedOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindRefundedOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {google.protobuf.ITimestamp|null} [afterTime] Request afterTime
                 * @property {google.protobuf.ITimestamp|null} [beforeTime] Request beforeTime
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindRefundedOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request afterTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} afterTime
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.afterTime = null;

                /**
                 * Request beforeTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} beforeTime
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeTime = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindRefundedOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.afterTime != null && Object.hasOwnProperty.call(message, "afterTime"))
                        $root.google.protobuf.Timestamp.encode(message.afterTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.beforeTime != null && Object.hasOwnProperty.call(message, "beforeTime"))
                        $root.google.protobuf.Timestamp.encode(message.beforeTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindRefundedOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindRefundedOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.afterTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.beforeTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 5: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.afterTime != null && message.hasOwnProperty("afterTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.afterTime);
                        if (error)
                            return "afterTime." + error;
                    }
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.beforeTime);
                        if (error)
                            return "beforeTime." + error;
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindRefundedOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindRefundedOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.afterTime != null) {
                        if (typeof object.afterTime !== "object")
                            throw TypeError(".waiternow.common.FindRefundedOrdersActionProto.Request.afterTime: object expected");
                        message.afterTime = $root.google.protobuf.Timestamp.fromObject(object.afterTime);
                    }
                    if (object.beforeTime != null) {
                        if (typeof object.beforeTime !== "object")
                            throw TypeError(".waiternow.common.FindRefundedOrdersActionProto.Request.beforeTime: object expected");
                        message.beforeTime = $root.google.protobuf.Timestamp.fromObject(object.beforeTime);
                    }
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.afterTime = null;
                        object.beforeTime = null;
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.afterTime != null && message.hasOwnProperty("afterTime"))
                        object.afterTime = $root.google.protobuf.Timestamp.toObject(message.afterTime, options);
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime"))
                        object.beforeTime = $root.google.protobuf.Timestamp.toObject(message.beforeTime, options);
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindRefundedOrdersActionProto.Request";
                };

                return Request;
            })();

            FindRefundedOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindRefundedOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindRefundedOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindRefundedOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindRefundedOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindRefundedOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindRefundedOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindRefundedOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindRefundedOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindRefundedOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindRefundedOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindRefundedOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindRefundedOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindRefundedOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindRefundedOrdersActionProto;
        })();

        common.FindDisputedOrdersActionProto = (function() {

            /**
             * Properties of a FindDisputedOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindDisputedOrdersActionProto
             */

            /**
             * Constructs a new FindDisputedOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindDisputedOrdersActionProto.
             * @implements IFindDisputedOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindDisputedOrdersActionProto=} [properties] Properties to set
             */
            function FindDisputedOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindDisputedOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindDisputedOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindDisputedOrdersActionProto} FindDisputedOrdersActionProto instance
             */
            FindDisputedOrdersActionProto.create = function create(properties) {
                return new FindDisputedOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindDisputedOrdersActionProto message. Does not implicitly {@link waiternow.common.FindDisputedOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindDisputedOrdersActionProto} message FindDisputedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDisputedOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindDisputedOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindDisputedOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {waiternow.common.IFindDisputedOrdersActionProto} message FindDisputedOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDisputedOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindDisputedOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindDisputedOrdersActionProto} FindDisputedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDisputedOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDisputedOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindDisputedOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindDisputedOrdersActionProto} FindDisputedOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDisputedOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindDisputedOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindDisputedOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindDisputedOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindDisputedOrdersActionProto} FindDisputedOrdersActionProto
             */
            FindDisputedOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindDisputedOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindDisputedOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindDisputedOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {waiternow.common.FindDisputedOrdersActionProto} message FindDisputedOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindDisputedOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindDisputedOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindDisputedOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindDisputedOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindDisputedOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindDisputedOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindDisputedOrdersActionProto";
            };

            FindDisputedOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindDisputedOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {google.protobuf.ITimestamp|null} [afterTime] Request afterTime
                 * @property {google.protobuf.ITimestamp|null} [beforeTime] Request beforeTime
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindDisputedOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request afterTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} afterTime
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.afterTime = null;

                /**
                 * Request beforeTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} beforeTime
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeTime = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindDisputedOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.afterTime != null && Object.hasOwnProperty.call(message, "afterTime"))
                        $root.google.protobuf.Timestamp.encode(message.afterTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.beforeTime != null && Object.hasOwnProperty.call(message, "beforeTime"))
                        $root.google.protobuf.Timestamp.encode(message.beforeTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindDisputedOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDisputedOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.afterTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.beforeTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 5: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.afterTime != null && message.hasOwnProperty("afterTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.afterTime);
                        if (error)
                            return "afterTime." + error;
                    }
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.beforeTime);
                        if (error)
                            return "beforeTime." + error;
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDisputedOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindDisputedOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.afterTime != null) {
                        if (typeof object.afterTime !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersActionProto.Request.afterTime: object expected");
                        message.afterTime = $root.google.protobuf.Timestamp.fromObject(object.afterTime);
                    }
                    if (object.beforeTime != null) {
                        if (typeof object.beforeTime !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersActionProto.Request.beforeTime: object expected");
                        message.beforeTime = $root.google.protobuf.Timestamp.fromObject(object.beforeTime);
                    }
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.afterTime = null;
                        object.beforeTime = null;
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.afterTime != null && message.hasOwnProperty("afterTime"))
                        object.afterTime = $root.google.protobuf.Timestamp.toObject(message.afterTime, options);
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime"))
                        object.beforeTime = $root.google.protobuf.Timestamp.toObject(message.beforeTime, options);
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDisputedOrdersActionProto.Request";
                };

                return Request;
            })();

            FindDisputedOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindDisputedOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindDisputedOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindDisputedOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindDisputedOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDisputedOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDisputedOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDisputedOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindDisputedOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDisputedOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDisputedOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindDisputedOrdersActionProto;
        })();

        common.FindDisputedOrdersForAllLocationsActionProto = (function() {

            /**
             * Properties of a FindDisputedOrdersForAllLocationsActionProto.
             * @memberof waiternow.common
             * @interface IFindDisputedOrdersForAllLocationsActionProto
             */

            /**
             * Constructs a new FindDisputedOrdersForAllLocationsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindDisputedOrdersForAllLocationsActionProto.
             * @implements IFindDisputedOrdersForAllLocationsActionProto
             * @constructor
             * @param {waiternow.common.IFindDisputedOrdersForAllLocationsActionProto=} [properties] Properties to set
             */
            function FindDisputedOrdersForAllLocationsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindDisputedOrdersForAllLocationsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.IFindDisputedOrdersForAllLocationsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto} FindDisputedOrdersForAllLocationsActionProto instance
             */
            FindDisputedOrdersForAllLocationsActionProto.create = function create(properties) {
                return new FindDisputedOrdersForAllLocationsActionProto(properties);
            };

            /**
             * Encodes the specified FindDisputedOrdersForAllLocationsActionProto message. Does not implicitly {@link waiternow.common.FindDisputedOrdersForAllLocationsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.IFindDisputedOrdersForAllLocationsActionProto} message FindDisputedOrdersForAllLocationsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDisputedOrdersForAllLocationsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindDisputedOrdersForAllLocationsActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindDisputedOrdersForAllLocationsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.IFindDisputedOrdersForAllLocationsActionProto} message FindDisputedOrdersForAllLocationsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDisputedOrdersForAllLocationsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindDisputedOrdersForAllLocationsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto} FindDisputedOrdersForAllLocationsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDisputedOrdersForAllLocationsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindDisputedOrdersForAllLocationsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto} FindDisputedOrdersForAllLocationsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDisputedOrdersForAllLocationsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindDisputedOrdersForAllLocationsActionProto message.
             * @function verify
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindDisputedOrdersForAllLocationsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindDisputedOrdersForAllLocationsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto} FindDisputedOrdersForAllLocationsActionProto
             */
            FindDisputedOrdersForAllLocationsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto)
                    return object;
                return new $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto();
            };

            /**
             * Creates a plain object from a FindDisputedOrdersForAllLocationsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto} message FindDisputedOrdersForAllLocationsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindDisputedOrdersForAllLocationsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindDisputedOrdersForAllLocationsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindDisputedOrdersForAllLocationsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindDisputedOrdersForAllLocationsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindDisputedOrdersForAllLocationsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindDisputedOrdersForAllLocationsActionProto";
            };

            FindDisputedOrdersForAllLocationsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
                 * @interface IRequest
                 * @property {google.protobuf.ITimestamp|null} [afterTime] Request afterTime
                 * @property {google.protobuf.ITimestamp|null} [beforeTime] Request beforeTime
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request afterTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} afterTime
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.afterTime = null;

                /**
                 * Request beforeTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} beforeTime
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeTime = null;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.afterTime != null && Object.hasOwnProperty.call(message, "afterTime"))
                        $root.google.protobuf.Timestamp.encode(message.afterTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.beforeTime != null && Object.hasOwnProperty.call(message, "beforeTime"))
                        $root.google.protobuf.Timestamp.encode(message.beforeTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.afterTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.beforeTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.afterTime != null && message.hasOwnProperty("afterTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.afterTime);
                        if (error)
                            return "afterTime." + error;
                    }
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.beforeTime);
                        if (error)
                            return "beforeTime." + error;
                    }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request();
                    if (object.afterTime != null) {
                        if (typeof object.afterTime !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request.afterTime: object expected");
                        message.afterTime = $root.google.protobuf.Timestamp.fromObject(object.afterTime);
                    }
                    if (object.beforeTime != null) {
                        if (typeof object.beforeTime !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request.beforeTime: object expected");
                        message.beforeTime = $root.google.protobuf.Timestamp.fromObject(object.beforeTime);
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.afterTime = null;
                        object.beforeTime = null;
                        object.continuationToken = "";
                    }
                    if (message.afterTime != null && message.hasOwnProperty("afterTime"))
                        object.afterTime = $root.google.protobuf.Timestamp.toObject(message.afterTime, options);
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime"))
                        object.beforeTime = $root.google.protobuf.Timestamp.toObject(message.beforeTime, options);
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Request";
                };

                return Request;
            })();

            FindDisputedOrdersForAllLocationsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IOrdersProto|null} [orders] Response orders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response orders.
                 * @member {waiternow.common.IOrdersProto|null|undefined} orders
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @instance
                 */
                Response.prototype.orders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                        $root.waiternow.common.OrdersProto.encode(message.orders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.orders = $root.waiternow.common.OrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.orders != null && message.hasOwnProperty("orders")) {
                        var error = $root.waiternow.common.OrdersProto.verify(message.orders);
                        if (error)
                            return "orders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.orders != null) {
                        if (typeof object.orders !== "object")
                            throw TypeError(".waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response.orders: object expected");
                        message.orders = $root.waiternow.common.OrdersProto.fromObject(object.orders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.orders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.orders != null && message.hasOwnProperty("orders"))
                        object.orders = $root.waiternow.common.OrdersProto.toObject(message.orders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDisputedOrdersForAllLocationsActionProto.Response";
                };

                return Response;
            })();

            return FindDisputedOrdersForAllLocationsActionProto;
        })();

        common.FindUnackedAndPaidOrdersForAllLocationsActionProto = (function() {

            /**
             * Properties of a FindUnackedAndPaidOrdersForAllLocationsActionProto.
             * @memberof waiternow.common
             * @interface IFindUnackedAndPaidOrdersForAllLocationsActionProto
             */

            /**
             * Constructs a new FindUnackedAndPaidOrdersForAllLocationsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindUnackedAndPaidOrdersForAllLocationsActionProto.
             * @implements IFindUnackedAndPaidOrdersForAllLocationsActionProto
             * @constructor
             * @param {waiternow.common.IFindUnackedAndPaidOrdersForAllLocationsActionProto=} [properties] Properties to set
             */
            function FindUnackedAndPaidOrdersForAllLocationsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindUnackedAndPaidOrdersForAllLocationsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.IFindUnackedAndPaidOrdersForAllLocationsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto} FindUnackedAndPaidOrdersForAllLocationsActionProto instance
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.create = function create(properties) {
                return new FindUnackedAndPaidOrdersForAllLocationsActionProto(properties);
            };

            /**
             * Encodes the specified FindUnackedAndPaidOrdersForAllLocationsActionProto message. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.IFindUnackedAndPaidOrdersForAllLocationsActionProto} message FindUnackedAndPaidOrdersForAllLocationsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindUnackedAndPaidOrdersForAllLocationsActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.IFindUnackedAndPaidOrdersForAllLocationsActionProto} message FindUnackedAndPaidOrdersForAllLocationsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindUnackedAndPaidOrdersForAllLocationsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto} FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindUnackedAndPaidOrdersForAllLocationsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto} FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindUnackedAndPaidOrdersForAllLocationsActionProto message.
             * @function verify
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindUnackedAndPaidOrdersForAllLocationsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto} FindUnackedAndPaidOrdersForAllLocationsActionProto
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto)
                    return object;
                return new $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto();
            };

            /**
             * Creates a plain object from a FindUnackedAndPaidOrdersForAllLocationsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto} message FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindUnackedAndPaidOrdersForAllLocationsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindUnackedAndPaidOrdersForAllLocationsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto";
            };

            FindUnackedAndPaidOrdersForAllLocationsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
                 * @interface IRequest
                 * @property {google.protobuf.ITimestamp|null} [afterTime] Request afterTime
                 * @property {google.protobuf.ITimestamp|null} [beforeTime] Request beforeTime
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request afterTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} afterTime
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.afterTime = null;

                /**
                 * Request beforeTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} beforeTime
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeTime = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.afterTime != null && Object.hasOwnProperty.call(message, "afterTime"))
                        $root.google.protobuf.Timestamp.encode(message.afterTime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.beforeTime != null && Object.hasOwnProperty.call(message, "beforeTime"))
                        $root.google.protobuf.Timestamp.encode(message.beforeTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.afterTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.beforeTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 4: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.afterTime != null && message.hasOwnProperty("afterTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.afterTime);
                        if (error)
                            return "afterTime." + error;
                    }
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.beforeTime);
                        if (error)
                            return "beforeTime." + error;
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request();
                    if (object.afterTime != null) {
                        if (typeof object.afterTime !== "object")
                            throw TypeError(".waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request.afterTime: object expected");
                        message.afterTime = $root.google.protobuf.Timestamp.fromObject(object.afterTime);
                    }
                    if (object.beforeTime != null) {
                        if (typeof object.beforeTime !== "object")
                            throw TypeError(".waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request.beforeTime: object expected");
                        message.beforeTime = $root.google.protobuf.Timestamp.fromObject(object.beforeTime);
                    }
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.afterTime = null;
                        object.beforeTime = null;
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.afterTime != null && message.hasOwnProperty("afterTime"))
                        object.afterTime = $root.google.protobuf.Timestamp.toObject(message.afterTime, options);
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime"))
                        object.beforeTime = $root.google.protobuf.Timestamp.toObject(message.beforeTime, options);
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Request";
                };

                return Request;
            })();

            FindUnackedAndPaidOrdersForAllLocationsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IOrdersProto|null} [orders] Response orders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response orders.
                 * @member {waiternow.common.IOrdersProto|null|undefined} orders
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @instance
                 */
                Response.prototype.orders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                        $root.waiternow.common.OrdersProto.encode(message.orders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.orders = $root.waiternow.common.OrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.orders != null && message.hasOwnProperty("orders")) {
                        var error = $root.waiternow.common.OrdersProto.verify(message.orders);
                        if (error)
                            return "orders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.orders != null) {
                        if (typeof object.orders !== "object")
                            throw TypeError(".waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response.orders: object expected");
                        message.orders = $root.waiternow.common.OrdersProto.fromObject(object.orders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.orders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.orders != null && message.hasOwnProperty("orders"))
                        object.orders = $root.waiternow.common.OrdersProto.toObject(message.orders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindUnackedAndPaidOrdersForAllLocationsActionProto.Response";
                };

                return Response;
            })();

            return FindUnackedAndPaidOrdersForAllLocationsActionProto;
        })();

        common.FindPaidOrdersInProgressActionProto = (function() {

            /**
             * Properties of a FindPaidOrdersInProgressActionProto.
             * @memberof waiternow.common
             * @interface IFindPaidOrdersInProgressActionProto
             */

            /**
             * Constructs a new FindPaidOrdersInProgressActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindPaidOrdersInProgressActionProto.
             * @implements IFindPaidOrdersInProgressActionProto
             * @constructor
             * @param {waiternow.common.IFindPaidOrdersInProgressActionProto=} [properties] Properties to set
             */
            function FindPaidOrdersInProgressActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindPaidOrdersInProgressActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {waiternow.common.IFindPaidOrdersInProgressActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindPaidOrdersInProgressActionProto} FindPaidOrdersInProgressActionProto instance
             */
            FindPaidOrdersInProgressActionProto.create = function create(properties) {
                return new FindPaidOrdersInProgressActionProto(properties);
            };

            /**
             * Encodes the specified FindPaidOrdersInProgressActionProto message. Does not implicitly {@link waiternow.common.FindPaidOrdersInProgressActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {waiternow.common.IFindPaidOrdersInProgressActionProto} message FindPaidOrdersInProgressActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPaidOrdersInProgressActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindPaidOrdersInProgressActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindPaidOrdersInProgressActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {waiternow.common.IFindPaidOrdersInProgressActionProto} message FindPaidOrdersInProgressActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPaidOrdersInProgressActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindPaidOrdersInProgressActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindPaidOrdersInProgressActionProto} FindPaidOrdersInProgressActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPaidOrdersInProgressActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaidOrdersInProgressActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindPaidOrdersInProgressActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindPaidOrdersInProgressActionProto} FindPaidOrdersInProgressActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPaidOrdersInProgressActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindPaidOrdersInProgressActionProto message.
             * @function verify
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindPaidOrdersInProgressActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindPaidOrdersInProgressActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindPaidOrdersInProgressActionProto} FindPaidOrdersInProgressActionProto
             */
            FindPaidOrdersInProgressActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindPaidOrdersInProgressActionProto)
                    return object;
                return new $root.waiternow.common.FindPaidOrdersInProgressActionProto();
            };

            /**
             * Creates a plain object from a FindPaidOrdersInProgressActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {waiternow.common.FindPaidOrdersInProgressActionProto} message FindPaidOrdersInProgressActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindPaidOrdersInProgressActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindPaidOrdersInProgressActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindPaidOrdersInProgressActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindPaidOrdersInProgressActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindPaidOrdersInProgressActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindPaidOrdersInProgressActionProto";
            };

            FindPaidOrdersInProgressActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {google.protobuf.ITimestamp|null} [afterTime] Request afterTime
                 * @property {google.protobuf.ITimestamp|null} [beforeTime] Request beforeTime
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request afterTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} afterTime
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @instance
                 */
                Request.prototype.afterTime = null;

                /**
                 * Request beforeTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} beforeTime
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeTime = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindPaidOrdersInProgressActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.afterTime != null && Object.hasOwnProperty.call(message, "afterTime"))
                        $root.google.protobuf.Timestamp.encode(message.afterTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.beforeTime != null && Object.hasOwnProperty.call(message, "beforeTime"))
                        $root.google.protobuf.Timestamp.encode(message.beforeTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindPaidOrdersInProgressActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaidOrdersInProgressActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.afterTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.beforeTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 5: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.afterTime != null && message.hasOwnProperty("afterTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.afterTime);
                        if (error)
                            return "afterTime." + error;
                    }
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.beforeTime);
                        if (error)
                            return "beforeTime." + error;
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPaidOrdersInProgressActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindPaidOrdersInProgressActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.afterTime != null) {
                        if (typeof object.afterTime !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersInProgressActionProto.Request.afterTime: object expected");
                        message.afterTime = $root.google.protobuf.Timestamp.fromObject(object.afterTime);
                    }
                    if (object.beforeTime != null) {
                        if (typeof object.beforeTime !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersInProgressActionProto.Request.beforeTime: object expected");
                        message.beforeTime = $root.google.protobuf.Timestamp.fromObject(object.beforeTime);
                    }
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.afterTime = null;
                        object.beforeTime = null;
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.afterTime != null && message.hasOwnProperty("afterTime"))
                        object.afterTime = $root.google.protobuf.Timestamp.toObject(message.afterTime, options);
                    if (message.beforeTime != null && message.hasOwnProperty("beforeTime"))
                        object.beforeTime = $root.google.protobuf.Timestamp.toObject(message.beforeTime, options);
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPaidOrdersInProgressActionProto.Request";
                };

                return Request;
            })();

            FindPaidOrdersInProgressActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationOrdersProto|null} [locationOrders] Response locationOrders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationOrders.
                 * @member {waiternow.common.ILocationOrdersProto|null|undefined} locationOrders
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @instance
                 */
                Response.prototype.locationOrders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindPaidOrdersInProgressActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationOrders != null && Object.hasOwnProperty.call(message, "locationOrders"))
                        $root.waiternow.common.LocationOrdersProto.encode(message.locationOrders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindPaidOrdersInProgressActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaidOrdersInProgressActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationOrders = $root.waiternow.common.LocationOrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders")) {
                        var error = $root.waiternow.common.LocationOrdersProto.verify(message.locationOrders);
                        if (error)
                            return "locationOrders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPaidOrdersInProgressActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPaidOrdersInProgressActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindPaidOrdersInProgressActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersInProgressActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationOrders != null) {
                        if (typeof object.locationOrders !== "object")
                            throw TypeError(".waiternow.common.FindPaidOrdersInProgressActionProto.Response.locationOrders: object expected");
                        message.locationOrders = $root.waiternow.common.LocationOrdersProto.fromObject(object.locationOrders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaidOrdersInProgressActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationOrders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationOrders != null && message.hasOwnProperty("locationOrders"))
                        object.locationOrders = $root.waiternow.common.LocationOrdersProto.toObject(message.locationOrders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPaidOrdersInProgressActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPaidOrdersInProgressActionProto.Response";
                };

                return Response;
            })();

            return FindPaidOrdersInProgressActionProto;
        })();

        common.FindPendingOrdersActionProto = (function() {

            /**
             * Properties of a FindPendingOrdersActionProto.
             * @memberof waiternow.common
             * @interface IFindPendingOrdersActionProto
             */

            /**
             * Constructs a new FindPendingOrdersActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindPendingOrdersActionProto.
             * @implements IFindPendingOrdersActionProto
             * @constructor
             * @param {waiternow.common.IFindPendingOrdersActionProto=} [properties] Properties to set
             */
            function FindPendingOrdersActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindPendingOrdersActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {waiternow.common.IFindPendingOrdersActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindPendingOrdersActionProto} FindPendingOrdersActionProto instance
             */
            FindPendingOrdersActionProto.create = function create(properties) {
                return new FindPendingOrdersActionProto(properties);
            };

            /**
             * Encodes the specified FindPendingOrdersActionProto message. Does not implicitly {@link waiternow.common.FindPendingOrdersActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {waiternow.common.IFindPendingOrdersActionProto} message FindPendingOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPendingOrdersActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindPendingOrdersActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindPendingOrdersActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {waiternow.common.IFindPendingOrdersActionProto} message FindPendingOrdersActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPendingOrdersActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindPendingOrdersActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindPendingOrdersActionProto} FindPendingOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPendingOrdersActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPendingOrdersActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindPendingOrdersActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindPendingOrdersActionProto} FindPendingOrdersActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPendingOrdersActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindPendingOrdersActionProto message.
             * @function verify
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindPendingOrdersActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindPendingOrdersActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindPendingOrdersActionProto} FindPendingOrdersActionProto
             */
            FindPendingOrdersActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindPendingOrdersActionProto)
                    return object;
                return new $root.waiternow.common.FindPendingOrdersActionProto();
            };

            /**
             * Creates a plain object from a FindPendingOrdersActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {waiternow.common.FindPendingOrdersActionProto} message FindPendingOrdersActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindPendingOrdersActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindPendingOrdersActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindPendingOrdersActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindPendingOrdersActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindPendingOrdersActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindPendingOrdersActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindPendingOrdersActionProto";
            };

            FindPendingOrdersActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindPendingOrdersActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {google.protobuf.ITimestamp|null} [afterTime] Request afterTime
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindPendingOrdersActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindPendingOrdersActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request afterTime.
                 * @member {google.protobuf.ITimestamp|null|undefined} afterTime
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.afterTime = null;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindPendingOrdersActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.afterTime != null && Object.hasOwnProperty.call(message, "afterTime"))
                        $root.google.protobuf.Timestamp.encode(message.afterTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindPendingOrdersActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPendingOrdersActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.afterTime = $root.google.protobuf.Timestamp.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.afterTime != null && message.hasOwnProperty("afterTime")) {
                        var error = $root.google.protobuf.Timestamp.verify(message.afterTime);
                        if (error)
                            return "afterTime." + error;
                    }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPendingOrdersActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindPendingOrdersActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.afterTime != null) {
                        if (typeof object.afterTime !== "object")
                            throw TypeError(".waiternow.common.FindPendingOrdersActionProto.Request.afterTime: object expected");
                        message.afterTime = $root.google.protobuf.Timestamp.fromObject(object.afterTime);
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.afterTime = null;
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.afterTime != null && message.hasOwnProperty("afterTime"))
                        object.afterTime = $root.google.protobuf.Timestamp.toObject(message.afterTime, options);
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPendingOrdersActionProto.Request";
                };

                return Request;
            })();

            FindPendingOrdersActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindPendingOrdersActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IOrdersProto|null} [orders] Response orders
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindPendingOrdersActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindPendingOrdersActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response orders.
                 * @member {waiternow.common.IOrdersProto|null|undefined} orders
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @instance
                 */
                Response.prototype.orders = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindPendingOrdersActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.orders != null && Object.hasOwnProperty.call(message, "orders"))
                        $root.waiternow.common.OrdersProto.encode(message.orders, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindPendingOrdersActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPendingOrdersActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.orders = $root.waiternow.common.OrdersProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.orders != null && message.hasOwnProperty("orders")) {
                        var error = $root.waiternow.common.OrdersProto.verify(message.orders);
                        if (error)
                            return "orders." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPendingOrdersActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPendingOrdersActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindPendingOrdersActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindPendingOrdersActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.orders != null) {
                        if (typeof object.orders !== "object")
                            throw TypeError(".waiternow.common.FindPendingOrdersActionProto.Response.orders: object expected");
                        message.orders = $root.waiternow.common.OrdersProto.fromObject(object.orders);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingOrdersActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.orders = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.orders != null && message.hasOwnProperty("orders"))
                        object.orders = $root.waiternow.common.OrdersProto.toObject(message.orders, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPendingOrdersActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPendingOrdersActionProto.Response";
                };

                return Response;
            })();

            return FindPendingOrdersActionProto;
        })();

        common.FindReviewsByLocationActionProto = (function() {

            /**
             * Properties of a FindReviewsByLocationActionProto.
             * @memberof waiternow.common
             * @interface IFindReviewsByLocationActionProto
             */

            /**
             * Constructs a new FindReviewsByLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindReviewsByLocationActionProto.
             * @implements IFindReviewsByLocationActionProto
             * @constructor
             * @param {waiternow.common.IFindReviewsByLocationActionProto=} [properties] Properties to set
             */
            function FindReviewsByLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindReviewsByLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {waiternow.common.IFindReviewsByLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindReviewsByLocationActionProto} FindReviewsByLocationActionProto instance
             */
            FindReviewsByLocationActionProto.create = function create(properties) {
                return new FindReviewsByLocationActionProto(properties);
            };

            /**
             * Encodes the specified FindReviewsByLocationActionProto message. Does not implicitly {@link waiternow.common.FindReviewsByLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {waiternow.common.IFindReviewsByLocationActionProto} message FindReviewsByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindReviewsByLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindReviewsByLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindReviewsByLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {waiternow.common.IFindReviewsByLocationActionProto} message FindReviewsByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindReviewsByLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindReviewsByLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindReviewsByLocationActionProto} FindReviewsByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindReviewsByLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindReviewsByLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindReviewsByLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindReviewsByLocationActionProto} FindReviewsByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindReviewsByLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindReviewsByLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindReviewsByLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindReviewsByLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindReviewsByLocationActionProto} FindReviewsByLocationActionProto
             */
            FindReviewsByLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindReviewsByLocationActionProto)
                    return object;
                return new $root.waiternow.common.FindReviewsByLocationActionProto();
            };

            /**
             * Creates a plain object from a FindReviewsByLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {waiternow.common.FindReviewsByLocationActionProto} message FindReviewsByLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindReviewsByLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindReviewsByLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindReviewsByLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindReviewsByLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindReviewsByLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindReviewsByLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindReviewsByLocationActionProto";
            };

            FindReviewsByLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindReviewsByLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.ThumbSignal|null} [thumbSignal] Request thumbSignal
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindReviewsByLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request thumbSignal.
                 * @member {waiternow.common.ThumbSignal} thumbSignal
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.thumbSignal = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindReviewsByLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.thumbSignal != null && Object.hasOwnProperty.call(message, "thumbSignal"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.thumbSignal);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindReviewsByLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindReviewsByLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.thumbSignal = reader.int32();
                                break;
                            }
                        case 3: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.thumbSignal != null && message.hasOwnProperty("thumbSignal"))
                        switch (message.thumbSignal) {
                        default:
                            return "thumbSignal: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindReviewsByLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindReviewsByLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    switch (object.thumbSignal) {
                    default:
                        if (typeof object.thumbSignal === "number") {
                            message.thumbSignal = object.thumbSignal;
                            break;
                        }
                        break;
                    case "THUMB_SIGNAL_UNSPECIFIED":
                    case 0:
                        message.thumbSignal = 0;
                        break;
                    case "THUMB_UP":
                    case 1:
                        message.thumbSignal = 1;
                        break;
                    case "THUMB_DOWN":
                    case 2:
                        message.thumbSignal = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.thumbSignal = options.enums === String ? "THUMB_SIGNAL_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.thumbSignal != null && message.hasOwnProperty("thumbSignal"))
                        object.thumbSignal = options.enums === String ? $root.waiternow.common.ThumbSignal[message.thumbSignal] === undefined ? message.thumbSignal : $root.waiternow.common.ThumbSignal[message.thumbSignal] : message.thumbSignal;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindReviewsByLocationActionProto.Request";
                };

                return Request;
            })();

            FindReviewsByLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindReviewsByLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationReviewsProto|null} [locationReviews] Response locationReviews
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindReviewsByLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationReviews.
                 * @member {waiternow.common.ILocationReviewsProto|null|undefined} locationReviews
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.locationReviews = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindReviewsByLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationReviews != null && Object.hasOwnProperty.call(message, "locationReviews"))
                        $root.waiternow.common.LocationReviewsProto.encode(message.locationReviews, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindReviewsByLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindReviewsByLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationReviews = $root.waiternow.common.LocationReviewsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationReviews != null && message.hasOwnProperty("locationReviews")) {
                        var error = $root.waiternow.common.LocationReviewsProto.verify(message.locationReviews);
                        if (error)
                            return "locationReviews." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindReviewsByLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindReviewsByLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindReviewsByLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindReviewsByLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationReviews != null) {
                        if (typeof object.locationReviews !== "object")
                            throw TypeError(".waiternow.common.FindReviewsByLocationActionProto.Response.locationReviews: object expected");
                        message.locationReviews = $root.waiternow.common.LocationReviewsProto.fromObject(object.locationReviews);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindReviewsByLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationReviews = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationReviews != null && message.hasOwnProperty("locationReviews"))
                        object.locationReviews = $root.waiternow.common.LocationReviewsProto.toObject(message.locationReviews, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindReviewsByLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindReviewsByLocationActionProto.Response";
                };

                return Response;
            })();

            return FindReviewsByLocationActionProto;
        })();

        common.FindDevicesByLocationActionProto = (function() {

            /**
             * Properties of a FindDevicesByLocationActionProto.
             * @memberof waiternow.common
             * @interface IFindDevicesByLocationActionProto
             */

            /**
             * Constructs a new FindDevicesByLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindDevicesByLocationActionProto.
             * @implements IFindDevicesByLocationActionProto
             * @constructor
             * @param {waiternow.common.IFindDevicesByLocationActionProto=} [properties] Properties to set
             */
            function FindDevicesByLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindDevicesByLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {waiternow.common.IFindDevicesByLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindDevicesByLocationActionProto} FindDevicesByLocationActionProto instance
             */
            FindDevicesByLocationActionProto.create = function create(properties) {
                return new FindDevicesByLocationActionProto(properties);
            };

            /**
             * Encodes the specified FindDevicesByLocationActionProto message. Does not implicitly {@link waiternow.common.FindDevicesByLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {waiternow.common.IFindDevicesByLocationActionProto} message FindDevicesByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDevicesByLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindDevicesByLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindDevicesByLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {waiternow.common.IFindDevicesByLocationActionProto} message FindDevicesByLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDevicesByLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindDevicesByLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindDevicesByLocationActionProto} FindDevicesByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDevicesByLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicesByLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindDevicesByLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindDevicesByLocationActionProto} FindDevicesByLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDevicesByLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindDevicesByLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindDevicesByLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindDevicesByLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindDevicesByLocationActionProto} FindDevicesByLocationActionProto
             */
            FindDevicesByLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindDevicesByLocationActionProto)
                    return object;
                return new $root.waiternow.common.FindDevicesByLocationActionProto();
            };

            /**
             * Creates a plain object from a FindDevicesByLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {waiternow.common.FindDevicesByLocationActionProto} message FindDevicesByLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindDevicesByLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindDevicesByLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindDevicesByLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindDevicesByLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindDevicesByLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindDevicesByLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindDevicesByLocationActionProto";
            };

            FindDevicesByLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindDevicesByLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindDevicesByLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindDevicesByLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindDevicesByLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicesByLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDevicesByLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindDevicesByLocationActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.locationId = "";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDevicesByLocationActionProto.Request";
                };

                return Request;
            })();

            FindDevicesByLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindDevicesByLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationDevicesProto|null} [locationDevices] Response locationDevices
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindDevicesByLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response locationDevices.
                 * @member {waiternow.common.ILocationDevicesProto|null|undefined} locationDevices
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.locationDevices = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindDevicesByLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.locationDevices != null && Object.hasOwnProperty.call(message, "locationDevices"))
                        $root.waiternow.common.LocationDevicesProto.encode(message.locationDevices, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindDevicesByLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicesByLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.locationDevices = $root.waiternow.common.LocationDevicesProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.locationDevices != null && message.hasOwnProperty("locationDevices")) {
                        var error = $root.waiternow.common.LocationDevicesProto.verify(message.locationDevices);
                        if (error)
                            return "locationDevices." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDevicesByLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDevicesByLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindDevicesByLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindDevicesByLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.locationDevices != null) {
                        if (typeof object.locationDevices !== "object")
                            throw TypeError(".waiternow.common.FindDevicesByLocationActionProto.Response.locationDevices: object expected");
                        message.locationDevices = $root.waiternow.common.LocationDevicesProto.fromObject(object.locationDevices);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.locationDevices = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.locationDevices != null && message.hasOwnProperty("locationDevices"))
                        object.locationDevices = $root.waiternow.common.LocationDevicesProto.toObject(message.locationDevices, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDevicesByLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDevicesByLocationActionProto.Response";
                };

                return Response;
            })();

            return FindDevicesByLocationActionProto;
        })();

        common.FindDevicesByPointOfServiceActionProto = (function() {

            /**
             * Properties of a FindDevicesByPointOfServiceActionProto.
             * @memberof waiternow.common
             * @interface IFindDevicesByPointOfServiceActionProto
             */

            /**
             * Constructs a new FindDevicesByPointOfServiceActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindDevicesByPointOfServiceActionProto.
             * @implements IFindDevicesByPointOfServiceActionProto
             * @constructor
             * @param {waiternow.common.IFindDevicesByPointOfServiceActionProto=} [properties] Properties to set
             */
            function FindDevicesByPointOfServiceActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindDevicesByPointOfServiceActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {waiternow.common.IFindDevicesByPointOfServiceActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto} FindDevicesByPointOfServiceActionProto instance
             */
            FindDevicesByPointOfServiceActionProto.create = function create(properties) {
                return new FindDevicesByPointOfServiceActionProto(properties);
            };

            /**
             * Encodes the specified FindDevicesByPointOfServiceActionProto message. Does not implicitly {@link waiternow.common.FindDevicesByPointOfServiceActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {waiternow.common.IFindDevicesByPointOfServiceActionProto} message FindDevicesByPointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDevicesByPointOfServiceActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindDevicesByPointOfServiceActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindDevicesByPointOfServiceActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {waiternow.common.IFindDevicesByPointOfServiceActionProto} message FindDevicesByPointOfServiceActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindDevicesByPointOfServiceActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindDevicesByPointOfServiceActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto} FindDevicesByPointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDevicesByPointOfServiceActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicesByPointOfServiceActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindDevicesByPointOfServiceActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto} FindDevicesByPointOfServiceActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindDevicesByPointOfServiceActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindDevicesByPointOfServiceActionProto message.
             * @function verify
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindDevicesByPointOfServiceActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindDevicesByPointOfServiceActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto} FindDevicesByPointOfServiceActionProto
             */
            FindDevicesByPointOfServiceActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindDevicesByPointOfServiceActionProto)
                    return object;
                return new $root.waiternow.common.FindDevicesByPointOfServiceActionProto();
            };

            /**
             * Creates a plain object from a FindDevicesByPointOfServiceActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {waiternow.common.FindDevicesByPointOfServiceActionProto} message FindDevicesByPointOfServiceActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindDevicesByPointOfServiceActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindDevicesByPointOfServiceActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindDevicesByPointOfServiceActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindDevicesByPointOfServiceActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindDevicesByPointOfServiceActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindDevicesByPointOfServiceActionProto";
            };

            FindDevicesByPointOfServiceActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
                 * @interface IRequest
                 * @property {string|null} [pointOfServiceId] Request pointOfServiceId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request pointOfServiceId.
                 * @member {string} pointOfServiceId
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @instance
                 */
                Request.prototype.pointOfServiceId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindDevicesByPointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.pointOfServiceId != null && Object.hasOwnProperty.call(message, "pointOfServiceId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.pointOfServiceId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindDevicesByPointOfServiceActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicesByPointOfServiceActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.pointOfServiceId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        if (!$util.isString(message.pointOfServiceId))
                            return "pointOfServiceId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDevicesByPointOfServiceActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindDevicesByPointOfServiceActionProto.Request();
                    if (object.pointOfServiceId != null)
                        message.pointOfServiceId = String(object.pointOfServiceId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.pointOfServiceId = "";
                    if (message.pointOfServiceId != null && message.hasOwnProperty("pointOfServiceId"))
                        object.pointOfServiceId = message.pointOfServiceId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDevicesByPointOfServiceActionProto.Request";
                };

                return Request;
            })();

            FindDevicesByPointOfServiceActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IPointOfServiceDevicesProto|null} [pointOfServiceDevices] Response pointOfServiceDevices
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response pointOfServiceDevices.
                 * @member {waiternow.common.IPointOfServiceDevicesProto|null|undefined} pointOfServiceDevices
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @instance
                 */
                Response.prototype.pointOfServiceDevices = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindDevicesByPointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.pointOfServiceDevices != null && Object.hasOwnProperty.call(message, "pointOfServiceDevices"))
                        $root.waiternow.common.PointOfServiceDevicesProto.encode(message.pointOfServiceDevices, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindDevicesByPointOfServiceActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindDevicesByPointOfServiceActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.pointOfServiceDevices = $root.waiternow.common.PointOfServiceDevicesProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.pointOfServiceDevices != null && message.hasOwnProperty("pointOfServiceDevices")) {
                        var error = $root.waiternow.common.PointOfServiceDevicesProto.verify(message.pointOfServiceDevices);
                        if (error)
                            return "pointOfServiceDevices." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindDevicesByPointOfServiceActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindDevicesByPointOfServiceActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindDevicesByPointOfServiceActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindDevicesByPointOfServiceActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.pointOfServiceDevices != null) {
                        if (typeof object.pointOfServiceDevices !== "object")
                            throw TypeError(".waiternow.common.FindDevicesByPointOfServiceActionProto.Response.pointOfServiceDevices: object expected");
                        message.pointOfServiceDevices = $root.waiternow.common.PointOfServiceDevicesProto.fromObject(object.pointOfServiceDevices);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {waiternow.common.FindDevicesByPointOfServiceActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.pointOfServiceDevices = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.pointOfServiceDevices != null && message.hasOwnProperty("pointOfServiceDevices"))
                        object.pointOfServiceDevices = $root.waiternow.common.PointOfServiceDevicesProto.toObject(message.pointOfServiceDevices, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindDevicesByPointOfServiceActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindDevicesByPointOfServiceActionProto.Response";
                };

                return Response;
            })();

            return FindDevicesByPointOfServiceActionProto;
        })();

        common.CreateSalespersonActionProto = (function() {

            /**
             * Properties of a CreateSalespersonActionProto.
             * @memberof waiternow.common
             * @interface ICreateSalespersonActionProto
             */

            /**
             * Constructs a new CreateSalespersonActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CreateSalespersonActionProto.
             * @implements ICreateSalespersonActionProto
             * @constructor
             * @param {waiternow.common.ICreateSalespersonActionProto=} [properties] Properties to set
             */
            function CreateSalespersonActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CreateSalespersonActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {waiternow.common.ICreateSalespersonActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CreateSalespersonActionProto} CreateSalespersonActionProto instance
             */
            CreateSalespersonActionProto.create = function create(properties) {
                return new CreateSalespersonActionProto(properties);
            };

            /**
             * Encodes the specified CreateSalespersonActionProto message. Does not implicitly {@link waiternow.common.CreateSalespersonActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {waiternow.common.ICreateSalespersonActionProto} message CreateSalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSalespersonActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CreateSalespersonActionProto message, length delimited. Does not implicitly {@link waiternow.common.CreateSalespersonActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {waiternow.common.ICreateSalespersonActionProto} message CreateSalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateSalespersonActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateSalespersonActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CreateSalespersonActionProto} CreateSalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSalespersonActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateSalespersonActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateSalespersonActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CreateSalespersonActionProto} CreateSalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateSalespersonActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateSalespersonActionProto message.
             * @function verify
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateSalespersonActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CreateSalespersonActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CreateSalespersonActionProto} CreateSalespersonActionProto
             */
            CreateSalespersonActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CreateSalespersonActionProto)
                    return object;
                return new $root.waiternow.common.CreateSalespersonActionProto();
            };

            /**
             * Creates a plain object from a CreateSalespersonActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {waiternow.common.CreateSalespersonActionProto} message CreateSalespersonActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateSalespersonActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreateSalespersonActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateSalespersonActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateSalespersonActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CreateSalespersonActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateSalespersonActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CreateSalespersonActionProto";
            };

            CreateSalespersonActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CreateSalespersonActionProto
                 * @interface IRequest
                 * @property {waiternow.common.ISalespersonProto|null} [salesperson] Request salesperson
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CreateSalespersonActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CreateSalespersonActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request salesperson.
                 * @member {waiternow.common.ISalespersonProto|null|undefined} salesperson
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @instance
                 */
                Request.prototype.salesperson = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CreateSalespersonActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CreateSalespersonActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.salesperson != null && Object.hasOwnProperty.call(message, "salesperson"))
                        $root.waiternow.common.SalespersonProto.encode(message.salesperson, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CreateSalespersonActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateSalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateSalespersonActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.salesperson = $root.waiternow.common.SalespersonProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateSalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.salesperson != null && message.hasOwnProperty("salesperson")) {
                        var error = $root.waiternow.common.SalespersonProto.verify(message.salesperson);
                        if (error)
                            return "salesperson." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateSalespersonActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateSalespersonActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CreateSalespersonActionProto.Request();
                    if (object.salesperson != null) {
                        if (typeof object.salesperson !== "object")
                            throw TypeError(".waiternow.common.CreateSalespersonActionProto.Request.salesperson: object expected");
                        message.salesperson = $root.waiternow.common.SalespersonProto.fromObject(object.salesperson);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.salesperson = null;
                    if (message.salesperson != null && message.hasOwnProperty("salesperson"))
                        object.salesperson = $root.waiternow.common.SalespersonProto.toObject(message.salesperson, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateSalespersonActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateSalespersonActionProto.Request";
                };

                return Request;
            })();

            CreateSalespersonActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CreateSalespersonActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ISalespersonProto|null} [salesperson] Response salesperson
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CreateSalespersonActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CreateSalespersonActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response salesperson.
                 * @member {waiternow.common.ISalespersonProto|null|undefined} salesperson
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @instance
                 */
                Response.prototype.salesperson = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CreateSalespersonActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CreateSalespersonActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.salesperson != null && Object.hasOwnProperty.call(message, "salesperson"))
                        $root.waiternow.common.SalespersonProto.encode(message.salesperson, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CreateSalespersonActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateSalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateSalespersonActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.salesperson = $root.waiternow.common.SalespersonProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateSalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.salesperson != null && message.hasOwnProperty("salesperson")) {
                        var error = $root.waiternow.common.SalespersonProto.verify(message.salesperson);
                        if (error)
                            return "salesperson." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateSalespersonActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateSalespersonActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CreateSalespersonActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CreateSalespersonActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.salesperson != null) {
                        if (typeof object.salesperson !== "object")
                            throw TypeError(".waiternow.common.CreateSalespersonActionProto.Response.salesperson: object expected");
                        message.salesperson = $root.waiternow.common.SalespersonProto.fromObject(object.salesperson);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateSalespersonActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.salesperson = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.salesperson != null && message.hasOwnProperty("salesperson"))
                        object.salesperson = $root.waiternow.common.SalespersonProto.toObject(message.salesperson, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateSalespersonActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateSalespersonActionProto.Response";
                };

                return Response;
            })();

            return CreateSalespersonActionProto;
        })();

        common.UpdateSalespersonActionProto = (function() {

            /**
             * Properties of an UpdateSalespersonActionProto.
             * @memberof waiternow.common
             * @interface IUpdateSalespersonActionProto
             */

            /**
             * Constructs a new UpdateSalespersonActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateSalespersonActionProto.
             * @implements IUpdateSalespersonActionProto
             * @constructor
             * @param {waiternow.common.IUpdateSalespersonActionProto=} [properties] Properties to set
             */
            function UpdateSalespersonActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateSalespersonActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {waiternow.common.IUpdateSalespersonActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateSalespersonActionProto} UpdateSalespersonActionProto instance
             */
            UpdateSalespersonActionProto.create = function create(properties) {
                return new UpdateSalespersonActionProto(properties);
            };

            /**
             * Encodes the specified UpdateSalespersonActionProto message. Does not implicitly {@link waiternow.common.UpdateSalespersonActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {waiternow.common.IUpdateSalespersonActionProto} message UpdateSalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSalespersonActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateSalespersonActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateSalespersonActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {waiternow.common.IUpdateSalespersonActionProto} message UpdateSalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateSalespersonActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateSalespersonActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateSalespersonActionProto} UpdateSalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSalespersonActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateSalespersonActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateSalespersonActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateSalespersonActionProto} UpdateSalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateSalespersonActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateSalespersonActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateSalespersonActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateSalespersonActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateSalespersonActionProto} UpdateSalespersonActionProto
             */
            UpdateSalespersonActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateSalespersonActionProto)
                    return object;
                return new $root.waiternow.common.UpdateSalespersonActionProto();
            };

            /**
             * Creates a plain object from an UpdateSalespersonActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {waiternow.common.UpdateSalespersonActionProto} message UpdateSalespersonActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateSalespersonActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateSalespersonActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateSalespersonActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateSalespersonActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateSalespersonActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateSalespersonActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateSalespersonActionProto";
            };

            UpdateSalespersonActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateSalespersonActionProto
                 * @interface IRequest
                 * @property {waiternow.common.ISalespersonProto|null} [salesperson] Request salesperson
                 * @property {Array.<waiternow.common.UpdateSalespersonActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateSalespersonActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateSalespersonActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request salesperson.
                 * @member {waiternow.common.ISalespersonProto|null|undefined} salesperson
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @instance
                 */
                Request.prototype.salesperson = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateSalespersonActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateSalespersonActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.salesperson != null && Object.hasOwnProperty.call(message, "salesperson"))
                        $root.waiternow.common.SalespersonProto.encode(message.salesperson, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateSalespersonActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateSalespersonActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.salesperson = $root.waiternow.common.SalespersonProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.salesperson != null && message.hasOwnProperty("salesperson")) {
                        var error = $root.waiternow.common.SalespersonProto.verify(message.salesperson);
                        if (error)
                            return "salesperson." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateSalespersonActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdateSalespersonActionProto.Request();
                    if (object.salesperson != null) {
                        if (typeof object.salesperson !== "object")
                            throw TypeError(".waiternow.common.UpdateSalespersonActionProto.Request.salesperson: object expected");
                        message.salesperson = $root.waiternow.common.SalespersonProto.fromObject(object.salesperson);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateSalespersonActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            case "PHONE_NUMBER":
                            case 1:
                                message.fieldsToRemove[i] = 1;
                                break;
                            case "STATUS_STATE":
                            case 2:
                                message.fieldsToRemove[i] = 2;
                                break;
                            case "SETTINGS_CODE":
                            case 3:
                                message.fieldsToRemove[i] = 3;
                                break;
                            case "COMMISSION_PER_PAID_ORDER":
                            case 4:
                                message.fieldsToRemove[i] = 4;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldsToRemove = [];
                    if (options.defaults)
                        object.salesperson = null;
                    if (message.salesperson != null && message.hasOwnProperty("salesperson"))
                        object.salesperson = $root.waiternow.common.SalespersonProto.toObject(message.salesperson, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateSalespersonActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdateSalespersonActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateSalespersonActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdateSalespersonActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 * @property {number} PHONE_NUMBER=1 PHONE_NUMBER value
                 * @property {number} STATUS_STATE=2 STATUS_STATE value
                 * @property {number} SETTINGS_CODE=3 SETTINGS_CODE value
                 * @property {number} COMMISSION_PER_PAID_ORDER=4 COMMISSION_PER_PAID_ORDER value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "PHONE_NUMBER"] = 1;
                    values[valuesById[2] = "STATUS_STATE"] = 2;
                    values[valuesById[3] = "SETTINGS_CODE"] = 3;
                    values[valuesById[4] = "COMMISSION_PER_PAID_ORDER"] = 4;
                    return values;
                })();

                return Request;
            })();

            UpdateSalespersonActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateSalespersonActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateSalespersonActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateSalespersonActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateSalespersonActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateSalespersonActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateSalespersonActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateSalespersonActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateSalespersonActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateSalespersonActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateSalespersonActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateSalespersonActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateSalespersonActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateSalespersonActionProto.Response";
                };

                return Response;
            })();

            return UpdateSalespersonActionProto;
        })();

        common.FindAllSalespersonsActionProto = (function() {

            /**
             * Properties of a FindAllSalespersonsActionProto.
             * @memberof waiternow.common
             * @interface IFindAllSalespersonsActionProto
             */

            /**
             * Constructs a new FindAllSalespersonsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindAllSalespersonsActionProto.
             * @implements IFindAllSalespersonsActionProto
             * @constructor
             * @param {waiternow.common.IFindAllSalespersonsActionProto=} [properties] Properties to set
             */
            function FindAllSalespersonsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindAllSalespersonsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {waiternow.common.IFindAllSalespersonsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindAllSalespersonsActionProto} FindAllSalespersonsActionProto instance
             */
            FindAllSalespersonsActionProto.create = function create(properties) {
                return new FindAllSalespersonsActionProto(properties);
            };

            /**
             * Encodes the specified FindAllSalespersonsActionProto message. Does not implicitly {@link waiternow.common.FindAllSalespersonsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {waiternow.common.IFindAllSalespersonsActionProto} message FindAllSalespersonsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAllSalespersonsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindAllSalespersonsActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindAllSalespersonsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {waiternow.common.IFindAllSalespersonsActionProto} message FindAllSalespersonsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindAllSalespersonsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindAllSalespersonsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindAllSalespersonsActionProto} FindAllSalespersonsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAllSalespersonsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAllSalespersonsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindAllSalespersonsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindAllSalespersonsActionProto} FindAllSalespersonsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindAllSalespersonsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindAllSalespersonsActionProto message.
             * @function verify
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindAllSalespersonsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindAllSalespersonsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindAllSalespersonsActionProto} FindAllSalespersonsActionProto
             */
            FindAllSalespersonsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindAllSalespersonsActionProto)
                    return object;
                return new $root.waiternow.common.FindAllSalespersonsActionProto();
            };

            /**
             * Creates a plain object from a FindAllSalespersonsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {waiternow.common.FindAllSalespersonsActionProto} message FindAllSalespersonsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindAllSalespersonsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindAllSalespersonsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindAllSalespersonsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindAllSalespersonsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindAllSalespersonsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindAllSalespersonsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindAllSalespersonsActionProto";
            };

            FindAllSalespersonsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindAllSalespersonsActionProto
                 * @interface IRequest
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindAllSalespersonsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindAllSalespersonsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindAllSalespersonsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAllSalespersonsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindAllSalespersonsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindAllSalespersonsActionProto.Request();
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.continuationToken = "";
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindAllSalespersonsActionProto.Request";
                };

                return Request;
            })();

            FindAllSalespersonsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindAllSalespersonsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ISalespersonsProto|null} [salespersons] Response salespersons
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindAllSalespersonsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response salespersons.
                 * @member {waiternow.common.ISalespersonsProto|null|undefined} salespersons
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @instance
                 */
                Response.prototype.salespersons = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindAllSalespersonsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.salespersons != null && Object.hasOwnProperty.call(message, "salespersons"))
                        $root.waiternow.common.SalespersonsProto.encode(message.salespersons, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindAllSalespersonsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindAllSalespersonsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.salespersons = $root.waiternow.common.SalespersonsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.salespersons != null && message.hasOwnProperty("salespersons")) {
                        var error = $root.waiternow.common.SalespersonsProto.verify(message.salespersons);
                        if (error)
                            return "salespersons." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindAllSalespersonsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindAllSalespersonsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindAllSalespersonsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindAllSalespersonsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.salespersons != null) {
                        if (typeof object.salespersons !== "object")
                            throw TypeError(".waiternow.common.FindAllSalespersonsActionProto.Response.salespersons: object expected");
                        message.salespersons = $root.waiternow.common.SalespersonsProto.fromObject(object.salespersons);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindAllSalespersonsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.salespersons = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.salespersons != null && message.hasOwnProperty("salespersons"))
                        object.salespersons = $root.waiternow.common.SalespersonsProto.toObject(message.salespersons, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindAllSalespersonsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindAllSalespersonsActionProto.Response";
                };

                return Response;
            })();

            return FindAllSalespersonsActionProto;
        })();

        common.AssociateSalespersonToLocationActionProto = (function() {

            /**
             * Properties of an AssociateSalespersonToLocationActionProto.
             * @memberof waiternow.common
             * @interface IAssociateSalespersonToLocationActionProto
             */

            /**
             * Constructs a new AssociateSalespersonToLocationActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an AssociateSalespersonToLocationActionProto.
             * @implements IAssociateSalespersonToLocationActionProto
             * @constructor
             * @param {waiternow.common.IAssociateSalespersonToLocationActionProto=} [properties] Properties to set
             */
            function AssociateSalespersonToLocationActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new AssociateSalespersonToLocationActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {waiternow.common.IAssociateSalespersonToLocationActionProto=} [properties] Properties to set
             * @returns {waiternow.common.AssociateSalespersonToLocationActionProto} AssociateSalespersonToLocationActionProto instance
             */
            AssociateSalespersonToLocationActionProto.create = function create(properties) {
                return new AssociateSalespersonToLocationActionProto(properties);
            };

            /**
             * Encodes the specified AssociateSalespersonToLocationActionProto message. Does not implicitly {@link waiternow.common.AssociateSalespersonToLocationActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {waiternow.common.IAssociateSalespersonToLocationActionProto} message AssociateSalespersonToLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AssociateSalespersonToLocationActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified AssociateSalespersonToLocationActionProto message, length delimited. Does not implicitly {@link waiternow.common.AssociateSalespersonToLocationActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {waiternow.common.IAssociateSalespersonToLocationActionProto} message AssociateSalespersonToLocationActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            AssociateSalespersonToLocationActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an AssociateSalespersonToLocationActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.AssociateSalespersonToLocationActionProto} AssociateSalespersonToLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AssociateSalespersonToLocationActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AssociateSalespersonToLocationActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an AssociateSalespersonToLocationActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.AssociateSalespersonToLocationActionProto} AssociateSalespersonToLocationActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            AssociateSalespersonToLocationActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an AssociateSalespersonToLocationActionProto message.
             * @function verify
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            AssociateSalespersonToLocationActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an AssociateSalespersonToLocationActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.AssociateSalespersonToLocationActionProto} AssociateSalespersonToLocationActionProto
             */
            AssociateSalespersonToLocationActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.AssociateSalespersonToLocationActionProto)
                    return object;
                return new $root.waiternow.common.AssociateSalespersonToLocationActionProto();
            };

            /**
             * Creates a plain object from an AssociateSalespersonToLocationActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {waiternow.common.AssociateSalespersonToLocationActionProto} message AssociateSalespersonToLocationActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            AssociateSalespersonToLocationActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this AssociateSalespersonToLocationActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            AssociateSalespersonToLocationActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for AssociateSalespersonToLocationActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            AssociateSalespersonToLocationActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.AssociateSalespersonToLocationActionProto";
            };

            AssociateSalespersonToLocationActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
                 * @interface IRequest
                 * @property {string|null} [salespersonId] Request salespersonId
                 * @property {string|null} [locationId] Request locationId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request salespersonId.
                 * @member {string} salespersonId
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.salespersonId = "";

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.AssociateSalespersonToLocationActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.salespersonId != null && Object.hasOwnProperty.call(message, "salespersonId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.salespersonId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.AssociateSalespersonToLocationActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AssociateSalespersonToLocationActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.salespersonId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        if (!$util.isString(message.salespersonId))
                            return "salespersonId: string expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AssociateSalespersonToLocationActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.AssociateSalespersonToLocationActionProto.Request();
                    if (object.salespersonId != null)
                        message.salespersonId = String(object.salespersonId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.salespersonId = "";
                        object.locationId = "";
                    }
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        object.salespersonId = message.salespersonId;
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AssociateSalespersonToLocationActionProto.Request";
                };

                return Request;
            })();

            AssociateSalespersonToLocationActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.AssociateSalespersonToLocationActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.AssociateSalespersonToLocationActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.AssociateSalespersonToLocationActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.AssociateSalespersonToLocationActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.AssociateSalespersonToLocationActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.AssociateSalespersonToLocationActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.AssociateSalespersonToLocationActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {waiternow.common.AssociateSalespersonToLocationActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.AssociateSalespersonToLocationActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.AssociateSalespersonToLocationActionProto.Response";
                };

                return Response;
            })();

            return AssociateSalespersonToLocationActionProto;
        })();

        common.UpdateBillActionProto = (function() {

            /**
             * Properties of an UpdateBillActionProto.
             * @memberof waiternow.common
             * @interface IUpdateBillActionProto
             */

            /**
             * Constructs a new UpdateBillActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateBillActionProto.
             * @implements IUpdateBillActionProto
             * @constructor
             * @param {waiternow.common.IUpdateBillActionProto=} [properties] Properties to set
             */
            function UpdateBillActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateBillActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {waiternow.common.IUpdateBillActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateBillActionProto} UpdateBillActionProto instance
             */
            UpdateBillActionProto.create = function create(properties) {
                return new UpdateBillActionProto(properties);
            };

            /**
             * Encodes the specified UpdateBillActionProto message. Does not implicitly {@link waiternow.common.UpdateBillActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {waiternow.common.IUpdateBillActionProto} message UpdateBillActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBillActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateBillActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateBillActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {waiternow.common.IUpdateBillActionProto} message UpdateBillActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateBillActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateBillActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateBillActionProto} UpdateBillActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBillActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBillActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateBillActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateBillActionProto} UpdateBillActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateBillActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateBillActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateBillActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateBillActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateBillActionProto} UpdateBillActionProto
             */
            UpdateBillActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateBillActionProto)
                    return object;
                return new $root.waiternow.common.UpdateBillActionProto();
            };

            /**
             * Creates a plain object from an UpdateBillActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {waiternow.common.UpdateBillActionProto} message UpdateBillActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateBillActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateBillActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateBillActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateBillActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateBillActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateBillActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateBillActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateBillActionProto";
            };

            UpdateBillActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateBillActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IBillProto|null} [bill] Request bill
                 * @property {Array.<waiternow.common.UpdateBillActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateBillActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateBillActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request bill.
                 * @member {waiternow.common.IBillProto|null|undefined} bill
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @instance
                 */
                Request.prototype.bill = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateBillActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBillActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateBillActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.bill != null && Object.hasOwnProperty.call(message, "bill"))
                        $root.waiternow.common.BillProto.encode(message.bill, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateBillActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBillActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBillActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.bill = $root.waiternow.common.BillProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBillActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.bill != null && message.hasOwnProperty("bill")) {
                        var error = $root.waiternow.common.BillProto.verify(message.bill);
                        if (error)
                            return "bill." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBillActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBillActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdateBillActionProto.Request();
                    if (object.bill != null) {
                        if (typeof object.bill !== "object")
                            throw TypeError(".waiternow.common.UpdateBillActionProto.Request.bill: object expected");
                        message.bill = $root.waiternow.common.BillProto.fromObject(object.bill);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateBillActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldsToRemove = [];
                    if (options.defaults)
                        object.bill = null;
                    if (message.bill != null && message.hasOwnProperty("bill"))
                        object.bill = $root.waiternow.common.BillProto.toObject(message.bill, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateBillActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdateBillActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBillActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBillActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdateBillActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    return values;
                })();

                return Request;
            })();

            UpdateBillActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateBillActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateBillActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateBillActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateBillActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateBillActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateBillActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateBillActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateBillActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateBillActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateBillActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateBillActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateBillActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateBillActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateBillActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateBillActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateBillActionProto.Response";
                };

                return Response;
            })();

            return UpdateBillActionProto;
        })();

        common.FindBillsByBusinessActionProto = (function() {

            /**
             * Properties of a FindBillsByBusinessActionProto.
             * @memberof waiternow.common
             * @interface IFindBillsByBusinessActionProto
             */

            /**
             * Constructs a new FindBillsByBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindBillsByBusinessActionProto.
             * @implements IFindBillsByBusinessActionProto
             * @constructor
             * @param {waiternow.common.IFindBillsByBusinessActionProto=} [properties] Properties to set
             */
            function FindBillsByBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindBillsByBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindBillsByBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindBillsByBusinessActionProto} FindBillsByBusinessActionProto instance
             */
            FindBillsByBusinessActionProto.create = function create(properties) {
                return new FindBillsByBusinessActionProto(properties);
            };

            /**
             * Encodes the specified FindBillsByBusinessActionProto message. Does not implicitly {@link waiternow.common.FindBillsByBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindBillsByBusinessActionProto} message FindBillsByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindBillsByBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindBillsByBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindBillsByBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindBillsByBusinessActionProto} message FindBillsByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindBillsByBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindBillsByBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindBillsByBusinessActionProto} FindBillsByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindBillsByBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBillsByBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindBillsByBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindBillsByBusinessActionProto} FindBillsByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindBillsByBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindBillsByBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindBillsByBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindBillsByBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindBillsByBusinessActionProto} FindBillsByBusinessActionProto
             */
            FindBillsByBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindBillsByBusinessActionProto)
                    return object;
                return new $root.waiternow.common.FindBillsByBusinessActionProto();
            };

            /**
             * Creates a plain object from a FindBillsByBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.FindBillsByBusinessActionProto} message FindBillsByBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindBillsByBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindBillsByBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindBillsByBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindBillsByBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindBillsByBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindBillsByBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindBillsByBusinessActionProto";
            };

            FindBillsByBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindBillsByBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindBillsByBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindBillsByBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindBillsByBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBillsByBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindBillsByBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindBillsByBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindBillsByBusinessActionProto.Request";
                };

                return Request;
            })();

            FindBillsByBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindBillsByBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IBusinessBillsProto|null} [businessBills] Response businessBills
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindBillsByBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response businessBills.
                 * @member {waiternow.common.IBusinessBillsProto|null|undefined} businessBills
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.businessBills = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindBillsByBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.businessBills != null && Object.hasOwnProperty.call(message, "businessBills"))
                        $root.waiternow.common.BusinessBillsProto.encode(message.businessBills, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindBillsByBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindBillsByBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.businessBills = $root.waiternow.common.BusinessBillsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.businessBills != null && message.hasOwnProperty("businessBills")) {
                        var error = $root.waiternow.common.BusinessBillsProto.verify(message.businessBills);
                        if (error)
                            return "businessBills." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindBillsByBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindBillsByBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindBillsByBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindBillsByBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.businessBills != null) {
                        if (typeof object.businessBills !== "object")
                            throw TypeError(".waiternow.common.FindBillsByBusinessActionProto.Response.businessBills: object expected");
                        message.businessBills = $root.waiternow.common.BusinessBillsProto.fromObject(object.businessBills);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindBillsByBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.businessBills = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.businessBills != null && message.hasOwnProperty("businessBills"))
                        object.businessBills = $root.waiternow.common.BusinessBillsProto.toObject(message.businessBills, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindBillsByBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindBillsByBusinessActionProto.Response";
                };

                return Response;
            })();

            return FindBillsByBusinessActionProto;
        })();

        common.FindPendingBillsByBusinessActionProto = (function() {

            /**
             * Properties of a FindPendingBillsByBusinessActionProto.
             * @memberof waiternow.common
             * @interface IFindPendingBillsByBusinessActionProto
             */

            /**
             * Constructs a new FindPendingBillsByBusinessActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindPendingBillsByBusinessActionProto.
             * @implements IFindPendingBillsByBusinessActionProto
             * @constructor
             * @param {waiternow.common.IFindPendingBillsByBusinessActionProto=} [properties] Properties to set
             */
            function FindPendingBillsByBusinessActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindPendingBillsByBusinessActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindPendingBillsByBusinessActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindPendingBillsByBusinessActionProto} FindPendingBillsByBusinessActionProto instance
             */
            FindPendingBillsByBusinessActionProto.create = function create(properties) {
                return new FindPendingBillsByBusinessActionProto(properties);
            };

            /**
             * Encodes the specified FindPendingBillsByBusinessActionProto message. Does not implicitly {@link waiternow.common.FindPendingBillsByBusinessActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindPendingBillsByBusinessActionProto} message FindPendingBillsByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPendingBillsByBusinessActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindPendingBillsByBusinessActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindPendingBillsByBusinessActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.IFindPendingBillsByBusinessActionProto} message FindPendingBillsByBusinessActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPendingBillsByBusinessActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindPendingBillsByBusinessActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindPendingBillsByBusinessActionProto} FindPendingBillsByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPendingBillsByBusinessActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPendingBillsByBusinessActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindPendingBillsByBusinessActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindPendingBillsByBusinessActionProto} FindPendingBillsByBusinessActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPendingBillsByBusinessActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindPendingBillsByBusinessActionProto message.
             * @function verify
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindPendingBillsByBusinessActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindPendingBillsByBusinessActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindPendingBillsByBusinessActionProto} FindPendingBillsByBusinessActionProto
             */
            FindPendingBillsByBusinessActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindPendingBillsByBusinessActionProto)
                    return object;
                return new $root.waiternow.common.FindPendingBillsByBusinessActionProto();
            };

            /**
             * Creates a plain object from a FindPendingBillsByBusinessActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {waiternow.common.FindPendingBillsByBusinessActionProto} message FindPendingBillsByBusinessActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindPendingBillsByBusinessActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindPendingBillsByBusinessActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindPendingBillsByBusinessActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindPendingBillsByBusinessActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindPendingBillsByBusinessActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindPendingBillsByBusinessActionProto";
            };

            FindPendingBillsByBusinessActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindPendingBillsByBusinessActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindPendingBillsByBusinessActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPendingBillsByBusinessActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPendingBillsByBusinessActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindPendingBillsByBusinessActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.businessId = "";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPendingBillsByBusinessActionProto.Request";
                };

                return Request;
            })();

            FindPendingBillsByBusinessActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IBusinessPendingBillsProto|null} [businessBills] Response businessBills
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response businessBills.
                 * @member {waiternow.common.IBusinessPendingBillsProto|null|undefined} businessBills
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @instance
                 */
                Response.prototype.businessBills = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindPendingBillsByBusinessActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.businessBills != null && Object.hasOwnProperty.call(message, "businessBills"))
                        $root.waiternow.common.BusinessPendingBillsProto.encode(message.businessBills, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindPendingBillsByBusinessActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPendingBillsByBusinessActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.businessBills = $root.waiternow.common.BusinessPendingBillsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.businessBills != null && message.hasOwnProperty("businessBills")) {
                        var error = $root.waiternow.common.BusinessPendingBillsProto.verify(message.businessBills);
                        if (error)
                            return "businessBills." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPendingBillsByBusinessActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPendingBillsByBusinessActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindPendingBillsByBusinessActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindPendingBillsByBusinessActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.businessBills != null) {
                        if (typeof object.businessBills !== "object")
                            throw TypeError(".waiternow.common.FindPendingBillsByBusinessActionProto.Response.businessBills: object expected");
                        message.businessBills = $root.waiternow.common.BusinessPendingBillsProto.fromObject(object.businessBills);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPendingBillsByBusinessActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.businessBills = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.businessBills != null && message.hasOwnProperty("businessBills"))
                        object.businessBills = $root.waiternow.common.BusinessPendingBillsProto.toObject(message.businessBills, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPendingBillsByBusinessActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPendingBillsByBusinessActionProto.Response";
                };

                return Response;
            })();

            return FindPendingBillsByBusinessActionProto;
        })();

        common.MarkBillAsPaidActionProto = (function() {

            /**
             * Properties of a MarkBillAsPaidActionProto.
             * @memberof waiternow.common
             * @interface IMarkBillAsPaidActionProto
             */

            /**
             * Constructs a new MarkBillAsPaidActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a MarkBillAsPaidActionProto.
             * @implements IMarkBillAsPaidActionProto
             * @constructor
             * @param {waiternow.common.IMarkBillAsPaidActionProto=} [properties] Properties to set
             */
            function MarkBillAsPaidActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new MarkBillAsPaidActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {waiternow.common.IMarkBillAsPaidActionProto=} [properties] Properties to set
             * @returns {waiternow.common.MarkBillAsPaidActionProto} MarkBillAsPaidActionProto instance
             */
            MarkBillAsPaidActionProto.create = function create(properties) {
                return new MarkBillAsPaidActionProto(properties);
            };

            /**
             * Encodes the specified MarkBillAsPaidActionProto message. Does not implicitly {@link waiternow.common.MarkBillAsPaidActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {waiternow.common.IMarkBillAsPaidActionProto} message MarkBillAsPaidActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkBillAsPaidActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified MarkBillAsPaidActionProto message, length delimited. Does not implicitly {@link waiternow.common.MarkBillAsPaidActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {waiternow.common.IMarkBillAsPaidActionProto} message MarkBillAsPaidActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            MarkBillAsPaidActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a MarkBillAsPaidActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.MarkBillAsPaidActionProto} MarkBillAsPaidActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkBillAsPaidActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkBillAsPaidActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a MarkBillAsPaidActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.MarkBillAsPaidActionProto} MarkBillAsPaidActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            MarkBillAsPaidActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a MarkBillAsPaidActionProto message.
             * @function verify
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            MarkBillAsPaidActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a MarkBillAsPaidActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.MarkBillAsPaidActionProto} MarkBillAsPaidActionProto
             */
            MarkBillAsPaidActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.MarkBillAsPaidActionProto)
                    return object;
                return new $root.waiternow.common.MarkBillAsPaidActionProto();
            };

            /**
             * Creates a plain object from a MarkBillAsPaidActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {waiternow.common.MarkBillAsPaidActionProto} message MarkBillAsPaidActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            MarkBillAsPaidActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this MarkBillAsPaidActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            MarkBillAsPaidActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for MarkBillAsPaidActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.MarkBillAsPaidActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            MarkBillAsPaidActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.MarkBillAsPaidActionProto";
            };

            MarkBillAsPaidActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.MarkBillAsPaidActionProto
                 * @interface IRequest
                 * @property {string|null} [billId] Request billId
                 * @property {string|null} [paymentId] Request paymentId
                 * @property {string|null} [businessId] Request businessId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.MarkBillAsPaidActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request billId.
                 * @member {string} billId
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.billId = "";

                /**
                 * Request paymentId.
                 * @member {string} paymentId
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.paymentId = "";

                /**
                 * Request businessId.
                 * @member {string} businessId
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.MarkBillAsPaidActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.billId != null && Object.hasOwnProperty.call(message, "billId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.billId);
                    if (message.paymentId != null && Object.hasOwnProperty.call(message, "paymentId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.paymentId);
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 3, wireType 2 =*/26).string(message.businessId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.MarkBillAsPaidActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkBillAsPaidActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.billId = reader.string();
                                break;
                            }
                        case 2: {
                                message.paymentId = reader.string();
                                break;
                            }
                        case 3: {
                                message.businessId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.billId != null && message.hasOwnProperty("billId"))
                        if (!$util.isString(message.billId))
                            return "billId: string expected";
                    if (message.paymentId != null && message.hasOwnProperty("paymentId"))
                        if (!$util.isString(message.paymentId))
                            return "paymentId: string expected";
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkBillAsPaidActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.MarkBillAsPaidActionProto.Request();
                    if (object.billId != null)
                        message.billId = String(object.billId);
                    if (object.paymentId != null)
                        message.paymentId = String(object.paymentId);
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.billId = "";
                        object.paymentId = "";
                        object.businessId = "";
                    }
                    if (message.billId != null && message.hasOwnProperty("billId"))
                        object.billId = message.billId;
                    if (message.paymentId != null && message.hasOwnProperty("paymentId"))
                        object.paymentId = message.paymentId;
                    if (message.businessId != null && message.hasOwnProperty("businessId"))
                        object.businessId = message.businessId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkBillAsPaidActionProto.Request";
                };

                return Request;
            })();

            MarkBillAsPaidActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.MarkBillAsPaidActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.MarkBillAsPaidActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.MarkBillAsPaidActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.MarkBillAsPaidActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.MarkBillAsPaidActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.MarkBillAsPaidActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.MarkBillAsPaidActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.MarkBillAsPaidActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.MarkBillAsPaidActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {waiternow.common.MarkBillAsPaidActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.MarkBillAsPaidActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.MarkBillAsPaidActionProto.Response";
                };

                return Response;
            })();

            return MarkBillAsPaidActionProto;
        })();

        common.UpdatePaycheckActionProto = (function() {

            /**
             * Properties of an UpdatePaycheckActionProto.
             * @memberof waiternow.common
             * @interface IUpdatePaycheckActionProto
             */

            /**
             * Constructs a new UpdatePaycheckActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdatePaycheckActionProto.
             * @implements IUpdatePaycheckActionProto
             * @constructor
             * @param {waiternow.common.IUpdatePaycheckActionProto=} [properties] Properties to set
             */
            function UpdatePaycheckActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdatePaycheckActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {waiternow.common.IUpdatePaycheckActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdatePaycheckActionProto} UpdatePaycheckActionProto instance
             */
            UpdatePaycheckActionProto.create = function create(properties) {
                return new UpdatePaycheckActionProto(properties);
            };

            /**
             * Encodes the specified UpdatePaycheckActionProto message. Does not implicitly {@link waiternow.common.UpdatePaycheckActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {waiternow.common.IUpdatePaycheckActionProto} message UpdatePaycheckActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePaycheckActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdatePaycheckActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdatePaycheckActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {waiternow.common.IUpdatePaycheckActionProto} message UpdatePaycheckActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdatePaycheckActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdatePaycheckActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdatePaycheckActionProto} UpdatePaycheckActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePaycheckActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdatePaycheckActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdatePaycheckActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdatePaycheckActionProto} UpdatePaycheckActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdatePaycheckActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdatePaycheckActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdatePaycheckActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdatePaycheckActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdatePaycheckActionProto} UpdatePaycheckActionProto
             */
            UpdatePaycheckActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdatePaycheckActionProto)
                    return object;
                return new $root.waiternow.common.UpdatePaycheckActionProto();
            };

            /**
             * Creates a plain object from an UpdatePaycheckActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {waiternow.common.UpdatePaycheckActionProto} message UpdatePaycheckActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdatePaycheckActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdatePaycheckActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdatePaycheckActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdatePaycheckActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdatePaycheckActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdatePaycheckActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdatePaycheckActionProto";
            };

            UpdatePaycheckActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdatePaycheckActionProto
                 * @interface IRequest
                 * @property {waiternow.common.IPaycheckProto|null} [paycheck] Request paycheck
                 * @property {Array.<waiternow.common.UpdatePaycheckActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdatePaycheckActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdatePaycheckActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request paycheck.
                 * @member {waiternow.common.IPaycheckProto|null|undefined} paycheck
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @instance
                 */
                Request.prototype.paycheck = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdatePaycheckActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdatePaycheckActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.paycheck != null && Object.hasOwnProperty.call(message, "paycheck"))
                        $root.waiternow.common.PaycheckProto.encode(message.paycheck, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdatePaycheckActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdatePaycheckActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.paycheck = $root.waiternow.common.PaycheckProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.paycheck != null && message.hasOwnProperty("paycheck")) {
                        var error = $root.waiternow.common.PaycheckProto.verify(message.paycheck);
                        if (error)
                            return "paycheck." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdatePaycheckActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdatePaycheckActionProto.Request();
                    if (object.paycheck != null) {
                        if (typeof object.paycheck !== "object")
                            throw TypeError(".waiternow.common.UpdatePaycheckActionProto.Request.paycheck: object expected");
                        message.paycheck = $root.waiternow.common.PaycheckProto.fromObject(object.paycheck);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdatePaycheckActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldsToRemove = [];
                    if (options.defaults)
                        object.paycheck = null;
                    if (message.paycheck != null && message.hasOwnProperty("paycheck"))
                        object.paycheck = $root.waiternow.common.PaycheckProto.toObject(message.paycheck, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdatePaycheckActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdatePaycheckActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdatePaycheckActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdatePaycheckActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    return values;
                })();

                return Request;
            })();

            UpdatePaycheckActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdatePaycheckActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdatePaycheckActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdatePaycheckActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdatePaycheckActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdatePaycheckActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdatePaycheckActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdatePaycheckActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdatePaycheckActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdatePaycheckActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdatePaycheckActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdatePaycheckActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdatePaycheckActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdatePaycheckActionProto.Response";
                };

                return Response;
            })();

            return UpdatePaycheckActionProto;
        })();

        common.FindPaychecksBySalespersonActionProto = (function() {

            /**
             * Properties of a FindPaychecksBySalespersonActionProto.
             * @memberof waiternow.common
             * @interface IFindPaychecksBySalespersonActionProto
             */

            /**
             * Constructs a new FindPaychecksBySalespersonActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindPaychecksBySalespersonActionProto.
             * @implements IFindPaychecksBySalespersonActionProto
             * @constructor
             * @param {waiternow.common.IFindPaychecksBySalespersonActionProto=} [properties] Properties to set
             */
            function FindPaychecksBySalespersonActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindPaychecksBySalespersonActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {waiternow.common.IFindPaychecksBySalespersonActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindPaychecksBySalespersonActionProto} FindPaychecksBySalespersonActionProto instance
             */
            FindPaychecksBySalespersonActionProto.create = function create(properties) {
                return new FindPaychecksBySalespersonActionProto(properties);
            };

            /**
             * Encodes the specified FindPaychecksBySalespersonActionProto message. Does not implicitly {@link waiternow.common.FindPaychecksBySalespersonActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {waiternow.common.IFindPaychecksBySalespersonActionProto} message FindPaychecksBySalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPaychecksBySalespersonActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindPaychecksBySalespersonActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindPaychecksBySalespersonActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {waiternow.common.IFindPaychecksBySalespersonActionProto} message FindPaychecksBySalespersonActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindPaychecksBySalespersonActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindPaychecksBySalespersonActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindPaychecksBySalespersonActionProto} FindPaychecksBySalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPaychecksBySalespersonActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaychecksBySalespersonActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindPaychecksBySalespersonActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindPaychecksBySalespersonActionProto} FindPaychecksBySalespersonActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindPaychecksBySalespersonActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindPaychecksBySalespersonActionProto message.
             * @function verify
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindPaychecksBySalespersonActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindPaychecksBySalespersonActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindPaychecksBySalespersonActionProto} FindPaychecksBySalespersonActionProto
             */
            FindPaychecksBySalespersonActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindPaychecksBySalespersonActionProto)
                    return object;
                return new $root.waiternow.common.FindPaychecksBySalespersonActionProto();
            };

            /**
             * Creates a plain object from a FindPaychecksBySalespersonActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {waiternow.common.FindPaychecksBySalespersonActionProto} message FindPaychecksBySalespersonActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindPaychecksBySalespersonActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindPaychecksBySalespersonActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindPaychecksBySalespersonActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindPaychecksBySalespersonActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindPaychecksBySalespersonActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindPaychecksBySalespersonActionProto";
            };

            FindPaychecksBySalespersonActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
                 * @interface IRequest
                 * @property {string|null} [salespersonId] Request salespersonId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request salespersonId.
                 * @member {string} salespersonId
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @instance
                 */
                Request.prototype.salespersonId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindPaychecksBySalespersonActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.salespersonId != null && Object.hasOwnProperty.call(message, "salespersonId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.salespersonId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindPaychecksBySalespersonActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaychecksBySalespersonActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.salespersonId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        if (!$util.isString(message.salespersonId))
                            return "salespersonId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPaychecksBySalespersonActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindPaychecksBySalespersonActionProto.Request();
                    if (object.salespersonId != null)
                        message.salespersonId = String(object.salespersonId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.salespersonId = "";
                    if (message.salespersonId != null && message.hasOwnProperty("salespersonId"))
                        object.salespersonId = message.salespersonId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPaychecksBySalespersonActionProto.Request";
                };

                return Request;
            })();

            FindPaychecksBySalespersonActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IPaychecksProto|null} [paychecks] Response paychecks
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response paychecks.
                 * @member {waiternow.common.IPaychecksProto|null|undefined} paychecks
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @instance
                 */
                Response.prototype.paychecks = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindPaychecksBySalespersonActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.paychecks != null && Object.hasOwnProperty.call(message, "paychecks"))
                        $root.waiternow.common.PaychecksProto.encode(message.paychecks, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindPaychecksBySalespersonActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindPaychecksBySalespersonActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.paychecks = $root.waiternow.common.PaychecksProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.paychecks != null && message.hasOwnProperty("paychecks")) {
                        var error = $root.waiternow.common.PaychecksProto.verify(message.paychecks);
                        if (error)
                            return "paychecks." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindPaychecksBySalespersonActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindPaychecksBySalespersonActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindPaychecksBySalespersonActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindPaychecksBySalespersonActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.paychecks != null) {
                        if (typeof object.paychecks !== "object")
                            throw TypeError(".waiternow.common.FindPaychecksBySalespersonActionProto.Response.paychecks: object expected");
                        message.paychecks = $root.waiternow.common.PaychecksProto.fromObject(object.paychecks);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {waiternow.common.FindPaychecksBySalespersonActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.paychecks = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.paychecks != null && message.hasOwnProperty("paychecks"))
                        object.paychecks = $root.waiternow.common.PaychecksProto.toObject(message.paychecks, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindPaychecksBySalespersonActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindPaychecksBySalespersonActionProto.Response";
                };

                return Response;
            })();

            return FindPaychecksBySalespersonActionProto;
        })();

        common.FindMetricsActionProto = (function() {

            /**
             * Properties of a FindMetricsActionProto.
             * @memberof waiternow.common
             * @interface IFindMetricsActionProto
             */

            /**
             * Constructs a new FindMetricsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindMetricsActionProto.
             * @implements IFindMetricsActionProto
             * @constructor
             * @param {waiternow.common.IFindMetricsActionProto=} [properties] Properties to set
             */
            function FindMetricsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindMetricsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {waiternow.common.IFindMetricsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindMetricsActionProto} FindMetricsActionProto instance
             */
            FindMetricsActionProto.create = function create(properties) {
                return new FindMetricsActionProto(properties);
            };

            /**
             * Encodes the specified FindMetricsActionProto message. Does not implicitly {@link waiternow.common.FindMetricsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {waiternow.common.IFindMetricsActionProto} message FindMetricsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindMetricsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindMetricsActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindMetricsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {waiternow.common.IFindMetricsActionProto} message FindMetricsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindMetricsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindMetricsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindMetricsActionProto} FindMetricsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindMetricsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindMetricsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindMetricsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindMetricsActionProto} FindMetricsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindMetricsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindMetricsActionProto message.
             * @function verify
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindMetricsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindMetricsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindMetricsActionProto} FindMetricsActionProto
             */
            FindMetricsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindMetricsActionProto)
                    return object;
                return new $root.waiternow.common.FindMetricsActionProto();
            };

            /**
             * Creates a plain object from a FindMetricsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {waiternow.common.FindMetricsActionProto} message FindMetricsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindMetricsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindMetricsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindMetricsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindMetricsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindMetricsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindMetricsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindMetricsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindMetricsActionProto";
            };

            FindMetricsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindMetricsActionProto
                 * @interface IRequest
                 * @property {waiternow.common.PeriodType|null} [periodType] Request periodType
                 * @property {waiternow.common.IDateProto|null} [afterDate] Request afterDate
                 * @property {waiternow.common.IDateProto|null} [beforeDate] Request beforeDate
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindMetricsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindMetricsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request periodType.
                 * @member {waiternow.common.PeriodType} periodType
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.periodType = 0;

                /**
                 * Request afterDate.
                 * @member {waiternow.common.IDateProto|null|undefined} afterDate
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.afterDate = null;

                /**
                 * Request beforeDate.
                 * @member {waiternow.common.IDateProto|null|undefined} beforeDate
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeDate = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindMetricsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindMetricsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.periodType);
                    if (message.afterDate != null && Object.hasOwnProperty.call(message, "afterDate"))
                        $root.waiternow.common.DateProto.encode(message.afterDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    if (message.beforeDate != null && Object.hasOwnProperty.call(message, "beforeDate"))
                        $root.waiternow.common.DateProto.encode(message.beforeDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 5, wireType 2 =*/42).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindMetricsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindMetricsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindMetricsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.periodType = reader.int32();
                                break;
                            }
                        case 2: {
                                message.afterDate = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 3: {
                                message.beforeDate = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 5: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindMetricsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.periodType != null && message.hasOwnProperty("periodType"))
                        switch (message.periodType) {
                        default:
                            return "periodType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.afterDate != null && message.hasOwnProperty("afterDate")) {
                        var error = $root.waiternow.common.DateProto.verify(message.afterDate);
                        if (error)
                            return "afterDate." + error;
                    }
                    if (message.beforeDate != null && message.hasOwnProperty("beforeDate")) {
                        var error = $root.waiternow.common.DateProto.verify(message.beforeDate);
                        if (error)
                            return "beforeDate." + error;
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindMetricsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindMetricsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindMetricsActionProto.Request();
                    switch (object.periodType) {
                    default:
                        if (typeof object.periodType === "number") {
                            message.periodType = object.periodType;
                            break;
                        }
                        break;
                    case "PERIOD_TYPE_UNSPECIFIED":
                    case 0:
                        message.periodType = 0;
                        break;
                    case "DAILY":
                    case 1:
                        message.periodType = 1;
                        break;
                    case "MONTHLY":
                    case 2:
                        message.periodType = 2;
                        break;
                    case "YEARLY":
                    case 3:
                        message.periodType = 3;
                        break;
                    }
                    if (object.afterDate != null) {
                        if (typeof object.afterDate !== "object")
                            throw TypeError(".waiternow.common.FindMetricsActionProto.Request.afterDate: object expected");
                        message.afterDate = $root.waiternow.common.DateProto.fromObject(object.afterDate);
                    }
                    if (object.beforeDate != null) {
                        if (typeof object.beforeDate !== "object")
                            throw TypeError(".waiternow.common.FindMetricsActionProto.Request.beforeDate: object expected");
                        message.beforeDate = $root.waiternow.common.DateProto.fromObject(object.beforeDate);
                    }
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.periodType = options.enums === String ? "PERIOD_TYPE_UNSPECIFIED" : 0;
                        object.afterDate = null;
                        object.beforeDate = null;
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.periodType != null && message.hasOwnProperty("periodType"))
                        object.periodType = options.enums === String ? $root.waiternow.common.PeriodType[message.periodType] === undefined ? message.periodType : $root.waiternow.common.PeriodType[message.periodType] : message.periodType;
                    if (message.afterDate != null && message.hasOwnProperty("afterDate"))
                        object.afterDate = $root.waiternow.common.DateProto.toObject(message.afterDate, options);
                    if (message.beforeDate != null && message.hasOwnProperty("beforeDate"))
                        object.beforeDate = $root.waiternow.common.DateProto.toObject(message.beforeDate, options);
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindMetricsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindMetricsActionProto.Request";
                };

                return Request;
            })();

            FindMetricsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindMetricsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.IMetricsProto|null} [metrics] Response metrics
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindMetricsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindMetricsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response metrics.
                 * @member {waiternow.common.IMetricsProto|null|undefined} metrics
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @instance
                 */
                Response.prototype.metrics = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindMetricsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindMetricsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.metrics != null && Object.hasOwnProperty.call(message, "metrics"))
                        $root.waiternow.common.MetricsProto.encode(message.metrics, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindMetricsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindMetricsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindMetricsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.metrics = $root.waiternow.common.MetricsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindMetricsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.metrics != null && message.hasOwnProperty("metrics")) {
                        var error = $root.waiternow.common.MetricsProto.verify(message.metrics);
                        if (error)
                            return "metrics." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindMetricsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindMetricsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindMetricsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindMetricsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.metrics != null) {
                        if (typeof object.metrics !== "object")
                            throw TypeError(".waiternow.common.FindMetricsActionProto.Response.metrics: object expected");
                        message.metrics = $root.waiternow.common.MetricsProto.fromObject(object.metrics);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindMetricsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.metrics = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.metrics != null && message.hasOwnProperty("metrics"))
                        object.metrics = $root.waiternow.common.MetricsProto.toObject(message.metrics, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindMetricsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindMetricsActionProto.Response";
                };

                return Response;
            })();

            return FindMetricsActionProto;
        })();

        common.FindLocationMetricsActionProto = (function() {

            /**
             * Properties of a FindLocationMetricsActionProto.
             * @memberof waiternow.common
             * @interface IFindLocationMetricsActionProto
             */

            /**
             * Constructs a new FindLocationMetricsActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindLocationMetricsActionProto.
             * @implements IFindLocationMetricsActionProto
             * @constructor
             * @param {waiternow.common.IFindLocationMetricsActionProto=} [properties] Properties to set
             */
            function FindLocationMetricsActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindLocationMetricsActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {waiternow.common.IFindLocationMetricsActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindLocationMetricsActionProto} FindLocationMetricsActionProto instance
             */
            FindLocationMetricsActionProto.create = function create(properties) {
                return new FindLocationMetricsActionProto(properties);
            };

            /**
             * Encodes the specified FindLocationMetricsActionProto message. Does not implicitly {@link waiternow.common.FindLocationMetricsActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {waiternow.common.IFindLocationMetricsActionProto} message FindLocationMetricsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationMetricsActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindLocationMetricsActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindLocationMetricsActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {waiternow.common.IFindLocationMetricsActionProto} message FindLocationMetricsActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindLocationMetricsActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindLocationMetricsActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindLocationMetricsActionProto} FindLocationMetricsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationMetricsActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationMetricsActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindLocationMetricsActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindLocationMetricsActionProto} FindLocationMetricsActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindLocationMetricsActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindLocationMetricsActionProto message.
             * @function verify
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindLocationMetricsActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindLocationMetricsActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindLocationMetricsActionProto} FindLocationMetricsActionProto
             */
            FindLocationMetricsActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindLocationMetricsActionProto)
                    return object;
                return new $root.waiternow.common.FindLocationMetricsActionProto();
            };

            /**
             * Creates a plain object from a FindLocationMetricsActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {waiternow.common.FindLocationMetricsActionProto} message FindLocationMetricsActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindLocationMetricsActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindLocationMetricsActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindLocationMetricsActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindLocationMetricsActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindLocationMetricsActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindLocationMetricsActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindLocationMetricsActionProto";
            };

            FindLocationMetricsActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindLocationMetricsActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.PeriodType|null} [periodType] Request periodType
                 * @property {waiternow.common.IDateProto|null} [afterDate] Request afterDate
                 * @property {waiternow.common.IDateProto|null} [beforeDate] Request beforeDate
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindLocationMetricsActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindLocationMetricsActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request periodType.
                 * @member {waiternow.common.PeriodType} periodType
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.periodType = 0;

                /**
                 * Request afterDate.
                 * @member {waiternow.common.IDateProto|null|undefined} afterDate
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.afterDate = null;

                /**
                 * Request beforeDate.
                 * @member {waiternow.common.IDateProto|null|undefined} beforeDate
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.beforeDate = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindLocationMetricsActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.periodType != null && Object.hasOwnProperty.call(message, "periodType"))
                        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.periodType);
                    if (message.afterDate != null && Object.hasOwnProperty.call(message, "afterDate"))
                        $root.waiternow.common.DateProto.encode(message.afterDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
                    if (message.beforeDate != null && Object.hasOwnProperty.call(message, "beforeDate"))
                        $root.waiternow.common.DateProto.encode(message.beforeDate, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 5, wireType 0 =*/40).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 6, wireType 2 =*/50).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindLocationMetricsActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationMetricsActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.periodType = reader.int32();
                                break;
                            }
                        case 3: {
                                message.afterDate = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 4: {
                                message.beforeDate = $root.waiternow.common.DateProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 5: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 6: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.periodType != null && message.hasOwnProperty("periodType"))
                        switch (message.periodType) {
                        default:
                            return "periodType: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                        case 3:
                            break;
                        }
                    if (message.afterDate != null && message.hasOwnProperty("afterDate")) {
                        var error = $root.waiternow.common.DateProto.verify(message.afterDate);
                        if (error)
                            return "afterDate." + error;
                    }
                    if (message.beforeDate != null && message.hasOwnProperty("beforeDate")) {
                        var error = $root.waiternow.common.DateProto.verify(message.beforeDate);
                        if (error)
                            return "beforeDate." + error;
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationMetricsActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindLocationMetricsActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    switch (object.periodType) {
                    default:
                        if (typeof object.periodType === "number") {
                            message.periodType = object.periodType;
                            break;
                        }
                        break;
                    case "PERIOD_TYPE_UNSPECIFIED":
                    case 0:
                        message.periodType = 0;
                        break;
                    case "DAILY":
                    case 1:
                        message.periodType = 1;
                        break;
                    case "MONTHLY":
                    case 2:
                        message.periodType = 2;
                        break;
                    case "YEARLY":
                    case 3:
                        message.periodType = 3;
                        break;
                    }
                    if (object.afterDate != null) {
                        if (typeof object.afterDate !== "object")
                            throw TypeError(".waiternow.common.FindLocationMetricsActionProto.Request.afterDate: object expected");
                        message.afterDate = $root.waiternow.common.DateProto.fromObject(object.afterDate);
                    }
                    if (object.beforeDate != null) {
                        if (typeof object.beforeDate !== "object")
                            throw TypeError(".waiternow.common.FindLocationMetricsActionProto.Request.beforeDate: object expected");
                        message.beforeDate = $root.waiternow.common.DateProto.fromObject(object.beforeDate);
                    }
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.periodType = options.enums === String ? "PERIOD_TYPE_UNSPECIFIED" : 0;
                        object.afterDate = null;
                        object.beforeDate = null;
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.periodType != null && message.hasOwnProperty("periodType"))
                        object.periodType = options.enums === String ? $root.waiternow.common.PeriodType[message.periodType] === undefined ? message.periodType : $root.waiternow.common.PeriodType[message.periodType] : message.periodType;
                    if (message.afterDate != null && message.hasOwnProperty("afterDate"))
                        object.afterDate = $root.waiternow.common.DateProto.toObject(message.afterDate, options);
                    if (message.beforeDate != null && message.hasOwnProperty("beforeDate"))
                        object.beforeDate = $root.waiternow.common.DateProto.toObject(message.beforeDate, options);
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationMetricsActionProto.Request";
                };

                return Request;
            })();

            FindLocationMetricsActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindLocationMetricsActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ILocationMetricsProto|null} [metrics] Response metrics
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindLocationMetricsActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindLocationMetricsActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response metrics.
                 * @member {waiternow.common.ILocationMetricsProto|null|undefined} metrics
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @instance
                 */
                Response.prototype.metrics = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindLocationMetricsActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.metrics != null && Object.hasOwnProperty.call(message, "metrics"))
                        $root.waiternow.common.LocationMetricsProto.encode(message.metrics, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindLocationMetricsActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindLocationMetricsActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.metrics = $root.waiternow.common.LocationMetricsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.metrics != null && message.hasOwnProperty("metrics")) {
                        var error = $root.waiternow.common.LocationMetricsProto.verify(message.metrics);
                        if (error)
                            return "metrics." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindLocationMetricsActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindLocationMetricsActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindLocationMetricsActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindLocationMetricsActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.metrics != null) {
                        if (typeof object.metrics !== "object")
                            throw TypeError(".waiternow.common.FindLocationMetricsActionProto.Response.metrics: object expected");
                        message.metrics = $root.waiternow.common.LocationMetricsProto.fromObject(object.metrics);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {waiternow.common.FindLocationMetricsActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.metrics = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.metrics != null && message.hasOwnProperty("metrics"))
                        object.metrics = $root.waiternow.common.LocationMetricsProto.toObject(message.metrics, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindLocationMetricsActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindLocationMetricsActionProto.Response";
                };

                return Response;
            })();

            return FindLocationMetricsActionProto;
        })();

        common.CreateCampaignActionProto = (function() {

            /**
             * Properties of a CreateCampaignActionProto.
             * @memberof waiternow.common
             * @interface ICreateCampaignActionProto
             */

            /**
             * Constructs a new CreateCampaignActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a CreateCampaignActionProto.
             * @implements ICreateCampaignActionProto
             * @constructor
             * @param {waiternow.common.ICreateCampaignActionProto=} [properties] Properties to set
             */
            function CreateCampaignActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new CreateCampaignActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {waiternow.common.ICreateCampaignActionProto=} [properties] Properties to set
             * @returns {waiternow.common.CreateCampaignActionProto} CreateCampaignActionProto instance
             */
            CreateCampaignActionProto.create = function create(properties) {
                return new CreateCampaignActionProto(properties);
            };

            /**
             * Encodes the specified CreateCampaignActionProto message. Does not implicitly {@link waiternow.common.CreateCampaignActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {waiternow.common.ICreateCampaignActionProto} message CreateCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCampaignActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified CreateCampaignActionProto message, length delimited. Does not implicitly {@link waiternow.common.CreateCampaignActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {waiternow.common.ICreateCampaignActionProto} message CreateCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            CreateCampaignActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a CreateCampaignActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.CreateCampaignActionProto} CreateCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCampaignActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateCampaignActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a CreateCampaignActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.CreateCampaignActionProto} CreateCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            CreateCampaignActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a CreateCampaignActionProto message.
             * @function verify
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            CreateCampaignActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a CreateCampaignActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.CreateCampaignActionProto} CreateCampaignActionProto
             */
            CreateCampaignActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.CreateCampaignActionProto)
                    return object;
                return new $root.waiternow.common.CreateCampaignActionProto();
            };

            /**
             * Creates a plain object from a CreateCampaignActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {waiternow.common.CreateCampaignActionProto} message CreateCampaignActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            CreateCampaignActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this CreateCampaignActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.CreateCampaignActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            CreateCampaignActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for CreateCampaignActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.CreateCampaignActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            CreateCampaignActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.CreateCampaignActionProto";
            };

            CreateCampaignActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.CreateCampaignActionProto
                 * @interface IRequest
                 * @property {waiternow.common.ICampaignProto|null} [campaign] Request campaign
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.CreateCampaignActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.CreateCampaignActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request campaign.
                 * @member {waiternow.common.ICampaignProto|null|undefined} campaign
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @instance
                 */
                Request.prototype.campaign = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.CreateCampaignActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.CreateCampaignActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.campaign != null && Object.hasOwnProperty.call(message, "campaign"))
                        $root.waiternow.common.CampaignProto.encode(message.campaign, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.CreateCampaignActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateCampaignActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.campaign = $root.waiternow.common.CampaignProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.campaign != null && message.hasOwnProperty("campaign")) {
                        var error = $root.waiternow.common.CampaignProto.verify(message.campaign);
                        if (error)
                            return "campaign." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateCampaignActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateCampaignActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.CreateCampaignActionProto.Request();
                    if (object.campaign != null) {
                        if (typeof object.campaign !== "object")
                            throw TypeError(".waiternow.common.CreateCampaignActionProto.Request.campaign: object expected");
                        message.campaign = $root.waiternow.common.CampaignProto.fromObject(object.campaign);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.campaign = null;
                    if (message.campaign != null && message.hasOwnProperty("campaign"))
                        object.campaign = $root.waiternow.common.CampaignProto.toObject(message.campaign, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateCampaignActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateCampaignActionProto.Request";
                };

                return Request;
            })();

            CreateCampaignActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.CreateCampaignActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ICampaignProto|null} [campaign] Response campaign
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.CreateCampaignActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.CreateCampaignActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response campaign.
                 * @member {waiternow.common.ICampaignProto|null|undefined} campaign
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @instance
                 */
                Response.prototype.campaign = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.CreateCampaignActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.CreateCampaignActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.campaign != null && Object.hasOwnProperty.call(message, "campaign"))
                        $root.waiternow.common.CampaignProto.encode(message.campaign, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.CreateCampaignActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.CreateCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.CreateCampaignActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.campaign = $root.waiternow.common.CampaignProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.CreateCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.campaign != null && message.hasOwnProperty("campaign")) {
                        var error = $root.waiternow.common.CampaignProto.verify(message.campaign);
                        if (error)
                            return "campaign." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.CreateCampaignActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.CreateCampaignActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.CreateCampaignActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.CreateCampaignActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.campaign != null) {
                        if (typeof object.campaign !== "object")
                            throw TypeError(".waiternow.common.CreateCampaignActionProto.Response.campaign: object expected");
                        message.campaign = $root.waiternow.common.CampaignProto.fromObject(object.campaign);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.CreateCampaignActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.campaign = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.campaign != null && message.hasOwnProperty("campaign"))
                        object.campaign = $root.waiternow.common.CampaignProto.toObject(message.campaign, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.CreateCampaignActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.CreateCampaignActionProto.Response";
                };

                return Response;
            })();

            return CreateCampaignActionProto;
        })();

        common.UpdateCampaignActionProto = (function() {

            /**
             * Properties of an UpdateCampaignActionProto.
             * @memberof waiternow.common
             * @interface IUpdateCampaignActionProto
             */

            /**
             * Constructs a new UpdateCampaignActionProto.
             * @memberof waiternow.common
             * @classdesc Represents an UpdateCampaignActionProto.
             * @implements IUpdateCampaignActionProto
             * @constructor
             * @param {waiternow.common.IUpdateCampaignActionProto=} [properties] Properties to set
             */
            function UpdateCampaignActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new UpdateCampaignActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {waiternow.common.IUpdateCampaignActionProto=} [properties] Properties to set
             * @returns {waiternow.common.UpdateCampaignActionProto} UpdateCampaignActionProto instance
             */
            UpdateCampaignActionProto.create = function create(properties) {
                return new UpdateCampaignActionProto(properties);
            };

            /**
             * Encodes the specified UpdateCampaignActionProto message. Does not implicitly {@link waiternow.common.UpdateCampaignActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {waiternow.common.IUpdateCampaignActionProto} message UpdateCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCampaignActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified UpdateCampaignActionProto message, length delimited. Does not implicitly {@link waiternow.common.UpdateCampaignActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {waiternow.common.IUpdateCampaignActionProto} message UpdateCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            UpdateCampaignActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an UpdateCampaignActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.UpdateCampaignActionProto} UpdateCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCampaignActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateCampaignActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an UpdateCampaignActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.UpdateCampaignActionProto} UpdateCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            UpdateCampaignActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an UpdateCampaignActionProto message.
             * @function verify
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            UpdateCampaignActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates an UpdateCampaignActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.UpdateCampaignActionProto} UpdateCampaignActionProto
             */
            UpdateCampaignActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.UpdateCampaignActionProto)
                    return object;
                return new $root.waiternow.common.UpdateCampaignActionProto();
            };

            /**
             * Creates a plain object from an UpdateCampaignActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {waiternow.common.UpdateCampaignActionProto} message UpdateCampaignActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            UpdateCampaignActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this UpdateCampaignActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            UpdateCampaignActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for UpdateCampaignActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.UpdateCampaignActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            UpdateCampaignActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.UpdateCampaignActionProto";
            };

            UpdateCampaignActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.UpdateCampaignActionProto
                 * @interface IRequest
                 * @property {waiternow.common.ICampaignProto|null} [campaign] Request campaign
                 * @property {Array.<waiternow.common.UpdateCampaignActionProto.Request.RemovableField>|null} [fieldsToRemove] Request fieldsToRemove
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.UpdateCampaignActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.UpdateCampaignActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    this.fieldsToRemove = [];
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request campaign.
                 * @member {waiternow.common.ICampaignProto|null|undefined} campaign
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @instance
                 */
                Request.prototype.campaign = null;

                /**
                 * Request fieldsToRemove.
                 * @member {Array.<waiternow.common.UpdateCampaignActionProto.Request.RemovableField>} fieldsToRemove
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @instance
                 */
                Request.prototype.fieldsToRemove = $util.emptyArray;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateCampaignActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.UpdateCampaignActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.campaign != null && Object.hasOwnProperty.call(message, "campaign"))
                        $root.waiternow.common.CampaignProto.encode(message.campaign, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.fieldsToRemove != null && message.fieldsToRemove.length) {
                        writer.uint32(/* id 2, wireType 2 =*/18).fork();
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            writer.int32(message.fieldsToRemove[i]);
                        writer.ldelim();
                    }
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.UpdateCampaignActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateCampaignActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.campaign = $root.waiternow.common.CampaignProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                if (!(message.fieldsToRemove && message.fieldsToRemove.length))
                                    message.fieldsToRemove = [];
                                if ((tag & 7) === 2) {
                                    var end2 = reader.uint32() + reader.pos;
                                    while (reader.pos < end2)
                                        message.fieldsToRemove.push(reader.int32());
                                } else
                                    message.fieldsToRemove.push(reader.int32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.campaign != null && message.hasOwnProperty("campaign")) {
                        var error = $root.waiternow.common.CampaignProto.verify(message.campaign);
                        if (error)
                            return "campaign." + error;
                    }
                    if (message.fieldsToRemove != null && message.hasOwnProperty("fieldsToRemove")) {
                        if (!Array.isArray(message.fieldsToRemove))
                            return "fieldsToRemove: array expected";
                        for (var i = 0; i < message.fieldsToRemove.length; ++i)
                            switch (message.fieldsToRemove[i]) {
                            default:
                                return "fieldsToRemove: enum value[] expected";
                            case 0:
                            case 1:
                            case 2:
                            case 3:
                            case 4:
                            case 5:
                                break;
                            }
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateCampaignActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateCampaignActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.UpdateCampaignActionProto.Request();
                    if (object.campaign != null) {
                        if (typeof object.campaign !== "object")
                            throw TypeError(".waiternow.common.UpdateCampaignActionProto.Request.campaign: object expected");
                        message.campaign = $root.waiternow.common.CampaignProto.fromObject(object.campaign);
                    }
                    if (object.fieldsToRemove) {
                        if (!Array.isArray(object.fieldsToRemove))
                            throw TypeError(".waiternow.common.UpdateCampaignActionProto.Request.fieldsToRemove: array expected");
                        message.fieldsToRemove = [];
                        for (var i = 0; i < object.fieldsToRemove.length; ++i)
                            switch (object.fieldsToRemove[i]) {
                            default:
                                if (typeof object.fieldsToRemove[i] === "number") {
                                    message.fieldsToRemove[i] = object.fieldsToRemove[i];
                                    break;
                                }
                            case "REMOVABLE_FIELD_UNSPECIFIED":
                            case 0:
                                message.fieldsToRemove[i] = 0;
                                break;
                            case "CODE":
                            case 1:
                                message.fieldsToRemove[i] = 1;
                                break;
                            case "DESCRIPTION":
                            case 2:
                                message.fieldsToRemove[i] = 2;
                                break;
                            case "DISCOUNT":
                            case 3:
                                message.fieldsToRemove[i] = 3;
                                break;
                            case "CONDITIONS":
                            case 4:
                                message.fieldsToRemove[i] = 4;
                                break;
                            case "IS_ACTIVE":
                            case 5:
                                message.fieldsToRemove[i] = 5;
                                break;
                            }
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.arrays || options.defaults)
                        object.fieldsToRemove = [];
                    if (options.defaults)
                        object.campaign = null;
                    if (message.campaign != null && message.hasOwnProperty("campaign"))
                        object.campaign = $root.waiternow.common.CampaignProto.toObject(message.campaign, options);
                    if (message.fieldsToRemove && message.fieldsToRemove.length) {
                        object.fieldsToRemove = [];
                        for (var j = 0; j < message.fieldsToRemove.length; ++j)
                            object.fieldsToRemove[j] = options.enums === String ? $root.waiternow.common.UpdateCampaignActionProto.Request.RemovableField[message.fieldsToRemove[j]] === undefined ? message.fieldsToRemove[j] : $root.waiternow.common.UpdateCampaignActionProto.Request.RemovableField[message.fieldsToRemove[j]] : message.fieldsToRemove[j];
                    }
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateCampaignActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateCampaignActionProto.Request";
                };

                /**
                 * RemovableField enum.
                 * @name waiternow.common.UpdateCampaignActionProto.Request.RemovableField
                 * @enum {number}
                 * @property {number} REMOVABLE_FIELD_UNSPECIFIED=0 REMOVABLE_FIELD_UNSPECIFIED value
                 * @property {number} CODE=1 CODE value
                 * @property {number} DESCRIPTION=2 DESCRIPTION value
                 * @property {number} DISCOUNT=3 DISCOUNT value
                 * @property {number} CONDITIONS=4 CONDITIONS value
                 * @property {number} IS_ACTIVE=5 IS_ACTIVE value
                 */
                Request.RemovableField = (function() {
                    var valuesById = {}, values = Object.create(valuesById);
                    values[valuesById[0] = "REMOVABLE_FIELD_UNSPECIFIED"] = 0;
                    values[valuesById[1] = "CODE"] = 1;
                    values[valuesById[2] = "DESCRIPTION"] = 2;
                    values[valuesById[3] = "DISCOUNT"] = 3;
                    values[valuesById[4] = "CONDITIONS"] = 4;
                    values[valuesById[5] = "IS_ACTIVE"] = 5;
                    return values;
                })();

                return Request;
            })();

            UpdateCampaignActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.UpdateCampaignActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.UpdateCampaignActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.UpdateCampaignActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.UpdateCampaignActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.UpdateCampaignActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.UpdateCampaignActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.UpdateCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.UpdateCampaignActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.UpdateCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.UpdateCampaignActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.UpdateCampaignActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.UpdateCampaignActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.UpdateCampaignActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.UpdateCampaignActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.UpdateCampaignActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.UpdateCampaignActionProto.Response";
                };

                return Response;
            })();

            return UpdateCampaignActionProto;
        })();

        common.DeleteCampaignActionProto = (function() {

            /**
             * Properties of a DeleteCampaignActionProto.
             * @memberof waiternow.common
             * @interface IDeleteCampaignActionProto
             */

            /**
             * Constructs a new DeleteCampaignActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a DeleteCampaignActionProto.
             * @implements IDeleteCampaignActionProto
             * @constructor
             * @param {waiternow.common.IDeleteCampaignActionProto=} [properties] Properties to set
             */
            function DeleteCampaignActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new DeleteCampaignActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {waiternow.common.IDeleteCampaignActionProto=} [properties] Properties to set
             * @returns {waiternow.common.DeleteCampaignActionProto} DeleteCampaignActionProto instance
             */
            DeleteCampaignActionProto.create = function create(properties) {
                return new DeleteCampaignActionProto(properties);
            };

            /**
             * Encodes the specified DeleteCampaignActionProto message. Does not implicitly {@link waiternow.common.DeleteCampaignActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {waiternow.common.IDeleteCampaignActionProto} message DeleteCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCampaignActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified DeleteCampaignActionProto message, length delimited. Does not implicitly {@link waiternow.common.DeleteCampaignActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {waiternow.common.IDeleteCampaignActionProto} message DeleteCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            DeleteCampaignActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a DeleteCampaignActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.DeleteCampaignActionProto} DeleteCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCampaignActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteCampaignActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a DeleteCampaignActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.DeleteCampaignActionProto} DeleteCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            DeleteCampaignActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a DeleteCampaignActionProto message.
             * @function verify
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            DeleteCampaignActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a DeleteCampaignActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.DeleteCampaignActionProto} DeleteCampaignActionProto
             */
            DeleteCampaignActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.DeleteCampaignActionProto)
                    return object;
                return new $root.waiternow.common.DeleteCampaignActionProto();
            };

            /**
             * Creates a plain object from a DeleteCampaignActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {waiternow.common.DeleteCampaignActionProto} message DeleteCampaignActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            DeleteCampaignActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this DeleteCampaignActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            DeleteCampaignActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for DeleteCampaignActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.DeleteCampaignActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            DeleteCampaignActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.DeleteCampaignActionProto";
            };

            DeleteCampaignActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.DeleteCampaignActionProto
                 * @interface IRequest
                 * @property {string|null} [campaignId] Request campaignId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.DeleteCampaignActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.DeleteCampaignActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request campaignId.
                 * @member {string} campaignId
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @instance
                 */
                Request.prototype.campaignId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteCampaignActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.DeleteCampaignActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.DeleteCampaignActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteCampaignActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.campaignId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                        if (!$util.isString(message.campaignId))
                            return "campaignId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteCampaignActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteCampaignActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.DeleteCampaignActionProto.Request();
                    if (object.campaignId != null)
                        message.campaignId = String(object.campaignId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.campaignId = "";
                    if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                        object.campaignId = message.campaignId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteCampaignActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteCampaignActionProto.Request";
                };

                return Request;
            })();

            DeleteCampaignActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.DeleteCampaignActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.DeleteCampaignActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.DeleteCampaignActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.DeleteCampaignActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.DeleteCampaignActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.DeleteCampaignActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.DeleteCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.DeleteCampaignActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.DeleteCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.DeleteCampaignActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.DeleteCampaignActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.DeleteCampaignActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.DeleteCampaignActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.DeleteCampaignActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.operationStatus = null;
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.DeleteCampaignActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.DeleteCampaignActionProto.Response";
                };

                return Response;
            })();

            return DeleteCampaignActionProto;
        })();

        common.GetCampaignActionProto = (function() {

            /**
             * Properties of a GetCampaignActionProto.
             * @memberof waiternow.common
             * @interface IGetCampaignActionProto
             */

            /**
             * Constructs a new GetCampaignActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetCampaignActionProto.
             * @implements IGetCampaignActionProto
             * @constructor
             * @param {waiternow.common.IGetCampaignActionProto=} [properties] Properties to set
             */
            function GetCampaignActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetCampaignActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {waiternow.common.IGetCampaignActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetCampaignActionProto} GetCampaignActionProto instance
             */
            GetCampaignActionProto.create = function create(properties) {
                return new GetCampaignActionProto(properties);
            };

            /**
             * Encodes the specified GetCampaignActionProto message. Does not implicitly {@link waiternow.common.GetCampaignActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {waiternow.common.IGetCampaignActionProto} message GetCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCampaignActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetCampaignActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetCampaignActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {waiternow.common.IGetCampaignActionProto} message GetCampaignActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetCampaignActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetCampaignActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetCampaignActionProto} GetCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCampaignActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetCampaignActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetCampaignActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetCampaignActionProto} GetCampaignActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetCampaignActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetCampaignActionProto message.
             * @function verify
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetCampaignActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetCampaignActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetCampaignActionProto} GetCampaignActionProto
             */
            GetCampaignActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetCampaignActionProto)
                    return object;
                return new $root.waiternow.common.GetCampaignActionProto();
            };

            /**
             * Creates a plain object from a GetCampaignActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {waiternow.common.GetCampaignActionProto} message GetCampaignActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetCampaignActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetCampaignActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetCampaignActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetCampaignActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetCampaignActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetCampaignActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetCampaignActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetCampaignActionProto";
            };

            GetCampaignActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetCampaignActionProto
                 * @interface IRequest
                 * @property {string|null} [campaignId] Request campaignId
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetCampaignActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetCampaignActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request campaignId.
                 * @member {string} campaignId
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @instance
                 */
                Request.prototype.campaignId = "";

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetCampaignActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetCampaignActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.campaignId != null && Object.hasOwnProperty.call(message, "campaignId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.campaignId);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetCampaignActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetCampaignActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.campaignId = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetCampaignActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                        if (!$util.isString(message.campaignId))
                            return "campaignId: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetCampaignActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetCampaignActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetCampaignActionProto.Request();
                    if (object.campaignId != null)
                        message.campaignId = String(object.campaignId);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults)
                        object.campaignId = "";
                    if (message.campaignId != null && message.hasOwnProperty("campaignId"))
                        object.campaignId = message.campaignId;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetCampaignActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetCampaignActionProto.Request";
                };

                return Request;
            })();

            GetCampaignActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetCampaignActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ICampaignProto|null} [campaign] Response campaign
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetCampaignActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetCampaignActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response campaign.
                 * @member {waiternow.common.ICampaignProto|null|undefined} campaign
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @instance
                 */
                Response.prototype.campaign = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetCampaignActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetCampaignActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.campaign != null && Object.hasOwnProperty.call(message, "campaign"))
                        $root.waiternow.common.CampaignProto.encode(message.campaign, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetCampaignActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetCampaignActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.campaign = $root.waiternow.common.CampaignProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetCampaignActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.campaign != null && message.hasOwnProperty("campaign")) {
                        var error = $root.waiternow.common.CampaignProto.verify(message.campaign);
                        if (error)
                            return "campaign." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetCampaignActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetCampaignActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetCampaignActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetCampaignActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.campaign != null) {
                        if (typeof object.campaign !== "object")
                            throw TypeError(".waiternow.common.GetCampaignActionProto.Response.campaign: object expected");
                        message.campaign = $root.waiternow.common.CampaignProto.fromObject(object.campaign);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {waiternow.common.GetCampaignActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.campaign = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.campaign != null && message.hasOwnProperty("campaign"))
                        object.campaign = $root.waiternow.common.CampaignProto.toObject(message.campaign, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetCampaignActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetCampaignActionProto.Response";
                };

                return Response;
            })();

            return GetCampaignActionProto;
        })();

        common.FindCampaignsByScopeActionProto = (function() {

            /**
             * Properties of a FindCampaignsByScopeActionProto.
             * @memberof waiternow.common
             * @interface IFindCampaignsByScopeActionProto
             */

            /**
             * Constructs a new FindCampaignsByScopeActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a FindCampaignsByScopeActionProto.
             * @implements IFindCampaignsByScopeActionProto
             * @constructor
             * @param {waiternow.common.IFindCampaignsByScopeActionProto=} [properties] Properties to set
             */
            function FindCampaignsByScopeActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new FindCampaignsByScopeActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {waiternow.common.IFindCampaignsByScopeActionProto=} [properties] Properties to set
             * @returns {waiternow.common.FindCampaignsByScopeActionProto} FindCampaignsByScopeActionProto instance
             */
            FindCampaignsByScopeActionProto.create = function create(properties) {
                return new FindCampaignsByScopeActionProto(properties);
            };

            /**
             * Encodes the specified FindCampaignsByScopeActionProto message. Does not implicitly {@link waiternow.common.FindCampaignsByScopeActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {waiternow.common.IFindCampaignsByScopeActionProto} message FindCampaignsByScopeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindCampaignsByScopeActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified FindCampaignsByScopeActionProto message, length delimited. Does not implicitly {@link waiternow.common.FindCampaignsByScopeActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {waiternow.common.IFindCampaignsByScopeActionProto} message FindCampaignsByScopeActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            FindCampaignsByScopeActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a FindCampaignsByScopeActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.FindCampaignsByScopeActionProto} FindCampaignsByScopeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindCampaignsByScopeActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindCampaignsByScopeActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a FindCampaignsByScopeActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.FindCampaignsByScopeActionProto} FindCampaignsByScopeActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            FindCampaignsByScopeActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a FindCampaignsByScopeActionProto message.
             * @function verify
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            FindCampaignsByScopeActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a FindCampaignsByScopeActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.FindCampaignsByScopeActionProto} FindCampaignsByScopeActionProto
             */
            FindCampaignsByScopeActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.FindCampaignsByScopeActionProto)
                    return object;
                return new $root.waiternow.common.FindCampaignsByScopeActionProto();
            };

            /**
             * Creates a plain object from a FindCampaignsByScopeActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {waiternow.common.FindCampaignsByScopeActionProto} message FindCampaignsByScopeActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            FindCampaignsByScopeActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this FindCampaignsByScopeActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            FindCampaignsByScopeActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for FindCampaignsByScopeActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.FindCampaignsByScopeActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            FindCampaignsByScopeActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.FindCampaignsByScopeActionProto";
            };

            FindCampaignsByScopeActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto
                 * @interface IRequest
                 * @property {string|null} [businessId] Request businessId
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.SortOrder|null} [sortOrder] Request sortOrder
                 * @property {string|null} [continuationToken] Request continuationToken
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request businessId.
                 * @member {string|null|undefined} businessId
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @instance
                 */
                Request.prototype.businessId = null;

                /**
                 * Request locationId.
                 * @member {string|null|undefined} locationId
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = null;

                /**
                 * Request sortOrder.
                 * @member {waiternow.common.SortOrder} sortOrder
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @instance
                 */
                Request.prototype.sortOrder = 0;

                /**
                 * Request continuationToken.
                 * @member {string} continuationToken
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @instance
                 */
                Request.prototype.continuationToken = "";

                // OneOf field names bound to virtual getters and setters
                var $oneOfFields;

                /**
                 * Request campaignScope.
                 * @member {"businessId"|"locationId"|undefined} campaignScope
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @instance
                 */
                Object.defineProperty(Request.prototype, "campaignScope", {
                    get: $util.oneOfGetter($oneOfFields = ["businessId", "locationId"]),
                    set: $util.oneOfSetter($oneOfFields)
                });

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.FindCampaignsByScopeActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.businessId != null && Object.hasOwnProperty.call(message, "businessId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.businessId);
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 2, wireType 2 =*/18).string(message.locationId);
                    if (message.sortOrder != null && Object.hasOwnProperty.call(message, "sortOrder"))
                        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sortOrder);
                    if (message.continuationToken != null && Object.hasOwnProperty.call(message, "continuationToken"))
                        writer.uint32(/* id 4, wireType 2 =*/34).string(message.continuationToken);
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.FindCampaignsByScopeActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindCampaignsByScopeActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.businessId = reader.string();
                                break;
                            }
                        case 2: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 3: {
                                message.sortOrder = reader.int32();
                                break;
                            }
                        case 4: {
                                message.continuationToken = reader.string();
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    var properties = {};
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        properties.campaignScope = 1;
                        if (!$util.isString(message.businessId))
                            return "businessId: string expected";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        if (properties.campaignScope === 1)
                            return "campaignScope: multiple values";
                        properties.campaignScope = 1;
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        switch (message.sortOrder) {
                        default:
                            return "sortOrder: enum value expected";
                        case 0:
                        case 1:
                        case 2:
                            break;
                        }
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        if (!$util.isString(message.continuationToken))
                            return "continuationToken: string expected";
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindCampaignsByScopeActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.FindCampaignsByScopeActionProto.Request();
                    if (object.businessId != null)
                        message.businessId = String(object.businessId);
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    switch (object.sortOrder) {
                    default:
                        if (typeof object.sortOrder === "number") {
                            message.sortOrder = object.sortOrder;
                            break;
                        }
                        break;
                    case "SORT_ORDER_UNSPECIFIED":
                    case 0:
                        message.sortOrder = 0;
                        break;
                    case "ASCENDING":
                    case 1:
                        message.sortOrder = 1;
                        break;
                    case "DESCENDING":
                    case 2:
                        message.sortOrder = 2;
                        break;
                    }
                    if (object.continuationToken != null)
                        message.continuationToken = String(object.continuationToken);
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.sortOrder = options.enums === String ? "SORT_ORDER_UNSPECIFIED" : 0;
                        object.continuationToken = "";
                    }
                    if (message.businessId != null && message.hasOwnProperty("businessId")) {
                        object.businessId = message.businessId;
                        if (options.oneofs)
                            object.campaignScope = "businessId";
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId")) {
                        object.locationId = message.locationId;
                        if (options.oneofs)
                            object.campaignScope = "locationId";
                    }
                    if (message.sortOrder != null && message.hasOwnProperty("sortOrder"))
                        object.sortOrder = options.enums === String ? $root.waiternow.common.SortOrder[message.sortOrder] === undefined ? message.sortOrder : $root.waiternow.common.SortOrder[message.sortOrder] : message.sortOrder;
                    if (message.continuationToken != null && message.hasOwnProperty("continuationToken"))
                        object.continuationToken = message.continuationToken;
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindCampaignsByScopeActionProto.Request";
                };

                return Request;
            })();

            FindCampaignsByScopeActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ICampaignsProto|null} [campaigns] Response campaigns
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response campaigns.
                 * @member {waiternow.common.ICampaignsProto|null|undefined} campaigns
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @instance
                 */
                Response.prototype.campaigns = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.FindCampaignsByScopeActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.campaigns != null && Object.hasOwnProperty.call(message, "campaigns"))
                        $root.waiternow.common.CampaignsProto.encode(message.campaigns, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.FindCampaignsByScopeActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.FindCampaignsByScopeActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.campaigns = $root.waiternow.common.CampaignsProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.campaigns != null && message.hasOwnProperty("campaigns")) {
                        var error = $root.waiternow.common.CampaignsProto.verify(message.campaigns);
                        if (error)
                            return "campaigns." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.FindCampaignsByScopeActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.FindCampaignsByScopeActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.FindCampaignsByScopeActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.FindCampaignsByScopeActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.campaigns != null) {
                        if (typeof object.campaigns !== "object")
                            throw TypeError(".waiternow.common.FindCampaignsByScopeActionProto.Response.campaigns: object expected");
                        message.campaigns = $root.waiternow.common.CampaignsProto.fromObject(object.campaigns);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {waiternow.common.FindCampaignsByScopeActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.campaigns = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.campaigns != null && message.hasOwnProperty("campaigns"))
                        object.campaigns = $root.waiternow.common.CampaignsProto.toObject(message.campaigns, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.FindCampaignsByScopeActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.FindCampaignsByScopeActionProto.Response";
                };

                return Response;
            })();

            return FindCampaignsByScopeActionProto;
        })();

        common.GetSalesSummaryReportActionProto = (function() {

            /**
             * Properties of a GetSalesSummaryReportActionProto.
             * @memberof waiternow.common
             * @interface IGetSalesSummaryReportActionProto
             */

            /**
             * Constructs a new GetSalesSummaryReportActionProto.
             * @memberof waiternow.common
             * @classdesc Represents a GetSalesSummaryReportActionProto.
             * @implements IGetSalesSummaryReportActionProto
             * @constructor
             * @param {waiternow.common.IGetSalesSummaryReportActionProto=} [properties] Properties to set
             */
            function GetSalesSummaryReportActionProto(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Creates a new GetSalesSummaryReportActionProto instance using the specified properties.
             * @function create
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {waiternow.common.IGetSalesSummaryReportActionProto=} [properties] Properties to set
             * @returns {waiternow.common.GetSalesSummaryReportActionProto} GetSalesSummaryReportActionProto instance
             */
            GetSalesSummaryReportActionProto.create = function create(properties) {
                return new GetSalesSummaryReportActionProto(properties);
            };

            /**
             * Encodes the specified GetSalesSummaryReportActionProto message. Does not implicitly {@link waiternow.common.GetSalesSummaryReportActionProto.verify|verify} messages.
             * @function encode
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {waiternow.common.IGetSalesSummaryReportActionProto} message GetSalesSummaryReportActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSalesSummaryReportActionProto.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                return writer;
            };

            /**
             * Encodes the specified GetSalesSummaryReportActionProto message, length delimited. Does not implicitly {@link waiternow.common.GetSalesSummaryReportActionProto.verify|verify} messages.
             * @function encodeDelimited
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {waiternow.common.IGetSalesSummaryReportActionProto} message GetSalesSummaryReportActionProto message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            GetSalesSummaryReportActionProto.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a GetSalesSummaryReportActionProto message from the specified reader or buffer.
             * @function decode
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {waiternow.common.GetSalesSummaryReportActionProto} GetSalesSummaryReportActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSalesSummaryReportActionProto.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetSalesSummaryReportActionProto();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a GetSalesSummaryReportActionProto message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {waiternow.common.GetSalesSummaryReportActionProto} GetSalesSummaryReportActionProto
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            GetSalesSummaryReportActionProto.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a GetSalesSummaryReportActionProto message.
             * @function verify
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            GetSalesSummaryReportActionProto.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                return null;
            };

            /**
             * Creates a GetSalesSummaryReportActionProto message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {waiternow.common.GetSalesSummaryReportActionProto} GetSalesSummaryReportActionProto
             */
            GetSalesSummaryReportActionProto.fromObject = function fromObject(object) {
                if (object instanceof $root.waiternow.common.GetSalesSummaryReportActionProto)
                    return object;
                return new $root.waiternow.common.GetSalesSummaryReportActionProto();
            };

            /**
             * Creates a plain object from a GetSalesSummaryReportActionProto message. Also converts values to other types if specified.
             * @function toObject
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {waiternow.common.GetSalesSummaryReportActionProto} message GetSalesSummaryReportActionProto
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            GetSalesSummaryReportActionProto.toObject = function toObject() {
                return {};
            };

            /**
             * Converts this GetSalesSummaryReportActionProto to JSON.
             * @function toJSON
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            GetSalesSummaryReportActionProto.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for GetSalesSummaryReportActionProto
             * @function getTypeUrl
             * @memberof waiternow.common.GetSalesSummaryReportActionProto
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            GetSalesSummaryReportActionProto.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/waiternow.common.GetSalesSummaryReportActionProto";
            };

            GetSalesSummaryReportActionProto.Request = (function() {

                /**
                 * Properties of a Request.
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto
                 * @interface IRequest
                 * @property {string|null} [locationId] Request locationId
                 * @property {waiternow.common.IDateRangeProto|null} [period] Request period
                 */

                /**
                 * Constructs a new Request.
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto
                 * @classdesc Represents a Request.
                 * @implements IRequest
                 * @constructor
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IRequest=} [properties] Properties to set
                 */
                function Request(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Request locationId.
                 * @member {string} locationId
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @instance
                 */
                Request.prototype.locationId = "";

                /**
                 * Request period.
                 * @member {waiternow.common.IDateRangeProto|null|undefined} period
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @instance
                 */
                Request.prototype.period = null;

                /**
                 * Creates a new Request instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IRequest=} [properties] Properties to set
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Request} Request instance
                 */
                Request.create = function create(properties) {
                    return new Request(properties);
                };

                /**
                 * Encodes the specified Request message. Does not implicitly {@link waiternow.common.GetSalesSummaryReportActionProto.Request.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.locationId != null && Object.hasOwnProperty.call(message, "locationId"))
                        writer.uint32(/* id 1, wireType 2 =*/10).string(message.locationId);
                    if (message.period != null && Object.hasOwnProperty.call(message, "period"))
                        $root.waiternow.common.DateRangeProto.encode(message.period, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Request message, length delimited. Does not implicitly {@link waiternow.common.GetSalesSummaryReportActionProto.Request.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IRequest} message Request message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Request.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Request message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetSalesSummaryReportActionProto.Request();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.locationId = reader.string();
                                break;
                            }
                        case 2: {
                                message.period = $root.waiternow.common.DateRangeProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Request message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Request} Request
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Request.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Request message.
                 * @function verify
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Request.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        if (!$util.isString(message.locationId))
                            return "locationId: string expected";
                    if (message.period != null && message.hasOwnProperty("period")) {
                        var error = $root.waiternow.common.DateRangeProto.verify(message.period);
                        if (error)
                            return "period." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Request message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Request} Request
                 */
                Request.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetSalesSummaryReportActionProto.Request)
                        return object;
                    var message = new $root.waiternow.common.GetSalesSummaryReportActionProto.Request();
                    if (object.locationId != null)
                        message.locationId = String(object.locationId);
                    if (object.period != null) {
                        if (typeof object.period !== "object")
                            throw TypeError(".waiternow.common.GetSalesSummaryReportActionProto.Request.period: object expected");
                        message.period = $root.waiternow.common.DateRangeProto.fromObject(object.period);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Request message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.Request} message Request
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Request.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.locationId = "";
                        object.period = null;
                    }
                    if (message.locationId != null && message.hasOwnProperty("locationId"))
                        object.locationId = message.locationId;
                    if (message.period != null && message.hasOwnProperty("period"))
                        object.period = $root.waiternow.common.DateRangeProto.toObject(message.period, options);
                    return object;
                };

                /**
                 * Converts this Request to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Request.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Request
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Request
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Request.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetSalesSummaryReportActionProto.Request";
                };

                return Request;
            })();

            GetSalesSummaryReportActionProto.Response = (function() {

                /**
                 * Properties of a Response.
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto
                 * @interface IResponse
                 * @property {waiternow.common.IOperationStatusProto|null} [operationStatus] Response operationStatus
                 * @property {waiternow.common.ISalesSummaryReportProto|null} [report] Response report
                 */

                /**
                 * Constructs a new Response.
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto
                 * @classdesc Represents a Response.
                 * @implements IResponse
                 * @constructor
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IResponse=} [properties] Properties to set
                 */
                function Response(properties) {
                    if (properties)
                        for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                            if (properties[keys[i]] != null)
                                this[keys[i]] = properties[keys[i]];
                }

                /**
                 * Response operationStatus.
                 * @member {waiternow.common.IOperationStatusProto|null|undefined} operationStatus
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @instance
                 */
                Response.prototype.operationStatus = null;

                /**
                 * Response report.
                 * @member {waiternow.common.ISalesSummaryReportProto|null|undefined} report
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @instance
                 */
                Response.prototype.report = null;

                /**
                 * Creates a new Response instance using the specified properties.
                 * @function create
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IResponse=} [properties] Properties to set
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Response} Response instance
                 */
                Response.create = function create(properties) {
                    return new Response(properties);
                };

                /**
                 * Encodes the specified Response message. Does not implicitly {@link waiternow.common.GetSalesSummaryReportActionProto.Response.verify|verify} messages.
                 * @function encode
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encode = function encode(message, writer) {
                    if (!writer)
                        writer = $Writer.create();
                    if (message.operationStatus != null && Object.hasOwnProperty.call(message, "operationStatus"))
                        $root.waiternow.common.OperationStatusProto.encode(message.operationStatus, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
                    if (message.report != null && Object.hasOwnProperty.call(message, "report"))
                        $root.waiternow.common.SalesSummaryReportProto.encode(message.report, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
                    return writer;
                };

                /**
                 * Encodes the specified Response message, length delimited. Does not implicitly {@link waiternow.common.GetSalesSummaryReportActionProto.Response.verify|verify} messages.
                 * @function encodeDelimited
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.IResponse} message Response message or plain object to encode
                 * @param {$protobuf.Writer} [writer] Writer to encode to
                 * @returns {$protobuf.Writer} Writer
                 */
                Response.encodeDelimited = function encodeDelimited(message, writer) {
                    return this.encode(message, writer).ldelim();
                };

                /**
                 * Decodes a Response message from the specified reader or buffer.
                 * @function decode
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @param {number} [length] Message length if known beforehand
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decode = function decode(reader, length) {
                    if (!(reader instanceof $Reader))
                        reader = $Reader.create(reader);
                    var end = length === undefined ? reader.len : reader.pos + length, message = new $root.waiternow.common.GetSalesSummaryReportActionProto.Response();
                    while (reader.pos < end) {
                        var tag = reader.uint32();
                        switch (tag >>> 3) {
                        case 1: {
                                message.operationStatus = $root.waiternow.common.OperationStatusProto.decode(reader, reader.uint32());
                                break;
                            }
                        case 2: {
                                message.report = $root.waiternow.common.SalesSummaryReportProto.decode(reader, reader.uint32());
                                break;
                            }
                        default:
                            reader.skipType(tag & 7);
                            break;
                        }
                    }
                    return message;
                };

                /**
                 * Decodes a Response message from the specified reader or buffer, length delimited.
                 * @function decodeDelimited
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Response} Response
                 * @throws {Error} If the payload is not a reader or valid buffer
                 * @throws {$protobuf.util.ProtocolError} If required fields are missing
                 */
                Response.decodeDelimited = function decodeDelimited(reader) {
                    if (!(reader instanceof $Reader))
                        reader = new $Reader(reader);
                    return this.decode(reader, reader.uint32());
                };

                /**
                 * Verifies a Response message.
                 * @function verify
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {Object.<string,*>} message Plain object to verify
                 * @returns {string|null} `null` if valid, otherwise the reason why it is not
                 */
                Response.verify = function verify(message) {
                    if (typeof message !== "object" || message === null)
                        return "object expected";
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus")) {
                        var error = $root.waiternow.common.OperationStatusProto.verify(message.operationStatus);
                        if (error)
                            return "operationStatus." + error;
                    }
                    if (message.report != null && message.hasOwnProperty("report")) {
                        var error = $root.waiternow.common.SalesSummaryReportProto.verify(message.report);
                        if (error)
                            return "report." + error;
                    }
                    return null;
                };

                /**
                 * Creates a Response message from a plain object. Also converts values to their respective internal types.
                 * @function fromObject
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {Object.<string,*>} object Plain object
                 * @returns {waiternow.common.GetSalesSummaryReportActionProto.Response} Response
                 */
                Response.fromObject = function fromObject(object) {
                    if (object instanceof $root.waiternow.common.GetSalesSummaryReportActionProto.Response)
                        return object;
                    var message = new $root.waiternow.common.GetSalesSummaryReportActionProto.Response();
                    if (object.operationStatus != null) {
                        if (typeof object.operationStatus !== "object")
                            throw TypeError(".waiternow.common.GetSalesSummaryReportActionProto.Response.operationStatus: object expected");
                        message.operationStatus = $root.waiternow.common.OperationStatusProto.fromObject(object.operationStatus);
                    }
                    if (object.report != null) {
                        if (typeof object.report !== "object")
                            throw TypeError(".waiternow.common.GetSalesSummaryReportActionProto.Response.report: object expected");
                        message.report = $root.waiternow.common.SalesSummaryReportProto.fromObject(object.report);
                    }
                    return message;
                };

                /**
                 * Creates a plain object from a Response message. Also converts values to other types if specified.
                 * @function toObject
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {waiternow.common.GetSalesSummaryReportActionProto.Response} message Response
                 * @param {$protobuf.IConversionOptions} [options] Conversion options
                 * @returns {Object.<string,*>} Plain object
                 */
                Response.toObject = function toObject(message, options) {
                    if (!options)
                        options = {};
                    var object = {};
                    if (options.defaults) {
                        object.operationStatus = null;
                        object.report = null;
                    }
                    if (message.operationStatus != null && message.hasOwnProperty("operationStatus"))
                        object.operationStatus = $root.waiternow.common.OperationStatusProto.toObject(message.operationStatus, options);
                    if (message.report != null && message.hasOwnProperty("report"))
                        object.report = $root.waiternow.common.SalesSummaryReportProto.toObject(message.report, options);
                    return object;
                };

                /**
                 * Converts this Response to JSON.
                 * @function toJSON
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @instance
                 * @returns {Object.<string,*>} JSON object
                 */
                Response.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                /**
                 * Gets the default type url for Response
                 * @function getTypeUrl
                 * @memberof waiternow.common.GetSalesSummaryReportActionProto.Response
                 * @static
                 * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
                 * @returns {string} The default type url
                 */
                Response.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                    if (typeUrlPrefix === undefined) {
                        typeUrlPrefix = "type.googleapis.com";
                    }
                    return typeUrlPrefix + "/waiternow.common.GetSalesSummaryReportActionProto.Response";
                };

                return Response;
            })();

            return GetSalesSummaryReportActionProto;
        })();

        return common;
    })();

    return waiternow;
})();

$root.google = (function() {

    /**
     * Namespace google.
     * @exports google
     * @namespace
     */
    var google = {};

    google.protobuf = (function() {

        /**
         * Namespace protobuf.
         * @memberof google
         * @namespace
         */
        var protobuf = {};

        protobuf.Timestamp = (function() {

            /**
             * Properties of a Timestamp.
             * @memberof google.protobuf
             * @interface ITimestamp
             * @property {number|null} [seconds] Timestamp seconds
             * @property {number|null} [nanos] Timestamp nanos
             */

            /**
             * Constructs a new Timestamp.
             * @memberof google.protobuf
             * @classdesc Represents a Timestamp.
             * @implements ITimestamp
             * @constructor
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             */
            function Timestamp(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Timestamp seconds.
             * @member {number} seconds
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Timestamp nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Timestamp
             * @instance
             */
            Timestamp.prototype.nanos = 0;

            /**
             * Creates a new Timestamp instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp=} [properties] Properties to set
             * @returns {google.protobuf.Timestamp} Timestamp instance
             */
            Timestamp.create = function create(properties) {
                return new Timestamp(properties);
            };

            /**
             * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.ITimestamp} message Timestamp message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Timestamp();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Timestamp message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Timestamp} Timestamp
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Timestamp.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Timestamp message.
             * @function verify
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Timestamp.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Timestamp} Timestamp
             */
            Timestamp.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Timestamp)
                    return object;
                var message = new $root.google.protobuf.Timestamp();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {google.protobuf.Timestamp} message Timestamp
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Timestamp.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Timestamp to JSON.
             * @function toJSON
             * @memberof google.protobuf.Timestamp
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Timestamp.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Timestamp
             * @function getTypeUrl
             * @memberof google.protobuf.Timestamp
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Timestamp.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Timestamp";
            };

            return Timestamp;
        })();

        protobuf.Any = (function() {

            /**
             * Properties of an Any.
             * @memberof google.protobuf
             * @interface IAny
             * @property {string|null} [type_url] Any type_url
             * @property {Uint8Array|null} [value] Any value
             */

            /**
             * Constructs a new Any.
             * @memberof google.protobuf
             * @classdesc Represents an Any.
             * @implements IAny
             * @constructor
             * @param {google.protobuf.IAny=} [properties] Properties to set
             */
            function Any(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Any type_url.
             * @member {string} type_url
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.type_url = "";

            /**
             * Any value.
             * @member {Uint8Array} value
             * @memberof google.protobuf.Any
             * @instance
             */
            Any.prototype.value = $util.newBuffer([]);

            /**
             * Creates a new Any instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny=} [properties] Properties to set
             * @returns {google.protobuf.Any} Any instance
             */
            Any.create = function create(properties) {
                return new Any(properties);
            };

            /**
             * Encodes the specified Any message. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.type_url != null && Object.hasOwnProperty.call(message, "type_url"))
                    writer.uint32(/* id 1, wireType 2 =*/10).string(message.type_url);
                if (message.value != null && Object.hasOwnProperty.call(message, "value"))
                    writer.uint32(/* id 2, wireType 2 =*/18).bytes(message.value);
                return writer;
            };

            /**
             * Encodes the specified Any message, length delimited. Does not implicitly {@link google.protobuf.Any.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.IAny} message Any message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Any.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes an Any message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Any();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.type_url = reader.string();
                            break;
                        }
                    case 2: {
                            message.value = reader.bytes();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes an Any message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Any
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Any} Any
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Any.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies an Any message.
             * @function verify
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Any.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    if (!$util.isString(message.type_url))
                        return "type_url: string expected";
                if (message.value != null && message.hasOwnProperty("value"))
                    if (!(message.value && typeof message.value.length === "number" || $util.isString(message.value)))
                        return "value: buffer expected";
                return null;
            };

            /**
             * Creates an Any message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Any
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Any} Any
             */
            Any.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Any)
                    return object;
                var message = new $root.google.protobuf.Any();
                if (object.type_url != null)
                    message.type_url = String(object.type_url);
                if (object.value != null)
                    if (typeof object.value === "string")
                        $util.base64.decode(object.value, message.value = $util.newBuffer($util.base64.length(object.value)), 0);
                    else if (object.value.length >= 0)
                        message.value = object.value;
                return message;
            };

            /**
             * Creates a plain object from an Any message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Any
             * @static
             * @param {google.protobuf.Any} message Any
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Any.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    object.type_url = "";
                    if (options.bytes === String)
                        object.value = "";
                    else {
                        object.value = [];
                        if (options.bytes !== Array)
                            object.value = $util.newBuffer(object.value);
                    }
                }
                if (message.type_url != null && message.hasOwnProperty("type_url"))
                    object.type_url = message.type_url;
                if (message.value != null && message.hasOwnProperty("value"))
                    object.value = options.bytes === String ? $util.base64.encode(message.value, 0, message.value.length) : options.bytes === Array ? Array.prototype.slice.call(message.value) : message.value;
                return object;
            };

            /**
             * Converts this Any to JSON.
             * @function toJSON
             * @memberof google.protobuf.Any
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Any
             * @function getTypeUrl
             * @memberof google.protobuf.Any
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Any.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Any";
            };

            return Any;
        })();

        protobuf.Duration = (function() {

            /**
             * Properties of a Duration.
             * @memberof google.protobuf
             * @interface IDuration
             * @property {number|null} [seconds] Duration seconds
             * @property {number|null} [nanos] Duration nanos
             */

            /**
             * Constructs a new Duration.
             * @memberof google.protobuf
             * @classdesc Represents a Duration.
             * @implements IDuration
             * @constructor
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             */
            function Duration(properties) {
                if (properties)
                    for (var keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                        if (properties[keys[i]] != null)
                            this[keys[i]] = properties[keys[i]];
            }

            /**
             * Duration seconds.
             * @member {number} seconds
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.seconds = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            /**
             * Duration nanos.
             * @member {number} nanos
             * @memberof google.protobuf.Duration
             * @instance
             */
            Duration.prototype.nanos = 0;

            /**
             * Creates a new Duration instance using the specified properties.
             * @function create
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration=} [properties] Properties to set
             * @returns {google.protobuf.Duration} Duration instance
             */
            Duration.create = function create(properties) {
                return new Duration(properties);
            };

            /**
             * Encodes the specified Duration message. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
             * @function encode
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encode = function encode(message, writer) {
                if (!writer)
                    writer = $Writer.create();
                if (message.seconds != null && Object.hasOwnProperty.call(message, "seconds"))
                    writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
                if (message.nanos != null && Object.hasOwnProperty.call(message, "nanos"))
                    writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
                return writer;
            };

            /**
             * Encodes the specified Duration message, length delimited. Does not implicitly {@link google.protobuf.Duration.verify|verify} messages.
             * @function encodeDelimited
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.IDuration} message Duration message or plain object to encode
             * @param {$protobuf.Writer} [writer] Writer to encode to
             * @returns {$protobuf.Writer} Writer
             */
            Duration.encodeDelimited = function encodeDelimited(message, writer) {
                return this.encode(message, writer).ldelim();
            };

            /**
             * Decodes a Duration message from the specified reader or buffer.
             * @function decode
             * @memberof google.protobuf.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @param {number} [length] Message length if known beforehand
             * @returns {google.protobuf.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decode = function decode(reader, length) {
                if (!(reader instanceof $Reader))
                    reader = $Reader.create(reader);
                var end = length === undefined ? reader.len : reader.pos + length, message = new $root.google.protobuf.Duration();
                while (reader.pos < end) {
                    var tag = reader.uint32();
                    switch (tag >>> 3) {
                    case 1: {
                            message.seconds = reader.int64();
                            break;
                        }
                    case 2: {
                            message.nanos = reader.int32();
                            break;
                        }
                    default:
                        reader.skipType(tag & 7);
                        break;
                    }
                }
                return message;
            };

            /**
             * Decodes a Duration message from the specified reader or buffer, length delimited.
             * @function decodeDelimited
             * @memberof google.protobuf.Duration
             * @static
             * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
             * @returns {google.protobuf.Duration} Duration
             * @throws {Error} If the payload is not a reader or valid buffer
             * @throws {$protobuf.util.ProtocolError} If required fields are missing
             */
            Duration.decodeDelimited = function decodeDelimited(reader) {
                if (!(reader instanceof $Reader))
                    reader = new $Reader(reader);
                return this.decode(reader, reader.uint32());
            };

            /**
             * Verifies a Duration message.
             * @function verify
             * @memberof google.protobuf.Duration
             * @static
             * @param {Object.<string,*>} message Plain object to verify
             * @returns {string|null} `null` if valid, otherwise the reason why it is not
             */
            Duration.verify = function verify(message) {
                if (typeof message !== "object" || message === null)
                    return "object expected";
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (!$util.isInteger(message.seconds) && !(message.seconds && $util.isInteger(message.seconds.low) && $util.isInteger(message.seconds.high)))
                        return "seconds: integer|Long expected";
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    if (!$util.isInteger(message.nanos))
                        return "nanos: integer expected";
                return null;
            };

            /**
             * Creates a Duration message from a plain object. Also converts values to their respective internal types.
             * @function fromObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {Object.<string,*>} object Plain object
             * @returns {google.protobuf.Duration} Duration
             */
            Duration.fromObject = function fromObject(object) {
                if (object instanceof $root.google.protobuf.Duration)
                    return object;
                var message = new $root.google.protobuf.Duration();
                if (object.seconds != null)
                    if ($util.Long)
                        (message.seconds = $util.Long.fromValue(object.seconds)).unsigned = false;
                    else if (typeof object.seconds === "string")
                        message.seconds = parseInt(object.seconds, 10);
                    else if (typeof object.seconds === "number")
                        message.seconds = object.seconds;
                    else if (typeof object.seconds === "object")
                        message.seconds = new $util.LongBits(object.seconds.low >>> 0, object.seconds.high >>> 0).toNumber();
                if (object.nanos != null)
                    message.nanos = object.nanos | 0;
                return message;
            };

            /**
             * Creates a plain object from a Duration message. Also converts values to other types if specified.
             * @function toObject
             * @memberof google.protobuf.Duration
             * @static
             * @param {google.protobuf.Duration} message Duration
             * @param {$protobuf.IConversionOptions} [options] Conversion options
             * @returns {Object.<string,*>} Plain object
             */
            Duration.toObject = function toObject(message, options) {
                if (!options)
                    options = {};
                var object = {};
                if (options.defaults) {
                    if ($util.Long) {
                        var long = new $util.Long(0, 0, false);
                        object.seconds = options.longs === String ? long.toString() : options.longs === Number ? long.toNumber() : long;
                    } else
                        object.seconds = options.longs === String ? "0" : 0;
                    object.nanos = 0;
                }
                if (message.seconds != null && message.hasOwnProperty("seconds"))
                    if (typeof message.seconds === "number")
                        object.seconds = options.longs === String ? String(message.seconds) : message.seconds;
                    else
                        object.seconds = options.longs === String ? $util.Long.prototype.toString.call(message.seconds) : options.longs === Number ? new $util.LongBits(message.seconds.low >>> 0, message.seconds.high >>> 0).toNumber() : message.seconds;
                if (message.nanos != null && message.hasOwnProperty("nanos"))
                    object.nanos = message.nanos;
                return object;
            };

            /**
             * Converts this Duration to JSON.
             * @function toJSON
             * @memberof google.protobuf.Duration
             * @instance
             * @returns {Object.<string,*>} JSON object
             */
            Duration.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            /**
             * Gets the default type url for Duration
             * @function getTypeUrl
             * @memberof google.protobuf.Duration
             * @static
             * @param {string} [typeUrlPrefix] your custom typeUrlPrefix(default "type.googleapis.com")
             * @returns {string} The default type url
             */
            Duration.getTypeUrl = function getTypeUrl(typeUrlPrefix) {
                if (typeUrlPrefix === undefined) {
                    typeUrlPrefix = "type.googleapis.com";
                }
                return typeUrlPrefix + "/google.protobuf.Duration";
            };

            return Duration;
        })();

        return protobuf;
    })();

    return google;
})();

module.exports = $root;
